<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import FormSwitch from '../Atoms/FormSwitch.vue';
import IconBase from '../icon/IconBase.vue';
import IconHelp from '../icon/IconHelp.vue';
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"

const store = useStore()

const toggleRetainContents = () => store.dispatch('Playout/toggleRetainContents')

const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])

</script>

<template>
  <section class="mt-4 mb-3 flex items-center !flex-wrap md:!flex-nowrap">
    <p class="text-surface-9 text-tiny basis-full md:basis-auto">Adjust the Start Time for the contents.</p>
    <FormSwitch class="md:ml-auto !mt-2 md:!mt-0" @change="toggleRetainContents()" :modelValue="playoutConfig?.settings?.retainContents">
      <div class="flex items-center">
        Retain Contents
      </div>
    </FormSwitch>
    <ejs-tooltip content="Toggling on will hold the content(s) in their places after the TV Playout stream has ended.">
      <icon-base class="ml-2 !mt-2 md:!mt-0">
        <icon-help />
      </icon-base>
    </ejs-tooltip>
  </section>
</template>

<style scoped></style>
