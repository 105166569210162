<template>
	<section>
		<b-row>
			<b-col>
				<b-form-group class="w-100 bg-dark-3 mt-5">
					<b-form-radio-group class="btn-group_spacing w-100" size="md" id="destination-mode-check" v-model="customPlatform.mode" buttons
						button-variant="dark-3" @input="">
						<b-form-radio @change="clearForm" value="rtmp" class="flex-wrap col-6 p-0">
							RTMP
						</b-form-radio>
						<b-form-radio @change="clearForm" class="col-6 p-0 text-s-m" value="srt">
							SRT
						</b-form-radio>
					</b-form-radio-group>
				</b-form-group>
			</b-col>
		</b-row>
		<template v-if="customPlatform.mode === 'rtmp'">
			<b-row>
				<b-col>
					<p v-if="errors.server" class="text-danger text-base position-absolute top-0 error-message m-0">Invalid destination ingest address</p>
					<label class="mt-3">
						streaming server
					</label>
					<div class="position-relative">
						<b-input v-model="customPlatform.server" placeholder="rtmp://broadcaster_addr/" size="md" :class="{ error: errors.server }" class="mb-4" @blur="validate('server')" />
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-checkbox class="mb-3" v-model="customPlatform.isAuthRequired">Authentication Required
					</b-form-checkbox>
				</b-col>
			</b-row>
			<b-row v-if="customPlatform.isAuthRequired">
				<b-col cols="12">
					<b-input v-model="customPlatform.username" placeholder="Username" size="md" class="mb-3" />
				</b-col>
				<b-col cols="12">
					<b-input type="password" v-model="customPlatform.password" placeholder="Password" size="md" class="mb-3" />
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<label class="">
						streaming key
					</label>
					<b-input v-model="customPlatform.key" placeholder="#streaming_key" size="md" class="mb-3" />
				</b-col>
			</b-row>
		</template>
		<template v-if="customPlatform.mode === 'srt'">
			<b-row>
				<b-col>
					<p v-if="customPlatform.port > 65535" class="text-danger text-base absolute -top-2 error-message">Invalid destination ingest address</p>
					<label class="mt-3">
						Host
					</label>
					<b-input v-model="customPlatform.server" placeholder="srt-ingest.example.com" size="md" class="mb-3" />
				</b-col>
			</b-row>
			<b-row>
				<b-col class="mb-3">
					<label class="">
						Port
					</label>
					<div class="position-relative">
						<b-input v-model="customPlatform.port" min="0" max="65535" placeholder="9998" type="number" size="md" class="" />
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<label class="">
						Stream Id
					</label>
					<b-input v-model="customPlatform.key" placeholder="stream id" size="md" class="mb-3" />
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<label class="">
						Latency
					</label>
					<b-input v-model="customPlatform.latency" type="number" placeholder="120" size="md" class="mb-3" />
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<label class="">
						Passphrase
					</label>
					<b-input v-model="customPlatform.passphrase" placeholder="Passphrase" size="md" class="mb-3" />
				</b-col>
			</b-row>
		</template>
	</section>
</template>

<script>
import utils from '@/utils'

import { mapGetters } from 'vuex'
import IconBase from '@/components/icon/IconBase.vue'
import IconSettings from '@/components/icon/IconSettings.vue'
import IconCaretDown from '@/components/icon/IconCaretDown.vue'

export default {
	name: 'PlatformDetailsCustom',
	components: {
		IconBase,
		IconSettings,
		IconCaretDown,
	},
	props: {

	},
	data() {
		return {
			customPlatform: {
				mode: 'rtmp',
				isAuthRequired: false,
				server: null,
				key: null,
				username: null,
				password: null,
				port: null,
				latency: 120,
				passphrase: null,
			},
			errors: {
				server: false,
			}
		};
	},
	computed: {
		...mapGetters({
			isPlatformSelected: "Destination/isPlatformSelected",
			selectedPlatform: "Destination/selectedPlatform",
			isCustomPlatform: "Destination/isCustomPlatform",
			isDisabled: "Destination/isDisabled",
		}),
		serverComputed() {
			if(this.customPlatform.mode === 'rtmp') {
				return this.customPlatform.isAuthRequired && this.customPlatform.username && this.customPlatform.password
				?	"rtmp://" + this.customPlatform?.username + ":" + this.customPlatform?.password + "@" + this.customPlatform?.server?.replace(/^(rtmp\:\/\/)/,"")
				: this.customPlatform.server
			} else if (this.customPlatform.server?.startsWith('srt://')) return this.customPlatform.server
			return 'srt://' + this.customPlatform.server
		}
	},
	watch: {
		customPlatform: {
			handler: function(item) {
				this.errors.server = false
				const customPlatform = {}
				item.mode === 'rtmp' 
					? (
						customPlatform.server = this.serverComputed,
						customPlatform.key = item.key)
					: (
						customPlatform.mode = 'srt',
						customPlatform.server = this.serverComputed,
						customPlatform.key = item.key,
						customPlatform.port = item.port,
						customPlatform.latency = item.latency,
						customPlatform.passphrase = item.passphrase
					)
				this.$emit('platform-details', customPlatform);
			},
			deep: true
		},
	},
	async mounted() {

	},
	methods: {
		clearForm() {
			this.customPlatform = {
				mode: this.customPlatform.mode,
				isAuthRequired: false,
				server: null,
				key: null,
				username: null,
				password: null,
				port: null,
				latency: 120,
				passphrase: null,
			}
		},
		validate(type) {
			if (type === 'server' && this.customPlatform.server) {
				const { valid } = utils.validateURL(this.customPlatform.server, { validatePathname: false })
				valid 
					? this.errors[type] = false
					: this.errors[type] = true
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-control.error + .error-message {
	bottom: -1.25rem
}
.form-control:invalid {
	border-color: var(--c-danger-1);
	background-color: var(--c-danger-bg);
}
</style>