<template>
	<div class>
		<!-- Modal Component -->
		<b-modal centered scrollable header-class="border-0" body-class="px-3" size="lg" @hide="reset" :id="'modal-select-live-to-vod'"
			ref="modalSelectLiveToVod">
			<template #modal-header>
				<b-row class="w-100">
					<b-col>
						<div class="stream-type-info">
							<p class="text-s-m text-dark-8 mt-2">Select the file you'd like to upload.</p>
							<div v-if="!selectedStream._id">
								<h4 class="text-s-xxxl d-none d-md-block text-500">Video Hosting</h4>
								<b-input-group size="md" class="search-form search-vod ml-auto mb-4">
									<template #prepend>
										<b-input-group-text class="bg-dark-3 border-0 text-dark-8">
											<svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" clip-rule="evenodd"
													d="M8.11096 15.2219C12.0382 15.2219 15.2219 12.0382 15.2219 8.11096C15.2219 4.18368 12.0382 1 8.11096 1C4.18368 1 1 4.18368 1 8.11096C1 12.0382 4.18368 15.2219 8.11096 15.2219Z"
													stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												<path d="M16.9994 16.9994L13.1328 13.1328" stroke="currentColor" stroke-width="1.5"
													stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</b-input-group-text>
									</template>
									<b-form-input ref="searchWidgetInputElement" placeholder="Search for files" class="search-form__field"
										@input="" v-model="searchString"></b-form-input>
									<b-button variant="icon" size="md" class="search-clear ml-n5">
										<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd"
												d="M8 15.5C11.866 15.5 15 12.366 15 8.5C15 4.63401 11.866 1.5 8 1.5C4.13401 1.5 1 4.63401 1 8.5C1 12.366 4.13401 15.5 8 15.5ZM6.03033 5.46967C5.73744 5.17678 5.26256 5.17678 4.96967 5.46967C4.67678 5.76256 4.67678 6.23744 4.96967 6.53033L6.93934 8.5L4.96967 10.4697C4.67678 10.7626 4.67678 11.2374 4.96967 11.5303C5.26256 11.8232 5.73744 11.8232 6.03033 11.5303L8 9.56066L9.96967 11.5303C10.2626 11.8232 10.7374 11.8232 11.0303 11.5303C11.3232 11.2374 11.3232 10.7626 11.0303 10.4697L9.06066 8.5L11.0303 6.53033C11.3232 6.23744 11.3232 5.76256 11.0303 5.46967C10.7374 5.17678 10.2626 5.17678 9.96967 5.46967L8 7.43934L6.03033 5.46967Z"
												fill="white" />
										</svg>
									</b-button>
									<b-dropdown ref="filter-vod" menu-class="dropdown-menu_md dropdown-menu_tag-list" toggle-class=""
										variant="dark-3" size="md" class="ml-md-2 mb-4 mb-md-0 tag-filter">
										<template #button-content>
											<div class="d-flex justify-content-between align-items-center w-100">
												<div class="text-dark-8 mr-2">{{ streamType }}</div>
											</div>
										</template>
										<b-dropdown-item @click="streamType = 'All'"
											link-class="d-flex justify-content-between align-items-center tag-item">
											<span class="">All</span>
										</b-dropdown-item>
										<b-dropdown-item @click="streamType = 'Live-to-VOD'"
											link-class="d-flex justify-content-between align-items-center tag-item">
											<span class="">Live-to-VOD</span>
										</b-dropdown-item>
										<b-dropdown-item @click="streamType = 'Normal VOD Folders'"
											link-class="d-flex justify-content-between align-items-center tag-item">
											<span class="">Normal VOD Folders</span>
										</b-dropdown-item>
									</b-dropdown>
								</b-input-group>
							</div>
							<div v-else class="d-flex mr-1 align-items-start">
								<b-button variant="icon" size="sm" @click="navigateManage()" class="text-dark-7 px-2">
									<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M7 13L1 7L7 1" stroke="#FAFAFB" stroke-width="2" stroke-linecap="round"
											stroke-linejoin="round" />
									</svg>
								</b-button>
								<span class="align-items-start mt-1">
									<h4 class="text-s-xl d-none d-md-block text-500">{{ selectedStream.name }}</h4>
								</span>
							</div>
						</div>
					</b-col>
				</b-row>
			</template>
			<div class="modal-container">
				<!-- Summary table -->
				<b-table v-if="isLoaded && filterStreams.length && !selectedStream._id" :items="filterStreams" :fields="fields"
					table-class="main-table main-table_bordered-3" thead-class="text-dark-6" stacked="lg">
					<template #cell(name)="row">
						<div @click="navigateManage(row.item)" class="d-flex align-items-center cursor-pointer">
							<svg v-if="!row.item.isFile" class="mr-2 text-dark-8" width="24" height="24" viewBox="0 0 24 24" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M1.5 8.3C1.5 6.61984 1.5 5.77976 1.82698 5.13803C2.1146 4.57354 2.57354 4.1146 3.13803 3.82698C3.77976 3.5 4.61984 3.5 6.3 3.5H8.17452C8.6637 3.5 8.90829 3.5 9.13846 3.55526C9.34254 3.60425 9.53763 3.68506 9.71657 3.79472C9.9184 3.9184 10.0914 4.09135 10.4373 4.43726L11.5627 5.56274C11.9086 5.90864 12.0816 6.0816 12.2834 6.20528C12.4624 6.31494 12.6575 6.39575 12.8615 6.44474C13.0917 6.5 13.3363 6.5 13.8255 6.5H17.7C19.3802 6.5 20.2202 6.5 20.862 6.82698C21.4265 7.1146 21.8854 7.57354 22.173 8.13803C22.5 8.77976 22.5 9.61984 22.5 11.3V15.7C22.5 17.3802 22.5 18.2202 22.173 18.862C21.8854 19.4265 21.4265 19.8854 20.862 20.173C20.2202 20.5 19.3802 20.5 17.7 20.5H6.3C4.61984 20.5 3.77976 20.5 3.13803 20.173C2.57354 19.8854 2.1146 19.4265 1.82698 18.862C1.5 18.2202 1.5 17.3802 1.5 15.7V8.3Z"
									fill="currentColor" />
							</svg>
							<div class="text-nowrap text-dark-9 text-s-m">
								<b-badge v-if="row.item.liveParent" variant="dark-3"
									class="badge_beta position-static mr-2">Live-to-VOD</b-badge>
								{{ toShortString(row.item.name, 25) }}
							</div>
						</div>
					</template>
					<template #cell(files)="row">
						<div @click="navigateManage(row.item)" class="text-nowrap text-dark-9 cursor-pointer">
							{{ row.item.totalFiles }}
						</div>
					</template>
					<template #cell(size)="row">
						<div @click="navigateManage(row.item)" class="text-nowrap text-dark-9 cursor-pointer">
							{{ getBytes(row.item.totalBytes) }}
						</div>
					</template>
					<template #cell(date)="row">
						<div @click="navigateManage(row.item)" class="text-nowrap text-dark-9 cursor-pointer">
							{{ dateFormated(row.item.creationTime, "hh:mm DD-MM-YYYY") }}
						</div>
					</template>
				</b-table>

				<!-- Details table-->
				<b-table v-else-if="isPlaylistLoaded" :items="selectedStream.playlist" :fields="fieldDetails"
					table-class="main-table main-table_bordered-3  text-s-m" thead-class="text-dark-6" stacked="lg">
					<template #cell(name)="row">
						<div class="d-flex align-items-center cursor-pointer">
							<div class="d-flex text-nowrap text-dark-9 align-items-center">
								<b-form-checkbox class="mr-2" size="md" @change="toggleVideo(row.item)" v-model="row.item.selected"
									:disabled="row.item.invalid" />
								{{ toShortString(row.item.fileName, 25) }}
							</div>
						</div>
					</template>
					<template #cell(size)="row">
						<div class="text-nowrap text-dark-9 cursor-pointer">
							{{ getBytes(row.item.bytes) }}
							<svg class="mb-1" v-if="row.item.invalid" v-b-tooltip.hover :title="row.item.errorMessage"
								:id="`${row.item.from}-${row.item.duration}`" width="14" height="15" viewBox="0 0 14 15" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z"
									fill="#E25858" />
							</svg>
						</div>
					</template>
					<template #cell(duration)="row">
						<div class="text-nowrap text-dark-9 cursor-pointer">
							{{ formatDurations(row.item.mediaInfo.duration) }}
						</div>
					</template>
					<template #cell(date)="row">
						<div class="text-nowrap text-dark-9 cursor-pointer">
							{{ dateFormated(row.item.creationTime, "hh:mm DD-MM-YYYY") }}
						</div>
					</template>
				</b-table>
			</div>
			<template #modal-footer>
				<b-alert v-if="isPlaylistLoaded && selectedStream.playlist" show
					class="py-2 px-3 my-0 mr-2 text-s-s d-flex align-items-start" variant="secondary">
					<svg class="mr-2" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd"
							d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z"
							fill="#AEBAD6" />
					</svg>
					<div>
						<p class="mb-0">{{ totalFilesSelected }} {{totalFilesSelected === 1 ? 'file' : 'files'}} selected</p>
						<p class="mb-0"
							:class="totalFilesSelected + videoFiles.length > totalFilesPerStream ? 'text-danger' : ''">Maximux {{ totalFilesSelected + videoFiles.length > totalFilesPerStream ? '0' : totalFilesPerStream - totalFilesSelected - videoFiles.length }} can be selected
						<router-link class="text-reset text-underline ml-2" to="/subscribe?category=live" target="_blank">
							Upgrade here!
						</router-link></p>
					</div>
				</b-alert>
				<b-button v-if="isLoaded && !isLoading" variant="primary" size="md" @click="onConfirm"
					:disabled="totalFilesSelected + videoFiles.length > totalFilesPerStream || totalFilesSelected === 0">
					Upload Video
				</b-button>
				<b-button v-else variant="primary" size="md"
					disabled>
					<Spinner text="Uploading..." classes="text-dark-8" spinner-color="var(--c-dark-8)" spinner-size="15px" />
				</b-button>
				
			</template>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { Duration } from "luxon";
import ClipLoader from "@/components/ui/ClipLoader.vue";
import StreamService from '@/services/StreamService';
import MetricsService from '@/services/MetricsService'
import IconBase from '@/components/icon/IconBase.vue';
import IconLivestream from '@/components/icon/IconLivestream.vue';
import IconMultistream from '@/components/icon/IconMultistream.vue';
import IconIpcam from '@/components/icon/IconIpcam.vue';
import IconSchedulestream from '@/components/icon/IconSchedulestream.vue';
import IconVodChannel from '@/components/icon/IconVodChannel.vue';
import UserService from '../../services/UserService';
import Spinner from "@/components/ui/Spinner.vue"
import { shortString, date } from '@/filtersNew'

export default {
	name: 'SelectLiveToVodModal',
	components: {
		ClipLoader,
		IconBase,
		IconLivestream,
		IconMultistream,
		IconIpcam,
		IconSchedulestream,
		IconVodChannel,
		Spinner
	},
	data() {
		return {
			selectedStream: {},
			streams: [],
			videoFilename: null,
			scheduledStorageLimit: {},
			activeScheduledSub: {},
			totalFilesSelected: 0,
			isLoaded: false,
			isLoading: false,
			isPlaylistLoaded: false,
			exceededFileQuota: false,
			searchString: '',
			streamType: 'All'
		};
	},
	props: {
		video: {
			type: Object,
		},
		stream: {
			type: Object,
		},
		totalFilesPerStream: Number,
		videoFiles: Array
	},
	computed: {
		...mapGetters({
			allStreams: 'Streams/streams',
		}),
		fields() {
			const allFields = [
				{ key: "name", label: "Name", sortable: true, sortDirection: "desc", class: 'text-500 text-s-s text-dark-6 pl-0' },
				{ key: "files", label: "Files", sortable: true, sortDirection: "desc", class: 'text-500 text-s-s text-dark-6' },
				{ key: "size", label: "Size", sortable: true, sortDirection: "desc", class: 'text-500 text-s-s text-dark-6' },
				{ key: "date", label: "Date", sortable: true, sortDirection: "desc", class: 'text-500 text-s-s text-dark-6' },
			];

			return allFields;
		},
		fieldDetails() {
			const allFields = [
				{ key: "name", label: "Name", sortable: true, sortDirection: "desc", class: 'pl-0' },

				{ key: "size", label: "File Size", sortable: true, sortDirection: "desc" },
				{ key: "duration", label: "Duration", sortable: true, sortDirection: "desc" },
				{ key: "date", label: "Date", sortable: true, sortDirection: "desc" },
			];

			return allFields;
		},
		preRecordedName() {
			return this.filename + '.mp4'
		},
		filename() {
			this.videoFilename = !this.videoFilename ? this.video.name : this.videoFilename
			return this.videoFilename
		},
		isScheduledTrialSub() {
			// Check Pre-recorded Stream 500MB - 7 Days Trial sub
			const subscriptions = this.$store.state.User.subscriptions;
			return subscriptions.some((sub) => sub.package._id === '5cd5485126fd039846a3255f')
		},
		maxVideoFileSize() {
			let maxSize = null;
			const subscriptions = this.$store.state.User.subscriptions;
			const activeScheduledSubs = _.filter(subscriptions, (sub) => sub.category === 'scheduled' && sub.enabled === true)

			activeScheduledSubs.forEach(sub => {
				try {
					let overrideSchedulerMaxVideoSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
					if (sub.definitionOverride && sub.definitionOverride.schedulerMaxVideoSize) {
						overrideSchedulerMaxVideoSize = parseFloat(sub.definitionOverride.schedulerMaxVideoSize)
					}
					if (maxSize) {
						const tmpMaxSize = overrideSchedulerMaxVideoSize;
						if (tmpMaxSize > maxSize) {
							maxSize = tmpMaxSize;
							this.activeScheduledSub = sub
						}
					} else {
						maxSize = overrideSchedulerMaxVideoSize;
						this.activeScheduledSub = sub
					}
				} catch (error) {
					console.error(error);
				}
			})

			if (this.scheduledStorageLimit?.unitsLeft > 0 && this.scheduledStorageLimit.unitsLeft < maxSize) {
				maxSize = parseFloat(this.scheduledStorageLimit.unitsLeft / (1024 ** 3)).toFixed(1)
			}
			if (this.scheduledStorageLimit?.unitsLeft <= 0) {
				maxSize = 0
			}
			return maxSize;
		},
		filterStreams() {
			const filteredStreams = _.orderBy(this.streams, ['creationTime'], ['desc']).filter((stream) => {
				if (stream.type !== 'vod') return false;
				return true
			});
			if (this.streamType === 'All') {
				return filteredStreams.filter(stream => stream.name.includes(this.searchString))
			} else if (this.streamType === 'Live-to-VOD') {
				return filteredStreams.filter(stream => stream.liveParent).filter(stream => stream.name.includes(this.searchString))
			} else if (this.streamType === 'Normal VOD Folders') {
				return filteredStreams.filter(stream => !stream.liveParent).filter(stream => stream.name.includes(this.searchString))
			}
		},
		videoSelected() {
			
				let unitsLeft = this.scheduledStorageLimit.unitsLeft
				this.selectedStream?.playlist?.forEach((item) => {
					if (item.selected) unitsLeft -= item.bytes
				})
				let selectedItems = this.selectedStream?.playlist?.map((item) => {
					if (!item.selected) {
						item.invalid = !(item.bytes <= parseFloat(this.maxVideoFileSize) * (1024 ** 3) && item.bytes <= unitsLeft && unitsLeft > 0)
						if (item.invalid) {
							if (item.bytes > parseFloat(this.maxVideoFileSize) * (1024 ** 3)) {
								item.errorMessage = 'Only file under ' + this.maxVideoFileSize + 'GB are allowed.'
							}
							if (item.bytes > unitsLeft || unitsLeft <= 0) {
								item.errorMessage = 'Your total storage limit has been exceeded. Please upgrade your subscription or delete some files to continue.'
							}
						}
					}
					return item
				})
				const videos = selectedItems?.filter(item => item.selected) || []
				return videos
			
		},
		activeTeam() {
			return this.$store.state.User.activatedTeam;
		}
	},
	async mounted() {
		this.$root.$on('bv::modal::show', async (bvEvent, modalId) => {
			if (modalId === 'modal-select-live-to-vod') {
				this.selectedStream = {}
				const res = await MetricsService.getSubscriptionStorage(UserService.getUserId(this.activeTeam), this.activeScheduledSub.package._id)
				this.scheduledStorageLimit = res ? res : {}
			}
		})
		while (!this.$store.state.User) {
			// Waiting
		}
		const subscriptions = this.$store.state.User.subscriptions;
		const activeScheduledSubs = _.filter(subscriptions, (sub) => sub.category === 'scheduled' && sub.enabled === true)

		let maxSize
		activeScheduledSubs.forEach(sub => {
			try {
				if (maxSize) {
					const tmpMaxSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
					if (tmpMaxSize > maxSize) {
						maxSize = tmpMaxSize;
						this.activeScheduledSub = sub
					}
				} else {
					maxSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
					this.activeScheduledSub = sub
				}
			} catch (error) {
				console.error(error);
			}
		})
		const res = await MetricsService.getSubscriptionStorage(UserService.getUserId(this.activeTeam), this.activeScheduledSub.package._id)
		this.scheduledStorageLimit = res ? res : {}
		this.streams = this.allStreams;
		this.isLoaded = true
	},
	methods: {
		videoValidate(video) {
			return (video.bytes <= parseFloat(this.maxVideoFileSize) * (1024 ** 3) && video.bytes <= this.scheduledStorageLimit.unitsLeft && this.scheduledStorageLimit.unitsLeft > 0)
		},
		onDismiss() {
			this.$refs.modalSelectLiveToVod.hide();
		},
		async onConfirm() {
			try {
				this.isLoading = true
				const streamObj = this.stream
				this.videoSelected.forEach(async (video) => {
					// Create playlist data
					let videoId
					if (video.liveToVod) {
						const videoUrl = video.downloadUrl
						const fileName = videoUrl.substring(videoUrl.indexOf(streamObj.user))
						let prefix
						if (video.archive) {
							prefix = video.archive.abr ? 'edge-en' : 'edge'
						} else {
							prefix = video.abr ? 'edge-en' : 'edge'
						}
						videoId = `episodes/${prefix}/${fileName}`
					} else {
						videoId = video.srcHls.substring(video.srcHls.indexOf('videos/'), video.srcHls.indexOf('/index.m3u8'))
					}

					const payload = {
						id: videoId,
						fileName: video.fileName + '.mp4',
						type: 'live-to-vod',
						bytes: video.bytes,
						mediaInfo: {
							codecs: [{ type: 'video', codec: 'AVC' }, { type: "audio", codec: "AAC" }],
							duration: video.mediaInfo.duration,
							durationMins: video.mediaInfo.durationMins
						}
					}
					const scheduledConfig = await StreamService.saveStreamPlaylistVideo(streamObj._id, payload)
					await this.$store.dispatch('Streams/getStreams');
				})
			} catch (err) {
				this.$notify({ group: 'error', title: err.error, text: err.message });
				console.log(err);
			}
			
			setTimeout(() => {
				this.onDismiss();
				this.$emit('updated');
				this.isLoading = false
			}, 2000);
		},
		reset() {
			this.totalFilesSelected = 0
			this.searchString = ''
			this.streamType = 'All'
		},
		navigateManage(stream) {
			if (!stream) {
				this.selectedStream = {}
				this.isPlaylistLoaded = false
			} else {
				this.selectedStream =_.cloneDeep(stream)
				StreamService.getStreamPlaylist(this.selectedStream._id).then(res => {
					this.selectedStream.playlist = res
					this.selectedStream.playlist = this.selectedStream.playlist.map(item => {
						item.liveToVod = this.selectedStream.liveParent ? true : false
						item.selected = false
						item.invalid = !this.videoValidate(item)
						if (item.invalid) item.errorMessage = this.getErrorMessage(item)
						return item
					})
					this.isPlaylistLoaded = true
				})
			}
		},
		getBytes(size) {
			if (size > 0) {
				let totalBytes = size;
				var i = Math.floor(Math.log(totalBytes) / Math.log(1000)),
					sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
				return (totalBytes / Math.pow(1000, i)).toFixed(2) * 1 + " " + sizes[i];
			} else {
				return 0;
			}
		},
		getErrorMessage(video) {
			let errorMessage = ''

			if (video.bytes > parseFloat(this.maxVideoFileSize) * (1024 ** 3)) {
				errorMessage = 'Only file under ' + this.maxVideoFileSize + 'GB are allowed.'
			}
			if (video.bytes > this.scheduledStorageLimit.unitsLeft || this.scheduledStorageLimit.unitsLeft <= 0) {
				errorMessage = 'Your total storage limit has been exceeded. Please upgrade your subscription or delete some files to continue.'
			}
			return errorMessage
		},
		formatDurations(durationSeconds) {
			if (!durationSeconds) return "-";
			let formatStr = "s's'";
			if (durationSeconds >= 60) formatStr = "m'm' s's'";
			if (durationSeconds >= 3600) formatStr = "h'h' " + formatStr;
			if (durationSeconds >= 1440 * 60) formatStr = "d'd' " + formatStr;
			return Duration.fromObject({ seconds: durationSeconds }).toFormat(
				formatStr
			);
		},
		toggleVideo(item) {
			if (item.selected) {
				this.totalFilesSelected = this.totalFilesSelected + 1
			} else {
				this.totalFilesSelected = this.totalFilesSelected - 1
			}
		},
		toShortString(value, number) {
			return shortString(value, number)
		},
		dateFormated(item, format) {
			return date(item, format)
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-form__field {
	min-width: 200px
}
</style>
