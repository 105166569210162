<template>
  <b-container id="resizible-wrapper" class="highlight-container mx-auto px-0 ml-0 position-relative">
    <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)"
      spinner-size="15px" />
    <main v-else>
      <div class="highlight_content mt-5">
        <div class="highlight--top d-flex align-items-center justify-content-between">
          <div>
            <div class="d-flex align-items-center justify-content-start">
              <a href="/app/highlight" class="btn pl-0 btn-link-7 d-none d-md-block mt-0"><svg class="mr-1"
                  data-v-216aa6dc="" width="7" height="12" viewBox="0 0 7 12" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path data-v-216aa6dc="" fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.42417 1.07573C6.18985 0.841415 5.80995 0.841415 5.57564 1.07573L1.07564 5.57573C0.841324 5.81004 0.841324 6.18994 1.07564 6.42426L5.57564 10.9243C5.80995 11.1586 6.18985 11.1586 6.42417 10.9243C6.65848 10.6899 6.65848 10.31 6.42417 10.0757L2.34843 5.99999L6.42417 1.92426C6.65848 1.68994 6.65848 1.31004 6.42417 1.07573Z"
                    fill="currentColor"></path>
                </svg></a>
              <textarea v-model="highlightName" ref="highlightTitle" rows="1" autocomplete="off" autocorrect="off"
                autocapitalize="off" spellcheck="false" title="" style="background:transparent;border:0px"
                class="text-s-xxl text-500 mb-0 highlight-name pl-1 overflow-hidden form-control form-control-sm"
                @keydown.enter.prevent="preventEnter" @change="onHighlightChange" @focus="resizeTextarea"
                @keyup="resizeTextarea"></textarea>
            </div>
            <div class="d-flex align-items-center justify-content-start mb-3 pl-4">
              <svg style="filter: brightness(0) invert(1)" class="ml-1 mr-1" xmlns="http://www.w3.org/2000/svg"
                width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.5758 3.42426C11.4294 2.27786 9.57076 2.27787 8.42436 3.42426L7.92436 3.92426C7.69005 4.15858 7.31015 4.15858 7.07583 3.92426C6.84152 3.68995 6.84152 3.31005 7.07583 3.07573L7.57583 2.57574C9.19086 0.960709 11.8093 0.960705 13.4244 2.57573C15.0394 4.19076 15.0394 6.80923 13.4244 8.42426L12.4244 9.42426C10.8093 11.0393 8.19086 11.0393 6.57583 9.42426C6.34152 9.18995 6.34152 8.81005 6.57583 8.57573C6.81015 8.34142 7.19005 8.34142 7.42436 8.57573C8.57076 9.72213 10.4294 9.72213 11.5758 8.57573L12.5758 7.57573C13.7222 6.42934 13.7222 4.57066 12.5758 3.42426ZM8.57583 7.42426C7.42944 6.27786 5.57076 6.27786 4.42436 7.42426L3.42436 8.42426C2.27796 9.57066 2.27796 11.4293 3.42436 12.5757C4.57076 13.7221 6.42944 13.7221 7.57584 12.5757L8.07583 12.0757C8.31015 11.8414 8.69005 11.8414 8.92436 12.0757C9.15868 12.31 9.15868 12.6899 8.92436 12.9243L8.42436 13.4243C6.80934 15.0393 4.19086 15.0393 2.57583 13.4243C0.960806 11.8092 0.960807 9.19076 2.57583 7.57573L3.57583 6.57574C5.19086 4.96071 7.80933 4.9607 9.42436 6.57573C9.65868 6.81005 9.65868 7.18995 9.42436 7.42426C9.19005 7.65858 8.81015 7.65858 8.57583 7.42426Z"
                  fill="#AEBAD6" />
              </svg>
              <p class="mb-0 tex-dark-9 text-s-ss text-normal">{{ this.highlight && this.highlight.stream?.name }}</p>
            </div>
          </div>

          <div class=" d-md-flex align-items-center justify-content-start mt-2">
            <a class="text-s-s text-dark-6 inline-flex items-center mr-2" target="_blank"
              href="https://docs.castr.com/en/articles/8856289-highlights">
              <svg width="16" height="16" class="mr-1" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z"
                  stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                <path d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z"
                  stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              </svg>How Live Clipping works</a>
            <b-button class="text-s-s text-dark-6 px-0 mr-2 py-0 font-weight-normal" variant="link"
              v-b-modal.modal-demo-video>
              <svg width="16" height="16" class="mr-1" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.98597 3.65906L10.6171 5.62591C12.3187 6.54763 13.1695 7.0085 13.3161 7.68016C13.3621 7.89091 13.3621 8.10909 13.3161 8.31984C13.1695 8.9915 12.3187 9.45237 10.6171 10.3741L6.98597 12.3409C5.41916 13.1896 4.63576 13.614 4.01328 13.4004C3.81704 13.3331 3.6369 13.2258 3.48425 13.0853C3 12.6397 3 11.7487 3 9.96685V6.03315C3 4.25126 3 3.36032 3.48425 2.91468C3.6369 2.77419 3.81704 2.6669 4.01328 2.59958C4.63576 2.38604 5.41916 2.81038 6.98597 3.65906Z"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Live Clipping Demo
            </b-button>
            <b-dropdown menu-class="dropdown-menu_md" right no-caret variant="icon" size="sm" toggle-class="p-0"
              class="">
              <template #button-content>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4 8C4 8.69036 3.44036 9.25 2.75 9.25C2.05964 9.25 1.5 8.69036 1.5 8C1.5 7.30964 2.05964 6.75 2.75 6.75C3.44036 6.75 4 7.30964 4 8ZM9.25 8C9.25 8.69036 8.69036 9.25 8 9.25C7.30964 9.25 6.75 8.69036 6.75 8C6.75 7.30964 7.30964 6.75 8 6.75C8.69036 6.75 9.25 7.30964 9.25 8ZM13.25 9.25C13.9404 9.25 14.5 8.69036 14.5 8C14.5 7.30964 13.9404 6.75 13.25 6.75C12.5596 6.75 12 7.30964 12 8C12 8.69036 12.5596 9.25 13.25 9.25Z"
                    fill="#8794B5" />
                </svg>
              </template>
              <b-dropdown-item variant="disable" class="p-0" @click="highlightStatus()">
                <span>{{ this.isDisabled ? 'Enable' : 'Disable' }}</span>
              </b-dropdown-item>
              <b-dropdown-item variant="delete" class="p-0" @click="highlightDelete()">
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="highlight--bottom d-flex align-items-start justify-content-start">
          <div class="highlight_bottom--left">
            <div class="highlight-stream d-flex align-items-center justifycontent-center position-relative">
              <div class="rmp-wrap" v-if="!this.isDisabled && this.hlsUrl && !this.countdownSec && !this.streamOffline">
                <div id="rmp"></div>
              </div>
              <div class="placeholder-wrapper w-100 h-100 text-center" v-else>
                <div v-if="(!this.isDisabled && this.duration) || this.streamOffline">
                  <div class="waiting-time" v-if="this.countdownSec">
                    <code>Playback will be available in {{ Math.round(this.countdownSec) }} seconds.</code>
                  </div>
                  <div class="waiting-time" v-else-if="this.streamOffline">
                    <code>Stream offline</code>
                  </div>
                  <code v-else>Processing playback ..</code>
                </div>
                <span class="mb-0 text-s-l text-dark-4" v-if="this.isDisabled && this.duration">Live Clipping
                  Disabled</span>
              </div>
            </div>
            <TimeLine :position="[rangeStart, rangeEnd]" :height="20" :timeLine="this.timeline"
              @range="onTimeLineRangeChanged" v-if="!this.isDisabled && !this.countdownSec && !this.streamOffline" />
            <div class="back-live position-relative d-flex align-items-center justify-content-end">
              <b-button variant="primary" size="sm" @click="goBacktolive" :disabled="isLiveMode">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15"
                  fill="none">
                  <path
                    d="M1.66602 8.66665C1.66602 11.6122 4.05383 14 6.99935 14C9.94487 14 12.3327 11.6122 12.3327 8.66665C12.3327 5.72113 9.94487 3.33331 6.99935 3.33331H2.99935M2.99935 3.33331L4.99935 5.33331M2.99935 3.33331L4.99935 1.33331"
                    stroke="#FAFAFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="text-s-ss text-500">Live</span>
              </b-button>
            </div>
            <div class="clipping-control position-relative mb-2 d-flex align-items-center justifycontent-start">
              <div class="cbox clip-name mr-1 d-flex align-items-start justify-content-start w-100 flex-column">
                <p class="text-dark-9 text-s-ss text-500 mb-1">Clip Name</p>
                <div class="w-100 mr-1">
                  <b-input v-model="clipName" placeholder="" size="md" class="w-100 d-block" @input="nameChanged()" />
                </div>
              </div>
              <div class="cbox mr-1 ml-1 d-flex align-items-start justify-content-start flex-column">
                <p class="text-dark-9 text-s-ss text-500 mb-1">Start</p>
                <div class="d-inline-block mr-1 text-s-ss d-flex align-items-center justify-content-center"
                  v-html="humanTime(rangeStart)"></div>
              </div>
              <div class="cbox  mr-1 d-flex align-items-start justify-content-start flex-column">
                <p class="text-dark-9 text-s-ss text-500 mb-1">End</p>
                <div class="d-inline-block mr-1 text-s-ss d-flex align-items-center justify-content-center"
                  v-html="humanTime(rangeEnd)"></div>
              </div>
              <div class="cbox  mr-1 d-flex align-items-start justify-content-start flex-column">
                <p class="text-dark-9 text-s-ss text-500 mb-1">Duration</p>
                <div class="d-inline-block mr-1 text-s-ss d-flex align-items-center justify-content-center"
                  v-html="humanTimeRange(duration)"></div>
              </div>
              <div class="cbox  mr-1 d-flex align-items-start justify-content-start flex-column">
                <p class="text-dark-9 text-s-ss text-500 mb-1"></p>
                <span id="clip_btn">
                  <b-button variant="primary" size="md" :disabled="this.clipbtnStatus" @click="onExportCustomRange">
                    <span>Clip</span>
                  </b-button>
                </span>
                <b-tooltip v-if="this.isTrialSubscription" target="clip_btn">
                  Upgrade plan to use the feature.
                </b-tooltip>
              </div>
              <div class="cbox  mr-0 d-flex align-items-start justify-content-start flex-column">
                <p class="text-dark-9 text-s-ss text-500 mb-1"></p>
                <b-tooltip v-if="this.isTrialSubscription" target="dload_btn">
                  Upgrade plan to use the feature.
                </b-tooltip>
                <span id="dload_btn">
                  <b-button @click="downloadClip()" :disabled="this.isTrialSubscription"
                    class="clip-download w-100 justify-content-start mb-0" size="sm" variant="outline-secondary">
                    <svg class="" width="16" height="15" viewBox="0 0 16 15" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M11.9247 5.92417C12.159 5.68985 12.159 5.30995 11.9247 5.07564C11.6903 4.84132 11.3104 4.84132 11.0761 5.07564L8.60039 7.55137V0.999902C8.60039 0.668532 8.33176 0.399902 8.00039 0.399902C7.66902 0.399902 7.40039 0.668532 7.40039 0.999902V7.55137L4.92465 5.07564C4.69034 4.84132 4.31044 4.84132 4.07613 5.07564C3.84181 5.30995 3.84181 5.68985 4.07613 5.92417L7.57613 9.42417C7.81044 9.65848 8.19034 9.65848 8.42465 9.42417L11.9247 5.92417ZM0.900391 10.2999L0.900391 10.325C0.900384 10.8637 0.900378 11.3043 0.929632 11.6624C0.959912 12.033 1.0245 12.3677 1.18377 12.6803C1.43304 13.1695 1.83079 13.5672 2.32002 13.8165C2.63261 13.9758 2.96733 14.0404 3.33794 14.0707C3.69598 14.0999 4.13655 14.0999 4.67524 14.0999H4.67526H4.70039H11.3004H11.3255H11.3255C11.8642 14.0999 12.3048 14.0999 12.6628 14.0707C13.0335 14.0404 13.3682 13.9758 13.6808 13.8165C14.17 13.5672 14.5677 13.1695 14.817 12.6803C14.9763 12.3677 15.0409 12.033 15.0711 11.6624C15.1004 11.3043 15.1004 10.8638 15.1004 10.3251V10.325V10.2999V9.4999C15.1004 9.16853 14.8318 8.8999 14.5004 8.8999C14.169 8.8999 13.9004 9.16853 13.9004 9.4999V10.2999C13.9004 10.8699 13.8999 11.2612 13.8751 11.5646C13.8509 11.861 13.8065 12.0203 13.7478 12.1355C13.6136 12.3989 13.3994 12.6131 13.136 12.7473C13.0207 12.806 12.8615 12.8504 12.5651 12.8746C12.2617 12.8994 11.8703 12.8999 11.3004 12.8999H4.70039C4.13044 12.8999 3.73906 12.8994 3.43565 12.8746C3.13925 12.8504 2.98004 12.806 2.8648 12.7473C2.60138 12.6131 2.3872 12.3989 2.25298 12.1355C2.19427 12.0203 2.14986 11.861 2.12565 11.5646C2.10086 11.2612 2.10039 10.8699 2.10039 10.2999V9.4999C2.10039 9.16853 1.83176 8.8999 1.50039 8.8999C1.16902 8.8999 0.900391 9.16853 0.900391 9.4999V10.2999Z"
                        fill="currentColor" />
                    </svg>
                  </b-button>
                </span>
              </div>
            </div>
            <div class="clipping-info d-flex algn-items-start justify-content-start">
              <svg class="mr-1 mt-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.00002 2.8499C5.15576 2.8499 2.85002 5.15564 2.85002 7.9999C2.85002 10.8442 5.15576 13.1499 8.00002 13.1499C10.8443 13.1499 13.15 10.8442 13.15 7.9999C13.15 5.15564 10.8443 2.8499 8.00002 2.8499ZM1.65002 7.9999C1.65002 4.49289 4.49302 1.6499 8.00002 1.6499C11.507 1.6499 14.35 4.49289 14.35 7.9999C14.35 11.5069 11.507 14.3499 8.00002 14.3499C4.49302 14.3499 1.65002 11.5069 1.65002 7.9999ZM9 5.74991C9 6.30219 8.55228 6.74991 8 6.74991C7.44772 6.74991 7 6.30219 7 5.74991C7 5.19762 7.44772 4.74991 8 4.74991C8.55228 4.74991 9 5.19762 9 5.74991ZM8.60005 8.7499C8.60005 8.41852 8.33142 8.1499 8.00005 8.1499C7.66868 8.1499 7.40005 8.41852 7.40005 8.7499V10.7499C7.40005 11.0813 7.66868 11.3499 8.00005 11.3499C8.33142 11.3499 8.60005 11.0813 8.60005 10.7499V8.7499Z"
                  fill="#8794B5" />
              </svg>
              <p class="mb-0 text-s-ss text-dark-7">You can only trim maximum duration of <span
                  v-if="maxHighlightDuration">{{ maxHighlightDuration }}</span> minutes. If you want to increase the
                limit, <span class="text-underline text-white cursor-pointer" onclick="Intercom('show')">contact
                  sales</span></p>
            </div>
          </div>
          <div class="highlight_bottom--right">
            <div class="trimmed">
              <div class="trimmed--placeholder">
                <div class="mb-2">
                  <p class="text-dark-9 text-s-ss text-500 mb-1">Content Title*</p>
                  <input class="h-input d-inline-block w-100" @input="metaChanged()" v-model="metaTitle" type="text" />
                </div>
                <div class="mb-2">
                  <p class="text-dark-9 text-s-ss text-500 mb-1">Content Desctiption</p>
                  <textarea class="h-input d-inline-block w-100" @input="metaChanged()" placeholder="Type something..."
                    type="text" v-model="metaDescription" />
                </div>
                <div class="mb-2 text-right">
                  <b-button variant="primary" size="sm" :disabled="!this.unsavedMeta" @click="saveasMeta()">
                    <span>Save</span>
                  </b-button>
                </div>
                <div>
                  <div class="mb-3 d-flex align-items-center justify-content-between">
                    <div>
                      <p class="text-dark-9 text-s-ss text-500 mb-0">Share to Socials</p>
                      <p class="text-dark-8 text-s-xs text-400 mb-0" v-if="this.highlight"><span class=""
                          style="color: #F46464;">{{ this.highlight.platforms.length }}/2</span> platform created</p>
                    </div>
                    <b-dropdown id="platform_icon" :disabled="this.highlight && (this.highlight.platforms.length >= 2)"
                      v-if="this.highlight && this.highlight.platforms.length > 0" no-caret variant="primary" size="md"
                      toggle-class="d-flex add-social-platform align-items-center justify-content-between text-500 p-1"
                      menu-class="dropdown-menu_md dropdown-menu_sm-100" class="">
                      <template #button-content>
                        <div class="d-flex justify-content-between align-items-center w-100">
                          <svg class="mr-0" width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4V10M10 16V10M10 10H4M10 10H16" stroke="white" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      </template>
                      <b-dropdown-item @click="getServiceOAuthUrl('youtube')">
                        <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                          fill="none">
                          <g clip-path="url(#clip0_3412_17459)">
                            <rect width="24" height="24" rx="12" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M100 0H0V100H100V0ZM18.4349 8.69891C18.2782 8.10982 17.8147 7.64509 17.2249 7.48582C16.1585 7.2 11.88 7.2 11.88 7.2C11.88 7.2 7.60364 7.2 6.53509 7.48582C5.9475 7.64291 5.48396 8.10764 5.32509 8.69891C5.04 9.768 5.04 12 5.04 12C5.04 12 5.04 14.232 5.32509 15.3011C5.48178 15.8902 5.94533 16.3549 6.53509 16.5142C7.60364 16.8 11.88 16.8 11.88 16.8C11.88 16.8 16.1585 16.8 17.2249 16.5142C17.8125 16.3571 18.276 15.8924 18.4349 15.3011C18.72 14.232 18.72 12 18.72 12C18.72 12 18.72 9.768 18.4349 8.69891ZM10.5133 14.0575L14.0671 12L10.5133 9.94255V14.0575Z"
                              fill="#FF3000" />
                          </g>
                          <defs>
                            <clipPath id="clip0_3412_17459">
                              <rect width="24" height="24" rx="12" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span class="text-dark-9 text-s-ss text-400">Youtube</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="getServiceOAuthUrl('facebook')">
                        <div class="d-flex align-items-center justify-content-between w-100">
                          <div class="flex items-center">
                            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <g clip-path="url(#clip0_3412_17537)">
                                <rect width="24" height="24" rx="12" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M100 0H0V100H100V0ZM15.0379 10.5789L14.696 12.7496H12.9315V18H10.5618V12.7496H8.64V10.5789H10.5618V8.92369C10.5618 7.04127 11.6936 6 13.4273 6C14.0001 6.00699 14.5716 6.05574 15.1371 6.14584V7.99435H14.166C13.2256 7.99435 12.9315 8.57434 12.9315 9.16789V10.5789H15.0379Z"
                                  fill="#337FFF" />
                              </g>
                              <defs>
                                <clipPath id="clip0_3412_17537">
                                  <rect width="24" height="24" rx="12" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span class="text-dark-9 text-s-ss text-400">Facebook</span>
                          </div>

                          <div>
                            <svg id="platform_facebook" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                              viewBox="0 0 16 16" fill="none">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M8.00002 2.0999C4.74154 2.0999 2.10002 4.74142 2.10002 7.9999C2.10002 11.2584 4.74154 13.8999 8.00002 13.8999C11.2585 13.8999 13.9 11.2584 13.9 7.9999C13.9 4.74142 11.2585 2.0999 8.00002 2.0999ZM0.900024 7.9999C0.900024 4.07868 4.0788 0.899902 8.00002 0.899902C11.9212 0.899902 15.1 4.07868 15.1 7.9999C15.1 11.9211 11.9212 15.0999 8.00002 15.0999C4.0788 15.0999 0.900024 11.9211 0.900024 7.9999ZM9 5.49991C9 6.05219 8.55228 6.49991 8 6.49991C7.44772 6.49991 7 6.05219 7 5.49991C7 4.94762 7.44772 4.49991 8 4.49991C8.55228 4.49991 9 4.94762 9 5.49991ZM8.6 8.49991C8.6 8.16854 8.33137 7.89991 8 7.89991C7.66863 7.89991 7.4 8.16854 7.4 8.49991V10.9999C7.4 11.3313 7.66863 11.5999 8 11.5999C8.33137 11.5999 8.6 11.3313 8.6 10.9999V8.49991Z"
                                fill="#68759C" />
                            </svg>
                            <b-tooltip target="platform_facebook">
                              Clips cannot be posted on user timelines.
                            </b-tooltip>
                          </div>
                        </div>

                      </b-dropdown-item>
                    </b-dropdown>
                    <b-tooltip v-if="this.highlight && (this.highlight.platforms.length == 2)" target="platform_icon">
                      You can only add up to 2 platforms
                    </b-tooltip>
                  </div>
                  <hr class="mb-0" />
                  <div v-if="this.highlight && this.highlight.platforms.length > 0">
                    <div v-for="(platform, index) in this.highlight.platforms" :key="platform._id"
                      class="position-relative">
                      <div class="highlight_platform_action">
                        <b-dropdown menu-class="dropdown-menu_md" right no-caret variant="icon" size="sm"
                          toggle-class="p-0" class="">
                          <template #button-content>
                            <img src="@/assets/icons/settings.svg" alt="Settings">
                          </template>
                          <b-dropdown-item variant="delete" class="p-0" @click="requestDelete(platform)">
                            <span>Delete</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <b-form-checkbox v-model="selectedPlatform" :value="platform" name="platformSingle"
                        class="highlight-platform d-flex align-items-start justify-content-start flex-wrap">
                        <svg v-if="platform.type == 'youtube'" class="mr-2" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.24451 9.94111C2.37304 7.96233 3.96395 6.41157 5.94447 6.31345C8.81239 6.17136 12.9115 6 16 6C19.0885 6 23.1876 6.17136 26.0555 6.31345C28.0361 6.41157 29.627 7.96233 29.7555 9.94111C29.8786 11.8369 30 14.1697 30 16C30 17.8303 29.8786 20.1631 29.7555 22.0589C29.627 24.0377 28.0361 25.5884 26.0555 25.6866C23.1876 25.8286 19.0885 26 16 26C12.9115 26 8.81239 25.8286 5.94447 25.6866C3.96395 25.5884 2.37304 24.0377 2.24451 22.0589C2.12136 20.1631 2 17.8303 2 16C2 14.1697 2.12136 11.8369 2.24451 9.94111Z" fill="#FC0D1B"/>
                          <path d="M12 12V20L20 16L12 12Z" fill="white"/>
                        </svg>
                        <svg v-if="platform.type == 'facebook'" class="mr-2" xmlns="http://www.w3.org/2000/svg"
                          width="28" height="28" viewBox="0 0 28 28" fill="none">
                          <path
                            d="M24.9003 26.2526C25.647 26.2526 26.2526 25.6471 26.2526 24.9002V3.10235C26.2526 2.35534 25.6471 1.75 24.9003 1.75H3.10235C2.35535 1.75 1.75 2.35534 1.75 3.10235V24.9002C1.75 25.647 2.35525 26.2526 3.10235 26.2526H24.9003Z"
                            fill="#3C5A99" />
                          <path
                            d="M18.6565 26.2526V16.7638H21.8414L22.3183 13.0659H18.6565V10.705C18.6565 9.63436 18.9538 8.90475 20.489 8.90475L22.4472 8.9039V5.59643C22.1086 5.55137 20.9462 5.45068 19.5938 5.45068C16.7705 5.45068 14.8376 7.17401 14.8376 10.3388V13.0659H11.6445V16.7638H14.8376V26.2526H18.6565Z"
                            fill="white" />
                        </svg>
                        <div>
                          <p class="mb-1 text-s-s text-500">{{ platform.name }}'s account</p>
                          <b-button variant="outline-secondary" size="sm" @click="addMetadata(platform)">
                            <span class="text-s-xs text-dark-8">Custom metadata</span>
                          </b-button>
                          <p class="platform-item__status mt-1 platform-item__status--error text-s-xs font-normal text-danger"
                            v-if="!isLinkvalid[platform.type]">
                            <template>
                              The API connection has expired. <a class="platform-item__status--error" href=""
                                @click.prevent="getServiceOAuthUrl(platform.type, true)"><u>Reconnect</u></a>
                            </template>
                          </p>
                        </div>
                      </b-form-checkbox>
                    </div>
                  </div>

                  <div class="platform--box text-center" v-else>
                    <h3 class="text-s-s text-500 mb-1"> No platforms yet</h3>
                    <p class="text-dark-7 text-s-ss text-400 mb-2">Add a platform to get started</p>
                    <b-dropdown no-caret variant="primary" size="md"
                      toggle-class="mt-1 d-flex align-items-center justify-content-between text-500 pl-2"
                      menu-class="dropdown-menu_md dropdown-menu_sm-100" class="">
                      <template #button-content>
                        <div class="d-flex justify-content-between align-items-center w-100">
                          <svg class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 4V10M10 16V10M10 10H4M10 10H16" stroke="white" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <span class="mr-auto">Add Platform</span>
                          <!-- <svg class="ml-2 dropdown-caret flex-shrink-0" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.26863 5.73137L7.13137 7.86863C6.73535 8.26465 6.53735 8.46265 6.30902 8.53684C6.10817 8.6021 5.89183 8.6021 5.69098 8.53684C5.46265 8.46265 5.26465 8.26465 4.86863 7.86863L2.73137 5.73137C1.87462 4.87462 1.44624 4.44624 1.4173 4.07846C1.39218 3.75934 1.52136 3.44749 1.76477 3.23959C2.04529 3 2.65111 3 3.86274 3L8.13726 3C9.34889 3 9.95471 3 10.2352 3.23959C10.4786 3.44749 10.6078 3.75934 10.5827 4.07846C10.5538 4.44624 10.1254 4.87462 9.26863 5.73137Z" fill="currentColor"/>
                        </svg> -->
                        </div>
                      </template>
                      <b-dropdown-item @click="getServiceOAuthUrl('youtube')">
                        <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                          fill="none">
                          <g clip-path="url(#clip0_3412_17459)">
                            <rect width="24" height="24" rx="12" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M100 0H0V100H100V0ZM18.4349 8.69891C18.2782 8.10982 17.8147 7.64509 17.2249 7.48582C16.1585 7.2 11.88 7.2 11.88 7.2C11.88 7.2 7.60364 7.2 6.53509 7.48582C5.9475 7.64291 5.48396 8.10764 5.32509 8.69891C5.04 9.768 5.04 12 5.04 12C5.04 12 5.04 14.232 5.32509 15.3011C5.48178 15.8902 5.94533 16.3549 6.53509 16.5142C7.60364 16.8 11.88 16.8 11.88 16.8C11.88 16.8 16.1585 16.8 17.2249 16.5142C17.8125 16.3571 18.276 15.8924 18.4349 15.3011C18.72 14.232 18.72 12 18.72 12C18.72 12 18.72 9.768 18.4349 8.69891ZM10.5133 14.0575L14.0671 12L10.5133 9.94255V14.0575Z"
                              fill="#FF3000" />
                          </g>
                          <defs>
                            <clipPath id="clip0_3412_17459">
                              <rect width="24" height="24" rx="12" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span class="text-dark-9 text-s-ss text-400">Youtube</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="getServiceOAuthUrl('facebook')">
                        <div class="d-flex align-items-center justify-content-between w-100">
                          <div class="d-flex">
                            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <g clip-path="url(#clip0_3412_17537)">
                                <rect width="24" height="24" rx="12" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M100 0H0V100H100V0ZM15.0379 10.5789L14.696 12.7496H12.9315V18H10.5618V12.7496H8.64V10.5789H10.5618V8.92369C10.5618 7.04127 11.6936 6 13.4273 6C14.0001 6.00699 14.5716 6.05574 15.1371 6.14584V7.99435H14.166C13.2256 7.99435 12.9315 8.57434 12.9315 9.16789V10.5789H15.0379Z"
                                  fill="#337FFF" />
                              </g>
                              <defs>
                                <clipPath id="clip0_3412_17537">
                                  <rect width="24" height="24" rx="12" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span class="text-dark-9 text-s-ss text-400">Facebook</span>
                          </div>
                          <div>
                            <svg id="platform_facebook" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                              viewBox="0 0 16 16" fill="none">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M8.00002 2.0999C4.74154 2.0999 2.10002 4.74142 2.10002 7.9999C2.10002 11.2584 4.74154 13.8999 8.00002 13.8999C11.2585 13.8999 13.9 11.2584 13.9 7.9999C13.9 4.74142 11.2585 2.0999 8.00002 2.0999ZM0.900024 7.9999C0.900024 4.07868 4.0788 0.899902 8.00002 0.899902C11.9212 0.899902 15.1 4.07868 15.1 7.9999C15.1 11.9211 11.9212 15.0999 8.00002 15.0999C4.0788 15.0999 0.900024 11.9211 0.900024 7.9999ZM9 5.49991C9 6.05219 8.55228 6.49991 8 6.49991C7.44772 6.49991 7 6.05219 7 5.49991C7 4.94762 7.44772 4.49991 8 4.49991C8.55228 4.49991 9 4.94762 9 5.49991ZM8.6 8.49991C8.6 8.16854 8.33137 7.89991 8 7.89991C7.66863 7.89991 7.4 8.16854 7.4 8.49991V10.9999C7.4 11.3313 7.66863 11.5999 8 11.5999C8.33137 11.5999 8.6 11.3313 8.6 10.9999V8.49991Z"
                                fill="#68759C" />
                            </svg>
                            <b-tooltip target="platform_facebook">
                              Clips cannot be posted on user timelines.
                            </b-tooltip>
                          </div>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <hr class="mb-0 mt-0" />
                  <div class="text-right clipping-action mt-3">
                    <b-button
                      :disabled="this.isDisabled || this.selectedPlatform.length == 0 || this.highlight.platforms.length == 0"
                      variant="primary" size="md" class="mr-0" @click="shareClip()">
                      <span>Share</span>
                    </b-button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clip-lists mt-3" v-if="this.highlight && this.highlight.clips.length > 0">
        <h4 class="text-s-ss text-500 position-relative posted-clips mb-3">Posted Live Clipping</h4>
        <hr>
        <div :class="clip.status + '_clip'" v-for="clip in reversedItems(this.highlight.clips)" :key="clip._id"
          class="clip-list col-md-12 col-lg-12 col-12">
          <div>
            <div class="d-flex align-items-center justify-content-between">

              <div class="clip-left d-flex align-items-center justify-content-start">
                <div class="highlight_thumb mr-2">

                </div>
                <div class="clip-details ">
                  <p class="text-dark-2 text-s-s text-500 mb-0">{{ clip.name }}</p>
                  <p class="text-dark-8 text-s-ss text-500 mb-0 d-flex align-items-center justify-content-start">
                    <span>{{ secondsToHms(clip.duration) }}</span>
                    <span class="mx-1">.</span>
                    <span v-if="clip.createdAt">
                      {{ getDate(clip.createdAt) }}</span>
                  </p>
                </div>
              </div>
              <div class="clip-middle d-flex" v-if="clip.publishedPlatforms.length > 0">

                <a class="d-flex align-items-center justify-content-center"
                  :class="['status_' + platform.status, 'type_' + platform.type]"
                  :disabled="((platform.status == 'failed') || platform.status == 'processing')"
                  :href="platform && platform.publishedClipUrl ? platform.publishedClipUrl : '#'" target="_blank"
                  v-for="(platform, index) in filteredPlatform(clip.publishedPlatforms)" :key="index">
                  <svg class="failed-clip" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"
                    fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.65914 1.65913C1.89346 1.42482 2.27336 1.42482 2.50767 1.65914L4.99998 4.15153L7.49248 1.65913C7.72679 1.42482 8.10669 1.42482 8.341 1.65914C8.57531 1.89346 8.57531 2.27336 8.34099 2.50767L5.84851 5.00006L8.34099 7.49246C8.57531 7.72677 8.57531 8.10667 8.341 8.34099C8.10669 8.57531 7.72679 8.57531 7.49247 8.341L4.99998 5.84859L2.50767 8.34099C2.27336 8.57531 1.89346 8.57531 1.65914 8.341C1.42482 8.10669 1.42482 7.72679 1.65913 7.49248L4.15145 5.00006L1.65913 2.50766C1.42482 2.27334 1.42482 1.89344 1.65914 1.65913Z"
                      fill="white" />
                  </svg>
                  <svg v-if="platform && platform.type == 'facebook'" xmlns="http://www.w3.org/2000/svg" width="32"
                    height="32" viewBox="0 0 32 32" fill="none">
                    <rect width="32" height="32" rx="16" fill="#A7B5E2" fill-opacity="0.08" />
                    <path
                      d="M19.5932 16.9994L20.0491 14.1051H17.2406V12.2239C17.2406 11.4324 17.6327 10.6591 18.8865 10.6591H20.1814V8.19446C19.4273 8.07432 18.6653 8.00933 17.9017 8C15.5901 8 14.0809 9.38835 14.0809 11.8982V14.1051H11.5186V16.9994H14.0809V24H17.2406V16.9994H19.5932Z"
                      fill="white" />
                  </svg>
                  <svg v-if="platform && platform.type == 'youtube'" xmlns="http://www.w3.org/2000/svg" width="32"
                    height="32" viewBox="0 0 32 32" fill="none">
                    <rect width="32" height="32" rx="16" fill="#A7B5E2" fill-opacity="0.08" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M22.9653 9.98119C23.7516 10.1936 24.3697 10.8132 24.5786 11.5986C24.9588 13.0241 24.9587 16.0001 24.9587 16.0001C24.9587 16.0001 24.9588 18.9761 24.5786 20.4016C24.3668 21.1899 23.7487 21.8096 22.9653 22.019C21.5435 22.4001 15.8388 22.4001 15.8388 22.4001C15.8388 22.4001 10.1369 22.4001 8.71221 22.019C7.92585 21.8066 7.30779 21.187 7.09887 20.4016C6.71875 18.9761 6.71875 16.0001 6.71875 16.0001C6.71875 16.0001 6.71875 13.0241 7.09887 11.5986C7.31069 10.8103 7.92875 10.1906 8.71221 9.98119C10.1369 9.6001 15.8388 9.6001 15.8388 9.6001C15.8388 9.6001 21.5435 9.6001 22.9653 9.98119ZM18.7549 16.0001L14.0165 18.7434V13.2568L18.7549 16.0001Z"
                      fill="white" />
                  </svg>
                </a>
                <p class="single_status single_status--facebook text-s-xs text-400 text-danger mb-0">The post failed to
                  publish at your facebook platform, please <span
                    class="cursor-pointer text-underline d-inline-block text-white"
                    @click="retry(clip._id)">retry</span> to
                  publish</p>
                <p class="single_status single_status--youtube text-s-xs text-400 text-danger mb-0">The post failed to
                  publish
                  at your youtube platform, please <span class="cursor-pointer text-underline d-inline-block text-white"
                    @click="retry(clip._id)">retry</span> to publish</p>
              </div>
              <div class="clip-right">
                <span class="text-dark-8 text-s-ss text-500" v-if="clip.status == 'processing'"> Processing </span>

                <span class="color-success text-s-ss text-500 clip-status" v-if="clip.status == 'completed'">
                  <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                    fill="none">
                    <path d="M2 8L6 12L14 4" stroke="#74DE85" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  <span>
                    Shared
                  </span>
                </span>

                <span class="text-dark-8 text-s-ss text-500 cursor-pointer clip-status" v-if="clip.status == 'failed'"
                  @click="retry(clip._id)">
                  <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                    fill="none">
                    <g clip-path="url(#clip0_4112_12045)">
                      <path
                        d="M14.5005 7.25H14.4998C14.4013 7.25008 14.3038 7.26956 14.2129 7.30733C14.1219 7.3451 14.0393 7.40042 13.9697 7.47012C13.9001 7.53983 13.8449 7.62256 13.8073 7.71359C13.7697 7.80462 13.7504 7.90216 13.7504 8.00066C13.7495 9.05213 13.4604 10.0832 12.9145 10.9819C12.3685 11.8805 11.5867 12.6123 10.654 13.0978C9.72132 13.5832 8.67339 13.8037 7.62415 13.7353C6.5749 13.6669 5.56449 13.3121 4.70273 12.7097C2.10586 10.8913 1.47245 7.29916 3.29082 4.70225C5.1092 2.10534 8.70136 1.47188 11.2982 3.29031C11.6911 3.56469 12.0482 3.88708 12.3611 4.25H10.2505C10.0516 4.25 9.8608 4.32902 9.72015 4.46967C9.5795 4.61032 9.50048 4.80109 9.50048 5C9.50048 5.19891 9.5795 5.38968 9.72015 5.53033C9.8608 5.67098 10.0516 5.75 10.2505 5.75H13.7505C13.9494 5.75 14.1402 5.67098 14.2808 5.53033C14.4215 5.38968 14.5005 5.19891 14.5005 5V1.5C14.5005 1.30109 14.4215 1.11032 14.2808 0.96967C14.1402 0.829018 13.9494 0.75 13.7505 0.75C13.5516 0.75 13.3608 0.829018 13.2202 0.96967C13.0795 1.11032 13.0005 1.30109 13.0005 1.5V2.74897C12.7373 2.49925 12.4559 2.26945 12.1586 2.06147C11.3788 1.51542 10.499 1.12832 9.56954 0.922267C8.64009 0.716217 7.67916 0.695254 6.7416 0.860574C5.80405 1.02589 4.90824 1.37426 4.10532 1.88578C3.3024 2.3973 2.60809 3.06196 2.06204 3.84181C0.959244 5.41679 0.52727 7.36534 0.861149 9.25882C1.19503 11.1523 2.26741 12.8356 3.84239 13.9384C5.05499 14.7925 6.50189 15.2511 7.98511 15.2513C8.41231 15.2511 8.83867 15.2137 9.25936 15.1393C10.9379 14.8432 12.4585 13.9652 13.5541 12.6595C14.6497 11.3538 15.2503 9.70383 15.2505 7.99934C15.2503 7.80055 15.1712 7.60996 15.0306 7.46945C14.89 7.32894 14.6993 7.25001 14.5005 7.25Z"
                        fill="#8794B5" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4112_12045">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>
                    Retry
                  </span>
                </span>
              </div>
            </div>

            <p class="failed_msg text-s-xs text-400 text-danger mb-0">Failed: Ensure that the platform where the post
              failed
              to publish is not removed; <br>otherwise, the retry function will not operate correctly.</p>

          </div>
        </div>
      </div>
    </main>
    <highlight-subchannel-modal :listChannels="this.subChannelLists" @set-platform="onSavePlatform"
      @closing="updateStatus" />
    <custom-metadata-modal :platform="this.updatePlatform" />
    <demo-video-modal videoid="E5NGhYcn07xZKW-9qMSTy" />
    <confirm-modal modalId="limit-exceed" :message="`Clip exceeds ${maxHighlightDuration}-minute duration limit.`"
      body="Please contact sales if they want to increase the duration limit" ok-text="Contact sales"
      cancel-text="Cancel" @modal-confirm="redirect" />
  </b-container>
</template>

<script>
import _ from "lodash";
import { mapGetters } from 'vuex';
import AddHighlightModal from "@/components/modals/AddHighlightModal.vue";
import HighlightSubchannelModal from "@/components/modals/HighlightSubchannelModal.vue";
import CustomMetadataModal from "@/components/modals/CustomMetadataModal.vue"
import Spinner from "@/components/ui/Spinner.vue";
import { v4 } from 'uuid';
import AlertModal from "@/components/modals/AlertModal.vue";
import moment from 'moment';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import HighlightCardView from '@/components/ui/HighlightCardView.vue';
import IntegrationService from '@/services/IntegrationService';
import StreamService from '@/services/StreamService';
import StreamStatsService from '@/services/StreamStatsService';
import HighlightService from '@/services/HighlightService';
import Vue from 'vue';
import { isProxy, toRaw } from 'vue';
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';
import TimeLine from './TimeLine.vue';

export default {
  name: "StreamManageHighlights",
  components: {
    Spinner,
    ConfirmModal,
    HighlightSubchannelModal,
    CustomMetadataModal,
    AddHighlightModal,
    HighlightCardView,
    AlertModal,
    DemoVideoModal,
    TimeLine,
  },
  computed: {
    ...mapGetters({
      loadingStatus: "Ui/loadingStatus",
      subscriptions: "User/subscriptions",
    }),
    subscription() {
      return this.$store.state.User.subscriptions;
    },
    isTrialSubscription() {
      const subs = this.subscription.map(sub => _.assign({ category: 'live' }, sub))
      const baseSub = _.find(subs, { category: 'live' }) || {};
      console.log(subs);
      console.log(baseSub);
      let basePackages = baseSub.package;
      if (isProxy(basePackages)) {
        basePackages = toRaw(basePackages)
      }
      return false;
    },
    computedSrc() {
      let start = Math.round(this.rangeStart / 1000);
      let duration = Math.round((this.rangeEnd - this.rangeStart) / 1000);
      if (this.highlight && this.highlight.stream) {
        let liveUrl = `https://castr-highlights.castr.com/highlights/${this.userId}/${this.highlight.stream.key}/index.fmp4.m3u8`
        let highlightUrl = `https://castr-highlights.castr.com/highlights/${this.userId}/${this.highlight.stream.key}/index-${start}-${duration}.fmp4.m3u8`
        if (!this.isDisabled) {
          if (this.isLiveMode) {
            this.hlsUrl = liveUrl;
          } else {
            this.hlsUrl = highlightUrl;
          }
        } else {
          this.hlsUrl = null;
        }
        return this.hlsUrl;
      }
    },
    // streamAlive() {
    // 	return this.streamStatus && this.mediaPulse && this.mediaPulse.alive;
    // },
    user() {
      return this.$store.state.User;
    },
    clipbtnStatus() {
      return this.isDisabled || (this.duration == 0) || (this.clipName.length == 0) || this.clipped || this.isTrialSubscription;
    },
    userId() {
      return this.highlight?.stream?.user || this.$store.state.User._id;
    },
    maxHighlightDuration() {
      let sub = null
      this.subscriptions.some((item) => {
        if (item.enabled && item.category === 'live') sub = item
      })
      let isValidPlanOverrideDefinition = null
      let isValidPlanDefinition = false
      if (sub) {
        if (sub?.package?.definition?.maxHighlightDuration) isValidPlanDefinition = sub.package.definition.maxHighlightDuration
        if (sub?.definitionOverride?.maxHighlightDuration) isValidPlanOverrideDefinition = sub.definitionOverride.maxHighlightDuration
      }
      if (isValidPlanOverrideDefinition) return isValidPlanOverrideDefinition
      return isValidPlanDefinition
    }
  },
  async mounted() {
    this.$store.dispatch('Ui/setLoadingStatus', true)
    await this.$store.dispatch("User/loadUser");
    await this.$store.dispatch('Highlight/getHighlight');
    console.log(this.$route.params);
    this.highlightId = this.$route.params.highlightId;
    await this.setupHighlight();
    if (this.highlight && this.highlight.stream) {
      console.log('The highlight');
      console.log(this.highlight);
      await this.setupRange();
      if (this.highlight.platforms.length > 0) {
        await this.normalizePlatform(this.highlight.platforms);
      }
      this.$store.dispatch('Ui/setLoadingStatus', false);
      this.setMediapulse(this.highlight.stream.key);
    }
  },
  created() {
    this.pollId = setInterval(() => this.pollRange(), 5000);
  },
  beforeDestroy() {
    clearInterval(this.pollId);
  },
  watch: {
    countdownSec: {
      handler(value) {
        if (this.duration && this.isLiveMode) {
          if (value > 0) {
            if (this.rmp) {
              this.rmp.setVolume(0);
            }
            setTimeout(() => {
              this.countdownSec--;
            }, 1000);
          } else {
            if (this.rmp) {
              this.rmp.setVolume(1);
            }
            this.rmp = null;
            this.rmp?.destroy();
            setTimeout(() => {
              this.setupRange();
            }, 1000);
          }
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  methods: {
    async setMediapulse(streamKey) {
      try {
        const pulseObject = await StreamStatsService.getStreamMediaPulse(
          streamKey
        );
        this.mediaPulse = pulseObject;
        this.streamOffline = !pulseObject.alive && !this.highlight?.stream?.enabled;
        this.streamOffline = !pulseObject.alive;
        if (this.streamOffline) {
          this.rmp = null;
          this.rmp?.destroy();
          this.countdownSec = 0;
        }
        if (!this.streamOffline && !this.rmp && !this.isDisabled) {
          console.log('initializing rmp');
          this.setupPlayer(this.computedSrc);
        }
      } catch (e) { }
    },
    metaChanged() {
      this.unsavedMeta = true;
    },
    saveasMeta() {
      if (this.highlight.platforms.length > 0) {
        this.highlight.platforms.map(async (p) => {
          let updatedPlatformdata = { ...p };
          updatedPlatformdata.meta.title = this.metaTitle;
          updatedPlatformdata.meta.description = this.metaDescription;
          try {
            const updatePlatform = await HighlightService.updatePlatformmeta(
              p.highlight,
              p._id,
              updatedPlatformdata
            );
          } catch (err) {
            console.log(err);
          }
        });
        this.$notify({ group: 'success', text: 'Platform Metadata Updated' });
        this.unsavedMeta = false;
      } else {
        this.$notify({
          group: 'error',
          title: "No Platform Available"
        });

      }
    },
    reversedItems(items) {
      return [...items].reverse();
    },
    async pollRange() {
      if (!this.isLiveMode) return;
      if (!this.highlight) {
        await this.setupHighlight();
      }
      if(!this.highlight?.stream?.key) return;
      const data = await HighlightService.getRecordingStatus(this.userId, this.highlight?.stream?.key);
      this.setMediapulse(this.highlight.stream?.key);
      if ((data?.length > 0) && (data[0].ranges?.length > 0)) {
        let defaultValue = data[0].ranges[0];
        for (let i = data[0].ranges.length - 1; i >= 0; i--) {
          if (data[0].ranges[i].duration > this.durationLimit) {
            defaultValue = data[0].ranges[i];
            break;
          }
        }
        const lastRange = defaultValue;
        const timelineStart = new Date(lastRange.from * 1000);
        const timelineEnd = new Date(
          (lastRange.from + lastRange.duration) * 1000
        );

        if (lastRange.duration > 3600) {
          this.timeline.start = timelineEnd.getTime() - 3600 * 1000;
        } else {
          this.timeline.start = timelineEnd.getTime() - lastRange.duration * 1000;
        }
        this.timeline.end = timelineEnd.getTime();

        this.rangeStart = this.timeline.start;
        this.rangeEnd = this.timeline.end;
        this.duration = this.rangeEnd - this.rangeStart;
        if ((this.duration > 0) && (this.duration / 1000 < this.durationLimit) && (this.countdownSec == 0) && !this.streamOffline && this.isLiveMode) {
          this.countdownSec = this.durationLimit - this.duration / 1000;
        }
      } else {
        await this.setupPlayer(this.computedSrc);
      }
    },
    downloadClip() {
      let start = Math.round(this.rangeStart / 1000);
      let duration = Math.round((this.rangeEnd - this.rangeStart) / 1000);
      let clipDownloadUrl = `https://central-storage-1.global.ssl.fastly.net/highlights/${this.userId}/${this.highlight.stream.key}/archive-${start}-${duration}.mp4?packing=compat`
      window.location.href = clipDownloadUrl;
    },
    goBacktolive() {
      this.rangeStart = this.timeline.start;
      this.rangeEnd = this.timeline.end;
      this.duration = this.rangeEnd - this.rangeStart;
      this.pollId = setInterval(() => this.pollRange(), 5000);
      this.isLiveMode = true;
      this.setupPlayer(this.computedSrc);
    },
    normalizePlatform(platforms) {
      platforms.map(async (p, index) => {
        let isTokenValid = await this.checkValidity(p);
        let type = p.type;
        this.isLinkvalid[type] = isTokenValid;
      });
    },
    nameChanged() {
      this.clipped = false;
    },
    setupPlayer(url) {
      const createPlayer = () => {
        const elementID = 'rmp';
        let src = {
          hls: url
        };
        const settings = {
          licenseKey: 'aWxhZGNsaWVndkAxNjc1MTcx',
          src: src,
          width: 550,
          height: 300,
          autoplay: true,
          skin: 's2',
          preload: 'smart',
          // hideControls: false,
        };

        this.rmp?.destroy();
        this.rmp = null;
        let rmp = new RadiantMP(elementID);
        this.rmp = rmp;
        this.rmp.on('error', async () => {
          // let rmp = new RadiantMP(elementID);
          // rmp.init(settings);
          this.rmp.destroy();
          this.hlsUrl = null;
          console.log('top-error');
        });
        this.rmp.one("ready", () => {
          // Invoke API methods
          console.log(rmp.getStreamType());
        });

        this.rmp.on('durationchange', (duration) => {
          console.log('duration changed', duration, this.rmp.getDuration());
          if(this.rmp.getDuration() > 0) {
            const diff = this.duration - this.rmp.getDuration();
            this.rangeEnd -= diff;
            this.timeline.end = Math.max(this.timeline.end, this.rangeEnd);
            this.duration = this.rmp.getDuration();
          }
        })

        this.rmp.init(settings);
      };
      if (!this.rmp) {
        // If Radiant Media Player library fails to load we do something
        const _onErrorLog = function () {
          console.log('middle-error');
          console.log('could not load Radiant Media Player JavaScript file');
        };
        // We create our script tag to async load Radiant Media Player library and append it to <head>
        const rmpScript = document.createElement('script');
        rmpScript.src = 'https://cdn.radiantmediatechs.com/rmp/9.12.0/js/rmp-hlsjs.min.js';
        rmpScript.async = true;
        rmpScript.addEventListener('load', createPlayer.bind(this));
        rmpScript.addEventListener('error', _onErrorLog);
        document.head.appendChild(rmpScript);
      } else {
        this.rmp?.setSrc({ hls: url });
        this.rmp.on('error', () => {
          console.log('bottom-error');
          this.hlsUrl = null;
          console.log('fatal error detected');
          this.rmp?.destroy();
          this.rmp = null;
          let rmp = new RadiantMP(elementID);
          this.rmp.init(settings);
        });
      }
    },
    async checkValidity(platform) {
      if (platform.type == 'facebook') {
        try {
          const authStatus = await IntegrationService.isFacebookTokenValid(platform.linkedService, true);
          return authStatus;
        } catch (error) {
          console.log(error);
        }
      }
      if (platform.type == 'youtube') {
        try {
          const authStatus = await IntegrationService.isYoutubeTokenValid(platform.linkedService, platform.meta.subChannel, true);
          return authStatus.isTokenValid;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async highlightStatus() {
      this.rmp?.destroy();
      this.rmp = null;
      console.log('nulling1');
      let status = await HighlightService.checkDisabled(this.highlight._id, !this.isDisabled);
      this.isDisabled = !this.isDisabled;
      let msg = this.isDisabled ? 'Disabled' : 'Enabled';
      this.setupHighlight();
      if (!this.isDisabled) {
        this.setupPlayer(this.computedSrc);
      }
      this.$notify({
        group: 'success',
        title: 'The Live Clipping is ' + msg,
      });
    },
    async getClipstatus(cid) {
      try {
        let status = await HighlightService.checkStatus(this.highlight._id, cid);
        return status.status;
      } catch (err) {
        console.log('integration-conflict-check-error', err);
      }
    },
    setupRange: _.debounce(async function () {
      if (this.highlight && this.highlight.stream) {
        const data = await HighlightService.getRecordingStatus(this.userId, this.highlight.stream.key);
        this.setMediapulse(this.highlight.stream.key);
        if ((data.length > 0) && (data[0].ranges?.length > 0)) {
          let defaultValue = data[0].ranges[0];
          for (let i = data[0].ranges.length - 1; i >= 0; i--) {
            if (data[0].ranges[i].duration > this.durationLimit) {
              defaultValue = data[0].ranges[i];
              break;
            }
          }
          const lastRange = defaultValue;
          // const lastRange = data[0].ranges[data[0].ranges.length - 1];
          const timelineStart = new Date(lastRange.from * 1000);
          const timelineEnd = new Date(
            (lastRange.from + lastRange.duration) * 1000
          );

          if (lastRange.duration > 3600) {
            this.timeline.start = timelineEnd.getTime() - 3600 * 1000;
          } else {
            this.timeline.start = timelineEnd.getTime() - lastRange.duration * 1000;
          }
          this.timeline.end = timelineEnd.getTime();

          if (!this.rangeEnd) {
            this.rangeStart = this.timeline.start;
            this.rangeEnd = this.timeline.end;
            this.duration = this.rangeEnd - this.rangeStart;
          }
          if ((this.duration > 0) && (this.duration / 1000 < this.durationLimit) && (this.countdownSec == 0) && !this.streamOffline && this.isLiveMode) {
            this.countdownSec = this.durationLimit - this.duration / 1000;
          }
          if (!this.isDisabled && !this.countdownSec) {
            await this.setupPlayer(this.computedSrc);
          }
        }
      }
    }, 500),
    onTimeLineRangeChanged(args) {
      this.clipped = false;
      this.rangeStart = args[0];
      this.rangeEnd = args[1];
      this.duration = this.rangeEnd - this.rangeStart;
      this.isLiveMode = false;
      clearInterval(this.pollId);
      this.setupRange();
    },
    async shareClip() {
      let clips = localStorage.getItem('clips');
      clips = JSON.parse(clips);
      let publishedPlatform = [];
      if (this.selectedPlatform && this.selectedPlatform.length && clips && (clips.length > 0)) {
        this.selectedPlatform.map(p => {
          let singlePlatform = {
            "title": p.meta.title,
            "description": p.meta.description,
            "type": p.type,
            "platform": p._id
          }
          if(p.meta.privacyStatus) singlePlatform['privacyStatus'] = p.meta.privacyStatus;
          publishedPlatform.push(singlePlatform);
        });
        let shredHighlight = await HighlightService.shareHighlight(this.highlight._id, clips[0].hname, Math.round(clips[0].rstart * 0.001), Math.round(clips[0].rend * 0.001), Math.round(clips[0].rdur * 0.001), this.highlight.stream._id, publishedPlatform);

        let clipStatus = await this.getClipstatus(shredHighlight._id);
        this.highlight.clips.push(shredHighlight);

        this.rangeStart = 0,
          this.rangeEnd = 0,
          this.duration = 0,
          this.clipName = '',
          localStorage.setItem("clips", []);
      } else {
        this.$notify({
          group: 'error',
          title: "No clip selected"
        });
      }
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      return hDisplay + mDisplay + sDisplay;
    },
    sortPlatforms(platforms) {
      return platforms.sort(function (a, b) {
        var textA = a.type.toUpperCase();
        var textB = b.type.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    async listenAckEventResponse(key, callback) {
      const ackRes = await IntegrationService.getAckByKey(key);
      if (ackRes && ackRes.success) {
        callback(ackRes.data);
        this.oauthProcessing = false;
        this.serviceResponseRecieved = false;
      } else {
        setTimeout(() => {
          this.listenAckEventResponse(key, callback);
        }, 4000);
      }
    },
    async onServiceAuthResponse(response) {
      if (this.serviceResponseRecieved) return;
      this.serviceResponseRecieved = true;
      if (!response || response.error) {
        this.serviceLinkError = true;
        return;
      }
      const oauthMetaId = response.linkedMeta;
      // acknowledge UI, authorization was successful
      this.linkedServiceCreds = oauthMetaId;
      // check for linking conflicts
      let linkResult;
      try {
        linkResult = await IntegrationService.checkIntegrationConflict(this.highlight.stream._id, oauthMetaId);
      } catch (err) {
        console.log('integration-conflict-check-error', err);
        window.alert('could not perform integration duplication check');
      }

      if (!linkResult || linkResult.linked) {
        // conflicts are there
        this.serviceLinkError = true;
        this.serviceLinkConflict = true;
        this.linkedServiceCreds = null;
        return;
      }
      if (!this.reconnecting) {
        this.setSubChannels(oauthMetaId);
      } else {
        await this.normalizePlatform(this.highlight.platforms);
        this.reconnecting = false;
        this.linkedServiceCreds = null;
      }

    },
    async onHighlightChange() {
      if (!this.highlightName) this.highlightName = this.highlight.name;

      if (this.highlight.name === this.highlightName) return;

      // try changing stream name
      try {
        await HighlightService.setHighlightName(this.highlight._id, this.highlightName);

        // track event
        window.trackEvent(
          `Updated stream name ${this.highlight.name} -> ${this.highlightName}`
        );
      } catch (err) {
        this.highlightName = this.highlight.name;
        this.$notify({
          group: 'error',
          title: "Couldn't change Live Clipping name",
          text: err.message
        });
      }
    },
    resizeTextarea: function (e) {
      let area = e.target;
      area.style.height = area.scrollHeight + 'px';
    },
    async retry(cid) {
      try {
        let retryShare = await HighlightService.retryClipshare(this.highlight._id, cid);
        await this.$store.dispatch('Highlight/getHighlight')
      } catch (err) {
        console.log('retryShare', err);
        // window.alert('could not addPlatform');
      }
    },
    preventEnter(e) {
      e.preventDefault();
    },
    async requestDelete(p) {
      try {
        let deletePlatform = await HighlightService.deletePlatform(this.highlight._id, p._id);
        this.selectedPlatform = [];
      } catch (err) {
        console.log('deletePlatform', err);
        // window.alert('could not addPlatform');
      }
      this.$notify({
        group: 'success',
        title: `${p.name}'s account is deleted.`,
      });
      try {
        await this.$store.dispatch('Highlight/getHighlight')
        const highlight = await HighlightService.getHighlightbyid(this.highlightId);
        this.highlight = highlight;
        // this.serviceResponseRecieved = false;
      } catch (err) {
        console.log('checkpf', err);
        // window.alert('could not addPlatform');
      }
    },
    async highlightDelete(p) {
      try {
        let deleteHighlight = await HighlightService.deleteHighlight(this.highlight._id);
        if (deleteHighlight.success) {
          this.$router.push({ name: 'Highlight' })
          this.$notify({
            group: 'success',
            title: "The Live Clipping is Deleted",
          });
        }
      } catch (err) {
        console.log('deletePlatform', err);
        // window.alert('could not addPlatform');
      }
    },
    redirect() {
      if (window.Intercom) {
        window.Intercom('show');
      }
    },
    async onSavePlatform(subChannelItem) {
      let metaOverrides = {};
      const ingestConfParams = {};
      const subchannelSlug = subChannelItem.type ? ((subChannelItem.type || '-') + '@' + subChannelItem.id) : subChannelItem.id;
      metaOverrides = {
        'title': this.metaTitle,
        'description': this.metaDescription,
        "subChannel": subchannelSlug,
        "subChannelName": subChannelItem.name
      };

      try {
        let addPlatform = await HighlightService.addPlatform(this.highlight._id, subChannelItem.name, this.oauthFactoryName, this.linkedServiceCreds, this.highlight.stream._id, metaOverrides);
        let newPlatforms = addPlatform.platforms;
        this.highlight.platforms = newPlatforms;
        // this.normalizePlatform(this.highlight.platforms);
        // await this.$store.dispatch('Highlight/getHighlight');
        this.serviceResponseRecieved = false;
      } catch (err) {
        console.log('addPlatform', err);
      }
    },
    async addMetadata(platform) {
      this.updatePlatform = platform;
      this.$bvModal.show('modal-custom-metadata');
    },
    async setSubChannels(oauthMetaId) {
      this.$bvModal.show('modal-highlight-subchannel');
      let subChannelList = await IntegrationService.getServiceSubChannels(oauthMetaId);
      this.subChannelLists = subChannelList;

    },
    getServiceOAuthUrl(oauthFactoryName, reconnect = false) {
      this.reconnecting = reconnect;
      this.oauthFactoryName = oauthFactoryName;
      const pathname = `integrations/${oauthFactoryName}/oauth`;
      const connectId = v4();
      this.oauthConnectId = connectId;

      const searchParams = `redirect=1&user=${this.user._id || this.user.id}&stream=${this.highlight._id}&connectId=${this.oauthConnectId}`;

      let routeUrl = `${process.env.VUE_APP_APP_API_BASE}/${pathname}?${searchParams}`;
      window.open(routeUrl, '_blank');
      this.listenAckEventResponse(connectId, this.onServiceAuthResponse);
    },
    async setupHighlight() {
      try {
        if(!this.highlightId) return;
        const highlight = await HighlightService.getHighlightbyid(this.highlightId);
        this.highlight = highlight;
        this.highlightName = this.highlight.name;
        this.isDisabled = this.highlight.isDisabled;
        if (this.highlight.platforms.length > 0) {
          this.metaTitle = this.highlight.platforms[0].meta.title;
          this.metaDescription = this.highlight.platforms[0].meta.description;
        } else {
          this.metaTitle = this.highlight.name;
        }
      } catch (err) {
        this.$notify({
          group: 'error',
          title: err.error,
          text: err.message
        });
      }
    },
    getDate(ts) {
      return moment(ts).format('LL');
    },
    filteredPlatform(platforms) {
      let filtered = platforms.filter(p => {
        return p && p.hasOwnProperty('status');
      });
      return filtered;
    },
    async onExportCustomRange() {

      if (this.rangeEnd === 0) {
        this.rangeEnd = this.duration
      }
      if (this.duration < (this.maxHighlightDuration * 60 * 1000)) {
        let clip = { hid: this.highlight._id, hname: this.clipName, rstart: this.rangeStart, rend: this.rangeEnd, rdur: this.duration, streamid: this.highlight.stream._id }
        this.clips = [clip].concat(this.clips)
        localStorage.setItem("clips", JSON.stringify(this.clips));
        this.metaTitle = this.clipName;
        this.clipped = true;
      } else {
        this.$bvModal.show("limit-exceed");
      }
    },
    humanTimeRange(duration) {
      return moment.utc(moment.duration(duration, "ms").asMilliseconds()).format("HH:mm:ss")
    },
    humanTime(time) {
      return moment(time)
        .format("HH:mm:ss");
    },
    updateStatus() {
      this.serviceResponseRecieved = false;
    }
  },
  data() {
    return {
      timeline: {
        start: 0,
        end: 0,
      },
      durationLimit: 120,
      countdownSec: 0,
      playlistIndex: 0,
      hasHighlight: false,
      highlightId: null,
      highlight: null,
      oauthConnectId: null,
      updatePlatform: null,
      fbCheck: null,
      ytCheck: null,
      highlightName: null,
      hlsUrl: null,
      rangeStart: 0,
      rangeEnd: 0,
      duration: 0,
      dvrDuration: 3600,
      dvrFrom: 0,
      clipName: '',
      serviceResponseRecieved: false,
      isDisabled: true,
      serviceLinkError: false,
      linkedServiceCreds: null,
      serviceLinkConflict: false,
      clips: [],
      selectedPlatform: [],
      subChannelLists: [],
      metaDescription: "",
      metaTitle: '',
      oauthFactoryName: '',
      clipped: false,
      reconnecting: false,
      isLinkvalid: {
        'facebook': true,
        'youtube': true
      },
      isLiveMode: true,
      unsavedMeta: false,
      streamOffline: false,
      mediaPulse: null,
    };
  },
};
</script>
<style>
#video-range-bar {
  display: none;
}
</style>
<style scoped>
.embed_highlight {
  width: 550px;
  height: 320px;
}

.video-range-bar .video-range-bar-left {
  border-radius: 100%;
}

.clipping-control {
  border-radius: 0px 0px 8px 8px;
  background: #232D4D;
  padding: 8px 12px 16px 12px;
}

.back-live {
  background: #232D4D;
  padding: 8px 5px 0px 5px;
}

.clipping-info {
  border-radius: 6px;
  background: rgba(167, 181, 226, 0.08);
  padding: 6px 12px;
}

.highlight-stream {
  background: #232D4D;
  min-height: 293px;
}

.clip-name {
  min-width: 177px;
}

.clipping-control .cbox>div {
  border-radius: 6px;
  background: var(--c-dark-3);
  height: 32px;
  padding: 0px 10px;
}

.clipping-control .cbox input {
  border-radius: 6px;
  background: var(--c-dark-3);
  height: 32px;
  padding: 0px;
}

.cbox p {
  min-height: 19.5px;
}

.trimmed--placeholder {
  padding-left: 24px;
}

.h-input {
  border-radius: 6px;
  background: var(--neutral-3, rgba(167, 181, 226, 0.08));
  border: 0;
  box-shadow: none;
  outline: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 4px 10px;
}

.highlight_bottom--right {
  width: 100%;
}

.platform--box {
  padding: 0px 16px;
  margin: 15px 0px;
}

.clip-list {
  border-radius: 8px;
  background: var(--neutral-dark-2, #232D4D);
  margin-bottom: 8px;
  padding: 12px 16px;
}

.highlight_thumb {
  width: 73px;
  height: 44px;
  border-radius: 4px;
  background: #000;
}

.clip-right {
  min-width: 100px;
  text-align: right;
}

.clip-middle {
  /* width: 100px; */
  text-align: center;
}

.clip-left {
  min-width: 39%;
}

.clip-middle a {
  padding: 0px 6px;
}

/*.posted-clips:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 112px;
  left: 0;
  background: #fff;
  bottom: -15px;
}*/

.clip-status {
  display: flex;
  align-items: center;
}

.status_failed .failed-clip {
  display: inline-block;
}

.failed-clip {
  display: none;
}

.status_failed {
  position: relative;
  display: inline-block;
  pointer-events: none;
  opacity: .6;
}

.status_failed .failed-clip {
  position: absolute;
  right: 5px;
  top: calc(50% + 6px);
}

.failed_msg {
  margin-left: 82px;
  display: none;
}

.failed_clip .failed_msg {
  display: block;
}

.highlight-name:focus {
  background: var(--c-dark-3) !important;
}

/* .clipping-control:after {
    content: "";
    position: absolute;
    width: calc(100% - 24px);
    background: #343E61;
    height: 1px;
    top: 16px;
    left: 12px;
} */
a.status_processing {
  pointer-events: none;
  opacity: .7;
}

.single_status {
  display: none;
}

.type_facebook.status_failed~.single_status--facebook,
.type_youtube.status_failed~.single_status--youtube {
  display: block;
}

.failed_clip .single_status {
  display: none !important;
}

.single_status {
  position: absolute;
  left: 97px;
  bottom: 5px;
}


.status_failed {
  height: 55px;
}

.failed_clip .status_failed {
  height: unset;
}

.highlight_platform_action {
  position: absolute;
  right: -10px;
  top: 12px;
  z-index: 9;
  cursor: pointer;
}

.waiting-time {
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waiting-time code {
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .highlight-stream {
    min-height: 200px;
  }

  .timeline-wrap {
    margin: 0px 5px;
  }
}
</style>
