<template>
	<div id="videos-uploader-widget" :class="stream.type+'_uploadbox'">
		<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mt-5 w-100 text-center" spinner-color="var(--c-dark-8)" spinner-size="15px" />
		<div class="w-100" v-else>
			<div v-if="widgetLoaded" class="uploader-body">
				<div v-if="!stream.liveParent && !advertisingMediaUpload" class="d-flex mb-4 flex-wrap align-items-start justify-content-start">
					<input :disabled="vodSubExpiredDays < 0" ref="uploadInputDrop" type="file" :multiple="allowMultipleUploads" style="display: none;" accept="video/mp4" name="fields[assetsFieldHandle][]" id="assetsFieldHandleDrop" @change="onChange">
					<b-dropdown v-if="uploadList.length > 0 || videoFiles.length > 0" no-caret :disabled="vodSubExpiredDays < 0" variant="primary" size="md" toggle-class="d-flex align-items-center justify-content-between text-500 pl-2 mr-2" menu-class="dropdown-menu_md dropdown-menu_sm-100" class="">
						<template #button-content>
							<div class="d-flex justify-content-between align-items-center w-100">
								<svg class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M10 4V10M10 16V10M10 10H4M10 10H16" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
								<span class="mr-auto">New Video</span>
								<svg class="ml-2 dropdown-caret flex-shrink-0" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9.26863 5.73137L7.13137 7.86863C6.73535 8.26465 6.53735 8.46265 6.30902 8.53684C6.10817 8.6021 5.89183 8.6021 5.69098 8.53684C5.46265 8.46265 5.26465 8.26465 4.86863 7.86863L2.73137 5.73137C1.87462 4.87462 1.44624 4.44624 1.4173 4.07846C1.39218 3.75934 1.52136 3.44749 1.76477 3.23959C2.04529 3 2.65111 3 3.86274 3L8.13726 3C9.34889 3 9.95471 3 10.2352 3.23959C10.4786 3.44749 10.6078 3.75934 10.5827 4.07846C10.5538 4.44624 10.1254 4.87462 9.26863 5.73137Z" fill="currentColor"/>
								</svg>
							</div>	
						</template>
						<b-dropdown-item v-if="!isDisabledControl && showUploadButton && (allowMultipleUploads || (videoFiles.length === 0 && uploadList.length === 0))" @click="$refs['uploadInputDrop'].click()">From Your Computer</b-dropdown-item>
						<b-dropdown-item v-if="!isTrialSub" v-b-modal.cloud-import-box-drop>Cloud import <b-badge variant="dark-5" class="badge_beta ml-2">beta</b-badge></b-dropdown-item>
					</b-dropdown>
					<div v-if="isDisabledControl" class="text-danger">Disable schedule to rearrange files</div>
					<!-- <input ref="uploadInputDrop" type="file" :multiple="allowMultipleUploads" style="display: none;" accept="video/mp4,video/x-m4v,video/*"> -->
					<b-button v-if="uploadList.length && allowMultipleUploads" variant="outline-danger" size="md" class="ml-2 mt-4 mt-lg-0 z-fix" @click="clearUploadList">Remove All</b-button>
					<div :class="{'drop-zone-hidden': ( videoFiles.length > 0 || uploadList.length > 0)}" class="w-100" @dragover="dragover" @dragleave="dragleave" @drop="drop">
						<input :disabled="vodSubExpiredDays < 0" ref="uploadInputDrop" type="file" :multiple="allowMultipleUploads" style="display: none;" accept="video/mp4" name="fields[assetsFieldHandle][]" id="assetsFieldHandleDrop" @change="onChange">
						<label v-if="uploadList.length === 0 && videoFiles.length === 0" for="assetsFieldHandleDrop" :class="vodFiles.length > 0 ? 'drop-zone' : 'drop-zone-tall'" class="d-flex w-100">
							<div class="rounded-circle drop-zone-circle drop-zone-circle_hovered d-flex flex-column align-items-center justify-content-center">
							<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M34.6 45.5H14.4C13.2716 45.5 12.4554 45.4996 11.8135 45.4472C11.1774 45.3952 10.7566 45.2946 10.411 45.1185C9.75247 44.783 9.21703 44.2475 8.88148 43.589C8.70539 43.2434 8.60481 42.8226 8.55284 42.1865C8.50039 41.5446 8.5 40.7284 8.5 39.6V8.4C8.5 7.27165 8.50039 6.45545 8.55284 5.81352C8.60481 5.17744 8.70539 4.75662 8.88148 4.41103C9.21703 3.75247 9.75247 3.21703 10.411 2.88148C10.7566 2.70539 11.1774 2.60481 11.8135 2.55284C12.4554 2.50039 13.2716 2.5 14.4 2.5H31.7929L40.5 11.2071V39.6C40.5 40.7284 40.4996 41.5446 40.4472 42.1865C40.3952 42.8226 40.2946 43.2434 40.1185 43.589C39.783 44.2475 39.2475 44.783 38.589 45.1185C38.2434 45.2946 37.8226 45.3952 37.1865 45.4472C36.5446 45.4996 35.7284 45.5 34.6 45.5Z" fill="#232D4D" stroke="#343E61"/>
								<path d="M25 33V23M25 23L21 27M25 23L29 27" stroke="#404B72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M32 2L41 11C41 11.5523 40.5523 12 40 12H35.2857C34.0888 12 33.4903 12 33.0092 11.8316C32.1474 11.5301 31.4699 10.8526 31.1683 9.99084C31 9.50972 31 8.91124 31 7.71429V3C31 2.44772 31.4477 2 32 2Z" fill="#343E61"/>
							</svg>
							<p class="text-s-m text-dark-9">Drag & drop or select a file</p>
							<p class="text-s-s text-dark-6 mb-2">Supported format: .mp4</p>
							<b-dropdown no-caret :disabled="vodSubExpiredDays < 0" variant="primary" size="md" toggle-class="d-flex align-items-center justify-content-between text-500 pl-2" menu-class="dropdown-menu_md dropdown-menu_sm-100" class="">
								<template #button-content>
									<div class="d-flex justify-content-between align-items-center w-100">
										<svg class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M10 4V10M10 16V10M10 10H4M10 10H16" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
										<span class="mr-auto">New Video</span>
										<svg class="ml-2 dropdown-caret flex-shrink-0" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9.26863 5.73137L7.13137 7.86863C6.73535 8.26465 6.53735 8.46265 6.30902 8.53684C6.10817 8.6021 5.89183 8.6021 5.69098 8.53684C5.46265 8.46265 5.26465 8.26465 4.86863 7.86863L2.73137 5.73137C1.87462 4.87462 1.44624 4.44624 1.4173 4.07846C1.39218 3.75934 1.52136 3.44749 1.76477 3.23959C2.04529 3 2.65111 3 3.86274 3L8.13726 3C9.34889 3 9.95471 3 10.2352 3.23959C10.4786 3.44749 10.6078 3.75934 10.5827 4.07846C10.5538 4.44624 10.1254 4.87462 9.26863 5.73137Z" fill="currentColor"/>
										</svg>
									</div>	
								</template>
								<b-dropdown-item v-if="!isDisabledControl && showUploadButton && (allowMultipleUploads || (videoFiles.length === 0 && uploadList.length === 0))" @click="$refs['uploadInputDrop'].click()">From Your Computer</b-dropdown-item>
								<b-dropdown-item v-if="!isTrialSub" v-b-modal.cloud-import-box-drop>Cloud import <b-badge variant="dark-5" class="badge_beta ml-2">beta</b-badge></b-dropdown-item>
							</b-dropdown>
							</div>
						</label>
						<label v-else for="assetsFieldHandle" class="d-flex" :class="vodFIles.length > 0 ? 'drop-zone' : 'drop-zone-tall'">
							<div class="rounded-circle drop-zone-circle d-flex flex-column align-items-center justify-content-center">
								<svg class="mb-4" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M34.6 45.5H14.4C13.2716 45.5 12.4554 45.4996 11.8135 45.4472C11.1774 45.3952 10.7566 45.2946 10.411 45.1185C9.75247 44.783 9.21703 44.2475 8.88148 43.589C8.70539 43.2434 8.60481 42.8226 8.55284 42.1865C8.50039 41.5446 8.5 40.7284 8.5 39.6V8.4C8.5 7.27165 8.50039 6.45545 8.55284 5.81352C8.60481 5.17744 8.70539 4.75662 8.88148 4.41103C9.21703 3.75247 9.75247 3.21703 10.411 2.88148C10.7566 2.70539 11.1774 2.60481 11.8135 2.55284C12.4554 2.50039 13.2716 2.5 14.4 2.5H31.7929L40.5 11.2071V39.6C40.5 40.7284 40.4996 41.5446 40.4472 42.1865C40.3952 42.8226 40.2946 43.2434 40.1185 43.589C39.783 44.2475 39.2475 44.783 38.589 45.1185C38.2434 45.2946 37.8226 45.3952 37.1865 45.4472C36.5446 45.4996 35.7284 45.5 34.6 45.5Z" fill="#232D4D" stroke="#343E61"/>
									<path d="M25 33V23M25 23L21 27M25 23L29 27" stroke="#404B72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M32 2L41 11C41 11.5523 40.5523 12 40 12H35.2857C34.0888 12 33.4903 12 33.0092 11.8316C32.1474 11.5301 31.4699 10.8526 31.1683 9.99084C31 9.50972 31 8.91124 31 7.71429V3C31 2.44772 31.4477 2 32 2Z" fill="#343E61"/>
								</svg>
								<p class="text-s-m text-dark-9">Release files to upload</p>
							</div>
						</label>
					</div>
					<b-modal hide-header hide-footer centered id="cloud-import-box-drop" class="cloud-import-box">
						<UppyUploader v-if="storageRestricitonsLoaded"
							:stream="stream" 
							:max-files="5" 
							:max-total-file-size="maxTotalFileSize"
							:maxFileSize="maxVideoFileSize * (1000 **3)"
							:storageLimit="aioStorageLimit"
							@upload-success="onVideoAdded"
							:encodingPresets="canEncodeVideos ? availableEncodingPresets : []"
						/>
					</b-modal>
				</div>
				<div @dragover="dragover" @dragleave="dragleave" @drop="drop" class="video-list">
					<b-alert v-if="hasIncompatibleMediaFiles" show variant="secondary" class="text-dark-7 text-s-s d-flex p-2">
						<span>
							<svg class="mr-2" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="currentColor"/>
							</svg>
						</span>
						<div>
							<p class="m-0 text-dark-9 text-s-m font-weight-500">File is not following our recommendation</p>
							<p class="text-s-s m-0">Your stream may be unstable. We recommend you to encode your file following <a href="https://docs.castr.io/en/articles/5161326-recommended-settings-for-recorded-videos" target="_blank" class="text-underline text-dark-8">our guide</a>. If you still want to use the current file, kindly do a test stream before the actual livestream.</p>
						</div>
					</b-alert>
				</div>
			</div>
		</div>
		
		<alert-modal
			:message="uploadErrorMessage || 'You are exceeding your storage limit. Please upgrade to continue'"
			modal-id="alert-storage-exceeding"
			ok-text="Got it, thanks"
			button-alignment="text-right"
		/>
		<alert-modal
			modal-id="alert-video-max-size"
			:message="maxFileSizeErrorMessage|| 'Your file size cannot exceed. Upload smaller file or upgrade your plan to continue.'"
			ok-text="Got it, thanks"
			button-alignment="text-right"
		/>
		<upload-alert-modal
			modal-id="alert-video-max-size1"
			:message="maxFileSizeErrorTitle"
			:body="maxFileSizeErrorMessage|| 'Your file size cannot exceed. Upload smaller file or upgrade your plan to continue.'"
			ok-text="Cancel"
			button-alignment="text-right"
		/>
		<alert-modal
			:message="maxFileNumberErrorMessage || 'Your number of files cannot exceed. Upgrade your plan if you need more files.'"
			modal-id="alert-video-max-file"
			ok-text="Got it, thanks"
			button-alignment="text-right"
		/>
		<alert-modal
			message="Please upload MP4 files only."
			modal-id="forbidden-file-format-modal-drop"
			ok-text="Ok"
			button-alignment="text-right"
		/>
	</div>
</template>
<script>
import Spinner from "@/components/ui/Spinner.vue"
import StreamService from '@/services/StreamService';
import UserService from "@/services/UserService";
import utils from '@/utils';
import Dropzone from '@/components/Dropzone.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import UploadAlertModal from '@/components/modals/UploadAlertModal.vue';
// import SelectLiveToVodModal from '@/components/modals/SelectLiveToVodModal.vue';
import _ from 'lodash';
import UppyUploader from '@/components/UppyUploader.vue';
import MetricsService from '@/services/MetricsService';
import { mapGetters } from 'vuex'
// import SharePlaylistModal from '@/components/modals/SharePlaylistModal.vue';

export default {
	name: 'VODUploadWidgetDrop',
	components: {
		Dropzone,
		Spinner,
		AlertModal,
		UploadAlertModal,
		// SelectLiveToVodModal,
		UppyUploader,
		// SharePlaylistModal,
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		mediaOnline: {
			type: Boolean,
		},
		schedulerSettings: {
			type: Object,
			default() { return {}; }
		},
		isOneMinuteBefore: {
			type: Boolean
		},
		streamAlive: {
			type: Boolean
		},
		canEncodeVideos: {
			type: Boolean,
			default: false
		},
		multipleUploads: {
			type: Boolean,
			default: true
		},
		maxFiles: {
			type: Number,
			default: 1
		},
		totalFilesPerStream: {
			type: Number,
			default: 999
		},
		videoFiles: {
			type: Array,
			default: () => []
		},
		mp4Only: {
			type: Boolean,
			default: true
		},
		isDisabledControl: {
			type: Boolean,
			default: false
		},
		isVodTrial: {
         type: Boolean,
         default() { return false }
		},
		vodSubExpiredDays: {
				type: Number,
				default() { return 1 }
		},
		advertisingMediaUpload: {
			type: Boolean,
			default: false
		},
		aioStorageLimit: {
			type: Object,
			default() { return {}; }
		},
	},
	data() {
		return {
			appUpdate: false,
			mediaInfoLib: null,
			widgetLoaded: false,
			processing: true,
			uploadErrorMessage: null,
			maxFileSizeErrorTitle: null,
			maxFileSizeErrorMessage: null,
			maxTotalFileSize: null,
			storageRestricitonsLoaded: false,
			scheduledSub: null,
			usedDvrStorage: {
				value: 0,
				loaded: false
			},
			maxFileNumberErrorMessage: null,
			uploadWidgetMaximized: false,
			uploadWidgetCollapse: true,
			uploadList: [],
			availableEncodingPresets: [
				{ id: '360p', text: '360p', minHeight: 300 },
				{ id: '480p', text: '480p', minHeight: 440 },
				{ id: '720p', text: '720p', minHeight: 700 },
				{ id: '1080p', text: '1080p', minHeight: 1020 }
			],
			filesDragging: false,
			allowMultipleUploads: this.multipleUploads,
			showUploadButton: true,
			cloudImp:false,
			showOptions:false,
		};
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			uploadFiles: "Ui/uploadFiles",
			vodFiles: "Streams/vodFiles",
			vodStreams: "Streams/vodStreams"
		}),
		streamKey() {
			// in live-to-vod, all the keys should be equal
			const v = this.videoFiles.find(x => x.archive?.key);
			return v?.archive?.key;
		},
		activeTeam(){
			return this.$store.state.User.activatedTeam;
		},
		shouldCheckMediaQuality() {
			return this.$route.name === 'ScheduledStreamManageDashboard'
		},
		hasIncompatibleMediaFiles(){
			return this.uploadFiles.some(media => media?.mediaInfo && !this.isMediaCompatible(media.mediaInfo))
		},
		// pendingUploadsList() {
		// 	return this.uploadList.filter(u => !u.uploading);
		// },
		// activeUploadsList() {
		// 	return this.uploadList.filter(u => u.uploading === true);
		// },
		maxVideoFileSize() {
			let maxSize = null;
			const subscriptions =  _.cloneDeep(this.$store.state.User.subscriptions);

			if (this.stream.type === 'vod' && this.aioStorageLimit) {
				this.aioStorageLimit.bytes += this.usedDvrStorage.value
				this.aioStorageLimit.unitsLeft -= this.usedDvrStorage.value
				// AIO plans does not have max file size setting, so we set it to 100GB by default
				maxSize = 100
				return maxSize
			}

			const activeScheduledSubs = _.filter(subscriptions, (sub) => sub.category === 'scheduled' && sub.enabled === true)
			
			activeScheduledSubs.forEach(sub => {
				try {
					if (sub.definitionOverride && sub.definitionOverride.schedulerMaxVideoSize) {
						sub.package.definition.schedulerMaxVideoSize = parseFloat(sub.definitionOverride.schedulerMaxVideoSize)
					}
					if (maxSize) {
						const tmpMaxSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
						if (tmpMaxSize > maxSize) {
							maxSize	= tmpMaxSize;
						}
					} else {
						maxSize	= parseFloat(sub.package.definition.schedulerMaxVideoSize);
					}
				} catch (error) {
					console.error(error);
				}
			})

			return maxSize
		},
		scheduledStorage() {
			let scheduledStorage = null;
			const subscriptions = this.$store.state.User.subscriptions;

			const activeScheduledSubs = _.filter(subscriptions, (sub) => sub.category === 'scheduled' && sub.enabled === true)
			
			activeScheduledSubs.forEach(sub => {
				try {
					let overrideStorage = parseFloat(sub.package.definition.storage);
					if (sub.definitionOverride && sub.definitionOverride.storage) {
						overrideStorage = parseFloat(sub.definitionOverride.storage)
					}
					if (scheduledStorage) {
						const tmpMaxSize = overrideStorage;
						if (tmpMaxSize > scheduledStorage) {
							scheduledStorage	= tmpMaxSize;
							this.scheduledSub = sub
						}
					} else {
						scheduledStorage = overrideStorage
						this.scheduledSub = sub
					}
				} catch (error) {
					console.error(error);
				}
			})

			return scheduledStorage
		},
		maxNumberOfVideoFiles() {
			return this.maxFiles;
		},
		vodStorageLimit() {
			return parseFloat(this.$store.state.User.maxVodStorage);
		},
		isTrialSub() {
			let isTrialSub = false;
			let isPaidSub = false;
			const subscriptions = this.$store.state.User.subscriptions;
			subscriptions.forEach(sub => {
				try {
					if (['5cd5485126fd039846a3255f', '5ccd92f8152b7f25111c39cf', '5e8db3dcc3db0533322db725',
								'6362114709950a6a642540c4', '6362114709950a6a642540c2', '6362114709950a6a642540c3'].indexOf(sub.package._id) !== -1 && sub.enabled === true) {
						isTrialSub = true;
					} else if (sub.enabled === true) {
						isPaidSub = true;
					}
				} catch (error) {
					console.error(error);
				}
			})
			return isPaidSub ? false : isTrialSub;
		},
		scheduleSubscriptionsStatus() {
			const subscriptions = this.$store.state.User.subscriptions;
			const scheduledSubscriptions = _.filter(subscriptions, (sub) => sub.category === 'scheduled');
			const result = scheduledSubscriptions.find(element => element.enabled === true);
			if (result) {
				return true
			} else return false;
		},
	},
	
	async mounted() {
		this.setupMediaLibs();
		this.setupAWSLibs();
		await this.initVideoUpload()
		setInterval(() => {
			this.initMaxFileSizeData();
		}, 3000);
	},
	destroyed() {
		// this.uploadList.forEach(this.cancelVideoUpload.bind(this));
	},
	directives: {
		'click-outside': {
			bind: function (el, binding, vnode) {
				el.clickOutsideEvent = function (event) {
					// console.log(event.target.classList[0].includes("uppy") );
				// here I check that click was outside the el and his children
				if (!(el == event.target || el.contains(event.target))) {
					// and if it did, call method provided in attribute value
					let uppywrapper = false;
					if(event.target.classList[0]){
						uppywrapper = event.target.classList[0].includes("uppy");
					}
					if((event.target.tagName == 'BUTTON' || event.target.tagName == 'SPAN')){
						var closest = event.target.tagName;
					}else{
						var closest = event.target.closest('.cloud-import-box-drop')
					}
					if(closest != null){
						uppywrapper = true;
					}
					if(!uppywrapper){vnode.context[binding.expression](event);}
					
				}
				};
				document.body.addEventListener('click', el.clickOutsideEvent)
			},
			unbind: function (el) {
				document.body.removeEventListener('click', el.clickOutsideEvent)
			},
		}
	},
	methods: {
		onClickShare() {
		},
		isMediaCompatible(mediaInfo) {
			if(!this.shouldCheckMediaQuality) return true

			if(mediaInfo.hasAudioTrack && !mediaInfo.isVbr) return true
			return false
		},
		getErrorMessage(mediaInfo){
			let message = ''
			
			if(mediaInfo.isVbr) {
				message = 'File should be CBR-encoded'
			}

			if(!mediaInfo.hasAudioTrack) {
				if(mediaInfo.isVbr) message += ' and have sound'
				else message = 'File should have sound'
			}

			message += '.'

			return message
		},
		toggleCI: function() {
			if(this.cloudImp == true){
    		this.cloudImp = false;
			}
			
    	},
		async initDvrStorageUsage() {
			let bytes = 0;
			this.usedDvrStorage.loaded = false;
			try {
				const resp = await MetricsService.getUserDvrStorage();
				bytes = resp.bytes || 0;
			} catch(e) {
				console.error('getUsedDvrStorage', e);
			}
			this.usedDvrStorage.loaded = true;
			this.usedDvrStorage.value = bytes;
		},
		onVideoAdded(videoItem) {
			this.$emit('video-added', videoItem);
			this.cloudImp = false;
			this.showOptions = false;
		},
		setupAWSLibs() {
			const vm = this
			let scriptId = 'aws-js'
			if (!document.getElementById(scriptId)) {
				// async load mediainfo js
				let scriptEl = document.createElement('script')
				scriptEl.src = 'https://sdk.amazonaws.com/js/aws-sdk-2.663.0.min.js'
				scriptEl.setAttribute('id', scriptId)
				scriptEl.onload = function() { initClass() }
				document.head.appendChild(scriptEl)
			} else {
				initClass()
			}

			function initClass() { vm.awsLoaded = true }
		},
		onFilesDrop(e) {
			e.preventDefault();
			e.stopPropagation();
			this.showOptions = false;
			if (e.dataTransfer.items) {
				for (const file of e.dataTransfer.items) {
					if (file.kind === 'file' && file.type.startsWith('video')) this.onMediaAdd(file.getAsFile());
				}
			} else {
				for (const file of e.dataTransfer.files) {
					if (file.type.startsWith('video')) this.onMediaAdd(file);
				}
			}
		},
		onMediaPresetSelect(media) {
			setTimeout(() => media.encodingUnitsRequired = computeEncodingUnits(media), 0);
		},
		isPresetSupported(media, preset) {
			return !media.mediaInfo ? false : media.mediaInfo.height >= preset.minHeight;
		},
		togglePresetList(media) {
			media.presetListCollapse = !media.presetListCollapse;
		},
		setupMediaLibs() {
			const vm = this;
			const scriptId = 'mediainfo-js';

			
				// async load mediainfo js
				const scriptEl = document.createElement('script');
				if ('WebAssembly' in window) {
					scriptEl.src = 'https://castr.com/libs/mediainfojs2/MediaInfoWasm.js';
				} else {
					scriptEl.src = 'https://castr.com/libs/mediainfojs2/MediaInfo.js';
				}

				scriptEl.setAttribute('id', scriptId);
				scriptEl.onload = function() {
					initClass();
				};
				document.head.appendChild(scriptEl);
		
			function initClass() {
				// eslint-disable-next-line no-undef
				vm.mediaInfoLib = MediaInfoLib({
					postRun() {
						if (typeof Promise !== 'undefined' && vm.mediaInfoLib instanceof Promise) {
							vm.mediaInfoLib.then((module)  => {
								vm.widgetLoaded = true;
								vm.mediaInfoLib = module
							});
						} else {
							vm.widgetLoaded = true;
						}
					}
				});
			}
		},
		async initMaxFileSizeData() {
			// Check max video file size (GB)
			let totalVodBytes = 0;
			const vodStorageTherehold = this.vodStorageLimit
			if (this.stream.type === 'vod' && vodStorageTherehold) {
				await this.initDvrStorageUsage();
				const streams = this.vodStreams;
				
				streams.forEach((item) => {
					if (item.type === 'vod' && !item.liveParent) {
						totalVodBytes += item.totalBytes
					}
				})

				if (totalVodBytes > 0) {
					totalVodBytes = totalVodBytes / (1000 ** 3)
				}

				const dvrHumanSize = this.usedDvrStorage.value / 1000 ** 3;
				// console.log('totalVodBytes', totalVodBytes, 'vodStorageTherehold', vodStorageTherehold, 'dvrUsed', dvrHumanSize);
				this.maxTotalFileSize = (vodStorageTherehold - (totalVodBytes + dvrHumanSize)) * (1000 ** 3);
			}

			this.storageRestricitonsLoaded = true;
		},
		async initVideoUpload() {
			const el = this.$refs.uploadInputDrop;
			if (!el) {
				setTimeout(() => this.initVideoUpload(), 5000);
				return;
			}

			const vm = this;

				if (!el.files.length) return;
				// Calculate max video files of the scheduled stream
				let maxFiles =  vm.maxNumberOfVideoFiles;
				maxFiles = vm.totalFilesPerStream - vm.videoFiles.length - vm.uploadList.length;
				if (el.files.length > maxFiles) {
					vm.maxFileNumberErrorMessage = `You can only upload a maximum of ${vm.totalFilesPerStream} files. Upgrade your plan if you need more files.`
					vm.$root.$emit('bv::show::modal', 'alert-video-max-file');
					el.value = null;
					return;
				};

				// Check max video file size (GB)
				let totalVodBytes = 0;								// total usage of all active(without deleted/archived) VOD + pseudoVOD(live to vod) 
				let totalRealVodBytes = 0							// total usage of all active(without deleted/archived) VOD

				const vodStorageTherehold = vm.vodStorageLimit
				if (vm.stream.type === 'vod' && vodStorageTherehold) {
					const streams = this.vodStreams;
					
					streams.forEach((item) => {
						if (item.type === 'vod') {
							totalVodBytes += item.totalBytes
						}

						if (item.type === 'vod' && !item.liveParent) {
							totalRealVodBytes += item.totalBytes
						}
					})

					if (totalVodBytes > 0) {
						totalVodBytes = totalVodBytes / (1000 ** 3)
					}

					if (totalRealVodBytes > 0) {
						totalRealVodBytes = totalRealVodBytes / (1000 ** 3)
					}

					let uploadQueueBytes = 0
					// console.log(el.files);
					// console.log(el.files[0]);
					if(Array.isArray(el.files)) {
						el.files.forEach((item) => {
							uploadQueueBytes = uploadQueueBytes + (item.size / (1000 ** 3))
						})
					} else {
						
						Object.entries(el.files).forEach(
							([key, value]) => {
								// console.log(value.size),
								uploadQueueBytes = uploadQueueBytes + (value.size / (1000 ** 3))
							}
						);

						// el.files.forEach((item) => {
						// 	uploadQueueBytes = uploadQueueBytes + (item.size / (1000 ** 3))
						// })
					}
					

					const uploadQueue = vm.uploadList || []
					uploadQueue.forEach((item) => {
						uploadQueueBytes = uploadQueueBytes + (item.file.size / (1000 ** 3))
					})

					const dvrHumanSize = vm.usedDvrStorage.value / 1000 ** 3;
					// console.log('totalRealVodBytes', totalRealVodBytes, 'totalVodBytes', totalVodBytes, 'dvrHumanSize', dvrHumanSize, 'uploadQueueBytes', uploadQueueBytes, 'vodStorageTherehold', vodStorageTherehold);
					// console.log(totalRealVodBytes + uploadQueueBytes + dvrHumanSize)
					// console.log(vodStorageTherehold)
					if((totalRealVodBytes + uploadQueueBytes + dvrHumanSize) > vodStorageTherehold) {
						// console.log(`Storage capacity cannot exceed ${vodStorageTherehold}GB. Upgrade your plan to continue.`);
						vm.maxFileSizeErrorTitle = `Your storage limit has been exceeded`
						vm.maxFileSizeErrorMessage = `Your VOD storage limit (${vodStorageTherehold}GB) has been exceeded. Upgrade now to increase your video upload capacity.`
						
						vm.$root.$emit('bv::show::modal', 'alert-video-max-size1');
						el.value = null;
						return;
					}
				}

				if (vm.stream.type === 'scheduled') {
					if (_.isNumber(vm.scheduledStorage)) {
						let fileSize = 0
						_.forEach(el.files, (file) => fileSize += (file.size / (1000 ** 3)));

						// Get storage usage
						const userId = vm.$store.state.User._id;
						const packageId = vm.scheduledSub.package._id
						const storageUsage = await MetricsService.getSubscriptionStorage(userId, packageId)
						const overStorage = (vm.scheduledStorage - (storageUsage?.bytes / (1000 ** 3)) - fileSize) < 0
						if (overStorage) {
							vm.maxFileSizeErrorMessage = `Storage capacity cannot exceed ${vm.scheduledStorage}GB. Upgrade your plan to continue.`
							
							vm.$root.$emit('bv::show::modal', 'alert-video-max-size');
							el.value = null;
							return;
						}
					}
					if (_.isNumber(vm.maxVideoFileSize)) {
						const overMaxSize = _.find(el.files,(file) => (file.size / (1000 ** 3)) > (vm.maxVideoFileSize - totalVodBytes));
						if (overMaxSize) {
							vm.maxFileSizeErrorMessage = `Your file size cannot exceed ${vm.maxVideoFileSize}GB. Upload smaller file or upgrade your plan to continue.`
							
							vm.$root.$emit('bv::show::modal', 'alert-video-max-size');
							el.value = null;
							return;
						}
					}
				}

				// #enabling non-mp4 uploads
				if (vm.mp4Only) {
					const ifHaveNonMp4 = _.find(el.files,(file) => file.type !== 'video/mp4');
					if(ifHaveNonMp4) {
						vm.$root.$emit('bv::show::modal', 'alert-video-encoding');
						el.value = null;
						return;
					}
				}
				this.$store.dispatch('Ui/addStreamToUpload', this.stream);
				Array.prototype.forEach.call(el.files, file => {
					// check for mime types
					const allowedExtensions = ["mp4"];
					const fileExtension = file.name.split(".").pop().toLowerCase();
					if (file.type.startsWith('video') && allowedExtensions.includes(fileExtension)) {
						vm.onMediaAdd(file,vm);
					} else {
						vm.$root.$emit("bv::show::modal", "forbidden-file-format-modal-drop");
					}
				});

				el.value = null;
		},
		computeVideoPrecedence (video) {
			let precedence = 0;
			let siblingVids = this.videoFiles;
			if (video) {
				siblingVids = _.filter(this.videoFiles, v => v.id !== video.id);
			}
			precedence = _.size(siblingVids) + 1;

			return precedence;
		},
		onMediaAdd(file,vm) {
			// not allow multiple uploads if disabled
			
			this.showOptions = false;
			if (this.allowMultipleUploads === false && this.uploadList.length > 0) {
				return;
			}

			let trimmedName = file.name;
			const nameChunks = trimmedName.split('.');
			const extname = nameChunks.length > 1 ? nameChunks.pop() : '';
			let basename = nameChunks.join('.');
			if (basename.length > 20) {
				basename = `${basename.substr(0, 14)} ... ${basename.substr(basename.length - 6)}`;
			}
			trimmedName = basename + '.' + extname;

			const newVideo = {
				id: `unnamed_${Math.random().toString().slice(2)}`,
				fileName: trimmedName,
				fileNameOriginal: file.name,
				bytes: file.size,
				mediaInfo: null,
				precedence: this.computeVideoPrecedence(),
				enabled: true,
				removing: false,
				statusProcessing: false,

				file,
				uploadable: true,
				uploading: false,
				uploadProgress: 0,
				encodingPresets: [],
				encodingUnitsRequired: 0,
				presetListCollapse: true,
				durationExceeded: false
			};
			// this.uploadList = [newVideo, ...this.uploadList];
			this.$store.dispatch('Ui/addFileToUpload', newVideo);
			// const uploadItem = this.uploadList[0];
			const uploadItem = this.uploadFiles[0]
			utils.inspectMediaFile(this.mediaInfoLib, file, info => {
				const durationSec = info.duration;
				const durationMins = Math.ceil(
					(durationSec < 60 ? 60 : durationSec) / 60
				);

				const mappedInfo = { ...info, durationMins };
				// uploadItem.mediaInfo = mappedInfo;
				const file = newVideo
				this.$store.dispatch('Ui/addFileData', {uploadItem: file, mappedInfo});
				if (this.isTrialSub && info.duration > 10 * 60) {
					// uploadItem.durationExceeded = true;
					this.$store.dispatch('Ui/addFileData', {uploadItem: file, durationExceeded: true})
				}
				this.$emit('upload-inspected', { upload: uploadItem, handler: 'raw' });
				// console.log('media-info', info)
			});

			if (this.multipleUploads === false) {
				this.showUploadButton = false;
			}

			this.$emit('upload-enqueue', { upload: newVideo, handler: 'raw' });
		},
		async requestSignedUploadUrl(uploadItem) {
			const fileObj = uploadItem.file;
			const uploadPayload = {
				encodingPresets: uploadItem.encodingPresets,
				metadata: {
					size: fileObj.size,
					type: fileObj.type,
					originalFilename: fileObj.name,
					precedence: uploadItem.precedence
				},
				mediaInfo: uploadItem.mediaInfo,
				streamType: this.stream.type,
				multipartSupport: fileObj.size / (1000 ** 3) > 1 // GB
			};

			const uploadUrl = await StreamService.requestVodMediaUploadUrl(this.stream.type === 'scheduled' ? this.stream._id : this.stream.key, uploadPayload);
			return uploadUrl;
		},
		startMultipartBucketUpload (uploadId, uploadItem, uploadCredentials, onBytesUploaded) {
      return new Promise((resolve) => {
        uploadItem.uploading = true

        let AWS = window.AWS
        AWS.config.update({
          region: uploadCredentials.region,
          useAccelerateEndpoint: uploadCredentials.useAccelerateEndpoint
        })

        // Initialize the Amazon Cognito credentials provider
        let identityConf = {
          IdentityPoolId: uploadCredentials.poolId,
          LoginId: UserService.getUser(this.activeTeam).email
        }

        AWS.config.credentials = new AWS.CognitoIdentityCredentials(identityConf)

        // Upload the File
        const uploadBucket = new AWS.S3({ params: { Bucket: uploadCredentials.bucketName } })
        const uploadOpts = {
          Key: uploadCredentials.keyPrefix + uploadId,
          ContentType: uploadItem.file.type,
          Body: uploadItem.file,
          queueSize: uploadCredentials.concurrency || 2, // default 2 parts in parallel
          partSize: uploadCredentials.chunkSize || (2 * 1000 * 1000), // default 2MB
          UseAccelerateEndpoint: true
				}

        const onBytes = (ev) => {
					let percent = (ev.loaded * 100) / ev.total
					onBytesUploaded(percent)
        }

        let multipartUploadHandle = uploadBucket.upload(uploadOpts)
        multipartUploadHandle.on('httpUploadProgress', onBytes)

        uploadItem.cancelSource = {
          cancel: () => void multipartUploadHandle.abort()
        }

        multipartUploadHandle.send((err, data) => {
          if (err) {
            uploadItem.uploadProgress = 0
            uploadItem.uploadError = true
            uploadItem.cancelSource = null
            uploadItem.uploading = false
          }

          resolve(!err)
        })
      })
    },
		async onVideoUploaded(uploadItem, uploadResult) {
			if (!uploadResult || !uploadResult.fileId) return;

			const oldId = uploadItem.id;

			uploadItem.id = uploadResult.fileId;
			uploadItem.mediaInfo = uploadResult.mediaInfo;
			uploadItem.creationTime = new Date();

			if (uploadResult.encodingRequired) {
				uploadItem.encodingRequired = true;
				uploadItem.encodingStatus = 'processing';
			}

			uploadItem.uploadable = false;
			uploadItem.file = null;

			const uindex = this.uploadList.indexOf(uploadItem);
			const newVideoArray = utils.removeArrayItem(this.uploadList, uindex);
			this.uploadList = newVideoArray;

			this.$emit('new-video', uploadItem);
			this.$emit('upload-dequeue', {upload: oldId, handler: 'raw'});
			if (!this.uploadList.length) {
				this.$emit('video-queue-emptied');
			}
			await this.$store.dispatch('Streams/getStreams');
			await this.$store.dispatch('Streams/getFilesInVODFolder')
			this.$notify({
				group: 'success',
				text: 'media uploaded successfully'
			});
			this.$emit('video-added', uploadItem);
			setTimeout(() => {
				this.$notify({ group: 'info', text: 'Video added to media list' });
			}, 2000);
			this.showOptions = false;
		},
		cancelVideoUpload(media) {
			if (!media) return;

			if (media.cancelSource) {
				media.cancelSource.cancel();
			}

			media.uploading = false;
		},
		async removeVideoFile(media) {
			media.removing = true;

			this.$emit('upload-dequeue', {upload: media, handler: 'raw'});

			this.cancelVideoUpload(media);

			const index = this.uploadList.indexOf(media);
			this.uploadList.splice(index, 1);

			if (!this.uploadList.length) {
				this.$emit('video-queue-emptied');
				this.showUploadButton = true;
			}
		},
		async clearUploadList() {
			const uploadList = this.uploadList.slice();
			uploadList.forEach(vid => void this.removeVideoFile(vid));
		},
		async selectLiveToVod() {
			setTimeout(() => {
				this.$root.$emit('bv::show::modal', 'modal-select-live-to-vod');
			}, 100);
		},
		onChange() {
			console.log('open drop');
      // this.uploadList = [...this.$refs.uploadInputDrop.files];
			this.initVideoUpload()
    },
    remove(i) {
      this.uploadList.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
			// console.log(event.currentTarget);
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('drop-zone-hidden_dragover')) {
        event.currentTarget.classList.remove('drop-zone-hidden_dragleave');
        event.currentTarget.classList.add('drop-zone-hidden_dragover');
      }
			if (event.currentTarget.classList.contains('w-100')) {
        event.currentTarget.classList.add('z-10');
      }
    },
    dragleave(event) {
			// Clean up
      event.currentTarget.classList.remove('drop-zone-hidden_dragover');
			if (event.currentTarget.classList.contains('w-100')) {
        event.currentTarget.classList.remove('z-10');
      }
    },
    drop(event) {
      event.preventDefault();
      this.$refs.uploadInputDrop.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('drop-zone-hidden_dragleave');
      event.currentTarget.classList.remove('drop-zone-hidden_dragover');
    }
	}
};

function computeEncodingUnits(media) {
	if (!media.mediaInfo) return;
	const encUnits = media.mediaInfo.durationMins * media.encodingPresets.length;
	return encUnits > 0 ? Math.ceil(encUnits) : encUnits;
}
</script>

<style scoped>
.vod_uploadbox {
	width: 100%;
}
.vod_uploadbox_line-fix {
	margin-top: -4rem;
}
.vod_uploadbox .video-item {
		position: relative;
		z-index: 12;
		padding: .5rem 0;
}
.video-item + .video-item {
	border-top: 1px solid var(--c-dark-2);
}
.font-weight-500 {
	font-weight: 500;
}
.uploader-body_line-fix {
	margin-top: -3.85rem;
}
.z-fix {
	position: relative;
	z-index: 1;
}
.progressbar-wrapper {
	height: 3px;
	border-radius: 3px;
	background-color: var(--c-dark-2);
}
.progress-label {
	position: absolute;
	top: 0;
	right: 1.75rem;
	color: var(--c-dark-6);
}
.drop-zone {
	padding: .25rem;
}
.drop-zone-tall {
	border: 1px dashed var(--c-dark-4);
	border-radius: 0.5rem;
	padding: .25rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-height: 100%;
	height: 80vh;
	max-width: 100%;
}
</style>

<style>
.vod_uploadbox + .video-list {
	opacity: 1;
	transition: .3s;
}
.vod_uploadbox:has(.drop-zone-hidden_dragover) + .video-list {
	opacity: 0;
	transition: .3s;
}
.z-10 {
	z-index: 10;
}
.drop-zone-circle {
	width: 220px;
	height: 220px;
}
</style>