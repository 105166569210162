<script>
export default {
  compatConfig: {
    MODE: 3,
    COMPONENT_V_MODEL: false,
  }
}
</script>
<script setup>

import { usePassthroughValue } from '@/Utils/usePassthroughValue'

const props = defineProps({
  type: {
    type: String,
    default: 'main',
  },
  size: {
    type: String,
    default: 'md',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
  },
  labelStyles: {
    type: String,
  },
  labelPosition: {
    type: String,
    default: 'right'
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
  }
  
})

const emit = defineEmits({
  'update:modelValue': (value) => {return value}
})
const checked = usePassthroughValue(props, emit)

</script>

<template>
  <label class="switcher-label mb-0" :class="[labelStyles, 'switcher-label-' + size]" :for="id">
    <input :id="id" :disabled="disabled" class="switcher" :class="[{'order-2': labelPosition === 'left'}, 'switcher-' + size]" :checked="checked"
      type="checkbox" v-model="checked" />
      <span class="" :class="{'order-1': labelPosition === 'left'}">
        {{ label }}
        <slot class="position" />
      </span>
  </label>
</template>

<style></style>

<style>
label.switcher-label {
  @apply relative inline-flex gap-x-2 items-center;
}
label.switcher-label-md {
  @apply h-6
}
label.switcher-label-sm {
  @apply h-4
}
.switcher[type="checkbox"] {
  @apply appearance-none;
  @apply cursor-pointer inline-block;
  @apply focus:outline-0;
  @apply border-0;
  @apply focus:ring-offset-transparent;
  @apply focus:ring-transparent;
  @apply focus-within:ring-0;
  @apply focus:shadow-none;

  @apply after:absolute before:absolute;
  @apply after:top-0 before:top-0;
  @apply after:block before:inline-block;
  @apply before:rounded-full after:rounded-full;

  @apply after:shadow-md after:duration-100;

  @apply before:content-[''] before:h-full;
  @apply before:shadow-[inset_0_0_#000];

  @apply after:bg-white;
  @apply before:bg-surface-4;
  @apply before:checked:bg-success;
  @apply checked:after:duration-300;

  @apply disabled:opacity-65;
  @apply disabled:after:bg-opacity-75 disabled:cursor-not-allowed;
  @apply disabled:checked:before:bg-opacity-40;
}

.switcher[type="checkbox"].switcher-md {
  @apply w-11 h-0 ;

  @apply after:content-[''] after:w-5 after:h-5 after:mt-0.5 after:ml-0.5;
  @apply before:w-10;
  @apply checked:after:translate-x-4;
}
.switcher[type="checkbox"].switcher-sm {
  @apply w-7 h-0; 
  @apply after:content-[''] after:w-3 after:h-3 after:mt-0.5 after:ml-0.5;
  @apply before:w-6;
  @apply checked:after:translate-x-2;
}
</style>
