<template>
	<div class>
		<b-modal centered id="modal-highlight-subchannel" @hidden="onReset" ref="modalHighlightSubchannel" body-class="d-flex"
			size="md" @hide="closeModal" hide-footer hide-header>
			<div class="modal-container w-100">
				<b-row class=" mb-2">
					<b-col>
						<h3 class="text-s-m mb-3 text-400">Select the Publishing Channel</h3>
					</b-col>
				</b-row>
				<Spinner v-if="listChannels.length == 0" text="Retrieving data..." classes="text-dark-8 mh-20"
					spinner-color="var(--c-dark-8)" spinner-size="15px" />
				<b-row class=" mb-3" v-else>
					<b-col class="positiom-relative">
						<label class="text-dark-9 text-500 text-s-ss mb-2">
							Publishing Channel
						</label>
						<b-dropdown variant="dark-3" size="md" id="" menu-class="dropdown-menu_md mw-100 bg-dark-3 list"
							toggle-class="justify-content-between text-truncate" class="w-100">
							<template #button-content>
								<div v-if="channelSelected" class="text-truncate">{{ channelSelected.name }}</div>
								<div v-else class="text-truncate text-s-m text-dark-9">
									Select Channel
								</div>
							</template>
							<template v-if="listChannels && listChannels.length > 0">
								<b-dropdown-item @click="setChannel(channel)" :key="index" v-for="(channel, index) in listChannels">
									<span class="text-truncate">{{ channel.name }}</span>
								</b-dropdown-item>
							</template>
						</b-dropdown>
					</b-col>
				</b-row>
				<b-row class="">
					<b-col>
						<div style="max-width: 300px;margin-left:auto;margin-right: 0;">
							<div class="text-center d-flex align-items-center justify-content-end mt-3">
								<b-button variant="outline-secondary" size="md" class=" mb-0 mb-md-0 mr-2" @click="closeModal">
									Cancel
								</b-button>
								<b-button variant="primary" size="md" class=" mb-0 mb-md-0 mr-2" @click="addPlatform">
									Add Platform
								</b-button>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import Spinner from "@/components/ui/Spinner.vue"
import { mapGetters } from 'vuex'
import HighlightService from '@/services/HighlightService'

export default {
	name: 'HighlightSubchannelModal',
	components: {
		Spinner
	},
	data() {
		return {
			channelSelected: null,
			loading: true
		};
	},
	props: {
		listChannels: {
			type: Array,
		},
		cred: {
			type: String,
		},
	},
	async mounted() {



	},
	methods: {
		setChannel(channel) {
			this.channelSelected = channel;
		},
		addPlatform() {
			this.$emit('set-platform', this.channelSelected);
			this.closeModal();
		},
		async onConfirm() {
			try {
				this.$store.dispatch('Ui/setLoadingStatus', true)
				// if(this.playlist?.length > 0){
				// 	const bool = this.playlist.filter((s)=>{
				// 		return s.name == this.playlistName
				// 	});
				// 	console.log('bool');
				// 	console.log(bool);
				// 	this.exists = bool;
				// }
				// if(this.exists.length > 0){
				// 	this.$notify({ group: 'error', text: `"${this.playlistName}" Playlist exists.` })
				// }else{
				const newHighlight = await HighlightService.createHighlight(
					this.highlightName,
					'',
					this.selectedAIO._id
				);
				this.$emit('highlight-created', newHighlight)
				console.log(newHighlight);
				this.$notify({ group: 'success', text: 'New Live Clipping Created' })

				this.$store.dispatch('Ui/setLoadingStatus', false)
				await this.$store.dispatch('Highlight/getHighlight')

				this.closeModal();

				// }

			} catch (err) {
				this.error = err.message
				// this.$store.dispatch('Ui/setLoadingStatus', false)
			}
		},
		async setElement(item) {
			this.selectedAIO = item;
			console.log(item);
			this.$refs.source.hide()
			// try {

			// } catch (err) {
			// 	console.log(err)
			// }
		},
		closeModal() {
			this.$emit('closing', false)
			this.channelSelected = null;
			this.$refs['modalHighlightSubchannel'].hide();
		},
		onReset() {
			this.error = null
		}
	}
};
</script>

<style scoped>
.selected-stream--name {
	position: absolute;
	left: 56px;
	top: 32px;
	z-index: 9;
}

#stream-source {
	height: 32px;
}

div#modal-add-highlight___BV_modal_content_ input {
	height: 32px;
}

.mh-20 {
	min-height: 20px;
}

@media (max-width: 767px) {}
</style>
