<script setup>
import { ref, onMounted, onBeforeMount, computed, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import _ from "lodash";
import StreamService from "@/services/StreamService";
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"
import AlertModal from "@/components/modals/AlertModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import FormButton from '../../components/Atoms/FormButton.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import IconBase from '../../components/icon/IconBase.vue';
import IconChevronUpDown from '../../components/icon/IconChevronUpDown.vue';
import FormSwitch from '../../components/Atoms/FormSwitch.vue';
import FormInput from '../../components/Atoms/FormInput.vue';
import IconHelp from '@/components/icon/IconHelp.vue';

const props = defineProps({
  stream: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, "_id");
    },
  },
})

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const themeColorDropdown = ref(null)

const ottProcessing = ref(false)
const isOtt = ref(false)
const streamMeta = ref(null)
const embedThemeColorSelection = ref(null)
const streamMetadataLoaded = ref(null)
const trialError = ref(null)
const features = ref({
  ga: {
    enabled: false,
    value: null,
    valueType: "string",
  },
  ottEnabled: {
    enabled: false,
    valueType: "bool",
  },
})

const featureProcessing = ref({
  ga: false,
  ottEnabled: false,
})

onBeforeMount(async () => {
  streamMeta.value = await StreamService.getStreamMetadata(props.stream._id);

  _.forIn(streamMeta.value, (value, key) => {
    if (value !== undefined && value !== null && key in features.value) {
      let enabled = value !== false;
      if (Array.isArray(enabled)) {
        enabled = enabled.length;
      }

      features.value[key].enabled = enabled;
      if (value.constructor !== Boolean) {
        features.value[key].value = value;
      }
    }
  });

  embedThemeColorSelection.value =
    streamMeta.value.options.embedThemeColors.find(({ color }) => {
      return color === streamMeta.value.embedThemeColor;
    });

  streamMetadataLoaded.value = true;
})

onMounted(async () => {
  try {
    // update vuex state
    await store.dispatch("User/checkOtt", null, { root: true });
    isOtt.value = store.state.User.isOtt;
  } catch (e) {
    trialError.value = e;
    return;
  }
  // event tracking
  window.trackEvent(
    props.stream.name + " - Stream Settings Page",
    props.stream
  );
})

const requestOttunpublish = () => {
  ottProcessing.value = true;
  if (!features.value.ottEnabled.enabled) {
    root.$emit("bv::show::modal", "unpublish-ott");
  } else {
    toggleFeature("ottEnabled");
  }
}

const getSelectedEmbedThemeColor = () => {
  if (!streamMeta.value) return;
  return streamMeta.value.embedThemeColor;
}

const selectEmbedThemeColor = (event, color) => {
  event.preventDefault();
  embedThemeColorSelection.value = color;
}

const saveEmbedThemeColor = async () => {
  if (!streamMeta.value) return;

  const colorNode = embedThemeColorSelection.value
  try {
    const color = colorNode.color
    await saveSetting("embedThemeColor", color)
    streamMeta.value.embedThemeColor = color
    notify({
      group: 'success',
      text: 'New theme color was saved'
    });
  } catch (e) {
    notify({
      group: "error",
      text: "could not update player color",
    })
  }
  // save changes
}

const canSaveEmbedThemeColor = () => {
  if (!embedThemeColorSelection.value) return;
  return (
    embedThemeColorSelection.value.color !== streamMeta.value.embedThemeColor
  );
}

const toggleOttcancel = () => {
  features.value["ottEnabled"].enabled =
    !features.value["ottEnabled"].enabled;
  ottProcessing.value = false;
}

const toggleFeature = async (featureName, newFeature = false) => {
  if (featureProcessing.value[featureName]) return;
  if (featureName === "ottEnabled") {
    ottProcessing.value = true;
  }
  const feature = features.value[featureName];
  if (!feature) return;

  let nstate = feature.enabled;
  feature.enabled = nstate;
  if (featureName === "ottEnabled" && !nstate) {
    feature.enabled = true;
  }
  if (feature.valueType === "bool" || !nstate) {
    if (feature.valueType === "array" && !nstate) {
      nstate = [];
    }
    await saveSetting(featureName, nstate);
  }
}

const savePlayerGAId = async () => {
  var gaId = features.value.ga.value;
  if (!gaId) return;
  await saveSetting("ga", gaId);
}

const saveSetting = async (key, value, successToast = false) => {
  featureProcessing.value[key] = true;
  try {
    await StreamService.saveStreamMetadata(props.stream._id, key, value);
    // clear stream meta cache in embed player
    StreamService.clearStreamMetadataCache(props.stream._id);
    if (successToast) {
      notify({ group: "success", text: "Setting saved" });
    }
  } catch (e) {
    notify({ group: "error", text: "could not save changes" });
  }
  if (key == "ottEnabled") {
    ottProcessing.value = false;
  }
  featureProcessing.value[key] = false;
}

</script>
<template>
  <div class="divide-y divide-surface-2">
    <section class="flex justify-between !py-3">
      <h4 class="text-base">Player Theme Color</h4>
      <div class="flex justify-end items-center gap-x-2">
        <DropDownMenu v-if="embedThemeColorSelection" type="secondary" ref="themeColorDropdown">
          <template #toggle-button>
            <div :style="{ 'background-color': embedThemeColorSelection.color }"
              class="size-3 border border-white rounded-full !mr-2" />
            <span class="capitalize">{{ embedThemeColorSelection.label }}</span>
            <icon-base class="!ml-1">
              <icon-chevron-up-down />
            </icon-base>
          </template>
          <template #menu-items>
            <DropDownMenuItem class="uppercase" v-for="(color, index) in streamMeta.options.embedThemeColors"
              :key="index" @click="selectEmbedThemeColor($event, color)">
              <div :style="{ 'background-color': color.color }" class="size-3 border border-white rounded-full !mr-2" />
              <span class="text-capitalize">{{ color.label }}</span>
            </DropDownMenuItem>
          </template>
        </DropDownMenu>
        <FormButton v-show="canSaveEmbedThemeColor()" :disabled="featureProcessing.theme" @click="saveEmbedThemeColor">
          {{ featureProcessing.theme ? 'Saving' : 'Save' }}
        </FormButton>
      </div>
    </section>
    <section class="grid grid-cols-2 grid-rows-[1fr_auto] !py-3">
      <h4 class="text-base">Enable Google Analytics</h4>
      <FormSwitch class="justify-self-end" @change="toggleFeature('ga')" v-model="features.ga.enabled" />
      <div class="col-span-2 flex items-center gap-x-2 !mt-1" v-if="features.ga.enabled">
        <label class="m-0">GA ID</label>
        <FormInput class="!w-1/3" v-model="features.ga.value" placeholder="UA-00000 or G-xxxxx" />
        <FormButton :disabled="!features.ga.value" @click="savePlayerGAId">
          {{ featureProcessing.ga ? 'Saving' : 'Save' }}
        </FormButton>
      </div>
    </section>
    <section class="flex justify-between items-center !py-3">
      <div class="leading-4">
        <h4 class="text-base flex items-center">Publish this Folder on OTT app
          <ejs-tooltip class="inline"
            content="OTT app is an iOS and Android compatible mobile app with your branding that you can use to publish your livestreams and video-on-demand to audiences.">
            <icon-base class="ml-2">
              <icon-help />
            </icon-base>
          </ejs-tooltip>
        </h4>
        <template v-if="!ottProcessing && !features.ottEnabled.enabled">
          <p class="text-tiny text-surface-7" v-show="!isOtt">Status: <span class="text-white">Not activated.
              <FormButton customClasses="text-white underline" onclick="Intercom('show')">Contact us</FormButton> to
              activate or <a class="text-white underline"
                href="https://docs.castr.com/en/articles/5483773-introducing-castr-ott-mobile-app-for-video-streaming">Learn
                more</a> here
            </span></p>
          <p class="text-tiny text-surface-7" v-show="isOtt">Status: <span class="text-white">Not published. This {{
            (stream.type === 'vod') ? 'folder' : 'stream' }} is not visible on your OTT app.</span></p>
        </template>
        <p v-if="!ottProcessing && features.ottEnabled.enabled" class="text-tiny text-surface-7">Status: <span
            class="text-white">Published. This {{ (stream.type === 'vod') ? 'folder' : 'stream' }} is visible on your
            OTT
            app for all viewers.</span></p>
        <p v-if="ottProcessing" class="text-tiny text-surface-7">Status: <span class="text-white">Processing...</span>
        </p>
      </div>
      <FormSwitch @change="requestOttunpublish()" v-model="features.ottEnabled.enabled"
        :disabled="!isOtt || ottProcessing" />
    </section>
  </div>

  <alert-modal modal-id="embed-upload-format" message="Only valid image files are allowed. Please pick an image file" />

  <alert-modal :message="'Uploaded image too large. Image must not exceed ' +
    maxPosterUploadMB +
    'MB'
    " modal-id="embed-image-size-exceed" />

  <alert-modal modal-id="embed-image-mime-type" message="Only PNG/JPG image files are allowed" />
  <confirm-modal modal-id="unpublish-ott" :message="'Unpublish this ' +
    (stream.type === 'vod' ? 'folder' : 'stream') +
    ' on OTT app? Your audience wont be able to see this on your app anymore. Are you sure?'
    " ok-text="Yes, unpublish this" cancel-text="Cancel" @modal-confirm="toggleFeature('ottEnabled')"
    @modal-dismiss="toggleOttcancel()" />
</template>

<style scoped></style>
