<script>
export default {
  compatConfig: {
    MODE: 3,
    COMPONENT_V_MODEL: false,
  }
}
</script>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import PlayoutTableItem from './PlayoutTableItem.vue';
import PlayoutTableEmpty from './PlayoutTableEmpty.vue';

const store = useStore()

const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])
const playlist = computed(() => store.getters['Playout/playlist'])

const handleDragStart = (e) => {
  e.dataTransfer.setData('text/plain', e.currentTarget.dataset.index)
}
const handleDragEnter = (e) => {
  e.currentTarget.classList.add('hover')
}
const handleDragLeave = (e) => {
  e.currentTarget.classList.remove('hover')
}
const handleDragOver = (e) => {
  e.preventDefault()
}
const handleDrop = (e) => {
  e.preventDefault()
  const itemIndex = e.dataTransfer.getData('text/plain')
  const droppedIndex = e.target.closest('li').dataset.index
  Array.from(e.currentTarget.parentNode.children).map(tr => {
    tr.classList.remove('hover')
  })
  handleListChange(itemIndex, droppedIndex)
}

const handleListChange = (index, droppedIndex) => {
  const oldIndex = parseInt(index, 10)
  const newIndex = parseInt(droppedIndex, 10)
  store.dispatch('Playout/setNewOrder', {oldIndex, newIndex})
}

</script>

<template>
  <ul v-if="playlist?.length > 0" class="text-tiny divide-y divide-surface-3">
    <li class="py-2 text-surface-8 flex">
      <div class="truncate flex items-center" :class="playoutConfig?.mode === 'schedule' ? 'basis-5/12' : 'basis-9/12'">
        Content
      </div> 
      <div  v-if="playoutConfig?.mode == 'schedule'" class="basis-4/12">
        Start Date/Time
      </div>
      <div class="basis-2/12">
        Duration
      </div>
    </li>
    <li v-for="(playoutItem, index) in playlist" 
      :key="index" 
      :data-index="index"
      draggable="true"
      @dragstart="(event) => handleDragStart(event, playoutItem)"
      @dragenter="(event) => handleDragEnter(event, playoutItem)"
      @dragleave="(event) => handleDragLeave(event, playoutItem)"
      @dragover="(event) => handleDragOver(event, playoutItem)"
      @drop="(event) => handleDrop(event, playoutItem)"
    >
      <PlayoutTableItem 
        :item="playoutItem" 
        />
    </li>
  </ul>
  <PlayoutTableEmpty v-else />
</template>
<style scoped>
li[draggable="true"] {
  cursor: move;
  &.hover {
    opacity: 0.2;
  }
}
</style>