<template>
	<div class="mt-2">
		<div :class="{ 'webcam-wrapper': streamSourceType === SourceTypes.Webcam }" class="video-wrapper">
			<webcam-player v-if="streamSourceType === SourceTypes.Webcam" :stream="stream" :audioDevice="audioDevice"
				:videoDevice="videoDevice" class="video-thumb" @stream-stopped="onWebcamStopped"
				@stream-started="onWebcamStarted" @stream-authorized="onWebcamAuthorized" />
			<!-- <div v-if="!isAlive()" class="video-thumb placeholder"> -->
			<div v-else-if="!stream.enabled" class="video-thumb placeholder">
				<p class="text-center text-s-xl text-dark-7 text-500 mb-1">
					Disabled Stream
				</p>
				<small class="text-s-m text-dark-5">Please enable</small>
				<FormButton @click="showStreamToggleModal"
					:id="this.activeTeam && this.cannotToggleStream ? ('member-disabled_' + stream._id) : ''" size="sm"
					class="mt-4">
					Enable Stream
				</FormButton>
				<b-tooltip :target="'member-disabled_' + stream._id" placement="top" v-if="memberPermission">
					<span class="text-dark-8 text-small">
						You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
							:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
				</b-tooltip>
			</div>
			<div v-else-if="!streamAlive" class="video-thumb placeholder">
				<p v-if="streamType === 'ipcam'">No Camera Source</p>
				<p v-else-if="countdownDate && streamCountdownSecLeft"
					class="d-flex justify-content-center align-items-center flex-column">
					Stream will go live in <br>
					<CountDown :endDate="countdownDate" />
				</p>
				<p class="m-0" v-else>Waiting for stream</p>
			</div>

			<stream-player v-else-if="streamPreviewSpecs.type === 'mse'" :stream="stream" :stream-url="streamPreviewSpecs.url"
				:media-pulse="mediaPulse" class="video-thumb" />

			<stream-player-hls v-else-if="streamPreviewSpecs.type === 'hls'" :stream="stream"
				:stream-url="streamPreviewSpecs.url" :autoplay="true" :frameless="true" class="video-thumb" />
		</div>
		<!-- stream/preview configurations -->
		<div class="tab-area">
			<a class="tab-headers" @click.prevent="onSelectTab('source-setup')"
				:class="{ 'tab-selected': selectedPreviewTab === 'source-setup' }">Source Setup</a>
			<a class="tab-headers relative" @click.prevent="onSelectTab('playback')"
				:class="{ 'tab-selected': (selectedPreviewTab === 'playback') }">
				Playback
				<svg v-if="streamAlive && this.stream.enabled && (selectedPreviewTab != 'playback') && !playbackClicked"
					style="top: 10px; right: -4px;" class="absolute" width="20" height="20" viewBox="0 0 20 20" fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<circle opacity="0.1" cx="10" cy="10" r="10" fill="#65C374" />
					<circle opacity="0.2" cx="10" cy="10" r="8" fill="#65C374" />
					<circle opacity="0.3" cx="10" cy="10" r="6" fill="#65C374" />
					<circle cx="10" cy="10" r="4" fill="#65C374" />
				</svg>
			</a>
			<a class="tab-headers" @click.prevent="onSelectTab('monitoring')"
				:class="{ 'tab-selected': selectedPreviewTab === 'monitoring' }">Monitoring</a>
		</div>

		<!-- <FormButton v-b-toggle.setup customClasses="btn-sidebar-collapse flex items-center justify-between">
			<span>Stream Source Setup</span>
			<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 6L6 1L11 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</FormButton> -->

		<div v-if="failoverIngestToggleState && selectedPreviewTab === 'source-setup'" class="p-3 bg-dark-2" style="">
			<div class="d-flex align-items-center justify-content-between">
				<h4 class="text-s-l font-weight-normal">Advanced Encoder Flow Enabled</h4>
				<FormButton @click="navigateBackupPage" type="secondary" size="sm" class="mr-2">
					Go to Backup
				</FormButton>
			</div>
			<div class="mt-3 d-flex align-items-center justify-content-between">
				<div class="d-flex justify-content-between align-items-center w-100 mt-2">
					<span class="text-s-m text-dark-9">Primary Ingest</span>
					<b-badge v-if="primaryStreamAlive" variant="success" class="ml-3 badge_beta inline-flex">
						<svg width="12" height="12" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M4.42041 8C6.62955 8 8.42041 6.20914 8.42041 4C8.42041 1.79086 6.62955 0 4.42041 0C2.21127 0 0.42041 1.79086 0.42041 4C0.42041 6.20914 2.21127 8 4.42041 8Z"
								fill="currentColor" />
						</svg>
						Live
					</b-badge>
					<b-badge v-else variant="dark-3" class="badge_beta ml-auto inline-flex">
						<svg width="12" height="12" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M4.42041 8C6.62955 8 8.42041 6.20914 8.42041 4C8.42041 1.79086 6.62955 0 4.42041 0C2.21127 0 0.42041 1.79086 0.42041 4C0.42041 6.20914 2.21127 8 4.42041 8Z"
								fill="currentColor" />
						</svg>
						Offline
					</b-badge>
				</div>
			</div>

			<div class="mt-3 d-flex align-items-center justify-content-between">
				<div class="d-flex justify-content-between align-items-center w-100 mt-2">
					<span class="text-s-m text-dark-9">Backup Ingest</span>
					<b-badge v-if="backupStreamAlive" variant="success" class="ml-3 badge_beta inline-flex">
						<svg width="12" height="12" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M4.42041 8C6.62955 8 8.42041 6.20914 8.42041 4C8.42041 1.79086 6.62955 0 4.42041 0C2.21127 0 0.42041 1.79086 0.42041 4C0.42041 6.20914 2.21127 8 4.42041 8Z"
								fill="currentColor" />
						</svg>
						Live
					</b-badge>
					<b-badge v-else variant="dark-3" class="badge_beta ml-auto inline-flex">
						<svg width="12" height="12" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M4.42041 8C6.62955 8 8.42041 6.20914 8.42041 4C8.42041 1.79086 6.62955 0 4.42041 0C2.21127 0 0.42041 1.79086 0.42041 4C0.42041 6.20914 2.21127 8 4.42041 8Z"
								fill="currentColor" />
						</svg>
						Offline
					</b-badge>
				</div>
			</div>
			<hr class="mt-2">

			<div class="flex flex-wrap">
				<div class="w-full">
					<div v-if="!transcoderView" class="d-flex align-items-center justify-content-center mt-3">
						<FormButton id="RTMP-pull" v-if="streamAlive && isRtmpPullEnabled" size="sm"
							v-show="stream.type === 'restream' || stream.type === 'live'" @click="requestRTMPPullUrl()">
							<span v-if="rmptPullUrlProcessing">
								<Spinner text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)"
									spinner-size="15px" />

							</span>
							<span v-else>Get RTMP Pull</span>
						</FormButton>

						<div tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover :title="rtmpPullErrorMessage">
							<FormButton size="sm" disabled id="RTMP-pull"
								v-show="stream.type === 'restream' || stream.type === 'live'">
								<span v-if="rmptPullUrlProcessing">
									<Spinner text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)"
										spinner-size="15px" />
								</span>
								<span v-else>Get RTMP Pull</span>
							</FormButton>
						</div>

						<b-tooltip v-if="streamAlive" target="RTMP-pull">
							<span class="text-dark-8 test-s-s">Click to get your RTMP pull URL. Need static URL? <a target="blank"
									class="text-reset text-underline"
									href="https://docs.castr.io/en/articles/4851668-how-to-get-constant-rtmp-address-in-castr">Here’s
									how</a>.</span>
						</b-tooltip>

						<!-- srt pull -->
						<FormButton class="ml-2" id="SRT-pull" v-if="streamAlive && isRtmpPullEnabled" size="sm"
							v-show="stream.type === 'restream' || stream.type === 'live'" @click="requestSRTPullUrl()">
							<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
								spinner-color="var(--c-dark-8)" spinner-size="15px" />
							<span v-else>Get SRT Pull</span>
						</FormButton>
						<div class="ml-2" tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover
							:title="rtmpPullErrorMessage">
							<FormButton disabled class="" size="sm" v-show="stream.type === 'restream' || stream.type === 'live'">
								<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
									spinner-color="var(--c-dark-8)" spinner-size="15px" />
								<span v-else>Get SRT Pull</span>
							</FormButton>
						</div>
					</div>
				</div>
			</div>

			<div class="flex flex-wrap">
				<div class="w-full">
					<div class="d-flex align-items-center justify-content-center mt-3">
						<a href="https://docs.castr.com/en/articles/4825093-recommended-settings-for-encoders" target="_blank"
							class="d-inline-flex align-items-center justify-content-center btn btn-sm btn-link text-dark-7 px-0">
							<svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<circle cx="8" cy="8" r="7" stroke="currentColor" stroke-width="1.5" />
								<path
									d="M6 6.25V6C6 4.89543 6.89543 4 8 4H8.08033C9.14054 4 10 4.86888 10 5.92909V5.92909C10 6.71975 9.50955 7.43392 8.76923 7.71154V7.71154C8.30653 7.88505 8 8.32738 8 8.82154V9"
									stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<circle cx="8" cy="11.5" r="1" fill="currentColor" />
							</svg>
							<span>Recommended settings</span>
						</a>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!failoverIngestToggleState" class="p-3 bg-dark-2"
			v-show='selectedPreviewTab === "source-setup" && !isTransitioning' id="setup">
			<div class="flex flex-wrap">
				<div class="w-full relative">
					<div v-if="shouldShowDeploymentRegion" class="region mb-3">
						<StreamRegion :stream="stream" @switch-stream-region="switchStreamRegion" fullWidth show-alert />
					</div>

					<div v-else class="region">
						<b-img-lazy width="20" v-if="shouldShowDeploymentRegion" :src="regionFlag" :alt="stream.uiRegion.name"
							class="region-flag" />
						<i v-else class="fas fa-globe preview-pane-icon" />
						<span v-if="shouldShowDeploymentRegion">{{ stream.uiRegion.name }}</span>
					</div>
				</div>
			</div>
			<div class="flex flex-wrap" v-if="!transcoderView">
				<div class="w-full">
					<div class="d-flex align-items-center justify-content-between w-100 mb-1">
						<b-form-group v-if="shouldShowDeploymentRegion" class="w-100 bg-dark-3">
							<b-form-radio-group class="btn-group_spacing w-100" size="md" v-model="streamSourceType" buttons
								button-variant="dark-3" @input="onSourceTypeChange">
								<b-form-radio :value="SourceTypes.Publish" :disabled="streamSourceTypeProcessing">Publish</b-form-radio>
								<b-form-radio v-if="!transcoderView" :value="SourceTypes.Pull"
									:disabled="streamSourceTypeProcessing">Pull</b-form-radio>
								<b-form-radio v-if="!transcoderView" :value="SourceTypes.Webcam"
									:disabled="streamSourceTypeProcessing">Webcam</b-form-radio>
								<b-form-radio v-if="!transcoderView && stream.type === 'live'" :value="SourceTypes.Linked"
									:disabled="streamSourceTypeProcessing">Linked</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
						<!-- @todo move to component, inline styling fix -->
					</div>
				</div>
			</div>

			<!-- Dropdown for Linked Source -->
			<div class="flex flex-wrap">
				<div class="w-full relative">
					<div v-show="stream.type === 'live' && streamSourceType === SourceTypes.Linked" class="mb-3 region">
						<div class="flex items-center mb-2">
							<label class="mb-0" for="sourceSelect">Select Source</label>
							<ejs-tooltip v-if="selectedLinkedSource && !selectedLinkedSource.enabled" content="Source is disabled">
								<icon-base class="mx-2 text-warning" fill="none">
									<icon-info />
								</icon-base>
							</ejs-tooltip>
						</div>
						<SelectLinkedSource :class="{'border border-warning rounded-md': selectedLinkedSource && !selectedLinkedSource.enabled}" :stream="stream" @selected-source="selectedSource"
							@init-linked-source="initSelectedSource" fullWidth show-alert />
						<b-button class="mt-3" variant="primary" size="sm" @click="setLinkedSource"
							:disabled="!selectedSource || sourceSet">Set as Source</b-button>
					</div>
				</div>
			</div>

			<!-- Webcam source tabs -->
			<div class="flex flex-wrap" v-if="!mobile">
				<div class="w-full">
					<b-tabs v-if="audioDevices.length && (!hasPullSource) && (streamSourceType === SourceTypes.Webcam)"
						class="tabs-light">
						<!-- Default sound tab -->
						<b-tab active>
							<template #title>
								Sound
							</template>
							<div class="flex flex-wrap">
								<div class="w-full mt-2">
									<div class="text-s-s text-dark-8 mt-2 md-2 flex items-center" for="">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M5.83337 6.66667C5.83337 4.36548 7.69885 2.5 10 2.5C12.3012 2.5 14.1667 4.36548 14.1667 6.66667V9.16667C14.1667 11.4679 12.3012 13.3333 10 13.3333C7.69885 13.3333 5.83337 11.4679 5.83337 9.16667V6.66667Z"
												stroke="white" stroke-width="1.2" />
											<path d="M9.16671 6.66666H10.8334" stroke="white" stroke-width="1.2" stroke-linecap="round" />
											<path d="M8.33329 9.16666L11.6666 9.16666" stroke="white" stroke-width="1.2"
												stroke-linecap="round" />
											<path
												d="M16.6667 8.33334V9.16668C16.6667 12.8486 13.6819 15.8333 10 15.8333C6.31814 15.8333 3.33337 12.8486 3.33337 9.16668V8.33334"
												stroke="white" stroke-width="1.2" stroke-linecap="round" />
											<path d="M10 15.8333V18.3333" stroke="white" stroke-width="1.2" stroke-linecap="round" />
										</svg>
										<span class="ml-1">Input device</span>
									</div>
									<b-dropdown id="webcam-dropdown" variant="dark-3" size="md" menu-class="dropdown-menu_md mw-100"
										class="w-100 mt-2" toggle-class="justify-content-between">
										<template #button-content>
											<span class="text-truncate flex items-center">
												<span class="text-capitalize">{{ defaultAudioDevice.label }}</span>
											</span>
										</template>
										<b-dropdown-item v-for="(device, index) in audioDevices" :key="index"
											@click="setDefaultAudioDevice(device)" :disabled="streamAlive">
											<span class="text-capitalize">{{ device.label }}</span>
											<div v-if="device.label === defaultAudioDevice.label" class="ml-auto inline-flex">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
														d="M13.9244 4.42426C13.9244 4.42427 13.9244 4.42428 13.9244 4.42429L13.9243 4.42432L13.9239 4.42471L13.9221 4.42658L13.9141 4.43462L13.8814 4.4676C13.8524 4.49697 13.8091 4.54096 13.7529 4.59857C13.6406 4.71381 13.4766 4.88354 13.2718 5.09996C12.862 5.53291 12.2893 6.15206 11.6391 6.89512C10.3354 8.38502 8.73419 10.3574 7.50876 12.318C7.40813 12.479 7.23723 12.5828 7.04798 12.598C6.85873 12.6132 6.67345 12.538 6.54843 12.3951L3.04843 8.39507C2.83022 8.14568 2.85549 7.76663 3.10487 7.54842C3.35425 7.33021 3.73331 7.35548 3.95152 7.60486L6.92732 11.0058C8.12691 9.2 9.56096 7.44783 10.736 6.10491C11.3984 5.34798 11.9819 4.71714 12.4003 4.27509C12.6095 4.05401 12.7776 3.87999 12.8937 3.76085C12.9518 3.70128 12.9969 3.65542 13.0277 3.62425L13.063 3.58863L13.0723 3.5793L13.0748 3.5768L13.0755 3.5761L13.0757 3.57588C13.0758 3.57581 13.0758 3.57576 13.5001 4.00002L13.0758 3.57576C13.3101 3.34145 13.69 3.34145 13.9244 3.57576C14.1587 3.81006 14.1587 4.18994 13.9244 4.42426Z"
														fill="#65C374" />
												</svg>
											</div>
										</b-dropdown-item>
									</b-dropdown>
								</div>
							</div>
						</b-tab>
						<!-- Camera tab -->
						<b-tab>
							<template #title>
								Camera
							</template>
							<div class="flex flex-wrap">
								<div class="w-full mt-2">
									<div class="text-s-s text-dark-8 mt-2 flex items-center" for="">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M14.1667 7.91666L14.7154 7.64235C16.337 6.83156 17.1477 6.42617 17.7406 6.79256C18.3334 7.15895 18.3334 8.06544 18.3334 9.87842V10.1216C18.3334 11.9346 18.3334 12.841 17.7406 13.2074C17.1477 13.5738 16.337 13.1684 14.7154 12.3576L14.1667 12.0833V7.91666Z"
												stroke="white" stroke-width="1.2" />
											<path
												d="M11.3006 6.19946C11.7888 6.68762 11.7888 7.47907 11.3006 7.96723C10.8125 8.45538 10.021 8.45538 9.53286 7.96723C9.04471 7.47907 9.04471 6.68762 9.53286 6.19946C10.021 5.7113 10.8125 5.7113 11.3006 6.19946Z"
												stroke="white" stroke-width="1.2" />
											<path
												d="M1.66675 9.58334C1.66675 6.84378 1.66675 5.47399 2.42338 4.55203C2.56189 4.38325 2.71666 4.22849 2.88544 4.08998C3.8074 3.33334 5.17718 3.33334 7.91675 3.33334C10.6563 3.33334 12.0261 3.33334 12.9481 4.08998C13.1168 4.22849 13.2716 4.38325 13.4101 4.55203C14.1667 5.47399 14.1667 6.84378 14.1667 9.58334V10.4167C14.1667 13.1562 14.1667 14.526 13.4101 15.448C13.2716 15.6168 13.1168 15.7715 12.9481 15.91C12.0261 16.6667 10.6563 16.6667 7.91675 16.6667C5.17718 16.6667 3.8074 16.6667 2.88544 15.91C2.71666 15.7715 2.56189 15.6168 2.42338 15.448C1.66675 14.526 1.66675 13.1562 1.66675 10.4167V9.58334Z"
												stroke="white" stroke-width="1.2" />
										</svg>

										<span class="ml-1">Camera</span>
									</div>
									<b-dropdown id="webcam-dropdown" variant="dark-3" size="md" menu-class="dropdown-menu_md"
										class="w-100 mt-2" toggle-class="justify-content-between">
										<template #button-content>
											<span class="text-truncate flex items-center">
												<span class="text-capitalize">{{ defaultVideoDevice.label }}</span>
											</span>
										</template>
										<b-dropdown-item v-for="(device, index) in videoDevices" :key="index"
											@click="setDefaultVideoDevice(device)" :disabled="streamAlive">
											<span class="text-capitalize">{{ device.label }}</span>
											<div v-if="device.label === defaultVideoDevice.label" class="ml-auto inline-flex">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
														d="M13.9244 4.42426C13.9244 4.42427 13.9244 4.42428 13.9244 4.42429L13.9243 4.42432L13.9239 4.42471L13.9221 4.42658L13.9141 4.43462L13.8814 4.4676C13.8524 4.49697 13.8091 4.54096 13.7529 4.59857C13.6406 4.71381 13.4766 4.88354 13.2718 5.09996C12.862 5.53291 12.2893 6.15206 11.6391 6.89512C10.3354 8.38502 8.73419 10.3574 7.50876 12.318C7.40813 12.479 7.23723 12.5828 7.04798 12.598C6.85873 12.6132 6.67345 12.538 6.54843 12.3951L3.04843 8.39507C2.83022 8.14568 2.85549 7.76663 3.10487 7.54842C3.35425 7.33021 3.73331 7.35548 3.95152 7.60486L6.92732 11.0058C8.12691 9.2 9.56096 7.44783 10.736 6.10491C11.3984 5.34798 11.9819 4.71714 12.4003 4.27509C12.6095 4.05401 12.7776 3.87999 12.8937 3.76085C12.9518 3.70128 12.9969 3.65542 13.0277 3.62425L13.063 3.58863L13.0723 3.5793L13.0748 3.5768L13.0755 3.5761L13.0757 3.57588C13.0758 3.57581 13.0758 3.57576 13.5001 4.00002L13.0758 3.57576C13.3101 3.34145 13.69 3.34145 13.9244 3.57576C14.1587 3.81006 14.1587 4.18994 13.9244 4.42426Z"
														fill="#65C374" />
												</svg>
											</div>
										</b-dropdown-item>
									</b-dropdown>
								</div>
							</div>
						</b-tab>
					</b-tabs>
				</div>
			</div>
			<div class="flex flex-wrap" v-if="(streamSourceType === SourceTypes.Webcam) && mobile">
				<div class="w-full">
					<div class="text-center py-2">
						<img class="d-inline-block mx-auto mb-2" src="@/assets/images/no-access.svg" alt="No Access">
						<p class="text-s-ss text-dark-8 text-400">Webcam access feature <br>is not available on mobile.</p>
					</div>
				</div>
			</div>

			<div class="flex flex-wrap">
				<div class="w-full">
					<b-tabs
						v-if="(!hasPullSource) && (streamSourceType !== SourceTypes.Webcam) && (streamSourceType !== SourceTypes.Linked)"
						class="tabs-light">
						<b-tab active>
							<template #title>
								RTMP
							</template>
							<div class="flex flex-wrap mt-3">
								<div class="w-full">
									<label v-if="failoverIgestActive && this.hasFailoverIngestFeature"><strong>Primary
											RTMP</strong></label>
									<b-input-group size="md"
										v-if="shouldShowDeploymentRegion && streamSourceType === SourceTypes.Publish">
										<FormInput class="w-[1%] flex-grow" :modelValue="getStreamPushUrl()"
											@click="doCopy(getStreamPushUrl())" readonly />
										<b-input-group-append>
											<FormButton class="relative z-10" isLink isIcon type="link" size="sm"
												@click="doCopy(getStreamPushUrl())">
												<icon-base class="" fill="none">
													<icon-copy />
												</icon-base>
											</FormButton>
										</b-input-group-append>
									</b-input-group>
								</div>
							</div>
							<div class="flex flex-wrap">
								<div class="w-full">
									<div class="flex flex-wrap">
										<div class="w-7/12">
											<label class="mt-3">Streaming Key</label>
										</div>
										<div class="w-5/12" id="reset-password-tooltip" v-if="stream.type === 'live'">
											<b-button v-bind:disabled="streamAlive" size="sm" variant="link" @click="resetStreamPassword()">
												<span class="mt-4 text-dark-9 text-s-s">Reset Password</span>
											</b-button>
											<b-tooltip triggers="hover" target="reset-password-tooltip" v-if="streamAlive">
												<div class="text-dark-8 test-s-s">
													Password cannot be reset as stream is live now
												</div>
											</b-tooltip>
										</div>
									</div>
									<div class="flex flex-wrap">
										<div class="flex-grow">
											<b-input-group size="md">
												<FormInput v-if="streamKeyVisible" class="w-[1%] flex-grow" :modelValue="streamKey"
													@click="doCopy(streamKey)" readonly />
												<FormInput v-if="!streamKeyVisible" class="w-[1%] flex-grow" :modelValue="'xxxxxxxxxxxxxxxxx'"
													readonly />
												<b-input-group-append>
													<FormButton class="relative z-10" isLink isIcon type="link" size="sm"
														@click="toggleStreamKeyVisibility">
														<span v-if="streamKeyVisible" class="show-counter absolute text-c-dark-7 text-xs">({{
															streamKeyVisibleTimeout / 1000 }})</span>
														<icon-base class="" fill="none">
															<IconEye v-if="!streamKeyVisible" />
															<IconEyeCrossed v-else />
														</icon-base>
													</FormButton>
													<FormButton class="relative z-10" isLink isIcon type="link" size="sm"
														@click="doCopy(streamKey)">
														<icon-base class="" fill="none">
															<icon-copy />
														</icon-base>
													</FormButton>
												</b-input-group-append>
											</b-input-group>
										</div>
										<div class="w-3/12 flex-center" v-if="stream.type === 'live' && !transcoderView">
											<span v-b-tooltip.hover.html id="live-password-disable-tooltip">
												<b-form-checkbox @change="togglePasswordCheck" v-model="isPasswordEnabled"
													:disabled="isAnyBackupStreamLive" class="backup_switch password-toggle"
													:class="{ 'inactive': isAnyBackupStreamLive }" switch>
												</b-form-checkbox>
											</span>
											<b-tooltip target="live-password-disable-tooltip" :delay="{ show: 250, hide: 80 }">
												<div class="text-dark-8 test-s-s"
													v-if="(isAnyBackupStreamLive && this.hasFailoverIngestFeature)">
													Turn off the stream to change this.
												</div>
												<div class="text-dark-8 test-s-s" v-else>
													If your encoder doesn't support passing query parameters, the password option can be disabled
													here.
												</div>
											</b-tooltip>
										</div>
									</div>
									<FormButton @click="doCopy(getStreamURLAndKey())" type="secondary" size="md"
										class="mt-3 w-full justify-center sm:hidden">
										Copy Stream URL & Key as One URL
									</FormButton>
									<div v-if="!transcoderView" class="d-flex align-items-center justify-content-start mt-3">
										<FormButton id="RTMP-pull" v-if="streamAlive && isRtmpPullEnabled" size="sm"
											v-show="stream.type === 'restream' || stream.type === 'live'" @click="requestRTMPPullUrl()">
											<span v-if="rmptPullUrlProcessing">
												<Spinner text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)"
													spinner-size="15px" />

											</span>
											<span v-else>Get RTMP Pull</span>
										</FormButton>

										<div tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover
											:title="rtmpPullErrorMessage">
											<FormButton size="sm" disabled id="RTMP-pull"
												v-show="stream.type === 'restream' || stream.type === 'live'">
												<span v-if="rmptPullUrlProcessing">
													<Spinner text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)"
														spinner-size="15px" />
												</span>
												<span v-else>Get RTMP Pull</span>
											</FormButton>
										</div>

										<b-tooltip v-if="streamAlive" target="RTMP-pull">
											<span class="text-dark-8 test-s-s">Click to get your RTMP pull URL. Need static URL? <a
													target="blank" class="text-reset text-underline"
													href="https://docs.castr.io/en/articles/4851668-how-to-get-constant-rtmp-address-in-castr">Here’s
													how</a>.</span>
										</b-tooltip>

										<!-- srt pull -->
										<FormButton class="ml-2" id="SRT-pull" v-if="streamAlive && isRtmpPullEnabled" size="sm"
											v-show="stream.type === 'restream' || stream.type === 'live'" @click="requestSRTPullUrl()">
											<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
												spinner-color="var(--c-dark-8)" spinner-size="15px" />
											<span v-else>Get SRT Pull</span>
										</FormButton>
										<div class="ml-2" tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover
											:title="rtmpPullErrorMessage">
											<FormButton disabled class="" size="sm"
												v-show="stream.type === 'restream' || stream.type === 'live'">
												<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
													spinner-color="var(--c-dark-8)" spinner-size="15px" />
												<span v-else>Get SRT Pull</span>
											</FormButton>
										</div>
									</div>
								</div>
							</div>
							<div class="flex flex-wrap">
								<div class="w-full">
									<div class="d-flex align-items-center justify-content-center mt-3">
										<a href="https://docs.castr.com/en/articles/4825093-recommended-settings-for-encoders"
											target="_blank"
											class="d-inline-flex align-items-center justify-content-center btn btn-sm btn-link text-dark-7 px-0">
											<svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<circle cx="8" cy="8" r="7" stroke="currentColor" stroke-width="1.5" />
												<path
													d="M6 6.25V6C6 4.89543 6.89543 4 8 4H8.08033C9.14054 4 10 4.86888 10 5.92909V5.92909C10 6.71975 9.50955 7.43392 8.76923 7.71154V7.71154C8.30653 7.88505 8 8.32738 8 8.82154V9"
													stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												<circle cx="8" cy="11.5" r="1" fill="currentColor" />
											</svg>
											<span>Recommended settings</span>
										</a>
									</div>
								</div>
							</div>

							<div class="flex flex-wrap" v-if="failoverIgestActive">
								<div class="w-full">
									<b-alert show variant="secondary" class="text-dark-7 text-s-s d-flex p-2 mt-3">
										<svg data-v-3503005c="" width="14" height="15" viewBox="0 0 14 15" fill="none"
											xmlns="http://www.w3.org/2000/svg" class="mr-2 mt-1 flex-shrink-0">
											<path data-v-3503005c="" fill-rule="evenodd" clip-rule="evenodd"
												d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z"
												fill="currentColor"></path>
										</svg>
										<div v-if="this.hasFailoverIngestFeature">
											Avoid failed stream with backup URL. Enabled ABR is recommended. <a class="text-reset"
												href="https://docs.castr.com/en/articles/5023371-rtmp-backup-ingest-how-to-use-benefits-and-limitations"
												target="_blank"><u>Learn more</u></a>
										</div>
									</b-alert>
									<label class="">Backup RTMP</label>
									<div :class="{
										'input-container': true,
										'disabled-container': !this.hasFailoverIngestFeature
									}">

										<b-input-group class="mb-3" size="md" v-if="shouldShowDeploymentRegion">
											<FormInput class="w-[1%] flex-grow" :modelValue="getStreamPushUrl(true)" readonly
												:disabled="!this.hasFailoverIngestFeature" @click="doCopy(getStreamPushUrl(true))" />
											<b-input-group-append>
												<FormButton :disabled="!this.hasFailoverIngestFeature" readonly class="relative z-10" isLink
													isIcon type="link" size="sm" @click="doCopy(getStreamPushUrl(true))">
													<icon-base class="" fill="none">
														<icon-copy />
													</icon-base>
												</FormButton>
											</b-input-group-append>
										</b-input-group>
									</div>
									<label>Backup Streaming Key</label>
									<div :class="{
										'input-container': true,
										'disabled-container': !this.hasFailoverIngestFeature
									}">
										<b-input-group size="md">
											<FormInput v-if="streamKeyVisible2" class="w-[1%] flex-grow" v-model="streamKey2"
												:modelValue="getStreamPushUrl(true)" :disabled="!this.hasFailoverIngestFeature" readonly
												@click="doCopy(streamKey2)" />
											<FormInput :disabled="!this.hasFailoverIngestFeature" readonly v-if="!streamKeyVisible2"
												class="w-[1%] flex-grow" modelValue="xxxxxxxxxxxxxxxxx" />
											<b-input-group-append>
												<FormButton class="relative z-10" isLink isIcon type="link" size="sm"
													@click="toggleStreamKeyVisibility2">
													<span v-if="streamKeyVisible2" class="show-counter absolute text-c-dark-7 text-xs">({{
														streamKeyVisibleTimeout2 / 1000 }})</span>
													<icon-base class="" fill="none">
														<IconEye v-if="!streamKeyVisible2" />
														<IconEyeCrossed v-else />
													</icon-base>
												</FormButton>
												<FormButton :disabled="!this.hasFailoverIngestFeature" class="relative z-10" isLink isIcon
													type="link" size="sm" @click="doCopy(streamKey2)">
													<icon-base class="" fill="none">
														<icon-copy />
													</icon-base>
												</FormButton>
											</b-input-group-append>
										</b-input-group>
									</div>
									<p class="text-s-s text-dark-7 mb-0 mt-2">Note: this key is different from the primary key</p>
								</div>
							</div>
						</b-tab>

						<b-tab>
							<template #title>
								SRT
							</template>
							<div v-if="streamType === 'restream' || streamType === 'live'">
								<div class="flex flex-wrap pt-3">
									<div class="w-full">
										<div class="flex flex-wrap">
											<div class="flex-grow">
												<b-input-group size="md"
													v-if="shouldShowDeploymentRegion && streamSourceType === SourceTypes.Publish">
													<FormInput class="w-[1%] flex-grow" :modelValue="streamKeySRT" @click="doCopy(streamKeySRT)"
														readonly />
													<b-input-group-append>
														<FormButton class="relative top-1 z-10" isLink isIcon type="link" size="sm"
															@click="doCopy(streamKeySRT)">
															<icon-base class="" fill="none">
																<icon-copy />
															</icon-base>
														</FormButton>
													</b-input-group-append>
												</b-input-group>
											</div>

											<div class="w-2/12 flex-center" v-if="stream.type === 'live' && !transcoderView">
												<span v-b-tooltip.hover.html id="live-password-disable-tooltip-srt">
													<b-form-checkbox @change="togglePasswordCheck" v-model="isPasswordEnabled"
														:disabled="isAnyBackupStreamLive" class="backup_switch password-toggle"
														:class="{ 'inactive': isAnyBackupStreamLive }" switch>
													</b-form-checkbox>
												</span>
												<b-tooltip target="live-password-disable-tooltip-srt" :delay="{ show: 250, hide: 80 }">
													<div class="text-dark-8 test-s-s" v-if="isAnyBackupStreamLive">
														Turn off the stream to change this.
													</div>
													<div class="text-dark-8 test-s-s" v-else>
														If your encoder doesn't support passing query parameters, the password option can be
														disabled
														here.
													</div>
												</b-tooltip>
											</div>


										</div>

										<!-- pull buttons -->
										<div v-if="!transcoderView" class="d-flex align-items-center justify-content-start mt-3">
											<FormButton size="sm" id="RTMP-pull" v-if="streamAlive && isRtmpPullEnabled"
												v-show="stream.type === 'restream' || stream.type === 'live'" @click="requestRTMPPullUrl()">
												<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
													spinner-color="var(--c-dark-8)" spinner-size="15px" />
												<span v-else>Get RTMP Pull</span>
											</FormButton>
											<div tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover
												:title="rtmpPullErrorMessage">
												<FormButton size="sm" disabled v-show="stream.type === 'restream' || stream.type === 'live'">
													<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
														spinner-color="var(--c-dark-8)" spinner-size="15px" />
													<span v-else>Get RTMP Pull</span>
												</FormButton>
											</div>

											<b-tooltip v-if="streamAlive" target="RTMP-pull">
												<span class="text-dark-8 test-s-s">Click to get your RTMP pull URL. Need static URL? <a
														target="blank" class="text-reset text-underline"
														href="https://docs.castr.io/en/articles/4851668-how-to-get-constant-rtmp-address-in-castr">Here’s
														how</a>.</span>
											</b-tooltip>

											<!-- srt pull -->
											<FormButton size="sm" id="SRT-pull" v-if="streamAlive && isRtmpPullEnabled" class="ml-2"
												v-show="stream.type === 'restream' || stream.type === 'live'" @click="requestSRTPullUrl()">
												<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
													spinner-color="var(--c-dark-8)" spinner-size="15px" />
												<span v-else>Get SRT Pull</span>
											</FormButton>
											<div class="ml-2" tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover
												:title="rtmpPullErrorMessage">
												<FormButton size="sm" disabled class="ml-2"
													v-show="stream.type === 'restream' || stream.type === 'live'">
													<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
														spinner-color="var(--c-dark-8)" spinner-size="15px" />
													<span v-else>Get SRT Pull</span>
												</FormButton>
											</div>

										</div>
										<!-- SRT split -->
										<div class="split-box" v-if="showSRT">
											<label class="mt-3 mb-2 text-dark-8">Hostname</label>
											<b-input-group size="md text-dark-8">
												<FormInput class="w-[1%] flex-grow" :modelValue="SRTHostname" @click="doCopy(SRTHostname)"
													readonly />
												<b-input-group-append>
													<FormButton class="relative z-10" isLink isIcon type="link" size="sm"
														@click="doCopy(SRTHostname)">
														<icon-base class="" fill="none">
															<icon-copy />
														</icon-base>
													</FormButton>
												</b-input-group-append>
											</b-input-group>
											<label class="mt-3 mb-2 text-dark-8">Port</label>
											<b-input-group size="md text-dark-8">
												<FormInput class="w-[1%] flex-grow" :modelValue="SRTPort" @click="doCopy(SRTPort)" readonly />
												<b-input-group-append>
													<FormButton class="relative z-10" isLink isIcon type="link" size="sm"
														@click="doCopy(SRTPort)">
														<icon-base class="" fill="none">
															<icon-copy />
														</icon-base>
													</FormButton>
												</b-input-group-append>
											</b-input-group>
											<label class="mt-3 mb-2 text-dark-8">Stream ID</label>
											<b-input-group size="md text-dark-8">
												<FormInput class="w-[1%] flex-grow" :modelValue="SRTID" @click="doCopy(SRTID)" readonly />
												<b-input-group-append>
													<FormButton class="relative z-10" isLink isIcon type="link" size="sm" @click="doCopy(SRTID)">
														<icon-base class="" fill="none">
															<icon-copy />
														</icon-base>
													</FormButton>
												</b-input-group-append>
											</b-input-group>
										</div>
										<div>
											<p class="text-dark-8 text-small text-center w-100 pt-3 mb-0 position-relative srt-split"
												@click="showSRT = !showSRT">
												<span class="d-inline-block px-2 position-relative dropdown-toggle"
													:class="{ 'toggled': showSRT }">{{
														showSRT ? 'Hide ' : 'Show ' }} Separate Fields</span>
											</p>
										</div>
									</div>
								</div>
								<div class="flex flex-wrap">
									<div class="w-full">
										<div class="d-flex align-items-center justify-content-center mt-3">
											<a href="https://docs.castr.com/en/articles/4825093-recommended-settings-for-encoders"
												target="_blank"
												class="d-inline-flex align-items-center justify-content-center btn btn-sm btn-link text-dark-7 px-0">
												<svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<circle cx="8" cy="8" r="7" stroke="currentColor" stroke-width="1.5" />
													<path
														d="M6 6.25V6C6 4.89543 6.89543 4 8 4H8.08033C9.14054 4 10 4.86888 10 5.92909V5.92909C10 6.71975 9.50955 7.43392 8.76923 7.71154V7.71154C8.30653 7.88505 8 8.32738 8 8.82154V9"
														stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<circle cx="8" cy="11.5" r="1" fill="currentColor" />
												</svg>
												<span>Recommended settings</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</b-tab>

						<b-tab>
							<template #title>
								WHIP
								<!-- <b-badge variant="dark-3" class="badge_beta ml-2">beta</b-badge> -->
							</template>
							<div v-if="streamType === 'restream' || streamType === 'live'">
								<div class="flex flex-wrap pt-3">
									<div class="w-full">
										<div class="flex flex-wrap">
											<div class="flex-grow">
												<b-input-group size="md"
													v-if="shouldShowDeploymentRegion && streamSourceType === SourceTypes.Publish">
													<FormInput class="w-[1%] flex-grow" :modelValue="streamKeyWHIP" @click="doCopy(streamKeyWHIP)"
														readonly />
													<b-input-group-append>
														<FormButton class="relative z-10" isLink isIcon type="link" size="sm"
															@click="doCopy(streamKeyWHIP)">
															<icon-base class="" fill="none">
																<icon-copy />
															</icon-base>
														</FormButton>
													</b-input-group-append>
												</b-input-group>
											</div>

											<div class="w-2/12 flex-center" v-if="stream.type === 'live' && !transcoderView">
												<span v-b-tooltip.hover.html id="live-password-disable-tooltip-whip">
													<b-form-checkbox @change="togglePasswordCheck" v-model="isPasswordEnabled"
														:disabled="isAnyBackupStreamLive" class="backup_switch password-toggle"
														:class="{ 'inactive': isAnyBackupStreamLive }" switch>
													</b-form-checkbox>
												</span>
												<b-tooltip target="live-password-disable-tooltip-whip" :delay="{ show: 250, hide: 80 }">
													<div class="text-dark-8 test-s-s" v-if="isAnyBackupStreamLive">
														Turn off the stream to change this.
													</div>
													<div class="text-dark-8 test-s-s" v-else>
														If your encoder doesn't support passing query parameters, the password option can be
														disabled
														here.
													</div>
												</b-tooltip>
											</div>

										</div>

										<!-- pull buttons -->
										<div v-if="!transcoderView" class="d-flex align-items-center justify-content-start mt-3">
											<FormButton size="sm" id="RTMP-pull" v-if="streamAlive && isRtmpPullEnabled"
												v-show="stream.type === 'restream' || stream.type === 'live'" @click="requestRTMPPullUrl()">
												<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
													spinner-color="var(--c-dark-8)" spinner-size="15px" />
												<span v-else>Get RTMP Pull</span>
											</FormButton>
											<div tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover
												:title="rtmpPullErrorMessage">
												<FormButton disabled size="sm" id="RTMP-pull"
													v-show="stream.type === 'restream' || stream.type === 'live'">
													<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
														spinner-color="var(--c-dark-8)" spinner-size="15px" />
													<span v-else>Get RTMP Pull</span>
												</FormButton>
											</div>
											<b-tooltip v-if="streamAlive" target="RTMP-pull">
												<span class="text-dark-8 test-s-s">Click to get your RTMP pull URL. Need static URL? <a
														target="blank" class="text-reset text-underline"
														href="https://docs.castr.io/en/articles/4851668-how-to-get-constant-rtmp-address-in-castr">Here’s
														how</a>.</span>
											</b-tooltip>

											<!-- srt pull -->
											<FormButton id="SRT-pull" v-if="streamAlive && isRtmpPullEnabled" class="ml-2"
												v-show="stream.type === 'restream' || stream.type === 'live'" @click="requestSRTPullUrl()">
												<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
													spinner-color="var(--c-dark-8)" spinner-size="15px" />
												<span v-else>Get SRT Pull</span>
											</FormButton>
											<div class="ml-2" tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover
												:title="rtmpPullErrorMessage">
												<FormButton disabled class="ml-2" size="sm"
													v-show="stream.type === 'restream' || stream.type === 'live'">
													<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
														spinner-color="var(--c-dark-8)" spinner-size="15px" />
													<span v-else>Get SRT Pull</span>
												</FormButton>
											</div>
										</div>
									</div>
								</div>
								<div class="flex flex-wrap">
									<div class="w-full">
										<div class="d-flex align-items-center justify-content-center mt-3">
											<a href="https://docs.castr.com/en/articles/4825093-recommended-settings-for-encoders"
												target="_blank"
												class="d-inline-flex align-items-center justify-content-center btn btn-sm btn-link text-dark-7 px-0">
												<svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<circle cx="8" cy="8" r="7" stroke="currentColor" stroke-width="1.5" />
													<path
														d="M6 6.25V6C6 4.89543 6.89543 4 8 4H8.08033C9.14054 4 10 4.86888 10 5.92909V5.92909C10 6.71975 9.50955 7.43392 8.76923 7.71154V7.71154C8.30653 7.88505 8 8.32738 8 8.82154V9"
														stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<circle cx="8" cy="11.5" r="1" fill="currentColor" />
												</svg>
												<span>Recommended settings</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</b-tab>
					</b-tabs>
				</div>
			</div>

			<div v-if="hasPullSource">
				<div class="flex flex-wrap">
					<div class="w-full flex justify-between">
						<div>
							<label>{{ streamType === 'ipcam' ? 'Camera source' : 'Pull Source' }}</label>
						</div>
						<div v-if="stream.enabled && stream.pullUrl" class="text-right">
							<code v-if="streamAlive" class="platform-connect-status" style="color:#1d87d2;">connected</code>
							<code v-else class="platform-connect-status">connecting..</code>
						</div>
					</div>
				</div>
				<div class="input-container">
					<FormInput class="mb-1" v-model="streamPullUrl" :isError="!isPullSourceValid" placeholder="specify source url"
						@blur="pullSourceValidation" @keypress="onPullUrlChange()" />
				</div>
			</div>

			<!-- <div v-else-if="streamSourceType === SourceTypes.Webcam" class="field-container" style="padding:0;" /> -->
			<div v-else-if="streamSourceType !== SourceTypes.Webcam" class="double-btn">
				<div v-if="activeIngestTypeTab === 'rtmp'">
					<div v-if="failoverIgestActive">
						<FormButton size="md" v-show="stream.type === 'restream'" @click="requestRTMPPullUrl()">
							<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
								spinner-color="var(--c-dark-8)" spinner-size="15px" />
							<span v-else>Get RTMP Pull</span>
						</FormButton>
					</div>
				</div>

			</div>
			<div v-if="hasPullSource" class="text-s-s mb-2 flex" :class="isPullSourceValid ? 'text-dark-7' : 'color-danger'">
				<svg v-if="!isPullSourceValid" width="16" height="15" viewBox="0 0 16 15" fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd"
						d="M5.36963 3.79666C6.53114 1.6786 7.1119 0.619568 8.00006 0.619568C8.88823 0.619568 9.46898 1.6786 10.6305 3.79666L14.0639 10.0575C15.15 12.0381 15.6931 13.0285 15.2575 13.7642C14.8218 14.5 13.6923 14.5 11.4334 14.5H4.56671C2.30779 14.5 1.17833 14.5 0.742676 13.7642C0.307027 13.0285 0.850109 12.0381 1.93627 10.0575L5.36963 3.79666ZM8.00002 4.74998C8.41424 4.74998 8.75002 5.08577 8.75002 5.49998V7.99998C8.75002 8.41419 8.41424 8.74998 8.00002 8.74998C7.58581 8.74998 7.25002 8.41419 7.25002 7.99998V5.49998C7.25002 5.08577 7.58581 4.74998 8.00002 4.74998ZM8.00006 12C8.55235 12 9.00006 11.5523 9.00006 11C9.00006 10.4477 8.55235 9.99999 8.00006 9.99999C7.44778 9.99999 7.00006 10.4477 7.00006 11C7.00006 11.5523 7.44778 12 8.00006 12Z"
						fill="#E25858" />
				</svg>
				<p class="ml-1">Please make sure the Pull URL follows our <a target="_blank"
					href="https://docs.castr.com/en/articles/5268042-supported-protocols-for-pull-mode"
					class="text-underline text-dark-9 text-500">supported formats</a></p>
			</div>
			<div class="field-container">
				<!-- <div class="label">RTMP pull url</div> -->
				<div>
					<FormButton size="sm" class="mr-2" v-if="hasPullSource"
						:disabled="!canSavePullUrl() || streamSourceTypeProcessing || !isPullSourceValid" @click="setStreamPullUrl">
						<Spinner v-if="streamSourceTypeProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
							spinner-color="var(--c-dark-8)" spinner-size="15px" />
						<span v-else>Save</span>
					</FormButton>
					<FormButton id="RTMP-pull2" v-if="hasPullSource && streamAlive && isRtmpPullEnabled" size="sm"
						v-show="streamSourceType !== SourceTypes.Webcam" @click="requestRTMPPullUrl()">
						<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
							spinner-color="var(--c-dark-8)" spinner-size="15px" />
						<span v-else>Get RTMP Pull</span>
					</FormButton>

					<FormButton id="SRT-pull2" class="ml-1" v-if="hasPullSource && streamAlive && isRtmpPullEnabled" size="sm"
						v-show="streamSourceType !== SourceTypes.Webcam" @click="requestSRTPullUrl()">
						<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
							spinner-color="var(--c-dark-8)" spinner-size="15px" />
						<span v-else>Get SRT Pull</span>
					</FormButton>

					<b-tooltip v-if="hasPullSource && streamAlive" target="RTMP-pull2">
						<span class="text-dark-8 test-s-s">Click to get your RTMP pull URL. Need static URL? <a target="blank"
								class="text-reset text-underline"
								href="https://docs.castr.io/en/articles/4851668-how-to-get-constant-rtmp-address-in-castr">Here’s
								how</a>.</span>
					</b-tooltip>

					<div class="mr-auto d-inline-block" tabindex="0" v-if="hasPullSource && (!streamAlive || !isRtmpPullEnabled)"
						v-b-tooltip.hover :title="rtmpPullErrorMessage">
						<FormButton v-if="hasPullSource" disabled class="mr-auto" size="sm"
							v-show="stream.type === 'restream' || stream.type === 'live'">
							<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
								spinner-color="var(--c-dark-8)" spinner-size="15px" />
							<span v-else>Get RTMP Pull</span>
						</FormButton>
					</div>
					<div class="mr-auto d-inline-block ml-1" tabindex="0"
						v-if="hasPullSource && (!streamAlive || !isRtmpPullEnabled)" v-b-tooltip.hover
						:title="rtmpPullErrorMessage">
						<FormButton v-if="hasPullSource" disabled class="mr-auto" size="sm"
							v-show="stream.type === 'restream' || stream.type === 'live'">
							<Spinner v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3"
								spinner-color="var(--c-dark-8)" spinner-size="15px" />
							<span v-else>Get SRT Pull</span>
						</FormButton>
					</div>
					<div v-if="streamPullError && streamSourceType === SourceTypes.Pull" class="text-danger"
						style="margin-top:10px;">
						Source pull url is invalid</div>
				</div>
			</div>
		</div>

		<div v-if="!transcoderView" class="p-3 bg-dark-2" v-show='selectedPreviewTab === "playback" && !isTransitioning'
			id="palyback">

			<Alert v-if="stream.paywallPopupAuth" show type="warning" class="d-flex p-2 mb-1"
				style="background: rgba(226, 160, 82, 1)">
				<div>
					<span class="text-light-2 text-s-m text-500 m-0">This player is now behind a paywall.</span>
				</div>
			</Alert>

			<Alert v-if="!isPasswordEnabled && stream.type === 'live'" class="d-inline-flex align-items-center w-100" show
				type="warning2">
				<div>Ingest is not secure.</div>
			</Alert>
			<b-overlay show variant="blured" class="player-overlay" blur="2px" opacity=".9" v-if="stream.type === 'restream'">

				<b-tabs class="tabs-light">
					<b-tab title="Video + Audio" title-link-class="blur-fix" active>
						<div class="mt-4 blur-fix">
							<label>Iframe Widget</label>
							<b-input-group size="md">
								<FormInput class="w-[1%] flex-grow"
									modelValue="https://player.castr.io/vod/live_xxxxxxxxxxxxxxxxxxxxxxxxxxxx"
									:isError="!isPullSourceValid" placeholder="specify source url" @blur="pullSourceValidation"
									@keypress="onPullUrlChange()" readonly />
								<b-input-group-append>
									<FormButton class="relative z-10" isLink isIcon type="link" size="sm">
										<icon-base class="" fill="none">
											<icon-copy />
										</icon-base>
									</FormButton>
								</b-input-group-append>
							</b-input-group>
						</div>
						<div class="blur-fix">
							<label>Embed Url</label>
							<b-input-group size="md">
								<FormInput class="w-[1%] flex-grow"
									modelValue="https://player.castr.io/vod/live_xxxxxxxxxxxxxxxxxxxxxxxxxxxx" readonly />
								<b-input-group-append>
									<FormButton class="relative z-10" isLink isIcon type="link" size="sm">
										<icon-base class="" fill="none">
											<icon-copy />
										</icon-base>
									</FormButton>
								</b-input-group-append>
							</b-input-group>
						</div>
					</b-tab>
					<b-tab title="Audio only">
					</b-tab>
				</b-tabs>
				<template #overlay>
					<div class="player-overlay__caption">
						<p class="text-s-xl font-weight-normal mb-2 mt-n4">Get your own embeddable player.</p>
						<p class="text-s-m font-weight-normal mb-3 text-c-dark-7">You can do so much more with your content, let us
							show you or upgrade to access instantly.</p>
						<p><b-link target="_blank" class="py-2 px-4 mr-2 btn btn-primary btn-sm"
								href="https://castr.com/blog/introducing-all-in-one-streaming-solution/">Show me</b-link> <b-link
								target="_blank" class="py-2 px-4 btn btn-outline-light btn-sm"
								href="https://castr.com/app/subscribe?category=live">Upgrade</b-link></p>
						<FormButton customClasses="text-underline text-tiny text-surface-8" type="link" size="sm"
							v-b-modal.modal-up>
							Already Upgraded?
						</FormButton>
					</div>
				</template>
			</b-overlay>
			<div v-if="stream.type !== 'restream'">
				<b-tabs class="tabs-light">
					<b-tab title="Normal" @click="switchPlaybackContentTab('full')" active></b-tab>
					<b-tab title="LL" @click="switchPlaybackContentTab('ll-hls')">
						<div>
							<b-alert show variant="secondary" class="text-dark-7 text-s-s d-flex mt-3 p-2 align-items-center">
								<b-badge variant="dark-4" class="badge_beta mr-2 region-name">beta</b-badge>
								<div>
									<p class="m-0 text-dark-7 text-s-s">Low Latency Embed will give you less than 15 seconds of latency.
									</p>
								</div>
							</b-alert>

							<div class="d-inline-flex align-items-center justify-content-between w-100">
								<label class="text-s-m text-white text-500 ">Enable Low Latency</label>

								<span id="llhls-disabled-tooltip">
									<b-form-checkbox v-if="baseCharge > 0" :disabled="!hasLLHls" @change="saveLLHls" v-model="llHls"
										class="backup_switch mt-n2" :class="{ 'inactive': llHls, 'active': !llHls, 'disabled': llHls }"
										switch>
									</b-form-checkbox>
								</span>

								<b-tooltip v-if="!hasLLHls" target="llhls-disabled-tooltip">
									Your plan is not supported. <a target="_blank" class="text-reset text-underline"
										href="/app/subscribe">Upgrade</a> to access this feature
								</b-tooltip>

							</div>
							<div v-if="llHls">
								<b-form-radio-group v-model="iframe" class="mb-2">
									<b-form-radio value="responsive">Responsive</b-form-radio>
									<b-form-radio value="fixed">Fixed size</b-form-radio>
								</b-form-radio-group>
								<b-input-group size="md" class="mb-3">
									<FormInput class="w-[1%] flex-grow" :modelValue="streamIframeCode" @click="doCopy(streamIframeCode)"
										readonly />
									<b-input-group-append>
										<FormButton class="relative z-10" isLink isIcon type="link" size="sm"
											@click="doCopy(streamIframeCode)">
											<icon-base class="" fill="none">
												<icon-copy />
											</icon-base>
										</FormButton>
									</b-input-group-append>
								</b-input-group>

								<div class="d-inline-flex align-items-center justify-content-between w-100 mb-1">
									<label>Direct Player Link</label>
								</div>
								<b-input-group size="md" class="mb-3">
									<FormInput class="w-[1%] flex-grow" :modelValue="streamEmbedUrl" @click="doCopy(streamEmbedUrl)"
										readonly />
									<b-input-group-append>
										<FormButton class="relative top-1 z-10" isLink isIcon type="link" size="sm"
											@click="doCopy(streamEmbedUrl)">
											<icon-base class="" fill="none">
												<icon-copy />
											</icon-base>
										</FormButton>
										<a target="_blank"
											class="relative top-1 z-10 w-7 h-7 flex items-center justify-center text-surface-8 hover:text-white"
											:href="streamEmbedUrl">
											<icon-base fill="none">
												<icon-link-external />
											</icon-base>
										</a>
									</b-input-group-append>
								</b-input-group>
							</div>
							<div v-if="baseCharge <= 0">
								<b-alert show variant="secondary" class="text-dark-7 text-s-s d-flex p-2 mt-2">
									<svg class="mr-2 mt-1 flex-shrink-0" width="24" height="24" viewBox="0 0 24 24" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z"
											fill="currentColor" />
									</svg>
									<div>
										<p class="m-0 text-dark-9 text-s-s text-500">Not available for trial</p>
										<p class="text-s-s m-0">Please <b-link class="text-dark-8 text-underline"
												onclick="Intercom('showNewMessage', 'I want to talk about low latency solution')">contact
												support</b-link> if you need more details about this feature.</p>
									</div>
								</b-alert>
							</div>
						</div>
					</b-tab>
					<b-tab title="ULL" @click="switchPlaybackContentTab('ull')">
						<div>
							<p class="text-dark-9 text-s-ss mt-3 mb-4">Ultra Low Latency is now Sub Second Streaming.</p>
							<FormButton size="md" to="/sub-second" class="mb-3">
								Create Sub Second Streaming
							</FormButton>
						</div>
					</b-tab>
					<b-tab title="Audio" @click="switchPlaybackContentTab('audio')"></b-tab>
				</b-tabs>

				<b-alert v-if="activePlaybackContentTab === 'audio'" show variant="secondary"
					class="text-dark-7 text-s-s d-flex mt-3 p-2">
					<svg class="mr-2 mt-1 flex-shrink-0" width="14" height="15" viewBox="0 0 14 15" fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd"
							d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z"
							fill="currentColor" />
					</svg>
					<div>
						<p class="m-0 text-dark-7 text-s-s">The below players will have only audio from your Livestream.</p>
					</div>
				</b-alert>

				<div class="mt-3" v-if="activePlaybackContentTab === 'audio' || activePlaybackContentTab === 'full'">
					<!-- <label>Iframe Widget</label>	 -->
					<b-form-radio-group v-model="iframe" class="mb-2">
						<b-form-radio value="responsive">Responsive</b-form-radio>
						<b-form-radio value="fixed">Fixed size</b-form-radio>
					</b-form-radio-group>
					<b-input-group size="md" class="mb-3">
						<FormInput class="w-[1%] flex-grow" :modelValue="streamIframeCode" @click="doCopy(streamIframeCode)"
							readonly />
						<b-input-group-append>
							<FormButton class="relative z-10" isLink isIcon type="link" size="sm" @click="doCopy(streamIframeCode)">
								<icon-base class="" fill="none">
									<icon-copy />
								</icon-base>
							</FormButton>
						</b-input-group-append>
					</b-input-group>
				</div>

				<div v-if="activePlaybackContentTab === 'audio' || activePlaybackContentTab === 'full'">
					<div class="d-inline-flex align-items-center justify-content-between w-100 mb-1">
						<label>Direct Player Link</label>
					</div>
					<b-input-group size="md" class="mb-3">
						<FormInput class="w-[1%] flex-grow" :modelValue="streamEmbedUrl" @click="doCopy(streamEmbedUrl)" readonly />
						<b-input-group-append>
							<FormButton class="relative z-10" isLink isIcon type="link" size="sm" @click="doCopy(streamEmbedUrl)">
								<icon-base class="" fill="none">
									<icon-copy />
								</icon-base>
							</FormButton>
							<a target="_blank"
								class="relative z-10 w-7 h-7 flex items-center justify-center text-surface-8 hover:text-white"
								:href="streamEmbedUrl">
								<icon-base fill="none">
									<icon-link-external />
								</icon-base>
							</a>
						</b-input-group-append>
					</b-input-group>
				</div>

				<div v-if="linkedCpCode && hlsUrl && allowHls && activePlaybackContentTab === 'full'">
					<label>HLS Url</label>
					<b-input-group size="md">
						<FormInput class="w-[1%] flex-grow" :modelValue="hlsUrl" @click="doCopy(hlsUrl)" readonly />
						<b-input-group-append>
							<FormButton class="relative top-1 z-10" isLink isIcon type="link" size="sm" @click="doCopy(hlsUrl)">
								<icon-base class="" fill="none">
									<icon-copy />
								</icon-base>
							</FormButton>
						</b-input-group-append>
					</b-input-group>
				</div>

				<div v-if="!allowHls && activePlaybackContentTab === 'full'">
					<label class="text-dark-7">HLS URL. <router-link class="text-dark-8 text-underline"
							to="/subscribe">Upgrade</router-link> to access this feature.</label>
					<b-input-group size="md">
						<FormInput class="w-[1%] flex-grow" modelValue="xxxxxxxxxxxxx" disabled readonly />
					</b-input-group>
				</div>

				<div v-if="!linkedCpCode && allowHls && activePlaybackContentTab === 'full'">
					<label class="text-dark-7">HLS URL is available. <b-link class="text-dark-8 text-underline"
							onclick="Intercom('show')">Contact us</b-link> to activate.</label>
					<b-input-group size="md">
						<FormInput class="w-[1%] flex-grow" modelValue="xxxxxxxxxxxxx" disabled readonly />
					</b-input-group>
				</div>

			</div>
		</div>

		<div v-if="!transcoderView && selectedPreviewTab === 'monitoring'" class="p-3 bg-dark-2"
			v-show='selectedPreviewTab === "monitoring" && !isTransitioning' id="monitoring">
			<MonitoringChart :streamKey="stream.key" :streamAlive="streamAlive" :isAllowed="isMonitoringAllowed" />
		</div>

		<div v-if="transcoderView" class="d-none d-md-flex justify-content-end mt-3"
			:class="{ 'pe-none': false, 'transcoder-action': transcoderView }">
			<FormButton type="secondary" size="md" @click="$emit('toggle-transcoder', stream)" :id="stream._id" class="mr-2">
				{{ stream.enabled ? 'Disable' : 'Enable' }}
			</FormButton>
			<FormButton type="danger" size="md" @click="$emit('delete-transcoder', stream)" :id="stream._id">
				<icon-base fill="none">
					<icon-bin />
				</icon-base>
			</FormButton>
		</div>

		<b-modal size="sm" id="modal-up" centered hide-header hide-footer>
			<p class="mb-4 text-s-m text-center text-dark-9">Please go to the dashboard and create a new all-in-one stream to
				get
				started.</p>
			<div class="d-flex justify-content-end">
				<FormButton size="md" @click="$bvModal.hide('modal-up')">
					Got it
				</FormButton>
			</div>
		</b-modal>
		<confirm-modal modal-id="modal-unset-linked-source" message="Are you sure you want to switch to a different source?"
			ok-text="Yes" cancel-text="No" @modal-confirm="unsetLinkedSource" @modal-dismiss="onLinkedSourceDismiss"
			:dismiss-after-confirm="false" />

		<confirm-modal modal-id="modal-set-publish-mode" message="Switching to Publish mode will disable your pulled stream"
			ok-text="Enable Publish Mode" cancel-text="Cancel" @modal-confirm="unsetStreamPullUrl" />

		<confirm-modal modal-id="modal-set-linked-mode"
			message="Switching to Linked Source mode will disable your pulled stream" ok-text="Enable Linked Source Mode"
			cancel-text="Cancel" @modal-confirm="unsetStreamPullUrl" />

		<confirm-modal modal-id="modal-set-publish-mode-webcam"
			message="Switching to Webcam mode will disable your pulled stream" ok-text="Disable Pulled Stream"
			cancel-text="Cancel" @modal-confirm="unsetStreamPullUrl(true)" />

		<confirm-modal modal-id="modal-webcam-leave-navigation" message="Webcam will stop streaming if you navigate"
			ok-text="Leave Anyway" cancel-text="Keep Webcam" @modal-confirm="confirmWebcamLeave" />

		<confirm-modal modal-id="modal-pull-hls-premium"
			message="http pull feature is only available in our paid subscriptions" ok-text="Upgrade now"
			cancel-text="No thanks" @modal-confirm="navigatePaymentsPage" />
		<confirm-modal modal-id="modal-failoverIngest-subscription"
			message="Backup ingest feature not available in your subscription" ok-text="Upgrade now" cancel-text="No thanks"
			@modal-confirm="navigatePaymentsPage" />

		<confirm-modal modal-id="modal-disable-password-mobile-dialog"
			message="If your encoder doesn't support passing query parameters, the password option can be disabled here."
			ok-text="Confirm" cancel-text="Cancel" @modal-confirm="confirmDisablePasswordMobile" />

		<alert-modal modal-id="alert-mixer-pull"
			message="Mixer pull is not available in this region. Please use any regions in the US and it will not impact the quality of the stream"
			ok-text="Got it" />

		<alert-modal modal-id="alert-disable-pull" message="Choose a specific region to use this mode." ok-text="Got it" />

		<prompt-modal modal-id="modal-mixer-username" message="Enter your Mixer username"
			message2="Note: If you are pulling, please disable push to mixer" ok-text="Grab Mixer Pull Url"
			cancel-text="Cancel" error-message="No FTL broadcasts found" @modal-prompt="onMixerUsername" />

		<confirm-modal modal-id="modal-backup-toggle-warning" message=""
			body="Adjusting this setting will alter your current stream key. Please ensure you update your streaming software with the new key to avoid any disruptions in your broadcast"
			ok-text="Ok, I will  update" cancel-text="No, don’t change it" :confirmCancelEvent="false"
			@modal-confirm="onBackupModalToggleConfirm" @modal-cancel="onBackupModalToggleCancel" />

		<confirm-modal modal-id="modal-pass-toggle-warning" message=""
			body="Adjusting this setting will alter your current stream key. Please ensure you update your streaming software with the new key to avoid any disruptions in your broadcast"
			ok-text="Ok, I will  update" cancel-text="No, don’t change it" :confirmCancelEvent="false"
			@modal-confirm="togglePasswordCheckProced" @modal-cancel="changePasstoggle" />

		<confirm-modal modal-id="modal-reset-stream-password"
			message="Adjusting this setting will alter your current stream key. Please ensure you update your streaming software with the new key to avoid any disruptions in your broadcast. If you have linked other products with this stream, please update them as well."
			ok-text="Ok, I will update" cancel-text="Cancel" @modal-confirm="confirmResetStreamPassword" />

	</div>
</template>

<script>
import _ from 'lodash';
import utils from '@/utils';
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import PlayoutService from '@/services/PlayoutService';
import IntegrationService from '@/services/IntegrationService';
import StreamPlayer from '@/components/ui/StreamPlayer.vue';
import StreamRegion from '@/components/ui/StreamRegion.vue';
import TranscoderService from '@/services/TranscoderService';
import SelectLinkedSource from '@/components/ui/SelectLinkedSource.vue';
import StreamPlayerHls from '@/components/ui/StreamPlayer.hls.vue';
import WebcamPlayer from '@/components/ui/WebcamPlayer.vue';
import PromptModal from '@/components/modals/PromptModal.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ExpansionPanel from '@/components/ExpansionPanel';
import AddStreamModal from '@/components/modals/AddStreamModal.vue';
import FormButton from '../../components/Atoms/FormButton.vue';
import FormInput from '../../components/Atoms/FormInput.vue';
import IconCopy from '../../components/icon/IconCopy.vue';
import IconEye from '../../components/icon/IconEye.vue';
import IconEyeCrossed from '../../components/icon/IconEyeCrossed.vue';
import IconLinkExternal from '../../components/icon/IconLinkExternal.vue';
import IconBase from '../../components/icon/IconBase.vue';
import IconBin from '../../components/icon/IconBin.vue';
import CountDown from "@/components/ui/CountDown.vue";
import MonitoringChart from './MonitoringChart.vue';
import { TooltipComponent } from "@syncfusion/ej2-vue-popups"
import IconInfo from '../../components/icon/IconInfo.vue';
import Alert from '../../components/Atoms/Alert.vue'

const SourceTypes = {
	Pull: 'pull',
	Publish: 'publish',
	Webcam: 'webcam',
	Linked: 'linked'
};

export default {
	name: 'StreamPreviewPaneNew',
	components: {
		Spinner,
		ConfirmModal,
		AlertModal,
		StreamPlayer,
		StreamPlayerHls,
		AddStreamModal,
		WebcamPlayer,
		PromptModal,
		ExpansionPanel,
		StreamRegion,
		SelectLinkedSource,
		IconBase,
		FormButton,
		FormInput,
		IconCopy,
		IconEye,
		IconEyeCrossed,
		IconLinkExternal,
		IconBin,
		CountDown,
		MonitoringChart,
		"ejs-tooltip": TooltipComponent,
		IconInfo,
		Alert,
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		mediaPulse: {
			type: Object,
			default() { return {}; }
		},
		streamAlive: {
			type: Boolean
		},
		backupStreamAlive: {
			type: Boolean,
			default() { return false; }
		},
		isRtmpPullEnabled: {
			type: Boolean,
			default() { return true; }
		},
		transcoderView: {
			type: Boolean,
			default: false
		},
		primaryStreamAlive: {
			type: Boolean,
			default() { return false; }
		},
	},
	beforeRouteLeave(to, from, next) {
		if (this.streamSourceType === SourceTypes.Webcam && this.webcamPushReady) {
			this.$root.$emit('bv::show::modal', 'modal-webcam-leave-navigation');
			this.webcamRouteLeaveNavigationCallback = () => next();
			return;
		}
		next();
	},
	data() {
		return {
			notAllowed: false,
			SourceTypes,
			processing: true,
			processingMessage: null,
			activeTab: 'encoder',
			rmptPullUrlProcessing: false,
			srtPullUrlProcessing: false,
			hlsUrl: null,
			streamSourceType: null,
			streamSourceTypeModel: null,
			streamPreviewSpecs: {},
			streamPullUrl: null,
			streamPullError: false,
			streamPullSourceChunksCount: 0,
			pullSourceWorking: true,
			pullSourceStatusTimeoutCtrl: -1,
			webcamPushReady: false,
			showError: false,
			streamSourceTypeProcessing: null,
			streamId: null,
			streamFps: null,
			playbackClicked: false,
			streamKeyVisible: false,
			streamKeyVisibleTimeout: 0,
			streamKeyVisibleTimeoutCtrl: null,

			streamKeyVisible2: false,
			streamKeyVisibleTimeout2: 0,
			streamKeyVisibleTimeoutCtrl2: null,

			windowWidth: window.innerWidth,

			windowHeight: 0,
			webcamRouteLeaveNavigationCallback: null,
			activeIngestTypeTab: 'rtmp',
			failoverIngestToggleState: false,
			failoverIngestToggleProcessing: false,
			lowLatencyPlaybackStage: false,
			lowLatencyStreamToggleProcessing: false,
			activePlaybackContentTab: 'full',
			regions: [],
			selectedRegion: null,
			switchRegionProcessing: false,
			showRegionDropdownMenu: false,
			autoDetectDisabled: false,
			autoDetectDisabledClass: { 'auto-detect-disabled': false },
			componentLoaded: false,
			showSRT: false,
			streamCountdownSecLeft: null,
			streamAbrToggleState: null,
			iframe: 'responsive',
			isPullSourceValid: true,
			llHls: false,
			isPasswordEnabled: true,
			webcamDevices: [],
			audioDevice: null,
			videoDevice: null,
			countdownDate: null,

			selectedPreviewTab: 'source-setup',
			backTransition: true,
			isTransitioning: false,
			linkedSource: null,
			selectedLinkedSource: null,
			sourceSet: false,
		};
	},
	computed: {
		isMonitoringAllowed() {
			const liveSub = _.find(this.subscription, { category: 'live' }) || {};
			return liveSub.enabled;
		},
		hasLLHls() {
			// TODO(remove): hardcode access to LL hls feature for user with premuin sub
			// LL hls should be blocked for current sub for all other clients
			if (this.stream.user === '653686e34d8f1722c0b3c9a4') {
				return true
			}
			if (this.stream.user === '66e83ba5bb1df9597da804a9') {
				return true
			}
			return this.$store.state.User.aggregatedDefinition.live.llHls ?? false
		},
		userEmail() {
			return this.$store.state.User.email;
		},
		isAnyBackupStreamLive() {
			return this.streamAlive || this.backupStreamAlive
		},
		mobile() {
			if (this.windowWidth > 767) {
				return false
			} else {
				return !this.transcoderView
			}
		},
		selectedRegionName() {
			if (!this.selectedRegion) {
				return '';
			}
			const name = this.selectedRegion.name;
			// if (name && name.length > 15) {
			// 	return name.split('(').pop().replace(')', '');
			// }
			return this.selectedRegion.name;
		},
		contextualRegionList() {
			const stype = this.stream.type;
			if (stype === 'scheduled') {
				return [];
			}
			return stype
				? this.regions.filter(r => r.platforms.indexOf('restream') > -1)
				: [];
		},
		failoverIgestActive() {
			return this.failoverIngestToggleState === true && this.streamSourceTypeModel === 'publish' && this.stream.type === 'live';
		},
		streamKey() {
			const prefix = this.failoverIgestActive && this.hasFailoverIngestFeature ? '_primary' : ''
			let pushKey = this.stream.key + prefix;
			if (this.stream.config && this.stream.config.password && this.isPasswordEnabled) {
				pushKey += '?password=' + this.stream.config.password;
			}

			return pushKey;
		},
		streamKey2() {
			let pushKey = `${this.stream.key}_backup`;
			if (this.stream.config && this.stream.config.password && this.isPasswordEnabled) {
				pushKey += '?password=' + this.stream.config.password;
			}

			return pushKey;
		},
		srtIngestPointForSelectedRegion() {
			switch (this.selectedRegion?.identifier) {
				case 'au':
				case 'sg':
					return 'au.castr.io'

				default:
					return 'srt-ingest.castr.io'
			}
		},
		srtRegionIngestDestination() {
			if (this.stream.user === '62c4b3398b2e840b11244bfd') {
				return 'fifa'
			}

			// hack for studio@jackshoot.com
			if (this.stream.user === '5da088ba2564bd064957e474' && this.stream.uiRegion._id === '62e2a0e108cb9dc2bcfb17d8') {
				return 'kr'
			}

			if (this.stream.user === '5ba39c5e688503562c8097b4' && this.stream.uiRegion._id === '63f3624d8ae252ca28ed79d2') {
				return 'ae'
			}


			const liveRegionRel = {
				'us-east-1': 'cg',
				'us-east-3': 'ny',
				'us-east-2': 'mi',
				'us-west-2': 'se',
				'us-west-1': 'la',
				'us-central-1': 'da',
				'na-east-1': 'qc',
				'sa-east-1': 'br',
				'eu-west-1': 'uk',
				'af-south-1': 'southafrica',
				'eu-central-1': 'fr',
				'ru-west-1': 'ru',
				'ap-southeast-1': 'sg',
				'ap-south-1': 'in',
				'ap-southeast-2': 'au',
				'madrid': 'madrid',
				'paris': 'paris',
				'milan': 'milan',
				'stockholm': 'stockholm',
				'mexico': 'mexico',
				'hongkong': 'hongkong',
				'colombia': 'bogota',
				'istanbul': 'istanbul',
				'telaviv': 'telaviv',
				'dubai': 'dubai',
				'tokyo': 'tokyo',
				'copenhagen': 'copenhagen',
				'santiago': 'santiago',
				'lagos': 'lagos-1',
			}

			const multistreamRegionRel = {
				'us-east-1': 'cg',
				'us-east-3': 'ny',
				'us-east-2': 'mi',
				'us-west-2': 'se',
				'us-west-1': 'la',
				'us-central-1': 'da',
				'na-east-1': 'qc',
				'sa-east-1': 'br',
				'eu-west-1': 'uk',
				'af-south-1': 'southafrica',
				'eu-central-1': 'fr',
				'ru-west-1': 'ru',
				'ap-southeast-1': 'sg',
				'ap-south-1': 'in',
				'ap-southeast-2': 'au',
				'madrid': 'madrid',
				'paris': 'paris',
				'milan': 'milan',
				'stockholm': 'stockholm',
				'mexico': 'mexico',
				'hongkong': 'hongkong',
				'colombia': 'bogota',
				'istanbul': 'istanbul',
				'telaviv': 'telaviv',
				'dubai': 'dubai',
				'tokyo': 'tokyo',
				'copenhagen': 'copenhagen',
				'santiago': 'santiago',
				'lagos': 'lagos-1',
			}

			if (this.stream.type === 'live') {
				return liveRegionRel[this.stream.uiRegion?.code] ?
					liveRegionRel[this.stream.uiRegion?.code] : 'str-ingest'
			}

			if (this.stream.type === 'restream') {
				return multistreamRegionRel[this.stream.uiRegion?.code] ?
					multistreamRegionRel[this.stream.uiRegion?.code] : 'str-ingest'
			}

			return 'srt-ingest'
		},
		streamKeySRT() {
			let streamURL = `srt://${this.srtRegionIngestDestination}.castr.io:9998?pkt_size=1316&streamid=#!::r=${this.stream.key},m=publish`;
			if (this.stream.config && this.stream.config.password && this.isPasswordEnabled) {
				streamURL = `srt://${this.srtRegionIngestDestination}.castr.io:9998?pkt_size=1316&streamid=#!::r=${this.stream.key},password=${this.stream.config.password},m=publish`
			}

			return streamURL;
		},
		streamKeyWHIP() {
			let hostname = _.get(this.stream, 'uiRegion.config.failover.backupIngest');
			if (!hostname) {
				hostname = _.get(this.stream, 'uiRegion.config.failover.primaryIngest');
			}
			if (!hostname) {
				hostname = this.stream.uiRegion.hostname
			}
			let streamURL = `https://${hostname}/${this.stream.key}/whip`;
			if (this.stream.config && this.stream.config.password && this.isPasswordEnabled) {
				streamURL = `https://${hostname}/${this.stream.key}/whip?password=${this.stream.config.password}`
			}

			return streamURL;
		},
		SRTHostname() {
			let streamHost = ` srt://${this.srtRegionIngestDestination}.castr.io`;
			return streamHost;
		},
		SRTPort() {
			let streamPort = `9998`;
			return streamPort;
		},
		SRTID() {
			let streamIDSrt = `#!::r=${this.stream.key},m=publish`;
			if (this.stream.config && this.stream.config.password && this.isPasswordEnabled) {
				streamIDSrt = `#!::r=${this.stream.key},password=${this.stream.config.password},m=publish`
			}
			return streamIDSrt;
		},
		shouldShowDeploymentRegion() {
			return this.streamType !== 'ipcam';
		},
		streamType() {
			return this.stream.type;
		},
		hasPullSource() {
			return this.streamSourceType === SourceTypes.Pull;
		},
		regionFlag() {
			const identifier = this.stream.uiRegion.identifier || '';
			return `https://assets.castr.io/countryflags/${identifier}/flat/64.png`;
		},
		streamIframeCode() {
			const embedUrl = this.streamEmbedUrl;
			let htmlCode = "";
			if (this.iframe === "responsive") {
				htmlCode = `<iframe src="${embedUrl}" width="100%" style="aspect-ratio: 16/9; min-height: 340px;" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen  webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
			} else {
				htmlCode = `<iframe src="${embedUrl}" width="590" height="431" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
			}

			if (this.activePlaybackContentTab === 'audio') {
				htmlCode = `<iframe src="${embedUrl}" width="100%" style="height: 50px;" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
			}

			return htmlCode;
		},
		streamEmbedUrl() {
			let embedUrl = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${this.stream.key}`;
			if (this.dvrEmbedEnabled) {
				embedUrl += '?playlist=1';
			}

			if (this.activePlaybackContentTab === 'audio') {
				// if stream source is webcam for only audio we need point player to 
				// pick correct track audio number(#2) from audio source
				let webcamSrc = '';
				if (this.stream.type === 'live' && this.streamSourceType === 'webcam') {
					webcamSrc = '&webcam=true';
				}
				let onlyAudio = `?onlyAudio=true${webcamSrc}`;
				if (embedUrl.includes('?')) {
					onlyAudio = `&onlyAudio=true${webcamSrc}`;
				}

				embedUrl += onlyAudio;
			}

			if (this.activePlaybackContentTab === 'll-hls') {
				let ll = `?ll=true`;
				if (embedUrl.includes('?')) {
					ll = `&ll=true`;
				}

				embedUrl += ll;
			}

			if (this.activePlaybackContentTab === 'ull') {
				embedUrl += '/realtime';
			}

			return embedUrl;
		},
		activeTeam() {
			return this.$store.state.User.activatedTeam;
		},
		memberPermission() {
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
		cannotToggleStream() {
			if (!this.activeTeam) return false;
			let permission = this.memberPermission && (
				((this.stream.type === 'live') && !this.memberPermission.liveStreamToggle) ||
				((this.stream.type === 'restream') && !this.memberPermission.recordedStreamToggle) ||
				((this.stream.type === 'scheduled') && !this.memberPermission.preRecordedStreamToggle));
			return permission;
		},
		subscription() {
			return this.$store.state.User.subscriptions;
		},
		baseSubscription() {
			const baseSub = _.find(this.subscription, { category: this.stream.type }) || {};
			return baseSub;
		},
		baseCharge() {
			if (this.componentLoaded === true && this.baseSubscription) {
				return this.baseSubscription?.package?.baseCharge;
			}
		},
		allowHls() {
			const hlsEnabled = _.get(this.baseSubscription, 'definitionOverride.hlsEnabled', _.get(this.baseSubscription, 'package.definition.hlsEnabled', false));
			if (hlsEnabled) return true;

			if ((this.baseCharge > 750 && this.baseSubscription.package.cycleAge >= 365) || (this.baseCharge > 100 && this.baseSubscription.package.cycleAge < 365)) {
				return true
			} else return false
		},
		isTrialSubscription() {
			const subs = this.subscription.map(sub => _.assign({ category: 'restream' }, sub))
			const baseSub = _.find(subs, { category: this.stream.type }) || {};
			return /trial/gi.test(baseSub.package.name) || false;
		},
		linkedCpCode() {
			const baseSub = this.baseSubscription;
			const cpcode = _.get(baseSub, 'definitionOverride.cdnDeploymentId');
			return cpcode;
		},
		hasFailoverIngestFeature() {
			const baseSub = this.baseSubscription;
			return !!_.get(baseSub, 'package.definition.failoverIngest');
		},
		failoverPrimaryIngest() {
			let ingest = _.get(this.stream, 'uiRegion.config.failover.primaryIngest');
			if (!ingest) {
				// ingest = this.stream.uiRegion.hostname.replace('.castr.io', '-primary.castr.io');
				ingest = this.stream.uiRegion.hostname
			}
			return ingest;
		},
		failoverBackupIngest() {
			if (!this.hasFailoverIngestFeature) {
				return 'xxxxxxxxxxxxxxxxx';
			}
			let ingest = _.get(this.stream, 'uiRegion.config.failover.backupIngest');
			if (!ingest) {
				// ingest = this.stream.uiRegion.hostname.replace('.castr.io', '-backup.castr.io');
				ingest = this.stream.uiRegion.hostname
			}
			return ingest;
		},
		rtmpPullErrorMessage() {
			return !this.isRtmpPullEnabled ? "This feature is not available for your plan. Please upgrade to use it." : "This can only be used when stream is live"
		},
		audioDevices() {
			return this.webcamDevices.filter((device) => device.kind === 'audioinput');
		},
		videoDevices() {
			return this.webcamDevices?.filter((device) => device.kind === 'videoinput');
		},
		defaultAudioDevice() {
			return this.audioDevice || this.audioDevices.find((device) => device.deviceId === 'default') || this.audioDevices[0];
		},
		defaultVideoDevice() {
			return this.videoDevice || this.videoDevices[0];
		},
	},
	watch: {
		async streamAbrToggleState() {
			await this.setStreamHlsUrl();
		},
		async mediaPulse() {
			this.onMediaPulseChanged();
		},
		streamSourceTypeModel() {
			if (this.streamSourceType === SourceTypes.Pull && this.stream.uiRegion.hostname === 'live.castr.io') {
				this.streamSourceType = SourceTypes.Publish;
				this.streamSourceTypeModel = SourceTypes.Publish;
				this.$root.$emit('bv::show::modal', 'alert-disable-pull');
			}
		},
	},
	async mounted() {
		FormInput.compatConfig = { MODE: 3 }
		this.isPasswordDisabled = Boolean(this.stream?.config?.isPasswordDisabled);
		if (localStorage.getItem('notAllowed' + this.stream.type)) {
			this.notAllowed = (localStorage.getItem('notAllowed' + this.stream.type) == 'true');
		}
		this.isPasswordEnabled = !Boolean(this.stream?.config?.isPasswordDisabled);
		await this.$store.dispatch('User/getinfoOwner', null, { root: true });
		this.failoverIngestToggleState = this.stream.failoverIngest;
		this.lowLatencyPlaybackStage = this.stream.lowLatencyPlayback;
		await this.setupStream();
		await this.setStreamHlsUrl();
		this.selectRegion(this.stream.uiRegion);

		this.webcamDevices = await navigator.mediaDevices?.enumerateDevices();

		if (this.stream.uiRegion.hostname === 'live.castr.io') {
			this.failoverIngestToggleProcessing = true;
		} else this.failoverIngestToggleProcessing = false;

		this.selectRegion(this.stream.uiRegion);
		this.componentLoaded = true;
		//TODO delete after move to component
		this.regions = await StreamService.getAvailableRegions('list');
		window.onresize = () => {
			this.windowWidth = window.innerWidth
		}
		if (this.stream.type === 'live' && !this.transcoderView) {
			const meta = await StreamService.getStreamMetadata(this.stream._id);
			if (meta.countdownDate) {
				this.countdownDate = meta.countdownDate
				this.streamCountdownSecLeft = Math.max(new Date(meta.countdownDate) - Date.now(), 0);
			}
			this.llHls = meta.llHls || false
		}

		// if(this.streamAlive) {
		// 	this.onSelectTab('monitoring');
		// }
	},
	methods: {
		navigateBackupPage() {
			this.$router.push({
				name: 'StreamsManageSettingsBackup',
				params: { streamId: this.stream._id }
			});
		},
		onSelectTab(id) {
			if (this.selectedPreviewTab === 'playback') this.playbackClicked = true;
			if (this.selectedPreviewTab === 'source-setup') this.backTransition = true;
			else if (this.selectedPreviewTab === 'monitoring') this.backTransition = false;
			else if (this.selectedPreviewTab === 'playback' && id === 'source-setup') this.backTransition = false;
			else this.backTransition = true;

			this.isTransitioning = false;
			this.selectedPreviewTab = id;
		},
		togglePasswordCheck() {
			this.$root.$emit(
				'bv::show::modal',
				'modal-pass-toggle-warning'
			);

		},
		togglePasswordCheckProced() {
			if (this.mobile) {
				this.showTogglePasswordDialog();
			} else {
				this.togglePassword()
			}
		},
		confirmDisablePasswordMobile() {
			this.isPasswordEnabled = !this.isPasswordEnabled;
			this.togglePassword();
		},
		showTogglePasswordDialog() {
			// undo the change done by v-model
			this.isPasswordEnabled = !this.isPasswordEnabled;
			this.$root.$emit('bv::show::modal', 'modal-disable-password-mobile-dialog');
		},
		async togglePassword() {
			// this.isPasswordEnabled = !this.isPasswordEnabled

			// optimistic update
			await this.$nextTick();
			try {
				const res = await StreamService.updatePasswordToggle(this.stream._id, !this.isPasswordEnabled);
				if (!res.success) {
					this.onFailedToTogglePassword();
				}
			} catch {
				this.onFailedToTogglePassword();
			}
		},
		onFailedToTogglePassword() {
			this.isPasswordEnabled = !this.isPasswordEnabled;
			this.$notify({ group: 'error', text: 'Could not toggle the password.' });
			// revert

		},
		async saveLLHls() {
			try {
				await StreamService.saveStreamMetadata(this.stream._id, 'llHls', this.llHls);
				this.$notify({ group: 'success', text: 'Setting saved' })
			} catch (e) {
				this.llHls = !this.llHls;
				this.$notify({ group: 'error', text: 'could not save changes' });
			}
		},
		onStreamCountdownTick(last, isFinished) {
			if (isFinished) {
				this.streamCountdownSecLeft = null;
			}
		},
		toggleStatus(ev) {
			this.$parent.toggleStatus(ev);
		},
		toggleRegionDropdownMenu() {
			this.showRegionDropdownMenu = !this.showRegionDropdownMenu;
		},
		closeRegionDropDownMenu() {
			this.showRegionDropdownMenu = false;
		},
		initSelectedSource(source) {
			this.linkedSource = source
			this.selectedSource(source)
			this.streamSourceTypeModel = this.SourceTypes.Linked
			this.onSourceTypeChange()
		},
		selectedSource(source) {
			this.selectedLinkedSource = source
			if (this.linkedSource && this.linkedSource._id === source._id) {
				this.sourceSet = true
			} else this.sourceSet = false
			this.stream.removedSource = false
		},
		async switchStreamRegion(region) {
			if (!region) {
				return;
			}

			this.switchRegionProcessing = true;
			try {
				await StreamService.switchStreamRegion(this.streamId, region._id, this.transcoderView);
				// this.closeRegionDropDownMenu();

				const selectedProps = _.pick(region, ['hostname', 'identifier', 'name']);
				const newRegion = _.assign({}, this.stream.region, selectedProps)
				this.$emit('stream-updated', { uiRegion: region });

				this.$notify({
					group: 'success',
					text: `region changed to ${region.name}`
				});
			} catch (e) {
				this.$notify({
					group: 'error',
					text: (e && e.message) || 'could not switch stream region'
				});
			}
			if (region.hostname === 'live.castr.io') {
				this.failoverIngestToggleProcessing = true;
			} else this.failoverIngestToggleProcessing = false;
			this.switchRegionProcessing = false;
		},
		getCountryFlag(region) {
			const identifier = _.get(region || {}, 'identifier', '');
			return `https://assets.castr.io/countryflags/${identifier}/flat/64.png`;
		},
		selectRegion(region) {
			this.selectedRegion = region;
		},
		switchIngestTypeTab(tab) {
			this.activeIngestTypeTab = tab;
		},
		switchPlaybackContentTab(tab) {
			this.activePlaybackContentTab = tab;
		},
		async onBackupModalToggleCancel() {
			this.failoverIngestToggleState = !this.failoverIngestToggleState;
		},
		async toggleIngestFailoverStatus() {
			if (this.hasFailoverIngestFeature) {
				this.$root.$emit(
					'bv::show::modal',
					'modal-backup-toggle-warning'
				);
			} else {

				this.onBackupModalToggleConfirm()
			}

		},
		async onBackupModalToggleConfirm() {
			// this.failoverIngestToggleState = !this.failoverIngestToggleState;

			if (!this.hasFailoverIngestFeature) {
				this.failoverIngestToggleState = false;
				return
			}

			this.failoverIngestToggleProcessing = true;
			try {
				await StreamService.toggleStreamFailoverIngest(this.streamId, this.failoverIngestToggleState);
			} catch (e) {
				this.failoverIngestToggleState = !this.failoverIngestToggleState;
				this.$notify({
					group: 'error',
					text: (e && e.message) || 'could not toggle backup stream ingest'
				});
			}

			// TODO: remove here mutation of prop and emit event to parent component
			// backupMediaPulse must stop processing when backup ingest disabled
			this.stream.failoverIngest = this.failoverIngestToggleState;
			this.autoDetectDisabled = this.failoverIngestToggleState;
			this.autoDetectDisabledClass = { 'auto-detect-disabled': true }

			this.failoverIngestToggleProcessing = false;
			if (this.failoverIngestToggleState) window.Intercom('trackEvent', 'toggle-backup-on')
		},
		async toggleLowLatencyStreaming() {
			// this.lowLatencyPlaybackStage = !this.lowLatencyPlaybackStage;

			this.lowLatencyStreamToggleProcessing = true;
			try {
				await StreamService.toggleStreamLowLatencyStreaming(this.streamId, this.lowLatencyPlaybackStage);
			} catch (e) {
				this.lowLatencyPlaybackStage = !this.lowLatencyPlaybackStage;
				this.$notify({
					group: 'error',
					text: (e && e.message) || 'Could not toggle low latency streaming'
				});
			}

			this.stream.lowLatencyPlayback = this.lowLatencyPlaybackStage;
			this.lowLatencyStreamToggleProcessing = false;
		},
		switchOptionsTab(tabName) {
			this.activeTab = tabName;
		},
		// openCreatestream(){
		// 	this.$bvModal.show('modal-add-stream');
		// 		this.$root.$emit(
		// 				'bv::show::modal',
		// 				'modal-add-stream'
		// 			);
		// 		this.$root.$emit('openAIO', true);
		// },
		async setupStream() {
			// get stream details
			const { stream } = this;
			// this.stream = stream;
			this.streamId = this.stream._id;

			const hasPullUrl = stream.pullUrl;
			if (!this.linkedSource) this.streamSourceTypeModel = hasPullUrl
				? SourceTypes.Pull
				: SourceTypes.Publish;

			this.onSourceTypeChange();
			if (this.streamType === 'ipcam') {
				this.streamSourceType = SourceTypes.Pull;
			}

			if (hasPullUrl) {
				this.streamPullUrl = stream.pullUrl;
			}
			if (stream.failoverIngest === true) {
				this.autoDetectDisabled = true;
				this.autoDetectDisabledClass = { 'auto-detect-disabled': true }
			}
		},
		async setStreamPreviewUrl() {
			try {
				this.streamPreviewSpecs = await StreamService.getStreamPreviewVideoUrl(
					this.stream._id
				);
			} catch (e) {
				this.$notify({
					group: 'error',
					text: (e && e.message) || 'could not fetch stream preview sepcs'
				});
			}
		},
		async setStreamHlsUrl() {
			try {
				const hlsUrlRes = await StreamService.getStreamHlsUrl(this.stream._id);
				if (!hlsUrlRes || !hlsUrlRes.hlsUrl) {
					throw new Error();
				}
				this.hlsUrl = hlsUrlRes.hlsUrl;
			} catch (e) {
				// do nothing
			}
		},
		async onMediaPulseChanged() {
			if (this.mediaPulse?.isWowza === true) {
				this.streamPreviewSpecs.type = 'hls';
				this.streamPreviewSpecs.url = `https://wowza-stage.castr.io/static/${this.stream.key}/playlist_sfm4s.m3u8`;
			}

			if (_.isEmpty(this.streamPreviewSpecs)) {
				this.streamPreviewSpecs.type = 'mse';
				this.streamPreviewSpecs.url = `wss://wowza-stage.castr.io/${this.stream.key}/mse_ld?tracks=v1a1`;
			}
		},
		doCopy(text) {
			try {
				if (text instanceof Function) text = text();

				this.$copyText(text);
				this.$notify({ group: 'info', text: 'Copied to clipboard' });
			} catch (e) { }
		},
		navigatePaymentsPage() {
			const category = this.stream.type;
			window.location.href = `/app/subscribe?category=${category}`;
		},
		pullSourceValidation() {
			// Clean the input string by removing all whitespace characters
			const pullSource = this.streamPullUrl?.replace(/\s/g, "");
			this.streamPullUrl = pullSource;
			this.isPullSourceValid = utils.validateSrtUrl(pullSource);
		},
		onPullUrlChange() {
			this.pullSourceValidation;
			this.streamPullError = false;
		},
		isMixerPullAuthorized() {
			const exlcudedRegions = ['br'];
			const curRegion = this.stream.region.identifier;

			let bypassed = true;
			for (let i = 0; i < exlcudedRegions.length; i++) {
				if (curRegion === exlcudedRegions[i]) {
					bypassed = false;
					break;
				}
			}

			return bypassed;
		},
		canSavePullUrl() {

			let canSave = false;
			// check for valid input
			const { streamPullUrl } = this;

			if (streamPullUrl) {
				// check if pull remained same
				if (streamPullUrl !== this.stream.pullUrl) canSave = true;
			}

			return canSave;
		},
		onWebcamAuthorized() {
			// check if streaming pulling mode is active
			if (this.stream.pullUrl) {
				this.$root.$emit(
					'bv::show::modal',
					'modal-set-publish-mode-webcam'
				);
			}
		},
		onWebcamStopped() {
			this.webcamPushReady = false;
		},
		onWebcamStarted() {
			this.webcamPushReady = true;
		},
		confirmWebcamLeave() {
			if (this.webcamRouteLeaveNavigationCallback) {
				this.webcamRouteLeaveNavigationCallback();
			}
		},
		async onSourceTypeChange(selectedTab) {
			if (this.streamSourceType === SourceTypes.Webcam && this.webcamPushReady) {
				setTimeout(() => {
					this.streamSourceTypeModel = this.streamSourceType;
				}, 100);

				this.$root.$emit(
					'bv::show::modal',
					'modal-webcam-leave-navigation'
				);
				this.webcamRouteLeaveNavigationCallback = () => {
					this.streamSourceType = this.streamSourceTypeModel;
				};
				return;
			}


			if (selectedTab && this.linkedSource && selectedTab !== SourceTypes.Linked) {
				this.$root.$emit('bv::show::modal', 'modal-unset-linked-source');
				return
			}

			if (selectedTab === SourceTypes.Linked) {
				// check if operational mode is `pull`
				const hadPullUrl = this.stream.pullUrl;
				if (hadPullUrl) this.$root.$emit('bv::show::modal', 'modal-set-linked-mode');
			}

			this.streamSourceType = this.streamSourceTypeModel;


			// check if new mode is `publish`
			if (selectedTab === SourceTypes.Publish) {
				// check if operational mode is `pull`
				const hadPullUrl = this.stream.pullUrl;
				// if (hadPullUrl) this.unsetStreamPullUrl();
				if (hadPullUrl) this.requestPublishPrompt();
			}

			if (selectedTab === SourceTypes.Webcam && _.isEmpty(this.webcamDevices)) {
				await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
				this.webcamDevices = await navigator.mediaDevices.enumerateDevices();
			}
		},
		requestPublishPrompt(preventSourceRestore) {
			this.$root.$emit('bv::show::modal', 'modal-set-publish-mode');

			if (preventSourceRestore) return;
			setTimeout(() => {
				this.streamSourceType = SourceTypes.Pull;
				this.streamSourceTypeModel = SourceTypes.Pull;
			});
		},
		requestMixerUsername() {
			// const res = await IntegrationService.getMixerFTLUrl('tidy')
			if (!this.isMixerPullAuthorized()) {
				this.$root.$emit('bv::show::modal', 'alert-mixer-pull');
				return;
			}

			this.$root.$emit('bv::show::modal', 'modal-mixer-username');
		},
		async onMixerUsername(mixerUsername, ackCB) {
			const res = await IntegrationService.getMixerFTLUrl(mixerUsername);
			ackCB(!res.mixerPullURL);

			const { mixerPullURL } = res;
			this.streamPullUrl = mixerPullURL;
		},
		setPullSourceStatus() {
			const status = this.streamAlive;
			this.pullSourceWorking = status;

			if (status) {
				const t = setTimeout(this.setPullSourceStatus, 5000);
				this.pullSourceStatusTimeoutCtrl = t;
			}
		},
		parseURL(srcURL) {
			let url;
			try {
				url = new URL(srcURL);
			} catch (_) {
				return false;
			}
			return url
		},
		async setStreamPullUrl() {
			this.streamPullError = false;

			const pullSource = this.streamPullUrl.replace(/\s/g, '');

			this.streamPullUrl = pullSource;

			if (isMixerFTLSource(pullSource) && !this.isMixerPullAuthorized()) {
				this.$root.$emit('bv::show::modal', 'alert-mixer-pull');
				return;
			}

			if (this.isTrialSubscription) {
				const url = this.parseURL(pullSource)
				if (url.protocol === "http:" || url.protocol === "https:") {
					this.$root.$emit('bv::show::modal', 'modal-pull-hls-premium');
					return;
				}
			}

			// swtich source mode to specified pull url
			this.streamSourceTypeProcessing = true;

			try {
				await StreamService.setStreamPullUrl(this.streamId, pullSource);
				// this.stream.pullUrl = pullSource;

				this.$emit('stream-updated', { pullUrl: pullSource });

				this.$notify({ group: 'success', text: 'stream pull url saved' });
			} catch (e) {
				this.$notify({
					group: 'error',
					text: 'could not save stream pull url'
				});
			}

			this.streamSourceTypeProcessing = false;
		},
		async unsetStreamPullUrl(preventSourceRestore) {
			this.streamSourceTypeProcessing = true;

			try {
				await StreamService.unsetStreamPullUrl(this.streamId);
				// this.stream.pullUrl = null;

				this.$emit('stream-updated', { pullUrl: null });

				this.$notify({ group: 'success', text: 'Publish mode activated' });

				if (!preventSourceRestore) {
					// change tab to publish
					this.streamSourceType = SourceTypes.Publish;
					this.streamSourceTypeModel = SourceTypes.Publish;
				}
			} catch (e) {
				if (!preventSourceRestore) {
					this.streamSourceType = SourceTypes.Pull;
					this.streamSourceTypeModel = SourceTypes.Pull;
				}

				this.$notify({
					group: 'error',
					text: 'could not switch to Publish mode'
				});
			}

			this.streamSourceTypeProcessing = false;
		},
		async requestRTMPPullUrl() {
			// check if RTMP pull is allowed
			if (!this.isRtmpPullEnabled) return

			// try copy to clipboard
			const rtmpPullUrl = this.getStreamPullUrl();
			try {
				this.$copyText(rtmpPullUrl);
				this.onStreamKeyCopied();
			} catch (e) { }
		},
		async requestSRTPullUrl() {
			// try copy to clipboard
			let server = this.streamKeySRT;
			let hostId = this.mediaPulse && this.mediaPulse.hostId;

			if (this.stream.user === '62c4b3398b2e840b11244bfd') {
				hostId = 'fifa'
			}

			if (hostId) {
				const mappedHostname = `${hostId}.castr.io`;
				server = 'srt://' + mappedHostname + ':9999'
				server += `?streamid=#!::r=${this.stream.key},m=request`;
			}

			try {
				this.$copyText(server);
				this.onStreamKeyCopied();
			} catch (e) { }
		},
		toggleStreamKeyVisibility() {
			window.clearInterval(this.streamKeyVisibleTimeoutCtrl);
			const newState = !this.streamKeyVisible;
			this.streamKeyVisible = newState;

			if (newState) {
				const timeout = 1000;
				this.streamKeyVisibleTimeout = 9000;
				this.streamKeyVisibleTimeoutCtrl = setInterval(() => {
					this.streamKeyVisibleTimeout -= timeout;
					if (!this.streamKeyVisibleTimeout)
						this.toggleStreamKeyVisibility();
				}, timeout);

				// track event
				window.trackEvent(
					`Viewed Stream key in stream ${this.stream.name}`
				);
			}
		},
		toggleStreamKeyVisibility2() {
			window.clearInterval(this.streamKeyVisibleTimeoutCtrl2);
			const newState = !this.streamKeyVisible2;
			this.streamKeyVisible2 = newState;

			if (newState) {
				const timeout = 1000;
				this.streamKeyVisibleTimeout2 = 9000;
				this.streamKeyVisibleTimeoutCtrl2 = setInterval(() => {
					this.streamKeyVisibleTimeout2 -= timeout;
					if (!this.streamKeyVisibleTimeout2)
						this.toggleStreamKeyVisibility2();
				}, timeout);

				// track event
				window.trackEvent(
					`Viewed Stream key in stream ${this.stream.name}`
				);
			}
		},
		onStreamKeyCopied() {
			this.$notify({ group: 'info', text: 'Copied to clipboard' });
			// track event
			window.trackEvent(`Copied RTMP pull for stream ${this.stream.name}`);
		},
		getTrackType(track) {
			if (!track) return;

			let type;
			if (/^a/gi.test(track.id)) type = 'audio';
			else if (/^v/gi.test(track.id)) type = 'video';

			return type;
		},
		test(value) {
			return value
		},
		getStreamPushUrl(backupIngest) {
			const stream = _.cloneDeep(this.stream)
			const { uiRegion: region } = stream
			let hostname = region.hostname;

			if (this.transcoderView && !region.rtmpPort) {
				region.rtmpPort = 1935
			}

			if (this.failoverIngestToggleState && !backupIngest && this.hasFailoverIngestFeature) {
				hostname = this.failoverPrimaryIngest;
			}

			if (this.failoverIngestToggleState && backupIngest) {
				hostname = this.failoverBackupIngest;
			}

			let pushUrl = `rtmp://${hostname}`;
			if (region.rtmpPort !== 1935) {
				pushUrl += ':' + region.rtmpPort;
			}

			// @todo
			let prefixPath = _.get(region, 'config.prefixPath');
			prefixPath = prefixPath || '/static';
			prefixPath = _.replace(prefixPath, /^\/\//gi, '/');

			pushUrl += prefixPath;


			if (!this.hasFailoverIngestFeature && backupIngest) {
				pushUrl = 'xxxxxxxxxxxxxx';
			}

			if (this.stream.user === '62c4b3398b2e840b11244bfd') {
				pushUrl = 'rtmp://fifa.castr.io/static'
			}

			return pushUrl;
		},
		getStreamPullUrl() {
			let server = this.getStreamPushUrl();
			// override hostname with region stream is being received
			let hostId = this.mediaPulse && this.mediaPulse.hostId;

			if (this.stream.user === '62c4b3398b2e840b11244bfd') {
				hostId = 'fifa'
			}

			if (hostId) {
				const mappedHostname = `${hostId}.castr.io`;
				const defaultHostname = this.stream.uiRegion.hostname;
				server = server.replace(defaultHostname, mappedHostname);
				if (this.failoverIngestToggleState && this.hasFailoverIngestFeature) {
					server = `rtmp://${mappedHostname}/static/`
				}
			}

			if (!/\/$/gi.test(server)) {
				server += '/';
			}

			const pullUrl = server + this.stream.key;
			return pullUrl;
		},
		getAutoDetectDisabledClass(region) {
			if (this.autoDetectDisabled && region.hostname === 'live.castr.io') {
				return this.autoDetectDisabledClass;
			}
		},
		gotoUpgrade() {
			window.location.href = `/app/subscribe?category=live`;
		},
		getStreamURLAndKey() {
			let server = this.getStreamPushUrl();
			let key = this.streamKey;
			const UrlAndKey = server + '/' + key;

			return UrlAndKey;
		},
		checkActivems() {
			let hasActivems = false;
			this.subscription.map((s) => {
				if (s && s.enabled && s.package.category == 'restream') {
					hasActivems = true;
				}
			});
			return hasActivems;
		},
		changePasstoggle() {
			this.isPasswordEnabled = !this.isPasswordEnabled;
		},
		showStreamToggleModal(ev) {
			ev.preventDefault();
			ev.stopPropagation();
			if (!this.checkActivems() && this.stream.type == 'restream' && !this.stream.enabled) {
				// this.$root.$emit('bv::show::modal', 'discontinued-ms');
				this.$root.$emit('bv::show::modal', 'create-aio')
				return;
			}
			console.log(this.notAllowed);
			if (this.notAllowed && !this.stream.enabled) {
				this.$root.$emit('bv::show::modal', 'increase-limit');
			}
			if (this.cannotToggleStream) return;
			// if (this.stream.enabled){
			if ((!this.notAllowed || (this.notAllowed && this.stream.enabled))) {
				this.$root.$emit('bv::show::modal', 'modalToggleStream' + this.stream._id, '#btnShow')
			}
		},
		resetStreamPassword() {
			this.$root.$emit('bv::show::modal', 'modal-reset-stream-password');
		},
		async confirmResetStreamPassword() {
			try {
				await StreamService.resetStreamPassword(this.stream._id)
				const stream = await StreamService.getStream(this.stream._id)
				this.stream.config.password = stream.config.password
				this.$notify({ group: 'success', text: 'Password changed!' });
			} catch (error) {
				console.log("confirmResetStreamPassword ~ error:", error)
				this.$notify({ group: 'error', text: 'Change password error!' });
			}
		},
		async doCopy(value) {
			await navigator.clipboard.writeText(value);
			this.$notify({ group: 'info', text: 'Copied to clipboard' });
		},
		setDefaultAudioDevice(device) {
			this.audioDevice = device
		},
		setDefaultVideoDevice(device) {
			this.videoDevice = device
		},
		async removeLinkedSource() {
			if (this.linkedSource.type === 'tvplayout') {
				// Unlink old playout config
				let oldConfig = await PlayoutService.getPlayoutConfig(this.linkedSource._id)
				oldConfig.destination = ""
				oldConfig.settings.destinationEnabled = false
				await PlayoutService.savePlayoutConfig(this.linkedSource._id, oldConfig)
			} else {
				// Unlink old transcoder config
				let oldConfig = await TranscoderService.getTranscoder(this.linkedSource._id)
				if (oldConfig) await TranscoderService.updateTranscoder(this.linkedSource._id, { destination: null })
			}
		},
		async setLinkedSource() {
			if (!this.selectedLinkedSource) return

			// Case TV Playout
			if (this.selectedLinkedSource.type === 'tvplayout') {
				const playoutConfig = await PlayoutService.getPlayoutConfig(this.selectedLinkedSource._id)
				playoutConfig.destination = this.stream._id
				playoutConfig.settings.destinationEnabled = true
				await PlayoutService.savePlayoutConfig(this.selectedLinkedSource._id, playoutConfig)
			}

			// Case Transcoder
			if (this.selectedLinkedSource.key.startsWith('coder_')) {
				const transcoder = await TranscoderService.getTranscoder(this.selectedLinkedSource._id)
				await TranscoderService.updateTranscoder(this.selectedLinkedSource._id, { destination: this.stream._id })
			}

			if (this.linkedSource && this.linkedSource._id !== this.selectedLinkedSource._id) {
				await this.removeLinkedSource()
			}

			this.linkedSource = this.selectedLinkedSource
			this.selectedLinkedSource = null
			this.sourceSet = true
			this.$notify({ group: 'info', text: 'Set linked source successfully' });
		},
		async unsetLinkedSource() {
			await this.removeLinkedSource()
			this.linkedSource = null
			this.selectedLinkedSource = null
			this.sourceSet = false
			this.stream.removedSource = true
			this.streamSourceTypeModel = this.streamSourceType
			this.onSourceTypeChange(this.streamSourceTypeModel)
			this.$notify({ group: 'info', text: 'Removed Linked Source successfully' });
		},
		onLinkedSourceDismiss() {
			this.streamSourceType = 'linked'
			this.streamSourceTypeModel = 'linked'
			this.onSourceTypeChange()
		}
	}
};

function isMixerFTLSource(pullUrl) {
	// return /^https?\:\/\/(www\.)?mixer\.com/gi.test(pullUrl)
	return /^https?\:\/\/((\w+)\.)?mixer\.com/gi.test(pullUrl);
}
</script>

<style>
.password-toggle .custom-control-label::after {
	background-image: url('~@/assets/icons/unlocked.svg');
}

.password-toggle .custom-control-input:checked~.custom-control-label::after {
	background-image: url('~@/assets/icons/lock.svg');
}
</style>

<style scoped>
.flex-center {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.video-thumb,
.video-wrapper {
	width: 100%;
	min-height: 236px;
	background-color: #000;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px 8px 0 0;
}

.player-overlay {
	border-radius: 0 0 6px 6px;
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);
}

:deep(.player-overlay .position-absolute) {
	width: 100%;
	text-align: center;
}

:deep(.player-overlay .bg-blured) {
	background-color: rgba(30, 39, 69, 0.95);
	margin: -1rem;
	width: calc(100% + 2rem);
}

.b-overlay {
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);
}

.btn-sidebar-collapse {
	padding: 7px 16px;
}

.btn-sidebar-collapse:active,
.btn-sidebar-collapse:hover {
	color: #fff;
}

.btn-sidebar-collapse.not-collapsed svg {
	transform: translateY(-50%) rotate(0deg);
	transition: all .3s ease-in-out;
}

.btn-sidebar-collapse.collapsed svg {
	transform: translateY(0%) rotate(180deg);
	transition: all .3s ease-in-out;
}

.show-counter {
	font-size: var(--font-s-s);
	left: -10px;
}

.region-name {
	font-weight: 400;
	color: #fff;
}

.auto-detect-disabled {
	pointer-events: none;
	opacity: 0.6;
}

.region-header .dropdown-header {
	white-space: normal;
}

:deep(.region .region-list) {
	color: var(--c-dark-9);
	height: 350px;
	overflow-y: auto;
}

:deep(.region .region-list .checkin-circle) {
	color: transparent;
}

:deep(.region .region-list li:hover),
:deep(.region .region-list li:hover .checkin-circle) {
	color: #fff;
	background-color: var(--c-dark-4);
}

:deep(.region .region-list .checked),
:deep(.region .region-list .checked .checkin-circle) {
	color: #fff;
	background-color: transparent;
}

:deep(.region .region-list li:first-child) {
	background-color: var(--c-dark-4);
	position: sticky;
	top: 0;
}

:deep(.region .region-list li:last-child) {
	background-color: var(--c-dark-4);
	position: sticky;
	bottom: 0;
}

.region-title {
	font-weight: 400;
	color: #fff;
}

:deep(.region .b-dropdown-form) {
	padding: .75rem 1.5rem;
	display: flex;
	align-items: center;
	cursor: pointer;
}

:deep(.input-group) {
	@apply flex-nowrap;
}

.beta_backup {
	margin-right: 36px;
}

.backup_switch {
	margin-right: -15px;
}

.srt-split:after {
	content: "";
	width: 100%;
	height: 1px;
	background: var(--c-dark-4);
	position: absolute;
	left: 0;
	top: unset;
	bottom: 10px;
}

.srt-split span {
	background: var(--c-dark-2);
	z-index: 9;
}

.srt-split .dropdown-toggle:after {
	transform: translateY(2px);
}

.srt-split {
	transform: translateY(6px);
	cursor: pointer;
}

.srt-split .dropdown-toggle.toggled:after {
	border-top: 0;
	border-bottom: 0.3em solid;
}

@supports not (backdrop-filter: blur(2px)) {
	:deep(* .blur-fix) {
		filter: blur(2px)
	}
}

@media (max-width: 767px) {
	.btn-sidebar-collapse {
		margin-bottom: 1px;
	}
}

.tab-area {
	display: flex;
	font-size: var(--font-s-s);
	background-color: var(--c-dark-3);
	border-bottom: 1px var(--c-dark-3);
}

.tab-area a:hover {
	cursor: pointer;
	border-color: transparent;
	color: var(--surface-8);
}

.tab-area a {
	text-decoration: none;
	padding: 8px;
	padding-left: 16px;
	padding-right: 16px;
	font-size: var(--font-s-l);
}

.tab-area a.tab-selected {
	text-decoration: none;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	border-color: var(--surface-8);
}

.slide-fade-enter-active {
	transition: all .3s ease;
}

.slide-fade-leave-active {
	transition: all .3s ease;
}

.slide-fade-enter {
	transform: translateX(100px);
	opacity: 0;
}

.slide-fade-leave-to {
	transform: translateX(-100px);
	opacity: 0;
}

/* Back Scrolling */
.slide-fade-reverse-enter-active {
	transition: all .3s ease;
}

.slide-fade-reverse-leave-active {
	transition: all .3s ease;
}

.slide-fade-reverse-enter {
	transform: translateX(-100px);
	opacity: 0;
}

.slide-fade-reverse-leave-to {
	transform: translateX(100px);
	opacity: 0;
}

.btn-group_spacing .btn-dark-3.focus:not(.active) {
	@apply bg-surface-3 border-surface-3
}
</style>
