<script setup>
import { computed, watch, ref, onMounted, getCurrentInstance } from 'vue'
import PaywallService from "@/services/PaywallService";
import IconBanknote from "../../components/icon/IconBanknote.vue"
import IconChevronRight from "../../components/icon/IconChevronRight.vue"
import IconBin from "../../components/icon/IconBin.vue"
import FormSwitch from "../../components/Atoms/FormSwitch.vue"
import { useStore } from 'vuex'
import { useNotification } from "@kyvg/vue3-notification";
import FormButton from '../Atoms/FormButton.vue';
import FormInput from '../Atoms/FormInput.vue';
import FormUpload from '../Atoms/FormUpload.vue';
import IconBase from '../icon/IconBase.vue';
import IconBookOpen from '../icon/IconBookOpen.vue';
import IconElipsisVertical from '@/components/icon/IconElipsisVertical.vue'
import { useRoute, useRouter } from 'vue-router'
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';
import Title from '../ui/Title.vue'
import Alert from '../Atoms/Alert.vue';

const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()
const store = useStore()
const { notify } = useNotification()

const companyName = ref(null)
const companyUrl = ref(null)
const companyEmail = ref(null)

const props = defineProps({
  currentPaywall: {
    type: Function,
  }
})

onMounted(() => {
  companyName.value = props.currentPaywall?.company_name
  companyUrl.value = props.currentPaywall?.company_url
  companyEmail.value = props.currentPaywall?.company_email
})

const changeCompanyInfo = async (prop) => {
  if (prop === 'companyUrl') {
    const isUrl = string => {
      try {
        return Boolean(new URL(string));
      }
      catch (e) { return false; }
    }

    if (!isUrl(companyUrl.value)) {
      notify({
        group: 'error',
        text: "Invalid company url",
      })
      return
    }
  }

  try {
    const value = (prop === 'companyUrl') ? companyUrl.value
      : (prop === 'companyName')
        ? companyName.value
        : companyEmail.value
    console.log(prop);
    console.log(value);
    const obj = {}

    obj[(prop === 'companyUrl')
      ? 'company_url'
      : (prop === 'companyName')
        ? 'company_name'
        : 'company_email'] = value
    await PaywallService.updateStreamPaywallById(props.currentPaywall.id, obj)
    store.dispatch('Paywalls/setPaywallProp', { id: props.currentPaywall.id, prop, value })

    notify({
      group: "success",
      text: "Company information updated",
    });

  } catch (err) {
    console.log(err);
    notify({
      group: 'error',
      text: err.message || "Company change failed",
    })
  }
}
</script>

<template>
  <section class="flex flex-col gap-y-4">
    <FormInput label="Company name" v-model="companyName" placeholder="Enter your company name"
      @change="changeCompanyInfo('companyName')" />
    <FormInput label="Company website" v-model="companyUrl" placeholder="http://"
      @change="changeCompanyInfo('companyUrl')" />
    <FormInput label="Support email" v-model="companyEmail" placeholder="Enter your support email"
      @change="changeCompanyInfo('companyEmail')" />
  </section>
</template>

<style scoped></style>
