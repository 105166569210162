<template>
	<div>
		<div class="" @click="onClickButton">
			<div class="vod-item my-2 p-3" v-if="stream.totalFiles > 0">
				<img class="vod-item__icon" width="32" height="21" src="@/assets/images/Folder.svg" alt="Folder">
				<h3 class="vod-item__title">
					{{ stream.name | shortstring(25) }}
				</h3>
				<div class="vod-item__files">
					{{ stream.totalFiles }} files
				</div>
				<div class="vod-item__size">
					{{ getBytes }}	
				</div>
				<div class="vod-item__date">
						{{ stream.creationTime | date('hh:mm DD-MM-YYYY') }}
				</div>
			</div>
			<div class="vod-item my-1 py-2 px-4" v-if="stream.fileName">
				<input :id="stream.id" type="checkbox" class="vod-item__chebox">
				<label :for="stream.id"></label>
				<div class="vod-item__info">
					<h3 class="vod-item__title vod-item__title_files">
						{{ stream.fileName | shortstring(25) }}
					</h3>
					<span class="vod-item__res">{{stream.mediaInfo.width}}x{{stream.mediaInfo.height}}</span>
					<span class="mx-2">•</span>
					<span class="vod-item__res">
						<span v-if="stream.mediaInfo.duration<60">
							{{ stream.mediaInfo.duration }}
							<small>SECS</small>
						</span>
						<span v-else-if="stream.mediaInfo.duration">
							{{ stream.mediaInfo.duration/60|number }}
							<small>MINS</small>
						</span>
					</span>
				</div>
				<ul class="vod-item__presets">
					<li class="vod-item__preset-item" :key="preset.id" v-for="preset in stream.encodingPresets">{{preset}}</li>
				</ul>
				<div class="vod-item__size">{{ showFileSize }}</div>
				<div class="vod-item__date">
					{{ stream.creationTime | date('hh:mm DD-MM-YYYY') }}
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
import StreamThumb from '@/components/ui/StreamThumb.vue';
// import StreamPlayerHls from "@/components/ui/StreamPlayer.hls.vue";
import StreamService from '@/services/StreamService';
import StreamStatsService from '@/services/StreamStatsService';

export default {
	name: 'VODCardView',
	components: {
		StreamThumb
		// StreamPlayerHls
	},
	props: {
		stream: {
			type: Object,
			required: true,
		},
		compactMode: {
			required: true
		}
	},
	data() {
		return {
			VODList: [],
			scopeAlive: true,
			randId: Math.random()
				.toString()
				.slice(2),
			statusProcessing: false,
			streamStatus: this.stream.enabled,
			mediaPulse: null,
			mediaPulseTimeoutCtrl: null
		};
	},
	computed: {
		streamAlive() {
			return this.streamStatus && this.mediaPulse && this.mediaPulse.alive;
		},
		getBytes() {
			if(this.stream.totalBytes && this.stream.totalBytes > 0){
		   let totalBytes =  this.stream.totalBytes;
		   var i = Math.floor(Math.log(totalBytes) / Math.log(1000)),
			sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			return (totalBytes / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + sizes[i];
			}else{
				return 0;
			}
		},
		showFileSize() {
			if(this.stream.bytes && this.stream.bytes > 0){
		   let fileSize =  this.stream.bytes;
		   var i = Math.floor(Math.log(fileSize) / Math.log(1000)),
			sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			return (fileSize / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + sizes[i];
			}else{
				return 0;
			}
		}
	},
	mounted() {
		if (this.stream.type !== 'vod') {
			this.subscribeMediaPulse();
		}
	},
	destroyed() {
		this.scopeAlive = false;
		this.unsubscribeMediaPulse();
	},
	methods: {
		onClickButton(event) {
      this.$emit('chosenFolder', this.stream._id)
    },
		async showInnerFiles() {
			let streamId = this.stream._id;
			this.$emit('chosenFolder', streamId);
				try {
				const result = await StreamService.getStreamPlaylist(streamId);
				setTimeout(() => {
					this.VODList = result;
					this.loading = false;
				}, 100);
			} catch (err) {
				this.$notify({ group: "error", title: err.error, text: err.message });
				this.loading = false;
				return;
			}
		},
		subscribeMediaPulse() {
			const streamKey = this.stream.key;
			const self = this;
			(async function loop() {
				if (!self.scopeAlive) return;

				let timeoutInterval = 5000;
				let shouldUpdatePulse = self.stream.enabled;
				if (shouldUpdatePulse && document.hidden) {
					shouldUpdatePulse = false;
					timeoutInterval = 6000;
				}

				if (shouldUpdatePulse) {
					if (!document.hasFocus()) {
						timeoutInterval = 14000;
					}
				}

				if (shouldUpdatePulse) {
					try {
						const pulseObject = await StreamStatsService.getStreamMediaPulse(
							streamKey
						);
						self.onMediaPulse(pulseObject);
					} catch (e) {}
				}

				// reschedule
				self.mediaPulseTimeoutCtrl = setTimeout(
					loop.bind(this),
					timeoutInterval
				);
			}());
		},
		onMediaPulse(pulse = {}) {
			this.mediaPulse = pulse;
			this.$emit('pulse-update', this.stream._id, pulse);
		},
		unsubscribeMediaPulse() {
			clearTimeout(this.mediaPulseTimeoutCtrl);
		},
	}
};
</script>
	
<style scoped>
	img {
		width: auto;
		height: auto;
	}
	.vod-item {
		width: 100%;
		background-color: #1E2745;
	}
	.vod-item:empty{
		display: none;
	}
	.vod-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		min-height: 60px;
	}
	.vod-item__icon {
		height: 21px;
		width: 32px;
		flex: 0 0 32px;
	}
	.vod-item__chebox {
		flex: 0 0 32px;
	}
	/* Base for label styling */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
		top: -7px;
    padding-left: 40px;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #97A0AF;
    background: #373F5E;
    border-radius: 4px;
  }
  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: url('data:image/svg+xml,%3Csvg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8.49373 0.758435C8.83831 0.413855 9.39698 0.413855 9.74156 0.758435C10.0861 1.10302 10.0861 1.66169 9.74156 2.00627L4.44745 7.30039C4.10287 7.64497 3.54419 7.64497 3.19961 7.30039L0.258435 4.35921C-0.0861451 4.01463 -0.0861451 3.45596 0.258435 3.11138C0.603015 2.7668 1.16169 2.7668 1.50627 3.11138L3.82353 5.42863L8.49373 0.758435Z" fill="white"/%3E%3C/svg%3E%0A');
    position: absolute;
    top: 6px;
    left: 5px;
    font-size: 1.375em;
    color: #fff;
    line-height: 0;
    -webkit-transition: all .2s;
        transition: all .2s;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
  }

  /* Disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
  }

  [type="checkbox"]:disabled:checked + label:after {
    color: #777;
  }

  [type="checkbox"]:disabled + label {
    color: #aaa;
  }

  /* Accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    /* box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(203, 34, 237, .2); */
  }

	.vod-item__title {
		font-size: 16px;
		font-weight: 400;
		margin: 0;
		flex: 0 0 calc(40% - 32px);
	}
	.vod-item__info {
		margin: 0;
		flex: 0 0 calc(40% - 32px);
		display: flex;
		flex-wrap:  wrap;
	}
	.vod-item__title_files {
		flex: 0 0 100%;
	}
	.vod-item__files {
		flex: 0 0 20%;
		text-align: right;
	}
	.vod-item__size {
		font-size: 16px;
		flex: 0 0 20%;
		text-align: right;
	}
	.vod-item__date {
		font-size: 16px;
		flex: 0 0 20%;
		text-align: right;
	}
	.vod-item__presets {
		display: flex;
		flex: 0 0 20%;
	}
	.vod-item__preset-item {
		font-size: 12px;
		padding: 8px;
		background-color: #373F5E;
		border: none;
		border-radius: 6px;
		margin: 0 2px;
	}
	.vod-item__res {
		font-size: 14px;
		color: #FAFAFB;
	}
</style>
