<template>
	<div>
		<div class="content-container">
			<div class="tabs tabs-filled mt-0">
				<b-nav tabs>
					<b-nav-item
					v-for="(viewItem, $index) in childViews"
					:key="$index"
					:to="(viewItem.viewName && !viewItem.skip)? {name: viewItem.viewName} : null"
					@click="onClickNavItem($event, viewItem)"
					class="text-capitalize"
					:class="viewItem.className || null"
					tag="li"
					active-class="active">
						<span v-if="viewItem.name">{{ viewItem.name }}</span>
						<span
							v-else-if="viewItem.nameHtml"
							v-html="viewItem.nameHtml"
						/>
					</b-nav-item>
				</b-nav>
				<FormButton class="ml-auto float-right my-[1rem] md:mt-0" size="md" v-if="(stream.type === 'live' || stream.type === 'restream') && this.$route.path.includes('input-health') && !this.$route.path.includes('input-health/session')" :to="{ name: 'StreamsManageAnalyticsStreamSessions' }">
					Stream Session History
				</FormButton>
				<FormButton class="ml-auto" size="md" v-if="(stream.type === 'subsecond') && this.$route.path.includes('input-health') && !this.$route.path.includes('input-health/session')" :to="{ name: 'SubSecondManageAnalyticsStreamSessions' }">
					Stream Session History
				</FormButton>
				<div class="tab-content position-relative mt-4 move-down">
					<router-view v-slot="{ Component }">
						<component :is="Component" :stream="stream" :stream-alive="streamAlive" />
					</router-view>
				</div>	
			</div>
		</div>
			
	</div>
</template>

<script>
import FormButton from '../../components/Atoms/FormButton.vue';

export default {
	name: 'StreamManageAnalytics',
	components: {
		FormButton
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		streamAlive: {
			type: Boolean
		},
	},
	data() {
		return {
			childViewList: [
				{
					streamType: 'live',
					name: 'input health',
					viewName: 'StreamsManageAnalyticsInputHealthCharts',
				},
				{
					streamType: 'subsecond',
					name: 'input health',
					viewName: 'SubSecondManageAnalyticsInputHealthCharts',
				},
				{
					streamType: 'restream',
					name: 'input health',
					viewName: 'StreamsManageAnalyticsInputHealthCharts',
				},
				{
					streamType: 'live',
					name: 'player',
					viewName: 'LivestreamManageAnalyticsPlayer',
					skip: true,
				},
				{
					streamType: 'live',
					name: 'advertising',
					viewName: 'LivestreamManageAnalyticsAdvertising'
				},
			]
		}
	},
	computed: {
		childViews() {
			return (this.stream && this.childViewList.filter(v => {
				if (v.name === 'paywall' && !this.paywallFeature) {
					return false;
				}

				const isBitmovinPlayer = _.get(this.stream, ['metadata', 'bitmovinPlayer'], false)
				if (v.name === 'advertising' && !isBitmovinPlayer) {
					return false;
				}

				const isIncluded = v.streamType === this.stream.type;
				return isIncluded && v.validate ? v.validate(this.stream) : isIncluded;
			}));
		},
	},
	methods: {
		onClickNavItem(e, item) {
			if(item.name === 'player') {
				const route = this.$router.resolve({ name: 'AnalyticsDashboardWithRoute', params: { videoId: this.stream.key }, query: { stream: true } });
				window.open(route.href, '_blank');
			}
		}
	}
};
</script>

<style scoped>
:deep(.tabs-filled .nav-tabs li:nth-of-type(1) .nav-link:after) {
  content: "Player";
}
:deep(.tabs-filled .nav-tabs li:nth-of-type(2) .nav-link:after) {
  content: "Advertising";
}

.full-width {
	width: 200px;
}

.stream-session {
	float: right;
}

@media (max-width: 767px) {
  .stream-session {
		width: 100%;
		margin: 8px auto;
		float: none;
	}
}

.move-down {
	margin-top: 4rem !important;
}

</style>
