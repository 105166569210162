// import _ from 'lodash'
import Vue from 'vue'
import RequestError from './RequestError'
import api from '@/api';
import { getFromCache, setCache } from './CacheManager';

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

export default {
  getUserSubscriptions,
  getUserBillingHistory,
  getSubscriptionPackages,
  getnewSubscriptionPackages,
  orderSubscription,
  requestTrial,
  unsubscribeSubscription,
  createInvoicePaymentRequest,
  createInvoiceSubaccountPaymentRequest,
  getCancellationReasons,
  getUserInvoicePayments,
  checkCouponCode,
  getUserPaymentMethods,
  changePaymentInfo,
  unsubscribeSubAccount,
}

async function getUserPaymentMethods() {
  return makeRequest('/subscriptions/paymentMethods')
}

async function getUserInvoicePayments() {
  const config = {
    path: '/subscriptions/invoices'
  }
  let response = await makeRequest(config)
  return response
}

function getCancellationReasons() {
  let uri = '/subscriptions/cancellationReasons'
  return makeRequest(uri)
}

async function getUserSubscriptions(getPackageDetails, activeTeam = null) {
  if(activeTeam){
    const key = `/teams/info/${activeTeam}?role=true`;
    const data = getFromCache(key);
    if(data) return data;

    const token = localStorage.getItem('token', null, String);
    const config = {
      path: `/teams/info/${activeTeam}?role=true`,
      headers: {
        'auth-token': token
      }
    }
    let response = await makeRequest(config);
    setCache(key, response.subscription);
    return response.subscription;
  }else{
    const key = `/subscriptions/user:${getPackageDetails}`;
    const data = getFromCache(key);
    if(data) return data;

    const config = {
      path: '/subscriptions/user'
    }
    if (getPackageDetails) {
      config.params = { explicit: 1 }
    }
    let response = await makeRequest(config)
    setCache(key, response);
    return response
  }
}

function getUserBillingHistory() {
  return makeRequest('subscriptions/user/transactions')
}

function getSubscriptionPackages(packId) {
  let uri = '/subscriptions/packages'
  if (packId) {
    uri += `?packageId=${packId}`
  }

  return makeRequest(uri)
}
function getnewSubscriptionPackages(packId) {
  let uri = '/subscriptions/packages?newPlan2023=true'
  if (packId) {
    uri += `?packageId=${packId}`
  }

  return makeRequest(uri)
}

/**
 * @param {string} packageId
 * @param {number} quantity
 * @param {string} paymentSourceToken
 */
function orderSubscription(packageId, quantity, paymentSourceToken) {
  return makeRequest({
    path: '/subscriptions/order',
    method: 'post',
    data: {
      subscription: { package: packageId, quantity, paymentSourceToken }
    }
  })
}

/**
 * @param {string} packageId
 * @param {number} quantity
 * @param {string} paymentSourceToken
 */
function requestTrial(packCategory) {
  return makeRequest({
    path: `/subscriptions/trials/${packCategory}`,
    method: 'post'
  })
}

/**
 * @param {string} packCategory
 */
function unsubscribeSubscription(packCategory, cancelReason, cancelComment) {
  return makeRequest({
    path: `/subscriptions/${packCategory}/unsubscribe`,
    method: 'post',
    data: { cancelReason, cancelComment }
  })
}

/**
 * @param {string} packageId
 * @param {string} paymentMethod
 * @param {string} couponCode
 */
function createInvoicePaymentRequest(packageId, paymentMethod, couponCode) {
  return makeRequest({
    path: `/subscriptions/invoices/new`,
    method: 'post',
    data: { packageId, paymentMethod, couponCode }
  })
}

/**
 * @param {string} packageId
 * @param {string} subAccount
 * @param {string} paymentMethod
 * @param {string} couponCode
 */
 function createInvoiceSubaccountPaymentRequest(packageId, subAccount, paymentMethod, couponCode) {
  return makeRequest({
    path: `/subscriptions/invoices/sub_accounts/new`,
    method: 'post',
    data: { 
      packageId: packageId, 
      paymentMethod: paymentMethod, 
      sub_account_email: subAccount,
      couponCode: couponCode    
    }
  })
}

/**
 * @param {string} packageId
 * @param {string} paymentMethod
 * @param {string} couponCode
 */
function checkCouponCode(packageId, paymentMethod, couponCode) {
  return makeRequest({
    path: `/subscriptions/checkCouponCode`,
    method: 'post',
    data: { packageId, paymentMethod, couponCode }
  })
}

function changePaymentInfo() {
  return makeRequest({
    path: `/users/cc/requestChange`,
    method: 'get',
  })
}

/**
 * @param {string} cancelReason
 * @param {string} cancelComment
 * @param {string} sub_account_email
 * @param {string} subscription
 */
 function unsubscribeSubAccount(subscription, sub_account_email, cancelReason, cancelComment) {
  return makeRequest({
    path: `/subscriptions/sub_accounts/${subscription}/unsubscribe`,
    method: 'post',
    data: { 
      cancelReason: cancelReason, 
      cancelComment: cancelComment, 
      sub_account_email: sub_account_email }
  })
}

/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeRequest(reqConfig) {
  if (typeof reqConfig === 'string') {
    reqConfig = { path: reqConfig }
  }

  reqConfig.url = reqConfig.path

  let res
  try {
    res = await api.request(reqConfig)
  } catch (err) {
    let edata = _.get(err, 'response.data')
    throw new RequestError(edata)
  }

  return res.data
}
