import _ from "lodash";
import RequestError from "./RequestError";
import amProxyUrl from "./AmProxyUrl";
import api from "@/api";

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

export default {
  addPlayout,
	getPlayoutConfig,
  savePlayoutConfig
};

/**
 * @param {string} name
 * @param {string} regionId
 * @param {string} [streamType]
 */
function addPlayout(name, mode, timeZone) {
	return makeRequest({
		path: '/streams/deploy',
		method: 'post',
		data: {
			stream: {
				name,
				type: 'tvplayout',
        config: {
          mode,
          timeZone,
        }
			}
		}
	});
}

/**
 * @param {string} playoutId
 */
async function getPlayoutConfig(playoutId) {
	return makeRequest({
		path: `/streams/${playoutId}/tvplayout/config`,
		method: 'get',
	});
}


/**
 * @param {string} playoutId
 * @param {object} config
 */
async function savePlayoutConfig(playoutId, config) {
	return makeRequest({
		path: `/streams/${playoutId}/tvplayout/config`,
		method: 'put',
    data: {
      mode: config.mode,
      datetime: config.datetime,
      timeZone: config.timeZone,
      destination: config.destination,
      playlist: config.playlist,
      settings: config.settings,
    }
	});
}

/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeRequest(reqConfig, preventProxy, throwReal) {
  if (typeof reqConfig === "string") {
    reqConfig = {
      path: reqConfig,
    };
  }

  if (preventProxy !== true) {
    reqConfig = amProxyUrl(reqConfig, process.env.VUE_APP_VOD_API_BASE_URL);
  }

  reqConfig.url = reqConfig.path;

  let res;
  try {
    res = await api.request(reqConfig);
  } catch (err) {
    if (throwReal) {
      throw err;
    }
    const edata = _.get(err, "response.data");
    throw new RequestError(edata);
  }

  return res && res.data;
}
