import _ from 'lodash'
import RequestError from './RequestError'
import api from '@/api'

export default {
	initPaywall,
    stripeLinkingStatus,
	getStreamPaywall,
    activateStreamPaywall,
    updateStreamPaywall,
	getPaywall,
	getTransactions,
	getUsers,
	getPaywallStreams,
	getPaywallFinancialReport,
	refundPayment,
	paywallUploadLogo,
	updatePaywallSettings,
	getTemporaryLinks,
	createTemporaryLink,
	getPaywallStreamSource,
	getSourceTransactions,
	updateStreamPaywallSource,
	toggleStreamPaywall,
	deleteStreamPaywall,
	unlinkPaywall,
	updateStreamPaywallById,
	toggleStreamPaywallPopupAuth,
	addPaywallEmailAccess,
	removePaywallEmailAccess
}

function removePaywallEmailAccess(streamId, id, email) {
	return makeRequest({
		method: 'delete',
		path: `/paywall/${streamId}/removeEmailAccess`,
		data: { email, id }
	})
}

function addPaywallEmailAccess(streamId, email) {
	return makeRequest({
		method: 'post',
		path: `/paywall/${streamId}/grantEmailAccess`,
		data: { email }
	})
}

function updateStreamPaywallById(streamId, payload) {
	return makeRequest({
		method: 'post',
		path: `/paywall/${streamId}/update/byId`,
		data: payload
	})
}

function unlinkPaywall() {
	return makeRequest({
		method: 'delete',
		path: `/paywall`,
	})
}

function deleteStreamPaywall(streamId) {
	return makeRequest({
		method: 'delete',
		path: `/paywall/${streamId}/`,
	})
}

function toggleStreamPaywall(streamId) {
	return makeRequest({
		method: 'put',
		path: `/paywall/${streamId}/toggle`,
		data: {}
	})
}

function toggleStreamPaywallPopupAuth(paywall, conflictCheck = false) {
	return makeRequest({
		method: 'put',
		path: `/paywall/${paywall}/toggle/popupAuth${conflictCheck ? '?conflictCheck=true' : ''}`,
		data: {}
	})
}

function updateStreamPaywallSource(id, sourceId, payload) {
	return makeRequest({
		method: 'put',
		path: `/paywall/source/${id}/${sourceId}`,
		data: payload
	})
}

function getPaywallStreamSource(id) {
	return makeRequest(`/paywall/source/${id}`)
}

function createTemporaryLink(streamId, expireAt, expireMin) {
	return makeRequest({
		method: 'post',
		path: `/paywall/${streamId}/temporaryLink?expireAt=${expireAt}&expireMin=${expireMin}`,
		data: {}
	})
}

function getTemporaryLinks(streamId) {
	return makeRequest(`/paywall/${streamId}/temporaryLinks`)
}

function getUsers() {
	return makeRequest(`/paywall/stat/users`)
}

function updatePaywallSettings(payload, source = false) {
	return makeRequest({
		method: 'post',
		path: `/paywall/settings/metadata${source ? '?sourceId=' + source : ''}`,
		data: payload
	})
}

function paywallUploadLogo(fdata, source = false) {
	return makeRequest({
		path: `/paywall/settings/logoUpload${source ? '?sourceId=' + source : ''}`,
		method: 'post',
		data: fdata,
		headers: {
			'content-type': 'multipart/form-data'
		}
	});
}

function refundPayment(paymentId) {
	return makeRequest({
		method: 'post',
		path: `/paywall/payment/${paymentId}/refund`,
		data: {}
	})
}

function getPaywallFinancialReport() {
	return makeRequest(`/paywall/stat/financialReport`)
}

function getPaywallStreams(sort = 'asc') {
	return makeRequest(`/paywall/streams?sort=${sort}`)
}

function getTransactions() {
	return makeRequest(`/paywall/stat/transactions`)
}

function getSourceTransactions(id) {
	return makeRequest(`/paywall/stat/${id}/transactions`)
}

function getPaywall() {
	return makeRequest(`/paywall/info`)
}

function initPaywall() {
	return makeRequest({
		method: 'post',
		path: '/paywall/init',
		data: {}
	})
}

function activateStreamPaywall(streamId, payload) {
	return makeRequest({
		method: 'post',
		path: `/paywall/${streamId}/`,
		data: payload
	})
}

function updateStreamPaywall(streamId, payload, videoId = false) {
	return makeRequest({
		method: 'post',
		path: `/paywall/${streamId}/update${videoId ? '?videoId=' + videoId : ''}`,
		data: payload
	})
}

function stripeLinkingStatus() {
	return makeRequest('/paywall/activationStatus')
}

function getStreamPaywall(streamId) {
	return makeRequest(`/paywall/${streamId}/info`)
}

async function makeRequest(reqConfig) {
	if (typeof reqConfig === 'string') {
		reqConfig = {
			path: reqConfig
		}
	}

	reqConfig.url = reqConfig.path

	let res
	try {
		res = await api.request(reqConfig)
	} catch (err) {
		let edata = _.get(err, 'response.data')
		throw new RequestError(edata)
	}

	return res.data
}