<template>
	<div class>
		<!-- Modal Component -->
		<b-modal
			hide-header
			hide-footer
			@hidden="clearChosenStreams"
			id="modal-add-castr-stream"
			ref="modalAddCastrStream"
			:modal-class="['modal-left', 'add-platform', { 'modal-collapsed': stage >= 1 }]"
		>
			<div class="modal-container">
				<b-row align-v="start" class="px-3 mb-2">
					<b-col cols="9" class="mt-3">
						<h3 class="add-platform-title mt-2 mb-4 d-sm-flex align-items-center">
							Add Stream within Castr <svg v-b-tooltip.hover title="You can select streams within Castr as custom destination for pre-recorded stream." data-v-3503005c="" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-dark-9 ml-2"><path data-v-3503005c="" fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="currentColor"></path></svg>
						</h3>
					</b-col>
					<b-col cols="3" class="mt-3 text-right">
						<b-button
							variant="icon"
							class="px-2"
							@click="dismiss"
						>
							<img
								src="@/assets/images/menu-close.svg"
								alt="Menu close"
							>
						</b-button>	
					</b-col>
				</b-row>
				<b-row align-v="start" class="px-3">
					<!-- Case total destination -->
					<b-col cols="12" class="">
						<b-alert show variant="secondary" class="d-flex p-2 mb-3" v-if="platformUsage && platformUsage.totalDestination"> 
							<svg data-v-3503005c="" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-dark-9 mr-2 mt-1 flex-shrink-0"><path data-v-3503005c="" fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="currentColor"></path></svg>
							<div>
								<span class="text-dark-9 text-s-m text-500 m-0">You have used {{  platformUsage.usedDestination}}/{{ platformUsage.totalDestination}} destinations.</span>
								<span class="text-dark-7 text-s-s font-weight-normal m-0"> Need more? <a class="text-underline text-dark-8" target="_blank" href="/app/subscribe">Upgrade</a> here.</span>
							</div>
						</b-alert>
					</b-col>
					<!-- Case unique/extra destination -->
					<b-col cols="12" class="">
						<b-alert show variant="secondary" class="d-flex p-2 mb-3" v-if="platformUsage && platformUsage.uniqueDestination && platformUsage.uniqueDestination.limit"> 
							<svg data-v-3503005c="" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-dark-9 mr-2 mt-1 flex-shrink-0"><path data-v-3503005c="" fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="currentColor"></path></svg>
							<div>
								<span class="text-dark-9 text-s-m text-500 m-0">You have used {{  platformUsage.uniqueDestination.used}}/{{ platformUsage.uniqueDestination.limit}} unique destinations and {{ platformUsage.extraDestination.used}}/{{ platformUsage.extraDestination.limit}} extra ones<span v-if="getStreamtype()"> across all {{ getStreamtype() }}</span>.</span>
								<span class="text-dark-7 text-s-s font-weight-normal m-0"> Need more? <a class="text-underline text-dark-8" target="_blank" href="/app/subscribe">Upgrade</a> here.</span>
							</div>
						</b-alert>
						<b-alert 
							:show="this.dismissCountDown"
							@dismiss-count-down="countDownChanged"
							variant="danger" 
							class="d-flex align-items-center p-2 mb-3"
						> 
							<svg class="flex-shrink-0 mr-2" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M5.36957 3.79672C6.53108 1.67866 7.11184 0.619629 8 0.619629C8.88817 0.619629 9.46892 1.67866 10.6304 3.79672L14.0638 10.0575C15.15 12.0382 15.693 13.0285 15.2574 13.7643C14.8217 14.5 13.6923 14.5 11.4334 14.5H4.56665C2.30773 14.5 1.17826 14.5 0.742615 13.7643C0.306966 13.0285 0.850048 12.0382 1.93621 10.0575L5.36957 3.79672ZM7.99996 4.75004C8.41417 4.75004 8.74996 5.08583 8.74996 5.50004V8.00004C8.74996 8.41425 8.41417 8.75004 7.99996 8.75004C7.58575 8.75004 7.24996 8.41425 7.24996 8.00004V5.50004C7.24996 5.08583 7.58575 4.75004 7.99996 4.75004ZM8 12.0001C8.55228 12.0001 9 11.5523 9 11.0001C9 10.4478 8.55228 10.0001 8 10.0001C7.44771 10.0001 7 10.4478 7 11.0001C7 11.5523 7.44771 12.0001 8 12.0001Z" fill="currentColor"/>
							</svg> Please upgrade your subscription to add more streams.
						</b-alert>
					</b-col>
				</b-row>
				<b-row align-v="start" class="px-3">
					<b-col cols="12" class="">
						<b-input-group size="md" class="search-form order-0 order-md-1 mb-3 mb-md-0">
							<template #prepend>
								<b-input-group-text class="search-form__toggle text-dark-7" >
									<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M8.11096 15.2219C12.0382 15.2219 15.2219 12.0382 15.2219 8.11096C15.2219 4.18368 12.0382 1 8.11096 1C4.18368 1 1 4.18368 1 8.11096C1 12.0382 4.18368 15.2219 8.11096 15.2219Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M16.9994 16.9994L13.1328 13.1328" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
									</b-input-group-text>
							</template>
							<b-form-input
								ref="searchWidgetInputElement"
								v-model="searchWidgetInput"
								placeholder="Search for streams"
								class="search-form__fiel mb-4 pl-5"
							></b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row class="px-3">
					<b-col>
						<div class="grid-wrapper">
							<div
								v-for="(categoryName, index) in streamCategoryList"
								:key="index"
								class="mb-3 mb-md-5"
							>
							<b-button
								:aria-controls="categoryName+'-streams'"
								:class="categoryCollapseHash[categoryName] ? null : 'collapsed'"
								variant="link"
								:aria-expanded="categoryCollapseHash[categoryName] ? 'true' : 'false'"
								class="text-reset pl-4 font-weight-normal btn-category"
								@click="categoryCollapseHash[categoryName] = !categoryCollapseHash[categoryName]">
									<img
										:src="getCategoryIcon(categoryName)"
										:alt="categoryName+'streams'"
										class="mr-2"
									>
									<span class="mr-2">{{ categoryNameMaps[categoryName] || categoryName }}</span> 
									<b-badge variant="dark-3" :id="'stream_count_'+categoryName" class="stream-count text-500" pill>{{ streamFilter(categoryName).length }}{{ getStreamnumber(categoryName) }}</b-badge>
									<b-tooltip
										:target="'stream_count_'+categoryName"
										placement="top"
										v-if="getStreamnumber(categoryName)"
										>
										<span class="text-dark-8 text-small">
											You have created {{ streamFilter(categoryName).length }}{{ getStreamnumber(categoryName) }} streams. Need more? <a class="text-underline text-white" target="_blank" href="/app/subscribe">Upgrade here</a> </span>
									</b-tooltip>
								</b-button>
								<b-collapse class="mt-3" v-model="categoryCollapseHash[categoryName]" :id="categoryName+'streams'">
									<b-row>
										<b-col cols="12" v-for="stream in streamFilter(categoryName)"
										:key="stream._id"
										class="grid-item">
											<div v-if="!stream.added" @click="toggleChoose(stream)">
												<stream-card-view
													:stream="stream"
													:compact-mode="true"
													:light-mode="true"
													:chosenStreams="chosenStreams"
												/>
											</div>
											<div v-if="stream.added">
												<stream-card-view
													:stream="stream"
													:compact-mode="true"
													:light-mode="true"
													:chosenStreams="chosenStreams"
												/>
											</div>
										</b-col>
									</b-row>
								</b-collapse>
							</div>
						</div>
					</b-col>
				</b-row>
				<b-row align-v="start" class="px-3">
					<b-col>
						<b-button :disabled="chosenStreams.length === 0" v-if="!loading" variant="primary" size="md" class="mt-4 w-100" @click="addWithinCast">Save</b-button>
						<b-button disabled v-else variant="primary" size="md" class="mt-4 w-100">
							<Spinner text="Saving..." classes="text-dark-8" spinner-color="var(--c-dark-8)" spinner-size="15px" />
						</b-button>
					</b-col>
				</b-row>
			</div>
		</b-modal>

		<alert-modal
			:description="`Please add Castr application to your Facebook Group '${selectedSubChannel && selectedSubChannel.name}' to connect with Castr`"
			modal-id="modal-facebook-groups-guide"
			message="Please add Castr app to your Facebook Group (Settings -> Manage Advanced Settings -> Apps) or check your permission to stream to Group on Facebook. If you still encounter problem, please contact our support team."
			ok-text="Close"
			ok-href="https://web.facebook.com/help/261149227954100?_rdc=1&_rdr"
		/>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import AlertModal from '@/components/modals/AlertModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import StreamCardView from '@/components/ui/StreamCardView.vue';
import SubscriptionService from '@/services/SubscriptionService';

export default {
	name: 'AddCastrStreamModal',
	components: {
		AlertModal,
		ConfirmModal,
		Spinner,
		StreamCardView
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		platformAdded: Boolean,
		platformDeleted: Boolean,
		platformCastrFiltered: null,
		platformUsageData: Object
	},
	data() {
		return {
			streams: [],
			oauthProcessing: false,
			chosenServer: null,
			stage: 0,
			processing: false,
			error: null,
			serviceLinkError: false,
			serviceLinkConflict: false,
			platformServerInputMode: null,
			configRequired: false,
			linkedServiceCreds: null,
			loading: false,
			serviceLinkProcessing: false,
			platform: { enabled: false, configurableMetadata: {} },
			selectedSubChannel: null,
			customPlatform: false,
			// platformTemplates: Platforms,
			platformTemplates: [],
			platformListProcessing: false,
			serviceResponseRecieved: false,
			formErrors: { server: false, streamKey: false },
			oauthConnectId: null,
			isAuthRequired: false,
			rtmpAuthUsername: '',
			rtmpAuthPassword: '',
			streamCreateDropdownActive: false,
			maxLengthTitle: 1000,
			maxLengthDescription: 5000,
      showTitleLimitTracking: false,
			searchWidgetInput: '',
			isSchedulerInfiniteLoop: false,
			platformUsage: null,
			categoryCollapseHash: {
				restream: true,
				live: true,
				vod: true,
				ipcam: true,
			},
			categoryNameMaps: {
				live: 'All In One Stream',
				restream: 'Multistream',
				ipcam: 'IP Camera',
				vod: 'Video Hosting',
			},
			userSubscription: null,
			chosenStreams: [],
			dismissSecs: 10,
			dismissCountDown: 0,
		};
	},
	computed: {
		...mapGetters({
			baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions'
		}),
		user() {
			return this.$store.state.User;
		},
		streamCategoryList() {
			const cats = {};
			if (this.stream.type === 'subsecond') {
				this.streams.forEach(s => {
				if (s.type === 'live') cats[s.type] = s.sortOrder;
			});
			} else {
				this.streams.forEach(s => {
				if(s.type != 'vod' && s.type != 'scheduled' && s.type != 'ipcam' && s.type != 'tvplayout' && s.type != 'subsecond')
				cats[s.type] = s.sortOrder;
			});
			}
			let categoryList = Object.keys(cats).sort(
				(cat1, cat2) => cats[cat1] > cats[cat2]
			);
			categoryList = _.sortBy(categoryList, cat => cats[cat]);

			return categoryList;
		},
		canSave() {
			const validStage = this.stage > 0;
			return validStage;
		},
		streamFilter() {
			return categoryName => {
				const filteredStreams = this.streams.filter(s => {
					let bool = s.type === categoryName;
					if (bool && this.searchWidgetInput) {
						const searchStr = this.searchWidgetInput.toLowerCase();
						bool = s.name.toLowerCase().includes(searchStr);
					}
					return bool;
				});

				return filteredStreams;
			};
		},
		activeTeam(){
			return this.$store.state.User.activatedTeam;
		}
	},
	watch: {
		platformAdded: async function() {
			const platformUsage = await StreamService.getPlatformUsage(this.stream.type);
			if(platformUsage) this.platformUsage = platformUsage;
		},
		platformDeleted: async function() {
			this.fetchPlatformUsage();
			try {
				const streamObj = await StreamService.getStream(this.stream._id);
				this.streams = this.streams.map((stream) => {
					const addedStream = streamObj.platforms.find(({ streamObject }) => {
						if (streamObject) return streamObject._id === stream._id
					})
					if (addedStream) {
						stream.added = true
					} else stream.added = false
					return stream
				})
			} catch (error) {
				console.log(error);
			}
		},
		platformUsageData: function(){
			this.platformUsage = this.platformUsageData;
		},
		isAuthRequired: function(isRequired) {
			if (!isRequired) {
				this.platform.server = this.platform.server.replace(
					/:\/\/.*?:.*?@/,
					'://'
				);
			} else {
				this.rtmpAuthUsername = '';
				this.rtmpAuthPassword = '';
			}
		},
	},
	async mounted() {
		this.$refs.modalAddCastrStream.$on('hide', this.onDismiss.bind(this));
		this.$refs.modalAddCastrStream.$on('show', this.onInit.bind(this));
		try {
			const streams = await StreamService.getUserStreams();
			setTimeout(() => {
				this.streams = streams;
				this.loading = false;
				this.userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions}

				this.streams.map((stream) => {
					const addedStream = this.stream.platforms.find(({ streamObject }) => {
					if (streamObject) return streamObject._id === stream._id
				})
				if (addedStream) stream.added = true
				return stream
				})
			}, 100);
		} catch (err) {
			this.$notify({ group: 'error', title: err.error, text: err.message });
			this.loading = false;
			return;
		}
	},
	methods: {
		async onInit() {
		},
		getCategoryIcon(categoryName) {
			return require(`@/assets/icons/stream-type-${categoryName}-ico.svg`); // eslint-disable-line no-undef
		},
		getStreamnumber(type) {
				let streamCountarray = []
				if(this.userSubscription.subscription && this.userSubscription.subscription.enabled && this.userSubscription.subscription.package.category == type){
					let streamCountoverride = _.get(this.userSubscription.subscription, ['definitionOverride', 'streams']);
					if(streamCountoverride){
						streamCountarray.push(streamCountoverride);
					}else{
						streamCountarray.push(_.get(this.userSubscription.subscription, ['package', 'definition', 'streams']));
					}
				}
				const typeFilteredaddonsub = _.map(this.userSubscription && this.userSubscription.addonSubscriptions, function(single){
					if(single.enabled && single.category == type){
						let streamCountoverride = _.get(single, ['definitionOverride', 'streams']);
						if(streamCountoverride){
							streamCountarray.push(streamCountoverride);
						}else{
							streamCountarray.push(_.get(single, ['package', 'definition', 'streams']));
						}
					}
				})
				if(streamCountarray.length) return '/'+(streamCountarray.length > 1 ? Math.max.apply(Math, streamCountarray) : streamCountarray[0]);
		},
		getStreamtype() {
			const category = this.stream.type;
			let CategoryName = null;
			if(category == 'live'){
				CategoryName = 'All in One Streams'
			}else if(category == 'scheduled'){
				CategoryName = 'Pre-recorded Streams'
			}else if(category == 'restream'){
				CategoryName = 'Multistreams'
			}
			return CategoryName;
		},
		stepBack() {
			this.stage--;

			this.clearErrors();
			this.resetPlatform();
		},
	
		dismiss() {
			this.$refs.modalAddCastrStream.hide();
			this.onDismiss();
		},
		
		onDismiss() {
			// clear up cache
			this.clearErrors();
			this.dismissCountDown = 0

			// lazy clear
			setTimeout(() => {
				this.stage = 0;
				this.processing = false;
			}, 1000);
		},
		clearErrors() {
			this.error = null;
			this.formErrors.server = false;
			this.formErrors.streamKey = false;
		},
		toggleChoose(stream) {
			// const chosenStreams = this.chosenStreams;
			const result = this.chosenStreams.find( ({ _id }) => _id === stream._id );
			if (!result ) {
				const addedStream = this.stream.platforms.find(({ streamObject }) => {
					if (streamObject) return streamObject._id === stream._id
				})
				if (addedStream) stream.added = true
				this.chosenStreams.push(stream);
			} else {
				let index;
				index = this.chosenStreams.indexOf(stream);
				this.chosenStreams = _.concat(
					this.chosenStreams.slice(0, index),
					this.chosenStreams.slice(index + 1)
				);
			}
			// console.log(this.chosenStreams.find(stream._id));
		},
		async fetchPlatformUsage() {
			const platformUsage = await StreamService.getPlatformUsage(this.stream.type);
			if(platformUsage) this.platformUsage = platformUsage
		},
		async addWithinCast() {
			this.loading = true;
			this.chosenStreams.forEach(async (video) => {
				const payload = {
					template: 'custom',
					server: video.ingest.address,
					key: video.ingest.key,
					region: video.region.name,
					enabled: false,
					castrName: video.name,
					castrStream: true,
					streamObject: video._id
				};
				if (video.config && video.config.password) {
					payload.key += '?password=' + video.config.password;
				}
				// await StreamService.addStreamPlatform(this.stream._id, payload);
				if (!video.added) {
					try {
						await StreamService.addStreamPlatform(this.stream._id, payload);
						this.$emit('platform-saved', payload);
						this.$emit('chosen-streams', this.chosenStreams);
						// await StreamService.setStreamPlatformName(
						// 	this.stream._id,
						// 	video._id,
						// 	video.name
						// );
						this.dismiss();
						this.loading = false;
						video.added = true;
						const platformUsage = await StreamService.getPlatformUsage(this.stream.type);
						if(platformUsage) this.platformUsage = platformUsage;
					}
					catch(error) {
						// console.error(error.stack);
						// if (error.message === 'Please upgrade your subscription to add more streams and platforms.') {
						// 	return this.error = error;
						// }
						this.dismissCountDown = this.dismissSecs;
						this.loading = false;
					}
				}	else {
					this.loading = false;
					this.dismiss();
				}
			});
		},
		clearChosenStreams(e) {
			this.chosenStreams = [];
		},
		countDownChanged(dismissCountDown) {
			this.dismissCountDown = dismissCountDown
		},
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.platform-list {
	max-height: calc(100% - 200px);
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
}
.platform-list>li:empty {
	display: none;
}
.platform-item {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	cursor: pointer;
}
.platform-item.active {
	background-color: var(--c-main-1);
}
.platform-icon img {
	max-height: 40px;
	object-fit: contain;
	margin-bottom: .5rem;
	width: 100%;
}
.platform-icon_secondary {
	max-height: 40px;
	object-fit: contain;
}
.second-modal {
		position: fixed;
		top: 0;
		background-color: var(--c-dark-2);
		margin: auto;
		width: 410px;
		height: 100%;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, left 0.3s ease-out;
		left: 0;
		z-index: -1;
		border-left: 1px solid var(--c-dark-3);
		border-right: 1px solid var(--c-dark-3);
	}
	.showform.second-modal {
		left: 510px;
		
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, left 0.3s ease-out;
	}
#service-subchannels-dropdown .hint-upgrade {
   display: none;
}
#service-subchannels-dropdown .disabled .hint-upgrade {
   display: block;
}
.lined {
	position: relative;
	display: flex;
	align-content: center;
	justify-content: center;
	white-space: nowrap;
}
.lined:before {
	content: '';
	position: relative;
	border-top: 1px solid var(--c-dark-3);
	width: 100%;
	top: 12px;
	margin-right: 10px;
}
.lined:after {
	content: '';
	position: relative;
	border-top: 1px solid var(--c-dark-3);
	width: 100%;
	top: 12px;
	margin-left: 10px;
}
:deep( * .dropdown-menu_md, .tests)	{
	max-height: 300px;
	overflow-y: auto;
	max-width: 100%;
}
:deep(.page-item .dropdown-item) {
	white-space: normal;
}
.platform-label {
	text-transform: capitalize;
	text-align: center;
}
.add-platform-title {
	font-size: 20px;
}
.platform-item:hover {
	background-color: var(--c-dark-3);
}
.grid-wrapper {
	max-height: calc(100vh - 350px);
	overflow-x: hidden;
	overflow-y: auto;
}
:deep(.grid-item .castr-card) {
	margin-bottom: 0;
}
.search-form__toggle {
	z-index: 10;
}
@media (max-width: 767px) {
	.showform.second-modal {
		top: 70px;
		left: 0;
		z-index: 1030;
		max-width: 100%;
	}
}
</style>