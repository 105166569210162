<template>
	<div class>
		<b-modal 
			id="modal-edit-origin" 
			ref="modalEditOrigin" 
			:size="modalSize" 
			hide-header 
			hide-footer 
			centered
		>
			<b-row>
				<b-col>
					<h5>Edit Origin URL</h5>
					<div class="mt-3">
						<label>Your new origin Domain</label>
						<b-input 
							size="md" 
							v-model="originUrl" 
							class="mb-2" placeholder="Enter new domain or IP address" />
					</div>
					<div class="text-right w-100">
						<b-button variant="outline-secondary" class="mr-2" size="md" @click="dismiss">
							Cancel
						</b-button>
						<b-button 
							variant="primary" 
							@click="edit(originUrl)" 
							size="md"
							:disabled="this.originUrl === '' || processing ">
							{{ !processing ? 'Save Change' : 'Saving' }}
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import ClipLoader from "@/components/ui/ClipLoader.vue";

export default {
	name: 'EditOriginModal',
	components: {
        ClipLoader,
    },
	props: {
      	editOrigin: { 
			type: Function 
		},
		modalSize: {
			type: String,
			default: 'sm',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
	},
	data() {
		return {
			processing: false,
            originUrl: ''
		};
	},
	computed: {
	},
    async mounted() {},
	methods: {
		dismiss() {
			this.$refs.modalEditOrigin.hide();
			this.originUrl = ''
			setTimeout(() => { this.processing = false; }, 1000);
		},
        async edit(url){
			if (url === '') {
				return
			}
			this.processing = true
            await this.editOrigin(url);
			this.processing = false
            this.dismiss();
        }
	},
};
</script>

<style>
</style>