<template>
  <b-container id="resizible-wrapper" class="highlight-container mx-auto px-0 ml-0 position-relative">
    <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)"
      spinner-size="15px" />
    <main v-else>
      <section class="text-center mt-200" v-if="!isAccessHighlight">
        <h2 class="text-s-m text-dark-9 text-500 mb-2">Upgrade required</h2>
        <p class="text-s-s text-dark-7 text-normal col-lg-4 mx-auto mb-4">Live Clipping is not available in your account. Please upgrade for the fullest experience.</p>
        <div style="max-width:200px" class="d-flex flex-column mx-auto">
          <b-button variant="outline-secondary mb-0 text-s-xs text-400 w-100 mb-2" target="_blank" href="https://docs.castr.com/en/articles/8856289-highlights" size="lg">
          <svg data-v-87caa36c="" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1"><path data-v-87caa36c="" d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path><path data-v-87caa36c="" d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path></svg>
            How Live Clipping works
          </b-button>
          <b-button class="mb-3 text-s-xs text-400" target="_blank" variant="outline-secondary" v-b-modal.modal-demo-video>
          <svg data-v-87caa36c="" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1"><path data-v-87caa36c="" d="M6.98597 3.65906L10.6171 5.62591C12.3187 6.54763 13.1695 7.0085 13.3161 7.68016C13.3621 7.89091 13.3621 8.10909 13.3161 8.31984C13.1695 8.9915 12.3187 9.45237 10.6171 10.3741L6.98597 12.3409C5.41916 13.1896 4.63576 13.614 4.01328 13.4004C3.81704 13.3331 3.6369 13.2258 3.48425 13.0853C3 12.6397 3 11.7487 3 9.96685V6.03315C3 4.25126 3 3.36032 3.48425 2.91468C3.6369 2.77419 3.81704 2.6669 4.01328 2.59958C4.63576 2.38604 5.41916 2.81038 6.98597 3.65906Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          Live Clipping Demo
          </b-button>
          <a href="/app/subscribe">
            <b-button
              variant="primary"
              size="md"
            >
              Upgrade now
            </b-button>
          </a>
        </div>
      </section> 

      <section class="container" v-else-if="this.rawHighlight && (this.rawHighlight.length > 0)">
      <div class="mt-5">
        <div class="title d-flex justify-content-between align-items-center mb-4">
          <h1 class=" mb-0 text-s-xxl">Live Clipping</h1>
          <button type="button" class="btn btn-primary btn-md" v-b-modal="'modal-add-highlight'">
            <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00002 1.89999C8.3314 1.89999 8.60002 2.16862 8.60002 2.49999V7.39999H13.5C13.8314 7.39999 14.1 7.66862 14.1 7.99999C14.1 8.33136 13.8314 8.59999 13.5 8.59999H8.60002V13.5C8.60002 13.8314 8.3314 14.1 8.00002 14.1C7.66865 14.1 7.40002 13.8314 7.40002 13.5V8.59999H2.50002C2.16865 8.59999 1.90002 8.33136 1.90002 7.99999C1.90002 7.66862 2.16865 7.39999 2.50002 7.39999H7.40002V2.49999C7.40002 2.16862 7.66865 1.89999 8.00002 1.89999Z" fill="white"/>
            </svg>
            <span > Create Live Clipping </span>
          </button>
        </div>
        <div class="toolbar-container tab-settings">
					<div class="d-flex w-100 flex-wrap flex-md-nowrap align-items-center my-3">
						<div class="mr-0 mr-md-2 mb-3 mb-md-0 filter-wrapper order-1 order-md-0">
							<b-dropdown class="w-100" variant="dark-3" no-caret
								toggle-class="d-flex align-items-center justify-content-between font-weight-normal"
								menu-class="dropdown-menu_md w-100">
								<template #button-content>
									<div class="d-flex align-items-center w-100" style="min-width:240px">
										<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.39999 3.99999C1.39999 3.66862 1.66862 3.39999 1.99999 3.39999H14C14.3314 3.39999 14.6 3.66862 14.6 3.99999C14.6 4.33136 14.3314 4.59999 14 4.59999H1.99999C1.66862 4.59999 1.39999 4.33136 1.39999 3.99999ZM2.89999 7.99999C2.89999 7.66862 3.16862 7.39999 3.49999 7.39999H12.5C12.8314 7.39999 13.1 7.66862 13.1 7.99999C13.1 8.33136 12.8314 8.59999 12.5 8.59999H3.49999C3.16862 8.59999 2.89999 8.33136 2.89999 7.99999ZM4.99999 11.4C4.66862 11.4 4.39999 11.6686 4.39999 12C4.39999 12.3314 4.66862 12.6 4.99999 12.6H11C11.3314 12.6 11.6 12.3314 11.6 12C11.6 11.6686 11.3314 11.4 11 11.4H4.99999Z" fill="white"/>
                    </svg>
										<!-- <span class="text-dark-5">Sort By:</span>  -->
										<span class="ml-1 text-s-ss text-400">{{ sortBy }}</span>
									</div> <i class="ml-2 fa fa-chevron-down" />
								</template>
								<b-dropdown-item :active="sortBy === 'A to Z'" @click="changeSortBy('A to Z')">
									<div class="ml-2">A to Z</div>
								</b-dropdown-item>
								<b-dropdown-item :active="sortBy === 'Z to A'" @click="changeSortBy('Z to A')">
									<div class="ml-2">Z to A</div>
								</b-dropdown-item>
							</b-dropdown>
						</div>
						<b-input-group size="md" :class="{ active: searchWidgetActive }"
							class="search-form order-0 order-md-1 mb-3 mb-md-0">
							<template #prepend>
								<b-input-group-text class="search-form__toggle color-light" @click="toggleSearchWidget">
									<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M8.11096 15.2219C12.0382 15.2219 15.2219 12.0382 15.2219 8.11096C15.2219 4.18368 12.0382 1 8.11096 1C4.18368 1 1 4.18368 1 8.11096C1 12.0382 4.18368 15.2219 8.11096 15.2219Z"
											stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M16.9994 16.9994L13.1328 13.1328" stroke="currentColor" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</b-input-group-text>
							</template>
							<b-form-input ref="searchWidgetInputElement" v-model="searchWidgetInput"
								:placeholder="searchWidgetActive ? 'Search for streams ..' : ''" class="search-form__field"
								@focus="onSearchWidgetFocus" @blur="onSearchWidgetBlur"></b-form-input>
						</b-input-group>
					</div>
				</div>
      </div>
      <b-row class="mt-3">
          <b-col 
              v-for="h in highlight" :key="h._id" class="grid-item col-md-6 col-lg-4 col-12">
              <highlight-card-view @delete-highlight="onDeleteHighlight" :streamhighlight="h" />
          </b-col>
      </b-row>
      </section>

      <section class="text-center mt-200" v-else>
        <h2 class="text-s-m text-dark-9 text-500 mb-2">Create your first highlight</h2>
        <p class="text-s-s text-dark-7 text-normal col-lg-4 mx-auto mb-4">Choose a stream and highlight a moment.</p>
        <div style="max-width:175px" class="d-flex flex-column mx-auto">
        <b-button variant="outline-secondary mb-0 text-s-xs text-400 w-100 mb-2" target="_blank" href="https://docs.castr.com/en/articles/8856289-highlights" size="lg">
          <svg data-v-87caa36c="" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1"><path data-v-87caa36c="" d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path><path data-v-87caa36c="" d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"></path></svg>
            How Live Clipping works
          </b-button>
          <b-button class="mb-3 text-s-xs text-400" target="_blank" variant="outline-secondary" v-b-modal.modal-demo-video>
          <svg data-v-87caa36c="" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1"><path data-v-87caa36c="" d="M6.98597 3.65906L10.6171 5.62591C12.3187 6.54763 13.1695 7.0085 13.3161 7.68016C13.3621 7.89091 13.3621 8.10909 13.3161 8.31984C13.1695 8.9915 12.3187 9.45237 10.6171 10.3741L6.98597 12.3409C5.41916 13.1896 4.63576 13.614 4.01328 13.4004C3.81704 13.3331 3.6369 13.2258 3.48425 13.0853C3 12.6397 3 11.7487 3 9.96685V6.03315C3 4.25126 3 3.36032 3.48425 2.91468C3.6369 2.77419 3.81704 2.6669 4.01328 2.59958C4.63576 2.38604 5.41916 2.81038 6.98597 3.65906Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          Live Clipping Demo
          </b-button>
          <b-button size="md" class="px-0" variant="primary" v-b-modal="'modal-add-highlight'">
            <svg class="mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.99999 0.399902C6.33136 0.399902 6.59999 0.668532 6.59999 0.999902V5.3999H11C11.3314 5.3999 11.6 5.66853 11.6 5.9999C11.6 6.33127 11.3314 6.5999 11 6.5999H6.59999V10.9999C6.59999 11.3313 6.33136 11.5999 5.99999 11.5999C5.66862 11.5999 5.39999 11.3313 5.39999 10.9999V6.5999H0.999994C0.668623 6.5999 0.399994 6.33127 0.399994 5.9999C0.399994 5.66853 0.668623 5.3999 0.999994 5.3999H5.39999V0.999902C5.39999 0.668532 5.66862 0.399902 5.99999 0.399902Z"
                fill="currentColor" />
            </svg>
            Create Live Clipping
          </b-button>
        </div>
      </section>
  
    </main>
    <confirm-modal
					modal-type="danger"
					message="Would you like to delete this Live Clipping and all of its content?"
					@modal-confirm="onHighlightDeleteConfirm"
				/>
    <add-highlight-modal @highlight-created="createdHighlight" :highlight="highlight"/>
        <demo-video-modal videoid="E5NGhYcn07xZKW-9qMSTy" />
  </b-container>
</template>

<script>
import _ from "lodash";
import { mapGetters } from 'vuex'
import AddHighlightModal from "@/components/modals/AddHighlightModal.vue"
import Spinner from "@/components/ui/Spinner.vue";
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import HighlightCardView from '@/components/ui/HighlightCardView.vue';
import StreamService from '@/services/StreamService';
import HighlightService from '@/services/HighlightService';
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';

export default {
  name: "ManageHighlights",
  components: {
    Spinner,
    ConfirmModal,
    AddHighlightModal,
    DemoVideoModal,
    HighlightCardView
  },
  computed: {
    ...mapGetters({
      loadingStatus: "Ui/loadingStatus",
      highlight: "Highlight/highlight",
      allInOneStreams: "Streams/allInOneStreams",
      subscriptions: "User/subscriptions",
    }),
	  isAccessHighlight() {
			let sub = null
			this.subscriptions.some((item) => {
				if (item.enabled && item.category === 'live') sub = item
			})
			let isValidPlanOverrideDefinition = null
			let isValidPlanDefinition = false
			if (sub) {
				if (sub?.package?.definition?.maxHighlightCount) isValidPlanDefinition = sub.package.definition.maxHighlightCount
				if (sub?.definitionOverride?.maxHighlightCount) isValidPlanOverrideDefinition = sub.definitionOverride.maxHighlightCount
			}
			if (isValidPlanOverrideDefinition) return isValidPlanOverrideDefinition
			return isValidPlanDefinition
		},
  },
  async mounted() {
    this.$store.dispatch('Ui/setLoadingStatus', true)
    await this.$store.dispatch('User/loadUser', null, { root: true });
    await this.$store.dispatch('Highlight/getHighlight')
    await this.$store.dispatch('Streams/getStreams');
    
    this.highlight.map(h=>{
      let streamobj = _.cloneDeep(this.allInOneStreams.filter(stream => stream._id === h.stream))
      h.streamobj = streamobj
    });
    this.rawHighlight = this.highlight;
     setTimeout(() => {
      this.$store.dispatch('Ui/setLoadingStatus', false)
    }, 1000);
  },
  methods: {
    toggleSearchWidget() {
			this.searchWidgetActive = !this.searchWidgetActive;
			this.$refs.searchWidgetInputElement.focus();
			if (!this.searchWidgetActive) {
				this.searchWidgetInput = '';
			}
		},
		onSearchWidgetFocus() {
			this.searchWidgetFocused = true;
		},
		onSearchWidgetBlur() {
			this.searchWidgetFocused = false;
			setTimeout(() => {
				const blankSearchVal =
					!this.searchWidgetInput || !this.searchWidgetInput.length;
				if (
					this.searchWidgetActive &&
					blankSearchVal &&
					!this.searchWidgetFocused
				) {
					this.toggleSearchWidget();
				}
			}, 500);
		},
    changeSortBy(val) {
			this.sortBy = val;
      this.$store.dispatch('Highlight/setSortOption', this.sortBy)
		},
      async onHighlightDeleteConfirm() {
			// pop stream out of the list
			// const index = this.streams.indexOf(this.deleteModalConfiguredStream);
			// this.streams = _.concat(
			// 	this.streams.slice(0, index),
			// 	this.streams.slice(index + 1)
			// );

			try {
				await HighlightService.deleteHighlight(this.deleteModalConfiguredStream._id, this.deleteModalConfiguredStream.name);
await this.$store.dispatch('Highlight/getHighlight')
this.rawHighlight = this.highlight
				// track event
				const removedStream = this.deleteModalConfiguredStream;
				window.trackEvent(
					`Deleted Live Clipping ${removedStream.name}`,
					removedStream
				);
				this.$notify({ group: 'info', text: 'The Live Clipping was deleted' });
			} catch (err) {
				this.$notify({ group: 'error', title: err.error, text: err.message });
				// push stream back to list
				// this.onNewStream(this.deleteModalConfiguredStream);
			}

			this.deleteModalConfiguredStream = null;
		},
    createdHighlight(h) {
      this.hasHighlight = true;
      this.$router.push({ path: '/highlight/' + h._id }).catch(() => { });
    //   this.selectPlaylist(0);
    },
    onDeleteHighlight(stream) {
        this.deleteModalConfiguredStream = stream;
        this.$root.$emit('bv::show::modal', 'modal-confirm');
    },
  },
  watch: {
    searchWidgetInput() {
      if(this.searchWidgetInput.length > 0){
      this.$store.dispatch('Highlight/searchHighlight', this.searchWidgetInput)
      }
    },
  },
  data() {
    return {
      playlistIndex: 0,
      hasHighlight: false,
      deleteModalConfiguredStream: null,
      sortBy: 'A to Z',
      searchWidgetActive: false,
			searchWidgetFocused: false,
			searchWidgetInput: '',
      rawHighlight: [],
      allHighlights: null
    };
  },
}

</script>

<style scoped>
.active .search-form__toggle {
	z-index: 9;
}

.search-form__field {
	border: none;
	width: 0;
	flex: 0 0 0;
	transition: all .2s ease-out;
	padding-left: 0;
	padding-right: 0;
	caret-color: var(--c-main-light);
}

.search-form__field::placeholder {
	color: var(--c-dark-6);
}

.input-group>.search-form__field {
	border-radius: 6px;
}

.active .search-form__field {
	width: 100%;
	max-width: 620px;
	flex: 1 1 auto;
	transition: all .3s ease-out;
	padding-left: 40px;
	padding-right: 16px;
	border: 1.5px solid var(--c-main-light);
	background: var(--c-dark-2);
}

.search-form__close {
	visibility: hidden;
}

.active .search-form__close {
	visibility: visible;
}
</style>