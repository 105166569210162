<template>
	<b-container class="mt-2 mt-md-5">
		<Spinner v-if="processing" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)"
			spinner-size="15px" />
		<b-row class="main-content" v-else :class="streamType + '_wrap'">
			<b-col class="content" lg="8">
				<div class="d-flex align-items-start w-100">
					<b-link to="/sub-second" class="btn btn-md btn-link-7 d-none d-md-block mt-2">
						<svg width="6" height="12" class="mr-2" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7 13L1 7L7 1" stroke="currentColor" stroke-width="2" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</b-link>
					<textarea v-model="streamName" ref="streamTitle" rows="1" autocomplete="off" autocorrect="off"
						autocapitalize="off" spellcheck="false" title="Edit stream name"
						class="page-title page-title_input text-uppercase ml-n2 pl-2 overflow-hidden form-control form-control-sm"
						@keydown.enter.prevent="preventEnter" @change="onStreamNameChange" @focus="resizeTextarea"
						@keyup="resizeTextarea"></textarea>
					<div class="d-none d-md-block mt-2" :class="this.stream.type + '_doc'">
						<a class="text-s-ss text-dark-6 inline-flex items-center text-nowrap" target="_blank" :href="this.doc[this.stream.type].link">
							<svg width="16" height="16" class="mr-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z"
									stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
								<path d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z"
									stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
							</svg>{{ this.doc[this.stream.type].text }}</a>
						<b-button class="text-s-ss text-dark-6 px-0 py-0 font-weight-normal text-nowrap inline-flex items-center" variant="link"
							v-b-modal.modal_tutorial>
							<svg width="16" height="16" class="mr-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M6.98597 3.65906L10.6171 5.62591C12.3187 6.54763 13.1695 7.0085 13.3161 7.68016C13.3621 7.89091 13.3621 8.10909 13.3161 8.31984C13.1695 8.9915 12.3187 9.45237 10.6171 10.3741L6.98597 12.3409C5.41916 13.1896 4.63576 13.614 4.01328 13.4004C3.81704 13.3331 3.6369 13.2258 3.48425 13.0853C3 12.6397 3 11.7487 3 9.96685V6.03315C3 4.25126 3 3.36032 3.48425 2.91468C3.6369 2.77419 3.81704 2.6669 4.01328 2.59958C4.63576 2.38604 5.41916 2.81038 6.98597 3.65906Z"
									stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
							Demo Link
						</b-button>
					</div>
					<b-dropdown v-if="streamType != 'vod'" menu-class="dropdown-menu_md" right no-caret variant="icon" size="sm"
						toggle-class="pr-0 pl-1" class="d-md-none">
						<template #button-content>
							<img src="@/assets/icons/settings.svg" alt="Settings">
						</template>
						<div v-if="this.activeTeam && this.checkTogglePermission()">
							<button :id="this.activeTeam && this.checkTogglePermission() ? ('member-toggle___' + stream._id) : ''"
								class="w-100 dropdown-item no-outline">
								<span v-if="stream.enabled">Disable</span>
								<span v-else>Enable</span>
							</button>
							<b-tooltip :target="'member-toggle___' + stream._id" placement="top" v-if="memberPermission">
								<span class="text-dark-8 text-small">
									You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
										:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
							</b-tooltip>
						</div>
						<b-dropdown-item @click="showStreamToggleModal" class="" v-else>
							<span v-if="stream.enabled">Disable</span>
							<span v-else>Enable</span>
						</b-dropdown-item>
						<div v-if="this.activeTeam && this.checkDelPermission()">
							<button :id="this.activeTeam && this.checkDelPermission() ? ('member-del___' + stream._id) : ''"
								class="w-100 dropdown-item no-outline">
								<span>Delete</span>
							</button>
							<b-tooltip :target="'member-del___' + stream._id" placement="top" v-if="memberPermission">
								<span class="text-dark-8 text-small">
									You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
										:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
							</b-tooltip>
						</div>
						<b-dropdown-item variant="delete" @click="requestStreamDelete" v-else>
							<span>Delete</span>
						</b-dropdown-item>
					</b-dropdown>
				</div>
				<div class="d-block d-md-none" :class="this.stream.type + '_doc'" v-if="streamType != 'vod'">
					<a class="text-s-s text-dark-6" target="_blank" :href="this.doc[this.stream.type].link">
						<svg width="16" height="16" class="mr-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z"
								stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
							<path d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z"
								stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
						</svg>{{ this.doc[this.stream.type].text }}</a>
					<b-button class="text-s-s text-dark-6 px-0 py-0 ml-2 font-weight-normal" variant="link"
						v-b-modal.modal_tutorial>
						<svg width="16" height="16" class="mr-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M6.98597 3.65906L10.6171 5.62591C12.3187 6.54763 13.1695 7.0085 13.3161 7.68016C13.3621 7.89091 13.3621 8.10909 13.3161 8.31984C13.1695 8.9915 12.3187 9.45237 10.6171 10.3741L6.98597 12.3409C5.41916 13.1896 4.63576 13.614 4.01328 13.4004C3.81704 13.3331 3.6369 13.2258 3.48425 13.0853C3 12.6397 3 11.7487 3 9.96685V6.03315C3 4.25126 3 3.36032 3.48425 2.91468C3.6369 2.77419 3.81704 2.6669 4.01328 2.59958C4.63576 2.38604 5.41916 2.81038 6.98597 3.65906Z"
								stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
						Demo Link
					</b-button>
				</div>
			</b-col>
			<b-col tag="main" order="1" order-md="0" lg="8" class="content">
				<sub-second-stats :stream="stream" :media-pulse="mediaPulse" :subscription="baseSubscription"
					:video-files="videoFiles" />
				<div class="tabs tabs-light mt-5">
					<b-nav tabs>
						<b-nav-item v-for="(viewItem, $index) in childViews" :key="$index"
							:to="viewItem.viewName ? { name: viewItem.viewName } : {}" class="text-capitalize"
							:class="viewItem.className || null" tag="li" active-class="active">
							<span v-if="viewItem.name">
								{{ viewItem.name }}
							</span>
							<span v-else-if="viewItem.nameHtml" v-html="viewItem.nameHtml" />
						</b-nav-item>
					</b-nav>
					<div class="tab-content position-relative mt-4">
						<router-view v-slot="{ Component }">
							<component :is="Component" :stream="stream" :stream-alive="streamAlive" :media-pulse="mediaPulse"
							:video-files="videFilesForCurrentPage" :is-vod-trial="isVodTrial" :trial-subscription="trialSubscription"
							:vod-sub-expired-days="vodTrialExpiredIn" @stream-updated="onStreamUpdates"
							@refresh-stream="fetchStreamDetails" @video-update="onVideoFileUpdate" @video-removed="onVideoFileRemoved"
							@set-live-stream-countdown="liveStreamCountdownSelected" @live-stream-abr-changed="liveStreamAbrChanged"
							@dvr-recording-toggle="dvrRecordingToggle"  />
						</router-view>
						<Spinner v-if="isVideoListLoading" text="Retrieving data..." classes="text-dark-8 my-3"
							spinner-color="var(--c-dark-8)" spinner-size="15px" />
						<b-pagination v-if="this.$route.name === 'VODBucketVideos'" v-model="currentPage"
							:total-rows="filteredVideoFileList.length" :per-page="perPage" size="md"
							v-show="filteredVideoFileList.length > perPage" class="mb-0 mt-5" page-class="li-page bg-dark-2 p-1"
							ellipsis-class="li-page bg-dark-2 p-1" first-class="li-first p-1" prev-class="li-prev p-1"
							next-class="li-next p-1" last-class="li-last p-1">
							<template #first-text>
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M8 3L3 8L8 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M13 3L8 8L13 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</template>
							<template #prev-text>
								<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M6 1L1 6L6 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</template>
							<template #next-text>
								<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 1L6 6L1 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</template>
							<template #last-text>
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M3 3L8 8L3 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M8 3L13 8L8 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</template>
						</b-pagination>
					</div>
				</div>
			</b-col>
			<b-col order="0" order-md="1" tag="aside" lg="4"
				:class="streamType === 'vod' ? 'vod-panes' : `sidebar ${streamType}_sidebar`">
				<SubSecondPreviewPane v-if="streamType !== 'scheduled'" :stream="stream" ref="sidebarStreamPreviewPane"
					:stream-alive="streamAlive" :backup-stream-alive="backupStreamAlive" :media-pulse="mediaPulse"
					:isRtmpPullEnabled="isRtmpPullEnabled" @stream-updated="onStreamUpdates" class="mb-4" />
				<div :class="streamType + '_action'" class="stream-action-area mt-4">
					<div v-if="statusProcessing">
						<i class="fas fa-spinner fa-spin" />
					</div>
					<div v-else>
						<div class="d-none d-md-flex justify-content-end" :class="{ 'pe-none': !userloaded }">
							<b-button v-if="stream.enabled" :disabled="toggleProcessing" size="md" variant="outline-secondary"
								class="mr-2"
								:id="this.activeTeam && this.checkTogglePermission() ? ('member--disabled__' + stream._id) : ''"
								@click="showStreamToggleModal">
								Disable
							</b-button>
							<b-button v-else :disabled="toggleProcessing" size="md" variant="outline-secondary" class="mr-2"
								:id="this.activeTeam && this.checkTogglePermission() ? ('member--disabled__' + stream._id) : ''"
								@click="showStreamToggleModal">
								Enable
							</b-button>
							<b-tooltip :target="'member--disabled__' + stream._id" placement="top" v-if="memberPermission">
								<span class="text-dark-8 text-small">
									You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
										:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
							</b-tooltip>
							&nbsp;

							<b-button variant="outline-danger" size="md" class="px-2"
								:id="this.activeTeam && this.checkDelPermission() ? ('member--del__' + stream._id) : ''"
								@click="requestStreamDelete">
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<ellipse cx="10" cy="4.375" rx="6.25" ry="1.875" stroke="currentColor" stroke-width="1.5" />
									<path
										d="M16.25 4.375L14.6875 16.25C14.25 17.5 12.5 18.125 10 18.125C7.5 18.125 5.8125 17.5 5.3125 16.25L3.75 4.375"
										stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								</svg>

							</b-button>

							<b-tooltip :target="'member--del__' + stream._id" placement="top" v-if="memberPermission">
								<span class="text-dark-8 text-small">
									You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
										:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
							</b-tooltip>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
		<add-stream-modal @new-channel="onNewStream" />
		<confirm-modal message="Would you like to delete this stream and all of its content?" modal-type="danger"
			@modal-confirm="onStreamDeleteConfirm" />

		<confirm-modal message="Please update your subscription to get more concurrent stream." modal-id="increase-limit"
			modal-type="danger" ok-text="Yes" cancel-text="Cancel" @modal-confirm="gotoUpgrade" />
		<confirm-modal
			message="Attention: Multistream has been discontinued. Set up a new All-In-One Stream today for uninterrupted streaming with enhanced features, at no extra cost."
			modal-id="create-aio" ok-text="Create All in One" cancel-text="Cancel" @modal-confirm="openCreatestream" />
		<confirm-modal modalId="delete-vod-stream" message="Permanently delete all files in this folder?" modal-type="danger"
			body="Yours files in VOD section inside the stream will also be deleted." @modal-confirm="onStreamDeleteConfirm" />
		<confirm-modal modalId="delete-vod-stream-showcase" message="Permanently delete all files in this folder?"
			modal-type="danger"
			body="This folder contains video(s) which are selected in the showcase. Deleting the folder will remove videos from the showcase as well."
			@modal-confirm="onStreamDeleteConfirm" />

		<confirm-modal modal-size="sm" modal-id="scheduled-status-warning" modal-type="danger"
			message="Stream is already scheduled" ok-text="Disable anyway" cancel-text="Cancel"
			body="If you disable the stream, it won’t start at the scheduled time."
			@modal-confirm="(scheduledAlert = false); toggleStatus()" />

		<alert-modal modal-id="alert-chat-down"
			message="We have encountered unusual behaviour in our chat app and would appreciate your patience while our team get things back online."
			ok-text="I Understand" class="text-center" />

		<demo-video-modal modalId="modal_tutorial" videoid="48ofjE3WZ064-SuIElpIr" />
		<toggle-stream-modal v-if="stream" :stream="stream" @modal-toggle="toggleStatus"
			@toggle-processing="onToggleProcessing" @toggle-done="onToggleDone" />
	</b-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import Spinner from "@/components/ui/Spinner.vue";
import StreamStatsService from '@/services/StreamStatsService';
import StreamService from '@/services/StreamService';
import MetricsService from '@/services/MetricsService';
import UserService from '@/services/UserService';
import StreamSchedulingConfigurator from '@/views/SchedulerDash/StreamSchedulingMode.vue';
import SubSecondStats from '@/components/SubSecondStream/SubSecondStats.vue';
import SubSecondPreviewPane from '@/components/SubSecondStream/SubSecondPreviewPane.vue';
import ScheduleStreamPreviewPane from '@/views/StreamsDash/ScheduleStreamPreviewPane.vue';
import VodStreamPreviewPane from '@/views/StreamsDash/VODStreamPreviewPane.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import utils from '@/utils';
import PaywallService from '@/services/PaywallService';
import ToggleStreamModal from '@/components/modals/ToggleStreamModal.vue';
import AddStreamModal from '@/components/modals/AddStreamModal.vue';
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue'
import Vue from 'vue';

export default {
	name: 'SubSecondManage',
	components: {
		Spinner,
		SubSecondStats,
		StreamSchedulingConfigurator,
		SubSecondPreviewPane,
		VodStreamPreviewPane,
		ScheduleStreamPreviewPane,
		AlertModal,
		AddStreamModal,
		ConfirmModal,
		ToggleStreamModal,
		DemoVideoModal
	},
	props: {
	},
	data() {
		return {
			notAllowed: false,
			currentPage: 1,
			perPage: 20,
			isVideoListLoading: false,
			videoFileSearchText: '',
			selectedFilteringTags: [],
			scopeAlive: true,
			statusProcessing: false,
			nameEdit: false,
			trialSubscription: null,
			processing: true,
			processingMessage: null,
			stream: null,
			streamAlive: false,
			streamId: null,
			streamName: null,
			streamFps: null,
			mediaPulse: null,
			backupMediaPulse: null,
			backupStreamAlive: false,
			mediaPulseTimeoutCtrl: null,
			windowHeight: 0,
			videoFiles: [],
			userSubscription: [],
			isVodPaid: false,
			isVodTrial: false,
			emptyVodSub: true,
			vodTrialExpiredIn: 0, // days
			schedulerSettings: null,
			scheduledAlert: true,
			isRtmpPullEnabled: null,
			scheduledAlert: true,
			streamDvrEnabled: null,
			scheduledStorageLimit: null,
			aioStorageLimit: null,
			childViewList: [
				{
					streamType: 'subsecond',
					name: 'destinations',
					viewName: 'SubSecondManageDashboard'
				},
				{
					streamType: 'subsecond',
					name: 'Analytics',
					viewName: 'SubSecondManageAnalytics'
				},
				{
					streamType: 'subsecond',
					name: 'Settings',
					viewName: 'SubSecondSettings'
				},
			],
			doc: {
				subsecond: {
					text: 'Guide Link',
					link: 'https://docs.castr.com/en/articles/8693777-sub-second-streaming',
					iframe: 'https://www.youtube.com/embed/Txy2k4IAIWI'
				},
			},
			toggleProcessing: false
		};
	},
	computed: {
		...mapGetters({
			baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions'
		}),
		userEmail() {
			return this.$store.state.User.email;
		},
		userloaded() {
			return this.$store.state.User.userloaded;
		},
		activeTeam() {
			return this.$store.state.User.activatedTeam;
		},
		memberPermission() {
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
		videFilesForCurrentPage() {
			const start = (this.currentPage - 1) * this.perPage
			return this.filteredVideoFileList.slice(start, start + this.perPage)
		},
		/** returns the list of video files filtered by tags and search text @returns {[]} */
		filteredVideoFileList() {
			const search = this.videoFileSearchText.trim().toLowerCase()
			return this.videoFiles.filter(file => {
				const searchResult = !search || file.fileName?.toLowerCase().includes(search)
				const tagsResult = !this.selectedFilteringTags.length || _.intersection(file?.tags, this.selectedFilteringTags).length === this.selectedFilteringTags.length
				return searchResult && tagsResult
			})
		},
		paywallFeature() {
			const user = UserService.getUser(this.activeTeam);
			if (user.paywallFeature) {
				return true;
			}

			const baseSub = this.baseSubscription;
			if (this.stream.type === 'live' && baseSub) {
				return _.get(baseSub, ['package', 'definition', 'paywall'], false);
			}

			return false;
		},
		advertisingFeature() {
			const userList = ['5f210874c8c7dc3c2fd283c1', '5f50620cff77767dd15db55c', '5b2e1c65f1e043163435d4e9', '5e94b43350920d5a9fe838f9'];
			// return userList.includes(UserService.getUserId());
			return true;
		},
		subscriptions() {
			return this.$store.state.User.subscriptions.map(sub => _.assign({ category: 'restream' }, sub));
		},
		productsFeatures() {
			return this.$store.state.User.aggregatedDefinition;
		},
		baseSubscription() {
			return this.subscriptions.find(sub => sub.category && sub.category.includes(this.stream.type));
		},
		streamType() {
			if (this.stream) {
				return this.stream.type;
			}
		},
		canEncodeVideos() {
			const encodingUnits = _.get(this.baseSubscription, 'package.definition.encodingUnits')
			return encodingUnits > 0
		},
		childViews() {
			return (this.stream && this.childViewList.filter(v => {
				if (v.viewName === 'StreamsManageAdvertising' && !this.advertisingFeature) {
					return false;
				}
				const isIncluded = v.streamType === this.stream.type;
				return isIncluded && v.validate ? v.validate(this.stream) : isIncluded;
			}));
		},
		streamResolution() {
			const { streamAlive, mediaPulse } = this;
			return (streamAlive && mediaPulse && mediaPulse.width) ? `${mediaPulse.width} x ${mediaPulse.height}` : null;
		},
		trialSubscriptionPromptMessage() {
			if ((this.stream.type == 'vod') && (this.userSubscription.maxVodItems > 0)) {
				this.trialSubscription = null
				return
			}
			var baseSub = this.baseSubscription;
			let remainingDays = 7;
			if (baseSub) {
				const subEndTime = new Date(baseSub.cend);
				let diff = subEndTime - new Date();
				if (_.isNumber(diff)) {
					diff /= 24 * 3600 * 1000;
					diff = Math.floor(diff);
					remainingDays = diff;
				}
			}
			let msg = `Your trial plan will end in ${remainingDays || 7} days.`;
			if (remainingDays <= 0) {
				msg = `Your trial period has expired.`;
			}

			return msg;
		}
	},
	updated() {
		this.$nextTick(() => {
			let area = this.$refs.streamTitle;
			if (area) {
				area.style.height = area.scrollHeight + 'px';
			}
		})
	},
	async mounted() {

		await this.$store.dispatch('User/getactiveTeam', null, { root: true });
		await this.$store.dispatch('Streams/getStreams');
		this.windowHeight = window.innerHeight - 200;
		this.streamId = this.$route.params.streamId;

		// get stream details
		await this.setupStream();

		if (!this.stream) return;
		if (localStorage.getItem('notAllowed' + this.stream.type)) {
			this.notAllowed = (localStorage.getItem('notAllowed' + this.stream.type) == 'true');
		}
		if ((this.streamType === 'scheduled') && localStorage.getItem('notAllowedscheduled')) {
			this.notAllowed = (localStorage.getItem('notAllowedscheduled') == 'true');
		}
		// Get storage usage


		this.streamDvrEnabled = this.stream.metadata.dvrArchive;

		// we can't just update region to new one due HLS binded to region
		// we do hack with multiple regions: region & uiRegion assigned for single stream
		if (!this.stream.uiRegion) {
			this.stream.uiRegion = this.stream.region;
		}

		await this.loadVOD();
		// event tracking
		window.trackEvent(this.stream.name + ' - Stream Page', this.stream);

		// update win title
		this.$emit(
			'updateTitle',
			_.toUpper(this.stream.name) + ' - Castr Restreams'
		);

		if (this.baseSubscription) {
			this.trialSubscription = /trial/gi.test(this.baseSubscription.package.name);
		}

		if (this.streamType === 'vod' || this.streamType === 'scheduled') {
			await this.fetchStreamVideoFileList();
		}

		this.processing = false;

	},
	destroyed() {
		this.scopeAlive = false;
		this.unsubscribeMediaPulse();
	},
	methods: {
		dvrRecordingToggle(status) {
			this.streamDvrEnabled = status
			console.log(this.childViewList[6])
			this.childViewList[6].nameHtml = 'Live to VOD'
			if (!status) {
				this.childViewList[6].nameHtml += '<span class="badge badge-dark-3 badge_beta ml-2 mr-auto">Off</span>'
			}

			this.$set(this.childViewList, 6, this.childViewList[6])
		},
		checkDelPermission() {
			let permission = this.memberPermission && (
				((this.stream.type === 'live') && !this.memberPermission.liveStreamDelete) ||
				((this.stream.type === 'restream') && !this.memberPermission.recordedStreamDelete) ||
				((this.stream.type === 'scheduled') && !this.memberPermission.preRecordedStreamDelete)
			);
			return permission;
		},
		checkTogglePermission() {
			let permission = this.memberPermission && (
				((this.stream.type === 'live') && !this.memberPermission.liveStreamToggle) ||
				((this.stream.type === 'restream') && !this.memberPermission.recordedStreamToggle) ||
				((this.stream.type === 'scheduled') && !this.memberPermission.preRecordedStreamToggle));
			return permission;
		},
		async loadVOD() {
			if (!this.stream) return
			const userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions };
			this.getRmtpPullPermission(userSubscription)

			if (this.stream && this.stream.type !== 'vod') {
				await this.subscribeMediaPulse();
			} else {
				this.userSubscription = userSubscription
				this.getVodSubExpiredDays()
			}
		},
		onSearchTextChanged(searchText) {
			this.isVideoListLoading = true
			this.debouncedSearch(searchText)
		},
		debouncedSearch: _.debounce(function (searchText) {
			this.videoFileSearchText = searchText
			this.isVideoListLoading = false
		}, 300),
		onTagSelected(filteredTags) {
			this.selectedFilteringTags = filteredTags.map(tag => tag.id)
		},
		getRmtpPullPermission(userSubscription) {
			try {
				// select the correct package from base + additional packages
				const packages = []

				// base subscription
				if (userSubscription?.subscription) {
					packages.push(userSubscription.subscription.package)
				}
				// add addon packages
				if (userSubscription?.addonSubscriptions?.length) {
					packages.push(...userSubscription.addonSubscriptions.map(sub => sub.package))
				}

				const packageForCurrentStream = packages.find(pack => pack.category === this.stream.type)

				// set the default value to 'true'
				// if the user has the correct package with correct permission, but the `enableRtmp` is not defined
				const userSubDefinition = packageForCurrentStream.definition
				this.isRtmpPullEnabled = 'enableRtmp' in userSubDefinition ? userSubDefinition.enableRtmp : true
			} catch {
				// under any other cicumstance, disable RTMP pull
				this.isRtmpPullEnabled = false
			}
		},
		liveStreamCountdownSelected(d) {
			let val = d;
			if (d) {
				val = Math.max(new Date(d) - Date.now(), 0);
			}
			this.$refs.sidebarStreamPreviewPane.streamCountdownSecLeft = val;
		},
		liveStreamAbrChanged(d) {
			this.$refs.sidebarStreamPreviewPane.streamAbrToggleState = d;
		},
		requestChatStatus() {
			this.$root.$emit('bv::show::modal', 'alert-chat-down');
		},
		openCreatestream() {
			this.$root.$emit('bv::show::modal', 'modal-add-stream');
		},
		onStreamUpdates(updates) {
			this.stream = _.assign({}, this.stream, updates);
		},
		async setupStream() {
			// get stream details
			try {
				const stream = await this.fetchStreamDetails();
				const meta = await StreamService.getStreamMetadata(stream._id);
				stream.metadata = meta;
				this.streamName = stream.name;
			} catch (err) {
				// redirect to stream list
				this.$router.push({ name: 'ChannelList' });
				this.$notify({
					group: 'error',
					title: err.error,
					text: err.message
				});
			}
		},
		async onNewStream(stream, regionDetails) {
			this.$notify({ group: 'success', text: 'Stream deployed successfully' });

			// setTimeout(() => {
			let redirectPath = '/subsecond/';

			redirectPath += stream._id;
			console.log(redirectPath);
			await this.$router.push({ path: redirectPath });
			window.location.reload()
		},
		async fetchStreamDetails(cb) {
			const stream = await StreamService.getStream(this.streamId);
			this.stream = stream;
			if (cb && cb.constructor === Function) {
				cb();
			}
			return stream;
		},
		async onStreamNameChange() {
			if (!this.streamName) this.streamName = this.stream.name;

			if (this.stream.name === this.streamName) return;

			console.log('change stream name to', this.streamName);
			// try changing stream name
			try {
				await StreamService.setStreamName(this.streamId, this.streamName);
				PaywallService.updateStreamPaywall(this.streamId, { title: this.streamName }).catch(err => {
					console.error('update paywall stream name failed: ', err)
				});

				// track event
				window.trackEvent(
					`Updated stream name ${this.stream.name} -> ${this.streamName}`
				);
			} catch (err) {
				this.streamName = this.stream.name;
				this.$notify({
					group: 'error',
					title: "Couldn't change stream name",
					text: err.message
				});
			}
		},
		subscribeMediaPulse() {
			const streamKey = this.stream.key;
			const self = this;
			(async function loop() {
				if (!self.scopeAlive) return;

				let timeoutInterval = 5000;
				let shouldUpdatePulse = self.stream.enabled;
				if (document.hidden) {
					shouldUpdatePulse = false;
					timeoutInterval = 6000;
				}

				if (shouldUpdatePulse) {
					if (!document.hasFocus()) {
						timeoutInterval = 14000;
					}
				}

				if (shouldUpdatePulse) {
					try {
						const pulseObject = await StreamStatsService.getStreamMediaPulse(
							streamKey
						);
						self.onMediaPulse(pulseObject);

						// console.log('mediPulse stream', self.stream)
						if (self.stream.failoverIngest) {
							const backupPulseObject = await StreamStatsService.getStreamMediaPulse(
								`${streamKey}_backup`
							);
							self.onBackupMediaPulse(backupPulseObject);
						}
					} catch (e) { }
				}

				if (self.mediaPulse && self.mediaPulse.withoutPushStats) {
					try {
						const pushIds = [];
						self.stream.platforms.forEach(p => {
							if (p.metaId && p.enabled) pushIds.push(p.metaId);
						});

						if (pushIds.length) {
							const pushStats = await MetricsService.getPublishPlatformsPulse(
								pushIds,
								self.stream.region._id,
								!!self.stream.pullUrl
							);
							self.onPublishPlatformsPulse(pushStats);
						}
					} catch (e) { }
				}

				// reschedule
				self.mediaPulseTimeoutCtrl = setTimeout(loop, timeoutInterval);
			})();
		},
		onMediaPulse(pulseObject = {}) {
			if (this.mediaPulse && this.mediaPulse.withoutPushStats) {
				const oldPushStats = this.mediaPulse.push_stats;
				pulseObject.push_stats = oldPushStats;
			}

			this.mediaPulse = pulseObject;
			// console.log(pulseObject);
			// this.mediaPulse = {
			//   alive: true,
			//   bitrate: 2000 * Math.random(),
			//   width: 1920,
			//   height: 1080,
			//   fps: 29,
			//   codecs: [{type:'video',codec:'avc'},{ type:'audio',codec:'AAC'}]
			// };

			this.streamAlive =
				this.stream.enabled && this.mediaPulse && this.mediaPulse.alive;
			this.streamFps = this.mediaPulse.fps;
		},
		onBackupMediaPulse(pulseObject = {}) {
			// console.log('onBackupMediaPulse', pulseObject)
			this.backupMediaPulse = pulseObject;
			this.backupStreamAlive =
				this.stream.enabled && this.backupMediaPulse && this.backupMediaPulse.alive;
		},
		onPublishPlatformsPulse(pushStats = {}) {
			const push_stats = {};
			this.stream.platforms.forEach(p => {
				const mid = p.metaId;
				if (pushStats[mid]) {
					const pushUrl = composePlatformPushUrl(p);
					push_stats[pushUrl] = pushStats[mid].alive ? 1 : 0;
				}
			});

			this.mediaPulse = { ...this.mediaPulse, push_stats };
		},
		unsubscribeMediaPulse() {
			clearTimeout(this.mediaPulseTimeoutCtrl);
		},
		async toggleStatus(ev, newStatus) {
			ev && ev.preventDefault();
			ev && ev.stopPropagation();
			if (this.stream.type === 'scheduled' && this.stream.enabled && this.schedulerSettings.mode == 'datetime') {
				let offset = this.schedulerSettings.datetime - Date.now()
				offset /= 1000;
				if (this.scheduledAlert && !(offset <= 0)) {
					this.$root.$emit('bv::show::modal', 'scheduled-status-warning');
					return;
				}
			}

			const oldStatus = this.stream.enabled;
			newStatus = newStatus === undefined ? !oldStatus : newStatus;

			// this.statusProcessing = true;
			this.stream.enabled = newStatus;
			if (!newStatus) {
				this.scheduledAlert = true
			}
			try {
				await StreamService.toggleStream(this.stream._id);
				// this.stream.enabled = newStatus;

				window.trackEvent(
					`${newStatus ? 'Enabled' : 'Disabled'} stream: ${this.stream.name
					}`,
					this.stream
				);
			} catch (err) {
				this.stream.enabled = oldStatus;

				this.$notify({
					group: 'error',
					title: "Couldn't toggle stream status",
					text: err.message
				});

				if (err.message && err.message.indexOf('upgrade') > -1) {
					this.$root.$emit('bv::show::modal', 'billing-prompt');
				}
			}

			this.statusProcessing = false;
		},
		onToggleProcessing(status) {
			this.toggleProcessing = status
		},
		onToggleDone(){
			this.fetchStreamDetails();
		},
		requestStreamDelete() {
			if (this.activeTeam && this.checkDelPermission()) return;
			if (!this.activeTeam || !(((this.stream.type === 'live') && this.memberPermission && !this.memberPermission.liveStreamDelete) || ((this.stream.type === 'restream') && this.memberPermission && !this.memberPermission.recordedStreamDelete))) {
				this.$root.$emit('bv::show::modal', 'modal-confirm');
			}
		},
		async onStreamDeleteConfirm() {
			this.processing = true;
			this.processingMessage = 'Deleting stream';

			let streamId = this.streamId;
			const pseudoVodStream = this.stream.liveParent ? true : false;
			if (pseudoVodStream) {
				streamId = this.stream.liveParent;
			}

			// Try to delete its dvr
			let vodEpisodes
			let permanentDvrArchives
			try {
				vodEpisodes = await StreamService.getStreamDvrArchives(streamId);
			} catch (e) {
				if (!pseudoVodStream) {
					this.$notify({
						group: 'error',
						text: 'could not retreive saved vod(s)'
					});
				}
			}

			vodEpisodes = _.map(vodEpisodes, vodEpisode => {
				const ep = _.assign({}, vodEpisode, {
					startTimeDate: new Date(vodEpisode.startTime),
					endTimeDate: new Date(vodEpisode.endTime)
				});

				ep.fileName = `${this.$options.filters.ago(ep.startTimeDate)}`;
				ep.fileName = ep.fileName.replace('a few seconds ago', 'a seconds ago');
				ep.creationTime = ep.startTimeDate;

				ep.live = false;
				ep.expanded = false;
				ep.streamType = this.stream.type;
				ep.blocking = false;
				ep.size = ep.size ? this.$options.filters.bytes(ep.size, false, 2) : '--';

				ep.exportProcessing = false;
				ep.exportStatusTimer = null;

				return ep;
			});

			permanentDvrArchives = _.filter(vodEpisodes, { exportStatus: 'EXPORTED' }) || []
			permanentDvrArchives.forEach(async (video) => {
				const q = {
					streamId: streamId,
					from: video.from,
					duration: video.duration,
					abr: video.abr,
					namedHls: video.namedHls
				};
				if (pseudoVodStream || !video.pseudoVod) {
					await StreamService.deleteStreamDvrArchive(q);
				}
			});

			// try deleting stream
			try {
				await StreamService.deleteStream(this.stream._id);
				// track event
				window.trackEvent(
					`Deleted stream ${this.stream.name}`,
					this.stream
				);
				if (this.stream.type == 'vod') {
					this.$router.push({ name: 'VodBucket' });
				} else if (this.stream.type == 'scheduled') {
					this.$router.push({ name: 'Scheduler' });
				} else {
					this.$router.push({ name: 'ChannelList' });
				}

			} catch (err) {
				// redirect to stream list
				this.$notify({
					group: 'error',
					title: err.error,
					text: err.message
				});
			}

			this.processing = false;
		},
		async fetchStreamVideoFileList() {
			let list = [];
			try {
				list = await StreamService.getStreamPlaylist(this.stream._id);
			} catch (e) {
				this.$notify({
					group: 'error',
					text: 'could not retreive playlist videos'
				});
			}

			list = _.compact(list).map(this.prepareVideoFileItem);

			this.videoFiles = list;
			this.playlistProcessing = false;
		},
		prepareVideoFileItem(videoFile) {
			videoFile.expanded = false;
			videoFile.removing = false;
			videoFile.statusProcessing = false;
			videoFile.uploadTime = new Date(videoFile.uploadTime);
			return videoFile;
		},
		onVideoFileList(videoFileList) {
			const list = _.compact(videoFileList).map(this.prepareVideoFileItem);
			this.videoFiles = list;
		},
		onVideoFileAdded(videoItem) {
			videoItem = this.prepareVideoFileItem(videoItem);
			this.videoFiles.unshift(videoItem);
		},
		onVideoFileUpdate(videoId, updates) {
			const vindex = _.findIndex(this.videoFiles, { id: videoId });
			if (vindex > -1) {
				const videoItem = _.assign({}, this.videoFiles[vindex], updates);
				this.videoFiles = utils.updateArrayItem(
					this.videoFiles,
					videoItem,
					vindex
				);
			}
		},
		onVideoFileRemoved(videoId) {
			const vindex = _.findIndex(this.videoFiles, { id: videoId });
			this.videoFiles = utils.removeArrayItem(this.videoFiles, vindex);
		},
		onSchedulerSettingsUpdated(updatedConfig) {
			this.schedulerSettings = updatedConfig;
		},
		getVodSubExpiredDays() {
			let originalVodSub;
			let paidSub;
			let trialSub;
			let hasMaxVodItems = false;
			if (_.isArray(this.userSubscription)) {
				this.userSubscription.forEach((sub) => {
					if (sub.category === 'vod') this.emptyVodSub = false;
					if (sub.category === 'vod' && sub.package.baseCharge === 0) {
						trialSub = sub;
						this.isVodTrial = true;
					}
					if (sub.category === 'vod' && sub.package.baseCharge > 0 && sub.enabled) {
						originalVodSub = sub;
					}
					if (sub.category !== 'vod' && sub.package.definition.maxVodItems && parseInt(sub.package.definition.maxVodItems, 10) > 0) {
						paidSub = sub;
						if (sub.enabled === true) hasMaxVodItems = true;
					}
				});
			}
			if (_.isObject(this.userSubscription)) {
				this.userSubscription.addonSubscriptions.forEach((sub) => {
					if (sub.category === 'vod') this.emptyVodSub = false;
					if (sub.category === 'vod' && sub.package.baseCharge === 0) {
						trialSub = sub;
						this.isVodTrial = true;
					}
					if (sub.category === 'vod' && sub.package.baseCharge > 0 && sub.enabled) {
						originalVodSub = sub;
					}
					if (sub.category !== 'vod' && sub.package.definition.maxVodItems && parseInt(sub.package.definition.maxVodItems, 10) > 0) {
						paidSub = sub;
						if (sub.enabled === true) hasMaxVodItems = true;
					}
				});
			}
			if (trialSub) {
				this.vodTrialExpiredIn = moment(trialSub.cend).diff(moment(), 'day');
			}

			if (paidSub) {
				this.isVodPaid = true;
				let paidSubExpiredIn = moment(paidSub.cend).diff(moment(), 'day');

				// Compare with the previous expire day to get which is available
				if (trialSub && this.vodTrialExpiredIn > paidSubExpiredIn) {
					paidSubExpiredIn = this.vodTrialExpiredIn;
					this.isVodPaid = false;
				}

				this.vodTrialExpiredIn = paidSubExpiredIn;

				if (hasMaxVodItems) {
					this.isVodTrial = false;
					this.vodTrialExpiredIn = 1;
				}
			}

			if (originalVodSub) {
				this.isVodPaid = true;
				const originalVodSubExpiredIn = moment(originalVodSub.cend).diff(moment(), 'day');

				if (originalVodSubExpiredIn > this.vodTrialExpiredIn) {
					this.vodTrialExpiredIn = originalVodSubExpiredIn;
					this.isVodTrial = false;
				}
			}

			return this.vodTrialExpiredIn;
		},
		resizeTextarea: function (e) {
			let area = e.target;
			area.style.height = area.scrollHeight + 'px';
		},
		gotoUpgrade() {
			window.location.href = `/app/subscribe?category=live`;
		},
		preventEnter(e) {
			e.preventDefault();
		},
		checkActivems() {
			let hasActivems = false;
			const subscriptions = this.$store.state.User.subscriptions;
			subscriptions.map((s) => {
				if (s && s.enabled && s.package.category == 'restream') {
					hasActivems = true;
				}
			});
			return hasActivems;
		},
		showStreamToggleModal(ev) {
			ev.preventDefault();
			ev.stopPropagation();
			if (!this.checkActivems() && this.stream.type == 'restream' && !this.stream.enabled) {
				// this.$root.$emit('bv::show::modal', 'discontinued-ms');
				this.$root.$emit('bv::show::modal', 'create-aio')
				return;
			}
			if (this.notAllowed + this.stream.type && !this.stream.enabled) {
				this.$root.$emit('bv::show::modal', 'increase-limit');
			}
			console.log(!this.notAllowed);
			if (this.activeTeam && this.checkTogglePermission()) return;
			if (!this.notAllowed || (this.notAllowed && this.stream.enabled)) {
				this.$root.$emit('bv::show::modal', 'modalToggleStream' + this.stream._id, '#btnShow');
			}
		},
	},
	watch: {
		'stream.enabled'() {
			if (!this.stream.enabled) {
				this.schedulerSettings = {}
			}
		},
		activeTeam() {
			this.loadVOD();
		}
	}
};

function composePlatformPushUrl(platform) {
	if (!platform) return;
	let pushUrl = platform.server;
	pushUrl = pushUrl.replace(/\/$/g, '');
	pushUrl += '/' + platform.key;
	return pushUrl;
}
</script>

<style scoped>
.vod_wrap .content {
	max-width: 100%;
	flex: 0 0 100%;
}

:deep(.content .page-title) {
	resize: none;
}

.no-outline {
	outline: none;
}

.page-title {
	max-width: calc (100% - 36px);
}

@media (max-width: 767px) {
	:deep(.vod-panes .dropdown) {
		width: 100%;
	}

	.vod-nav {
		margin-right: 48px;
	}
}
</style>
