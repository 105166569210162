<template>
  <g>
    <rect x="21" y="2" width="25" height="25" rx="3" fill="#1F2A4A" />
    <rect x="21.5" y="2.5" width="24" height="24" rx="2.5" stroke="#A7B5E2" stroke-opacity="0.08" />
    <rect x="2" y="13" width="22" height="22" rx="3" fill="#1F2A4A" />
    <rect x="2.5" y="13.5" width="21" height="21" rx="2.5" stroke="#A7B5E2" stroke-opacity="0.08" />
    <rect x="20" y="29" width="17" height="17" rx="3" fill="#1F2A4A" />
    <rect x="20.5" y="29.5" width="16" height="16" rx="2.5" stroke="#A7B5E2" stroke-opacity="0.08" />
    <g clip-path="url(#clip0_1002_1556)">
      <path
        d="M25.875 33L24 34.7857V41.2143H26.25V43L28.125 41.2143H29.625L33 38V33H25.875ZM32.25 37.6429L30.75 39.0714H29.25L27.9375 40.3214V39.0714H26.25V33.7143H32.25V37.6429Z"
        fill="#A1B2E8" fill-opacity="0.14" />
      <path d="M31.125 34.9643H30.375V37.1072H31.125V34.9643Z" fill="#A1B2E8" fill-opacity="0.14" />
      <path d="M29.0625 34.9643H28.3125V37.1072H29.0625V34.9643Z" fill="#A1B2E8" fill-opacity="0.14" />
    </g>
    <path
      d="M36.9211 15.2248L37.3378 12.5796H34.7709V10.8603C34.7709 10.137 35.1293 9.43025 36.2752 9.43025H37.4586V7.17772C36.7694 7.06792 36.0731 7.00852 35.3751 7C33.2625 7 31.8833 8.26885 31.8833 10.5627V12.5796H29.5414V15.2248H31.8833V21.6228H34.7709V15.2248H36.9211Z"
      fill="#A1B2E8" fill-opacity="0.14" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M16.8848 20.2084C17.3149 20.3245 17.6529 20.6634 17.7671 21.093C17.975 21.8725 17.975 23.5 17.975 23.5C17.975 23.5 17.975 25.1275 17.7671 25.907C17.6513 26.3382 17.3133 26.677 16.8848 26.7916C16.1073 27 12.9875 27 12.9875 27C12.9875 27 9.86932 27 9.09017 26.7916C8.66013 26.6755 8.32213 26.3366 8.20788 25.907C8 25.1275 8 23.5 8 23.5C8 23.5 8 21.8725 8.20788 21.093C8.32372 20.6618 8.66172 20.323 9.09017 20.2084C9.86932 20 12.9875 20 12.9875 20C12.9875 20 16.1073 20 16.8848 20.2084ZM14.5823 23.5L11.991 25.0002V21.9998L14.5823 23.5Z"
      fill="#A1B2E8" fill-opacity="0.14" />
    <defs>
      <clipPath id="clip0_1002_1556">
        <rect width="9" height="10" fill="white" transform="translate(24 33)" />
      </clipPath>
    </defs>
  </g>
</template>
