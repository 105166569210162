<script>
export default {
  compatConfig: {
    MODE: 3,
    COMPONENT_V_MODEL: false,
  }
}
</script>
<script setup>
import { computed } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'radio',
    validator: (value) => ['checkbox', 'radio', 'switch'].includes(value)
  },
  label: {
    type: String,
    default: null
  },
  modelValue: {
    type: [Array, String, Number, Boolean],
    default: null
  },
  inputValue: {
    type: [String, Number, Boolean],
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const computedValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})
</script>

<template>
  <label class="flex" :class="type">
    <input :disabled="disabled" v-model="computedValue" type="radio" :name="name" :value="inputValue" />
    <span class="check" />
    <span class="pl-1">{{ label }}</span>
  </label>
</template>