<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])
const playoutMaxDurationLimit = computed(() => store.getters['User/playoutMaxDurationLimit'])

</script>

<template>
 <div class="mx-auto text-center mt-5 w-7/12">
    <img class="mb-4 mx-auto" src="@/assets/empty-playout-placeholder.png" alt="Playout Line Up">
    <h3 class="text-lg text-surface-text-1">No content has been added</h3>
    <p class="mb-4 text-tiny text-surface-7">Add content from the right sidebar</p>
    <p v-if="playoutConfig?.mode === 'loop'" class="text-tiny text-surface-7">*You can upload {{ playoutMaxDurationLimit }} hours worth of contents</p>
  </div>
</template>
