<template>
  <div class="view">
    <Spinner v-if="apiKeyProcessing" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)" spinner-size="15px" />
    <div v-else>
    <div class="row" v-if="!this.activeTeam || (this.memberPermission && this.memberPermission.developers )">
      <div class="col-sm-5">
        <h1 class="page-title text-500 mb-4">Developer Settings</h1>
        <div>
          <div class="field-container">
            <label class="text-s-s">API Key</label>

            <div v-if="apiKeyProcessing" class="input disabled">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
            <b-input
              v-else
              disabled
              size="md"
              v-model="form.apiKey"
              placeholder="no api key assigned"
              readonly
            />

            <div v-show="!apiKeyProcessing" style="margin-top:15px;">
              <b-button
                v-if="!form.apiKey"
                variant="primary"
                size="md"
                onclick="Intercom('show')"
              >Request API Key</b-button>
              <b-button v-else variant="primary" @click="clipboardCopy(form.apiKey)">Copy</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-7"></div>
    </div>
    <div class="row" v-else>
      <div class="col-sm-12">
        <h1 class="page-title text-500 mb-4">Developer Settings</h1>
          <div class="text-center pt-5 mt-5">
            <h4 class="text-dark-9 text-s-xl text-500 mb-1">You don’t have permission to access this.</h4>
            <p class="text-dark-6 text-s-m">Contact the team owner at <a  class="text-dark-6 text-s-m text-underline" :href="'mailto:'+userEmail">{{userEmail}}</a> to gain access.</p>
          </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import Spinner from "@/components/ui/Spinner.vue";

export default {
  name: 'ManageDeveloperSettings',
  components:{
    Spinner
  },
  data() {
    return {
      processing: false,
      apiKeyProcessing: false,
      form: {
        apiKey: null
      }
    }
  },
  async mounted() {
    await this.setApiKey()

    // track event
    window.trackEvent(`Developer Settings Page`)
  },
  computed:{
    activeTeam(){
			return this.$store.state.User.activatedTeam;
		},
    memberPermission(){
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
    userEmail() {
			return this.$store.state.User.email;
		},
		userloaded(){
			return this.$store.state.User.userloaded;
		},
  },
  methods: {
    clipboardCopy(text) {
      try {
        if (text instanceof Function) text = text()

        this.$copyText(text)
        this.$notify({ group: 'info', text: 'Copied to clipboard' })
      } catch (e) {}
    },
    async setApiKey() {
      this.apiKeyProcessing = true

      try {
        let res = await UserService.getUserApiKey()
        if (res && res.apiKey) {
          this.form.apiKey = res.apiKey
        }
      } catch (e) {
        this.$notify({ group: 'error', text: `could not retreive api key` })
      }

      this.apiKeyProcessing = false
    }
  }
}
</script>

<style scoped>
</style>
