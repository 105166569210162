<script setup>
import { computed, ref } from 'vue'
import IconBase from '../icon/IconBase.vue';
import Tooltip from '../Atoms/Tooltip.vue';

const props = defineProps({
  expanded: {
    type: Boolean,
  },
  title: {
    type: String,
  },
  icon: {
    type: Object,
  }
})


</script>

<template>
  <icon-base v-show="props.expanded" class="shrink-0" fill="none">
    <component :is="props.icon" />
  </icon-base>
  <Tooltip :content="props.title" v-show="!props.expanded">
    <icon-base class="shrink-0 mx-auto" fill="none">
      <component :is="props.icon" />
    </icon-base>
  </Tooltip>
</template>

<style scoped></style>
