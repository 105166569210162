<script setup>
import { computed, ref } from 'vue'

import { useStore } from 'vuex'
import _ from 'lodash'
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconSearch from '../icon/IconSearch.vue';
import IconCross from '../icon/IconCross.vue';
import IconCameraFilled from '../icon/IconCameraFilled.vue';
import IconPlus from '../icon/IconPlus.vue';
import FormInput from '../Atoms/FormInput.vue';
import Tooltip from '../Atoms/Tooltip.vue';

const store = useStore()

const emit = defineEmits({
	'add-livestream': (value) => { return value }
})

const allInOneStreams = computed(() => store.getters['Streams/allInOneStreams'])
const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])

const search = ref('')

const liveStreamsFiltered = computed(() => {
	const liveStreams = _.cloneDeep(allInOneStreams.value.filter(stream => stream.name.toLowerCase().includes(search.value.toLowerCase())))
	return liveStreams.sort(function (a, b) {
		return new Date(b.creationTime) - new Date(a.creationTime)
	})
})

const clearSearch = () => search.value = ''

const addFile = (file) => {
	emit('add-livestream', file)
}

const isDestination = (streamId) => streamId === playoutConfig.value?.destination

</script>

<template>
	<section v-if="allInOneStreams.length === 0" class="min-h-28">
		<h3 class="text-sm text-surface-8 font-medium text-center mt-5">You have no livestreams yet</h3>
		<p class="text-center my-3">
			<FormButton to="/">
				Open Livestreams
			</FormButton>
		</p>
	</section>
	<section v-else>
		<div class="flex items-center mb-3 relative">
			<icon-base class="absolute z-10 left-2 text-surface-7">
				<icon-search />
			</icon-base>
			<FormInput customClasses="!px-8" v-model="search" />
			<FormButton class="absolute z-10 right-0" isIcon type="link" v-if="search" @click="clearSearch">
				<icon-base>
					<icon-cross />
				</icon-base>
			</FormButton>
		</div>
		<slot />
		<ul class="flex flex-col divide-y divide-surface-3 max-h-[400px] overflow-y-auto overflow-x-hidden">
			<li v-for="stream in liveStreamsFiltered" :key="stream._id" :title="stream.name"
				class="hover:bg-surface-3 flex items-center py-1 gap-x-2 px-2">
				<template v-if="!isDestination(stream._id)">
					<icon-base>
						<icon-camera-filled />
					</icon-base>
					<span class="text-sm grow-0 truncate">{{ stream.name }}</span>
					<FormButton size="sm" isIcon class="shrink-0 ml-auto" @click="addFile(stream)">
						<icon-base>
							<icon-plus />
						</icon-base>
					</FormButton>
				</template>
				<template v-else>
					<tooltip wrapperClass="w-full" content="This stream is set as destination for this TV Playout and cannot be selected.">
						<div class="flex gap-x-2 text-surface-7 items-center w-full">
							<icon-base>
								<icon-camera-filled />
							</icon-base>
							<span class="text-sm grow-0 truncate">{{ stream.name }}</span>
							<FormButton size="sm" isIcon class="shrink-0 ml-auto" disabled>
								<icon-base>
									<icon-plus />
								</icon-base>
							</FormButton>
						</div>
					</tooltip>
				</template>
			</li>
		</ul>
	</section>
</template>

<style scoped></style>