<template>
	<div class="">
		<b-modal
			:id="modalId"
			ref="modalRenameVODFolder"
			:size="modalSize"
			@hide="newName = '', id = ''"
			@show="newName = streamName, id = streamId"
			hide-header
			hide-footer
			centered
			:no-close-on-backdrop="closeoutsideClick"
		>
		<b-row>
			<b-col><h3 class="text-s-m text-500 mb-4">{{ message }}</h3></b-col>
		</b-row>
		<b-row>
			<b-col class="text-s-s text-dark-8">
				<label class="text-s-s text-dark-8" for="newName">Folder name</label>
				<b-input
					id="newName"
					v-model="newName"
					size="md"
					placeholder="name"
					class="mb-3"
				/>
			</b-col>
		</b-row>
		<slot>
		</slot>
		<b-row >
			<b-col class="text-right mt-3">
				<b-button
					variant="outline-secondary"
					@click="dismiss"
					class="mr-2"
					size="md"
				>
					{{ cancelText }}
				</b-button>
				<b-button
					v-if="showConfirm"
					:variant="modalType"
					size="md"
					@click="onConfirm"
				>
					{{ okText }}
				</b-button>
			</b-col>
		</b-row>
		</b-modal>
	</div>
</template>

<script>
import StreamService from '@/services/StreamService';
import PaywallService from '@/services/PaywallService'

export default {
	name: 'RenameVODFolderModal',
	components: {},
	props: {
		modalId: {
			type: String,
			default: 'modal-confirm'
		},
		modalSize: {
			type: String,
			default: 'sm',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
		streamId: {
			type: String,
			required: true,
		},
		streamName: {
			type: String,
			required: true,
		},
		modalType: {
			type: String,
			default: 'primary'
		},
		message: {
			type: String,
			required: false
		},
		body: {
			type: String,
			required: false
		},
		streamCat: {
			type: String
		},
		okText: {
			type: String,
			default: 'Yes'
		},
		cancelText: {
			type: String,
			default: 'No'
		},
		showConfirm: {
			type: Boolean,
			default: true,
			required: false
		},
		closeoutsideClick: {
			type: Boolean,
			default: false
		},
		confirmCancelEvent: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			processing: false,
			id: '',
			newName: '',
		};
	},
	mounted() {
		this.id = this.streamId
		this.newName = this.streamName
	},
	methods: {
		async onConfirm() {
			if (!this.newName) this.newName = this.streamName;
			if (this.streamName === this.newName) return;
			// try changing stream name
			try {
				await StreamService.setStreamName(this.id, this.newName);
				PaywallService.updateStreamPaywall(this.id, { title: this.newName }).catch(err => {
					console.error('update paywall stream name failed: ', err)
				});
				await this.$store.dispatch('Streams/getStreams')
				await this.$store.dispatch('Streams/getFilesInVODFolder')
				this.$notify({
					group: 'success',
					title: "Stream name was changed successfully",
				});
				// track event
				window.trackEvent(
					`Updated stream name ${this.streamName} -> ${this.newName}`
				);
			} catch (err) {
				this.newName = this.streamName;
				this.$notify({
					group: 'error',
					title: "Couldn't change stream name",
					text: err.message
				});
			}
			this.$emit('modal-confirm');
			this.dismiss(this.confirmCancelEvent);
		},
		dismiss(cancel = true) {
			if (cancel) {
				this.$emit('modal-cancel');
			}
			this.$refs.modalRenameVODFolder.hide();
			this.$emit('modal-dismiss');
			// lazy clear
			setTimeout(() => { this.processing = false; }, 1000);
		},
	},
};
</script>

<style scoped>

</style>
