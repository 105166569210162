import PlaylistService from "@/services/Playlistservice";

const defaultState = () => ({
  playList: null,
});

const state = defaultState();

const mutations = {
  GET_PLAYLIST(state, playlist) {
    state.playList = playlist.reverse();
  },
};

const actions = {
  async getPlaylist({ commit }) {
    const playlist = await PlaylistService.getPlaylist();
    commit("GET_PLAYLIST", playlist);
  },
};

const getters = {
  playlist: (state) => state.playList,
  hasPlaylist: (state, getters) => {
    if (
      (typeof getters.playlist === "object" && getters.playlist === null) ||
      (getters.playlist != null && Object.keys(getters.playlist).length === 0)
    ) {
      return false;
    } else return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
