<script setup>
import { computed, onMounted, ref, getCurrentInstance, provide } from 'vue'
import moment from 'moment';
import { newDate } from '@/filtersNew'
import { useStore } from 'vuex'
import IconBase from '../icon/IconBase.vue';
import IconChevronDown from '../icon/IconChevronDown.vue';
import DropDownMenu from '../Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../Atoms/DropDownMenuItem.vue';
import { DateTimePickerComponent as EjsDatetimepicker, MaskedDateTime } from "@syncfusion/ej2-vue-calendars"; 
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

const props = defineProps({
  mediaPulse: {
    type: Object,
  },
})

provide('datetimepicker', [MaskedDateTime])

const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const playoutModeDropdown = ref()

onMounted(() => {
	EjsDatetimepicker.compatConfig = { MODE: 3 }
})

const playout = computed(() => store.getters['Streams/tvPlayout'])
const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])
const playoutStartTime = computed(() => store.getters['Playout/playoutStartTime'])
const playlist = computed(() => store.getters['Playout/playlist'])

const maxPlayoutLoopDate = computed(() => store.getters['User/maxTvPlayoutLoopDate'])

const startTimeComputed = computed({
  get () {
    const startDate = newDate(playoutStartTime.value)
    console.log(startDate);
    return startDate
  },
  set (value) {
    store.dispatch('Playout/changeStartTime', value)
  }
})

const loopDateComputed = computed({
  get () {
    return newDate(moment(playoutStartTime.value).add(maxPlayoutLoopDate.value, 'days').valueOf())
  },
  set (value) {
    store.dispatch('Playout/setLoopMode', value)
  }
})

const maxLoopDateComputed = computed(() => newDate(moment(playoutStartTime.value).add(maxPlayoutLoopDate.value, 'days').valueOf()))
const minLoopDateComputed = computed(() => newDate(moment(playoutStartTime.value).add(1, 'days').valueOf()))

const newMode = ref(null)

const showToggleModeModal = (mode) => {
  newMode.value = mode
  if (playlist.value?.length > 0) {
    root.$emit("bv::show::modal", 'playout-change-mode' + playout?.value._id)
  } else {
    setPlayoutMode()
  }
}

const setPlayoutMode = () => {
  console.log(newMode.value );
  store.dispatch('Playout/setMode', newMode.value.toLowerCase())
  playoutModeDropdown.value.toggleDropdown()
  newMode.value = null
}
const minDate = ref(new Date())
</script>

<template>
  <div v-if="playoutConfig" class="w-full p-3 bg-surface-2 rounded-lg">
    <h3 class="mb-2 font-medium text-sm">Playout Mode</h3>
    <drop-down-menu v-if="playoutConfig.mode" ref="playoutModeDropdown" class="mb-3 " type="secondary" customClasses="w-full text-tiny disabled:text-surface-7 h-8 bg-surface-3 rounded-md flex items-center px-3 capitalize" :fullWidth="true">
      <template #toggle-button>
        {{ playoutConfig.mode }}
        <icon-base
          class="ml-auto">
          <icon-chevron-down />
        </icon-base>
      </template>
      <template #menu-items>
        <drop-down-menu-item class="w-full" @click="showToggleModeModal('Schedule')">
          Schedule
        </drop-down-menu-item>
        <drop-down-menu-item class="w-full" @click="showToggleModeModal('Loop')">
          Loop
        </drop-down-menu-item>
      </template>
    </drop-down-menu>
    <div :class="{'[&>*]:!border-none': props.mediaPulse?.alive}">
      <ejs-datetimepicker :min="minDate" v-model="startTimeComputed" placeholder="M/DD/YYYY, HH:mm" :enableMask="true" format="M/dd/yy HH:mm" timeFormat="HH:mm" :step="5"></ejs-datetimepicker>
    </div>
    <div  v-if="playoutConfig.mode === 'loop'" class="mt-3">
      <ejs-datetimepicker :min="minLoopDateComputed" :max="maxLoopDateComputed" v-model="loopDateComputed" placeholder="M/DD/YYYY, HH:mm" :enableMask="true" format="M/dd/yy HH:mm" timeFormat="HH:mm" :step="5"></ejs-datetimepicker>
    </div>
    <!-- <hr class="my-3">
    <div class="flex items-center relative">
      <icon-base width-view-box="16" height-view-box="16" iconColor="none" width="16" height="16"
        class="flex-shrink-0 absolute z-10 left-3 text-surface-9">
        <icon-globe />
      </icon-base>
      <FormInput class="[&>*]:capitalize [&>*]:!pl-10" disabled v-model="playoutConfig.timeZone" />
    </div> -->
    <confirm-modal :modalId="'playout-change-mode' + playout?._id" :message="`Are you sure you want to switch to ${newMode} mode?`" body="We will remove all of your existing content on this playout."
      @modal-confirm="setPlayoutMode" @modal-cancel="newMode = null" />
  </div>
</template>

<style scoped>
  @import '@syncfusion/ej2-base/styles/tailwind.css';
  @import '@syncfusion/ej2-inputs/styles/tailwind.css';
  @import '@syncfusion/ej2-popups/styles/tailwind.css';
  @import '@syncfusion/ej2-lists/styles/tailwind.css';
  @import "@syncfusion/ej2-vue-calendars/styles/tailwind.css";
</style>