<script setup>
import { computed, watch, ref, onMounted } from 'vue'

import { useStore } from 'vuex'
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconPlus from '../icon/IconPlus.vue';
import IconBookOpen from '../icon/IconBookOpen.vue';
import FormSwitch from '../Atoms/FormSwitch.vue';

const store = useStore()

const paywallActive = ref(false)

const userPaywallActived = computed(() => store.getters['User/userPaywallActived'])
const userPaywall = computed(() => store.getters['User/userPaywall'])
const hasLiveSubscription = computed(() => store.getters['User/hasLiveSubscription'])
const paywalls = computed(() => store.getters['Paywalls/paywalls'])

const props = defineProps({
  onStripeConnectClick: {
    type: Function,
  },
  onStripeConnectCancelClick: {
    type: Function
  },
  stripeConnectProcessing: {
    type: Boolean,
  },
  stripeOauthLink: {
    type: String
  },
  stripeConnectLinkError: {
    type: Boolean
  },
  paywallActive: {
    type: Boolean
  }

})

</script>

<template>
  <header class="flex items-center mb-3 mt-5">
    <h1 class="text-2xl font-medium">Paywall</h1>
  </header>
  <main v-if="!userPaywallActived">
    <section class="md:w-1/2 mx-auto">
      <img class="mx-auto mb-3" height="80" width="80" src="@/assets/icons/secure.svg" />
      <h2 class="text-center text-xl font-medium">Start earning money with Paywall</h2>
      <p class="text-center text-surface-6 text-sm mb-4">Paywall protect your livestream and VOD so only paid users can
        access.
        <a class="underline" target="_blank" href="https://docs.castr.com/en/collections/2958860-castr-paywall">Learn
          more</a>
      </p>
    </section>
    <section class="mt-10 md:w-1/2 mx-auto bg-surface-2 rounded-xl p-8">
      <ol class="mb-0 p-0">
        <li class="custom-list-item before:content-['1'] text-sm">
          <div class="flex flex-col bordered items-start">
            <img width="86" class="mb-3" src="@/assets/images/stripe-logo.svg" />
            <p class="mb-2">Set up Stripe to receive payment.</p>

            <p class="text-surface-6 mb-4">
              Set up your Stripe account to use Castr Paywall. You will receive payment from viewers using
              your Stripe account.
            </p>
            <FormButton v-if="!stripeConnectProcessing" @click="onStripeConnectClick">Connect to Stripe</FormButton>
            <div v-if="stripeOauthLink" class="flex">
              <p v-if="stripeConnectProcessing" class="text-center text-white mb-1">
                Connecting to Stirpe ...
                <i class="fas fa-spinner fa-spin" />
                <FormButton @click="onStripeConnectCancelClick">
                  Cancel
                </FormButton>
              </p>
              <p v-if="stripeConnectLinkError" class="text-center text-danger mb-2 mt-2">
                Stripe connect link error. Try again.
              </p>
            </div>
            <hr class="w-full my-3">
          </div>
        </li>
        <li class="custom-list-item before:content-['2'] text-sm">
          <div class="w-full !mt-1">
            Choose the livestream or VOD file that you would like to set up Paywall for.
            <hr class="w-full my-3">
          </div>
        </li>
        <li class="custom-list-item before:content-['3'] text-sm">
          <div class="w-full !mt-1">
            Your audience will be asked to pay when accessing your livestream / VOD.
            <hr class="w-full my-3">
          </div>
        </li>
        <li class="custom-list-item before:content-['4'] text-sm">
          <div class="!mt-1">
            You receive your payment in Stripe!
          </div>
        </li>
      </ol>
    </section>
  </main>

  <main v-else-if="paywalls.length === 0">
    <section class="flex flex-col items-center mt-32">
      <h2 class="text-sm text-surface-9 font-medium mb-2">Paywall creation simplified</h2>
      <p class="text-sm text-surface-8 mb-4">Design your own paywall and share it with
        your audience</p>
      <div class="flex flex-col gap-y-3 mx-auto w-[320px]">
        <FormButton class="text-center justify-center" type="secondary" href="https://docs.castr.com/en/articles/8925810-how-to-create-a-paywall"
          target="_blank">
          <icon-base class="mr-1">
            <icon-book-open />
          </icon-base>
          <span class="ml-2">How Paywall works</span>
        </FormButton>
        <FormButton class="text-center justify-center" v-if="hasLiveSubscription" v-b-modal="'add-paywall-modal'">
          <icon-base class="mr-1">
            <icon-plus />
          </icon-base>
          Create Paywall
        </FormButton>
        <FormButton class="text-center justify-center" v-else to="/subscribe">
          Upgrade
        </FormButton>
      </div>

      <div v-if="userPaywall" class="flex flex-col items-center mt-6">
        <div class="flex !border !border-surface-3 rounded-xl flex-col w-[320px] !py-4">
          <FormSwitch class="mx-6" label="Linked to Stripe" v-model="props.paywallActive" @change="$root.$emit('bv::show::modal', 'unlink-stripe')" />
          <hr class="!my-4">
          <dl class="flex justify-between mx-6 text-surface-9">
            <dt class="">Stripe ID</dt>
              <dd class="">{{ userPaywall.externalMasterId }}</dd>
          </dl>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
.custom-list-item {
  @apply before:text-surface-8 before:text-sm before:font-medium before:bg-surface-4 before:rounded-full before:flex before:items-center before:justify-center before:size-8 before:shrink-0 before:mr-4 flex items-start
}
</style>
