<template>
	<div
		id="videos-uploader-widget"
		:class="stream.type+'_uploadbox'"
	>
		<ul class="video-list list-unstyled">
			<b-row
				v-for="(media,index) in videoFiles"
				:key="media.name"
				class="video-item mb-3"
				no-gutters
			>
				<b-col cols="1">
					<b-button @click="requestVideoPreview(media)" variant="dark-3" class="btn-rounded p-0" size="sm">
						<svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2.66745 0.967098L7.13238 3.42281L7.13239 3.42282C8.24796 4.03638 8.80575 4.34316 8.90224 4.78803C8.93254 4.92772 8.93254 5.07228 8.90224 5.21197C8.80575 5.65684 8.24796 5.96362 7.13239 6.57718L7.13238 6.57719L2.66745 9.0329C1.61992 9.60905 1.09615 9.89712 0.679163 9.75586C0.547758 9.71135 0.427052 9.63998 0.324723 9.54629C0 9.24899 0 8.65123 0 7.45571V2.54429C0 1.34877 0 0.751007 0.324723 0.453707C0.427052 0.360019 0.547758 0.28865 0.679163 0.244137C1.09615 0.102884 1.61992 0.390955 2.66745 0.967098Z" fill="currentColor"/>
						</svg>
					</b-button>
				</b-col>
				<b-col class="pl-2" cols="8">
					<span
						:title="media.fileNameOriginal"
						class="video-item__name"
					>{{ media.fileName }}</span>
					<div
						v-if="media.mediaInfo"
						class="video-item__stats text-dark-7 text-s-s"
					>
						
						<!-- media resolution -->
						<span v-if="media.mediaInfo.width && media.mediaInfo.height">{{ media.mediaInfo.width }} x {{ media.mediaInfo.height }} . </span>
						<!-- upload duration -->
						
						<span
							v-if="media.mediaInfo.duration>60"
						>{{ media.mediaInfo.durationMins }} mins . </span>
						<span v-else>{{ media.mediaInfo.duration }} secs . </span>
						<!-- upload size -->
						<span class="text-uppercase"> {{ showInBytes(media.bytes, false, 0, true) }}</span>
					</div>
					<!-- codecs -->
					<div v-if="media.mediaInfo" class="codec-list">
						<b-badge
							v-for="(track, index) in media.mediaInfo.codecs"
							:key="index"
							class="mr-1 badge_xs text-500"
							:class="track.type"
							variant="dark-3"
						>
							{{ track.codec }}
						</b-badge>
					</div>
				</b-col>
				<b-col class="d-flex align-items-end justify-content-start flex-column">
					<div class="d-flex align-items-center justify-content-end">
						<b-form-checkbox
						@change="toggleVideoStatus(media)"
						:disabled="media.statusProcessing || isDisabledControl || movePlaylist || (streamAlive && stream.enabled) || isOneMinuteBefore"
						v-model="media.enabled"
						switch />
						<b-dropdown menu-class="dropdown-menu_md" right variant="icon" no-caret :disabled="streamAlive && stream.enabled">
							<template #button-content>
								<i class="fa fa-ellipsis-v" />
							</template>
							<b-dropdown-item class="playlist__dropdown-item" :disabled="media.statusProcessing || isDisabledControl ||movePlaylist || (streamAlive && stream.enabled) || isOneMinuteBefore"
								@click="requestVideoRemoval(media)"
							><icon-base

								width-view-box="28"
								height-view-box="28"
								icon-name="edit"
							>
								<icon-trash />
							</icon-base> Delete</b-dropdown-item>
						</b-dropdown>
					</div>
					<div v-show="!media.file && !isAnyUpload" class="move-buttons pr-1">
						<b-button variant="icon" :class="`${isDisabledControl || movePlaylist || (streamAlive && stream.enabled) || isOneMinuteBefore ? 'disabled' : ''} ${index !== 0 ? '' : 'd-none'}`" class="px-1" @click="moveVideo('up',media)" :disabled="streamAlive && stream.enabled"> <i class="fa fa-arrow-up text-dark-8" /> </b-button>
						<b-button variant="icon" :class="`${isDisabledControl || movePlaylist || (streamAlive && stream.enabled) || isOneMinuteBefore ? 'disabled' : ''} ${index < videoFiles.length - 1 ? '' : 'd-none'}`" class="px-1" @click="moveVideo('down',media)" :disabled="streamAlive && stream.enabled"> <i class="fa fa-arrow-down text-dark-8" /> </b-button>
					</div>
				</b-col>
			</b-row>
		</ul>

		<confirm-modal
			:message="'You are about to delete video named `' + (focusedVideo && focusedVideo.fileName || '') + '`'"
			modal-id="confirm-video-removal"
			modal-type="danger"
			ok-text="Delete"
			cancel-text="Keep"
			@modal-confirm="removeVideoFile()"
		/>
		<dvr-archive-video-preview-modal
			v-if="selectedVideo"
			:stream="stream"
			:video="selectedVideo"
			/>
	</div>
</template>
<script>
import _ from 'lodash';
import { bytes } from '@/filtersNew';
import StreamService from '@/services/StreamService';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import IconBase from '@/components/icon/IconBase.vue';
import IconTrash from '@/components/icon/IconTrash.vue';
import DvrArchiveVideoPreviewModal from '@/components/modals/DvrArchiveVideoPreviewModal.vue';

export default {
	name: 'SchedulerPlaylistCompact',
	components: {
		ConfirmModal,
		IconBase,
		IconTrash,
		DvrArchiveVideoPreviewModal
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		streamAlive: {
			type: Boolean
		},
		videoFiles: {
			type: Array,
			default() { return []; }
		},
		movePlaylist: {
			type: Boolean,
			default() { return false; }
		},
		isAnyUpload: {
			type: Boolean,
			default() { return false; }
		},
		schedulerSettings: {
			type: Object,
			default() { return {}; }
		},
		isDisabledControl: {
			type: Boolean,
			default() { return false; }
		},
	},
	computed: {
		isOneMinuteBefore () {
			if (!this.schedulerSettings || !this.schedulerSettings.mode) return false
			const {mode: modeSchedule, datetime: dateTimeSchedule} = this.schedulerSettings
			if(modeSchedule !== 'datetime' || !this.stream.enabled)  {
				return false;
			}
			const value = (dateTimeSchedule - Date.now()) / 1000;
			return value > 0 &&  Math.max(value, 0) <= 60;
		}
	},
	data() {
		return {
			focusedVideo: {},
			processing: true,
			selectedVideo: null,
		};
	},
	async mounted() {
		// let videoList = []
		// try {
		//   videoList = await StreamService.getStreamPlaylist(this.stream._id)
		// } catch (e) {
		//   this.$notify({ group: 'error', text: 'could not retreive playlist videos' })
		// }

		// videoList = _.compact(videoList).map((videoFile) => {
		//   videoFile.removing = false
		//   videoFile.statusProcessing = false
		//   videoFile.uploadTime = new Date(videoFile.uploadTime)

		//   return videoFile
		// })

		// this.videoFiles = videoList

		this.processing = false;
	},
	methods: {
		requestVideoUpload() {
			this.$emit('request-upload');
		},
		moveVideo(method = 'up', video) {
			this.$emit('move-video', method, video);
		},
		async toggleVideoStatus(video) {
			this.$emit('toggle-video-status', video);
		},
		async requestVideoRemoval(videoItem) {
			this.focusedVideo = videoItem;
			this.$root.$emit('bv::show::modal', 'confirm-video-removal');
		},
		async requestVideoPreview(media) {
			this.selectedVideo = media;
			setTimeout(() => {
				this.$root.$emit('bv::show::modal', 'modal-dvr-archive-video-preview');
			}, 100);
		},
		async removeVideoFile() {
			const videoId = this.focusedVideo.id;

			const vindex = _.findIndex(this.videoFiles, { id: videoId });
			this.$emit('video-update', vindex, { removing: true });

			await StreamService.removeStreamPlaylistVideo(
				this.stream._id,
				videoId,
				this.focusedVideo.type === 'live-to-vod'
			);
			// let index = this.videoFiles.indexOf(video)
			// this.videoFiles.splice(index, 1)

			this.$emit('video-removed', videoId);
		},
		showInBytes(item) {
			bytes(item, false, 0, true)
		}
	}
};
</script>

<style>
.video-item__name {
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	display: block;
	padding: .5rem 0;
	max-width: 100%;
}
.hidden {
	visibility: hidden;
}
</style>
