<template>
	<b-sidebar class="add-destination-sidebar" width="516px" id="add-destination-modal" no-header bg-variant="dark-2" header-class="text-s-xxl text-white font-500" body-class="bg-dark-2" shadow backdrop @hidden="clearSelected">
		<template #default="{ hide }">
			<header class="d-flex justify-content-between align-items-center p-3">
				<h4 class="text-white text-s-xxl text-500 mb-0">New Destination</h4>
				<b-button size="sm" variant="icon" @click="hide">
					<icon-base width-view-box="12" height-view-box="12" iconColor="none" width="12" height="12" class="text-dark-7">
						<icon-close />
					</icon-base>
				</b-button>
			</header>
			<main>
				<b-row tag="ul" class="list-unstyled mw-100 w-100 platform-list" no-gutters>
					<b-col tag="li" cols="4">
						<PlatformItem />
					</b-col>
					<b-col tag="li" v-for="(item, index) in platforms" :key="index" cols="4">
						<PlatformItem :platform="item" />
					</b-col>
				</b-row>
				<PlatformDetails />
			</main>
		</template>
	</b-sidebar>
</template>

<script>
import { mapGetters } from 'vuex'
import Spinner from "@/components/ui/Spinner.vue"
import IconBase from '@/components/icon/IconBase.vue'
import IconClose from '@/components/icon/IconClose.vue'
import PlatformItem from "@/components/PlatformItem.vue"
import PlatformDetails from "@/components/PlatformDetails.vue"

export default {
	name: 'AddDestinationModal',
	components: {
		Spinner,
		IconBase,
		IconClose,
		PlatformItem,
		PlatformDetails,
	},
	props: {
		
	},
	data() {
		return {
			
		};
	},
	computed: {
		...mapGetters({
			platforms: "Destination/platforms",
			isPlatformSelected: "Destination/isPlatformSelected",
		}),
	},
	watch: {
		
	},
	async mounted() {
	
	},
	methods: {
		clearSelected() {
			this.$store.dispatch('Destination/clearSelectedPlatform')
			this.$store.dispatch('Destination/clearDestination')
		}
	}
}
</script>

<style>
.add-destination-sidebar .b-sidebar > .b-sidebar-body {
	overflow-y: visible;
}
.add-destination-sidebar .platform-list {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 85vh;
}
</style>