<template>
	<svg
		width="30"
		height="28"
		viewBox="0 0 30 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.0664 12.0205L16.9332 15.5406L11.0664 19.0606V12.0205Z"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14 4.54053C20.0868 4.54053 25.0002 9.45396 25.0002 15.5407C25.0002 21.6275 20.0868 26.541 14 26.541"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.9997 26.5412C7.91294 26.5412 2.99951 21.6278 2.99951 15.541"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-dasharray="0 6"
		/>
		<path
			d="M2.99951 15.5407C2.99951 9.45396 7.91294 4.54053 13.9997 4.54053"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-dasharray="0 6"
		/>
		<path
			d="M16.9333 7.84076L13.7065 4.5407L16.9333 1.31396"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/></svg></template>
