import Vue from 'vue';
import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';

import api from '@/api';
// Vuex.Store.prototype.axios = api;

import Ui from './modules/Ui';
import Auth from './modules/Auth';
import User from './modules/User';
import Streams from './modules/Streams';
import DynamicPlayers from './modules/DynamicPlayers';
import Playout from './modules/Playout';
import Playlist from './modules/Playlist';
import Highlight from './modules/Highlight';
import Transcoders from './modules/Transcoders';
import Paywalls from './modules/Paywalls';
import Destination from './modules/Destination';

const vuexStorage = new VuexPersistence({
	storage: window.localStorage,
	supportCircular: false,
	// Save only these modules:
	reducer: (state) => ({
		Ui: state.Ui,
		Auth: state.Auth
	})
});

const defaultState = () => ({
	modal: null,
	isLoading: false,
});

const state = defaultState();

const mutations = {
	SET_MODAL(state, modalName) {
		state.modal = modalName;
	},
	SET_LOADING(state, isLoading) {
		state.isLoading = isLoading;
	}
};

const actions = {
	setModal({ commit }, modalName) {
		commit('SET_MODAL', modalName);
	},
	setLoading({ commit }, isLoading) {
		commit('SET_LOADING', isLoading);
	}
};

export default createStore({
	state,
	mutations,
	actions,
	modules: {
		Ui,
		Auth,
		User,
		Streams,
		DynamicPlayers,
		Playout,
		Highlight,
		Playlist,
		Transcoders,
		Paywalls,
		Destination
		// accounts,
		// streams
	},
	plugins: [vuexStorage.plugin],
	strict: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging'
});
