<template>
	<div class>
		<!-- Modal Component -->
		<!-- modal-class="modal-add-pre-record modal-fullscreen1 modal-fullscreen-right modal-platform" -->
		<b-modal
			hide-header
			hide-footer
			centered
			size="lg"
			:id="'modal-add-pre-record' + video.name"
			ref="modalAddPreRecord"
		>
			<div class="modal-container">
				<b-row>
					<b-col>
						<div class="stream-type-info">
							<h4 class="text-s-xl d-none d-md-block text-500">New Pre-Recorded Stream</h4>
							<p class="text-s-l text-dark-8 mt-2">Schedule your recorded file to broadcast on multiple social platforms and destinations at the same time.</p>
						</div>
					</b-col>
				</b-row>

				<b-card bg-variant="dark-2" text-variant="white" class="text-center" v-if="isNoScheduledSub">
					<b-card-text>Please activate your Pre-recorded subscription first.</b-card-text>
				</b-card>

				<b-row v-if="!isNoScheduledSub">
					<b-col>
						<label class="text-s-s text-dark-8 mb-1">Stream Name</label>
						<b-input
							v-model="video.name"
							size="md"
							placeholder="#stream_name"
							class="mb-5"
						/>
					</b-col>
				</b-row>
				<b-row v-if="isLoaded">
					<b-col>
						<p class="text-s-s text-dark-8 mb-0">Playlist</p>
					</b-col>
				</b-row>
				<hr>
				<b-row v-if="isLoaded" no-gutter>
					<b-col md="6">
						<p class="text-m-m text-dark-7 text-truncate mt-2 mt-md-0 mb-0">{{filename}}.mp4</p>
					</b-col>
					<b-col cols="6" md="3">
						<p class="text-m-m text-dark-7 mt-2 mt-md-0 mb-0">{{video.size}} 
							<svg class="mb-1" v-if="!videoValidate(video)" v-b-tooltip.hover :title="exceededFileMessage" :id="`${video.from}-${video.duration}`" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="#E25858"/>
							</svg>
						</p>
					</b-col>
					<b-col cols="6" md="3">
						<p class="text-m-m text-dark-7 mt-2 mt-md-0 mb-0">{{video.duration}} secs
							<svg class="mb-1" v-if="isScheduledTrialSub && parseInt(video.duration) > 60 * 10" v-b-tooltip.hover :title="'Only file under 10 minutes are allowed.'"  :id="`${video.from}-${video.duration}`" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="#E25858"/>
							</svg>
						</p>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="text-right mt-5">
						<b-button
							variant="outline-secondary"
							@click="onDismiss"
							class="mr-2"
							size="md"
						>
							Cancel
						</b-button>
						<b-button
							v-if="isLoaded"
							variant="primary"
							size="md"
							@click="onConfirm"
							:disabled="(!video.name || !videoValidate(video)) || (isScheduledTrialSub && parseInt(video.duration) > 60 * 10)"
						>
							Create Pre-recorded Stream
						</b-button>
					</b-col>
				</b-row>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import MetricsService from '@/services/MetricsService'
import IconBase from '@/components/icon/IconBase.vue';
import IconLivestream from '@/components/icon/IconLivestream.vue';
import IconMultistream from '@/components/icon/IconMultistream.vue';
import IconIpcam from '@/components/icon/IconIpcam.vue';
import IconSchedulestream from '@/components/icon/IconSchedulestream.vue';
import IconVodChannel from '@/components/icon/IconVodChannel.vue';
import UserService from '../../services/UserService';

export default {
	name: 'AddPreRecordStreamModal',
	components: {
		Spinner,
		IconBase,
		IconLivestream,
		IconMultistream,
		IconIpcam,
		IconSchedulestream,
		IconVodChannel
	},
	data() {
		return {
			videoFilename: null,
			scheduledStorageLimit: {},
			activeScheduledSub: {},
			isLoaded: false,
			isNoScheduledSub: false
		};
	},
	props: {
		video: {
			type: Object,
		},
		stream: {
			type: Object,
		}
	},
	computed: {
		preRecordedName() {
			return this.filename + '.mp4'
		},
		filename() {
			this.videoFilename = !this.videoFilename ? this.video.name : this.videoFilename
			if (this.videoFilename.includes('.mp4')) this.videoFilename = this.videoFilename.replace('.mp4', '')
			return this.videoFilename
		},
		isScheduledTrialSub() {
			// Check Pre-recorded Stream 500MB - 7 Days Trial sub
			const subscriptions = this.$store.state.User.subscriptions;
			return subscriptions.some((sub) => sub.package._id === '5cd5485126fd039846a3255f' || sub.package._id === '6362114709950a6a642540c4')
		},
		exceededFileMessage() {
			let errorMessage = 'Only file under ' + this.maxVideoFileSize + 'GB are allowed.'
			if (this.scheduledStorageLimit?.unitsLeft <= 0 || this.video.sizeRaw > this.scheduledStorageLimit?.unitsLeft) {
				errorMessage = 'Your total storage limit has been exceeded. Please upgrade your subscription or delete some files to continue.'
			}
			return errorMessage
		},
		maxVideoFileSize() {
			let maxSize = null;
			const subscriptions = this.$store.state.User.subscriptions;
			const activeScheduledSubs = _.filter(subscriptions, (sub) => sub.category === 'scheduled' && sub.enabled === true)
			
			activeScheduledSubs.forEach(sub => {
				try {
					if (sub.definitionOverride && sub.definitionOverride.schedulerMaxVideoSize) {
						sub.package.definition.schedulerMaxVideoSize = parseFloat(sub.definitionOverride.schedulerMaxVideoSize)
					}
					if (maxSize) {
						const tmpMaxSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
						if (tmpMaxSize > maxSize) {
							maxSize	= tmpMaxSize;
							this.activeScheduledSub = sub
						}
					} else {
						maxSize	= parseFloat(sub.package.definition.schedulerMaxVideoSize);
						this.activeScheduledSub = sub
					}
				} catch (error) {
					console.error(error);
				}
			})

			if (this.scheduledStorageLimit?.unitsLeft > 0 && this.scheduledStorageLimit.unitsLeft < maxSize) {
				maxSize = parseFloat(this.scheduledStorageLimit.unitsLeft / (1024 ** 3)).toFixed(1)
			}
			if (this.scheduledStorageLimit?.unitsLeft <= 0) {
				maxSize = 0
			}
			return maxSize;
		},
		activeTeam(){
			return this.$store.state.User.activatedTeam;
		}
	},
	async mounted() {
		while (!this.$store.state.User) {
			// Waiting
		}
		const subscriptions = this.$store.state.User.subscriptions;
		const activeScheduledSubs = _.filter(subscriptions, (sub) => sub.category === 'scheduled' && sub.enabled === true)
		
		let maxSize
		activeScheduledSubs.forEach(sub => {
			try {
				if (sub.definitionOverride && sub.definitionOverride.schedulerMaxVideoSize) {
						sub.package.definition.schedulerMaxVideoSize = parseFloat(sub.definitionOverride.schedulerMaxVideoSize)
					}
				if (maxSize) {
					const tmpMaxSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
					if (tmpMaxSize > maxSize) {
						maxSize	= tmpMaxSize;
						this.activeScheduledSub = sub
					}
				} else {
					maxSize	= parseFloat(sub.package.definition.schedulerMaxVideoSize);
					this.activeScheduledSub = sub
				}
			} catch (error) {
				console.error(error);
			}
		})
		if (!this.activeScheduledSub.package) {
			this.isNoScheduledSub = true
			return
		}
		const res = await MetricsService.getSubscriptionStorage(UserService.getUserId(this.activeTeam), this.activeScheduledSub.package._id)
		this.scheduledStorageLimit = res ? res : {}
		this.isLoaded = true
	},
	methods: {
		videoValidate(video) {
			return (video.sizeRaw <= parseFloat(this.maxVideoFileSize) * (1024 ** 3) && video.sizeRaw <= this.scheduledStorageLimit.unitsLeft && this.scheduledStorageLimit.unitsLeft > 0)
			
		},
		onDismiss() {
			this.$emit('modal-dismiss');
		},
		async onConfirm() {
			try {
				this.regions = await StreamService.getAvailableRegions('scheduled');
				const region = _.head(this.regions.filter(r => r.platforms.indexOf('scheduled') > -1));
				const streamObj = await StreamService.addStream(this.video.name, region._id, 'scheduled');
				console.log('streamObj', JSON.stringify(streamObj));
				// Create playlist data
				const videoUrl = this.video?.downloadUrl
				const fileName = videoUrl ? videoUrl.substring(videoUrl.indexOf(this.video.streamKey)) : this.video.fileName
				const prefix = this.video.abr ? 'edge-en' : 'edge'
				const videoId = `episodes/${prefix}/${this.stream.user}/${fileName}`
				const durationSec = this.video.duration;
				const durationMins = Math.round((durationSec < 60 ? 60 : durationSec) / 60);

				const payload = {
					id: this.video.vodId || videoId,
					fileName: this.preRecordedName,
					type: 'live-to-vod',
					bytes: this.video.sizeRaw || null,
					mediaInfo: {
						codecs: [{ type: 'video', codec: 'AVC' }, { type: "audio", codec: "AAC" }],
						duration: durationSec,
						durationMins
					}
				}
				console.log('scheduledConfig.payload', JSON.stringify(payload));
				const scheduledConfig = await StreamService.saveStreamPlaylistVideo(streamObj._id, payload)
				console.log('scheduledConfig', JSON.stringify(scheduledConfig));
				window.location.href = `/app/scheduled/${streamObj._id}/dashboard`
			} catch (err) {
				console.error(err);
				this.$notify({ group: 'error', text: err.message });
				await this.$store.dispatch('Streams/getStreams');
				// this.$notify({ group: 'error', text: 'You already reached pre-recorded stream limit. Please upgrade to have more available slots.' });
			}
			this.$emit('modal-confirm');
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.stream-item {
		padding: 16px 26px;
		position: relative;
		/* margin-bottom: 20px; */
		cursor: pointer;
	}
	.stream-item__desc {
		position: relative;
		flex-grow: 1;
	}
	.stream-item:not(:last-child) .stream-item__desc:after {
		content: '';
		display: block;
		border-top: 1px solid var(--c-dark-3);
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		bottom: -15px;
	}
	.stream-item:hover {
		background-color: var(--c-dark-3);
	}
	.stream-item.active {
		background-color: var(--c-main-1);
	}
	.stream-item.active .stream-item__desc:after {
		content: none;
	}
	.stream-item svg {
		color: var(--c-main-1);
	}
	.stream-item:hover svg, .stream-item.active svg {
		color: #fff;
	}
	.stream-item.active .stream-item__p {
		color: #fff;
	}
	.stream-item__title {
		font-size: var(--font-s-m);
	}
	.stream-item__p {
		font-size: var(--font-s-s);
	}
	/* .hideform {
		display: none;
	}
	.showform {
		display: flex;
	} */
	.second-modal {
		position: fixed;
		top: 0;
		background-color: var(--c-dark-2);
		margin: auto;
		width: 400px;
		height: 100%;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
		right: 0;
		z-index: -1;
		border-left: 1px solid var(--c-dark-3);
		border-right: 1px solid var(--c-dark-3);
	}
	.showform.second-modal {
		right: 400px;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	.stream-type-info {
		/* background-color: var(--c-dark-3); */
		padding: 0px;
	}
	.region-dropdown .dropdown-menu {
		left: -100%!important;
	}
	.region-flag {
		width: 20px;
	}
	.modal-logo{
		padding-left: 9px;
	}
	.stream-type-info h4,
	.stream-type-info p {
    	cursor: pointer;
	}
	@media (max-width: 767px) {
		.mobile-header {
			border-radius: 24px 24px 0 0;
			margin-top: 0;
			background-color: var(--c-dark-3);
			border-bottom: 1px solid var(--c-dark-5);
		}
		.hideform.second-modal {
			bottom: -115vh;
			left: 0;
			z-index: 0;
			max-width: 100%;
			transition: opacity 0.3s linear, bottom 0.3s ease-out;
		}
		.showform.second-modal {
			height: auto;
			top: auto;
			bottom: 0;
			left: 0;
			z-index: 1030;
			max-width: 100%;
			transition: opacity 0.3s linear, bottom 0.3s ease-out;
		}
		.modal-logo {
			display: none;
		}
		.modal-title {
			padding: 16px 0;
		}
		.modal-title-style2 {
			display: none;
		}
		.second-modal {
			width: 100%;
			border-left: none;
			border-right: none;
		}
		.btn-close {
			background-color: var(--c-dark-5);
			border-radius: 50%;
			color: var(--c-dark-8);
			height: 32px;
			width: 32px;
		}
		.mt-200 {
			margin-top: 0;
		}
		.stream-item {
			padding: 15px;
		}
		.btn-icon {
			color: var(--c-dark-7);
		}
		:deep(.region-dropdown .dropdown-menu) {
			max-width: 315px;
		}
	}
	@media (min-width: 768px) and (max-width: 991px) {
  .showform.second-modal {
    right: 100%;
  }
}
</style>
