<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import StreamService from '@/services/StreamService';
import CountDown from './CountDown.vue';

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	},
	compactMode: {
		required: true
	},
	mediaPulse: {
		type: Object,
		default() { return {}; }
	}
})

const scopeAlive = ref(true),
	hasStreamThumb = ref(null),
	thumbUrl = ref(null),
	savedSchedulerConfig = ref(null),
	scheduleTimeActive = ref(false),
	thumbCreated = ref(false),
	meta = ref(null),
	liveStreamCountdownSecLeft = ref(null),
	thumbContainer = ref()

const hasStreamScheduled = computed(() => {
	const conf = savedSchedulerConfig.value
	return conf?.mode === 'datetime' && conf?.datetime
})
const countdownSecsLeft = computed(() => {
	const value = savedSchedulerConfig.value?.datetime - Date.now()
	return Math.max(value, 0)
})

onMounted(async () => {
	const isWowzaRegion = !!props.stream?.region?.v2;
	let hostname = 'dvr.castr.io'
	let prefixPath = ''
	if (isWowzaRegion) {
		hostname = 'preview.castr.io'
		prefixPath = 'preview/'
	}

	thumbUrl.value = `https://${hostname}/`
	if (prefixPath) {
		thumbUrl.value += prefixPath
	}

	const regionDepId = props.stream?.region?.identifierHaxr
	thumbUrl.value += `${props.stream.key}/preview.mp4?&region=${regionDepId}`

	// setTimeout(this.setThumbnail.bind(this), 1000);
	if (props.stream.type === 'scheduled') {
		await setupScheduling()
	}

	if (props.stream.type === 'live') {
		const metaInfo = await StreamService.getStreamMetadata(props.stream._id);
		meta.value = metaInfo
		if (meta.value?.countdownDate) {
			liveStreamCountdownSecLeft.value = Math.max(new Date(meta.value.countdownDate) - Date.now(), 0);
		}
	}
})
onUnmounted(() => {
	scopeAlive.value = false
})

watch(() => props.mediaPulse, () => {
	const hostId = props.mediaPulse?.hostId
	if (hostId) {
		const staticPrefix = props.mediaPulse?.staticPrefix;
		const anc = document.createElement('a')
		anc.href = thumbUrl.value
		if (/\w+-\d+/gi.test(hostId) || props.stream.pullUrl) {
			anc.hostname = `${hostId}.castr.io`;
			if (!anc.pathname.includes('static/') && staticPrefix) {
				anc.pathname = anc.pathname.replace(props.stream.key, `static/${props.mediaPulse?.name}`);
			}
		}
		thumbUrl.value = anc.href;
		if (!thumbCreated.value && thumbUrl.value) {
			setThumbnail()
		}
	}
})

const onStreamCountdownTick = (last, isFinished) => {
	if (isFinished) {
		liveStreamCountdownSecLeft.value = null
	}
}

const setThumbnail = () => {
	if (!scopeAlive.value) return;

	const thumbContainerInstatce = thumbContainer.value;
	// prepare a new video element to overalp existing ones
	let mediaElement;
	const streamEnabled = props.stream?.enabled;
	const streamAlive = props.mediaPulse?.alive;

	if (streamEnabled && streamAlive && thumbContainerInstatce) {
		const isWowzaRegion = !!props.stream.region.v2;
		if (isWowzaRegion) {
			const thumbUrl = getVideoThumbUrl();
			if (thumbUrl) {
				thumbCreated.value = true
				mediaElement = createImgInstance(thumbUrl, () => {
					thumbContainerInstatce.appendChild(mediaElement);
				})
			}
		} else {
			const videoSrc = getVideoPreviewUrl();
			mediaElement = createVideoInstance(videoSrc);
			if (mediaElement) {
				thumbCreated.value = true;
				thumbContainerInstatce.appendChild(mediaElement);
				// preview fix for edge: play video to let video thumb appear
				mediaElement.play();
			}
		}
	}
	// add few secs delay to make the video chunk appear
	if (mediaElement) {
		setTimeout(() => {
			mediaElement.className = mediaElement.className + ' ready';

			// reschedule thumb setup
			scheduleSetup.call(this, mediaElement.tagName.toLowerCase());
		}, 3000);
	} else {
		// reschedule thumb setup
		scheduleSetup.call(this);
	}

	function scheduleSetup(thumbType = 'video') {
		// setTimeout(setThumbnail.bind(this), 8000)

		if (!thumbContainer.value) return;

		// remove old video thumbs
		const streamEnabled = props.stream.enabled;

		// recheck if placeholder text has to appear instead of video thumb
		const hasStreamThumbnail = streamEnabled && props.mediaPulse?.alive;
		hasStreamThumb.value = hasStreamThumbnail;

		const mediaEls = thumbContainer.value.getElementsByTagName(thumbType);
		Array.prototype.forEach.call(mediaEls, mediaEl => {
			// remove video if stream is offline or is older video
			if (!hasStreamThumb || mediaEl !== mediaElement) {
				if (thumbContainer.value) {
					thumbContainer.value.removeChild(mediaEl)
				}
			}
		})
	}
}
const getVideoPreviewUrl = () => {
	if (!props.mediaPulse?.alive) return;
	const randomkey = Math.random().toString().slice(2)
	const vidUrl = thumbUrl.value + `&rand=${randomkey}`
	return vidUrl
}
const setupScheduling = async () => {
	const schedulerConfig = await StreamService.getStreamScheduleSettings(
		props.stream._id
	) || {};
	savedSchedulerConfig.value = schedulerConfig;
}
const onScheduleCountdownTick = (value, forceEnded) => {
	if (!savedSchedulerConfig.value) return;
	let offset = 0;
	if (!forceEnded) {
		offset = savedSchedulerConfig.value.datetime - Date.now();
		offset /= 1000;
		if (value) offset = value.totalSeconds;
	}
	scheduleTimeActive.value = offset <= 0;
}

function createVideoInstance(videoSrc) {
	if (!videoSrc) return;
	const vid = document.createElement('video');
	vid.src = videoSrc;
	vid.className = 'stream-preview' + computedClasses.value;
	return vid;
}

function createImgInstance(imgSrc, callback) {
	if (!imgSrc) return;
	const img = document.createElement('img');
	img.src = imgSrc;
	img.className = 'stream-preview';
	img.onload = () => void callback();
	return img;
}
const computedClasses = computed(() => props.compactMode ? 'w-full rounded-md' : 'w-full rounded-t-lg')

const computedPlaceholder = computed(() => {
	if (!props.stream.enabled) return 'Disabled Stream'
	if (props.mediaPulse?.alive) return 'Waiting for frames'
	if (props.stream.type === 'ipcam') return 'No camera source'
	if (props.stream.type === 'vod') return 'Static Media Files'
	if ((props.stream.type === 'scheduled') || (props.stream.type === 'live' && liveStreamCountdownSecLeft)) return 'Stream will go Live in'
	return 'Waiting for stream'

})
</script>
<template>
	<div :class="props.compactMode ? 'line-view': 'grid-view'">
		<div id="thumb-container" ref="thumbContainer" :class="computedClasses" class="aspect-video relative bg-black">
			<div v-show="!hasStreamThumb"
				class="placeholder w-full h-full flex justify-center items-center absolute text-surface-8">
				<span v-if="!compactMode && !thumbCreated">{{ computedPlaceholder }}</span>
			</div>
		</div>
	</div>
</template>
<style>
.line-view .ready {
	@apply rounded-b-lg
}
</style>
