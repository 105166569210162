<script setup>
import _ from 'lodash'
import { ref, onMounted, getCurrentInstance, watch } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import IconBase from '../icon/IconBase.vue'
import IconCross from '../icon/IconCross.vue'
import IconPlus from '../icon/IconPlus.vue'
import FormButton from '../Atoms/FormButton.vue'
import StreamService from '@/services/StreamService'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import FormInput from '../Atoms/FormInput.vue'
import FormInputDuration from '../Atoms/FormInputDuration.vue'
import { timeStringToObject } from '@/utils'

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()

const props = defineProps({
  vodFile: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  vodFolder: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  playerUrl: {
    type: String
  }
})

const chapters = ref(null)
const chaptersCopy = ref(null)
const saveDisabled = ref(true)
const saveProcessing = ref(false)
const chapterSelectedIndex = ref(null)

const emit = defineEmits({
  'route-change': (id) => { return id },
  'video-update': (value) => { return value },
  'video-removed': (value) => { return value }
})

onMounted(async () => {
  getChapters()
})

watch(() => route.params, async () => {
  getChapters()
})

const getChapters = async () => {
  const videoChapters = await StreamService.getVideoChapters(route.params.streamId, props.vodFile?._id)
  chapters.value = _.map(videoChapters, (item) => {
    item.startSec = formatTime(item.startSec)
    item.endSec = formatTime(item.endSec)
    return item
  })
}
const formatTime = (seconds) => {
  const secNum = parseInt(seconds, 10)
  const hours = Math.floor(secNum / 3600)
  const minutes = Math.floor(secNum / 60) % 60
  const sec = secNum % 60

  return { hours, minutes, seconds: sec }
}

const updateChapterStart = (value, item) => {
  const startSec = timeStringToObject(value).hours * 60 * 60 + timeStringToObject(value).minutes * 60 + timeStringToObject(value).seconds
  const endSec = item.endSec.hours * 60 * 60 + item.endSec.minutes * 60 + item.endSec.seconds
  item.startSec = timeStringToObject(value)
  if (endSec <= startSec) {
    item.endSec = item.startSec
  }
  onVodChapterChange(item)
}

const updateChapterEnd = (value, item) => {
  console.log(item);
  console.log(timeStringToObject(value));
  const startSec = item.startSec.hours * 60 * 60 + item.startSec.minutes * 60 + item.startSec.seconds
  const endSec = timeStringToObject(value).hours * 60 * 60 + timeStringToObject(value).minutes * 60 + timeStringToObject(value).seconds
  const duration = props.vodFile?.mediaInfo?.duration
  endSec <= startSec ? item.errorMsg = 'Invalid time range selected' : item.errorMsg = false

  const hasTimeDurationError = duration && ((startSec > duration) || (endSec > duration))
  item.endSec = timeStringToObject(value)
  let isChapterNameEmpty = item.chapter == ''
  if (hasTimeDurationError) {
    item.errorMsg = 'selected time greater than video duration'
  } else if (isChapterNameEmpty) {
    item.errorMsg = 'Chapter field required'
  } else {
    item.errorMsg = false
  }
  onVodChapterChange(item)
}

const onVodChapterChange = (item) => {
  item?.chapter ? item.errorMsg = false : item.errorMsg = 'Chapter field required'
  saveDisabled.value = false
}

const removeChapter = (index) => {
  chapterSelectedIndex.value = index
  root.$emit('bv::show::modal', 'confirm-chapter-removal');
}

const preventEnter = (e) => {
  e.preventDefault()
}
const addChapter = async () => {
  saveDisabled.value = true
  let startSec = {
    hours: 0,
    minutes: 0,
    seconds: 0
  }
  let endSec = {
    hours: 0,
    minutes: 0,
    seconds: 1
  }
  if (chapters.value.length > 0) {
    startSec = chapters.value[chapters.value.length - 1].endSec
    endSec = chapters.value[chapters.value.length - 1].endSec
  }

  const chapter = {
    startSec,
    endSec,
    chapter: '',
    errorMsg: false,
    modified: false,
    orphan: true
  }
  chapters.value.push(chapter)
  copyChapters()
}

const copyChapters = () => {
  chaptersCopy.value = _.cloneDeep(chapters.value);
}

const saveChapters = async () => {
  saveProcessing.value = true
  try {
    const data = _.map(chapters.value, (item) => {
      return {
        startSec: item.startSec.hours * 60 * 60 + item.startSec.minutes * 60 + item.startSec.seconds,
        endSec: item.endSec.hours * 60 * 60 + item.endSec.minutes * 60 + item.endSec.seconds,
        chapter: item.chapter
      }
    })

    const resp = await StreamService.addVideoChapters(route.params.streamId, props.vodFile._id, data)

    chapters.value = _.map(resp.chapters, (item) => {
      item.startSec = formatTime(item.startSec)
      item.endSec = formatTime(item.endSec)
      return item
    })

    copyChapters()
    saveDisabled.value = true

    notify({ group: 'success', text: 'Chapters added' })
  } catch (err) {
    console.log(err)
    notify({ group: 'error', text: 'could not add chapters' })
  }
  saveProcessing.value = false
}

const removeVideoChapter = async () => {
  const chapter = chapters.value[chapterSelectedIndex.value]

  if (!chapter.id) {
    chapters.value.splice(chapterSelectedIndex.value, 1);
    copyChapters()
    return
  }

  try {
    await StreamService.deleteVideoChapter(route.params.streamId,props.vodFile._id, chapter.id)
    chapters.value.splice(chapterSelectedIndex.value, 1)
    notify({ group: 'success', text: 'Chapter deleted' })
    copyChapters()
  } catch (err) {
    console.log(err)
  }
}
</script>

<template>
  <section>
    <h3 class="text-lg font-mediun mt-6 !mb-2">Add chapters to the video</h3>
    <p class="text-surface-8 text-sm !mb-2">It breaks down the video into parts, facilitating easier navigation for
      users. <a target="_blank" href="https://docs.castr.com/en/articles/7948974-add-chapters-to-your-vod"
        class="text-surface-9 underline">Learn more</a>.
    </p>
    <ul class="flex flex-col gap-y-3">
      <li v-for="(chapter, index) in chapters" :key="index" class="grid grid-cols-[1fr_1fr_1fr_auto] gap-2"
        :class="{ 'grid-row-2': chapter?.errorMsg }">
        <FormInputDuration is-object format="HH:mm:ss" :modelValue="chapter.startSec"
          @update:modelValue="value => updateChapterStart(value, chapter)" />
        <FormInputDuration is-object format="HH:mm:ss" :modelValue="chapter.endSec"
          @update:modelValue="value => updateChapterEnd(value, chapter)" />
        <FormInput v-model="chapter.chapter" @keydown.enter.prevent="preventEnter"
          @change="onVodChapterChange(chapter)" />
        <FormButton type="link" isIcon @click="removeChapter(index)">
          <icon-base class="shrink-0" fill="transparent">
            <icon-cross />
          </icon-base>
        </FormButton>
        <span v-if="chapter.errorMsg" class="text-error text-tiny col-span-4">{{ chapter?.errorMsg }}</span>
      </li>
    </ul>
    <div class="flex gap-x-2 mt-6">
      <FormButton @click="addChapter" type="secondary">
        <icon-base class="shrink-0" fill="transparent">
          <icon-plus />
        </icon-base>
        Add chapter
      </FormButton>
      <FormButton :isloading="saveProcessing" :disabled="saveDisabled" @click="saveChapters">
        Save
      </FormButton>
    </div>
    <confirm-modal :message="'You are about to delete chapter'" modal-type="danger" modal-id="confirm-chapter-removal"
      ok-text="Delete" cancel-text="Keep" @modal-confirm="removeVideoChapter()" />
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
