<template>
  <circle cx="8" cy="8" r="3.4" stroke="currentColor" stroke-width="1.2" />
  <circle cx="3" cy="3" r="1.4" stroke="currentColor" stroke-width="1.2" />
  <circle cx="3" cy="13" r="1.4" stroke="currentColor" stroke-width="1.2" />
  <circle cx="13" cy="3" r="1.4" stroke="currentColor" stroke-width="1.2" />
  <circle cx="13" cy="13" r="1.4" stroke="currentColor" stroke-width="1.2" />
  <path d="M6 6L4 4" stroke="currentColor" stroke-width="1.2" />
  <path d="M12 12L10 10" stroke="currentColor" stroke-width="1.2" />
  <path d="M6 10L4 12" stroke="currentColor" stroke-width="1.2" />
  <path d="M12 4L10 6" stroke="currentColor" stroke-width="1.2" />
</template>