<template>
	<div class="">
		<b-modal
			:id="'modalToggleStream' + this.stream._id"
			:ref="'modalToggleStream' + this.stream._id"
			:size="modalSize"
			hide-header
			hide-footer
			centered
		>
		<b-row>
			<b-col><h4 v-if="stream" class="text-s-xl">{{ stream.enabled ? 'Disable' : 'Enable' }} {{ stream.name}}?</h4></b-col>
		</b-row>
		<b-row v-if="stream.enabled">
			<b-col v-if="!transcoderView" class="text-s-s text-dark-8">The stream will not play until you enable it back. Your audience won’t be able to watch this stream anymore.</b-col>
			<b-col v-else class="text-s-s text-dark-8">The transcoder will not work until you enable it back.</b-col>
		</b-row>

		<b-row >
			<b-col class="text-right mt-3">
				<b-button
					variant="dark-3"
					@click="dismiss"
					class="mr-2"
					size="md"
				>
					{{ cancelText }}
				</b-button>
				<b-button 
					v-if="stream.enabled"
					variant="danger"
					size="md"
					@click="toggleStatus"
				>
					Disable
				</b-button>
				<b-button 
					v-if="!stream.enabled"
					variant="primary"
					size="md"
					@click="toggleStatus"
				>
					Enable
				</b-button>
			</b-col>
		</b-row>
		</b-modal>
	</div>
</template>

<script>
import StreamService from '@/services/StreamService';
import TranscoderService from '@/services/TranscoderService';

export default {
	name: 'ToggleStreamModal',
	components: {},
	props: {
		stream: {
			type: Object,
			default() { return {}; }
		},
		modalId: {
			type: String,
			default: 'modal-toggle'
		},
		modalSize: {
			type: String,
			default: 'sm',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
		modalType: {
			type: String,
			default: 'primary'
		},
		body: {
			type: String,
			required: false
		},
		streamCat: {
			type: String
		},
		okText: {
			type: String,
			default: 'Disable'
		},
		cancelText: {
			type: String,
			default: 'Cancel'
		},
		transcoderView: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			processing: false,
			statusProcessing: false,
			streamStatus: this.stream.enabled,
			toggleProcessing: false
		};
	},
	methods: {
		onConfirm() {
			this.$emit('modal-toggle');
			this.dismiss();
		},
		dismiss() {
			this.$emit('modal-cancel');
			this.$refs['modalToggleStream' + this.stream._id].hide();
			this.$emit('modal-dismiss');
			// lazy clear
			setTimeout(() => { this.processing = false; }, 1000);
		},
		async toggleStatus(ev, newStatus) {
			// ev.preventDefault();
			// ev.stopPropagation();
			// this.$emit('toggle-stream', this.stream);
			// TODO this is a legasy hack we should get rig of this in the future. All stream updates should go throug the storage. Not with direct changes!
			const oldStatus = this.streamStatus;
			newStatus = newStatus === undefined ? !oldStatus : newStatus;
			this.streamStatus = newStatus;
			// this.stream.enabled = newStatus;
			if (this.$route.name == 'LivestreamManageDashboard' || this.$route.name == 'ScheduledStreamManageDashboard') {
				this.stream.enabled = newStatus;
			}
			try {
				this.$emit('toggle-processing', true)
				this.$refs['modalToggleStream' + this.stream._id].hide();

				if (!this.transcoderView) {
					await StreamService.toggleStream(this.stream._id);
					await this.$store.dispatch('Streams/getStreams');
				} else {
					await TranscoderService.toggleTranscoder(this.stream._id);
				}
				this.$emit('toggle-done', true)
				// this.streamStatus = newStatus;
				// this.stream.enabled = newStatus;

				window.trackEvent(
					`${newStatus ? 'Enabled' : 'Disabled'} stream: ${
						this.stream.name
					}`,
					this.stream
				);
				this.$notify({ group: "success", text: `You ${newStatus ? 'enabled' : 'disabled'} ${
						this.stream.name} stream` });
			} catch (err) {
				this.streamStatus = oldStatus;
				this.stream.enabled = oldStatus;

				this.$notify({
					group: 'error',
					title: "Couldn't toggle stream status",
					text: err.message
				});

				if (err.message && err.message.indexOf('upgrade') > -1) {
					this.$root.$emit('bv::show::modal', 'billing-prompt');
				}
				setTimeout(() => {
					this.$emit('toggle-processing', false)
				}, 2000);
			}

			setTimeout(() => {
				this.$emit('toggle-processing', false)
			}, 2000);
			this.statusProcessing = false;
		},
	},
	watch: {
		stream() {
			this.streamStatus = this.stream.enabled;
		}
	}
};
</script>

<style scoped>

</style>
