<template>
  <div class="">
    <b-modal ref="modalConfirm"
      :id="modalId || 'modal-confirm'"
      modal-class="modal-platform"
      :size="modalSize || 'md'"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop>
      <p class="text-dark-8">We always strive to improve our service. Please tell us why you decided to cancel:</p>
      <b-form-group label="Cancellation Reasons"    
      class="text-dark-7 text-s-m" 
      :state="state"  
      :invalid-feedback="invalidFeedback"
      :valid-feedback="validFeedback"
      v-if="this.cancellationReasons.length > 0"
      >
        <div v-for="(reason, index) in cancellationReasons" :key="index">
          <b-form-radio v-model="selected" name="cancel-reason" :value="reason" class="text-s-s">{{reason}}</b-form-radio>
        </div>
        <b-form-invalid-feedback :state="state"></b-form-invalid-feedback>
        <b-form-valid-feedback :state="state"></b-form-valid-feedback>
      </b-form-group>
      <label class="text-dark-8">Comments</label>
      <b-form-textarea
        :state="stateComment"
        id="textarea"
        v-model="comment"
        placeholder=""
        rows="5"
        max-rows="10"
        class="mb-3"
      ></b-form-textarea>
      <span class="text-dark-7">{{message}}</span>
        <div class="text-right mt-4">
        <b-button
        class="mr-1"
          variant="dark-6"
          size="md"
          @click="dismiss">{{cancelLabel}}</b-button>
        <b-button @click="onConfirm"
          variant="primary"
          size="md">{{okLabel}}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import SubscriptionService from '@/services/SubscriptionService'

export default {
  name: 'SubscriptionCancelModal',
  props: ['modalId', 'modalSize', 'message', 'okText', 'cancelText'],
  computed: {
    state() {
      if (this.selected === '') {
        return false
      }
      return true
    },
    invalidFeedback() {
      return 'Please select one'
    },
    validFeedback() {
      return ''
    },
    stateComment() {
      if (this.comment === '') {
        return false
      }
      return true
    }
  },
  async mounted () {
    const reasons = await SubscriptionService.getCancellationReasons();
    this.cancellationReasons = reasons;
    this.okLabel = this.okText || 'Ok'
    this.cancelLabel = this.cancelText || 'Cancel'
  },
  data() {
    return {
      cancellationReasons: [],
      comment: '',
      selected: '',
      processing: false,
      okLabel: null,
      cancelLabel: null
    }
  },
  methods: {
    onConfirm() {
      if (( (this.cancellationReasons.length > 0) && this.selected === '') || this.comment === '') {
        return
      }
      this.$emit('modal-confirm', {reason: this.selected, comment: this.comment})
      this.dismiss()
    },
    dismiss() {
      this.$refs.modalConfirm.hide()
      // lazy clear
      setTimeout(() => { this.processing = false }, 1000)
    }
  },
  components: {}
}
</script>

<style scoped>
</style>
