<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		:viewBox="`0 0 ${widthViewBox} ${heightViewBox}`"
		:aria-labelledby="iconName"
		role="presentation"
		:class="classIcon"
	>
		<title :id="iconName" lang="en">{{ iconName }}</title>
		<g :fill="fill ? fill : iconColor">
			<slot />
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		iconName: {
			type: String,
			default: ''
		},
		width: {
			type: [Number, String],
			default: 16
		},
		height: {
			type: [Number, String],
			default: 16
		},
		widthViewBox: {
			type: [Number, String],
			default: 16
		},
		heightViewBox: {
			type: [Number, String],
			default: 16
		},
		iconColor: {
			type: String,
			default: 'currentColor'
		},
		classIcon: {
			type: String,
			default: ''
		},
		fill: {
			type: String,
		}
	}
};
</script>
