<template>
	<div class="">
		<b-modal
			:id="'modalCreateAccessToken'"
			:ref="'modalCreateAccessToken'"
			:size="modalSize"
			hide-header
			hide-footer
			centered
		>
		<b-row>
			<b-col><h4 class="text-s-xl">New Access Token</h4></b-col>
		</b-row>
		<b-row>
			<b-col class="text-s-s mb-2 mt-2">Access token name</b-col>
		</b-row>
    <b-row>
      <b-col>
        <b-input v-model="tokenName" class="input" placeholder="" required="true"/>
      </b-col>
    </b-row>
    <hr>
		<b-row >
			<b-col class="text-right mt-3">
				<b-button variant="dark-3" @click="dismiss" class="mr-2" size="md">{{ cancelText }}</b-button>
				<b-button  variant="primary" size="md" @click="createToken" :disabled="!tokenName">Create New Token</b-button>
			</b-col>
		</b-row>
		</b-modal>
	</div>
  <get-access-token-modal :access-token="accessToken"/>
</template>

<script>
import AccessTokenService from '@/services/AccessTokenService';
import GetAccessTokenModal from '@/components/modals/GetAccessTokenModal.vue'

export default {
	name: 'CreateAccessTokenModal',
	components: {
    GetAccessTokenModal
  },
	props: {
		modalSize: {
			type: String,
			default: 'md',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
		modalType: {
			type: String,
			default: 'primary'
		},
		body: {
			type: String,
			required: false
		},
		streamCat: {
			type: String
		},
		okText: {
			type: String,
			default: 'Disable'
		},
		cancelText: {
			type: String,
			default: 'Cancel'
		}
	},
	data() {
		return {
			processing: false,
			tokenName: '',
      accessToken: {},
		};
	},
	methods: {
		onConfirm() {
			this.$emit('modal-toggle');
			this.dismiss();
		},
		dismiss() {
			this.tokenName = ''
			this.$emit('modal-cancel');
			this.$refs['modalCreateAccessToken'].hide();
			this.$emit('modal-dismiss');
			// lazy clear
			setTimeout(() => { this.processing = false; }, 1000);
		},
		async createToken() {
			try {
				this.$emit('toggle-processing', true)
				this.$refs['modalCreateAccessToken'].hide();
				this.accessToken = await AccessTokenService.createAccessToken({ name: this.tokenName, permissions: ['video:read', 'video:write'] })
        this.$root.$emit('bv::show::modal', 'modalGetAccessToken')
				this.$emit('token-created');
			} catch (err) {
				this.$notify({
					group: 'error',
					title: "Couldn't create access token",
					text: err.message
				});

				if (err.message && err.message.indexOf('upgrade') > -1) {
					this.$root.$emit('bv::show::modal', 'billing-prompt');
				}
			}		},
	},
	watch: {
	}
};
</script>

<style scoped>

</style>
