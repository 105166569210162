<script>
export default {
	compatConfig: {
		MODE: 3,
		COMPONENT_V_MODEL: false,
	}
}
</script>
<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import _ from 'lodash';
import { useNotification } from "@kyvg/vue3-notification";
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ModalBox from './ModalBox.vue';
import IconBase from '@/components/icon/IconBase.vue';
import IconBin from '@/components/icon/IconBin.vue';
import IconSearch from '@/components/icon/IconSearch.vue';
import IconSorting from '@/components/icon/IconSorting.vue';
import IconCross from '@/components/icon/IconCross.vue';
import IconPlus from '@/components/icon/IconPlus.vue';
import FormButton from '@/components/Atoms/FormButton.vue';
import FormInput from '@/components/Atoms/FormInput.vue';
import Title from '../ui/Title.vue';
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';
import DeleteTagModal from './DeleteTagModal.vue';
import { lowerCase } from 'lodash';

const store = useStore()
const { notify } = useNotification()
const router = useRouter()

const sortTagsDropdown = ref(null)
const chooseColorDropdown = ref([])
const chooseNewTagColorDropdown = ref(null)

const newTag = ref(null)

const isVisible = ref(false)
const searchTagQuery = ref('')
const colors = ref([
	{ name: 'color-1' },
	{ name: 'color-2' },
	{ name: 'color-3' },
	{ name: 'color-4' },
	{ name: 'color-5' },
	{ name: 'color-6' },
	{ name: 'color-7' },
	{ name: 'color-8' },
	{ name: 'color-9' }
])
const generalTags = computed(() => store.getters['Ui/generalTags'])
const sortBy = computed(() => store.getters['Streams/sortBy'])

const generalTagsFiltered = computed(() => {
	const tags = _.cloneDeep(generalTags.value)
	const searchResult = tags.filter(tag => tag.title.toLowerCase().includes(searchTagQuery.value.toLowerCase()))
	return searchResult
})

const toggleModal = () => {
	isVisible.value = !isVisible.value
}
const saveNewTagName = async (newTagName, tag) => {
	if (generalTags.value.some(tag => tag.title.toLowerCase() === newTagName.toLowerCase())) {
		notify({ group: 'error', text: 'Tag with this name already exist. Please chose the different one' });
		await store.dispatch('Ui/changeTagName', { newTagName: tag.title, tag });
		return
	}
	if (newTagName.length === 0) {
		notify({ group: 'error', text: 'Tag name can not be empty' });
		await store.dispatch('Ui/changeTagName', { newTagName: tag.title, tag });
		await store.dispatch('Ui/getAllTags')
		return
	}
	if (newTagName === tag.title) {
		notify({ group: 'error', text: 'New name should be different' });
		await store.dispatch('Ui/changeTagName', { newTagName: tag.title, tag });
		return
	}
	try {
		await store.dispatch('Ui/changeTagName', { newTagName, tag });
		notify({ group: 'success', text: 'Tag name was changed' });
	} catch (error) {
		console.log(error);
		await store.dispatch('Ui/changeTagName', { newTagName: tag.title, tag });
		notify({
			group: 'error',
			title: "Couldn't change tag name",
			text: error.message
		});
	}
}

const changeTagColor = async (color, tag, tagIndex) => {
	try {
		await store.dispatch('Ui/changeTagColor', { color, tag });
		notify({ group: 'success', text: 'Tag color was changed' });
		chooseColorDropdown.value[tagIndex].toggleDropdown()
	} catch (error) {
		notify({
			group: 'error',
			title: "Couldn't change tag color",
			text: error.message
		});
		await store.dispatch('Ui/changeTagColor', { color: tag.color, tag });
	}
}

const changeSortTagsBy = (value) => {
	store.dispatch('Ui/setSortTags', value)
	sortTagsDropdown.value.toggleDropdown()
}
const sortedBy = (sort) => sort === sortBy.value

const deleteGeneralTag = (tag, index) => {
	store.dispatch('Ui/deleteTag', tag)
	const messageSuccess = 'The ' + tag.title + ' was succesfully deleted';
	notify({ group: 'success', text: messageSuccess });
}
onMounted(() => {
	// Access individual elements via itemRefs
	chooseColorDropdown.value.forEach((el, idx) => {
		console.log(`Element for item ${idx}:`, el);
	});
})

const closeModal = () => {
	isVisible.value = false
	clearData()
}
const clearData = () => {
	newTag.value = null
}
const addNewGeneralTag = () => {
	newTag.value = {
		color: 'color-1',
		title: 'New Tag'
	}
}

const setTitleForNewTag = (title) => {
	newTag.value.title = title
}

const setNewTagColor = (color) => {
	newTag.value.color = color
	chooseNewTagColorDropdown.value.toggleDropdown()
}

const addNewTag = async () => {
	if (generalTags.value.some(tag => tag.title.toLowerCase() === newTag.value.title.toLowerCase())) {
		notify({ group: 'error', text: 'Tag with this name already exist. Please chose the different one' });
	} else if (newTag.value.title.length === 0) {
		notify({ group: 'error', text: 'Tag name can not be empty' });
	} else {
		try {
			await store.dispatch("Ui/addTag", newTag.value)
			await store.dispatch('Ui/getAllTags')
			notify({ group: 'success', text: `New tag ${newTag.value.title} was added` });
			newTag.value = null
		} catch (error) {
			console.log(error);
			notify({
				group: 'error',
				title: "Couldn't add a new tag",
				text: error.message
			});
		}
	}
}

const computedListHeight = computed(() => newTag.value ? 'h-[232px]' : 'h-[280px]')

defineExpose({ toggleModal })

</script>

<template>
	<ModalBox v-model="isVisible" title="Manage Tags" @close="closeModal">
		<section class="!px-5">
			<div class="flex relative items-center transition-all w-full !mb-4">
				<icon-base class="text-surface-8 absolute left-3 shrink-0 z-10" fill="none">
					<icon-search />
				</icon-base>
				<FormInput placeholder="Search" customClasses="!px-8" v-model="searchTagQuery" />
				<FormButton type="link" size="sm" @click="searchTagQuery = ''" :class="searchTagQuery
					? 'opacity-100 absolute right-8 shrink-0 z-10'
					: 'opacity-0 absolute right-2 shrink-0 z-10'">
					<icon-base class="shrink-0" fill="none">
						<icon-cross />
					</icon-base>
				</FormButton>
				<DropDownMenu class="ml-2 relative z-10" position="right" type="secondary" isIcon ref="sortTagsDropdown">
					<template #toggle-button>
						<icon-base class="shrink-0 text-surface-8" fill="none">
							<icon-sorting />
						</icon-base>
					</template>
					<template #menu-items>
						<DropDownMenuItem :active="sortBy === 'A to Z'" @click="changeSortTagsBy('A to Z')">
							A to Z
							<icon-base class="shrink-0 !ml-2 text-surface-7" v-if="sortedBy('A to Z')" fill="none">
								<icon-checkmark />
							</icon-base>
						</DropDownMenuItem>
						<DropDownMenuItem :active="sortBy === 'Sort by Oldest'" @click="changeSortTagsBy('Z to A')">
							Z to A
							<icon-base class="shrink-0 !ml-2 text-surface-7" v-if="sortedBy('Z to A')" fill="none">
								<icon-checkmark />
							</icon-base>
						</DropDownMenuItem>
					</template>
				</DropDownMenu>
			</div>
			<ul class="divide-y divide-surface-3 overflow-y-auto !px-1" :class="computedListHeight">
				<li class="grid grid-cols-[1fr_5fr_1fr] items-center !py-2 gap-x-3" v-for="(tag, tagIndex) in generalTagsFiltered"
					:key="tagIndex">
					<DropDownMenu class="ml-2 relative" type="secondary" isIcon ref="chooseColorDropdown" wrapperClass="w-24"
						listClasses="grid grid-cols-3">
						<template #toggle-button>
							<div class="rounded-full size-4 hover:ring-2 ring-surface-9" :class="'tag-color ' + tag.color"></div>
						</template>
						<template #menu-items>
							<DropDownMenuItem v-for="(color, index) in colors" :key="index"
								@click="changeTagColor(color, tag, tagIndex)">
								<div class="rounded-full size-4 hover:ring-2 ring-surface-9" :class="'tag-color ' + color.name"></div>
							</DropDownMenuItem>
						</template>
					</DropDownMenu>
					<Title replaceInnerText @changed="value => saveNewTagName(value, tag)"
						titleClasses="h-8 !p-2 m-0 text-tiny rounded-md" :editable="true" :title="tag.title" @edit="" />
					<FormButton class="ml-auto"
						customClasses="h-7 w-7 text-tiny hover:no-underline rounded-md flex items-center justify-center hover:ring-1  hover:ring-error-light hover:text-error-light transition-colors"
						@click="deleteGeneralTag(tag)" size="sm">
						<icon-base fill="transparent">
							<icon-bin />
						</icon-base>
					</FormButton>
				</li>
			</ul>
			<div class="grid grid-cols-[1fr_5fr_1fr] items-center !py-2 !px-1 gap-x-3 !mt-2" v-if="newTag">
				<DropDownMenu position="top-left" class="ml-2 relative" type="secondary" isIcon ref="chooseNewTagColorDropdown"
					wrapperClass="w-24" listClasses="grid grid-cols-3">
					<template #toggle-button>
						<div class="rounded-full size-4 hover:ring-2 ring-surface-9" :class="'tag-color ' + newTag.color"></div>
					</template>
					<template #menu-items>
						<DropDownMenuItem v-for="(color, index) in colors" :key="index" @click="setNewTagColor(color.name)">
							<div class="rounded-full size-4 hover:ring-2 ring-surface-9" :class="'tag-color ' + color.name"></div>
						</DropDownMenuItem>
					</template>
				</DropDownMenu>
				<Title replaceInnerText @changed="setTitleForNewTag" titleClasses="h-8 !p-2 m-0 text-tiny rounded-md"
					:editable="true" :title="newTag.title" @edit="" />
				<FormButton class="ml-auto !mr-2" type="secondary" isIcon :disabled="!newTag?.title" @click="addNewTag(newTag)"
					size="sm">
					<icon-base fill="transparent">
						<icon-plus />
					</icon-base>
				</FormButton>
			</div>
		</section>
		<DeleteTagModal />
		<template v-slot:footer>
			<div class="flex w-full !px-5 !pb-5 !pt-2">
				<FormButton @click="addNewGeneralTag" size="sm">
					<icon-base fill="transparent">
						<icon-plus />
					</icon-base>
					New Tag
				</FormButton>
				<FormButton class="ml-auto" @click="closeModal" type="secondary" size="sm">
					Close
				</FormButton>
			</div>
		</template>
	</ModalBox>
</template>