<template>
	<div class="">
		<!-- Modal Component -->
		<b-modal
			id="modal-vod-video"
			ref="modalVODVideo"
			class="modal-platform modal-frameless"
			size="lg"
			centered
			hide-header
			hide-footer
		>
			<div>
				<b-row>
					<b-col md="6">
						<!-- iframe widget -->
						<div class="placeholder-wrapper">
							<code>Processing playback ..</code>
						</div>
						<iframe
							:src="getPlayerUrl()"
							class="embed"
							frameborder="0"
							width="500"
							height="380"
							allow="autoplay"
							scrolling="no"
							allowfullscreen
							webkitallowfullscreen
							mozallowfullscreen
							oallowfullscreen
							msallowfullscreen
						/>
					</b-col>
					<b-col v-if="active">
						<b-list-group class="list-group-flush">
							<b-list-group-item class="p-2" v-if="!video.dvr">
								<b-row>
									<b-col md="4" class="text-s-m text-dark-7">
										Video name
									</b-col>
									<b-col class="text-s-m text-truncate text-right">
										{{ video.fileName }}
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2" v-if="video.dvr">
								<b-row>
									<b-col md="4" class="text-s-m text-dark-7">
										Duration
									</b-col>
									<b-col md="8" class="text-s-m text-right">
										{{ video.durationText }} {{ video.abbr }}
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2" v-if="!video.dvr">
								<b-row>
									<b-col md="4" class="text-s-m text-dark-7">
										File Size
									</b-col>
									<b-col md="8" class="text-s-m text-right">
										<span
											v-if="video.bytes"
											class="value"
										>
											{{ video.bytes | bytes }}
										</span>
										<span v-else>
											-
										</span>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2" v-if="video.mediaInfo">
								<b-row>
									<b-col md="4" class="text-s-m text-dark-7">
										Duration
									</b-col>
									<b-col md="8" class="text-s-m text-truncate text-right">
										<span v-if="video.mediaInfo.duration<60">{{ video.mediaInfo.duration }} <small>SECS</small> </span>
												<span v-else-if="video.mediaInfo.duration">{{ video.mediaInfo.duration/60|number }} <small>MINS</small> </span>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2" v-if="video.mediaInfo && video.mediaInfo.width">
								<b-row>
									<b-col md="4" class="text-s-m text-dark-7">
										Media Source
									</b-col>
									<b-col md="8" class="text-s-m text-truncate text-right">
									<span>{{ video.mediaInfo.width }} x {{ video.mediaInfo.height }}</span>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item  class="p-2" v-if="!video.dvr">
								<b-row>
									<b-col md="4" class="text-s-m text-dark-7">
										Codecs
									</b-col>
									<b-col md="8" class="text-s-m text-right">
										<div v-if="video.mediaInfo && video.mediaInfo.codec">
											
											
											<!-- media codecs -->
											<div v-if="video.mediaInfo && video.mediaInfo.codecs">
												<b-badge
													v-for="(codec, index) in video.mediaInfo.codecs.filter(({ type}) => type !== 'general')"
													:key="index"
													class="badge_sm ml-2 text-500"
													:variant="codec.type"
													
												>{{ codec.codec }}</b-badge>
											</div>
										</div>
										<div v-else>
											n/a
										</div>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item  class="p-2" v-if="!video.dvr">
								<b-row>
									<b-col md="4" class="text-s-m text-dark-7">
										Created Date
									</b-col>
									<b-col md="8" class="text-s-m text-right">
										{{ video.creationTime | date }}
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</b-col>
					<b-button
						variant="icon"
						size="md"
						@click="onConfirm"
						class="position-absolute btn-close"
					>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8 8L13 3M8 8L13 13M8 8L3 3M8 8L3 13" stroke="#68759C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</b-button>
				</b-row>
			</div>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'VodVideoModal',
	components: {},
	props: {
		video: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			processing: false,
			active: false
		};
	},
	mounted () {
		this.$refs.modalVODVideo.$on('shown', this.onInit);
		this.$refs.modalVODVideo.$on('hide', this.onDismiss);
	},
	methods: {
		onInit() {
			this.active = true;
		},
		onDismiss() {
			this.active = false;
		},
		onConfirm() {
			this.$emit('modal-confirm');
			this.dismiss();
		},
		dismiss() {
			this.$refs.modalVODVideo.hide();
			// lazy clear
			setTimeout(() => {
				this.processing = false;
			}, 1000);
		},
		getPlayerUrl () {
			const video = this.video;
			if (video.dvr) {
				const range = `${video.from}-${video.duration}`
				return `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/vod/${video.streamKey}?range=${range}&preview=true&abr=${video.abr ? 'true' : 'false'}&server=${video.server}`;
			}

			if (video.archive) {
				const range = `${video.archive.from}-${video.archive.duration}`
				return `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${video.archive.key}?range=${range}&preview=true&abr=${video.archive.abr ? 'true' : 'false'}`
							+ (video.archive.namedHls ? '&namedHls=true' : '');
			}

			if (!this.active) return;
			// let playerUri = `https://player.castr.io/vod/${video.id}`
			const playerUri = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/vod/${video.id}?preview=true`;
			return playerUri;
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.placeholder-wrapper {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  z-index:0;
}
iframe.embed {
  max-width: 100%;
  height: 420px;
  margin: 0;
  padding: 0;
  margin-bottom: -7px;
  position: relative;
  z-index:1;
}
.btn-icon {
	top: -15px;
	right: -10px;
}
.btn-close {
	z-index: 9;
}
</style>
