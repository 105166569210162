<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import FormButton from '../Atoms/FormButton.vue';
import { useNotification } from "@kyvg/vue3-notification";

const { notify }  = useNotification()

const store = useStore()

const playout = computed(() => store.getters['Streams/tvPlayout'])
const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])

const isDisabled = ref(true)

watch(
  () => store.state?.Playout?.playoutConfig,
  (configNew, configOld) => {
    if (configOld && playoutConfig.value?.playlist?.length > 0) {
      isDisabled.value = false
    } else  isDisabled.value = true
    configNew?.playlist.map( element => {
      if ((element.endTime - element.startTime < 10 * 60 * 1000) && element.sourceType === 'live') {
        isDisabled.value = true
      }
    })
  },
  { deep: true }
)

const setSchedule = async () =>  {
  await store.dispatch('Playout/setSchedule', playout.value._id)
  notify({
    group: 'success',
    text: `${playout.value.name} was set`
  })
  isDisabled.value = true
}

</script>
<template>
  <FormButton :disabled="isDisabled" label="Set Schedule" @click="setSchedule()" />
</template>

<style scoped></style>
