<template>
	<div class>
		<b-modal centered id="modal-custom-metadata" @hidden="onReset" ref="modalCustomMetadata" body-class="d-flex" size="md"
			hide-footer hide-header @hide="closeModal" @shown="getData">
			<div class="modal-container w-100">
				<b-row class=" px-3 mb-3">
					<b-col>
						<h3 class="text-s-m mb-2 text-400">Custom Metadata</h3>
						<div class="d-flex align-items-center justify-content-start" v-if="platform">
							<svg v-if="platform.type == 'youtube'" class="mr-2" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M2.24451 9.94111C2.37304 7.96233 3.96395 6.41157 5.94447 6.31345C8.81239 6.17136 12.9115 6 16 6C19.0885 6 23.1876 6.17136 26.0555 6.31345C28.0361 6.41157 29.627 7.96233 29.7555 9.94111C29.8786 11.8369 30 14.1697 30 16C30 17.8303 29.8786 20.1631 29.7555 22.0589C29.627 24.0377 28.0361 25.5884 26.0555 25.6866C23.1876 25.8286 19.0885 26 16 26C12.9115 26 8.81239 25.8286 5.94447 25.6866C3.96395 25.5884 2.37304 24.0377 2.24451 22.0589C2.12136 20.1631 2 17.8303 2 16C2 14.1697 2.12136 11.8369 2.24451 9.94111Z" fill="#FC0D1B"/>
								<path d="M12 12V20L20 16L12 12Z" fill="white"/>
							</svg>

							<svg v-if="platform.type == 'facebook'" class="mr-2" xmlns="http://www.w3.org/2000/svg" width="28"
								height="28" viewBox="0 0 28 28" fill="none">
								<path
									d="M24.9003 26.2526C25.647 26.2526 26.2526 25.6471 26.2526 24.9002V3.10235C26.2526 2.35534 25.6471 1.75 24.9003 1.75H3.10235C2.35535 1.75 1.75 2.35534 1.75 3.10235V24.9002C1.75 25.647 2.35525 26.2526 3.10235 26.2526H24.9003Z"
									fill="#3C5A99" />
								<path
									d="M18.6565 26.2526V16.7638H21.8414L22.3183 13.0659H18.6565V10.705C18.6565 9.63436 18.9538 8.90475 20.489 8.90475L22.4472 8.9039V5.59643C22.1086 5.55137 20.9462 5.45068 19.5938 5.45068C16.7705 5.45068 14.8376 7.17401 14.8376 10.3388V13.0659H11.6445V16.7638H14.8376V26.2526H18.6565Z"
									fill="white" />
							</svg>
							<span class="text-s-xxl text-500">{{ platform.name }}'s account</span>
						</div>
					</b-col>
				</b-row>
				<b-row class="px-3 mb-2">
					<b-col>
						<label class="text-dark-9 text-500 text-s-ss mb-1">
							Stream title <span>({{ this.cmetaTitle ? this.cmetaTitle.length : 0 }}/100)</span>
						</label>
						<b-input maxlength="100" style="height: 32px;" v-model="cmetaTitle" class="input" placeholder="" />
					</b-col>
				</b-row>
				<b-row class="px-3 mb-2">
					<b-col>
						<label class="text-dark-9 text-500 text-s-ss mb-1">
							Description
						</label>
						<b-textarea style="" v-model="cmetaDesc" class="input" placeholder="Type something..." />
					</b-col>
				</b-row>
				<b-row v-if="platform.type == 'youtube'" class="px-3 mb-2">
					<b-col>
						<label class="text-dark-9 text-500 text-s-ss mb-1">
							Privacy Status
						</label>
						<b-dropdown class="w-full" menu-class="dropdown-menu_md" variant="dark-3">
							<template #button-content>
									<div class="d-flex align-items-center justify-content-between w-100">
										<span class="text-dark-8 font-weight-normal text-s-m capitalize">{{ selectedPrivacyStatus }}</span>
										<icon-base width-view-box="10" height-view-box="6" iconColor="none" width="10" height="6"
											class="text-dark-8">
											<icon-caret-down />
										</icon-base>
									</div>
								</template>
							<b-dropdown-item @click="onChangeStatus('public')">Public</b-dropdown-item>
							<b-dropdown-item @click="onChangeStatus('unlisted')">Unlisted</b-dropdown-item>
							<b-dropdown-item @click="onChangeStatus('private')">Private</b-dropdown-item>
						</b-dropdown>
					</b-col>
				</b-row>

				<b-row class="px-3">
					<b-col>
						<div style="max-width: 300px;margin-left:auto;margin-right: 0;">
							<div class="text-center d-flex align-items-center justify-content-end mt-3">
								<b-button variant="outline-secondary" size="md" class="w-auto mb-0 mb-md-0 mr-2" @click="closeModal">
									Cancel
								</b-button>
								<b-button @click="onConfirm" variant="primary" size="md" class="w-auto mt-0">
									Save
								</b-button>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import Spinner from "@/components/ui/Spinner.vue"
import { mapGetters } from 'vuex'
import HighlightService from '@/services/HighlightService'

export default {
	name: 'CustomMetadataModal',
	components: {
		Spinner
	},
	data() {
		return {
			cmetaDesc: '',
			cmetaTitle: '',
			error: null,
			exists: [],
			selectedAIO: {},
			search: '',
			selectedPrivacyStatus: 'public'
		};
	},
	props: {
		platform: {
			type: Object,
		},
	},
	computed: {
		...mapGetters({
		}),
		allInOneStreamsFiltered() {
			const allInOneStreams = _.cloneDeep(this.allInOneStreams.filter(stream => stream.name.toLowerCase().includes(this.search.toLowerCase())))
			return allInOneStreams.sort(function (a, b) {
				return new Date(b.creationTime) - new Date(a.creationTime)
			})
		},
	},
	async mounted() {
		await this.$store.dispatch('Streams/getStreams');
		this.cmetaTitle = '';
		this.cmetaDesc = '';
		this.selectedPrivacyStatus = 'public';
	},
	methods: {
		onChangeStatus(status) {
			this.selectedPrivacyStatus = status;
		},
		getData() {
			console.log(this.platform);
			this.cmetaTitle = this.platform.meta.title;
			this.cmetaDesc = this.platform.meta.description;
			this.selectedPrivacyStatus = this.platform.meta.privacyStatus || 'public';
		},
		async onConfirm() {
			let updatedPlatformdata = { ...this.platform }
			updatedPlatformdata.meta.title = this.cmetaTitle;
			updatedPlatformdata.meta.description = this.cmetaDesc;
			updatedPlatformdata.meta.privacyStatus = this.selectedPrivacyStatus;
			try {
				// this.$store.dispatch('Ui/setLoadingStatus', true)
				const updatePlatform = await HighlightService.updatePlatformmeta(
					this.platform.highlight,
					this.platform._id,
					updatedPlatformdata
				);
				this.$notify({ group: 'success', text: 'Platform Metadata Updated' })
				this.closeModal();

				// }

			} catch (err) {
				this.error = err.message
				// this.$store.dispatch('Ui/setLoadingStatus', false)
			}
		},
		async setElement(item) {
			this.selectedAIO = item;
			console.log(item);
			this.$refs.source.hide()
			// try {

			// } catch (err) {
			// 	console.log(err)
			// }
		},
		closeModal() {
			this.cmetaDesc = '';
			this.cmetaTitle = '';
			this.selectedPrivacyStatus = 'public';
			this.$refs['modalCustomMetadata'].hide()
		},
		onReset() {
			this.error = null
		}
	}
};
</script>

<style scoped>
.selected-stream--name {
	position: absolute;
	left: 56px;
	top: 32px;
	z-index: 9;
}

#stream-source {
	height: 32px;
}

div#modal-add-highlight___BV_modal_content_ input {
	height: 32px;
}

.btn-bg-color {
	background-color: var(--c-dark-3);
	border-color: var(--c-dark-3);
	color: #fff;
}

@media (max-width: 767px) {}
</style>
