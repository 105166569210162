<template>
    <g clip-path="url(#clip0_12180_2158)">
      <path
        d="M1.33337 8C1.33337 5.48584 1.33337 4.22876 2.11442 3.44771C2.89547 2.66666 4.15255 2.66666 6.66671 2.66666H9.33337C11.8475 2.66666 13.1046 2.66666 13.8857 3.44771C14.6667 4.22876 14.6667 5.48584 14.6667 8V9.33333C14.6667 11.8475 14.6667 13.1046 13.8857 13.8856C13.1046 14.6667 11.8475 14.6667 9.33337 14.6667H6.66671C4.15255 14.6667 2.89547 14.6667 2.11442 13.8856C1.33337 13.1046 1.33337 11.8475 1.33337 9.33333V8Z"
        stroke="currentColor" stroke-width="1.5" />
      <path
        d="M12 10.6667L10.6667 10.6667M10.6667 10.6667L9.33337 10.6667M10.6667 10.6667L10.6667 9.33334M10.6667 10.6667L10.6667 12"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
      <path d="M4.66663 2.66666V1.66666" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
      <path d="M11.3334 2.66666V1.66666" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
      <path d="M1.66663 6H14.3333" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    </g>
</template>