<template>
	<div id="uppy" style="width: 100%;"></div>
</template>

<script>
const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const Dropbox = require('@uppy/dropbox');
const GoogleDrive = require('@uppy/google-drive');
const AwsS3 = require('@uppy/aws-s3');
import _ from 'lodash';
import UserService from '../services/UserService';

// require('@uppy/core/dist/style.css');
require('@uppy/dashboard/dist/style.css');

export default {
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		totalFilesPerStream: {
			type: Number,
			default() { return 999; }
		},
		videoFiles: {
			type: Array,
			default() { return []; }
		},
		uploadFiles: {
			type: Array,
			default() { return []; }
		},
		encodingPresets: {
			type: Array,
			default() { return []; }
		},
		maxFileSize: {
			type: Number,
			default() { return null; }
		},
		storageLimit: {
			type: Object,
			default() { return null; }
		},
		maxTotalFileSize: {
			type: Number,
			default() { return null; }
		},
	},
	data() {
		return {
			uppy: null
		};
	},
	computed: {
		currentFilesNumber() {
			return this.videoFiles.length + this.uploadFiles.length;
		}
	},
	mounted() {
		this.uppy = Uppy({
			restrictions: {
				maxNumberOfFiles: this.totalFilesPerStream - this.currentFilesNumber <= 0 ? -1 : this.totalFilesPerStream - this.currentFilesNumber,
				minNumberOfFiles: this.totalFilesPerStream - this.currentFilesNumber < 1 ? 0 : 1,
				maxFileSize: this.maxFileSize > 0 ? this.maxFileSize : 1,
				maxTotalFileSize: this.maxTotalFileSize,
				allowedFileTypes: ['video/*']
			},
			locale: {
				strings: {
					youCanOnlyUploadX: {
						0: `You can only upload a maximum of ${this.totalFilesPerStream} files. Upgrade your plan if you need more files.`,
						1: `You can only upload a maximum of ${this.totalFilesPerStream} files. Upgrade your plan if you need more files.`,
					},
					exceedsSize2: 'You are exceeding your storage limit. Please upgrade to continue'
				}
			},
			autoProceed: false,
			onBeforeFileAdded: (currentFile) => {
				this.uploadFiles.push(currentFile);
				if (currentFile.source === 'Dashboard') {
					this.uppy.info(`Drag'n'Drop not available`, 'error', 5000);
					return false;
				}
				const modifiedFile = { ...currentFile };
				modifiedFile.remote.url = modifiedFile.remote.url + `?streamId=${this.stream._id}&streamKey=${this.stream.key}&streamType=${this.stream.type}`;
				modifiedFile.remote.body.encodingPresets = this.encodingPresets;
				this.$emit('upload-enqueue', {upload: modifiedFile, handler: 'uppy'});
				return modifiedFile;
			},
			onBeforeUpload: (files) => {
				return files;
			}})
			.use(Dashboard, {
				inline: true,
				target: '#uppy',
				theme: 'light',
				height: 380,
				locale: {
					strings: {
						dropHint: '',
						dropPaste: '',
						dropPasteImport: 'Import file from cloud provider'
					}
				}
			})
			.use(Dropbox, {
				target: Dashboard,
				companionUrl: process.env.VUE_APP_COMPANION_API_BASE_URL,
				companionHeaders: {
					'auth-token': UserService.getUserToken()
				}
			})
			// .use(GoogleDrive, {
			// 	target: Dashboard,
			// 	companionUrl: process.env.VUE_APP_COMPANION_API_BASE_URL,
			// 	companionHeaders: {
			// 		'auth-token': UserService.getUserToken()
			// 	}
			// })
			.use(AwsS3, {
				getResponseData(responseText, response) {
					return {
						mediaInfo: responseText.mediainfo,
						id: responseText.videoId
					};
				},
				companionUrl: process.env.VUE_APP_COMPANION_API_BASE_URL
			})
			.on('file-removed', (file, reason) => {
				this.uploadFiles.forEach((item, idx) => {
					if (item.id === file.id) {
						this.uploadFiles.splice(idx, 1);
					}
				});
				this.$emit('upload-dequeue', { upload: file, handler: 'uppy' });
			})
			.on('restriction-failed', (file, reason) => {
				this.uploadFiles.forEach((item, idx) => {
					if (item.id === file.id) {
						this.uploadFiles.splice(idx, 1);
					}
				});
				if(reason.toString().includes('exceeding your storage')) {
					let errorMessage = 'upload failed'
					if (this.maxFileSize && this.storageLimit && file.data.size > this.maxFileSize && file.data.size < this.storageLimit.unitsLeft) {
						errorMessage = `Your file size cannot exceed ${parseFloat(this.maxFileSize/(1024 ** 3)).toFixed(1)}GB. Upload smaller file or upgrade your plan to continue.`;
					}
					if (this.storageLimit && file.data.size > this.storageLimit.unitsLeft) {
						errorMessage = `Storage capacity cannot exceed ${parseFloat(this.storageLimit.assigned/(1000 ** 3)).toFixed(1)}GB. Upgrade your plan to continue.`;
					}
					this.$emit('filesize-restriction-exceeded', errorMessage);
				}
			})
			.on('upload-error', (file, error, response) => {
				this.$emit('upload-error', {file, error, response});
			})
			.on('upload-success', (result, response) => {
				const uploadInfo = {
					fileName: result.name,
					fileNameOriginal: result.name,
					bytes: result.size,
					type: result.type,
					source: result.source,
					creationTime: new Date(),
					enabled: true,
					... response.body
				};
				this.uppy.removeFile(result.id);
				this.uploadFiles.forEach((item, idx) => {
					if (item.id === result.id) {
						this.uploadFiles.splice(idx, 1);
					}
				})
				this.uppy.setOptions({
					restrictions: {
						maxNumberOfFiles: this.totalFilesPerStream - this.currentFilesNumber <= 0 ? -1 : this.totalFilesPerStream - this.currentFilesNumber,
						minNumberOfFiles: this.totalFilesPerStream - this.currentFilesNumber < 1 ? 0 : 1,
						maxTotalFileSize: this.maxTotalFileSize - uploadInfo.bytes,
					}
				});
				this.$emit('upload-success', uploadInfo);
			});
	},
	watch: {
		videoFiles() {
			this.$emit('video-files', this.videoFiles);
			this.uppy.setOptions({
				restrictions: {
					maxNumberOfFiles: this.totalFilesPerStream - this.currentFilesNumber <= 0 ? -1 : this.totalFilesPerStream - this.currentFilesNumber,
					minNumberOfFiles: this.totalFilesPerStream - this.currentFilesNumber < 1 ? 0 : 1,
				}
			});
		},
		uploadFiles() {
		}
	}
};
</script>

<style>
div[data-uppy-acquirer-id="MyDevice"] {
    display: none !important;
}
.modal .uppy-Dashboard-AddFiles-title {
  font-size: var(--font-s-xl);
  color: #fff;
  text-align: left;
  font-weight: 400;
  margin: 0px;
  padding: 15px;
  border-bottom: 1px solid var(--c-dark-3);
}
.uppy-Dashboard-AddFiles-list{
	justify-content: center;
}
.modal .uppy-Dashboard-inner span[role="presentation"] {
  display: flex;
  justify-content: center;
}
.modal .uppy-DashboardTab {
  flex: 0 0 50%;
  padding: 10px;
	border: none;
}
.modal .uppy-DashboardTab-btn {
  background-color: var(--c-main-1);
  border: none;
  border-radius: 6px;
  color: #fff;
  width: 100%;
  height: 150px;
	text-align: center;
	flex-direction: column;
	justify-content: center;
}
.modal .uppy-DashboardTab-btn svg {
	margin-bottom: 10px;
}
.modal .uppy-c-btn-link {
	padding: 5px 10px;
	background-color: transparent;
	border: 1px solid var(--c-dark-3);
	color: var(--c-dark-8);
	border-radius: 6px;
}
.modal .uppy-Dashboard-poweredBy {
  display: none;
}
.modal .uppy-Dashboard-inner {
	background-color: transparent;
	border: none;
  max-height: 100%;
  max-width: 100%;
}
.modal .uppy-Dashboard-AddFiles {
	border: 0;
	margin: 0;
}
.modal .uppy-DashboardContent-panel, .modal .uppy-ProviderBrowser-viewType--list, .modal .uppy-ProviderBrowser-headerBar, .modal .uppy-ProviderBrowser-user, .modal .uppy-ProviderBrowser-list, .modal .uppy-ProviderBrowser-search, .modal .uppy-ProviderBrowser-footer, .modal .uppy-Dashboard-AddFilesPanel, .modal .uppy-StatusBar:not([aria-hidden=true]).is-waiting, .modal .uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
	color: #fff;
	background-color: var(--c-dark-2);
	border: none;
}
.modal .uppy-DashboardContent-bar {
	background-color: var(--c-dark-2);
	border-bottom: none;
}
.modal .uppy-Provider-breadcrumbs .uppy-u-reset, .modal .uppy-ProviderBrowser-list .uppy-u-reset {
	background: transparent;
	border: none;
	color: var(--c-dark-8);
}
.modal .uppy-Dashboard-Item-action--remove{
    background: transparent;
    border: none;
}
.uppy-Dashboard-Item, .uppy-StatusBar-actions{
	border: none;
}
.uppy-StatusBar:before{
	display: none;
}
.uppy-StatusBar-actionCircleBtn {
    background: transparent;
    border: 0px;
}
.modal .uppy-ProviderBrowser-list .uppy-ProviderBrowserItem-fakeCheckbox {
	background-color: var(--c-dark-3);
}
.modal .uppy-c-btn-primary {
	background-color: var(--c-main-1);
	border-radius: 6px;
	border: none;
	color: #fff;
	padding: 5px 10px;
}
.modal .uppy-Provider-authTitle {
	color: var(--c-dark-8);
}
.modal .uppy-ProviderBrowser-searchInput {
	background-color: var(--c-dark-3);
}
.modal .uppy-ProviderBrowser-userLogout {
	border: 1px solid var(--c-dark-3);
	background-color: transparent;
	color: #fff;
	padding: 4px 8px;
	float: right;
	position: relative;
	top: -2px;
}
.uppy-Provider-breadcrumbs{
	margin: 0;
}
.uppy-ProviderBrowser-header{
	border: 0px;
}
.uppy-ProviderBrowser-headerBar{
	display: flex;
	align-items: center;
}
</style>


<style scoped>


</style>
