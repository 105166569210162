<template>
  <div class="view-wrapper d-flex align-items-center justify-content-center" :style="{ 'min-height': minWindowHeight || 'auto' }">
    <div class="container">
      <div class="castr-logo-wrapper">
            <div class="logo-icon-dot"></div>
            <a href="/">
              <img class="logo-icon"
                  src="@/assets/castr.png"
                    alt="Streaming Dashboard - Castr" />
            </a>
            <!-- <a href="/">
              <img class="logo-icon"
                  src="https://castr.com/wp-content/uploads/2023/12/Christmas_logo_2023.png"
                    alt="Streaming Dashboard - Castr" />
            </a> -->
          </div>
      <div class="form">
        <h2 class="text-s-xxl">Forgot Password</h2>
        <p class="text-s-m text-dark-9">
          We can help you reset your password and security info.<br>
          First, enter your registered email address and follow<br> the instructions mentioned.
        </p>
        <br>
        <!-- error placeholder -->
        <b-alert v-if="error"
                 show
                 variant="danger"
                 class="left inline"
                 style="margin:15px 0;">{{error.message}}</b-alert>

        <div class="field-container">
          <label class="text-s-s">* Type in your email address</label>
          <b-input v-model="email"
            type="email"
            
            placeholder="example@mail.io"
            @keypress="onInputChange('email')" />

          <p v-show="formErrors.email" class="text-danger">identify yourself</p>
        </div>

        <div v-if="!requested">
          <b-button v-if="!processing"
                    variant="primary"
                    class="button"
                    @click="requestReset">Request reset</b-button>

          <b-progress v-if="processing"
                      :value="100"
                      :max="100"
                      animated
                      style="margin-top:5px;width:300px;"></b-progress>

          <div style="margin-top:30px;">
            <!-- Getting few hints? try <router-link to="/signin">Sign in</router-link> instead -->
            Getting few hints? try <a href="/signin">Sign in</a> instead
          </div>
        </div>
        <div v-else>
          <div class="text-success"
               style="font-size:15px;">
            <i class="fa fa-check-circle"></i>
            Please check your mailbox
          </div>
          <div style="margin-top:20px;">
            <!-- back to <router-link to="/signin">Sign in</router-link> -->
            back to <a href="/signin">Sign in</a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService'

export default {
  name: 'RequestPasswordReset',
  mounted () {
    this.minWindowHeight = window.innerHeight + 'px'
  },
  data() {
    return {
      minWindowHeight: null,
      error: null,
      requested: false,
      processing: false,
      email: null,
      formErrors: {
        email: false
      },
      onInputChange(prop) {
        this.formErrors[prop] = false
      }
    }
  },
  methods: {
    async requestReset () {
      this.error = null
      if (!this.validateForm()) return

      this.processing = true

      try {
        await UserService.requestPasswordReset(this.email)

        this.$notify({ group: 'info', title: 'Request Sent', text: 'We have sent you an email for further instructions' })
        this.requested = true
      } catch (err) {
        this.error = err
      }

      this.processing = false
    },
    validateForm() {
      const prop = 'email'
      const valid = !!this[prop]
      this.formErrors[prop] = !valid

      return valid
    }
  },
  components: { }
}
</script>

<style scoped>

.subtitle {
  font-size: 26px;
  color: #f7f7f7;
  font-weight: 600;
  margin-bottom: 10px;
}
.castr-logo-wrapper {
  position: relative;
  display: inline-block;
}
.castr-logo-wrapper .logo-icon-dot {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  right: 1px;
  top: 16px;
  background-color: #ec0104;
  -webkit-animation: blink 2s ease-in-out infinite;
  animation: blink 2s ease-in-out infinite;
}
.logo-icon {
  width: 132px;
  margin-left: -5px;
}
.form {
  margin-top: 100px;
}
.field-container {
  width: 300px;
  padding: 10px 0;
}
.field-container:last-of-type {
  border-bottom: none;
}
</style>
