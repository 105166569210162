<template>
	<section>
		<b-table 
			responsive
			class="main-table"
			thead-class="text-dark-6" 
			:items="paginateUsers" 
			:fields="fields"
			:busy="loading"
			sort-icon-left
			show-empty>

			<template #table-busy>
				<Spinner text="Retrieving data..." classes="text-dark-8 mt-5" spinner-color="var(--c-dark-8)" spinner-size="15px" />
			</template>

			<template #cell(plain_pass)="row">
				<b-input-group class="min-w-[200px]" size="md">
					<b-form-input v-if="passwordVisibility[row.item.id]" :value="row.value" @click="row.value" readonly></b-form-input>
					<b-form-input v-if="!passwordVisibility[row.item.id]" :value="'xxxxxxxxxxxxxxxxx'" readonly></b-form-input>
					<b-input-group-append>
						<b-button class="py-1 px-2 d-inline-flex align-items-center justify-content-center position-relative" variant="icon" @click="togglePasswordVisibility(row)">
							<svg v-if="!passwordVisibility[row.item.id]" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 7C2.25 6.79345 2.34977 6.35431 2.65019 5.77493C2.94087 5.21433 3.39124 4.57815 4.01786 3.98002C5.26173 2.79268 7.20417 1.75 10 1.75C12.7958 1.75 14.7383 2.79268 15.9821 3.98002C16.6088 4.57815 17.0591 5.21433 17.3498 5.77493C17.6502 6.35431 17.75 6.79345 17.75 7C17.75 7.20655 17.6502 7.64569 17.3498 8.22507C17.0591 8.78567 16.6088 9.42185 15.9821 10.02C14.7383 11.2073 12.7958 12.25 10 12.25C7.20417 12.25 5.26173 11.2073 4.01786 10.02C3.39124 9.42185 2.94087 8.78567 2.65019 8.22507C2.34977 7.64569 2.25 7.20655 2.25 7ZM10 0.25C6.79583 0.25 4.48827 1.45732 2.98214 2.89498C2.23376 3.60935 1.68413 4.37942 1.31856 5.08445C0.96273 5.77069 0.75 6.45655 0.75 7C0.75 7.54345 0.96273 8.22931 1.31856 8.91555C1.68413 9.62058 2.23376 10.3907 2.98214 11.105C4.48827 12.5427 6.79583 13.75 10 13.75C13.2042 13.75 15.5117 12.5427 17.0179 11.105C17.7662 10.3907 18.3159 9.62058 18.6814 8.91555C19.0373 8.22931 19.25 7.54345 19.25 7C19.25 6.45655 19.0373 5.77069 18.6814 5.08445C18.3159 4.37942 17.7662 3.60935 17.0179 2.89498C15.5117 1.45732 13.2042 0.25 10 0.25ZM8.25 7C8.25 6.0335 9.0335 5.25 10 5.25C10.9665 5.25 11.75 6.0335 11.75 7C11.75 7.9665 10.9665 8.75 10 8.75C9.0335 8.75 8.25 7.9665 8.25 7ZM10 3.75C8.20507 3.75 6.75 5.20507 6.75 7C6.75 8.79493 8.20507 10.25 10 10.25C11.7949 10.25 13.25 8.79493 13.25 7C13.25 5.20507 11.7949 3.75 10 3.75Z" fill="currentColor"/>
							</svg>
							<!-- <span v-if="passwordVisibility[row.item.id]" class="show-counter position-absolute text-c-dark-7">({{  streamKeyVisibleTimeout / 1000 }})</span> -->
							<svg v-if="passwordVisibility[row.item.id]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M2.99988 3L16.9999 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M8 4.17947C8.61797 4.06407 9.28407 4 10 4C16 4 18.5 8.5 18.5 10C18.5 10.6561 18.0217 11.8861 16.9815 13.0625M5 5.29808C2.57295 6.74711 1.5 9.01732 1.5 10C1.5 11.5 4 16 10 16C11.8596 16 13.3829 15.5678 14.5999 14.9265" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M11.7678 11.7678C10.7915 12.7441 9.20854 12.7441 8.23223 11.7678C7.25592 10.7915 7.25592 9.20854 8.23223 8.23223" stroke="currentColor" stroke-width="1.5"/>
							</svg>
						</b-button>
						<b-button class="py-1 px-2 m-0" variant="icon" @click="doCopy(row.value)">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</template>

			<template #cell(action)="row">
				<div>
					<b-button 
						variant="outline-danger" 
						size="sm" 
						class="py-0 px-0 btn_delete" 
						:class="{'order-md-2 order-4 ml-md-auto ml-2' : true}"
						@click.prevent="requestDelete(row)">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<ellipse cx="10" cy="4.375" rx="6.25" ry="1.875" stroke="currentColor" stroke-width="1.5" />
								<path
									d="M16.25 4.375L14.6875 16.25C14.25 17.5 12.5 18.125 10 18.125C7.5 18.125 5.8125 17.5 5.3125 16.25L3.75 4.375"
									stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</b-button>

				</div>
			</template>

		</b-table>

		<b-pagination
					v-model="currentPage"
					:total-rows="filteredUsers.length"
					:per-page="perPage"
					size="md"
					v-show="filteredUsers.length > perPage"
					class="my-0"
					page-class="li-page bg-dark-2 p-1"
					ellipsis-class="li-page bg-dark-2 p-1"
					first-class="li-first p-1"
					prev-class="li-prev p-1"
					next-class="li-next p-1"
					last-class="li-last p-1"
		>
			<template #first-text>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8 3L3 8L8 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M13 3L8 8L13 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</template>
			<template #prev-text>
				<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6 1L1 6L6 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</template>
			<template #next-text>
				<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 1L6 6L1 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</template>
			<template #last-text>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M3 3L8 8L3 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M8 3L13 8L8 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</template>
		</b-pagination>
		<confirm-modal 
			modalId="remove-email-access" 
			message="Are you sure you want to remove access for this email ?" 
			modal-type="danger"
			@modal-confirm="onEmailAccessRemoveConfirm" />
	</section>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Spinner from "@/components/ui/Spinner.vue";
import IconBase from '@/components/icon/IconBase.vue';
import IconReorder from '@/components/icon/IconReorder.vue';
import PaywallService from '@/services/PaywallService';
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

export default {
	name: 'PaywallEmailAccess',
	components: {
		IconBase,
		IconReorder,
		Spinner,
		ConfirmModal
	},
	props: {
		paywallStreamId: {
			type: Number,
			require: false
		}
	},
	data() {
		return {
			sortBy: 'Newest',
			fields: [
				{ key: 'id', label: 'ID', class: 'text-s-m text-dark-9 text-s-m', thStyle: { width: '100px'} },
				{ key: 'email', label: 'Email', thStyle: { width: '350px' }},
				{ key: 'plain_pass', label: 'Password', thStyle: { width: '250px' }, tdClass: 'text-left' },
				{ key: 'action', label: '', class: 'last-col action-item' }
			],
			users: [],
			passwordVisibility: [],
			loading: false,
			selected: null,
			currentPage: 1,
			perPage: 10,
		};
	},
	computed: {
		paginateUsers() {
			return this.filteredUsers.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage,
			);
		},
		filteredUsers() {
			return this.users
		}
    },
	async mounted() {
		await this.setupUsers();
	},
	methods: {
		requestDelete(item) {
			this.$root.$emit("bv::show::modal", "remove-email-access");
			this.selected = item
		},
		async onEmailAccessRemoveConfirm() {
			try {
				const id = this.selected.item.id
				const email = this.selected.item.email

				await PaywallService.removePaywallEmailAccess(this.paywallStreamId, id, email)

				// console.log(this.users, id)

				const userIndex = this.users.findIndex(obj => { 
      				return Number(obj.id) === Number(id) 
    			})

				// this.$delete(this.users, userIndex)
				this.users.splice(userIndex, 1);

				this.$notify({
					group: "success",
					text: "Email removed",
				})
				this.selected = null
			} catch(err) {
				console.error("paywall remove access email failed", err)
				this.$notify({ group: "error", text: "could not remove email" })
			}
		},
		togglePasswordVisibility(row) {
			const item = row.item
			const status = this.passwordVisibility[item.id]
			if (!status) {
				this.passwordVisibility[item.id] = true	
				setTimeout(() => {
					this.passwordVisibility[item.id] = false
				}, 5000)
				return
			}

			return this.passwordVisibility[item.id] = false
		},
		doCopy(text) {
			try {
				if (text instanceof Function) text = text();

				this.$copyText(text);
				this.$notify({ group: 'info', text: 'Copied to clipboard' });
			} catch (e) {}
		},
		updateEmailAccess(item) {
			this.users.unshift(item)
		},
		async setupUsers() {
			this.loading = true;
			try {
				const users = await PaywallService.getUsers();
				this.users = _.orderBy(_.filter(users, (user) => { 
					return user.plain_pass && user.paywall === this.paywallStreamId
				}), ['id'], ['desc'])
			} catch(err) {
				this.$notify({
					group: 'error',
					text: err.message || 'get users failed'
				});
				console.error('getUsers failed', err);
			}
			this.loading = false;
		},
		activatePaywall() {
			this.paywallStatus = true
		},
		formatPrice(value) {
			let val = (value/1).toFixed(2).replace(',', '.')
			return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    	}
	},
}
</script>

<style scoped>
</style>
<style>
	.action-item {
		vertical-align: middle !important;
	}
	
	@media (max-width: 767px) {
		.main-table  td, .main-table  th {
			white-space: nowrap;
		}
	}
</style>