<template>
	<div class>
		<b-modal id="modal-add-transcoder" @hidden="onReset" ref="modalAddTranscoder" modal-class="modal-right" body-class="d-flex"
			size="md" hide-footer hide-header>
			<div class="modal-container">
				<b-row align-v="start" class="mt-100 px-3 mobile-header">
					<b-col cols="10" lg="8" class="mt-lg-3">
						<b-navbar-brand :to="{ path: '/dashboard' }" tag="a" class="modal-logo d-none d-lg-block">
							<b-img height="32px" class="logo-icon" :src="require('@/assets/castr.svg')" alt="Streaming Dashboard - Castr" />
							<div class="logo-icon-dot" />
						</b-navbar-brand>
						<h3 class="modal-title d-lg-none m-0">New Transcoder Profile</h3>
					</b-col>
					<b-col cols="2" lg="4" class="mt-3 text-right">
						<b-button variant="icon" class="px-2" @click="closeModal">
							<img :src="require('@/assets/images/menu-close.svg')" alt="Menu close">
						</b-button>
					</b-col>
				</b-row>
				<b-row class="mt-4 px-3">
					<b-col>
						<h3 class="text-s-l text-500 d-none d-lg-block modal-title-style2 px-0">New Transcoder Profile</h3>
						<p class="text-dark-8 mb-4 text-s-s">
							Seamlessly modify codecs and bitrates with new transcoding profile
						</p>
						<b-alert v-if="error" variant="danger" show class="d-flex align-items-start">
							<svg class="flex-shrink-0 mr-2" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M5.36938 3.29623C6.5309 1.17817 7.11166 0.119141 7.99982 0.119141C8.88798 0.119141 9.46874 1.17817 10.6303 3.29623L14.0636 9.55705C15.1498 11.5377 15.6929 12.528 15.2572 13.2638C14.8216 13.9996 13.6921 13.9996 11.4332 13.9996H4.56647C2.30754 13.9996 1.17808 13.9996 0.742432 13.2638C0.306783 12.528 0.849865 11.5377 1.93603 9.55705L5.36938 3.29623ZM7.99978 4.24955C8.41399 4.24955 8.74978 4.58534 8.74978 4.99955V7.49955C8.74978 7.91377 8.41399 8.24955 7.99978 8.24955C7.58556 8.24955 7.24978 7.91377 7.24978 7.49955V4.99955C7.24978 4.58534 7.58556 4.24955 7.99978 4.24955ZM7.99982 11.4996C8.5521 11.4996 8.99982 11.0518 8.99982 10.4996C8.99982 9.94728 8.5521 9.49956 7.99982 9.49956C7.44753 9.49956 6.99982 9.94728 6.99982 10.4996C6.99982 11.0518 7.44753 11.4996 7.99982 11.4996Z" fill="white"/>
							</svg>
							<div class="mt-n1">
								<p class="text-s-m text-500 mb-0">You don’t have enough quota</p>
								<p class="text-s-s mb-0">Please contact <span class="text-underline cursor-pointer text-s-s" onclick="Intercom('show')">sales</span> team to get more Transcoders.</p>
							</div>
						</b-alert>
					</b-col>
				</b-row>
				<b-row class="px-3">
					<b-col>
						<label class="text-s-s">
						    Transcoder Profile name
						</label>

						<b-input v-model="transcoderName" class="input" placeholder="Enter a name" />
					</b-col>
				</b-row>
				<b-row class="px-3 mt-3">
					<b-col
						v-show="(contextualRegionList.length > 0 && selectedStreamType === 'live' ) || contextualRegionList.length > 1"
						class=""
					>
						<label class="text-s-s w-100">
							Hosting Region *
						</label>
						<StreamRegion class="position-relative" :stream="newStreamComputed" @switch-stream-region="selectRegion" />
						<p
							v-show="formErrors.region"
							class="text-warning pl-1"
						>
							specify hosting region
						</p>
					</b-col>
				</b-row>
				<b-row class="px-3">
					<b-col>
						<div>
							<div class="text-center">
								<b-button :disabled="transcoderName.length === 0 || !selectedRegion" @click="onConfirm" variant="primary" size="md" class="w-100 mt-4 mb-4 mb-lg-0">
									{{ processing ? 'Creating...' : 'Create Transcoder'}}
								</b-button>
								<div class="mt-2 d-none d-lg-block">
									<b-button variant="outline-secondary" size="md" class="w-100 mb-3 mb-md-0"
										@click="closeModal">
										Cancel
									</b-button>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import Spinner from "@/components/ui/Spinner.vue";
import TranscoderService from '@/services/TranscoderService';
import StreamService from '@/services/StreamService'; 
import StreamRegion from '../ui/StreamRegion.vue';

export default {
	name: 'AddTranscoderModal',
	components: {
		Spinner,
		StreamRegion
	},
	data() {
		return {
			transcoderName: '',
			processing: false,
			error: null,
			selectedRegion: null,
			regions: [],
			selectedStreamType: 'live',
			channel: {
				name: null,
				region: null
			},
			formErrors: { name: false, region: false },
			onInputChange(prop) {
				this.formErrors[prop] = false;
			}
		};
	},
	computed: {
		newStreamComputed() {
			const newStream = {}
			newStream.uiRegion = this.selectedRegion
			newStream.type = 'live'
			return newStream
		},
		contextualRegionList() {
			const stype = this.selectedStreamType;
			// we don't ask scheduled region from user
			if (stype === 'scheduled') {
				return [];
			}

			return stype
				? this.regions.filter(r => r.platforms.indexOf('restream') > -1)
				: [];
		},
	},
	async mounted() {
		this.regions = await StreamService.getAvailableRegions('list');
	},
	methods: {
		selectRegion(region) {
			this.selectedRegion = region;
			this.channel.region = region._id;
			this.onInputChange('region');
		},
		async onConfirm() {
			this.processing = true;
			try {
				const newTranscoder = await TranscoderService.createTranscoder(
					this.transcoderName,
					this.selectedRegion._id
				)
				this.$emit('transcoder-added', newTranscoder)
				this.closeModal()
			} catch (err) {
				console.error(err)
				this.error = err
				this.selectedRegion = null
			}
			this.processing = false;
		},
		closeModal() {
			this.transcoderName = ''
			this.$refs['modalAddTranscoder'].hide()
		},
		onReset() {
			this.error = null
			this.channel.name = null;
			this.selectedRegion = null;
		}
	}
};
</script>

<style scoped>
@media (max-width: 767px) {
	.mobile-header {
		border-radius: 24px 24px 0 0;
		margin-top: 0;
		background-color: var(--c-dark-3);
	}
	.modal-title {
    padding: 16px 0;
	}
}
</style>