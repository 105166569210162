<script setup>
import _ from 'lodash'
import { ref, onMounted, computed, watch, getCurrentInstance, nextTick, createApp } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import IconBase from '../icon/IconBase.vue'
import IconFile from '../icon/IconFile.vue'
import IconGlobe from '../icon/IconGlobe.vue'
import IconCross from '../icon/IconCross.vue'
import IconSparkles from '../icon/IconSparkles.vue'
import FormButton from '../Atoms/FormButton.vue'
import { bytes } from '../../filtersNew'
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"
import MetricsService from '../../services/MetricsService'
import UserService from "@/services/UserService"
import StreamService from '@/services/StreamService'
import FormSwitch from '../Atoms/FormSwitch.vue'
import FormInput from '../Atoms/FormInput.vue'
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import Alert from '../Atoms/Alert.vue'
import languages from '@/Utils/languages'
import { v4 } from "uuid"

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

// const captionLimitReachedMsg = `<div class="hover-tooltip">The maximum limit for allowed subtitles has been reached. Need more captions? Contact <span class="underline cursor-pointer sales-btn">sales</span></div>`

const app = createApp();
var tooltipContentVue = app.component("tooltipContent", {
  template: `
    <div class="hover-tooltip">The maximum limit for allowed subtitles has been reached. Need more captions? Contact <span class="underline cursor-pointer sales-btn" @click="showIntercom">sales</span></div>`,
  data() {
    return {
      data: {}
    };  
  },
  methods: {
    showIntercom() {
      window.Intercom('show')
    }
  }
});

const captionLimitReachedMsg = () => {
  return { template: tooltipContentVue }
};

const selectedLang = ref({ code: '', name: 'Select Language', nativeName: '' })

const languagesDropdown = ref(null)

const isLoading = ref(false)

const isGenerateCaption = ref(false)

const captions = ref({
  status: false,
  isProcessing: false,
  path: '',
  language: '',
})

const addonCaptions = ref([]);

const uploadInput = ref(null)
const languageFilter = ref('')

const props = defineProps({
  vodFile: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  vodFolder: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  }
})

const emit = defineEmits({
  'video-removed': (value) => { return value }
})

onMounted(async () => {
  getCaptions()
})

watch(() => route.params, async () => {
  getCaptions()
})

const getCaptions = async () => {
  //TODO Move this call in parent component to prevent double Meta call for components (Chaptes, Schedule, etc.)

  const vodMeta = await StreamService.getStreamPlaylistVideoMetaData(route.params.videoId);

  if (vodMeta?.captions) {
    captions.value = vodMeta?.captions
    // if (vodMeta.captions.language) {
    //  selectedLang.value = languages.find(x => x.code === vodMeta.captions.language)
    // }
    checkCaptionGenerationStatus();
  }

  if (vodMeta?.addonCaptions) {
    addonCaptions.value = vodMeta?.addonCaptions;
  }
}

const checkCaptionGenerationStatus = async () => {  
  if (!captions.value.isProcessing || captions.value.path) {
    updateCaption({ isProcessing: false });
    isGenerateCaption.value = false;
    return;
  }

  isGenerateCaption.value = true;
  try {
    let videoId = route.params.videoId;
    if (props.vodFile.replaceDetails && props.vodFile.replaceDetails.videoId) {
      videoId = props.vodFile.replaceDetails.videoId;
    }
    const result = await StreamService.checkFileExists(route.params.streamId, videoId);
    if (result.exists) {
      notify({ group: 'success', text: 'Finished generating caption!' });
      updateCaption({ path: result.uri, isProcessing: false, language: '' });
      isGenerateCaption.value = false;
    }
    setTimeout(() => {
      checkCaptionGenerationStatus();
    }, 10000);
  } catch (err) {
    console.log(err);
  }
}

const onSelectLanguage = (lang) => {
  selectedLang.value = lang;
  languageFilter.value = ''
  languagesDropdown.value.toggleDropdown()
}

const canUploadFile = computed(() => {
  let totalCount = Number(!!(captions.value.path));
  totalCount += addonCaptions.value?.length ?? 0;
  const subs = store.state.User.subscriptions || [];
  const active = subs.filter(x => x.enabled && ['vod', 'live'].includes(x.category));
  let maxCount = 1;
  for(let sub of active) {
    if (sub.definitionOverride && !_.isNil(sub.definitionOverride.numOfSubtitles)) {
      maxCount = Number(sub.definitionOverride.numOfSubtitles);
      break;
    } else if (sub.package && !_.isNil(sub.package.definition.numOfSubtitles)) {
      maxCount = Math.max(Number(sub.package.definition.numOfSubtitles), maxCount);
    }
  }

  if(!active.length) {
    maxCount = 0;
  }

  return totalCount < maxCount;
})

const languagesMapByCode = computed(() => {
  let map = {};
  languages.forEach(x => {
    map[x.code] = x;
  });
  return map;
})

const languagesFiltered = computed(() => {
  return languages.filter(language => language.name.toLowerCase().includes(languageFilter.value.toLowerCase()))
})

const aiTooltipText = computed(() => (captions.value.path && !captions.value.language) ? 'Only 1 AI Generation is allowed per video file.' : !canUploadFile.value
  ?   captionLimitReachedMsg
  : 'AI Generation is currently unable to process files that exceed 2GB in size or have a duration longer than 4 hours. To ensure the inclusion of captions, kindly upload the corresponding subtitles.'
)

const maxAiCaptionGenerationSizeReached = computed(() => (props.vodFile?.mediaInfo?.durationMins > 240) || (props.vodFile?.bytes / 1000000000) > 2)

const uploadCounter = ref(0);

const onClickUploadCaptions = () => {
  uploadInput.value.click()
}
const startUpload = async () => {
  captions.value.isProcessing = true;
  console.log('starting upload');
  
  isLoading.value = true;
  const fdata = new FormData();
  fdata.append("file", uploadInput.value.files[0]);
  uploadCounter.value++;
  try {
    const uniqId = v4();
    const result = await StreamService.uploadVttFile(fdata, route.params.streamId, route.params.videoId, uniqId);
    updateCaption({ path: result.uri, isProcessing: false, language: selectedLang.value.code, });
    notify({ group: 'success', text: 'Successfully uploaded the caption file!' });
    captions.value.isProcessing = false;
  } catch (err) {
    console.log(err);
    notify({ group: 'error', text: 'Failed to upload the caption file!' });
  }

  isLoading.value = false;
  selectedLang.value = { code: "", name: "Select Language", nativeName: "" };
}

const updateCaption = async (payload) => {
  if(!captions.value.path || (!payload.path && 'isProcessing' in payload) || (!payload.path && 'status' in payload)) {
    captions.value = {
      ...captions.value,
      ...payload,
    }
    await StreamService.updateStreamPlaylistVideoMetaData(route.params.streamId, route.params.videoId, { captions: captions.value })
  } else if(payload.path) {
    addonCaptions.value.push(payload);
    await StreamService.updateStreamPlaylistVideoMetaData(route.params.streamId, route.params.videoId, { addonCaptions: addonCaptions.value })
  }
}

const removeCaption = async (fileName, isMain = false) => {
  console.log(fileName, isMain);
  
  if(isMain) {
    captions.value = {
      status: false,
      isProcessing: false,
      path: '',
      language: '',
    }
    await StreamService.updateStreamPlaylistVideoMetaData(route.params.streamId, route.params.videoId, { captions: captions.value })
  } else {
    addonCaptions.value = addonCaptions.value.filter(x => !x.path.includes(fileName));
    await StreamService.updateStreamPlaylistVideoMetaData(route.params.streamId, route.params.videoId, { addonCaptions: addonCaptions.value })
  }
  console.log(captions.value);
  console.log(addonCaptions.value);
  
}

const onClickAiGenerateCaptions = async () => {
  if (captions.value.isProcessing || captions.value.path) {
    if(!canUploadFile.value) return;
    // move the existing main captions
    captions.value.isProcessing = false;
    updateCaption({...captions.value});
    captions.value.path = '';
    captions.value.language = '';
  }
  updateCaption({ isProcessing: true });
  isGenerateCaption.value = true;
  try {
    let videoId = route.params.videoId;
    if (props.vodFile.replaceDetails && props.vodFile.replaceDetails.videoId) {
      videoId = props.vodFile.replaceDetails.videoId;
    }
    const result = await StreamService.generateVttAi(route.params.streamId, videoId);
    if (result.success) {
      notify({ group: 'success', text: 'Caption generation has started!' });
      checkCaptionGenerationStatus();
    } else {
      notify({ group: 'error', text: 'Unable to start caption generation!' });
      updateCaption({ isProcessing: false });
      isGenerateCaption.value = false
    }
  } catch (e) {
    console.log(e);
    notify({ group: 'error', text: 'Unable to start caption generation!' });
    updateCaption({ isProcessing: false });
  }
}
const formatFilePath = (str, caption = null, raw = false) => {
  if (!captions.value.language && !caption?.language && !raw) return 'Captions generated by AI';
  const x = str.split('/');
  if (!x.length) return '';
  return x[x.length - 1];
}
const deleteExistingCaptionFile = async (fileName = '', isMain = false) => {
  // this.featureProcessing.deleteCaptionFile = true;
  try {
    const result = await StreamService.deleteCaptionFile(route.params.streamId, route.params.videoId, fileName);
    if (result.success) {
      notify({ group: 'success', text: 'Successfully deleted existing caption file!' });
      // updateCaption({ path: '', language: '' });
      removeCaption(fileName, isMain);
      selectedLang.value = { code: "", name: "Select Language", nativeName: "" };
    } else {
      notify({ group: 'error', text: 'Failed to delete caption!' });
    }
  } catch (err) {
    console.log(err);
    notify({ group: 'error', text: 'Failed to delete caption!' });
  }
  // this.featureProcessing.deleteCaptionFile = false;
}

const limitTextLength = (text) => {
  if(text.length > 15) {
    return text.slice(0, 6) + '...' + text.slice(text.length - 6);
  }
  return text;
}

const beforeCloseHandler = (args) => {
  // console.log(args.event.target.classList);  
  // if (args.event && args.event.target && args.event.target.classList.contains("hover-tooltip")) {
  //   args.cancel = true;
  // } else {
  //   args.cancel = false;
  // }
}
</script>

<template>
  <section>

    <input :key="uploadCounter" class="!hidden" ref="uploadInput" @change="startUpload" type="file" :multiple="false" accept=".srt,.vtt">
    <h3 class="text-lg font-mediun mt-6 !mb-2">Add captions to the video</h3>
    <p class="text-surface-8 text-sm !mb-2">You can upload .vtt or .srt files or try automatic generation to let
      computers do it for you.</p>
    <Alert class="!mb-4">
      <p>AI generates English captions only. For non-English videos, upload your file for better accuracy. <a
          class="underline text-sureface-8" target="_blank"
          href="https://docs.castr.com/en/articles/7916627-generate-captions-for-vod">Learn More</a>.</p>
    </Alert>

    <div class="my-3 flex gap-x-2 items-center !border border-surface-3 rounded-md text-surface-8"
      v-if="captions.path">

      <div class="!p-2 border-surface-3 border-right-only w-1/3 text-sm">
        <!-- <img v-if="captions.language && languagesMapByCode[captions.language]" width="24" class="mr-2" :src="`https://assets.castr.io/countryflags/${languagesMapByCode[captions.language].code}/flat/64.png`"> -->
        {{ captions.language ? languagesMapByCode[captions.language].name : 'AI Generated' }}
      </div>

      
      <div class="!p-2 flex gap-x-2 items-center w-2/3 text-sm">
        <icon-base class="mr-2 shrink-0" fill="transparent">
          <icon-file />
        </icon-base>
        {{ limitTextLength(formatFilePath(captions.path)) }}
        <FormButton class="ml-auto" type="link" @click="deleteExistingCaptionFile(formatFilePath(captions.path), true)">
          <icon-base class="shrink-0" fill="transparent">
            <icon-cross />
          </icon-base>
        </FormButton>
      </div>
    </div>
    <div class="my-3 flex gap-x-2 items-center !border border-surface-3 rounded-md text-surface-8"
      v-for="addonCaption in addonCaptions" :key="addonCaption.path">

      <div class="!p-2 border-surface-3 border-right-only w-1/3 text-sm">
        <!-- <img v-if="addonCaption.language && languagesMapByCode[addonCaption.language]" width="24" class="mr-2" :src="`https://assets.castr.io/countryflags/${languagesMapByCode[addonCaption.language].code}/flat/64.png`"> -->
        {{ addonCaption.language ? languagesMapByCode[addonCaption.language].name : 'AI Generated' }}
      </div>

      
      <div class="!p-2 flex gap-x-2 items-center w-2/3 text-sm">
        <icon-base class="mr-2 shrink-0" fill="transparent">
          <icon-file />
        </icon-base>
        {{ limitTextLength(formatFilePath(addonCaption.path, addonCaption)) }}
        <FormButton class="ml-auto" type="link" @click="deleteExistingCaptionFile(formatFilePath(addonCaption.path, addonCaption, true))">
          <icon-base class="shrink-0" fill="transparent">
            <icon-cross />
          </icon-base>
        </FormButton>
      </div>
    </div>
    
    <div class="flex gap-2 items-center flex-wrap" style="padding-bottom: 60px;">
      <div class="max-h-48 hidden"></div>
      <DropDownMenu ref="languagesDropdown" type="secondary" maxHeight="48">
        <template #toggle-button>
          <icon-base class="!mr-2" fill="none">
            <icon-globe />
          </icon-base>
          {{ selectedLang.name }}
          <icon-base class="!ml-1">
            <icon-chevron-up-down />
          </icon-base>
        </template>
        <template #menu-items>
          <DropDownMenuItem isCustom class="!p-2 hover:!bg-transarent">
            <FormInput v-model="languageFilter" />
          </DropDownMenuItem>
          <DropDownMenuItem v-for="(lang, index) in languagesFiltered" :key="index" @click="onSelectLanguage(lang)">
            <span class="capitalize">{{ lang.name }} - {{ lang.nativeName }}</span>
          </DropDownMenuItem>
        </template>
      </DropDownMenu>
      <ejs-tooltip v-if="!selectedLang.code" content="You need to select the language before uploading.">
        <FormButton type="secondary" disabled>
          <icon-base class="mr-2 shrink-0" fill="transparent">
            <icon-file />
          </icon-base>
          Upload file
        </FormButton>
      </ejs-tooltip>
      <ejs-tooltip :beforeClose="beforeCloseHandler" closeDelay='1000' v-else-if="!canUploadFile" :content="captionLimitReachedMsg">
        <FormButton type="secondary" disabled>
          <icon-base class="mr-2 shrink-0" fill="transparent">
            <icon-file />
          </icon-base>
          Upload file
        </FormButton>
      </ejs-tooltip>
      <FormButton v-else type="secondary" @click="onClickUploadCaptions"
        :disabled="!canUploadFile" :isLoading="isLoading">
        <icon-base class="mr-2 shrink-0" fill="transparent">
          <icon-file />
        </icon-base>
        Upload file
      </FormButton>
      <ejs-tooltip :beforeClose="beforeCloseHandler" closeDelay='1000' v-if="!canUploadFile || maxAiCaptionGenerationSizeReached || (captions.path && !captions.language)" :content="aiTooltipText">
        <FormButton type="secondary" disabled>
          <icon-base class="mr-2 shrink-0" fill="transparent">
            <icon-sparkles />
          </icon-base>
          Generate Captions Using AI
        </FormButton>
      </ejs-tooltip>
      <FormButton v-else type="secondary" @click="onClickAiGenerateCaptions"
        :isLoading="isGenerateCaption"
        :disabled="maxAiCaptionGenerationSizeReached || !canUploadFile || captions.isProcessing">
        <icon-base class="mr-2 shrink-0" fill="transparent">
          <icon-sparkles />
        </icon-base>
        Generate Captions Using AI
      </FormButton>
      <FormSwitch class="ml-auto" label="Enabled" labelPosition="left"
        @change="updateCaption({ status: !captions.status })" :modelValue="captions.status" />
    </div>
    <div class="max-h-48 hidden"></div>
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.border-right-only {
  border-right-width: 1px;
  border-right-style: solid;
}
</style>
