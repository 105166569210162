<template>
  <div>
    <vue-slider
      v-if="end && start"
      v-model="currentPos"
      :enableCross="false"
      :style="`height: ${height}px`"
      :min="start"
      :max="end"
    >
      <template v-slot:dot="{}">
        <div
          :style="`
            width: 10px;
            margin-left: 2px;
            height: ${height}px;
            margin-top: -${height / 2 - 7}px;
            cursor: pointer;`"
        >
            <div class="slider"></div>
        </div>
      </template>

      <template v-slot:process="{ style }">
        <div
          class="vue-slider-process selected-area"
          :style="style"
          style="height: 20px"
        >
          <div
            :class="['merge-tooltip']"
            style="background-color: transparent"
          ></div>
        </div>
      </template>

      <template v-slot:tooltip="{ value, focus }">
        <div :class="['custom-tooltip', { focus }]">
          {{ humanTimeRange(value - timeLine.start) }}
        </div>
      </template>

      <template v-slot:label="{ label, active }">
        <div :class="['vue-slider-mark-label', 'custom-label', { active }]">
          {{ label }}
        </div>
      </template>
      <div class="timescale" ref="timescale">
        <div v-for="t in ts" :key="t" class="tick-container" :style="{ left: `${time2Px(t) - 35/2}px` }">
          <div class="time-lines"></div>
          <div class="time-text">{{ humanTime(t + normalizedStart) }}</div>
        </div>
      </div>
    </vue-slider>
  </div>
</template>

<script>
import VueSlider from 'vue-3-slider-component';
import moment from "moment";
// import 'vue-slider-component/theme/antd.css'

export default {
  name: "TimeLine",
  components: {
    VueSlider,
  },
  props: {
    height: {
      type: Number,
      default: 25,
    },
    timeLine: {
      type: Object,
      default: () => ({ start: 0, end: 100 }),
    },
    position: {
      type: Array,
      default: () => [1, 99],
    },
  },
  mounted() {
    VueSlider.compatConfig = { MODE: 3 }
    if(!this.start) this.start = this.timeLine.start;
    if(!this.end) this.end = this.timeLine.end;
    setTimeout(() => {
      this.calcTick();
    }, 2000);
    window.addEventListener('wheel', this.onZoom, { passive: false });
  },
  beforeDestroy () {
    window.removeEventListener('wheel', this.onZoom);
  },
  data() {
    return {
      ts: [],
      normalizedStart: 0,
      normalizedEnd: 0,
      start: 0,
      end: 0,
      tickInterval: 1,
    };
  },
  methods: {
    time2Px(time) {
      const width = this.$refs.timescale?.clientWidth;
      if(!width) return 0;
      return (time) * width / (this.end - this.start);
    },
    onZoom(e) {
      if(!e.altKey || !e.srcElement.className.includes('vue-slider')) return;
      e.preventDefault();
      let delta = e.deltaY * 1.25;
      const isZoomingIn = delta < 0;
      const tick = Math.max(.1, Math.ceil((this.end - this.start) * 0.01)) * Math.abs(delta);
      if(isZoomingIn && (this.end - this.start) <= 20000) return;

      this.end += isZoomingIn ? -tick : tick;
      this.end = Math.ceil(this.end);
      if(this.end <= this.start) this.end = this.start + 1;
      if(this.position[1] >= this.end) this.$emit("range", [this.position[0], this.end - 1]);
      if(this.position[0] <= this.start) this.$emit("range", [this.start + 1, this.position[1]]);
      this.calcTick();
    },
    calcTick() {
      try {
        const TENSEC = 10000;
        const MIN = 6000 * TENSEC;
        const FIVE_MINS = 5 * MIN;
        const TEN_MINS = 10 * MIN;
        const FIFTEEN_MINS = 15 * MIN;
        const THIRTY_MINS = 10 * MIN;
        const HOUR = 60 * MIN;
        const FOUR_HOURS = 4 * HOUR;
        const EIGHT_HOURS = 8 * HOUR;
        const TWELVE_HOURS = 12 * HOUR;
        const DAY = 24 * HOUR;
  
        const START = this.start;
        const END = this.end;
  
        const duration = END - START;
        let tick = Math.ceil(duration / 10);
  
        let interval = TENSEC;
        let lastGap = Math.abs(tick - TENSEC);
        for (const tf of [TENSEC, MIN, FIVE_MINS, TEN_MINS, FIFTEEN_MINS, THIRTY_MINS, HOUR, FOUR_HOURS, EIGHT_HOURS, TWELVE_HOURS, DAY]) {
          if(Math.abs(tick - tf) < lastGap) {
            interval = tf;
            lastGap = Math.abs(tick - tf);
          }
        }
  
        tick = tick - tick % interval;
        if(tick < TENSEC) return;
        const start = START - START % interval;
        this.normalizedStart = start;
        this.normalizedEnd = END - END % interval;
  
        this.ts = [];
        for(let i = start; i < this.normalizedEnd; i += tick) {
          const duration = i - start;
          this.ts.push(duration);
        }
      } catch (e) {
        console.log(e);
      }
    },
    humanTimeRange(duration) {
      return moment
        .utc(moment.duration(duration, "ms").asMilliseconds())
        .format(duration > 1440*60000 ? "D HH:mm:ss" : "HH:mm:ss");
    },
    humanTime(time) {
      return moment(time)
        .format("HH:mm:ss");
    },
  },
  computed: {
    currentPos: {
      set(args) {
        this.$emit("range", args);
      },
      get() {
        return this.position;
      },
    },
  },
  watch: {
    timeLine: {
      deep: true,
      handler(newValue, oldValue) {
        this.start = newValue.start;
        this.end = newValue.end;
        this.calcTick();
      }
    }
  },
};
</script>

<style>
.custom-tooltip {
    padding: 8px;
  background: rgba(0, 0, 0, 0.606);
  color: white;
  width: 100px;
}

.vue-slider-rail {
  background: #4F5C87;
  border-radius: 0 !important;
}

.selected-area {
  background: #0062FF;
  border-radius: 0 !important;
}
.slider {
    height: 20px;
    background: white;
    border-radius: 5px;
    margin-top: 0px;
}

.timescale {
  height: 100%;
  width: 100%;
  position: absolute;
  font-size: 8px;
  pointer-events: none;
  z-index: 100;
  top: 0;
  display: flex;
  overflow: hidden;
}

.tick-container {
  position: absolute;
  height: 100%;
  width: 35px;
  /* background: red; */
}

.time-lines {
  position: absolute;
  width: 1px;
  height: 50%;
  left: 50%;
  background: white;
}

.time-text {
  color: white;
  position: absolute;
  bottom: 0;
}

.vue-slider{
  padding: 0 !important;
}
.vue-slider-dot-focus:hover .vue-slider-dot-tooltip {
    visibility: visible;
}

.vue-slider-dot-focus .vue-slider-dot-tooltip {
    visibility: hidden;
}
</style>