<template>
  <div class>
    <b-modal id="modal-analytics-select-stream" ref="modalSelectStreamModal" size="lg" hide-header hide-footer centered
      @shown="updateShowcase">
      <img src="@/assets/modal-close.svg" alt="Close" @click="dismiss" class="sw_close" />
      <b-row>
        <b-col class="loading-showcase" v-if="this.stream">
          <div v-if="!this.processing">
            <b-tabs content-class="mt-3">
              <b-tab class="text-dark-8 tab-livestream" active>
                <template #title>
                  Livestreams
                </template>
                <b-input-group>
                  <template #append>
                    <b-input-group-text class="seach-icon">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.11096 15.2219C12.0382 15.2219 15.2219 12.0382 15.2219 8.11096C15.2219 4.18368 12.0382 1 8.11096 1C4.18368 1 1 4.18368 1 8.11096C1 12.0382 4.18368 15.2219 8.11096 15.2219Z"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16.9994 16.9994L13.1328 13.1328" stroke="currentColor" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </b-input-group-text>
                  </template>
                  <b-form-input ref="searchWidgetInput" placeholder="Search for files" class="search-form__field"
                    v-model="liveStreamSearchKey"></b-form-input>
                </b-input-group>

                <div v-if="!streamFilter('live').length && !liveStreamSearchKey" class="no-data-err">
                  <div>
                    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16 4H11C7.49967 4 5.74951 4 4.41256 4.68121C3.23655 5.28042 2.28042 6.23655 1.68121 7.41256C1 8.74951 1 10.4997 1 14V18C1 21.5003 1 23.2505 1.68121 24.5874C2.28042 25.7635 3.23655 26.7196 4.41256 27.3188C5.74951 28 7.49967 28 11 28H16C19.5003 28 21.2505 28 22.5874 27.3188C23.7635 26.7196 24.7196 25.7635 25.3188 24.5874C26 23.2505 26 21.5003 26 18V14C26 10.4997 26 8.74951 25.3188 7.41256C24.7196 6.23655 23.7635 5.28042 22.5874 4.68121C21.2505 4 19.5003 4 16 4Z"
                        fill="#A7B5E2" fill-opacity="0.25" />
                      <path
                        d="M35 22.7104V9.28958C35 8.30062 33.9059 7.70332 33.0741 8.23811L25.5741 13.0595C25.2163 13.2895 25 13.6857 25 14.111V17.889C25 18.3143 25.2163 18.7105 25.5741 18.9405L33.0741 23.7619C33.9059 24.2967 35 23.6994 35 22.7104Z"
                        fill="#485377" />
                      <path
                        d="M31 39C35.4183 39 39 35.4183 39 31C39 26.5817 35.4183 23 31 23C26.5817 23 23 26.5817 23 31C23 35.4183 26.5817 39 31 39Z"
                        fill="#E25858" />
                      <path
                        d="M32.408 30.9991L34.7046 28.712C34.7977 28.6189 34.8716 28.5083 34.922 28.3866C34.9724 28.265 34.9983 28.1345 34.9983 28.0029C34.9983 27.8712 34.9724 27.7407 34.922 27.6191C34.8716 27.4974 34.7977 27.3869 34.7046 27.2937C34.6115 27.2006 34.501 27.1267 34.3794 27.0763C34.2577 27.0259 34.1273 27 33.9957 27C33.864 27 33.7336 27.0259 33.612 27.0763C33.4903 27.1267 33.3798 27.2006 33.2867 27.2937L31 29.5909L28.7133 27.2937C28.6202 27.2006 28.5097 27.1267 28.388 27.0763C28.2664 27.0259 28.136 27 28.0043 27C27.8727 27 27.7423 27.0259 27.6206 27.0763C27.499 27.1267 27.3885 27.2006 27.2954 27.2937C27.2023 27.3869 27.1284 27.4974 27.078 27.6191C27.0276 27.7407 27.0017 27.8712 27.0017 28.0029C27.0017 28.1345 27.0276 28.265 27.078 28.3866C27.1284 28.5083 27.2023 28.6189 27.2954 28.712L29.592 30.9991L27.2954 33.2863C27.2018 33.3792 27.1275 33.4896 27.0768 33.6113C27.0261 33.7331 27 33.8636 27 33.9954C27 34.1273 27.0261 34.2578 27.0768 34.3795C27.1275 34.5013 27.2018 34.6117 27.2954 34.7046C27.3882 34.7982 27.4986 34.8725 27.6203 34.9232C27.742 34.9739 27.8725 35 28.0043 35C28.1362 35 28.2667 34.9739 28.3884 34.9232C28.51 34.8725 28.6205 34.7982 28.7133 34.7046L31 32.4074L33.2867 34.7046C33.3795 34.7982 33.49 34.8725 33.6116 34.9232C33.7333 34.9739 33.8638 35 33.9957 35C34.1275 35 34.258 34.9739 34.3797 34.9232C34.5014 34.8725 34.6118 34.7982 34.7046 34.7046C34.7982 34.6117 34.8725 34.5013 34.9232 34.3795C34.9739 34.2578 35 34.1273 35 33.9954C35 33.8636 34.9739 33.7331 34.9232 33.6113C34.8725 33.4896 34.7982 33.3792 34.7046 33.2863L32.408 30.9991Z"
                        fill="white" />
                    </svg>

                    <h3 class="mt-3">There are no live streams to show</h3>
                    <div class="err-description">
                      Please create a livestream to track its analytics.
                    </div>
                    <b-button variant="primary" class="mt-3" to="/dashboard">Go to Livestreams</b-button>
                  </div>
                </div>

                <div v-else>
                  <div class="vodList_single w-100 d-flex align-items-center justify-content-start">
                    <p class="text-500 text-s-s mb-0 text-white ml-4">Stream</p>
                    <!-- <p class="text-500 text-s-s mb-0 text-white">Status</p> -->
                  </div>
                  <div v-for="(categoryName, index) in streamCategoryList" :key="categoryName">
                    <b-row>
                      <b-col cols="12" md="6" lg="12" class="grid-item">
                        <!-- {{stream.name}} -->
                        <b-form-checkbox v-for="streamq in streamFilter(categoryName)" v-model="selectedStream"
                          :key="streamq.key" :value="streamq.key" name="showcaseStreamsLive" class="showcase_list_modal">
                          <div class="d-flex align-items-center justify-content-start inner_folder_wrap w-100">
                            <div class="mb-0">
                              <p class=" text-500 text-s-l mb-0">
                                {{
                                  streamq.name.slice(0, 25) +
                                  (streamq.name.length > 25 ? "..." : "")
                                }}
                              </p>
                            </div>
                          </div>
                        </b-form-checkbox>
                        <!-- <stream-card-view :stream="stream" :class="`showcaseGrid`" :show-substreams="true"
                    :compact-mode="true"  /> -->
                      </b-col>
                    </b-row>
                    <hr :class="{ 'd-none': shouldShowCompactModeStreams }"
                      v-show="index != streamCategoryList.length - 1" />
                  </div>
                </div>
              </b-tab>
              <b-tab class="tab-vod">
                <template #title>
                  VOD Files
                </template>
                <div v-if="!streamFilter('vod').length && !liveStreamSearchKey" class="no-data-err">
                  <div>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M29.5 7.30769C29.5 9.68677 24.0833 11.6154 16.5 11.6154C8.91667 11.6154 3.5 9.68677 3.5 7.30769M29.5 7.30769C29.5 4.92862 24.0833 3 16.5 3C8.91667 3 3.5 4.92862 3.5 7.30769M29.5 7.30769V17M3.5 7.30769V17M29.5 17V26.6923C29.5 29.0714 24.0833 31 16.5 31C8.91667 31 3.5 29.0714 3.5 26.6923V17M29.5 17C29.5 19.3791 24.0833 21.3077 16.5 21.3077C8.91667 21.3077 3.5 19.3791 3.5 17"
                        stroke="#A7B5E2" stroke-opacity="0.25" stroke-width="1.2" />
                      <path
                        d="M30.5 39C34.9183 39 38.5 35.4183 38.5 31C38.5 26.5817 34.9183 23 30.5 23C26.0817 23 22.5 26.5817 22.5 31C22.5 35.4183 26.0817 39 30.5 39Z"
                        fill="#E25858" />
                      <path
                        d="M31.908 30.9991L34.2046 28.712C34.2977 28.6189 34.3716 28.5083 34.422 28.3866C34.4724 28.265 34.4983 28.1345 34.4983 28.0029C34.4983 27.8712 34.4724 27.7407 34.422 27.6191C34.3716 27.4974 34.2977 27.3869 34.2046 27.2937C34.1115 27.2006 34.001 27.1267 33.8794 27.0763C33.7577 27.0259 33.6273 27 33.4957 27C33.364 27 33.2336 27.0259 33.112 27.0763C32.9903 27.1267 32.8798 27.2006 32.7867 27.2937L30.5 29.5909L28.2133 27.2937C28.1202 27.2006 28.0097 27.1267 27.888 27.0763C27.7664 27.0259 27.636 27 27.5043 27C27.3727 27 27.2423 27.0259 27.1206 27.0763C26.999 27.1267 26.8885 27.2006 26.7954 27.2937C26.7023 27.3869 26.6284 27.4974 26.578 27.6191C26.5276 27.7407 26.5017 27.8712 26.5017 28.0029C26.5017 28.1345 26.5276 28.265 26.578 28.3866C26.6284 28.5083 26.7023 28.6189 26.7954 28.712L29.092 30.9991L26.7954 33.2863C26.7018 33.3792 26.6275 33.4896 26.5768 33.6113C26.5261 33.7331 26.5 33.8636 26.5 33.9954C26.5 34.1273 26.5261 34.2578 26.5768 34.3795C26.6275 34.5013 26.7018 34.6117 26.7954 34.7046C26.8882 34.7982 26.9986 34.8725 27.1203 34.9232C27.242 34.9739 27.3725 35 27.5043 35C27.6362 35 27.7667 34.9739 27.8884 34.9232C28.01 34.8725 28.1205 34.7982 28.2133 34.7046L30.5 32.4074L32.7867 34.7046C32.8795 34.7982 32.99 34.8725 33.1116 34.9232C33.2333 34.9739 33.3638 35 33.4957 35C33.6275 35 33.758 34.9739 33.8797 34.9232C34.0014 34.8725 34.1118 34.7982 34.2046 34.7046C34.2982 34.6117 34.3725 34.5013 34.4232 34.3795C34.4739 34.2578 34.5 34.1273 34.5 33.9954C34.5 33.8636 34.4739 33.7331 34.4232 33.6113C34.3725 33.4896 34.2982 33.3792 34.2046 33.2863L31.908 30.9991Z"
                        fill="white" />
                    </svg>

                    <h3 class="mt-3">There are no VOD to show</h3>
                    <div class="err-description">
                      Please upload video files at Video Hosting to track its
                      analytics.
                    </div>
                    <b-button variant="primary" class="mt-3" to="/vods">Go to Video Hosting</b-button>
                  </div>
                </div>
                <div v-else>
                  <div v-if="!this.showVideofiles">
                    <div class="vodList_single-head">
                      <div class="vodList_single w-100 d-flex align-items-center justify-content-between">
                        <p class="text-500 text-s-s mb-0 text-dark-6 pl-0">
                          Name
                        </p>
                        <p class="text-500 text-s-s mb-0 text-dark-6 pl-3">
                          Files
                        </p>
                        <p class="text-500 text-s-s mb-0 text-dark-6 pl-2">
                          Size
                        </p>
                      </div>
                    </div>
                    <div v-for="streamq in streamFilter('vod')" :key="streamq._id" name="showcaseStreamsVod"
                      class="d-flex align-items-center" @click="getvodFiles(streamq._id, streamq.liveParent)">
                      <img src="@/assets/images/solid_folder.svg" alt="Folder" class="mr-3" />
                      <div class="vodList_single w-100 d-flex align-items-center justify-content-between">
                        <p class="text-500 text-s-l mb-0">
                          <b-badge variant="dark-3" v-if="streamq.liveParent"
                            class="badge_beta align-self-center mr-2">Live-to-VOD
                          </b-badge>
                          {{
                            streamq.name.length > 20
                            ? streamq.name.substring(0, 20 - 3) + "..."
                            : streamq.name
                          }}
                        </p>
                        <p class="text-400 text-s-m text-dark-9 mb-0">
                          {{ streamq.totalFiles }}
                        </p>
                        <p class="text-400 text-s-m text-dark-9 mb-0">
                          {{ getBytes(streamq.totalBytes) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="showcased_folder_inner" v-else>
                    <div class="files_parent files_parent_al w-full flex items-center pb-2" @click="showVideofiles = false">
                      <img src="@/assets/images/left-arrow.svg" alt="Arrow" class="mr-4 ml-2" />
                      <img src="@/assets/images/solid_folder.svg" alt="Folder" class="mr-2" />
                      <b-badge variant="dark-3" v-if="this.livetovod"
                        class="badge_beta al_badge align-self-center mr-2">Live-to-VOD
                      </b-badge>
                      <span class="d-inline-block text-500 text-s-m">{{
                        videoFiles[0].playlistName
                      }}</span>
                    </div>
                    <div class="vodList_single w-100 d-flex align-items-center justify-content-between">
                      <p class="text-500 text-s-s mb-0 text-dark-6 pl-0">
                        Stream
                      </p>
                      <p class="text-500 text-s-s mb-0 text-dark-6 pl-3">
                        Duration
                      </p>
                      <p class="text-500 text-s-s mb-0 text-dark-6 pl-2">
                        Date
                      </p>
                    </div>
                    <b-form-checkbox v-for="stream in videoFiles" v-model="selectedVod" :key="getKey(stream)"
                      :value="getKey(stream)" @change="onSelectVideoFile" name="showcaseStreamsVod">
                      <div class="d-flex align-items-center justify-content-between inner_folder_wrap w-100">
                        <div class="mb-0">
                          <p class=" text-500 text-s-l mb-0">
                            {{
                              stream.fileName.slice(0, 25) +
                              (stream.fileName.length > 25 ? "..." : "")
                            }}
                          </p>
                          <p class="mb-0 text-s-s text-400 text-dark-9" v-if="stream.mediaInfo.width">
                            {{ stream.mediaInfo.width }} x
                            {{ stream.mediaInfo.height }}
                          </p>
                        </div>
                        <p class="text-400 text-s-m mb-0 text-dark-8">
                          {{ getDuration(stream) }}
                        </p>
                        <p class="text-400 text-s-m text-dark-8 mb-0">
                          {{ getDate(stream.creationTime) }}
                        </p>
                      </div>
                    </b-form-checkbox>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div class="no-access mt-0" v-else>
            <div class="page-placeholder">
              <div class="page-placeholder-content">
                <div class="page-placeholder-content text-center mt-6">
                  <div class="mb-2 text-dark-8">Please wait</div>
                  <clip-loader color="var(--c-dark-8)" />
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col v-else>
          <div class="no-access mt-0">
            <div class="page-placeholder">
              <div class="page-placeholder-content">
                <div class="page-placeholder-content text-center mt-6">
                  <div class="mb-2 text-dark-8">Retrieving data...</div>
                  <clip-loader color="var(--c-dark-8)" />
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!this.processing">
        <b-col>
          <div class="text-right w-100 py-3 sw_modal_action">
            <b-button variant="outline-secondary" class="mr-2" size="md" @click="dismiss">
              Cancel
            </b-button>
            <b-button variant="outline-danger" class="mr-2" size="md"
              v-if="selectedStream.length + selectedVod.length > 0" @click="clearStreamSelection">
              Clear Selections
            </b-button>
            <b-button variant="primary" :disabled="selectedStream.length + selectedVod.length == 0" @click="addStream"
              size="md">
              {{ `Select(${selectedStream.length + selectedVod.length})` }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import ClipLoader from "@/components/ui/ClipLoader.vue";
import StreamService from "@/services/StreamService";
import StreamStatsService from "@/services/StreamStatsService";
import UserService from "@/services/UserService";
import SubscriptionService from "@/services/SubscriptionService";
import { mapGetters } from "vuex";

export default {
  name: "AnalyticsSelectStreamModal",
  components: {
    ClipLoader,
  },
  props: {
    modalSize: {
      type: String,
      default: "sm",
      validator(v) {
        return ["xl", "lg", "md", "sm"].some((size) => size === v);
      },
    },
  },
  data() {
    return {
      processing: true,
      userSubscription: null,
      showcase: null,
      categoryNameMaps: {
        live: "All In One Stream",
        restream: "Multistream",
        ipcam: "IP Camera",
        vod: "Video Hosting",
      },
      categoryCollapseHash: {
        restream: true,
        live: true,
        vod: true,
        ipcam: true,
      },
      selectedStream: [],
      selectedVod: [],
      videoFiles: [],
      selectedVodFiles: [],
      showVideofiles: false,
      livetovod: false,
      setOrder: [],
      liveCount: null,
      vodCount: null,
      liveStreamSearchKey: "",
    };
  },
  watch: {
    selectedStream: function () {
      let livestream = this.selectedStream.filter((s) => {
        return s.type;
      });
      this.vodCount =
        this.selectedStream.length - livestream.length > 0
          ? this.selectedStream.length - livestream.length
          : null;
      this.liveCount = livestream.length;
    },
  },
  computed: {
    ...mapGetters({
      allInOneStreams: "Streams/allInOneStreams",
      vodStreams: "Streams/vodStreams",
      baseSubscription: 'User/baseSubscription',
      addonSubscriptions: 'User/addonSubscriptions'
    }),
    stream() {
      return [...this.allInOneStreams, ...this.vodStreams];
    },
    streamFilter() {
      let streamList = this.stream;
      return (categoryName) => {
        const filteredStreams = streamList.filter((s) => {
          let bool = s.type === categoryName;
          if (bool && this.liveStreamSearchKey) {
            bool = s.name
              .toLowerCase()
              .includes(this.liveStreamSearchKey.toLowerCase());
          }
          return bool;
        });
        return filteredStreams.reverse();
      };
    },
    setStream() {
      let stream = [];
      return (s) => {
        stream[0] = s;
        return stream;
      };
    },
    streamCategoryList() {
      const cats = {};
      // if(this.hasSubStreams && this.showSubStreams){
      // 	this.streams = this.emailFilteredStreams.length > 0 ? this.emailFilteredStreams : this.subaccStreams
      // }
      let streamList = this.stream;
      streamList.forEach((s) => {
        if (s.type != "vod" && s.type != "scheduled" && s.type != "restream")
          cats[s.type] = s.sortOrder;
      });

      let categoryList = Object.keys(cats).sort(
        (cat1, cat2) => cats[cat1] > cats[cat2]
      );
      categoryList = _.sortBy(categoryList, (cat) => cats[cat]);

      return categoryList;
    },
    activeTeam() {
      return this.$store.state.User.activatedTeam;
    },
    shouldShowCompactModeStreams() {
      return this.$store.state.Ui.shouldShowCompactModeStreams;
    },
    shouldShowSubstreams() {
      if (this.showSubStreams) {
        this.streams =
          this.emailFilteredStreams.length > 0
            ? this.emailFilteredStreams
            : this.subaccStreams;
      } else {
        this.streams = this.masteraccStreams;
      }
      return this.hasSubStreams && this.showSubStreams;
    },
  },
  async mounted() {
    this.$store.dispatch("Streams/getStreams");
    this.userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions };
  },

  methods: {
    onSelectVideoFile(vodFileIds = []) {
      this.selectedVodFiles = this.videoFiles.filter(video => vodFileIds.includes(this.getKey(video))).slice();
    },
    getKey(stream) {
      if (stream.refId) {
        return stream.refId;
      } else {
        return stream?.archive?.key
          ? stream.archive.key
          : stream.id.replace(".mp4", "");
      }
    },
    async updateShowcase() {
      this.processing = true;
      this.processing = false;
    },
    getBytes(b) {
      if (b > 0) {
        var i = Math.floor(Math.log(b) / Math.log(1000)),
          sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        return (b / Math.pow(1000, i)).toFixed(2) * 1 + " " + sizes[i];
      } else {
        return 0 + " MB";
      }
    },
    countryFlagIcon(streamq) {
      if (streamq.type) {
        let identifier = _.get(
          streamq,
          ["uiRegion", "identifier"],
          streamq.region.identifier
        );
        // hack for studio@jackshoot.com
        if (
          streamq.user === "5da088ba2564bd064957e474" &&
          streamq.uiRegion &&
          streamq.uiRegion._id === "62e2a0e108cb9dc2bcfb17d8"
        ) {
          identifier = "kr";
        }

        return `https://assets.castr.io/countryflags/${identifier}/flat/64.png`;
      }
    },
    async getvodFiles(id, livetovod = false) {
      let list = [];
      try {
        list = await StreamService.getStreamPlaylist(id);
      } catch (e) {
        this.$notify({
          group: "error",
          text: "could not retreive playlist videos",
        });
      }
      if (livetovod) {
        this.livetovod = true;
      } else {
        this.livetovod = false;
      }
      this.videoFiles = list;
      if (list.length > 0) {
        this.showVideofiles = true;
      }
      // this.playlistProcessing = false;
    },
    getCategoryIcon(categoryName) {
      return require(`@/assets/icons/stream-type-${categoryName}-ico.svg`); // eslint-disable-line no-undef
    },
    getStreamnumber(type) {
      if (this.userSubscription) {
        let streamCountarray = [];
        if (
          this.userSubscription.subscription &&
          this.userSubscription.subscription.enabled &&
          this.userSubscription.subscription.package.category == type
        ) {
          let streamCountoverride = _.get(this.userSubscription.subscription, [
            "definitionOverride",
            "streams",
          ]);
          if (streamCountoverride) {
            streamCountarray.push(streamCountoverride);
          } else {
            streamCountarray.push(
              _.get(this.userSubscription.subscription, [
                "package",
                "definition",
                "streams",
              ])
            );
          }
        }
        const typeFilteredaddonsub = _.map(
          this.userSubscription && this.userSubscription.addonSubscriptions,
          function (single) {
            if (single.enabled && single.category == type) {
              let streamCountoverride = _.get(single, [
                "definitionOverride",
                "streams",
              ]);
              if (streamCountoverride) {
                streamCountarray.push(streamCountoverride);
              } else {
                streamCountarray.push(
                  _.get(single, ["package", "definition", "streams"])
                );
              }
            }
          }
        );
        if (streamCountarray.length)
          return (
            "/" +
            (streamCountarray.length > 1
              ? Math.max.apply(Math, streamCountarray)
              : streamCountarray[0])
          );
      }
    },
    getDuration(d) {
      if (d.mediaInfo.duration) {
        // const totalMinutes = Math.floor(d.mediaInfo.duration / 60);
        // if(totalMinutes < 60){
        // 	return (d.mediaInfo.duration/60).toFixed(2) + ' mins';
        // }else{
        // 	const hr = Math.floor(totalMinutes/60);
        //     const min = (totalMinutes % 60);
        // 	return hr + ' h ' + ((min > 0) ? (min +' mins') : '');
        // }

        if (d.mediaInfo.duration < 60) {
          return d.mediaInfo.duration + " sec";
        } else if (d.mediaInfo.duration > 60 && d.mediaInfo.duration < 3600) {
          return (
            Math.floor(d.mediaInfo.duration / 60) +
            " mins " +
            (d.mediaInfo.duration % 60) +
            " sec"
          );
        } else {
          return (
            Math.floor(d.mediaInfo.duration / 3600) +
            " hr " +
            Math.floor((d.mediaInfo.duration % 3600) / 60) +
            " mins"
          );
        }
      }
    },
    getDate(d) {
      return moment(d).format("HH:mm DD-MM-YYYY");
    },
    dismiss() {
      this.showVideofiles = false;
      this.$refs.modalSelectStreamModal.hide();
      setTimeout(() => {
        this.processing = true;
      }, 1000);
    },
    addStream() {
      this.$parent.addStream(this.selectedStream, this.selectedVod, this.selectedVodFiles);
      this.dismiss();
    },
    clearStreamSelection() {
      this.selectedStream = [];
      this.selectedVod = [];
      this.$parent.addStream(this.selectedStream, this.selectedVod);
    },
    onSearchLiveStreams(searchKey) {
      console.log(searchKey);
    },
  },
};
</script>

<style>
#modal-analytics-select-stream .modal-sm {
  max-width: 600px;
  padding: 0px 10px;
}

#modal-analytics-select-stream .modal-body .col {
  max-height: 80vh;
  overflow-y: auto;
}

#modal-analytics-select-stream .modal-sm .grid-item {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
}

#modal-analytics-select-stream .platform_details_wrapper {
  display: none;
}

#modal-analytics-select-stream___BV_modal_body_ ul {
  border-bottom: 1px solid var(--c-dark-3);
}

#modal-analytics-select-stream___BV_modal_body_ ul li a {
  background: transparent;
  border: 0px;
  color: var(--c-dark-8);
  font-weight: 400;
  font-size: 15px;
}

div#modal-analytics-select-stream___BV_modal_body_ ul li a.active {
  font-weight: 500;
  color: #fff;
  border-bottom: 2px solid #fff;
}
</style>

<style scroped>
.seach-icon {
  transform: translateX(0);
  background: transparent;
  border: none;
  color: rgba(173, 184, 220, 0.5);
}

.no-data-err {
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.no-data-err h3 {
  color: #fff;
  font-size: var(--font-s-s);
  font-weight: 500;
}

.no-data-err .err-description {
  color: rgba(135, 148, 181, 1);
  font-size: 13px;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .tab-vod .inner_folder_wrap>p:last-child {
    display: none !important;
  }

  .tab-vod .inner_folder_wrap>p {
    width: 50%;
  }

  .tab-livestream .inner_folder_wrap>div {
    width: 100%;
  }

  .files_parent_al {
    height: unset;
    padding: 10px 0px;
  }

  .files_parent_al .al_badge+span {
    padding-left: 38px;
  }

  #modal-analytics-select-stream .loading-showcase {
    padding: 0;
  }

  #modal-analytics-select-stream .modal-body {
    padding-left: 15px;
    padding-right: 15px;
  }

  #modal-analytics-select-stream .modal-body .col {
    overflow-x: hidden;
  }

  .tab-vod .files_parent_al>img:first-child {
    margin-right: 5px !important;
  }

  #modal-analytics-select-stream .sw_modal_action {
    padding: 0;
  }

  #modal-analytics-select-stream .sw_modal_action .btn-outline-danger {
    margin-right: 0 !important;
  }

  #modal-analytics-select-stream .sw_modal_action button {
    margin-bottom: 10px;
    width: 48%;
  }
}
</style>
