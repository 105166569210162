<template>
  <div>
    <div v-if="!streamAlive && !isLoading" class="text-center">
      <h3 class="color-light text-s-xl">Stream is offline</h3>
      <span class="text-dark-6 text-s-m">To see the analytics of previous streams, go to
        <a class="text-dark-6 text-underline cursor-pointer" :to="{ name: 'StreamsManageAnalyticsStreamSessions' }">Stream Sessions
          History</a>.</span>
    </div>

    <Spinner v-if="isLoading" text="Retrieving data..." classes="text-dark-8 mt-5" spinner-color="var(--c-dark-8)"
      spinner-size="15px" />

    <div v-else-if="streamAlive && liveSession" class="w-3/4 py-2 mb-3">
      <div class="flex w-full border-bottom">
        <div class="flex-1 p-3">
          Stream Started On
        </div>
        <div class="flex-1 p-3">
          {{ formatTime(liveSession.started_at) }}
        </div>
      </div>
      <div class="flex w-full border-bottom">
        <div class="flex-1 p-3">
          Status
        </div>
        <div class="flex-1 p-3" v-if="liveSession.live">
          <span class="pill-primary">{{ liveStream.broadcasting_status }}</span>
        </div>
        <div class="flex-1 p-3" v-else>
          Finished
        </div>
      </div>
    </div>

    <!-- actual charts -->
    <div id="chartContainer">
      <section class="w-100" v-if="!isLoading && streamAlive && liveSession">
        <Card class="metric-chart">
          <div class="chart-wrapper">
            <div class="d-flex justify-content-between">
              <p class="mb-1"><span class="text-s-m text-dark-7">Delay</span> <span class="text-white text-s-xxl">{{
                currDelay }} ms</span></p>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                v-b-tooltip.hover :title="chartControlMsg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75ZM0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8ZM9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5ZM8.75 8.5C8.75 8.08579 8.41421 7.75 8 7.75C7.58579 7.75 7.25 8.08579 7.25 8.5V11.5C7.25 11.9142 7.58579 12.25 8 12.25C8.41421 12.25 8.75 11.9142 8.75 11.5V8.5Z"
                  fill="#68759C" />
              </svg>
            </div>
            <p class="text-s-s text-dark-6 mb-2">The ideal delay is under 1000 ms. Any unusual hike means our server
              doesn't receive input from your end on time. <a target="_blank"
                href="https://docs.castr.com/en/articles/6553493-understanding-health-charts-of-your-stream"
                class="text-dark-9 text-underline">Learn more</a></p>
            <input-health-chart ref="delayChart" :chart-data="delayData" />
          </div>
        </Card>
        <Card class="metric-chart">
          <div class="chart-wrapper">
            <div class="d-flex justify-content-between">
              <p class="mb-1"><span class="text-s-m text-dark-7">Video Bitrate</span> <span
                  class="text-white text-s-xxl">{{ currVidBitrate }} Kbps</span></p>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                v-b-tooltip.hover :title="chartControlMsg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75ZM0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8ZM9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5ZM8.75 8.5C8.75 8.08579 8.41421 7.75 8 7.75C7.58579 7.75 7.25 8.08579 7.25 8.5V11.5C7.25 11.9142 7.58579 12.25 8 12.25C8.41421 12.25 8.75 11.9142 8.75 11.5V8.5Z"
                  fill="#68759C" />
              </svg>
            </div>
            <p class="text-s-s text-dark-6 mb-2">The stream bitrate should stay constant. A sudden drop or hike in bitrate
              can cause issues on the playback side. <a target="_blank"
                href="https://docs.castr.com/en/articles/6553493-understanding-health-charts-of-your-stream"
                class="text-dark-9 text-underline">Learn more</a></p>
            <input-health-chart ref="vidBitrateChart" :chart-data="videoBitRateData" />
          </div>
        </Card>
        <Card class="metric-chart">
          <div class="chart-wrapper">
            <div class="d-flex justify-content-between">
              <p class="mb-1"><span class="text-s-m text-dark-7">Audio Bitrate</span> <span
                  class="text-white text-s-xxl">{{ currAudioBitrate }} Kbps</span></p>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                v-b-tooltip.hover :title="chartControlMsg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75ZM0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8ZM9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5ZM8.75 8.5C8.75 8.08579 8.41421 7.75 8 7.75C7.58579 7.75 7.25 8.08579 7.25 8.5V11.5C7.25 11.9142 7.58579 12.25 8 12.25C8.41421 12.25 8.75 11.9142 8.75 11.5V8.5Z"
                  fill="#68759C" />
              </svg>
            </div>
            <p class="text-s-s text-dark-6 mb-2">The stream bitrate should stay constant. A sudden drop or hike in bitrate
              can cause issues on the playback side. <a target="_blank"
                href="https://docs.castr.com/en/articles/6553493-understanding-health-charts-of-your-stream"
                class="text-dark-9 text-underline">Learn more</a></p>
            <input-health-chart ref="audioBitrateChart" :chart-data="audioBitRateData" />
          </div>
        </Card>
        <Card class="metric-chart">
          <div class="chart-wrapper">
            <div class="d-flex justify-content-between">
              <p class="mb-1"><span class="text-s-m text-dark-7">Frame Rate</span> <span class="text-white text-s-xxl">{{
                currFps }} fps</span></p>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                v-b-tooltip.hover :title="chartControlMsg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75ZM0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8ZM9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5ZM8.75 8.5C8.75 8.08579 8.41421 7.75 8 7.75C7.58579 7.75 7.25 8.08579 7.25 8.5V11.5C7.25 11.9142 7.58579 12.25 8 12.25C8.41421 12.25 8.75 11.9142 8.75 11.5V8.5Z"
                  fill="#68759C" />
              </svg>
            </div>
            <p class="text-s-s text-dark-6 mb-2">The stream FPS should stay constant. A sudden drop or hike in FPS can
              cause issues on the playback side. <a target="_blank"
                href="https://docs.castr.com/en/articles/6553493-understanding-health-charts-of-your-stream"
                class="text-dark-9 text-underline">Learn more</a></p>
            <input-health-chart ref="fpsChart" :chart-data="frameRateData" />
          </div>
        </Card>
      </section>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import InputHealthChart from "@/components/ui/InputHealthChart.vue";
import StreamStatsService from "@/services/StreamStatsService";
import { mapGetters } from 'vuex';
import Spinner from "@/components/ui/Spinner.vue";
import Card from '@/components/Atoms/Card.vue'

const MAX_HISTORICAL_DATA_LIMIT = 920;

export default {
  name: "StreamsManageAnalyticsLiveInputHealthCharts",
  components: { InputHealthChart, Spinner, Card },
  props: {
    stream: {
      type: Object,
      required: true,
      validator(v) {
        return Object.prototype.hasOwnProperty.call(v, "_id");
      },
    },
    streamAlive: {
      type: Boolean,
    },
  },
  data() {
    return {
      liveStream: null,
      isLoading: true,
      xScale: [],
      currDelay: 0,
      currVidBitrate: 0,
      currAudioBitrate: 0,
      currFps: 0,
      lastTime: 0,
      historicalData: [],
      historicalLowData: [],
      currentSessionId: null,
      liveSession: null,
    };
  },
  computed: {
    delayData() {
      let data = [];
      this.historicalData.forEach((d) => {
        data.push({ x: d.time_ms, y: d.ts_delay });
      });
      return {
        labels: this.xScale,
        datasets: [
          {
            fill: true,
            data: data,
            borderColor: "rgba(45, 104, 255, 1)",
            borderWidth: 2,
            pointRadius: 0.3,
            backgroundColor: "rgba(45, 104, 255, 0.03)",
          },
        ],
      };
    },
    videoBitRateData() {
      let data = [];
      this.historicalData.forEach((d) => {
        data.push({ x: d.time_ms, y: d.video_bitrate });
      });
      return {
        labels: this.xScale,
        datasets: [
          {
            fill: true,
            data,
            borderColor: "rgba(45, 104, 255, 1)",
            borderWidth: 2,
            pointRadius: 0.3,
            backgroundColor: "rgba(45, 104, 255, 0.03)",
          },
        ],
      };
    },
    audioBitRateData() {
      let data = [];
      this.historicalData.forEach((d) => {
        data.push({ x: d.time_ms, y: d.audio_bitrate });
      });
      return {
        labels: this.xScale,
        datasets: [
          {
            fill: true,
            data,
            borderColor: "rgba(45, 104, 255, 1)",
            borderWidth: 2,
            pointRadius: 0.3,
            backgroundColor: "rgba(45, 104, 255, 0.03)",
          },
        ],
      };
    },
    frameRateData() {
      let data = [];
      this.historicalData.forEach((d) => {
        data.push({ x: d.time_ms, y: d.fps });
      });
      return {
        labels: this.xScale,
        datasets: [
          {
            fill: true,
            data,
            borderColor: "rgba(45, 104, 255, 1)",
            borderWidth: 2,
            pointRadius: 0.3,
            backgroundColor: "rgba(45, 104, 255, 0.03)",
          },
        ],
      };
    },
    chartControlMsg() {
      return `Hold 'Ctrl' (windows) or 'Cmd' (mac) while scroll to zoom in/out.`
    },
  },
  async created() {
    await this.initializeCharts();
  },
  mounted() {
    document.getElementById('chartContainer').addEventListener('wheel', this.onMouseWheel, { capture: true, passive: false });
  },
  beforeDestroy() {
    if (this.statsSubId) clearInterval(this.statsSubId);
    document.getElementById('chartContainer')?.removeEventListener('wheel', this.onMouseWheel);
  },
  methods: {
    onMouseWheel(event) {
      if (!event.ctrlKey && !event.metaKey) {
        event.stopPropagation();
      }
    },
    async initializeCharts(showLoader = true) {
      if (showLoader) this.isLoading = true;
      this.liveStream = this.$props.stream;
      const now = Date.now();
      const aggInterval = 60 * 1000;
      this.lastTime = now - (now % aggInterval);
      if (this.stream.key) {
        // load historical data
        let sessionsList = await StreamStatsService.getSessionsList(
          this.stream.key
        );
        if(!_.isArray(sessionsList)) {
          sessionsList = [];
        }
        const liveSession = sessionsList.find((session) => session.live);
        this.liveSession = liveSession;

        if (liveSession) {
          this.liveStream.broadcasting_status = 'online';
          this.currentSessionId = liveSession.id;
          this.historicalData = await StreamStatsService.getSessionData(
            liveSession.id,
            MAX_HISTORICAL_DATA_LIMIT,
          );
          if (this.historicalData.length) {
            this.historicalData = this.historicalData.reverse();
            const lastDataPoint = this.historicalData[
              this.historicalData.length - 1
            ];
            this.lastTime = lastDataPoint.time_ms;
            this.currDelay = lastDataPoint.ts_delay || 0;
            this.currVidBitrate = lastDataPoint.video_bitrate || 0;
            this.currAudioBitrate = lastDataPoint.audio_bitrate || 0;
            this.currFps = lastDataPoint.fps || 0;

            const startTime = this.lastTime - 900000
            this.historicalData = this.historicalData.filter(d => d.time_ms >= startTime)
          }
        }
      }

      this.subscribeToStats();

      this.xScale = [];

      if (this.historicalData?.length < 10) {
        const currAggTime = now - (now % aggInterval);
        this.lastAggInterval = currAggTime;

        for (let index = 0; index < 10; index++) {
          this.xScale.push(new Date(currAggTime + index * aggInterval));
        }

        this.lastTime = this.historicalData?.[0]?.time
          ? new Date(this.historicalData[0].time).getTime() + 10 * aggInterval
          : currAggTime + 10 * aggInterval;
      }

      if (showLoader) this.isLoading = false;
    },
    subscribeToStats() {
      // this.fetchStatsData();
      if (this.statsSubId) clearInterval(this.statsSubId);
      this.statsSubId = setInterval(() => {
        this.fetchStatsData();
      }, 5000);
    },
    async fetchStatsData() {
      try {
        if (!this.currentSessionId) {
          const pulseObject = await StreamStatsService.getStreamMediaPulse(
            this.stream.key,
            true
          );

          if ("success" in pulseObject && !pulseObject.success) {
            this.liveStream.broadcasting_status = "offline";
            return;
          }

          if (this.liveStream.broadcasting_status === "offline") {
            // stream has started
            this.liveStream.broadcasting_status = "online";
            return this.initializeCharts(false);
          }
        }
        let newData = null;

        try {
          newData = await StreamStatsService.getSessionData(
            this.currentSessionId,
            10
          );
        } catch {
          this.liveStream.broadcasting_status = "offline";
          return;
        }

        this.liveStream.broadcasting_status = "online";
        newData = newData.reverse();
        this.addNewData(newData);
      } catch (e) {
        console.log(e);
      }
    },
    addNewData(newData) {
      if (!newData?.length) return;
      const len = Math.max(0, this.historicalData.length - 1);
      if (len === 0) return this.historicalData.push(...newData);

      const lastHistoricalDataPoint = this.historicalData[len];
      for (const point of newData) {
        const time = point.time_ms;
        if (time > lastHistoricalDataPoint.time_ms) {
          this.historicalData.push(point);
          if (this.historicalData.length > MAX_HISTORICAL_DATA_LIMIT) this.historicalData.shift()
        }
      }

      const newLastPoint = newData[newData?.length - 1];
      this.lastTime = newLastPoint.time_ms;
      this.currDelay = newLastPoint.ts_delay || 0;
      this.currVidBitrate = newLastPoint.video_bitrate || 0;
      this.currAudioBitrate = newLastPoint.audio_bitrate || 0;
      this.currFps = newLastPoint.fps || 0;
    },
    formatTime(time) {
      return moment.utc(time).local()
        .format("MMM DD, h:mm:ss A");
    },
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #232d4d5a;
  border-color: #232d4d5a !important;
}

.metric-chart {
  background: #1e2745;
  padding: 8px;
  margin-bottom: 8px;
}
</style>
