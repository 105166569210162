<template>
  <div class="">
    <b-modal
      :id="modalId"
      ref="modalConfirm"
      :size="modalSize"
      hide-header
      hide-footer
      centered
      :no-close-on-backdrop="closeoutsideClick"
    >
      <!-- <VODUploadWidgetDrop /> -->
      <b-row>
        <b-col>{{ message }}{{ streamCat }}</b-col>
      </b-row>
      <b-row>
        <b-col class="text-s-s text-dark-8">{{ body }}</b-col>
      </b-row>
      <slot> </slot>
      <b-row>
        <b-col class="text-right mt-3">
          <b-button
            variant="outline-secondary"
            @click="dismiss"
            class="mr-2"
            size="md"
          >
            {{ cancelText }}
          </b-button>
          <b-dropdown
            no-caret
            variant="primary"
            size="md"
            toggle-class="d-flex align-items-center justify-content-between text-500 pl-2"
            menu-class="dropdown-menu_md dropdown-menu_sm-100"
            class=""
          >
            <template #button-content>
              <div
                class="d-flex justify-content-between align-items-center w-100"
              >
                <svg
                  class="mr-2"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 4V10M10 16V10M10 10H4M10 10H16"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="mr-auto">{{ okText }}</span>
                <svg
                  class="ml-2 dropdown-caret flex-shrink-0"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.26863 5.73137L7.13137 7.86863C6.73535 8.26465 6.53735 8.46265 6.30902 8.53684C6.10817 8.6021 5.89183 8.6021 5.69098 8.53684C5.46265 8.46265 5.26465 8.26465 4.86863 7.86863L2.73137 5.73137C1.87462 4.87462 1.44624 4.44624 1.4173 4.07846C1.39218 3.75934 1.52136 3.44749 1.76477 3.23959C2.04529 3 2.65111 3 3.86274 3L8.13726 3C9.34889 3 9.95471 3 10.2352 3.23959C10.4786 3.44749 10.6078 3.75934 10.5827 4.07846C10.5538 4.44624 10.1254 4.87462 9.26863 5.73137Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </template>
            <b-dropdown-item
              @click="$emit('modal-confirm', 'raw')"
              >From Your Computer</b-dropdown-item
            >
            <b-dropdown-item v-b-modal.cloud-import-replace2 @click="$emit('modal-confirm', 'cloud')"
              >Cloud import
              <b-badge variant="dark-5" class="badge_beta ml-2"
                >beta</b-badge
              ></b-dropdown-item
            >
          </b-dropdown>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import VODUploadWidgetDrop from '../ui/VODUploadWidgetDrop.vue';

export default {
  name: "ReplaceVodModal",
  components: {
    VODUploadWidgetDrop,
  },
  props: {
    modalId: {
      type: String,
      default: "modal-confirm",
    },
    modalSize: {
      type: String,
      default: "sm",
      validator(v) {
        return ["xl", "lg", "md", "sm"].some((size) => size === v);
      },
    },
    modalType: {
      type: String,
      default: "primary",
    },
    message: {
      type: String,
      required: false,
    },
    body: {
      type: String,
      required: false,
    },
    streamCat: {
      type: String,
    },
    okText: {
      type: String,
      default: "Yes",
    },
    cancelText: {
      type: String,
      default: "No",
    },
    showConfirm: {
      type: Boolean,
      default: true,
      required: false,
    },
    closeoutsideClick: {
      type: Boolean,
      default: false,
    },
    confirmCancelEvent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    onConfirm() {
      this.$emit("modal-confirm");
      this.dismiss(this.confirmCancelEvent);
    },
    dismiss(cancel = true) {
      if (cancel) {
        this.$emit("modal-cancel");
      }
      this.$refs.modalConfirm.hide();
      this.$emit("modal-dismiss");
      // lazy clear
      setTimeout(() => {
        this.processing = false;
      }, 1000);
    },
  },
};
</script>

<style scoped></style>
