<script setup>
import IconBase from '../icon/IconBase.vue';
import IconPlay from '../icon/IconPlay.vue';
import IconPlus from '../icon/IconPlus.vue';
import IconBookOpen from '../icon/IconBookOpen.vue';
import FormButton from '../Atoms/FormButton.vue'

const emit = defineEmits({
  'createPlayout': (value) => {return value}
})
const showCreatePlayoutModal = () => emit('createPlayout')
const showDemo = () => emit('showDemo')
</script>

<template>
  <main>
    <header class="flex items-center mb-3 mt-5">
      <h1 class="text-2xl">
        TV Playout
      </h1>
      <FormButton type="primary" @click="showCreatePlayoutModal" class="ml-auto">
        <icon-base class="mr-1">
          <icon-plus />
        </icon-base>
        Create TV Playout
      </FormButton>
    </header>
    <section class="text-center mt-48">
      <h2 class="text-2xl mb-1">There is no TV Playout here!</h2>
      <p class="text-sm text-surface-7 mb-3">Fortunately, it’s very easy to create one</p>
      <p class="flex flex-col w-full items-center">
        <FormButton type="secondary" class="mb-3 w-64 justify-center" href="https://docs.castr.com/en/articles/8001118-create-your-tv-playout">
          <icon-base class="mr-2">
            <icon-book-open />
          </icon-base>
          How TV Playout works
        </FormButton>
        <FormButton type="secondary" class="mb-3 w-64 justify-center" @click="showDemo">
          <icon-base class="mr-2">
            <icon-play />
          </icon-base>
          Watch a tutorial
        </FormButton>
        <FormButton class=" w-64 justify-center" @click="showCreatePlayoutModal">
          <icon-base class="mr-2">
            <icon-plus />
          </icon-base>
          Create TV Playout
        </FormButton>
      </p>
    </section>
  </main>
</template>
