<script setup>
import PlayoutSidebar from '../../components/TVPlayout/PlayoutSidebar.vue'
import PlayoutHeader from '../../components/TVPlayout/PlayoutHeader.vue'
import PlayoutRetainContents from '../../components/TVPlayout/PlayoutRetainContents.vue'
import PlayoutTable from '../../components/TVPlayout/PlayoutTable.vue';
import StreamStatsService from '../../services/StreamStatsService';
import StreamsWidget from '../../components/ui/StreamsWidget.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import PlayoutSetSchedule from '../../components/TVPlayout/PlayoutSetSchedule.vue';
import FormButton from '../../components/Atoms/FormButton.vue';

import { ref, computed, onUnmounted, onMounted, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const instance = getCurrentInstance();
const root = instance.proxy.$root;

const scopeAlive = ref(true)
const streamAlive = ref(null)
const mediaPulse = ref(null)
const streamFps = ref(null)
const mediaPulseTimeoutCtrl = ref(null)

const playout = computed(() => store.getters['Streams/tvPlayout'])
const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])
const playoutDuration = computed(() => store.getters['Playout/playoutDuration'])
const playoutMaxDurationLimit = computed(() => store.getters['User/playoutMaxDurationLimit'])
const tvPlayoutLineUpDuration = computed(() => store.getters['User/tvPlayoutLineUpDuration'])

const subscribeMediaPulse = async () => {
	(async function loop() {
		if (!scopeAlive.value) return;

		let timeoutInterval = 5000;
		let shouldUpdatePulse = playout.value?.enabled;
		if (document.hidden) {
			shouldUpdatePulse = false;
			timeoutInterval = 6000;
		}

		if (shouldUpdatePulse) {
			if (!document.hasFocus()) {
				timeoutInterval = 6000;
			}
		}
		if (shouldUpdatePulse) {
			try {
				const streamKey = playout.value?.key;
				const pulseObject = await StreamStatsService.getStreamMediaPulse(
					streamKey
				);
				onMediaPulse(pulseObject);
			} catch (e) { 
				console.log(e);
			}
		}
		// reschedule
		mediaPulseTimeoutCtrl.value = setTimeout(loop, timeoutInterval);
	})();
}

const onMediaPulse = (pulseObject = {}) => {
	if (mediaPulse.value?.withoutPushStats) {
		const oldPushStats = mediaPulse.value.push_stats;
		pulseObject.push_stats = oldPushStats;
	}
	mediaPulse.value = pulseObject;
	streamAlive.value = playout.value.enabled && mediaPulse.value?.alive;
	streamFps.value = mediaPulse.value?.fps;
}

const unsubscribeMediaPulse = () => {
	clearTimeout(mediaPulseTimeoutCtrl.value);
}
onMounted( async () => {
	subscribeMediaPulse()
	await store.dispatch('Streams/getStreams')
	await store.dispatch('Playout/getPlayoutConfig', route?.params?.tvId)
})
onUnmounted(() => {
	unsubscribeMediaPulse()
	scopeAlive.value = false
	store.dispatch('Playout/clear')
})
const addToPlayout = (stream) =>  {
	const durationLimit = playoutMaxDurationLimit.value * 1000 * 60 * 60
	const streamDuration = stream ? stream.mediaInfo?.duration * 1000 : 600000
	playoutConfig.value?.mode === 'schedule' 
		? store.dispatch('Playout/addItem', stream)
		: playoutDuration.value + streamDuration >= durationLimit
			? root.$emit("bv::show::modal", 'modal-overload-duration')
			: store.dispatch('Playout/addItem', stream)
}
</script>

<template>
	<div class="wrapper grid md:grid-cols-[292px_1fr] grid-cols-1 w-full min-h-screen !mt-12 md:!mt-0">
		<PlayoutSidebar :mediaPulse="mediaPulse" />
		<div class="grid grid-cols-1 grid-rows-[auto_auto] md:grid-cols-[minmax(0,_1fr)_280px] md:grid-rows-[100px_1fr] gap-x-4 !px-4 md:!px-0 container justify-self-start ml-0">
			<PlayoutHeader class="!hidden md:!flex md:col-span-2 md:order-1" />
			<main class="md:max-w-[calc(100%-10px)] md:pl-4 order-2">
				<PlayoutRetainContents />
				<PlayoutTable />
			</main>
			<aside class="!mt-4 md:!mt-0 md:pr-4 flex md:block justify-end order-1 md:order-3 items-center">
				<h3 class="mr-auto font-medium text-lg md:hidden">Schedule</h3>
				<PlayoutSetSchedule class="md:!mt-4 !mr-2 md:mr-0" />
				<div id="#streams-widget-wrapper">
					<StreamsWidget placement="body" @add-stream="addToPlayout" />
				</div>
			</aside>
		</div>
		<alert-modal modalId="modal-overload-duration" message="Unable to add Video to Timetable"
			buttonAlignment="text-right" okText="Ok, got it" body-class="text-pretty">
			Uploaded video is exceeding the duration limit of {{ tvPlayoutLineUpDuration }} hours. To increase the limit, contact <FormButton type="link" onclick="Intercom('show')" customClasses="inline underline text-white">sales team</FormButton>.
		</alert-modal>
	</div>
</template>

<style scoped></style>
