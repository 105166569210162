<script setup>
import { ref, computed } from 'vue'
import FormButton from './FormButton.vue';

const props = defineProps({
  label: {
    type: String,
  },
  size: {
    type: String,
    default: 'md',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  customClasses: {
    type: String,
  },
  type: {
    type: String,
    default: 'button'
  },
  to: {
    type: String,
  },
  onClick: Function
})

const tabClasses = computed(() => {
  const buttonClasses = `h-8 p-2 ${props.disabled ? '' : 'hover:bg-surface-3'} hover:no-underline rounded text-surface-9 hover:text-white text-nowrap leading-none text-tiny flex items-center w-full ${props.isActive ? 'bg-surface-3' : 'bg-transparent'}`
  const lineClasses = `h-8 py-2 border-b-2 ${props.disabled ? 'text-surface-7' : 'hover:border-white hover:text-white text-surface-9'} hover:no-underline text-nowrap leading-none text-sm flex items-center w-full capitalize -mb-px ${props.isActive ? 'text-white border-white' : 'border-transparent'}`
  return props.type === 'line'
    ? lineClasses
    : buttonClasses
}
)
</script>

<template>
  <li class="">
    <FormButton :customClasses="customClasses ? customClasses : tabClasses" :type="type" :size="size" :to="to" :label="label" @click="onClick" >
      <slot />
    </FormButton>
  </li>
</template>
<style>
</style>
