<template>
	<div class="mt-200 text-center d-flex flex-column col-lg-3 mx-auto align-items-center">
		<icon-base width-view-box="48" height-view-box="48" width="48" height="48" iconColor="none" class="mb-3">
			<icon-destination-empty-placeholder-image />
		</icon-base>
		<div>
			<h3 class="text-s-m text-dark-9">Destinations have not been added yet</h3>
			<p class="mb-4 text-s-s text-dark-7">Destinations added in this section can be swiftly integrated into streams</p>
			<b-button variant="outline-secondary" target="_blank" href="https://docs.castr.com/en/articles/8799194-reusable-destinations" class="w-100 mb-3" size="lg">
				<icon-base width-view-box="15" height-view-box="16" iconColor="none" width="15" height="16" class="text-dark-8" classIcon="mr-2">
					<icon-book />
				</icon-base>
				How Destination works
			</b-button>
			<b-button variant="outline-secondary" v-b-modal.modal_tutorial class="w-100 mb-3" size="lg">
				<icon-base width-view-box="16" height-view-box="16" iconColor="none" width="16" height="16" class="text-dark-8" classIcon="mr-2">
					<icon-play />
				</icon-base>
				Destination demo
			</b-button>
			<b-button v-if="hasDestinations" v-b-toggle.add-destination-modal class="w-100" variant="primary" size="lg">
				<icon-base width-view-box="14" height-view-box="14" iconColor="none" width="14" height="14" classIcon="mr-2">
					<icon-plus />
				</icon-base>
				Create new
			</b-button>
			<b-button v-else to="/subscribe" class="w-100" variant="primary" size="lg">
				Upgrade
			</b-button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import IconBase from '@/components/icon/IconBase.vue'
import IconPlus from '@/components/icon/IconPlus.vue'
import IconPlay from '@/components/icon/IconPlay.vue'
import IconBook from '@/components/icon/IconBook.vue'
import IconDestinationEmptyPlaceholderImage from '@/components/icon/IconDestinationEmptyPlaceholderImage.vue'

export default {
	name: 'DestinationEmptyPlaceholder',
	components: {
		IconBase,
		IconPlus,
		IconPlay,
		IconBook,
		IconDestinationEmptyPlaceholderImage
	},
	data() {
		return {
			
		};
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			hasDestinations: "User/hasDestinations"
		}),
	},
	watch: {
	},
	async mounted() {
	},
	destroyed() {

	},
	methods: {
		
	},
}

</script>

<style scoped>
</style>