import _ from "lodash";
import moment from "moment";
import { ref, onUnmounted } from "vue";

export default {
  promisify,
  toArray,
  swapArray,
  updateArrayItem,
  matchUrlProto,
  isValidUrlProto,
  validateSrtUrl,
  validateURL,
  resolveURL,
  resolveStreamKey,
  removeArrayItem,
  binarySearch,
  parseQueryString,
  inspectMediaFile,
  sortStreams,
  // filters
  ago,
  bytes,
};

function ago(value) {
  if (value) {
    return moment(new Date(String(value))).fromNow();
  }
  return value;
}

function bytes(num, breakSegments, decimalPlaces = 3, mini = false) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== "number" || isNaN(num)) {
    throw new TypeError("Expected a number");
  }

  const neg = num < 0;
  const units = [
    mini ? "B" : "Bytes",
    "kB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB",
  ];

  if (neg) num = -num;
  if (num < 1) {
    const value = (neg ? "-" : "") + num;
    return breakSegments ? { value, unit: units[0] } : value + " Bytes";
  }

  const exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1
  );
  const unit = units[exponent].toLowerCase();

  num = (num / Math.pow(1000, exponent)).toFixed(decimalPlaces) * 1;

  const value = (neg ? "-" : "") + num;
  return breakSegments ? { value: Number(value), unit } : value + " " + unit;
}

function promisify(func) {
  return new Promise((resolve) => {
    func.call(0, resolve);
  });
}

function matchUrlProto(url, protoToMatch) {
  return url && new RegExp(`^${protoToMatch}://`).test(url);
}

function isValidUrlProto(url) {
  return /^(http|https|ftp|ftps|hls|rtsp|rtmp|mpegts):\/\//gi.test(url);
}

function validateSrtUrl(url) {
  const regexp = new RegExp(
    "^(?!.*@.*@)(http|https|tshttp|tshttps|mpegts|hls|hlss|rtsp|rtsp2|m4s|m4f|file|fake|rtmp|srt|timeshift|mixer|shoutcast|shoutcasts)://",
    "i"
  );

  const regexp2 = new RegExp(
    "(?:\uD81A[\uDF40-\uDF43]|\uD81B[\uDF93-\uDF9F\uDFE0]|[\u00B0\u00B2\u00B3\u00B9\u02AF\u0670\u0711\u2121\u213B\u2207\u29B5\uFC5B-\uFC5D\uFC63\uFC90\uFCD9\u2070\u2071\u2074-\u208E\u2090-\u209C\u0345\u0656\u17D2\u1D62-\u1D6A\u2A27\u2C7C\u02B0-\u02C1\u02C6-\u02D1\u02E0-\u02E4\u02EC\u02EE\u0374\u037A\u0559\u0640\u06E5\u06E6\u07F4\u07F5\u07FA\u081A\u0824\u0828\u0971\u0E46\u0EC6\u10FC\u17D7\u1843\u1AA7\u1C78-\u1C7D\u1D2C-\u1D6A\u1D78\u1D9B-\u1DBF\u2071\u207F\u2090-\u209C\u2C7C\u2C7D\u2D6F\u2E2F\u3005\u3031-\u3035\u303B\u309D\u309E\u30FC-\u30FE\uA015\uA4F8-\uA4FD\uA60C\uA67F\uA69C\uA69D\uA717-\uA71F\uA770\uA788\uA7F8\uA7F9\uA9CF\uA9E6\uAA70\uAADD\uAAF3\uAAF4\uAB5C-\uAB5F\uFF70\uFF9E\uFF9F\u00AA\u00BA\u2071\u207F])"
  );

  // Remove spaces from the URL
  const pullSource = url?.replace(/\s/g, "");

  // Check if the URL starts with "srt://"
  const isSrtUrl = pullSource?.toLowerCase().startsWith("srt://");

  // Check if the URL starts with "http://" or "https://"
  const isHttpUrl =
    pullSource?.toLowerCase().startsWith("http://") ||
    pullSource?.toLowerCase().startsWith("https://");

  // Add port number check for SRT URLs with optional data after the port
  const srtPortCheck = isSrtUrl
    ? /srt:\/\/[^:]+:\d{1,5}($|\?)/.test(pullSource)
    : true;

  // Add file extension check for HTTP/HTTPS URLs
  const httpFileCheck = isHttpUrl
    ? /\.(m3u8|ts)$/.test(pullSource)
    : true;

  // Original validation
  const isValid =
    regexp.test(pullSource) &&
    !regexp2.test(pullSource) &&
    !pullSource.includes("^") &&
    !pullSource.includes("youtube") &&
    srtPortCheck && // Check SRT port requirement
    httpFileCheck; // Check HTTP/HTTPS file extension requirement
    
  return isValid;
}

/**
 * @param {string} url
 * @param {object} options
 * @param {Array<string>} options.allowedProtos
 */
function validateURL(url, options) {
  options = _.assign({ validatePathname: true }, options);

  let urlChunks;
  try {
    urlChunks = extractUrlSegments(url);
  } catch (e) {}
  if (!urlChunks) return {};
  if (!urlChunks.protocol || !validateProto(urlChunks.protocol)) {
    return { reason: "protocol" };
  }

  if (!urlChunks.host || !validateHost(urlChunks.host)) {
    return { reason: "host" };
  }

  if (!urlChunks.pathname && options.validatePathname) {
    return { reason: "pathname" };
  }

  return { valid: true };

  function validateProto(protocol) {
    const baseProto = _.replace(protocol, "://", "");
    const DefaultAllowedProtos = ["rtmp", "rtmps", "rtsp"];
    const PROTOS = options.allowedProtos || DefaultAllowedProtos;

    return PROTOS.indexOf(baseProto) > -1;
  }

  function validateHost(host) {
    // check for dot `.` occurence
    let validated = !!_.size(host.split("."));
    if (validated) {
      // check for invalid chars
      const ValidChars = /\d|[a-z]|[A-Z]|-|_|\.|:/g;
      const validCharsCount = _.size(host.match(ValidChars));
      validated = validCharsCount === _.size(host);
    }

    if (validated) {
      // check for valid port spec
      const hasPort = host.indexOf(":") > -1;
      if (hasPort) {
        validated = /:\d+$/gi.test(host);
      }
    }

    return validated;
  }
}

/**
 * @param {string} url
 */
function resolveURL(url) {
  let updatedUrl = url;
  // replace backslashes to forward slash
  updatedUrl = _.replace(updatedUrl, /\\/i, "/");

  // trim down multiple slashes `/` to just one
  const { protocol } = extractUrlSegments(url);
  const noProtoURL = _.replace(url, protocol, "");

  _.split(noProtoURL, "/").forEach(() => {
    // noProtoURL = _.replace(noProtoURL, /\/{2,}/gi, '/')
  });

  updatedUrl = (protocol || "") + noProtoURL;
  return encodeURI(updatedUrl);
}

function resolveStreamKey(streamKey) {
  // const trimmedKey = streamKey.replace(/\s|\//g, '')
  const trimmedKey = streamKey.replace(/\s/g, "");
  return trimmedKey;
}

function extractUrlSegments(url) {
  let host, pathname, auth;

  const protocol = _.head(url.match(/^\w+:\/\//gi));
  if (protocol) {
    host = _.chain(url).replace(protocol, "").split("/").head().value();

    let _host = host;
    const authSegmentIndex = host.lastIndexOf("@");
    if (authSegmentIndex > -1) {
      const authSegment = host.substr(0, authSegmentIndex);
      if (authSegment) {
        const [user, pass] = _.split(authSegment, ":");
        auth = { user, pass };
      }

      _host = host.substr(authSegmentIndex + 1);
    }

    host = _host;
  }

  if (protocol && host) {
    pathname = _.chain(url)
      .replace(protocol, "")
      .replace(host)
      .split("/")
      .slice(1)
      .join("/")
      .value();
  }

  return { protocol, host, pathname, href: url, auth };
}

function updateArrayItem(array, newValue, atIndex) {
  return _.concat(
    array.slice(0, atIndex),
    [newValue],
    array.slice(atIndex + 1)
  );
}

function removeArrayItem(array, atIndex) {
  return _.concat(array.slice(0, atIndex), array.slice(atIndex + 1));
}

function swapArray(array, nodeIndex1, nodeIndex2) {
  const arr = array.slice();
  const tempNode1 = arr[nodeIndex1];
  arr[nodeIndex1] = arr[nodeIndex2];
  arr[nodeIndex2] = tempNode1;
  return arr;
}

function toArray(param) {
  if (!_.isArray(param)) param = [param];

  return param;
}

function binarySearch(ar, el, compareFunc) {
  let m = 0;
  let n = ar.length - 1;
  while (m <= n) {
    const k = (n + m) >> 1;
    const cmp = compareFunc(el, ar[k]);
    if (cmp > 0) {
      m = k + 1;
    } else if (cmp < 0) {
      n = k - 1;
    } else {
      return k;
    }
  }

  return -m - 1;
}

function parseQueryString(qstring) {
  const query = {};
  if (qstring) {
    qstring = qstring.replace("?", "");
    const params = qstring.split("&");
    for (let i = 0; i < params.length; i++) {
      const pars = params[i].split("=");
      query[pars[0]] = pars[1];
    }
  }

  return query;
}

let _parsingFile = false;
const _inspectMediaQueue = [];

function inspectMediaFile(miJS, file, callback) {
  if (_parsingFile) {
    _inspectMediaQueue.push([file, callback]);
    return;
  }

  _parsingFile = true;

  // var offset = 0;

  // Initialise MediaInfo
  const MI = new miJS.MediaInfo();

  // Open the file
  MI.Open(file, () => {
    const result = buildInspectResult();
    callback(result);

    MI.Close();
    MI.delete();
    _parsingFile = false;

    if (_inspectMediaQueue.length) {
      const [nextFile, nextCallback] = _inspectMediaQueue.pop();
      inspectMediaFile(miJS, nextFile, nextCallback);
    }
  });

  /*  By buffer example:
  miJS.Option('File_FileName', file.name);
  miJS.Open_Buffer_Init(file.size, 0);

  var loop = function(length) {
    if (_file_parsing) {
      var r = new FileReader();
      var blob = file.slice(offset, offset + length);
      r.onload = processChunk;
      r.readAsArrayBuffer(blob);
    } else {
      finish()
    }
  };

  var processChunk = function(e) {
    if (e.target.error === null) {
      // Send the buffer to MediaInfo
      var state = miJS.Open_Buffer_Continue(e.target.result);

      //Test if there is a MediaInfo request to go elsewhere
      var seekTo = miJS.Open_Buffer_Continue_Goto_Get();
      if(seekTo === -1) {
        offset += e.target.result.byteLength;
      } else {
        offset = seekTo;
        miJS.Open_Buffer_Init(file.size, seekTo); // Inform MediaInfo we have seek
      }
    } else {
      finish();
      alert('An error happened reading your file!');
      return;
    }

    // Bit 3 set means finalized
    if (state&0x08 || e.target.result.byteLength < 1) {
      miJS.Open_Buffer_Finalize();
      callback();
      return;
    }

    loop(CHUNK_SIZE);
  };

   // Start
  loop(CHUNK_SIZE); */

  function buildInspectResult() {
    const info = {
      hasAudioTrack: false,
    };

    info.isVbr =
      MI.Get(miJS.Stream.General, 0, "OverallBitRate_Mode") === "VBR";
    // extract duration
    info.duration = Math.ceil(
      parseInt(MI.Get(miJS.Stream.General, 0, "Duration")) / 1000
    );

    // extract resolution, frame rate
    info.width = parseInt(MI.Get(miJS.Stream.Video, 0, "Width"));
    info.height = parseInt(MI.Get(miJS.Stream.Video, 0, "Height"));
    info.fps = parseInt(MI.Get(miJS.Stream.General, 0, "FrameRate"));

    // extract media streams/codecs
    info.codecs = [];
    if (parseInt(MI.Count_Get(miJS.Stream.Video))) {
      const c = {
        type: "video",
        codec: MI.Get(miJS.Stream.Video, 0, "Format"),
      };

      info.codecs.push(c);
    }

    if (parseInt(MI.Count_Get(miJS.Stream.Audio))) {
      info.hasAudioTrack = true;
      const c = {
        type: "audio",
        codec: MI.Get(miJS.Stream.Audio, 0, "Format"),
      };

      info.codecs.push(c);
    }

    return info;
  }
}

export function timeStringToObject(string) {
  const [hours, minutes, seconds] = string.split(":").map(Number);
  return { hours, minutes, seconds };
}

export function timeObjectToString(pbject) {
  const { hours, minutes, seconds } = pbject;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function useMatchMedia(query) {
  const match = window.matchMedia(query);
  const isMatching = ref(match.matches);
  match.addEventListener("change", (e) => (isMatching.value = e?.matches));
  return isMatching;
}

export function useInnerWidth() {
  const width = ref(window.innerWidth);
  const syncWidth = () => (width.value = window.innerWidth);
  window.addEventListener("resize", syncWidth);
  onUnmounted(() => window.removeEventListener("resize", syncWidth));
  return width;
}

export function sortStreams(streams, sortBy, isFile = false) {
  if (sortBy === "Sort by Oldest") {
    const streamsSorted = _.orderBy(streams, ["creationTime"], ["asc"]);
    return streamsSorted;
  } else if (sortBy === "Sort by Newest") {
    const streamsSorted = _.orderBy(streams, ["creationTime"], ["desc"]);
    return streamsSorted;
  } else if (sortBy === "A to Z") {
    const lowerCaseStreams = streams.map((item) => ({
      ...item,
      lowerCaseName: _.lowerCase(isFile ? item.fileName : item.name),
    }));
    const streamsSorted = _.orderBy(
      lowerCaseStreams,
      ["lowerCaseName"],
      ["asc"]
    );
    return streamsSorted;
  } else if (sortBy === "Z to A") {
    const lowerCaseStreams = streams.map((item) => ({
      ...item,
      lowerCaseName: _.lowerCase(isFile ? item.fileName : item.name),
    }));
    const streamsSorted = _.orderBy(
      lowerCaseStreams,
      ["lowerCaseName"],
      ["desc"]
    );
    return streamsSorted;
  }
}

export function sortTagsUtil(tags, sortBy) {
  if (sortBy === "A to Z") {
    const lowerCaseStreams = tags.map((item) => ({
      ...item,
      lowerCaseName: _.lowerCase(item.title),
    }));
    const tagsSorted = _.orderBy(
      lowerCaseStreams,
      ["lowerCaseName"],
      ["asc"]
    );
    return tagsSorted;
  } else if (sortBy === "Z to A") {
    const lowerCaseStreams = tags.map((item) => ({
      ...item,
      lowerCaseName: _.lowerCase(item.title),
    }));
    const tagsSorted = _.orderBy(
      lowerCaseStreams,
      ["lowerCaseName"],
      ["desc"]
    );
    return tagsSorted;
  }
}