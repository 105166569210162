import _ from 'lodash'
import RequestError from './RequestError'
import api from '@/api'

export default {
	getStreamAd,
    toggleStreamAd,
    updateStreamAd,
    createStreamAd
}

function createStreamAd(streamId, payload) {
	return makeRequest({
		method: 'post',
		path: `/advertising/${streamId}/create`,
		data: payload
	})
}

function toggleStreamAd(streamId) {
	return makeRequest({
		method: 'post',
		path: `/advertising/${streamId}/toggle`,
		data: {}
	})
}

function updateStreamAd(streamId, payload) {
	return makeRequest({
		method: 'PUT',
		path: `/advertising/${streamId}/update`,
		data: payload
	})
}

function getStreamAd(streamId) {
	return makeRequest(`/advertising/${streamId}`)
}

async function makeRequest(reqConfig) {
	if (typeof reqConfig === 'string') {
		reqConfig = {
			path: reqConfig
		}
	}

	reqConfig.url = reqConfig.path

	let res
	try {
		res = await api.request(reqConfig)
	} catch (err) {
		let edata = _.get(err, 'response.data')
		throw new RequestError(edata)
	}

	return res.data
}