<script setup>
import { ref } from 'vue'
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconGrid from '../icon/IconGrid.vue';
import IconList
 from '../icon/IconList.vue';

 const currentView = ref('grid')

const emit = defineEmits({
  'toggle-view': (value) => {return value}
})

const toggle = (view) => {
  currentView.value = view
  emit('toggle-view', view)
}
const styling = 'hover:bg-surface-4 text-surface-8 hover:text-surface-9 h-8 w-8 flex items-center justify-center' 
</script>

<template>
  <ul class="flex">
    <li class="[&>*]:rounded-l-md">
      <FormButton isIcon @click="toggle('grid')" :customClasses="[styling, currentView === 'grid' ? 'bg-surface-3' : 'bg-surface-2']">
        <icon-base>
          <icon-grid />
        </icon-base>
      </FormButton>
    </li>
    <li class="[&>*]:rounded-r-md">
      <FormButton isIcon @click="toggle('list')" :customClasses="[styling, currentView === 'list' ? 'bg-surface-3' : 'bg-surface-2']">
        <icon-base>
          <icon-list />
        </icon-base>
      </FormButton>
    </li>
  </ul>
</template>

<style scoped>
</style>
