<script setup>
import { computed, watch, ref, onMounted, getCurrentInstance } from 'vue'
import PaywallService from "@/services/PaywallService";
import IconBanknote from "../../components/icon/IconBanknote.vue"
import IconChevronRight from "../../components/icon/IconChevronRight.vue"
import IconBin from "../../components/icon/IconBin.vue"
import FormSwitch from "../../components/Atoms/FormSwitch.vue"
import { useStore } from 'vuex'
import { useNotification } from "@kyvg/vue3-notification";
import FormButton from '../Atoms/FormButton.vue';
import FormInput from '../Atoms/FormInput.vue';
import IconBase from '../icon/IconBase.vue';
import IconPlus from '../icon/IconPlus.vue';
import EmailAccess from './EmailAccess.vue';
import IconElipsisVertical from '@/components/icon/IconElipsisVertical.vue'
import { useRoute, useRouter } from 'vue-router'
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';
import Title from '../ui/Title.vue'
import Alert from '../Atoms/Alert.vue';

const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()
const store = useStore()
const { notify } = useNotification()

const paywallEmailAccessRef = ref(null)

const props = defineProps({
  currentPaywall: {
    type: Function,
  }
})

const showEmaillAccessPopup = () => {
  root.$emit("bv::show::modal", "modal-paywall-add-email");
}
const updateEmailAccess = (data) => {
  paywallEmailAccessRef.value.updateEmailAccess({ id: data.id, email: data.email, plain_pass: data.plain_pass })
}
defineExpose({ updateEmailAccess })

onMounted(() => {
  
})

</script>

<template>
  <section class="">
    <p class="text-tiny text-surface-8 !mb-4">These emails will have direct access to the contents behind paywall and does not have to pay anything.</p>
      <FormButton item @click="showEmaillAccessPopup">
        <icon-base>
          <icon-plus />
        </icon-base>
        Add new email
      </FormButton>
      <email-access :paywallStreamId="props.currentPaywall.id" :key="props.currentPaywall.id" ref="paywallEmailAccessRef" />
  </section>
</template>

<style scoped></style>
