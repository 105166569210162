<template>
	<svg
		width="30"
		height="21"
		viewBox="0 0 30 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.1601 15.3984C17.3882 15.5075 17.6306 15.5626 17.8752 15.5626C18.0636 15.5626 18.252 15.5306 18.436 15.4645C18.8547 15.3136 19.1897 15.0073 19.377 14.6018L21.7591 9.44082C22.1349 8.62656 21.7823 7.64814 20.9746 7.25919L8.08652 1.05368C7.68215 0.859755 7.2304 0.835515 6.8106 0.987568C6.3908 1.13852 6.05585 1.44483 5.86854 1.8503L3.48638 7.01128C3.11065 7.82553 3.46324 8.80396 4.27088 9.1929L17.1601 15.3984ZM7.1827 2.02438C7.2433 2.00235 7.3061 1.99133 7.36891 1.99133C7.45154 1.99133 7.53308 2.01006 7.60801 2.04642L20.4961 8.25193C20.765 8.38195 20.8818 8.70809 20.7573 8.97914L18.3751 14.1401C18.3134 14.2756 18.2021 14.3781 18.0611 14.4277C17.9222 14.4784 17.7713 14.4707 17.6358 14.4057L4.74765 8.20015C4.4788 8.07013 4.36201 7.74399 4.48652 7.47294L6.86868 2.31196C6.93148 2.17644 7.04277 2.07507 7.1827 2.02438Z"
			fill="currentColor"
		/>
		<path
			d="M10.1432 15.9393C8.62382 15.9393 7.38867 14.703 7.38867 13.1847C7.38867 11.9826 8.16436 10.9314 9.31908 10.5667C9.61107 10.4764 9.91848 10.6361 10.0099 10.927C10.1014 11.2179 9.94051 11.5264 9.65073 11.6179C8.95658 11.836 8.4905 12.4652 8.4905 13.1847C8.4905 14.0959 9.23204 14.8374 10.1432 14.8374C11.0545 14.8374 11.796 14.0959 11.796 13.1847C11.796 12.9919 11.7574 12.7935 11.6814 12.5941C11.5723 12.3098 11.7145 11.9914 11.9987 11.8823C12.2863 11.7743 12.6014 11.9165 12.7105 12.2007C12.835 12.5258 12.8978 12.8563 12.8978 13.1847C12.8978 14.703 11.6627 15.9393 10.1432 15.9393Z"
			fill="currentColor"
		/>
		<path
			d="M10.1434 20.3466H1.32875C1.02464 20.3466 0.777832 20.0997 0.777832 19.7956C0.777832 19.4915 1.02464 19.2447 1.32875 19.2447H9.59248V15.3883C9.59248 15.0842 9.83929 14.8374 10.1434 14.8374C10.4475 14.8374 10.6943 15.0831 10.6943 15.3883V19.7956C10.6943 20.0997 10.4475 20.3466 10.1434 20.3466Z"
			fill="currentColor"
		/>
		<path
			d="M23.9165 17.5921C23.8316 17.5921 23.7479 17.5723 23.6697 17.5337L22.5678 16.9828C22.4378 16.9167 22.3376 16.8021 22.2913 16.6644L21.8054 15.2044L19.226 13.6564C18.9648 13.4999 18.88 13.1617 19.0365 12.9005C19.1929 12.6394 19.5345 12.5567 19.7923 12.7121L22.5469 14.3649C22.6604 14.4332 22.7452 14.5378 22.7871 14.6634L23.2664 16.1002L23.6531 16.2931L25.9516 11.2368L25.5285 11.0252L24.0917 11.5034C23.9672 11.5453 23.8349 11.5398 23.7126 11.4924L20.9581 10.3905C20.6749 10.2771 20.5383 9.95643 20.6518 9.67436C20.7652 9.39119 21.087 9.25456 21.3668 9.36695L23.9341 10.3939L25.3951 9.90684C25.5351 9.86057 25.686 9.87159 25.816 9.93659L26.9179 10.4875C27.1823 10.6197 27.2958 10.9382 27.1724 11.2081L24.4178 17.2682C24.3561 17.4048 24.2415 17.5095 24.1005 17.5591C24.0399 17.5811 23.9793 17.5921 23.9165 17.5921Z"
			fill="currentColor"
		/>
		<path
			d="M10.1437 13.7356C10.448 13.7356 10.6946 13.489 10.6946 13.1847C10.6946 12.8804 10.448 12.6338 10.1437 12.6338C9.83943 12.6338 9.59277 12.8804 9.59277 13.1847C9.59277 13.489 9.83943 13.7356 10.1437 13.7356Z"
			fill="#0062FF"
		/>
	</svg>
</template>
