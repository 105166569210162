<template>
	<div>
		<div class="content-container">
			<div class="tabs tabs-filled mt-0">		
				<div class="tab-content position-relative mt-4">
					<router-view
						:stream="stream"
						:stream-alive="streamAlive"
						@set-live-stream-countdown="liveStreamCountdownSelected"
						@live-stream-abr-changed="liveStreamAbrChanged"
					/>
				</div>	
			</div>
		</div>
			
	</div>
</template>

<script>

export default {
	name: 'SubSecondSettings',
	components: {
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		streamAlive: {
			type: Boolean
		},
	},
	data() {
		return {
			childViewList: [
				{
					streamType: 'live',
					name: '<span class="d-none d-md-inline">player</span> interface',
					viewName: 'StreamsManageSettingsPlayerInterface'
				},
				{
					streamType: 'live',
					name: '<span class="d-none d-md-inline">player</span>  functions',
					viewName: 'StreamsManageSettingsPlayerFunctions'
				},
				{
					streamType: 'live',
					name: 'security',
					viewName: 'LivestreamManageSecuritySettings'
				},
				{
					streamType: 'vod',
					name: 'player settings',
					viewName: 'VODBucketPlayerSettings'
				},
				{
					streamType: 'vod',
					name: 'security',
					viewName: 'VODBucketSecuritySettings'
				}
			]
		}
	},
	computed: {
		childViews() {
			return (this.stream && this.childViewList.filter(v => {
				if (v.name === 'paywall' && !this.paywallFeature) {
					return false;
				}
				const isIncluded = v.streamType === this.stream.type;
				return isIncluded && v.validate ? v.validate(this.stream) : isIncluded;
			}));
		},
	},
	methods: {
		liveStreamCountdownSelected(d) {
			this.$emit('set-live-stream-countdown', d);
		},
		liveStreamAbrChanged(d) {
			this.$emit('live-stream-abr-changed', d);
		},
	}
};
</script>

<style scoped>
:deep(.tabs-filled .nav-tabs li:nth-of-type(1) .nav-link:after) {
  content: "Player Settings";
}
:deep(.tabs-filled .nav-tabs li:nth-of-type(2) .nav-link:after) {
  content: "Security";
}
@media (max-width: 767px) {
	
}

</style>
