<template>
	<div class="container">
		<header class="mt-5 mb-4">
			<b-row>
				<b-col>
					<div class="d-flex align-items-center justify-content-between w-100 doc">
						<h1 class="page-title">Paywall 
							<!-- <b-badge variant="dark-3" class="badge_beta badge_top ml-2 mr-auto position-relative">beta</b-badge> -->
							</h1>
						<div class="d-flex flex-column align-items-start justify-content-start pw-doc">
							<a class="text-s-s text-dark-6 mb-1 flex items-center" target="_blank" href="https://docs.castr.com/en/articles/5252719-paywall-introduction#h_104ec45c57"><img src="@/assets/images/book.png" alt="Doc"> How Paywall works</a>	
							<b-button class="text-s-s text-dark-6 font-normal px-0 py-0" variant="link" v-b-modal.modal_tutorial_paywall><img class="mr-1" src="@/assets/images/play_icon.png"> Watch a tutorial</b-button>
						</div>
					</div>
				</b-col>
			</b-row>
		</header>
		<Spinner v-if="loadingStatus || !this.userloaded" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)" spinner-size="15px" />
		<div v-else class="paywall">
			<div v-if="!this.activeTeam || this.checkPaywallAccess()">
				<section v-if="this.stripeConnectStartedMessage">	
					<b-row>
						<b-col md="5" class="mx-auto">
							<b-img class="d-block mx-auto mb-3 paywall-img" height="80" width="80" :src="require('@/assets/icons/secure.svg')"></b-img>
							<h2 class="text-center text-s-xl text-500">Start earning money with Paywall <b-badge variant="dark-3" class="badge_beta badge_top position-relative ml-2 mr-auto">beta</b-badge></h2>
							<p class="text-center text-dark-6 text-s-l mb-4">Paywall protects your livestream so only paid users can access. 
							<a class="text-reset text-underline" target="_blank" href="https://docs.castr.com/en/collections/2958860-castr-paywall">Learn more</a></p>
						</b-col>
					</b-row>
					<b-row>
						<b-col class="mx-auto" md="5">
							<b-card class="p-4 mb-4" bg-variant="dark-2">
								<b-card-text>
									<ol class="mb-0 p-0">
										<li><span class="bordered">Set up Stripe to receive payment.</span></li>
										<li><span class="bordered">Choose the livestream file that you would like to set up Paywall for.</span></li>
										<li><span class="bordered">Your audience will be asked to pay when accessing your livestream.</span></li>
										<li class="mb-0">You receive your payment in Stripe!</li>
									</ol>
								</b-card-text>
							</b-card>
							<b-button class="mx-auto d-block" variant="primary" size="md" @click="activatePaywall">Get started</b-button>
						</b-col>
					</b-row>
				</section>
				<section v-if="!this.stripeConnectStartedMessage">
					<b-row>
						<b-col>
							<div class="tabs tabs-light">
								<b-nav class="" tabs>
									<b-nav-item link-classes="nav-tabs_light" to="/paywall/transactions"
										:disabled="!paywallActivated"
										:active="$route.path === '/paywall/transactions' || $route.hash === '#transactions'">
										Transactions
									</b-nav-item>
									<!-- <b-nav-item link-classes="nav-tabs_light" to="/paywall/purchasers"
										:disabled="!paywallActivated"
										:active="$route.path === '/paywall/purchasers' || $route.hash === '#purchasers'">
										All Purchasers
									</b-nav-item> -->
									<b-nav-item link-classes="nav-tabs_light" to="/paywall/streams"
										:disabled="!paywallActivated"
										:active="$route.path === '/paywall/streams' || $route.hash === '#streams'">
										All Streams With Paywall
									</b-nav-item>
									<b-nav-item link-classes="nav-tabs_light" to="/paywall/users"
										:disabled="!paywallActivated"
										:active="$route.path === '/paywall/users' || $route.hash === '#users'">
										Users
									</b-nav-item>
									<b-nav-item link-classes="nav-tabs_light" to="/paywall/payment"
										:disabled="!paywallActivated"
										:active="$route.path === '/paywall/payment' || $route.hash === '#payment'">
										Payment Method
									</b-nav-item>
									<b-nav-item link-classes="nav-tabs_light" to="/paywall/settings"
										:disabled="!paywallActivated"
										:active="$route.path === '/paywall/settings' || $route.hash === '#settings'">
										Settings
									</b-nav-item>
								</b-nav>
								<div class="tab-content">
									<router-view :paywallStreamId="paywallStreamIdFilter" :paywall="pwObject"></router-view>
								</div>
							</div>
						</b-col>
					</b-row>
				</section>
			</div>
			<div class="no-access mt-5" v-else>
			<div class="text-center pt-5 mt-5">
				<h4 class="text-dark-9 text-s-xl text-500 mb-1">You don’t have permission to access this.</h4>
				<p class="text-dark-6 text-s-m">Contact the team owner at <a  class="text-dark-6 text-s-m text-underline" :href="'mailto:'+userEmail">{{userEmail}}</a> to gain access.</p>
			</div>
			</div>
		</div>
		<b-modal modal-class="modal_tutorial" id="modal_tutorial_paywall" centered>
			<iframe src="https://www.youtube.com/embed/lKXw3OBGGMo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</b-modal>
	</div>
</template>

<script>
import Spinner from "@/components/ui/Spinner.vue";
import PaywallTransactions from '@/views/Paywall/PaywallTransactions.vue'
import PaywallService from '@/services/PaywallService';
import { mapGetters } from 'vuex';

export default {
	name: 'PaywallDashdoard',
	components: {
		Spinner,
		PaywallTransactions,
	},
	data() {
		return {
			paywallActivated: false,
			stripeConnectStartedMessage: true,
			processing: true,
			paywallStreamIdFilter: null,
			pwObject: null
		};
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus"
		}),
		activeTeam(){
			return this.$store.state.User.activatedTeam;
		},
		memberPermission(){
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
    	userEmail() {
			return this.$store.state.User.email;
		},
		userloaded(){
			return this.$store.state.User.userloaded;
		},
	},
	watch:{
		async userloaded(){
			const permission = await this.checkPaywallAccess();
			// this.processing = false;
			this.$store.dispatch('Ui/setLoadingStatus', false);
		}
	},
	async mounted() {
		this.paywallStreamIdFilter = parseInt(this.$route.query.stream || 0);
		await this.setupPaywall();
		this.$root.$on('teamchanging', data => {
			// this.processing = true;
			this.$store.dispatch('Ui/setLoadingStatus', true);
		});
		this.$root.$on('teamchanged', async data => {
			await this.setupPaywall();
			// this.processing = false;
			this.$store.dispatch('Ui/setLoadingStatus', false);
		});
	},
	methods: {
		checkPaywallAccess(){
			return this.activeTeam && this.memberPermission && this.memberPermission.paywall;
		},
		async setupPaywall() {
			// this.processing = true;
			this.$store.dispatch('Ui/setLoadingStatus', true);
			try {
				const pwObj = await PaywallService.getPaywall();
				if (pwObj.stripe_account_status === 'active') {
					this.paywallActivated = true;
					this.stripeConnectStartedMessage = false;
					this.pwObject = pwObj;
					this.$router.push('/paywall/transactions').catch(()=>{});
				}
			} catch(err) {
				if (err.toString().includes('paywall not activate')) {
					// this.processing = false;
					this.$store.dispatch('Ui/setLoadingStatus', false);
					return
				}

				console.error('err', err);
				this.$notify({
					group: 'error',
					text: err.message || 'get paywall info failed'
				});
				console.error('getPaywall failed', err);
			}
			if(this.userloaded) this.$store.dispatch('Ui/setLoadingStatus', false);
		},
		activatePaywall() {
			this.stripeConnectStartedMessage = false;
			this.$router.push('/paywall/payment').catch(()=>{});
		}
	},
}
</script>

<style scoped>

ol {
  list-style: none;
  counter-reset: steps;
}
ol li {
	display: flex;
  counter-increment: steps;
}
ol li::before {
	font-size: var(--font-s-m);
	color: var(--c-dark-8);
  content: counter(steps);
  margin-right: 1.5rem;
  background: var(--c-dark-3);
  width: 24px;
  height: 24px;
  border-radius: 50%;
	flex: 0 0 24px;
  display: inline-grid;
  place-items: center;
}
.bordered {
	border-bottom: 1px solid var(--c-dark-3);
	width: 100%;
	padding-bottom: .75rem;
	margin-bottom: .75rem;
}
:deep(* .btn-sort) {
	color: #fff;
	height: 36px;
}

:deep(* .table-hover.table-light tbody tr:hover)  {
	color: #fff;
	background-color: var(--color-dark-3);
}
:deep(* .table-light__header) {
	font-size: calc(var(--text-size) * 0.875);
	font-weight: 400;
  line-height: calc(var(--text-size) * 1.25);
	color: var(--color-dark4);
}
.badge_top {
	top: -4px;
}
.pw-doc img{
	max-width: 16px;
}
@media (max-width: 767px) {
	:deep(* .tabs-light) {
		margin: 0 -15px;
	}
}
</style>