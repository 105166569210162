<template>
  <div class="container page-title-area">
    <header class="mt-5">
      <div class="title d-flex align-items-center">
        <b-button
          variant="icon"
          @click="goBack"
          class="text-dark-7 mr-2 mr-md-4 p-1"
        >
          <svg width="6" height="12" class="mr-2" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 13L1 7L7 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </b-button>
        <h2 class="page-title m-0">Manage Tags</h2>
        <b-button size="md" class="ml-auto" variant="primary" v-b-modal="'1-modal'">
          <svg class="ml-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 4V10M10 16V10M10 10H4M10 10H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          New Tag
        </b-button>
      </div>
    </header>
    <section class="">
      <b-list-group class="tag-list list-group-flush mt-4">
        <b-list-group-item class="d-flex no-gutters align-items-center px-0 tag-list__header">
          <b-col class="text-dark-6 text-s-s" cols="2" lg="1">Color</b-col>
          <b-col class="text-dark-6 text-s-s" cols="8" lg="9">Name</b-col>
          <b-col class="text-dark-6 text-s-s" cols="2"></b-col>
        </b-list-group-item>
        <div v-if="this.generalTags.length === 0" class="text-center mt-5">
          <p class="text-s-xl text-dark-9 mb-1">No tags added yet</p>
          <p class="text-s-m text-dark-6">Add a new tag to get started</p>
        </div>
        <b-list-group-item v-for="(tag, index) in generalTags" :key="tag.id || tag.title" class="d-flex no-gutters align-items-center px-0 tag-list__item py-2">
          <b-col cols="2" lg="1">
            <b-dropdown 
              variant="link" 
              no-caret 
              toggle-class="rounded-pill p-0"
              menu-class="dropdown-menu_md mt-0"
            >
              <template #button-content>
                <span class="tag-color bordered" :class="tag.color"></span>
              </template>
              <b-dropdown-header class="tags-header">
                Choose a color
              </b-dropdown-header>
              <b-dropdown-form>
                <div class="d-flex flex-wrap">
                  <b-button variant="icon" class="tag-color tag-color_action mr-3 mb-3 p-0" @click="changeTagColor(color, tag)" v-for="color in colorsList" :key="color.name" :class="color.name"></b-button>
                </div>
              </b-dropdown-form>
            </b-dropdown>
          </b-col>
          <b-col cols="8" lg="9" class="d-flex">
            <span contenteditable @input="onInput(tag.title, index)" :ref="'tag' + index" class="text-dark-9 px-3 mx-n3 text-capitalize text-s-m" @keydown.enter.prevent="preventEnter">{{tag.title}}</span>
            <b-button variant="primary" class="ml-4 d-none d-md-inline-flex btn-tag-save" size="sm" @click="changeTagName(tag, index)">Save</b-button>
            <b-button variant="outline-secondary" class="ml-2 d-none d-md-inline-flex btn-tag-cancel" size="sm" @click="resetTagName(tag, index)">Cancel</b-button>
          </b-col>
          <b-col cols="2" class="text-right">
            <b-button ref="deleteTag" variant="icon" size="sm" class="p-1 btn-tag-delete d-none d-md-inline-flex" @click="showDeleteModal(tag, index)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="10" cy="4" rx="7" ry="2" stroke="currentColor" stroke-width="1.5"/>
                <path d="M17 4L15.5 16C15 17.5 12 18 10 18C8 18 5 17.5 4.5 16L3 4" stroke="currentColor" stroke-width="1.5"/>
              </svg>
            </b-button>
            <b-dropdown
              menu-class="dropdown-menu_md" 
              right no-caret 
              variant="icon"
              size="sm"
              toggle-class="pr-1 pl-1" 
              class="d-md-none">
              <template #button-content>
                <img src="@/assets/icons/settings.svg" alt="Settings">
              </template>
              <b-dropdown-item @click="changeTagName(tag, index)" class="">   
                <span>Save</span>
              </b-dropdown-item>
              <b-dropdown-item @click="resetTagName(tag, index)" class="">
                <span>Cancel</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showDeleteModal(tag, index)" variant="delete">  
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-modal centered size="sm" :id="'modal-delete-tag' + index" hide-footer hide-header>
            <h4 class="text-500 text-s-xl text-left">Are yousure you want to delete the "<span class="prevent-overflow">{{deleteName}}</span>" tag?</h4>
            <p class="text-s-l text-dark-8 mb-4">We’ll remove this tags from your files.</p>
            <div class="d-flex justify-content-end">
              <b-button
                size="md"
                variant="outline-secondary"
                class="mr-2"
                @click="$bvModal.hide('modal-delete-tag' + index)"
              >
                Cancel
              </b-button>
              <b-button
                size="md"
                variant="danger"
                class=""
                @click="deleteTag(tag)"
              >
                Delete
              </b-button>
            </div>
          </b-modal>
        </b-list-group-item>
      </b-list-group>
      <AddTagModal
        :video-id="1"
        :video-tags="generalTags" 
      />
    </section>
  </div>
</template>

<script>
import AddTagModal from '@/components/modals/AddTagModal.vue'; 

export default {
  name: "VODTagsManage",
  components: {
    AddTagModal
  },
  data() {
    return {
      colorsList: [
        {name: 'color-1'},
        {name: 'color-2'},
        {name: 'color-3'},
        {name: 'color-4'},
        {name: 'color-5'},
        {name: 'color-6'},
        {name: 'color-7'},
        {name: 'color-8'},
        {name: 'color-9'},
      ],
      deleteName: null,
    };
  },
  computed: {
    generalTags() {
      return this.$store.state.Ui.tags;
    }
  },
  methods: {
    onInput(tag, index) {
      const messageError = 'The Tag Name should not contain more than 50 characters';
      // console.log(tag);
      // console.log(this.$refs['tag' + index][0].innerHTML)
      if (this.$refs['tag' + index][0].innerHTML.length > 50) {
        this.$notify({ group: 'error', text: messageError });
        this.$refs['tag' + index][0].innerHTML = tag
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    changeTagColor(color, tag) {
			this.$store.dispatch('Ui/changeTagColor', {color, tag})
      const messageSuccess = 'The color of the <span class="text-capitalize">' + tag.title + '</span> was changed';
      this.$notify({ group: 'success', text: messageSuccess });
		},
    changeTagName(tag, index) {
      const newTagName = this.$refs['tag' + index][0].innerHTML;
      const messageSuccess = 'The name of the <span class="text-capitalize">' + tag.title + '</span> was changed';
      const messageError = 'The <span class="text-capitalize">' + newTagName + '</span> tag name is already exist. Please come up with another one.';
      
      let generalTags = this.$store.state.Ui.tags;
      let existingTag = generalTags.find((generalTag => generalTag.title.toLowerCase() == newTagName.toLowerCase()));
      if (newTagName.length == 0) {
        this.$notify({ group: 'error', text: 'Tag name can not be empty' });
        this.$refs['tag' + index][0].innerHTML = tag.title;
      } else if (existingTag && newTagName != tag.title) {
        this.$notify({ group: 'error', text: messageError });
        this.$refs['tag' + index][0].innerHTML = tag.title;
      } else if (newTagName != tag.title) {
        this.$store.dispatch('Ui/changeTagName', {newTagName, tag});
        this.$notify({ group: 'success', text: messageSuccess });
      }
    },
    resetTagName(tag, index) {
      this.$refs['tag' + index][0].innerHTML = tag.title;
    },
    showDeleteModal(tag, index) {
      this.deleteName = tag.title;
      this.$bvModal.show('modal-delete-tag' + index)
      // this.$root.$emit('bv::show::modal', 'modal-delete-tag', '#deleteTag')
    },
    deleteTag(tag, index) {
      this.$store.dispatch('Ui/deleteTag', tag)
      this.$bvModal.hide('modal-delete-tag' + index)
      const messageSuccess = 'The <span class="text-capitalize">' + tag.title + '</span> was succesfully deleted';
      this.$notify({ group: 'success', text: messageSuccess });
    },
    preventEnter(e) {
			e.preventDefault();
		}
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
:deep(.tags-header header) {
  color: var(--c-dark-8);
  font-size: var(--font-s-m);
}
.tag-list__item:hover {
  background-color: var(--c-dark-2);
}
.btn-tag-delete {
  color: var(--c-dark-6);
  opacity: 0;
}
.btn-tag-save, .btn-tag-cancel {
  opacity: 0;
}
.tag-list__item:hover .btn-tag-delete, .tag-list__item:active .btn-tag-delete, .tag-list__item:focus .btn-tag-delete, .tag-list__item:hover .btn-tag-save, .tag-list__item:hover .btn-tag-cancel {
  opacity: 1;
}
.tag-color_action {
  position: relative;
}
.tag-color_action:after {
  content: '';
  display: block;
  border-radius: 13px;
  height: 26px;
  width: 26px;
  border: 2px solid var(--c-dark-6);
  position: absolute;
  top: -4px;
  left: -4px;
  opacity: 0;
  transition: .3s;
}
.tag-color:hover:after {
  opacity: 1;
  transition: .3s;
}
.tag-color_action:active:after {
  border-color: #fff;
  
}
.list-group-item [contenteditable] {
  display: inline-block;
  border-radius: 6px;
  border: 2px solid transparent;
  outline: 0px solid transparent;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.list-group-item [contenteditable]:hover {
  background-color: var(--c-dark-1);
}
.list-group-item [contenteditable]:focus {
  box-sizing: border-box;
  border: 2px solid var(--c-main-light);
}
.list-group-item [contenteditable]:focus + .btn-tag-save, .list-group-item [contenteditable]:active + .btn-tag-save, .list-group-item [contenteditable]:focus + .btn-tag-cancel, .list-group-item [contenteditable]:active + .btn-tag-cancel {
  opacity: 1;
}
.prevent-overflow {
  overflow-wrap: break-word;
}
</style>
