/**
 * Rewrite URL for all requests to aMember base
 *
 * @param {RequestConfig} reqConfig
 */
export default function amProxyUrl(reqConfig, baseURL = null) {
	// // const amBaseUrl = window.amApiProxyUrl; // "http://test.castr.io/castr-api" set in Castr_AppControllergit
	// const amBaseUrl = process.env.VUE_APP_AMEMBER_API_BASE_URL;

	// if (!reqConfig.method || reqConfig.method.match(/get/i)) { return reqConfig; } // we do not change GET requests
	// if (reqConfig.path.startsWith('/')) {
	// 	reqConfig = {...reqConfig, path: amBaseUrl + reqConfig.path};
	// } else if (baseURL && reqConfig.path.startsWith(baseURL)) {
	// 	reqConfig = {...reqConfig, path: amBaseUrl + reqConfig.path.substr(baseURL.length)};
	// } else {
	// 	throw new Error('Unknown url in amProxyUrl ' + reqConfig.path);
	// }
	// // console.debug('amProxyUrl rewritten', reqConfig, baseURL)
	return reqConfig;
}
