import _ from 'lodash';
import RequestError from './RequestError';
import amProxyUrl from './AmProxyUrl';
import api from '@/api';
import axios from 'axios'

let platformTemplates = null;

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

export default {
	isFacebookTokenValid,
	isYoutubeTokenValid,
	checkIntegrationConflict,
	getServiceIngest,
	getMixerFTLUrl,
	getIntegrationMetadata,
	updateIntegrationMetadata,
	getDiscordIntegrations,
	saveDiscordIntegration,
	updateDiscordIntegration,
	deleteDiscordIntegration,
	buildStreamChatUrl,
	flushStreamChat,
	getServiceSubChannels,
	getPublishPlatformList,
	getPublishPlatform,
	getPublishPlatformIcon,
	getAckByKey,
	getCountryList,
	getTwitchCategories
};

/**
 * @param {string} accessToken 
 */
async function isFacebookTokenValid(linkedServiceCreds, direct = false) {
	if(direct){
		return makeRequest(`/integrations/check-validation/${linkedServiceCreds}/token?direct=true`);
	}else{
		return makeRequest(`/integrations/check-validation/${linkedServiceCreds}/token`);
	}
	
}

/**
 * @param {string} accessToken 
 */
 async function isYoutubeTokenValid(linkedServiceCreds, channelId, direct = false) {
	if(direct){
		return makeRequest(`/integrations/youtube/${linkedServiceCreds}/token?channelId=${channelId}&direct=true`);
	}else{
		return makeRequest(`/integrations/youtube/${linkedServiceCreds}/token?channelId=${channelId}`);
	}
}

/**
 * @param {string} serviceName
 * @param {string} streamId
 */
function checkIntegrationConflict(streamId, oauthMetaId) {
	return makeRequest(`/integrations/conflictCheck?streamId=${streamId}&oauthMetaId=${oauthMetaId}`);
	// return makeRequest('/integrations/' + serviceName)
}

/**
 * @param {string} integrationId
 * @param {string} streamId
 * @param {string} integrationId
 * @param {string} method Request custom method
 */
function getServiceIngest(linkedMetaId, queryParams, method) {
	const url = `/integrations/${linkedMetaId}/ingest`;

	if (method && method.toLowerCase() === 'post') {
		return makeRequest({
			path: url,
			method,
			data: queryParams || {}
		});
	}

	return makeRequest({
		path: url,
		params: queryParams || {}
	});
}

/**
 * @param {string} integrationId
 * @param {string} streamId
 * @param {string} integrationId
 */
function getServiceSubChannels(linkedMetaId, queryParams) {
	// let url = `/integrations/${serviceName}/ingest?stream=${streamId}`
	const url = `/integrations/${linkedMetaId}/subchannels`;
	// if (integrationId) url += `&integration=${integrationId}`

	return makeRequest({
		path: url,
		params: queryParams || {}
	});
	// return makeRequest('/integrations/' + serviceName)
}

/**
 * @param {string} username
 */
function getMixerFTLUrl(username) {
	const url = `/integrations/mixerFTL/username/${username}`;
	return makeRequest(url);
	// return makeRequest('/integrations/' + serviceName)
}

/*
 * @param {string} linkedMetaId
 */
function getIntegrationMetadata(linkedMetaId) {
	const url = `/integrations/${linkedMetaId}/metadata`;
	return makeRequest(url);
}

/**
 * @param {string} linkedMetaId
 */
function updateIntegrationMetadata(linkedMetaId, metaUpdates) {
	const url = `/integrations/${linkedMetaId}/metadata/update?isLinkedOAuth=1`;
	return makeRequest({
		path: url,
		method: 'put',
		data: {
			metadata: metaUpdates
		}
	});
}

/**
 * @param {string} streamId
 */
function getDiscordIntegrations(streamId) {
	const url = `/integrations/stream/${streamId}/discord/webhooks`;
	return makeRequest(url);
}

/**
 * @param {string} streamId
 * @param {string} integrationId
 */
function saveDiscordIntegration(streamId, discordConfig) {
	const url = `/integrations/discord/webhooks?stream=` + streamId;

	return makeRequest({
		path: url,
		method: 'post',
		data: { discord: discordConfig }
	});
}

/**
 * @param {number} channelId
 * @param {object} updates
 */
function updateDiscordIntegration(integrationId, updates) {
	const url = `/integrations/${integrationId}/discord/webhooks`;
	return makeRequest({
		path: url,
		method: 'put',
		data: { updates }
	});
}

/**
 * @param {string} channelId
 */
function deleteDiscordIntegration(integrationId) {
	const url = `/integrations/${integrationId}/discord/webhooks`;
	return makeRequest({
		path: url,
		method: 'delete'
	});
}

/**
 * @param {string} streamId
 */
function buildStreamChatUrl(streamId) {
	const url = `/integrations/chat/buildStreamChatUrl/${streamId}`;
	return makeRequest({
		path: url,
		method: 'POST'
	});
}

/**
 * @param {string} streamId
 */
function flushStreamChat(streamId) {
	const url = `/integrations/chat/${streamId}/clear`;
	return makeRequest({
		path: url,
		method: 'POST'
	});
}

async function getPublishPlatformList() {
	if (!platformTemplates) {
		try {
			platformTemplates = await (await fetch('https://castr.com/app/static/data/platformList.json')).json()
		} catch (error) {
			try {
				platformTemplates = await makeRequest({
					path: 'https://assets.castr.io/data/platformList.json',
					timeout: 2000
				});
			} catch(err) {
				console.log('load platformlist failed', err);
				// try to get platformlist from 2nd source
				// if DO CDN has issue
				platformTemplates = await makeRequest({
					path: 'https://castr.com/platformList.json'
				});
			}
		}

		// platformTemplates.forEach(plat => {
		//   plat.id = _.replace(plat, /\s/gi, "_");
		//   plat.iconUrl = true;
		// });
	}

	return platformTemplates;
}

async function getPublishPlatform(templateName) {
	const platformTemplates = await getPublishPlatformList();
	const platformTemplate = _.find(platformTemplates, { name: templateName });
	return platformTemplate;
}

async function getPublishPlatformIcon(templateName) {
	let platformIcon;
	const platformTemplates = await getPublishPlatformList();
	const platform = _.find(platformTemplates, { name: templateName });
	if (platform) {
		platformIcon = platform.icon;
	}

	return platformIcon;
}

/**
 * @param {string} key
 */
function getAckByKey(key) {
	const url = `/acks/key/${key}`;
	return makeRequest(url);
}

/**
 * @param {string} streamId
 */
function getCountryList() {
	return makeRequest({
		path: 'https://assets.castr.io/data/countryList.json'
	});
}

/**
 * @param {string} linkedMetaId
 * @param {string} searchText
 */
 function getTwitchCategories(linkedMetaId, searchText) {
	const url = `/integrations/twitch/${linkedMetaId}/category?search=${searchText}`;
	return makeRequest({
		path: url
	});
}


/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeRequest(reqConfig) {
	if (typeof reqConfig === 'string') {
		reqConfig = { path: reqConfig };
	}

	reqConfig = amProxyUrl(reqConfig);

	reqConfig.url = reqConfig.path;

	let res;
	try {
		res = await api.request(reqConfig);
	} catch (err) {
		const edata = _.get(err, 'response.data');
		throw new RequestError(edata);
	}

	return res.data;
}
