<template>
	<div
		ref="dropzone"
		class="dropzone"
		:class="{'highlight': isDragging, 'block': isDragging, 'hidden': !isDragging}"
		@mouseleave="isDragging = false"
	>
		<slot />
	</div>
</template>

<script>
export default {
	data() {
		return {
			isDragging: false
		};
	},
	mounted() {
		window.addEventListener('dragenter', () => {
			this.isDragging = true;
		});

		const dropArea = this.$refs.dropzone;

		for (const eventName of ['dragenter', 'dragover', 'dragleave', 'drop']) {
			dropArea.addEventListener(eventName, ((e) => {
				e.preventDefault();
				e.stopPropagation();
			}), false);
		}
		for (const eventName of ['dragenter', 'dragover']) {
			dropArea.addEventListener(eventName, (() => {
				this.isDragging = true;
			}), false);
		}
		for (const eventName of ['dragleave', 'drop']) {
			dropArea.addEventListener(eventName, (() => {
				this.isDragging = false;
			}), false);
		}
		dropArea.addEventListener('drop', (e) => {
			this.$emit('drop', e);
		}, false);
	}
};
</script>

<style scoped>
.dropzone {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	user-select: none;
	border-radius: 1rem;
}

.block {
	display: block;
}

.hidden {
	display: none;
}

.highlight {
	opacity: .25;
	background: lime;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 4 4'%3E%3Cpath fill='%23000000' fill-opacity='.5' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}
</style>
