<template>
	<div class="">
		<b-modal
			:id="modalId"
			ref="modalConfirm"
			:size="modalSize"
			@hidden="resetModal"
			hide-header
			hide-footer
			centered
			:no-close-on-backdrop="closeoutsideClick"
		>
		<b-row>
			<b-col>{{ message }}{{streamCat}}</b-col>
		</b-row>
		<b-row>
			<b-col class="text-s-s text-dark-8">{{ body }}</b-col>
		</b-row>
		<slot>
		</slot>
		<b-row >
			<b-col class="text-right mt-3">
				<b-button
					variant="outline-secondary"
					@click="dismiss"
					class="mr-2"
					size="md"
				>
					{{ cancelText }}
				</b-button>
				<b-button
					v-if="showConfirm"
					:variant="modalType"
					size="md"
					@click="onConfirm"
				>
					{{ okText }}
				</b-button>
			</b-col>
		</b-row>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'ConfirmModal',
	components: {},
	props: {
		modalId: {
			type: String,
			default: 'modal-confirm'
		},
		modalSize: {
			type: String,
			default: 'sm',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
		modalType: {
			type: String,
			default: 'primary'
		},
		message: {
			type: String,
			required: false
		},
		body: {
			type: String,
			required: false
		},
		streamCat: {
			type: String
		},
		okText: {
			type: String,
			default: 'Yes'
		},
		cancelText: {
			type: String,
			default: 'No'
		},
		showConfirm: {
			type: Boolean,
			default: true,
			required: false
		},
		closeoutsideClick: {
			type: Boolean,
			default: false
		},
		confirmCancelEvent: {
			type: Boolean,
			default: true
		},
		dismissAfterConfirm: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			processing: false,
		};
	},
	methods: {
		onConfirm() {
			this.$emit('modal-confirm');
			this.dismissAfterConfirm 
				? this.dismiss(this.confirmCancelEvent)
				: this.closeModal()
		},
		closeModal() {
			this.$emit('modal-close');
			this.$refs.modalConfirm.hide();
		},
		dismiss(cancel = true) {
			if (cancel) {
				this.$emit('modal-cancel');
			}
			this.$refs.modalConfirm.hide();
			this.$emit('modal-dismiss');
			// lazy clear
			setTimeout(() => { this.processing = false; }, 1000);
		},
		resetModal() {
			this.$emit('modal-hidden');
		}
	},
};
</script>

<style scoped>

</style>
