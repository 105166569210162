<script setup>
import { computed, watch, ref, onMounted, getCurrentInstance } from 'vue'
import PaywallService from "@/services/PaywallService";
import IconBanknote from "../../components/icon/IconBanknote.vue"
import IconChevronRight from "../../components/icon/IconChevronRight.vue"
import IconBin from "../../components/icon/IconBin.vue"
import FormSwitch from "../../components/Atoms/FormSwitch.vue"
import { useStore } from 'vuex'
import { useNotification } from "@kyvg/vue3-notification";
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconBookOpen from '../icon/IconBookOpen.vue';
import IconElipsisVertical from '@/components/icon/IconElipsisVertical.vue'
import { useRoute, useRouter } from 'vue-router'
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';
import Title from '../ui/Title.vue'
import Alert from '../Atoms/Alert.vue';

const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()
const store = useStore()
const { notify } = useNotification()

const userPaywall = computed(() => store.getters['User/userPaywall'])
const paywalls = computed(() => store.getters['Paywalls/paywalls'])
const hasLiveSubscription = computed(() => store.getters['User/hasLiveSubscription'])
const isLiveSubscriptionExpired = computed(() => store.getters['User/isLiveSubscriptionExpired'])

const props = defineProps({
  onStripeConnectClick: {
    type: Function,
  },
  onStripeConnectCancelClick: {
    type: Function
  },
  stripeConnectProcessing: {
    type: Boolean,
  },
  stripeOauthLink: {
    type: String
  },
  stripeConnectLinkError: {
    type: Boolean
  },
  paywallActive: {
    type: Boolean
  },
  currentPaywall: {
    type: Object
  }
})

const emit = defineEmits({
  'paywall-toggle': (value) => { return value }
})

const changeName = async (newName) => {
  if (props.currentPaywall.title === newName) {
    return
  }
  try {
    await PaywallService.updateStreamPaywallById(props.currentPaywall.id, { title: newName })
    store.dispatch('Paywalls/setPaywallProp', { id: props.currentPaywall.id, prop: 'title', value: newName })
    notify({
      group: "success",
      text: "Paywall source updated",
    });
  } catch (err) {
    notify({
      group: 'error',
      text: err.message || "Paywall name change failed",
    })
  }
}

const togglePaywall = async () => {
  try {
    await PaywallService.toggleStreamPaywall(props.currentPaywall.id)

    store.dispatch('Paywalls/setPaywallToggle', props.currentPaywall.id)

    emit('paywall-toggle')

    notify({
      group: "success",
      text: "Paywall updated",
    });
  } catch (err) {
    notify({
      group: "error",
      text: err.message || "Paywall toggle failed",
    });
  }
}
const showDeleteModal = () => root.$emit('bv::show::modal', 'delete-paywall')

</script>

<template>
  <header v-if="currentPaywall" class="flex flex-wrap items-center mb-6 md:mb-0 md:p-6 gap-x-3">
    <Title class="!hidden md:!block" titleClasses="text-xl font-medium w-full max-w-full break-all md:w-auto" :editable="true" :title="currentPaywall?.title"
      @changed="changeName" />
    <FormButton class="ml-auto" @click="togglePaywall" type="secondary"
      :label="currentPaywall.enabled ? 'Disable' : 'Enable'" />
    <FormButton @click="showDeleteModal" type="danger" isIcon>
      <icon-base>
        <icon-bin />
      </icon-base>
    </FormButton>
    <p class="!order-first md:!order-none md:w-full text-surface-6 text-tiny mb-0">
      <a class="inline-flex items-center"
      href="https://docs.castr.com/en/articles/8925810-how-to-create-a-paywall" target="_blank">
      <icon-base class="mr-1">
        <icon-book-open />
      </icon-base>
      How Paywall works
    </a>
    </p>
  </header>
  <Alert v-if="!hasLiveSubscription || isLiveSubscriptionExpired" type="error" class="mx-6">
    Your plan has ended. Please <a class="text-underline text-reset" target="_blank" href="/app/subscribe">upgrade</a>
    to
    continue.
  </Alert>
</template>

<style scoped></style>
