<script setup>
import { ref, computed } from 'vue'
import FormButton from './FormButton.vue';

const isVisible = ref(false)

const props = defineProps({
  type: {
    type: String,
    default: 'main',
  },
  size: {
    type: String,
    default: 'md',
  },
  isCustom: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
  },
  position: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isText: {
    type: Boolean,
    default: false,
  },
  customClasses: {
    type: String,
  },
  id: {
    type: String,
  },
  to: {
    type: String,
  },
  onClick: Function
})

const toggleDropdown = () => {
  isVisible.value = !isVisible.value
}
</script>

<template>
  <li class="[&>*]:last:rounded-b-md [&>*]:first:rounded-t-md">
    <slot v-if="isCustom" />
    <div v-else-if="isText" class="h-8 p-2 text-surface-7 text-nowrap leading-none text-tiny flex items-center w-full max-w-[90vw] truncate">
      <slot />
    </div>
    <FormButton :id="id" :customClasses="customClasses ? customClasses : 'h-8 p-2 hover:bg-surface-3 hover:text-white text-nowrap leading-none text-tiny flex items-center w-full max-w-[90vw] truncate'" :type="type" :size="size" v-else-if="!to" @click="onClick" :disabled="disabled">
      <slot />
    </FormButton>
    <router-link :class="customClasses ? customClasses : 'h-8 p-2 hover:bg-surface-3 hover:text-white hover:no-underline text-nowrap leading-none text-tiny flex items-center w-full max-w-[90vw] truncate'" v-else :to="to">
      <slot />
    </router-link>
  </li>
</template>

<style></style>
