<template>
	<path
		d="M2 7.5H18M2 7.5V11.5C2 12.0523 2.44772 12.5 3 12.5H17C17.5523 12.5 18 12.0523 18 11.5V7.5M2 7.5L3.49711 3.75722C3.80084 2.9979 4.53626 2.5 5.35407 2.5H14.6459C15.4637 2.5 16.1992 2.9979 16.5029 3.75722L18 7.5M15.5 10H8.5"
		stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	<path
		d="M15.5 15H8.5M3 17.5H17C17.5523 17.5 18 17.0523 18 16.5V13.5C18 12.9477 17.5523 12.5 17 12.5H3C2.44772 12.5 2 12.9477 2 13.5V16.5C2 17.0523 2.44772 17.5 3 17.5Z"
		stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	<circle cx="5" cy="15" r="1" fill="currentColor" />
	<circle cx="5" cy="10" r="1" fill="currentColor" />
</template>
