<script setup>
import { computed, ref, onMounted, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import _ from 'lodash';
import moment from "moment";
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import PaywallService from '@/services/PaywallService';
import TabGroup from "../../components/Atoms/TabGroup.vue";
import TabItem from "../../components/Atoms/TabItem.vue";
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import utils from '@/utils';
import Title from '../../components/ui/Title.vue';
import VODNav from '@/components/VOD/VODNav.vue';
import Badge from '../../components/Badge.vue';
import Alert from '../../components/Atoms/Alert.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const isVideoListLoading = ref(false)
const statusProcessing = ref(false)
const trialSubscription = ref(null)
const processingMessage = ref(null)
const stream = ref(null)
const windowHeight = ref(0)
const isVodPaid = ref(false)
const emptyVodSub = ref(true)
const vodTrialExpiredIn = ref(0) // day
const schedulerSettings = ref(null)
const platformUsage = ref(null)
const scheduledAlert = ref(true)
const streamDvrEnabled = ref(null)
const aioStorageLimit = ref(null)
const dividerPosition = ref(25)

const loadingStatus = computed(() => store.getters['Ui/loadingStatus'])
const addonSubscriptions = computed(() => store.getters['User/addonSubscriptions'])
const baseSubscription = computed(() => store.getters['User/baseSubscription'])
const vodStreams = computed(() => store.getters['Streams/vodStreams'])
const vodFiles = computed(() => store.getters['Streams/vodFiles'])
const isVodTrial = computed(() => store.getters['User/hasVodSubscription']?.trial)
const maxVodFolders = computed(() => store.getters['User/maxVodFolders'])
const maxVodStorage = computed(() => store.getters['User/maxVodStorage'])

const userSubscription = computed(() => {
	return {
		subscription: baseSubscription.value,
		addonSubscriptions: addonSubscriptions.value,
		maxVodItems: maxVodFolders.value,
		maxVodStorage: maxVodStorage.value
	}
})

const deleteMessage = computed(() => vodFolder.value?.liveParent
	? 'Deleting this folder will permanently remove all videos within it and remove them from the VOD section of your streams. Are you sure you want to proceed?'
	: 'Deleting this folder will permanently remove all files within it. Are you sure you want to continue?'
)

const handleDragging = (e) => {
	e.preventDefault();
	const position = e.pageX
	const wrapper = document.getElementById('resizible-wrapper')
	const percentage = ((position - 260) / wrapper.offsetWidth) * 100
	if (percentage >= 20 && percentage <= 30) {
		dividerPosition.value = percentage.toFixed(2)
	}
	if (position - 260 <= 0) {
		document.removeEventListener('mousemove', handleDragging)
	}
}

const startDragging = () => {
	document.addEventListener('mousemove', handleDragging)
}

const endDragging = () => {
	document.removeEventListener('mousemove', handleDragging)
}

onMounted(async () => {
	store.dispatch('Ui/setLoadingStatus', true);
	await store.dispatch('Streams/getStreams');
	await store.dispatch('Streams/getFilesInVODFolder');
	await store.dispatch('User/getactiveTeam', null, { root: true });
	getVodSubExpiredDays()
	store.dispatch('Ui/setLoadingStatus', false);
})

const vodFolder = computed(() => {
	return vodStreams.value?.find(vod => vod._id === route.params?.streamId)
})

const onStreamDeleteConfirm = async () => {
	store.dispatch('Ui/setLoadingStatus', true);
	// this.processing = true;
	processingMessage.value = 'Deleting stream';

	let streamId = vodFolder.value.liveParent ? vodFolder.value.liveParent : vodFolder.value._id;

	// Try to delete its dvr
	let vodEpisodes
	let permanentDvrArchives
	try {
		vodEpisodes = await StreamService.getStreamDvrArchives(streamId);
	} catch (e) {
		if (!vodFolder.value.liveParent) {
			notify({
				group: 'error',
				text: 'could not retreive saved vod(s)'
			});
		}
	}

	vodEpisodes = _.map(vodEpisodes, vodEpisode => {
		const ep = _.assign({}, vodEpisode, {
			startTimeDate: new Date(vodEpisode.startTime),
			endTimeDate: new Date(vodEpisode.endTime)
		});

		ep.fileName = `${utils.ago(ep.startTimeDate)}`;
		ep.fileName = ep.fileName.replace('a few seconds ago', 'a seconds ago');
		ep.creationTime = ep.startTimeDate;

		ep.live = false;
		ep.expanded = false;
		ep.streamType = vodFolder.value.type;
		ep.blocking = false;
		ep.size = ep.size ? utils.bytes(ep.size, false, 2) : '--';

		ep.exportProcessing = false;
		ep.exportStatusTimer = null;

		return ep;
	});

	permanentDvrArchives = _.filter(vodEpisodes, { exportStatus: 'EXPORTED' }) || []
	permanentDvrArchives.forEach(async (video) => {
		const q = {
			streamId: streamId,
			from: video.from,
			duration: video.duration,
			abr: video.abr,
			namedHls: video.namedHls
		};
		if (vodFolder.value.liveParent || !video.pseudoVod) {
			await StreamService.deleteStreamDvrArchive(q);
		}
	});

	// try deleting stream
	try {
		await StreamService.deleteStream(vodFolder.value._id);
		// track event
		window.trackEvent(
			`Deleted stream ${vodFolder.value.name}`,
			vodFolder.value
		);

	} catch (err) {
		// redirect to stream list
		notify({
			group: 'error',
			title: err.error,
			text: err.message
		});
	}
	await store.dispatch('Streams/getStreams');
	router.push({ name: 'VodBucket' });
	store.dispatch('Ui/setLoadingStatus', false);
	// this.processing = false;
}
const getVodSubExpiredDays = () => {
	let originalVodSub;
	let paidSub;
	let trialSub;
	let hasMaxVodItems = false;
	if (_.isArray(userSubscription.value)) {
		userSubscription.value.forEach((sub) => {
			if (sub.category === "vod") emptyVodSub.value = false;
			if (sub.category === "vod" && sub.package.baseCharge === 0) {
				trialSub = sub;
				isVodTrial.value = true;
			}
			if (
				sub.category === "vod" &&
				sub.package.baseCharge > 0 &&
				sub.enabled
			) {
				originalVodSub = sub;
			}
			if (
				sub.category !== "vod" &&
				sub.package.definition.maxVodItems &&
				parseInt(sub.package.definition.maxVodItems, 10) > 0
			) {
				paidSub = sub;
				if (sub.enabled === true) hasMaxVodItems = true;
			}
		});
	}
	if (_.isObject(userSubscription.value)) {
		userSubscription.value.addonSubscriptions.forEach((sub) => {
			if (sub.category === "vod") emptyVodSub.value = false;
			if (sub.category === "vod" && sub.package.baseCharge === 0) {
				trialSub = sub;
				isVodTrial.value = true;
			}
			if (
				sub.category === "vod" &&
				sub.package.baseCharge > 0 &&
				sub.enabled
			) {
				originalVodSub = sub;
			}
			if (
				sub.category !== "vod" &&
				sub.package.definition.maxVodItems &&
				parseInt(sub.package.definition.maxVodItems, 10) > 0
			) {
				paidSub = sub;
				if (sub.enabled === true) hasMaxVodItems = true;
			}
		});
	}
	if (trialSub) {
		vodTrialExpiredIn.value = moment(trialSub.cend).diff(moment(), "day");
	}

	if (paidSub) {
		isVodPaid.value = true;
		let paidSubExpiredIn = moment(paidSub.cend).diff(moment(), "day");

		// Compare with the previous expire day to get which is available
		if (trialSub && vodTrialExpiredIn.value > paidSubExpiredIn) {
			paidSubExpiredIn = vodTrialExpiredIn.value;
			isVodPaid.value = false;
		}

		vodTrialExpiredIn.value = paidSubExpiredIn;

		if (hasMaxVodItems) {
			isVodTrial.value = false;
			vodTrialExpiredIn.value = 1;
		}
	}

	if (originalVodSub) {
		isVodPaid.value = true;
		const originalVodSubExpiredIn = moment(originalVodSub.cend).diff(
			moment(),
			"day"
		);

		if (originalVodSubExpiredIn > vodTrialExpiredIn.value) {
			vodTrialExpiredIn.value = originalVodSubExpiredIn;
			isVodTrial.value = false;
		}
	}
	return vodTrialExpiredIn.value;
}

const onStreamNameChange =  async (newName, vod) => {
	if (newName === vodFolder.value.name) return;

	// try changing stream name
	try {
		await StreamService.setStreamName(vod._id, newName);
		PaywallService.updateStreamPaywall(vod._id, { title: newName }).catch(err => {
			console.error('update paywall stream name failed: ', err)
		});
		store.dispatch('Streams/getStreams')
		notify({
			group: 'success',
			title: `The ${newName} name was succesfully changed`,
		});
		// track event
		window.trackEvent(
			`Updated stream name ${vod.name} -> ${newName}`
		);
	} catch (err) {
		notify({
			group: 'error',
			title: "Couldn't change stream name",
			text: err.message
		});
	}
}

const shouldShowSettingsNav = computed(() => route.name === 'VODBucketPlayerSettings' || route.name === 'VODBucketSecuritySettings' ||  route.name === 'StreamsManageVodAdvertising' || route.name === 'VODstreamManagePaywall')
</script>

<template>
	<div class="w-full !ml-0" @mouseup="endDragging()">
		<main id="resizible-wrapper" class="flex !flex-wrap lg:!flex-nowrap relative">
			<VODNav :is-vod-trial="isVodTrial" :stream="stream" :aioStorageLimit="aioStorageLimit"
				:style="{ width: `${dividerPosition}%`, 'flex-basis': `${dividerPosition}%` }" @video-added="onVideoFileAdded"
				:vod-sub-expired-days="vodTrialExpiredIn" class="pt-2 pt-md-5 min-w-full md:min-w-0"
				@route-change="goToItem" />
			<div class="aside-divider" :style="{
				left: `${dividerPosition}%`
			}" @mousedown="startDragging()">
			</div>
			<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-surface-8 mt-16 mx-auto"
				spinner-color="var(--c-surface-8)" spinner-size="15px" />
			<div v-show="!loadingStatus" class="!pl-4 !pr-4 md:!pl-6 pt-6 md:pt-24 lg:pt-10 min-w-full md:min-w-0"
				:style="{ width: `${100 - dividerPosition}%`, 'flex-basis': `${100 - dividerPosition}%` }">
				<header v-if="route.name !== 'VODStreamManage' && route.name !== 'VODBucketVideos'" class="flex mb-6 items-center flex-wrap gap-y-2">
					<Badge v-if="vodFolder?.liveParent" title="Live-to-VOD" class="!mr-2" />
					<Title :editable="true" @changed="value => onStreamNameChange(value, vodFolder)" :title="vodFolder?.name" />
					<Alert class="md:ml-auto">
						Need more storage/bandwidth?&nbsp;<a class="underline" href="/app/subscribe">Upgrade here</a>!
					</Alert>
				</header>
				<div v-if="isVodTrial && !isVodPaid">
					<p class="color-danger text-center md:text-left" v-if="vodTrialExpiredIn >= 0">
						Your trial plan will end in {{ vodTrialExpiredIn }} days. Please
						<router-link to="/subscribe">
							Upgrade
						</router-link>
						to extend your service.
					</p>
					<p class="color-danger" v-else>
						Your trial plan has ended. Please
						<router-link to="/subscribe">
							Upgrade
						</router-link>
						to extend your service.
					</p>
				</div>
				<div v-if="(!emptyVodSub || !isVodTrial) && isVodPaid && vodTrialExpiredIn < 0">
					<p class="color-danger">
						Your plan has ended. Please
						<router-link to="/subscribe">
							Upgrade
						</router-link>

						to continue.
					</p>

					<div v-if="isVodTrial && !isVodPaid">
						<p class="color-danger" v-if="vodTrialExpiredIn >= 0">
							Your trial plan will end in {{ vodTrialExpiredIn }} days. Please
							<router-link to="/subscribe?category=live">
								Upgrade
							</router-link>
							to extend your service.
						</p>
						<p class="color-danger" v-else>
							Your trial plan has ended. Please
							<router-link to="/subscribe?category=live">
								Upgrade
							</router-link>
							to extend your service.
						</p>
					</div>
				</div>
				<TabGroup v-if="shouldShowSettingsNav" class="mb-6" type="lines">
        <TabItem
          type="line"
          label="Settings"
					:to="'/vods/' + vodFolder?._id + '/settings/player-interface'"
          :isActive="route.name === 'VODBucketPlayerSettings' || route.name ===  'VODBucketSecuritySettings'"
        />
        <TabItem
          type="line"
          label="Advertising"
					:to="'/vods/' + vodFolder?._id + '/advertising'"
          :isActive="route.name === 'StreamsManageVodAdvertising'"
        />
        <TabItem
          type="line"
          label="Paywall"
					:to="'/vods/' + vodFolder?._id + '/paywall'"
          :isActive="route.name === 'VODstreamManagePaywall'"
        />
      </TabGroup>
				<router-view v-if="vodFolder" v-slot="{ Component }">
					<component :is="Component" :stream="vodFolder" :stream-alive="streamAlive" :media-pulse="mediaPulse"
						:video-files="videFilesForCurrentPage" :is-vod-trial="isVodTrial" :trial-subscription="trialSubscription"
						:vod-sub-expired-days="vodTrialExpiredIn" :search="videoFileSearchText" @stream-updated="onStreamUpdates"
						@refresh-stream="fetchStreamDetails" @video-update="onVideoFileUpdate" @video-removed="onVideoFileRemoved"
						@set-live-stream-countdown="liveStreamCountdownSelected" @live-stream-abr-changed="liveStreamAbrChanged"
						@dvr-recording-toggle="dvrRecordingToggle" @video-added="fetchStreamVideoFileList"
						:aioStorageLimit="aioStorageLimit" />
				</router-view>
				<Spinner v-if="isVideoListLoading" text="Retrieving data..." classes="text-dark-8 my-3"
					spinner-color="var(--c-dark-8)" spinner-size="15px" />

			</div>
			<confirm-modal modalId="delete-vod-stream" message="Are you sure you want to delete this folder?"
				modal-type="danger" :body="deleteMessage" @modal-confirm="onStreamDeleteConfirm" />
		</main>
	</div>
</template>
