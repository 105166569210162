<template>
  <div class="">
    <b-modal
      size="sm"
      centered
      hide-header
      hide-footer
      :id="videoId + '-modal'"
    >
      <b-row>
        <b-col>
          <h3 class="text-s-xl text-500 mb-3">Create New Tag</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="text-dark-8" for="">Tag title</label>
          <b-form-input
            v-model="tagTitle"
            maxlength="50"
            v-on:keyup.enter="addTag()"
            @input="cleanError"
            placeholder="Press enter to add a tag"
            class="form-control mb-2"
            v-bind:class="{ error: error }"
          ></b-form-input>
          <p v-if="error" class="color-danger d-flex align-items-start">
            <svg
              class="flex-shrink-0 mr-2 mt-1"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.36956 3.79623C6.53108 1.67817 7.11184 0.619141 8 0.619141C8.88816 0.619141 9.46892 1.67817 10.6304 3.79623L14.0638 10.0571C15.15 12.0377 15.693 13.028 15.2574 13.7638C14.8217 14.4996 13.6923 14.4996 11.4334 14.4996H4.56665C2.30772 14.4996 1.17826 14.4996 0.742611 13.7638C0.306962 13.028 0.850044 12.0377 1.93621 10.0571L5.36956 3.79623ZM7.99996 4.74955C8.41417 4.74955 8.74996 5.08534 8.74996 5.49955V7.99955C8.74996 8.41377 8.41417 8.74955 7.99996 8.74955C7.58574 8.74955 7.24996 8.41377 7.24996 7.99955V5.49955C7.24996 5.08534 7.58574 4.74955 7.99996 4.74955ZM8 11.9996C8.55228 11.9996 9 11.5518 9 10.9996C9 10.4473 8.55228 9.99956 8 9.99956C7.44771 9.99956 7 10.4473 7 10.9996C7 11.5518 7.44771 11.9996 8 11.9996Z"
                fill="#E25858"
              />
            </svg>
            {{ errorText || 'This tag title already exists. Please come up with another one.' }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <b-form-radio-group
              v-model="tagColor"
              class="colors-list mt-4"
              button-variant="color-item"
              :options="colorsList"
              name="colors-list"
              buttons
            >
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button
            size="md"
            variant="outline-secondary"
            @click="$bvModal.hide(videoId + '-modal')"
            class="mr-3"
            >Cancel</b-button
          >
          <b-button
            size="md"
            :disabled="!availableToAdd"
            @click="addTag()"
            variant="primary"
            >Add</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "AddTagModal",
  components: {},
  props: {
    videoId: null,
    videoTags: {
      type: Array,
      default: null,
    },
    tempTagIds: null,
    liveParent: {
			type: Object
		},
		streamParent: {
			type: Object
		}
  },
  data() {
    return {
      tagTitle: "",
      tagColor: "color-1",
      colorsList: [
        { text: "", value: "color-1" },
        { text: "", value: "color-2" },
        { text: "", value: "color-3" },
        { text: "", value: "color-4" },
        { text: "", value: "color-5" },
        { text: "", value: "color-6" },
        { text: "", value: "color-7" },
        { text: "", value: "color-8" },
        { text: "", value: "color-9" },
      ],
      error: false,
      errorText: ''
    };
  },
  updated() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      this.error = false;
      this.errorText = ''
    });
  },
  computed: {
    availableToAdd() {
      if (this.tagTitle && this.tagColor) return true;
    },
    allTags() {
      return this.$store.state.Ui.tags;
    },
    // clearError() {
    // 	let existingTag = this.videoTags.find((videoTag => videoTag.title.toLowerCase() == this.tagTitle.toLowerCase()));
    // 	console.log(existingTag);
    // 	if (this.tagTitle.length > 0 && existingTag) {
    // 		return false
    // 	} else return true
    // }
  },
  methods: {
    cleanError() {
      if (this.tagTitle.trim()) {
        this.error = false;
        this.errorText = ''
      }
    },
    addTag() {
      if(!this.tagTitle.trim()) {
        this.errorText = 'Tag title cannot be empty!'
        this.error = true
        return
      }

      this.errorText = ''
      this.error = false

      let tag = {
        title: this.tagTitle.toLowerCase(),
        color: this.tagColor,
      };
      let tagInGeneralTags = this.$store.state.Ui.tags.filter(
        (el) => el.title == this.tagTitle.toLowerCase()
      ).length;
      if (tagInGeneralTags > 0) {
        const message =
          'The ' +
          tag.title +
          " is already exist";
        this.$notify({ group: "error", text: message });
        this.error = true;
      } else {

        this.tagTitle = ''
        this.tagColor = 'color-1'
        this.$bvModal.hide(this.videoId + '-modal')

        this.$store.dispatch("Ui/addTag", tag).then(() => {
          let newTagId = this.$store.state.Ui.tags.find(
            (videoTag) => videoTag.title.toLowerCase() == tag.title
          );
          this.tempTagIds.push(newTagId.id);
          // this.$store.dispatch('Ui/updateTagsOfVideoFile', { videoId: this.$props.videoId, updatedTagIds: this.tempTagIds })
          const activeTagIds = this.allTags.map((tag) => tag.id);
          const filteredTags = this.tempTagIds.filter((tagId) =>
            activeTagIds.includes(tagId)
          );
          // console.log(filteredTags)
          this.$store.dispatch("Ui/updateTagsOfVideoFile", {
            videoId: this.$props.videoId,
            updatedTagIds: filteredTags,
            parent: this.$props.liveParent, 
            parentId: this.$props.streamParent?.liveParent
          });
        });

        const messageSuccess = 'The ' + tag.title + ' tag was created';
        this.$notify({ group: "success", text: messageSuccess, html:true  });
      }

      // let tagInTags = 0

      // if (this.videoTags !== null) {
      // 	tagInTags = this.videoTags.filter( el => el.title == this.tagTitle.toLowerCase() ).length;
      // }

      // let tagInGeneralTags = this.$store.state.Ui.tags.filter( el => el.title == this.tagTitle.toLowerCase() ).length;
      // if (tagInTags === 0) {
      // 	if (this.videoTags !== null) {
      // 		this.videoTags.push(tag);
      // 	}
      // 	// this.$emit('new-tags', this.videoTags);
      // 	if (tagInGeneralTags === 0) {
      // 		this.$store.dispatch('Ui/addTag', tag);
      // 		console.log(this.tagTitle);

      // 		setTimeout(() => {
      // 			let newTagId = this.$store.state.Ui.tags.find((videoTag => videoTag.title.toLowerCase() == tag.title));
      // 			console.log(newTagId);
      // 			this.tempTagIds.push(newTagId.id);
      // 			this.$store.dispatch('Ui/updateTagsOfVideoFile', { videoId: this.$props.videoId, updatedTagIds: this.tempTagIds })
      // 		}, 1000);
      // 		// this.$store.dispatch('Ui/updateTagsOfVideoFile', { videoId: this.$props.videoId, updatedTagIds: filteredTags })
      // 		let message = 'The <span class="text-capitalize">' + tag.title + '</span> was created'
      // 		this.$notify({ group: 'success', text: message });
      // 	}
      // 	this.tagTitle = null;
      // 	this.tagColor = null;
      // 	this.error = false;
      // } else this.error = true;
    },
  },
};
</script>

<style scoped>
.colors-list {
  width: 100%;
  justify-content: space-between;
}
:deep(.colors-list .btn-color-item) {
  flex: 0 0 24px;
  height: 24px;
  width: 24px;
  max-width: 24px;
  border-radius: 12px;
  background-color: var(--c-main-light);

  position: relative;
}
:deep(.colors-list .btn:not(:last-child):not(.dropdown-toggle)),
:deep(.colors-list .colors-list:not(:last-child) > .btn) {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
:deep(.colors-list .btn-color-item:after) {
  content: "";
  border: 2px solid transparent;
  border-radius: 17px;
  position: absolute;
  flex: 0 0 34px;
  height: 34px;
  width: 34px;
  top: -6px;
  left: -6px;
  transition: 0.3s;
}
:deep(.colors-list .btn-color-item:hover:after) {
  border: 2px solid var(--c-dark-8);
  transition: 0.3s;
}
:deep(.colors-list .btn-color-item.active:after) {
  border: 2px solid #fff;
  transition: 0.3s;
}
:deep(.colors-list .btn-color-item:nth-of-type(1)) {
  background-color: #0062ff;
}
:deep(.colors-list .btn-color-item:nth-of-type(2)) {
  background-color: #3da9f7;
}
:deep(.colors-list .btn-color-item:nth-of-type(3)) {
  background-color: #32de96;
}
:deep(.colors-list .btn-color-item:nth-of-type(4)) {
  background-color: #6ed75d;
}
:deep(.colors-list .btn-color-item:nth-of-type(5)) {
  background-color: #e4df63;
}
:deep(.colors-list .btn-color-item:nth-of-type(6)) {
  background-color: #f0bb6c;
}
:deep(.colors-list .btn-color-item:nth-of-type(7)) {
  background-color: #f1587d;
}
:deep(.colors-list .btn-color-item:nth-of-type(8)) {
  background-color: #d85cbd;
}
:deep(.colors-list .btn-color-item:nth-of-type(9)) {
  background-color: #9766ff;
}
</style>
