<template>
	<div>
		<div class="content-container">
			<div class="feature-item">
				<b-row class="align-items-center">
					<b-col cols="9" md="8">
						<h4 class="text-s-l font-weight-normal mb-0">Enable Domain Whitelisting</h4>
						<p class="m-0 text-s-s text-dark-7">Allow the embedded player to be watched only on specific domains.</p>
					</b-col>
					<b-col cols="3" md="4" class="text-right">
						<b-form-checkbox
							v-if="permissions && permissions.domainWhitelists"
							v-model="features.domainWhitelists.enabled"
							:disabled="featureProcessing.domainWhitelists"
							@change="toggleFeature('domainWhitelists')"
							switch>
						</b-form-checkbox>
						<span id="playlist-domain-whitelist-tooltip" v-else-if="permissions && !permissions.domainWhitelists">
							<b-form-checkbox class="d-inline-block" disabled switch />
						</span>
						<b-tooltip v-if="permissions && !permissions.domainWhitelists" target="playlist-domain-whitelist-tooltip">
							Please <a class="text-underline text-reset" target="_blank" href="/app/subscribe">upgrade</a> subscription to use this feature.
						</b-tooltip>
					</b-col>
				</b-row>
				<b-row class="toggle-hide" v-if="features.domainWhitelists.enabled">
					<b-col>
						<div v-if="!whitelistedDomainsLoaded">
							<i class="fa fa-spinner fa-spin" />
						</div>
						<div class="mt-2" v-else>
								<!-- v-model="features.domainWhitelists.value"  -->
								<b-form-textarea
									v-model="whitelistedDomains"
									class="mb-2"
									placeholder="one domain per line"
									autocomplete="off"
									autocorrect="off"
									autocapitalize="off"
									spellcheck="false"
									rows="4"
								/>
							<div>
								<b-button
									:disabled="!features.domainWhitelists.value"
									class="ml-0 mt-1"
									variant="dark-5"
									@click="saveWhitelistedDomains"
								>
									{{ featureProcessing.domainWhitelists ? 'saving ..' : 'Save' }}
								</b-button>
							</div>
						</div>
					</b-col>
				</b-row>

				<div class="feature-desc">

					<div
						v-if="features.domainWhitelists.enabled"
						class="pane"
					>
						<p class="mt-3">
							Whitelisted Domains
							<small class="ml-1">(one domain per line)</small>
						</p>
						
						
						<div class="text-s-m"
						>
							example:
							<br>
							<code>https://example.com</code>
							<br>
							<code>https://mysite.com</code>
							<br>
							<code>https://analytics-site.net</code>
						</div>
					</div>
				</div>
			</div>

			<div class="feature-item">
				<b-row align-v="center">
					<b-col cols="9" md="8">
						<h4 class="text-s-l font-weight-normal mb-0">Geo/Country Blocking</h4>
						<p class="m-0 text-s-s text-dark-7">Prevent (ban) viewers from specific country(ies).</p>
					</b-col>
					<b-col cols="3" md="4" class="text-right">
						<b-form-checkbox
							v-if="permissions && permissions.countryBlock"
							v-model="features.countryBlock.enabled"
							:disabled="featureProcessing.countryBlock"
							@change="toggleFeature('countryBlock')"
							switch>
						</b-form-checkbox>
						<span id="playlist-country-block-tooltip" v-else-if="permissions && !permissions.countryBlock">
							<b-form-checkbox class="d-inline-block" disabled switch />
						</span>
						<b-tooltip v-if="permissions && !permissions.countryBlock" target="playlist-country-block-tooltip">
							Please <a class="text-underline text-reset" target="_blank" href="/app/subscribe">upgrade</a> subscription to use this feature.
						</b-tooltip>
					</b-col>
				</b-row>
				<b-row class="toggle-hide" v-if="features.countryBlock.enabled">
				<!-- <b-row> -->
					<b-col>
						<div v-if="!countryListLoaded">
								<i class="fas fa-spinner fa-spin loader" /> working ..
							</div>
						<div class="mt-2" v-else inline>
							<multiselect
								v-model="countryBlockSelection"
								:options="countryList"
								:multiple="true"
								:taggable="true"
								tag-placeholder="Unsupported region"
								placeholder="Search for a country"
								label="name"
								track-by="code"
								class="col col-md-11 p-0 select_md mr-2 mb-2"
								@tag="updateGeoBlockSelection"
							/>
							<b-button
								:disabled="featureProcessing.countryBlock || !canSaveGeoBlocking"
								variant="dark-5"
								size="md"
								@click="saveGeoBlocking"
							>
								{{ featureProcessing.countryBlock ? 'saving ..' : 'Save' }}
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>

			<div class="feature-item">
				<b-row class="align-items-center">
					<b-col cols="9" md="8">
						<h4 class="text-s-l font-weight-normal mb-0">Geo/Country Whitelisting</h4>
						<p class="m-0 text-s-s text-dark-7">Allow viewers only from specific country(ies).</p>
					</b-col>
					<b-col cols="3" md="4" class="text-right">
						<b-form-checkbox
							v-if="permissions && permissions.countryWhitelists"
							v-model="features.countryWhitelists.enabled"
							:disabled="featureProcessing.countryWhitelists"
							@change="toggleFeature('countryWhitelists')"
							switch>
						</b-form-checkbox>
						<span id="playlist-country-whitelist-tooltip" v-else-if="permissions && !permissions.countryWhitelists">
							<b-form-checkbox class="d-inline-block" disabled switch />
						</span>
						<b-tooltip v-if="permissions && !permissions.countryWhitelists" target="playlist-country-whitelist-tooltip">
							Please <a class="text-underline text-reset" target="_blank" href="/app/subscribe">upgrade</a> subscription to use this feature.
						</b-tooltip>
					</b-col>
				</b-row>
				<b-row class="toggle-hide" v-if="features.countryWhitelists.enabled">
					<b-col>
						<div v-if="!countryListLoaded">
							<i class="fas fa-spinner fa-spin loader" /> working ..
						</div>
						<div class="mt-2" inline v-else>
							<multiselect
								v-model="countryWhitelistSelection"
								:options="countryList"
								:multiple="true"
								:taggable="true"
								tag-placeholder="Unsupported region"
								placeholder="Search for a country"
								label="name"
								track-by="code"
								class="col col-md-11 p-0 select_md mr-2 mb-2"
								@tag="updateGeoBlockSelection"
							/>

							<b-button
								:disabled="featureProcessing.countryWhitelists"
								@click="saveGeoWhitelisting"
								variant="dark-5"
								size="md"
							>
								{{ featureProcessing.countryWhitelists ? 'saving ..' : 'Save' }}
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>
		<confirm-modal
			modal-id="feature-upgrade"
			ok-text="Upgrade Now"
			cancel-text="Later"
			@modal-confirm="navigateToPayments(stream.type)">
			<p class="mb-0 text-s-m"><a target="_blank" class="text-reset text-underline" href="/app/subscribe">Upgrade</a> to access this feature. Check <a  target="_blank" class="text-reset text-underline text-nowrap" href="https://castr.com/pricing">Pricing page</a> to know more about eligible plan.</p>
		</confirm-modal>
	</div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import StreamService from '@/services/StreamService';
import IntegrationService from '@/services/IntegrationService';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';

export default {
	name: 'PlaylistSecuritySettings',
	components: {
		ConfirmModal,
		Multiselect
	},
	props: {
		playlistSettings: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			processing: true,
			processingMessage: null,
			streamId: null,
			streamMeta: null,
			countryBlockSelection: [],
			countryWhitelistSelection: [],
			countryList: [],
			countryListLoaded: false,
			whitelistedDomains: [],
			whitelistedDomainsLoaded: false,
			features: {
				countryBlock: {
					enabled: false,
					value: [],
					valueType: 'array'
				},
				countryWhitelists: {
					enabled: false,
					value: [],
					valueType: 'array'
				},
				domainWhitelists: {
					enabled: false,
					value: [],
					valueType: 'array'
				}
			},
			featureProcessing: {
				countryBlock: false,
				countryWhitelists: false,
				domainWhitelists: false
			}
		};
	},
	computed: {
		liveSub() {
			return this.$store.state.User.addonSub.find(({ category }) => category === 'live');
		},
		permissions() {
			const domainWhiteList = !_.isNil(this.liveSub?.definitionOverride?.['playlist:domain-whitelist']) ? this.liveSub?.definitionOverride?.['playlist:domain-whitelist'] : (this.liveSub?.package?.definition?.['playlist:domain-whitelist'] || false);
			const geoBlock = !_.isNil(this.liveSub?.definitionOverride?.['playlist:geo-block']) ? this.liveSub?.definitionOverride?.['playlist:geo-block'] : (this.liveSub?.package?.definition?.['playlist:geo-block'] || false);
			const geoWhitelist = !_.isNil(this.liveSub?.definitionOverride?.['playlist:geo-whitelist']) ? this.liveSub?.definitionOverride?.['playlist:geo-whitelist'] : (this.liveSub?.package?.definition?.['playlist:geo-whitelist'] || false);

			return {
				countryBlock: geoBlock,
				countryWhitelists: geoWhitelist,
				domainWhitelists: domainWhiteList,								
			}
		},
		canSaveGeoBlocking: () => true,
		productsFeatures() {
			return this.$store.state.User.aggregatedDefinition;
		}
	},
	async mounted() {


		this.setup();

		// event tracking
		// window.trackEvent(
		// 	this.stream.name + ' - Live Stream Security Settings Page',
		// 	this.stream
		// );
	},
	methods: {
		async setup() {
			this.streamMeta = _.cloneDeep(this.playlistSettings);
			this.features = {...this.features, ...this.streamMeta };
			// fetch country list
			this.countryList = await IntegrationService.getCountryList();
			this.countryListLoaded = true;

			this.countryBlockSelection = _.map(
				this.features.countryBlock.value,
				code => _.find(this.countryList, { code })
			);
			this.countryWhitelistSelection = _.map(
				this.features.countryWhitelists.value,
				code => _.find(this.countryList, { code })
			);

			this.whitelistedDomains = _.join(
				_.filter(this.features.domainWhitelists.value, item => item !== 'https://castr.com'),
				'\r\n',
			);
			this.whitelistedDomainsLoaded = true;
		},
		navigateToPayments (category = 'live') {
			this.$router.push({ name: 'Payments', query: { category } });
		},
		updateGeoBlockSelection() {
			// console.log('countryNode', countryNode)
		},
		clipboardCopy(text) {
			try {
				if (text instanceof Function) text = text();

				this.$copyText(text);
				this.$notify({ group: 'info', text: 'Copied to clipboard' });
			} catch (e) {}
		},
		async toggleFeature(featureName) {
			if (this.featureProcessing[featureName]) return;

			const feature = this.features[featureName];
			if (!feature) return;

			let mappedSecurityFeatName = featureName;
			switch (featureName) {
				case 'embedPassword':
					mappedSecurityFeatName = 'securityFeaturesPasswordAuth';
					break;
				case 'countryBlock':
					mappedSecurityFeatName = 'securityFeaturesGeoBlock';
					break;
				case 'countryWhitelists':
					mappedSecurityFeatName = 'securityFeaturesGeoWhitelist';
					break;
				case 'domainWhitelists':
					mappedSecurityFeatName = 'securityFeaturesDomainWhitelist';
					break;
			}
			
			let isToggledOn = this.features[featureName].enabled;

			if(isToggledOn && !this.permissions[featureName]) {
				this.$root.$emit('bv::show::modal', 'feature-upgrade');
				isToggledOn = false;
				setTimeout(() => {
					this.features[featureName].enabled = !this.features[featureName].enabled;
				}, 500);
			}

			if(feature.valueType === 'array') {
				let newValue = []
				if(isToggledOn) {
					newValue = this.features[featureName].value;
				}
				await this.saveSetting(featureName, newValue);
			} else if(feature.valueType === 'bool') {
				await this.saveSetting(featureName, isToggledOn);
			}
		},
		async saveGeoBlocking() {
			const updatedSettings = this.countryBlockSelection;
			await this.saveSetting('countryBlock', _.map(updatedSettings, 'code'));
		},
		async saveGeoWhitelisting() {
			const updatedSettings = this.countryWhitelistSelection;
			await this.saveSetting(
				'countryWhitelists',
				_.map(updatedSettings, 'code')
			);
		},
		async setStreamPassword() {
			const password = this.features.embedPassword.value;
			if (!password) return;

			await this.saveSetting('embedPassword', password);
		},
		async saveWhitelistedDomains() {
			let domains = _.split(this.whitelistedDomains, '\n')
				.map(d => d.trim())
				.filter(Boolean);
			const castrExist = domains.find((item) => item === 'https://castr.com');
			if (_.isEmpty(castrExist)) {
				domains = [...domains, 'https://castr.com'];
			}
			if (!domains.length) return;

			await this.saveSetting('domainWhitelists', domains);
		},
		onMediaPulseChanged() {},
		async saveSetting(key, value) {
			this.featureProcessing[key] = true;
			try {
				// await StreamService.saveStreamMetadata(
				// 	this.streamId,
				// 	key,
				// 	value
				// );
				this.features[key].value = value;
				this.$emit('on-update', { key, value: this.features[key] })
				// clear stream meta cache in embed player
				// StreamService.clearStreamMetadataCache(this.streamId);
			} catch (e) {
				console.log(e);
				this.$notify({ group: 'error', text: 'could not save changes' });
			}
			this.featureProcessing[key] = false;
		}
	},
	watch: {
		playlistSettings() {
			this.setup();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.modal-open .toggle-hide {
 display: none;
}
</style>
