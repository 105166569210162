<template>
	<div class="">
		<!-- Modal Component -->
		<b-modal id="modal-vod-clip-trimming" ref="modalVodClipTrimming" hide-header hide-footer size="lg" centered>
			<div class="p-md-4">
				<b-row>
					<b-col cols="12" md="7">
						<!-- iframe widget -->
						<div class="placeholder-wrapper">
							<code>Processing playback ..</code>
						</div>
						<iframe :src="getIframeUrl()" class="embed" frameborder="0" width="550" height="300" allow="autoplay"
							scrolling="no" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen
							msallowfullscreen />
					</b-col>
					<b-col class="d-flex flex-column justify-content-between" v-if="active">
						<h3 class="text-s-xl text-500 mb-2">Clip video</h3>
						<p class="text-dark-8 text-s-s">
							Move the green anchors to select a range.
						</p>
						<b-list-group class="list-group-flush">

						</b-list-group>
						<b-row class="mt-auto mb-2">
							<p class="text-s-s text-white text-500 ml-3">Clip's name</p>
							<b-col class="d-flex align-items-center mb-4 mb-lg-0" cols="12">
								<b-input v-model="name" class="" @keydown.enter.prevent="e.preventDefault()" />
							</b-col>
						</b-row>
						<b-row class="p-2">
							<b-col cols="5" class="text-s-m text-dark-7">
								<p class="mb-0">Selected range:</p>
							</b-col>
							<b-col class="text-s-m text-dark-7  text-right">
								<span v-html="humanTimeRange(rangeStart)"></span>
								-
								<span v-html="humanTimeRange(rangeEnd)"></span>
							</b-col>
						</b-row>
						<b-row class="p-2">
							<b-col class="text-s-m">
								<b-button :disabled="this.rangeStart < 0 || this.rangeEnd <= 0 || this.name === ''" variant="primary"
									size="md" @click="onExportCustomRange">
									<span>{{ !saveProcessing ? 'Clip' : 'Saving...' }}</span>
								</b-button>

								<b-button @click="dismiss();" class="ml-2" size="md" variant="outline-secondary">
									Cancel
								</b-button>

							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import prettyMilliseconds from 'pretty-ms';
import StreamService from '@/services/StreamService'

export default {
	name: 'VodClipTrimmingModal',
	components: {},
	props: {
		hlsUrl: {
			type: String,
			required: true
		},
		video: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			processing: false,
			active: false,
			rangeStart: 0,
			rangeEnd: 0,
			slider: 0,
			name: '',
			saveProcessing: false
		};
	},
	mounted() {
		this.$refs.modalVodClipTrimming.$on('shown', this.onInit);
		this.$refs.modalVodClipTrimming.$on('hide', this.onDismiss);
		window.addEventListener('message', this.receiveMessage);
	},
	methods: {
		receiveMessage(event) {
			if (event.data) {
				try {
					const data = JSON.parse(event.data);
					if ((data.start || data.start === 0) && data.end) {
						this.rangeStart = Math.round(data.start);
						this.rangeEnd = Math.round(data.end);
					}
				} catch (err) { }
			}
		},
		async onExportCustomRange() {
			this.saveProcessing = true
			try {

				const data = {
					name: this.name,
					startSec: this.rangeStart === 0 ? 1 : this.rangeStart,
					endSec: this.rangeEnd
				}
				const resp = await StreamService.addVodClip(this.$route.params.streamId, this.video._id, data)
				this.$emit('request-vod-clip-range-created', resp.data[0]);
			} catch (err) {
				console.log(err)
				this.$notify({ group: 'error', text: 'could not add clip' })
			}
			this.saveProcessing = false
			this.dismiss();
		},
		onInit() {
			this.active = true;
		},
		onDismiss() {
			this.active = false;
			this.rangeStart = 0;
			this.name = ''
			this.rangeEnd = 0;
		},
		onConfirm() {
			this.$emit('modal-confirm');
			this.dismiss();
		},
		dismiss() {
			this.$refs.modalVodClipTrimming.hide();
			// lazy clear
			setTimeout(() => {
				this.processing = false;
			}, 1000);
		},
		getIframeUrl() {
			const appPath = process.env.NODE_ENV === 'production' ? '/app/' : '/';
			// var url = 'https://na-storage-3.castr.io/archives/edge-en/5f50620cff77767dd15db55c/live_0af9b76035be11ee9489ef29946044d7/archive-1691480160-54.m3u8'
			return `${appPath}static/player/index.html?url=${this.hlsUrl}&slider=${this.slider}`;
		},
		humanTimeRange(duration) {
			return moment.utc(moment.duration(duration, "s").asMilliseconds()).format("HH:mm:ss")
		},
		humanTime(duration) {
			return prettyMilliseconds(duration * 1000, { unitCount: 2 })
		}
	},
	beforeDestroy() {
		window.removeEventListener('message', this.receiveMessage)
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.placeholder-wrapper {
	position: absolute;
	width: 100%;
	text-align: center;
	margin-top: 50px;
	z-index: 0;
}

iframe.embed {
	max-width: 100%;
	margin: 0;
	padding: 0;
	margin-bottom: -7px;
	position: relative;
	z-index: 1;
}

.btn-icon {
	top: -15px;
	right: -10px;
}

.list-group-item {
	text-overflow: ellipsis;
	overflow: hidden;
}

@media (max-width: 767px) {
	iframe.embed {
		height: 186px;
	}
}
</style>
