<template>
	<div class="">
		<div :class="computedStatStyles"
			class="media-info mt-1 pl-2 mt-md-5 mx-n1 mx-md-0 grid md:flex w-full bg-surface-2 first:rounded-l-md last rounded-r-md py-1">
			<!-- analytics data -->
			<div class="grow flex items-center flex-wrap justify-center content-center"
				v-if="checkColumnVisibility('analytics') && stream.type !== 'restream'">
				<!-- bandwidth stats -->
				<h6 class="flex items-center justify-center w-full text-sm text-surface-8">
					<icon-base class="text-orange mr-1" width-view-box="16" height-view-box="16" iconColor="none" width="16"
						height="16">
						<icon-bandwidth />
					</icon-base>
					<span class="media-info__text media-info__text_orange">Bandwidth</span>
				</h6>
				<div class="">
					<div class="text-s-s media-info__value" v-if="bandwidthStats.loaded">
						<span>
							{{ bandwidthStats.value }}
							<span class="text-uppercase">{{ bandwidthStats.unit }}</span>
						</span>
					</div>
					<div v-else>
						<span>
							<i class="fas fa-spinner fa-spin loader" />
						</span>
					</div>
				</div>
			</div>
			<div class="grow flex items-center flex-wrap justify-center content-center"
				v-if="(checkColumnVisibility('analytics')) && (stream.type !== 'restream')">
				<!-- viewers stats -->
				<h6 class="flex items-center justify-center w-full text-sm text-surface-8">
					<icon-base class="text-blue mr-1" width-view-box="16" height-view-box="16" iconColor="none" width="16"
						height="16">
						<icon-eye />
					</icon-base>
					<span class="media-info__text media-info__text_blue">Viewers</span>
				</h6>
				<div class="info mt-auto order-0 order-md-1">
					<div class="text-s-s media-info__value">
						<div v-if="streamAlive">
							<div v-if="clientsCount !== -1">
								<span class="">
									{{ numberFormated(clientsCount) }}
								</span>
							</div>
							<div v-else>
								<span class="">
									<i class="fas fa-spinner fa-spin loader" />
								</span>
							</div>
						</div>
						<div v-else>
							..
						</div>
					</div>
				</div>
			</div>
			<!-- stream bitrate -->
			<div class="grow flex items-center flex-wrap justify-center content-center"
				v-if="checkColumnVisibility('stream_bitrate')">
				<b-tooltip target="bitrate-button" placement="top">
					<span class="text-dark-8 text-small" v-if="(stream.type === 'live' || stream.type === 'restream')">
						Open Input Health Charts
					</span>
				</b-tooltip>
				<ejs-tooltip
					:content="(stream.type === 'live' || stream.type === 'restream') ? 'Open Input Health Charts' : null">
					<div class="grow flex items-center flex-wrap justify-center content-center"
						:class="{ 'input-health-navigation': (stream.type === 'live' || stream.type === 'restream') }"
						@click="onClickBitrate">
						<h6 class="flex items-center justify-center w-full text-sm text-surface-8">
							<icon-base class="text-pink mr-1" width-view-box="16" height-view-box="16" iconColor="none" width="16"
								height="16">
								<icon-bitrate />
							</icon-base>
							<span class="media-info__text media-info__text_pink">Bitrate</span>
						</h6>
						<div class="info mt-auto order-0 order-md-1">
							<div class="text-s-s media-info__value ml-0 mt-0">
								<div v-if="streamAlive">
									<span v-if="mediaPulse.bitrate">
										<span class="flex items-center">
											{{ mediaPulse.bitrate }}
											<span>kbps</span>
											<span class="ml-2" v-if="stream.type === 'live' || stream.type === 'restream'">
												<icon-base class="text-surface-8" width-view-box="16" height-view-box="16" iconColor="none"
													width="16" height="16">
													<icon-diagram />
												</icon-base>
											</span>
										</span>
									</span>
									<span v-else class="fas fa-spinner fa-spin loader" />
								</div>
								<div v-else>
									..
								</div>
							</div>
						</div>
					</div>
				</ejs-tooltip>
			</div>
			<!-- media resolution & quality -->
			<div class="grow flex items-center flex-wrap justify-center content-center"
				v-if="checkColumnVisibility('media_quality')">
				<h6 class="flex items-center justify-center w-full text-sm text-surface-8">
					<icon-base class="text-gold mr-1" width-view-box="16" height-view-box="16" iconColor="none" width="16"
						height="16">
						<icon-resolution />
					</icon-base>
					<span class="media-info__text media-info__text_gold">Resolution</span>
				</h6>
				<div class="info mt-auto">
					<div class="text-s-s media-info__value">
						<div v-if="streamAlive && streamResolution">
							<span class="">
								{{ streamResolution }}
							</span>
							<span class="text-uppercase">{{ streamQuality }}</span>
						</div>
						<div v-else class="">
							..
						</div>
					</div>
				</div>
			</div>
			<!-- media FPS, codecs -->
			<div class="grow flex items-center flex-wrap justify-center content-center"
				v-if="checkColumnVisibility('media_codecs')" :class="streamAlive ? 'stream-alive' : 'empty'">
				<h6 class="flex items-center justify-center w-full text-sm text-surface-8">
					<icon-base class="text-purple mr-1" width-view-box="16" height-view-box="16" iconColor="none" width="16"
						height="16">
						<icon-codec />
					</icon-base>
					<span class="media-info__text media-info__text_purple">Codecs</span>
				</h6>
				<div class="info mt-auto order-0 order-md-1">
					<div class="text-s-s media-info__value">
						<div v-if="streamAlive"
							class="flex d-md-block items-center justify-content-center flex-wrap text-xl-nowrap">
							<span v-if="mediaPulse.fps" class="fps mr-1 order-1 text-s-s">
								<span class="">
									{{ formatFPS(mediaPulse.fps) }}
									<span class="text-uppercase">fps</span>
								</span>
							</span>
							<span v-if="streamAlive" class="order-0">
								<b-badge v-for="(track, index) in mediaPulse.codecs" :key="index"
									class="mr-1 text-s-xxs text-uppercase text-500" :variant="track.type">
									{{ track.codec }}
								</b-badge>
							</span>
							<span v-else>
								..
							</span>
						</div>
						<div v-else>
							..
						</div>
					</div>
				</div>
			</div>
			<!-- stream bitrate -->
			<div class="grow flex items-center flex-wrap justify-center content-center"
				v-if="checkColumnVisibility('stream_bitrate')">
				<h6 class="flex items-center justify-center w-full text-sm text-surface-8">
					<icon-base class="text-pink mr-1" width-view-box="16" height-view-box="16" iconColor="none" width="16"
						height="16">
						<icon-speaker />
					</icon-base>
					<span class="media-info__text media-info__text_pink text-nowrap">Audio Bitrate</span>
				</h6>
				<div class="info mt-auto order-0 order-md-1">
					<div class="text-s-s media-info__value ml-0 mt-0">
						<div v-if="streamAlive">
							<span v-if="mediaPulse.audioBitrate">
								<span class="flex items-center">
									{{ mediaPulse.audioBitrate }}
									<span>kbps</span>
									<span class="ml-2" v-if="stream.type === 'live' || stream.type === 'restream'">
										<icon-base class="text-surface-8" width-view-box="16" height-view-box="16" iconColor="none"
											width="16" height="16">
											<icon-diagram />
										</icon-base>
									</span>
								</span>
							</span>
							<span v-else class="fas fa-spinner fa-spin loader" />
						</div>
						<div v-else>
							..
						</div>
					</div>
				</div>
			</div>
			<!-- videos count -->
			<div class="grow flex items-center flex-wrap justify-center content-center"
				v-if="checkColumnVisibility('videos_count')">
				<h6 class="flex items-center justify-center w-full text-sm text-surface-8">
					<icon-base class="text-olive mr-1" width-view-box="20" height-view-box="20" iconColor="none" width="16"
						height="16">
						<icon-files />
					</icon-base>
					<span class="media-info__text media-info__text_pink text-nowrap">Total Files</span>
				</h6>
				<div class="info mt-auto order-0 order-md-1">
					<div class="text-s-s media-info__value ml-0 mt-0">
						{{ videoFiles.length }}
					</div>
				</div>
			</div>
			<!-- bytes storage -->
			<div class="grow flex items-center flex-wrap justify-center content-cente"
				v-if="checkColumnVisibility('bytes_storage')">
				<h6 class="flex items-center justify-center w-full text-sm text-surface-8">
					<icon-base class="text-white mr-1" width-view-box="20" height-view-box="20" iconColor="none" width="16"
						height="16">
						<icon-storage />
					</icon-base>
					<span class="media-info__text media-info__text_pink text-nowrap">Storage</span>
				</h6>
				<div class="info mt-auto order-0 order-md-1">
					<div v-if="videoFiles.length">
						<div class="text-s-s media-info__value ml-0 mt-0">
							{{ getStorageused().totalBytes }}
							<span class="text-uppercase">{{ getStorageused().unit }}</span>
						</div>
					</div>
					<div v-else-if="videoFiles.length === 0" class="text-s-s media-info__value">
						0
					</div>
					<div v-else class="text-s-s media-info__value">
						<i class="fas fa-spinner fa-spin loader" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import StreamService from '@/services/StreamService';
import MetricsService from '@/services/MetricsService';
import { bytes, normalizedFPS, numberFormat } from '@/filtersNew';
import IconBase from '@/components/icon/IconBase.vue';
import IconBandwidth from '@/components/icon/IconBandwidth.vue';
import IconEye from '@/components/icon/IconEye.vue';
import { TooltipComponent } from "@syncfusion/ej2-vue-popups"
import IconBitrate from '@/components/icon/IconBitrate.vue';
import IconResolution from '@/components/icon/IconResolution.vue';
import IconCodec from '@/components/icon/IconCodec.vue';
import IconSpeaker from '@/components/icon/IconSpeaker.vue';
import IconDiagram from '@/components/icon/IconDiagram.vue';
import IconStorage from '../../components/icon/IconStorage.vue';
import IconFiles from '../../components/icon/IconFiles.vue';

export default {
	name: 'StreamOverviewStats',
	components: {
		IconBase,
		IconBandwidth,
		IconEye,
		"ejs-tooltip": TooltipComponent,
		IconBitrate,
		IconResolution,
		IconCodec,
		IconSpeaker,
		IconDiagram,
		IconStorage,
		IconFiles
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		videoFiles: {
			type: Array,
			default() { return []; }
		},
		mediaPulse: {
			type: Object,
			default() { return {}; }
		}
	},
	data() {
		return {
			scopeAlive: true,
			processing: true,
			processingMessage: null,
			windowHeight: 0,
			clientsCount: -1,
			totalEncodingStorageTime: 0,
			bandwidthStats: {
				value: 0,
				unit: 'bytes',
				loaded: false
			},
			encodingStats: {
				value: 0,
				loaded: false
			},
			storageStats: {
				value: 0,
				unit: 'bytes',
				loaded: false
			},
			columns: [
				{ id: 'stream_status', streamTypes: ['restream', 'scheduled'] },
				{ id: 'analytics', streamTypes: ['live', 'ipcam', 'vod'] },
				{
					id: 'stream_bitrate',
					streamTypes: ['restream', 'live', 'ipcam', 'scheduled']
				},
				{ id: 'media_quality', streamTypes: ['restream', 'live', 'ipcam'] },
				{ id: 'media_codecs', streamTypes: ['restream', 'live', 'ipcam'] },
				{ id: 'videos_count', streamTypes: ['scheduled', 'vod'] },
				{ id: 'bytes_storage', streamTypes: ['scheduled', 'vod'] }
			]
		};
	},
	computed: {
		subscription() {
			let subs = this.$store.state.User.subscriptions.find(sub => sub.category && sub.category.includes(this.stream.type));
			if (!subs && this.stream.type === 'vod') {
				subs = this.$store.state.User.subscriptions.find(sub => sub.package && sub.package.definition.maxVodItems && parseInt(sub.package.definition.maxVodItems, 10) > 0);
			}
			return subs;
		},
		computedStatStyles() {
			if (this.stream.type === ('restream' || 'scheduled' || 'vod')) return 'row_4'
			if (this.stream.type === 'live') return 'row_6'
			return ''
		},
		streamAlive() {
			return this.stream.enabled && this.mediaPulse && this.mediaPulse.alive;
		},
		streamResolution() {
			return (this.streamAlive && this.mediaPulse.width) ? `${this.mediaPulse.width} x ${this.mediaPulse.height}` : null;
		},
		streamQuality() {
			const sizes = [480, 720, 1080, 1440, 2160];
			const sizesFmt = ['sd', 'hd', 'fhd', 'qhd', 'uhd'];

			const height = this.mediaPulse.height || 0;

			let quality = 'sd';
			for (let i = 0; i < sizes.length; i++) {
				const nsize = sizes[i + 1];
				if (height >= sizes[i]) {
					if (!nsize || height < nsize) {
						quality = sizesFmt[i];
						break;
					}
				}
			}

			return quality;
		}
	},
	mounted() {
		if (this.stream.type === 'live' || this.stream.type === 'ipcam') {
			this.setupBandwidthCounter();
			this.setupViewershipCounter();
		} else if (this.stream.type === 'vod') {
			this.setupStorageCounter();
			this.setupBandwidthCounter();
			this.setupEncodingCounter();
			this.setupViewershipCounter();
		} else if (this.stream.type === 'scheduled') {
			this.setupStorageCounter();
			// this.setupEncodingCounter()
			// this.setupViewershipCounter()
		}
	},
	destroyed() {
		this.scopeAlive = false;
	},
	methods: {
		onClickBitrate() {
			if (this.stream.type === 'live' || this.stream.type === 'restream') this.$router.push({ name: 'StreamsManageAnalyticsInputHealthCharts' })
		},
		checkColumnVisibility(colId) {
			const col = this.columns.find(c => c.id === colId);
			if (col) {
				return col.streamTypes.indexOf(this.stream.type) > -1;
			}
		},
		setupViewershipCounter() {
			(async function updateCounter() {
				let timeoutInterval = 5000;
				let shouldUpdateValue = this.stream.enabled;
				if (document.hidden) {
					shouldUpdateValue = false;
				}

				if (shouldUpdateValue) {
					if (!document.hasFocus()) {
						timeoutInterval *= 6;
					}
				}

				if (shouldUpdateValue) {
					try {
						const data = await StreamService.getStreamViewership(
							this.stream.key
						);
						this.clientsCount = data.viewers || 0;
					} catch (e) { }
				}

				if (this.scopeAlive) {
					setTimeout(updateCounter.bind(this), timeoutInterval);
				}
			}.bind(this)());
		},
		setupBandwidthCounter() {
			(async function updateCounter() {
				let timeoutInterval = 10000;
				let shouldUpdateValue =
					this.stream.enabled || !this.bandwidthStats.loaded;
				if (document.hidden) {
					shouldUpdateValue = false;
				}

				if (shouldUpdateValue) {
					if (!document.hasFocus()) {
						timeoutInterval *= 6;
					}
				}

				if (this.stream.type === 'vod') {
					timeoutInterval = 60000;
				}

				if (shouldUpdateValue) {
					try {
						let startTime = this.subscription.cstart;
						if (_.isString(startTime)) {
							startTime = new Date(startTime).getTime();
							if (this.subscription.enabled === false) {
								startTime = moment(startTime).utc().startOf('day').valueOf()
							}
						}

						// let bandwidthRes
						// if (this.stream.type === 'vod') {
						//   let productId = this.subscription.id
						//   bandwidthRes = await MetricsService.getSubscriptionBandwidth(undefined, productId)
						// } else {
						const bandwidthRes = await MetricsService.getStreamBandwidth(
							this.stream._id,
							startTime
						);
						// }
						const downloadedBytes = _.get(bandwidthRes, 'bytes') || 0;
						const bytesInfo = bytes(
							downloadedBytes >= 100000000 ? downloadedBytes : 0, // only show bandwidth > 100MB
							true,
							2,
							true
						);
						this.bandwidthStats = _.assign(
							{},
							this.bandwidthStats,
							bytesInfo
						);
						this.bandwidthStats.loaded = true;
					} catch (e) {

					}
				}

				if (this.scopeAlive) {
					setTimeout(updateCounter.bind(this), timeoutInterval);
				}
			}.bind(this)());
		},
		setupStorageCounter() {
			(async function updateCounter() {
				let timeoutInterval = 10000;
				let shouldUpdateValue = true;
				if (document.hidden) {
					shouldUpdateValue = false;
				}

				if (shouldUpdateValue) {
					if (!document.hasFocus()) {
						timeoutInterval *= 6;
					}
				}

				if (shouldUpdateValue) {
					try {
						const productId = this.subscription.package._id;
						const storageRes = await MetricsService.getSubscriptionStorage(
							undefined,
							productId
						);

						const bytesConsumed = _.get(storageRes, 'bytes') || 0;
						const bytesInfo = this.$options.filters.bytes(
							bytesConsumed,
							true,
							2,
							true
						);

						this.storageStats = _.assign(
							{},
							this.storageStats,
							bytesInfo
						);
						this.storageStats.loaded = true;
					} catch (e) { }
				}

				if (this.scopeAlive) {
					setTimeout(updateCounter.bind(this), timeoutInterval);
				}
			}.bind(this)());
		},
		setupEncodingCounter() {
			(async function updateCounter() {
				let timeoutInterval = 30000;
				// let shouldUpdateValue = !this.encodingStats.loaded
				let shouldUpdateValue = true;
				if (document.hidden) {
					shouldUpdateValue = false;
				}

				if (shouldUpdateValue) {
					if (!document.hasFocus()) {
						timeoutInterval *= 6;
					}
				}

				if (shouldUpdateValue) {
					try {
						const productId = this.subscription.package._id;
						const encodingUsageInfo = await MetricsService.getSubscriptionEncodingUnits(null, productId);
						const minsEncoded = _.get(encodingUsageInfo, 'units') || 0;
						this.encodingStats = {
							value: minsEncoded > 0 ? Math.round(minsEncoded) : minsEncoded,
							loaded: true
						};
					} catch (e) {
						console.log('e', e);
					}
				}

				if (this.scopeAlive) {
					setTimeout(updateCounter.bind(this), timeoutInterval);
				}
			}.bind(this)());
		},
		getStorageused() {
			let totalBytes = this.videoFiles.reduce((tf, item) => tf + item.bytes, 0);
			const k = 1000;
			const decimals = 2;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			const i = Math.floor(Math.log(totalBytes) / Math.log(k));
			totalBytes = parseFloat((totalBytes / Math.pow(k, i)).toFixed(dm));
			return {
				totalBytes: totalBytes,
				unit: sizes[i]
			}
		},
		formatFPS(number) {
			return normalizedFPS(number)
		},
		numberFormated(number) {
			return numberFormat(number)
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row_4 {
	@apply grid-cols-2 grid-rows-2 gap-y-4
}

.row_6 {
	@apply grid-cols-2 grid-rows-3 gap-y-4 gap-x-3
}
</style>
