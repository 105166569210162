<template>
  <div class="">
    <b-modal :id="modalId" ref="EditPhoneNumberModal" :size="modalSize" hide-header hide-footer centered>
      <b-row>
        <b-col>Update phone number</b-col>
      </b-row>
      <b-row>
        <b-col class="text-s-s text-dark-8">
          All streams have alert linked with this number will be updated too.
        </b-col>
      </b-row>
      <b-row class="ml-1 mt-4">
        <vue-tel-input class="phone-input" v-model="phoneNumberInput" mode="national" auto-format :dropdown-options="options" @validate="onValidate"></vue-tel-input>
      </b-row>
      <b-row v-if="validationError" class="ml-1 mt-2">
        <span style="color: #ba2929;" class="text-s-s">{{ errorMsg }}</span>
      </b-row>
      <b-row>
        <b-col class="text-right mt-3">
          <b-button variant="outline-secondary" @click="dismiss" class="mr-2" size="md">
            Cancel
          </b-button>
          <b-button variant="primary" size="md" @click="onSave" :disabled="!phoneNumberValid || processing">
            Update Number

            <span v-if="processing" class="ml-2">
              <clip-loader color="#ffffff" size="15px" />
            </span>

          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import ClipLoader from "@/components/ui/ClipLoader.vue";
import UserService from '@/services/UserService';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
  name: 'EditPhoneNumberModal',
  components: {
    VueTelInput,
    ClipLoader
  },
  props: {
    modalId: {
      type: String,
      default: 'EditPhoneNumberModal'
    },
    modalSize: {
      type: String,
      default: 'sm',
      validator(v) {
        return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
      }
    },
    contact: {
      required: true
    }
  },
  data() {
    return {
      processing: false,
      validationError: false,
      phoneNumberInput: null,
      phoneNumberValid: false,
      phoneFormattedNumber: null,
      countryCode: null,
      contactId: null,
      options: {
				showDialCodeInList: true,
				showDialCodeInSelection: true,
				showFlags: true,
				showSearchBox: true,
			}
    };
  },
  watch: {
    contact: function (newVal, old) {
      if (newVal) {
        this.phoneNumberInput = newVal.contact.phoneNumber
        this.countryCode = newVal.contact.countryCode
        this.contactId = newVal._id
      }
    }
  },
  mounted() {
    console.log('mounted EditPhoneNumberModal', this.contact)
  },
  methods: {
    onValidate(mobile) {
      this.validationError = false
      if (this.contact && (mobile.number == this.contact.contact.phoneNumber)) {
        this.phoneNumberValid = false
        return
      }

      if (!mobile.valid) {
        this.phoneNumberValid = false
        return
      }

      this.phoneFormattedNumber = mobile.number;
      this.phoneNumberValid = true
    },
    async onSave() {
      this.processing = true
      try {
        const payload = {
          phone: this.phoneFormattedNumber
        }
        const updatedContact = await UserService.updateUserContact(this.contactId, payload, true)
        this.$emit('user-contact-updated', updatedContact)

        // TODO: move to high order component once git conflict resolved
        const updatedUser = _.assign({}, UserService.getUser(), { contact: { id: this.contactId, phoneNumber: this.phoneFormattedNumber } })
        localStorage.setItem('user', JSON.stringify(updatedUser))

        // setTimeout(() => {
        // 	this.$refs.AddPhoneNumberModal.hide()
        // }, 1000)
      } catch (e) {
        this.$notify({ group: 'error', text: e ? e.message : 'Failed to update phone number' })
        this.errorMsg = e ? e.message : 'Failed to update phone number'
        this.validationError = true
        setTimeout(() => {
          this.validationError = false
        }, 5000)
      }
      this.processing = false
    },
    dismiss() {
      this.$refs.EditPhoneNumberModal.hide();
      // lazy clear
      setTimeout(() => { this.processing = false; }, 1000);
    }
  },
};
</script>

<style>
.country-selector__input {
  border: 0px solid #ccc !important;
}

.input-tel__input {
  border: 0px solid #ccc !important;
}

.input-phone-number {
  margin-left: 10px;
  width: 200px;
}
</style>