<template>
  <path
    d="M14.4 5.6V4.8C14.4 4.61429 14.4 4.52144 14.3959 4.44299C14.3155 2.90964 13.0903 1.68447 11.557 1.60411C11.4785 1.6 11.3857 1.6 11.2 1.6V1.6H10.4M14.4 10.4V10.4C14.4 11.33 14.4 11.795 14.2978 12.1765C14.0204 13.2117 13.2117 14.0204 12.1764 14.2978C11.7949 14.4 11.3299 14.4 10.4 14.4V14.4M1.59998 10.4V11.2V11.2C1.59998 11.3857 1.59998 11.4786 1.60409 11.557C1.68445 13.0904 2.90962 14.3155 4.44297 14.3959C4.52142 14.4 4.61427 14.4 4.79997 14.4H5.59998M1.59998 5.6V4.8V4.8C1.59998 4.61429 1.59998 4.52144 1.60409 4.44299C1.68445 2.90964 2.90962 1.68447 4.44297 1.60411C4.52142 1.6 4.61427 1.6 4.79998 1.6V1.6H5.59998"
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  <path
    d="M12.6742 5.05001C12.3997 4.91783 12.0806 5.05709 11.9613 5.36092L10.5548 8.94277L9.03236 5.3455C8.90538 5.04551 8.58289 4.91615 8.31191 5.05691C8.04105 5.19755 7.92436 5.55478 8.05134 5.85483L10.0828 10.6547C10.1721 10.8657 10.3634 11 10.5733 11C10.5759 11 10.5785 11 10.581 10.9999C10.7939 10.9966 10.9852 10.8555 11.0702 10.6393L12.9549 5.83941C13.0742 5.53558 12.9485 5.18213 12.6742 5.05001Z"
    fill="currentColor" />
  <path
    d="M6.41936 5H3.58064C3.25995 5 3 5.26862 3 5.6C3 5.93138 3.25995 6.2 3.58064 6.2H4.41933V10.4C4.41933 10.7314 4.67928 11 4.99997 11C5.32066 11 5.58062 10.7314 5.58062 10.4V6.2H6.41936C6.74005 6.2 7 5.93138 7 5.6C7 5.26862 6.74005 5 6.41936 5Z"
    fill="currentColor" />
</template>