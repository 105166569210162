<template>
	<div class="">
		<b-modal
			:id="'modalDeleteAccessToken'"
			:ref="'modalDeleteAccessToken'"
			:size="modalSize"
			hide-header
			hide-footer
			centered
		>
		<b-row>
			<b-col><h4 class="text-s-xl">Edit Access Token</h4></b-col>
		</b-row>
		<b-row>
			<b-col class="text-s-s text-dark-9 mb-2 mt-2">The “{{ accessToken.name }}” token access will become invalid for all users. This action cannot be undone</b-col>
		</b-row>
    <hr>
		<b-row >
			<b-col class="text-right mt-3">
				<b-button variant="dark-3" @click="dismiss" class="mr-2" size="md">{{ cancelText }}</b-button>
				<b-button  variant="danger" size="md" @click="deleteToken">Revoke</b-button>
			</b-col>
		</b-row>
		</b-modal>
	</div>
</template>

<script>
import AccessTokenService from '@/services/AccessTokenService';

export default {
	name: 'DeleteAccessTokenModal',
	components: {
    AccessTokenService
  },
	props: {
    accessToken: {
			type: Object,
			default() { return {}; }
		},
		modalSize: {
			type: String,
			default: 'md',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
		modalType: {
			type: String,
			default: 'primary'
		},
		body: {
			type: String,
			required: false
		},
		streamCat: {
			type: String
		},
		okText: {
			type: String,
			default: 'Disable'
		},
		cancelText: {
			type: String,
			default: 'Cancel'
		}
	},
	data() {
		return {
			processing: false,
			tokenName: '',
		};
	},
  mounted() {
  },
  computed: {
  },
  watch: {
  },
	methods: {
		onConfirm() {
			this.$emit('modal-toggle');
			this.dismiss();
		},
		dismiss() {
			this.$emit('modal-cancel');
			this.$refs['modalDeleteAccessToken'].hide();
			this.$emit('modal-dismiss');
			// lazy clear
			setTimeout(() => { this.processing = false; }, 1000);
		},
		async deleteToken() {
			try {
				this.$emit('toggle-processing', true)
				this.$refs['modalDeleteAccessToken'].hide();
				await AccessTokenService.deleteAccessToken(this.accessToken._id)
        this.$notify({ group: 'success', text: 'Access token is deleted' })
        this.$emit('token-deleted');
			} catch (err) {
				this.$notify({
					group: 'error',
					title: "Couldn't delete access token",
					text: err.message
				});

				if (err.message && err.message.indexOf('upgrade') > -1) {
					this.$root.$emit('bv::show::modal', 'billing-prompt');
				}
			}		},
	},
	watch: {
	}
};
</script>

<style scoped>

</style>
