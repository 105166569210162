<template>
  <div class="container">
      <h1 class="page-title mt-5">{{ this.$route.meta.title }}</h1>
      <div class="tabs tabs-light">
        <b-nav v-if="this.$route.name != 'SubscribeSubAccount' && this.$route.name != 'Team' && this.$route.name != 'Billing'" class="mb-4" tabs>
          <b-nav-item 
            v-for="(menuItem, index) in menu"
            v-bind:key="index"
            link-classes="nav-tabs_light text-capitalize" 
            v-bind:to="menuItem.href"
          >
            {{menuItem.name}}
          </b-nav-item>
        </b-nav>
        <div class="tab-content">
            <router-view :user-subscription="userSubscription"></router-view>
        </div>
      </div>
  </div>
</template>

<script>
import AlertModal from '@/components/modals/AlertModal.vue';
import _ from 'lodash'
import SubscriptionService from '@/services/SubscriptionService'
import UserService from '@/services/UserService'
import { mapGetters } from 'vuex'

export default {
  name: 'AppManage',
  props: ['subscriptions'],
  components: {
    AlertModal,
  },
  async mounted () {
    this.menuProcess = true;
    try{
      if (this.baseSubscription) {
				this.baseSubscription.unsubscribeProcessing = false
			}
      this.userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions};;
			if (_.isArray(this.userSubscription.addonSubscriptions)) {
				this.userSubscription.addonSubscriptions.forEach((sub) => {
					if (sub.package.baseCharge > 0) {
						this.paidSub = true;
					}
				});
			}
			const baseCharge = _.get(this.userSubscription, ['subscription', 'package', 'baseCharge'], 0);
			if (baseCharge > 0) {
				this.paidSub = true;
			}
      this.menuProcess = false;
      await this.setUser();
      // if (this.paidSub && !this.userInformation.parentAccount) {
      //   this.menu.push( { name: 'Refer and Earn', href: '/manage/refer-and-earn' } );
      // }
      // if ((this.userInformation.email == 'tam.nguyen@castr.io') || (this.userInformation.email == 'kellylam+16@gmail.com') || (this.userInformation.email == 'thao.lam+017@castr.io') || (this.userInformation.email == 'duy.quach@castr.io') || (this.userInformation.email == 'hans@apex-audio.be') || (this.userInformation.email == 'am0401@na.baps.org') || (this.userInformation.email == 'kenyacitizentv@gmail.com') || this.userInformation && !this.userInformation.parentAccount ) {
      //   this.menu.splice(3, 0, { name: 'sub accounts', href: '/manage/sub-accounts' })
      // }
      // if (this.subAccountsAvailable && this.permissionSubAccountCreateView) {
      //   this.menu.splice(3, 0, { name: 'sub accounts', href: '/manage/sub-accounts' })
      // }
      // if (this.subAccountsAvailable) {
      //   this.menu.splice(3, 0, { name: 'sub accounts', href: '/manage/sub-accounts' })
      // }
      // if (this.userInformation.parentAccount) {
        // this.menu.splice(1, 1)
      // }
      // const initInterval = setInterval(() => {
      //   if (this.$store.state.User.userloaded) {
      //     const hasTeamMember = !_.isEmpty(this.$store.state.User.memberDetails)
      //     const hasSubAccountPackage = this.subscriptions.some(item => item.package && item.package.category === 'sub-accounts')
      //     // console.log('CHECK SUB-ACCOUNT TAB', {hasTeamMember, hasSubAccountPackage, isTeamOwner: this.isTeamOwner, permissionSubAccountCreateView: this.permissionSubAccountCreateView});
      //     if (!this.menu.some(item => item.name === 'sub accounts') && this.isTeamOwner && hasSubAccountPackage && this.permissionSubAccountCreateView) {
      //       this.menu.splice(3, 0, { name: 'sub accounts', href: '/manage/sub-accounts' })
      //     }
      //     if (!this.menu.some(item => item.name === 'sub accounts') && !hasTeamMember && hasSubAccountPackage && this.permissionSubAccountCreateView) {
      //       this.menu.splice(3, 0, { name: 'sub accounts', href: '/manage/sub-accounts' })
      //     }
      //     clearInterval(initInterval)
      //   }
      // }, 100);
   }catch (e) {
      // window.alert('error: ' + e.message)
      console.log('error', e)
    }
    // update win title
    this.$emit('updateTitle', 'My Account')
  },
  data() {
    return {
      menu: [
        { name: 'account', href: '/manage/account' },
        // TODO We are hidding Team since it was moved to the sidebar. If all good we can delete it here
        // { name: 'team', href: '/manage/team' },
        { name: 'change password', href: '/manage/change-password' },
        { name: 'developers', href: '/manage/developers' },
        { name: 'API', href: '/manage/api' },
      ],
      account: {},
      userSubscription: null,
      paidSub: false,
      menuProcess: false,
      memberPermission: null,
      userInformation: null,
      isTeamOwner: false,
      list: [
      'hans@apex-audio.be',
      'am0401@na.baps.org',
      'kenyacitizentv@gmail.com',
      'thao.lam@castr.io',
      'thao.lam+12@castr.io',
      'thao.lam+test5b@castr.io',
      'thao.lam+test5c@castr.io',
      'thao.lam+9@castr.io',
      'thao.lam+9a@castr.io',
      'artem.komyza@castr.io'
      ]
    }
  },
  computed: {
    ...mapGetters({
			permissionSubAccountCreateView: 'User/permissionSubAccountCreateView',
      loadingStatus: "Ui/loadingStatus",
      isOwner: 'User/isOwner',
      baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions'
		}),
    activeTeam(){
        return this.$store.state.User.activatedTeam;
    },
    subAccountsAvailable() {
      const emailExist = this.list.find((item => item === this.userInformation.email))
      if (emailExist || this.isTeamOwner && this.userInformation && !this.userInformation.parentAccount) {
        return true
      } else return false
    }
  },
  watch: {
     activeTeam: function(){
      this.setUser();
    },
    // subscriptions() {
      // if ( this.userInformation && !this.userInformation.parentAccount)
      // {
      //   this.menu.splice(3, 0, { name: 'sub accounts', href: '/manage/sub-accounts' })
      // }
    // }
  },
  methods:{
    async checkBilingaccess(team){
      const billing = _.isBoolean(this.userInformation.isBillingAccess) ? this.userInformation.isBillingAccess : true;
      if(!team){
        let hasCP = this.menu.some(element => {
        if (element.name == 'change password') {
            return true;
          }
            return false;
        }); 
        if (!this.menuProcess && !hasCP) {
          this.menu.splice(3, 0, { name: 'change password', href: '/manage/change-password' })
        }
        let hasBilling = this.menu.some(element => {
          if (element.name == 'billing') {
            return true;
              }
                return false;
            }); 
            // TODO We are hidding bulling since it was moved to the sidebar. If all good we can delete it here
            // if (!this.menuProcess && !hasBilling && billing !== false && !this.userInformation.parentAccount) {
            //   this.menu.splice(2, 0, { name: 'billing', href: '/manage/billing' })
            // }
      }else{
        const permission = await UserService.getMemberpermission(team,this.userInformation._id); 
        let role = null;
        if(permission.role){
          role = permission.role;
        }
        if(role != 'admin'){
            this.menu = this.menu.filter(function(item, idx) {
              return ((item.name != 'billing') && (item.name != 'change password'));
            });
        }
        if(billing && role == 'admin'){
          let hasBilling = this.menu.some(element => {
            if (element.name == 'billing') {
                return true;
              }
                return false;
            }); 
            // TODO We are hidding bulling since it was moved to the sidebar. If all good we can delete it here
            // if (!this.menuProcess && !hasBilling && this.userInformation.isBillingAccess !== false && !this.userInformation.parentAccount ) {
            //   this.menu.splice(2, 0, { name: 'billing', href: '/manage/billing' })
            // }
        }
        if(role == 'admin'){
            let hasCP = this.menu.some(element => {
            if (element.name == 'change password') {
                return true;
              }
                return false;
            }); 
            if (!this.menuProcess && !hasCP) {
              this.menu.splice(3, 0, { name: 'change password', href: '/manage/change-password' })
            }
        }
      }
    },
    async setUser(){
        let userInfo = this.$store.state.User
        if(!userInfo.activeTeam){
          this.userInformation = userInfo;
          this.isTeamOwner = true
        }else{
          let owneruserInfo  = await UserService.getTeamOwner(userInfo.activeTeam)
          this.isTeamOwner = owneruserInfo.owner._id === userInfo._id
          owneruserInfo.owner._id = userInfo._id;
          owneruserInfo.owner.activeTeam = userInfo.activeTeam;
          this.userInformation = owneruserInfo.owner;
        }
        this.checkBilingaccess(this.userInformation.activeTeam);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-wrapper {
  padding-top: 30px;
}
.menu-list {
  margin: 0;
  padding: 0;
}
.menu-item {
  display: inline-block;
  color: #9ba0be;
  margin-right: 25px;
  text-transform: capitalize;
}
.menu-item a {
  color: inherit;
  padding: 12px 0;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
.menu-item a:hover,
.menu-item a.router-link-active {
  display: block;
  color: #f7f7f7;
  border-bottom-color: #3d9bff;
  font-weight: 500;
  cursor: pointer;
}
.content-wrapper {
  /* background-color: #202940; */
  /* background-color: #1c1c24; */
  padding: 60px 0;
  min-height: 600px;
}
.title {
  padding: 5px 7px;
  font-size: 20px;
  color: #f7f7f7;
}
</style>