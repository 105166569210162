<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex'
import _ from 'lodash'
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';
import Spinner from "@/components/ui/Spinner.vue"
import AddTvPlayoutModal from "@/components/modals/AddTvPlayoutModal.vue"
import PlayoutItem from "@/components/TVPlayout/PlayoutItem.vue"
import PlayoutEmptyPlaceholder from "@/components/TVPlayout/PlayoutEmptyPlaceholder.vue"
import { useRouter } from 'vue-router'
import FormButton from '../../components/Atoms/FormButton.vue'
import FormInput from '../../components/Atoms/FormInput.vue'
import IconBase from '../../components/icon/IconBase.vue'
import IconPlus from '../../components/icon/IconPlus.vue'
import IconChevronDown from '../../components/icon/IconChevronDown.vue'
import IconSearch from '../../components/icon/IconSearch.vue'
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue'
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue'
import ViewModeSwitcher from '../../components/ui/ViewModeSwitcher.vue';

const store = useStore()
const router = useRouter()

const searchWidgetInputElement = ref()
const showPlayoutModal = ref()

const sortBy = ref('Sort by Oldest')
const searchWidgetActive = ref(false)
const searchWidgetInput = ref('')
const sortDropdown = ref()

const viewMode = ref('grid')

const playouts = computed(() => store.getters['Streams/tvPlayouts'])
const loadingStatus = computed(() => store.getters['Ui/loadingStatus'])
const isLiveSubscriptionExpired = computed(() => store.getters['User/isLiveSubscriptionExpired'])
const hasLiveSubscription = computed(() => store.getters['User/hasLiveSubscription'])

const tvPlayoutsFiltered = computed(() => {
	const result = playouts.value.filter(tvPlayout => tvPlayout.name.toLowerCase().includes(searchWidgetInput.value.toLowerCase()))
	return result
})

onMounted(async () => {
	store.dispatch('Ui/setLoadingStatus', true)
	await store.dispatch('Streams/getStreams')
	store.dispatch('Ui/setLoadingStatus', false)
})

const onNewTv = async (player) => {
	await store.dispatch('Streams/getStreams')
	router.push({
		name: "PlayoutView",
		params: {
			tvId: player._id
		}
	})
}

const toggleView = (view) => {
	viewMode.value = view
}
const viewModeClasses = computed(() => {
	const gridClasses = 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'
	const listClasses = 'grid-cols-1 divide-y divide-surface-2'
	return viewMode.value === 'grid'
		? gridClasses
		: listClasses
})
const toggleSearchWidget = () => {
	searchWidgetActive.value = !searchWidgetActive.value
	if (!searchWidgetActive.value) {
		searchWidgetInput.value = ''
	}
}

const onSearchWidgetBlur = (state) => {
	if (searchWidgetActive.value && !state) toggleSearchWidget()
}

const changeSortBy = (option) => {
	sortBy.value = option
	store.dispatch('Streams/setSortOption', sortBy.value)
	sortDropdown.value.toggleDropdown()
}
const showCreatePlayoutModal = () => {
	showPlayoutModal.value.toggleSidebar()
}
const showDemoModal = () => {
	modalDEMOVideo.value.show()
}

</script>

<template>
	<div class="container">
		<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-surface-8 mt-96" spinner-color="var(--c-dark-8)"
			spinner-size="15px" />
		<PlayoutEmptyPlaceholder v-else-if="hasLiveSubscription && !isLiveSubscriptionExpired && playouts.length === 0"
			@createPlayout="showCreatePlayoutModal" @showDemo="showDemoModal" />
		<main v-else-if="!hasLiveSubscription || isLiveSubscriptionExpired">
			<header class="flex items-center mb-3 mt-5">
				<h1 class="text-2xl font-medium  m-0">
					TV Playout
				</h1>
			</header>
			<section class="text-center mt-100 flex items-center w-full">
				<div class="lg:w-6/12 mx-auto">
					<h2 class="text-surface-text-2 text-lg font-medium">The TV Playout feature seamlessly plays your content in
						succession at the designated scheduled time.</h2>
					<p class="text-base text-surface-6 mb-3">Simply add your contents and it will be played automatically</p>
					<FormButton to="/subscribe">
						Upgrade Plan
					</FormButton>
				</div>
			</section>
		</main>
		<main v-else>
			<header class="flex items-center mb-3 mt-5 !mt-24 md:!mt-14">
				<h1 class="text-2xl font-medium m-0">
					TV Playout
				</h1>
				<FormButton @click="showCreatePlayoutModal" class="ml-auto">
					<icon-base
          class="mr-2">
						<icon-plus />
					</icon-base>
					Create TV Playout
				</FormButton>
			</header>
			<section class="flex w-100 flex-wrap md:!flex-nowrap items-center my-3">
				<div class="mr-0 md:mr-2 mb-2 mb-md-0 filter-wrapper">
					<drop-down-menu ref="sortDropdown" class="" type="secondary" customClasses="w-full text-tiny disabled:text-surface-7 h-8 bg-surface-3 rounded-md flex items-center px-3 capitalize text-nowrap">
						<template #toggle-button>
							{{ sortBy }}
							<icon-base class="ml-2">
								<icon-chevron-down />
							</icon-base>
						</template>
						<template #menu-items>
							<drop-down-menu-item @click="changeSortBy('Sort by Oldest')">
								Sort by Oldest
							</drop-down-menu-item>
							<drop-down-menu-item @click="changeSortBy('Sort by Newest')">
								Sort by Newest
							</drop-down-menu-item>
							<drop-down-menu-item @click="changeSortBy('A to Z')">
								A to Z
							</drop-down-menu-item>
							<drop-down-menu-item @click="changeSortBy('Z to A')">
								Z to A
							</drop-down-menu-item>
						</template>
					</drop-down-menu>
				</div>
				<div :class="{ active: searchWidgetActive }"
					class="flex items-center ml-2 relative">
					<FormButton class="absolute z-10" isIcon type="link" @click="toggleSearchWidget">
						<icon-base>
							<icon-search />
						</icon-base>
					</FormButton>
					<FormInput ref="searchWidgetInputElement" v-model="searchWidgetInput"
						:placeholder="searchWidgetActive ? 'Search for streams ..' : ''" class="transition" customClasses="!pl-10" :class="searchWidgetActive ? 'w-[250px] !opacity-100' : 'w-px !opacity-0'"
						@blur="onSearchWidgetBlur"></FormInput>
				</div>
				<ViewModeSwitcher class="ml-auto" @toggle-view="toggleView" />
			</section>
			<section>
				<ul class="grid" :class="viewModeClasses">
					<li class="" v-for="(playout, index) in tvPlayoutsFiltered" :key="index">
						<playout-item :playout="playout" :view-mode="viewMode" />
					</li>
				</ul>
			</section>
		</main>
		<demo-video-modal ref="modalDEMOVideo" videoid="QGTpiwWH57_GmgD79QRbC" />
		<add-tv-playout-modal @playout-added="onNewTv" ref="showPlayoutModal" />
	</div>
</template>

<style>

</style>
