<template>
<g clip-path="url(#clip0_12095_2156)">
<path d="M1.33337 7.99999C1.33337 4.8573 1.33337 3.28595 2.30968 2.30964C3.286 1.33333 4.85734 1.33333 8.00004 1.33333C11.1427 1.33333 12.7141 1.33333 13.6904 2.30964C14.6667 3.28595 14.6667 4.8573 14.6667 7.99999C14.6667 11.1427 14.6667 12.714 13.6904 13.6904C12.7141 14.6667 11.1427 14.6667 8.00004 14.6667C4.85734 14.6667 3.286 14.6667 2.30968 13.6904C1.33337 12.714 1.33337 11.1427 1.33337 7.99999Z" stroke="currentColor" stroke-width="1.2"/>
<path d="M11 11L5 5M5 5H8.5625M5 5V8.5625" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_12095_2156">
<rect width="16" height="16" rx="5" fill="currentColor"/>
</clipPath>
</defs>
</template>
