<template>
  <div>
    <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)" spinner-size="15px" />
    <div v-else-if="!isTeamModerator">
      <div class="view" v-if="isBillingAccess">
        <div class="row-container">
          <b-row class="mb-4">
            <b-col md="6">
              <h2 class="page-title text-500">Subscriptions</h2>
            </b-col>
            <b-col md="6" class="text-md-right">
              <span class="text-s-m text-dark-7">Email:</span><span class="text-s-m mr-3">&nbsp;{{userInformation.email}}</span>
              <b-button
                variant="outline-secondary"
                class="ml-auto"
                @click="requestChangePaymentInfo()">
                  Change Payment Details
              </b-button>
            </b-col>
          </b-row>
          <div v-if="!userSubscription">
            <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mt-5" spinner-color="var(--c-dark-8)" spinner-size="15px" />
          </div>
          <div v-else class="user-sub-list">
            <ul class="susbscription-status">
              <li @click="showActivesub=true" :class="showActivesub ? 'active' : ''">
                Active
              </li>
              <li @click="showActivesub=false" :class="!showActivesub ? 'active' : ''">
                Expired
              </li>
            </ul>

            <div v-if="userSubscription.subscription && ( showActivesub ? isSubEnabled() : !isSubEnabled())" class="user-sub-item bg-dark-2 mb-4 p-3" :class="{ disabled: !isSubEnabled() }">
              <b-row>
                <b-col md="6">
                  <div>
                    <!-- <div class="subscription-badge">{{getSubscriptionName()}}</div> -->
                    <h4 class="pack-name text-s-xl text-500">
                      {{getSubscriptionName()}}
                      <b-badge class="ml-2 text-500 text-capitalize" variant="outline-success" pill v-if="isSubEnabled()">active</b-badge>
                      <b-badge class="ml-2 text-500 text-capitalize" v-else variant="outline-danger" pill>expired</b-badge>
                    </h4>
                    <div class="text-dark-8 text-s-m mb-2">
                      USD ${{getSubscriptionFee()}}/{{ isAnnualPackage() ? 'year' : 'month' }}
                    </div>
                    <b-badge variant="dark-3" class="text-500 mb-2 mb-md-0">Restreaming</b-badge>
                  </div>
                </b-col>
                <!-- <b-col cols="2">
                  <div class="subscription-badge package-category-badge">restream</div>
                </b-col>-->
                <b-col md="4" class="text-md-right ml-auto">
                  <div class="d-flex flex-wrap justify-content-md-end align-items-center">
                    <div class="text-dark-8 text-s-m mb-2 mb-md-0 mr-4 mr-md-2" v-if="isSubEnabled() && (isPaidSubscription() || isTrialSubscription())">
                      Valid until
                      <span class="color-danger">{{  dateFormated(getSubscriptionAge(), 'DD MMM, YYYY') }}</span>
                    </div>
                    <div v-if="isBundledSub()">
                      <b-badge variant="dark-3" class="text-500">Bundled</b-badge>
                    </div>
                    <div v-else>
                      <b-button :to="subscriptionManagePage(null, 'restream', userSubscription.subscription, isSubEnabled())" :variant="isPaidSubscription() ? 'secondary' : 'primary'" size="sm" v-if="isSubEnabled()">
                        <span>{{isPaidSubscription() ? 'Change' : 'Upgrade'}}</span>
                      </b-button>
                         <div class="d-flex align-items-center justify-content-end" v-else>
                        <div class="text-dark-8 text-s-m mb-2 mb-md-0 mr-4 mr-md-3">
                        Expired
                          <span class="color-danger">{{ dateFormated(getSubscriptionAge(), 'DD MMM, YYYY') }}</span>
                          </div>
                          <b-button :to="{ path: '/subscribe' }" :variant="isPaidSubscription() ? 'secondary' : 'primary'" size="sm">
                            <span>Renew</span>
                          </b-button>
                      </div>
                      <span v-if="isPaidSubscription() && isSubEnabled()" class="ml-1">
                          <b-button v-if="hasUnsubscribed()"
                                    variant="outline-secondary"
                                    disabled
                                    size="sm">Unsubscribed</b-button>
                          <b-button
                            v-else
                            variant="outline-secondary"
                            size="sm"
                            :disabled="userSubscription.subscription.unsubscribeProcessing"
                            @click="requestUnsubscribe()">
                            {{userSubscription.subscription.unsubscribeProcessing ? 'Please wait ..' : 'Cancel'}}
                            </b-button>
                      </span>
                    </div>
                  </div>
                  
                </b-col>
              </b-row>
              <hr class="my-3">
              <div class="sub-usage-container">
                  <b-row>
                    <b-col md="6">
                      <div class="text-dark-9 text-500 mb-3">
                        Usage Quota
                      </div>
                      <div class="sub-usage">
                        <div v-for="(usageProp, index) in usageProps" :key="index">
                          <div v-if="usageProp.name == 'streams'" class="item-usage-row mb-2">
                            <b-row>
                              <b-col cols="3">
                                <label class="item-name text-s-m text-dark-8 text-capitalize mb-2">Streams</label>
                              </b-col>
                              <b-col>
                                <span class="item-usage text-s-m">
                                  <span>
                                    <span class="value">{{getAllowedPropUsage(usageProp, userSubscription.subscription)}} </span>
                                    assigned
                                  </span>
                                </span>
                              </b-col>
                            </b-row>

                            <div v-if="userSubscription.subscription.package.definition.phoneAlerts">
                              <div class="item-usage-row mb-2">
                                <div class="row">
                                  <div class="col-3">
                                    <label class="item-name text-s-m text-dark-8 text-capitalize">phone alerts</label>
                                  </div>
                                  <div class="col">
                                    <span class="item-usage text-s-m"><span>
                                      <span class="value">{{ userSubscription.subscription.package.definition.phoneAlerts }}
                                        </span>
                                        <span>SMS </span> 
                                        assigned
                                      </span>
                                    </span>
                                    </div>
                                </div>
                                <div class="item-usage-explicit">
                                  <div class="row">
                                    <div class="col offset-3">
                                      <div class="explicit-usage-wrapper">
                                        <div class="card d-inline-flex text-center bg-dark-3">
                                          <div class="card-body text-s-m py-2 px-2">
                                            <span class="text-500"> {{ baseSubPhoneAlerts }} <span
                                                class="text-uppercase text-500">SMS</span></span>
                                            <div class="text-dark-8 text-s-s">Usage</div>
                                          </div>
                                        </div>
                                        <div class="card d-inline-flex text-center ml-2 bg-dark-3">
                                          <div class="card-body py-2 px-2 text-s-m">
                                            <span class="text-500">{{ userSubscription.subscription.package.definition.phoneAlerts }} </span>
                                            <span class="text-500">SMS</span>
                                            <div class="text-dark-8 text-s-s">Assigned</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
              </div>
            </div>
            <!-- OTHER SUBS -->
            <div v-if="hasAddonSubscriptions()">
              <div
                v-for="(sub, index) in sortAddonsubscription"
                :key="index"
                :class="{ disabled: !isSubEnabled(sub) }"
                
                >
                <div class="user-sub-item bg-dark-2 mb-4 p-3" v-if="showActivesub ? isSubEnabled(sub) : !isSubEnabled(sub)">
                  <b-row>
                    <b-col md="6">
                      <div>
                        <!-- <div class="subscription-badge">{{getSubscriptionName()}}</div> -->
                        <h4 class="pack-name text-s-xl text-500">
                          {{getSubscriptionName(sub)}}
                          <b-badge class="ml-2 text-500 text-capitalize" v-if="isSubEnabled(sub)" variant="outline-success" pill>active</b-badge>
                          <b-badge class="ml-2 text-500 text-capitalize" v-else variant="outline-danger" pill>expired</b-badge>
                        </h4>
                        <div class="text-dark-8 text-s-m mb-2" v-if="getSubscriptionFee(sub) > 0">
                          USD ${{getSubscriptionFee(sub)}} /{{ isAnnualPackage(sub) ? 'year' : 'month' }}
                        </div>
                        <b-badge variant="dark-3" class="text-500 mb-2 mb-md-0">
                          {{getSubCategoryName(sub)}}
                        </b-badge>
                      </div>
                    </b-col>
                    <!-- <b-col cols="2">
                      <div class="subscription-badge package-category-badge">{{sub.category}}</div>
                    </b-col>-->
                    <b-col md="4" class="text-md-right ml-auto last-col">
                      <div class="d-flex flex-wrap justify-content-md-end align-items-center">
                        <div class="text-dark-8 text-s-m mb-2 mb-md-0 mr-md-2" v-if="isSubEnabled(sub) && (isPaidSubscription(sub) || isTrialSubscription(sub))">
                          Valid until
                          <span class="color-danger"
                          >{{  dateFormated(getSubscriptionAge(sub), 'DD MMM, YYYY') }}</span>
                        </div>
                        <div v-if="isBundledSub(sub)">
                          <b-badge variant="dark-3" class="text-500">Bundled</b-badge>
                        </div>
                        <div class="w-100-mobile" v-else>
                          <!-- <b-button :variant="isSubEnabled(sub) ? 'link' : 'danger'" size="sm"> -->
                          <b-button :to="subscriptionManagePage(null, sub.category, sub, isSubEnabled(sub))" :variant="isPaidSubscription(sub) ? 'secondary' : 'primary'" size="sm" v-if="isSubEnabled(sub) && getSubCategoryName(sub) != 'sub-accounts'">
                            <span>{{isPaidSubscription(sub) ? 'Change' : 'Upgrade'}}</span>
                          </b-button>
                          <b-button :to="{ path: '/subscribe' }" :variant="isPaidSubscription(sub) ? 'secondary' : 'primary'" size="sm" v-else-if="isSubEnabled(sub) && getSubCategoryName(sub) == 'sub-accounts'">
                            <span>{{isPaidSubscription(sub) ? 'Change' : 'Upgrade'}}</span>
                          </b-button>
                         <div class="d-flex align-items-center justify-content-end" v-else>
                        <div class="text-dark-8 text-s-m mb-2 mb-md-0 mr-4 mr-md-3">
                        Expired
                          <span class="color-danger">{{ dateFormated(getSubscriptionAge(sub), 'DD MMM, YYYY') }}</span>
                          </div>
                          <b-button :to="{ path: '/subscribe' }" :variant="isPaidSubscription() ? 'secondary' : 'primary'" size="sm">
                            <span>Renew</span>
                          </b-button>
                      </div>

                          <!-- <b-button
                            v-if="isSubEnabled(sub)"
                            variant="link"
                            size="sm"
                            onclick="Intercom('show')"
                          >CANCEL</b-button>&nbsp; -->
                          <span v-if="isPaidSubscription(sub) && isSubEnabled(sub)" class="ml-1">
                            <b-button v-if="hasUnsubscribed(sub)"
                              variant="outline-secondary"
                              disabled
                              size="sm">Unsubscribed</b-button>
                            <b-button
                              v-else
                              variant="outline-secondary"
                              size="sm"
                              :disabled="sub.unsubscribeProcessing"
                              @click="requestUnsubscribe(sub)">{{sub.unsubscribeProcessing ? 'Please wait ..' : 'Cancel'}}</b-button>
                          </span>
                        </div>
                      </div>
                      
                    </b-col>
                  </b-row>
                  <hr class="my-3">
                  <div class="sub-usage-container">
                    <b-row>
                      <b-col md="6">
                        <div class="text-dark-9 text-500 mb-3">
                          Usage Quota
                        </div>
                        <div class="sub-usage">
                          <div v-for="(usageProp, index) in usageProps" :key="index">
                            <div v-if="hasUsageProp(usageProp, sub)" class="item-usage-row mb-2">
                              <b-row v-if="(getSubCategoryName(sub) !== 'Live Streaming') || (usageProp.name !== 'concurrent viewers')">
                                <b-col cols="3">
                                  <label class="item-name text-s-m text-dark-8 text-capitalize">{{usageProp.name || usageProp}}</label>
                                </b-col>
                                <b-col>
                                  <span class="item-usage text-s-m">
                                    <span v-if="usageProp.nousage">
                                      <span class="value">{{getAllowedPropUsage(usageProp, sub)}} </span>
                                      
                                      <span v-if="usageProp.unitMap">{{usageProp.unitMap(sub)}} </span>
                                      <span v-else-if="usageProp.unit">{{usageProp.unit}} </span>
                                      
                                      assigned
                                    </span>
                                    <span v-else>
                                      <span class="value">{{getPropUsage(usageProp, sub)}}</span> used from
                                      <span
                                        class="value">{{getAllowedPropUsage(usageProp, sub)}}</span>
                                    </span>
                                  </span>
                                </b-col>
                              </b-row>
                              <div v-if="usageProp.explicit" class="item-usage-explicit">
                                <b-row>
                                  <b-col offset="3">
                                    <div class="explicit-usage-wrapper">
                                      <b-card class="d-inline-flex text-center" bg-variant="dark-3" body-class="text-s-m py-2 px-2">
                                          <i v-if="loadingUsages && !hasExplicitUsageProp(usageProp, sub)" class="fas fa-spinner fa-spin"></i>
                                          <span class="text-500" v-else>
                                          {{ getExplicitUsageProp(usageProp, sub).value }}
                                            <span class="text-uppercase text-500">{{getExplicitUsageProp(usageProp, sub).unit}}</span>
                                          </span>
                                        <div class="text-dark-8 text-s-s">Usage</div>
                                      </b-card>
                                      <b-card class="d-inline-flex text-center ml-2" bg-variant="dark-3" body-class="py-2 px-2 text-s-m">         
                                          <span class="text-500">{{getAllowedPropUsage(usageProp, sub)}} </span>          
                                          <span v-if="usageProp.unitMap" class="text-500">{{usageProp.unitMap(sub)}}</span>
                                          <span v-else-if="usageProp.unit" class="text-500">{{usageProp.unit}}</span>
                                          <!-- {{usageProp.unitMap ? usageProp.unitMap(sub) : usageProp.unit}} -->
                                        <div class="text-dark-8 text-s-s">Assigned</div>
                                      </b-card>
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </div>
            <!-- {{allSubscriptions}}{{getSubscriptionFee()}}/{{ isAnnualPackage() ? 'year' : 'month' }} -->
           
            <b-row align-h="center" v-if="showActivesub && !hasactiveAddonsubscription">
              <b-col md="5">
                <b-card bg-variant="transparent" class="mb-5 bordered text-center">
                  <p class="text-center mb-1 text-500 text-dark-9 text-s-xl">It’s empty here :(</p>
                   <b-link to="/subscribe?category=live" class="text-dark-7 text-s-s text-underline mb-0">Checkout Plans</b-link>
                </b-card>
              </b-col>
            </b-row>
            <!-- <b-row v-if="showActivesub && !hasactiveAddonsubscription || showActivesub && !hasactiveAddonsubscription && packagesToUpdate.length === 0"> -->
              <!-- <b-col md="4">
                <b-card class="mb-4 mb-lg-0" bg-variant="dark-2">
                  <h3 class="text-s-xl text-500 d-flex align-items-center">
                    <svg class="text-main-1 mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 5V12M12 12H17C18.1046 12 19 12.8954 19 14V19M12 12H7C5.89543 12 5 12.8954 5 14V19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <circle cx="12" cy="5" r="2.5" fill="currentColor"/>
                      <circle cx="19" cy="19" r="2.5" fill="currentColor"/>
                      <circle cx="5" cy="19" r="2.5" fill="currentColor"/>
                    </svg>
                    Multistream
                  </h3>
                  <p class="text-dark-8 text-s-m">Perfect for streaming to multiple social platforms such as Facebook and Youtube.</p>
                  <hr>
                  <div class="d-flex justify-content-between mb-3">
                    <span class="text-s-l text-500 text-dark-8">Plus</span>
                    <span class="text-right">
                      <span class="text-s-xxl text-dark-9">$12.50</span>
                      <span class="text-s-m text-dark-6">/month</span>
                      <div class="text-s-m text-dark-6">Billed as $149.99 yearly</div>
                    </span>
                  </div>
                  <div class="text-center">
                    <b-button variant="primary" class="w-100 mb-3" to="/dashboard?action=restream">Try 7 days free</b-button>
                    <b-link to="/subscribe?category=live" class="text-dark-6 text-underline">Subscribe</b-link>
                  </div>
                </b-card>
              </b-col> -->
              <!-- <b-col md="4">
                <b-card class="mb-4 mb-lg-0" bg-variant="dark-2">
                  <h3 class="text-s-xl text-500 d-flex align-items-center">
                    <svg class="text-main-1 mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.8711 7.80409L15.951 10.5981L15.951 10.5981C16.7821 11.0551 17.1976 11.2837 17.3351 11.5857C17.4549 11.8489 17.4549 12.1511 17.3351 12.4143C17.1976 12.7163 16.7821 12.9449 15.951 13.4019L15.951 13.4019L10.8711 16.1959C10.0905 16.6252 9.70023 16.8399 9.38103 16.8016C9.10251 16.7683 8.85081 16.6194 8.68734 16.3915C8.5 16.1302 8.5 15.6848 8.5 14.794V9.20603C8.5 8.3152 8.5 7.86979 8.68734 7.60853C8.85081 7.38057 9.10251 7.23175 9.38103 7.19837C9.70023 7.16013 10.0905 7.37478 10.8711 7.80409Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5"/>
                    </svg>
                    Starter
                  </h3>
                  
                  <hr>
                  <div class="d-flex justify-content-between mb-3">
                 
                    <span class="text-right">
                      <span class="text-s-xxl text-dark-9">$12.50</span>
                      <span class="text-s-m text-dark-6">/month</span>
                      <div class="text-s-m text-dark-6">Billed as $149.99 yearly</div>
                    </span>
                  </div>
                  <div class="text-center">
                    <b-button variant="primary" class="w-100 mb-3" to="/dashboard?action=live">Try 7 days free</b-button>
                    <b-link to="/subscribe?category=live" class="text-dark-6 text-underline">Subscribe</b-link>
                  </div>
                </b-card>
              </b-col> -->
              <!-- <b-col md="4">
                <b-card bg-variant="dark-2">
                  <h3 class="text-s-xl text-500 d-flex align-items-center">
                    Need something else?
                  </h3>
                  <p class="text-dark-8 text-s-m">Check out the Upgrade page for completed list of plans and features offered.</p>
                  <div class="text-center">
                    <b-button variant="primary" class="w-100 mb-3" to="/subscribe">Check it out</b-button>
                  </div>
                </b-card>
              </b-col> -->
            <!-- </b-row> -->
            <!-- <b-row :class="packagesToUpdate.length == 1 ? 'col-lg-7 mx-auto':''" align-v="stretch" v-if="showActivesub && !hasactiveAddonsubscription && packagesToUpdate.length > 0">
            <template v-for="item in packagesToUpdate">
              <b-col class="mb-4 mb-lg-0 px-1" cols="12" md="6" lg>
                <b-card no-body bg-variant="dark-2" class="h-100">
                  <div class="bg-dark-3 card-top px-2 py-3">
                    <h3 v-if="item.category === 'live'" class="text-s-xxl text-500 px-2">
                      {{item.name.replace('-','').replace('Yearly','')}}
                    </h3>
                    <h2 v-if="item.category === 'restream'" class="text-s-s text-500 text-dark-6 px-2">
                      Multistream
                    </h2>
                    <h3 v-if="item.category === 'restream'" class="text-s-xl text-500 px-2">
                      {{item.name.split('Multistream').pop()}}
                    </h3>
                    <p class="px-2"><span class="price">${{item.baseCharge}}</span><span class="text-s-s text-dark-8 font-weight-normal">/ {{item.cycleAge == 30 ? 'month' : 'year'}}</span></p>
                    <div class="text-center">
                      <b-button variant="primary" class="w-100" :to="`/subscribe?package=${item._id}`">
                        Subscribe<span class="ml-1" v-if="(allSubscriptions.length != 0) && (item.baseCharge === lastExpired.package.baseCharge)">Again</span>
                      </b-button>
                    </div>
                  </div>
                  <ul class="text-dark-9 list-unstyled px-2 py-3 m-0 text-s-m">
                    <li class="d-flex items-center mb-2">
                      <svg v-b-tooltip.hover title="The number of streams happening at the same time" data-v-3503005c="" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-dark-5 mr-2 mt-1 info-icon flex-shrink-0"><path data-v-3503005c="" fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="currentColor"></path></svg>
                      {{showConcurrentDescription(item._id)}} concurrent streams
                    </li>
                    <li class="d-flex items-center mb-2">
                      <svg v-b-tooltip.hover title="Extra destination means Facebook Page, Facebook Group, custom RTMP channel, and any second or more destination" data-v-3503005c="" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-dark-5 mr-2 mt-1 info-icon flex-shrink-0"><path data-v-3503005c="" fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="currentColor"></path></svg>
                      {{showDestinationDescription(item._id)}} Multistream destinations
                    </li>
                    <li v-if="item.category === 'live'" class="d-flex items-center mb-2">
                      <svg v-b-tooltip.hover title="Amount of bandwidth available for live and video hosting player" data-v-3503005c="" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-dark-5 mr-2 mt-1 info-icon flex-shrink-0"><path data-v-3503005c="" fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="currentColor"></path></svg>
                      {{showBandwidth(item._id)}} 
                    </li>
                    <li class="d-flex items-center mb-2">
                      <svg v-b-tooltip.hover title="Broadcast pre-recorded videos as livestreams" data-v-3503005c="" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-dark-5 mr-2 mt-1 info-icon flex-shrink-0"><path data-v-3503005c="" fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="currentColor"></path></svg>
                      {{showSpaceDescription(item._id)}} Video Storage
                    </li>
                  </ul>
                </b-card>
              </b-col>
            </template>
              <b-col col>
                <b-card bg-variant="dark-2">
                  <h3 class="text-s-xxl text-500 d-flex align-items-center">
                    Need something else?
                  </h3>
                  <p class="text-dark-8 text-s-m">Check out the Upgrade page for completed list of plans and features offered.</p>
                  <div class="text-center">
                    <b-button variant="primary" class="w-100" to="/subscribe">Check it out</b-button>
                  </div>
                </b-card>
              </b-col>
            </b-row> -->
            
            <b-row align-h="center" v-if="!showActivesub  && !hasexpiredAddonsubscription">
              <b-col md="5">
                <b-card bg-variant="transparent" class="mb-5 bordered">
                  <p class="text-center mb-2 text-500 text-dark-9 text-s-xl">It’s empty here :(</p>
                  <p class="text-dark-6 text-center mb-0">You don’t have any expired subscription plan</p>
                </b-card>
              </b-col>
            </b-row>
            <div v-if="invoiceItems.length > 0" class="mt-5">
              <h2 class="page-title">Payments History</h2>
              <div class="user-sub-item">
                <div>
                  <b-table responsive :fields="invoiceFields" :items="invoiceItems" class="main-table" thead-class="text-dark-6">
                    <template v-slot:cell(invoice)="data">
                      <b-link :href="data.value" :target="'_blank'">
                        <b-button variant="dark-5" class="px-0" size="sm">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.99994 2V11M7.99994 11L11.5 7.5M7.99994 11L4.5 7.5M3 14H13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </b-button>
                      </b-link>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>

        <subscription-cancel-modal 
           modal-id="modal-unsubscribe-package"
          :message="configurableSubscription ? 'Are you sure you want to unsubscribe from \''+ getSubscriptionName(configurableSubscription, true) +'\' package?' : ''"
          @modal-confirm="unsubscribeSubCategory">
        </subscription-cancel-modal>
        <alert-modal
          modal-id="modal-change-payment-info-failed"
          buttonAlignment="text-right"
          message="We only support to change credit card details for active account at the moment. If you are using Paypal or other payment type, please contact our support for help."
          ok-text="Got it"
        />
      </div>
    </div>
    <div v-else>
      <b-row class="mt-5" align-h="center">
        <b-col md="5">
          <h3 class="text-s-xl text-center text-dark-9 mt-md-5">Moderators don't have access to the Billing page.</h3>
          <p class="text-dark-6 text-center text-s-m">Only the team owner and admins can access this page. Contact your team owner (<a class="text-reset text-underline" :href="'mailto:'+ this.$store.state.User.guestData.ownersEmail">{{this.$store.state.User.guestData.ownersEmail}}</a>) for more information.</p>
          <p class="text-center">
            <b-button size="md" variant="primary" to="/">
              Go to Livestreams
            </b-button>
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import AlertModal from '@/components/modals/AlertModal.vue';
import UserService from '@/services/UserService'
import SubscriptionService from '@/services/SubscriptionService'
import MetricsService from '@/services/MetricsService'
import SubscriptionCancelModal from '@/components/modals/SubscriptionCancelModal'
import Spinner from "@/components/ui/Spinner.vue";
import { mapGetters } from 'vuex'
import { date, bytes } from "@/filtersNew"

export default {
  name: 'ManageBilling',
  components: {
		Spinner,
    AlertModal,
    SubscriptionCancelModal
	},
  data() {
    var self = this
    return {
      // processing: true,
      isBillingAccess: false,
      usageProps: [
        { name: 'streams', key: 'streams', nousage: true },
        {
          name: 'phone alerts',
          key: 'phoneAlerts',
          nousage: true,
          unit: 'SMS',
          unitMap: (sub) => {
            return 'SMS'
          },
          mapFn: gb => {
            let val = gb
            return val
          },
          explicit: true
        },
        {
          name: 'bandwidth',
          key: 'bandwidth',
          nousage: true,
          unit: 'TB',
          unitMap: (sub) => {
            let mappedUnit = 'TB'
            let allocatedVal = this.getSubscriptionDefProp(sub, 'bandwidth')

            if (allocatedVal < 1) {
              mappedUnit = 'MB'
            } else if (allocatedVal < 1000) {
              mappedUnit = 'GB'
            }

            return mappedUnit
          },
          mapFn: gb => {
            let val = gb

            if (gb < 1) {
              val *= 1000
            } else if (gb >= 1000) {
              val = (gb / 1000).toFixed(3)
            }

            // let val = (gb/1000).toFixed(3)
            return val
          },
          explicit: true
        },
        {
          name: 'storage',
          key: 'storage',
          nousage: true,
          unit: 'GB',
          unitMap: (sub) => {
            let mappedUnit = 'GB'
            let allocatedVal = this.getSubscriptionDefProp(sub, 'storage')

            if (allocatedVal < 1) {
              mappedUnit = 'MB'
            }

            return mappedUnit
          },
          mapFn: gb => {
            let val = gb

            if (gb < 1) {
              val *= 1000
            }

            return val
          },
          explicit: true
        },
        {
          name: 'concurrent viewers',
          key: 'maxConcurrentUsers',
          nousage: true,
          unit: 'Conns'
        },
        // { name: 'dvr', key: 'dvrUnits', nousage: true, unit: 'GB' },
        { name: 'storage mins', key: 'encodingUnits', nousage: true }
      ],
      packages: [],
      // processing: true,
      configurableSubscription: null,
      paymentMethod: null,
      userInformation: null,
      basicPackage: null,
      superiorPackage: null,
      userSubscription: null,
      showActivesub: true,
      hasactiveAddonsubscription: false,
      hasexpiredAddonsubscription: false,
      getCycleEnd(cstart) {
        const cend = new Date(cstart)
        cend.setMonth(cend.getMonth() + 1)

        return cend.toString()
      },
      subscriptionManagePage(action, category, pid, subenabled) {
        let suffix = '?'
        if (action === 'cancel') {
          const freePack = this.basicPackage
          suffix += (freePack && `package=${freePack._id}&`) || ''
        } else if (action === 'upgrade') {
          if (category === 'restream') {
            const supPack = this.superiorPackage
            suffix += (supPack && `package=${supPack._id}&`) || ''
          } else {
            // suffix += 'action=upgrade&'
            suffix += ''
          }
        } else if (action) {
          suffix += `action=${action}&`
        }
        
        if (category) {
          if (category === 'Scheduled') { category = 'Restream' } else if (category === 'VOD') { category = 'Live' }
            if (category === 'scheduled') { 
              category = 'restream' 
              suffix += `category=${category}`
            } else if (category === 'ipcam') { 
              category = 'restream' 
              suffix += `category=${category}`
            } else if (category === 'vod') { 
              category = 'live' 
              suffix += `category=${category}`
            }else if (category === 'restream' || 'live'){
                (pid && !subenabled) ? suffix += `package=${pid.package._id}` : suffix += `category=${category}`
            }
        }

        if(self.isTrialSubscription(pid)){
          suffix = `?category=${category}`
          // suffix = `?category=${category}`
        }

        return '/subscribe?category=live '
        // return '/subscribe?category=live '
        // return '/subscribe' + suffix
      },
      invoiceItems: [],
      invoiceFields: [
        'date',
        'products',
        'amount',
        { key: 'invoice', label: 'Invoice' }
      ],
      baseSubPhoneAlerts: 0,
      loadingUsages: true
    }
  },
  watch:{
    activeTeam(newValue, oldValue){
      this.setUser();
    },
    'userSubscription.addonSubscriptions'() {
    }
  },
  async mounted() {
    this.$store.dispatch('Ui/setLoadingStatus', true);
    await this.setUser();
    if (!this.isTeamModerator) {
      await this.loadSubscription(this.userInformation.activeTeam)
    } else this.$store.dispatch('Ui/setLoadingStatus', false);
    window.trackEvent(`Billing Details`)
  },
  computed: {
    ...mapGetters({
      isTeamModerator: 'User/isTeamModerator',
      loadingStatus: "Ui/loadingStatus",
      baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions'
    }),
    sortAddonsubscription() {
      return _.orderBy(this.userSubscription.addonSubscriptions, o => {
        return o.cend
      }, this.showActivesub ? 'asc' : 'desc')
    },
    allSubscriptions() {
      let array = this.userSubscription.addonSubscriptions.concat()
      if (array.length > 0 && this.userSubscription.subscription) {
        array.push(this.userSubscription.subscription)
      }
      if (array.find(item => (item.enabled))) {
        return []
      } else return array.filter(item => (item.package.category == 'live' || item.package.category == 'restream'))
      // return array.filter(item => (item.package.category == 'live' || item.package.category == 'restream'))
      // return array.find(item => (item.enabled))
    },
    lastExpired() {
      if (this.allSubscriptions.length > 0) {
        return this.allSubscriptions.reduce((a, b) => a.cend > b.cend ? a : b)
      } else return null
      // let lastPackage = this.allSubscriptions.find(item => (new Date(item.cend) === last))
      // let last = new Date(Math.max(...this.allSubscriptions.map(e => new Date(e.cend))))
    },
    packagesToUpdate() {
      // console.log('------');
      // console.log(this.lastExpired.package.baseCharge);
      // console.log(this.lastExpired);
      if(!this.lastExpired){
        return this.packages.filter(item => (item.cycleAge == 365))
      }
      const planType = this.lastExpired.package.cycleAge <= 30 ? 30 : 365
      if (this.lastExpired && this.lastExpired.package.category == 'restream') {
        return this.packages.filter(item => (item.cycleAge == planType && item.baseCharge >= this.lastExpired.package.baseCharge))
      } else if (this.lastExpired && this.lastExpired.package.category == 'live') {
           if((this.lastExpired.package.baseCharge == 6000)){
          return this.packages.filter(item => (item.cycleAge == planType && item.baseCharge >= 3000))
        }
        
        if((this.lastExpired.package.baseCharge == 620) || (this.lastExpired.package.baseCharge == 320)){
          return this.packages.filter(item => (item.cycleAge == planType && item.baseCharge >= 298))
        }
     
        return this.packages.filter(item => (item.cycleAge == planType && item.baseCharge >= this.lastExpired.package.baseCharge))
      } else {
        // return []
        return this.packages.filter(item => (item.cycleAge == planType))
      }
    },
    activeTeam(){
			return this.$store.state.User.activatedTeam;
		}
  },
  methods: {
   		async setUser(){
        let userInfo = await UserService.getUserInfo();
        if(!userInfo.activeTeam){
          this.userInformation = userInfo;
        }else{
          let owneruserInfo  = await UserService.getTeamOwner(userInfo.activeTeam)
          owneruserInfo.owner._id = userInfo._id;
          owneruserInfo.owner.activeTeam = userInfo.activeTeam;
          this.userInformation = owneruserInfo.owner;
        }
        // this.loadSubscription(this.userInformation.activeTeam);
		},
    async loadSubscription(team){
      try {
            const packagesList = await SubscriptionService.getSubscriptionPackages()
            const packagesListnew = await SubscriptionService.getnewSubscriptionPackages()
            this.packages = packagesList
            // this.packages = packagesList
            // get user subscription
            const baseSubscription = _.cloneDeep(this.baseSubscription)
            const addonSubscriptions = _.cloneDeep(this.addonSubscriptions)
            const usersub = { subscription: baseSubscription, addonSubscriptions: addonSubscriptions};
            if (usersub.subscription) {
              usersub.subscription.unsubscribeProcessing = false
            }

            if (_.get(usersub, ['subscription', 'package', 'definition', 'phoneAlerts'], false)) {
              // restream phone alerts sub
              try {
                const res = await MetricsService.getSubscriptionNotificationCount('restream')
                this.baseSubPhoneAlerts = res ? res.sms : 0
              } catch (e) { console.log('e', e) }
            }

            this.isBillingAccess = _.isBoolean(this.userInformation.isBillingAccess) ? this.userInformation.isBillingAccess : true

            if (this.isBillingAccess) {
              this.$store.dispatch('Ui/setLoadingStatus', false);
            }

            usersub.addonSubscriptions = _.map(usersub.addonSubscriptions, (addonSub) => {
              return _.assign(addonSub, {
                unsubscribeProcessing: false,
                usage: {
                // bandwidth: null
                }})
            })
            usersub.addonSubscriptions = _.orderBy(usersub.addonSubscriptions, ['category', 'parentPackage'], ['asc', 'desc'])
            this.userSubscription = usersub
            try {
              let rawInvoices = await SubscriptionService.getUserInvoicePayments()
              const paidInvoices = _.filter(rawInvoices, item => {
                return _.isEmpty(_.get(item, 'nested', 'invoice-payments-list'), false) === false
              })

              let payments = []
              _.each(paidInvoices, (invoice, idx) => {
                const invoiceTitle = _.get(invoice, ['nested', 'invoice-items', '0', 'item_title'], 'N/A')
                const paymentsList = _.get(invoice, ['nested', 'invoice-payments-list'], [])
                payments.push(_.map(paymentsList, elem => {
                  let pdfLink = elem['pdf-invoice-link']
                  if (elem.paysys_id === 'fastspring-contextual' && _.startsWith(elem.transaction_id, 'CASTR')) {
                    pdfLink = `https://castrhq.onfastspring.com/account/order/${elem.transaction_id}/invoice/pdf`
                  }

                  return {
                    date: elem.dattm,
                    products: invoiceTitle,
                    amount: `$${elem.amount}`,
                    invoice: pdfLink
                  }
                }))
              })

              payments = _.flatten(payments)
              this.invoiceItems = _.orderBy(payments, 'date', 'desc')
            } catch (e) { console.log('e', e) }


            if (usersub.subscription) {
              const isPaid = this.isPaidSubscription()
              const packages = await SubscriptionService.getSubscriptionPackages()
              if (!isPaid) {
                const starterPack = _.chain(packages)
                  .filter(p => p.baseCharge > 0)
                  .sortBy(p => p.baseCharge)
                  .head()
                  .value()
                if (starterPack) this.superiorPackage = starterPack
              } else {
                const basicPack = _.find(packages, { baseCharge: 0 })
                if (basicPack) this.basicPackage = basicPack
              }
            }

            await Promise.all(this.userSubscription.addonSubscriptions.map(async (addonSub) => {
              const phoneAlerts = this.getSubscriptionDefProp(addonSub, 'phoneAlerts')
              if (phoneAlerts) {
                let sms = 0
                try {
                  let res = await MetricsService.getSubscriptionNotificationCount()
                  sms = res ? res.sms : 0
                } catch (e) { console.log('e', e) }

                addonSub.usage = _.assign({}, addonSub.usage, {
                  phoneAlerts: { value: sms, unit: 'SMS' }
                })
              }
              
              // const bwLimit =  _.get(addonSub, 'package.definition.bandwidth')
              const bwLimit = this.getSubscriptionDefProp(addonSub, 'bandwidth')
              if (bwLimit) {
                // let bytes = '-'
                let currentBytes = 0
                // let originBytes = 0
                try {
                  let res = await MetricsService.getSubscriptionBandwidth(UserService.getUserId(this.activeTeam), addonSub.package._id)
                  currentBytes = res ? res.bytes : 0
                  currentBytes =  currentBytes >= 100000000 ? currentBytes : 0; // only show bandwidth > 100MB

                  // const originBandwidth = await OriginService.getUserOriginBandwidth()
                  // originBytes = originBandwidth.bytes

                  // TODO: remove this
                  let userInfo = await UserService.getUserInfo();
                  if (userInfo._id === '65a508f3e2026e6faf40dfdc') {
                    currentBytes = currentBytes + 1024 * 1024 * 1024 * 1024 * 29
                  }

                } catch (e) { console.log('e', e) }

                addonSub.usage = _.assign({}, addonSub.usage, {
                  bandwidth: bytes(currentBytes, true, 3, true)
                })
              }

              const storageLimit = this.getSubscriptionDefProp(addonSub, 'storage')
              if (storageLimit) {
                // let bytes = '-'
                let currentBytes = 0
                try {
                  let res = await MetricsService.getSubscriptionStorage(UserService.getUserId(this.activeTeam), addonSub.package._id)
                  currentBytes = res ? res.bytes : 0;

                  if(addonSub.category === 'vod' || addonSub.category === 'live') {
                    let res2 = await MetricsService.getUserDvrStorage();
                    currentBytes += res2 ? res2.bytes : 0;
                  }

                } catch (e) { console.log('e', e) }

                addonSub.usage = _.assign({}, addonSub.usage, {
                  storage: bytes(currentBytes, true, 3, true)
                })
              }
              return addonSub
            }))
            this.loadingUsages = false
            // track event
          } catch (e) {
            // window.alert('error: ' + e.message)
            console.log('error', e)
          }
    },
    hasExplicitUsageProp (usageProp, baseSub) {
      let prop = usageProp.key || usageProp
      return prop in baseSub.usage
    },
    getExplicitUsageProp (usageProp, baseSub) {
      let prop = usageProp.key || usageProp
      return baseSub.usage[prop]
    },
    isAnnualPackage (sub) {
      sub = sub || this.userSubscription.subscription
      return /yearly/i.test(sub.package.name)
    },
    hasUsageProp(usageProp, baseSub) {
      let prop = usageProp.key || usageProp
      let propValue = this.getSubscriptionDefProp(baseSub, prop)

      return !!propValue
    },
    getAllowedPropUsage(usageProp, baseSub) {
      let prop = usageProp.key || usageProp
      // let value = _.get(baseSub, "package.definition." + prop);
      // if (baseSub.category === 'live') {
      //   console.log('baseSub', baseSub, usageProp)
      // }
      let value = this.getSubscriptionDefProp(baseSub, prop)
      return usageProp.mapFn ? usageProp.mapFn(value) : value
    },
    getPropUsage(usageProp, baseSub) {
      // let prop = usageProp.key || usageProp
      return 1
    },
    getSubscriptionDefProp (sub, prop) {
      return _.get(sub, 'definitionOverride.' + prop) || _.get(sub, 'package.definition.' + prop)
    },
    isBundledSub(sub) {
      sub = sub || this.userSubscription.subscription
      return sub.parentPackage
    },
    getSubCategoryName (sub) {
      let mappedCat = sub.category
      switch (mappedCat) {
        case 'live':
          mappedCat = 'Live Streaming'
          break
        case 'ipcam':
          mappedCat = 'IP Camera Restreaming'
          break
        case 'vod':
          mappedCat = 'Video Hosting'
          break
        case 'scheduled':
          mappedCat = 'Scheduler'
          break
      }

      return mappedCat
    },
    isSubEnabled(sub) {
      if(sub){
        sub.enabled ? (this.hasactiveAddonsubscription = true) : (this.hasexpiredAddonsubscription = true)
      }else{
        this.userSubscription.subscription.enabled ? (this.hasactiveAddonsubscription = true) : (this.hasexpiredAddonsubscription = true)
      }
      sub = sub || this.userSubscription.subscription
      return sub.enabled
    },
    getSubscriptionName(sub, formatted) {
      sub = sub || this.userSubscription.subscription
      if (!sub) return
      let packname = sub.package.name
      packname = formatted ? _.startCase(packname) : packname
      return packname
    },
    getSubscriptionAge(sub) {
      sub = sub || this.userSubscription.subscription
      if (!sub) return
      return sub.cend
    },
    isPaidSubscription(sub) {
      return this.getSubscriptionFee(sub) > 0
    },
    isTrialSubscription(sub) {
      sub = sub || this.userSubscription.subscription
      let packname = sub.package.name
      return packname && /trial/gi.test(packname)
    },
    getSubscriptionFee(sub) {
      sub = sub || this.userSubscription.subscription
      return sub && sub.package.baseCharge
    },
    hasAddonSubscriptions() {
      return _.size(this.userSubscription.addonSubscriptions) > 0
    },
    hasUnsubscribed(sub) {
      sub = sub || this.userSubscription.subscription
      return sub.unsubscribed
    },
    async requestUnsubscribe (sub) {
      sub = sub || this.userSubscription.subscription
      this.configurableSubscription = sub
      this.$root.$emit('bv::show::modal', 'modal-unsubscribe-package')
    },
    async unsubscribeSubCategory (cancelObj) {
      let packCategory = this.configurableSubscription.category || 'restream'

      try {
        this.configurableSubscription.unsubscribeProcessing = true
        await SubscriptionService.unsubscribeSubscription(packCategory, cancelObj.reason, cancelObj.comment)
        this.configurableSubscription.unsubscribed = true
        this.configurableSubscription.unsubscribeProcessing = false

        this.$notify({ group: 'success', text: `unsubscribed ${packCategory} package successfully` })
      } catch (e) {
        this.$notify({ group: 'error', text: `failed to unsubscribe ${packCategory} package` })
      }
    },
    async requestChangePaymentInfo () {
      let result
      try {
        result = await SubscriptionService.changePaymentInfo()
      } catch (e) {
        this.$notify({ group: 'error', text: `Failed to request change payment info` })
      }

      if (result && result.ok === false && !result.link) {
        this.$root.$emit('bv::show::modal', 'modal-change-payment-info-failed')
      }
      if (result && result.link) {
        window.location.href = result.link
      }
    },
    comparePackages(){
      this.allSubscriptions.map((s)=>{
        let amount = this.getSubscriptionFee(s);
        let yearly = this.isAnnualPackage(s);
      //         const planType = this.lastExpired.package.cycleAge <= 30 ? 30 : 365
      // if (this.lastExpired && this.lastExpired.package.baseCharge <= 1099.99 && this.lastExpired.package.category == 'restream') {
      //   return this.packages.filter(item => (item.category == this.lastExpired.package.category && item.cycleAge == planType && item.baseCharge >= this.lastExpired.package.baseCharge)).slice(0, 4)
      // } else if (this.lastExpired && this.lastExpired.package.baseCharge <= 6000 && this.lastExpired.package.category == 'live') {
      //   return this.packages.filter(item => (item.category == this.lastExpired.package.category && item.cycleAge == planType && item.baseCharge >= this.lastExpired.package.baseCharge)).slice(0, 4)
      // } else {
      //   return this.packages.filter(item => (item.category == this.lastExpired.package.category && item.cycleAge == planType)).slice(0, 4)
      // }
        // console.log('========////');
        // console.log(this.allSubscriptions);
        // console.log(this.packages);
      });
    },
    showConcurrentDescription(id) {
      switch(id) {
        case '6434ffbd40771d569f1e98e6' :
        return 2
        case '6435032f40771d569f1e98f2' :
        return 3
        case '6435001640771d569f1e98e8' :
        return 2
        case '6435035f40771d569f1e98f4' :
        return 3
        case '6435007440771d569f1e98ea' :
        return 3
        case '643503a640771d569f1e98f6' :
        return 10
        case '6435028a40771d569f1e98ee' :
        return 3
        case '6435041040771d569f1e98f8' :
        return 10
        case '643502dd40771d569f1e98f0' :
        return 3
        case '6435044340771d569f1e98fa' :
        return 15
        default: 0
      }
    },
    showDestinationDescription(id) {
      switch(id) {
         case '6434ffbd40771d569f1e98e6' :
        return 6
        case '6435032f40771d569f1e98f2' :
        return 6
        case '6435001640771d569f1e98e8' :
        return 10
        case '6435035f40771d569f1e98f4' :
        return 10
        case '6435007440771d569f1e98ea' :
        return 15
        case '643503a640771d569f1e98f6' :
        return 15
        case '6435028a40771d569f1e98ee' :
        return 20
        case '6435041040771d569f1e98f8' :
        return 20
        case '643502dd40771d569f1e98f0' :
        return 30
        case '6435044340771d569f1e98fa' :
        return 30
        default: 0
      }
    },
    showSpaceDescription(id) {
      switch(id) {
       case '6434ffbd40771d569f1e98e6' :
        return '100 GB'
        case '6435032f40771d569f1e98f2' :
        return '100 GB'
        case '6435001640771d569f1e98e8' :
        return '200 GB'
        case '6435035f40771d569f1e98f4' :
        return '100 GB'
        case '6435007440771d569f1e98ea' :
        return '1 TB'
        case '643503a640771d569f1e98f6' :
        return '1 TB'
        case '6435028a40771d569f1e98ee' :
        return '2 TB'
        case '6435041040771d569f1e98f8' :
        return '2 TB'
        case '643502dd40771d569f1e98f0' :
        return '3 TB'
        case '6435044340771d569f1e98fa' :
        return '3 TB'
        default: 0
      }
    },
    showBandwidth(id) {
      switch(id) {
       case '6434ffbd40771d569f1e98e6' :
        return '200 GB player bandwidth'
        case '6435032f40771d569f1e98f2' :
        return '2.4 TB upfront bandwidth'
        case '6435001640771d569f1e98e8' :
        return '500 GB player bandwidth'
        case '6435035f40771d569f1e98f4' :
        return '6 TB upfront bandwidth'
        case '6435007440771d569f1e98ea' :
        return '2 TB player bandwidth'
        case '643503a640771d569f1e98f6' :
        return '24 TB upfront bandwidth'
        case '6435028a40771d569f1e98ee' :
        return '3 TB player bandwidth'
        case '6435041040771d569f1e98f8' :
        return '36 TB upfront bandwidth'
        case '643502dd40771d569f1e98f0' :
        return '5 TB player bandwidth'
        case '6435044340771d569f1e98fa' :
        return '60 TB upfront bandwidth'
        default: 0
      }
    },
    dateFormated(value, format) {
      return date(value, format)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* >>>>>>> staging */
.user-sub-item {
  border-radius: 6px;
}
.card {
  min-width: 100px;
}
.susbscription-status {
    background: var(--c-dark-3);
    display: inline-flex;
    padding: 3px;
    border-radius: 4px;
    margin-bottom: 32px;
}

.susbscription-status li {
    list-style: none;
    color: var(--c-dark-9);
    font-size: var(--font-s-m);
    padding: 4px 15px;
    cursor: pointer;
    border-radius: 4px;
}
.susbscription-status li.active {
    background: var(--c-dark-5);
    font-weight: 500;
    color: #fff;
}
@media (max-width: 576px) {
  .card {
  min-width: 80px;
}
.w-100-mobile {
  width: 100%;
}
}
.card-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.price {
  font-size: 22px;
}
</style>
