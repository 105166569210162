<script setup>
import { ref } from 'vue'

const props = defineProps({
	title: {
		type: String,
		required: false,
		default: ''
	},
	editable: {
		default: false
	},
	replaceInnerText: {
		type: Boolean,
		default: false
	},
	titleClasses: {
		type: String,
		default: 'font-medium m-[0px] text-base rounded'
	}
})

const emit = defineEmits({
  'changed': (value) => { return value },
  'edit': (value) => { return value }
})

const titleWrapper = ref(null)
const allowEditTitle = ref(false)

const editTitle = () => {
	if (props.editable) {
		allowEditTitle.value = true
		emit('edit', allowEditTitle.value)
		setTimeout(() => {
			titleWrapper.value.focus()
		}, "100")
	}
}
const saveNewTitle = (e) => {
	const newName = e.target.innerText
	if (newName !== props.title) emit('changed', newName)
	allowEditTitle.value = false
	if (props.replaceInnerText) e.target.innerText = props.title
	emit('edit', allowEditTitle.value)
}

</script>

<template>
	<h2 ref="titleWrapper" :class="[titleClasses, { 'title-editable': allowEditTitle }]" :contenteditable="allowEditTitle"
		@click="editTitle()" @blur="saveNewTitle" @keyup.enter="saveNewTitle" v-text="title" />
</template>

<style scoped>
.title-editable {
	-webkit-user-modify: read-write-plaintext-only;
}
</style>
