<template>
    <div
		id="thumb-container"
		ref="thumbContainer"
		class=""
        v-if="this.streamo"
	>	<img
			src="@/assets/images/handle.svg"
			alt="Add Showcase"
		>
        <div
        v-show="!hasStreamThumb"
        class="placeholder"
        :class="{ 'vod-placeholder': !this.streamo.type, 'no-video': !hasStreamThumb }" 
		>
        <img
			src="@/assets/images/showcase_play.svg"
			alt="Add Showcase"
		>
        </div>
    </div>
</template>

<script>
import StreamService from '@/services/StreamService';
import StreamStatsService from '@/services/StreamStatsService';
import UserService from '@/services/UserService';
import _ from "lodash";

export default {
	name: 'ShowcaseThumb',
	components: {
		
	},
	props: {
		streamo: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
            mediaPulse : null,
            streamStatus: this.streamo && this.streamo.enabled,
            hasStreamThumb: null,
            mediaPulseTimeoutCtrl: null,
			thumbUrl: null,
            scopeAlive: true,
            randId: Math.random()
				.toString()
				.slice(2),
		};
	},
	computed: {
	    streamAlive() {
			return this.streamoStatus && this.mediaPulse && this.mediaPulse.alive;
		},
	    getName() {
			return this.streamo && this.streamo.name;
		},
	    getType() {
			return this.streamo && this.streamo.type;
		},
        getBytes() {
			if (this.streamo && this.streamo.totalBytes && this.streamo.totalBytes > 0) {
				let totalBytes = this.streamo.totalBytes;
				var i = Math.floor(Math.log(totalBytes) / Math.log(1000)),
					sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
				return (totalBytes / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + sizes[i];
			} else {
				return "";
			}
		},
        getMediapulse(){
            return this.mediaPulse;
        },
	},
	watch: {
		mediaPulse () {
			// overwrite url if load balancer enabled
			const hostId = this.mediaPulse && this.mediaPulse.hostId;
			if (hostId) {
				const staticPrefix = this.mediaPulse.staticPrefix;
				const anc = document.createElement('a');
				anc.href = this.thumbUrl;
				// if (/\w+-\d+/gi.test(hostId)) {
                    if (/\w+-\d+/gi.test(hostId) || (this.streamo && this.streamo.pullUrl)) {
					anc.hostname = `${hostId}.castr.io`;
					if (!anc.pathname.includes('static/') && staticPrefix) {
                        anc.pathname = anc.pathname.replace(this.streamo.key,  `static/${this.mediaPulse.name}`);
                      
					}
				}
				this.thumbUrl = anc.href;

				if (!this.thumbCreated && this.thumbUrl) {
					this.setThumbnail();
				}
			}
		}
	},
	async mounted() {
        console.log('mount');
      this.setMP();
	},
	methods: {
        async setMP(){
  if(this.streamo && this.streamo.key){
            const isWowzaRegion = !!this.streamo.region.v2;
            let hostname = 'dvr.castr.io'
            let prefixPath = ''
            if (isWowzaRegion) {
                hostname = 'preview.castr.io'
                prefixPath = 'preview/'
            }

            this.thumbUrl = `https://${hostname}/`
            if (prefixPath) {
                this.thumbUrl += prefixPath
            }

            const regionDepId = this.streamo.region.identifierHaxr
            this.thumbUrl += `${this.streamo.key}/preview.mp4?&region=${regionDepId}`

		    this.setMediapulse(this.streamo.key);
            this.subscribeMediaPulse();
        }
        },
        async setMediapulse(streamKey){
            try {
                const pulseObject = await StreamStatsService.getStreamMediaPulse(
                    streamKey
                );
                this.mediaPulse = pulseObject;
            } catch (e) { }
        },
        subscribeMediaPulse() {
			const streamKey = this.streamo && this.streamo.key;
			const self = this;
			(async function loop() {
				if (!self.scopeAlive) return;
				let timeoutInterval = 5000;
				let shouldUpdatePulse = self.streamo.enabled;
        
				if (shouldUpdatePulse && document.hidden) {
					shouldUpdatePulse = false;
					timeoutInterval = 6000;
				}

				if (shouldUpdatePulse) {
					if (!document.hasFocus()) {
						timeoutInterval = 14000;
					}
				}

				if (shouldUpdatePulse) {
					try {
						const pulseObject = await StreamStatsService.getStreamMediaPulse(
							streamKey
						);
						self.mediaPulse = pulseObject;
					} catch (e) { }
				}

				// reschedule
				self.mediaPulseTimeoutCtrl = setTimeout(
					loop.bind(this),
					timeoutInterval
				);
			}());
		},
        unsubscribeMediaPulse() {
			clearTimeout(this.mediaPulseTimeoutCtrl);
		},
 
		// onStreamCountdownTick(last, isFinished) {
		// 	if (isFinished) {
		// 		this.liveStreamCountdownSecLeft = null;
		// 	}
		// },
		setThumbnail: function setThumbnail() {
			if (!this.scopeAlive) return;

			const thumbContainer = this.$refs.thumbContainer;

			// prepare a new video element to overalp existing ones
			let mediaElement;
			const streamEnabled = this.streamo && this.streamo.enabled;
			const streamAlive = this.mediaPulse && this.mediaPulse.alive;

			if (streamEnabled && streamAlive && thumbContainer) {
				const isWowzaRegion = !!this.streamo.region.v2;
				if (isWowzaRegion) {
					const thumbUrl = this.getVideoThumbUrl();
					if (thumbUrl) {
						this.thumbCreated = true;
						mediaElement = createImgInstance(thumbUrl, () => {
							thumbContainer.appendChild(mediaElement);
						});
					}
				} else {
					const videoSrc = this.getVideoPreviewUrl();
					mediaElement = createVideoInstance(videoSrc);
					if (mediaElement) {
						this.thumbCreated = true;
						thumbContainer.appendChild(mediaElement);
						// preview fix for edge: play video to let video thumb appear
						mediaElement.play();
					}
				}
			}

			// add few secs delay to make the video chunk appear
			if (mediaElement) {
				setTimeout(() => {
					mediaElement.className = mediaElement.className + ' ready';

					// reschedule thumb setup
					scheduleSetup.call(this, mediaElement.tagName.toLowerCase());
				}, 3000);
			} else {
				// reschedule thumb setup
				scheduleSetup.call(this);
			}

			function scheduleSetup(thumbType = 'video') {
				// setTimeout(setThumbnail.bind(this), 8000)
				if (!thumbContainer) return;

				// remove old video thumbs
				const streamEnabled = this.streamo.enabled;

				// recheck if placeholder text has to appear instead of video thumb
				const hasStreamThumb = streamEnabled && this.mediaPulse && this.mediaPulse.alive;
				this.hasStreamThumb = hasStreamThumb;

				const mediaEls = thumbContainer.getElementsByTagName(thumbType);
				Array.prototype.forEach.call(mediaEls, mediaEl => {
					// remove video if stream is offline or is older video
					if (!hasStreamThumb || mediaEl !== mediaElement) {
						if (this.$refs.thumbContainer) {
							this.$refs.thumbContainer.removeChild(mediaEl);
						}
					}
				});
			}
		},
		getVideoPreviewUrl() {
			if (!this.mediaPulse || !this.mediaPulse.alive) return;
			const randomkey = Math.random().toString().slice(2);
			const vidUrl = this.thumbUrl + `&rand=${randomkey}`;
            
			return vidUrl;
		},
		// async setupScheduling() {
		// 	const schedulerConfig = await StreamService.getStreamScheduleSettings(
		// 		this.streamo._id
		// 	) || {};
		// 	this.savedSchedulerConfig = schedulerConfig;
		// 	// this.scheduleMode = schedulerConfig.mode;

		// 	// const { datetime } = schedulerConfig;
		// 	// if (datetime) {
		// 	// 	this.scheduledDateTime = new Date(datetime).toISOString();
		// 	// }

		// 	// this.scheduleConfigLoaded = true;
		// },
		// onScheduleCountdownTick (value, forceEnded) {
		// 	if (!this.savedSchedulerConfig) return;
		// 	let offset = 0;
		// 	if (!forceEnded) {
		// 		offset = this.savedSchedulerConfig.datetime - Date.now();
		// 		offset /= 1000;
		// 		if (value) offset = value.totalSeconds;
		// 	}
		// 	this.scheduleTimeActive = offset <= 0;
		// }
	}
};

function createVideoInstance(videoSrc) {
	if (!videoSrc) return;
	const vid = document.createElement('video');
	vid.src = videoSrc;
	vid.className = 'stream-preview';
	return vid;
}

// function createImgInstance(imgSrc, callback) {
// 	if (!imgSrc) return;
// 	const img = document.createElement('img');
// 	img.src = imgSrc;
// 	img.className = 'stream-preview';
// 	img.onload = () => void callback();
// 	return img;
// }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
