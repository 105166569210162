<template>
	<div
		id="thumb-container"
		ref="thumbContainer"
		:class="{compact: compactMode}"
		class=""
	>
		<div
			v-show="!hasStreamThumb"
			class="placeholder"
		>
			<div v-if="stream.enabled">
				<span
					v-if="mediaPulse && mediaPulse.alive"
					class="thumb__message d-flex justify-content-center align-items-center flex-column "
				>Waiting for frames</span>
				<span
					v-else-if="!compactMode"
					class="thumb__message d-flex justify-content-center align-items-center flex-column  "
				>
					<span v-if="stream.type === 'ipcam'">No camera source</span>
					<span v-else-if="stream.type === 'vod'">Static Media Files</span>
					<span v-else-if="stream.type === 'scheduled' && hasStreamScheduled && !scheduleTimeActive && countdownSecsLeft">
						<CountDown :endDate="savedSchedulerConfig.datetime" @progress="onScheduleCountdownTick">
							Stream will go Live in <br>
						</CountDown>
					</span>
					<span v-else-if="stream.type === 'live' && liveStreamCountdownSecLeft" clas>
						Stream will go live in <br>
						<CountDown :endDate="meta.countdownDate" />
					</span>
					<span v-else>Waiting for stream</span>
				</span>
			</div>
			<span
				v-else-if="!compactMode"
				class="thumb__message text-s-l text-dark-8 d-flex justify-content-center align-items-center flex-column "
			>
				<p class="text-center text-500 text-s-xl text-dark-7 mb-1">
					Disabled Stream
				</p>
				<small class="text-s-m text-dark-5">Please enable</small>
			</span>
		</div>
	</div>
</template>

<script>
import StreamService from '@/services/StreamService';
import CountDown from './CountDown.vue';
export default {
	name: 'StreamThumb',
	components: {
		CountDown
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		compactMode: {
			required: true
		},
		mediaPulse: {
			type: Object,
			default() { return {}; }
		}
	},
	data() {
		return {
			scopeAlive: true,
			hasStreamThumb: null,
			thumbUrl: null,
			savedSchedulerConfig: null,
			scheduleMode: null,
			scheduledDateTime: null,
			schedulerConfigProcessing: false,
			scheduleConfigLoaded: false,
			scheduleTimeActive: false,
			minScheduleDateTime: null,
			thumbCreated: false,
			meta: null,
			liveStreamCountdownSecLeft: null
		};
	},
	computed: {
		hasStreamScheduled () {
			const conf = this.savedSchedulerConfig;
			return conf && conf.mode === 'datetime' && conf.datetime;
		},
		countdownSecsLeft () {
			const value = this.savedSchedulerConfig.datetime - Date.now();
			return Math.max(value, 0);
		},
	},
	watch: {
		mediaPulse () {
			// overwrite url if load balancer enabled
			const hostId = this.mediaPulse && this.mediaPulse.hostId;
			if (hostId) {
				const staticPrefix = this.mediaPulse.staticPrefix;
				const anc = document.createElement('a');
				anc.href = this.thumbUrl;
				// if (/\w+-\d+/gi.test(hostId)) {
				if (/\w+-\d+/gi.test(hostId) || this.stream.pullUrl) {
					anc.hostname = `${hostId}.castr.io`;
					if (!anc.pathname.includes('static/') && staticPrefix) {
						anc.pathname = anc.pathname.replace(this.stream.key,  `static/${this.mediaPulse.name}`);
					}
				}
				this.thumbUrl = anc.href;
				if (!this.thumbCreated && this.thumbUrl) {
					this.setThumbnail();
				}
			}
		}
	},
	async mounted() {
		const isWowzaRegion = !!this.stream.region.v2;
		let hostname = 'dvr.castr.io'
  		let prefixPath = ''
		if (isWowzaRegion) {
			hostname = 'preview.castr.io'
			prefixPath = 'preview/'
		}

		this.thumbUrl = `https://${hostname}/`
		if (prefixPath) {
			this.thumbUrl += prefixPath
		}

		const regionDepId = this.stream.region.identifierHaxr
		this.thumbUrl += `${this.stream.key}/preview.mp4?&region=${regionDepId}`

		// setTimeout(this.setThumbnail.bind(this), 1000);
		if (this.stream.type === 'scheduled') {
			await this.setupScheduling();
		}

		if (this.stream.type === 'live') {
			const meta = await StreamService.getStreamMetadata(this.stream._id);
			this.meta = meta
			if (meta.countdownDate) {
				this.liveStreamCountdownSecLeft = Math.max(new Date(meta.countdownDate) - Date.now(), 0);
			}
		}
	},
	destroyed() {
		this.scopeAlive = false;
	},
	methods: {
		onStreamCountdownTick(last, isFinished) {
			if (isFinished) {
				this.liveStreamCountdownSecLeft = null;
			}
		},
		setThumbnail: function setThumbnail() {
			if (!this.scopeAlive) return;

			const thumbContainer = this.$refs.thumbContainer;
			// prepare a new video element to overalp existing ones
			let mediaElement;
			const streamEnabled = this.stream && this.stream.enabled;
			const streamAlive = this.mediaPulse && this.mediaPulse.alive;

			if (streamEnabled && streamAlive && thumbContainer) {
				const isWowzaRegion = !!this.stream.region.v2;
				if (isWowzaRegion) {
					const thumbUrl = this.getVideoThumbUrl();
					if (thumbUrl) {
						this.thumbCreated = true;
						mediaElement = createImgInstance(thumbUrl, () => {
							thumbContainer.appendChild(mediaElement);
						});
					}
				} else {
					const videoSrc = this.getVideoPreviewUrl();
					mediaElement = createVideoInstance(videoSrc);
					if (mediaElement) {
						this.thumbCreated = true;
						thumbContainer.appendChild(mediaElement);
						// preview fix for edge: play video to let video thumb appear
						mediaElement.play();
					}
				}
			}

			// add few secs delay to make the video chunk appear
			if (mediaElement) {
				setTimeout(() => {
					mediaElement.className = mediaElement.className + ' ready';

					// reschedule thumb setup
					scheduleSetup.call(this, mediaElement.tagName.toLowerCase());
				}, 3000);
			} else {
				// reschedule thumb setup
				scheduleSetup.call(this);
			}

			function scheduleSetup(thumbType = 'video') {
				// setTimeout(setThumbnail.bind(this), 8000)

				if (!thumbContainer) return;

				// remove old video thumbs
				const streamEnabled = this.stream.enabled;

				// recheck if placeholder text has to appear instead of video thumb
				const hasStreamThumb = streamEnabled && this.mediaPulse && this.mediaPulse.alive;
				this.hasStreamThumb = hasStreamThumb;

				const mediaEls = thumbContainer.getElementsByTagName(thumbType);
				Array.prototype.forEach.call(mediaEls, mediaEl => {
					// remove video if stream is offline or is older video
					if (!hasStreamThumb || mediaEl !== mediaElement) {
						if (this.$refs.thumbContainer) {
							this.$refs.thumbContainer.removeChild(mediaEl);
						}
					}
				});
			}
		},
		getVideoPreviewUrl() {
			if (!this.mediaPulse || !this.mediaPulse.alive) return;
			const randomkey = Math.random().toString().slice(2);
			const vidUrl = this.thumbUrl + `&rand=${randomkey}`;
			return vidUrl;
		},
		async setupScheduling() {
			const schedulerConfig = await StreamService.getStreamScheduleSettings(
				this.stream._id
			) || {};
			this.savedSchedulerConfig = schedulerConfig;
			// this.scheduleMode = schedulerConfig.mode;

			// const { datetime } = schedulerConfig;
			// if (datetime) {
			// 	this.scheduledDateTime = new Date(datetime).toISOString();
			// }

			// this.scheduleConfigLoaded = true;
		},
		onScheduleCountdownTick (value, forceEnded) {
			if (!this.savedSchedulerConfig) return;
			let offset = 0;
			if (!forceEnded) {
				offset = this.savedSchedulerConfig.datetime - Date.now();
				offset /= 1000;
				if (value) offset = value.totalSeconds;
			}
			this.scheduleTimeActive = offset <= 0;
		}
	}
};

function createVideoInstance(videoSrc) {
	if (!videoSrc) return;
	const vid = document.createElement('video');
	vid.src = videoSrc;
	vid.className = 'stream-preview';
	return vid;
}

function createImgInstance(imgSrc, callback) {
	if (!imgSrc) return;
	const img = document.createElement('img');
	img.src = imgSrc;
	img.className = 'stream-preview';
	img.onload = () => void callback();
	return img;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.thumb, .video-thumb {
	display: flex;
	align-items: center;
	justify-content: center;
}
.compact.offline.enabled .thumb::after {
	font-size: 14px;
	color: var(--c-dark-5);
	content: "Waiting for stream";
	text-align: center;
}
:deep(.thumb .stream-preview) {
	max-height: 100%;
	max-width: 100%;
}
.thumb__message {
	text-align: center;
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	color: var(--c-dark-5);
	top: 0;
	cursor: pointer;
}
.scheduler-countdown-content .value-container {
    margin: 0;
}
.sub-streams .thumb__message{
	cursor: unset;
}
</style>
