<script setup>
import { defineProps, ref, onMounted, watch } from 'vue'
import _ from 'lodash'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

const props = defineProps({
  startDate: {
    type: Date,
    default: null
  },
  endDate: {
    type: Date,
    default: new Date()
  }
})
const emit = defineEmits({
  progress: (value) => {return value}
})

const timeLeft = ref()

const intervalId = ref()

const countTimeLeft = () => {
  const startTime = (props.startDate ? moment(props.startDate).valueOf() : moment().valueOf());
  let time = moment(props.endDate).valueOf() - startTime;
  intervalId.value = setInterval(() => {
    if (timeLeft <= 0) {
      clearInterval(intervalId.value)
      intervalId.value = null
    } else {
      time = time - 1000
      timeLeft.value = time
      emit('progress', {time})
    }
  }, 1000)
}
const timeFormat = (time) => {
  return moment.duration(time).format("d [days] h [hours] mm [mins] ss [secs]")
}

onMounted(() => {
  countTimeLeft()
})

watch(() => props.endDate, () => {
  clearInterval(intervalId.value)
  intervalId.value = null
  countTimeLeft()
})
</script>

<template>
  <slot />
  <span v-if="timeLeft > 0">
    {{ timeFormat(timeLeft) }}
  </span>
</template>