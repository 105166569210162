<template>
	<svg
		width="30"
		height="29"
		viewBox="0 0 30 29"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0)">
			<path
				d="M24.0448 16.2961C24.9518 15.8765 25.7154 15.1994 26.2404 14.349C26.7655 13.4986 27.0288 12.5126 26.9976 11.5137C26.9865 10.7658 26.8248 10.0278 26.522 9.3438C26.2192 8.65984 25.7817 8.04392 25.2354 7.53284C24.6893 7.0218 24.0456 6.62613 23.343 6.36949C22.6404 6.11285 21.8933 6.0005 21.1463 6.03917C20.4867 4.71141 19.4677 3.59538 18.2053 2.81801C16.9429 2.04064 15.4877 1.63312 14.0051 1.64182C12.0195 1.61518 10.0984 2.34677 8.63343 3.68749C7.16853 5.0282 6.27002 6.87711 6.12107 8.85733C5.51342 8.72378 4.88369 8.72661 4.27728 8.86575C3.67087 9.00489 3.10288 9.27687 2.61424 9.66199C2.12561 10.0471 1.72851 10.5359 1.45156 11.0931C1.17461 11.6502 1.02472 12.2618 1.01267 12.8838C0.965118 13.7753 1.23598 14.6545 1.77689 15.3646C2.31779 16.0747 3.09351 16.5694 3.9655 16.7603"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M14.0003 17.4401C17.6696 17.4401 20.6442 16.2503 20.6442 14.7825C20.6442 13.3149 17.6696 12.125 14.0003 12.125C10.331 12.125 7.35645 13.3149 7.35645 14.7825C7.35645 16.2503 10.331 17.4401 14.0003 17.4401Z"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.35645 14.7822V18.768C7.35645 20.2355 10.3311 21.4256 14.0003 21.4256C17.6695 21.4256 20.6442 20.2361 20.6442 18.768V14.7822"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.35645 18.7676V22.7539C7.35645 24.2215 10.3311 25.4115 14.0003 25.4115C17.6695 25.4115 20.6442 24.2215 20.6442 22.7539V18.7676"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect
					width="28"
					height="28"
					fill="white"
					transform="translate(0 0.0371094)"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
