import _ from "lodash";
import RequestError from "./RequestError";
import amProxyUrl from "./AmProxyUrl";
import api from "@/api";


export default {
  getPlaylist,
  getPlaylistbyid,
  createPlaylist,
  updatePlaylist,
  deletePlaylist,
  playlistToggle,
  nameChange,
  moveVideoToAnotherFolder,
  updateSettings,
  vodMoveStatus,
};


function createPlaylist(name, streams) {
    console.log(name);
    console.log(streams);
	return makeRequest({
		path: '/playlist',
		method: 'post',
		data: {
			name: name, streams: streams
		}
	});
}

function updatePlaylist(name, streams, playerId) {
	return makeRequest({
		path: `/playlist/${playerId}`,
		method: 'put',
		data: {
			name: name, streams: streams
		}
	});
}

function getPlaylist() {
	return makeRequest({
		path: '/playlist',
		method: 'get',
	});
}

function getPlaylistbyid(playlistId) {
  return makeRequest({
		path: `/playlist/${playlistId}`,
		method: 'get',
	});
}

function deletePlaylist(playerId) {
  return makeRequest({
		path: `/playlist/${playerId}`,
		method: 'delete',
	});
}

function playlistToggle(playerId, shouldDisabled) {
  return makeRequest({
		path: `/playlist/${playerId}`,
		method: 'put',
        data: {
			disabled: shouldDisabled
		}
	});
}

function nameChange(playerId, name) {
  return makeRequest({
		path: `/playlist/${playerId}`,
		method: 'put',
        data: {
			name: name
		}
	});
}

function updateSettings(playerId, settings) {
  return makeRequest({
		path: `/playlist/${playerId}`,
		method: 'put',
    data: {
			settings: settings
		}
	});
}

function moveVideoToAnotherFolder(vodId, folderId, isVideoLiveToVod = false) {
  return makeRequest({
		path: `/streams/move-vod/${vodId}/folder/${folderId}?isLive=${isVideoLiveToVod}`,
		method: 'post',
	});
}

function vodMoveStatus(folder, vodId) {
	return makeRequest({
		  path: `/streams/move-vod/${folder}/${vodId}/status`,
		  method: 'get',
	  });
  }

async function makeRequest(reqConfig, preventProxy, throwReal) {
  if (typeof reqConfig === "string") {
    reqConfig = {
      path: reqConfig,
    };
  }

  if (preventProxy !== true) {
    reqConfig = amProxyUrl(reqConfig, process.env.VUE_APP_VOD_API_BASE_URL);
  }

  reqConfig.url = reqConfig.path;

  let res;
  try {
    res = await api.request(reqConfig);
  } catch (err) {
    if (throwReal) {
      throw err;
    }
    const edata = _.get(err, "response.data");
    throw new RequestError(edata);
  }

  return res && res.data;
}
