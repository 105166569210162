<script setup>
import _ from 'lodash'
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment'
import IconBase from '../icon/IconBase.vue'
import IconGlobe from '../icon/IconGlobe.vue'
import FormCheckbox from '../Atoms/FormCheckbox.vue'
import StreamService from '@/services/StreamService'
import FormInput from '../Atoms/FormInput.vue'
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const props = defineProps({
  vodFile: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  vodFolder: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  playerUrl: {
    type: String
  },
  videoMeta: {
    type: Object
  }
})

const emit = defineEmits({
	'video-metadata-schedule-updated': (value) => { return value },
})

const schedule = ref({
  publishDate: '',
  publishTime: '',
  expireDate: '',
  expireTime: '',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC',
  enableExpiry: false,
})

const scheduleValidation = ref({
  publishDate: '',
  publishTime: '',
  expiryDate: '',
  expiryTime: '',
})

const timeZoneDropdown = ref(null)

const timeZoneFilter = ref('')
const bypassScheduleUpdate = ref(false)

onMounted(async () => {
  getSchedule()
})

watch(() => route.params, async () => {
  resetScheduleErrors()
  getSchedule()
})

const getSchedule = async () => {
  if (props.videoMeta?.schedule) {
    bypassScheduleUpdate.value = true
    schedule.value = _.assign({}, schedule.value, props.videoMeta?.schedule);
    if (schedule.value.publishTime) {
      let timePart = schedule.value.publishTime.split('T')?.[1];
      timePart = timePart.substring(0, timePart.length - 4);
      const timeArray = timePart.split(':')
      schedule.value.publishTime = {
        hours: parseInt(timeArray[0], 10),
        minutes: parseInt(timeArray[1], 10),
        seconds: parseInt(timeArray[2], 10)
      }
    }
    if (schedule.value.expireTime) {
      let timePart = schedule.value.expireTime.split('T')?.[1];
      timePart = timePart.substring(0, timePart.length - 4);
      const timeArray = timePart.split(':')
      schedule.value.expireTime = {
        hours: parseInt(timeArray[0], 10),
        minutes: parseInt(timeArray[1], 10),
        seconds: parseInt(timeArray[2], 10)
      }
    }
    bypassScheduleUpdate.value = false
  }
}

const toggleExpiryEnable = async () => {
    const curr = schedule.value.enableExpiry;
    updateScheduleDetails(curr);
}

const timeZoneFormatted = (timeZone) => {
  if (timeZone) {
    return `(GMT${moment().tz(timeZone).utcOffset() / 60 >= 0 ? '+ ' + moment().tz(timeZone).utcOffset() / 60 : moment().tz(timeZone).utcOffset() / 60}) ${timeZone}`
  }
}
const timeZonesFiltered = computed(() => {
  let array = Intl.supportedValuesOf('timeZone').filter(element => element.toLowerCase().includes(timeZoneFilter.value.toLowerCase()))
  const sortedArray = array.sort(function (a, b) {
    return moment().tz(a).utcOffset() / 60 - moment().tz(b).utcOffset() / 60
  })
  return sortedArray
})

const updateScheduleDetails = async (enableValidation = true) => {
  if (bypassScheduleUpdate.value) {
    return
  }
  if (enableValidation && !validateScheduleDateTime()) return;

  const scheduleCopy = _.cloneDeep(schedule.value)
  if (schedule.value.publishTime && schedule.value.publishDate) {
    const publishDate = schedule.value.publishDate
    const publishTime = schedule.value.publishTime
    const datePart = publishDate.split('T')?.[0]

    scheduleCopy.publishTime = `${datePart}T${publishTime.hours < 10 ? ('0' + publishTime.hours) : publishTime.hours}:${publishTime.minutes < 10 ? ('0' + publishTime.minutes) : publishTime.minutes}:${publishTime.seconds < 10 ? ('0' + publishTime.seconds) : publishTime.seconds}.000Z`
  }

  if (schedule.value.expireTime && schedule.value.expireDate) {
    const expireDate = schedule.value.expireDate
    const expireTime = schedule.value.expireTime
    const datePart = expireDate.split('T')?.[0]

    scheduleCopy.expireTime = `${datePart}T${expireTime.hours < 10 ? ('0' + expireTime.hours) : expireTime.hours}:${expireTime.minutes < 10 ? ('0' + expireTime.minutes) : expireTime.minutes}:${expireTime.seconds < 10 ? ('0' + expireTime.seconds) : expireTime.seconds}.000Z`
  }

  if (route.params.videoId.includes('.mp4')) {
    await StreamService.updateStreamPlaylistVideoMetaData(route.params.streamId, route.params.videoId,
      {
        schedule: scheduleCopy
      }
    )
    StreamService.clearStreamMetadataCache(route.params.streamId, route.params.videoId.replace('.mp4', ''));
  } else {
    await StreamService.saveStreamMetadata(route.params.streamId, 'schedule', scheduleCopy, props.vodFile._id);
    const url = props.playerUrl;
    let key = undefined;
    if (url.includes('?')) {
      key = url.split('?')[1]
    }
    StreamService.clearStreamMetadataCache(route.params.streamId, key);
  }

  emit('video-metadata-schedule-updated', scheduleCopy);
}

const getTimezoneOffset = (timeZone, date = new Date()) => {
  const tz = date.toLocaleString("en", { timeZone, timeStyle: "long" }).split(" ").slice(-1)[0];
  const dateString = date.toString();
  const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);

  // return UTC offset in millis
  return offset;
}

const validateScheduleDateTime = () => {
  resetScheduleErrors();

  const { enableExpiry, expireDate, expireTime, publishDate, publishTime, timeZone } = schedule.value;
  const offset = getTimezoneOffset(timeZone);

  const pubDateIso = publishDate.split('T')?.[0];
  const pubTimeIso = publishTime
  const absolutePubTime = `${pubDateIso}T${pubTimeIso}`;
  const absPubTimeInMs = new Date(absolutePubTime).getTime() - offset;
  if (Date.now() > absPubTimeInMs) {
    scheduleValidation.value.publishTime = 'The entered time is already passed.';
    return false;
  }

  if (enableExpiry) {
    const expiryDateIso = expireDate.split('T')?.[0];
    const expiryTimeIso = expireTime
    const absoluteExpiryTime = `${expiryDateIso}T${expiryTimeIso}`;
    const absExpiryTimeInMs = new Date(absoluteExpiryTime).getTime() - offset;

    if (absExpiryTimeInMs <= absPubTimeInMs) {
      scheduleValidation.value.expiryTime = 'Expiration date & time should be later than the publish date.';
      return false;
    }
  }

  return true;
}

const changeTimeZone = (timeZone) => {
  schedule.value.timeZone = timeZone
  updateScheduleDetails()
  timeZoneDropdown.value.toggleDropdown()
}

const resetScheduleErrors = () => {
  scheduleValidation.value.publishTime = '';
  scheduleValidation.value.expiryTime = '';
}
</script>

<template>
  <section>
    <h3 class="text-lg font-mediun mt-6 !mb-2">Schedule VOD file to stream in certain period</h3>
    <p class="text-surface-8 text-sm !mb-2">Start date is when the video file starts displaying. Expiration date is
      when the video file ends displaying.</p>
    <div class="grid gap-x-2 gap-y-3 grid-cols-2 md:grid-cols-3">
      <div class="flex flex-col">
        <label class="text-sm text-surface-8 !mb-1">
          Publish Date
        </label>
        <VueDatePicker input-id="startDate" utc :enable-time-picker="false" v-model="schedule.publishDate"
          inputClassName="h-8 bg-surface-3 hover:bg-surface-4 rounded border-none"
          @update:model-value="updateScheduleDetails"
          :class="scheduleValidation.publishDate ? 'border border-error' : 'border-none'" />
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-surface-8 !mb-1">
          Time
        </label>
        <VueDatePicker input-id="start-time-selector" utc enable-seconds time-picker format="HH:mm:ss"
          v-model="schedule.publishTime" inputClassName="h-8 bg-surface-3 hover:bg-surface-4 rounded border-none"
          @update:model-value="updateScheduleDetails"
          :class="scheduleValidation.publishTime ? 'border border-error' : 'border-none'" />
      </div>
      <div class="flex flex-col col-span-2 md:col-auto">
        <div class="hidden max-h-48"></div>
        <label class="text-sm text-surface-8 !mb-1">
          Time Zone
        </label>
        <DropDownMenu ref="timeZoneDropdown"
          customClasses="flex items-center truncate w-full grow-0 bg-surface-3 rounded-md h-8 !px-2 hover:bg-surface-4 text-tiny"
          wrapperClass="w-full"
          maxHeight="48">
          <template #toggle-button>
            <icon-base class="!mr-2" fill="none">
              <icon-globe />
            </icon-base>
            {{ timeZoneFormatted(schedule.timeZone) }}
            <icon-base class="!ml-1">
              <icon-chevron-up-down />
            </icon-base>
          </template>
          <template #menu-items>
            <DropDownMenuItem isCustom class="!p-2 hover:!bg-transarent">
              <FormInput v-model="timeZoneFilter" />
            </DropDownMenuItem>
            <DropDownMenuItem v-for="(timeZone, index) in timeZonesFiltered" :key="index"
              @click="changeTimeZone(timeZone)">
              {{ timeZoneFormatted(timeZone) }}
            </DropDownMenuItem>
          </template>
        </DropDownMenu>
      </div>
      <FormCheckbox class=" col-span-2 md:col-span-3 mb-0" v-model="schedule.enableExpiry" @change="toggleExpiryEnable" label="Set expiration date" />
      <div class="flex flex-col">
        <label class="text-sm text-surface-8 !mb-1">
          Expiry Date
        </label>
        <VueDatePicker input-id="endDate" :min-date="schedule.publishDate"
          :disabled="!schedule.enableExpiry || !schedule.publishDate || !schedule.publishTime" utc
          :enable-time-picker="false" v-model="schedule.expireDate" @update:model-value="updateScheduleDetails"
          inputClassName="h-8 bg-surface-3 hover:bg-surface-4 disabled:text-surface-6 disabled:bg-surface-2 hover:disabled:bg-surface-2 rounded border-none"
          :class="scheduleValidation.expiryTime ? 'border border-error' : 'border-none'" />
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-surface-8 !mb-1">
          Time
        </label>
        <VueDatePicker :disabled="!schedule.enableExpiry || !schedule.publishDate || !schedule.publishTime" utc
          enable-seconds time-picker format="HH:mm:ss" v-model="schedule.expireTime"
          inputClassName="h-8 bg-surface-3 hover:bg-surface-4 disabled:text-surface-6 disabled:bg-surface-2 hover:disabled:bg-surface-2 rounded border-none"
          @update:model-value="updateScheduleDetails"
          :class="scheduleValidation.expiryTime ? 'border border-error' : 'border-none'" />
      </div>
    </div>

  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
