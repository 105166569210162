<script setup>
import { computed, ref, onMounted, getCurrentInstance, watch } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import _ from 'lodash';
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"
import StreamService from '@/services/StreamService';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import FormSwitch from '../../components/Atoms/FormSwitch.vue';
import FormButton from '../../components/Atoms/FormButton.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import IconBase from '../../components/icon/IconBase.vue';
import IconChevronUpDown from '../../components/icon/IconChevronUpDown.vue';
import IconLock from '@/components/icon/IconLock.vue';
import FormInput from '../../components/Atoms/FormInput.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const props = defineProps({
  stream: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  streamAlive: {
    type: Boolean
  },
})

const emit = defineEmits({
  'set-live-stream-countdown': (value) => { return value },
  'live-stream-abr-changed': (value) => { return value }
})

const fpsDropdown = ref(null)
const streamMeta = ref(null)
const abr = ref(null)
const abrProcessing = ref(false)
const selectedFps = ref(null)
const fpsProcessing = ref(false)
const fpsOpts = ref([
  { name: 25 },
  { name: 30 },
  { name: 50 },
  { name: 60 },
  { name: 'Pass' }
])

const customBitrateLadder = ref(null)

const customBitrate = ref({
  'source': { enabled: false, bitrate: '' },
  '1080p': { enabled: false, bitrate: '' },
  '720p': { enabled: false, bitrate: '' },
  '480p': { enabled: false, bitrate: '' },
  '360p': { enabled: false, bitrate: '' },
  '240p': { enabled: false, bitrate: '' },
})

const customBitrateErrors = ref({
  'source': null,
  '1080p': null,
  '720p': null,
  '480p': null,
  '360p': null,
  '240p': null,
}
)
const bitrateProcessing = ref(false)
const featureProcessing = ref(false)
const saveBitrateDisabled = ref(true)
const bypassBitrateValidation = ref(false)

const bitradeLadderFlag = computed(() => store.getters['User/bitradeLadderFlag'])

watch(
  () => customBitrate,
  (configNew, configOld) => {
    validateCustomBitrate()
  },
  { deep: true }
)

onMounted(async () => {
  streamMeta.value = await StreamService.getStreamMetadata(props.stream._id)
  customBitrateLadder.value = streamMeta.value.customBitrateLadder
  abr.value = streamMeta.value.abr
  selectedFps.value = streamMeta.value.abrFps

  if (streamMeta.value.customBitrate) {
    bypassBitrateValidation.value = true
    customBitrate.value = JSON.parse(streamMeta.value.customBitrate)
  } else {
    streamMeta.value.customBitrate = _.cloneDeep(customBitrate.value)
  }
})

const customBitrateChange = async (bitrate) => {
  if (!customBitrate.value[bitrate].enabled) {
    customBitrate.value[bitrate].bitrate = ''
    customBitrateErrors.value[bitrate] = null
  }
}
const toggleABR = async () => {
  const nstate = abr.value;
  const methodName = nstate === true ? 'enableStreamABR' : 'disableStreamABR';

  abrProcessing.value = true
  try {
    await StreamService[methodName](props.stream._id);
    abr.value = nstate;
    notify({ group: 'success', text: 'Setting saved' })
    if (methodName === 'disableStreamABR' && customBitrateLadder.value) {
      await saveSetting('customBitrateLadder', false, false)
      customBitrateLadder.value = false
    }
  } catch (e) {
    if (_.includes(_.get(e, 'message'), 'upgrade')) {
      root.$emit('bv::show::modal', 'feature-upgrade');
      abr.value = !nstate;
    } else {
      console.log('api-error', e);
    }

    const errorMsg = e ? e.message : 'could not toggle stream ABR';
    abr.value = !nstate;
    notify({ group: 'error', text: errorMsg });
  }
  abrProcessing.value = false

  emit('live-stream-abr-changed', nstate);
}

const validateCustomBitrate = () => {
  if (bypassBitrateValidation.value) {
    bypassBitrateValidation.value = false
    return
  }

  const objectsEqual = (o1, o2) =>
    typeof o1 === 'object' && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length
      && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
      : o1 === o2;

  if (objectsEqual(customBitrate.value, streamMeta.value.customBitrate)) {
    saveBitrateDisabled.value = true
    return
  }

  const disabled = _.every(Object.values(customBitrate.value), { 'enabled': false })
  if (disabled) {
    saveBitrateDisabled.value = true
    return
  }

  _.map(Object.keys(customBitrate.value), (key, val) => {
    const bitrate = customBitrate.value[key].bitrate
    if (customBitrate.value[key].enabled) {
      if (!isNumeric(bitrate)) {
        customBitrateErrors.value[key] = 'Only numeric value allowed'
      } else if (Number(bitrate) > 15000 || Number(bitrate) < 250) {
        customBitrateErrors.value[key] = 'Bitrate value should be from 250 to 15000'
      } else {
        customBitrateErrors.value[key] = null
        saveBitrateDisabled.value = false
      }
    }
  })

  let validation = false
  _.map(Object.values(customBitrateErrors.value), (val, n) => {
    if (val) {
      validation = true
    }
  })

  if (validation) {
    saveBitrateDisabled.value = true
    return
  }

  saveBitrateDisabled.value = false
}

const isNumeric = (str) => {
  if (typeof str === 'number') return true
  if (typeof str != "string") return false
  return !isNaN(str) && !isNaN(parseFloat(str))
}

const saveBitrate = async () => {
  bitrateProcessing.value = true
  await saveSetting('customBitrate', JSON.stringify(customBitrate.value), true)

  streamMeta.customBitrate = _.cloneDeep(customBitrate.value)
  saveBitrateDisabled.value = true
  bitrateProcessing.value = false
}

const saveFps = async () => {
  fpsProcessing.value = true
  await saveSetting('abrFps', selectedFps.value, true)
  fpsProcessing.value = false
  streamMeta.value.abrFps = selectedFps.value
}

const onFpsSelect = (fps) => {
  selectedFps.value = fps.name
  fpsDropdown.value.toggleDropdown()
}

const navigateToBilling = () => {
  if ((this.stream.type === 'live') || this.stream.type === 'vod') {
    window.location.href = '/app/subscribe?category=live';
  } else {
    window.location.href = '/app/subscribe';
  }
}

const toggleFeature = async (featureName, flag) => {
  if (featureName === 'abr') {
    toggleABR()
    return
  }
  await saveSetting(featureName, flag, true)
}

const saveSetting = async (key, value, successToast = false) => {
  featureProcessing.value = true;
  try {
    await StreamService.saveStreamMetadata(props.stream._id, key, value)
    if (successToast) {
      notify({ group: 'success', text: 'Setting saved' })
    }
  } catch (e) {
    notify({ group: 'error', text: 'could not save changes' })
  }
  featureProcessing.value = false;
}

const ladderTooltip = computed(() => {
  if (props.streamAlive) return 'You cannot change this while the stream is live.'
  if (!abr) return 'Adaptive Bitrate needs to be enabled.'
  return ''
})
const saveTooltip = `Customizing available only on Ultra plan. Please <a class="text-underline text-reset" target="_blank"
href="/app/subscribe">upgrade</a>`

</script>

<template>
  <div class="divide-y divide-surface-2">
    <section class="flex justify-between !py-3">
      <div>
        <h4 class="text-base">Enable Adaptive Bitrate</h4>
        <p class="text-tiny text-surface-7">Dynamically adjusts video quality to ensure seamless playback and minimal
          buffering, optimizing for varying network conditions.</p>
      </div>
      <ejs-tooltip v-if="props.streamAlive" content="You cannot change this while the stream is live.">
        <FormSwitch :disabled="abrProcessing || props.streamAlive" @change="toggleFeature('abr', abr)" v-model="abr" />
      </ejs-tooltip>
      <FormSwitch v-else :disabled="abrProcessing || props.streamAlive" @change="toggleFeature('abr', abr)"
        v-model="abr" />
    </section>
    <section class="flex flex-col !py-3">
      <h4 class="text-base">Set Custom FPS</h4>
      <p class="text-tiny text-surface-7">The default FPS for transcoding is set to 30. If you stream at a different
        FPS, adjust it here.</p>
      <div class="flex gap-x-2 !mt-2">
        <DropDownMenu type="secondary" ref="fpsDropdown">
          <template #toggle-button>
            {{ selectedFps ? selectedFps : 'Select an option' }}
            <icon-base class="!ml-1">
              <icon-chevron-up-down />
            </icon-base>
          </template>
          <template #menu-items>
            <DropDownMenuItem class="uppercase" v-for="(fps, index) in fpsOpts" :key="index" @click="onFpsSelect(fps)">
              {{ fps.name }}
            </DropDownMenuItem>
          </template>
        </DropDownMenu>
        <FormButton v-if="streamMeta" @click="saveFps"
          :disabled="Number(streamMeta.abrFps) === Number(selectedFps) || props.streamAlive || !abr || (streamMeta.abrFps === 'Pass' && selectedFps === 'Pass') || fpsProcessing">
          <span>{{ fpsProcessing ? 'Saving' : 'Save' }}</span>
        </FormButton>
      </div>
    </section>
    <section class="flex flex-col !py-3">
      <h4 class="text-base">Advanced Transcode Settings</h4>
      <p class="text-tiny text-surface-7 !mb-2">Customize the bitrates and add more profiles to the stream output. The
        changes
        below will override the default Castr bitrate ladder.</p>
      <div class="flex justify-between">
        <h4 class="text-base !mb-2">Customize Bitrate Ladder</h4>
        <ejs-tooltip v-if="props.streamAlive || !abr" :content="ladderTooltip">
          <FormSwitch @change="toggleFeature('customBitrateLadder', customBitrateLadder)" v-model="customBitrateLadder"
            :disabled="!abr || props.streamAlive" />
        </ejs-tooltip>
        <FormSwitch v-else @change="toggleFeature('customBitrateLadder', customBitrateLadder)" v-model="customBitrateLadder"
          :disabled="!abr || props.streamAlive" />
      </div>
      <div class="grid md:grid-cols-2 grid-rows-auto gap-4">
        <div class="flex items-center gap-x-2">
          <FormSwitch label="Source" v-model="customBitrate['source'].enabled" @change="customBitrateChange('source')"
            class="" :disabled="!customBitrateLadder" />
          <FormInput :isError="customBitrateErrors['source']" :helper="customBitrateErrors['source'] ? customBitrateErrors['source'] : null" helperClass="text-error" class="w-[300px] ml-auto" v-model="customBitrate['source'].bitrate"
            :disabled="!customBitrateLadder" placeholder="Specify bitrate in Kbps" />
        </div>
        <div class="flex items-center gap-x-2">
          <FormSwitch label="1080p" v-model="customBitrate['1080p'].enabled" @change="customBitrateChange('1080p')"
            class="" :disabled="!customBitrateLadder" />
          <FormInput :isError="customBitrateErrors['1080p']" :helper="customBitrateErrors['1080p'] ? customBitrateErrors['1080p'] : null" class="w-[300px] ml-auto" v-model="customBitrate['1080p'].bitrate" :disabled="!customBitrateLadder"
            placeholder="Specify bitrate in Kbps" />
        </div>
        <div class="flex items-center gap-x-2">
          <FormSwitch label="720p" v-model="customBitrate['720p'].enabled" @change="customBitrateChange('720p')"
            class="" :disabled="!customBitrateLadder" />
          <FormInput :isError="customBitrateErrors['720p']" :helper="customBitrateErrors['720p'] ? customBitrateErrors['720p'] : null" class="w-[300px] ml-auto" v-model="customBitrate['720p'].bitrate" :disabled="!customBitrateLadder"
            placeholder="Specify bitrate in Kbps" />
        </div>
        <div class="flex items-center gap-x-2">
          <FormSwitch label="480p" v-model="customBitrate['480p'].enabled" @change="customBitrateChange('480p')"
            class="" :disabled="!customBitrateLadder" />
          <FormInput :isError="customBitrateErrors['480p']" :helper="customBitrateErrors['480p'] ? customBitrateErrors['480p'] : null" class="w-[300px] ml-auto" v-model="customBitrate['480p'].bitrate" :disabled="!customBitrateLadder"
            placeholder="Specify bitrate in Kbps" />
        </div>
        <div class="flex items-center gap-x-2">
          <FormSwitch label="360p" v-model="customBitrate['360p'].enabled" @change="customBitrateChange('360p')"
            class="" :disabled="!customBitrateLadder" />
          <FormInput :isError="customBitrateErrors['360p']" :helper="customBitrateErrors['360p'] ? customBitrateErrors['360p'] : null" class="w-[300px] ml-auto" v-model="customBitrate['360p'].bitrate" :disabled="!customBitrateLadder"
            placeholder="Specify bitrate in Kbps" />
        </div>
        <div class="flex items-center gap-x-2">
          <FormSwitch label="240p" v-model="customBitrate['240p'].enabled" @change="customBitrateChange('240p')"
            class="" :disabled="!customBitrateLadder" />
          <FormInput :isError="customBitrateErrors['240p']" :helper="customBitrateErrors['240p'] ? customBitrateErrors['240p'] : null" class="w-[300px] ml-auto" v-model="customBitrate['240p'].bitrate" :disabled="!customBitrateLadder"
            placeholder="Specify bitrate in Kbps" />
        </div>
      </div>
      <ejs-tooltip v-if="!bitradeLadderFlag" :content="saveTooltip">
        <FormButton class="mr-auto !mt-2" :disabled="saveBitrateDisabled || !bitradeLadderFlag" @click="saveBitrate">
        <icon-base v-if="!bitradeLadderFlag" class="!mr-1">
          <icon-lock />
        </icon-base>
        {{ bitrateProcessing ? 'Saving' : 'Save' }}
      </FormButton>
        </ejs-tooltip>
      <FormButton v-else class="mr-auto !mt-2" :disabled="saveBitrateDisabled || !bitradeLadderFlag || bitrateProcessing" @click="saveBitrate">
        <icon-base v-if="!bitradeLadderFlag" class="!mr-1">
          <icon-lock />
        </icon-base>
        {{ bitrateProcessing ? 'Saving' : 'Save' }}
      </FormButton>
    </section>
  </div>
  <confirm-modal modal-id="feature-upgrade" ok-text="Upgrade Now" cancel-text="Later"
    @modal-confirm="navigateToBilling()">
    <p class="mb-0 text-s-m"><a target="_blank" class="text-reset text-underline" href="/app/subscribe">Upgrade</a> to
      access this feature. Check <a target="_blank" class="text-reset text-underline text-nowrap"
        href="https://castr.com/pricing">Pricing page</a> to know more about eligible plan.</p>
  </confirm-modal>
</template>
