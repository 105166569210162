<template>
      <ejs-chart theme="Material" :primaryXAxis='primaryXAxis' :primaryYAxis='primaryYAxis' :tooltip='tooltip' :chartArea='chartArea' :zoomSettings='zoom' :legendSettings='legendSettings'>
        <e-series-collection>
            <e-series :dataSource='compData' type='Line' xName='x' yName='y' 
            :marker="marker" :name='chartData.datasets.length > 1 ? "High":""' legendShape='SeriesType' pointColorMapping='color' fill="dodgerblue"> </e-series>
            <e-series v-if="chartData.datasets.length > 1" :dataSource='compData2' type='Line' xName='x' yName='y' 
            :marker="marker" name='Low' legendShape='SeriesType' pointColorMapping='color' fill="red"> </e-series>
        </e-series-collection>
    </ejs-chart>
</template>
<script>
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, LineSeries, DateTime, Zoom, Legend, Tooltip } from "@syncfusion/ej2-vue-charts";

export default {
name: "InputHealthChart",
components: {
    'ejs-chart': ChartComponent,
    'e-series-collection': SeriesCollectionDirective,
    'e-series': SeriesDirective
  },
  props: {
    chartData: {
      type: Object,
      default: () => {}
    },
  },
  mounted () {
    
  },
  data() {
    return {
        primaryXAxis: {
           valueType: 'DateTime',
           intervalType: 'Auto',
           minorGridLines: { width: 0 },
           majorGridLines: { width: 0 },
           labelStyle: {
            size: '12px', color: '#8794B5',
            fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
           },
        },
        zoom:
        {
            enableMouseWheelZooming: true,
            enableSelectionZooming: true,
            enablePinchZooming: true,
            enablePan: true,
            mode: 'X'
        },
        //Initializing Primary Y Axis
        primaryYAxis: {
          title: '',
          lineStyle: { width: 0 },
          minorGridLines: { width: 0 },
          majorGridLines: { width: 0 },
          labelStyle: {
            size: '12px', color: '#8794B5',
            fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
          },
        },
        chartArea: {
          border: {
            width: 0
          }
        },
        tooltip: {
          enable: true,
          textStyle: {
            fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
          }
        },
        legend: {enableHighlight : true},
        marker: {
          visible: false, width: 1, height: 1
        },
      legendSettings: {
        visible: true,
        position: 'Top'
      }
    };
  },
  computed: {
    compData() {
      return this.chartData.datasets[0].data.map(d => ({ x: new Date(d.x), y: d.y, color: "dodgerblue" }));
    },
    compData2() {
      return this.chartData.datasets[1].data?.length ? this.chartData.datasets[1].data.map(d => ({ x: new Date(d.x), y: d.y, color: "red" })) : [];
    }
  },
  watch: {
  },
  provide: {
    chart: [LineSeries, DateTime, Zoom, Legend, Tooltip]
  }
};
</script>
<style>
#container {
   height: 350px;
 }
</style>