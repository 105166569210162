import _ from 'lodash';
import RequestError from './RequestError';
import api from '@/api';

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

export default {
	getStreamViewers,
	getStreamBandwidth,
	getUserBandwidth,
	getSubscriptionBandwidth,
	getSubscriptionStorage,
	validateStorageUpload,
	getSubscriptionEncodingUnits,
	getUserStorage,
	getAnalyticViewers,
	getAnalyticImpressions,
	getAnalyticAdvImpressions,
	getAnalyticAdvCompleted,
	getAnalyticAdvPlays,
	getAnalyticAdvClickRate,
	getStreamMediaPulse,
	getPublishPlatformsPulse,
	getUserDvrStorage,
	getSubscriptionNotificationCount,
	getBitmovinAnalytics,
	getVodBandwidth,
};

function getSubscriptionNotificationCount(type = 'live') {
	const uri = `/metrics/subscription/notification?streamType=${type}`;
	return makeRequest({
		path: uri,
		method: 'get'
	});
}

function getUserDvrStorage() {
	const uri = `/metrics/vod/dvrStorage`;
	return makeRequest({
		path: uri,
		method: 'get'
	});
}

function getVodBandwidth(videoId, startTime, endTime) {
	let uri = `/metrics/vod/${videoId}/bandwidth`;
	if(startTime || endTime) {
		uri += '?'
		if(startTime) uri += `startTime=${startTime}`
		if(endTime) uri += `&endTime=${endTime}`
	}
	return makeRequest({
		path: uri,
		method: 'get'
	});
}

/**
 * @param {string} streamId
 */
function getStreamMediaPulse(streamKey, regionId) {
	const viaproxy = false;
	let streamkeyPathname = streamKey;
	if (_.isPlainObject(regionId)) {
		const regionObj = regionId;
		regionId = regionId.identifierHaxr;
		// viaproxy = regionObj.v2 === true
		if (regionObj.v2) {
			regionId = 'fs-pull-1';
			streamkeyPathname = 'castr%2F' + streamkeyPathname;
		}
	}

	const baseUrl = getPulseApiBase(viaproxy, true);
	// let uri = `${baseUrl}/${streamKey}?region=${regionId}&pretty_codecs=1`
	const uri = `${baseUrl}/${streamkeyPathname}?region=${regionId}&pretty_codecs=1`;
	// let uri = `https://deps.castr.io/pulse/${streamKey}?region=${regionId}&pretty_codecs=1`
	// console.log('pulse-uri', uri)

	return makeRequest(uri);
}

/**
 * @param {string} platformMetaIds
 */
function getPublishPlatformsPulse(platformMetaIds, regionId, hasPullUrlEnabled) {
	const baseUrl = getPulseApiBase(true);
	let uri = `${baseUrl}/pulse/pushtargets?region=${regionId}`;

	if (hasPullUrlEnabled !== undefined) {
		uri += `&pullEnabled=${!!hasPullUrlEnabled}`;
	}

	uri += `&pushIds=${platformMetaIds.join(',')}`;
	// console.log('pub-pulse-uri', uri)

	return makeRequest(uri);
}

/**
 * @param {string} streamId
 */
function getStreamViewers(streamId) {
	const uri = `/streams/${streamId}/viewership`;
	return makeRequest(uri);
}

/**
 * @param {string} streamId
 * @param {number|Date} startTime
 * @param {number|Date} endTime
 */
function getStreamBandwidth(streamId, startTime, endTime) {
	if (!endTime) { endTime = new Date(); }

	if (startTime.getTime) { startTime = startTime.getTime(); }

	if (endTime.getTime) { endTime = endTime.getTime(); }

	const uri = `/metrics/streams/${streamId}/bandwidth`;
	return makeRequest({
		path: uri,
		params: { startTime, endTime }
	});
}

/**
 * @param {string} userId
 */
function getUserBandwidth(userId) {
	userId = userId || 'me';
	const uri = `/metrics/subscription/${userId}/bandwidth`;
	return makeRequest({
		path: uri,
		method: 'post'
	});
}

/**
 * @param {string} userId
 * @param {string} packageId
 */
function getSubscriptionBandwidth(userId, packageId) {
	userId = userId || 'me';
	const uri = `/usage_metrics/subscription/${userId}/bandwidth/${packageId}`;
	return makeRequest({
		path: uri,
		method: 'post'
	});
}

/**
 * @param {string} userId
 * @param {string} packageId
 */
function getSubscriptionStorage(userId, packageId) {
	userId = userId || 'me';
	const uri = `/metrics/subscription/${userId}/storage/${packageId}`;
	return makeRequest({
		path: uri,
		method: 'post'
	});
}

/**
 * @param {string} packageId
 * @param {number} uploadBytes
 */
function validateStorageUpload(packageId, uploadBytes) {
	const uri = `/metrics/subscription/${packageId}/storage/validate`;
	return makeRequest({
		path: uri,
		method: 'post',
		data: {
			uploadBytes
		}
	});
}

/**
 * @param {string} userId
 * @param {string} packageId
 */
function getSubscriptionEncodingUnits(userId, packageId) {
	userId = userId || 'me';
	const uri = `/metrics/subscription/${userId}/encodings/${packageId}`;
	return makeRequest({
		path: uri,
		method: 'post'
	});
}

function getUserStorage() {
	const uri = `/metrics/user/storage`;
	return makeRequest({
		path: uri,
		method: 'post'
	});
}

function getAnalyticViewers(streamId, startTime, endTime, type) {
	const uri = `${process.env.VUE_APP_APP_API_BASE}/metrics/streams/${streamId}/viewers`
	return makeRequest({
		path: uri,
		method: 'post',
		data: {
			start: startTime,
			end: endTime,
			type: type
		}
	});
}

function getAnalyticImpressions(LiveId, startTime, endTime, type) {
	// let uri = `http://46.101.75.72:22769/analytics/${LiveId}/impressions`
	const uri = `https://metrics.castr.io/analytics/${LiveId}/impressions`;
	return makeRequest({
		path: uri,
		method: 'post',
		data: {
			start: startTime,
			end: endTime,
			type: type
		}
	});
}

function getAnalyticAdvCompleted(LiveId, startTime, endTime, type) {
	// let uri = `http://46.101.75.72:22769/analytics/${LiveId}/impressions`
	const uri = `https://metrics.castr.io/analytics/ads/${LiveId}/completed`;
	return makeRequest({
		path: uri,
		method: 'post',
		data: {
			start: startTime,
			end: endTime,
			type: type
		}
	});
}


function getAnalyticAdvImpressions(LiveId, startTime, endTime, type) {
	// let uri = `http://46.101.75.72:22769/analytics/${LiveId}/impressions`
	const uri = `https://metrics.castr.io/analytics/ads/${LiveId}/impressions`;
	return makeRequest({
		path: uri,
		method: 'post',
		data: {
			start: startTime,
			end: endTime,
			type: type
		}
	});
}

function getAnalyticAdvPlays(LiveId, startTime, endTime, type) {
	// let uri = `http://46.101.75.72:22769/analytics/${LiveId}/impressions`
	const uri = `https://metrics.castr.io/analytics/ads/${LiveId}/plays`;
	return makeRequest({
		path: uri,
		method: 'post',
		data: {
			start: startTime,
			end: endTime,
			type: type
		}
	});
}

function getAnalyticAdvClickRate(LiveId, startTime, endTime, type) {
	// let uri = `http://46.101.75.72:22769/analytics/${LiveId}/impressions`
	const uri = `https://metrics.castr.io/analytics/ads/${LiveId}/clickRate`;
	return makeRequest({
		path: uri,
		method: 'post',
		data: {
			start: startTime,
			end: endTime,
			type: type
		}
	});
}

function getBitmovinAnalytics(payload) {
	const uri = `/metrics/playerAnalytics`;
	payload.endTime = new Date(new Date(payload.endTime).getTime() + 60000).toISOString();
	return makeRequest({
		path: uri,
		method: 'post',
		data: payload,
	});
}

/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeRequest(reqConfig) {
	if (typeof reqConfig === 'string') {
		reqConfig = {
			path: reqConfig
		};
	}

	reqConfig.url = reqConfig.path;

	let res;
	try {
		res = await api.request(reqConfig);
	} catch (err) {
		const edata = _.get(err, 'response.data');
		throw new RequestError(edata);
	}

	return res && res.data;
}

function getPulseApiBase(viaproxy, includePrefixPath = false) {
	let baseURL = 'https://deps.castr.io';
	let pathname = '/pulse';
	if (viaproxy) {
		baseURL = process.env.VUE_APP_APP_API_BASE;
		pathname = '/pulse/stream';
	}

	if (includePrefixPath) {
		baseURL += pathname;
	}

	return baseURL;
}
