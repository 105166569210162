<template>
  <div class="paywall">
    <header class="">
      <!-- <b-container> -->
      <b-row>
        <b-col>
          <b-button
            variant="link"
            @click="$emit('close')"
            class="d-inline-flex align-items-center px-0 mb-3 text-dark-8"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 18L9 12L15 6"
                stroke="#97A0AF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Back
          </b-button>
        </b-col>
      </b-row>
    </header>
    <section>
      <b-row>
        <b-col md="12">
          <div
            v-if="streamPaywall.price > 0"
            class="d-flex align-items-center inline-block mb-3"
          >
            <b-badge pill variant="outline-success" class="ml-0 mr-auto"
              >Active</b-badge
            >
            <b-form-checkbox
              v-if="streamPaywall.price > 0"
              @change="paywallActiveToggle()"
              v-model="paywallActive"
              :disabled="priceChangeProcessing || streamPaywall.price === 0"
              switch
            >
            </b-form-checkbox>
          </div>
          <b-card bg-variant="dark-2" class="mb-4" body-class="px-4 py-2">
            <b-list-group class="list-group-flush">
              <b-list-group-item
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  px-0
                  py-2
                "
              >
                <span class="text-s-m text-dark-7">Created date</span>
                <span>{{ paywallCreatedAt }}</span>
              </b-list-group-item>
              <b-list-group-item
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  px-0
                  py-2
                "
              >
                <span class="text-s-m text-dark-7">Price Type</span>
                <span>
                  {{
                    Number(streamPaywall.price) === 0
                      ? "Collect emails only"
                      : streamPaywall.recurring === 0 ? "Pay Per View" : 'Subscription'
                  }}</span
                >
              </b-list-group-item>
              <b-list-group-item
                v-if=" streamPaywall.recurring === 1"
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  px-0
                  py-2
                "
              >
                <span class="text-s-m text-dark-7">Subscription Period</span>
                <span>
                  {{ 
                    streamPaywall.period
                  }}</span
                >
              </b-list-group-item>
              <b-list-group-item v-if="Number(streamPaywall.price) !== 0"
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  px-0
                  py-2
                "
              >
                <span class="text-s-m text-dark-7">Currency</span>
                <span>
                  {{ streamPaywall.currency.toUpperCase() }}
                </span>
              </b-list-group-item>
              <b-list-group-item
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  px-0
                  py-2
                "
              >
                <span class="text-s-m text-dark-7">Price</span>
                <span>
                  {{ formatPrice(streamPaywall.price) }}
                </span>
              </b-list-group-item>
              <b-list-group-item
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  px-0
                  py-2
                "
              >
              <span class="text-s-m text-dark-7">Commission</span>
                <span>
                  <i
                    v-if="financialReportLoading"
                    class="fas fa-spinner fa-spin"
                  ></i>
                  <span v-else> {{ Number(streamPaywall.master.stripe_commission) }}%</span>
                </span>
              </b-list-group-item>

            </b-list-group>
          </b-card>

          <div class="d-flex">
            <div class="py-2 d-flex card  text-center bg-dark-3" style="width: 50%; flex-direction: row; justify-content: flex-start;">
                <div class="py-2 px-2 text-s-m ml-4 mr-2">
                  <div class="text-dark-8 text-s-s">Total Payments</div>
                  <i
                    v-if="financialReportLoading"
                    class="fas fa-spinner fa-spin"
                  ></i>
                  <span v-else class="text-600 mt-2" style="float: left;">{{ formatPrice(totalPayments) }}</span>
                </div>
                <div class="mx-3" style="border-left: 1px solid rgba(167, 181, 226, 0.08); "></div>
                <div class="py-2 px-2 text-s-m">
                  <div class="text-dark-8 text-s-s">Total Refunds</div>
                  <i
                    v-if="financialReportLoading"
                    class="fas fa-spinner fa-spin"
                  ></i>
                  <span v-else class="text-600 text-danger mt-2" style="float: left;">{{ formatPrice(totalRefunds) }}</span>
                </div>
            </div>

            <div class="ml-4 py-2 d-flex card  text-center bg-dark-3" style="width: 70%; flex-direction: row; justify-content: flex-start;">
                <div class="py-2 px-2 text-s-m ml-4 mr-2">
                  <div class="text-dark-8 text-s-s">Total Earnings</div>
                  <i
                    v-if="financialReportLoading"
                    class="fas fa-spinner fa-spin"
                  ></i>
                  <span v-else class="text-600 mt-2" style="float: left;">{{ formatPrice(totalPayments - totalRefunds) }} </span>
                </div>
            </div>
        </div>


          <b-list-group class="list-group-flush mb-4">
            <b-list-group-item
              class="
                d-flex
                justify-content-between
                align-items-center
                px-0
                py-0
              "
            >
              <span></span>
              <span v-if="totalRefunds > 0 || totalPayments > 0">
                <b-button
                  variant="link"
                  class="text-dark-6 font-weight-normal text-underline px-0"
                  :to="{
                    name: 'Transactions',
                    query: { stream: streamPaywall.id },
                  }"
                  >All transactions</b-button
                >
                <!-- <a href="/app/paywall/transactions">All transactions</a> -->
              </span>
            </b-list-group-item>
          </b-list-group>

          <b-list-group class="list-group-flush mb-4">
            <b-list-group-item
              class="
                d-flex
                justify-content-between
                align-items-center
                px-0
                py-2
              "
            >
              <h5 class="text-s-m text-dark-6 font-weight-normal">
                Subscription include
              </h5>
            </b-list-group-item>
            <b-list-group-item
              :href="streamPaywall._streamUrl"
              target="_blank"
              class="
                d-flex
                subscription-item
                justify-content-between
                align-items-center
                py-2
              "
            >
              <p class="d-flex align-items-center text-dark-7 text-s-m m-0">
                <span>
                  {{ stream.name }}
                </span>
              </p>
              <a :href="streamPaywall._streamUrl" target="_blank">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.28571 2H4C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V11V9.71429"
                    stroke="#A7B1C9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 9L14 2"
                    stroke="#A7B1C9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 2H14V6"
                    stroke="#A7B1C9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </section>

    <confirm-modal
      message="New users will have free access. All users who have already paid will still have access."
      modal-id="confirm-paywall-stream-toggle-off"
      ok-text="Disable"
      cancel-text="Cancel"
      @modal-confirm="onStreamPaywallDisable"
      @modal-cancel="offStreamPaywallDisable"
    />
  </div>
</template>

<script>
import moment from "moment";
import PaywallService from "@/services/PaywallService";
import ClipLoader from "@/components/ui/ClipLoader.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
  name: "PaywallSubscriptionDetails",
  components: {
    ClipLoader,
    ConfirmModal,
  },
  computed: {
    paywallCreatedAt() {
      return moment(new Date(this.streamPaywall.created_at)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
  },
  props: {
    stream: {
      type: Object,
      required: true,
      validator(v) {
        return Object.prototype.hasOwnProperty.call(v, "_id");
      },
    },
    streamPaywall: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      priceChangeProcessing: false,
      financialReportLoading: false,
      paywallActive: null,
      totalRefunds: 0,
      totalPayments: 0,
    };
  },
  async mounted() {
    // console.log(this.stream, this.streamPaywall) 

    this.paywallActive = this.streamPaywall.price > 0
    await this.loadFinancialReport();
  },
  methods: {
    paywallActiveToggle() {
      this.$root.$emit("bv::show::modal", "confirm-paywall-stream-toggle-off");
    },
    async loadFinancialReport() {
      this.financialReportLoading = true;

      try {
        const stream = this.streamPaywall.id;
        const reports = await PaywallService.getPaywallFinancialReport();
        const report = _.find(reports, { stream_id: stream });

        this.totalRefunds = _.get(report, "refunds_total", 0);
        this.totalPayments = _.get(report, "payments_total", 0);
      } catch (err) {
        this.$notify({
          group: "error",
          text: err.message || "get stream financial report failed",
        });
      }

      this.financialReportLoading = false;
      return;
    },
    async onStreamPaywallDisable() {
      this.priceChangeProcessing = true;

      try {
        const payload = {
          price: 0,
        };
        await PaywallService.updateStreamPaywall(this.stream._id, payload);

        this.$emit("price-changed", 0);
        this.$notify({
          group: "success",
          text: "Stream paywall udpated",
        });
      } catch (err) {
        this.$notify({
          group: "error",
          text: err.message || "Stream paywall update failed",
        });
      }

      this.priceChangeProcessing = false;
      return;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
		offStreamPaywallDisable() {
			this.priceChangeProcessing = false
		}
  },
};
</script>

<style scoped>
.subscription-item {
  padding: 0.5rem 0;
}
.subscription-item:hover {
  margin: 0 -0.5rem;
  padding: 0.5rem;
  width: calc(100% + 1rem);
}
</style>