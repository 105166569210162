<script setup>
import _ from 'lodash'
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment'
import IconBase from '../icon/IconBase.vue'
import IconPlus from '../icon/IconPlus.vue'
import IconCopy from '../icon/IconCopy.vue'
import IconExternalLink from '../icon/IconExternalLink.vue'
import IconBin from '../icon/IconBin.vue'
import FormButton from '../Atoms/FormButton.vue'
import VodClipTrimmingModal from '@/components/modals/VodClipTrimmingModal.vue'
import StreamService from '@/services/StreamService'
import Alert from '../Atoms/Alert.vue'
import Title from '../ui/Title.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const props = defineProps({
  vodFile: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  vodFolder: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  playerUrl: {
    type: String
  }
})

const emit = defineEmits({
  'clips-update': (clip) => { return clip },
})

const clips = ref(null)
const selectedClipIndex = ref(null)

onMounted(async () => {
  getClips()
})

watch(() => route.params, async () => {
  getClips()
})

const getClips = async () => {
  const result = await StreamService.getVodClips(route.params.streamId, props.vodFile?._id)
  clips.value = result
}

const openClipTrimmingModal = async () => {
  setTimeout(() => {
    root.$emit('bv::show::modal', 'modal-vod-clip-trimming');
  }, 100);
}
const getVodClipIframeSnippet = (clip) => {
  const iframeSrc = props.playerUrl;
  return `<iframe src="${iframeSrc}${props.vodFile.archive ? '&' : '?'}clip=${clip.id}" width="590" height="431" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
}

const getVodClipDirectUrl = (clip) => {
  const iframeSrc = props.playerUrl;
  return `${iframeSrc}${props.vodFile.archive ? '&' : '?'}clip=${clip.id}`
}

const onClipNameChange = async (newName, clip) => {
  try {
    await StreamService.setVodClipName(route.params.streamId, clip.id, newName)
    notify({ group: 'success', text: 'Clip name was changed' });
  } catch (err) {
    notify({
      group: 'error',
      title: "Couldn't change clip name",
      text: err.message
    });
  }
}
const clipboardCopy = async (value) => {
  await navigator.clipboard.writeText(value);
  notify({ group: 'info', text: 'Copied to clipboard' });
}
const removeSelectedVodClip = (index) => {
  selectedClipIndex.value = index
  root.$emit('bv::show::modal', 'confirm-clip-removal');
  return
}
const removeVideoClip = async () => {
  const clip = clips.value[selectedClipIndex.value]
  if (!clip.id) {
    clips.value.splice(selectedClipIndex.value, 1);
    return
  }
  try {
    await StreamService.deleteVodClip(route.params.streamId, props.vodFile._id, clip.id)
    clips.value.splice(selectedClipIndex.value, 1)
    emit('clips-update', clip);
    notify({ group: 'success', text: 'Clip deleted' })
  } catch (err) {
    console.log(err)
  }
}
const humanTimeRange = (duration) => {
  return moment.utc(moment.duration(duration, "s").asMilliseconds()).format("HH:mm:ss")
}
const onVodClipRangeCreated = async (clip) => {
  clips.value.push(clip)
  notify({ group: 'success', text: 'Clip added' })
  emit('clips-update', clip);
}
</script>

<template>
  <section>
    <h3 class="text-lg font-mediun mt-6 !mb-2">Clip video</h3>
    <p class="text-surface-8 text-sm !mb-2">Cut a clip from your videos and start sharing.</p>
    <FormButton class="" @click="openClipTrimmingModal()">
      <icon-base class="mr-2 shrink-0" fill="transparent">
        <icon-plus />
      </icon-base>
      Add new clip
    </FormButton>
    <hr class="my-6 border-surface-3">
    <Alert class="!mb-4" text="If the source file is deleted, all the clips generated from it will also be removed." />
    <ul v-if="clips?.length > 0" class="divide-y divide-surface-3">
      <li class="flex gap-x-2 text-surface-6 font-medium text-sm !py-2">
        <h3 class="basis-3/12">Clip Video</h3>
        <h3 class="basis-9-12">Embed Player URL</h3>
      </li>
      <li v-for="(clip, index) in clips" class="!py-4 grid gap-3 grid-cols-[1fr_3fr_auto]" :key="clip.id">
        <div>
          <Title :editable="true" @changed="value => onClipNameChange(value, clip)" class="basis-3/12"
            :title="clip.name" />
          <span class="text-surface-8 text-tiny">{{ humanTimeRange((clip.endSec - clip.startSec) + 1) }}</span>
        </div>
        <textarea readonly class="bg-surface-3 w-full rounded-md p-2 text-sm block" rows="3"
          @click="clipboardCopy(getVodClipIframeSnippet(clip))" :value="getVodClipIframeSnippet(clip)"></textarea>
        <FormButton @click="removeSelectedVodClip(index)" type="danger" isIcon size="sm">
          <icon-base class="shrink-0" fill="transparent">
            <icon-bin />
          </icon-base>
        </FormButton>
        <div class="md:col-start-2 col-span-3 md:cols-span-auto flex gap-x-2 !my-1 justify-end md:justify-start">
          <FormButton @click="clipboardCopy(getVodClipIframeSnippet(clip))" size="sm" type="secondary">
            <icon-base class="mr-2 shrink-0" fill="transparent">
              <icon-copy />
            </icon-base>
            Copy to Clipboard
          </FormButton>
          <FormButton @click="clipboardCopy(getVodClipDirectUrl(clip))" size="sm" type="secondary">
            <icon-base class="mr-2 shrink-0" fill="transparent">
              <icon-copy />
            </icon-base>
            Copy Direct Url
          </FormButton>
          <FormButton target="_blank" :href="getVodClipDirectUrl(clip)" type="secondary" isIcon size="sm">
            <icon-base class="shrink-0" fill="transparent">
              <icon-external-link />
            </icon-base>
          </FormButton>
        </div>
      </li>
    </ul>
    <confirm-modal :message="'You are about to delete clip'" modal-type="danger" modal-id="confirm-clip-removal"
      ok-text="Delete" cancel-text="Keep" @modal-confirm="removeVideoClip" />
    <vod-clip-trimming-modal v-if="props.vodFile?.srcHls" :hlsUrl="props.vodFile.srcHls" :video="props.vodFile"
      @request-vod-clip-range-created="onVodClipRangeCreated" />
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
