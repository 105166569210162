import _ from 'lodash'
import RequestError from './RequestError'
import api from '@/api'

export default {
	createOrigin,
    getOrigins,
    updateOrigin,
    deleteOrigin,
    toggleOrigin,
    getOriginServiceBandwidth,
    getUserOriginBandwidth
}

function getUserOriginBandwidth() {
	return makeRequest({
		path: `/metrics/origin/bandwidth`,
		method: 'get',
	});
}

function getOriginServiceBandwidth(origin) {
	return makeRequest({
		path: `/metrics/origin/${origin}/bandwidth`,
		method: 'get',
	});
}

function toggleOrigin(origin) {
    return makeRequest({
          path: `/origin/${origin}/toggle`,
          method: 'post',
      });
  }

function deleteOrigin(origin) {
    return makeRequest({
        path: `/origin/${origin}`,
        method: 'delete',
    });
}

function createOrigin(name) {
	return makeRequest({
		method: 'post',
		path: `/origin`,
		data: { name }
	})
}

function getOrigins() {
	return makeRequest({
		path: '/origin',
		method: 'get',
	});
}

function updateOrigin(id, data) {
	return makeRequest({
		method: 'put',
		path: `/origin/${id}`,
		data: data
	})
}

async function makeRequest(reqConfig) {
	if (typeof reqConfig === 'string') {
		reqConfig = {
			path: reqConfig
		}
	}

	reqConfig.url = reqConfig.path

	let res
	try {
		res = await api.request(reqConfig)
	} catch (err) {
		let edata = _.get(err, 'response.data')
		throw new RequestError(edata)
	}

	return res.data
}