import TranscoderService from "@/services/TranscoderService";
import router from "@/router";
import _ from "lodash"

const defaultState = () => ({
  transcoders: [],
  transcoder: {},
  currentTranscoderId: "",
  transcoderLoaded: false
});

const state = defaultState();

const mutations = {
  GET_TRANSCODERS(state, transcoders) {
    state.transcoders = transcoders
  },
  GET_TRANSCODER(state, transcoder) {
    state.transcoder = transcoder
    state.transcoderLoaded = true
  },
  SET_CURRENT_TRANSCODER(state, id) {
    state.currentTranscoderId = id
  },
};

const actions = {
  async getTranscoders({ commit }) {
    const transcoders = await TranscoderService.getTranscoders()
    transcoders.map( item => {
      if (item.metadata) {
        const deinterlace = _.get(this.transcoder, ['metadata', 'deinterlace'], false)
        const audioTrackEnabled = _.get(this.transcoder, ['metadata', 'audioTrackEnabled'], false)
        item.metadata.deinterlace = deinterlace
        item.metadata.audioTrackEnabled = audioTrackEnabled
      }
    })
    commit("GET_TRANSCODERS", transcoders)
  },
  async getTranscoder({ commit }, id) {
    const transcoder = await TranscoderService.getTranscoder(id)
    commit("GET_TRANSCODER", transcoder)
  },
  setCurrentTranscoder({ commit }, id) {
    commit("SET_CURRENT_TRANSCODER", id)
  },
};

const getters = {
  transcoders: (state) => state.transcoders,
  transcoder: (state) => state.transcoder,
  currentTranscoder: (state) => {
    if (state.currentTranscoderId === '') {
      return state.transcoders.find(
        (ts) =>
            ts._id === (router.currentRoute.params && router.currentRoute.params.transcoderId)
      );
    } else {
      return state.transcoders.find((ts) => ts._id === state.currentTranscoderId);
    }
  },
  transcoderLoaded: (state) => state.transcoderLoaded,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
