<template>
	<div class="">
		<b-modal
			:id="'modalGetAccessToken'"
			:ref="'modalGetAccessToken'"
			:size="modalSize"
			hide-header
			hide-footer
			centered
		>
		<b-row>
			<b-col><h4 class="text-s-xl">"{{ accessToken.name }}" Access Token</h4></b-col>
		</b-row>
		<b-row>
			<b-col class="text-s-s mb-2 mt-2">Access Token ID</b-col>
		</b-row>
    <b-row>
      <b-col>
        <b-input-group size="md" class="mb-3">
          <b-form-input :value="accessToken.accessId" @click="doCopy(accessToken.accessId)" readonly></b-form-input>
          <b-input-group-append>
            <b-button class="py-1 px-2" variant="icon" size="md" @click="doCopy(accessToken.accessId)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
			<b-col class="text-s-s mb-2 mt-2">Secret Key</b-col>
		</b-row>
    <b-row>
      <b-col>
        <b-input-group size="md" class="mb-3">
          <b-form-input :value="accessToken.secret" @click="doCopy(accessToken.secret)" readonly></b-form-input>
          <b-input-group-append>
            <b-button class="py-1 px-2" variant="icon" size="md" @click="doCopy(accessToken.secret)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert show variant="secondary" class="text-dark-7 text-s-s d-flex mt-3 p-2">
          <svg class="mr-2 mt-1 flex-shrink-0" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="currentColor"/>
          </svg>
          <div>
            <p class="m-0 text-dark-7 text-s-s">We do not store the secret keys, so ensure you save them in a secure location.</p>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <hr>
		<b-row >
			<b-col class="text-right mt-3">
				<b-button variant="dark-3" @click="dismiss" class="mr-2" size="md">Close</b-button>
			</b-col>
		</b-row>
		</b-modal>
	</div>
</template>

<script>
import AccessTokenService from '@/services/AccessTokenService';

export default {
	name: 'GetAccessTokenModal',
	components: {},
	props: {
		accessToken: {
			type: Object,
			default() { return {}; }
		},
		modalSize: {
			type: String,
			default: 'md',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
		modalType: {
			type: String,
			default: 'primary'
		},
		body: {
			type: String,
			required: false
		},
		streamCat: {
			type: String
		},
		okText: {
			type: String,
			default: 'Disable'
		},
		cancelText: {
			type: String,
			default: 'Cancel'
		}
	},
	data() {
		return {
			processing: false,
			tokenName: '',
		};
	},
	methods: {
    doCopy(text) {
			try {
				navigator.clipboard.writeText(text);
				this.$notify({ group: 'info', text: 'Copied to clipboard' });
			} catch (e) {}
		},
		onConfirm() {
			this.$emit('modal-toggle');
			this.dismiss();
		},
		dismiss() {
			this.$emit('modal-cancel');
			this.$refs['modalGetAccessToken'].hide();
			this.$emit('modal-dismiss');
			// lazy clear
			setTimeout(() => { this.processing = false; }, 1000);
		}
	},
	watch: {
	}
};
</script>

<style scoped>

</style>
