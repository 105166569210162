<script setup>
import { computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import IconBase from '../icon/IconBase.vue';
import IconBin from '../icon/IconBin.vue';
import FormButton from '../Atoms/FormButton.vue';
import ToggleStreamModal from '@/components/modals/ToggleStreamModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import StreamService from '../../services/StreamService';
import { useRouter } from 'vue-router';
import { useNotification } from "@kyvg/vue3-notification";

const { notify }  = useNotification()

const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const router = useRouter()

const playout = computed(() => store.getters['Streams/tvPlayout'])

const toggleLabel = computed(() => playout.value?.enabled ? 'Disable' : 'Enable')

const toggleStatus = (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  root.$emit('bv::show::modal', 'modalToggleStream' + playout.value._id, '#btnShow')
}
const onDeletePlayout = async () => {
  root.$emit("bv::hide::modal", 'delete-playout-' + playout.value._id);
  try {
    await StreamService.deleteStream(
      playout.value._id
    );
    notify({ group: 'info', text: 'TV Playout Deleted' })
    router.push({ name: 'TVPlayoutDashboard' })
  } catch (err) {
    console.log(err);
  }
}
const showDeleteModal = () => {
  root.$emit("bv::show::modal", 'delete-playout-' + playout.value._id);
}
</script>

<template>
  <div>
    <div class="flex items-center gap-x-2">
      <FormButton type="secondary"  @click="toggleStatus">
        {{ toggleLabel }}
      </FormButton>
      <FormButton type="danger" isIcon @click="showDeleteModal">
        <icon-base class="" fill="none">
          <icon-bin />
        </icon-base>
      </FormButton>
    </div>
    <toggle-stream-modal :stream="playout" @modal-toggle="toggleStatus" />
    <confirm-modal :modalId="'delete-playout-' + playout?._id" message="Are you sure you want to delete your TV Playout?"
      modal-type="danger" @modal-confirm="onDeletePlayout" />
  </div>
</template>

<style scoped></style>
