<script>
export default {
  compatConfig: {
    MODE: 3,
    COMPONENT_V_MODEL: false,
  }
}
</script>
<script setup>
import { computed } from 'vue'

import { usePassthroughValue } from '@/Utils/usePassthroughValue'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'radio',
    validator: (value) => ['checkbox', 'radio', 'switch'].includes(value)
  },
  label: {
    type: String,
    default: null
  },
  labelPosition: {
    type: String,
    default: 'right'
  },
  size: {
    type: String,
    default: 'md',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  inputValue: {
    type: [String, Number, Boolean],
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits({
  'update:modelValue': (value) => {return value}
})
const checked = usePassthroughValue(props, emit)

</script>

<template>
  <label class="flex" :class="type">
    <input :disabled="disabled" v-model="checked" type="checkbox" :name="name" :value="inputValue" :class="[{'order-2': labelPosition === 'left'}, 'checkbox-' + size]" />
    <span class="check" />
    <span class="pl-1" :class="{'order-1': labelPosition === 'left'}">{{ label }}</span>
  </label>
</template>