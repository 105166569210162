<template>
	<div>
		<div class="player-container">
			<video class="player" v-if="playbackPaused" :src="videoPauseImgUrl"></video>
			<div v-if="!buffered" class="player-loading-indicator position-absolute">
				<Spinner text="" classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
			</div>
			<div v-if="playback" class="player-controls-wrapper">
				<div><b-badge variant="success">Live</b-badge></div>

				<div :class="{ 'playing': playing }" class="player-controls">
					<div class="d-flex mt-5">
						<b-button v-if="!playbackPaused" variant="pause" size="md" class="mr-2 btn_pause px-2"
							@click="stopPlayback()">
							<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect x="0.5" y="0.5" width="13" height="13" rx="2.5" fill="white" />
							</svg>
						</b-button>
						<b-button v-else variant="pause" size="md" class="btn_pause px-0" @click="initPlaybackSetup()">
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M6.94286 3.04444L11.8773 5.60306C13.6617 6.52828 14.5538 6.99088 14.7057 7.67487C14.7533 7.88902 14.7533 8.11098 14.7057 8.32513C14.5538 9.00912 13.6617 9.47172 11.8773 10.3969L6.94286 12.9556C5.38894 13.7613 4.61198 14.1642 3.99792 13.946C3.80415 13.8772 3.6266 13.7693 3.47629 13.6289C3 13.1842 3 12.309 3 10.5586V5.44138C3 3.69099 3 2.81579 3.47629 2.37106C3.6266 2.23072 3.80415 2.12279 3.99792 2.05396C4.61198 1.83584 5.38894 2.23871 6.94286 3.04444Z"
									fill="white" />
							</svg>
						</b-button>
						<b-button v-if="!controls.volume" variant="mute" size="md" class="btn_mute px-0" @click="toggleVolume">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M3 3L15 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M3.18665 6.68391C3.02535 6.48228 2.73116 6.43265 2.5529 6.61945C2.21035 6.97842 2 7.46464 2 8V12C2 13.1046 2.89543 14 4 14H7.44603C7.80852 14 8.16419 14.0985 8.47502 14.285C8.78044 14.4683 9.11128 14.0897 8.88878 13.8116L3.18665 6.68391ZM12.2932 15.6659C12.4604 15.8749 12.7795 15.9174 12.8981 15.6773C12.9629 15.5461 13 15.3964 13 15.2338V4.76619C13 3.9889 12.152 3.50879 11.4855 3.9087L8.47502 5.71499C8.16419 5.90149 7.80852 6 7.44603 6H5.60077C5.18152 6 4.94843 6.48497 5.21034 6.81235L12.2932 15.6659ZM15.7764 14.7765C16.0438 15.0439 16.4734 15.0691 16.7425 14.8034C17.9819 13.5796 18.75 11.8795 18.75 10.0001C18.75 8.12066 17.9819 6.42064 16.7425 5.19682C16.4734 4.9311 16.0438 4.9563 15.7764 5.22371C15.4601 5.54 15.4993 6.06088 15.8073 6.38521C16.7014 7.32659 17.25 8.59926 17.25 10.0001C17.25 11.4009 16.7014 12.6736 15.8073 13.615C15.4992 13.9393 15.4601 14.4602 15.7764 14.7765Z"
									fill="white" />
							</svg>
						</b-button>
						<b-button v-else variant="mute" size="md" class="btn_mute px-0" @click="toggleVolume">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M1.5 8C1.5 6.89543 2.39543 6 3.5 6H5.94603C6.30852 6 6.66419 5.90148 6.97502 5.71499L9.9855 3.9087C10.652 3.50878 11.5 3.9889 11.5 4.76619V15.2338C11.5 16.0111 10.652 16.4912 9.9855 16.0913L6.97502 14.285C6.66419 14.0985 6.30852 14 5.94603 14H3.5C2.39543 14 1.5 13.1046 1.5 12V8Z"
									fill="white" />
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M16.3888 14.1025C16.1216 14.4664 16.1751 14.9915 16.5458 15.2493C16.8542 15.4639 17.2773 15.416 17.5054 15.1175C18.6012 13.6836 19.2499 11.9193 19.2499 10C19.2499 8.08082 18.6012 6.31652 17.5055 4.88265C17.2774 4.58408 16.8543 4.53623 16.5458 4.75081C16.1752 5.00862 16.1217 5.5337 16.3888 5.89759C17.2491 7.0692 17.7499 8.48537 17.7499 10C17.7499 11.5147 17.2491 12.9309 16.3888 14.1025ZM14.0999 13.5479C14.389 13.749 14.7848 13.7176 15.0075 13.4447C15.7845 12.4928 16.2499 11.3059 16.2499 10C16.2499 8.69421 15.7845 7.5073 15.0076 6.55542C14.7849 6.28255 14.3891 6.25116 14.1 6.4523C13.713 6.72147 13.684 7.28214 13.9635 7.66167C14.463 8.34005 14.7499 9.14821 14.7499 10C14.7499 10.8519 14.463 11.6601 13.9634 12.3385C13.6839 12.718 13.7129 13.2787 14.0999 13.5479Z"
									fill="white" />
							</svg>
						</b-button>
					</div>
					<b-button variant="pause" size="md" class="btn_fullscreen px-0" @click="toggleFullscreen">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M18 7V6C18 5.07003 18 4.60504 17.8978 4.22354C17.6204 3.18827 16.8117 2.37962 15.7765 2.10222C15.395 2 14.93 2 14 2V2H13M18 13V13.2C18 14.8802 18 15.7202 17.673 16.362C17.3854 16.9265 16.9265 17.3854 16.362 17.673C15.7202 18 14.8802 18 13.2 18H13M2 13V14V14C2 14.93 2 15.395 2.10222 15.7765C2.37962 16.8117 3.18827 17.6204 4.22354 17.8978C4.60504 18 5.07003 18 6 18H7M2 7V6V6C2 5.07003 2 4.60504 2.10222 4.22354C2.37962 3.18827 3.18827 2.37962 4.22354 2.10222C4.60504 2 5.07003 2 6 2V2H7"
								stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</b-button>
				</div>

			</div>
			<video id="player" :class="{ 'container-size': stream.type === 'ipcam' || stream.type === 'scheduled' }" />
		</div>
	</div>
</template>

<script>
import Spinner from "@/components/ui/Spinner.vue";
import MSEPlayer from '@flussonic/flussonic-mse-player';

export default {
	name: 'StreamPlayer',
	components: {
		Spinner
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		mediaPulse: {
			type: Object,
			default() { return {}; }
		},
		streamUrl: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			bufferWaitTimeout: null,
			playing: false,
			playback: false,
			playbackUrl: null,
			playbackPaused: false,
			videoPauseImgUrl: null,
			buffered: true,
			controls: {
				volume: false,
				fullscreen: false
			},
			scopeAlive: true,
			flashDisabled: false,
			videoDOM: null,
			videoPlayer: null
		};
	},
	mounted() {
		window.FlussonicMsePlayer = MSEPlayer;
		this.playbackUrl = this.streamUrl;

		const hostId = this.mediaPulse && this.mediaPulse.hostId;
		if (hostId) {
			const staticPrefix = this.mediaPulse.staticPrefix;
			const anc = document.createElement('a');
			anc.href = this.playbackUrl;

			if (/\w+-\d+/gi.test(hostId) || this.stream.pullUrl) {
				anc.hostname = `${hostId}.castr.io`;
				anc.pathname = anc.pathname.replace(this.stream.key, staticPrefix ? `static/${this.mediaPulse.name}` : this.mediaPulse.name);
			}

			this.playbackUrl = anc.href;
		}

		this.initPlaybackSetup();
	},
	destroyed() {
		this.scopeAlive = false;
		// window.alert('player comp destroyed')
		this.stopPlayback();
		// this.videoPlayer.stop();
	},
	methods: {
		initPlaybackSetup() {
			if (!this.scopeAlive) return;

			this.playbackPaused = false;

			const element = document.getElementById('player');

			if (!element) return;

			this.videoDOM = element;
			this.videoDOM.volume = 0;

			const playerConfig = {
				bufferMode: 'sequence',
				onProgress: () => {
					this.playing = true;
				},
				onMediaInfo: () => {
					this.playback = true;
				},
				onError: () => {
					if (!this.scopeAlive) return;

					this.stopPlayback(() => {
						if (!this.scopeAlive) return;

						window.player.play();
						if (window.player.setBufferMode) { window.player.setBufferMode('segments'); }
					});
				}
			};

			// eslint-disable-next-line no-undef
			const playerInstance = new FlussonicMsePlayer(
				element,
				this.playbackUrl,
				playerConfig
			);

			this.buffered = false;
			playerInstance.play();

			const bufferWaitTimeout = setTimeout(() => {
				this.stopPlayback(() => {
					this.initPlaybackSetup();
				});
			}, 15 * 1000);

			window.player = this.videoPlayer = playerInstance;

			// DOM events
			if (element) {
				element.onplaying = () => {
					this.buffered = true;
					clearTimeout(bufferWaitTimeout);
				};
			}
		},
		stopPlayback(cb) {
			// const { player } = window;
			const player = this.videoPlayer;
			this.playing = false;
			if (this.controls.volume) {
				this.toggleVolume();
			}
			const regex = /(\/mse_).*/s;
			const str = this.playbackUrl;
			const subst = ``;
			const result = str.replace(regex, subst);
			this.videoPauseImgUrl = 'https' + result.substring(3) + '/preview.mp4';
			try {
				player.pause();
			} catch (e) { }

			setTimeout(async () => {
				player.stop();
				this.playbackPaused = true;

				if (player.stopPromise) {
					await player.stopPromise;
				}
				if (cb) {
					cb();
				}
			}, 500);
		},
		toggleVolume() {
			const oldVolume = this.videoDOM.volume;
			this.videoDOM.volume = oldVolume === 1 ? 0 : 1;

			this.controls.volume = this.videoDOM.volume === 1;
		},
		toggleFullscreen() {
			const elem = this.videoDOM;
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.mozRequestFullScreen) {
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullscreen) {
				elem.webkitRequestFullscreen();
			}
		}
	}
};
</script>

<style scoped>
.badge {
	position: absolute;
	left: 10px;
	top: 10px;
	width: 48px;
}

.btn_pause {
	position: absolute;
	bottom: 10px;
	left: calc(50% - 48px);
	z-index: 11;
}

.btn_mute {
	position: absolute;
	bottom: 10px;
	left: calc(50% + 12px);
	z-index: 11;
}

.btn_fullscreen {
	position: absolute;
	bottom: 10px;
	right: 10px;
	z-index: 11;
}

.player {
	position: absolute;
	top: 0;
	left: 0;
}
</style>
