<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import TabItem from './TabItem.vue';
import FormButton from './FormButton.vue';



const props = defineProps({
  size: {
    type: String,
    default: 'md',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  wrapper: {
    type: String,
    default: 'ul'
  },
  type: String,
  default: 'buttons'
})

const computedClass = computed(() => props.type === 'lines'
  ? 'inline-flex border-b border-b-surface-3 gap-x-3 max-w-full overflow-x-auto overflow-y-hidden no-scrollbar'
  : 'inline-flex flex-wrap !p-1 rounded bg-surface-2')
</script>

<template>
  <component :is="props.wrapper" :class="computedClass">
      <slot />
  </component>
</template>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
</style>
