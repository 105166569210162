import axios from 'axios';
import store from '@/store';
import _ from 'lodash';
import UserService from './services/UserService';

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_APP_API_BASE,
	timeout: 30 * 1000,
	// withCredentials: true,
	config: {
		headers: {
			'content-type': 'application/json'
		}
	}
});

// Request interceptor:
axiosInstance.interceptors.request.use((request) => {
	if (request.url && request.url.includes('stats.castr.io')) {
		return request;
	}

	const token = UserService.getUserToken();
	if(token) {
		request.headers['auth-token'] = token;
	}

	return request;
}, (error) => {
	console.log('err', error)
	throw error;
});

// Response interceptor:
axiosInstance.interceptors.response.use((response) => response, async (error) => {
	if (_.get(error,'response.status') === 401) {
		await store.dispatch('Auth/logout');
		console.log('auth redirect: status code 401');

		const { href } = window.location;
		if (!/login|signin|signup|forgot-password|samford/gi.test(href))  {
			setTimeout(() => {
				window.location.href = process.env.VUE_APP_API_ENV === 'prod' ? '/signin' : '/app/login';
			});
			return;
		}
	}

	return Promise.reject(error);
});

export default axiosInstance;
