<template>
  <div class="view-wrapper upgrade-page text-center" :style="{ 'min-height': minWindowHeight || 'auto' }">
    <div class="d-flex justify-content-center upgrade-page-header">
      <b-link to="/manage/sub-accounts" class="text-dark-7 ml-0 d-flex align-items-center">
        <svg width="6" height="12" class="mr-2" viewBox="0 0 8 14" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M7 13L1 7L7 1" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg> Back
      </b-link>
      <h3 class="page-title text-500 mt-4 col-lg-6 mx-auto">
        Select a subscription plan for the {{subAccount && subAccount.email}} sub-account
      </h3>
    </div>
    <div class="form">
     <div v-if="processing"
          style="display:flex;justify-content:center;align-items:center;">
          <b-progress :value="100"
                    :max="100"
                    animated
                    class=""
                    style="height: 10px;width:300px;"></b-progress>
     </div>
     <div v-else >
      <b-tabs content-class="subscription_packages">
        <b-tab  v-for="(packCat, index) in packCategories"
                :key="index"
                @click="selectSubscriptionPackageCategory(packCat)" 
                :title="packCat.label" 
                :class="packCat.label"
                :active="(packCategory && packCategory.value === packCat.value) ? true : false"
        > 
            <div class="yearly-switch">
              <p>Monthly</p>
                  <toggle-button
                      :value="monthly"
                      :color="{ checked: 'var(--c-dark-4) !important', unchecked: 'var(--c-success-2)', disabled:'#cccccc'}"
                      :width="36"
                      :height="22"
                      :font-size="9"
                      :sync="true"
                      style=""
                      @change="toggleYearly()"
                    />
                    <p>Yearly <span class="text-normal">- {{ packCat.value == 'live' ? 'Save up to 37%' : 'Get 2 months free' }}</span></p>
            </div>

            <div class="n-feature-wrapper n-feature-multistream">
              <div class="n-feature-single show-check">
                <div class="n-feature-name d-flex align-items-center justify-content-start mb-1">
                  <img class="castr-check-enabled" src="@/assets/icons/checkmark-circle.svg" alt="o">
                  <img class="castr-circle-disabled" src="@/assets/icons/checkmark-circle-close.svg" alt="o">
                  <span class="ml-2 text-s-m text-500">Multistream</span>
                </div>
                <p>Stream to multiple social channels at the same time</p>
              </div>
              <div class="n-feature-single show-check">
                <div class="n-feature-name d-flex align-items-center justify-content-start mb-1">
                  <img class="castr-check-enabled" src="@/assets/icons/checkmark-circle.svg" alt="o">
                  <img class="castr-circle-disabled" src="@/assets/icons/checkmark-circle-close.svg" alt="o">
                  <span class="ml-2 text-s-m text-500">Pre-Recorded Live</span>
                </div>
                <p>Schedule pre-recorded videos as livestreams</p>
              </div>
              <div class="n-feature-single show-close">
                <div class="n-feature-name d-flex align-items-center justify-content-start mb-1">
                  <img class="castr-check-enabled" src="@/assets/icons/checkmark-circle.svg" alt="o">
                  <img class="castr-circle-disabled" src="@/assets/icons/checkmark-circle-close.svg" alt="o">
                  <span class="ml-2 text-s-m text-500 text-dark-9">HTML5 Player</span>
                </div>
                <p class="text-dark-7">Only available in <span  @click="selectSubscriptionPackageCategory(packCategories[1])">All In One plans</span></p>
              </div>
              <div class="n-feature-single show-close">
                <div class="n-feature-name d-flex align-items-center justify-content-start mb-1">
                  <img class="castr-check-enabled" src="@/assets/icons/checkmark-circle.svg" alt="o">
                  <img class="castr-circle-disabled" src="@/assets/icons/checkmark-circle-close.svg" alt="o">
                  <span class="ml-2 text-s-m text-500 text-dark-9">Video Hosting</span>
                </div>
                <p class="text-dark-7">Only available in <span  @click="selectSubscriptionPackageCategory(packCategories[1])">All In One plans</span></p>
              </div>
            </div>

            <div class="n-feature-wrapper n-feature-allinone">
              <div class="n-feature-single show-check">
                <div class="n-feature-name d-flex align-items-center justify-content-start mb-1">
                  <img class="castr-check-enabled" src="@/assets/icons/checkmark-circle.svg" alt="o">
                  <span class="ml-2 text-s-m text-500">Embeddable Player</span>
                </div>
                <p>Embed videos to host events on your website</p>
              </div>
              <div class="n-feature-single show-check">
                <div class="n-feature-name d-flex align-items-center justify-content-start mb-1">
                  <img class="castr-check-enabled" src="@/assets/icons/checkmark-circle.svg" alt="o">
                  <span class="ml-2 text-s-m text-500">Live to VOD</span>
                </div>
                <p>Share your livestreams as on-demand videos in 4K</p>
              </div>
              <div class="n-feature-single show-check">
                <div class="n-feature-name d-flex align-items-center justify-content-start mb-1">
                  <img class="castr-check-enabled" src="@/assets/icons/checkmark-circle.svg" alt="o">
                  <span class="ml-2 text-s-m text-500">Pre-Recorded Live</span>
                </div>
                <p>Schedule pre-recorded videos as livestreams</p>
              </div>
              <div class="n-feature-single show-check">
                <div class="n-feature-name d-flex align-items-center justify-content-start mb-1">
                  <img class="castr-check-enabled" src="@/assets/icons/checkmark-circle.svg" alt="o">
                  <span class="ml-2 text-s-m text-500">Multistream</span>
                </div>
                <p>Stream to multiple social channels at the same time</p>
              </div>
            </div>
            <div class="package-card-wrapper" :class=" monthly ? 'monthly-plans' : 'yearly-plans' ">

            <div class="package-card" v-for="(pack, index) in filteredPackages"
              :key="index">
                <div class="package-heading"
                  :class="{ selected: subscriptionPackage === pack }">
                  <div class="package-info">
                  <p class="package-name text-s-xxl text-500" v-html ="getPackageName(pack)" />

                  <div v-if="pack.baseCharge > 0">
                    <p v-if="!isAnnualPackage(pack)" class="package-price" :class="isAnnualPackage(pack) ? 'mb-1' : ''">${{pack.baseCharge}}<span style="text-transform:none;">/month</span></p>
                    <div class="mb-3" v-else>
                      <div class="" v-for="(mp, index) in PackageCharacteristics" :key="index">
                        <span class="package-price" v-if="pack.name.includes(mp.info.name)">${{mp.info.yearly}}<span>/month</span></span>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p class="package-price">Free</p>
                  </div>
                </div>
                <b-button size="md" @click="selectSubscriptionPackage(pack)" variant="primary" class="package-btn w-100 px-0" :class=" {'selected' : (subscriptionPackage === pack), 'current-plan' : isCurrentSubscription(pack)} " :disabled="isCurrentSubscription(pack)">
                  <span v-if="isCurrentSubscription(pack)" class="">Current</span>
                  <span v-else>
                    {{ (subscriptionPackage === pack) ? 'Selected' : (monthly ? 'Subscribe' : getSavedamount(pack)) }}
                  </span>
                </b-button>
                <p v-if="isAnnualPackage(pack)" class="text-dark-7 text-small text-center mt-2 mb-0"><span>Billed as ${{pack.baseCharge}} yearly</span></p>
                </div>
                <div class="package-characteristics"
                  :class="{ selected: subscriptionPackage === pack }">
                    <div v-for="(mp, index) in PackageCharacteristics" :key="index">
                      <ul v-if="pack.name.includes(mp.info.name)">
                        <li v-for="(ch, index) in mp.charectiristics" :key="index">
                          <b-button class="mr-1" v-b-tooltip.hover="{ customClass: 'ch-popover-btn' }" :title="ch.details">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                          </b-button>
                          {{ch.name}} 
                          </li>
                      </ul>
                    </div>
                </div>
            </div>
            
            <div class="package-card" v-if="packCategory.label == 'multistream'">
              <div class="package-heading no-background">
                <div class="package-info">
                  <p class="package-name text-s-xxl text-500">Want more features?</p>
                  <p class="package-price text-dark-8 text-s-l"><span style="text-transform: none;">Our All in One Plans start at $50/month</span></p> 
                </div>
                <button type="button" class="btn package-btn w-100 btn-outline-light btn-md" @click="selectSubscriptionPackageCategory(packCategories[1])">
                  <span>All in One Plans</span>
                </button>
              </div>
              <div class="package-characteristics"></div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div class="full-plan-comparison no-background mx-auto">
        <a target="_blank" class="btn package-btn w-100 btn-outline-light btn-md" href="https://castr.com/pricing/#full_plan_castr">See full plan comparison </a>
      </div>
      <div class="custom-plan" v-if="packCategory.value == 'live'">
        <div class="c-plan-left">
          <h4>Custom Plan</h4>
          <p>Contact us for custom plan that fit your company's need</p>
        </div>
        <div class="c-plan-right">
          <b-button  variant="secondary" size="md"  onclick="Intercom('show')"> 
            <i class="fa fa-comment-dots"></i> Contact us
          </b-button>
        </div>
      </div>
      <div id="reciept-wrapper" class="receipt-wrapper" v-if="subscriptionPackage">
      <div class="subscription-reciept">

      <div class="reciept-row reciept-row-top">
        <div class="reciept-row-left">
          <p>{{subscriptionPackage.name}}</p>
          <p v-if="subscriptionPackage.subscriptionTerm">
              <span class="package-price">
                Subscription Terms : &nbsp;&nbsp;&nbsp; {{subscriptionPackage.subscriptionTerm}}
              </span>
              <!-- <span v-if="!(subscriptionPackage.baseCharge > 0)" class="package-price">Free</span> -->
          </p>
        </div>
        <div class="reciept-row-right">
          <p v-if="subscriptionPackage">${{getTotalFee()}}</p>
          <p v-else>--</p>
        </div>
      </div>
      <div v-if="subscriptionPackage" class="reciept-row reciept-row-coupon">
        <div class='reciept-row-left w-100' v-if="isShowCouponCode">
            <b-button variant="link" style="padding: 0" @click="gotaCouponCode" v-if="!isFillInCouponCode">Got a coupon code?</b-button>
            <div class="coupon-form d-flex align-items-center justify-content-start" :class="(!couponCode.isValid && couponCode.status) ? 'coupon-code-error' : ''" v-if="isFillInCouponCode">
              <input class="input mr-1" v-model="couponCode.value"/>
              <b-button variant="secondary" size="sm" class="coupon-btn" @click="applyCouponCode">Apply</b-button>
            </div>
            <div class="coupon-status mt-1" v-if="couponCode.isValid && couponCode.status"><p style="color: green;margin-bottom: 0px">{{couponCode.status}}</p></div>

            <div class="coupon-status mt-2 mb-0 d-flex align-items-center" v-if="!couponCode.isValid && couponCode.status"><i class="fas mr-1 fa-exclamation-circle c-text-error"></i><p class="text-small c-text-error">{{couponCode.status}}</p></div>
        </div>
      </div>
      <div v-if="hasFee()" class="reciept-row">
        <div class="reciept-row-left">
          <p>Valid till</p>
        </div>
        <div class="reciept-row-right">
          <p>{{getEndingDate() | date('DD-MM-YYYY')}}</p>
        </div>
      </div>

      <div class="reciept-row">
        <div class="reciept-row-left">
          <p>Base Charge</p>
        </div>
        <div class="reciept-row-right">
          <p v-if="subscriptionPackage">${{getSubscriptionFee()}}</p>
          <p v-else>--</p>
        </div>
      </div>

      <div class="reciept-row payable-amount">
        <div class="reciept-row-left">
          <p>Payable Amount</p>
        </div>
        <div class="reciept-row-right">
          <p v-if="subscriptionPackage">${{getTotalFee()}}</p>
          <p v-else>--</p>
        </div>
      </div>

      <div class="reciept-row reciept-row-final">
        <div class="reciept-row-left">
          <p>Subtotal</p>
        </div>
        <div class="reciept-row-right">
          <p v-if="subscriptionPackage">${{getTotalFee()}}</p>
          <p v-else>--</p>
        </div>
      </div>

      <div class="reciept-row receipt-total">
        <div class="reciept-row-left">
          <p>Total</p>
        </div>
        <div class="reciept-row-right">
          <p v-if="subscriptionPackage">${{getTotalFee()}}</p>
          <p v-else>--</p>
        </div>
      </div>
      <div v-if="subscriptionPackage && paymentMethodsVisible" class="reciept-row reciept-row-payment-method" style="">
          <div class="reciept-row-left"><p>Pay With</p></div>
          <div class="value currency reciept-row-right">
            <b-dropdown class="package-dropdown w-100" v-if="subscriptionPackage.slug.includes('multistream')">
            <template slot="button-content">
              <div v-if="!subscriptionPackage" style="display:inline-block;">Select</div>
              <div v-else
                    class="package-dropdown-item package-dropdown-item-placeholder text-uppercase"
                    style="display: inline-block;margin-right: 10px;">
                <span class="package-name">{{selectedPaymentMethod.label}}</span>
              </div>
            </template>
            <b-dropdown-item v-for="method in availablePaymentMethods"
                              :key="method.value"
                              @click="selectPaymentMethod(method)">
              <div class="a package-dropdown-item"
                    :class="{ selected: selectedPaymentMethod.value === method.value }">
                    <span class="package-name">{{method.label}}</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
          <p class="text-capitalize" v-else>Credit Card</p>
          </div>
      </div>
  
    </div>
    <div class="text-center reciept-action-area">
      <div v-if="checkoutStep==0 && subscriptionPackage">
        <b-button size="md"
                  class="mb-3"
                  variant="primary"
                  @click="requestCheckout">
          &nbsp;
          <span>{{ hasFee() ? 'Proceed to Checkout' : 'Change Package' }}</span>
          &nbsp;
        </b-button>
        <p class="text-left text-small text-dark-6 mb-0">*All prices are in USD</p>
      </div>

      <div v-else-if="checkoutStep==1" class="text-info message">Request is being validated</div>

      <div v-else-if="checkoutStep==2" class="text-info message">{{ hasFee() ? 'Validating payment' : 'Changing subscription' }}</div>
      <div v-else-if="checkoutStep==3">
        <div class="alert alert-success ">
          <div class="message"><strong>Successfully Subscribed</strong></div>
          <p>Great, we have enabled your selected package to your account.</p>
        </div>
        <br>
        <p>Back to <router-link to="/dashboard">Dashboard</router-link></p>
      </div>

      <div v-if="checkoutStep==1 || checkoutStep==2"
            style="display:flex;justify-content:center;align-items:center;margin-top:5px;">
              <b-progress :value="100"
                          :max="100"
                          animated
                          class="w-50 mt-2"
                          style="height: 10px;"></b-progress>
        </div>
      </div>

      <div class="reiept-terms">
        <p>
          By clicking <span>Proceed to Checkout and proceed with your payment in the next page</span>, you agree that:
        </p>
        <p>
        We'll automatically renew your subscription at the purchase price. You can see your renewal date in Billing page after the payment is made. You may cancel your subscription anytime and will not be charged in the next billing cycle. After cancellation, you can still access the package until the end of the paid period.
        </p>
        <p>All paid subscriptions and successful rebill payments are non-refundable. You also agree to Castr <a href="https://castr.com/terms">Terms of Service</a>  and <a href="https://castr.com/privacypolicy">Privacy Policy</a></p>
        <p>Not willing to change? <router-link to="/manage/billing">Go to accounts</router-link></p>
      </div>
    </div>
  </div>

    <!-- error placeholder -->
    <b-alert v-if="error"
      show
      variant="danger"
      class="left inline"
      style="margin:15px 0;max-width:500px;">{{error.message}}</b-alert>

    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex';
import UserService from '@/services/UserService'
import SubscriptionService from '@/services/SubscriptionService'

// const AvailablePaymentMethods = [{ label: 'Credit Card', value: 'cc' }, { label: 'Paypal', value: 'paypal' }]
// const AvailablePaymentMethods = [{ label: 'Credit Card', value: 'cc' }]
const AvailablePaymentMethods = [{label: 'Fastspring', value: 'fastspring-contextual'}]
const PackageCharacteristics = [
  {
  info: 
  {
    name:'Multistream Plus',
    yearly:'12.5',
    saved: '29.9'
  },
  charectiristics:[
    {
      name: '2 concurrent streams',
      details: 'The number of streams happening at the same time'
    },
    {
      name: '2 extra destinations',
      details: 'Extra destination means Facebook Page, Facebook Group, custom RTMP channel, and any second or more destination'
    },
    {
      name: '2 GB pre-recorded live file',
      details: 'Broadcast pre-recorded videos as livestreams'
    }
  ]
  },
  {
  info: {
    name:'Multistream Premium',
    yearly:'33.5',
    saved: '79.9'
  },
  charectiristics:[
    {
      name: '5 concurrent streams',
      details: 'The number of streams happening at the same time'
    },
    {
      name: '5 extra destinations',
      details: 'Extra destination means Facebook Page, Facebook Group, custom RTMP channel, and any second or more destination'
    },
    {
      name: '4 GB pre-recorded live file',
      details: 'Broadcast pre-recorded videos as livestreams'
    }
  ]
  },
  {
  info: {
    name:'Multistream Business',
    yearly:'92',
    saved: '219'
  },
  charectiristics:[
    {
      name: '15 concurrent streams',
      details: 'The number of streams happening at the same time'
    },
    {
      name: '15 extra destinations',
      details: 'Extra destination means Facebook Page, Facebook Group, custom RTMP channel, and any second or more destination'
    },
    {
      name: '10 GB pre-recorded live file',
      details: 'Broadcast pre-recorded videos as livestreams'
    }
  ]
  },
  {
  info: {
    name:'All in One - Entry',
    yearly:'37.5',
    saved: '150'
  },
  charectiristics:[
    {
      name: '2 concurrent streams',
      details: 'The number of streams happening at the same time'
    },
    {
      name: '5 extra destinations',
      details: 'Extra destination means Facebook Page, Facebook Group, custom RTMP channel, and any second or more destination'
    },
    {
      name: '500 GB player monthly bandwidth',
      details: 'Amount of bandwidth available for live and video hosting player'
    },
    {
      name: '6 TB player yearly bandwidth',
      details: 'Amount of bandwidth available for live and video hosting player'
    },
    {
      name: '4 GB pre-recorded live file',
      details: 'Broadcast pre-recorded videos as livestreams'
    },
    {
      name: '25 GB video hosting storage',
      details: 'Amount of storage available for storing video files'
    }
  ]
  },
  {
  info: {
    name:'All in One - Plus',
    yearly:'62.5',
    saved: '450'
  },
  charectiristics:[
    {
      name: '5 concurrent streams',
      details: 'The number of streams happening at the same time'
    },
    {
      name: '5 extra destinations',
      details: 'Extra destination means Facebook Page, Facebook Group, custom RTMP channel, and any second or more destination'
    },
    {
      name: '1 TB player monthly bandwidth',
      details: 'Amount of bandwidth available for live and video hosting player'
    },
    {
      name: '12 TB player yearly bandwidth',
      details: 'Amount of bandwidth available for live and video hosting player'
    },
    {
      name: '4 GB pre-recorded live file',
      details: 'Broadcast pre-recorded videos as livestreams'
    },
    {
      name: '50 GB video hosting storage',
      details: 'Amount of storage available for storing video files'
    }
  ]
  },
  {
  info: {
    name:'All in One - Premium',
    yearly:'104.5',
    saved: '550'
  },
  charectiristics:[
    {
      name: '5 concurrent streams',
      details: 'The number of streams happening at the same time'
    },
    {
      name: '15 extra destinations',
      details: 'Extra destination means Facebook Page, Facebook Group, custom RTMP channel, and any second or more destination'
    },
    {
      name: '2 TB player monthly bandwidth',
      details: 'Amount of bandwidth available for live and video hosting player'
    },
    {
      name: '24 TB player yearly bandwidth',
      details: 'Amount of bandwidth available for live and video hosting player'
    },
    {
      name: '10 GB pre-recorded live file',
      details: 'Broadcast pre-recorded videos as livestreams'
    },
    {
      name: '150 GB video hosting storage',
      details: 'Amount of storage available for storing video files'
    }
  ]
  },
  {
  info: {
    name:'All in One - Business',
    yearly:'250',
    saved: '840'
  },
  charectiristics:[
    {
      name: '10 concurrent streams',
      details: 'The number of streams happening at the same time'
    },
    {
      name: '15 extra destinations',
      details: 'Extra destination means Facebook Page, Facebook Group, custom RTMP channel, and any second or more destination'
    },
    {
      name: '5 TB player monthly bandwidth',
      details: 'Amount of bandwidth available for live and video hosting player'
    },
    {
      name: '60 TB player yearly bandwidth',
      details: 'Amount of bandwidth available for live and video hosting player'
    },
    {
      name: '10 GB pre-recorded live file',
      details: 'Broadcast pre-recorded videos as livestreams'
    },
    {
      name: '500 GB video hosting storage',
      details: 'Amount of storage available for storing video files'
    }
  ]
  },
  {
  info: {
    name:'All in One - Enterprise',
    yearly:'500',
    saved: '1,440'
  },
  charectiristics:[
    {
      name: '15 concurrent streams',
      details: 'The number of streams happening at the same time'
    },
    {
      name: '15 extra destinations',
      details: 'Extra destination means Facebook Page, Facebook Group, custom RTMP channel, and any second or more destination'
    },
    {
      name: '10 TB player monthly bandwidth',
      details: 'Amount of bandwidth available for live and video hosting player'
    },
    {
      name: '120 TB player yearly bandwidth',
      details: 'Amount of bandwidth available for live and video hosting player'
    },
    {
      name: '10 GB pre-recorded live file',
      details: 'Broadcast pre-recorded videos as livestreams'
    },
    {
      name: '1TB video hosting storage',
      details: 'Amount of storage available for storing video files'
    }
  ]
  }
  ]

export default {
  name: 'SubscribeSubAccount',
  data() {
    return {
      error: null,
      minWindowHeight: null,
      checkoutStep: 0,
      processing: true,
      cardValidated: false,
      packages: [],
      showArchivedPlans: false,
      userSubscription: null,
      userBaseSubscription: null,
      packCategory: null,
      subscriptionPackage: null,
      subscriptionPackages: [],
      packCategories: [
        { label: 'multistream', value: 'restream' },
        { label: 'all in one', value: 'live' },
      ],
      quantity: 1,
      selectedPaymentMethod: AvailablePaymentMethods[0],
      availablePaymentMethods: AvailablePaymentMethods,
      PackageCharacteristics: PackageCharacteristics,
      paymentMethodsVisible: true,
      isShowCouponCode: true,
      isFillInCouponCode: false,
      monthly:false,
      couponCode: {
        value: null,
        status: null,
        isValid: false
      },
      subAccounts: [],
      getEndingDate(pack) {
        const today = new Date()
        if (this.isAnnualPackage(this.subscriptionPackage)) {
          today.setFullYear(today.getFullYear() + 1)
        } else {
          today.setMonth(today.getMonth() + this.quantity)
        }

        return today
      },
      getSubscriptionFee() {
        return this.subscriptionPackage.baseCharge
      },
      getTotalFee() {
        let fee = this.quantity * this.subscriptionPackage.baseCharge
        fee = fee.toFixed(2)
        return fee
      },
      hasFee() {
        return this.subscriptionPackage && this.getTotalFee() > 0
      },
      onInputChange(prop) {
        this.formErrors[prop] = false
      }
    }
  },
  props: {
  },
  async mounted() {
    this.minWindowHeight = window.innerHeight + 'px'

    try {
      // Fetch available subscriptions packages
      const subAccounts = await UserService.getSubAccounts()
      this.subAccounts = subAccounts
      let packages = await SubscriptionService.getSubscriptionPackages()
      
      packages = _.sortBy(packages, p => p.name[0])

      this.subscriptionPackages = packages
      const paymentMethods = await SubscriptionService.getUserPaymentMethods()
      this.selectedPaymentMethod = paymentMethods[0]
      this.availablePaymentMethods = paymentMethods

      if (!_.isEmpty(paymentMethods) && paymentMethods.length === 1) {
        this.paymentMethodsVisible = false
      }

      // Set selected subscription
      let packageId = this.$route.query && this.$route.query.package
      const showPromotionPackageOnly = this.$route.query && this.$route.query.ppo
    
      if (packageId) {
        this.subscriptionPackage = _.find(packages, { _id: packageId })
        if (_.isEmpty(this.subscriptionPackage)) {
          this.subscriptionPackage = await SubscriptionService.getSubscriptionPackages(packageId);
        }
        // Update plan category
        setTimeout(() => {
          const packCat = _.find(this.packCategories, { value: this.subscriptionPackage.category })
          if (packCat) { this.packCategory = packCat }

          if (showPromotionPackageOnly === 'true') {
            this.packages = [this.subscriptionPackage]
            this.packCategories = [packCat]
          }
        }, 100)
      }

      // Read url query params
      this.processURLSearchParams()

      // Fetch user subscriptions
      this.userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions};

      this.updateUserBaseSubscription()
      let baseSub = this.userBaseSubscription

      const action = this.$route.query && this.$route.query.action
      const category = this.$route.query && this.$route.query.category
      const baseCharge = parseFloat(this.$route.query.bycharge)
      const currentPackage = _.find(packages, { _id: packageId })
      
      if (action === 'upgrade' || (this.subscriptionPackage && this.subscriptionPackage.baseCharge === 0)) {
        const userPackage = baseSub ? baseSub.package : { baseCharge: 0, cycleAge: 30 }
        let superiorPackage = _.find(this.packages, p => {
          let minBaseCharge = Math.max(userPackage.baseCharge, 9.99)
          let minCycleAge = Math.max(userPackage.cycleAge, 30)
          return p.cycleAge === minCycleAge && p.baseCharge > minBaseCharge
        })

        if (!superiorPackage) { superiorPackage = _.find(this.packages, p => p.baseCharge === userPackage.baseCharge) }
        if (superiorPackage) {
          this.subscriptionPackage = superiorPackage
        }
        setTimeout(() => {
          if (category === 'live' || category === 'restream') {
            this.selectSubscriptionPackage(this.filteredPackages[0])
          }
          const el = document.querySelector('.full-plan-comparison');
          el && el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }, 1000)
      } else if (action === 'resubscribe') {
        const userPackage = baseSub ? baseSub.package : { baseCharge: 0 }
        const curPack = _.find(this.packages, p => p.baseCharge === userPackage.baseCharge)
        this.subscriptionPackage = curPack
      } else if (baseCharge) {
        const isAnnualPack = this.$route.query.annual === '1'

        const subPack = _.find(this.packages, p => {
          let bool = p.baseCharge === baseCharge
          if (isAnnualPack) {
            bool = bool && /year|annual/gi.test(p.name)
          }

          return bool
        })
        if (subPack) {
          this.subscriptionPackage = subPack
        }
      }

      this.processing = false
    } catch (e) {
      this.error = e
    }

    window.trackEvent(`Payments Page`)

    // Update win title
    this.$emit('updateTitle', 'Purchase Subscription')
  },
  computed: {
    ...mapGetters({
			baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions'
		}),
    subAccount() {
      return this.subAccounts.find((item) => {
        return item._id === this.$route.params.subAccountId
      })
    },
    filteredPackages () {
      let packs = this.showArchivedPlans ? this.packages : _.filter(this.packages, pack => !pack.archived)
      if(this.monthly){
        packs = _.filter(packs, pack => !this.isAnnualPackage(pack))
      }else{
        packs = _.filter(packs, pack => this.isAnnualPackage(pack))
      }
      return packs
    },
    comparePlanLink() {
      if (!this.packCategory) return

      let href = 'https://castr.com/'
      if (this.packCategory.value === 'live') {
        href += 'livestreaming'
      }

      href += '#pricing'
      return href
    },
    activeTeam(){
			return this.$store.state.User.activatedTeam;
		}
  },
  methods: {
    getSavedamount (pack) {
     
      const saved_amount = _.find( PackageCharacteristics, function(singlepack){
        let packname = pack.name;
        return packname.includes(singlepack.info.name);
      }) ;
      return 'Subscribe and Save $'+saved_amount.info.saved;
    },
    getPackageName (pack) {
      let packageName = pack.name;
      if(pack.category == 'restream'){
        return packageName.replace('- Yearly', '').replace("Multistream", '<div class="subtext text-dark-6 text-small text-500">Multistream</div>');
      }
      if(pack.category == 'live'){
        return packageName.replace(' Yearly', '').replace('-', '').replace("All in One", '<div class="subtext text-dark-6 text-small text-500">All in One</div>');
      }
    },
    toggleYearly(){
      this.monthly = !this.monthly
    },
    isAnnualPackage (pack) {
      return /yearly/i.test(pack.name)
    },
    processURLSearchParams () {
      const searchParams = _.reduce((window.location.search || '').split('&'), (o, e) => {
        let pars = _.split(e, '=')
        return _.assign({}, o, { [_.replace(pars[0], /^\?/, '')]: pars[1] })
      }, {})

      const predefinedCat = searchParams.category || 'restream'
      if(searchParams.package){
        const packCat = _.find(this.packCategories, { value: this.subscriptionPackage.category })
        this.selectSubscriptionPackageCategory(packCat, false)
          this.monthly = !this.isAnnualPackage(this.subscriptionPackage)
          setTimeout(() => {
            const el = document.querySelector('.full-plan-comparison');
            el && el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
          }, 2000)
      }else{
        if (predefinedCat) {
          const packCat = _.find(this.packCategories, { value: predefinedCat })
          if (packCat) {
            this.selectSubscriptionPackageCategory(packCat, false)
          }
        }
      }
    },
    selectPaymentMethod (method) {
      this.selectedPaymentMethod = method
    },
    gerPermalink () {
      const route = window.location
      const host = `${route.protocol}//${route.hostname}`
      return `${host}/billing/pay?userId=${UserService.getUserId(this.activeTeam)}&packageId=${this.subscriptionPackage._id}`
    },
    isCurrentSubscription (pack) {
      pack = pack || this.subscriptionPackage
      let subAccountAddonSubscriptionPlan = this.subAccount.subscriptions.addonSubscriptions.find(plan => {
        return plan.category == 'live' || plan.category == 'restream'
      })
      
      let subAccountSubscriptionPlan = false
      if (this.subAccount.subscriptions.subscription && (this.subAccount.subscriptions.subscription.package.category === 'restream' || this.subAccount.subscriptions.subscription.package.category === 'live')) {
        subAccountAddonSubscriptionPlan = this.subAccount.subscriptions.subscription
      }
      // const subAccountAddonSubscriptionPlan = this.subAccount.subscriptions.subscription.find(plan => {
      //    plan.category == 'live' && plan.enabled || plan.category == 'restream' && plan.enabled
      // })
      if (subAccountSubscriptionPlan && subAccountSubscriptionPlan.package._id === pack._id) {
        return true
      } else if (subAccountAddonSubscriptionPlan && subAccountAddonSubscriptionPlan.package._id === pack._id) {
        return true
      } else return false
      // 
      // if (!subAccountSubscriptionPlan || !pack) return
      // return subAccountSubscriptionPlan.enabled && subAccountSubscriptionPlan.package === pack._id
    },
    async selectSubscriptionPackage (pack) {
      this.subscriptionPackage = pack
      if (this.subscriptionPackage) {
        this.subscriptionPackage.subAccount = this.subAccount.email
      }
      if(pack){
        let setScroll = setTimeout(() => {
          const el = document.querySelector('.full-plan-comparison');
          el && el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }, 10);
        const checkpaymentMethods = await SubscriptionService.getUserPaymentMethods()
        if(pack.category == 'live' && checkpaymentMethods){
          this.selectedPaymentMethod = checkpaymentMethods[0]
        }
        // const receipt = await $(".reciept-wrapper").length 
      
      }
    },
    selectSubscriptionPackageCategory (packCat, closeCouponCode = true) {
      const oldPackCat = this.packCategory
      this.packCategory = packCat
      this.filterSubscriptionPacks()

      if (oldPackCat && oldPackCat !== packCat) { this.selectSubscriptionPackage(null) }

      this.updateUserBaseSubscription()

      // Reset current coupon code
      if (closeCouponCode) this.closeCouponCode()
    },
    updateUserBaseSubscription () {
      const packCategoryType = this.packCategory.value
      const userSub = this.userSubscription
      if (!userSub) return

      let baseSub = userSub.subscription
      if (packCategoryType !== 'restream') {
        baseSub = _.find(userSub.addonSubscriptions, { category: packCategoryType })
        baseSub = baseSub || { package: { baseCharge: 0 } }
      }

      this.userBaseSubscription = baseSub
    },
    filterSubscriptionPacks () {
      const packCat = this.packCategory
      const catPackages = _.filter(this.subscriptionPackages, { category: packCat.value })
      this.packages = catPackages
    },
    async requestCheckout() {
      this.checkoutStep = 1

      try {
        // Validate coupon code first
        if (this.couponCode.value) {
          await this.applyCouponCode()
          if (!this.couponCode.isValid) {
            this.$notify({ group: 'error', text: this.couponCode.status })
            this.checkoutStep = 0
            return
          }
        } else if (this.isFillInCouponCode) {
          // Force user fill in the coupon code or they must choose `No coupon code`
          this.couponCode.status = 'The coupon code cannot be empty'
          this.$notify({ group: 'error', text: this.couponCode.status })
          this.checkoutStep = 0
          return
        }

        // Create invoice
        const invoiceRes = await SubscriptionService.createInvoiceSubaccountPaymentRequest(this.subscriptionPackage._id, this.subscriptionPackage.subAccount, this.selectedPaymentMethod.value, this.couponCode.value)
        window.location.href = invoiceRes.invoiceUrl
      } catch (e) {
        this.$notify({ group: 'error', text: e ? e.message : 'failed to create invoice0' })
        this.checkoutStep = 0
      }
    },
    gotaCouponCode() {
      this.isFillInCouponCode = !this.isFillInCouponCode
      if (!this.isFillInCouponCode) this.closeCouponCode()
    },
    closeCouponCode() {
      this.isFillInCouponCode = false
      this.couponCode = {
        value: null,
        status: null,
        isValid: false
      }
    },
    async applyCouponCode() {
      try {
        if (!this.couponCode.value) {
          this.couponCode.status = 'The coupon code cannot be empty'
          return
        }
        const data = await SubscriptionService.checkCouponCode(this.subscriptionPackage._id, this.selectedPaymentMethod.value, this.couponCode.value)
        if (data.status === 'valid_code') {
          this.couponCode.isValid = true
          this.couponCode.status = "Promo code successfully redeemed. You\'ll see the discounted price on the next page"
        } else {
          this.couponCode.isValid = false
          this.couponCode.status = 'Your promo code is no longer valid'
        }
      } catch (e) {
        this.$notify({ group: 'error', text: e ? e.message : 'Failed to check coupon code' })
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.view-wrapper {
  color: #ffffff;
}
.title {
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 46px;
text-align: center;
color: #FFFFFF;
}
.receipt-wrapper {
  width: 588px;
  text-align: left;
  margin: 0 auto;
}

.receipt-wrapper .key {
  text-transform: capitalize;
  opacity: 0.65;
}
.receipt-wrapper .value {
  text-transform: uppercase;
}
.logo-icon {
  width: 130px;
  margin-left: -5px;
}
/* .form {
  display: inline-block;
  align-self: center;
  padding: 60px 0;
} */
.field-container {
  width: 300px;
  padding: 10px 0;
  /* border-bottom: 1px solid #22244d; */
}
.field-container:last-of-type {
  border-bottom: none;
}
.label {
  font-size: 12px;
  opacity: 0.65;
  text-transform: capitalize;
}
.message {
  font-size: 15px;
}
.archived-plans-ctrl {
  margin-top:7px;
  font-size:12.5px;
  text-transform: lowercase;
  opacity: 0.8;
}
.archived-plans-ctrl input {
  vertical-align: middle;
  margin-right:2px;
}
.n-feature-name d-flex align-items-center justify-content-start mb-1>img {
    max-width: 16px;
}
.n-feature-single:not(.show-close) .castr-circle-disabled, .n-feature-single:not(.show-check) .castr-check-enabled, .n-feature-wrapper{
  display: none;
}
.multistream .n-feature-multistream, .all.in.one .n-feature-allinone{
  display: flex;
}
.all.in.one .yearly-plans p.package-name {
    min-height: 44px;
    max-width: 159px;
}
.n-feature-wrapper {
    margin: 0 auto;
    background: var(--c-dark-2);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px 25px;
    margin-bottom: 40px;
    align-items: flex-start;
    justify-content: space-around;
    text-align: center;
}

.n-feature-name d-flex align-items-center justify-content-start mb-1>span {
    display: block;
    margin-left: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.n-feature-single>p {
    font-weight: normal;
    font-size: var(--font-s-s);
    line-height: 18px;
    text-align: left;
    color: var(--c-dark-8);
    margin-bottom: 0;
    padding-left: 24px;
    padding-right: 4px;
}

.n-feature-single>p>span {
    color: var(--c-dark-8);
    text-decoration: underline;
    cursor: pointer;
}
.n-feature-wrapper {
    flex-wrap: wrap;
}

.n-feature-single {
    width: 25%;
}
.yearly-switch {
    margin: 40px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yearly-switch>label {
    margin: 0px 17px;
}

.yearly-switch>p {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 0;
}

.yearly-switch>p>span {
    color: var(--c-dark-7);
}
.upgrade-page-header {
    padding-top: 17px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.package-card-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 40px;
}

.package-card {
    width: 100%;
    text-align: left;
    margin-right: 24px;
}
.package-card:last-child{
  margin-right: 0;
}
.package-card-wrapper .package-card:last-child{
  border-right: 0px;
}

p.package-name {
    font-style: normal;
    color: #FFFFFF;
    margin-bottom: 12px;
    line-height: 1.3;
}

.package-price {
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 16px;
    color: #FFFFFF;
}

.package-price>span {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--c-dark-8);
}

/* .btn.package-btn {
    background: var(--c-main-1);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-s-s);
    line-height: 17px;
    color: #FFFFFF;
    padding: 11px 43px;
    position: relative;
    min-width: 155px;
    margin-bottom: 0px;
    width: 100%;
} */
.package-characteristics{
  background-color: var(--c-dark-2);
  border-radius: 0px 0px 8px 8px;
  padding: 20px 22px 20px 22px;
}
.package-characteristics ul {
    padding-left: 0;
}
.package-characteristics li {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-s-m);
    line-height: 24px;
    color: var(--c-dark-9);
    margin-bottom: 12px;
    list-style-type: none;
    position: relative;
        padding-left: 21px;
}

.package-characteristics li>i {
    color: #434B6B;
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
}
.package-btn.btn-outline-light:hover{
      background-color: var(--c-main-1);
      color: #fff;
}
.btn.package-btn.selected:after {
    content: "\f00c";
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    left: 23px;
}
.subscription-reciept {
    padding: 24px 40px 0px 40px;
  background: var(--c-dark-2);
  border-radius: 8px 8px 0px 0px;
}
.reciept-action-area{
  background: var(--c-dark-2);
  border-radius: 0px 0px 8px 8px;
}
.reiept-terms {
    max-width: 570px;
    margin: 0 auto;
    margin-top: 25px;
}

.reiept-terms>p {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-s-s);
    line-height: 20px;
    color: var(--c-dark-5);
    margin-bottom: 20px;
    text-align: center;
}

.reiept-terms>p>a {
    color: var(--c-dark-6);
    text-decoration: underline;
}
.reiept-terms>p>span{
  color: var(--c-dark-6);
  font-weight: 500;
}
.reiept-terms {
    max-width: 570px;
    margin: 0 auto;
    margin-top: 25px;
}

.reciept-action-button {
    background: #0062FF;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    padding: 14px 128px;
    margin-bottom: 15px;
}

.reciept-action-button+p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #B0B3B8;
}

.reciept-action-area {
    padding-top: 20px;
    padding-bottom: 24px;
    padding-left: 40px;
    padding-right: 40px;
}
.btn.package-btn.current-plan {
    background: var(--c-dark-4);
    border-color: var(--c-dark-4);
    color: var(--c-dark-7);
    opacity: 1;
}
.reciept-row-left p {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-s-m);
    line-height: 1;
    color: var(--c-dark-7);
    margin-bottom: 0;
}

.reciept-row-top .reciept-row-left p {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-s-xxl);
    line-height: 1;
    color: #fff;
    margin-bottom: 4px;
}

.reciept-row-top .reciept-row-left p>span {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-s-m);
    line-height: 20px;
    color: var(--c-dark-7);
    display: block;
}

.reciept-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid var(--c-dark-3);
}
.reciept-row-right p {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-s-m);
    line-height: 1.3;
    text-align: right;
    color: #FFFFFF;
    margin-bottom: 0;
}
.reciept-row.reciept-row-top {
    padding-bottom: 0px;
    border-bottom: 0px;
}

.reciept-row.reciept-row-coupon {
    padding-top: 4px;
    border-bottom: 2px solid var(--c-dark-4);
    margin-bottom: 10px;
}
.reciept-row.payable-amount, .reciept-row.receipt-total{
    border-bottom: 2px solid var(--c-dark-4);
    padding-bottom: 20px;
    margin-bottom: 10px;
}
.reciept-row-top .reciept-row-right p {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    color: #FFFFFF;
}

.reciept-row.reciept-row-coupon button.btn-link {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-s-s);
    line-height: 1.2;
    color: var(--c-dark-6);
    text-decoration: underline;
}

.reciept-row.reciept-row-coupon>.row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.reciept-row.reciept-row-payment-method .value button {
    background: #434B6B;
    border-radius: 6px;
    padding: 9px 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.full-plan-comparison {
    margin-bottom: 80px;
    max-width: 194px;
}

.custom-plan {
    background: var(--c-dark-2);
    border-radius: 8px;
    width: 100%;
    max-width: 588px;
    margin: 0 auto;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-bottom: 10px;
}

.c-plan-left h4 {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-s-xxl);
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 4px;
}

.c-plan-left p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--c-dark-8);
    padding-right: 20px;
}

.package-characteristics button {
    background: transparent;
    padding: 0px;
    outline: none;
    color: var(--c-dark-5);
    border: 0px;
    position: absolute;
    top: 5px;
    left: 0px;
}

.package-characteristics button:hover {
    background: transparent;
    outline: none;
}
.c-plan-right>.btn {
    padding: 0px 14px;
}

.c-plan-right>.btn>i {
    margin-right: 8px;
}

.c-plan-right {
    min-width: 124px;
}
.value.currency.reciept-row-right .dropdown-item {
    padding: 0px;
}

.reciept-row-coupon .reciept-row-right{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.reciept-row.reciept-row-payment-method {
    align-items: center;
}

.reciept-row-coupon .reciept-row-right input {
    display: inline-block;
    max-width: 200px;
    width: 100%;
    background: #FFFFFF !important;
    border-radius: 8px;
    height: 40px;
    color: #000;
    padding: 0px 10px !important;
    line-height: 40px !important;
}

.reciept-row-coupon .reciept-row-right button {
    background: #0062FF;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 17px;
    height: 40px;
    padding: 0px 32px;
}
.coupon-status {
    width: 100%;
}

.reciept-row-right .coupon-status>p {
    font-size: 14px;
}
@media only screen and (max-width: 1240px){
.package-card-wrapper {
    flex-wrap: wrap;
}

.package-card {
    margin-bottom: 49px;
}
}
@media only screen and (max-width: 600px){
  .custom-plan {
    flex-direction: column;
    justify-content: center;
}

.c-plan-left {
    text-align: center;
    margin-bottom: 15px;
}
.form {
    max-width: 100%;
    overflow: hidden;
}
.n-feature-multistream {
    flex-wrap: wrap;
}
.n-feature-wrapper {
    flex-wrap: wrap;
}
.n-feature-single {
    width: 100%;
    margin-bottom: 20px;
}

.package-card {
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 20px;
}

.form ul.nav li a {
    font-size: 14px;
    padding: 10px 23px;
}
.subscription-reciept {
    padding: 30px;
}
.receipt-wrapper {
    width: 100%;
}

.reciept-action-button {
    max-width: 300px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.reciept-row.reciept-row-top .reciept-row-left {
    max-width: 50%;
}
.n-feature-single>p>span {
    display:inline-block;
}
  .text-center.upgrade-page-header {
    padding: 5px 0px 25px 0px;
}
.coupon-status {
    width: 100%;
}

.reciept-row-right .coupon-status>p {
    font-size: 14px;
}

.reciept-row.reciept-row-coupon {
    flex-direction: column;
}

.reciept-row-right .coupon-status>p {
    text-align: center;
}
.all.in.one .yearly-plans p.package-name{
  max-width: 100%;
  min-height: unset;
}
}
</style>

<style>
 .upgrade-page .form ul.nav {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  background-color: #343E61;
  padding: 3px 3px 4px 3px;
  border-radius: 6px;
} 

.upgrade-page .form ul.nav li a {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #fff;
  background: transparent;
  outline: 0px;
  border: 0px;
  border-radius: 4px;
  text-transform: capitalize;
  padding: 4px 14px;
}
.upgrade-page .form ul.nav li a.active {
  background: #4F5C87;
  font-weight: 500;
}
.upgrade-page .form ul.nav li a:hover {
  outline: 0px;
}
.upgrade-page .value.reciept-row-right button {
  font-family: "Fira Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-s-s);
  line-height: 1;
  border-radius: 6px;
  padding: 6px 8px;
  border: 0px;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.06), 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.12), inset 0px 0.5px 0px rgba(255, 255, 255, 0.08);
}
.ch-popover-btn .tooltip-inner {
  background-color: #343E61;
  font-size: 14px;
  opacity: 1;
  color: #fff;
}
.upgrade-page .value.reciept-row-right button span {
  text-transform: capitalize;
}
.upgrade-page .value.reciept-row-right .btn-group button {
  background: var(--c-dark-3);
}
.upgrade-page .value.reciept-row-right .btn-group button+ul {
  background: #fff;
  width: 180px;
  left: unset !important;
  right: 0;
  top: 8px !important;
  border-radius: 6px;
}
.upgrade-page .value.reciept-row-right .btn-group button+ul li a span{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-family: "Fira Sans", sans-serif;
}
.upgrade-page .package-dropdown .dropdown-menu .dropdown-item:hover, .package-dropdown-item:hover, .package-dropdown-item.selected {
  background-color: #F2F3F4 !important;
}
.upgrade-page .value .a.package-dropdown-item {
  padding: 9.5px 24px;
}
.upgrade-page .value.currency.reciept-row-right .dropdown-item{
  padding: 0;
}
.upgrade-page .package-dropdown-item:hover{
  background: transparent !important;
}
.subscription_packages .vue-js-switch .v-switch-core {
    transform: rotate(180deg);
}
.castr-check-enabled, .castr-circle-disabled{
  max-width: 21px;
}
.package-heading{
  background-color: var(--c-dark-3);
  padding: 16px 8px;
  border-bottom: 1px solid var(--c-dark-4);
  border-radius: 8px 8px 0px 0px;
}
.package-info {
    padding: 0px 8px;
}
.package-heading.no-background{
  background-color: var(--c-dark-2);
  border-radius: 8px;
  border-bottom-color: transparent;
}
.package-heading.no-background+.package-characteristics{
  display: none;
}
.no-background .package-btn{
  /* border-color: var(--c-dark-3); */
  color: var(--c-dark-1);
  font-size: var(--font-s-ss);
}

.coupon-form input {
    background: var(--c-dark-3);
    border: 1.5px solid var(--c-dark-3);
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    max-width: 185px;
    padding: .5px 9px;
    margin: 0px;
    color: #fff;
}
.coupon-code-error.coupon-form input {
    border-color: var(--c-danger-1);
}
.coupon-form input:focus {
  border-color: var(--c-main-light);
}
.coupon-form input:focus-visible {
  border-color: var(--c-main-light);
  outline: 0px;
}
.coupon-form button {
    background: var(--c-dark-4);
    border-color: var(--c-dark-4);
    color: var(--c-dark-7);
    font-size: var(--font-s-s);
}
.coupon-form input:focus+button{
    background: var(--c-secondary-1);
    border-color: var(--c-secondary-1);
    color: #fff;
}
.all.in.one .monthly-plans .package-characteristics li:nth-child(4) {
    display: none;
}

.all.in.one .yearly-plans .package-characteristics li:nth-child(3) {
  display: none;
}
.package-heading, .package-characteristics {
    border: 2px solid transparent;
}

.package-heading.selected {
    border-color: var(--c-main-light);
    border-bottom: 0px;
}

.package-characteristics.selected {
    border-color: var(--c-main-light);
    border-top: 0px;
    padding-bottom: 24px;
}
.n-feature-single>p.text-dark-7{
    color: var(--c-dark-7);
}
@media only screen and (max-width: 768px){
.package-card {
  margin-right: 0px !important;
}
}
@media only screen and (max-width: 600px){
  
 .upgrade-page .form ul.nav {
      margin-top: 0px;
      width: 100%;
  }
 .upgrade-page .form ul.nav li {
    width: 50%;
}
 .upgrade-page .form ul.nav li a {
    padding: 12px 6px;
} 
.package-card {
  margin-right: 0px !important;
  padding: 0px !important;
}
}
</style>
