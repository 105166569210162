<template>
	<div class>
		<!-- Modal Component -->
		<!-- modal-class="modal-add-stream modal-fullscreen1 modal-fullscreen-right modal-platform" -->
		<b-modal
			id="modal-add-schedule"
			ref="modalAddStream"
			:modal-class="['modal-right', { 'modal-collapsed': selectedStreamType }]"
			hide-header
			hide-footer
		>
		{{trialError}}
			<!-- stream types -->
			<div class="modal-container">
				<div class="" v-show="operational">
					<b-row align-v="start" class="mb-2 mt-100">
						<b-col cols="8" class="mt-3 pl-5">
							<!-- <h3 class="page-title">Create New</h3> -->
							<b-navbar-brand
								:to="{ path: '/dashboard' }"
								tag="a">
									<!-- <b-img
									height="40px"
										class="logo-icon"
										src="https://castr.com/wp-content/uploads/2023/12/Christmas_logo_2023.png"
										alt="Streaming Dashboard - Castr"
									/> -->
									<img
									height="32px"
										class="logo-icon"
										src="@/assets/castr.svg"
										alt="Streaming Dashboard - Castr"
									/>
									<div class="logo-icon-dot" />
							</b-navbar-brand>
						</b-col>
						<b-col cols="4" class="mt-3 text-right">
							<b-button
								variant="icon"
								class="px-2"
								@click="dismiss"
							>
								<img
									src="@/assets/images/menu-close.svg"
									alt="Menu close"
								>
							</b-button>	
						</b-col>
					</b-row>
					<b-row>
						<b-col class="col-px-5 mt-100">
							<div class="stream-item">
								<div class="stream-item__desc">
									<h4 class="text-s-xxl  text-500">{{ selectedStreamTypeDef.title }}</h4>
									<p class="stream-item__p text-dark-7 mb-0">{{ selectedStreamTypeDef.description2 }}</p>
								</div>
							</div>
						</b-col>
					</b-row>
				</div>
				<b-row>
					<b-col>
						<b-alert
							v-if="error && !error.role"
							show
							variant="danger"
							class="text-left mx-4 alert-danger-style2"
						>
							<div v-html="error.message"></div>
							<div
								v-if="error.subscription"
							>
								<a href="/app/subscribe">
									<b-button
									>
										upgrade now
									</b-button>
								</a>
							</div>
						</b-alert>
						<div v-if="error && error.role">
							<div class="row bg-dark-3 no-gutters">
								<div class="p-3 col" v-if="error.role">
									<p class="mb-2">You do not have an active pre-recorded subscription.</p>
									<p class="mb-0 text-s-m text-dark-7">Would you like to activate our 7 days trial ?</p>
								</div>
							</div>
							<div class="col-px-5 mt-4 col">
								<b-alert class="alert alert-danger"  v-if="trialError"
									variant="danger"
								>{{ trialError.message }}</b-alert>
								
								<div v-if="trialProcessing"
								>
									<b-progress :value="100"
										:max="100"
										animated
										class="w-100"
									/>
								</div>
								<div v-else>
									<b-button  variant="primary" size="md" class="w-100 mb-3" @click="forceActivateTrial">Activate Trial</b-button>

									<div v-if="selectedStreamType !== 'scheduled'">
										<b-button :to="`/subscribe?category=${selectedStreamType}`">
											<button class="btn btn-sm btn-outline-primary">Want more options?</button>
										</b-button>
									</div>

									<b-button variant="outline-secondary" class="w-100 btn-outline-secondary" size="md" @click="dismiss">cancel</b-button>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
				<div  v-show="operational">
					<b-row>
						<b-col class="col-px-5 mt-3">
							<label class="text-s-s">
								Streaming Name *
							</label>
							<b-input
								v-model="channel.name"
								size="md"
								placeholder="#stream_name"
								class="mb-2"
								@keypress="onInputChange('name')"
							/>
								<b-alert
									variant="danger"
									v-show="formErrors.name"
								>
									specify stream name
								</b-alert>
						</b-col>
					</b-row>
					<b-row>
						<b-col class="col-px-5">
							<b-button
								variant="primary"
								size="md"
								class="w-100 mt-3"
								disabled
								v-if="processing"
							>
								<Spinner text="Creating..." classes="text-dark-8" spinner-color="var(--c-dark-8)" spinner-size="15px" />
							</b-button>
							<div
								v-if="!processing"
							>
								<b-button
									variant="primary"
									size="md"
									class="w-100 mb-2"
									@click="onSaveChannel"
								>
									<!-- Create {{ selectedStreamType === 'vod' ? 'Bucket' : 'Stream' }} -->
									Create Pre-recorded Stream
								</b-button>
								<b-button
									variant="outline-secondary"
									size="md"
									class="w-100"
									@click="dismiss"
								>
									Cancel
								</b-button>
							</div>
						</b-col>
					</b-row>
				</div>
			</div>	
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import IconBase from '@/components/icon/IconBase.vue';
import IconLivestream from '@/components/icon/IconLivestream.vue';
import IconMultistream from '@/components/icon/IconMultistream.vue';
import IconIpcam from '@/components/icon/IconIpcam.vue';
import IconSchedulestream from '@/components/icon/IconSchedulestream.vue';
import IconVodChannel from '@/components/icon/IconVodChannel.vue';
import SubscriptionService from '@/services/SubscriptionService';

export default {
	name: 'AddScheduleModal',
	components: {
		Spinner,
		IconBase,
		IconLivestream,
		IconMultistream,
		IconIpcam,
		IconSchedulestream,
		IconVodChannel
	},
	data() {
		return {
			processing: false,
			trialProcessing: false,
			error: null,
			trialError: null,
			operational: true,
			viewStage: 1,
			selectedStreamType: 'scheduled',
			userSubscription: null,
			channel: {
				name: null,
				region: null
			},
			selectedRegion: null,
			regions: [],
			formErrors: { name: false, region: false },
			streamTypeList: [
				{
					type: 'scheduled',
					title: 'New Pre-recorded Stream',
					description: 'Schedule your live presence',
					description2: 'Schedule your pre-recorded video to broadcast on multiple social platforms and destinations at the same time.',
					icon: require('@/assets/images/scheduler-plan.svg'), // eslint-disable-line no-undef
					iconAlt: 'far fa-calendar-alt'
				},
			],
			onInputChange(prop) {
				this.formErrors[prop] = false;
			}
		};
	},
	computed: {
		subscriptions() {
			return this.$store.state.User.subscriptions;
		},
		deploymentRegions() {
			return this.$store.state.User.deploymentRegions;
		},
		contextualRegionList() {
			const stype = this.selectedStreamType;
			// we don't ask scheduled region from user
			if (stype === 'scheduled') {
				return [];
			}

			// if (stype === 'live' && !_.isEmpty(this.deploymentRegions)) {
			// 	const platformNode = _.find(this.deploymentRegions,
			// 		pnode => _.includes(pnode.platforms, stype));
			// 	if (platformNode && !_.isEmpty(platformNode.regions)) {
			// 		const regions = platformNode.regions;
			// 		return this.regions.filter(r => {
			// 			return r.platforms.indexOf(stype) > -1 && regions.includes(r._id);
			// 		});
			// 	}
			// }

			return stype
				? this.regions.filter(r => r.platforms.indexOf(stype) > -1)
				: [];
		},
		selectedStreamTypeDef() {
			return this.streamTypeList.find(
				e => e.type === this.selectedStreamType
			);
		},
		streamTypeSubscriptionCategory() {
			return 'scheduled';
		}
	},
	async mounted() {
		this.$refs.modalAddStream.$on('hide', this.onDismiss);
		this.$refs.modalAddStream.$on('shown', this.onInit);
		this.regions = await StreamService.getAvailableRegions('list');
	},
	methods: {
		async onInit() {
			this.trialError = null;
			// this.processing = true;
			this.trialProcessing = false;
			

			const hasRequiredSub = this.subscriptions.find(
				(s) => {
					return _.get(s, 'category', '').includes('scheduled');
				}
			);

			if (!hasRequiredSub) {
				this.operational = false;
				this.error = { role: true };
			} else {
				this.operational = true;
				this.error = null;
			}

		},
		onDismiss() {
			this.clearErrors();
			// lazy clear
			setTimeout(() => {
				this.processing = false;
			}, 1000);
		},
		dismiss() {
			this.$refs.modalAddStream.hide();
			this.onDismiss();
		},
		selectRegion(region) {
			this.selectedRegion = region;
			this.channel.region = region._id;
			this.onInputChange('region');
		},
		getCountryFlag(region) {
			return `https://assets.castr.io/countryflags/${region.identifier}/flat/64.png`;
		},
		selectStreamType(streamType) {
			if (!this.selectedStreamType) {
				this.selectedStreamType = 'scheduled';
				this.onStreamTypeSelected();
			} else {
				this.unselectStreamType();
				setTimeout(() => {
					this.selectStreamType('scheduled');
				}, 250);
			}
		},
		async onStreamTypeSelected() {
			this.operational = true;
			this.error = null;
			this.trialError = null;
			this.trialProcessing = false;

			const subCat = this.selectedStreamType;
			const hasRequiredSub = this.subscriptions.find(
				(s) => {
					return _.get(s, 'category', '').includes(subCat);
				}
			);

			if (!hasRequiredSub) {
				this.operational = false;
				this.error = { role: true };
			}

			// If client has assigned deployment region for certain stream type
			// we need to show only those regions when creating new stream
			// const platformNode = _.find(this.deploymentRegions, pnode =>
			// 	_.includes(pnode.platforms, subCat)
			// );
			// if (platformNode && !_.isEmpty(platformNode.regions)) {
			// }

			this.regions = await StreamService.getAvailableRegions(subCat);

			if (this.contextualRegionList.length === 1) {
				this.channel.region = this.contextualRegionList[0]._id;
			}
		},
		unselectStreamType() {
			this.selectedStreamType = 'scheduled';
			// this.resetForm();
			this.clearErrors();
		},
		async forceActivateTrial() {
			this.trialError = null;
			this.trialProcessing = true;

			// activate trial
			const subCat = this.selectedStreamType;
			try {
				await SubscriptionService.requestTrial(subCat);
				// update vuex state
				await this.$store.dispatch('User/loadUser', null, { root: true });
			} catch (e) {
				this.trialError = e;
				return;
			} finally {
				this.trialProcessing = false;
			}

			this.error = null;
			this.operational = true;

			// on trial activated
			if (!this.trialError) {
				this.$notify({
					group: 'success',
					text: `pre-recorded trial activated successfully`
				});
			}
		},
		async onSaveChannel() {
			this.error = null;

			// if selected scheduled, set first region from server list
			const stype = this.selectedStreamType;
			if (stype === 'scheduled') {
				const region = _.head(this.regions.filter(r => r.platforms.indexOf(stype) > -1));
				this.channel.region = region._id;
			}

			if (!this.validateForm()) return;

			this.processing = true;

			try {
				// const stream = await StreamService.addStream(
				await StreamService.addStream(
					this.channel.name,
					this.channel.region,
					this.selectedStreamType
				);

				// #custom-patch: new stream not found in api response
				const streamList = await StreamService.getUserStreams();
				const stream = _.find(
					streamList,
					s =>
						s.type === this.selectedStreamType &&
                  s.name === this.channel.name
				);
				// --

				this.$emit('new-channel', stream, this.selectedRegion);
				this.dismiss();
			} catch (err) {
				this.error = err;
				this.processing = false;
				err.message = '<i class="fas fa-exclamation-triangle mr-2"></i>'+err.message;
				if(this.selectedStreamType === 'scheduled') {
					err.message = err.message.replace('upgrade', '<a class="upgradelink" target="_blank" href="/app/subscribe?category=restream">upgrade</a>');
				}
				if(this.selectedStreamType === 'vod') {
					err.message = err.message.replace('streams and platforms', 'VOD Bucket');
				}
				// check if subscripiton error
				if (err && _.indexOf(err.message, 'subscription') > -1) {
					this.error.subscription = true;
				}
			}
		},
		validateForm() {
			const props = ['name', 'region'];

			let valids = 0;
			_.each(props, prop => {
				const val = this.channel[prop];
				if (val) valids++;
				// else this.formErrors[prop] = true;

				this.formErrors[prop] = !val;
			});

			return valids === props.length;
		},
		clearErrors() {
			this.error = null;
			this.formErrors.name = false;
			this.formErrors.region = false;
		},
		resetForm() {
			this.selectedStreamType = null;
			this.channel.name = null;
			this.channel.region = null;
			this.selectedRegion = null;
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.stream-item {
		position: relative;
		margin-bottom: 20px;
	}
	.stream-item__desc {
		position: relative;
		flex-grow: 1;
	}
	.stream-item__desc:after {
		content: '';
		display: block;
		border-top: 1px solid var(--c-dark-3);
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		bottom: -10px;
		display: none;
	}
	/* .stream-item:hover {
		background-color: var(--c-dark-3);
	} */
	.stream-item.active {
		background-color: var(--c-main-1);
	}
	.stream-item.active .stream-item__desc:after {
		content: none;
	}
	.stream-item svg {
		color: var(--c-main-1);
	}
	.stream-item:hover svg, .stream-item.active svg {
		color: #fff;
	}
	.stream-item.active .stream-item__p {
		color: #fff;
	}
	.stream-item__title {
		font-size: var(--font-s-m);
	}
	.stream-item__p {
		font-size: var(--font-s-m);
	}
	/* .hideform {
		display: none;
	}
	.showform {
		display: flex;
	} */
	.second-modal {
		position: fixed;
		top: 0;
		background-color: var(--c-dark-2);
		margin: auto;
		width: 350px;
		height: 100%;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
		right: 0;
		z-index: -1;
		border-left: 1px solid var(--c-dark-3);
		border-right: 1px solid var(--c-dark-3);
	}
	.showform.second-modal {
		right: 350px;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	.stream-type-info {
		background-color: var(--c-dark-3);
		padding: 15px;
	}
	.region-dropdown .dropdown-menu {
		left: -100%!important;
	}
	.region-flag {
		width: 20px;
	}
	.stream-item__p, .stream-item__desc h4{
		cursor: pointer;
	}
</style>
