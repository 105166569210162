<script setup>
import { computed, watch, ref, onMounted, getCurrentInstance } from 'vue'
import PaywallService from "@/services/PaywallService";
import IconBanknote from "../../components/icon/IconBanknote.vue"
import IconChevronRight from "../../components/icon/IconChevronRight.vue"
import IconBin from "../../components/icon/IconBin.vue"
import FormSwitch from "../../components/Atoms/FormSwitch.vue"
import { useStore } from 'vuex'
import { useNotification } from "@kyvg/vue3-notification";
import FormButton from '../Atoms/FormButton.vue';
import FormUpload from '../Atoms/FormUpload.vue';
import IconBase from '../icon/IconBase.vue';
import IconBookOpen from '../icon/IconBookOpen.vue';
import IconElipsisVertical from '@/components/icon/IconElipsisVertical.vue'
import { useRoute, useRouter } from 'vue-router'
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';
import Title from '../ui/Title.vue'
import Alert from '../Atoms/Alert.vue';

const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()
const store = useStore()
const { notify } = useNotification()

const logoUrlTmp = ref(null)
const logoSrc = ref(null)
const maxLogoUploadMB = ref(null)
const logoProcessing = ref(false)
const errorMsg = ref(null)

const logoUrl = computed(() => logoUrlTmp.value ? logoUrlTmp.value : logoSrc.value)

const userPaywall = computed(() => store.getters['User/userPaywall'])
const paywalls = computed(() => store.getters['Paywalls/paywalls'])
const hasLiveSubscription = computed(() => store.getters['User/hasLiveSubscription'])
const isLiveSubscriptionExpired = computed(() => store.getters['User/isLiveSubscriptionExpired'])

const props = defineProps({
  currentPaywall: {
    type: Function,
  }
})

const onLogoPreview = (file, type) => {
  const selector = "#logo-image-input";
  const imageInput = document.querySelector(selector);
  const imageFile = file;

  if (!imageFile) return;

  if (imageFile.type.indexOf("image/") === -1) {
    root.$emit("bv::show::modal", "embed-upload-format");
    imageInput.value = null;
    return;
  }

  let maxSize = 1;
  maxLogoUploadMB.value = 1;

  maxSize *= 1024 * 1024;

  if (imageFile.size > maxSize) {
    root.$emit("bv::show::modal", "embed-image-size-exceed");
    imageInput.value = null;
    return;
  }

  const validImageTypes = null;
  const uploadedImageFileType = imageFile.type.replace("image/", "");
  if (
    validImageTypes &&
    validImageTypes.indexOf(uploadedImageFileType) === -1
  ) {
    root.$emit("bv::show::modal", "embed-image-mime-type");
    imageInput.value = null;
    return;
  }
  imageReader(imageFile, (base64) => {
    logoUrlTmp.value = base64;
  });
}

const saveLogo = async () => {
  logoProcessing.value = false;

  const selector = "#logo-image-input";
  const imageInput = document.querySelector(selector);
  const imageFile = imageInput.files[0];

  if (!imageInput.value || !imageFile) {
    errorMsg.value = "please pick an image file";
    return;
  }

  logoProcessing.value = true;

  // -- upload reuqest --
  const fdata = new FormData();
  fdata.append("file", imageFile);

  try {
    const res = await PaywallService.paywallUploadLogo(fdata, props.currentPaywall.id);
    logoUrlTmp.value = null;
    logoSrc.value = res.logoUrl;
    errorMsg.value = null;

    store.dispatch('Paywalls/setPaywallProp', { id: props.currentPaywall.id, prop: 'company_logo', value: res.logoUrl })

    notify({
      group: "success",
      text: `Logo uploaded with success`,
    });
  } catch (err) {
    errorMsg.value = "could not handle image upload. Please try again later";
    notify({
      group: "error",
      text: err.message || "could not upload paywall logo",
    });
  }

  logoProcessing.value = false;
}

function imageReader(file, cb) {
  if (!file) {
    return;
  }

  var reader = new FileReader();
  reader.onload = function () {
    if (cb) {
      cb(reader.result);
    }
  };

  reader.readAsDataURL(file);
}

const cancelUpload = async () => {
  logoProcessing.value = true;
  const selector = "#logo-image-input";
  const previewFlag = logoUrlTmp.value;
  errorMsg.value = null;
  if (previewFlag) {
    logoUrlTmp.value = null;
    document.querySelector(selector).value = null;
    return;
  }
  try {
    await PaywallService.updatePaywallSettings({ company_logo: null }, props.currentPaywall.id);
    logoSrc.value = null;
    store.dispatch('Paywalls/setPaywallProp', { id: props.currentPaywall.id, prop: 'company_logo', value: null })
  } catch (e) {
    console.error("paywall remove logo failed", e);
    notify({ group: "error", text: "could not save changes" });
  }
  logoProcessing.value = false;
}

onMounted(() => {
  logoSrc.value = props.currentPaywall?.company_logo
})
</script>

<template>
  <section>
    <h3 class="text-sm font-medium">Custom Poster</h3>
    <p class="text-surface-8 text-tiny !mb-0">The paywall automatically adopts the poster set at the
      <a v-if="props.currentPaywall?.source" class="text-underline" target="_blank"
        :href="(props.currentPaywall?.source?.type == 'live') ? `/app/livestreams/${props.currentPaywall?.source?._id}/settings/player-interface` : `/app/vods/${props.currentPaywall?.source?._id}/videos`">source</a>
      <span v-else>source</span>.
    </p>
    <hr class="my-6">
    <div class="flex flex-col gap-y-3 items-start">
      <label for="embed-poster-input" class="mb-0 text-sm">Logo</label>
      <p class="text-tiny mb-2 text-surface-8"> *Required size: 120px (width) x 50px (height)</p>
      <div class="flex gap-x-2 items-center w-full">
        <img v-if="logoUrl" :src="logoUrl" alt="thumbnail image" class="size-[100px]">
        <p v-else class="text-tiny text-surface-6">No file choosen</p>
        <FormUpload class="ml-auto" name="endEmbedPoster" id="logo-image-input" placeholder="No file chosen"
          :label="logoSrc ? 'Change' : 'Select Logo'" type="secondary" size="sm" :on-file-change="onLogoPreview" />
        <FormButton v-if="logoUrlTmp" :label="logoProcessing ? 'Saving' : 'Save Logo'" :disabled="logoProcessing"
          size="sm" @click="saveLogo" />
        <FormButton v-if="logoUrlTmp || logoSrc" :disabled="logoProcessing" type="secondary" size="sm"
          @click="cancelUpload">
          <span v-if="logoSrc && logoUrlTmp">Restore</span>
          <span v-else-if="logoUrlTmp">Cancel</span>
          <span v-else>Remove</span>
        </FormButton>
      </div>
      <Alert type="error" v-show="errorMsg" :text="errorMsg" />
    </div>
  </section>
</template>

<style scoped></style>
