import _ from 'lodash'
import DestinationService from "@/services/DestinationService"
import IntegrationService from "@/services/IntegrationService"

const defaultState = () => ({
	destinations: [],
	platforms: [],
	selectedPlatform: null,
	isCustom: false,
	destination: {
		name: null,
		template: null,
		server: null,
		key: null,
		enabled: false,
		mode: null,
		latency: null,
		port: null,
		passphrase: null
	},
});

const state = defaultState();

const mutations = {
	GET_DESTINATIONS(state, list) {
		state.destinations = list;
	},
	GET_PLATFORMS(state, list) {
		state.platforms = list;
	},
	SELECT_PLATFORM(state, item) {
		item 
			? (
					state.selectedPlatform = item, 
					state.isCustom = false, 
					state.destination.name = item.name, 
					state.destination.template = item.name
				)
			: (
					state.selectedPlatform = null, 
					state.isCustom = true,
					state.destination.name = 'Custom',
					state.destination.template = 'custom'
				)
	},
	SET_SERVER(state, item) {
		state.destination.server = item;
	},
	SET_KEY(state, item) {
		state.destination.key = item;
	},
	SET_MODE(state, item) {
		state.destination.mode = item;
	},
	SET_LATENCY(state, item) {
		state.destination.latency = item;
	},
	SET_PORT(state, item) {
		state.destination.port = item;
	},
	SET_PASSPHRASE(state, item) {
		state.destination.passphrase = item;
	},
	CLEAR_DESTINATION(state) {
		state.destination = {
			name: null,
			template: null,
			server: null,
			key: null,
			enabled: false,
			mode: null,
			latency: null,
			port: null,
			passphrase: null
		},
		state.isCustom = null
	},
	CLEAR_SELECTED_PLATFORM(state) {
		state.selectedPlatform = null
	},
}

const actions = {
	async getDestinations({ commit }) {
    const list = await DestinationService.getDestinations()
    commit("GET_DESTINATIONS", list)
  },
	async getPlatforms({ commit }) {
		const list = await IntegrationService.getPublishPlatformList()
		commit("GET_PLATFORMS", list)
	},
	selectPlatform({ commit }, item) {
		commit("CLEAR_DESTINATION")
		commit("SELECT_PLATFORM", item)
	},
	setServer({ commit }, item) {
		commit("SET_SERVER", item)
	},
	setKey({ commit }, item) {
		commit("SET_KEY", item)
	},
	setMode({ commit }, item) {
		commit("SET_MODE", item)
	},
	setLatency({ commit }, item) {
		commit("SET_LATENCY", item)
	},
	setPort({ commit }, item) {
		commit("SET_PORT", item)
	},
	setPassphrase({ commit }, item) {
		commit("SET_PASSPHRASE", item)
	},
	clearDestination({commit}) {
		commit("CLEAR_DESTINATION")
	},
	clearSelectedPlatform({commit}) {
		commit("CLEAR_SELECTED_PLATFORM")
	},
	async createDestination({ state, commit }) {
		const newDestination = {}
		newDestination.name = state.destination.name
		newDestination.template = state.destination.template
		newDestination.server = state.destination.server
		newDestination.key = state.destination.key
		newDestination.enabled = state.destination.enabled

		if (state.destination.mode === 'srt') {
			newDestination.mode = state.destination.mode
			newDestination.port = state.destination.port
			newDestination.latency = state.destination.latency
			newDestination.passphrase = state.destination.passphrase
		}
		console.log(newDestination)
		await DestinationService.createDestination(newDestination)
		commit("CLEAR_DESTINATION")
		commit("SELECT_PLATFORM")
	},
};

const getters = {
	destinations: (state) => state.destinations,
	platforms: (state) => state.platforms,
	selectedPlatform: (state) => state.selectedPlatform,
	isCustomPlatform: (state) => state.isCustom,
	isPlatformSelected:(state) => {
		return state.selectedPlatform || state.isCustom ? true : false
	},
	isDisabled: (state) => !state.destination.key || !state.destination.server
};

export default {
  namespaced: true,
	state,
	mutations,
	actions,
	getters
};
