<template>
  <div :class="classes" class="d-flex align-items-center justify-content-center spinner-wrapper">
    <clip-loader :color="spinnerColor" :size="spinnerSize" class="mr-2" />
    <p v-if="text" class="mb-0" :class="textClasses">{{ text }}</p>
  </div>
</template>

<script>
import ClipLoader from "@/components/ui/ClipLoader.vue"

export default {
	name: 'Spinner',
  components: {
    ClipLoader,
  },
  props: {
    spinnerSize: {
      type: String,
      default: '16px'
    },
    textClasses: {
      type: String,
      default: 'text-s-m'
    },
    classes: {
      type: String,
      default: ''
    },
    spinnerColor: {
      type: String,
      default: '#fff'
    },
    text: {
      type: String,
      default: ''
    },
  }
}
</script>

<style scoped>
:deep(.spinner-wrapper .v-spinner .v-clip) {
  display: block;
}
</style>