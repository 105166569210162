<script setup>
import { computed, ref, watch } from 'vue'
import DropDownMenu from '../Atoms/DropDownMenu.vue'
import DropDownMenuItem from '../Atoms/DropDownMenuItem.vue'
import IconBase from '../icon/IconBase.vue'
import IconChevronDown from '../icon/IconChevronDown.vue'
import IconCamera from '../icon/IconCamera.vue'
import IconInfoSmall from '../icon/IconInfoSmall.vue'
import IconCheckmark from '../icon/IconCheckmark.vue'
import FormSwitch from '../Atoms/FormSwitch.vue'
import FormButton from '../Atoms/FormButton.vue'
import { useStore } from 'vuex'
import { useNotification } from "@kyvg/vue3-notification";
import Tooltip from '../Atoms/Tooltip.vue'

const { notify } = useNotification()

const props = defineProps({
  mediaPulse: {
    type: Object,
  },
})

const destinationDropdown = ref()

const store = useStore()

const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])
const tvPlayout = computed(() => store.getters['Streams/tvPlayout'])

const playlist = computed(() => store.getters['Playout/playlist'])

const allInOneStreams = computed(() => store.getters['Streams/allInOneStreams'])

const hasPlayoutLoopMode = computed(() => store.getters['User/hasPlayoutLoopMode'])
const hasPlayoutScheduleMode = computed(() => store.getters['User/hasPlayoutScheduleMode'])

const showWarning = computed(() => (!hasPlayoutLoopMode.value && playoutConfig.value?.mode == 'loop') || (!hasPlayoutScheduleMode.value && playoutConfig.value?.mode == 'schedule')
  ? true
  : false)

const destination = computed(() => playoutConfig.value?.destination
  ? allInOneStreams.value.find(livestream => livestream._id === playoutConfig.value?.destination)?.name
  : 'Choose destination')

const destinationEnabled = computed(() => playoutConfig.value?.settings?.destinationEnabled
  ? true
  : false)

const setDestination = async (id) => {
  await store.dispatch('Playout/setDestination', id)
  await store.dispatch('Playout/toggleDestination', true)
  destinationDropdown.value.toggleDropdown()
  await applyChanges()
}

const toggleDestination = async () => {
  await store.dispatch('Playout/toggleDestination')
  await applyChanges()
}

const removeDestination = async () => {
  await store.dispatch('Playout/setDestination', null)
  await store.dispatch('Playout/toggleDestination', false)
  await applyChanges()
}

const applyChanges = async () => {
  await store.dispatch('Playout/setSchedule', tvPlayout.value._id)
  notify({
    group: 'success',
    text: `${tvPlayout.value.name} destination was updated`
  })
}

const disabled = (streamKey) => {
  return allInOneStreams.value.find(stream => stream.key === streamKey).playoutDestination || !!playlist.value?.some(item => item.sourceLocation === streamKey)
}

const tooltipMessage = (streamKey) => {
  if (allInOneStreams.value.find(stream => stream.key === streamKey).playoutDestination) return 'This stream is destination for another TV Playout and cannot be selected.'

  if (!!playlist.value?.some(item => item.sourceLocation === streamKey)) return 'This stream added on the playlist, it cannot be set as destination'
}
</script>

<template>
  <drop-down-menu ref="destinationDropdown" type="secondary"
    customClasses="mt-3 w-full text-tiny disabled:text-surface-7 h-8 bg-surface-3 rounded-md flex items-center px-3 capitalize truncate">
    <template #toggle-button>
      <icon-base class="mr-2">
        <icon-camera />
      </icon-base>
      {{ destination }}
      <icon-base class="ml-auto">
        <icon-chevron-down />
      </icon-base>
    </template>
    <template v-if="allInOneStreams.length > 0" #menu-items>
      <template v-for="liveStream in allInOneStreams" :key="liveStream._id">
        <drop-down-menu-item v-if="!disabled(liveStream.key)" @click="setDestination(liveStream._id)">
          <icon-base class="mr-2">
            <icon-camera />
          </icon-base>
          {{ liveStream.name }}
          <icon-base v-if="liveStream?._id === playoutConfig?.destination" class="ml-auto">
            <icon-checkmark />
          </icon-base>
        </drop-down-menu-item>
        <drop-down-menu-item isText disabled v-else>
          <tooltip :content="tooltipMessage(liveStream.key)">
            <div class="flex items-center w-full">
              <icon-base class="mr-2">
                <icon-camera />
              </icon-base>
              {{ liveStream.name }}
            </div>
          </tooltip>
        </drop-down-menu-item>
      </template>
    </template>
    <template v-else #menu-items>
      <drop-down-menu-item class="text-surface-8">
        Please create an All in One stream
      </drop-down-menu-item>
    </template>
  </drop-down-menu>
  <div class="flex mt-4 items-center justify-between">
    <FormSwitch @change="toggleDestination()" v-model="destinationEnabled" />
    <span v-if="destinationEnabled && mediaPulse?.alive" class="text-success text-xs ml-1 mr-auto">Connected</span>
    <FormButton type="link" label="Remove" @click="removeDestination" />
  </div>
  <div v-if="showWarning" class="flex rounded-md bg-surface-2 text-surface-8 text-tiny p-2 !mt-2 md:!mt-0">
    <icon-base class="mr-2 shrink-0 mt-px" fill="none">
      <icon-info-small />
    </icon-base>
    <p class="mb-0">This is for demo purposes only. To remove the Castr logo from the stream, <a target="_blank"
        class="text-white underline font-bold" href="/app/subscribe">upgrade</a> plan.
    </p>
  </div>
</template>

<style scoped></style>
