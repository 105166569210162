<template>
	<router-view v-slot="{ Component }">
		<component :is="Component" :stream="stream" :stream-alive="streamAlive" />
	</router-view>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';

export default {
	name: 'StreamsManageAnalyticsInputHealthCharts',
	components: {
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		streamAlive: {
			type: Boolean,
		}
	},
	data() {
		return {};
	},
	computed: {
	},
	mounted() {
	},
	methods: {
		
	}
};
</script>

<style scoped>
</style>
