<script setup>
import { computed, ref, onMounted, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import _ from "lodash";
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from "@/services/StreamService";
import UserService from "@/services/UserService";
import AlertModal from "@/components/modals/AlertModal.vue";
import UploadAlertModal from "@/components/modals/UploadAlertModal.vue";
import UppyUploaderReplace from "../../components/UppyUploaderReplace.vue";
import MetricsService from "../../services/MetricsService";
import FormButton from "../../components/Atoms/FormButton.vue";
import VODHeader from "../../components/VOD/VODHeader.vue";
import VODActionBar from "../../components/VOD/VODActionBar.vue";
import VODEmbedTab from "../../components/VOD/VODEmbedTab.vue";
import VODChaptersTab from "../../components/VOD/VODChaptersTab.vue";
import VODCaptionsTab from "../../components/VOD/VODCaptionsTab.vue";
import VODScheduleTab from "../../components/VOD/VODScheduleTab.vue";
import VODMetaTab from "../../components/VOD/VODMetaTab.vue";
import VODSocialTab from "../../components/VOD/VODSocialTab.vue";
import VODClipsTab from "../../components/VOD/VODClipsTab.vue";
import TabGroup from "../../components/Atoms/TabGroup.vue";
import TabItem from "../../components/Atoms/TabItem.vue";
import Badge from "../../components/Badge.vue";
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const props = defineProps({
  stream: {
    type: Object,
    required: true,
  },
});

const activeTab = ref("embed");
const counter = ref(0);
const streamMeta = ref(null);

const maxPosterUploadMB = ref(2);
const videoMeta = ref(null);

const clips = ref([]);

const availableEncodingPresets = ref([
  { id: "360p", text: "360p", minHeight: 300 },
  { id: "480p", text: "480p", minHeight: 440 },
  { id: "720p", text: "720p", minHeight: 700 },
  { id: "1080p", text: "1080p", minHeight: 1020 },
]);
const maxTotalFileSize = ref(0);
const aioStorageLimit = ref({});
const storageRestricitonsLoaded = ref(false);

const loadingStatus = computed(() => store.getters["Ui/loadingStatus"]);
const vodFiles = computed(() => store.getters['Streams/vodFiles'])

const maxVideoFileSize = computed(() => {
  let maxSize = null;
  const subscriptions = _.cloneDeep(store.state.User.subscriptions);

  if (props.stream.type === "vod" && aioStorageLimit.value) {
    maxSize = 100;
    return maxSize;
  }
  return maxSize;
});
const canEncodeVideos = computed(() => {
  const encodingUnits = _.get(
    store.state.User.baseSub,
    "package.definition.encodingUnits"
  );
  return encodingUnits > 0;
});

const maxFileSizeErrorMessage = computed(() => {
  const assignedSpace = store.state.User.maxVodStorage;
  return `Your VOD storage limit (${assignedSpace}GB) has been exceeded. Upgrade now to increase your video upload capacity.`;
});

const isLiveToVod = computed(() => {
  return props.stream.liveParent;
});
const isTrialOnly = computed(() => {
  const subs = store.state.User.subscriptions;
  const active = subs.filter(
    (x) => x.enabled && ["vod", "live"].includes(x.category)
  );
  const allTrial = active.filter(
    (x) => x.package.name && /trial/gi.test(x.package.name)
  );
  return !active.length || allTrial.length === active.length;
});
const currentFile = computed(() => {
  return vodFiles.value?.find((file) => file.id === route.params.videoId);
});

const playerUrl = computed(() => {
  if (currentFile.value?.dvr) {
    const range = `${currentFile.value?.from}-${currentFile.value?.duration}`;
    return `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/vod/${
      currentFile.value.streamKey
    }?range=${range}&preview=true&abr=${
      currentFile.value.abr ? "true" : "false"
    }&server=${currentFile.value.server}`;
  }

  if (currentFile.value?.archive) {
    const range = `${currentFile.value?.archive?.from}-${currentFile.value?.archive?.duration}`;
    return (
      `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${
        currentFile.value.archive.key
      }?range=${range}&preview=true&abr=${
        currentFile.value?.archive?.abr ? "true" : "false"
      }` + (currentFile.value?.archive?.namedHls ? "&namedHls=true" : "")
    );
  }
  const playerUri = `${
    process.env.VUE_APP_PLAYER_APP_BASE_URL
  }/vod/${currentFile.value?.id?.replace(".mp4", "")}?preview=true`;
  return playerUri;
});

onMounted(async () => {
  await store.dispatch("Streams/getStreams");
  await store.dispatch("Streams/getFilesInVODFolder");
  await getClips()
  await getData()
});

watch(
  () => route.params,
  async () => {
    // setupMediaLibs();
    store.dispatch("Ui/setLoadingStatus", true);
    await store.dispatch("Streams/getStreams");
    await store.dispatch("Streams/getFilesInVODFolder");
    await getData();
    await getClips()
    store.dispatch("Ui/setLoadingStatus", false);
  }
);

const getData = async () => {
  const videoMetaData = await StreamService.getStreamPlaylistVideoMetaData(
    route.params.videoId
  );
  const streamMetadata = await StreamService.getStreamMetadata(
    route.params.streamId,
    currentFile.value._id
  );
  videoMeta.value = videoMetaData;
  streamMeta.value = streamMetadata;
};

const setTab = (tab) => {
  activeTab.value = tab;
};

const isTabActive = (tab) => {
  return tab === activeTab.value;
};

const calculateAioStorageLimit = async () => {
  const subscriptions = store.state.User.subscriptions;
  const activeAIOSub = _.find(
    subscriptions,
    (sub) => sub.category === "live" && sub.enabled === true
  );
  if (activeAIOSub) {
    const res = await MetricsService.getSubscriptionStorage(
      UserService.getUserId(activeTeam.value),
      activeAIOSub.package._id
    );
    aioStorageLimit.value = res ? res : {};
  }
};

const calculateAvailableStorage = async () => {
  const assignedSpace = parseFloat(store.state.User.maxVodStorage);
  const existingFileStorage = currentFile.value?.bytes;
  let freeSpaceAvailable = 0;

  let totalVodBytes = 0;
  let usedDvrStorage = 0;
  if (assignedSpace) {
    const resp = await MetricsService.getUserDvrStorage();
    usedDvrStorage = resp.bytes || 0;
    const streams = await StreamService.getUserStreams();
    if (aioStorageLimit.value) {
      aioStorageLimit.value.bytes += usedDvrStorage;
      aioStorageLimit.value.unitsLeft -= usedDvrStorage;
    }

    streams.forEach((item) => {
      if (item.type === "vod" && !item.liveParent) {
        totalVodBytes += item.totalBytes;
      }
    });

    if (totalVodBytes > 0) {
      totalVodBytes = totalVodBytes / 1000 ** 3;
    }

    const dvrHumanSize = usedDvrStorage / 1000 ** 3;
    freeSpaceAvailable =
      (assignedSpace - (totalVodBytes + dvrHumanSize)) * 1000 ** 3;
  }
  maxTotalFileSize.value = freeSpaceAvailable + existingFileStorage;
};

const onFinishedUploadingVideoThroughCloud = async (video) => {
  const streamId = props.stream._id;
  const streamKey = props.stream.key;
  const newVideoId = video.videoId || video.id;
  video.file = {
    name: currentFile.value.fileName,
    size: video.bytes,
  };
  video.precedence = currentFile.value.precedence;
  const uploadResult = await StreamService.onCompleteVodReplacement(
    currentFile.value.id,
    newVideoId,
    streamId,
    streamKey,
    video
  );
  // this will clear captions, chapeters, schedule, and posters.
  await StreamService.clearVodMetadata(
    streamId,
    currentFile.value.id,
    currentFile.value._id
  );
  notify({
    group: "success",
    text: "media replaced successfully",
  });

  setTimeout(() => window.location.reload(), 300);
};

const setupMediaLibs = () => {
  const vm = this;
  const scriptId = "mediainfo-js";

  // async load mediainfo js
  const scriptEl = document.createElement("script");
  if ("WebAssembly" in window) {
    scriptEl.src = "https://castr.com/libs/mediainfojs2/MediaInfoWasm.js";
  } else {
    scriptEl.src = "https://castr.com/libs/mediainfojs2/MediaInfo.js";
  }

  scriptEl.setAttribute("id", scriptId);
  scriptEl.onload = function () {
    initClass();
  };
  document.head.appendChild(scriptEl);

  function initClass() {
    // eslint-disable-next-line no-undef
    vm.mediaInfoLib = MediaInfoLib({
      postRun() {
        if (
          typeof Promise !== "undefined" &&
          vm.mediaInfoLib instanceof Promise
        ) {
          vm.mediaInfoLib.then((module) => {
            vm.widgetLoaded = true;
            vm.mediaInfoLib = module;
          });
        } else {
          vm.widgetLoaded = true;
        }
      },
    });
  }
};

const onClickAnalytics = () => {
  const route = router.resolve({
    name: "AnalyticsDashboardWithRoute",
    params: { videoId: currentFile?.value.refId ?? route.params.videoId },
    query: { folderId: props.stream._id },
  });
  window.open(route.href, "_blank");
};

const videoMetadataScheduleUpdated = (meta) => {
	if (!videoMeta?.value?.schedule) {
		videoMeta.value = { schedule: meta }
	}
	videoMeta.value.schedule = meta
}

const getPlayerUrl = () => {
  if (currentFile.value) {
    let playerUri = currentFile.value.embedUrl;
    if (!playerUri) {
      let vid = currentFile.value?.id;
      if (vid.split(".")[1] === "mp4") {
        vid = vid.replace(".mp4", "");
      }
      playerUri = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/vod/${vid}`;
    }
    playerUri = playerUri.replace(
      "https://player.castr.io",
      process.env.VUE_APP_PLAYER_APP_BASE_URL
    );
    playerUri = playerUri;
    return playerUri;
  }
};
const getClips = async () => {
  clips.value = await StreamService.getVodClips(
    route.params.streamId,
    currentFile.value._id
  );
};
</script>
<template>
  <div>
    <Spinner
      v-if="loadingStatus"
      text="Retrieving data..."
      classes="text-dark-8 mh-60"
      spinner-color="var(--c-dark-8)"
      spinner-size="15px"
    />
    <div v-else>
      <VODHeader class="mb-6" :vodFile="currentFile" :vodFolder="stream" />
      <div class="flex gap-x-4 flex-wrap lg:flex-nowrap">
        <iframe
          :src="playerUrl"
          class="embed video-frame !mb-2 lg:!mb-6 basis-full lg:basis-8/12"
          frameborder="0"
          width="100%"
          scrolling="no"
          allowfullscreen
          webkitallowfullscreen
          mozallowfullscreen
          oallowfullscreen
          msallowfullscreen
        />
        <VODActionBar
          class="basis-full lg:basis-3/12"
          :vodFile="currentFile"
          :vodFolder="stream"
          :query-string="query"
          :player-url="getPlayerUrl()"
        />
      </div>
      <TabGroup class="" type="lines">
        <TabItem
          type="line"
          @click="setTab('embed')"
          label="embed"
          :isActive="isTabActive('embed')"
        />
        <TabItem
          type="line"
          @click="setTab('chapters')"
          label="chapters"
          :isActive="isTabActive('chapters')"
        />
        <TabItem
          v-if="!isTrialOnly && !isLiveToVod"
          type="line"
          @click="setTab('captions')"
          label="captions"
          :isActive="isTabActive('captions')"
        />
        <TabItem v-if="isLiveToVod || isTrialOnly" type="line" disabled>
          <ejs-tooltip
            :content="
              isLiveToVod
                ? 'Captions available for VOD files only, Live-to-VOD captions support is coming soon...'
                : isTrialOnly
                ? 'Upgrade to use this feature.'
                : ''
            "
          >
            <span class="!text-sm">Captions</span>
          </ejs-tooltip>
        </TabItem>
        <TabItem
          type="line"
          @click="setTab('schedule')"
          label="schedule"
          :isActive="isTabActive('schedule')"
        />
        <TabItem
          type="line"
          @click="setTab('analytics')"
          label="analytics"
          :isActive="isTabActive('analytics')"
        />
        <TabItem
          type="line"
          @click="setTab('meta')"
          label="meta"
          :isActive="isTabActive('meta')"
        />
        <TabItem
          type="line"
          @click="setTab('social share')"
          label="social share"
          :isActive="isTabActive('social share')"
        />
        <TabItem
          type="line"
          @click="setTab('clips')"
          :isActive="isTabActive('clips')"
        >
          Clips
          <Badge class="!ml-1">{{ clips.length }}</Badge>
        </TabItem>
      </TabGroup>
      <div class="md:w-11/12 pb-24">
        <VODEmbedTab
          v-if="isTabActive('embed')"
          :vodFile="currentFile"
          :vodFolder="stream"
          :player-url="getPlayerUrl()"
          :stream-meta="streamMeta"
        />
        <VODChaptersTab
          v-if="isTabActive('chapters')"
          :vodFile="currentFile"
          :vodFolder="stream"
        />
        <VODCaptionsTab
          v-if="isTabActive('captions')"
          :vodFile="currentFile"
          :vodFolder="stream"
        />
        <VODScheduleTab
          v-if="isTabActive('schedule')"
          :vodFile="currentFile"
          :vodFolder="stream"
		  @video-metadata-schedule-updated="videoMetadataScheduleUpdated"
          :player-url="getPlayerUrl()"
          :video-meta="videoMeta"
        />
        <section v-if="isTabActive('analytics')">
          <h3 class="text-base frnt-medium mt-4 mb-2">Checkout Analytics</h3>
          <p class="text-surface-8 text-sm !mb-2">
            By clicking the button below you will be redirected to the Analytics
            menu
          </p>
          <FormButton
            label="Checkout analytics of your video"
            @click="onClickAnalytics"
          />
        </section>
        <VODMetaTab
          v-if="isTabActive('meta')"
          :vodFile="currentFile"
          :vodFolder="stream"
          :stream-meta="streamMeta"
        />
        <VODSocialTab
          v-if="isTabActive('social share')"
          :vodFile="currentFile"
          :vodFolder="stream"
          :stream-meta="streamMeta"
        />
        <VODClipsTab
          v-if="isTabActive('clips')"
          @clips-update="getClips"
          :vodFile="currentFile"
          :vodFolder="stream"
          :player-url="getPlayerUrl()"
        />
      </div>
    </div>

    <alert-modal
      modal-id="embed-upload-format"
      message="Only valid image files are allowed. Please pick an image file"
    />

    <alert-modal
      :message="
        'Uploaded image too large. Image must not exceed ' +
        maxPosterUploadMB +
        'MB'
      "
      modal-id="embed-image-size-exceed"
    />

    <alert-modal
      modal-id="embed-image-mime-type"
      message="Only PNG/JPG image files are allowed"
    />

    <b-modal
      hide-header
      hide-footer
      centered
      id="cloud-import-replace"
      class="cloud-import-box"
    >
      <UppyUploaderReplace
        v-if="storageRestricitonsLoaded"
        :stream="stream"
        :max-files="1"
        :max-total-file-size="maxTotalFileSize"
        :maxFileSize="
          maxVideoFileSize ? maxVideoFileSize * 1000 ** 3 : maxVideoFileSize
        "
        :storageLimit="aioStorageLimit"
        :oldFileSize="currentFile.bytes"
        :replaceVideoId="currentFile.id"
        @upload-success="onFinishedUploadingVideoThroughCloud"
        :encodingPresets="canEncodeVideos ? availableEncodingPresets : []"
      />
    </b-modal>

    <upload-alert-modal
      modal-id="max-size-vod-storage-reached"
      message="Your storage limit has been exceeded"
      :body="
        maxFileSizeErrorMessage ||
        'Your file size cannot exceed. Upload smaller file or upgrade your plan to continue.'
      "
      ok-text="Cancel"
      button-alignment="text-right"
    />
  </div>
</template>

<style scoped></style>
