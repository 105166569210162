<script setup>
import _ from 'lodash';
import { computed, ref, onMounted, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Spinner from "@/components/ui/Spinner.vue"
import IconBase from '../icon/IconBase.vue';
import IconCamera from '../icon/IconCamera.vue';
import IconSearch from '../icon/IconSearch.vue';
import IconFolder from '../icon/IconFolder.vue';
import IconPlaylist from '../icon/IconPlaylist.vue';
import FormInput from '../Atoms/FormInput.vue';
import FormButton from '../Atoms/FormButton.vue';
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';
import IconChevronUpDown from '../icon/IconChevronUpDown.vue';
import StreamsWidget from './StreamsWidget.vue';
import { useMatchMedia } from '@/utils'

const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const props = defineProps({
  selectedStream: {
    type: Object,
    required: false
  },
  dropRight: {
    type: Boolean,
    required: false,
    default: false
  },
  showPlaylist: {
    type: Boolean,
    default: false
  },
  hideNotification: {
    type: Boolean,
    default: false
  },
  closeAfterSelect: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits({
  'on-show': (value) => { return value },
  'on-hide': (value) => { return value }
})

const selectedSourceId = computed(() => {
  if (props.selectedStream) return props.selectedStream?.id
  return false
})

const selectedSourceIcon = computed(() => {
  if (props.selectedStream?.type === 'live') return IconCamera
  if (props.selectedStream?.type === 'vod') return IconFolder
  if (props.selectedStream?.streams) return IconPlaylist
  return
})

onMounted(() => {
  if (props.selectedStream) {
    sourceSelectedName.value = props.selectedStream.name
  }
})

const isDesktop = useMatchMedia('(min-width: 768px)');

const sourceDropdown = ref(null)

const selectedVodCheckbox = ref(false)
const sourceSelectedName = ref('Select source')

const onStreamSourceSelected = (stream, type) => {
  sourceSelectedName.value = stream.name
  if (sourceDropdown.value) sourceDropdown.value.toggleDropdown()
  emit('on-source-selected', stream)
  selectedVodCheckbox.value = false
}
</script>
<template>
  <div class="flex justify-between items-center w-full">
    <drop-down-menu v-if="isDesktop" ref="sourceDropdown" class="w-full"
      customClasses="w-full text-tiny disabled:text-surface-7 h-8 bg-surface-3 rounded-md flex items-center px-3 capitalize truncate"
      fullWidth="true" position="right" preventHide maxHeight="max-h-auto">
      <template #toggle-button>
        <icon-base class="!mr-2 shrink-0">
          <component :is="selectedSourceIcon" />
        </icon-base>
        <span class="truncate">{{ sourceSelectedName }}</span>
        <icon-base class="!ml-auto shrink-0">
          <icon-chevron-up-down />
        </icon-base>
      </template>
      <template #menu-items>
        <drop-down-menu-item isCustom class="!px-3 !pb-3">
          <!-- TODO Stream Widget should always have wrapper ID and placement prop = body!
           This might have big problems if not exist. We need to refctore it better in the future -->
          <div id="#streams-widget-wrapper">
            <StreamsWidget placement="body" @add-stream="onStreamSourceSelected" source="paywall" />
          </div>
        </drop-down-menu-item>
      </template>
    </drop-down-menu>
    <div v-else id="#streams-widget-wrapper" class="flex items-center w-full">
      <icon-base class="!mr-2 shrink-0">
          <component :is="selectedSourceIcon" />
        </icon-base>
        <span class="truncate mr-auto">{{ sourceSelectedName }}</span>
      <StreamsWidget placement="body" @add-stream="onStreamSourceSelected" :hide-notification="hideNotification" :close-after-select="closeAfterSelect" source="paywall" />
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>