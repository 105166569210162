<script setup>
import _ from 'lodash'
import { ref, computed, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import IconBase from '../icon/IconBase.vue'
import IconHosting from '../icon/IconHosting.vue'
import IconInfo from '../icon/IconInfo.vue'
import IconBookOpen from '../icon/IconBookOpen.vue'
import Title from '../ui/Title.vue'
import FormButton from '../Atoms/FormButton.vue'
import { bytes } from '../../filtersNew'
import DemoVideoModal from '../modals/DemoVideoModal.vue'
import DropDownMenu from '../Atoms/DropDownMenu.vue'
import DropDownMenuItem from '../Atoms/DropDownMenuItem.vue'
import StreamService from '@/services/StreamService'
import MetricsService from '@/services/MetricsService'

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()

const props = defineProps({
  vodFile: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  vodFolder: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  }
})

const infoDropdown = ref(null)
const vodBandwidth = ref(null)
const isVodBandwidthLoading = ref(false)
const editTitle = ref(false)

const emit = defineEmits({
  'route-change': (id) => { return id }
})

const getVodBandwidth = async () => {
  vodBandwidth.value = 0;
  isVodBandwidthLoading.value = true;
  let id = props.vodFolder.liveParent ? props.vodFile?._id : props.vodFile?.id
  const startTime = new Date(props.vodFile.creationTime).getTime();
  const data = await MetricsService.getVodBandwidth(id, startTime, Date.now());
  vodBandwidth.value = data.bytes;
  isVodBandwidthLoading.value = false;
}

watch(() => route.params, async () => {
  console.log('route change');
  await getVodBandwidth()
}, {immediate: true})

const saveNewName = async (newName) => {
  if (!newName || newName === props.vodFile.fileName) return
  try {
    if (props.vodFolder?.liveParent) {
      const q = {
        streamId: props.vodFolder.liveParent,
        from: props.vodFile.archive.from,
        duration: props.vodFile.archive.duration,
        name: newName,
      };
      await StreamService.setStreamDvrArchiveName(q)
    } else {
      await StreamService.updateStreamPlaylistVideoFilename(props.vodFolder._id, props.vodFile?.id, newName);
    }
    window.trackEvent(
      `Updated video name ${props.vodFile.fileName} -> ${newName}`
    );
    await store.dispatch('Streams/getStreams')
    await store.dispatch('Streams/getFilesInVODFolder')
    notify({
      group: 'success',
      title: "The file name was successfully changed",
    });
    // track event

  } catch (err) {
    notify({
      group: 'error',
      title: "Couldn't change stream name",
      text: err.message
    });
  }
}
const bytesFormated = (item) => {
  return bytes(item)
}
const closeDropdown = () => infoDropdown.value.toggleDropdown()

const showDemoModal = () => {
  root.$emit('bv::show::modal', 'modal-demo-video');
}

const toggleEdit = (value) => editTitle.value = value

const computedClasses = computed(() => {
  return `text-lg font-medium rounded-md block flex-grow-0 items-center max-w-[700px] ${editTitle.value ? '': 'truncate h-10 max-w-[85vw]'}`
})
</script>

<template>
  <header class="flex pr-8">
    <div>
      <Title :editable="true" :title="vodFile?.fileName" @changed="saveNewName" @edit="toggleEdit" :titleClasses="computedClasses" />
      <p class="text-surface-6 text-sm flex flex-wrap gap-x-4">
        <a target="_blank" class="flex items-center hover:text-surface-9  hover:no-underline"
          href="https://docs.castr.com/en/collections/1750908-video-hosting">
          <icon-base class="!mr-2" fill="none">
            <icon-book-open />
          </icon-base>
          How Video Hosting work
        </a>
        <FormButton type="link" customClasses="flex items-center hover:text-surface-9" @click="showDemoModal">
          <icon-base class="!mr-2" fill="none">
            <icon-hosting />
          </icon-base>
          Video Hosting Demo
        </FormButton>
      </p>
    </div>
    <DropDownMenu class="ml-auto" type="secondary" position="right" isIcon ref="infoDropdown" listClasses="w-[200px]">
      <template #toggle-button>
        <icon-base class="shrink-0 text-surface-8" fill="none">
          <icon-info />
        </icon-base>
      </template>
      <template #menu-items>
        <DropDownMenuItem isCustom @click="closeDropdown">
          <dl class="grid grid-cols-2 gap-2 text-sm !p-2">
            <dt class="font-normal text-surface-9">File size</dt>
            <dd class="text-right text-surface-7">
              <span v-if="props.vodFile?.bytes" class="text-uppercase">{{ bytesFormated(props.vodFile?.bytes) }}</span>
              <span v-else>-</span>
            </dd>
            <template v-if="props.vodFile?.mediaInfo?.width">
              <dt class="font-normal text-surface-9">Resolution</dt>
              <dd class="text-right text-surface-7">
                {{ props.vodFile.mediaInfo.width }} x {{ props.vodFile.mediaInfo.height }}
              </dd>
            </template>
            <template v-if="props.vodFile?.mediaInfo?.fps">
              <dt class="font-normal text-surface-9">FPS</dt>
              <dd class="text-right text-surface-7">
                {{ props.vodFile.mediaInfo.fps }} FPS
              </dd>
            </template>
            <template v-if="props.vodFile?.mediaInfo?.codecs">
              <dt class="font-normal text-surface-9">Codecs</dt>
              <dd class="text-right text-surface-7">
                <span v-for="(codec, index) in props.vodFile.mediaInfo.codecs.filter(({ type }) => type !== 'general')"
                  :key="index" :variant="codec.type" class="">
                  {{ codec.codec }}
                  <span
                    v-if="index != (props.vodFile.mediaInfo.codecs.filter(({ type }) => type !== 'general').length - 1)">,</span>
                </span>
              </dd>
            </template>
            <dt class="font-normal text-surface-9">Bandwidth</dt>
            <dd class="text-right text-surface-7">
              <span v-if="isVodBandwidthLoading" class="text-uppercase">Loading...</span>
              <span v-else-if="vodBandwidth > 0" class="text-uppercase">{{ bytesFormated(vodBandwidth) }}</span>
              <span v-else>-</span>
            </dd>
          </dl>
        </DropDownMenuItem>
      </template>
    </DropDownMenu>
    <demo-video-modal videoid="x0PfzYt39V5hHxW9_gn0A" />
  </header>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
