<template>
  <g clip-path="url(#clip0_11601_2631)">
    <path d="M5.08055 3.06119L5.96033 1C3.37682 1.71869 1.53673 3.79572 0.997546 6.24392" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.8627 13.1437L9.96338 15.1964C12.5536 14.5024 14.4134 12.443 14.9759 10" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="3" y="6" width="6" height="7" rx="2" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 10H11C12.1046 10 13 9.10457 13 8V5C13 3.89543 12.1046 3 11 3H9C7.89543 3 7 3.89543 7 5V6" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_11601_2631">
      <rect width="16" height="16" fill="currentColor"/>
    </clipPath>
  </defs>
</template>