<template>
	<div class>
		<!-- Modal Component -->
		<!-- modal-class="modal-add-stream modal-fullscreen1 modal-fullscreen-right modal-platform" -->
		<b-modal
			id="modal-add-vod"
			ref="modalAddVod"
			:modal-class="['modal-right', { 'modal-collapsed': selectedStreamType }]"
			size="md"
			centered
			hide-footer
			hide-header
		>
			<!-- stream types -->
			<div class="modal-container">
				<div class="" v-show="operational">
					<b-row align-v="start" class="mb-2 px-3 mt-100">
						<b-col cols="8" class="mt-3">
							<!-- <h3 class="page-title">New Folder</h3>
							<p class="text-s-m text-dark-8">You can add files and set protection for all files in a folder</p> -->
							<b-navbar-brand
								:to="{ path: '/dashboard' }"
								tag="a"
								class="modal-logo"
								>
									<!-- <b-img
									height="40px"
										class="logo-icon"
										src="https://castr.com/wp-content/uploads/2023/12/Christmas_logo_2023.png"
										alt="Streaming Dashboard - Castr"
									/> -->
									<b-img
									height="32px"
										class="logo-icon"
										:src="require('@/assets/castr.svg')"
										alt="Streaming Dashboard - Castr"
									/>
									<div class="logo-icon-dot" />
							</b-navbar-brand>
						</b-col>
						<b-col cols="4" class="mt-3 text-right">
							<b-button
								variant="icon"
								class="px-2"
								@click="dismiss"
							>
								<img
									src="@/assets/images/menu-close.svg"
									alt="Menu close"
								>
							</b-button>	
						</b-col>
					</b-row>
					<b-row class="px-3">
						<b-col>
							<b-alert
								v-if="error && !error.role"
								show
								variant="danger"
								class="text-left alert-danger-style2"
							>
								<div v-html="error.message"></div>
								<div
									v-if="error.subscription"
									class="text-center"
									style="margin-top:10px"
								>
									<a href="/app/subscribe">
										<b-button
											variant="success"
											size="md"
										>
											upgrade now
										</b-button>
									</a>
								</div>
							</b-alert>
							<div v-if="error">
								<div v-if="error.role">
									<p>
										You do not have an active {{ (selectedStreamType === 'restream') ? 'Multistream' : selectedStreamType + (selectedStreamType !== 'vod' ? ' streaming' : '') }} subscription.
										<br>Would you like to activate our 7 days trial ?</p>
									<div v-if="trialError"
										class="alert alert-danger"
									>{{ trialError.message }}</div>
									<div class="text-right">
										<div v-if="trialProcessing"
										>
											<b-progress :value="100"
												:max="100"
												animated
												class="w-75"
											/>
										</div>
										<div v-else>
											<b-button variant="outline-secondary" size="md" @click="dismiss">cancel</b-button>
											<b-button variant="primary" size="md" @click="forceActivateTrial">Activate Trial</b-button>
											<div v-if="selectedStreamType !== 'scheduled'">
												<router-link :to="`/subscribe?category=${selectedStreamType}`">
													<button class="btn btn-sm btn-outline-primary">Want more options?</button>
												</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
					<b-row class="mt-100 px-3">
						<b-col>
							<h3 class="text-s-xxl  text-500">New Folder</h3>
							<p class="stream-item__p text-dark-7 mb-4">You can add files and set protection for all files in a folder</p>
						</b-col>
					</b-row>
					<b-row class="px-3">
						<b-col>
							<label class="text-s-s">
								Folder name *
							</label>
							<b-input
								v-model="channel.name"
								class="input"
								placeholder="#folder_name"
								@keypress="onInputChange('name')"
							/>
							<p
								v-show="formErrors.name"
								class="color-danger pl-1"
							>
								#folder_name
							</p>
						</b-col>
					</b-row>
					<b-row class="px-3">
						<b-col>
							<div
							v-show="operational || processing"
						>
							<b-button
								variant="primary"
								size="md"
								class="w-100 mt-3"
								disabled
								v-if="processing"
							>
								<Spinner text="Creating..." classes="text-dark-8" spinner-color="var(--c-dark-8)" spinner-size="15px" />
							</b-button>
							<div
								v-else
								class="text-center"
							>
								<b-button
									variant="primary"
									size="md"
									class="w-100 mt-4"
									@click="onSaveChannel"
								>
									<!-- Create {{ selectedStreamType === 'vod' ? 'Bucket' : 'Stream' }} -->
									Create Folder
								</b-button>
								<div class="mt-2">
									<b-button
										variant="outline-secondary"
										size="md"
										class="w-100 mb-3 mb-md-0"
										@click="unselectStreamType"
									>
										Cancel
									</b-button>
								</div>
							</div>
						</div>
						</b-col>
					</b-row>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import IconBase from '@/components/icon/IconBase.vue';
import IconLivestream from '@/components/icon/IconLivestream.vue';
import IconMultistream from '@/components/icon/IconMultistream.vue';
import IconIpcam from '@/components/icon/IconIpcam.vue';
import IconSchedulestream from '@/components/icon/IconSchedulestream.vue';
import IconVodChannel from '@/components/icon/IconVodChannel.vue';
import SubscriptionService from '@/services/SubscriptionService';

export default {
	name: 'AddVodModal',
	components: {
		Spinner,
		IconBase,
		IconLivestream,
		IconMultistream,
		IconIpcam,
		IconSchedulestream,
		IconVodChannel
	},
	data() {
		return {
			processing: false,
			trialProcessing: false,
			error: null,
			trialError: null,
			operational: true,
			viewStage: 1,
			selectedStreamType: 'vod',
			userSubscription: null,
			channel: {
				name: null
			},
			selectedRegion: null,
			regions: [],
			formErrors: { name: false, region: false },
			streamTypeList: [
				{
					type: 'vod',
					title: 'New VOD Bucket',
					description: 'HLS Videos With Castr CDN',
					description2: 'Embed videos on your website and OTT platforms.',
					icon: require('@/assets/images/vod-plan.png'), // eslint-disable-line no-undef
					iconAlt: 'fa fa-stream'
				}
			],
			onInputChange(prop) {
				this.formErrors[prop] = false;
			}
		};
	},
	computed: {
		subscriptions() {
			return this.$store.state.User.subscriptions;
		},
		contextualRegionList() {
			const stype = 'vod';
			return stype
				? this.regions.filter(r => r.platforms.indexOf(stype) > -1)
				: [];
		},
		streamTypeSubscriptionCategory() {
			return 'vod';
		}
	},
	async mounted() {
		this.$refs.modalAddVod.$on('hide', this.onDismiss);
		this.$refs.modalAddVod.$on('shown', this.onInit);
		this.regions = await StreamService.getAvailableRegions('list');
		this.selectedStreamType = 'vod';
		this.onStreamTypeSelected();
	},
	methods: {
		async onInit() {
			this.operational = true;
			this.error = null;
			this.trialError = null;
			// this.processing = true;
			this.trialProcessing = false;
		},
		onDismiss() {
			this.resetForm();
			this.clearErrors();
			// lazy clear
			setTimeout(() => {
				this.processing = false;
			}, 1000);
		},
		dismiss() {
			this.$refs.modalAddVod.hide();
			this.onDismiss();
		},
		getCountryFlag(region) {
			return `https://assets.castr.io/countryflags/${region.identifier}/flat/64.png`;
		},
		async onStreamTypeSelected() {
			this.operational = true;
			this.error = null;
			this.trialError = null;
			this.trialProcessing = false;

			const subCat = this.selectedStreamType;
			const hasRequiredSub = this.subscriptions.find(
				(s) => {
					const chain = (subCat === 'restream') ? 'package.category': 'category';
					return _.get(s, chain, '').includes(subCat);
				}
			);

			if (!hasRequiredSub) {
				this.operational = false;
				this.error = { role: true };
			}

			// If client has assigned deployment region for certain stream type
			// we need to show only those regions when creating new stream
			// const platformNode = _.find(this.deploymentRegions, pnode =>
			// 	_.includes(pnode.platforms, subCat)
			// );
			// if (platformNode && !_.isEmpty(platformNode.regions)) {
			// }

			this.regions = await StreamService.getAvailableRegions(subCat);

			if (this.contextualRegionList.length === 1) {
				this.channel.region = this.contextualRegionList[0]._id;
			}
		},
		unselectStreamType() {
			// this.selectedStreamType = null;
			this.resetForm();
			this.clearErrors();
			this.dismiss();
		},
		async forceActivateTrial() {
			this.trialError = null;
			this.trialProcessing = true;

			// activate trial
			const subCat = this.selectedStreamType;
			try {
				await SubscriptionService.requestTrial(subCat);
				// update vuex state
				await this.$store.dispatch('User/loadUser', null, { root: true });
			} catch (e) {
				this.trialError = e;
				return;
			} finally {
				this.trialProcessing = false;
			}

			this.error = null;
			this.operational = true;

			// on trial activated
			if (!this.trialError) {
				this.$notify({
					group: 'success',
					text: `${this.selectedStreamType} trial activated successfully`
				});
			}
		},
		async onSaveChannel() {
			this.error = null;
			const stype = 'vod';
			if (!this.validateForm()) return;
			this.processing = true;

			try {
				// const stream = await StreamService.addStream(
				const newStream = await StreamService.addStream(
					this.channel.name,
					this.channel.region,
					'vod'
				);
				// #custom-patch: new stream not found in api response
				const streamList = await StreamService.getUserStreams();
				const stream = _.find(
					streamList,
					s =>
						s.type === 'vod' &&
                  s.name === this.channel.name
				);
				this.$emit('new-channel', stream, this.selectedRegion);
				this.dismiss();
				await this.$store.dispatch('Streams/getStreams');
				await this.$store.dispatch('Streams/getVodFilesByVodId', newStream._id)
				this.$router.push({
          name: "VODBucketVideos",
          params: { streamId: newStream._id },
        });
			} catch (err) {
				this.error = err;
				this.processing = false;
				err.message = '<i class="fas fa-exclamation-triangle mr-2"></i>'+err.message;
				if(this.selectedStreamType === 'vod') {
					err.message = err.message.replace('streams and platforms', 'VOD Bucket');
				}
				// check if subscripiton error
				if (err && _.indexOf(err.message, 'subscription') > -1) {
					this.error.subscription = true;
				}
			}
		},
		validateForm() {
			const props = ['name'];
			let valids = 0;
			_.each(props, prop => {
				const val = this.channel[prop];
				if (val) valids++;
				// else this.formErrors[prop] = true;

				this.formErrors[prop] = !val;
			});

			return valids === props.length;
		},
		clearErrors() {
			this.error = null;
			this.formErrors.name = false;
		},
		resetForm() {
			this.channel.name = null;
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stream-item__p{
	cursor: pointer;
}
@media (max-width: 767px) {
	:deep(* .modal-dialog-centered) {
		align-items: flex-end;
	}	
	.mt-100 {
		margin-top: 0;
	}
}
</style>
