<template>
	<div id="videos-uploader-widget" :class="stream.type + '_uploadbox'">
		<input ref="uploadInput" type="file" :multiple="allowMultipleUploads" style="display: none;" accept="video/mp4"
			name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange">
		<div v-if="widgetLoaded" class="uploader-body">
			<div v-if="stream.type == 'vod' && !stream.liveParent && !advertisingMediaUpload"
				class="d-flex mb-4 flex-wrap align-items-start justify-content-start">
				<b-dropdown no-caret :disabled="vodSubExpiredDays < 0" variant="primary" size="md"
					toggle-class="d-flex align-items-center justify-content-between text-500 pl-2"
					menu-class="dropdown-menu_md dropdown-menu_sm-100" class="">
					<template #button-content>
						<div class="d-flex justify-content-between align-items-center w-100">
							<svg class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M10 4V10M10 16V10M10 10H4M10 10H16" stroke="white" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
							<span class="mr-auto">New Video</span>
							<svg class="ml-2 dropdown-caret flex-shrink-0" width="12" height="12" viewBox="0 0 12 12" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M9.26863 5.73137L7.13137 7.86863C6.73535 8.26465 6.53735 8.46265 6.30902 8.53684C6.10817 8.6021 5.89183 8.6021 5.69098 8.53684C5.46265 8.46265 5.26465 8.26465 4.86863 7.86863L2.73137 5.73137C1.87462 4.87462 1.44624 4.44624 1.4173 4.07846C1.39218 3.75934 1.52136 3.44749 1.76477 3.23959C2.04529 3 2.65111 3 3.86274 3L8.13726 3C9.34889 3 9.95471 3 10.2352 3.23959C10.4786 3.44749 10.6078 3.75934 10.5827 4.07846C10.5538 4.44624 10.1254 4.87462 9.26863 5.73137Z"
									fill="currentColor" />
							</svg>
						</div>
					</template>
					<b-dropdown-item
						v-if="!isDisabledControl && showUploadButton && (allowMultipleUploads || (videoFiles.length === 0 && uploadList.length === 0))"
						@click="$refs['uploadInput'].click()">From Your Computer</b-dropdown-item>
					<b-dropdown-item v-if="!isTrialSub" v-b-modal.cloud-import-box>Cloud import <b-badge variant="dark-5"
							class="badge_beta ml-2">beta</b-badge></b-dropdown-item>
				</b-dropdown>

				<div v-if="videoFiles.length > 0">
					<b-button variant="outline-secondary" size="md" class="ml-lg-2 mt-4 mt-lg-0 text-nowrap"
						v-b-modal.share-playlist>
						<svg class="mr-2 flex-shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path d="M16.5 12.5V15.5C16.5 16.6046 15.6046 17.5 14.5 17.5H5.5C4.39543 17.5 3.5 16.6046 3.5 15.5V12.5"
								stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M10 2.5V12.5M10 2.5L6 6.5M10 2.5L14 6.5" stroke="currentColor" stroke-width="1.5"
								stroke-linecap="round" stroke-linejoin="round" />
						</svg>
						Share Playlist <b-badge variant="dark-3" class="badge_beta ml-2">Beta</b-badge>
					</b-button>
				</div>
				<div v-else>
					<div class="d-inline-block" v-b-tooltip.hover title="Please upload a video to get the playlist">
						<b-button variant="outline-secondary" size="md" class="ml-lg-2 mt-4 mt-lg-0 text-nowrap" disabled>
							<svg class="mr-2 flex-shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path d="M16.5 12.5V15.5C16.5 16.6046 15.6046 17.5 14.5 17.5H5.5C4.39543 17.5 3.5 16.6046 3.5 15.5V12.5"
									stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M10 2.5V12.5M10 2.5L6 6.5M10 2.5L14 6.5" stroke="currentColor" stroke-width="1.5"
									stroke-linecap="round" stroke-linejoin="round" />
							</svg>
							Share Playlist <b-badge variant="dark-3" class="badge_beta ml-2">Beta</b-badge>
						</b-button>
					</div>
				</div>
				<div v-if="isDisabledControl" class="text-danger">Disable schedule to rearrange files</div>
				<input ref="uploadInput" type="file" :multiple="allowMultipleUploads" style="display: none;" accept="video/mp4">
				<b-button v-if="uploadList.length && allowMultipleUploads" variant="outline-danger" size="md"
					class="ml-2 mt-4 mt-lg-0" @click="clearUploadList">Remove All</b-button>
				<b-modal hide-header hide-footer centered id="cloud-import-box" class="cloud-import-box">
					<UppyUploader v-if="storageRestricitonsLoaded" :stream="stream" :max-files="5"
						:max-total-file-size="maxTotalFileSize"
						:maxFileSize="maxVideoFileSize ? (maxVideoFileSize * (1000 ** 3)) : maxVideoFileSize"
						:storageLimit="aioStorageLimit" @upload-success="onVideoAdded"
						:encodingPresets="canEncodeVideos ? availableEncodingPresets : []" />
				</b-modal>
			</div>
			<div class="" v-else-if="stream.type == 'scheduled'">
				<div>
					<b-row>
						<b-col>
							<div v-if="(multipleUploads && isDisabledControl && stream.enabled)" class="text-danger text-center mb-2">
								Disable stream to make changes</div>
						</b-col>
					</b-row>
					<b-row v-if="(!streamAlive && (videoFiles.length < this.totalFilesPerStream)) || !stream.enabled">
						<b-col class="d-flex justify-content-center mb-3">
							<b-dropdown
								:disabled="(!scheduleSubscriptionsStatus && stream.type == 'scheduled' || (multipleUploads && isDisabledControl && stream.enabled))"
								v-if="uploadList.length < this.totalFilesPerStream" variant="primary" size="md"
								toggle-class="d-flex align-items-center justify-content-between text-500" menu-class="dropdown-menu_md">
								<template #button-content>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
										class="mr-2">
										<path d="M7.99998 14.4V7.19995M7.99998 7.19995L5.59998 9.59995M7.99998 7.19995L10.4 9.59995"
											stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path
											d="M13.2 11.5718C14.1564 11.0185 14.8 9.98435 14.8 8.7999C14.8 7.21762 13.6515 5.90356 12.1428 5.64575C11.4969 3.75753 9.70699 2.3999 7.59995 2.3999C4.94898 2.3999 2.79995 4.54894 2.79995 7.1999C2.79995 7.24535 2.80058 7.29065 2.80184 7.3358C1.8687 7.66474 1.19995 8.55422 1.19995 9.5999C1.19995 10.4882 1.68259 11.2639 2.39995 11.6788"
											stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
									Add Video
								</template>
								<b-dropdown-item @click="$refs['uploadInput'].click()">From Your Computer</b-dropdown-item>
								<b-dropdown-item @click="cloudImp = !cloudImp"
									v-if="uploadList.length < this.totalFilesPerStream && !isTrialSub" v-click-outside="toggleCI"
									v-b-modal.cloud-import-modal>Cloud import <b-badge variant="dark-5"
										class="badge_beta ml-2">beta</b-badge></b-dropdown-item>
								<b-dropdown-item @click="selectLiveToVod()" v-if="!isTrialSub">Video Hosting</b-dropdown-item>
							</b-dropdown>
							<b-button size="md" class="ml-2" v-if="uploadList.length && allowMultipleUploads"
								variant="outline-secondary" @click="clearUploadList">Remove All</b-button>
						</b-col>
					</b-row>
					<b-row v-if="this.totalFilesPerStream != 999">
						<b-col>
							<p class="text-s-s text-dark-7 text-center">
								{{ (videoFiles.length < this.totalFilesPerStream) ? 'Start adding your videos' : 'You have' }} ({{
									videoFiles.length }}/{{ this.totalFilesPerStream }} Video Files)</p>
						</b-col>
					</b-row>
				</div>
			</div>
			<div v-else>
				<div v-if="!stream.liveParent || advertisingMediaUpload" class="d-flex flex-column">
					<div class="upload-area--title">
						<b-row align-v="center">
							<b-col md="9">
								<p v-if="advertisingMediaUpload && videoFiles.length === 0" class="text-s-m text-dark-6 m-0">Upload the
									video from your device. Maximum length is 2 minutes.</p>
							</b-col>
							<b-col md="3" class="text-right">
								<!-- <input ref="uploadInput" type="file" :multiple="allowMultipleUploads" style="display: none;" accept="video/mp4" /> -->
								<input ref="uploadInput" type="file" :multiple="allowMultipleUploads" style="display: none;"
									accept="video/mp4" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange">
								<b-button variant="primary" size="sm"
									v-if="!isDisabledControl && showUploadButton && (allowMultipleUploads || (videoFiles.length === 0 && uploadList.length === 0))"
									@click="$refs['uploadInput'].click()">
									<span v-if="advertisingMediaUpload">
										Select File
									</span>
									<span v-else-if="!advertisingMediaUpload">
										<svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M8 14V6.5M8 6.5L5.5 9M8 6.5L10.5 9" stroke="white" stroke-width="1.5"
												stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M3.00959 10.7961C2.12152 10.4126 1.5 9.52887 1.5 8.5C1.5 7.47113 2.12152 6.58743 3.00959 6.20386C3.16208 3.85662 5.11422 2 7.5 2C9.49121 2 11.1804 3.29329 11.7733 5.08571C13.3342 5.43771 14.5 6.83271 14.5 8.5C14.5 9.37858 14.1763 10.1816 13.6416 10.7961"
												stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
										Upload
									</span>
								</b-button>
								<b-button v-if="uploadList.length && allowMultipleUploads" size="md" variant="outline-danger" class="ml-1"
									@click="clearUploadList">Remove All</b-button>
							</b-col>
						</b-row>
					</div>
					<div v-if="isDisabledControl" class="text-danger">Disable schedule to rearrange files</div>
				</div>
			</div>
			<div class="video-list">
				<b-alert v-if="hasIncompatibleMediaFiles" show variant="secondary" class="text-dark-7 text-s-s d-flex p-2">
					<span>
						<svg class="mr-2" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z"
								fill="currentColor" />
						</svg>
					</span>
					<div>
						<p class="m-0 text-dark-9 text-s-m font-weight-500">File is not following our recommendation</p>
						<p class="text-s-s m-0">Your stream may be unstable. We recommend you to encode your file following <a
								href="https://docs.castr.com/en/articles/5161326-recommended-settings-for-recorded-videos" target="_blank"
								class="text-underline text-dark-8">our guide</a>. If you still want to use the current file, kindly do a
							test stream before the actual livestream.</p>
					</div>
				</b-alert>
				<div v-for="media in uploadList" :key="media.name" class="video-item mb-2">
					<b-row v-if="!media.mediaInfo">
						<b-col cols="7">
							<span :title="media.fileNameOriginal" class="video-name">{{ media.fileName }}</span>
						</b-col>
						<b-col cols>
							<div class="text-center">
								<i class="fas fa-spinner fa-spin" />
							</div>
						</b-col>
					</b-row>
					<ul class="list-unstyled mb-0" v-else>
						<b-row tag="li">
							<b-col cols="7" v-if="isMediaCompatible(media.mediaInfo)">
								<span :title="media.fileNameOriginal" class="video-item__name">{{ media.fileName }}</span>
								<div class="video-item__stats text-dark-7 text-s-s">
									<span class="video-item__stats text-dark-7 text-s-s"> {{ media.mediaInfo.width }} x {{
										media.mediaInfo.height }}</span>
									<span v-if="media.mediaInfo.duration > 60"> - {{ media.mediaInfo.durationMins }} mins</span>
									<span v-else> - {{ media.mediaInfo.duration }} secs</span>
									<span> - {{ media.bytes | bytes(false, 0, true) }}</span>
								</div>
								<b-badge v-for="(track, index) in media.mediaInfo.codecs" :key="index" class="mr-1 badge_xs"
									:class="track.type" variant="dark-3">
									{{ track.codec }}
								</b-badge>
							</b-col>
							<b-col cols="7" class="pl-3" v-else>
								<span :title="media.fileNameOriginal" class="video-item__name">{{ media.fileName }}</span>
								<span class="color-warning text-s-s d-flex">
									<svg class="mr-1" width="16" height="15" viewBox="0 0 16 15" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M5.36957 3.79623C6.53108 1.67817 7.11184 0.619141 8 0.619141C8.88817 0.619141 9.46892 1.67817 10.6304 3.79623L14.0638 10.0571C15.15 12.0377 15.693 13.028 15.2574 13.7638C14.8217 14.4996 13.6923 14.4996 11.4334 14.4996H4.56665C2.30773 14.4996 1.17826 14.4996 0.742615 13.7638C0.306966 13.028 0.850048 12.0377 1.93621 10.0571L5.36957 3.79623ZM7.99996 4.74955C8.41417 4.74955 8.74996 5.08534 8.74996 5.49955V7.99955C8.74996 8.41377 8.41417 8.74955 7.99996 8.74955C7.58575 8.74955 7.24996 8.41377 7.24996 7.99955V5.49955C7.24996 5.08534 7.58575 4.74955 7.99996 4.74955ZM8 11.9996C8.55228 11.9996 9 11.5518 9 10.9996C9 10.4473 8.55228 9.99956 8 9.99956C7.44771 9.99956 7 10.4473 7 10.9996C7 11.5518 7.44771 11.9996 8 11.9996Z"
											fill="#E2A052" />
									</svg>
									<span>{{ getErrorMessage(media.mediaInfo) }}</span>
								</span>
							</b-col>
							<b-col cols="5" class="text-right">
								<div v-show="!media.uploading" class="h-100 vod-upload-action justify-content-end align-items-center">

									<!-- upload button -->
									<b-button title="Click to upload this file" variant="primary" size="sm" class="px-2"
										@click="uploadVideoFile(media)" :disabled="media.durationExceeded">
										<span v-if="!media.statusProcessing">
											<svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M10 18V9M10 9L7 12M10 9L13 12" stroke="white" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
												<path
													d="M16.5 14.4649C17.6956 13.7733 18.5 12.4806 18.5 11C18.5 9.02214 17.0645 7.37957 15.1785 7.05731C14.3712 4.69704 12.1338 3 9.5 3C6.18629 3 3.5 5.68629 3.5 9C3.5 9.05681 3.50079 9.11344 3.50236 9.16987C2.33593 9.58104 1.5 10.6929 1.5 12C1.5 13.1104 2.1033 14.0799 3 14.5987"
													stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
											<span v-if="!shouldCheckMediaQuality">Upload</span>
										</span>
										<i v-else class="fa fa-spinner fa-spin" />
									</b-button>

									<!-- remove button -->
									<b-button :key="media.fileName" class="px-1 ml-2" v-show="!media.statusProcessing"
										variant="outline-danger" size="sm" @click="removeVideoFile(media)">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<ellipse cx="10" cy="4.375" rx="6.25" ry="1.875" stroke="currentColor" stroke-width="1.5" />
											<path
												d="M16.25 4.375L14.6875 16.25C14.25 17.5 12.5 18.125 10 18.125C7.5 18.125 5.8125 17.5 5.3125 16.25L3.75 4.375"
												stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>

									</b-button>
								</div>
								<div v-show="media.uploading" class="h-100 vod-upload-action justify-content-end align-items-center">
									<b-progress :value="media.uploadProgress" :max="100" show-progress animated variant="primary"
										class="progressbar-wrapper w-100" />
									<b-button variant="icon" class="p-0" size="sm" @click="cancelVideoUpload(media)">
										<img src="@/assets/images/close2.svg" alt="close">
									</b-button>
								</div>
							</b-col>
						</b-row>
						<b-row v-if="media.durationExceeded">
							<b-col class="text-s-xs text-danger"> Your video exceed 10 mins for trial, please upload shorter
								video.</b-col>
						</b-row>
					</ul>
				</div>
			</div>
		</div>
		<Spinner v-else text="Retrieving data..." classes="text-dark-8 mt-4 mb-5" spinner-color="var(--c-dark-8)"
			spinner-size="15px" />

		<alert-modal :message="uploadErrorMessage || 'You are exceeding your storage limit. Please upgrade to continue'"
			modal-id="alert-storage-exceeding" ok-text="Got it, thanks" button-alignment="text-right" />
		<upload-alert-modal modal-id="alert-video-max-size" :message="maxFileSizeErrorTitle"
			:body="maxFileSizeErrorMessage || 'Your file size cannot exceed. Upload smaller file or upgrade your plan to continue.'"
			ok-text="Cancel" button-alignment="text-right" />
		<upload-alert-detailed-modal modal-id="alert-video-max-size1" :message="maxFileSizeErrorTitle"
			:body="maxFileSizeErrorMessage || 'Your file size cannot exceed. Upload smaller file or upgrade your plan to continue.'"
			:file="alertFileInfo" ok-text="Cancel" button-alignment="text-right" />
		<upload-alert-modal :message="maxFileSizeErrorTitle"
			:body="maxFileNumberErrorMessage || 'Your number of files cannot exceed. Upgrade your plan if you need more files.'"
			modal-id="alert-video-max-file" ok-text="Cancel" button-alignment="text-right" />
		<select-live-to-vod-modal :stream="stream" :total-files-per-stream="totalFilesPerStream" :video-files="videoFiles"
			@updated="onUpdate" />
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Spinner from "@/components/ui/Spinner.vue"
import StreamService from '@/services/StreamService';
import UserService from "@/services/UserService";
import utils from '@/utils';
import Dropzone from '@/components/Dropzone.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import UploadAlertModal from '@/components/modals/UploadAlertModal.vue';
import UploadAlertDetailedModal from '@/components/modals/UploadAlertDetailedModal.vue';
import SelectLiveToVodModal from '@/components/modals/SelectLiveToVodModal.vue';
import _ from 'lodash';
import UppyUploader from '@/components/UppyUploader.vue';
import MetricsService from '@/services/MetricsService';

export default {
	name: 'VideosUploaderCompactWidget',
	components: {
		Dropzone,
		Spinner,
		AlertModal,
		UploadAlertModal,
		UploadAlertDetailedModal,
		SelectLiveToVodModal,
		UppyUploader
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		mediaOnline: {
			type: Boolean,
		},
		schedulerSettings: {
			type: Object,
			default() { return {}; }
		},
		isOneMinuteBefore: {
			type: Boolean
		},
		streamAlive: {
			type: Boolean
		},
		canEncodeVideos: {
			type: Boolean,
			default: false
		},
		multipleUploads: {
			type: Boolean,
			default: true
		},
		maxFiles: {
			type: Number,
			default: 1
		},
		totalFilesPerStream: {
			type: Number,
			default: 999
		},
		videoFiles: {
			type: Array,
			default: () => []
		},
		mp4Only: {
			type: Boolean,
			default: false
		},
		isDisabledControl: {
			type: Boolean,
			default: false
		},
		isVodTrial: {
			type: Boolean,
			default() { return false }
		},
		vodSubExpiredDays: {
			type: Number,
			default() { return 1 }
		},
		advertisingMediaUpload: {
			type: Boolean,
			default: false
		},
		aioStorageLimit: {
			type: Object,
			default() { return {}; }
		},
	},
	data() {
		return {
			appUpdate: false,
			mediaInfoLib: null,
			widgetLoaded: false,
			processing: true,
			uploadErrorMessage: null,
			maxFileSizeErrorTitle: null,
			maxFileSizeErrorMessage: null,
			alertFileInfo: null,
			maxTotalFileSize: null,
			storageRestricitonsLoaded: false,
			scheduledSub: null,
			usedDvrStorage: {
				value: 0,
				loaded: false
			},
			maxFileNumberErrorMessage: null,
			uploadWidgetMaximized: false,
			uploadWidgetCollapse: true,
			uploadList: [],
			availableEncodingPresets: [
				{ id: '360p', text: '360p', minHeight: 300 },
				{ id: '480p', text: '480p', minHeight: 440 },
				{ id: '720p', text: '720p', minHeight: 700 },
				{ id: '1080p', text: '1080p', minHeight: 1020 }
			],
			filesDragging: false,
			allowMultipleUploads: this.multipleUploads,
			showUploadButton: true,
			cloudImp: false,
			showOptions: false,
		};
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			uploadFiles: "Ui/uploadFiles",
			isOwner: "User/isOwner",
			vodStreams: "Streams/vodStreams"
		}),
		activeTeam() {
			return this.$store.state.User.activatedTeam;
		},
		shouldCheckMediaQuality() {
			return this.$route.name === 'ScheduledStreamManageDashboard'
		},
		hasIncompatibleMediaFiles() {
			return this.uploadFiles.some(media => media?.mediaInfo && !this.isMediaCompatible(media.mediaInfo))
		},
		// pendingUploadsList() {
		// 	return this.uploadList.filter(u => !u.uploading);
		// },
		// activeUploadsList() {
		// 	return this.uploadList.filter(u => u.uploading === true);
		// },
		maxVideoFileSize() {
			let maxSize = null;
			const subscriptions = _.cloneDeep(this.$store.state.User.subscriptions);

			if (this.stream.type === 'vod' && this.aioStorageLimit) {
				this.aioStorageLimit.bytes += this.usedDvrStorage.value
				this.aioStorageLimit.unitsLeft -= this.usedDvrStorage.value
				// AIO plans does not have max file size setting, so we set it to 100GB by default
				maxSize = 100
				return maxSize
			}

			const activeScheduledSubs = _.filter(subscriptions, (sub) => sub.category === 'scheduled' && sub.enabled === true)

			activeScheduledSubs.forEach(sub => {
				try {
					if (sub.definitionOverride && sub.definitionOverride.schedulerMaxVideoSize) {
						sub.package.definition.schedulerMaxVideoSize = parseFloat(sub.definitionOverride.schedulerMaxVideoSize)
					}
					if (maxSize) {
						const tmpMaxSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
						if (tmpMaxSize > maxSize) {
							maxSize = tmpMaxSize;
						}
					} else {
						maxSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
					}
				} catch (error) {
					console.error(error);
				}
			})

			return maxSize
		},
		scheduledStorage() {
			let scheduledStorage = null;
			const subscriptions = this.$store.state.User.subscriptions;

			const activeScheduledSubs = _.filter(subscriptions, (sub) => sub.category === 'scheduled' && sub.enabled === true)

			activeScheduledSubs.forEach(sub => {
				try {
					let overrideStorage = parseFloat(sub.package.definition.storage);
					if (sub.definitionOverride && sub.definitionOverride.storage) {
						overrideStorage = parseFloat(sub.definitionOverride.storage)
					}
					if (scheduledStorage) {
						const tmpMaxSize = overrideStorage;
						if (tmpMaxSize > scheduledStorage) {
							scheduledStorage = tmpMaxSize;
							this.scheduledSub = sub
						}
					} else {
						scheduledStorage = overrideStorage
						this.scheduledSub = sub
					}
				} catch (error) {
					console.error(error);
				}
			})

			return scheduledStorage
		},
		maxNumberOfVideoFiles() {
			return this.maxFiles;
		},
		vodStorageLimit() {
			while (!this.$store.state.User) {
				// Waiting for data
			}
			return parseFloat(this.$store.state.User.maxVodStorage);
		},
		isTrialSub() {
			let isTrialSub = false;
			let isPaidSub = false;
			const subscriptions = this.$store.state.User.subscriptions;


			subscriptions.forEach(sub => {
				try {
					if (['5cd5485126fd039846a3255f', '5ccd92f8152b7f25111c39cf', '5e8db3dcc3db0533322db725',
						'6362114709950a6a642540c4', '6362114709950a6a642540c2', '6362114709950a6a642540c3'].indexOf(sub.package._id) !== -1 && sub.enabled === true) {
						isTrialSub = true;
					} else if (sub.enabled === true) {
						isPaidSub = true;
					}
				} catch (error) {
					console.error(error);
				}
			})
			return isPaidSub ? false : isTrialSub;
		},
		scheduleSubscriptionsStatus() {
			const subscriptions = this.$store.state.User.subscriptions;
			const scheduledSubscriptions = _.filter(subscriptions, (sub) => sub.category === 'scheduled');
			const result = scheduledSubscriptions.find(element => element.enabled === true);
			if (result) {
				return true
			} else return false;
		},
	},

	async mounted() {
		this.setupMediaLibs();
		this.setupAWSLibs();
		await this.initMaxFileSizeData()
		await this.initVideoUpload()
		setInterval(() => {
			this.initMaxFileSizeData();
		}, 5000);
	},
	watch: {
		uploadFiles() {
			if (this.uploadFiles.length === 0) {
				this.showUploadButton = true
			}
		}
	},
	destroyed() {
		// this.uploadList.forEach(this.cancelVideoUpload.bind(this));
	},
	directives: {
		'click-outside': {
			beforeMount: (el, binding) => {
				el.clickOutsideEvent = event => {
					if (!(el == event.target || el.contains(event.target))) {
						binding.value();
					}
				};
				document.addEventListener("click", el.clickOutsideEvent);
			},
			unmounted: el => {
				document.removeEventListener("click", el.clickOutsideEvent);
			},
			// bind: function (el, binding, vnode) {
			// 	el.clickOutsideEvent = function (event) {
			// 		// console.log(event.target.classList[0].includes("uppy") );
			// 		// here I check that click was outside the el and his children
			// 		if (!(el == event.target || el.contains(event.target))) {
			// 			// and if it did, call method provided in attribute value
			// 			let uppywrapper = false;
			// 			if (event.target.classList[0]) {
			// 				uppywrapper = event.target.classList[0].includes("uppy");
			// 			}
			// 			if ((event.target.tagName == 'BUTTON' || event.target.tagName == 'SPAN')) {
			// 				var closest = event.target.tagName;
			// 			} else {
			// 				var closest = event.target.closest('.cloud-import-box')
			// 			}
			// 			if (closest != null) {
			// 				uppywrapper = true;
			// 			}
			// 			if (!uppywrapper) { vnode.context[binding.expression](event); }

			// 		}
			// 	};
			// 	document.body.addEventListener('click', el.clickOutsideEvent)
			// },
			// unbind: function (el) {
			// 	document.body.removeEventListener('click', el.clickOutsideEvent)
			// },
		}
	},
	methods: {
		isMediaCompatible(mediaInfo) {
			if (!this.shouldCheckMediaQuality) return true

			if (mediaInfo.hasAudioTrack && !mediaInfo.isVbr) return true
			return false
		},
		getErrorMessage(mediaInfo) {
			let message = ''

			if (mediaInfo.isVbr) {
				message = 'File should be CBR-encoded'
			}

			if (!mediaInfo.hasAudioTrack) {
				if (mediaInfo.isVbr) message += ' and have sound'
				else message = 'File should have sound'
			}

			message += '.'

			return message
		},
		toggleCI: function () {
			if (this.cloudImp == true) {
				this.cloudImp = false;
			}

		},
		async initDvrStorageUsage() {
			let bytes = 0;
			this.usedDvrStorage.loaded = false;
			try {
				const resp = await MetricsService.getUserDvrStorage();
				bytes = resp.bytes || 0;
			} catch (e) {
				console.error('getUsedDvrStorage', e);
			}
			this.usedDvrStorage.loaded = true;
			this.usedDvrStorage.value = bytes;
		},
		onVideoAdded(videoItem) {
			this.$emit('video-added', videoItem);
			this.cloudImp = false;
			this.showOptions = false;
		},
		setupAWSLibs() {
			const vm = this
			let scriptId = 'aws-js'
			if (!document.getElementById(scriptId)) {
				// async load mediainfo js
				let scriptEl = document.createElement('script')
				scriptEl.src = 'https://sdk.amazonaws.com/js/aws-sdk-2.663.0.min.js'
				scriptEl.setAttribute('id', scriptId)
				scriptEl.onload = function () { initClass() }
				document.head.appendChild(scriptEl)
			} else {
				initClass()
			}

			function initClass() { vm.awsLoaded = true }
		},
		onFilesDrop(e) {
			e.preventDefault();
			e.stopPropagation();
			this.showOptions = false;
			if (e.dataTransfer.items) {
				for (const file of e.dataTransfer.items) {
					if (file.kind === 'file' && file.type.startsWith('video')) this.onMediaAdd(file.getAsFile());
				}
			} else {
				for (const file of e.dataTransfer.files) {
					if (file.type.startsWith('video')) this.onMediaAdd(file);
				}
			}
		},
		onMediaPresetSelect(media) {
			setTimeout(() => media.encodingUnitsRequired = computeEncodingUnits(media), 0);
		},
		isPresetSupported(media, preset) {
			return !media.mediaInfo ? false : media.mediaInfo.height >= preset.minHeight;
		},
		togglePresetList(media) {
			media.presetListCollapse = !media.presetListCollapse;
		},
		setupMediaLibs() {
			const vm = this;
			const scriptId = 'mediainfo-js';

			if (!document.getElementById(scriptId)) {
				// async load mediainfo js
				const scriptEl = document.createElement('script');
				if ('WebAssembly' in window) {
					scriptEl.src = 'https://castr.com/libs/mediainfojs2/MediaInfoWasm.js';
				} else {
					scriptEl.src = 'https://castr.com/libs/mediainfojs2/MediaInfo.js';
				}

				scriptEl.setAttribute('id', scriptId);
				scriptEl.onload = function () {
					initClass();
				};
				document.head.appendChild(scriptEl);
			} else {
				initClass();
			}
			function initClass() {
				// eslint-disable-next-line no-undef
				vm.mediaInfoLib = MediaInfoLib({
					postRun() {
						if (typeof Promise !== 'undefined' && vm.mediaInfoLib instanceof Promise) {
							vm.mediaInfoLib.then((module) => {
								vm.widgetLoaded = true;
								vm.mediaInfoLib = module
							});
						} else {
							vm.widgetLoaded = true;
						}
					}
				});
			}
		},
		async initMaxFileSizeData() {
			// Check max video file size (GB)
			let totalVodBytes = 0;
			const vodStorageTherehold = this.vodStorageLimit
			if (this.stream.type === 'vod' && vodStorageTherehold) {
				await this.initDvrStorageUsage();
				const streams = this.vodStreams;

				streams.forEach((item) => {
					if (item.type === 'vod' && !item.liveParent) {
						totalVodBytes += item.totalBytes
					}
				})

				if (totalVodBytes > 0) {
					totalVodBytes = totalVodBytes / (1000 ** 3)
				}

				const dvrHumanSize = this.usedDvrStorage.value / 1000 ** 3;
				// console.log('totalVodBytes', totalVodBytes, 'vodStorageTherehold', vodStorageTherehold, 'dvrUsed', dvrHumanSize);
				this.maxTotalFileSize = (vodStorageTherehold - (totalVodBytes + dvrHumanSize)) * (1000 ** 3);
			}

			if (_.isNumber(this.maxVideoFileSize) && this.stream.type === 'scheduled') {
				this.maxTotalFileSize = (this.maxVideoFileSize - totalVodBytes) * (1000 ** 3);
			}
			this.storageRestricitonsLoaded = true;
		},
		async initVideoUpload() {
			const el = this.$refs.uploadInput;
			if (!el) {
				setTimeout(() => this.initVideoUpload(), 1000);
				return;
			}

			const vm = this;

			if (!el.files.length) return;
			// Calculate max video files of the scheduled stream
			let maxFiles = vm.maxNumberOfVideoFiles;
			maxFiles = vm.totalFilesPerStream - vm.videoFiles.length - vm.uploadList.length;
			if (this.uploadFiles.length + el.files.length > maxFiles) {
				vm.maxFileSizeErrorTitle = `You have exceeded the maximum number of files allowed for upload`
				vm.maxFileNumberErrorMessage = `You have exceeded the limit (${vm.totalFilesPerStream} maximum files allowed) for video files. Upgrade now to increase the number of files you can upload.`
				vm.$root.$emit('bv::show::modal', 'alert-video-max-file');
				el.value = null;
				return;
			};

			// Check max video file size (GB)
			let totalVodBytes = 0;								// total usage of all active(without deleted/archived) VOD + pseudoVOD(live to vod) 
			let totalRealVodBytes = 0							// total usage of all active(without deleted/archived) VOD

			const vodStorageTherehold = vm.vodStorageLimit
			if (vm.stream.type === 'vod' && vodStorageTherehold) {
				const streams = this.vodStreams;

				streams.forEach((item) => {
					if (item.type === 'vod') {
						totalVodBytes += item.totalBytes
					}

					if (item.type === 'vod' && !item.liveParent) {
						totalRealVodBytes += item.totalBytes
					}
				})

				if (totalVodBytes > 0) {
					totalVodBytes = totalVodBytes / (1000 ** 3)
				}

				if (totalRealVodBytes > 0) {
					totalRealVodBytes = totalRealVodBytes / (1000 ** 3)
				}

				let uploadQueueBytes = 0
				// console.log(el.files);
				// console.log(el.files[0]);
				if (Array.isArray(el.files)) {
					el.files.forEach((item) => {
						uploadQueueBytes = uploadQueueBytes + (item.size / (1000 ** 3))
					})
				} else {

					Object.entries(el.files).forEach(
						([key, value]) => {
							// console.log(value.size),
							uploadQueueBytes = uploadQueueBytes + (value.size / (1000 ** 3))
						}
					);

					// el.files.forEach((item) => {
					// 	uploadQueueBytes = uploadQueueBytes + (item.size / (1000 ** 3))
					// })
				}


				const uploadQueue = vm.uploadList || []
				uploadQueue.forEach((item) => {
					uploadQueueBytes = uploadQueueBytes + (item.file.size / (1000 ** 3))
				})

				const dvrHumanSize = vm.usedDvrStorage.value / 1000 ** 3;
				// console.log('totalRealVodBytes', totalRealVodBytes, 'totalVodBytes', totalVodBytes, 'dvrHumanSize', dvrHumanSize, 'uploadQueueBytes', uploadQueueBytes, 'vodStorageTherehold', vodStorageTherehold);
				if ((totalRealVodBytes + uploadQueueBytes + dvrHumanSize) > vodStorageTherehold) {
					vm.maxFileSizeErrorMessage = `Storage capacity cannot exceed ${vodStorageTherehold}GB. Upgrade your plan to continue.`
					vm.$root.$emit('bv::show::modal', 'alert-video-max-size');
					el.value = null;
					return;
				}
			}

			if (vm.stream.type === 'scheduled') {
				if (_.isNumber(vm.scheduledStorage)) {
					let fileSize = 0
					_.forEach(el.files, (file) => fileSize += (file.size / (1000 ** 3)));
					let userId = vm.$store.state.User._id;
					// Get storage usage
					if (!this.isOwner) {
						userId = await UserService.getUserId(vm.$store.state.User.activeTeam)
						console.log(userId);
					}
					const packageId = vm.scheduledSub.package._id

					const storageUsage = await MetricsService.getSubscriptionStorage(userId, packageId)
					const overStorage = (vm.scheduledStorage - (storageUsage?.bytes / (1000 ** 3)) - fileSize) < 0
					if (overStorage) {
						vm.maxFileSizeErrorTitle = `Your storage limit has been exceeded`
						vm.maxFileSizeErrorMessage = `Your Pre-recorded stream storage limit (${vm.scheduledStorage}GB) has been exceeded. Upgrade now to increase your video upload capacity.`
						vm.$root.$emit('bv::show::modal', 'alert-video-max-size');
						el.value = null;
						return;
					}
				}
				if (_.isNumber(vm.maxVideoFileSize)) {
					const overMaxSize = _.find(el.files, (file) => (file.size / (1000 ** 3)) > (vm.maxVideoFileSize - totalVodBytes));
					if (overMaxSize) {
						vm.maxFileSizeErrorTitle = `Exceeds maximum file size limit`
						vm.maxFileSizeErrorMessage = `File size exceeds maximum limit. Maximum allowed file size is ${vm.maxVideoFileSize}GB. Upgrade plan to upload bigger file sizes.`
						vm.alertFileInfo = overMaxSize
						vm.$root.$emit('bv::show::modal', 'alert-video-max-size1');
						el.value = null;
						return;
					}
				}
			}

			// #enabling non-mp4 uploads
			if (vm.mp4Only) {
				const ifHaveNonMp4 = _.find(el.files, (file) => file.type !== 'video/mp4');
				if (ifHaveNonMp4) {
					vm.$root.$emit('bv::show::modal', 'alert-video-encoding');
					el.value = null;
					return;
				}
			}
			this.$store.dispatch('Ui/addStreamToUpload', this.stream);
			Array.prototype.forEach.call(el.files, file => {
				// check for mime types
				if (file.type.startsWith('video')) {
					vm.onMediaAdd(file, vm);
				}
			});

			el.value = null;
		},
		computeVideoPrecedence(video) {
			let precedence = 0;
			let siblingVids = this.videoFiles;
			if (video) {
				siblingVids = _.filter(this.videoFiles, v => v.id !== video.id);
			}
			precedence = _.size(siblingVids) + 1;

			return precedence;
		},
		onMediaAdd(file, vm) {
			// not allow multiple uploads if disabled

			this.showOptions = false;
			if (this.allowMultipleUploads === false && this.uploadList.length > 0) {
				return;
			}

			let trimmedName = file.name;
			const nameChunks = trimmedName.split('.');
			const extname = nameChunks.length > 1 ? nameChunks.pop() : '';
			let basename = nameChunks.join('.');
			if (basename.length > 20) {
				basename = `${basename.substr(0, 14)} ... ${basename.substr(basename.length - 6)}`;
			}
			trimmedName = basename + '.' + extname;

			const newVideo = {
				id: `unnamed_${Math.random().toString().slice(2)}`,
				fileName: trimmedName,
				fileNameOriginal: file.name,
				bytes: file.size,
				mediaInfo: null,
				precedence: this.computeVideoPrecedence(),
				enabled: true,
				removing: false,
				statusProcessing: false,

				file,
				uploadable: true,
				uploading: false,
				uploadProgress: 0,
				encodingPresets: [],
				encodingUnitsRequired: 0,
				presetListCollapse: true,
				durationExceeded: false
			};
			// console.log('Ui/addFileToUpload');
			// this.uploadList = [newVideo, ...this.uploadList];
			this.$store.dispatch('Ui/addFileToUpload', newVideo);
			// const uploadItem = this.uploadList[0];
			const uploadItem = this.uploadFiles[0]
			utils.inspectMediaFile(this.mediaInfoLib, file, info => {
				const durationSec = info.duration;
				const durationMins = Math.ceil(
					(durationSec < 60 ? 60 : durationSec) / 60
				);

				const mappedInfo = { ...info, durationMins };
				// uploadItem.mediaInfo = mappedInfo;
				const file = newVideo
				this.$store.dispatch('Ui/addFileData', { uploadItem: file, mappedInfo });
				if (this.isTrialSub && info.duration > 10 * 60) {
					// uploadItem.durationExceeded = true;
					this.$store.dispatch('Ui/addFileData', { uploadItem: file, durationExceeded: true })
				}
				this.$emit('upload-inspected', { upload: uploadItem, handler: 'raw' });
				// console.log('media-info', info)
			});

			if (this.multipleUploads === false) {
				this.showUploadButton = false;
			}

			this.$emit('upload-enqueue', { upload: newVideo, handler: 'raw' });
		},
		async requestSignedUploadUrl(uploadItem) {
			const fileObj = uploadItem.file;
			const uploadPayload = {
				encodingPresets: uploadItem.encodingPresets,
				metadata: {
					size: fileObj.size,
					type: fileObj.type,
					originalFilename: fileObj.name,
					precedence: uploadItem.precedence
				},
				mediaInfo: uploadItem.mediaInfo,
				streamType: this.stream.type,
				multipartSupport: fileObj.size / (1000 ** 3) > 1 // GB
			};

			const uploadUrl = await StreamService.requestVodMediaUploadUrl(this.stream.type === 'scheduled' ? this.stream._id : this.stream.key, uploadPayload);
			return uploadUrl;
		},
		startMultipartBucketUpload(uploadId, uploadItem, uploadCredentials, onBytesUploaded) {
			return new Promise((resolve) => {
				uploadItem.uploading = true

				let AWS = window.AWS
				AWS.config.update({
					region: uploadCredentials.region,
					useAccelerateEndpoint: uploadCredentials.useAccelerateEndpoint
				})

				// Initialize the Amazon Cognito credentials provider
				let identityConf = {
					IdentityPoolId: uploadCredentials.poolId,
					LoginId: UserService.getUser(this.activeTeam).email
				}

				AWS.config.credentials = new AWS.CognitoIdentityCredentials(identityConf)

				// Upload the File
				const uploadBucket = new AWS.S3({ params: { Bucket: uploadCredentials.bucketName } })
				const uploadOpts = {
					Key: uploadCredentials.keyPrefix + uploadId,
					ContentType: uploadItem.file.type,
					Body: uploadItem.file,
					queueSize: uploadCredentials.concurrency || 2, // default 2 parts in parallel
					partSize: uploadCredentials.chunkSize || (2 * 1000 * 1000), // default 2MB
					UseAccelerateEndpoint: true
				}

				const onBytes = (ev) => {
					let percent = (ev.loaded * 100) / ev.total
					onBytesUploaded(percent)
				}

				let multipartUploadHandle = uploadBucket.upload(uploadOpts)
				multipartUploadHandle.on('httpUploadProgress', onBytes)

				uploadItem.cancelSource = {
					cancel: () => void multipartUploadHandle.abort()
				}

				multipartUploadHandle.send((err, data) => {
					if (err) {
						uploadItem.uploadProgress = 0
						uploadItem.uploadError = true
						uploadItem.cancelSource = null
						uploadItem.uploading = false
					}

					resolve(!err)
				})
			})
		},
		async onVideoUploaded(uploadItem, uploadResult) {
			if (!uploadResult || !uploadResult.fileId) return;

			const oldId = uploadItem.id;

			uploadItem.id = uploadResult.fileId;
			uploadItem.mediaInfo = uploadResult.mediaInfo;
			uploadItem.creationTime = new Date();

			if (uploadResult.encodingRequired) {
				uploadItem.encodingRequired = true;
				uploadItem.encodingStatus = 'processing';
			}

			uploadItem.uploadable = false;
			uploadItem.file = null;

			const uindex = this.uploadList.indexOf(uploadItem);
			const newVideoArray = utils.removeArrayItem(this.uploadList, uindex);
			this.uploadList = newVideoArray;

			this.$emit('new-video', uploadItem);
			this.$emit('upload-dequeue', { upload: oldId, handler: 'raw' });
			if (!this.uploadList.length) {
				this.$emit('video-queue-emptied');
			}
			await this.$store.dispatch('Streams/getStreams');
			await this.$store.dispatch('Streams/getFilesInVODFolder')
			this.$notify({
				group: 'success',
				text: 'media uploaded successfully'
			});
			this.$emit('video-added', uploadItem);
			setTimeout(() => {
				this.$notify({ group: 'info', text: 'Video added to media list' });
			}, 2000);
			this.showOptions = false;
		},
		cancelVideoUpload(media) {
			if (!media) return;

			if (media.cancelSource) {
				media.cancelSource.cancel();
			}

			media.uploading = false;
		},
		async removeVideoFile(media) {
			media.removing = true;

			this.$emit('upload-dequeue', { upload: media, handler: 'raw' });

			this.cancelVideoUpload(media);

			const index = this.uploadList.indexOf(media);
			this.uploadList.splice(index, 1);

			if (!this.uploadList.length) {
				this.$emit('video-queue-emptied');
				this.showUploadButton = true;
			}
		},
		async clearUploadList() {
			const uploadList = this.uploadList.slice();
			uploadList.forEach(vid => void this.removeVideoFile(vid));
		},
		async selectLiveToVod() {
			setTimeout(() => {
				this.$root.$emit('bv::show::modal', 'modal-select-live-to-vod');
			}, 100);
		},
		onChange() {
			// this.uploadList = [...this.$refs.uploadInput.files];
			this.initVideoUpload()
		},
		remove(i) {
			this.uploadList.splice(i, 1);
		},
		dragover(event) {
			event.preventDefault();
			// console.log(event.currentTarget);
			// Add some visual fluff to show the user can drop its files
			if (!event.currentTarget.classList.contains('drop-zone-hidden_dragover')) {
				event.currentTarget.classList.remove('drop-zone-hidden_dragleave');
				event.currentTarget.classList.add('drop-zone-hidden_dragover');
			}
			if (event.currentTarget.classList.contains('w-100')) {
				event.currentTarget.classList.add('z-10');
			}
		},
		dragleave(event) {
			// Clean up
			event.currentTarget.classList.remove('drop-zone-hidden_dragover');
			if (event.currentTarget.classList.contains('w-100')) {
				event.currentTarget.classList.remove('z-10');
			}
		},
		drop(event) {
			event.preventDefault();
			this.$refs.uploadInput.files = event.dataTransfer.files;
			this.onChange(); // Trigger the onChange event manually
			// Clean up
			event.currentTarget.classList.add('drop-zone-hidden_dragleave');
			event.currentTarget.classList.remove('drop-zone-hidden_dragover');
		},
		onUpdate() {
			this.$emit('updated')
		}
	}
};

function computeEncodingUnits(media) {
	if (!media.mediaInfo) return;
	const encUnits = media.mediaInfo.durationMins * media.encodingPresets.length;
	return encUnits > 0 ? Math.ceil(encUnits) : encUnits;
}
</script>

<style scoped>
.vod_uploadbox {
	width: 100%;
}

.vod_uploadbox .video-item {
	background: var(--c-dark-2);
	border-radius: 6px;
	padding: 7px;
}

.font-weight-500 {
	font-weight: 500;
}
</style>
