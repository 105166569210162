<template>
	<div class="">
		<b-modal
			id="share-playlist"
			ref="share-playlist"
			modal-class="modal-platform"
			size="md"
			centered
			hide-header
			hide-footer
		>
			<b-row>
				<b-col><h3 class="text-s-xl">Share Folder Playlist</h3></b-col>
			</b-row>
			<b-row>
				<b-col class="text-s-l text-dark-8 mb-4">Everyone who has the link will be able to watch all the videos this folder contains.</b-col>
			</b-row>
			<b-row>
				<b-col>
					<label class="text-s-s text-dark-8" for="">iFrame Widget</label>
					<b-form-radio-group v-model="selectedWidget" class="mb-2">
						<b-form-radio value="responsive">Responsive</b-form-radio>
						<b-form-radio value="fixed">Fixed Size</b-form-radio>
					</b-form-radio-group>
				</b-col>
			</b-row>
			<b-row class="mb-3">
				<b-col class="">
					<b-input-group size="md">
            <b-form-input autocomplete="do-not-autofill" type="text" :value="iframe" @click="clipboardCopy(iframe)" />
            <b-input-group-append>
              <b-button class="py-1 px-2 d-inline-flex align-items-center justify-content-center position-relative" variant="icon" @click="clipboardCopy(iframe)">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
              </b-button>
            </b-input-group-append>
          </b-input-group>
				</b-col>
			</b-row>
			<b-row class="mb-4">
				<b-col>
					<label class="text-s-s text-dark-8" for="">Embed Url</label>
					<b-input-group size="md">
            <b-form-input autocomplete="do-not-autofill" type="text" :value="embedUrl" @click="clipboardCopy(embedUrl)"/>
            <b-input-group-append>
              <b-button class="py-1 px-2 d-inline-flex align-items-center justify-content-center position-relative" variant="icon" @click="clipboardCopy(embedUrl)">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
              </b-button>
							<b-button :href="embedUrl" target="_blank" class="py-1 px-2 d-inline-flex align-items-center justify-content-center position-relative" variant="icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M6 4V4C4.59987 4 3.8998 4 3.36502 4.27248C2.89462 4.51217 2.51217 4.89462 2.27248 5.36502C2 5.8998 2 6.59987 2 8V10C2 11.4001 2 12.1002 2.27248 12.635C2.51217 13.1054 2.89462 13.4878 3.36502 13.7275C3.8998 14 4.59987 14 6 14H8C9.40013 14 10.1002 14 10.635 13.7275C11.1054 13.4878 11.4878 13.1054 11.7275 12.635C12 12.1002 12 11.4001 12 10V10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M7.5 8.5L14 2M14 2H9.5M14 2V6.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
              </b-button>
            </b-input-group-append>
          </b-input-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col :class="buttonAlignment">
					<b-button
						variant="outline-secondary"
						size="md"
						class="ml-auto"
						@click="$bvModal.hide('share-playlist')"
					>
						Close
					</b-button>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'SharePlayListModal',
	components: {},
	props: {
		modalId: {
			type: String,
			default: 'modal-alert'
		},
		okText: {
			type: String,
			default: 'Yes'
		},
		buttonAlignment: {
			type: String,
			default: ''
		},
		stream: {
			type: Object,
		},
		streamKey: {
			type: String || undefined
		}
	},
	data() {
		return {
			processing: false,
			selectedWidget: 'responsive'
		};
	},
	computed: {
		iframe() {
			let code = ''
			if (this.stream?.liveParent && this.selectedWidget === 'responsive') {
				code = '<iframe src="https://player.castr.com/' + this.streamKey + '?playlist=true" width="100%" style="aspect-ratio: 16/9; min-height: 340px;" frameborder="0" scrolling="yes" allow="autoplay" allowfullscreen  webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'
			} else if (this.stream?.liveParent && this.selectedWidget === 'fixed') {
				code = '<iframe src="https://player.castr.com/' + this.streamKey + '?playlist=true" width="590" height="620" frameborder="0" scrolling="yes" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'
			} else if (!this.stream?.liveParent && this.selectedWidget === 'responsive') {
				code = '<iframe src="https://player.castr.com/vod/' + this.stream?._id + '?playlist=true" width="100%" style="aspect-ratio: 16/9; min-height: 340px;" frameborder="0" scrolling="yes" allow="autoplay" allowfullscreen  webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'
			} else if (!this.stream?.liveParent && this.selectedWidget === 'fixed') {
				code = '<iframe src="https://player.castr.com/vod/' + this.stream?._id + '?playlist=true" width="590" height="620" frameborder="0" scrolling="yes" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'
			}
			return code
		},
		embedUrl() {
			let url = ''
			if (this.stream?.liveParent) {
				url = 'https://player.castr.com/' + this.streamKey + '?playlist=true'
			} else url = 'https://player.castr.com/vod/' + this.stream?._id + '?playlist=true'
			return url
		}
	},
	methods: {
		onConfirm() {
			this.$emit('modal-confirm');
			this.dismiss();
		},
		dismiss() {
			this.$refs.modalAlert.hide();
			// lazy clear
			setTimeout(() => { this.processing = false; }, 1000);
		},
		clipboardCopy(text) {
			navigator.clipboard.writeText(text)
			this.$notify({ group: 'info', text: 'Copied to clipboard' });
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
