import _ from "lodash";
import RequestError from "./RequestError";
import amProxyUrl from "./AmProxyUrl";
import api from "@/api";

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

export default {
    createTranscoder,
    getTranscoders,
	getTranscoder,
	renameTranscoder,
    deleteTranscoder,
	toggleTranscoder,
    uploadTranscoderPoster,
    saveTranscoderMetadata,
    clearTranscoderMetadataCache,
	updateTranscoder,
	setTranscoderSettings
};

function setTranscoderSettings(transcoder, payload) {
	return makeRequest({
		path: `/transcoders/${transcoder}/settings`,
		method: 'put',
		data: payload
	});
}

function updateTranscoder(transcoder, payload) {
	return makeRequest({
		path: `/transcoders/${transcoder}`,
		method: 'put',
		data: payload
	});
}

/**
 * @param {string} name
 */
function createTranscoder(name, region) {
	return makeRequest({
		path: '/transcoders',
		method: 'post',
		data: {
			name,
			region
		}
	});
}

function getTranscoders() {
	return makeRequest({
		path: '/transcoders',
		method: 'get',
	});
}

/**
 * @param {string} playerId
 */
function getTranscoder(playerId) {
  return makeRequest({
		path: `/transcoders/${playerId}`,
		method: 'get',
	});
}

/**
 * @param {string} transcoderId
 */
function deleteTranscoder(transcoderId) {
  return makeRequest({
		path: `/transcoders/${transcoderId}`,
		method: 'delete',
	});
}

/**
 * @param {string} playerId
 * @param {FormData} fdata
 */
function renameTranscoder(playerId, newName) {
	return makeRequest({
		path: `/transcoders/${playerId}`,
		method: 'put',
		data: {
			name: newName
		},
	});
}

/**
 * @param {string} transcoder
 */
function toggleTranscoder(transcoder) {
  return makeRequest({
		path: `/transcoders/${transcoder}/toggle`,
		method: 'post',
	});
}

/**
 * @param {string} playerId
 * @param {FormData} fdata
 */
function uploadTranscoderPoster(playerId, fdata, isWatermark) {
	return makeRequest({
		path: `/streams/${playerId}/metadata/poster?${isWatermark ? 'type=watermark' : ''}&transcoder=true`,
		method: 'post',
		data: fdata,
		headers: {
			'content-type': 'multipart/form-data'
		}
	});
}

/**
 * @param {string} playerId
 * @param {string} key
 * @param {string} value
 */
function saveTranscoderMetadata(playerId, key, value) {
	return makeRequest({
		path: `/streams/${playerId}/metadata/save?transcoder=true`,
		method: 'put',
		data: {
			updates: {
				key,
				value
			}
		}
	});
}

/**
 * @param {string} playerId
 */
function clearTranscoderMetadataCache(playerId) {
	return makeRequest({
		path: `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/cache/clear?stream=${playerId}`,
		method: 'get'
	});
}

/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeRequest(reqConfig, preventProxy, throwReal) {
  if (typeof reqConfig === "string") {
    reqConfig = {
      path: reqConfig,
    };
  }

  if (preventProxy !== true) {
    reqConfig = amProxyUrl(reqConfig, process.env.VUE_APP_VOD_API_BASE_URL);
  }

  reqConfig.url = reqConfig.path;

  let res;
  try {
    res = await api.request(reqConfig);
  } catch (err) {
    if (throwReal) {
      throw err;
    }
    const edata = _.get(err, "response.data");
    throw new RequestError(edata);
  }

  return res && res.data;
}
