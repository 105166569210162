<template>
  <div class="w-100" ref="dropdownContainer">
    <b-button variant="dark-3" class="justify-content-start text-left w-100" @click="toggleVisible" size="md">
      <div v-if="!source" class="flex justify-content-start text-left w-100">
        Select an option
        <svg class="ml-auto mr-0" width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.60944 0.544448C4.83413 0.351853 5.16569 0.351853 5.39039 0.544448L8.89039 3.54445C9.14198 3.7601 9.17112 4.13888 8.95547 4.39048C8.73981 4.64207 8.36103 4.67121 8.10944 4.45556L4.99991 1.79025L1.89039 4.45556C1.63879 4.67121 1.26001 4.64207 1.04436 4.39048C0.828706 4.13888 0.857843 3.7601 1.10944 3.54445L4.60944 0.544448ZM1.04436 7.60953C1.26001 7.35793 1.63879 7.32879 1.89039 7.54445L4.99991 10.2098L8.10944 7.54445C8.36103 7.32879 8.73981 7.35793 8.95547 7.60953C9.17112 7.86112 9.14198 8.2399 8.89039 8.45556L5.39039 11.4556C5.16569 11.6481 4.83413 11.6481 4.60944 11.4556L1.10944 8.45556C0.857843 8.2399 0.828706 7.86112 1.04436 7.60953Z"
            fill="currentColor" />
        </svg>
      </div>
      <div v-else class="justify-content-start text-left w-100">
        <div class="flex">
          {{ source.name }}
          <svg class="ml-auto" width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.60944 0.544448C4.83413 0.351853 5.16569 0.351853 5.39039 0.544448L8.89039 3.54445C9.14198 3.7601 9.17112 4.13888 8.95547 4.39048C8.73981 4.64207 8.36103 4.67121 8.10944 4.45556L4.99991 1.79025L1.89039 4.45556C1.63879 4.67121 1.26001 4.64207 1.04436 4.39048C0.828706 4.13888 0.857843 3.7601 1.10944 3.54445L4.60944 0.544448ZM1.04436 7.60953C1.26001 7.35793 1.63879 7.32879 1.89039 7.54445L4.99991 10.2098L8.10944 7.54445C8.36103 7.32879 8.73981 7.35793 8.95547 7.60953C9.17112 7.86112 9.14198 8.2399 8.89039 8.45556L5.39039 11.4556C5.16569 11.6481 4.83413 11.6481 4.60944 11.4556L1.10944 8.45556C0.857843 8.2399 0.828706 7.86112 1.04436 7.60953Z"
              fill="currentColor" />
          </svg>
        </div>
      </div>
    </b-button>
    <div v-if="visible" class="select-source-wrapper mt-2">
      <b-tabs class="tabs-light ml-3 mr-3 mb-2">
        <b-tab title="TV Playout">
          <div v-if="playoutList && playoutList.length > 0" class="select-source-list-container">
            <ul class="list-unstyled text-500 select-source-list mt-1">
              <li v-for="(stream, index) in playoutList" :key="index" class="">
                <span v-b-tooltip.hover.lefttop :title="stream.destination ? 'Destination is already set for this.' : ''">
                  <b-button :disabled="stream.destination" @click="setSource(stream)"
                    variant="none" 
                    class="w-100 text-left justify-content-start text-s-s text-dark-9 list-item">
                    {{ stream.name }}
                    <span v-if="stream.destination" class="ml-auto pl-3">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.4001 5.59998V4.79997C14.4001 4.61427 14.4001 4.52142 14.396 4.44297C14.3156 2.90962 13.0905 1.68445 11.5571 1.60409C11.4787 1.59998 11.3858 1.59998 11.2001 1.59998V1.59998H10.4001M14.4001 10.4V10.4C14.4001 11.3299 14.4001 11.7949 14.2979 12.1764C14.0205 13.2117 13.2118 14.0204 12.1766 14.2978C11.7951 14.4 11.3301 14.4 10.4001 14.4V14.4M1.6001 10.4V11.2V11.2C1.6001 11.3857 1.6001 11.4785 1.60421 11.557C1.68457 13.0903 2.90974 14.3155 4.44309 14.3959C4.52154 14.4 4.61439 14.4 4.8001 14.4H5.6001M1.6001 5.59998V4.79998V4.79998C1.6001 4.61427 1.6001 4.52142 1.60421 4.44297C1.68457 2.90962 2.90974 1.68445 4.44309 1.60409C4.52154 1.59998 4.61439 1.59998 4.8001 1.59998V1.59998H5.6001" stroke="#8DA3B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.6742 5.05001C12.3997 4.91783 12.0806 5.05709 11.9613 5.36092L10.5548 8.94277L9.03236 5.3455C8.90538 5.04551 8.58289 4.91615 8.31191 5.05691C8.04105 5.19755 7.92436 5.55478 8.05134 5.85483L10.0828 10.6547C10.1721 10.8657 10.3634 11 10.5733 11C10.5759 11 10.5785 11 10.581 10.9999C10.7939 10.9966 10.9852 10.8555 11.0702 10.6393L12.9549 5.83941C13.0742 5.53558 12.9485 5.18213 12.6742 5.05001Z" fill="#8DA3B7"/>
                        <path d="M6.41936 5H3.58064C3.25995 5 3 5.26862 3 5.6C3 5.93138 3.25995 6.2 3.58064 6.2H4.41933V10.4C4.41933 10.7314 4.67928 11 4.99997 11C5.32066 11 5.58062 10.7314 5.58062 10.4V6.2H6.41936C6.74005 6.2 7 5.93138 7 5.6C7 5.26862 6.74005 5 6.41936 5Z" fill="#8DA3B7"/>
                      </svg>
                    </span>
                  </b-button>
                </span>
              </li>
            </ul>
          </div>
          <div v-else class="text-center">
            <div class="d-flex justify-content-center mb-3 mt-3">
              <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.6579 64C49.1416 64 63.316 49.7008 63.316 32C63.316 14.2992 49.1416 0 31.6579 0C14.1741 0 -0.000244141 14.2992 -0.000244141 32C-0.000244141 49.7008 14.1741 64 31.6579 64Z" fill="#343E61"/>
                <path d="M8.36692 11.2756H53.3876C55.1984 11.2756 56.5721 12.6614 56.5721 14.4882V43.2756C56.5721 45.1024 55.1984 46.4882 53.3876 46.4882H8.36692C6.5561 46.4882 5.18237 45.1024 5.18237 43.2756V14.4882C5.18237 12.6614 6.68098 11.2756 8.36692 11.2756Z" fill="#4F5C87"/>
                <path d="M57.4467 29.7322V43.9055C57.4467 45.7322 56.0106 47.244 54.1373 47.244H7.86778C6.05696 47.244 4.55835 45.7322 4.55835 43.9055V28.5354" stroke="#1676EE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M53.513 21.7952H38.9015C37.4654 21.7952 36.0917 22.2992 35.0301 23.181L31.2212 26.3307C30.1597 27.2125 28.7859 27.7165 27.3498 27.7165H10.8651C9.17914 27.7165 7.80542 29.1023 7.80542 30.8031C7.80542 30.9291 7.80542 31.1181 7.86786 31.244L10.9275 47.496C11.1773 49.0078 12.4261 50.1417 13.9872 50.1417H49.2669C50.7655 50.1417 52.0768 49.0708 52.3266 47.559L56.5726 25.3228C56.8848 23.622 55.7609 22.1102 54.075 21.7952C53.8252 21.7952 53.6379 21.7952 53.513 21.7952Z" fill="#9FA4A8"/>
                <path d="M60.0695 17.4488H41.0871C39.2139 17.4488 37.4655 18.0787 36.0293 19.2755L31.034 23.37C29.6602 24.5039 27.8494 25.1968 25.9762 25.1968H4.4961C2.31063 25.1968 0.562256 27.0236 0.562256 29.1653C0.562256 29.3543 0.562256 29.5433 0.624698 29.7322L4.55854 50.9606C4.87076 52.9763 6.55669 54.4252 8.49239 54.4252H54.4497C56.4479 54.4252 58.0714 53.0393 58.3836 51.0236L63.9409 21.9842C64.3156 19.7795 62.8794 17.8267 60.6939 17.3858C60.5066 17.4488 60.2568 17.4488 60.0695 17.4488Z" fill="#68759C"/>
                <path d="M23.3533 39.4333C24.4773 39.4333 25.4139 38.4884 25.4139 37.3545C25.4139 36.2207 24.4773 35.2758 23.3533 35.2758C22.2294 35.2758 21.2927 36.2207 21.2927 37.3545C21.2927 38.4884 22.2294 39.4333 23.3533 39.4333Z" fill="#FAFAFB"/>
                <path d="M39.4012 39.3701C40.5251 39.3701 41.4618 38.4252 41.4618 37.2914C41.4618 36.1575 40.5251 35.2126 39.4012 35.2126C38.2772 35.2126 37.3406 36.1575 37.3406 37.2914C37.3406 38.4882 38.2772 39.3701 39.4012 39.3701Z" fill="#FAFAFB"/>
                <path d="M33.3438 41.6377H29.4724V42.5826H33.3438V41.6377Z" fill="#FAFAFB"/>
              </svg>
            </div>
            <p class="mb-2 text-dark-9 text-s-s">Do not have any TV Playout.</p>
            <b-button variant="primary" size="sm" @click="createNewPlayout" class="mb-1">Create new playout</b-button>
          </div>
        </b-tab>
        <b-tab title="Transcoder">
          <div v-if="transcoderList && transcoderList.length > 0" class="select-source-list-container">
            <ul class="list-unstyled text-500 select-source-list mt-1">
              <li v-for="(transcoder, index) in transcoderList" :key="index" class="">
                <span v-b-tooltip.hover.lefttop :title="transcoder.destination ? 'Destination is already set for this.' : ''">
                  <b-button :disabled="transcoder.destination" @click="setSource(transcoder)"
                    variant="none" 
                    class="w-100 text-left justify-content-start text-s-s text-dark-9 list-item">
                    {{ transcoder.name }}
                    <span v-if="transcoder.destination" class="ml-auto">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_11366_3586)">
                          <path d="M5.08043 3.06119L5.96021 1C3.37669 1.71869 1.53661 3.79572 0.997424 6.24392" stroke="#8DA3B7" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10.8627 13.1437L9.96338 15.1964C12.5536 14.5024 14.4134 12.443 14.9759 9.99999" stroke="#8DA3B7" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                          <rect x="3" y="6" width="6" height="7" rx="2" stroke="#8DA3B7" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M9 10H11C12.1046 10 13 9.10457 13 8V5C13 3.89543 12.1046 3 11 3H9C7.89543 3 7 3.89543 7 5V6" stroke="#8DA3B7" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_11366_3586"><rect width="16" height="16" fill="white"/></clipPath>
                        </defs>
                      </svg>
                    </span>
                  </b-button>
                </span>
              </li>
            </ul>
          </div>
          <div v-else class="text-center">
            <div class="d-flex justify-content-center mb-3 mt-3">
              <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.6579 64C49.1416 64 63.316 49.7008 63.316 32C63.316 14.2992 49.1416 0 31.6579 0C14.1741 0 -0.000244141 14.2992 -0.000244141 32C-0.000244141 49.7008 14.1741 64 31.6579 64Z" fill="#343E61"/>
                <path d="M8.36692 11.2756H53.3876C55.1984 11.2756 56.5721 12.6614 56.5721 14.4882V43.2756C56.5721 45.1024 55.1984 46.4882 53.3876 46.4882H8.36692C6.5561 46.4882 5.18237 45.1024 5.18237 43.2756V14.4882C5.18237 12.6614 6.68098 11.2756 8.36692 11.2756Z" fill="#4F5C87"/>
                <path d="M57.4467 29.7322V43.9055C57.4467 45.7322 56.0106 47.244 54.1373 47.244H7.86778C6.05696 47.244 4.55835 45.7322 4.55835 43.9055V28.5354" stroke="#1676EE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M53.513 21.7952H38.9015C37.4654 21.7952 36.0917 22.2992 35.0301 23.181L31.2212 26.3307C30.1597 27.2125 28.7859 27.7165 27.3498 27.7165H10.8651C9.17914 27.7165 7.80542 29.1023 7.80542 30.8031C7.80542 30.9291 7.80542 31.1181 7.86786 31.244L10.9275 47.496C11.1773 49.0078 12.4261 50.1417 13.9872 50.1417H49.2669C50.7655 50.1417 52.0768 49.0708 52.3266 47.559L56.5726 25.3228C56.8848 23.622 55.7609 22.1102 54.075 21.7952C53.8252 21.7952 53.6379 21.7952 53.513 21.7952Z" fill="#9FA4A8"/>
                <path d="M60.0695 17.4488H41.0871C39.2139 17.4488 37.4655 18.0787 36.0293 19.2755L31.034 23.37C29.6602 24.5039 27.8494 25.1968 25.9762 25.1968H4.4961C2.31063 25.1968 0.562256 27.0236 0.562256 29.1653C0.562256 29.3543 0.562256 29.5433 0.624698 29.7322L4.55854 50.9606C4.87076 52.9763 6.55669 54.4252 8.49239 54.4252H54.4497C56.4479 54.4252 58.0714 53.0393 58.3836 51.0236L63.9409 21.9842C64.3156 19.7795 62.8794 17.8267 60.6939 17.3858C60.5066 17.4488 60.2568 17.4488 60.0695 17.4488Z" fill="#68759C"/>
                <path d="M23.3533 39.4333C24.4773 39.4333 25.4139 38.4884 25.4139 37.3545C25.4139 36.2207 24.4773 35.2758 23.3533 35.2758C22.2294 35.2758 21.2927 36.2207 21.2927 37.3545C21.2927 38.4884 22.2294 39.4333 23.3533 39.4333Z" fill="#FAFAFB"/>
                <path d="M39.4012 39.3701C40.5251 39.3701 41.4618 38.4252 41.4618 37.2914C41.4618 36.1575 40.5251 35.2126 39.4012 35.2126C38.2772 35.2126 37.3406 36.1575 37.3406 37.2914C37.3406 38.4882 38.2772 39.3701 39.4012 39.3701Z" fill="#FAFAFB"/>
                <path d="M33.3438 41.6377H29.4724V42.5826H33.3438V41.6377Z" fill="#FAFAFB"/>
              </svg>
            </div>
            <p class="mb-2 text-dark-9 text-s-s">Do not have any Transcoder.</p>
            <b-button variant="primary" size="sm" @click="createNewTranscoder" class="mb-1">Create new transcoder</b-button>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'SelectLinkedSource',

  props: {
    stream: {
      type: Object,
      required: true
    }
  },
  data() {
		return {
			visible: false,
      source: null,
		};
	},

  async mounted() {
    await this.$store.dispatch('Transcoders/getTranscoders');
    document.addEventListener('click', this.handleClickOutside);
    
    _.forEach(this.playoutList, (item) => {
      if (item.destination === this.stream.name) {
        this.source = item
        this.$emit('init-linked-source', this.source)
      }
    })
    if (!this.source) {
      _.forEach(this.transcoderList, (item) => {
      if (item.destination && item.destination.name === this.stream.name) {
        this.source = item
        this.$emit('init-linked-source', this.source)
      }
    })
    }
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  computed: {
    ...mapGetters({
			streams: 'Streams/streams',
      transcoderList: 'Transcoders/transcoders'
		}),
    playoutList() {
      return _.filter(this.streams, (item) => item.type === 'tvplayout')
    },
  },

  watch: {
    source() {
      this.$emit('selected-source', this.source)
    },
    stream() {
      if (this.stream.removedSource) this.source = null
    }
  },

  methods: {
    toggleVisible() {
			this.visible = !this.visible
		},
    setSource(source) {
      this.source = source
      this.toggleVisible();
    },
    createNewPlayout() {
      window.location.href = '/app/tv-playout';
    },
    createNewTranscoder() {
      window.location.href = '/app/transcoder';
    },
    handleClickOutside(event) {
      const dropdownContainer = this.$refs.dropdownContainer;
      if (dropdownContainer && !dropdownContainer.contains(event.target)) {
        this.visible = false;
      }
    },
  }
};
</script>

<style scoped>
.select-source-wrapper {
	position: absolute;
	top: 50%;
  left: 3%;
	z-index: 1050;
	background-color: var(--c-dark-3);
	border-radius: .5rem;
	border: 1px solid var(--c-neutral-n3);
	min-width: 94%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.select-source-list-container {
  max-height: 200px;
  overflow-y: auto;
}

:deep(.nav-pills .select-source-tab-link),
:deep(.nav-pills .show>.select-source-tab-link) {
	font-size: var(--font-s-xs);
	font-weight: 500;
	color: var(--c-dark-9);
	padding: .5rem;
	margin-left: 1rem;
	border-radius: .5rem 0 0 .5rem;
}

:deep(.nav-pills .select-source-tab-link.active),
:deep(.nav-pills .show>.select-source-tab-link) {
	color: #fff;
	background-color: var(--c-dark-5);
}

:deep(.rounded-r) {
	border-radius: 0 .5rem .5rem 0;
}
@media (max-width: 767px) {
	.select-source-list {
		max-height: 210px;
		overflow-y: auto;	
	}
}
.list-item {
  display: flex;
  padding: 5px;
}

.list-item:hover {
    color: #fff;
    background-color: var(--c-dark-4);
    border-color: var(--c-dark-4);
}
</style>
