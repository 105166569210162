<script setup>
import { computed, onMounted, ref } from 'vue'

const props = defineProps({
	zIndex: {
		type: String,
		default: 'z-50',
	}
})

const emit = defineEmits({
	'overlay-click': (event) => { return event},
})

const overlayClick = (event) => {
  emit('overlay-click', event)
}

// Take the focus on displaying this modal.  Partial implementation of
// https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

const rootElement = ref(null)

onMounted(() => {
  if (rootElement.value) {
    const firstElement = rootElement.value.querySelectorAll(focusableElements)[0]
    firstElement?.focus()
  }
})
</script>

<template>
  <div
    class="flex items-center flex-col justify-center overflow-hidden fixed inset-0"
		:class="props.zIndex"
    ref="rootElement"
  >
    <transition
      enter-active-class="transition duration-150 ease-in"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        class="absolute inset-0 backdrop-blur-sm bg-black/30 bg-modal-overlay"
        @click.stop.prevent="overlayClick"
      />
    </transition>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="animate-fadeOut"
    >
      <slot />
    </transition>
  </div>
</template>
