<script setup>
import { computed, ref } from 'vue'
import IconBase from '../icon/IconBase.vue';
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"


const props = defineProps({
  content: {
    type: String,
  },
  wrapperClass: {
    type: String,
    default: 'inline'
  },
})

</script>
<template>
  <div :class="wrapperClass">
    <ejs-tooltip v-if="props.content" :content="props.content">
      <slot />
    </ejs-tooltip>
    <slot v-else />
  </div>
</template>

<style scoped>
</style>