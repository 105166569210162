import _ from "lodash";
import RequestError from "./RequestError";
import amProxyUrl from "./AmProxyUrl";
import api from "@/api";

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

export default {
  getDestinations,
  createDestination,
  deleteDestination,
  updateDestination,
  addDestinationToStream,
  removeDestinationFromStream
};

function getDestinations() {
	return makeRequest({
		path: '/destinations',
		method: 'get',
	});
}

/**
 * @param {object} destination
 */
async function createDestination(destination) {
	return makeRequest({
		path: '/destinations',
		method: 'post',
    data: destination
	});
}

/**
 * @param {string} id
 * @param {string} name
 * @param {string} server
 * @param {number} key
 * @param {object} port
 * @param {string} latency
 * @param {string} passphrase
 */
async function updateDestination(id, name, server, key, port, latency, passphrase) {
	return makeRequest({
		path: `/destinations/${id}`,
		method: 'patch',
    data: {
      name,
      server,
      key,
      port,
      latency,
      passphrase
    }
	});
}

/**
 * @param {string} destinationId
 * @param {string} streamId
 */
async function addDestinationToStream(destinationId, streamId) {
	return makeRequest({
		path: `/destinations/${destinationId}/streams/${streamId}`,
		method: 'post',
	});
}

/**
 * @param {string} destinationId
 * @param {string} streamId
 */
async function removeDestinationFromStream(destinationId, streamId) {
	return makeRequest({
		path: `/destinations/${destinationId}/streams/${streamId}`,
		method: 'delete',
	});
}


/**
 * @param {string} id
 */
async function deleteDestination(id) {
	return makeRequest({
		path: `/destinations/${id}`,
		method: 'delete',
	});
}


/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeRequest(reqConfig, preventProxy, throwReal) {
  if (typeof reqConfig === "string") {
    reqConfig = {
      path: reqConfig,
    };
  }

  if (preventProxy !== true) {
    reqConfig = amProxyUrl(reqConfig, process.env.VUE_APP_VOD_API_BASE_URL);
  }

  reqConfig.url = reqConfig.path;

  let res;
  try {
    res = await api.request(reqConfig);
  } catch (err) {
    if (throwReal) {
      throw err;
    }
    const edata = _.get(err, "response.data");
    throw new RequestError(edata);
  }

  return res && res.data;
}
