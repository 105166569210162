<script setup>
import { computed, onMounted, ref, getCurrentInstance } from 'vue'
import _ from 'lodash';
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"
import StreamService from '@/services/StreamService';
import AddPhoneNumberModal from '@/components/modals/AddPhoneNumberModal.vue';
import UserService from '@/services/UserService';
import { useStore } from 'vuex'
import IconBase from '@/components/icon/IconBase.vue';
import IconHelp from '@/components/icon/IconHelp.vue';
import FormButton from '../../components/Atoms/FormButton.vue';
import FormSwitch from '../../components/Atoms/FormSwitch.vue';
import { useNotification } from "@kyvg/vue3-notification"

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const addPhoneNumber = ref(null)

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	}
})
const processing = ref(false)
const streamId = ref(null)
const features = ref({
	phoneAlert: {
		enabled: false,
		value: null,
		contactId: null,
		valueType: 'string'
	}
})

const userContacts = computed(() => store.getters['User/userContacts'])
const isOwner = computed(() => store.getters['User/isOwner'])
const teamOwner = computed(() => store.getters['User/teamOwner'])

const tooltipMessage = 'Send message to your phone when stream is down. <a target="_blank" class="text-reset text-underline" href="http://docs.castr.com/en/articles/6816008-setting-up-sms-alerts-for-when-stream-is-down">Learn more</a>.'
const tooltipMessageDisabled = `Please contact owner (<a class="text-reset underline" href="mailto:${teamOwner.value?.email}">${teamOwner.value?.email}</a>) to add a phone number for stream alerts. Once the phone number is added, you can toggle on the alerts.`

const phoneAlertsSub = computed(() => {
	const userSub = store.state.User.subscriptions.find(sub => ((sub.category && sub.category.includes(props.stream.type)) || (_.get(sub, ['package', 'category'], '').includes(props.stream.type))));
	return _.get(userSub, ['package', 'definition', 'phoneAlerts'], false)
})
onMounted(async () => {
	await store.dispatch('User/setupUserContacts');
	streamId.value = props.stream._id;
	const notifications = await StreamService.getStreamNotifications(streamId.value);
	if (notifications > 1) {
		notify({ group: 'error', text: 'multiple notifications for stream not supported so far' })
		return
	}
	features.value.phoneAlert.enabled = notifications.length === 0 ? false : true;
	if (notifications.length === 1) {
		features.value.phoneAlert.value = notifications[0].phone
		features.value.phoneAlert.contactId = notifications[0].contact
	}
})

const onPhoneNumberSaved = async (contact) => {
	try {
		await StreamService.setStreamNotification(streamId.value, contact.contact.phoneNumber)
		if (userContacts.value.length == 0 && isOwner.value) {
			notify({ group: 'success', text: 'You successfully added a phone number' });
		}
		addPhoneNumber.value.dismiss();

		features.value.phoneAlert.enabled = true
		features.value.phoneAlert.value = contact.contact.phoneNumber
		features.value.phoneAlert.contactId = contact.contact.id
		props.stream.notificationEnabled = true

		const userContact = userContacts.value[0]
		if (!userContact) {
			const updatedUser = _.assign({}, UserService.getUser(), { contact: { id: contact.contact.id, phoneNumber: contact.contact.phoneNumber } })
			localStorage.setItem('user', JSON.stringify(updatedUser))
		}
	} catch (e) {
		notify({ group: 'error', text: e ? e.message : 'Failed to save phone number' })
	}
}
const toggleFeature = async (featureName) => {
	const feature = features.value[featureName]
	if (!feature) return;
	processing.value = true
	// toggle ON
	if (featureName === 'phoneAlert' && features.value[featureName].enabled) {
		const userContact = userContacts.value[0]
		if (userContact) {
			// if user has already assign contact 
			const contact = {
				contact: { id: userContacts.value[0]._id, phoneNumber: userContacts.value[0].contact.phoneNumber }
			}
			await onPhoneNumberSaved(contact)
			processing.value = false
			return
		}
		if (userContacts.value.length == 0 && isOwner.value) {
			root.$emit('bv::show::modal', 'add-phone-number-modal');
		} else {
			onPhoneNumberSaved(userContacts.value[0])
		}
		setTimeout(() => {
			features.value[featureName].enabled = false
			features.value.phoneAlert.value = null
		}, 0)
		processing.value = false
		return
	}
	// toggle OFF
	if (featureName === 'phoneAlert' && !features.value[featureName].enabled) {
		try {
			await StreamService.removeStreamNotification(streamId.value, features.value.phoneAlert.contactId)

			features.value[featureName].enabled = false
			features.value.phoneAlert.value = null
			features.value.phoneAlert.contactId = null

			props.stream.notificationEnabled = false
		} catch (e) {
			notify({ group: 'error', text: e ? e.message : 'Failed to remove stream alert' })
		}
		processing.value = false
		return
	}
	processing.value = false
}
</script>
<template>
	<div>
		<section>
			<h2 class="text-xl !mb-4 flex items-center leading-none">
				Alerts when stream is down
				<ejs-tooltip :content="tooltipMessage">
					<icon-base class="ml-2">
						<icon-help />
					</icon-base>
				</ejs-tooltip>
			</h2>
			<div class="flex flex-col text-center" v-if="!phoneAlertsSub">
				<p class="text-lg !mt-6 !mb-2">You don’t have access to this feature</p>
				<p class="text-sm text-surface-8"><router-link to="/subscribe?category=live" class="hover:no-underline">Upgrade</router-link> or <FormButton
						type="link" customClasses="text-surface-8 underline" onclick="Intercom('show')">contact us</FormButton> to
					access this feature</p>
			</div>
			<div v-else class="flex items-start justify-between w-full">
				<div>
					<h4 class="text-base font-weight-normal mb-0">Phone alert</h4>
					<p v-if="userContacts.length > 0" class="text-sm text-surface-7">
						<router-link to="/manage/account" class="text-reset underline">
							Manage phone numbers
						</router-link>
					</p>
				</div>
				<div class="flex">
					<span v-if="features.phoneAlert.value" class="text-surface-7 mx-auto py-0 md:block !mr-2">{{
						features.phoneAlert.value }}</span>
					<FormSwitch v-if="isOwner || userContacts.length > 0" @change="toggleFeature('phoneAlert')"
						v-model="features.phoneAlert.enabled" :disabled="processing">
					</FormSwitch>
					<ejs-tooltip v-else-if="teamOwner && teamOwner.email" :content="tooltipMessageDisabled">
						<FormSwitch disabled />
					</ejs-tooltip>
					<FormSwitch v-else disabled />
				</div>
			</div>
		</section>
		<add-phone-number-modal ref="addPhoneNumber" modal-id="add-phone-number-modal" :stream="stream"
			@user-contact-created="onPhoneNumberSaved" />
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.modal-open .toggle-hide {
	display: none;
}
</style>
