<script>
export default {
	compatConfig: {
		MODE: 3,
		COMPONENT_V_MODEL: false,
	}
}
</script>
<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import _ from 'lodash';
import { useNotification } from "@kyvg/vue3-notification";
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ModalBox from './ModalBox.vue';
import IconBase from '@/components/icon/IconBase.vue';
import IconBin from '@/components/icon/IconBin.vue';
import IconSearch from '@/components/icon/IconSearch.vue';
import IconSorting from '@/components/icon/IconSorting.vue';
import IconCross from '@/components/icon/IconCross.vue';
import FormButton from '@/components/Atoms/FormButton.vue';
import FormInput from '@/components/Atoms/FormInput.vue';
import Title from '../ui/Title.vue';
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';

const store = useStore()
const { notify } = useNotification()
const router = useRouter()

const props = defineProps({
	tag: {
		type: Object,
		default: () => {}
	},
})

const isVisible = ref(false)


const toggleModal = () => {
	isVisible.value = !isVisible.value
}

const deleteGeneralTag = (tag, index) => {
	store.dispatch('Ui/deleteTag', tag)
	const messageSuccess = 'The <span class="text-capitalize">' + tag.title + '</span> was succesfully deleted';
	notify({ group: 'success', text: messageSuccess });
}

defineExpose({ toggleModal })

</script>

<template>
	<ModalBox v-model="isVisible" title="Manage Tags">
		<section class="!px-5">
			
		</section>
	</ModalBox>
</template>