<template>
  <section class="">
    <b-row>
      <b-col class="text-right">
        <b-tooltip
				:target="'member-disabled_'+this.stream._id"
				placement="top"
				v-if="memberPermission"
				>
				<span class="text-dark-8 text-small">
					You don’t have permission. Contact the team owner at <a  class="text-dark-8 text-small" :href="'mailto:'+userEmail">{{userEmail}}</a> to gain access.</span>
			</b-tooltip>
        <!-- <b-button variant="outline-danger" class="delete-folder" size="md" @click="vodDelete()"> Delete Folder </b-button> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" v-if="!stream.liveParent">

      </b-col>
      <b-col :lg="stream.liveParent ? 12 : 6" v-if="stream.liveParent">
        <div class="text-s-m live-to-vod-alert">
          <b-alert show variant="secondary" class="text-dark-7 text-s-s d-inline-flex p-2 mb-4 w-100"> 
            <svg width="14" height="14" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2 mt-1 flex-shrink-0"><path data-v-3503005c="" fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="currentColor"></path></svg>
            <p class="mb-0">
              This folder contains all your live-to-VOD records of <a class="text-reset" :href="`/app/livestreams/${stream.liveParent}/dashboard`">{{ stream.name }}</a>. You cannot manually upload videos in this folder.
            </p>
          </b-alert>
        </div>
      </b-col>
      <b-col lg="6" v-if="stream.liveParent && this.$route.name === 'VODBucketVideos'">
        <div v-if="videoFiles.length > 0">
          <b-button
            variant="outline-secondary"
            size="md"
            class="text-nowrap mb-4 mb-lg-0"
            v-b-modal.share-playlist
          >
            <svg class="mr-2 flex-shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.5 12.5V15.5C16.5 16.6046 15.6046 17.5 14.5 17.5H5.5C4.39543 17.5 3.5 16.6046 3.5 15.5V12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 2.5V12.5M10 2.5L6 6.5M10 2.5L14 6.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Share Playlist<b-badge  variant="dark-3" class="badge_beta ml-2">Beta</b-badge>
          </b-button>
        </div>
        <div v-else>
          <div class="d-inline-block" v-b-tooltip.hover title="Please upload a video to get the playlist">
            <b-button
              variant="outline-secondary"
              size="md"
              class="text-nowrap mb-4 mb-lg-0"
              disabled
            >
              <svg class="mr-2 flex-shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 12.5V15.5C16.5 16.6046 15.6046 17.5 14.5 17.5H5.5C4.39543 17.5 3.5 16.6046 3.5 15.5V12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 2.5V12.5M10 2.5L6 6.5M10 2.5L14 6.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Share Playlist<b-badge  variant="dark-3" class="badge_beta ml-2">Beta</b-badge>
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col lg="6">
        <div v-if="this.$route.name === 'VODBucketVideos'" class="d-flex mb-4 search-wrapper">
          <b-input-group size="md" class="search-form search-vod ml-auto mb-4 mb-md-0">
						<template #prepend>
							<b-input-group-text class="search-form__toggle text-dark-8" >
								<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M8.11096 15.2219C12.0382 15.2219 15.2219 12.0382 15.2219 8.11096C15.2219 4.18368 12.0382 1 8.11096 1C4.18368 1 1 4.18368 1 8.11096C1 12.0382 4.18368 15.2219 8.11096 15.2219Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M16.9994 16.9994L13.1328 13.1328" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
								</b-input-group-text>
						</template>
						<b-form-input
							ref="searchWidgetInputElement"
							placeholder="Search for files"
							class="search-form__field"
              @input="videoFileSearchTextChanged"
              :value="videoFileSearchText"
							></b-form-input>
            <b-button variant="icon" size="md" @click="videoFileSearchTextChanged('')" class="search-clear" :class="{ 'active': videoFileSearchText.length }">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 15.5C11.866 15.5 15 12.366 15 8.5C15 4.63401 11.866 1.5 8 1.5C4.13401 1.5 1 4.63401 1 8.5C1 12.366 4.13401 15.5 8 15.5ZM6.03033 5.46967C5.73744 5.17678 5.26256 5.17678 4.96967 5.46967C4.67678 5.76256 4.67678 6.23744 4.96967 6.53033L6.93934 8.5L4.96967 10.4697C4.67678 10.7626 4.67678 11.2374 4.96967 11.5303C5.26256 11.8232 5.73744 11.8232 6.03033 11.5303L8 9.56066L9.96967 11.5303C10.2626 11.8232 10.7374 11.8232 11.0303 11.5303C11.3232 11.2374 11.3232 10.7626 11.0303 10.4697L9.06066 8.5L11.0303 6.53033C11.3232 6.23744 11.3232 5.76256 11.0303 5.46967C10.7374 5.17678 10.2626 5.17678 9.96967 5.46967L8 7.43934L6.03033 5.46967Z"
                  fill="white"
                />
              </svg>
            </b-button>
					</b-input-group>
          <b-dropdown 
            ref="search-vod" 
            menu-class="dropdown-menu_md dropdown-menu_tag-list"
            toggle-class=""
            variant="dark-3"
            size="md"
            right
            no-caret
            class="ml-md-2 mb-4 mb-md-0 tag-filter">
            <template slot="button-content">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="text-dark-8 mr-2">Filter by tags</div>
                <div v-if="selectedTags.length > 0" class="w-100 d-flex">
                  <div class="" v-for="tag in theeSelectedTags" :key="tag.id || tag.title">
                    <b-badge pill class="badge_tag mr-2" :variant="tag.color">{{tag.title}} </b-badge>
                  </div>
                  <b-badge v-if="selectedTags.length > 3" pill class="badge_tag mr-2" variant="dark-4">+{{selectedTags.length - 3}}</b-badge>
                </div>
                <svg class="ml-2 dropdown-caret flex-shrink-0" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.26863 5.73137L7.13137 7.86863C6.73535 8.26465 6.53735 8.46265 6.30902 8.53684C6.10817 8.6021 5.89183 8.6021 5.69098 8.53684C5.46265 8.46265 5.26465 8.26465 4.86863 7.86863L2.73137 5.73137C1.87462 4.87462 1.44624 4.44624 1.4173 4.07846C1.39218 3.75934 1.52136 3.44749 1.76477 3.23959C2.04529 3 2.65111 3 3.86274 3L8.13726 3C9.34889 3 9.95471 3 10.2352 3.23959C10.4786 3.44749 10.6078 3.75934 10.5827 4.07846C10.5538 4.44624 10.1254 4.87462 9.26863 5.73137Z" fill="currentColor"/>
                </svg>
              </div>
            </template>
            <b-dropdown-item ref="tag-item" link-class="d-flex justify-content-between align-items-center tag-item" v-for="tag in generalTags" :key="tag.id || tag.title" @click="toggleTag(tag)">
              <b-badge pill class="badge_tag" :variant="tag.color">{{tag.title}} </b-badge>
              <svg class="tag-check flex-shrink-0" :class="tag.selected" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 21.5C16.799 21.5 21.5 16.799 21.5 11C21.5 5.20101 16.799 0.5 11 0.5C5.20101 0.5 0.5 5.20101 0.5 11C0.5 16.799 5.20101 21.5 11 21.5ZM17.101 8.60104C17.433 8.26909 17.433 7.73091 17.101 7.39896C16.7691 7.06701 16.2309 7.06701 15.899 7.39896L10 13.2979L7.10104 10.399C6.76909 10.067 6.23091 10.067 5.89896 10.399C5.56701 10.7309 5.56701 11.2691 5.89896 11.601L9.39896 15.101C9.7309 15.433 10.2691 15.433 10.601 15.101L17.101 8.60104Z" fill="currentColor"/>
              </svg>
            </b-dropdown-item>
            <b-dropdown-item link-class="d-flex justify-content-between align-items-center tag-item"  v-if="generalTags.length === 0">
              <span class="color-danger">No tags added yet</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <share-playlist-modal
      :stream="stream"
      :streamKey="streamKey"
			button-alignment="text-right"
		/>
  </section>
</template>

<script>
import Vue from 'vue';
import VODUploadWidget from "@/components/ui/VODUploadWidget.vue";
import UppyUploader from "@/components/UppyUploader.vue";
import ExpansionPanel from "@/components/ExpansionPanel.vue";
import SharePlaylistModal from '@/components/modals/SharePlaylistModal.vue';
import UserService from "@/services/UserService";

export default {
  name: "VODStreamPreviewPane",
  components: {
    VODUploadWidget,
    UppyUploader,
    ExpansionPanel,
    SharePlaylistModal
  },
  props: {
    stream: {
      type: Object,
      required: true,
      validator(v) {
        return Object.prototype.hasOwnProperty.call(v, "_id");
      },
    },
    videoFiles: {
      type: Array,
      default() {
        return [];
      },
    },
    isVodTrial: {
      type: Boolean,
      default() {
        return false;
      },
    },
    vodSubExpiredDays: {
      type: Number,
      default() {
        return 1;
      },
    },
    aioStorageLimit: {
			type: Object,
			default() { return {}; }
		},
  },
  data() {
    return {
      videoFileSearchText: '',
      streamId: null,
      activeTab: "queue",
      windowHeight: 0,
      availableEncodingPresets: [
        { id: "360p", text: "360p", minHeight: 300 },
        { id: "480p", text: "480p", minHeight: 440 },
        { id: "720p", text: "720p", minHeight: 700 },
        { id: "1080p", text: "1080p", minHeight: 1020 },
      ],
      processing: true,
      processingMessage: null,
      statusProcessing: false,
      streamId: null,
      showUploadWidget: true,
      // generalTags: [],
      selectedTags: [],
    };
  },
  computed: {
    streamKey() {
      if (this.stream.liveParent) {
        const key = this.videoFiles[0] && this.videoFiles[0].embedUrl.substring(25, this.videoFiles[0].embedUrl.lastIndexOf("?"))
        return key
      } else return null
    },
    theeSelectedTags() {
      return this.selectedTags.slice(0,3);
    },
    videoFilesCount() {
      return this.videoFiles.length;
    },
    hasEncodingEnabled() {
      return (
        this.$store.state.User.aggregatedDefinition &&
        this.$store.state.User.aggregatedDefinition.vod &&
        this.$store.state.User.aggregatedDefinition.vod.transcoding
      );
      // return true;
    },
    subscriptions() {
      return this.$store.state.User.subscriptions.map((sub) =>
        _.assign({ category: "restream" }, sub)
      );
    },
    baseSubscription() {
      return this.subscriptions.find(
        (sub) => sub.category && sub.category.includes(this.stream.type)
      );
    },
    canEncodeVideos() {
      const encodingUnits = _.get(
        this.baseSubscription,
        "package.definition.encodingUnits"
      );
      return encodingUnits > 0;
    },
    generalTags() {
      let generalTags = this.$store.state.Ui.tags.slice();
      generalTags.forEach(function (tag) {
        tag.selected = 'unselected'
      });
      return generalTags;
    },
    userloaded(){
			return this.$store.state.User.userloaded;
		},
		activeTeam(){
			return this.$store.state.User.activatedTeam;
		},
		memberPermission(){
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
    userEmail() {
			return this.$store.state.User.email;
		},
  },
  watch:{
    $route (to, from){
      if (to.name === 'StreamsManageVodAdvertising' || to.name === 'VODstreamManagePaywall') {
        this.showUploadWidget = false;
      } else {
        this.showUploadWidget = true;
      }
    },
    stream() {
      this.videoFileSearchText = ''
    }
  },
  async mounted() {
    this.streamId = this.$route.params.streamId;
    if (this.stream.type === 'vod' && (this.$route.name === 'StreamsManageVodAdvertising' || this.$route.name === 'VODstreamManagePaywall')) {
      this.showUploadWidget = false;
    };
    let showcase = await UserService.getShowcase();
		this.showcase = showcase;
  },
 
  methods: {
    checkDelPermission(){
      let hostingDelete = (this.memberPermission && this.memberPermission.hostingDelete);
			return hostingDelete;
		},
    videoFileSearchTextChanged(text) {
      this.videoFileSearchText = text
      this.$emit('search-text-changed', text)
    },
    onVideoAdded(videoItem) {
      this.$emit("video-added", videoItem);
    },
    vodDelete() {
      if(!this.activeTeam || this.checkDelPermission()){
        	let modalId = "delete-vod-stream";
				console.log(this.showcase);
			if(this.showcase && this.showcase.vods.length>0){
				let hasShowcased = this.showcase.vods.filter((sw)=>{
          let hasVod = this.videoFiles.some((v)=>{
            return v.id == sw.id;
          });
            console.log(hasVod);
          return hasVod;
					// return sw.id == video.id;
				});
				if(hasShowcased.length > 0){
					this.showcasedStream = hasShowcased[0];
					modalId = "delete-vod-stream-showcase";
				}
			}
        this.$root.$emit("bv::show::modal", modalId);
      }
      
    },
    toggleTag(tag) {
      let checkTag = this.selectedTags.filter( el => el.title == tag.title );
      let checkTagInGeneral = this.generalTags.find(el => el.title === tag.title);
      let index  = this.generalTags.findIndex(el => el.title === tag.title);
      if (checkTag.length === 0) {
        checkTagInGeneral.selected = 'selected';
        Vue.set(this.generalTags, index, checkTagInGeneral);
        this.selectedTags.push(tag);
        
      } else {
        checkTagInGeneral.selected = '';
        Vue.set(this.generalTags, index, checkTagInGeneral);
        this.selectedTags = this.selectedTags.filter( el => el.title != tag.title )
      }
      
      this.$emit('tag-selected', this.selectedTags)
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.delete-folder {
  position: absolute;
  top: -65px;
  right: 15px;
}
.VODBucketPlayerSettings_area .vod_uploadbox, .VODBucketSecuritySettings_area .vod_uploadbox {
  display: none;
}
.search-form__toggle {
	z-index: 9;
}
.search-form__field {
	border: none;
	padding-left: 0;
	padding-right: 0;
	caret-color: var(--c-main-light);
}
.search-form__field::placeholder{
	color: var(--c-dark-5);
}
.input-group > .search-form__field {
	border-radius: 6px;
}
.search-form__field:active, .search-form__field:focus {
  border: 1px solid var(--c-main-light)
}
.search-form__field {
	width: 100%;
	padding-left: 40px;
	padding-right: 16px;
	background: var(--c-dark-2);
}
.search-clear {
  position: absolute;
  right: 0;
  opacity: 0;
  z-index: 9;
}
.search-clear.active {
  opacity: 1;
}
:deep(.tag-filter .tag-check) {
  color: transparent;
}
:deep(.tag-filter .tag-item:hover) {
  background-color: var(--c-dark-4);
}
:deep(.tag-filter .tag-item:hover .tag-check) {
  color: var(--c-dark-5);
}
:deep(.tag-filter .tag-item .tag-check.selected) {
  color: #fff;
}
.search-vod {
  max-width: 220px;
}
@media (max-width: 767px) {
  .search-form {
    width: 100%;
    max-width: 100%;
  }
  :deep(.tags-fix .uploader-body > .d-flex) {
    position: static;
  }
  .search-wrapper {
    flex-wrap: wrap;
  }
}
@media (min-width: 1200px) {
  :deep(.tags-fix .video-list) {
    /* TODO this was comented because we have a layout issiue on VOD video layout - app/vods/63a2b192599c8c3e86696a10/videos */
    width: calc(100vw - 290px);
    max-width: 1270px;
    position: relative;
    z-index: 1;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  :deep(.tags-fix .video-list) {
    width: calc(100vw - 290px);
  }
}
</style>
