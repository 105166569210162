<script setup>
import { computed } from 'vue'
import TabGroup from '../../components/Atoms/TabGroup.vue';
import TabItem from '../../components/Atoms/TabItem.vue';
import { useRoute } from 'vue-router'

const route = useRoute()

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	},
	streamAlive: {
		type: Boolean
	},
	backupStreamAlive: {
		type: Boolean,
		default() { return false; }
	},
})

const childViewList = [
	{
		streamType: 'live',
		name: 'player interface',
		viewName: 'StreamsManageSettingsPlayerInterface'
	},
	{
		streamType: 'live',
		name: 'player functions',
		viewName: 'StreamsManageSettingsPlayerFunctions'
	},
	{
		streamType: 'live',
		name: 'security',
		viewName: 'LivestreamManageSecuritySettings'
	},
	{
		streamType: 'live',
		name: 'adaptive bitrate',
		viewName: 'StreamsManageSettingsAdaptiveBitrate'
	},
	{
		streamType: 'vod',
		name: 'player settings',
		viewName: 'VODBucketPlayerSettings'
	},
	{
		streamType: 'vod',
		name: 'security',
		viewName: 'VODBucketSecuritySettings'
	}
]

const isActive = (name) => {
	return route.name === name
}

const childViews = computed(() => {
	return (props.stream && childViewList.filter(v => {
		const isIncluded = v.streamType === props.stream.type
		return isIncluded && v.validate ? v.validate(props.stream) : isIncluded
	}))
})

const emit = defineEmits({
	'set-live-stream-countdown': (value) => { return value },
	'live-stream-abr-changed': (value) => { return value }
})
const liveStreamCountdownSelected = (d) => {
	emit('set-live-stream-countdown', d);
}
const liveStreamAbrChanged = (d) => {
	emit('live-stream-abr-changed', d);
}
</script>
<template>
	<div>
		<TabGroup class="mb-6">
			<TabItem class="capitalize" v-for="(viewItem, index) in childViews" :key="index" :label="viewItem.name"
				:isActive="isActive(viewItem.viewName)" :to="{ name: viewItem.viewName }" />
		</TabGroup>
		<router-view :stream="stream" :stream-alive="streamAlive" :backup-stream-alive="backupStreamAlive"
			@set-live-stream-countdown="liveStreamCountdownSelected" @live-stream-abr-changed="liveStreamAbrChanged" />
	</div>
</template>

<style scoped></style>
