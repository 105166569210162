<script setup>
import { computed, watch, ref, onMounted, getCurrentInstance } from 'vue'
import IconBanknote from "../../components/icon/IconBanknote.vue"
import IconChevronRight from "../../components/icon/IconChevronRight.vue"
import IconPlus from "../../components/icon/IconPlus.vue"
import FormSwitch from "../../components/Atoms/FormSwitch.vue"
import { useStore } from 'vuex'
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconChevronUpDown from '../icon/IconChevronUpDown.vue';
import IconStripe from '@/components/icon/IconStripe.vue'
import { useRoute, useRouter } from 'vue-router'
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';

const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()
const store = useStore()

const userPaywall = computed(() => store.getters['User/userPaywall'])
const paywalls = computed(() => store.getters['Paywalls/paywalls'])

const props = defineProps({
  onStripeConnectClick: {
    type: Function,
  },
  onStripeConnectCancelClick: {
    type: Function
  },
  stripeConnectProcessing: {
    type: Boolean,
  },
  stripeOauthLink: {
    type: String
  },
  stripeConnectLinkError: {
    type: Boolean
  },
  paywallActive: {
    type: Boolean
  },
  currentPaywall: {
    type: Object
  }
})

const isActiveLink = (id) => route.params.paywallId?.toString() === id.toString()

</script>

<template>
  <header class="mt-24 md:mt-6 flex justify-between items-center md:pl-2 !mb-2 md:!mb-6 gap-x-3">
    <h2 class="text-lg font-medium m-0 !hidden md:!block">
      Paywall
    </h2>
    <DropDownMenu class="md:hidden" type="light" ref="paywallListDropdown" maxHeight="[300px]"
      wrapperClass="">
      <template #toggle-button>
        <span class="truncate max-w-[50vw] -ml-2 text-lg">{{ props.currentPaywall?.title }}</span>
        <icon-base class="!ml-1">
          <icon-chevron-up-down />
        </icon-base>
      </template>
      <template #menu-items>
        <DropDownMenuItem isCustom class="!p-2 hover:!bg-transarent">
          <div class="flex relative items-center transition-all w-full">
            <icon-base class="text-surface-8 absolute left-3 shrink-0 z-10" fill="none">
              <icon-search />
            </icon-base>
            <FormInput placeholder="Search" customClasses="!px-8" v-model="searchFoldersQuery" />
            <FormButton type="icon" size="sm" @click="searchFoldersQuery = ''" :class="searchFoldersQuery
              ? 'opacity-100 absolute right-2 shrink-0 z-10'
              : 'opacity-0 absolute right-2 shrink-0 z-10'">
              <icon-base class="shrink-0" fill="none">
                <icon-cross />
              </icon-base>
            </FormButton>
          </div>
        </DropDownMenuItem>
        <DropDownMenuItem v-for="(paywall, index) in paywalls" :key="index" :active="isActiveLink(paywall?.id)"
          :to="'/paywall/v2/' + paywall.id">
          <icon-base class="mr-2 shrink-0" fill="none">
            <icon-banknote />
          </icon-base>
          <span class="block truncate">{{ paywall.title }}</span>
        </DropDownMenuItem>
      </template>
    </DropDownMenu>
    <FormButton class="ml-auto" type="secondary" v-b-modal="'add-paywall-modal'">
      <icon-base class="md:!mr-2 shrink-0">
        <icon-plus />
      </icon-base>
      <span class="!hidden md:!inline">Create</span>
    </FormButton>
    <DropDownMenu
      customClasses="font-medium size-8 rounded-md flex items-center justify-center ring-1 ring-surface-4 bg-transparent hover:text-white hover:ring-surface-6 !p-1"
      listClasses="overflow-y-visible" position="mobile-right" ref="paywallMenu">
      <template #toggle-button>
        <icon-base width="11" height="17" heightViewBox="17" widthViewBox="11">
          <icon-stripe />
        </icon-base>
      </template>
      <template #menu-items>
        <DropDownMenuItem isCustom class="text-surface-9 flex flex-col gap-y-3 text-tiny w-48 !p-3">
          <FormSwitch size="sm" label="Linked to Stripe" v-model="props.paywallActive" />
          <dl class="flex justify-between w-full items-center">
            <dt class="">Stripe ID</dt>
            <dd class="">{{ userPaywall.externalMasterId }}</dd>
          </dl>
        </DropDownMenuItem>
      </template>
    </DropDownMenu>
  </header>
  <ul class="!hidden md:!block list-group max-h-[90vh] overflow-y-auto">
    <li v-for="(paywall, index) in paywalls" :key="index">
      <router-link :title="paywall.title"
        class="text-sm text-surface-8 hover:text-white hover:bg-surface-3 flex items-center !px-3 !py-2 hover:no-underline rounded basis-full"
        :active="isActiveLink(paywall?.id)" :to="'/paywall/v2/' + paywall.id">
        <icon-base class="mr-2 shrink-0" fill="none">
          <icon-banknote />
        </icon-base>
        <span class="block truncate">{{ paywall.title }}</span>
      </router-link>
    </li>
  </ul>
</template>

<style scoped>
.router-link-active {
  @apply bg-surface-3 text-white
}
</style>
