<template>
	<div class="schedule-sidebar mt-2 mt-md-5">
		<div class="mode p-3 mb-4">
			<b-row>
				<b-col md="8">
					<h3 class="text-s-l text-500 mb-4 d-flex align-items-center">Scheduling Mode <div class="text-s-s font-weight-normal text-dark-8" v-if="!scheduleConfigLoaded">
						<i class="fa fa-spinner fa-spin ml-2" /> Retrieving
					</div></h3>
				</b-col>
				<b-col md="4" v-if="!scheduleMode" class="text-right">
					<span
						class="text-danger"
					>Not Configured</span>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div id="shedule-switcher" class="d-flex align-items-center justify-content-between w-100 mb-1">
						<b-form-group  class="w-100 bg-dark-3 mb-0">
							<b-form-radio-group 
								class="btn-group_spacing w-100" 
								size="md" 
								id="stream-source-type"
								v-model="scheduleMode" 
								buttons 
								button-variant="dark-3" 
								@input="onScheduleModeChanged"
							>
								<b-form-radio :value="ScheduleModes.Loop"
									class="flex-wrap col-6 p-0"
									:disabled="schedulerConfigProcessing || infiniteLoopDisabled || !stream.enabled"
								><p class="m-0 w-100 text-s-m">Infinite loop</p><p class="m-0 text-s-xs"> {{ infiniteLoopUpgradeTxt }}</p></b-form-radio>
								<b-form-radio 
									class="col-6 p-0 text-s-m"
									:value="ScheduleModes.Datetime"
									:disabled="schedulerConfigProcessing || !stream.enabled"
								>Schedule Date Time</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
						<!-- @todo move to component, inline styling fix -->
					</div>
					<b-tooltip v-if="!stream.enabled" target="shedule-switcher" title="Tooltip title" triggers="hover">
						Enable stream first
					</b-tooltip>
				</b-col>
			</b-row>
			<b-row 	v-if="scheduleMode === 'datetime'">
				<b-col>
					<VueDatePicker placeholder="Select date time" v-model="scheduledDateTime" :min-date="new Date(Date.now() + (180 * 1000))"   @focus="setMinScheduleDateTime" inputClassName="border-0 form-control form-control-md bg-dark-3"/>
					<!-- <VueDatePicker placeholder="Select date time" v-model="scheduledDateTime" utc :min-date="this.minScheduleDateTime" @focus="setMinScheduleDateTime" inputClassName="border-0 form-control form-control-md bg-dark-3"></VueDatePicker> -->
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-button
						v-if="canSaveScheduleSetting && scheduleMode === 'datetime'"
						variant="primary"
						size="sm"
						class="mt-3"
						@click="saveSchedulerConfig"
					>
						<span
							v-if="schedulerConfigProcessing"
							class="fas fa-spinner fa-spin"
						/>
						<span v-else>Save</span>
					</b-button>
					<b-button
						v-if="canSaveScheduleSetting && scheduleMode === 'loop'"
						variant="primary"
						size="sm"
						class="mt-3"
						@click="loopConfirm"
					>
						<span
							v-if="schedulerConfigProcessing"
							class="fas fa-spinner fa-spin"
						/>
						<span v-else>Save</span>
					</b-button>
				</b-col>
			</b-row>
		</div>
		<confirm-modal
			modal-id="feature-upgrade"
			message="Please upgrade your subscription plan to access this feature"
			ok-text="Upgrade Now"
			cancel-text="Later"
			@modal-confirm="navigateToPayments()"
		/>
		<confirm-modal
			modal-size="md"
			modal-id="infinite-loop-warning"
			message="Infinite loop mode does not support Facebook"
			ok-text="Change to Infinite Loop"
			cancel-text="Cancel"
			body="For Facebook platforms that connected with Castr via Login to Facebook option, you won’t be able to use them with Infinite Loop mode due to restriction of Facebook. Manually conntected Facebook platforms with streaming URL and key will not be affected."
			@modal-confirm="infiniteLoopConfirmed()"
		/>
		<confirm-modal
			modal-size="md"
			modal-id="enable-video-warning"
			message="Enable at least one video from playlist for streaming"
			:show-confirm="false"
			cancel-text="Got it"
			body="No video turned on for this stream. Please choose at least one video from the playlist to start your looped stream."
		/>
		<confirm-modal
			modal-size="md"
			modal-id="enable-video-confirm"
			message="Set live immediately?"
			:show-confirm="true"
			ok-text="Confirm Infinite Loop"
			cancel-text="Cancel"
			body="All enabled videos in the playlist will go live now on your destinations and stream as a loop."
			@modal-confirm="saveSchedulerConfig()"
		/>
		<confirm-modal
			modal-size="sm"
			modal-id="stream-status-warning"
			message="Stream is disabled"
			ok-text="Enable stream"
			cancel-text="Cancel"
			body="Please enable stream before setting your scheduling mode."
			@modal-confirm="saveSchedulerConfig()"
		/>
	</div>
</template>
<script setup>
import { ref } from 'vue';
const date = ref(new Date());
</script>
<script>
import StreamService from '@/services/StreamService';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import moment from 'moment'
import 'moment-timezone'
import _ from 'lodash'

const ScheduleModes = {
	Loop: 'loop',
	Datetime: 'datetime'
};

export default {
	name: 'StreamSchedulingModeConfigurator',
	components: {
		ConfirmModal
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		videoFiles: {
			type: Array,
			required: false
		}
	},
	data() {
		return {
			ScheduleModes,
			savedSchedulerConfig: null,
			scheduleMode: null,
			scheduledDateTime: null,
			schedulerConfigProcessing: false,
			scheduleConfigLoaded: false,
			minScheduleDateTime: null,
			infiniteLoopUpgradeTxt: '',
			infiniteLoopDisabled: false,
			infinityLoopFacebookWarningShown: false
			// videoList: []
		};
	},
	computed: {
		subscriptions() {
			return this.$store.state.User.subscriptions;
		},
		async infiniteLoopCheck() {
			const data = _.filter(await this.$store.state.User.subscriptions, (item) => item.category === 'scheduled')[0];
			// Disable loop mode
			if (data && data.package && data.package.definition.schedulerInfiniteLoop === false) {
				this.infiniteLoopUpgradeTxt = `(upgrade to use this)`
				this.infiniteLoopDisabled = true
			}
		},
		productsFeatures() {
			return this.$store.state.User.aggregatedDefinition;
		},
		canSaveScheduleSetting() {
			if (!this.savedSchedulerConfig) return;

			let changed = this.savedSchedulerConfig.mode !== this.scheduleMode;
			if (this.scheduleMode === 'loop') return changed;

			const hasDatetime = !!this.scheduledDateTime;
			if (!hasDatetime) return false;

			const scheduledMs = new Date(this.scheduledDateTime).getTime();
			changed = scheduledMs !== this.savedSchedulerConfig.datetime;
			return changed;
		},
		hasEnabledVideo() {
			let result = this.videoFiles.find((video => video.enabled === true))
			if (result) {
				return true
			} else return false
		}
	},
	async mounted() {
		await this.setupStream();
		await this.setupScheduling();
	},
	methods: {
		enableStream(){
			 this.$parent.toggleStatus();
			 this.stream.enabled && this.saveSchedulerConfig()
		},
		navigateToPayments() {
			window.location.href = '/app/subscribe';
		},
		async setupStream() {},
		async setupScheduling() {
			this.infiniteLoopCheck;
			const schedulerConfig = await StreamService.getStreamScheduleSettings(
				this.stream._id
			) || {};
			this.savedSchedulerConfig = schedulerConfig;
			this.scheduleMode = schedulerConfig.mode;

			const { datetime } = schedulerConfig;
			if (datetime) {
				this.scheduledDateTime = new Date(datetime).toISOString();
			}

			this.scheduleConfigLoaded = true;

			this.setMinScheduleDateTime();
			this.$emit('scheduler-settings-updated', this.savedSchedulerConfig);
		},
		setMinScheduleDateTime() {
			return new Date(
				Date.now() + 180 * 1000
			).toISOString();
		},
		async onScheduleModeChanged() {
			const updatedMode = this.scheduleMode;

			if (updatedMode === 'loop') {
				const playlistCanLoop = _.get(this.productsFeatures,'scheduled.loopingFeature',false);
				if (!playlistCanLoop) {
					this.$root.$emit('bv::show::modal', 'feature-upgrade');
					this.scheduleMode = ScheduleModes.Datetime;
				}

				// Infinite loop mode does not support Facebook
				const isFacebookContained = this.stream.platforms.some((item) => item.template === 'facebook live' && item.linkedServiceCreds)
				if (isFacebookContained && this.savedSchedulerConfig.mode !== ScheduleModes.Loop && !this.infinityLoopFacebookWarningShown) {
					this.$root.$emit('bv::show::modal', 'infinite-loop-warning');
					this.infinityLoopFacebookWarningShown = true
					this.scheduleMode = ScheduleModes.Datetime;
				}
			}
		},
		async infiniteLoopConfirmed() {
			this.scheduleMode = ScheduleModes.Loop;
			await this.saveSchedulerConfig()
		},
		async saveSchedulerConfig() {
			if (this.schedulerConfigProcessing) return;
			if(!this.stream.enabled){
				this.$root.$emit('bv::show::modal', 'stream-status-warning');
				return;
			}
			this.schedulerConfigProcessing = true;
			try {
				await StreamService.saveStreamScheduleSettings(
					this.stream._id,
					this.scheduleMode,
					new Date(this.scheduledDateTime)
				);

				const updatedConfig = { mode: this.scheduleMode };
				if (this.scheduleMode === 'datetime' && this.hasEnabledVideo) {
					updatedConfig.datetime = new Date(
						this.scheduledDateTime
					).getTime();
				} else if (this.scheduleMode === 'datetime' && !this.hasEnabledVideo) {
					this.$root.$emit('bv::show::modal', 'enable-video-warning');
				} else if (this.scheduleMode === 'loop') {
					this.scheduledDateTime = null;

					// Toggle off all OAuth Facebook platforms
					await Promise.all(this.stream.platforms.map((item) => {
						if (item.enabled && item.template === 'facebook live' && item.linkedServiceCreds) {
							StreamService.toggleStreamPlatform(this.stream._id, item._id);
						}
					}));
					this.$emit('refresh-stream');
				}

				this.savedSchedulerConfig = updatedConfig;
				this.savedSchedulerConfigParent = updatedConfig;
				this.$emit('scheduler-settings-updated', this.savedSchedulerConfig);
				this.$root.$emit('scheduleMode', this.scheduleMode);
			} catch (e) {
				this.$notify({
					group: 'error',
					text: 'Could not update scheduler settings'
				});
				console.log(e);
			}

			this.schedulerConfigProcessing = false;

			this.$emit('stream-updated', { platforms: this.streamPlatforms });
		},
		loopConfirm() {
			if (!this.hasEnabledVideo) {
				this.$root.$emit('bv::show::modal', 'enable-video-warning');
			} else {
				this.$root.$emit('bv::show::modal', 'enable-video-confirm');
			}
		}
	},
	watch: {
		'stream.enabled'() {
			if (!this.stream.enabled) this.scheduleMode = null
			if (!this.stream.enabled) this.savedSchedulerConfig = {}
		},
		subscriptions(){
			this.infiniteLoopCheck;
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mode {
	background-color: var(--c-dark-2);
	border-radius: 6px;
}
.form-group.bg-dark-3 {
	border-radius: 6px;
}
.custom-caret:after{
	content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
	top: 58%;
    right: 30px;
    position: absolute;
}
</style>
