// Boostrap
import axios from 'axios'
import VueAxios from 'vue-axios'
import BootstrapVue from 'bootstrap-vue';
import { DropdownPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import '@vuepic/vue-datepicker/dist/main.css'
// import 'vue-datetime/dist/vue-datetime.css';

import '@/styles/variables.css';
import '@/styles/base.css';
import '@/styles/castr.css';
import '@/styles/main.css';

import * as Vue from 'vue'
import { createApp } from 'vue'
import filters from '@/filters';
import router from '@/router';
import store from '@/store';
import App from '@/App';
console.log(Vue.version)
// import api from '@/api';
// Vue.prototype.axios = api;

import StreamService from '@/services/StreamService';

import { activateSentry } from '@/lib/analytics';

import VueClipboard from 'vue3-clipboard'
import Notifications from '@kyvg/vue3-notification'
import VueLocalStorage from 'vue-localstorage';
//TODO MIGRATION
import VueDatePicker from '@vuepic/vue-datepicker';

// import VueCookie from 'vue-cookie';

const app = createApp(App)

import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdkBjW39bcXxWQ2dU');

app.config.globalProperties.$filters = {
  bytes(num, breakSegments, decimalPlaces = 3, mini = false) {
		// jacked from: https://github.com/sindresorhus/pretty-bytes
		if (typeof num !== 'number' || isNaN(num)) {
			throw new TypeError('Expected a number');
		}

		const neg = num < 0;
		const units = [(mini ? 'B' : 'Bytes'), 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		if (neg) num = -num;
		if (num < 1) {
			const value = (neg ? '-' : '') + num;
			return breakSegments ? { value, unit: units[0] } : (value + ' Bytes');
		}

		const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
		const unit = units[exponent].toLowerCase();

		num = (num / Math.pow(1000, exponent)).toFixed(decimalPlaces) * 1;

		const value = (neg ? '-' : '') + num;
		return breakSegments ? { value: Number(value), unit } : (value + ' ' + unit);
  }
}

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.use(Notifications);
app.use(BootstrapVue);
app.use(DropdownPlugin);
//TODO MIGRATION
// Vue.use(ToggleButton);
app.use(VueAxios, axios)
app.use(VueLocalStorage, { name: 'localStorage', bind: true });
// app.use(VueCookie);
app.use(store)
app.use(router)
// filters(Vue);
app.component('VueDatePicker', VueDatePicker)
StreamService.getAvailableRegions();

activateSentry();
window.trackEvent = () => {};

// Vue.config.productionTip = false;

/* eslint-disable no-new */
app.mount('#app');
