<script>
export default {
  compatConfig: {
    MODE: 3,
    COMPONENT_V_MODEL: false,
  }
}
</script>
<script setup>
import { computed, ref } from 'vue'

import { timeStringToObject, timeObjectToString } from '@/utils'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  size: {
    type: String,
    default: 'md',
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  maxlength: {
    type: Number,
    default: 524288
  },
  helper: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  isError: {
    type: Boolean,
    default: false,
  },
  customClasses: {
    type: String,
  },
  maxHours: {
    type: Number,
    default: 12,
  },
  minTime: {
    type: Object,
  },
  id: {
    type: String,
  },
  modelValue: {
    default: ''
  },
  isObject: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits({
  'update:modelValue': (value) => { return value }
})

const computedValue = computed({
  get: () => {
    if (props.isObject) return props.modelValue
    return timeStringToObject(props.modelValue)
  },
  set: value => {
    if (value) emit('update:modelValue', timeObjectToString(value))
  },
})

const inputClass = computed(() => {
  const base = [
    'w-full duration leading-body border-none bg-surface-3 focus:bg-surface-4 focus:outline-0 focus:ring-0 rounded transition-all placeholder-sureface-8 text-surface-9 disabled:text-surface-7',
  ]
  if (props.isError) {
    base.push('ring-1 ring-error-light focus:ring-error-light bg-error-transparent')
  } else {
    base.push('border-neutral-n50 focus:border-surfaces-primary focus:placeholder:text-transparent invalid:ring-1 invalid:ring-error-light invalid:bg-error-transparent invalid:focus:ring-1 invalid:focus:bg-error-transparent invalid:focus:ring-error-light')
  }
  const size = props.size === 'lg' ? 'h-10' : 'h-8 !px-2 md:!px-3 text-tiny md:text-tiny'
  base.push(size)
  return base.join(' ')
})

const wrapperClass = computed(() => {
  return props.helper ? 'pb-4' : ''
})

const focusState = ref(false)

const toggleFocus = async (state) => {
  focusState.value = state
  emit('blur', event)
}

const helperClass = computed(() => {
  return props.isError ? 'text-error' : 'text-surface-9'
})

</script>

<template>
  <div class="relative w-full" :class="wrapperClass">
    <label v-if="label" :for="id">{{ label }}</label>
    <VueDatePicker text-input v-model="computedValue" utc enable-seconds time-picker format="HH:mm:ss" placeholder="Select Time"
      :min-time="props.minTime" :max-time="{ hours: 12, minutes: 0, seconds: 0 }"
      :inputClassName="inputClass" />
    <p class="absolute top-10 text-xs px-3" :class="helperClass">{{ helper }}</p>
  </div>
</template>

<style>
@layer tailwind {
  input {
    @apply px-3 py-2
  }
}
.dp__input_icon {
  @apply hidden
}
input.duration {
  @apply min-w-[70px]
}
.dp__clear_icon {
  @apply !hidden md:!block
}
input[type=time].duration::-webkit-datetime-edit-ampm-field, input[type=time].duration::-webkit-datetime-edit-meridiem-field, input[type=time].duration::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
