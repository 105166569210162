<script setup>
import _ from 'lodash'
import AddTagModal from '@/components/modals/AddTagModal.vue';
import { ref, onMounted, getCurrentInstance, computed } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Badge from '../Badge.vue';
import IconPlus from '../icon/IconPlus.vue'
import IconCross from '../icon/IconCross.vue'
import FormButton from '../Atoms/FormButton.vue'
import IconBase from '../icon/IconBase.vue'
import IconSearch from '../icon/IconSearch.vue'
import IconSorting from '../icon/IconSorting.vue'
import FormInput from '../Atoms/FormInput.vue'
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import { sortTagsUtil } from "../../utils";
import ModalManageTags from '../modals/ModalManageTags.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()

const props = defineProps({
	videoId: {
		type: String,
		default: ''
	},
	tagIds: {
		type: Array,
		default: () => []
	},
	liveParent: {
		type: Object
	},
	streamParent: {
		type: Object
	}
})

const tagsDropdown = ref(null)
const sortTagsDropdown = ref(null)
const tagsEditing = ref(false)
const tempTagIds = ref([])
const searchQuery = ref('')
const sortBy = ref('A to Z')
const manageTagsModal = ref(null)

const generalTags = computed(() => store.getters['Ui/generalTags']) 

const tagsFiltered = computed(() => {
	const uniqTags = _.cloneDeep(generalTags.value).filter(tag => !props.tagIds.includes(tag.id))
	const searchResult = uniqTags.filter(tag => tag?.title?.toLowerCase().includes(searchQuery.value.toLowerCase()))
	return searchResult
})

const tags = computed(() => {
	const fileTags = _.cloneDeep(generalTags.value).filter(tag => props.tagIds.includes(tag.id))
	return sortTagsUtil(fileTags, 'A to Z')
})

onMounted(() => {
	tempTagIds.value = props.tagIds
})

const toggleEdit = () => {
	tagsEditing.value = !tagsEditing.value
}

const addTagForVod =  async (tag) => {
	const tagsArray = _.cloneDeep(props.tagIds)
	tagsArray.push(tag.id)
	updateVideoTags(tagsArray)
}

const deleteTag = (deletedTag) => {
	const tagsArray = _.cloneDeep(props.tagIds).filter(tag => tag !== deletedTag.id)
	updateVideoTags(tagsArray)
}

const updateVideoTags = async (updatedTagIds) => {
	await store.dispatch('Ui/updateTagsOfVideoFile', { videoId: props.videoId, updatedTagIds, parent: props.liveParent, parentId: props.streamParent?.liveParent })
	await store.dispatch("Streams/getFilesInVODFolder");
}

const sortedBy = (sort) => sort === sortBy.value

const changeSortTagsBy = (value) => {
	store.dispatch('Ui/setSortTags', value)
	sortTagsDropdown.value.toggleDropdown()
}
const showManageTagsModal = () => {
	manageTagsModal.value.toggleModal()
}

defineExpose({ showManageTagsModal })

</script>

<template>
	<div class="">
		<div class="flex flex-wrap gap-1 items-start !py-2">
			<Badge tag v-for="(tag, i) in tags" :key="tag.id || tag.title + i" :type="tag.color"
				class="items-center shadow group relative !px-4">
				<span class="group-hover:opacity-50">{{tag.title}}</span>
				<FormButton @click="deleteTag(tag)" customClasses="flex opacity-0 group-hover:opacity-100 p-0 size-3 items-center justify-center rounded-full bg-surface-9 text-surface-2 !ml-1 absolute right-1 transition-opacity"
					type="link" isIcon>
					<icon-base width="8" height="8" class="shrink-0" fill="transparent">
						<icon-cross />
					</icon-base>
				</FormButton>
			</Badge>
			<DropDownMenu ref="tagsDropdown"
				customClasses="flex items-center truncate w-full grow-0 bg-surface-3 rounded-md h-5 !px-2 rounded-xl hover:bg-surface-4 text-xs"
				wrapperClass="w-48" maxHeight="48" position="right">
				<template #toggle-button>
					<icon-base class="shrink-0" width="12" height="12" fill="transparent">
						<icon-plus />
					</icon-base>
					Tag
				</template>
				<template #menu-items>
					<DropDownMenuItem isCustom class="!p-2 hover:!bg-transarent">
						<div class="flex relative items-center transition-all w-full">
							<icon-base class="text-surface-8 absolute left-3 shrink-0 z-10" fill="none">
							<icon-search />
						</icon-base>
						<FormInput size="sm" placeholder="Search" customClasses="!px-8" v-model="searchQuery" />
						<FormButton type="link" isIcon size="sm" @click="searchQuery = ''" :class="searchQuery
							? 'opacity-100 absolute right-10 shrink-0 z-10'
							: 'opacity-0 absolute right-2 shrink-0 z-10'">
							<icon-base class="shrink-0" fill="none">
								<icon-cross />
							</icon-base>
						</FormButton>
						<DropDownMenu class="ml-2 relative z-10" position="right" type="secondary" isIcon ref="sortTagsDropdown">
							<template #toggle-button>
								<icon-base class="shrink-0 text-surface-8" fill="none">
									<icon-sorting />
								</icon-base>
							</template>
							<template #menu-items>
								<DropDownMenuItem :active="sortBy === 'A to Z'" @click="changeSortTagsBy('A to Z')">
									A to Z
									<icon-base class="shrink-0 !ml-2 text-surface-7" v-if="sortedBy('A to Z')" fill="none">
										<icon-checkmark />
									</icon-base>
								</DropDownMenuItem>
								<DropDownMenuItem :active="sortBy === 'Sort by Oldest'" @click="changeSortTagsBy('Z to A')">
									Z to A
									<icon-base class="shrink-0 !ml-2 text-surface-7" v-if="sortedBy('Z to A')" fill="none">
										<icon-checkmark />
									</icon-base>
								</DropDownMenuItem>
							</template>
						</DropDownMenu>
						</div>
					</DropDownMenuItem>
					<DropDownMenuItem v-for="tag in tagsFiltered" :key="tag.id || tag.title" @click="addTagForVod(tag)">
						<Badge :type="tag.color" :title="tag.title" tag class="items-center" />
					</DropDownMenuItem>
				</template>
			</DropDownMenu>
			<!-- 
				<b-dropdown-item link-class="text-dark-7" :to="{ name: 'VODTagsManage' }">
					Manage Tags
				</b-dropdown-item>
			</b-dropdown> -->
		</div>
		<AddTagModal
      :video-id="1"
      :video-tags="generalTags" 
    />
		<ModalManageTags ref="manageTagsModal" />
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
:deep(.tags .badge-list) {
	max-height: 400px;
	overflow-y: auto;
}
</style>
