<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import _ from "lodash"
import moment from "moment"
import { mapActions, mapGetters } from 'vuex'
import Spinner from "@/components/ui/Spinner.vue"
import PlaylistService from "@/services/Playlistservice"
import PaywallService from "@/services/PaywallService";
import AddPlaylistModal from "@/components/modals/AddPlaylistModal.vue"
import draggable from 'vuedraggable'
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';
import IconBase from '@/components/icon/IconBase.vue'
import IconHandleVertical from '@/components/icon/IconHandleVertical.vue'
import AddPaywallModal from "@/components/modals/AddPaywallModal.vue"
import AddPaywallEmailAccess from "@/components/modals/AddPaywallEmailAccess.vue"

import PaywallTransactions from '@/components/Paywall/TransactionsTab.vue'
import PaywallCustomers from '@/components/Paywall/CustomersTab.vue'
import PaywallEmailAccess from '@/components/Paywall/PaywallEmailAccess.vue'

import StreamSourceDropdown from "@/components/ui/StreamSourceDropdown.vue"
import PaywallPlaceholder from "../../components/Paywall/PaywallPlaceholder.vue"
import IconCopy from "../../components/icon/IconCopy.vue"
import IconChevronRight from "../../components/icon/IconChevronRight.vue"
import IconLinkExternal from "../../components/icon/IconLinkExternal.vue"
import FormSwitch from "../../components/Atoms/FormSwitch.vue"
import FormRadio from "../../components/Atoms/FormRadio.vue"
import PaywallNav from "../../components/Paywall/PaywallNav.vue"
import PaywallHeader from "../../components/Paywall/PaywallHeader.vue"
import PaywallInterface from '../../components/Paywall/PaywallInterface.vue'
import PaywallSettings from '../../components/Paywall/PaywallSettings.vue'
import FormButton from "../../components/Atoms/FormButton.vue"
import FormInput from "../../components/Atoms/FormInput.vue"
import IconEdit from "../../components/icon/IconEdit.vue"
import IconInfoSmall from "../../components/icon/IconInfoSmall.vue"
import Tooltip from '../../components/Atoms/Tooltip.vue'
import { useNotification } from "@kyvg/vue3-notification"
import Badge from '../../components/Badge.vue'
import TabGroup from '../../components/Atoms/TabGroup.vue'
import TabItem from '../../components/Atoms/TabItem.vue'

const instance = getCurrentInstance();
const root = instance.proxy.$root;

const store = useStore()
const router = useRouter()
const route = useRoute()

const { notify } = useNotification()

const paywallEmailAccessComponent = ref(null)

const resStep = ref(1)
const loading = ref(false)
const dividerPosition = ref(25)
const contentPosition = ref(75)
const isDragging = ref(false)
const currentPaywallIndex = ref(null)
const payments = ref(null)
const tempLinks = ref([])
const tempLinkDateTime = ref(null)
const tempLinkMaxDateTime = ref(null)
const tempLinkMinDateTime = ref(null)
const showTempLinkDatePicker = ref(false)
const tempLinkProcessing = ref(false)
const iframe = ref('responsive')
const logoUrl = ref(null)
const logoUrlTmp = ref(null)
const logoSrc = ref(null)
const logoProcessing = ref(false)
const errorMsg = ref(null)
const companyName = ref('')
const companyUrl = ref('')
const companyEmail = ref('')
const showEditPriceInput = ref(false)
const paywallName = ref('')
const paywallPrice = ref(null)
const paywallReports = ref(null)
const sourceConflictCheck = ref(false)
const stripeConnectStatusChecker = ref(null)
const stripeConnectProcessing = ref(false)
const stripeConnectLinkError = ref(null)
const stripeOauthLink = ref(null)
const popupAuth = ref(false)
const paywallActive = ref(false)
const streamSourceDropdownVisible = ref(false)
const isDropdownDetailsVisible = ref(false)

// Computed
const loadingStatus = computed(() => store.getters['Ui/loadingStatus'])
const userloaded = computed(() => store.getters['User/userLoaded'])
const paywallStripeCommission = computed(() => store.getters['User/paywallStripeCommission'])
const paywalls = computed(() => store.getters['Paywalls/paywalls'])
const currentPaywall = computed(() => store.getters['Paywalls/currentPaywall'])

const userPaywallActived = computed(() => store.getters['User/userPaywallActived'])

const totalPayments = ref(null)
const totalRefunds = ref(null)
const totalEarnings = ref(null)

const paywallIframeCode = computed(() => {
  if (!currentPaywall.value) return ''

  const url = currentPaywall.value._streamUrl.replace('_disabled', '')
  if (iframe.value === 'responsive') {
    return `<div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="${url}" frameborder="0" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`
  }
  return `<iframe width="640" height="360" src="${url}" frameborder="0" allowfullscreen></iframe>`
})

onMounted(async () => {
  store.dispatch('Ui/setLoadingStatus', true)
  await store.dispatch('User/loadUser')
  await store.dispatch('Streams/getStreams')
  await store.dispatch('Playlist/getPlaylist')

  currentPaywallIndex.value = route.params.paywallId

  try {
    await store.dispatch('Paywalls/getPaywalls', 'desc')
    if (currentPaywallIndex.value) {
      await selectPaywall(currentPaywallIndex.value)
    }
  } catch (err) {
    console.error(err)
  } finally {
    store.dispatch('Ui/setLoadingStatus', false)
  }

  if (paywalls.value.length > 0 && !currentPaywallIndex.value) {
    const id = paywalls.value[0].id
    router.push({ path: '/paywall/v2/' + id }).catch(() => { });
    currentPaywallIndex.value = id
    // store.dispatch('Paywalls/setCurrentPaywall', paywalls.value[0].id)
  }

  if (currentPaywall.value && currentPaywall.value.source) {
    const resp = await PaywallService.getTemporaryLinks(currentPaywall.value.source._id);
    tempLinks.value = resp.links;
  }

  paywallActive.value = userPaywallActived.value
  if (currentPaywall.value) {
    paywallName.value = currentPaywall.value.title
    companyUrl.value = currentPaywall.value.company_url
    companyName.value = currentPaywall.value.company_name
    companyEmail.value = currentPaywall.value.company_email
    logoSrc.value = currentPaywall.value.company_logo
    paywallPrice.value = currentPaywall.value.price
    popupAuth.value = currentPaywall.value.popupAuth
  }
  paywallReports.value = await PaywallService.getPaywallFinancialReport();
  displayPaywallReport()
  document.addEventListener('click', componentClickHandler())
  dropdownEventHandlers()

  setTimeout(() => {
    store.dispatch('Ui/setLoadingStatus', false)
  }, 2000);
})

onBeforeUnmount(() => {
  clearInterval(stripeConnectStatusChecker.value)
  // document.removeEventListener('mousemove', onDrag)
})

// Watchers
watch(() => route.params.paywallId, (newId) => {
  selectPaywall(newId)
})

const emailAccessCreated = (data) => {
  paywallEmailAccessComponent.value.updateEmailAccess({ id: data.id, email: data.email, plain_pass: data.password })
}

const togglePopupAuthAlert = () => {
  sourceConflictCheck.value = false
  if (!currentPaywall.value.popupAuth) {
    sourceConflictCheck.value = true
  }

  togglePopupAuth()
}

const togglePopupAuthCancel = () => {
  popupAuth.value = false
}
const onPaywallTogge = async () => {
  if (popupAuth.value) {
    await togglePopupAuth(false)
  }
}

const togglePopupAuth = async (showNotify = true) => {
  try {
    const resp = await PaywallService.toggleStreamPaywallPopupAuth(currentPaywall.value.id, sourceConflictCheck.value);
    popupAuth.value = resp.popupAuth

    store.dispatch('Paywalls/setPaywallProp', { id: currentPaywall.value.id, prop: 'popupAuth', value: popupAuth.value })

    if (showNotify) {
      notify({
        group: "success",
        text: `Paywall protected player iframe updated`,
      });
    }
  } catch (err) {
    if (err.message === 'source conflict') {
      sourceConflictCheck.value = false
      return root.$emit('bv::show::modal', 'toggle-popupauth')
    }
    notify({
      group: "error",
      text: err.message || "could not update paywall protected player iframe",
    });
  }
}

const displayPaywallReport = () => {
  if (currentPaywall.value) {
    const report = _.find(paywallReports.value, { stream_id: currentPaywall.value.id })

    totalPayments.value = Number(_.get(report, 'payments_total', 0.00)).toFixed(2)
    totalRefunds.value = Number(_.get(report, 'refunds_total', 0.00)).toFixed(2)
    totalEarnings.value = (totalPayments.value - totalRefunds.value).toFixed(2)
  }
}

const onStripeConnectClick = async () => {
  stripeConnectLinkError.value = false;
  try {
    const pwMaster = await PaywallService.initPaywall()
    if (pwMaster.oauthLink) {

      stripeOauthLink.value = pwMaster.oauthLink
      stripeConnectProcessing.value = true

      monitorStripeLinkingStatus()
      window.open(stripeOauthLink.value)
    }

    window.open(stripeOauthLink.value)
  } catch (err) {
    notify({
      group: "error",
      text: err.message || "Stripe link failed",
    });
  }
}

const monitorStripeLinkingStatus = async () => {
  stripeConnectStatusChecker.value = setInterval(async () => {
    try {
      const resp = await PaywallService.stripeLinkingStatus();

      if (resp.status === "active") {
        // init stream
        stripeOauthLink.value = null;
        stripeConnectLinkError.value = false;
        clearInterval(stripeConnectStatusChecker.value);
        window.location.reload();
      }

      if (resp.status === "failed") {
        stripeConnectProcessing.value = false;
        stripeConnectLinkError.value = true;
        clearInterval(stripeConnectStatusChecker.value);
      }
    } catch (e) {
      notify({
        group: "error",
        text: err.message || "linking paywall failed",
      });
      clearInterval(stripeConnectStatusChecker.value);
    }
  }, 3000);
}

const onStripeConnectCancelClick = () => {
  clearInterval(stripeConnectStatusChecker.value)
  stripeOauthLink.value = null;
  stripeConnectProcessing.value = false
}

const onSourceDropdownHide = () => {
  streamSourceDropdownVisible.value = false
}

const onSourceDropdownShow = () => {
  streamSourceDropdownVisible.value = true
}

const onUnlinkStripe = async () => {
  try {
    await PaywallService.unlinkPaywall()

    notify({
      group: "success",
      text: "Stripe unlinked",
    });

    await store.dispatch('User/unloadPaywall')
    await store.dispatch('Paywalls/unloadPaywall')

    paywallActive.value = false

    setTimeout(() => {
      router.push({ path: '/paywall/v2/' }).catch(() => { });
    }, 300)

  } catch (err) {
    notify({
      group: "error",
      text: err.message || "Paywall unlink failed",
    });
  }
}

const componentClickHandler = (ev) => {
  if (streamSourceDropdownVisible.value ||
    !ev?.target?.parentElement?.className ||
    ev?.target?.parentElement.className.includes('toggle-popupauth') ||
    ev?.target?.parentElement.className.includes('custom-control')) {
    return
  }
  if (ev.target.className === 'custom-control-label') {
    root.$emit('bv::show::modal', 'unlink-stripe')
  }
}

const dropdownEventHandlers = () => {
  root.$on('bv::dropdown::show', bvEvent => {
    if (bvEvent.componentId === 'dropdown-details') {
      isDropdownDetailsVisible.value = true;
    }
  })
  root.$on('bv::dropdown::hide', bvEvent => {
    if (bvEvent.componentId === 'dropdown-details') {
      isDropdownDetailsVisible.value = false;
    }
    if (isDropdownDetailsVisible.value) {
      bvEvent.preventDefault()
    }
  })
}

const onPaywallRemove = async () => {
  try {
    await PaywallService.deleteStreamPaywall(currentPaywall.value.id)

    notify({
      group: "success",
      text: "Paywall deleted",
    });

    store.dispatch('Paywalls/removePaywall', currentPaywall.value.id)

    if (paywalls.value.length > 0) {
      router.push({ path: '/paywall/v2/' + paywalls.value[0].id }).catch(() => { });
      currentPaywallIndex.value = paywalls.value[0].id
      // store.dispatch('Paywalls/setCurrentPaywall', paywalls.value[0].id)
      paywallName.value = currentPaywall.value.title
      return
    }

    setTimeout(() => {
      router.push({ path: '/paywall/v2/' }).catch(() => { });
    }, 500)

  } catch (err) {
    notify({
      group: "error",
      text: err.message || "Paywall toggle failed",
    });
  }
}

const onSourceStreamSelected = async (stream) => {
  const rand = Math.random().toString(36).slice(-7)
  try {
    const payload = {
      url: `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${stream.key}?paywall=1&rand=${rand}`,
    }

    if (stream.type === 'vod') {
      payload.url = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/vod/playlist/${stream._id}?paywall=1&rand=${rand}`;
    }

    if (stream.streams) {
      // should be playlist
      payload.url = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/playlist/${stream._id}?paywall=1&rand=${rand}`;
    }

    await PaywallService.updateStreamPaywallSource(
      currentPaywall.value.id,
      stream._id,
      payload
    );

    store.dispatch('Paywalls/setStreamSource', { id: currentPaywall.value.id, stream })

    notify({
      group: "success",
      text: "Paywall source updated",
    });
  } catch (err) {
    notify({
      group: "error",
      text: err.message || "Paywall source update failed",
    });
  }
}

const tempLinksFiltered = () => {
  const now = new Date().getTime();
  const filtered = _.filter(tempLinks.value, (item) => {
    return new Date(item.expireAt).getTime() > now;
  });

  return _.sortBy(filtered, function (d) {
    return new Date(d.expireAt);
  });
}
const generateTempLink = async () => {
  tempLinkProcessing.value = true;
  try {
    const expireAt = new Date(tempLinkDateTime.value).getTime();
    const expireMin = Math.round((expireAt - new Date().getTime()) / 60000);

    const resp = await PaywallService.createTemporaryLink(
      currentPaywall.value.source._id,
      expireAt,
      expireMin
    );
    tempLinks.value.push({
      url: resp.link,
      expireAt,
    });

    showTempLinkDatePicker.value = null;
    tempLinkDateTime.value = null;
    notify({
      group: "success",
      text: "Temporary link generated",
    });
  } catch (err) {
    console.error("err", err);
    notify({
      group: "error",
      text: err.message || "Generate temporary link failed",
    });
  }
  tempLinkProcessing.value = false;
}

const setRangesTempLinkDateTime = () => {
  tempLinkMaxDateTime.value = new Date(
    Date.now() + 1 * 1000 * 60 * 60 * 24 * 30
  ).toISOString();
  tempLinkMinDateTime.value = new Date(
    Date.now() + 180 * 1000
  ).toISOString();
}

const humanDate = (date) => {
  return moment(new Date(date)).format("MMM DD, YYYY, [at] hh:mm A");
}

const paywallCreated = (paywall) => {
  notify({
    group: "success",
    text: "Paywall created",
  });

  setTimeout(() => {
    router.push({ path: '/paywall/v2/' + paywall.id }).catch(() => { });

    setTimeout(() => {
      window.location.reload()
    }, 200);
  }, 100)
}

const selectPaywall = async (paywallId) => {
  currentPaywallIndex.value = paywallId
  displayPaywallReport()
  paywallName.value = currentPaywall.value.title
  companyUrl.value = currentPaywall.value.company_url
  logoSrc.value = currentPaywall.value.company_logo
  companyName.value = currentPaywall.value.company_name
  companyEmail.value = currentPaywall.value.company_email
  popupAuth.value = currentPaywall.value.popupAuth
  if (currentPaywall.value?.source) {
    // const sourceTransactions = await PaywallService.getSourceTransactions(this.currentPaywall.source._id)
    const resp = await PaywallService.getTemporaryLinks(currentPaywall.value.source._id)
    tempLinks.value = resp.links
  }
  return
}

const changeRouteQuery = (item) => {
  if (route.query.paywallTab != item) {
    router.replace({ query: { ...route.query, paywallTab: item } })
  } else return
}

const onPriceEdit = () => {
  showEditPriceInput.value = true
}
const onPriceUpdate = async () => {
  try {
    await PaywallService.updateStreamPaywallById(currentPaywall.value.id, { price: Number(paywallPrice.value) })
    store.dispatch('Paywalls/setPaywallProp', { id: currentPaywall.value.id, prop: 'price', value: Number(paywallPrice.value) })
    showEditPriceInput.value = false

    notify({
      group: "success",
      text: "Price updated",
    });
  } catch (err) {
    notify({
      group: 'error',
      text: err.message || "Update price failed",
    })
  }
}

const clipboardCopy = async (text) => {
  try {
    if (text instanceof Function) text = text();

    await navigator.clipboard.writeText(text);
    notify({ group: 'info', text: 'Copied to clipboard' });
  } catch (e) { }
}

const handleDragging = (e) => {
  e.preventDefault();
  const position = e.pageX
  const wrapper = document.getElementById('resizible-wrapper')
  const windowWidth = window.innerWidth
  const percentage = ((position - 260) / wrapper.offsetWidth) * 100
  if (percentage >= 20 && percentage <= 30) {
    dividerPosition.value = percentage.toFixed(2)
  }
  if (position - 260 <= 0) {
    document.removeEventListener('mousemove', handleDragging.value)
  }
}
const startDragging = () => {
  document.addEventListener('mousemove', handleDragging.value)
}

const endDragging = () => {
  document.removeEventListener('mousemove', handleDragging.value)
}

const isTabActive = (tabName, isActive = false) => {
  if (route.query.paywallTab) return route.query?.paywallTab === tabName
  return isActive
} 


</script>

<template>
  <div id="resizible-wrapper" class="container" @mouseup="endDragging()" :class="{ '!ml-0': paywalls?.length > 0 }">
    <Spinner v-if="loadingStatus || !userloaded" text="Retrieving data..." classes="text-dark-8 mh-60"
      spinner-color="var(--c-dark-8)" spinner-size="15px" />
    <main v-else-if="paywalls?.length > 0">
      <div class="playlist-list md:flex items-stretch justify-between w-full relative">
        <div class="md:pr-6 min-w-full md:min-w-0" :class="{ 'first-step': (resStep == 1) }"
          :style="{ width: `${dividerPosition}%`, 'flex-basis': `${dividerPosition}%` }">
          <PaywallNav :paywallActive="paywallActive" :current-paywall="currentPaywall" />
        </div>

        <div class="!hidden md:!block aside-divider" :style="{
          left: `${dividerPosition}%`
        }" @mousedown="startDragging()">
        </div>
        <div class="paywall_right min-w-full md:min-w-0" :class="{ 'second-step': (resStep == 2), 'third-step': (resStep == 3) }"
          :style="{ width: `${100 - dividerPosition}%`, 'flex-basis': 100 - `${dividerPosition}%` }">
          <PaywallHeader :current-paywall="currentPaywall" @paywall-toggle="onPaywallTogge" />
          <div class="flex flex-col md:grid grid-cols-[2fr_1fr] md:px-6 gap-6">
            <div class="min-w-0 order-2 md:order-1">
              <h6 class="text-surface-8 text-sm font-medium">Source</h6>
              <stream-source-dropdown @on-show="onSourceDropdownShow" @on-hide="onSourceDropdownHide"
                @on-source-selected="onSourceStreamSelected" :dropRight="false" :key="currentPaywall?.id"
                :selectedStream="currentPaywall?.source" :showPlaylist="true" />
              <div class="flex my-6">
                <div class="basis-1/3">
                  <h6 class="text-surface-8 text-tiny font-medium !mb-1">Payment Type</h6>
                  <p>Pay Per View</p>
                </div>
                <div class="basis-1/3">
                  <h6 class="text-surface-8 text-tiny font-medium !mb-1">Currency</h6>
                  <p>{{ currentPaywall?.currency.toUpperCase() }}</p>
                </div>
                <div class="flex flex-wrap basis-1/3 ">
                  <h6 class="text-surface-8 text-tiny font-medium basis-full !mb-1">Amount</h6>
                  <p v-if="!showEditPriceInput">{{ currentPaywall?.price }} </p>
                  <FormButton v-if="!showEditPriceInput" class="-mt-1 !ml-1" @click="onPriceEdit" type="link" size="sm"
                    isIcon>
                    <icon-base>
                      <icon-edit />
                    </icon-base>
                  </FormButton>
                  <FormInput v-if="showEditPriceInput" @change="onPriceUpdate" v-model="paywallPrice">
                  </FormInput>
                </div>
              </div>
              <h6 class="text-surface-8 flex w-full text-sm !mb-1">
                Protected Player Page
                <tooltip wrapperClass="!ml-2"
                  content="Your audiences can acess this page to register, pay and watch your content.">
                  <icon-base>
                    <icon-info-small />
                  </icon-base>
                </tooltip>
              </h6>
              <div class="flex relative mb-6">
                <FormInput customClasses="!pr-16 truncate"
                  :modelValue="currentPaywall?._streamUrl.replace('_disabled', '')"
                  @click="clipboardCopy(currentPaywall._streamUrl.replace('_disabled', ''))" readonly />
                <FormButton class="absolute z-10 right-8" size="sm" type="link" isIcon
                  @click="clipboardCopy(currentPaywall?._streamUrl.replace('_disabled', ''))">
                  <icon-base>
                    <icon-copy />
                  </icon-base>
                </FormButton>
                <FormButton class="absolute z-10 right-2" size="sm" type="link" isIcon
                  :href="currentPaywall?._streamUrl.replace('_disabled', '')" target="_blank">
                  <icon-base>
                    <icon-link-external />
                  </icon-base>
                </FormButton>
              </div>
              <template v-if="currentPaywall?.source?.type === 'live' || currentPaywall?.source?.streams">
                <h6 class="text-surface-8 flex item-center w-full text-sm mt-6 !mb-1">
                  Protected Player iframe
                  <tooltip wrapperClass="!ml-2"
                    content="Toggling this on, will convert the player to a protected player.">
                    <icon-base>
                      <icon-info-small />
                    </icon-base>
                  </tooltip>
                  <FormSwitch class="ml-auto" v-if="currentPaywall.enabled" v-model="popupAuth"
                    @change="togglePopupAuthAlert" />
                </h6>
                <div class="flex relative">
                  <FormInput customClasses="!pr-10 truncate" :modelValue="paywallIframeCode"
                    @click="clipboardCopy(paywallIframeCode)" readonly />
                  <FormButton class="absolute z-10 right-2" size="sm" type="link" isIcon
                    @click="clipboardCopy(paywallIframeCode)">
                    <icon-base>
                      <icon-copy />
                    </icon-base>
                  </FormButton>
                </div>
                <div class="flex gap-x-4 !mt-2 mb-6">
                  <FormRadio class="m-0" label="responsive" v-model="iframe" inputValue="responsive" />
                  <FormRadio class="m-0" label="fixed" v-model="iframe" inputValue="fixed" />
                </div>
              </template>
              <hr class="my-6">
              <h4>Generate temporary access link</h4>
              <p class="text-tiny text-surface-8">Temporary access link will be expired based on your selection and
                require no sign up or login from
                users</p>
              <template v-if="showTempLinkDatePicker">
                  <label class="mt-6">Expiration date</label>
                  <VueDatePicker placeholder="Select date time" v-model="tempLinkDateTime" utc
                    :min-date="tempLinkMinDateTime" :max-date="tempLinkMaxDateTime" @focus="setRangesTempLinkDateTime"
                    inputClassName="text-tiny font-normal font-[Inter] h-8 bg-surface-3 hover:bg-surface-4 disabled:text-surface-6 disabled:bg-surface-2 hover:disabled:bg-surface-2 rounded border-none !px-3">
                  </VueDatePicker>
                </template>
              <div class="flex gap-x-3 !mt-2">
                <FormButton v-show="!showTempLinkDatePicker" label="Generate"
                  @click="showTempLinkDatePicker = true" />
                <FormButton v-show="showTempLinkDatePicker" :label="tempLinkProcessing ? 'Generating' : 'Generate Link'"
                  :disabled="!tempLinkDateTime" @click="generateTempLink" />
                <FormButton v-show="showTempLinkDatePicker" type="danger" label="Cancel" @click="
                  showTempLinkDatePicker = false;
                tempLinkDateTime = null;" />
              </div>
              <ul class="mt-6">
                <li v-for="(item, idx) in tempLinksFiltered()" :key="idx">
                  <h5 class="text-sm text-surface-8 !mb-1">Expiration date - {{ humanDate(item.expireAt) }}</h5>
                  <div class="flex relative mb-6">
                    <FormInput customClasses="!pr-16 truncate" :modelValue="item.url" @click="clipboardCopy(item.url)"
                      readonly />
                    <FormButton class="absolute z-10 right-2" size="sm" type="link" isIcon
                      @click="clipboardCopy(item.url)">
                      <icon-base>
                        <icon-copy />
                      </icon-base>
                    </FormButton>
                  </div>
                </li>
              </ul>
            </div>
            <div class="grid grid-cols-2 md:grid-cols-1 gap-4 self-start min-w-0 order-1 md:order-2 w-full">
              <div class="flex flex-col !border !border-surface-3 rounded-xl !p-4">
                <h6 class="text-surface-8 text-tiny font-medium">Total Payments</h6>
                <p class="text-xl">{{ totalPayments }}</p>
              </div>

              <div class="flex flex-col !border !border-surface-3 rounded-xl !p-4">
                <h6 class="text-surface-8 text-tiny font-medium">Total Refunds</h6>
                <p class="text-xl">{{ totalRefunds }}</p>
              </div>

              <div class="col-span-2 md:col-span-1 flex flex-col !border !border-surface-3 rounded-xl !p-4 items-start">
                <h6 class="text-surface-8 text-tiny font-medium flex items-center">
                  Total Earnings
                  <Badge class="!ml-2" v-if="paywallStripeCommission">Commission: {{ paywallStripeCommission }}%</Badge>
                </h6>
                <p class="text-xl">{{ totalEarnings }}</p>
              </div>
            </div>
          </div>
          <div class="md:px-6">
            <hr class="my-6">
            <TabGroup class="w-full" type="lines">
              <TabItem type="line" @click="changeRouteQuery('interface')" label="interface"
                :isActive="isTabActive('interface', true)" />
              <TabItem type="line" @click="changeRouteQuery('settings')" label="settings"
                :isActive="isTabActive('settings')" />
              <TabItem type="line" @click="changeRouteQuery('transactions')" label="transactions"
                :isActive="isTabActive('transactions')" />
              <TabItem type="line" @click="changeRouteQuery('customers')" label="customers"
                :isActive="isTabActive('customers')" />
              <TabItem type="line" @click="changeRouteQuery('email_access')" label="email access grant"
                :isActive="isTabActive('email_access')" />
            </TabGroup>
            <div class="pt-6" v-if="currentPaywall">
              <PaywallInterface v-if="isTabActive('interface', true)" :current-paywall="currentPaywall" />
              <PaywallSettings v-if="isTabActive('settings')" :current-paywall="currentPaywall" />
              <PaywallTransactions v-if="isTabActive('transactions')" :paywallStreamId="currentPaywall.id"
                :key="currentPaywall.id" />
              <PaywallCustomers v-if="isTabActive('customers')" :paywallStreamId="currentPaywall.id"
                :key="currentPaywall.id" />
              <PaywallEmailAccess ref="paywallEmailAccessComponent" v-if="isTabActive('email_access')"
                :current-paywall="currentPaywall" />
            </div>
          </div>
        </div>
      </div>
    </main>
    <PaywallPlaceholder v-else :onStripeConnectClick="onStripeConnectClick"
      :onStripeConnectCancelClick="onStripeConnectCancelClick" :stripeConnectProcessing="stripeConnectProcessing"
      :stripeOauthLink="stripeOauthLink" :stripeConnectLinkError="stripeConnectLinkError"
      :paywallActive="paywallActive" />

    <add-paywall-email-access :paywall="currentPaywallIndex" @email-created="emailAccessCreated" />

    <add-paywall-modal @paywall-created="paywallCreated" />
    <demo-video-modal videoid="1" />
    <confirm-modal modalId="delete-paywall" message="Are you sure you want to delete paywall ?" modal-type="danger"
      @modal-confirm="onPaywallRemove" />
    <confirm-modal modalId="unlink-stripe" message="Unlink Stripe Account"
      body="Are you certain about this action? Continuing will result in the loss of all paywalls and their associated information."
      okText="Confirm" cancelText="Cancel" modal-type="danger" @modal-confirm="onUnlinkStripe" />
    <confirm-modal modalId="toggle-popupauth" message="Source Conflict"
      body="Activating the paywall for this player will deactivate all other paywalls with this stream source. Are you sure you want to continue?"
      okText="Proceed" cancelText="Cancel" @modal-confirm="togglePopupAuth" @modal-cancel="togglePopupAuthCancel" />
  </div>
</template>

<style>
.stream-source-menu {
  background: linear-gradient(0deg, #232D4D, #232D4D), linear-gradient(0deg, #343E61, #343E61);
}
</style>
