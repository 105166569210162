<script setup>
import { computed, ref, onMounted, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import _ from 'lodash';
import utils from '@/utils';
import { timeAgo, date } from '@/filtersNew'
import Spinner from "@/components/ui/Spinner.vue"
import StreamService from '@/services/StreamService';
import VodVideoModal from '@/components/modals/VODVideoModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import DvrArchiveVideoPreviewModal from '@/components/modals/DvrArchiveVideoPreviewModal.vue';
import prettyMilliseconds from 'pretty-ms'
import FormSwitch from '@/components/Atoms/FormSwitch.vue';
import FormButton from '@/components/Atoms/FormButton.vue';
import IconBase from '@/components/icon/IconBase.vue';
import IconDownload from '@/components/icon/IconDownload.vue';
import IconPlay from '@/components/icon/IconPlay.vue';
import IconGear from '@/components/icon/IconGear.vue';
import IconBin from '@/components/icon/IconBin.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import Alert from '../../components/Atoms/Alert.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	},
	trialSubscription: {
		type: Boolean,
		required: false,
	}
})

const emit = defineEmits({
	'stream-updated': (value) => { return value },
})

const vodEpisodes = ref([])
const hasDvrUnits = ref(false)
const dvrStatusProcessing = ref(false)
const selectedVideo = ref(null)

const loadingStatus = computed(() => store.getters['Ui/loadingStatus'])

onMounted(async () => {
	store.dispatch('Ui/setLoadingStatus', true);
	hasDvrUnits.value = props.stream.dvrUnits > 0;
	try {
		vodEpisodes.value = await StreamService.getStreamRecordedFiles(
			props.stream._id
		);
	} catch (e) {
		notify({
			group: 'error',
			text: 'could not retreive saved vod(s)'
		});
	}
	vodEpisodes.value = _.map(vodEpisodes.value, vodEpisode => {
		const ep = _.assign({}, vodEpisode, {
			startTimeDate: new Date(vodEpisode.startTime),
			endTimeDate: new Date(vodEpisode.endTime)
		});
		ep.fileName = `${timeAgo(
			ep.startTimeDate
		)}`;
		ep.creationTime = ep.startTimeDate;
		ep.embedUrl = getEpisodeEmbedUrl(ep);
		ep.iframeSnippet = getEpisodeIframeSnippet(ep);
		ep.removing = false;
		ep.statusProcessing = false;
		return ep;

	});

	vodEpisodes.value = _.filter(vodEpisodes.value, (el) => {
		return Math.round((new Date().getTime() - new Date((el.endTimeDate || el.creationTime))) / 1000) < 86400 * 4;
	}) || [];

	store.dispatch('Ui/setLoadingStatus', false);
})

const toggleRecordingStatus = async () => {
	const dvrEnabled = props.stream.dvrUnits > 0;
	const updatedDvrUnits = dvrEnabled ? 0 : 1;
	dvrStatusProcessing.value = true;
	try {
		if (dvrEnabled) {
			await StreamService.disableStreamDvr(props.stream._id);
		} else {
			await StreamService.enableStreamDvr(props.stream._id);
		}
		emit('stream-updated', { dvrUnits: updatedDvrUnits });
	} catch (e) {
		notify({
			group: 'error',
			text: "couldn't change recording status. try again later"
		});
	}
	dvrStatusProcessing.value = false;
}

const playPreview = (video) => {
	if (!video) return;

	const recordMeta = parseDvrRecordMetadata(video);
	if (!recordMeta) {
		notify({
			group: 'error',
			text: 'Preview failed: invalid record metadata'
		})
		return
	}

	selectedVideo.value = { ...video, ...recordMeta };
	setTimeout(() => {
		root.$emit('bv::show::modal', 'modal-dvr-archive-video-preview');
	}, 100);
}

const onCustomRangeDownload = (customRange) => {
	redirectDownloadUrl(selectedVideo.value, customRange);
}

const downloadDvrArchive = (video) => {
	window.location.href = video.downloadUrl.replace('.castr.io', '.global.ssl.fastly.net');
}

const redirectDownloadUrl = (video, { from, duration }) => {
	const hostname = video.server.replace('.castr.io', '.global.ssl.fastly.net');
	const url = `https://${hostname}/store/${props.stream.key}/archive-${from}-${duration}.mp4`;
	window.location.href = url;
}

const requestVideoDelete = (video) => {
	if (!video) return;
	selectedVideo.value = video;
	setTimeout(() => {
		root.$emit('bv::show::modal', 'modal-delete-recorded-video');
	}, 100);
}

const parseDvrRecordMetadata = (video) => {
	// TODO: return this info from API
	const downloadUrl = video.downloadUrl;
	const { hostname: storageServer } = new URL(downloadUrl);
	const match = downloadUrl.match(/archive-(\d+)-(\d+).mp4/);
	if (!match || !storageServer)
		return;

	return {
		server: storageServer,
		from: parseInt(match[1]),
		duration: parseInt(match[2])
	};
}

const humanTime = (t) => {
	return prettyMilliseconds(t * 1000, { unitCount: 2 })
}

const deleteVideo = async () => {
	const { startTime, downloadUrl } = selectedVideo.value

	const { hostname: storageServer } = new URL(downloadUrl);
	const match = downloadUrl.match(/archive-(\d+)-(\d+).mp4/);
	if (!match || !storageServer) {
		notify({
			group: 'error',
			text: 'Failed to delete recorded file'
		});
	}

	const vidIndex = _.findIndex(vodEpisodes.value, { startTime });
	if (vidIndex === -1) return;
	vodEpisodes.value = utils.updateArrayItem(
		vodEpisodes.value,
		_.assign({}, vodEpisodes.value[vidIndex], { removing: true }),
		vidIndex
	);
	try {
		const q = {
			streamId: props.stream._id,
			from: match[1],
			duration: match[2],
			abr: false,
			storageServer: storageServer
		};
		await StreamService.deleteStreamTempDvrArchive(q);
	} catch (e) {
		notify({
			group: 'error',
			text: 'Failed to delete recorded file'
		});
		vodEpisodes.value = utils.updateArrayItem(
			vodEpisodes.value,
			_.assign({}, vodEpisodes.value[vidIndex], { removing: false }),
			vidIndex
		);
	}
	setTimeout(() => {
		vodEpisodes.value = utils.removeArrayItem(
			vodEpisodes.value,
			vidIndex
		);
	}, 1000);
}

const getEpisodeEmbedUrl = (episode) => {
	let playerUrl = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${props.stream.key}?playlist=1&`;
	if (episode.watchId) {
		playerUrl += `watchId=${episode.watchId}`;
	} else if (episode.episodeId) {
		playerUrl += `episodeId=${episode.episodeId}`;
	}
	return playerUrl;
}

const getEpisodeIframeSnippet = (episode) => {
	let iframeSrc = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${props.stream.key}?playlist=1&`;
	if (episode.watchId) {
		iframeSrc += `watchId=${episode.watchId}`;
	} else if (episode.episodeId) {
		iframeSrc += `episodeId=${episode.episodeId}`;
	}
	return `<iframe src="${iframeSrc}" width="590" height="430" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`;
}

const dateFormated = (item) => {
	return date(item)
}

</script>

<template>
	<div class="view-wrapper">
		<section v-if="!stream.dvrReady">
			<div class="flex items-start justify-between !flex-wrap sm:!flex-nowrap">
				<h3 class="text-base text-surface-7 text-nowrap">Record streams</h3>
				<FormSwitch class="!ml-3" switch disabled />
				<Alert>
					<p class="">To ensure stable recording, follow our <a target="_blank" class="underline"
							href="https://docs.castr.com/en/articles/4825093-recommended-settings-for-encoders">recommended encoder
							settings</a>. All your streams are recorded and kept here for 3 days.</p>
				</Alert>
			</div>
			<div class="text-center mt-6">
				<p class="text-surface-9 text-base font-medium !mb-2">
					Upgrade to use recordings
				</p>
				<p class="text-sm text-surface-7 !mb-4">This feature is not included in your subscription plan. Upgrade to
					access
					this feature.</p>
				<FormButton to="/subscribe">Upgrade Now</FormButton>
			</div>
		</section>
		<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mt-5" spinner-color="var(--c-dark-8)"
			spinner-size="15px" />
		<template v-else-if="!trialSubscription">
			<section class="flex items-start justify-between !flex-wrap sm:!flex-nowrap mb-6">
				<h3 class="text-base text-nowrap">Record streams</h3>
				<FormSwitch @change="toggleRecordingStatus" v-model="hasDvrUnits" class="!ml-3" />
				<Alert>
					<p class="mb-0">To ensure stable recording, follow our <a target="_blank" class="underline"
							href="https://docs.castr.com/en/articles/4825093-recommended-settings-for-encoders">recommended encoder
							settings</a>. All your streams are recorded and kept here for 3 days.</p>
				</Alert>
			</section>

			<section v-if="!vodEpisodes.length">
				<temlate class="text-center" v-if="stream.dvrUnits > 0">
					<p class="text-lg !mb-2 mt-6">Nothing recorded yet</p>
					<p class="text-surface-7">Your streams will be saved here</p>
				</temlate>
				<template class="text-center" v-else>
					<p class="text-lg !mb-2 mt-6">Recording is disabled </p>
					<p class="text-surface-7">Enabling will allow us to save your former broadcasts to list here</p>
				</template>
			</section>
			<section v-else>
				<ul class="flex flex-col">
					<li class="flex text-sm">
						<h5 class="w-4/12">
							Recorded Clips
						</h5>
						<h5 class="w-4/12 md:w-2/12">
							Duration
						</h5>
						<h5 class="w-4/12 md:w-2/12">
							Recorded at
						</h5>
					</li>
					<li v-for="video in vodEpisodes" :key="video.starTime" class="flex text-tiny items-center !py-2">
						<p class="text-dark-9 w-4/12">
							{{ video.fileName }}
						</p>
						<p class="text-dark-9 w-4/12 md:w-2/12">
							{{ video.duration ? humanTime(video.duration) : 'Unknown' }}
						</p>
						<p class="text-dark-9 w-4/12 md:w-3/12">
							{{ dateFormated(video.startTimeDate) }}
						</p>
						<p class="ml-auto">
							<div v-if="video.removing" class="text-center">
								<code>removing ..</code>
							</div>
							<div class="flex gap-x-2" v-else>
								<DropDownMenu v-if="video.downloadUrl" size="sm" type="secondary" listWidth="w-[220px]" position="mobile-right" :disabled="video.exportProcessin"
									ref="downloadDropdown" customClasses="inline-flex items-center justify-center !rounded-md leading-none disabled:bg-surface-5 disabled:ring-surface-5 disabled:text-surface-8 h-7 w-7 text-surface-text-1 ring-1 ring-surface-4 bg-transparent hover:bg-surface-2 hover:text-white hover:ring-surface-6">
									<template #toggle-button>
										<icon-base>
											<icon-download />
										</icon-base>
									</template>
									<template #menu-items>
										<DropDownMenuItem @click="downloadDvrArchive(video)">
											<icon-base class="!mr-2">
												<icon-download />
											</icon-base>
											Download whole video
										</DropDownMenuItem>
										<DropDownMenuItem @click="playPreview(video)">
											<icon-base class="!mr-2">
												<icon-gear />
											</icon-base>
											Select a range and download
										</DropDownMenuItem>
									</template>
								</DropDownMenu>
								<FormButton type="secondary" size="sm" isIcon @click="playPreview(video)">
									<icon-base class="">
										<icon-play />
									</icon-base>
								</FormButton>
								<FormButton v-if="video.removable" isIcon @click="requestVideoDelete(video)" type="danger" size="sm">
									<icon-base class="">
										<icon-bin />
									</icon-base>
								</FormButton>
							</div>
						</p>
					</li>
				</ul>
			</section>
		</template>

		<vod-video-modal v-if="selectedVideo" :stream="stream" :video="selectedVideo" />
		<confirm-modal modal-type="danger" modal-id="modal-delete-recorded-video"
			message="Do you want to delete this recorded file?" @modal-confirm="deleteVideo" />

		<dvr-archive-video-preview-modal v-if="selectedVideo"
			@request-dvr-archive-export-custom-range="onCustomRangeDownload" :stream="stream" :video="selectedVideo" />
	</div>
</template>