<template>
	<div>
		<div class="player-container">
			<!-- <div v-if="!buffered" class="player-loading-indicator">
        <div class="inline-block text-center">
          <i class="fas fa-spinner fa-spin"></i>
          <div>{{ playback ? 'loading' : 'requesting'}} media</div>
        </div>
      </div>
      <div v-if="playback" class="player-controls-wrapper">
        <div class="player-controls">
          <button class="player-control"
                  @click="toggleVolume">
            <i class="fa"
               :class="{ 'fa-volume-down': controls.volume, 'fa-volume-off': !controls.volume }"></i>
          </button>
          <button class="player-control"
                  @click="toggleFullscreen">
            <i class="fa"
               :class="{ 'fa-compress': controls.fullscreen, 'fa-expand': !controls.fullscreen }"></i>
          </button>
        </div>
      </div> -->
			<div id="hls-player" />
			<!-- <video id="player" :class="{'container-size': stream.type === 'ipcam' || stream.type === 'scheduled' }"/> -->
		</div>
	</div>
</template>

<script>
import _ from 'lodash';

const playerContainerId = '#hls-player';

export default {
	name: 'StreamPlayerHlsDynamic',
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		streamUrl: {
			type: String,
			required: true
		},
		autoplay: {
			type: Boolean,
			default: true
		},
		frameless: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			playerInstance: null,
			bufferWaitTimeout: null,
			playback: false,
			buffered: true,
			controls: {
				volume: false,
				fullscreen: false
			},
			scopeAlive: true,
			flashDisabled: false,
			videoDOM: null,
			videoPlayer: null
		};
	},
	mounted() {
		console.log('HLS player started', this.streamUrl);
		this.initPlayerConf();
		setTimeout(this.initPlayback.bind(this), 1000);
	},
	destroyed() {
		this.scopeAlive = false;
		this.stopPlayback();
		console.log('PLAYER SCOPE DESTROYED - VIDEO SHOULD STOP');
	},
	methods: {
		initPlayerConf() {
			const playerConfig = {
				key: '731cb904-5208-47db-9891-5da3131270f1',
				// analytics: {
				//   key: 'bf60fe72-02ec-40bd-8670-4b231f656607',
				//   videoId: `mse-${this.stream.key}`,
				//   title: `mse-${this.stream.key}`
				// },
				adaptation: {
					preload: false,
					desktop: {
						preload: false
					},
					mobile: {
						preload: false
					}
				},
				playback: {
					muted: true,
					autoplay: this.autoplay,
					seeking: false
				},
				style: {
					width: '100%',
					controls: false
				},
				skin: {
					screenLogoImage: ''
				},
				logs: { bitmovin: false }
			};

			if (this.frameless) {
				playerConfig.ui = false;
			}

			const playerWrapper = document.querySelector(playerContainerId);
			// eslint-disable-next-line no-undef
			const player = new bitmovin.player.Player(playerWrapper, playerConfig);
			this.playerInstance = player;

			let playedFirstChunk = false;
			player.on('playing', (ev) => {
				if (playedFirstChunk) return;
				playedFirstChunk = true;
				this.$emit('playing', ev, this.stream.key, this.playerInstance);
			});
		},
		async initPlayback() {
			this.playerInstance.innerHTML = '';
			// let streamSource = this.getStreamURL()
			const streamSource = this.streamUrl;
			// console.log('playing', streamSource)

			console.log('prop:streamUrl', this.streamUrl);
			// - defining player playback config
			const playbackSource = { hls: streamSource };
			try {
				await this.playerInstance.load(playbackSource);
				this.onPlayerLoad();
			} catch(e) {
				this.onPlayerLoadError(e);
			}
		},
		onPlayerLoad () {
			// console.log('playback started')
			this.$emit('playback-started', this.stream.key, this.playerInstance);
		},
		onPlayerLoadError (err) {
			console.log('playback error', err);
		},
		async stopPlayback(cb) {
			const unloaded = () => {
				this.playerInstance.innerHTML = '';
				if (cb) cb();
			};

			this.playerInstance.pause();
			try {
				await this.playerInstance.destroy();
				unloaded();
			} catch(e) {
				unloaded(e);
			}
		},
		getStreamURL() {
			const { region, key } = this.stream;
			const hostname = region.hostname;
			const proto = 'https';
			// if (region.hostnameCDN) {
			//   proto = 'https'
			//   hostname = region.hostnameCDN
			// }

			let streamName = key;
			if (this.stream.pullUrl) {
				streamName += '.stream';
			}

			let prefixPath = _.get(region, 'config.prefixPath');
			if (this.stream.type === 'live') {
				prefixPath = '/edge';
			}

			if (prefixPath) {
				streamName = prefixPath + '/' + streamName;
			}

			streamName = _.replace(streamName, /^\//g, '');

			// const playbackUrl = `${proto}://${hostname}:1935/${streamName}/playlist.m3u8`;
			const playbackUrl = `${proto}://${hostname}/${streamName}/index.m3u8`;
			console.log('url', playbackUrl);
			return playbackUrl;
		}
	}
};
</script>

<style scoped>
.player-controls-wrapper {
  display: flex;
  align-items: flex-end;
  height: inherit;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.player-controls {
  height: 40px;
  width: 100%;
  background-color: rgb(32, 41, 64);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 3px;
  transition: all 0.2s ease-in-out;
}
.player-controls-wrapper:hover .player-controls {
  background-color: rgb(31, 41, 66);
}
.player-control {
  border: none;
  color: #ffffff;
  background-color: transparent;
  padding: 3px 14px;
  border-radius: 4px;
  outline: none !important;
  font-size: 18px;
  cursor: pointer;
}
.player-control:hover {
  background-color: #000;
}

#preview-player {
  margin: 10px 0;
  height: 100%;
}

#preview-player [data-player] {
  max-width: 100%;
}

#preview-player [data-no-op-msg] {
  font-size: 1.15em;
  padding: 0 10%;
  text-align: center;
}

#preview-player .player-poster {
  background-size: 105% auto;
  border-radius: 4px;
}
#preview-player .container[data-container] {
  max-width: 100%;
}

.player-container {
  border: none;
  height: inherit;
  position: relative;
  overflow: hidden;
}
#player {
  position: relative;
  width: 100%;
  min-height: 236px;
  max-height: 236px;
}
#player.container-size {
  max-width: unset;
  max-height: 100%;
}
.mbr-controls {
  display: none;
}

div.player-error-screen__content[data-error-screen] {
  margin-top: 0;
  font-size: 12px;
}
div.player-error-screen__title[data-error-screen] {
  font-weight: 400;
  line-height: auto;
}
div.player-error-screen__message[data-error-screen] {
  text-transform: lowercase;
}
div.player-error-screen__code[data-error-screen] {
  display: none;
}
.player-loading-indicator {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: inherit;
  font-size: 11.5px;
}
.player-loading-indicator .fa-spin {
  font-size: 16px;
  margin-bottom: 6px;
}
</style>
