const activateIntercom = (userObject) => {
	const isCastrUser = userObject._id && userObject.email && userObject.name;
	if (window._learnq && isCastrUser) {
		window._learnq.push(['identify', {
			'$email': userObject.email
		}]);
	}

	if (window.Autopilot && isCastrUser) {
		window.Autopilot.run("associate", {
			Email: userObject.email,
			FirstName: userObject.name,
			custom: {
				"string--Logged--In": "true"
			}
		});
	}

	if (window.Intercom && isCastrUser) {
		const identityObject = {
			name: userObject.name,
			email: userObject.email,
			user_id: userObject._id,
			created_at: Math.round(new Date(userObject.joinDate || new Date()).getTime() / 1000)
		};

		if(userObject.cmsEntityId && userObject.cmsEntityId > 139398) {
			// use cmdId as user id for intercom 
			identityObject.user_id = userObject.cmsEntityId;
		}
		if (userObject.parentAccount && identityObject.email.includes('castr.io')) {
			identityObject.unsubscribed_from_emails = true
			console.log('identityObject', JSON.stringify({ userObject, identityObject }));
		}

		window.Intercom('boot', {
			...identityObject,
			app_id: process.env.VUE_APP_INTERCOM_APP_ID
		});
	}

	if (window.delighted && isCastrUser) {
		try {
			const sub = (userObject?.subscriptions ?? []).find((sub) => sub.category && sub.category.includes('live'));
			const opt = {
				email: userObject.email,
				name: userObject.name,
				signed_up: userObject.joinDate,
			}
			if (sub) {
				opt.properties = {
					plan: sub.package?.name ?? 'n/a',
					purchase_date: sub.cstart
				}
				if (!(sub.package?.name ?? '').toLowerCase().includes('trial')) {
					delighted.survey(opt)
				}
			}

		} catch(err) {
			console.error('delighted lib init failed')
		}
	}
	
};

const activateSentry = () => {
	if (window.Sentry) {
		Sentry.init({ dsn: process.env.VUE_APP_SENTRY_DSN });
	}
};

export {
	activateIntercom,
	activateSentry
};
