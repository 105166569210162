<template>
  <div class="view">
    <div class="row">
      <div class="col-sm-5">
        <h1 class="page-title text-500 mb-4">Change Password</h1>
        <div>
          <!-- error placeholder -->
          <b-alert v-if="error"
                   show
                   variant="danger"
                   class="left inline"
                   >{{error.message}}</b-alert>

          <div class="field-container">
            <label class="text-s-s">Current Password</label>
            <b-input v-model="form.currentPassword"
                   type="password" 
                   size="md"
                   class="mb-2"
                   :class="formErrors.currentPassword ? 'error' : ''" 
                   placeholder="current password"
                   @keypress="onInputChange('currentPassword')" />

            <p v-show="formErrors.currentPassword" class="color-danger">specify current password</p>
          </div>

          <div class="field-container">
            <label class="text-s-s">New Password</label>
            <b-input v-model="form.newPassword"
                   type="password" 
                   class="mb-2" 
                   :class="formErrors.newPassword ? 'error' : ''" 
                   size="md"
                   placeholder="new password"
                   @keypress="onInputChange('newPassword')" />
            
            <p v-show="formErrors.newPassword" class="color-danger">specify new password</p>
          </div>
          
          <div class="field-container">
            <label class="text-s-s">Confirm New Password</label>
            <b-input v-model="form.newPasswordConfirm"
                   type="password" 
                   class="mb-2" 
                   size="md"
                   :class="formErrors.newPasswordConfirm ? 'error' : ''" 
                   placeholder="confirm password"
                   @keypress="onInputChange('newPasswordConfirm')" />
            
            <p v-show="formErrors.newPasswordConfirm" class="color-danger">please confirm new password</p>
          </div>

          <br>

          <b-button v-if="!processing"
                    variant="primary" 
                    size="md"
                    @click="savePassword">Save & Change</b-button>
          
          <b-progress v-if="processing"
                    :value="100" 
                    :max="100" 
                    animated
                    class="w-50"></b-progress>

        </div>
      </div>
      <div class="col-sm-7"></div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
export default {
  name: "ManagePasswordChange",
  mounted() {
    // track event
    window.trackEvent(`Password Settings`)
  },
  data() {
    return {
      error: null,
      processing: false,
      formErrors: {
        currentPassword: false,
        newPassword: false,
        newPasswordConfirm: false
      },
      form: {
        currentPassword: null,
        newPassword: null,
        newPasswordConfirm: null
      },
      onInputChange(prop) {
        this.formErrors[prop] = false;
      }
    };
  },
  methods: {
    async savePassword() {
      this.error = null;
      if (!this.validateForm()) return;

      this.processing = true;
      try {
        await UserService.changePassword(this.form);
        this.$notify({ group: "success", title: "password reset successfully" });
        
        // track event
        window.trackEvent(`Changed account password`)

      } catch (err) {
        this.error = err;
      }

      this.processing = false;
    },
    validateForm() {
      const props = ["currentPassword", "newPassword", "newPasswordConfirm"];

      let valids = 0;
      _.each(props, prop => {
        const val = this.form[prop];
        if (val) valids++;
        this.formErrors[prop] = !val;
      });

      return valids === props.length;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
