<template>
  <div class="">
    <!-- Modal Component -->
    <b-modal
      id="modal-paywall-transaction-details"
      ref="mpdalPaywallTransactionDetails"
      class="modal-platform modal-frameless"
      size="md"
      centered
      body-class="p-4"
      hide-footer
      hide-header
    >
      <b-row>
        <b-col md="12">
          <p class="text-s-s text-dark-6 mt-2 mb-1">ID</p>
          <h4 class="text-s-xl text-500 mb-4">
            {{ txnObject.public_id }}
          </h4>
          <h5 class="text-s-m m-0 text-dark-8 text-500"
            >Transaction made for stream</h5
          >
          <hr class="my-2">
          <p
            class="d-flex align-items-center text-dark-7 mb-4"
          >
            <span>
              {{ txnObject.product_title }}
            </span>
          </p>
          <h5 class="text-s-m m-0 text-dark-8 text-500"
            >Transaction Details</h5
          >
          <hr class="mt-2 mb-0">
          <b-list-group class="list-group-flush mb-4">
            <b-list-group-item
              class="d-flex justify-content-between align-items-center px-0 py-2"
            >
              <span class="text-s-m text-dark-7">Customer</span>
              <span class="text-s-m">{{ txnObject.user.email }}</span>
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center px-0 py-2"
              v-if="txnObject.payments.length > 0"
            >
              <span class="text-s-m text-dark-7">Status</span>
              <span>
                <b-badge
                  v-if="txnObject.payments[0].status === 'paid'"
                  variant="outline-success"
                  class="text-capitalize text-500"
                  pill
                  >{{ txnObject.payments[0].status }}</b-badge
                >
                <b-badge
                  v-else-if="txnObject.payments[0].status === 'refund'"
                  variant="outline-danger"
                  class="text-capitalize text-500"
                  pill
                  >{{ txnObject.payments[0].status }}</b-badge
                >
                <b-badge v-else class="text-500" :variant="txnObject.payments[0].status">{{
                  txnObject.payments[0].status
                }}</b-badge>
              </span>
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center px-0 py-2"
              v-if="txnObject.payments.length > 0"
            >
              <span class="text-s-m text-dark-7">Created Date</span>
              <span class="text-s-m">{{ formatDate(txnObject.payments[0].dattm) }}</span>
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center px-0 py-2"
              v-if="txnObject.started_at"
            >
              <span class="text-s-m text-dark-7">Start Date</span>
              <span class="text-s-m">
                {{ formatDate(txnObject.started_at) }}
              </span>
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center px-0 py-2"
              v-if="txnObject.expires_at"
            >
              <span class="text-s-m text-dark-7">End Date</span>
              <span class="text-s-m">

                {{ formatDate(txnObject.expires_at) }}
              </span>
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center px-0 py-2"
            >
              <span class="text-s-m text-dark-7">Price Type</span>
              <span class="text-s-m">
                {{
                  parseInt(txnObject.price) === 0 ? "Collect emails only" : "Pay Per View"
                }}
              </span>
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center px-0 py-2"
            >
              <span class="text-s-m text-dark-7">Currency</span>
              <span class="text-s-m">
                {{ txnObject.currency }}
              </span>
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center px-0 py-2"
            >
              <span class="text-s-m text-dark-7">Price</span>
              <span class="text-s-m">
                {{ formatPrice(txnObject.price) }}
              </span>
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center px-0 py-2"
              v-if="txnObject.status"
            >
              <span class="text-s-m text-dark-7">Subscription Status</span>
              <span>
                <b-badge
                  v-if="txnObject.status === 'active'"
                  variant="outline-success"
                  class="text-capitalize text-500"
                  pill
                  >{{ txnObject.status }}</b-badge
                >
                <b-badge
                  v-else-if="txnObject.status === 'pending'"
                  variant="dark-3"
                  class="text-capitalize text-500"
                  pill
                  >{{ txnObject.status }}</b-badge
                >
                <b-badge
                  v-else-if="txnObject.status === 'expired'"
                  variant="outline-danger"
                  class="text-capitalize text-500"
                  >{{ txnObject.status }}</b-badge
                >
                <b-badge
                  v-else-if="txnObject.status === 'refund'"
                  variant="outline-danger"
                  class="text-capitalize text-500"
                  pill
                  >{{ txnObject.status }}</b-badge
                >
                <b-badge v-else :variant="txnObject.status">{{
                  txnObject.status
                }}</b-badge>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row class="text-right">
        <b-col>
          <b-button
            variant="dark-6"
            size="md"
            v-if="
              txnObject.payments.length > 0 &&
              txnObject.payments[0].status === 'paid'
            "
            @click="refundPayment"
          >
            <Spinner v-if="refundProcessing" text="" classes="text-dark-8 mt-2" spinner-color="var(--c-dark-8)" spinner-size="15px" />
            Refund
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Spinner from "@/components/ui/Spinner.vue";
import PaywallService from "@/services/PaywallService";

export default {
  name: "PaywallTransactionDetailsModal",
  components: {
    Spinner,
  },
  props: {
    txnObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
      active: false,
      refundProcessing: false,
    };
  },
  computed: {},
  mounted() {
    this.$refs.mpdalPaywallTransactionDetails.$on("shown", this.onInit);
    this.$refs.mpdalPaywallTransactionDetails.$on("hide", this.onDismiss);
  },
  methods: {
    async refundPayment() {
      this.refundProcessing = true;
      try {
        await PaywallService.refundPayment(this.txnObject.payments[0].id);

        // TODO: emit here without mutation
        this.txnObject.status = "refund";
        this.txnObject.payments[0].status = "refund";
        this.$notify({
          group: "success",
          text: "Payment refunded",
        });
      } catch (err) {
        this.$notify({
          group: "error",
          text: err.message || "refund payment failed",
        });
        console.error("refund payemnet failed", err);
      }
      this.refundProcessing = false;
    },
    onInit() {},
    onDismiss() {},
    onConfirm() {
      this.$emit("modal-confirm");
      this.dismiss();
    },
    dismiss() {
      this.$refs.mpdalPaywallTransactionDetails.hide();
      // lazy clear
      setTimeout(() => {
        this.processing = false;
      }, 1000);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDate(time) {
      return moment(new Date(time)).format("YYYY-MM-DD hh:mm:ss");
    },
  },
  beforeDestroy() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
