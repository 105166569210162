<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useNotification } from "@kyvg/vue3-notification"
import FormButton from '../Atoms/FormButton.vue';
import SubscriptionService from '@/services/SubscriptionService';

const store = useStore()

const error = ref(null)

const isLoading = ref(false)
const { notify } = useNotification()

const activateTrial = async () => {
	try {
		isLoading.value = true
		await SubscriptionService.requestTrial('live');
		// update vuex state
		await store.dispatch('User/loadUser', null, { root: true });
		notify({
			group: 'success',
			text: `All in One Stream trial activated successfully`
		});
	} catch (e) {
		error.value = e;
		return;
	} finally {
		isLoading.value = false
	}
}
</script>

<template>
	<section>
		<div class="bg-surface-3 !p-4 my-6">
			<p>You do not have an active all in one subscription. </p>
			<p class="text-sm text-surface-8">Would you like to activate our 7 days trial ?</p>
		</div>
		<div class="!px-4">
			<FormButton :isLoading="isLoading" class=" w-full text-center justify-center" label="Activate trial"
				@click="activateTrial" />
			<FormButton class="text-center justify-center w-full !my-3" type="link" to="/subscribe?category=live">
				Want more options?
			</FormButton>
			<FormButton class="w-full text-center justify-center" type="secondary" label="Close" @click="toggleSidebar" />
		</div>
	</section>
</template>
