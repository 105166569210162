<template>
  <div class="view">
    <div class="row">
      <div class="col-sm-5">
        <h1 class="page-title text-500 mb-4">API Access Tokens</h1>
      </div>
      <div class="col-sm-7"></div>
    </div>
    <main>
      <section class="text-center mt-200" v-if="!isValidPlan">
        <h2 class="text-s-m text-dark-9 text-500 mb-2">Upgrade required</h2>
        <p class="text-s-s text-dark-7 text-normal col-lg-4 mx-auto mb-4">API Access Tokens are not available in your current plan. Please <a class="text-underline text-dark-7" target="_blank" href="/app/subscribe">upgrade</a> to enable token generation.</p>
        <div style="max-width:200px" class="d-flex flex-column mx-auto">
          <a href="/app/subscribe">
            <b-button
              variant="primary"
              size="md"
            >
              Upgrade now
            </b-button>
          </a>
        </div>
      </section> 
      
      <div v-else>
        <div class="row">
          <div class="col-sm-12">
            <p class="text-s-s text-dark-7 text-normal mb-4">
              Access tokens are used to authenticate API request. Tokens are environment-specific and can have different permissions based on the product you’re using. Learn more in our guide.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-4">
            <div id="create-token-tooltip" class="d-flex">
              <b-button variant="primary" size="md" @click="createToken" :disabled="accessTokens.length">Create API Token
              </b-button>
              <b-tooltip v-if="accessTokens.length" triggers="hover" target="create-token-tooltip">
                Only one token access can created at a time. Please delete the existing token access to generate a new one.
              </b-tooltip>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <b-table 
              responsive
              class="main-table"
              thead-class="text-dark-6" 
              :items="accessTokens" 
              :fields="fields"
              :busy="loading"
              sort-icon-left
              show-empty>
              <template #cell(actions)="row">
                <b-button @click="editAccessToken(row.item)" size="sm" variant="outline-secondary" >
                  Edit
                </b-button>
                <b-button @click="deleteAccessToken(row.item)" size="sm" class="p-1 ml-2" variant="outline-danger">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="10" cy="4.375" rx="6.25" ry="1.875" stroke="currentColor" stroke-width="1.5" />
                    <path
                      d="M16.25 4.375L14.6875 16.25C14.25 17.5 12.5 18.125 10 18.125C7.5 18.125 5.8125 17.5 5.3125 16.25L3.75 4.375"
                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </main>
  </div>
  <create-access-token-modal @token-created="onTokenCreated"/>
  <edit-access-token-modal :access-token="accessToken" @token-dismiss="onTokenDismiss" @token-edited="onTokenEdited"/>
  <delete-access-token-modal :access-token="accessToken" @token-deleted="onTokenDeleted"/>
</template>

<script>
import { mapGetters } from 'vuex'

import CreateAccessTokenModal from '@/components/modals/CreateAccessTokenModal.vue'
import EditAccessTokenModal from '@/components/modals/EditAccessTokenModal.vue'
import DeleteAccessTokenModal from '@/components/modals/DeleteAccessTokenModal.vue'
import AccessTokenService from '@/services/AccessTokenService';

export default {
  name: "ManageApi",
  components: {
    CreateAccessTokenModal,
    EditAccessTokenModal,
    DeleteAccessTokenModal,
    AccessTokenService
  },
  data() {
    return {
      error: null,
      processing: false,
      onInputChange(prop) {
        this.formErrors[prop] = false;
      },
      accessTokens: [],
      accessToken: {},
      fields: [
				{ key: 'name', label: 'Token Name', class: 'text-s-s' },
				{ key: 'accessId', label: 'Access ID', class: 'text-s-s' },
				{ key: 'creationTime', label: 'Created', class: 'text-s-s' },
        { key: 'actions', label: '' }
			],
    };
  },
  computed: {
    ...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			subscriptions: "User/subscriptions",
			v2ApiKey: "User/v2ApiKey",
		}),
    isValidPlan() {
      const validPlans = [
        '602cc8de03afd67aa9831cf1',
        '602cc8a503afd67aa9831cf0',
        '602cc81503afd67aa9831cee',
        '602cc85e03afd67aa9831cef',
        '6361ecd609950a6a642540be',
        '6361ecd609950a6a642540bd',
        '6361ecd609950a6a642540bb',
        '6361ecd609950a6a642540bc',
        '6435028a40771d569f1e98ee',
        '6435041040771d569f1e98f8',
        '643502dd40771d569f1e98f0',
        '6435044340771d569f1e98fa'
      ]
      const AIOPlan = this.subscriptions.find(item => item.enabled && item.category === 'live')
      return AIOPlan && this.v2ApiKey
    }
  },
  async mounted() {
    this.accessTokens = await AccessTokenService.getAccessTokens()
  },
  methods: {
    async createToken() {
      this.$root.$emit('bv::show::modal', 'modalCreateAccessToken')
    },
    async editAccessToken(accessToken) {
      this.accessToken = accessToken
      this.$root.$emit('bv::show::modal', 'modalEditAccessToken')
    },
    async deleteAccessToken(accessToken) {
      this.accessToken = accessToken
      this.$root.$emit('bv::show::modal', 'modalDeleteAccessToken')
    },
    async onTokenDismiss() {
      this.accessToken = {}
    },
    async onTokenCreated() {
      this.accessTokens = await AccessTokenService.getAccessTokens()
    },
    async onTokenDeleted() {
      this.accessTokens = await AccessTokenService.getAccessTokens()
    },
    async onTokenEdited() {
      this.accessTokens = await AccessTokenService.getAccessTokens()
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
