<template>
  <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)" spinner-size="15px" />
  <div v-else class="view">
    <div class="row" v-if="!this.activeTeam || this.checkAccAccess()">
      <div class="col-sm-5">
        <div>
          <h1 class="page-title text-500 mb-4">Account</h1>
        <div>
          <div class="field-container">
            <label class="text-s-s">Name</label>
            <b-input
              size="md"
              v-model="form.name"
              class="mb-2"
              placeholder="#full_name"
              :disabled="!(activeTeam == null)"
            />
            <b-button
              variant="primary"
              size="md"
              class="mb-4"
              @click="changeName"
              :disabled="nameProcessing || !form.name || user.name == form.name || !(activeTeam == null)"
              >{{ nameProcessing ? "saving .." : "Change" }}</b-button
            >
          </div>
          <div class="field-container">
            <label class="text-s-s">Username</label>
            <b-input
              placeholder="#username"
              v-model="form.username"
              size="md"
              class="mb-2"
              @keypress="onUsernameChange()"
              :disabled="!(activeTeam == null)"
            />
            <b-button
              variant="primary"
              @click="changeUsername"
              size="md"
              class="mb-4"
              :disabled="
                unameProcessing ||
                !form.username ||
                user.username == form.username || 
                !(activeTeam == null)
              "
              >{{ unameProcessing ? "saving .." : "Change" }}</b-button
            >
            <div v-if="formErrors.username" class="text-danger">
              {{ formErrors.username.message }}
            </div>
          </div>
          <div class="field-container">
            <label class="text-s-s">Email</label>
            <b-input
              size="md"
              class="mb-4"
              placeholder="#email"
              :disabled="!(this.activeTeam == 'null')"
              :value="user && user.email"
              readonly
            />
          </div>

          <div class="field-container">
            <label class="text-s-s">Language</label>
            <b-input size="md" class="mb-4" value="English" :disabled="!(activeTeam == null)"></b-input>
          </div>
          <div class="field-container" v-if="isOtt">
            <label class="text-s-s"
              >Upload screen loading poster for OTT app</label
            >
            <b-row no-gutters align-v="center" align-h="between">
              <b-col v-if="posterUrl" lg="2">
                <div class="poster-thumb">
                  <b-img width="100px" :src="posterUrl" class="w-100" />
                </div>
              </b-col>
              <b-col lg="5">
                <b-file
                  id="embed-ott-poster-input"
                  size="xl"
                  ref="ott-file"
                  placeholder="No file chosen"
                  class="
                    custom-upload_dark-3
                    ott-file-upload
                    text-dark-6 text-s-m
                  "
                  drop-placeholder="Drop file here..."
                  maxlength="60"
                  @change="onottPreview('#embed-ott-poster-input')"
                  :disabled="!(activeTeam == null)"
                />
              </b-col>
              <b-col lg="5" class="d-flex justify-content-md-end">
                <b-button
                  variant="secondary"
                  size="sm"
                  class="mx-2"
                  v-if="!ottPosterTemp"
                  :disabled="featureProcessing.ottPoster || !(activeTeam == null)"
                  @click="hitUpload('#embed-ott-poster-input')"
                >
                  <span v-if="features.ottPoster.value">Change</span>
                  <span v-else>Select Poster</span>
                </b-button>

                <b-button
                  v-if="ottPosterTemp"
                  :disabled="featureProcessing.ottPoster"
                  variant="secondary"
                  size="sm"
                  class="mx-2"
                  @click="saveottImage('ottPoster')"
                >
                  <span>{{
                    featureProcessing.ottPoster ? "Saving .." : "Save Poster"
                  }}</span>
                </b-button>

                <b-button
                  v-if="ottPosterTemp || features.ottPoster.value"
                  :disabled="featureProcessing.ottPoster"
                  variant="outline-secondary"
                  size="sm"
                  @click="cancelUpload('#embed-ott-poster-input')"
                >
                  <span v-if="ottPosterTemp && features.ottPoster.value"
                    >Restore</span
                  >
                  <span v-else-if="ottPosterTemp">Cancel</span>
                  <span v-else>Remove</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div class="field-container" v-if="isOtt">
            <label class="text-s-s">Upload logo for OTT app</label>
            <b-row no-gutters align-v="center" align-h="between">
              <b-col v-if="logoUrl" lg="2">
                <div class="poster-thumb">
                  <b-img width="100px" :src="logoUrl" class="w-100" />
                </div>
              </b-col>
              <b-col lg="5">
                <b-file
                  id="embed-ott-logo-input"
                  size="xl"
                  ref="ott-file"
                  placeholder="No file chosen"
                  class="
                    custom-upload_dark-3
                    ott-file-upload
                    text-dark-6 text-s-m
                  "
                  drop-placeholder="Drop file here..."
                  maxlength="60"
                  :disabled="!(activeTeam == null)"
                  @change="onottPreview('#embed-ott-logo-input')"
                />
              </b-col>
              <b-col lg="5" class="d-flex justify-content-md-end">
                <b-button
                  variant="secondary"
                  size="sm"
                  class="mx-2"
                  v-if="!ottLogoTemp"
                  :disabled="featureProcessing.ottLogo"
                  @click="hitUpload('#embed-ott-logo-input')"
                >
                  <span v-if="features.ottLogo.value">Change</span>
                  <span v-else>Select Logo</span>
                </b-button>

                <b-button
                  v-if="ottLogoTemp"
                  :disabled="featureProcessing.ottLogo"
                  variant="secondary"
                  size="sm"
                  class="mx-2"
                  @click="saveottImage('ottLogo')"
                >
                  <span>{{
                    featureProcessing.ottLogo ? "Saving .." : "Save Logo"
                  }}</span>
                </b-button>

                <b-button
                  v-if="ottLogoTemp || features.ottLogo.value"
                  :disabled="featureProcessing.ottLogo"
                  variant="outline-secondary"
                  size="sm"
                  @click="cancelUpload('#embed-ott-logo-input')"
                >
                  <span v-if="ottLogoTemp && features.ottLogo.value">Restore</span>
                  <span v-else-if="ottLogoTemp">Cancel</span>
                  <span v-else>Remove</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
        </div>
      </div>
      <div class="col-sm-7 mt-4">
        <div class="flex phone-number-section flex-col">
          <div class="d-flex justify-content-between align-items-center" style="border-bottom: 1px solid #232D4D;">
            <div class="text-s-xl p-2 ml-2" style="">Phone numbers</div>
            <div class="mr-2">
              <!-- <a href="#">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 4V10M10 16V10M10 10H4M10 10H16" stroke="#AEBAD6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a> -->
            </div>
          </div>

          <div class="d-flex flex-column pt-2 ml-2 pb-3 pl-2">

            <span v-if="contacts.length === 0" class="text-dark-6 p-2">You don’t have phone numbers yet.</span>

            <div 
              v-for="contact in contacts"
              :key="contact._id"
              class="d-flex pt-3 justify-content-between align-items-center text-dark-9">
              {{ contact.contact.phoneNumber }}
              <div class="d-flex flex-row mr-2">
                <a v-if="isOwner" href="#" @click.prevent="onPhoneNumberEditClick(contact)" class="mr-2">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 4.5H7.3C5.61984 4.5 4.77976 4.5 4.13803 4.82698C3.57354 5.1146 3.1146 5.57354 2.82698 6.13803C2.5 6.77976 2.5 7.61984 2.5 9.3V12.7C2.5 14.3802 2.5 15.2202 2.82698 15.862C3.1146 16.4265 3.57354 16.8854 4.13803 17.173C4.77976 17.5 5.61984 17.5 7.3 17.5H10.7C12.3802 17.5 13.2202 17.5 13.862 17.173C14.4265 16.8854 14.8854 16.4265 15.173 15.862C15.5 15.2202 15.5 14.3802 15.5 12.7V12" stroke="#68759C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M14.5 2.5L11.2535 5.74651C10.7869 6.21308 10.5536 6.44636 10.364 6.71022C10.1956 6.94448 10.053 7.19626 9.93877 7.46119C9.8101 7.7596 9.73009 8.07965 9.57006 8.71977L9 11L11.2802 10.4299L11.2802 10.4299C11.9204 10.2699 12.2404 10.1899 12.5388 10.0612C12.8037 9.947 13.0555 9.80443 13.2898 9.63604C13.5536 9.44636 13.7869 9.21308 14.2535 8.74651L17.5 5.5C17.5797 5.42035 17.6195 5.38052 17.6515 5.34526C18.3449 4.58244 18.3449 3.41756 17.6515 2.65474C17.6195 2.61948 17.5797 2.57966 17.5 2.50003L17.5 2.5C17.4203 2.42035 17.3805 2.38052 17.3453 2.34847C16.5824 1.65508 15.4176 1.65508 14.6547 2.34847C14.6195 2.38052 14.5797 2.42035 14.5 2.5Z" stroke="#68759C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M14.5 2.5V2.5C14.25 4.25 15.75 5.75 17.5 5.5V5.5" stroke="#68759C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </a>

                <a v-if="isOwner" href="#" @click.prevent="onPhoneNumberRemoveClick(contact)">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="10" cy="4" rx="7" ry="2" stroke="#68759C" stroke-width="1.5"/>
                    <path d="M17 4L15.5 16C15 17.5 12 18 10 18C8 18 5 17.5 4.5 16L3 4" stroke="#68759C" stroke-width="1.5"/>
                  </svg>
                </a>

              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-sm-7"></div>
    </div>
 
    <div class="no-access mt-0" v-else>
      <h1 class="page-title text-500 mb-4">Account</h1>
			<div class="text-center pt-5 mt-5">
				<h4 class="text-dark-9 text-s-xl text-500 mb-1">You don’t have permission to access this.</h4>
				<p class="text-dark-6 text-s-m">Contact the team owner at <a  class="text-dark-6 text-s-m text-underline" :href="'mailto:'+userEmail">{{userEmail}}</a> to gain access.</p>
			</div>
			</div>
    <alert-modal
      modal-id="ott_embed-upload-format"
      message="Only valid image files are allowed. Please pick an image file"
    />
    <alert-modal
      :message="
        'Uploaded image too large. Image must not exceed ' +
        maxPosterUploadMB +
        'MB'
      "
      modal-id="ott_embed-image-size-exceed"
    />
    <confirm-modal
			modal-id="confirm-phone-removal"
			:body="`The phone number: ${(selectedContact && selectedContact.contact && selectedContact.contact.phoneNumber) || ''} will be deleted from all streams it linked to. You can\'t undo this action.`"
      message="Delete this phone number?"
			ok-text="Delete Number"
			cancel-text="Cancel"
			@modal-confirm="removePhoneNumber()"
		/>

    <edit-phone-number-modal
			ref="EditPhoneNumberModal"
			modal-id="edit-phone-number-modal"
      :contact="selectedContact"
			@user-contact-updated="onPhoneNumberUpdated"
		/>
  </div>
</template>

<script>
import Vue from 'vue';
import UserService from "@/services/UserService";
import AlertModal from "@/components/modals/AlertModal.vue";
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import EditPhoneNumberModal from '@/components/modals/EditPhoneNumberModal.vue';
import Spinner from "@/components/ui/Spinner.vue";
import { mapGetters } from 'vuex';

export default {
  name: "ManageAccount",
  components: {
    AlertModal,
    ConfirmModal,
    EditPhoneNumberModal,
    AlertModal,
    Spinner,
  },
  async mounted() {
    this.$store.dispatch('Ui/setLoadingStatus', true);
    this.setUser();
    await this.setupUserContacts();
    // track event
    window.trackEvent(`Account Page`);
  },
  data() {
    return {
      ottPosterurl: false,
      processing: false,
      maxPosterUploadMB: 2,
      ottLogourl: false,
      maxLogoUploadMB: 2,
      processing: false,
      nameProcessing: false,
      unameProcessing: false,
      user: null,
      ottPosterTemp: null,
      ottLogoTemp: null,
      isOtt:false,
      selectedContact: null,
      form: {
        name: null,
        username: null,
      },
      formErrors: {
        username: null,
      },
      featureProcessing: {
        ottPoster: false,
        ottLogo: false,
      },
      features: {
        ottPoster: {
          error: false,
          enabled: false,
          value: null,
          valueType: "string",
        },
        ottLogo: {
          error: false,
          enabled: false,
          value: null,
          valueType: "string",
        },
      },
      contacts: []
    };
  },
  computed: {
   	activeTeam(){
			return this.$store.state.User.activatedTeam;
		},
		memberPermission(){
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
    userEmail() {
			return this.$store.state.User.email;
		},
		userloaded(){
			return this.$store.state.User.userloaded;
		},
    posterUrl() {
      if (this.ottPosterTemp) {
        return this.ottPosterTemp;
      }
      if (this.ottLocalTemp) {
        return this.ottLocalTemp;
      }
      const imageId = this.ottPosterurl;
      return imageId
        ? `https://assets.castr.io/ottAppPosters/${imageId}`
        : null;
    },
    logoUrl() {
      if (this.ottLogoTemp) {
        return this.ottLogoTemp;
      }
      const imageId = this.ottLogourl;
      return imageId ? `https://assets.castr.io/ottAppLogo/${imageId}` : null;
    },
    ...mapGetters({
      isOwner: 'User/isOwner',
      loadingStatus: "Ui/loadingStatus"
    }),
  },
  watch:{
    activeTeam(){
      this.setUser();
    },
    async userloaded(){
			const permission = await this.checkAccAccess();
		}
  },
  methods: {
    onPhoneNumberUpdated(newContact) {
      // TODO: support multiple user contacts
      this.contacts = [newContact];
      this.$notify({ group: 'success', text: 'New phone number updated successfully' });
      this.$refs.EditPhoneNumberModal.dismiss();
    },
    onPhoneNumberEditClick(contact) {
      this.selectedContact = contact;
      this.$root.$emit('bv::show::modal', 'edit-phone-number-modal');
    },
    onPhoneNumberRemoveClick(contact) {
      this.selectedContact = contact;
      this.$root.$emit('bv::show::modal', 'confirm-phone-removal');
    },
    async removePhoneNumber() {
      try {
        await UserService.deleteUserContact(this.selectedContact._id)
        this.contacts = _.filter(this.contacts,  (i) => {
          return i._id !== this.selectedContact._id
        })
        this.$notify({
          group: "success",
          text: `The phone number: ${(this.selectedContact && this.selectedContact.contact && this.selectedContact.contact.phoneNumber) || ''} is completely deleted from your account`
        })

        const updatedUser = _.assign({}, UserService.getUser(), { contact: null })
	      localStorage.setItem('user', JSON.stringify(updatedUser))

        this.selectedContact = null
      } catch(err) {
        this.$notify({
          group: "error",
          text: err.message || "remove user contact failed",
        })
      }
    },
    async setupUserContacts() {
      try {
        this.contacts = await UserService.getUserContacts('phone')
      } catch(err) {
        this.$notify({
          group: "error",
          text: err.message || "get user contacts failed",
        });
      }
    },
    checkAccAccess(){
			return this.activeTeam && this.memberPermission && this.memberPermission.accountView;
		},
    async setUser(){
        this.processing = true;
        this.user = this.$store.state.User;
        this.form.name = this.user.name;
        this.form.username = this.user.username;
        this.ottPosterurl = this.$store.state.User.ottAppPoster;
        this.features.ottPoster.value = this.$store.state.User.ottAppPoster;
        this.ottLogourl = this.$store.state.User.ottAppLogo;
        this.features.ottLogo.value = this.$store.state.User.ottAppLogo;
        try {
        // update vuex state
        await this.$store.dispatch('User/checkOtt', null, { root: true });
        this.isOtt = this.$store.state.User.isOtt;
        } catch (e) {
        this.trialError = e;
        return;
        }
        if(this.userloaded) this.$store.dispatch('Ui/setLoadingStatus', false);
    },
    async saveottImage(featureProp = "ottPoster") {
      this.features[featureProp].error = false;

      let selector
      switch (featureProp) {
        case 'ottPoster':
          selector = "#embed-ott-poster-input";
          break;
        case 'ottLogo':
            selector = "#embed-ott-logo-input";
            break;
        default:
          selector = "#embed-ott-poster-input";
          break;
      }
      const imageInput = document.querySelector(selector);
      const imageFile = imageInput.files[0];
      if (!imageInput.value || !imageFile) {
        this.features[featureProp].error = "please pick an image file";
        return;
      }

      // this.featureProcessing.ottPoster = true;
      this.featureProcessing[featureProp] = true;

      // -- upload reuqest --
      const fdata = new FormData();
      fdata.append("file", imageFile);

      try {
        if (featureProp === "ottPoster") {
          const res = await UserService.uploadOttPoster(fdata);
          if (res.success) {
            const poster = `https://assets.castr.io/embedImages/${res.uploadId}`;
            this.ottPosterTemp = null;
            this.ottPosterurl = res.uploadId;
            this.features[featureProp].value = res.uploadId;

            this.$notify({
              group: "success",
              text: `OTT poster uploaded with success`,
            });
          } else {
            this.features[featureProp].error =
              "could not handle image upload. Please try again later";
          }
        }
        if (featureProp === "ottLogo") {
          const res = await UserService.uploadOttLogo(fdata);
          if (res.success) {
            const poster = `https://assets.castr.io/embedImages/${res.uploadId}`;
            this.ottLogoTemp = null;
            this.ottLogourl = res.uploadId;
            this.features[featureProp].value = res.uploadId;

            this.$notify({
              group: "success",
              text: `OTT Logo uploaded with success`,
            });
          } else {
            this.features[featureProp].error =
              "could not handle image upload. Please try again later";
          }
        }
      } catch (err) {
        this.features[featureProp].error =
          "could not handle image upload. Please try again later";
        this.$notify({
          group: "error",
          text: err.message || "could not upload OTT Poster",
        });
      }

      this.featureProcessing[featureProp] = false;
    },
    async cancelUpload(selector = "#embed-ott-poster-input") {
      let previewFlag
      if (selector === "#embed-ott-poster-input") {
        previewFlag = this.ottPosterTemp;
      }
      if (selector === "#embed-ott-logo-input") {
        previewFlag = this.ottLogoTemp;
      }
      this.$refs["ott-file"].reset();
      if (selector === "#embed-ott-poster-input" && previewFlag) {
        this.ottPosterTemp = null;
        this.features.ottPoster.error = false;
        document.querySelector(selector).value = null;
        return;
      }
      if (selector === "#embed-ott-logo-input" && previewFlag) {
        this.ottLogoTemp = null;
        this.features.ottLogo.error = false;
        document.querySelector(selector).value = null;
        return;
      }

      this.removeImage(selector);
    },
    async removeImage(selector = "#embed-ott-poster-input") {
      try {
        if (selector === "#embed-ott-poster-input") {
          const deleteRes = await UserService.deleteOttposter();
          if (deleteRes.success) {
            this.ottPosterurl = false;
            this.features.ottPoster.value = null;
            this.$refs["ott-file"].reset();
            this.$notify({
              group: "success",
              text: `OTT poster deleted`,
            });
          }
        }
        if (selector === "#embed-ott-logo-input") {
          const deleteRes = await UserService.deleteOttLogo();
          if (deleteRes.success) {
            this.ottLogourl = false;
            this.features.ottLogo.value = null;
            this.$refs["ott-file"].reset();
            this.$notify({
              group: "success",
              text: `OTT logo deleted`,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    hitUpload(selector = "#embed-ott-poster-input") {
      document.querySelector(selector).click();
    },
    onottPreview(selector = "#embed-ott-poster-input") {
      const imageInput = document.querySelector(selector);
      const imageFile = imageInput.files[0];
      if (!imageFile) return;

      if (imageFile.type.indexOf("image/") === -1) {
        this.$root.$emit("bv::show::modal", "ott_embed-upload-format");
        imageInput.value = null;
        return;
      }

      let maxSize = 2;
      this.maxPosterUploadMB = maxSize;

      maxSize *= 1024 * 1024;

      if (imageFile.size > maxSize) {
        this.$root.$emit("bv::show::modal", "ott_embed-image-size-exceed");
        imageInput.value = null;
        return;
      }

      imageReader(imageFile, (base64) => {
        if (selector === "#embed-ott-poster-input") {
          this.ottPosterTemp = base64;
        }
        if (selector === "#embed-ott-logo-input") {
          this.ottLogoTemp = base64;
        }
      });
    },
    onUsernameChange() {
      this.formErrors.username = null;
    },
    async changeName() {
      this.nameProcessing = true;
      try {
        const { name } = this.form;
        await UserService.changeName(name);

        // track event
        window.trackEvent(`Changed account name ${this.user.name} -> ${name}`);

        this.$notify({ group: "success", text: "Account name saved" });
        this.user.name = name;
      } catch (e) {
        this.form.name = this.user.name;
        this.$notify({
          group: "error",
          text: "Counld not change account name",
        });
      }

      this.nameProcessing = false;
    },
    async changeUsername() {
      this.formErrors.username = null;

      const { username } = this.form;
      if (username.length < 5) {
        this.formErrors.username = {
          message: "must have atleast 5 letters",
        };
        return;
      }

      this.unameProcessing = true;

      try {
        const { username } = this.form;
        await UserService.changeUsername(username);

        // track event
        window.trackEvent(
          `Changed account username ${this.user.username} -> ${username}`
        );

        this.$notify({ group: "success", text: "Account username saved" });
        this.user.username = username;
      } catch (e) {
        this.formErrors.username = e;
        // this.form.username = this.user.username
        this.$notify({
          group: "error",
          text: "Counld not change account username",
        });
      }

      this.unameProcessing = false;
    },
  },
};

function imageReader(file, cb) {
  if (!file) {
    return;
  }

  var reader = new FileReader();
  reader.onload = function () {
    if (cb) {
      cb(reader.result);
    }
  };

  reader.readAsDataURL(file);
}
</script>

<style scoped>
 .phone-number-section {
    border: 1px solid #dee2e6; 
    border-color: var(--c-dark-3); 
    border-radius: 12px;
 }
</style>
