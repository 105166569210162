<template>
  <path
    d="M6.2 18.5001H10.8C11.9201 18.5001 12.4802 18.5001 12.908 18.2821C13.2843 18.0904 13.5903 17.7844 13.782 17.4081C14 16.9802 14 16.4202 14 15.3001V9.82557C14 9.33639 14 9.0918 13.9447 8.86163C13.8957 8.65756 13.8149 8.46247 13.7053 8.28352C13.5816 8.08169 13.4086 7.90874 13.0627 7.56283L10.9373 5.43735L10.9373 5.43735C10.5914 5.09144 10.4184 4.91849 10.2166 4.79481C10.0376 4.68515 9.84254 4.60435 9.63846 4.55535C9.40829 4.50009 9.1637 4.50009 8.67452 4.50009H6.2C5.0799 4.50009 4.51984 4.50009 4.09202 4.71808C3.71569 4.90983 3.40973 5.21579 3.21799 5.59211C3 6.01993 3 6.57999 3 7.70009V15.3001C3 16.4202 3 16.9802 3.21799 17.4081C3.40973 17.7844 3.71569 18.0904 4.09202 18.2821C4.51984 18.5001 5.07989 18.5001 6.2 18.5001Z"
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  <path
    d="M14 15.5V15.5C14.9318 15.5 15.3977 15.5 15.7653 15.3478C16.2554 15.1449 16.6448 14.7555 16.8478 14.2653C17 13.8978 17 13.4319 17 12.5001V6.82558C17 6.33639 17 6.0918 16.9447 5.86163C16.8957 5.65756 16.8149 5.46247 16.7053 5.28352C16.5816 5.08169 16.4086 4.90874 16.0627 4.56283L13.9373 2.43735C13.5914 2.09145 13.4184 1.91849 13.2166 1.79481C13.0376 1.68515 12.8425 1.60435 12.6385 1.55535C12.4083 1.50009 12.1637 1.50009 11.6745 1.50009H9.2C8.07989 1.50009 7.51984 1.50009 7.09202 1.71808C6.71569 1.90983 6.40973 2.21579 6.21799 2.59211C6 3.01993 6 3.57999 6 4.70009V5"
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
  <path
    d="M9 5V6.30003C9 7.42013 9 7.98019 9.21799 8.40801C9.40973 8.78433 9.71569 9.0903 10.092 9.28204C10.5198 9.50003 11.0799 9.50003 12.2 9.50003H13.5"
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</template>