<script setup>
import { computed, ref } from 'vue'

import { useStore } from 'vuex'

import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconChevronLeft from '../icon/IconChevronLeft.vue';

const props = defineProps({
  expanded: {
    type: Boolean,
  },
})

const store = useStore()

const playout = computed(() => store.getters['Streams/tvPlayout'])

const animationStart = ref(false)
const animationEnd = ref(false)
const onEnter = () => animationStart.value = !animationStart.value
const onLeave = () => animationEnd.value = !animationEnd.value
</script>

<template>
  <!-- {{ animationStart }}
  {{ animationEnd }} -->
  <router-link class="block" to="/">
    <Transition name="fade" @before-enter="onEnter" @after-leave="onEnter">
      <figure class="relative h-10 flex items-center" v-if="expanded">
        <img height="40" width="120" class="min-w-[120px]" src="@/assets/castr.svg" alt="Streaming Dashboard - Castr" />
        <span class="absolute top-3 left-[116px] animate-pulse absolute flex size-1 rounded-full bg-[#EF0000]">
        </span>
      </figure>
      <figure class="relative h-10 flex items-center" v-else>
        <img src="@/assets/castr-short.svg" alt="Streaming Dashboard - Castr" height="32px" class="logo-icon" />
      </figure>
    </Transition>
  </router-link>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease;
  position: relative;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  position: absolute;
}
</style>
