<script setup>
import { computed, ref, onMounted } from 'vue'

const isVisible = ref(false)

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

onMounted(() => {
  if (props.isOpen) isVisible.value = true
})
const toggleView = () => {
  isVisible.value = !isVisible.value
}

defineExpose({ toggleView, isVisible })
</script>
<template>
  <Transition name="collapse">
    <section class="collapse-wrapper" v-if="isVisible">
      <slot />
    </section>
  </Transition>
</template>

<style scoped>

.collapse-enter-active, .collapse-leave-active {
  transition: max-height 0.3s ease-in-out, opacity 0.4s ease-in-out;
}
.collapse-enter, .collapse-leave-to /* .collapse-leave-active in <2.1.8 */ {
  max-height: 0;
  opacity: 0;
}
.collapse-enter-to, .collapse-leave {
  max-height: 500px; /* or the height of your content */
  opacity: 1;
}
.content {
  overflow: hidden;
  max-height: 500px; /* or the height of your content */
}
</style>