<template>
  <b-container>
    <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)"
      spinner-size="15px" />
    <main v-else-if="origins && origins.length === 0">
      <header class="d-flex align-items-center mb-3 mt-5">
        <h1 ref="title" class="page-title m-0">
          Your origin
        </h1>
      </header>
      <section class="text-center mt-200">
        <h2 class="text-s-m text-dark-9 text-500 mb-2">Bring Your Own Origin</h2>
        <p class="text-s-s text-dark-8 text-normal col-lg-4 mx-auto mb-2">Have your own media server? Use our
          high-performance CDN <br>to reduce load on your origin and ensure low-latency global delivery.</p>
        <a class="text-dark-8 text-s-ss text-500 mb-4 flex items-center justify-center"
          href="https://docs.castr.com/en/articles/8392177-create-custom-cdn" target="_blank">
          <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1.4999 1.8999C1.16853 1.8999 0.899902 2.16853 0.899902 2.4999V12.4999C0.899902 12.8313 1.16853 13.0999 1.4999 13.0999H5.7499C6.66117 13.0999 7.3999 13.8386 7.3999 14.7499V14.9999C7.3999 15.3313 7.66853 15.5999 7.9999 15.5999C8.04132 15.5999 8.08176 15.5957 8.12082 15.5877C8.39423 15.5318 8.5999 15.2899 8.5999 14.9999V14.7499C8.5999 13.8386 9.33863 13.0999 10.2499 13.0999H14.4999C14.8313 13.0999 15.0999 12.8313 15.0999 12.4999V2.4999C15.0999 2.16853 14.8313 1.8999 14.4999 1.8999H10.4999C9.47379 1.8999 8.56413 2.39844 7.9999 3.16652C7.43568 2.39844 6.52601 1.8999 5.4999 1.8999H1.4999ZM8.5999 4.9999L8.5999 12.4258C9.06554 12.0947 9.63498 11.8999 10.2499 11.8999H13.8999V3.0999H10.4999C9.45056 3.0999 8.5999 3.95056 8.5999 4.9999ZM7.3999 4.9999C7.3999 3.95056 6.54924 3.0999 5.4999 3.0999H2.0999V11.8999H5.7499C6.36483 11.8999 6.93426 12.0947 7.3999 12.4258V4.9999Z"
              fill="#ADB8DC" fill-opacity="0.5" />
          </svg>
          How Your Origin work
        </a>
        <b-button v-if="maxOriginsSub > 0 && !isLiveSubscriptionExpired" size="md" variant="primary"
          v-b-modal="'modal-add-origin'">
          <svg class="mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.99999 0.399902C6.33136 0.399902 6.59999 0.668532 6.59999 0.999902V5.3999H11C11.3314 5.3999 11.6 5.66853 11.6 5.9999C11.6 6.33127 11.3314 6.5999 11 6.5999H6.59999V10.9999C6.59999 11.3313 6.33136 11.5999 5.99999 11.5999C5.66862 11.5999 5.39999 11.3313 5.39999 10.9999V6.5999H0.999994C0.668623 6.5999 0.399994 6.33127 0.399994 5.9999C0.399994 5.66853 0.668623 5.3999 0.999994 5.3999H5.39999V0.999902C5.39999 0.668532 5.66862 0.399902 5.99999 0.399902Z"
              fill="currentColor" />
          </svg>
          Create a new CDN resource
        </b-button>
        <a v-else href="/app/subscribe">
          <b-button size="md" variant="primary">
            Upgrade
          </b-button>
        </a>
      </section>
    </main>
    <main v-else>
      <header class="d-flex align-items-center mb-3 mt-5">
        <h1 ref="title" class="page-title m-0">
          Your origin
        </h1>
        <span id="exceed-origin-limit-tooltip" class="ml-auto">
          <b-button :disabled="maxOriginsSub === 0 || origins.length >= maxOriginsSub || isLiveSubscriptionExpired"
            size="md" variant="primary" v-b-modal="'modal-add-origin'" class="ml-auto">
            <svg class="mr-2 flex-shrink-0" width="12" height="12" viewBox="0 0 12 12" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.99999 0.399902C6.33136 0.399902 6.59999 0.668532 6.59999 0.999902V5.3999H11C11.3314 5.3999 11.6 5.66853 11.6 5.9999C11.6 6.33127 11.3314 6.5999 11 6.5999H6.59999V10.9999C6.59999 11.3313 6.33136 11.5999 5.99999 11.5999C5.66862 11.5999 5.39999 11.3313 5.39999 10.9999V6.5999H0.999994C0.668623 6.5999 0.399994 6.33127 0.399994 5.9999C0.399994 5.66853 0.668623 5.3999 0.999994 5.3999H5.39999V0.999902C5.39999 0.668532 5.66862 0.399902 5.99999 0.399902Z"
                fill="currentColor" />
            </svg>
            Create a new CDN resource
          </b-button>
        </span>

        <b-tooltip v-if="maxOriginsSub === 0 || origins.length >= maxOriginsSub || isLiveSubscriptionExpired"
          target="exceed-origin-limit-tooltip" placement="top">
          To create more CDN resources, contact Sales team.
        </b-tooltip>
      </header>

      <b-alert v-if="isLiveSubscriptionExpired && bandwidthLimitReachedMsg" variant="danger" show
        class="d-flex align-items-start px-2">
        <svg class="flex-shrink-0 mr-2" width="16" height="14" viewBox="0 0 16 14" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.36938 3.29623C6.5309 1.17817 7.11166 0.119141 7.99982 0.119141C8.88798 0.119141 9.46874 1.17817 10.6303 3.29623L14.0636 9.55705C15.1498 11.5377 15.6929 12.528 15.2572 13.2638C14.8216 13.9996 13.6921 13.9996 11.4332 13.9996H4.56647C2.30754 13.9996 1.17808 13.9996 0.742432 13.2638C0.306783 12.528 0.849865 11.5377 1.93603 9.55705L5.36938 3.29623ZM7.99978 4.24955C8.41399 4.24955 8.74978 4.58534 8.74978 4.99955V7.49955C8.74978 7.91377 8.41399 8.24955 7.99978 8.24955C7.58556 8.24955 7.24978 7.91377 7.24978 7.49955V4.99955C7.24978 4.58534 7.58556 4.24955 7.99978 4.24955ZM7.99982 11.4996C8.5521 11.4996 8.99982 11.0518 8.99982 10.4996C8.99982 9.94728 8.5521 9.49956 7.99982 9.49956C7.44753 9.49956 6.99982 9.94728 6.99982 10.4996C6.99982 11.0518 7.44753 11.4996 7.99982 11.4996Z"
            fill="white" />
        </svg>
        <div class="mt-n1">
          <p class="text-s-m text-500 mb-0">You've reached your bandwidth limit.</p>
          <p class="text-s-s text-500 mb-0">The origin will be disabled. Please contact <a
              class="text-reset text-underline" href="#" onclick="Intercom('show')">sales</a> team to get more bandwidth.
          </p>
        </div>
      </b-alert>

      <b-alert v-if="isLiveSubscriptionExpired && !bandwidthLimitReachedMsg" variant="danger" show
        class="d-flex align-items-start px-2">
        <svg class="flex-shrink-0 mr-2" width="16" height="14" viewBox="0 0 16 14" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.36938 3.29623C6.5309 1.17817 7.11166 0.119141 7.99982 0.119141C8.88798 0.119141 9.46874 1.17817 10.6303 3.29623L14.0636 9.55705C15.1498 11.5377 15.6929 12.528 15.2572 13.2638C14.8216 13.9996 13.6921 13.9996 11.4332 13.9996H4.56647C2.30754 13.9996 1.17808 13.9996 0.742432 13.2638C0.306783 12.528 0.849865 11.5377 1.93603 9.55705L5.36938 3.29623ZM7.99978 4.24955C8.41399 4.24955 8.74978 4.58534 8.74978 4.99955V7.49955C8.74978 7.91377 8.41399 8.24955 7.99978 8.24955C7.58556 8.24955 7.24978 7.91377 7.24978 7.49955V4.99955C7.24978 4.58534 7.58556 4.24955 7.99978 4.24955ZM7.99982 11.4996C8.5521 11.4996 8.99982 11.0518 8.99982 10.4996C8.99982 9.94728 8.5521 9.49956 7.99982 9.49956C7.44753 9.49956 6.99982 9.94728 6.99982 10.4996C6.99982 11.0518 7.44753 11.4996 7.99982 11.4996Z"
            fill="white" />
        </svg>
        <div class="mt-n1">
          <p class="text-s-m text-500 mb-0">Your subscription has expired.</p>
          <p class="text-s-s text-500 mb-0">The origin will be disabled. Please <a class="text-reset text-underline"
              href="/app/subscribe?category=live">upgrade plan.</a> </p>
        </div>
      </b-alert>

      <section>
        <ul v-for="(orig, index) in origins" :key="index" class="list-unstyled">
          <div class="bg-dark-2 origin-item-container" style="padding: 20px;">

            <div class="d-flex justify-content-between align-items-center">
              <!-- <h2 class="text-s-xxl text-500 mb-1">{{ orig.name }}</h2> -->

              <textarea v-model="orig.name" style="width: 90%;" ref="streamTitle" rows="1" autocomplete="off"
                autocorrect="off" autocapitalize="off" spellcheck="false" title="Edit stream name"
                class="page-title page-title_input ml-n2 pl-2 overflow-hidden form-control form-control-sm"
                @keydown.enter.prevent="preventEnter" @change="onOriginNameChange(index)" @focus="resizeTextarea"
                @keyup="resizeTextarea"></textarea>

              <div class="d-flex">
                <div v-if="orig.originUrl">
                  <span id="exceed-origin-limit-tooltip-toggle">
                    <b-button v-if="orig.enabled" :disabled="isLiveSubscriptionExpired" @click="toggleOrigin(index)"
                      variant="outline-secondary" size="sm" class="mr-2">
                      Disable
                    </b-button>
                    <b-button v-else :disabled="isLiveSubscriptionExpired" @click="toggleOrigin(index)" variant="primary"
                      size="sm" class="mr-2">
                      Enable
                    </b-button>
                  </span>

                  <b-tooltip v-if="isLiveSubscriptionExpired" target="exceed-origin-limit-tooltip-toggle" placement="top">
                    <p class="text-s-s text-500 mb-0">Origin disabled. Please <a class="text-reset text-underline"
                        href="/app/subscribe?category=live">upgrade plan.</a> </p>
                  </b-tooltip>

                </div>
                <b-button @click="onRemoveOrigin(index)" variant="outline-danger" size="sm" class="px-0">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="8" cy="3.5" rx="5" ry="1.5" stroke="currentColor" stroke-width="1.5" />
                    <path d="M13 3.5L11.75 13C11.4 14 10 14.5 8 14.5C6 14.5 4.65 14 4.25 13L3 3.5" stroke="currentColor"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </b-button>
              </div>
            </div>

            <div v-if="!orig.originUrl">
              <label>Your origin Domain</label>
              <div class="d-flex justify-content-md-end align-items-center">
                <b-input v-model="orig.originUrlInput" class="mr-2" size="md"
                  placeholder="Enter the domain or IP address" />
                <b-button class=" truncate" :disabled="!orig.originUrlInput || orig.originProcessing"
                  @click="addOriginUrl(index)" variant="primary">{{ orig.originProcessing ? 'Saving..' : 'Add CDN'}}</b-button>
              </div>

              <div class="mt-3">
                <a href="#" @click.prevent id="example-url-tooltip" class="text-s-s text-dark-7">
                  Example URL
                </a>
                <b-tooltip target="example-url-tooltip" placement="leftcenter" custom-class="tooltip-container">
                  <div class="tooltip-content">
                    <p class="text-dark-8 test-s-s">To stream your video via the CDN, you simple need to replace the URLs
                      pointing to your static files with the <span style="color: #fff">New url</span> host name. Your
                      video date will ve automatically downloaded, cached and delivered through the CDN.</p>

                    <p class="text-dark-8 test-s-s">For example, update the video file URLs pointing to:</p>
                    <input value="http://myvideoserver.com/videos/playlist.m3u8" type="text" readonly="readonly"
                      style="color: #fff; background-color: rgba(167, 181, 226, 0.08); border: none; outline: none;"
                      class="form-control">

                    <br>
                    <p class="text-dark-8 test-s-s">to your <span style="color: #fff">New url</span> hostname:</p>
                    <input value="http://castr.b-cdn.net/video/playlist.m3u8" type="text" readonly="readonly"
                      style="color: #fff; background-color: rgba(167, 181, 226, 0.08); border: none; outline: none;"
                      class="form-control">

                  </div>
                </b-tooltip>
              </div>

            </div>

            <b-tabs v-else class="tabs-light mt-2">
              <b-tab title="Origin configuration" active>
                <b-row align-v="center" class="mt-3">
                  <b-col cols="3" class="mt-3">

                    <label class="text-s-s text-dark-7 text-500">Origin URL</label><br>

                    <b-input-group size="md">
                      <b-form-input :value="orig.originUrl" readonly></b-form-input>
                      <b-input-group-append>
                        <b-button class="py-1 px-2 m-0" variant="icon" @click="clipboardCopy(orig.originUrl)">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667"
                              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z"
                              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>

                  </b-col>
                  <b-col cols="3" class="mt-3">
                    <label class="text-s-s text-dark-7 text-500">CDN URL</label><br>
                    <b-input-group size="md">
                      <b-form-input :value="'https://' + orig.cdnUrl" readonly></b-form-input>
                      <b-input-group-append>
                        <b-button class="py-1 px-2 m-0" variant="icon" @click="clipboardCopy('https://' + orig.cdnUrl)">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667"
                              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z"
                              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col cols="1">
                    <b-button @click="onEditOrigin(index)" variant="outline-secondary" size="sm" class="mr-2"
                      style="margin-top: 40px;">
                      Edit
                    </b-button>
                  </b-col>

                  <div class="mx-5" style="border-left:1px solid #343E61; height: 80px;"></div>

                  <b-col>
                    <label class="text-s-s text-dark-7 text-500">Bandwidth</label><br>
                    <span v-if="orig.bandwidthUsage" class="text-s-s color-text-1">{{ orig.bandwidthUsage.value }} {{
                      orig.bandwidthUsage.unit.toUpperCase() || 'GB' }} </span>
                    <span v-else class="fas fa-spinner fa-spin mr-4"></span>

                    <span class="text-s-s text-dark-7"> used by CDN</span><br>

                    <span v-if="totalBandwidthUsage" class="text-s-s color-text-1"
                      :class="{ 'text-danger': bandwidthLimitReachedMsg }">{{ totalBandwidthUsage.value || 0 }} {{
                        totalBandwidthUsage.unit.toUpperCase() || 'GB' }} / </span>
                    <span v-else class="fas fa-spinner fa-spin mr-4"></span>

                    <span class="text-s-s text-dark-7">{{ totalBandwidthLimit.value }} {{ totalBandwidthLimit.mappedUnit
                    }} assigned</span>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-4">
                  <div class="example-links-container">
                    <label class="text-s-s text-dark-7 text-500">Example URL</label>
                    <p class="text-s-s color-text-1">https://{{ orig.originUrl }}/content/media.mp4</p>

                    <label class="text-s-s text-dark-7 text-500">CDN Content URL is</label>
                    <p class="text-s-s color-text-1">https://{{ orig.cdnUrl }}/content/media.mp4</p>

                  </div>
                </b-row>
              </b-tab>
              <b-tab title="Real-time analytics" disabled>
                <template #title>
                  Real-time analytics
                  <b-badge variant="dark-3" class="badge_beta align-self-center mr-2">Coming soon</b-badge>
                </template>
              </b-tab>
            </b-tabs>

          </div>
        </ul>
      </section>
    </main>
    <add-origin-modal @origin-created="originCreated" />
    <edit-origin-modal :edit-origin="editOriginUrl" />
    <confirm-modal modalId="delete-cdn" message="Are you sure you want to delete the CDN resource ?" modal-type="danger"
      @modal-confirm="removeOrigin()" />
  </b-container>
</template>

<script>
import Vue from 'vue';
import _ from "lodash";
import { mapGetters } from 'vuex'
import Spinner from "@/components/ui/Spinner.vue"
import OriginService from "@/services/OriginService"
import AddOriginModal from "@/components/modals/AddOriginModal.vue"
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
// TODO MIGRATION
// import VueCircle from 'vue2-circle-progress'
import { bytes } from '@/filtersNew'
import EditOriginModal from "@/components/modals/EditOriginModal.vue"
import UserService from '@/services/UserService'
import MetricsService from '@/services/MetricsService'

export default {
  name: "ManagePlaylists",
  components: {
    Spinner,
    AddOriginModal,
    ConfirmModal,
    // TODO MIGRATION
    // FIXME extra import
    // VueCircle,
    EditOriginModal
  },
  data() {
    return {
      origins: [],
      originProcessing: false,
      currentIndexed: 0,
      progress: 0,
      totalOriginBandwidth: null,
      totalBandwidthUsage: null,
      bandwidthLimitReachedMsg: false
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "Ui/loadingStatus",
      isLiveSubscriptionExpired: "User/isLiveSubscriptionExpired",
      hasLiveSubscription: "User/hasLiveSubscription"
    }),
    liveSub() {
      return this.$store.state.User.subscriptions.find(sub => ((sub.category && sub.category.includes('live'))))
    },
    maxOriginsSub() {
      return _.get(this.liveSub, ['package', 'definition', 'maxOrigins'], 0)
    },
    originBandwidth() {
      let bandwidth = 0
      if (this.totalOriginBandwidth) {
        bandwidth = this.totalOriginBandwidth
      }
      const data = bytes(bandwidth, true, 3, true)
      return { value: data.value, unit: data.unit }
    },
    totalBandwidthLimit() {
      let mappedUnit = 'TB'
      let allocatedVal = this.getSubscriptionDefProp(this.liveSub, 'bandwidth')

      if (allocatedVal < 1) {
        mappedUnit = 'MB'
      } else if (allocatedVal < 1000) {
        mappedUnit = 'GB'
      }

      let val = allocatedVal
      if (allocatedVal < 1) {
        val *= 1000
      } else if (allocatedVal >= 1000) {
        val = (allocatedVal / 1000).toFixed(3)
      }
      return { value: val, mappedUnit }
    }
  },
  async mounted() {
    this.$store.dispatch('Ui/setLoadingStatus', true)
    try {
      const data = await OriginService.getOrigins()
      this.origins = _.map(data, (item, idx) => {
        item.originProcessing = false
        item.originUrlInput = null
        item.bandwidthUsage = null
        return item
      }).reverse()

    } catch (err) {
      console.error(err)
    }

    setTimeout(async () => {
      const originBandwidth = await OriginService.getUserOriginBandwidth()
      this.totalOriginBandwidth = originBandwidth.bytes
      await this.getTotalBandwidthUsage()
      await this.loadOriginBandwidth()
    }, 0)

    this.$store.dispatch('Ui/setLoadingStatus', false)
  },
  methods: {
    async getTotalBandwidthUsage() {
      const addonSub = this.liveSub
      const bwLimit = this.getSubscriptionDefProp(addonSub, 'bandwidth')
      if (bwLimit) {
        let bwBytes = 0
        try {
          let res = await MetricsService.getSubscriptionBandwidth(UserService.getUserId(this.activeTeam), addonSub.package._id)
          bwBytes = res ? res.bytes : 0
          bwBytes = bwBytes >= 100000000 ? bwBytes : 0; // only show bandwidth > 100MB
        } catch (e) { console.log('e', e) }

        this.totalBandwidthUsage = bytes(bwBytes, true, 3, true)

        let { value, mappedUnit } = this.totalBandwidthLimit
        if (mappedUnit === 'TB') {
          value = value * 1000 ** 4
        }
        if (mappedUnit === 'GB') {
          value = value * 1000 ** 3
        }
        if (mappedUnit === 'MB') {
          value = value * 1000 * 1000
        }

        if (bwBytes > value) {
          this.bandwidthLimitReachedMsg = true
        }
      }
    },
    getSubscriptionDefProp(sub, prop) {
      return _.get(sub, 'definitionOverride.' + prop) || _.get(sub, 'package.definition.' + prop)
    },
    onEditOrigin(index) {
      this.currentIndexed = index
      this.$root.$emit("bv::show::modal", 'modal-edit-origin');
    },
    async editOriginUrl(url) {
      const originFiltered = url.replace('https://', '').replace('http://', '')
      const index = this.currentIndexed
      try {
        await OriginService.updateOrigin(this.origins[index]._id, { originUrl: originFiltered })
        this.origins[index].originUrl = originFiltered
        this.currentIndexed = null
        this.$notify({ group: 'success', text: 'CDN url updated' })
      } catch (err) {
        console.error(err)
        this.$notify({
          group: 'error',
          text: err.message || 'Edit CDN failed'
        });
      }
    },
    resizeTextarea: function (e) {
      let area = e.target;
      area.style.height = area.scrollHeight + 'px';
    },
    preventEnter(e) {
      e.preventDefault();
    },
    originCreated(origin) {
      this.origins.unshift(Object.assign(origin, { originUrlInput: null, originUrl: null, originProcessing: null }))
    },
    async onOriginNameChange(index) {
      if (this.origins[index].name === '') {
        return
      }

      try {
        await OriginService.updateOrigin(this.origins[index]._id, { name: this.origins[index].name })
        this.$notify({ group: 'success', text: 'CDN name changed' })
      } catch (err) {
        console.error(err)
        this.$notify({
          group: 'error',
          text: err.message || 'Change CDN name failed'
        });
      }
    },
    async loadOriginBandwidth() {
      _.map(this.origins, async (item, index) => {
        try {
          const data = await OriginService.getOriginServiceBandwidth(item._id)
          this.origins[index].bandwidthUsage = bytes(data.bytes, true, 3, true)
        } catch (err) {
          console.error(err)
        }
      })
    },
    async addOriginUrl(index) {
      this.origins[index].originProcessing = true
      const originFiltered = this.origins[index].originUrlInput.replace('https://', '').replace('http://', '')
      try {
        const origin = await OriginService.updateOrigin(this.origins[index]._id, { originUrl: originFiltered })
        Vue.set(this.origins, index, Object.assign(origin, { originProcessing: null, originUrl: originFiltered }))
        this.$notify({ group: 'success', text: 'CDN added' })
      } catch (err) {
        console.error(err)
        this.$notify({
          group: 'error',
          text: err.message || 'Add CDN failed'
        });
      }

      this.origins[index].originProcessing = false
    },
    async onRemoveOrigin(index = null) {
      this.currentIndexed = index
      this.$root.$emit("bv::show::modal", 'delete-cdn');
    },
    async removeOrigin() {
      try {
        await OriginService.deleteOrigin(this.origins[this.currentIndexed]._id)
        this.origins.splice(this.currentIndexed, 1);
        this.$notify({ group: 'success', text: 'CDN resource removed' })
      } catch (err) {
        console.error(err)
        this.$notify({
          group: 'error',
          text: err.message || 'remove CDN failed'
        });
      }
    },
    async toggleOrigin(index) {
      try {
        await OriginService.toggleOrigin(this.origins[index]._id)
        this.origins[index].enabled = !this.origins[index].enabled
      } catch (err) {
        console.error(err)
        this.$notify({
          group: 'error',
          text: err.message || 'toggle CDN failed'
        });
      }
    },
    async toggleFeature(featureName) {
      if (featureName === 'responsive' || featureName === 'audioOnly' || featureName === 'loop' || featureName === 'muted' || featureName === 'hiddenControl' || featureName === 'hiddenVolume') {
        this.featureProcessing[featureName] = !this.featureProcessing[featureName]
      }
    },
    clipboardCopy(text) {
      try {
        if (text instanceof Function) text = text();

        this.$copyText(text);
        this.$notify({ group: 'info', text: 'Copied to clipboard' });
      } catch (e) { }
    },
  },
};
</script>

<style scoped>
.origin-item-container {
  border-radius: 6px;
}

.tooltip-container {
  max-width: 750px !important;
}

:deep(.tooltip-container .tooltip-inner) {
  max-width: none;
}

.tooltip-content {
  text-align: left;
  padding: 5px;
}

.tooltip-input {
  color: red;
}

.page-title {
  font-size: 23px !important;
}

.example-links-container {
  background-color: var(--c-dark-3);
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  margin-left: 15px;
}</style>
<style>.circle-percent-text-body {
  width: 105% !important;
  height: 90% !important;
}

.circle-percent-text-body>.percent-text {
  font-size: 16px !important;
  font-weight: 500 !important;
}</style>
