<template>
	<div class="">
		<!-- Modal Component -->
		<b-modal
			id="vod-import-modal"
			ref="modalVODVideo"
			content-class="vod-import-modal"
			size="xl"
			centered
      scrollable
		>
    <template #modal-header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <h5 class="modal-title">VOD Bucket</h5>
        <small>File size limit: 1 GB.</small>
      </div> 
    </template>
    <b-button variant="link" @click="getVODListBack" class="btn-lg btn-back d-flex align-items-center" v-bind:class="{ active: filesVisible }">
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 13L1 7L7 1" stroke="#FAFAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span class="ml-2">Go Back</span>
    </b-button>
    <ul 
      class="vod-list"
      v-if="this.filesVisible === false"
    >
      <li
        v-for="stream in filterStreams('vod')"
        :key="stream._id"
        class="vod-list__item"
      >
     
      <VOD-card-view
        :stream="stream"
        :compact-mode="false"
        @chosenFolder="chooseFolder"
      />
      </li>
    </ul>
    <ul 
      class="vod-list" 
      v-if="this.filesVisible === true"
    >
      <li
        v-for="stream in streams"
        :key="stream._id"
        class="vod-list__item my-1"
      >
     
      <VOD-card-view
        :stream="stream"
        :compact-mode="false"
        @chosenFolder="chooseFolder"
      />
      </li>
    </ul> 
		</b-modal>
	</div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from 'vuex';
import ClipLoader from "@/components/ui/ClipLoader.vue";

import IconBase from "@/components/icon/IconBase.vue";
import IconReorder from "@/components/icon/IconReorder.vue";
import StreamService from "@/services/StreamService";
import Utils from "@/utils";
import VODCardView from "@/components/ui/VODCardView.vue";
import AddVodModal from "@/components/modals/AddVodModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import UserService from "@/services/UserService";
import SubscriptionService from "@/services/SubscriptionService";
import MetricsService from "@/services/MetricsService";

export default {
  name: 'VODListModal',
  components: {
    ClipLoader,
    VODCardView,
    AddVodModal,
    ConfirmModal,
    IconBase,
    IconReorder,
	},
	data() {
    return {
      VODFolderID: "",
      filesVisible: false,
      searchWidgetActive: false,
      searchWidgetFocused: false,
      searchWidgetInput: "",

      deleteModalConfiguredStream: null,
      loading: true,
      streams: [],
      streamCreateDropdownActive: false,
      rawStreams: [],
      userSubscription: [],
      usedBandwidth: {
        value: '0 MB',
        loaded: false
      },
      categoryNameMaps: {
        live: "Live Streaming",
        restream: "Multistream",
        ipcam: "IP Camera",
        vod: "Video Hosting",
        scheduled: "Scheduled Stream",
      },
      categoryCollapseHash: {
        restream: true,
        live: true,
        vod: true,
        ipcam: true,
        scheduled: true,
      },
      sortBy: "Sort by Oldest",
    };
  },
  computed: {
    ...mapGetters({
			baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions'
		}),
    getSubscriptions() {
      return this.$store.state.User.subscriptions;
    },
    filterStreams() {
      return (categoryName) => {
        // let orderedStream = _.orderBy(this.streams, ['creationTime'],['desc']);
        let orderedStream = _.orderBy(this.streams, ['desc']);
        const filteredStreams = orderedStream.filter((s) => {
          let bool = s.type === categoryName;
          if (bool && this.searchWidgetInput) {
            const searchStr = this.searchWidgetInput.toLowerCase();
            bool = s.name.toLowerCase().includes(searchStr);
          }
          return bool;
        });
        return filteredStreams;
      };
    },
    activeTeam(){
			return this.$store.state.User.activatedTeam;
		}
  },
  async mounted() {
    try {
      const streams = await StreamService.getUserStreams();
      this.userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions};;
      setTimeout(() => {
        this.streams = streams;
        this.loading = false;
        this.getUsedBandwidth();
      }, 100);
    } catch (err) {
      this.$notify({ group: "error", title: err.error, text: err.message });
      this.loading = false;
      return;
		}

    const streamsCount = _.size(this.streams);

    // update window title
    this.$emit("updateTitle", "Dashboard - VOD Bucket");

    this.onViewLoaded();
  },
  methods: {
    async chooseFolder(value) {
			let streamId = value;
			// this.$emit('chosenFolder', streamId);
      if (this.filesVisible === false) {
        this.filesVisible = true;
          try {
          const result = await StreamService.getStreamPlaylist(streamId);
          setTimeout(() => {
            this.streams = result;
            this.loading = false;
          }, 100);
        } catch (err) {
          this.$notify({ group: "error", title: err.error, text: err.message });
          this.loading = false;
          return;
        }
      }
    },
    async getVODListBack() {
      this.filesVisible = false
			try {
        const streams = await StreamService.getUserStreams();
        setTimeout(() => {
          this.streams = streams;
          this.loading = false;
        }, 100);
      } catch (err) {
        this.$notify({ group: 'error', title: err.error, text: err.message });
        this.loading = false;
        return;
      }
		},
    toggleFilesVisible() {
      this.filesVisible = false;
    },
    getTotalfiles() {
      return this.filterStreams("vod").reduce(
        (tf, item) => tf + item.totalFiles,
        0
      );
    },
    getTotalstorage() {
      let totalBytes = this.filterStreams("vod").reduce(
        (tf, item) => tf + item.totalBytes,
        0
      );
      const k = 1000;
      const decimals = 2;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(totalBytes) / Math.log(k));
      totalBytes = parseFloat((totalBytes / Math.pow(k, i)).toFixed(dm));
      return {
        totalBytes: totalBytes ? totalBytes : 0,
        unit: sizes[i],
      };
    },
    getMaxstorage() {
      if (
        this.userSubscription.maxVodStorage &&
        this.userSubscription.maxVodStorage > 0
      ) {
        let totalBytes =
          this.userSubscription.maxVodStorage * 1000 * 1000 * 1000;
        let i = Math.floor(Math.log(totalBytes) / Math.log(1000)),
          sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        return (totalBytes / Math.pow(1000, i)).toFixed(2) * 1 + " " + sizes[i];
      } else {
        return 0;
      }
    },
    getUsedBandwidth() {
      (async function updateCounter() {
        let totalBytes = 0;
        const subs = _.filter(this.$store.state.User.subscriptions, (item) => ['vod', 'live'].indexOf(item.category) > -1)
        await Promise.all(subs.map(async (item) => {
          const result = await MetricsService.getSubscriptionBandwidth(UserService.getUserId(this.activeTeam), item.package._id)
          totalBytes += result.bytes || 0;
        })).catch(error => {
          console.log(error);
          return this.usedBandwidth.loaded = false;
        });
        if (_.isNumber(totalBytes) && totalBytes > 0) {
          let i = Math.floor(Math.log(totalBytes) / Math.log(1000)), sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
          this.usedBandwidth.value = (totalBytes / Math.pow(1000, i)).toFixed(2) * 1 + " " + sizes[i];
        }
        this.usedBandwidth.loaded = true;
			}.bind(this)());
    },
    updateStreamPulse(streamId, pulseData) {
      const sindex = _.findIndex(this.streams, (s) => s._id === streamId);
      if (sindex > -1) {
        const updatedStream = { ...this.streams[sindex], pulse: pulseData };
        this.streams = Utils.updateArrayItem(
          this.streams,
          updatedStream,
          sindex
        );
      }
    },
    onViewLoaded() {
      const qs = Utils.parseQueryString(window.location.search) || {};
      if (qs.action === "newstream") {
        let streamCreationType = qs.streamtype || "";
        streamCreationType =
          streamCreationType === "restream" ? "" : streamCreationType;
        if (streamCreationType) streamCreationType += "-";

        const streamCreationModalId = `modal-add-${streamCreationType}channel`;

        this.$root.$emit("bv::show::modal", streamCreationModalId);
      }
    },
    onStreamCreateToggle(state) {
      this.streamCreateDropdownActive = state;
    },
    onNewStream(stream, regionDetails) {
      this.streams = [...this.streams, stream];
      this.$notify({ group: "success", text: "Stream deployed successfully" });

      // setTimeout(() => {
      let redirectPath = "/multistreams/";
      if (stream.type === "live") {
        redirectPath = "/livestreams/";
      } else if (stream.type === "ipcam") {
        redirectPath = "/ipcams/";
      } else if (stream.type === "scheduled") {
        redirectPath = "/scheduled/";
      } else if (stream.type === "vod") {
        redirectPath = "/vods/";
      }

      redirectPath += stream._id;
      this.$router.push({ path: redirectPath });
      // }, 3000)

      // track event
      window.trackEvent(
        `Deployed new stream ${stream.name}${
          regionDetails ? ` in ${regionDetails.name}` : ""
        }`,
        stream
      );
    },
    onStreamDeleteRequest(stream) {
      this.deleteModalConfiguredStream = stream;
      this.$root.$emit("bv::show::modal", "modal-confirm");
    },
    async onStreamDeleteConfirm() {
      // pop stream out of the list
      const index = this.streams.indexOf(this.deleteModalConfiguredStream);
      this.streams = _.concat(
        this.streams.slice(0, index),
        this.streams.slice(index + 1)
      );

      try {
        await StreamService.deleteStream(this.deleteModalConfiguredStream._id);

        // track event
        const removedStream = this.deleteModalConfiguredStream;
        window.trackEvent(
          `Deleted stream ${removedStream.name}`,
          removedStream
        );
      } catch (err) {
        this.$notify({ group: "error", title: err.error, text: err.message });
        // push stream back to list
        this.onNewStream(this.deleteModalConfiguredStream);
      }

      this.deleteModalConfiguredStream = null;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.vod-import-modal.modal-content {
  background-color: #182039!important;
}
.vod-import-modal .modal-title {
  font-size: 20px;
  font-weight: 300;
}
button.btn-back.btn-link, button.btn-back.btn-link:visited, button.btn-back.btn-link:hover, button.btn-back.btn-link:active, button.btn-back.btn-link:focus {
  font-size: 16px;
  color: #FAFAFB;
  border-width: 1px solid transparent;
}
.btn-back.btn-link {
  visibility: hidden;
}
.btn-back.btn-link.active {
  visibility: visible;
}
</style>
