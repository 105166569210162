import StreamService from "../../services/StreamService";
import { sortTagsUtil } from "../../utils";

const defaultState = () => ({
  shouldShowCompactModeStreams: false,
  loadingStatus: true,
  tags: [],
  uploadFiles: [],
  streamToUpload: null,
  sortBy: "A to Z",
  sidebarExpanded: true,
});

const state = defaultState();

const mutations = {
  SET_COMPACT_MODE_STREAMS(state, bool) {
    state.shouldShowCompactModeStreams = bool;
  },
  SET_LOADING_STATUS(state, bool) {
    state.loadingStatus = bool;
  },
  ADD_TAG(state, tag) {
    state.tags.push(tag);
  },
  CHANGE_TAG_COLOR(state, { tag, color }) {
    let index = state.tags.findIndex(
      (generalTag) => generalTag.title == tag.title
    );
    state.tags[index].color = color.name;
  },
  async CHANGE_TAG_NAME(state, { tag, newTagName }) {
    let index = state.tags.findIndex((generalTag) => generalTag.id == tag.id);
    state.tags[index].title = newTagName;
    await StreamService.updateTagById(tag.id, {
      title: newTagName,
      color: tag.color,
    });
  },
  DELETE_TAG(state, tag) {
    let index = state.tags.findIndex(
      (generalTag) => generalTag.title == tag.title
    );
    state.tags.splice(index, 1);
  },
  UPDATE_TAGS(state, newTags) {
    state.tags = newTags;
  },
  SORT_TAGS(state, option) {
    state.tags = state.tags.sort(function (a, b) {
      if (option == "Newest first") {
        return new Date(b.creationTime) - new Date(a.creationTime);
      }
      if (option == "Oldest first") {
        return new Date(a.creationTime) - new Date(b.creationTime);
      }
    });
  },
  SET_SORT_TAGS(state, option) {
    state.sortBy = option;
  },
  ADD_FILE_TO_UPLOAD(state, file) {
    state.uploadFiles = [file, ...state.uploadFiles];
  },
  CANCEL_VIDEO_UPLOAD(state, file) {
    const index = state.uploadFiles.findIndex(
      (item) => item.fileName === file.fileName && item.bytes === file.bytes
    );
    state.uploadFiles[index].uploading = false;
  },
  DROP_VIDEO_STATUS(state, file) {
    const index = state.uploadFiles.findIndex(
      (item) => item.fileName === file.fileName && item.bytes === file.bytes
    );
    state.uploadFiles[index].statusProcessing = false;
  },
  REMOVE_FILE_FROM_UPLOAD(state, file) {
    const index = state.uploadFiles.findIndex(
      (item) => item.fileName === file.fileName && item.bytes === file.bytes
    );
    state.uploadFiles.splice(index, 1);
  },
  DROP_FILES_TO_UPLOAD(state) {
    state.uploadFiles = [];
  },
  ADD_FILE_MAPPED_INFO(state, { uploadItem, mappedInfo }) {
    const index = state.uploadFiles.findIndex(
      (item) =>
        item.fileName === uploadItem.fileName && item.bytes === uploadItem.bytes
    );
    state.uploadFiles[index].mediaInfo = mappedInfo;
  },
  ADD_FILE_DURATION_EXCEEDED(state, uploadItem) {
    const fileIndex = state.uploadFiles.findIndex(
      (item) =>
        item.fileName === uploadItem.fileName && item.bytes === uploadItem.bytes
    );
    state.uploadFiles[fileIndex].durationExceeded = true;
  },
  ADD_ENCODING_PRESETS(state, { uploadItem, encodingPresets }) {
    const index = state.uploadFiles.findIndex(
      (item) =>
        item.fileName === uploadItem.fileName && item.bytes === uploadItem.bytes
    );
    state.uploadFiles[index].encodingPresets = encodingPresets;
  },
  ADD_STATUS_PROCESSING(state, { uploadItem, statusProcessing }) {
    const index = state.uploadFiles.findIndex(
      (item) =>
        item.fileName === uploadItem.fileName && item.bytes === uploadItem.bytes
    );
    state.uploadFiles[index].statusProcessing = statusProcessing;
  },
  ADD_STATUS_UPLOADING(state, { uploadItem, uploading }) {
    const index = state.uploadFiles.findIndex(
      (item) =>
        item.fileName === uploadItem.fileName && item.bytes === uploadItem.bytes
    );
    state.uploadFiles[index].uploading = uploading;
  },
  ADD_CANCEL_SOURCE(state, { uploadItem, cancelSource }) {
    const index = state.uploadFiles.findIndex(
      (item) =>
        item.fileName === uploadItem.fileName && item.bytes === uploadItem.bytes
    );
    state.uploadFiles[index].cancelSource = cancelSource;
  },
  ADD_UPLOADING_PROGRESS(state, { uploadItem, uploadProgress }) {
    const index = state.uploadFiles.findIndex(
      (item) =>
        item.fileName === uploadItem.fileName && item.bytes === uploadItem.bytes
    );
    state.uploadFiles[index].uploadProgress = uploadProgress;
  },
  ADD_UPLOADED(state, { uploadItem, uploaded }) {
    const index = state.uploadFiles.findIndex(
      (item) =>
        item.fileName === uploadItem.fileName && item.bytes === uploadItem.bytes
    );
    state.uploadFiles[index].uploadProgress = uploaded;
  },
  ADD_UPLOAD_ERROR(state, { uploadItem, uploadError }) {
    const index = state.uploadFiles.findIndex(
      (item) =>
        item.fileName === uploadItem.fileName && item.bytes === uploadItem.bytes
    );
    state.uploadFiles[index].uploadError = uploadError;
  },
  ADD_ADV_MEDIA(state, { uploadItem, advMedia }) {
    const index = state.uploadFiles.findIndex(
      (item) =>
        item.fileName === uploadItem.fileName && item.bytes === uploadItem.bytes
    );
    state.uploadFiles[index].mediaInfo.advMedia = advMedia;
  },
  ADD_STREAM_TO_UPLOAD(state, stream) {
    state.streamToUpload = stream;
  },
  ADD_UPLOAD_RESULT(state, { uploadItem, uploadResult }) {
    const index = state.uploadFiles.findIndex(
      (item) =>
        item.fileName === uploadItem.fileName && item.bytes === uploadItem.bytes
    );
    state.uploadFiles[index].id = uploadResult.fileId;
    state.uploadFiles[index].mediaInfo = uploadResult.mediaInfo;
    state.uploadFiles[index].creationTime = new Date();
    if (uploadResult.encodingRequired) {
      state.uploadFiles[index].encodingRequired = true;
      state.uploadFiles[index].encodingStatus = "processing";
    }
    state.uploadFiles[index].uploadable = false;
  },
  DROP_STREAM_TO_UPLOAD(state) {
    state.streamToUpload = null;
  },
  TOGGLE_SIDEBAR_EXPANDED(state) {
    state.sidebarExpanded = !state.sidebarExpanded;
  },
	EXPAND_SIDEBAR(state) {
		state.sidebarExpanded = true
	}
};

const actions = {
  async setLoadingStatus({ commit }, bool) {
    commit("SET_LOADING_STATUS", bool);
  },
  async setCompactModeStreams({ commit }, bool) {
    commit("SET_COMPACT_MODE_STREAMS", bool);
  },
  async getAllTags({ commit }) {
    const result = await StreamService.getAllTags();
    const tags = result.isError ? [] : result.tags;
    commit("UPDATE_TAGS", tags);
  },
  async addTag({ commit }, tag) {
    const result = await StreamService.createNewTags(tag);
    if (!result.isError) {
      commit("UPDATE_TAGS", result.data);
    }
  },
  async changeTagColor({ commit }, { tag, color }) {
    commit("CHANGE_TAG_COLOR", { tag, color });

    if (!("id" in tag)) return;
    await StreamService.updateTagById(tag.id, {
      title: tag.title,
      color: color.name,
    });
  },
  async changeTagName({ commit }, { tag, newTagName }) {
    commit("CHANGE_TAG_NAME", { tag, newTagName });
  },
  async deleteTag({ commit }, tag) {
    commit("DELETE_TAG", tag);

    if (!("id" in tag)) return;
    await StreamService.deleteTagById(tag.id);
  },
  async updateTagsOfVideoFile(
    {},
    { videoId, updatedTagIds, parent, parentId }
  ) {
    const uniqueTagIds = _.uniq(updatedTagIds);
    if (parent && parentId) {
      return await StreamService.updateTagsOfArchivedVideoFile(
        parentId,
        parent.from,
        parent.duration,
        uniqueTagIds
      );
    }

    await StreamService.updateTagsOfVideoFile(videoId, uniqueTagIds);
  },
  async sortTags({ commit }, option) {
    commit("SORT_TAGS", option);
  },
  async setSortTags({ commit }, option) {
    commit("SET_SORT_TAGS", option);
  },
  async addFileToUpload({ commit }, file) {
    commit("ADD_FILE_TO_UPLOAD", file);
  },
  async removeFileFromUpload({ commit }, file) {
    commit("REMOVE_FILE_FROM_UPLOAD", file);
  },
  async dropVideoStatus({ commit }, file) {
    commit("DROP_VIDEO_STATUS", file);
  },
  async cancelVideoUpload({ commit }, file) {
    commit("CANCEL_VIDEO_UPLOAD", file);
  },
  dropFilesToUpload({ commit }) {
    commit("DROP_FILES_TO_UPLOAD");
  },
  async addFileData(
    { commit },
    {
      uploadItem,
      mappedInfo,
      durationExceeded,
      encodingPresets,
      statusProcessing,
      uploading,
      uploaded,
      cancelSource,
      uploadProgress,
      uploadError,
      advMedia,
      uploadResult,
    }
  ) {
    if (mappedInfo) {
      commit("ADD_FILE_MAPPED_INFO", { uploadItem, mappedInfo });
    }
    if (durationExceeded) {
      commit("ADD_FILE_DURATION_EXCEEDED", uploadItem);
    }
    if (encodingPresets) {
      commit("ADD_ENCODING_PRESETS", { uploadItem, encodingPresets });
    }
    if (statusProcessing) {
      commit("ADD_STATUS_PROCESSING", { uploadItem, statusProcessing });
    }
    if (uploading) {
      commit("ADD_STATUS_UPLOADING", { uploadItem, uploading });
    }
    if (cancelSource) {
      commit("ADD_CANCEL_SOURCE", { uploadItem, cancelSource });
    }
    if (uploadProgress) {
      commit("ADD_UPLOADING_PROGRESS", { uploadItem, uploadProgress });
    }
    if (uploaded) {
      commit("ADD_UPLOADED", { uploadItem, uploaded });
    }
    if (uploadError) {
      commit("ADD_UPLOAD_ERROR", { uploadItem, uploadError });
    }
    if (advMedia) {
      commit("ADD_ADV_MEDIA", { uploadItem, advMedia });
    }
    if (uploadResult) {
      commit("ADD_UPLOAD_RESULT", { uploadItem, uploadResult });
    }
  },
  async addStreamToUpload({ commit }, stream) {
    commit("ADD_STREAM_TO_UPLOAD", stream);
  },
  dropStreamToUpload({ commit }) {
    commit("DROP_STREAM_TO_UPLOAD");
  },
  toggleSidebarExpanded({ commit }, value) {
    commit("TOGGLE_SIDEBAR_EXPANDED", value);
  },
	expandSidebar({ commit }) {
    commit("EXPAND_SIDEBAR");
  },
};

const getters = {
  loadingStatus: (state) => state.loadingStatus,
  uploadFiles: (state) => state.uploadFiles,
  streamToUpload: (state) => state.streamToUpload,
  sidebarExpanded: (state) => state.sidebarExpanded,
  generalTags: (state) => {
    const tags = state.tags;
    return sortTagsUtil(tags, state.sortBy);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
