<script setup>
import _ from 'lodash'
import { ref, onMounted, watch, computed, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import FormButton from '../Atoms/FormButton.vue'
import StreamService from '@/services/StreamService'
import FormInput from '../Atoms/FormInput.vue'
import Alert from '../Atoms/Alert.vue'
import FormUpload from '../Atoms/FormUpload.vue'
import PaywallService from '@/services/PaywallService'

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const props = defineProps({
  vodFile: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  vodFolder: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  playerUrl: {
    type: String
  },
  streamMeta: {
    type: Object,
  }
})

const title = ref('')
const description = ref('')
const embedPosterTemp = ref(null)
const endEmbedPosterTemp = ref(null)
const embedPoster = ref({
  error: false,
  enabled: false,
  value: null,
  valueType: 'string'
})
const endEmbedPoster = ref({
  error: false,
  enabled: false,
  value: null,
  valueType: 'string'
})
const videoMeta = ref(null)

const isLoading = ref({
  title: false,
  description: false
})

const maxPosterUploadMB = ref(null)

const posterUrl = computed(() => {
  if (embedPosterTemp.value) return embedPosterTemp.value

  const imageId = embedPoster.value.value;
  return imageId ? `https://assets.castr.io/embedImages/${imageId}` : null;
})

const endPosterUrl = computed(() => {
  if (endEmbedPosterTemp.value) return endEmbedPosterTemp.value

  const imageId = endEmbedPoster.value.value;
  return imageId ? `https://assets.castr.io/embedImages/${imageId}` : null;
})

onMounted(async () => {
  getMeta()
})

watch([() => route.params, () => props.streamMeta], async () => {
  getMeta()
});

const getMeta = async () => {
  //TODO Move this call in parent component to prevent double Meta call for components (Chaptes, Schedule, etc.)
  videoMeta.value = props.streamMeta?.videoMeta
  if (props.streamMeta?.videoMeta?.title) {
    title.value = props.streamMeta.videoMeta?.title;
  }

  if (props.streamMeta?.videoMeta?.description) {
    description.value = props.streamMeta.videoMeta?.description;
  }
  if (props.streamMeta?.videoMeta?.embedPoster) embedPoster.value.value = props.streamMeta.videoMeta.embedPoster
  if (props.streamMeta?.videoMeta?.endEmbedPoster) endEmbedPoster.value.value = props.streamMeta.videoMeta.endEmbedPoster
}

const onChangeTitle = async () => {
  isLoading.title = true;
  if (title.value.length > 100) {
    title.value = title.value.substring(0, 97) + '...';
  }
  await saveSetting('title', title.value, false, props.vodFile._id);
  videoMeta.value.title = title.value
  isLoading.title = false;
}

const onChangeDescription = async () => {
  isLoading.description = true;
  if (description.value.length > 1000) {
    description.value = description.value.substring(0, 997) + '...';
  }
  await saveSetting('description', description.value, false, props.vodFile._id);
  videoMeta.value.description = description.value
  isLoading.description = false;
}

const saveSetting = async (key, value, successToast = false, videoId) => {
  isLoading.value[key] = true;
  try {
    await StreamService.saveStreamMetadata(route.params.streamId, key, value, videoId);
    // clear stream meta cache in embed player
    StreamService.clearStreamMetadataCache(route.params.streamId);
    if (successToast) {
      notify({ group: 'success', text: 'Setting saved' })
    }
  } catch (e) {
    notify({ group: 'error', text: 'could not save changes' });
  }
  isLoading.value[key] = false;
}

const onEmbedPosterPreview = (file, type) => {
  const selector = type === 'endEmbedPoster' ? '#end-embed-poster-input' : '#embed-poster-input';
  const imageInput = document.querySelector(selector);
  const imageFile = file;
  if (!imageFile) return;

  if (imageFile.type.indexOf('image/') === -1) {
    root.$emit('bv::show::modal', 'embed-upload-format');
    imageInput.value = null;
    return;
  }

  let maxSize = type === 'watermark' ? 1 : 2;
  maxPosterUploadMB.value = maxSize;

  maxSize *= 1024 * 1024;

  if (imageFile.size > maxSize) {
    root.$emit('bv::show::modal', 'embed-image-size-exceed');
    imageInput.value = null;
    return;
  }

  const validImageTypes = ['png', 'jpeg']
  const uploadedImageFileType = imageFile.type.replace('image/', '');
  // if (validImageType && imageFile.type.indexOf(validImageType) === -1) {
  if (
    validImageTypes &&
    validImageTypes.indexOf(uploadedImageFileType) === -1
  ) {
    root.$emit('bv::show::modal', 'embed-image-mime-type');
    imageInput.value = null;
    return;
  }
  imageReader(imageFile, base64 => {
    if (type === 'endEmbedPoster') {
      endEmbedPosterTemp.value = base64;
    } else {
      embedPosterTemp.value = base64;
    }
  });
}

function imageReader(file, cb) {
  if (!file) {
    return;
  }

  var reader = new FileReader();
  reader.onload = function () {
    if (cb) {
      cb(reader.result);
    }
  };

  reader.readAsDataURL(file);
}

const saveEmbedPoster = async (type) => {
  const featureProp = type === 'endEmbedPoster' ? 'endEmbedPoster' : 'embedPoster';
  featureProp === 'endEmbedPoster'
    ? endEmbedPoster.value.error = false
    : embedPoster.value.error = false;

  const selector = featureProp === 'endEmbedPoster' ? '#end-embed-poster-input' : '#embed-poster-input';
  const imageInput = document.querySelector(selector);
  const imageFile = imageInput.files[0];

  if (!imageInput.value || !imageFile) {
    featureProp === 'endEmbedPoster'
      ? endEmbedPoster.value.error = 'please pick an image file'
      : embedPoster.value.error = 'please pick an image file';
    return;
  }

  isLoading.value[featureProp] = true;

  // -- upload reuqest --
  const fdata = new FormData();
  fdata.append('file', imageFile);
  const res = await StreamService.uploadStreamPoster(
    props.vodFolder._id,
    fdata,
    type === 'endEmbedPoster',
    props.vodFile._id
  );

	if (res.success) {
    if (featureProp === 'endEmbedPoster') {
      endEmbedPosterTemp.value = null;
    } else {
			const poster = `https://assets.castr.io/embedImages/${res.uploadId}`;
			PaywallService.updateStreamPaywall(props.vodFolder._id, { wallpaper_url: poster }, props.vodFile.id).catch(err => {
				console.error('update paywall stream poster failed: ', err)
			});
      embedPosterTemp.value = null;
    }

    type === 'endEmbedPoster'
      ? endEmbedPoster.value.value = res.uploadId
      : embedPoster.value.value = res.uploadId

    notify({
      group: 'success',
      text: `${featureProp === 'endEmbedPoster' ? 'End Embed' : 'Poster'} uploaded with success`
    });
  } else {
    type === 'endEmbedPoster'
      ? endEmbedPoster.value.error = 'could not handle image upload. Please try again later'
      : embedPoster.value.error = 'could not handle image upload. Please try again later'
  }

  isLoading.value[featureProp] = false;
}

const cancelUpload = async (type) => {
  const selector = type === 'endEmbedPoster' ? '#end-embed-poster-input' : '#embed-poster-input';
  if (type === 'endEmbedPoster') {
    endEmbedPosterTemp.value = null;
    endEmbedPoster.value.error = false;
    endEmbedPoster.value.value = null;
  } else {
    embedPosterTemp.value = null;
    embedPoster.value.error = false;
    embedPoster.value.value = null;
  }

  document.querySelector(selector).value = null;


  removePoster(type);
}

const removePoster = async (type) => {
  try {
    const settingsProp =
      type === 'endEmbedPoster' ? 'endEmbedPoster' : 'embedPoster';
    await saveSetting(settingsProp, null, true, props.vodFile._id);
    type.value = null;
  } catch (e) {
    console.log(e);
  }
}

</script>

<template>
  <section>
    <div class="flex flex-col gap-y-3 items-start !mt-4">
      <FormInput label="Title" v-model="title" :maxlength="100" />
      <FormButton :label="isLoading.title ? 'Saving ..' : 'Save'"
        :disabled="isLoading.title || title === (videoMeta?.title || '')" @click="onChangeTitle" />
      <label for="description" class="mb-0 text-sm">Description</label>
      <textarea id="description" class="bg-surface-3 w-full rounded-md p-2 text-sm block" rows="3"
        v-model="description"></textarea>
      <FormButton :label="isLoading.description ? 'Saving ..' : 'Save'"
        :disabled="isLoading.description || description === (videoMeta?.description || '')"
        @click="onChangeDescription" />
    </div>
    <hr class="border-surface-3 !my-4">
    <div class="flex flex-col gap-y-3 items-start">
      <label for="embed-poster-input" class="mb-0 text-sm">Thumbnail</label>
      <div class="flex gap-x-2 items-center w-full">
        <img v-if="posterUrl" :src="posterUrl" alt="thumbnail image" class="size-[100px]">
        <p v-else class="text-tiny text-surface-6">No file choosen</p>
        <FormUpload class="ml-auto" name="poster" id="embed-poster-input" placeholder="No file chosen"
          :label="embedPoster.value ? 'Change' : 'Select Thumbnail'" type="secondary" size="sm"
          :on-file-change="onEmbedPosterPreview" />
        <FormButton v-if="embedPosterTemp" :label="isLoading.embedPoster ? 'Saving' : 'Save Thumbnail'"
          :disabled="isLoading.embedPoster" size="sm" @click="saveEmbedPoster" />
        <FormButton v-if="embedPosterTemp || embedPoster.value" :disabled="isLoading.embedPoster" type="secondary"
          size="sm" @click="cancelUpload">
          <span v-if="embedPosterTemp && embedPoster.value">Restore</span>
          <span v-else-if="embedPosterTemp">Cancel</span>
          <span v-else>Remove</span>
        </FormButton>
      </div>
      <Alert type="error" v-show="embedPoster.error" :text="embedPoster.error" />
    </div>
    <hr class="border-surface-3 !my-4">
    <div class="flex flex-col gap-y-3 items-start">
      <label for="embed-poster-input" class="mb-0 text-sm">End Embed Poster</label>
      <div class="flex gap-x-2 items-center w-full">
        <img v-if="endPosterUrl" :src="endPosterUrl" alt="thumbnail image" class="size-[100px]">
        <p v-else class="text-tiny text-surface-6">No file choosen</p>
        <FormUpload class="ml-auto" name="endEmbedPoster" id="end-embed-poster-input" placeholder="No file chosen"
          :label="endEmbedPoster.value ? 'Change' : 'Select Poster'" type="secondary" size="sm"
          :on-file-change="onEmbedPosterPreview" />
        <FormButton v-if="endEmbedPosterTemp" :label="isLoading.endEmbedPoster ? 'Saving' : 'Save Poster'"
          :disabled="isLoading.endEmbedPoster" size="sm" @click="saveEmbedPoster('endEmbedPoster')" />
        <FormButton v-if="endEmbedPosterTemp || endEmbedPoster.value" :disabled="isLoading.endEmbedPoster"
          type="secondary" size="sm" @click="cancelUpload('endEmbedPoster')">
          <span v-if="endEmbedPosterTemp && endEmbedPoster.value">Restore</span>
          <span v-else-if="endEmbedPosterTemp">Cancel</span>
          <span v-else>Remove</span>
        </FormButton>
      </div>
      <Alert type="error" v-show="endEmbedPoster.error" :text="endEmbedPoster.error" />
    </div>
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
