<template>
    <div class="card-wrapper">
        <div class="card-content">
            <slot />
        </div>
    </div>
</template>

<style>
.card-wrapper {
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card-content {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}
</style>