<template>
	<div class="">
		<b-modal
			:id="modalId"
			ref="StreamAbrSettingsModal"
			:size="modalSize"
			hide-header
			hide-footer
			centered
		>
		<b-row>
			<b-col>Change FPS</b-col>
		</b-row>
		<b-row>
			<b-col class="text-s-s text-dark-8">Default FPS for transcoding is 30 FPS. If you're streaming at 60 FPS, you can change it here.</b-col>
		</b-row>
        <b-row class="ml-1 mt-4">
            <b-form-radio-group v-model="selectedFps" class="mb-2">
                <b-form-radio value="30">30fps</b-form-radio>
                <b-form-radio value="60">60fps</b-form-radio>
            </b-form-radio-group>
        </b-row>
		<b-row >
			<b-col class="text-right mt-3">
				<b-button
					variant="outline-secondary"
					@click="dismiss"
					class="mr-2"
					size="md"
				>
					Cancel
				</b-button>
				<b-button
					variant="primary"
                    :disabled="(Number(fps) === Number(selectedFps))"
					size="md"
					@click="onSave"
				>
                    Save Changes
				</b-button>
			</b-col>
		</b-row>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'StreamAbrSettingsModal',
	components: {},
	props: {
		modalId: {
			type: String,
			default: 'StreamAbrSettingsModal'
		},
		modalSize: {
			type: String,
			default: 'sm',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
        fps: {
            type: Number,
            default: 30
        },
        stream: {
            type: Object,
			required: true
        },
	},
	data() {
		return {
			processing: false,
            selectedFps: this.fps
		};
	},
	methods: {
		onSave() {
			this.$emit('abr-fps-changed', this.selectedFps);
			this.dismiss();
		},
		dismiss() {
			this.$refs.StreamAbrSettingsModal.hide();
			// lazy clear
			setTimeout(() => { this.processing = false; }, 1000);
		}
	},
};
</script>

<style scoped>

</style>
