<template>
	<div class="mt-3">
		<div class="feature-item">
      <label class="text-s-m font-weight-normal mb-1">Custom Embed Poster</label>
      <b-row no-gutters align-v="center" align-h="between">
        <b-col v-if="posterUrl" lg="2">
          <div class="poster-thumb">
            <b-img width="100px" :src="posterUrl" class="w-100" />
          </div>
        </b-col>
        <b-col lg="6">
          <b-file id="embed-poster-input" size="xl" accept=".jpg, .png" :placeholder="player.metadata && player.metadata.embedPoster ? '' :'No file chosen'" class="custom-upload_dark-3"
            drop-placeholder="Drop file here..." @change="onEmbedPosterPreview" />
        </b-col>
        <b-col lg="4" class="d-flex justify-content-md-end">
          <b-button variant="dark-5" size="sm" class="mx-2" v-if="!embedPosterTemp"
            :disabled="featureProcessing.embedPoster" @click="hitUpload">
            <span v-if="player.metadata && player.metadata.embedPoster">Change</span>
            <span v-else>Select Poster</span>
          </b-button>

          <b-button v-if="embedPosterTemp" :disabled="featureProcessing.embedPoster" variant="dark-5" size="sm"
            class="mx-2" @click="saveEmbedPoster">
            <!-- <span v-if="features.embedPoster.value">Save Poster</span> -->
            <span>{{ featureProcessing.embedPoster ? 'Saving ..' : 'Save Poster' }}</span>
          </b-button>

          <b-button v-if="embedPosterTemp || player.metadata && player.metadata.embedPoster" :disabled="featureProcessing.embedPoster"
            variant="outline-secondary" size="sm" @click="cancelUpload">
            <span v-if="embedPosterTemp  ||  player.metadata && player.metadata.embedPoster">Remove</span>
            <span v-else-if="embedPosterTemp">Cancel</span>
            <span v-else>Remove</span>
          </b-button>
        </b-col>
      </b-row>
      <div v-show="features.embedPoster.error" class="alert alert-danger">
        {{ features.embedPoster.error }}
      </div>
    </div>
    <div class="feature-item embedmark">
      <label class="text-s-m font-weight-normal mb-1">Embed Watermark</label>
      <b-row no-gutters align-v="center" align-h="between" class="">
        <b-col v-show="watermarkUrl" lg="2">
          <div class="poster-thumb">
            <b-img width="100px" :src="watermarkUrl" class="w-100" />
          </div>
        </b-col>
        <b-col lg="3">
          <b-file id="embed-watermark-input" accept=".jpg, .png" size="xl" :placeholder="watermarkUrl ? '' : 'No file chosen'" class="custom-upload_dark-3"
            drop-placeholder="Drop file here..." @change="onEmbedPosterPreview('watermark')" />
        </b-col>
        <b-col lg="7" class="d-flex justify-content-md-end position-form" v-show="watermarkUrl">
          <b-form inline>
            <label class="text-s-m text-dark-7 font-weight-normal mr-2 mb-0">Position</label>
            <b-dropdown size="sm" menu-class="dropdown-menu_md w-100 mt-0" toggle-class="dropdown_position"
              variant="dark-3" no-caret class="m-md-2">
              <template #button-content>
                <div class="d-flex align-items-center justify-content-between w-100">
                  <span class="font-weight-normal text-s-s">
                    <span>{{ features.embedWatermarkPosition.title }}</span>
                  </span>
                  <svg class="ml-2" width="10" height="6" viewBox="0 0 10 6" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.26863 2.73137L6.13137 4.86863C5.73535 5.26465 5.53735 5.46265 5.30902 5.53684C5.10817 5.6021 4.89183 5.6021 4.69098 5.53684C4.46265 5.46265 4.26465 5.26465 3.86863 4.86863L1.73137 2.73137C0.874618 1.87462 0.446241 1.44624 0.417296 1.07846C0.392181 0.759341 0.521356 0.447486 0.764766 0.239594C1.04529 0 1.65111 0 2.86274 0L7.13726 0C8.34889 0 8.95471 0 9.23523 0.239594C9.47864 0.447486 9.60782 0.759341 9.5827 1.07846C9.55376 1.44624 9.12538 1.87462 8.26863 2.73137Z"
                      fill="currentColor" />
                  </svg>
                </div>
              </template>
              <b-dropdown-item-button
                @click="features.embedWatermarkPosition.value = 'tr'; features.embedWatermarkPosition.title = 'Top Right'">
                Top Right</b-dropdown-item-button>
              <b-dropdown-item-button
                @click="features.embedWatermarkPosition.value = 'tl'; features.embedWatermarkPosition.title = 'Top Left'">
                Top Left</b-dropdown-item-button>
              <b-dropdown-item-button
                @click="features.embedWatermarkPosition.value = 'cc'; features.embedWatermarkPosition.title = 'Center'">
                Center</b-dropdown-item-button>
              <b-dropdown-item-button
                @click="features.embedWatermarkPosition.value = 'bl'; features.embedWatermarkPosition.title = 'Bottom left'">
                Bottom Left</b-dropdown-item-button>
              <b-dropdown-item-button
                @click="features.embedWatermarkPosition.value = 'br'; features.embedWatermarkPosition.title = 'Bottom Right'">
                Bottom Right</b-dropdown-item-button>
            </b-dropdown>
            <b-button v-show="canSaveWatermarkPosition()" :disabled="featureProcessing.embedWatermarkPosition"
              size="sm" variant="dark-5" class="ml-2" @click="saveWatermarkPosition">
              <span>{{ featureProcessing.embedWatermarkPosition ? 'Saving ..' : 'Save' }}</span>
            </b-button>
            <b-button v-if="!embedWatermarkTemp" :disabled="featureProcessing.embedWatermark" variant="dark-5"
              size="sm" class="ml-2 mr-0 mr-sm-2" @click="hitUpload('watermark')">
              <span v-if="watermarkUrl">Change</span>
              <span v-else>Select Watermark</span>
            </b-button>

            <b-button v-if="embedWatermarkTemp" :disabled="featureProcessing.embedWatermark" variant="dark-5"
              class="mx-2" size="sm" @click="saveEmbedPoster('watermark')">
              <!-- <span v-if="features.embedPoster.value">Save Poster</span> -->
              <span>{{ featureProcessing.embedWatermark ? 'Saving ..' : 'Save' }}</span>
            </b-button>
            <b-button class="ml-2" v-if="embedWatermarkTemp || watermarkUrl"
              :disabled="featureProcessing.embedWatermark" variant="outline-secondary" size="sm"
              @click="cancelUpload('watermark')">
              <span v-if="embedWatermarkTemp && watermarkUrl">Remove</span>
              <span v-else-if="embedWatermarkTemp">Cancel</span>
              <span v-else>Remove</span>
            </b-button>
          </b-form>
        </b-col>
      </b-row>
      <div class="feature-desc">
        <div class="pane">
          <div v-show="features.embedWatermark.error" class="alert alert-danger">
            {{ features.embedWatermark.error }}
          </div>
        </div>
      </div>
    </div>
		<alert-modal :message="'Uploaded image too large. Image must not exceed ' + maxPosterUploadMB + 'MB'"
			modal-id="embed-image-size-exceed" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DynamicService from '@/services/DynamicService'
import PaywallService from '@/services/PaywallService'
import AlertModal from '@/components/modals/AlertModal.vue'

export default {
	name: 'DynamicPlayerInterface',
	components: {
		AlertModal,
	},
	data() {
		return {
      features: {
				embedPoster: {
					error: false,
					enabled: false,
					value: null,
					valueType: 'string'
				},
				embedWatermark: {
					error: false,
					enabled: false,
					value: null,
					valueType: 'string'
				},
				embedWatermarkPosition: {
					error: false,
					enabled: true,
					value: 'tr',
					title: 'Top Right',
					valueType: 'string'
				},
			},
			featureProcessing: {
				embedPoster: false,
				embedWatermark: false,
				embedWatermarkPosition: false,
			},
			embedPosterTemp: null,
			embedWatermarkTemp: null,
			maxPosterUploadMB: 2,
		};
	},
	computed: {
    ...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			player: "DynamicPlayers/dynamicPlayer",
		}),
    posterUrl() {
			if (this.embedPosterTemp) {
				return this.embedPosterTemp;
			}
			const imageId = this.player.metadata && this.player.metadata.embedPoster;
			// return `https://static.castr.io/embedPosters/${imageId}`;
			return imageId ? `https://assets.castr.io/embedImages/${imageId}` : null;
		},
		watermarkUrl() {
			if (this.embedWatermarkTemp) return this.embedWatermarkTemp;
			const imageId = this.player.metadata && this.player.metadata.embedWatermark;
			return imageId ? `https://assets.castr.io/embedImages/${imageId}` : null;
		}
	},
	watch: {
		
	},
	async mounted() {
		
	},
	destroyed() {

	},
	methods: {
		onEmbedPosterPreview(type) {
			const selector = type === 'watermark' ? '#embed-watermark-input' : '#embed-poster-input';
			const imageInput = document.querySelector(selector);
			const imageFile = imageInput.files[0];

			if (!imageFile) return;

			if (imageFile.type.indexOf('image/') === -1) {
				this.$root.$emit('bv::show::modal', 'embed-upload-format');
				imageInput.value = null;
				return;
			}

			let maxSize = type === 'watermark' ? 1 : 2;
			this.maxPosterUploadMB = maxSize;

			maxSize *= 1024 * 1024;

			if (imageFile.size > maxSize) {
				this.$root.$emit('bv::show::modal', 'embed-image-size-exceed');
				imageInput.value = null;
				return;
			}

			const validImageTypes = type === 'watermark' ? ['png', 'jpeg'] : null;
			const uploadedImageFileType = imageFile.type.replace('image/', '');
			// if (validImageType && imageFile.type.indexOf(validImageType) === -1) {
			if (
				validImageTypes &&
				validImageTypes.indexOf(uploadedImageFileType) === -1
			) {
				this.$root.$emit('bv::show::modal', 'embed-image-mime-type');
				imageInput.value = null;
				return;
			}

			imageReader(imageFile, base64 => {
				if (type === 'watermark') {
					this.embedWatermarkTemp = base64;
				} else {
					this.embedPosterTemp = base64;
				}
			});
		},
		async saveEmbedPoster(type) {
			const featureProp = type === 'watermark' ? 'embedWatermark' : 'embedPoster';
			this.features[featureProp].error = false;

			const selector = type === 'watermark' ? '#embed-watermark-input' : '#embed-poster-input';
			const imageInput = document.querySelector(selector);
			const imageFile = imageInput.files[0];

			if (!imageInput.value || !imageFile) {
				this.features[featureProp].error = 'please pick an image file';
				return;
			}

			this.featureProcessing[featureProp] = true;

			// -- upload reuqest --
			const fdata = new FormData();
			fdata.append('file', imageFile);
			const res = await DynamicService.uploadPlayerPoster(
				this.player._id,
				fdata,
				type === 'watermark'
			);

			if (res.success) {
				if (type === 'watermark') {
					await this.saveWatermarkPosition();
					this.embedWatermarkTemp = null;
				} else {
					const poster = `https://assets.castr.io/embedImages/${res.uploadId}`;
					PaywallService.updateStreamPaywall(this.player._id, { wallpaper_url: poster }).catch(err => {
						console.error('update paywall stream poster failed: ', err)
					});
					this.embedPosterTemp = null;
				}

				this.features[featureProp].value = res.uploadId;

				this.$notify({
					group: 'success',
					text: `${type === 'watermark' ? 'Watermark' : 'Poster'} uploaded with success`
				});
			} else {
				this.features[featureProp].error = 'could not handle image upload. Please try again later';
			}

			this.featureProcessing[featureProp] = false;
		},
		async cancelUpload(type) {
			const selector = type === 'watermark' ? '#embed-watermark-input' : '#embed-poster-input';
			const previewFlag = type === 'watermark' ? this.embedWatermarkTemp : this.embedPosterTemp;
			if (previewFlag) {
				if (type === 'watermark') {
					this.embedWatermarkTemp = null;
				} else {
					this.embedPosterTemp = null;
					this.features.embedPoster.error = false;
				}

				document.querySelector(selector).value = null;
				return;
			}

			this.removePoster(type);
		},
		async removePoster(type) {
			try {
				const settingsProp =
					type === 'watermark' ? 'embedWatermark' : 'embedPoster';
				await this.saveSetting(settingsProp, null);
				this.features[settingsProp].value = null;
			} catch (e) {
				console.log(e);
			}
		},
    async saveWatermarkPosition() {
			var newpos = this.features.embedWatermarkPosition.value;
			await this.saveSetting('embedWatermarkPosition', newpos);
			await this.$store.dispatch('DynamicPlayers/getPlayer', this.$route && this.$route.params.playerId);
			// this.streamMeta.embedWatermarkPosition = newpos;
		},
    hitUpload(type) {
			const selector = type === 'watermark' ? '#embed-watermark-input' : '#embed-poster-input';
			document.querySelector(selector).click();
		},
    canSaveWatermarkPosition() {
			return (
				this.features.embedWatermark.value &&
				this.features.embedWatermarkPosition.value !==
				this.player.metadata.embedWatermarkPosition
			);
		},
    async saveSetting(key, value, successToast = false) {
			this.featureProcessing[key] = true;
			try {
				await DynamicService.savePlayerMetadata(this.player._id, key, value);
				// clear stream meta cache in embed player
				DynamicService.clearPlayerMetadataCache(this.player._id);
				await this.$store.dispatch('DynamicPlayers/getPlayer', this.$route && this.$route.params.playerId);
				if (successToast) {
					this.$notify({ group: 'success', text: 'Setting saved' })
				}
				
			} catch (e) {
				this.$notify({ group: 'error', text: 'could not save changes' });
			}
			if (key == 'ottEnabled') {
				this.ottProcessing = false
			}
			this.featureProcessing[key] = false;
		}
	},
}
function imageReader(file, cb) {
	if (!file) {
		return;
	}

	var reader = new FileReader();
	reader.onload = function () {
		if (cb) {
			cb(reader.result);
		}
	};

	reader.readAsDataURL(file);
}
</script>

<style scoped>
:deep(.b-form-file > .custom-file-label) {
	padding: .5rem 0;
}

</style>