<template>
	<div class="">
		<b-modal :id="modalId" ref="AddPhoneNumberModal" :size="modalSize" hide-header hide-footer centered>
			<b-row>
				<b-col>Add phone number</b-col>
			</b-row>
			<b-row>
				<b-col class="text-s-s text-dark-8">
					Castr will send SMS notifications to the number you added.
				</b-col>
			</b-row>
			<b-row class="mt-4">
				<b-col>
					<vue-tel-input class="phone-input" v-model="phoneNumberInput" mode="national" auto-format :dropdown-options="options" @validate="onValidate"></vue-tel-input>
				</b-col>
				<!-- TODO The component below not working properly replaced with input temporarely--> 
			</b-row>
			<b-row v-if="validationError" class="ml-1 mt-2">
				<span style="color: #ba2929;" class="text-s-s">{{ errorMsg }}</span>
			</b-row>
			<b-row>
				<b-col class="text-right mt-3">
					<b-button variant="outline-secondary" @click="dismiss" class="mr-2" size="md">
						Cancel
					</b-button>
					<b-button variant="primary" size="md" @click="onSave" :disabled="!phoneNumberValid || processing">
						Add Number
						<Spinner v-if="processing" text="" classes="text-dark-8 ml-2" spinner-color="var(--c-dark-8)"
							spinner-size="15px" />
					</b-button>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import Spinner from "@/components/ui/Spinner.vue";
import UserService from '@/services/UserService';

export default {
	name: 'AddPhoneNumberModal',
	components: {
		VueTelInput,
		Spinner
	},
	props: {
		modalId: {
			type: String,
			default: 'AddPhoneNumberModal'
		},
		modalSize: {
			type: String,
			default: 'sm',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
		stream: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			processing: false,
			validationError: false,
			phoneNumberInput: null,
			phoneNumberValid: false,
			phoneFormattedNumber: null,
			countryCode: null,
			options: {
				showDialCodeInList: true,
				showDialCodeInSelection: true,
				showFlags: true,
				showSearchBox: true,
			}
		};
	},
	mounted() {
		VueTelInput.compatConfig = { MODE: 3 }
	},
	methods: {
		onValidate(mobile) {
			this.validationError = false
			if (!mobile.valid) {
				this.phoneNumberValid = false
				return
			}

			this.phoneFormattedNumber = mobile.number;
			this.phoneNumberValid = true
		},
		async onSave() {
			this.processing = true
			try {
				const contact = await UserService.createUserContact(this.phoneFormattedNumber)
				contact.contact.id = contact._id
				this.$emit('user-contact-created', contact)
			} catch (e) {
				this.$notify({ group: 'error', text: e ? e.message : 'Failed to save phone number' })
				this.errorMsg = e ? e.message : 'Failed to save phone number'
				this.validationError = true
				setTimeout(() => {
					this.validationError = false
				}, 5000)
			}
			this.processing = false
		},
		dismiss() {
			this.$refs.AddPhoneNumberModal.hide();
			// lazy clear
			setTimeout(() => { this.processing = false; }, 1000);
		}
	},
};
</script>

<style>
.country-selector__input {
	border: 0px solid #ccc;
}

.input-tel__input {
	border: 0px solid #ccc;
}

.input-phone-number {
	margin-left: 10px;
	width: 200px;
}
</style>