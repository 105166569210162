<template>
	<div class="container page-title-area">
		<header class="mt-5">
			<div class="title d-flex justify-content-between align-items-center mb-4">
				<h1 class="page-title mb-0">Sub Second Streaming</h1>
				<span id="create-sub-second-stream">
					<b-button :disabled="subSecondStreamsFiltered.length >= maxSubSecondStreams" v-if="!loadingStatus && hasLiveSubscription.enabled && !hasLiveSubscription.trial" v-b-modal.modal-add-sub-second-stream variant="primary" size="lg">
					<icon-base width="12" height="12" widthViewBox="12" heightViewBox="12" icon-name="plus" classIcon="mr-2"><icon-plus /></icon-base>
					New Sub Second Stream
				</b-button>
				</span>
				<b-tooltip
					target="create-sub-second-stream"
					placement="top"
					v-if="subSecondStreamsFiltered.length >= maxSubSecondStreams"
					>
					<span class="text-dark-8 text-small">
						Want more streams? Contact <a class="text-reset text-underline" href="#" onclick="Intercom('show')">sales</a>.</span>
				</b-tooltip>
			</div>
		</header>
		<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)" spinner-size="15px" />
		<div v-else-if="subSecondStreams && subSecondStreams.length === 0" class="mt-5 text-center d-flex flex-column col-3 mx-auto align-items-center">
			<img class="mb-4" width="100" src="@/assets/empty-playout-placeholder.png" alt="Playout Line Up">
			<div v-if="hasLiveSubscription.enabled && maxSubSecondStreams > 0">
				<h3 class="text-s-m text-dark-9">There are no sub second streams here!</h3>
				<p class="mb-4 text-s-s text-dark-7">Fortunately, it’s very easy to create one</p>
				<b-button variant="outline-secondary" v-b-modal.modal_tutorial class="w-100 mb-3" size="lg">
					<div class="rounded-circle icon-round mr-2">
						<icon-base width-view-box="8" height-view-box="10" iconColor="none" width="8" height="10" class="text-dark-8">
							<icon-lightning-round />
						</icon-base>
					</div>
					Sub second stream demo
				</b-button>
				<b-button :disabled="subSecondStreamsFiltered.length >= maxSubSecondStreams" v-b-modal.modal-add-sub-second-stream class="w-100" variant="primary" size="lg">
					<div class="rounded-circle icon-round mr-2">
						<icon-base width-view-box="8" height-view-box="10" iconColor="none" width="8" height="10" class="text-dark-8">
							<icon-lightning-round />
						</icon-base>
					</div>
					Create sub second stream
				</b-button>
			</div>
			<div v-else>
				<h3 class="text-s-m text-dark-9">The Sub-Second Streams feature is not included in your current subscription plan</h3>
				<b-button variant="primary" to="/subscribe?category=live" class="w-100 mb-3" size="lg">
					Upgrade
				</b-button>
			</div>
		</div>
		<div v-else>
			<section class="mt-5">
				<div class="toolbar-container tab-settings">
					<div class="d-flex w-100 flex-wrap flex-md-nowrap align-items-center my-3">
						<div class="mr-0 mr-md-2 mb-2 mb-md-0 filter-wrapper order-1 order-md-0">
							<b-dropdown class="w-100" variant="dark-3" no-caret
								toggle-class="d-flex align-items-center justify-content-between font-weight-normal"
								menu-class="dropdown-menu_md w-100">
								<template #button-content>
									<div class="d-flex align-items-center w-100">
										<icon-base width-view-box="20" icon-name="Sort By">
											<icon-sort />
										</icon-base>
										<!-- <span class="text-dark-5">Sort By:</span>  -->
										<span class="ml-1">{{ sortBy }}</span>
									</div> <i class="ml-2 fa fa-chevron-down" />
								</template>
								<b-dropdown-item :active="sortBy === 'Sort by Oldest'" @click="changeSortBy('Sort by Oldest')">
									<div class="ml-2">Sort by Oldest</div>
								</b-dropdown-item>
								<b-dropdown-item :active="sortBy === 'Sort by Newest'" @click="changeSortBy('Sort by Newest')">
									<div class="ml-2">Sort by Newest</div>
								</b-dropdown-item>
								<b-dropdown-item :active="sortBy === 'A to Z'" @click="changeSortBy('A to Z')">
									<div class="ml-2">A to Z</div>
								</b-dropdown-item>
								<b-dropdown-item :active="sortBy === 'Z to A'" @click="changeSortBy('Z to A')">
									<div class="ml-2">Z to A</div>
								</b-dropdown-item>
							</b-dropdown>
						</div>
						<b-input-group size="md" :class="{ active: searchWidgetActive }"
							class="search-form order-0 order-md-1 mb-3 mb-md-0">
							<template #prepend>
								<b-input-group-text class="search-form__toggle color-light" @click="toggleSearchWidget">
									<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M8.11096 15.2219C12.0382 15.2219 15.2219 12.0382 15.2219 8.11096C15.2219 4.18368 12.0382 1 8.11096 1C4.18368 1 1 4.18368 1 8.11096C1 12.0382 4.18368 15.2219 8.11096 15.2219Z"
											stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M16.9994 16.9994L13.1328 13.1328" stroke="currentColor" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</b-input-group-text>
							</template>
							<b-form-input 
								ref="searchWidgetInputElement" 
								v-model="searchWidgetInput"
								@focus="onSearchWidgetFocus" 
								@blur="onSearchWidgetBlur"
								:placeholder="searchWidgetActive ? 'Search for streams ..' : ''"
								class="search-form__field"></b-form-input>
						</b-input-group>
						<b-button-group class="order-2 ml-auto">
							<b-button @click="unsetCompactMode" variant="dark-3" :class="{ active: !shouldShowCompactModeStreams }" class="view-mode no-focus"
								>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect x="2" y="2" width="7.25" height="7.25" rx="2" fill="currentColor" />
									<rect x="2" y="10.75" width="7.25" height="7.25" rx="2" fill="currentColor" />
									<rect x="10.75" y="2" width="7.25" height="7.25" rx="2" fill="currentColor" />
									<rect x="10.75" y="10.75" width="7.25" height="7.25" rx="2" fill="currentColor" />
								</svg>
							</b-button>
							<b-button @click="setCompactMode" variant="dark-3" :class="{ active: shouldShowCompactModeStreams }" class="view-mode no-focus">
								<svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M6 1H19" stroke="currentColor" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M1 2C1.55228 2 2 1.55228 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2Z"
										fill="currentColor" />
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M1 8C1.55228 8 2 7.55228 2 7C2 6.44772 1.55228 6 1 6C0.447715 6 0 6.44772 0 7C0 7.55228 0.447715 8 1 8Z"
										fill="currentColor" />
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M1 14C1.55228 14 2 13.5523 2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14Z"
										fill="currentColor" />
									<path d="M6 7H19" stroke="currentColor" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M6 13H19" stroke="currentColor" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</b-button>
						</b-button-group>
					</div>
				</div>
			</section>
			<section>
				<b-button aria-controls="sub-second-streams"
					:class="{'collapsed': !isShown}" variant="link"
					:aria-expanded="true"
					class="text-reset pl-4 font-weight-normal btn-category"
					@click="toggleCollapce">
					<icon-base width-view-box="20" iconColor="none" class="text-primary" icon-name="Sub Second">
						<icon-lightning />
					</icon-base>
					<span class="mr-2">Sub Second Streams</span>
					<b-badge variant="dark-3" id="stream_count_-sub-stream" class="stream-count" pill> {{subSecondStreamsFiltered.length }}/{{ maxSubSecondStreams }}
					</b-badge>
					<b-tooltip target="stream_count_-sub-stream" placement="top" v-if="false">
						<span class="text-dark-8 text-small">
							You have created {{subSecondStreamsFiltered.length }}/??? sub second streams.
							Need more? <a class="text-underline text-white" target="_blank" href="/app/subscribe">Upgrade here</a>
						</span>
					</b-tooltip>
				</b-button>
				<b-collapse class="mt-3" v-model="isShown" id="sub-second-streams">
					<b-row>
						<b-col cols="12" md="6" :lg="shouldShowCompactModeStreams ? '12' : '4'"
							v-for="stream in subSecondStreamsFiltered" :key="stream._id" class="grid-item">
							<stream-card-view :stream="stream"
								:compact-mode="shouldShowCompactModeStreams"
								@delete-stream="onStreamDeleteRequest" 
								/>
						</b-col>
					</b-row>
				</b-collapse>
			</section>
		</div>
		<add-sub-second-stream-modal @new-channel="onNewStream" />
		<demo-video-modal modalId="modal_tutorial" videoid="48ofjE3WZ064-SuIElpIr" />
		<confirm-modal message="Would you like to delete this stream and all of its content?" modal-id="delete-stream"
			modal-type="danger"
			@modal-confirm="onStreamDeleteConfirm" />
	</div>
</template>

<script>
import _ from 'lodash';

import Spinner from '@/components/ui/Spinner.vue'
import IconBase from '@/components/icon/IconBase.vue'
import IconPlus from '@/components/icon/IconPlus.vue'
import IconLightning from '@/components/icon/IconLightning.vue'
import IconLightningRound from '@/components/icon/IconLightningRound.vue'
import IconSort from '@/components/icon/IconSort.vue'
import AddSubSecondStreamModal from '@/components/modals/AddSubSecondStreamModal.vue'
import StreamCardView from '@/components/ui/StreamCardView.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { mapGetters } from 'vuex';
import StreamService from '@/services/StreamService'
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue'

export default {
	name: 'SubSecondDashboard',
	components: {
		Spinner,
		IconBase,
		IconPlus,
		IconLightning,
		IconLightningRound,
		IconSort,
		AddSubSecondStreamModal,
		StreamCardView,
		ConfirmModal,
		DemoVideoModal
	},
	data() {
		return {
			searchWidgetActive: false,
			searchWidgetFocused: false,
			searchWidgetInput: '',
			isShown: true,
			deleteModalConfiguredStream: null,
			deleteProcessing: false,
		};
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions',
			subSecondStreams: "Streams/subSecondStreams",
			sortBy: "Streams/sortBy",
			hasLiveSubscription: "User/hasLiveSubscription",
			maxSubSecondStreams: 'User/maxSubSecondStreams',
		}),
		shouldShowCompactModeStreams() {
			return this.$store.state.Ui.shouldShowCompactModeStreams;
		},
		subSecondStreamsFiltered() {
			const searchStr = this.searchWidgetInput.toLowerCase();
			const result  = this.subSecondStreams.filter(stream => stream.name.toLowerCase().includes(searchStr))
			return result
		}
	},
	watch: {
		async hasLiveSubscription() {
			this.$store.dispatch('Ui/setLoadingStatus', true)
			await this.$store.dispatch('Streams/getStreams')
			this.$store.dispatch('Ui/setLoadingStatus', false)
		}
	},
	async mounted() {
		this.$store.dispatch('Ui/setLoadingStatus', true)
		await this.$store.dispatch('Streams/getStreams')
		this.$store.dispatch('Ui/setLoadingStatus', false)
	},
	methods: {
		toggleCollapce() {
			this.isShown = !this.isShown
		},
		toggleSearchWidget() {
			this.searchWidgetActive = !this.searchWidgetActive;
			this.$refs.searchWidgetInputElement.focus();
			if (!this.searchWidgetActive) {
				this.searchWidgetInput = '';
			}
		},
		onSearchWidgetFocus() {
			this.searchWidgetFocused = true;
		},
		onSearchWidgetBlur() {
			this.searchWidgetFocused = false;
			setTimeout(() => {
				const blankSearchVal =
					!this.searchWidgetInput || !this.searchWidgetInput.length;
				if (
					this.searchWidgetActive &&
					blankSearchVal &&
					!this.searchWidgetFocused
				) {
					this.toggleSearchWidget();
				}
			}, 500);
		},
		changeSortBy(val) {
			this.$store.dispatch('Streams/setSortOption', val)
		},
		setCompactMode() {
			this.$store.dispatch('Ui/setCompactModeStreams', true);
		},
		unsetCompactMode() {
			this.$store.dispatch('Ui/setCompactModeStreams', false);
		},
		onNewStream(substream) {
			this.$router.push({ path: '/sub-second/' + substream._id + '/dashboard' });
		},
		async onStreamDeleteRequest(stream) {
			this.deleteModalConfiguredStream = stream;
			this.$bvModal.show('delete-stream');
		},
		async onStreamDeleteConfirm() {
			// pop stream out of the list
			this.deleteProcessing = true;
			try {
				await StreamService.deleteStream(this.deleteModalConfiguredStream._id);
				await this.$store.dispatch('Streams/getStreams')
				// track event
				const removedStream = this.deleteModalConfiguredStream;
				window.trackEvent(
					`Deleted stream ${removedStream.name}`,
					removedStream
				);
			} catch (err) {
				this.$notify({ group: 'error', title: err.error, text: err.message });
				// push stream back to list
				this.onNewStream(this.deleteModalConfiguredStream);
			}

			this.deleteModalConfiguredStream = null;
			this.deleteProcessing = false;
			this.$notify({
				group: 'success',
				text: 'Deleted successfully'
			});
		},
	},
	beforeDestroy() {
		
	},
	
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-form__toggle:hover {
	cursor: pointer;
}
.active .search-form__toggle {
	z-index: 9;
}

.search-form__field {
	border: none;
	width: 0;
	flex: 0 0 0;
	transition: all .2s ease-out;
	padding-left: 0;
	padding-right: 0;
	caret-color: var(--c-main-light);
}

.search-form__field::placeholder {
	color: var(--c-dark-6);
}

.input-group>.search-form__field {
	border-radius: 6px;
}

.active .search-form__field {
	width: 100%;
	max-width: 620px;
	flex: 1 1 auto;
	transition: all .3s ease-out;
	padding-left: 40px;
	padding-right: 16px;
	border: 1.5px solid var(--c-main-light);
	background: var(--c-dark-2);
}

.search-form__close {
	visibility: hidden;
}

.active .search-form__close {
	visibility: visible;
}
</style>
