<template>
	<div class="">
		<b-modal
			:id="modalId"
			ref="modalConfirm"
			modal-class="modal-platform"
			size="sm"
			centered
		>
			<template slot="modal-header">
				<div style="width: 100%">
					<b-row>
						<b-col><span>{{ message }}</span></b-col>
					</b-row>
					<div>
						<input
							v-model="promptInput"
							type="text"
							placeholder="Enter here"
							class="input"
							@keypress.enter="onConfirm"
						>
						<p
							v-if="message2"
							class="message2"
							style="margin-bottom:10px;"
						>
							{{ message2 }}
						</p>
						<div
							v-if="promptInputError"
							class="text-danger"
						>
							&nbsp;{{ errorMessage }}
						</div>
					</div>
				</div>
			</template>

			<div />

			<template
				slot="modal-footer"
				class="text-left"
			>
				<button
					v-if="!processing"
					:disabled="processing"
					type="button"
					class="modal-button"
					@click="dismiss"
				>
					{{ cancelText }}
				</button>
				<button
					:disabled="processing"
					:class="{ 'highlight': !processing }"
					type="submit"
					class="modal-button"
					@click="onConfirm"
				>
					{{ processing ? 'loading ..' : okText }}
				</button>
			</template>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'PromptModal',
	components: {},
	props: {
		modalId: {
			type: String,
			default: 'modal-prompt'
		},
		message: {
			type: String,
			required: true
		},
		message2: {
			type: String,
			default: null
		},
		errorMessage: {
			type: String,
			default: 'invalid input'
		},
		okText: {
			type: String,
			default: 'Yes'
		},
		cancelText: {
			type: String,
			default: 'No'
		}
	},
	data() {
		return {
			processing: false,
			promptInput: '',
			promptInputError: null,
			promptInputErrorMessage: null
		};
	},
	methods: {
		onConfirm() {
			this.promptInputError = false;

			if (!this.promptInput) {
				this.promptInputError = true;
				return;
			}

			this.processing = true;
			this.$emit('modal-prompt', this.promptInput, (err) => {
				this.processing = false;

				if (err) {
					this.promptInputError = true;
					return;
				}

				this.dismiss();
			});
			// this.dismiss();
		},
		dismiss() {
			this.$refs.modalConfirm.hide();
			// lazy clear
			setTimeout(() => {
				this.processing = false;
				this.promptInput = '';
				this.promptInputError = false;
			}, 1000);
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-button {
  border: none;
  padding: 7px 30px;
  font-size: 15.5px;
  color: #f7f7f7;
  background-color: transparent;
  text-transform: capitalize;
  font-size: 13.5px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 50px;
  outline: none;
}
.modal-button:hover {
  background-color: rgba(0, 98, 255, 0.425);
}
.modal-button:active {
  background-color: rgba(0, 98, 255, 0.7);
}
.modal-button.highlight {
  background-color: #0062ff;
}
.modal-button.highlight:hover {
  background-color: #001aff
}
.label {
  font-size: 12px;
  opacity: 0.65;
  text-transform: capitalize;
}
.message2 {
  font-size: 13px;
  color: #ffc107;
}
.input {
  display: block;
  width: 100%;
  height: auto !important;
  margin: 7px 0 7px 0;
  padding: 10px 14px;
  color: #ffffff;
  background-color: #292932;
  border: none;
  border-radius: 2px;
}
.input:focus {
  background-color: rgba(1, 3, 41, 0.47);
}
</style>
