<script setup>
import { ref } from 'vue'

import PlayoutSettings from './PlayoutSettings.vue';
import PlayoutDestination from './PlayoutDestination.vue';

const props = defineProps({
  mediaPulse: {
    type: Object,
  },
})

const activeTab = ref('destination')

const changeTab = (tabName) => {
  activeTab.value = tabName
}

</script>

<template>
  <div class="border-[1px] border-surface-2 rounded-lg p-3">
    <section class="flex text-tiny space-x-4 border-b border-surface-3 overflow-x-auto max-w-full mb-6">
      <button class="capitalize hover:text-white hover:no-underline py-1 border-b-2 flex items-center text-nowrap"
        @click="changeTab('destination')"
        :class="activeTab === 'destination' ? '!border-surface-9 text-white' : 'text-surface-8 border-transparent'">
        Destination
      </button>
      <button class="capitalize hover:text-white hover:no-underline disabled:hover:text-surface-8 py-1 border-b-2 flex items-center text-nowrap"
        @click="changeTab('settings')"
        :class="activeTab === 'settings' ? '!border-surface-9 text-white' : 'text-surface-8 border-transparent'">
        Advanced Settings
      </button>
    </section>
    <section>
      <PlayoutDestination :mediaPulse="mediaPulse" v-if="activeTab === 'destination'" />
      <PlayoutSettings v-else />
    </section>
  </div>
</template>

<style scoped></style>
