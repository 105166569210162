<template>
	<section>
		<b-table 
			hover
			table-class="main-table" 
			thead-class="text-dark-6"
			responsive
			:items="paginateTransactions" 
			:fields="fields"
			:busy="loading"
			sort-icon-left
			@row-clicked="openTransactionDetailsModal"
			show-empty
		>

			<template #table-busy>
				<Spinner text="Retrieving data..." classes="text-dark-8 mt-5" spinner-color="var(--c-dark-8)" spinner-size="15px" />
			</template>

			<template #head(id)="row">
				<span class="text-dark-6">{{ row.label }}</span>
			</template>
			<template #head(date)="row">
				<span class="text-dark-6">{{ row.label }}</span>
			</template>
			<template #head(currency)="row">
				<span class="text-dark-6">{{ row.label }}</span>
			</template>
			<template #head(amount)="row">
				<span class="text-dark-6">{{ row.label }} <span class="sort"></span></span>
			</template>
			<template #head(status)="row">
				<span class="text-dark-6">{{ row.label }} <span class="sort"></span></span>
			</template>

			<template #cell(status)="row">
				<b-badge v-if="row.value === 'active'" style="font-weight: 500" pill variant="outline-success">Succeeded</b-badge>
				<b-badge v-else-if="row.value === 'pending'" style="font-weight: 500" pill variant="dark-3">{{ row.value }}</b-badge>
				<b-badge v-else-if="row.value === 'expired'" style="font-weight: 500" pill variant="outline-danger">{{ row.value }}</b-badge>
				<b-badge v-else-if="row.value === 'refund'" style="font-weight: 500" pill variant="outline-danger">{{ row.value }}</b-badge>
				<b-badge v-else :variant="row.value">{{ row.value }}</b-badge>
			</template>

			<template #row-details="row">
				<b-card>
					<ul>
						<li v-for="(value, key) in row.transactions" :key="key">{{ key }}: {{ value }}</li>
					</ul>
				</b-card>
			</template>

			<template #cell(amount)="row">
				{{ formatPrice(row.value) }}
			</template>

			<template #cell(action)="row">
				<b-dropdown 
					menu-class="dropdown-menu_md" 
					right no-caret 
					variant="icon"
					size="sm"
					toggle-class="pr-0 pl-1" 
					class="">
						<template #button-content>
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="2.25" cy="8" r="1.75" fill="#A7B1C9" />
								<circle cx="8" cy="8" r="1.75" fill="#A7B1C9" />
								<circle cx="13.75" cy="8" r="1.75" fill="#A7B1C9" />
							</svg>
						</template>
						<b-dropdown-item @click="openTransactionDetailsModal(row)" class="">
							<span>Details</span>
						</b-dropdown-item>
						<b-dropdown-item v-if="row.item.amount != '0.00' && row.item.status === 'active' " @click="refundPayment(row)">
							<span>Refund</span>
						</b-dropdown-item>
					</b-dropdown>
			</template>

		</b-table>
		<b-pagination
			v-model="currentPage"
			:total-rows="filteredTransactions.length"
			:per-page="perPage"
			size="md"
			v-show="filteredTransactions.length > perPage"
			class="my-0"
			page-class="li-page bg-dark-2 p-1"
			ellipsis-class="li-page bg-dark-2 p-1"
			first-class="li-first p-1"
			prev-class="li-prev p-1"
			next-class="li-next p-1"
			last-class="li-last p-1"
		>
			<template #first-text>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8 3L3 8L8 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M13 3L8 8L13 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</template>
			<template #prev-text>
				<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6 1L1 6L6 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</template>
			<template #next-text>
				<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 1L6 6L1 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</template>
			<template #last-text>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M3 3L8 8L3 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M8 3L13 8L8 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</template>
		</b-pagination>

		<paywall-transaction-details-modal
			v-if="selectedTxn"
			:txnObject="selectedTxn"
		/>

	</section>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Spinner from "@/components/ui/Spinner.vue";
import IconBase from '@/components/icon/IconBase.vue';
import IconReorder from '@/components/icon/IconReorder.vue';
import PaywallService from '@/services/PaywallService';
import PaywallTransactionDetailsModal from '@/components/modals/PaywallTransactionDetailsModal.vue';

export default {
	name: 'TransactionsTab',

	components: {
		IconBase,
		IconReorder,
		Spinner,
		PaywallTransactionDetailsModal
	},
	props: {
		paywallStreamId: {
			type: Number,
			require: false
		}
	},
	data() {
		return {
			sortBy: 'Newest',
			fields: [

				{ key: 'id', label: 'ID', class: 'text-s-m text-dark-9 text-s-m' },
				{ key: 'date', label: 'Date', sortable: true, class: 'text-s-m text-dark-9' },
				{ key: 'customer', label: 'Customer' },
				{ key: 'currency', label: 'Currency', class: 'text-s-m text-dark-9' },
				{ key: 'amount', label: 'Amount', sortable: true, class: 'text-s-m text-dark-9' },
				{ key: 'status', label: 'Status', sortable: true, class: 'text-s-m text-capitalize' },
				{ key: 'action', label: '', class: 'text-s-m text-right last-col', stickyColumn: true}
			],
			transactions: [],
			loading: false,
			selectedTxn: null,
			currentPage: 1,
			perPage: 10,
			paywallStreams: [
				{id: 0, name: 'All streams'},
			],
			paywallSelectedStreamId: this.paywallStreamId || 0
		};
	},
	computed: {
		paywallSelectedStream() {
			const t = _.find(this.paywallStreams, { id: this.paywallSelectedStreamId });
			if (!t) {
				return 'All streams';
			}
			return t.name;
		},
		paginateTransactions() {
			return this.filteredTransactions.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage,
			);
		},
		filteredTransactions() {
			if (this.paywallSelectedStreamId === 0) {
				return this.transactions;
			}

			return _.filter(this.transactions, { stream_id: this.paywallSelectedStreamId });
		}
    },
	async mounted() {
		await this.setupTransactions();
	},
	methods: {
		transactionStreamNamgeChanged(row) {
			const pwStreamId = row.item.stream_id;
			const pwStream = _.find(this.paywallStreams, { id: pwStreamId });
			if (!pwStream) {
				return false;
			}
			
			if (pwStream.name === row.value) {
				return false;
			}

			return true;
		}, 
		transactionStreamBadge(row) {
			const pwStreamId = row.item.stream_id;
			const pwStream = _.find(this.paywallStreams, { id: pwStreamId });
			return pwStream.type;
		},
		async setupTransactions() {
			this.loading = true;
			try {
				const pwStreams = await PaywallService.getPaywallStreams();
				const txns =  await PaywallService.getTransactions();
				const txnsStreams = _.uniq(_.map(txns, 'stream_id'));
				this.paywallStreams = _.map(txnsStreams, (pwStreamId) => {
					const stream = _.find(pwStreams, { id: pwStreamId });
					if(!stream) {
						return;
					}

					const type = stream.url.includes('vod/playlist') ? 'VOD' : 'AIO';  // transaction badge
					return { id: pwStreamId, name: stream.title, type }
				}).filter((item) => !!item);
				this.paywallStreams.push({ id: 0, name: 'All streams'});
				this.paywallStreams = _.sortBy(this.paywallStreams, ['id']);

				this.transactions = _.map(txns, (txn) => {
					return {
						id: txn.id,
						date: moment(new Date(txn.created_at)).format('YYYY-MM-DD hh:mm A'),
						stream_id: txn.stream_id,
						customer: txn.user.email || 'n/a',
						currency: txn.currency,
						amount: txn.price,
						status: txn.status,
						txn: txn
					}
				}).reverse();
			} catch(err) {
				this.$notify({
					group: 'error',
					text: err.message || 'get transactions failed'
				});
				console.error('getTransactions failed', err);
			}
			this.loading = false;
		},
		onStreamFilterClick(stream) {
			this.loading = true;
			this.paywallSelectedStreamId = stream.id;
			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
		openTransactionDetailsModal(item) {
			let row = item;
			if (item.item) {
				row = item.item;
			}
			const txnId = row.id;
			this.selectedTxn = _.find(this.transactions, { id: txnId }).txn;
			setTimeout(() => {
				this.$root.$emit('bv::show::modal', 'modal-paywall-transaction-details');
			}, 100);
		},
		activatePaywall() {
			this.paywallStatus = true
		},
		formatPrice(value) {
			let val = (value/1).toFixed(2).replace(',', '.')
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		async refundPayment(item) {
			let row = item;
			if (item.item) {
				row = item.item;
			}
			const txnId = row.id;

			const idx = _.findIndex(this.transactions, { id: txnId })
			this.selectedTxn = this.transactions[idx].txn
			this.refundProcessing = true;

			try {
				await PaywallService.refundPayment(this.selectedTxn.payments[0].id);

				this.selectedTxn.status = "refund";
				if (this.selectedTxn.payments.length) {
					this.selectedTxn.payments[0].status = "refund";
				}
				this.transactions[idx].status = 'refund'


				this.$notify({
					group: "success",
					text: "Payment refunded",
				});
			} catch (err) {
				this.$notify({
					group: "error",
					text: err.message || "refund payment failed",
				});
				console.error("refund payemnet failed", err);
			}

      		this.refundProcessing = false;
    	},
	},
}
</script>

<style>
.table-responsive > table.main-table > tbody > tr > .b-table-sticky-column.last-col {
	position: static;
}
@media (max-width: 767px) {
	td {
		white-space: nowrap;
	}
	.table-responsive > table.main-table > tbody > tr > .b-table-sticky-column.last-col {
		position: sticky;
		right: -1px;
		border-left: 1px solid var(--c-dark-2);
	}
	.table.main-table.table-hover > tbody > tr:hover > .b-table-sticky-column.last-col {
		z-index: 9;
	}
	.table.b-table > thead > tr > [aria-sort="none"] .sort, .table.b-table > tfoot > tr > [aria-sort="none"] .sort {
		content: none;
	}
	.sort, .sr-only {
		display: none;
	}
}
</style>