<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import IconBase from '../icon/IconBase.vue';
import IconPlay from '../icon/IconPlay.vue';
import IconBookOpen from '../icon/IconBookOpen.vue';
import DemoVideoModal from '../modals/DemoVideoModal.vue';

const store = useStore()

const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])

</script>

<template>
  <header class="p-4 flex items-center border-b border-surface-2">
    <div>
      <h2 class="capitalize text-lg font-medium mb-2">{{ playoutConfig?.mode }}</h2>
      <a target="_blank" class="inline-flex items-center text-sm text-surface-7"
        href="https://docs.castr.com/en/articles/8001118-create-your-tv-playout">
        <icon-base class="mr-1">
          <icon-book-open />
        </icon-base>
        How TV Playout works
      </a>
      <a class="inline-flex items-center text-sm text-surface-7 md:ml-4" v-b-modal.modal-demo-video>
        <icon-base class="mr-1">
          <icon-play />
        </icon-base>
        TV Playout Demo
      </a>
    </div>
    <demo-video-modal videoid="cly4943mk0e93mb30nkdztonp" />
  </header>
</template>
<style scoped></style>
