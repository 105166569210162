<template>
	<div>
		<div class="content-container">
			<div class="video-wrapper">
				<div v-if="!stream.enabled" class="video-thumb placeholder mb-0 style-2">
					<p class="text-center text-s-xl text-dark-7 text-500 mb-1">
						Disabled Stream
					</p>
					<small class="text-s-m text-dark-5">Please enable</small>
					<b-button :disabled="toggleProcessing" size="md" variant="primary" class="mt-4" @click="showStreamToggleModal"
						:id="this.activeTeam && this.cannotToggleStream ? ('member-disabled_' + stream._id) : ''">
						Enable Stream
					</b-button>
					<b-tooltip :target="'member-disabled_' + stream._id" placement="top" v-if="memberPermission">
						<span class="text-dark-8 text-small">
							You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
								:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
					</b-tooltip>
				</div>
				<div v-else-if="hasStreamScheduled && !scheduleTimeActive"
					class="video-thumb placeholder plaseholder_variant-2 mb-0">
					<CountDown :endDate="schedulerSettings.datetime" @progress="onScheduleCountdownTick">
						Stream will go Live in
					</CountDown>
				</div>

				<div v-else-if="!streamAlive" class="video-thumb placeholder plaseholder_variant-2 mb-0">
					<p class="m-0">Waiting for stream</p>
				</div>

				<stream-player v-else-if="streamPreviewSpecs.type === 'mse' && mediaPulseTick" :stream="stream"
					:stream-url="streamPreviewSpecs.url" :media-pulse="mediaPulse" class="video-thumb" />
			</div>

			<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mt-5" spinner-color="var(--c-dark-8)"
				spinner-size="15px" />
			<div v-else>
				<b-button class="d-flex justify-content-between" variant="sidebar-collapse" v-b-toggle.palyback>
					<span>Playlist</span>
					<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 6L6 1L11 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</b-button>
				<b-collapse class="p-3 bg-dark-2 mb-4" visible id="palyback">
					<videos-uploader-compact-widget v-if="rawUploaderActive" :stream="stream" :media-online="isOnline"
						:stream-alive="streamAlive" :subscription="subscription" :multiple-uploads="isPrevilegedSub"
						:is-one-minute-before="isOneMinuteBefore" :video-files="videoFiles" :mp4-only="true"
						:total-files-per-stream="totalFilesPerScheduledStream"
						:is-disabled-control="isShowDisabledControl || (videoFiles.length > 1 && ((streamAlive && stream.enabled) || isOneMinuteBefore))"
						@video-added="onVideoAdded" @video-queue-emptied="onVideoQueueEmptied" @upload-enqueue="uploadEnqueue"
						@upload-dequeue="uploadDequeue" @updated="gatherPlaylistInfo" />
					<div class="encoder-content-container">
						<scheduler-playlist-compact :stream="stream" :media-online="isOnline" :stream-alive="streamAlive"
							:video-files="sortedVideoFiles" :move-playlist="movePlaylist" :is-any-upload="isAnyUpload"
							:scheduler-settings="schedulerSettings"
							:is-disabled-control="isShowDisabledControl || (videoFiles.length > 1 && ((streamAlive && stream.enabled) || isOneMinuteBefore))"
							@video-update="onVideoItemUpdate" @video-removed="onVideoRemoved"
							@request-upload="switchOptionsTab('queue')" @move-video="moveVideo"
							@toggle-video-status="toggleVideoStatus" />
					</div>
					<hr />
					<a href="https://docs.castr.com/en/articles/5161326-recommended-settings-for-recorded-videos" target="_blank"
						class="h-auto p-0 w-100 d-inline-flex align-items-center justify-content-center btn btn-sm btn-link text-dark-7 px-0">
						<svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="8" cy="8" r="7" stroke="currentColor" stroke-width="1.5" />
							<path
								d="M6 6.25V6C6 4.89543 6.89543 4 8 4H8.08033C9.14054 4 10 4.86888 10 5.92909V5.92909C10 6.71975 9.50955 7.43392 8.76923 7.71154V7.71154C8.30653 7.88505 8 8.32738 8 8.82154V9"
								stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<circle cx="8" cy="11.5" r="1" fill="currentColor" />
						</svg>
						<span>Recommended settings</span>
					</a>
				</b-collapse>
				<!-- stream/preview configurations -->
				<b-modal
					v-if="!(streamAlive && stream.enabled) && !isShowDisabledControl && uppyUploaderActive && (isPrevilegedSub || videoFiles.length === 0)"
					content-class="p-0" body-class="p-0" centered hide-footer hide-header id="cloud-import-modal">
					<UppyUploader class="cloud-import" :stream="stream" :video-files="videoFiles"
						:total-files-per-stream="totalFilesPerScheduledStream"
						:maxFileSize="maxVideoFileSize ? (maxVideoFileSize * (1024 ** 3)) : maxVideoFileSize"
						:storageLimit="scheduledStorageLimit" @upload-success="onVideoAdded" @upload-enqueue="uploadEnqueue"
						@upload-dequeue="uploadDequeue" @filesize-restriction-exceeded="onFileSizeLimit" />
				</b-modal>
				<VODListModal />
			</div>
		</div>
		<alert-modal modal-id="alert-video-encoding"
			message="Only MP4 Videos are allowed. Please upload video with appropriate encoding/format"
			ok-text="Got it, thanks" />

		<alert-modal modal-id="alert-video-max-size-exceeded"
			:message="maxFileSizeErrorMessage || 'Your file size cannot exceed. Upload smaller file or upgrade your plan to continue.'"
			ok-text="Got it, thanks" />
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex'
import Spinner from "@/components/ui/Spinner.vue"
import AlertModal from '@/components/modals/AlertModal.vue';
import VODListModal from '@/components/modals/VODListModal.vue';
import utils from '@/utils';
import StreamService from '@/services/StreamService';
import MetricsService from '@/services/MetricsService';
import UserService from '../../services/UserService';
import StreamPlayer from '@/components/ui/StreamPlayer.vue';
import SchedulerPlaylistCompact from '@/components/ui/SchedulerPlaylistCompact.vue';
import VideosUploaderCompactWidget from '@/components/ui/VideosUploaderCompactWidget.vue';
import ExpansionPanel from '@/components/ExpansionPanel';
import UppyUploader from '@/components/UppyUploader';
import ToggleStreamModal from '@/components/modals/ToggleStreamModal.vue';
import CountDown from '@/components/ui/CountDown.vue';
import Vue from 'vue';

export default {
	name: 'ScheduleStreamPreviewPane',
	components: {
		Spinner,
		SchedulerPlaylistCompact,
		VideosUploaderCompactWidget,
		StreamPlayer,
		ExpansionPanel,
		AlertModal,
		UppyUploader,
		VODListModal,
		ToggleStreamModal,
		CountDown
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		mediaPulse: {
			type: Object,
			default() { return {}; }
		},
		streamAlive: {
			type: Boolean
		},
		subscription: {
			type: Object,
			default() { return {}; }
		},
		schedulerSettings: {
			type: Object,
			default() { return {}; }
		},
		scheduledStorageLimit: {
			type: Object,
			default() { return {}; }
		},
		toggleProcessing: {
			type: Boolean
		}
	},
	data() {
		return {
			uppyUploaderActive: true,
			rawUploaderActive: true,
			multipleUploads: true,
			activeTab: 'queue',
			streamPreviewSpecs: {},
			notAllowedscheduled: false,
			videoFiles: [],
			scheduleTimeActive: false,
			uploadingvid: false,
			movePlaylist: false,
			isAnyUpload: false,
			isShowDisabledControl: false,
			maxFileSizeErrorMessage: null,
			totalFilesPerScheduledStream: 1,
			onlineStatus: '',
			mediaPulseTick: false
		};
	},
	computed: {
		userEmail() {
			return this.$store.state.User.email;
		},
		...mapGetters({
			loadingStatus: "Ui/loadingStatus"
		}),
		isOnline() {
			this.onlineStatus = this.mediaPulse;
		},
		isPrevilegedSub() {
			const baseSub = this.$store.state.User.baseSub;
			const isPrevileged = _.get(baseSub, ['package', 'baseCharge'], 0) > 2500;

			// Calculate max video files of the scheduled stream
			const subscriptions = this.$store.state.User.subscriptions;
			const activeScheduledSubs = _.filter(subscriptions, (sub) => sub.category === 'scheduled' && sub.enabled === true)
			let schedulerMaxNumberOfVideoFile;
			activeScheduledSubs.forEach(sub => {
				try {
					if (sub.package.definition.schedulerMaxNumberOfVideoFile) {
						this.totalFilesPerScheduledStream = parseInt(sub.package.definition.schedulerMaxNumberOfVideoFile, 10);
						schedulerMaxNumberOfVideoFile = parseInt(sub.package.definition.schedulerMaxNumberOfVideoFile, 10);
					}
					if (sub.definitionOverride && sub.definitionOverride.schedulerMaxNumberOfVideoFile) {
						this.totalFilesPerScheduledStream = parseInt(sub.definitionOverride.schedulerMaxNumberOfVideoFile, 10);
						schedulerMaxNumberOfVideoFile = parseInt(sub.definitionOverride.schedulerMaxNumberOfVideoFile, 10);
					}
				} catch (error) {
					console.error(error);
				}
			})


			// Set the value to 999 if those setting haven't been set yet
			if (!schedulerMaxNumberOfVideoFile) {
				this.totalFilesPerScheduledStream = 999;
			}
			return isPrevileged || this.totalFilesPerScheduledStream >= 1;
		},
		hasStreamScheduled() {
			const conf = this.schedulerSettings;
			return conf && conf.mode === 'datetime' && conf.datetime;
		},
		videoFilesCount() {
			return this.videoFiles.length;
		},
		sortedVideoFiles() {
			const sortedVids = _.sortBy(this.videoFiles, vid => vid.precedence);
			return sortedVids;
		},
		isOneMinuteBefore() {
			if (!this.schedulerSettings || !this.schedulerSettings.mode) return false
			const { mode: modeSchedule, datetime: dateSchedule } = this.schedulerSettings;
			if (modeSchedule !== 'datetime' || !this.stream.enabled) {
				return false;
			}
			const value = (dateSchedule - Date.now()) / 1000;
			return value > 0 && Math.max(value, 0) <= 60;
		},
		maxVideoFileSize() {
			let maxSize = null;
			const subscriptions = _.cloneDeep(this.$store.state.User.subscriptions);
			const activeScheduledSubs = _.filter(subscriptions, (sub) => sub.category === 'scheduled' && sub.enabled === true)

			activeScheduledSubs.forEach(sub => {
				try {
					if (sub.definitionOverride && sub.definitionOverride.schedulerMaxVideoSize) {
						sub.package.definition.schedulerMaxVideoSize = parseFloat(sub.definitionOverride.schedulerMaxVideoSize)
					}
					if (maxSize) {
						const tmpMaxSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
						if (tmpMaxSize > maxSize) {
							maxSize = tmpMaxSize;
							this.activeScheduledSub = sub
						}
					} else {
						maxSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
						this.activeScheduledSub = sub
					}
				} catch (error) {
					console.error(error);
				}
			})

			if (this.scheduledStorageLimit?.unitsLeft > 0 && this.scheduledStorageLimit?.unitsLeft < parseInt(maxSize * (1024 ** 3))) {
				maxSize = parseFloat(this.scheduledStorageLimit.unitsLeft / (1024 ** 3)).toFixed(1)
			}
			if (this.scheduledStorageLimit?.unitsLeft <= 0) {
				maxSize = 0
			}

			return maxSize;
		},
		activeTeam() {
			return this.$store.state.User.activatedTeam;
		},
		memberPermission() {
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
		cannotToggleStream() {
			if (!this.activeTeam) return false;
			let permission = this.memberPermission && (
				((this.stream.type === 'live') && !this.memberPermission.liveStreamToggle) ||
				((this.stream.type === 'restream') && !this.memberPermission.recordedStreamToggle) ||
				((this.stream.type === 'scheduled') && !this.memberPermission.preRecordedStreamToggle));
			return permission;
		},
	},
	watch: {
		videoFiles(filesNew, filesOld) {
			if (filesNew !== filesOld) {
				this.$emit('video-files', this.videoFiles);
				const noOrderFile = this.videoFiles.find((item) => !item.precedence);
				if (noOrderFile) {
					this.updateFirstInitOrder();
				}
			}
		},
		schedulerSettings() {
			this.scheduleTimeActive = false;
			this.isShowDisabledControl = false;
		},
		mediaPulse() {
			this.mediaPulseTick = true
			if (this.mediaPulse.isWowza === true) {
				this.streamPreviewSpecs.type = 'hls';
				this.streamPreviewSpecs.url = `https://fake/static/${this.stream.key}/playlist_sfm4s.m3u8`;
			}

			if (_.isEmpty(this.streamPreviewSpecs)) {
				this.streamPreviewSpecs.type = 'mse';
				this.streamPreviewSpecs.url = `wss://fake/${this.stream.key}/mse_ld?tracks=v1a1`;
			}
		},
	},
	async mounted() {
		this.$store.dispatch('Ui/setLoadingStatus', true);
		await this.setStreamPreviewUrl();
		this.gatherPlaylistInfo();
		const noOrderFile = this.videoFiles.find((item) => !item.precedence);
		if (noOrderFile) {
			this.updateFirstInitOrder();
		}
	},

	methods: {
		toggleStatus(ev) {
			this.$parent.toggleStatus(ev);
		},
		onFileSizeLimit(errorMessage) {
			this.maxFileSizeErrorMessage = errorMessage
			this.$root.$emit('bv::show::modal', 'alert-video-max-size-exceeded');
		},
		uploadEnqueue(uploadData) {
			if (this.isPrevilegedSub === false && uploadData.handler === 'raw') {
				this.uppyUploaderActive = false;
			}
			if (this.isPrevilegedSub === false && uploadData.handler === 'uppy') {
				this.rawUploaderActive = false;
			}
		},
		uploadDequeue(uploadData) {
			if (this.isPrevilegedSub === false && uploadData.handler === 'raw') {
				this.uppyUploaderActive = true;
			}
			if (this.isPrevilegedSub === false && uploadData.handler === 'uppy') {
				this.rawUploaderActive = true;
			}
		},
		async updateFirstInitOrder() {
			const list = [];
			_.each(this.videoFiles, (video, index) => {
				const item = _.assign({}, video, {
					precedence: index + 1
				});
				list.push(item);
			});
			this.videoFiles = list;
			await StreamService.updateOrderPlaylistVideo(this.stream._id, list);
		},
		computeVideoPrecedence(video) {
			let precedence = 0;
			let siblingVids = this.videoFiles;
			if (video) {
				siblingVids = _.filter(this.videoFiles, v => v.id !== video.id);
			}
			precedence = _.size(siblingVids) + 1;

			return precedence;
		},
		async moveVideo(method = 'up', video) {
			if (!video || video.file) return;

			// video.statusProcessing = true
			const vindex = _.findIndex(this.videoFiles, v => v.id === video.id);
			const vindexSorted = _.findIndex(this.sortedVideoFiles, v => v.id === video.id);
			this.movePlaylist = true;

			let video1 = {};
			let video2 = {};

			if (method === 'up') {
				video1 = this.sortedVideoFiles[vindexSorted];
				video2 = this.sortedVideoFiles[vindexSorted - 1];
			}
			if (method === 'down') {
				video1 = this.sortedVideoFiles[vindexSorted];
				video2 = this.sortedVideoFiles[vindexSorted + 1];
			}

			try {
				const video2Index = _.findIndex(this.videoFiles, v => v.id === video2.id);
				const video2Sorted = _.findIndex(this.sortedVideoFiles, v => v.id === video2.id);

				this.videoFiles = utils.updateArrayItem(this.videoFiles, { ...video1, precedence: video2Sorted + 1 }, vindex);
				this.videoFiles = utils.updateArrayItem(this.videoFiles, { ...video2, precedence: vindexSorted + 1 }, video2Index);

				await StreamService.updateOrderPlaylistVideo(this.stream._id, this.sortedVideoFiles);
				this.movePlaylist = false;
			} catch (e) {
				console.log('e', e);
			}
		},
		async toggleVideoStatus(video) {
			const vindex = _.findIndex(this.videoFiles, v => v.id === video.id);
			this.videoFiles = utils.updateArrayItem(this.videoFiles, { ...video, statusProcessing: true }, vindex);
			const nstate = video.enabled;
			try {
				await StreamService.togleStreamPlaylistVideoStatus(
					this.stream._id,
					video.id,
					nstate
				);
				video.enabled = nstate;
			} catch (e) {
				console.log('e', e);
			}
			this.videoFiles = utils.updateArrayItem(this.videoFiles, { ...video, statusProcessing: false }, vindex);
		},
		switchOptionsTab(tabName) {
			this.activeTab = tabName;
		},
		onScheduleCountdownTick(value, forceEnded) {
			
			if (!this.schedulerSettings) return;
			let offset = 0;
			if (!forceEnded) {
				offset = this.schedulerSettings.datetime - Date.now();
				offset /= 1000;
				if (value) offset = value.totalSeconds;
			}
			this.scheduleTimeActive = (offset <= 0) || (value.time <=0);
			const { mode: modeSchedule } = this.schedulerSettings;
			if (modeSchedule === 'datetime' && this.stream.enabled) {
				const isOneMinute = offset > 0 && Math.max(offset, 0) <= 60;
				if (isOneMinute) {
					this.isShowDisabledControl = true;
				} else {
					this.isShowDisabledControl = false;
				}
			}

		},
		onVideoAdded(videoItem) {
			videoItem = this.preparePlaylistVideoItem(videoItem);
			this.videoFiles.push(videoItem);
		},
		onVideoItemUpdate(videoId, updates) {
			const vindex = _.findIndex(this.videoFiles, { id: videoId });
			if (vindex > -1) {
				const videoItem = _.assign({}, this.videoFiles[vindex], updates);
				this.videoFiles = utils.updateArrayItem(
					this.videoFiles,
					videoItem,
					vindex
				);
			}
		},
		onVideoRemoved(videoId) {
			const vindex = _.findIndex(this.videoFiles, { id: videoId });
			this.videoFiles = utils.removeArrayItem(this.videoFiles, vindex);

			if (!this.videoFilesCount) {
				this.switchOptionsTab('queue');
			}
		},
		onVideoQueueEmptied() {
			this.switchOptionsTab('playlist');
		},
		async setStreamPreviewUrl() {
			try {
				// this.streamPreviewSpecs = await StreamService.getStreamPreviewVideoUrl(
				// 	this.stream._id
				// );

				if (_.isEmpty(this.streamPreviewSpecs)) {
					this.streamPreviewSpecs.type = 'mse';
					this.streamPreviewSpecs.url = `wss://fake/${this.stream.key}/mse_ld?tracks=v1a1`;
				}
			} catch (e) {
				this.$notify({
					group: 'error',
					text: (e && e.message) || 'could not fetch stream preview sepcs'
				});
			}
		},
		async gatherPlaylistInfo() {
			let videoList = [];
			try {
				videoList = await StreamService.getStreamPlaylist(this.stream._id);
			} catch (e) {
				this.$notify({
					group: 'error',
					text: 'could not retreive playlist videos'
				});
			}

			videoList = _.compact(videoList).map(this.preparePlaylistVideoItem);
			if (videoList.length) {
				this.switchOptionsTab('playlist');
			}

			this.videoFiles = videoList;
			// this.playlistProcessing = false;
			this.$store.dispatch('Ui/setLoadingStatus', false);
		},
		preparePlaylistVideoItem(videoFile) {
			videoFile.removing = false;
			videoFile.statusProcessing = false;
			videoFile.uploadTime = new Date(videoFile.uploadTime);
			return videoFile;
		},
		showStreamToggleModal(ev) {
			ev.preventDefault();
			ev.stopPropagation();
			if (localStorage.getItem('notAllowedscheduled')) {
				this.notAllowedscheduled = (localStorage.getItem('notAllowedscheduled') == 'true');
			}
			if (this.notAllowedscheduled && !this.stream.enabled) {
				this.$root.$emit('bv::show::modal', 'increase-limit');
			}
			if (this.cannotToggleStream) return;
			// if (this.stream.enabled){
			if ((!this.notAllowedscheduled || (this.notAllowedscheduled && this.stream.enabled))) {
				if (this.cannotToggleStream) return;
				this.$root.$emit('bv::show::modal', 'modalToggleStream' + this.stream._id, '#btnShow')
			}
		},
		onUpdated() {

		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.video-thumb,
.video-wrapper {
	width: 100%;
	min-height: 236px;
	background-color: #000;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px 8px 0 0;
}

.opacity-4 {
	opacity: 0.4;
}

#palyback {
	border-radius: 0px 0px 8px 8px;
}
</style>
