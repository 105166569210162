// Simple helper function to make pass-through values a little easier to manage

import { computed } from 'vue'

export function usePassthroughValue(props, emit) {
  const value = computed({
    get: () => props.modelValue,
    set: value => emit('update:modelValue', value),
  })

  return value
}
