<template>
	<section>
		<b-table 
			responsive
			class="main-table"
			thead-class="text-dark-6" 
			:items="paginateUsers" 
			:fields="fields"
			:busy="loading"
			sort-icon-left
			show-empty>

			<template #table-busy>
				<Spinner text="Retrieving data..." classes="text-dark-8 mt-5" spinner-color="var(--c-dark-8)" spinner-size="15px" />
			</template>

		</b-table>
        <b-pagination
					v-model="currentPage"
					:total-rows="filteredUsers.length"
					:per-page="perPage"
					size="md"
					v-show="filteredUsers.length > perPage"
					class="my-0"
					page-class="li-page bg-dark-2 p-1"
					ellipsis-class="li-page bg-dark-2 p-1"
					first-class="li-first p-1"
					prev-class="li-prev p-1"
					next-class="li-next p-1"
					last-class="li-last p-1"
        >
				<template #first-text>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8 3L3 8L8 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M13 3L8 8L13 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</template>
				<template #prev-text>
					<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6 1L1 6L6 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</template>
				<template #next-text>
					<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 1L6 6L1 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</template>
				<template #last-text>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M3 3L8 8L3 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M8 3L13 8L8 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</template>
				</b-pagination>

	</section>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Spinner from "@/components/ui/Spinner.vue";
import IconBase from '@/components/icon/IconBase.vue';
import IconReorder from '@/components/icon/IconReorder.vue';
import PaywallService from '@/services/PaywallService';

export default {
	name: 'CustomersTab',

	components: {
		IconBase,
		IconReorder,
		Spinner,
	},
	props: {
		paywallStreamId: {
			type: Number,
			require: false
		}
	},
	data() {
		return {
			sortBy: 'Newest',
			fields: [
				{ key: 'id', label: 'ID', class: 'text-s-m text-dark-9 text-s-m' },
				{ key: 'stream_id', label: 'Stream ID', thClass: 'd-none', tdClass: 'd-none' },
				{ key: 'name', label: 'Name' },
				{ key: 'customer', label: 'Email'},
				// { key: 'register_date', label: 'Register Date', sortable: true, class: 'text-s-m text-dark-9' },
				{ key: 'subscription_start', label: 'Subscribe at', sortable: true, class: 'text-s-m' },
				// { key: 'subscription_period', label: 'Subscription Period', sortable: true, class: 'text-s-m text-dark-9' },
				// { key: 'subscription_expires', label: 'Subscription Expires', sortable: true, class: 'text-s-m text-dark-9' },
			],
			users: [],
			loading: false,
			selectedUser: null,
			currentPage: 1,
			perPage: 10,
			paywallStreams: [
				{id: 0, name: 'All streams'},
			],
			paywallSelectedStreamId: this.paywallStreamId || 0
		};
	},
	computed: {
		paywallSelectedStream() {
			const t = _.find(this.paywallStreams, { id: this.paywallSelectedStreamId });
			if (!t) {
				return 'All streams';
			}
			return t.name;
		},
		paginateUsers() {
			return this.filteredUsers.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage,
			);
		},
		filteredUsers() {
			if (this.paywallSelectedStreamId === 0) {
				return this.users;
			}

			return _.filter(this.users, { stream_id: this.paywallSelectedStreamId });
		}
    },
	async mounted() {
		await this.setupUsers();
	},
	methods: {
		userStreamNamgeChanged(row) {
			const pwStreamId = row.item.stream_id;
			const pwStream = _.find(this.paywallStreams, { id: pwStreamId });
			if (!pwStream) {
				return false;
			}
			
			if (pwStream.name === row.value) {
				return false;
			}

			return true;
		}, 
		async setupUsers() {
			this.loading = true;
			try {
				const pwStreams = await PaywallService.getPaywallStreams();
				const users =  await PaywallService.getUsers();
				let flattenInvoices = _.reduce(users, (result, value) => {
					const user = _.pick(value, ['id', 'name', 'email', 'email_verified_at', 'created_at']);
					const invoices = _.map(value.invoices, function(el) { 
						return _.extend({}, el, user );
					});
					return [...result, ...invoices]
				}, []);

				// each user could be subscribed to stream N times, get latest subscription to stream from list
				let streamInvoices = _.chain(flattenInvoices).groupBy('stream_id').reduce((result, value) => {
					const invoices = _.uniqBy(_.sortBy(value, (item) => item.started_at).reverse(), 'id');
					return [...result, ...invoices]
				}, []).value();

				const userStreams = _.uniq(_.map(streamInvoices, 'stream_id'));
				this.paywallStreams = _.map(userStreams, (pwStreamId) => {
					const stream = _.find(pwStreams, { id: pwStreamId });
					if(!stream) {
						return;
					}
					return { id: pwStreamId, name: stream.title }
				}).filter((item) => !!item);
				this.paywallStreams.push({ id: 0, name: 'All streams'});
				this.paywallStreams = _.sortBy(this.paywallStreams, ['id']);

				this.users = _.map(streamInvoices, (user) => {
					return {
						id: user.id,
						name: user.name,
						customer: user.email || 'n/a',
						stream_id: user.stream_id,
						// register_date: moment(new Date(user.created_at)).format('YYYY-MM-DD hh:mm A'),
						subscription_start: moment(new Date(user.started_at)).format('YYYY-MM-DD hh:mm A'),
						// subscription_period: user.period,
						// subscription_expires: moment(new Date(user.expires_at)).format('YYYY-MM-DD hh:mm A'),
					}
				}).reverse();
			} catch(err) {
				this.$notify({
					group: 'error',
					text: err.message || 'get users failed'
				});
				console.error('getUsers failed', err);
			}
			this.loading = false;
		},
		onStreamFilterClick(stream) {
			this.loading = true;
			this.paywallSelectedStreamId = stream.id;
			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
		activatePaywall() {
			this.paywallStatus = true
		},
		formatPrice(value) {
			let val = (value/1).toFixed(2).replace(',', '.')
			return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    	}
	},
}
</script>

<style>
	@media (max-width: 767px) {
	.main-table  td, .main-table  th {
		white-space: nowrap;
	}
}
</style>