import _ from "lodash";
import RequestError from "./RequestError";
import amProxyUrl from "./AmProxyUrl";
import api from "@/api";

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

export default {
  createPlayer,
  setLiveStreamPlayer,
  setVodPlayer,
	getPlayers,
	getPlayer,
	renamePlayer,
  deletePlayer,
	togglePlayer,
  uploadPlayerPoster,
	savePlayerMetadata,
	clearPlayerMetadataCache
};

/**
 * @param {string} name
 */
function createPlayer(name) {
	return makeRequest({
		path: '/dynamic_players',
		method: 'post',
		data: {
			name: name,
		}
	});
}
/**
 * @param {string} playerId
 * @param {string} playerName
 * @param {string} id
 */
function setLiveStreamPlayer(playerId, playerName, id) {
	return makeRequest({
		path: `/dynamic_players/${playerId}`,
		method: 'put',
		data: {
      source: id,
      name: playerName
		}
	});
}

/**
 * @param {string} playerId
 * @param {string} playerName
 * @param {string} vodId
 * @param {string} fileId
 */
function setVodPlayer(playerId, playerName, vodId, fileId) {
	return makeRequest({
		path: `/dynamic_players/${playerId}`,
		method: 'put',
		data: {
      source: vodId,
      name: playerName,
      subSource: fileId
		}
	});
}

function getPlayers() {
	return makeRequest({
		path: '/dynamic_players',
		method: 'get',
	});
}

/**
 * @param {string} playerId
 */
function getPlayer(playerId) {
  return makeRequest({
		path: `/dynamic_players/${playerId}`,
		method: 'get',
	});
}

/**
 * @param {string} playerId
 */
function deletePlayer(playerId) {
  return makeRequest({
		path: `/dynamic_players/${playerId}`,
		method: 'delete',
	});
}

/**
 * @param {string} playerId
 * @param {FormData} fdata
 */
function renamePlayer(playerId, newName) {
	return makeRequest({
		path: `/dynamic_players/${playerId}`,
		method: 'put',
		data: {
			name: newName
		},
	});
}

/**
 * @param {string} playerId
 */
function togglePlayer(playerId) {
  return makeRequest({
		path: `/dynamic_players/${playerId}/toggle`,
		method: 'post',
	});
}

/**
 * @param {string} playerId
 * @param {FormData} fdata
 */
function uploadPlayerPoster(playerId, fdata, isWatermark) {
	return makeRequest({
		path: `/streams/${playerId}/metadata/poster?${isWatermark ? 'type=watermark' : ''}&dynamicPlayer=true`,
		method: 'post',
		data: fdata,
		headers: {
			'content-type': 'multipart/form-data'
		}
	});
}

/**
 * @param {string} playerId
 * @param {string} key
 * @param {string} value
 */
function savePlayerMetadata(playerId, key, value) {
	return makeRequest({
		path: `/streams/${playerId}/metadata/save?dynamicPlayer=true`,
		method: 'put',
		data: {
			updates: {
				key,
				value
			}
		}
	});
}

/**
 * @param {string} playerId
 */
function clearPlayerMetadataCache(playerId) {
	return makeRequest({
		path: `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/cache/clear?stream=${playerId}`,
		method: 'get'
	});
}

/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeRequest(reqConfig, preventProxy, throwReal) {
  if (typeof reqConfig === "string") {
    reqConfig = {
      path: reqConfig,
    };
  }

  if (preventProxy !== true) {
    reqConfig = amProxyUrl(reqConfig, process.env.VUE_APP_VOD_API_BASE_URL);
  }

  reqConfig.url = reqConfig.path;

  let res;
  try {
    res = await api.request(reqConfig);
  } catch (err) {
    if (throwReal) {
      throw err;
    }
    const edata = _.get(err, "response.data");
    throw new RequestError(edata);
  }

  return res && res.data;
}
