<template>
	<div class>
		<!-- Modal Component -->
		<b-modal id="modal-configure-platform" ref="modalConfigurePlatform" modal-class="modal-fullscreen1 modal-platform"
			size="sm" centered hide-footer hide-header>
			<b-row>
				<b-col>
					<h4 class="text-s-xl font-weight-normal mb-4" v-html="modalTitle" />
				</b-col>
			</b-row>
			<div>
				<!-- error placeholder -->
				<b-alert v-if="error" show variant="danger">
					{{ error.message }}
				</b-alert>
				<p v-show="formErrors.server" class="text-danger">
					invalid {{ platform.custom && 'destination' || platform.name }} ingest address
				</p>
				<!-- form -->
				<div v-if="platform.mode || platform.custom || hasMultipleServers() || platform.customServer"
					class="field-container">
					<label>
						Streaming {{ platform.custom && 'platform address' || 'server' }}
					</label>

					<div v-if="platform.custom && platform.mode === 'srt'">
						<b-input v-model="hostName" class="mb-3" placeholder="srt://srt-ingest.castr.io"
							@keypress="onInputChange('server')" @keyup="onInputChange('server')" @blur="onInputChange('server')" />
						<p v-show="customSrtPlatformErrors.server && platform.mode === 'srt'" class="text-danger">
							{{ customSrtPlatformErrors.server }}
						</p>
						<label>
							Port
						</label>
						<b-input v-model="platformConfig.port" class="mb-3" placeholder="9998" @keypress="onInputChange('port')"
							@keyup="onInputChange('port')" @blur="onInputChange('port')" />
						<p v-show="customSrtPlatformErrors.port" class="text-danger">
							{{ customSrtPlatformErrors.port }}
						</p>

						<label>
							Stream Id
						</label>
						<b-input v-model="platformConfig.key" class="mb-3" placeholder="stream id" @keypress="onInputChange('key')"
							@keyup="onInputChange('key')" @blur="onInputChange('key')" />
							<p v-show="customSrtPlatformErrors.key" class="text-danger">
								{{ customSrtPlatformErrors.key }}
							</p>

						<label>
							Passphrase
						</label>
						<b-input v-model="platformConfig.passphrase" class="mb-3" placeholder="passphrase" @keypress="onInputChange('passphrase')"
							@keyup="onInputChange('passphrase')" @blur="onInputChange('passphrase')" />
						<p v-show="customSrtPlatformErrors.passphrase" class="text-danger">
							{{ customSrtPlatformErrors.passphrase }}
						</p>

						<label>
							Latency
						</label>
						<b-input v-model="platformConfig.latency" class="mb-3" placeholder="120" @keypress="onInputChange('latency')"
							@keyup="onInputChange('latency')" @blur="onInputChange('latency')" />
						<p v-show="customSrtPlatformErrors.latency" class="text-danger">
							{{ customSrtPlatformErrors.latency }}
						</p>
					</div>

					<div v-else-if="platform.custom">
						<b-input v-model="platformConfig.server" class="mb-3" placeholder="rtmp://broadcaster_addr/"
							@keypress="onInputChange('server')" @keyup="onInputChange('server')" @blur="onInputChange('server')" />
						<label>
							<b-form-checkbox v-model="isAuthRequired" class="mb-2">Authentication Required </b-form-checkbox>
						</label>

						<div v-if="isAuthRequired">
							<b-input v-model="rtmpAuthUsername" class="mb-3" placeholder="Username" />

							<b-input v-model="rtmpAuthPassword" type="password" class="mb-3" placeholder="Password"
								:class="{ error: !isRtmpPassValid }" @blur="checkPassword" />
							<p v-if="!isRtmpPassValid" class="color-danger d-flex">
								<svg class="flex-shrink-0 mr-2 mt-1" width="14" height="14" viewBox="0 0 14 14" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10C6 9.44771 6.44772 9 7 9C7.55228 9 8 9.44771 8 10ZM7.75 3.5C7.75 3.08579 7.41421 2.75 7 2.75C6.58579 2.75 6.25 3.08579 6.25 3.5V6.5C6.25 6.91421 6.58579 7.25 7 7.25C7.41421 7.25 7.75 6.91421 7.75 6.5V3.5Z"
										fill="#E25858" />
								</svg>
								'@' symbol is not supported in password, please change the password.
							</p>
						</div>
					</div>

					<b-input v-else-if="platform.customServer" v-model="platformConfig.server"
						:placeholder="platform.serverInputPlaceholder || 'rtmp://broadcaster_addr/'" class="input"
						@keypress="onInputChange('server')" />

					<div v-else-if="serverKeySegments" class="input-container" @click="focusServerKeySegment">
						<div v-for="(segment, index) in serverKeySegments" :key="index">
							<b-input v-if="segment.input" ref="segment" v-model="serverKeySegmentValues[index]"
								:placeholder="segment.placeholder" :style="{ width: (segment.width || 20) + 'px' }" class="input"
								@change="computePlatformServerFromSegments" @keypress="onInputChange('server')" />
							<div v-else-if="!segment.hidden" class="value-placeholder">
								{{ segment.text || segment }}
							</div>
						</div>
					</div>
					<!-- :options="getPlatformServers(platform.template)" -->
					<b-form-select v-else v-model="platformConfig.server" :options="platformServers" class="input"
						@change="onInputChange('server')" />
					<p v-show="customSrtPlatformErrors.server && platform.mode != 'srt'" class="text-danger">
						{{ customSrtPlatformErrors.server }}
					</p>
					<p v-show="formErrors.port" class="text-danger">
						Please use a valid port
					</p>
				</div>
				<div class="field-container" v-if="platform.mode !== 'srt'">
					<label>
						Streaming key
					</label>
					<b-input v-model="platformConfig.key" class="input" placeholder="#streaming_key"
						@keypress="onInputChange('key')" @keyup="onInputChange('key')" @blur="onInputChange('key')" />
					<p v-show="customSrtPlatformErrors.key" class="text-danger">
						{{ customSrtPlatformErrors.key }}
					</p>
					<p v-show="formErrors.key" class="text-danger">
						specify stream key
					</p>
				</div>
			</div>
			<b-row class="mt-4">
				<b-col class="text-right">
					<b-progress v-if="processing" :value="100" :max="100" animated class="w-75" />
					<div v-else>
						<b-button variant="outline-secondary" size="md" class="mr-2" @click="dismiss">
							Cancel
						</b-button>
						<b-button variant="primary" size="md" @click="onSavePlatform"
							:disabled="(customSrtPlatformErrors.host.length + customSrtPlatformErrors.latency.length + customSrtPlatformErrors.port.length + customSrtPlatformErrors.server.length + customSrtPlatformErrors.key.length) > 0"
						>
							Save
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import StreamService from '@/services/StreamService';
import IntegrationService from '@/services/IntegrationService';
import utils from '@/utils';

let platformTemplates = [];

export default {
	name: 'EditPlatformModal',
	props: {
		stream: {
			type: Object,
			required: true
		},
		platform: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			error: null,
			processing: false,
			platformConfig: { server: null, key: null, port: null, latency: 120 },
			serverKeySegments: null,
			serverKeySegmentValues: null,
			platformServers: [],
			formErrors: { server: false, key: false, port: false },
			isAuthRequired: false,
			rtmpAuthUsername: '',
			rtmpAuthPassword: '',
			customSrtPlatformErrors: {
				port: '',
				latency: '',
				host: '',
				key: '',
				server: '',
				passphrase: ''
			},
			isRtmpPassValid: true
		};
	},
	computed: {
		modalTitle() {
			if (this.platform.custom) {
				return 'Custom Server';
			} else {
				const ptemplate = platformTemplates.find(({ name }) => name === this.platform.template);
				return ptemplate && ptemplate.icon ? `<img src="${ptemplate.icon}" class="picon"/> &nbsp;` : null;
			}
		},
		hostName: {
			get() {
				const vals = this.platformConfig.server?.split(':')
				if (vals?.length !== 3) return this.platformConfig.server
				const port = vals.slice(-1)[0]
				return this.platformConfig.server?.replace(`:${port}`, '')
			},
			set(value) {
				this.platformConfig.server = value
			}
		}
	},
	watch: {
		isAuthRequired: function (isRequired) {
			if (!isRequired) {
				this.platformConfig.server = this.platformConfig.server.replace(
					/:\/\/.*?:.*?@/,
					'://'
				);
			} else {
				this.rtmpAuthUsername = '';
				this.rtmpAuthPassword = '';
			}
		},
		rtmpAuthUsername: function (val) {
			if (this.platform.server) {
				this.platformConfig.server = this.platformConfig.server.replace(
					/:\/\/.*?:.*?@/,
					'://'
				);
				if (val && this.rtmpAuthPassword) {
					this.platformConfig.server = this.platformConfig.server.replace(
						'://',
						'://' +
						encodeURIComponent(val) +
						':' +
						encodeURIComponent(this.rtmpAuthPassword) +
						'@'
					);
				}
			}
		},
		rtmpAuthPassword: function (val) {
			if (this.platform.server) {
				this.platformConfig.server = this.platformConfig.server.replace(
					/:\/\/.*?:.*?@/,
					'://'
				);
				if (this.rtmpAuthUsername && val) {
					this.platformConfig.server = this.platformConfig.server.replace(
						'://',
						'://' +
						encodeURIComponent(this.rtmpAuthUsername) +
						':' +
						encodeURIComponent(val) +
						'@'
					);
				}
			}
		}
	},
	async mounted() {
		this.$refs.modalConfigurePlatform.$on('shown', this.onInit);
		this.$refs.modalConfigurePlatform.$on('hide', () => {
			this.resetForm();
			this.clearErrors();
		});

		setTimeout(async () => {
			platformTemplates = await IntegrationService.getPublishPlatformList();
		}, 2000);
	},
	methods: {
		onInit() {
			this.platform.custom = this.platform.template === 'custom';

			const serverAddr = this.platform.server;
			this.platformConfig.server = serverAddr;
			this.platformConfig.port = this.platform.port
			this.platformConfig.key = this.platform.key;
			this.platformConfig.latency = this.platform.latency || 120;
			this.platformConfig.passphrase = this.platform.passphrase;

			const platformTemplate = _.find(platformTemplates, {
				name: this.platform.template
			});
			if (platformTemplate) {
				if (platformTemplate.customServer) {
					this.platform.customServer = true;
					this.platform.serverInputPlaceholder = platformTemplate.serverInputPlaceholder;
				}

				const { serverKeySegments } = platformTemplate;
				if (serverKeySegments) {
					this.serverKeySegments = serverKeySegments;
					this.serverKeySegmentValues = getServerUrlSegmentValues(
						serverAddr,
						serverKeySegments
					);
				} else {
					this.platformServers = platformTemplate.servers;
				}
			}

			let userAuthMatch;

			if (
				(userAuthMatch = this.platformConfig.server.match(/:\/\/(.*?):(.*?)@/))
			) {
				this.isAuthRequired = true;

				setTimeout(() => {
					this.rtmpAuthUsername = decodeURIComponent(userAuthMatch[1]);
					this.rtmpAuthPassword = decodeURIComponent(userAuthMatch[2]);
				}, 100);
			}
		},
		onInputChange(prop) {
			this.formErrors[prop] = false;

			if (prop === 'port' && this.platformConfig.port) {
				this.platformConfig.port = this.platformConfig.port.replace(/\s/g, '');
				const portNum = parseInt(this.platformConfig.port)
				if (!portNum || /\D/.test(this.platformConfig.port)) {
					this.customSrtPlatformErrors.port = 'Port must be a number'
					// replace value
					this.platformConfig.port = ''
				}
				else if (portNum > 65535) {
					this.customSrtPlatformErrors.port = 'Port must be equal to or below 65535'
				} else {
					this.customSrtPlatformErrors.port = ''
				}
			} else {
				this.customSrtPlatformErrors.port = ''
			}

			if (prop === 'latency' && this.platformConfig.latency) {
				this.platformConfig.latency = this.platformConfig.latency.replace(/\s/g, '');
				const portNum = parseInt(this.platformConfig.latency)
				if (!portNum || /\D/.test(this.platformConfig.latency)) {
					this.customSrtPlatformErrors.latency = 'Latency must be a number'
					// replace value
					this.platformConfig.latency = ''
				} else {
					this.customSrtPlatformErrors.latency = ''
				}
			} else {
				this.customSrtPlatformErrors.latency = ''
			}
			if (prop === 'key') {
				this.platformConfig.key = this.platformConfig.key.replace(/\s/g, '');
				if (/\^/.test(this.platformConfig.key)) {
					this.customSrtPlatformErrors.key = 'invalid streaming key'
					// replace value
					// this.customSrtPlatform.host = ''
				} else {
					this.customSrtPlatformErrors.key = ''
				}
			}
			// remove unnecessary key chars
			if (prop === 'key' && this.platform.mode !== 'srt') {
				setTimeout(() => {
					this.platformConfig[prop] = utils.resolveStreamKey(
						this.platformConfig[prop]
					);
				});
			}
			if (prop === 'server') {
				this.platformConfig.server = this.platformConfig.server.replace(/\s/g, '');
				if (/\^/.test(this.platformConfig.server)) {
					this.customSrtPlatformErrors.server = 'invalid destination ingest address'
					// replace value
					// this.customSrtPlatform.host = ''
				} else {
					this.customSrtPlatformErrors.server = ''
				}
			}
			if (this.platform.mode !== 'srt') {
				setTimeout(() => {
					if (
						this.platformConfig.server &&
						!this.platformConfig.server.match(/^[a-z]+:\/\//i)
					) {
						this.platformConfig.server = 'rtmp://' + this.platformConfig.server;
					}
				});
			}
		},
		hasMultipleServers() {
			return _.size(this.platformServers) > 1 || _.size(this.serverKeySegments);
		},
		focusServerKeySegment(event) {
			const soruceElement = event.target || event.srcElement;
			if (soruceElement instanceof HTMLInputElement) return;

			this.$refs.segment[0].focus();
		},
		computePlatformServerFromSegments() {
			const { serverKeySegments, serverKeySegmentValues } = this;
			const inputSegments = _.filter(serverKeySegments, { input: true });
			const validSegmentValues = _.compact(serverKeySegmentValues);

			if (_.size(inputSegments) !== _.size(validSegmentValues)) {
				this.platformConfig.server = null;
				return;
			}

			let serverAddr = '';
			_.each(this.serverKeySegments, (segment, index) => {
				if (segment.exclude) return;

				if (segment.input) {
					serverAddr += serverKeySegmentValues[index];
				} else {
					serverAddr += segment.text || segment;
				}
			});

			this.platformConfig.server = serverAddr;
		},
		getPlatformServers(templateName) {
			const platform = _.find(platformTemplates, { name: templateName });
			return (platform && platform.servers) || [];
		},
		async onSavePlatform() {
			this.checkPassword()
			this.error = null;
			if (!this.validateForm() || !this.isRtmpPassValid) return;

			const { server, key, port, latency, passphrase } = this.platformConfig;

			if (this.platform.server === server && this.platform.key === key) {
				if (this.platform.mode !== 'srt') {
					return this.dismiss();
				}
				if (this.platform.port === port && this.platform.latency === latency && this.platform.passphrase === passphrase) return this.dismiss();
			}

			let serverAddr = server;
			let streamKey = key;

			if (this.platform.mode !== 'srt') {
				serverAddr = utils.resolveURL(serverAddr, true);
				streamKey = utils.resolveStreamKey(streamKey);
			}

			this.processing = true;

			try {
				const updates = { server: serverAddr, key: streamKey };

				// no need encode if its srt
				if (this.platform.mode === 'srt') {
					updates['server'] = this.hostName
					updates['port'] = this.platformConfig.port
					updates['latency'] = this.platformConfig.latency
					updates['key'] = this.platformConfig.key
					updates['passphrase'] = this.platformConfig.passphrase
					console.log(this.platformConfig);
				}

				await StreamService.setStreamPlatformAddress(
					this.stream._id,
					this.platform._id,
					updates
				);

				this.$emit('platform-updated', this.platform, updates);
				this.dismiss();
			} catch (err) {
				this.error = err;
				this.processing = false;
			}
		},
		dismiss() {
			this.$refs.modalConfigurePlatform.hide();
			// clear up cache
			this.resetForm();
			this.clearErrors();

			// lazy clear
			setTimeout(() => {
				this.processing = false;
			}, 1000);
		},
		validateForm() {
			const props = ['server'];
			if (this.platform.mode === 'srt') {
				props.push('port')
			} else {
				props.push('key')
			}

			let valids = 0;
			_.each(props, prop => {
				let val = this.platformConfig[prop];
				if (prop === 'server') {
					if (this.platform.mode === 'srt') {
						val = val.startsWith('srt://')
					} else {
						let { valid } = utils.validateURL(val, { validatePathname: false });
						if (!valid) val = null;
					}
					if(val) {
						let valid = true;
						const platformName = this.platform?.name?.toLowerCase() || '';
						if(platformName.includes('tiktok')) {
							valid = val.includes('tiktokcdn');
						} else if(platformName.includes('instagram')) {
							valid = val.includes('fbcdn.net') || val.includes('instagram.com');
						}
						if (!valid) val = null;
					}
				}

				if (val) valids++;

				this.formErrors[prop] = !val;
			});

			let srtValidation = true
			if (this.platform.mode === 'srt') {
				srtValidation = srtValidation && !this.customSrtPlatformErrors.port
				srtValidation = srtValidation && !this.customSrtPlatformErrors.latency
			}

			return valids === props.length && srtValidation;
		},
		clearErrors() {
			this.formErrors.server = false;
			this.formErrors.key = false;
			this.formErrors.port = false;

			this.customSrtPlatformErrors = {
				port: '',
				latency: '',
				host: '',
				key: '',
				server: '',
				passphrase: ''
			}
		},
		resetForm() {
			this.platformServers = [];
			this.platformConfig.key = null;
			this.platformConfig.server = null;
			this.platformConfig.port = null;
			this.platformConfig.latency = null;
			this.platformConfig.passphrase = null;
			this.serverKeySegments = null;
			this.serverKeySegmentValues = null;
			this.rtmpAuthUsername = '';
			this.rtmpAuthPassword = '';
		},
		checkPassword() {
			if (this.rtmpAuthPassword.includes('@')) {
				this.isRtmpPassValid = false
			} else this.isRtmpPassValid = true
		}
	}
};

function getServerUrlSegmentValues(serverAddr, serverKeySegments) {
	const pos = [];
	const url = serverAddr;

	_.each(serverKeySegments, (param) => {
		if (!param || param.exclude) return;

		let val;
		if (!param.input) {
			const text = param.text || param;
			const si = url.indexOf(text);
			const n = _.size(text);
			val = [si, si + n, n];
		}

		pos.push(val);
	});

	const values = [];
	_.each(pos, (each, index) => {
		let val;

		if (!each) {
			const pnode = pos[index - 1];
			const nnode = pos[index + 1];

			const si = pnode ? pnode[1] : 0;
			const ei = nnode ? nnode[0] : url.length;

			val = url.substr(si, ei - si);
		}

		values[index] = val;
	});

	return values;
}
</script>

<style scoped></style>
