<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"
import _ from 'lodash'
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconBreak from '../icon/IconBreak.vue';
import IconHelp from '../icon/IconHelp.vue';
import IconPlus from '../icon/IconPlus.vue';

const store = useStore()
const playlist = computed(() => store.getters['PlayoutNew/playlist'])

const emit = defineEmits({
  'add-break': (value) => {return value}
})

const addBreak = () => {
	emit('add-break')
}
</script>

<template>
	<section class="flex items-center bg-surface-3 h-10 px-2 rounded-md">
		<icon-base class="mr-2">
			<icon-break />
		</icon-base>
		<span class="text-sm grow-0 truncate">Breaks</span>
		<ejs-tooltip content="Add 'Breaks' to your content playlist. You can set the duration for each break, ranging from a minimum of 2 minutes to a maximum of 12 hours.">
      <icon-base class="ml-2 text-surface-6 hover:text-surface-8">
        <icon-help />
      </icon-base>
    </ejs-tooltip>
		<FormButton size="sm" isIcon class="shrink-0 ml-auto" @click="addBreak">
			<icon-base>
				<icon-plus />
			</icon-base>
		</FormButton>
	</section>
</template>

<style scoped></style>