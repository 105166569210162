<template>
	<div class>
		<b-modal hide-header hide-footer id="modal-add-sub-second-stream" ref="modalAddStream" @shown="checkSelected"
			:modal-class="['modal-right', { 'modal-collapsed': selectedStreamType }]">
			<!-- stream types -->
			<div class="modal-container">
				<b-row align-v="center" class="px-3 mb-2 mt-100 mobile-header">
					<b-col cols="8" class="">
						<b-navbar-brand :to="{ path: '/dashboard' }" tag="a" class="modal-logo">
							<b-img height="32px" class="logo-icon" :src="require('@/assets/castr.svg')" alt="Streaming Dashboard - Castr" />
							<div class="logo-icon-dot" />
						</b-navbar-brand>
						<h3 class="text-s-m d-block d-md-none">
							Create New
						</h3>
					</b-col>
					<b-col cols="4" class="text-right">
						<b-button variant="icon" class="px-2 btn-close" @click="dismiss">
							<img :src="require('@/assets/images/menu-close.svg')" alt="Menu close">
						</b-button>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<h3 class="text-s-m ml-4 mt-5">
							Sub Second Streaming
						</h3>
						<p class="ml-4 text-s-s text-dark-8">Enhance your users' experience real-time streaming</p>
						<div>
							<div v-if="error">
								<div v-if="error.role">
									<b-row no-gutters class="bg-dark-3">
										<b-col class="p-3">
											<p class="mb-2">
												You do not have an active {{ (selectedStreamType === 'restream') ? 'Multistream' :
													(selectedStreamType !== 'vod' ? ' all in one' : '') }} subscription.</p>
											<p class="mb-0 text-s-m text-dark-7">Would you like to activate our 7 days trial ?</p>
										</b-col>
									</b-row>

									<div v-if="trialError" class="alert alert-danger">{{ trialError.message }}</div>
									<div class="text-right">
										<div v-if="trialProcessing">
											<b-progress :value="100" :max="100" animated class="w-100" />
										</div>
										<div v-else>
											<b-row>
												<b-col class="px-5 mt-4">
													<b-button :disabled="trialProcessing" variant="primary" size="md" class="w-100 mb-3"
														@click="forceActivateTrial"><span v-if="!trialProcessing">Activate Trial</span><span
															v-else>Activating... Please wait</span></b-button>
													<b-button variant="link" size="md" class="text-dark-7 w-100 mb-3"
														:to="`/subscribe?category=${selectedStreamType}`">
														Want more options?
													</b-button>
													<b-button variant="outline-secondary" class="w-100" size="md" @click="dismiss">Cancel</b-button>
												</b-col>
											</b-row>
										</div>
									</div>
								</div>
							</div>

							<!-- form -->
							<div class="mt-5">
								<b-row>
									<b-col>
										<b-alert v-if="error && !error.role" show variant="danger-style2" class="text-left mx-4">
											<!-- <div>{{ error.message }}</div> -->
											<div v-html="error.message"></div>
											<div v-if="error.subscription" class="text-left">
												<a href="/app/subscribe"><strong>upgrade now</strong></a>
											</div>
										</b-alert>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col class="px-4 py-2">
										<label class="text-s-s text-dark-9 text-500">
											Stream Name
										</label>
										<b-input v-model="channel.name" size="md" placeholder="Enter your stream name"
											@keypress="onInputChange('name')" />
										<p v-show="formErrors.name" class="text-warning pl-1">
											specify stream name
										</p>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col class="px-4 py-2">
										<label class="text-s-s text-dark-9 text-500">
											Ingest Region
										</label>
										<StreamRegion class="position-relative" :stream="channel" @switch-stream-region="changeRegion" />
										<p v-show="formErrors.region" class="text-warning pl-1">
											specify hosting region
										</p>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col class="px-4" v-show="operational || processing">
										<b-button variant="primary" size="md" class="w-100 mt-3" disabled v-if="processing">
											<Spinner text="Creating..." classes="text-dark-8" spinner-color="var(--c-dark-8)"
												spinner-size="15px" />
										</b-button>
										<div v-else class="w-100">
											<b-button variant="primary" size="md" class="w-100" @click="onSaveChannel">
												<!-- Create {{ selectedStreamType === 'vod' ? 'Bucket' : 'Stream' }} -->
												Create Sub Second Stream
											</b-button>
											<div class="mt-2">
												<b-button variant="outline-secondary" size="md" @click="dismiss"
													class="text-center w-100 mb-3 mb-md-0">
													Cancel
												</b-button>
											</div>
										</div>
									</b-col>
								</b-row>
							</div>
							<!-- <br> -->
						</div>
					</b-col>
				</b-row>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import IconBase from '@/components/icon/IconBase.vue';
import IconChevronUpDown from '@/components/icon/IconChevronUpDown.vue';
import IconLivestream from '@/components/icon/IconLivestream.vue';
import IconMultistream from '@/components/icon/IconMultistream.vue';
import IconIpcam from '@/components/icon/IconIpcam.vue';
import IconSchedulestream from '@/components/icon/IconSchedulestream.vue';
import IconVodChannel from '@/components/icon/IconVodChannel.vue';
import SubscriptionService from '@/services/SubscriptionService';
import StreamRegion from '../ui/StreamRegion.vue';

export default {
	name: 'AddSubSecondStreamModal',
	components: {
		Spinner,
		IconBase,
		IconChevronUpDown,
		IconLivestream,
		IconMultistream,
		IconIpcam,
		IconSchedulestream,
		IconVodChannel,
		StreamRegion
	},
	data() {
		return {
			processing: false,
			trialProcessing: false,
			error: null,
			trialError: null,
			operational: true,
			viewStage: 1,
			selectedStreamType: null,
			userSubscription: null,
			channel: {
				title: null,
				uiRegion: null,
				type: 'subsecond'
			},
			selectedRegion: null,
			regions: [],
			formErrors: { name: false, uiRegion: false },
			onInputChange(prop) {
				this.formErrors[prop] = false;
			}
		};
	},
	props: {
		
	},
	computed: {
		userEmail() {
			return this.$store.state.User.email;
		},
		subscriptions() {
			return this.$store.state.User.subscriptions;
		},
		activeTeam() {
			return this.$store.state.User.activatedTeam;
		},
		memberPermission() {
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
	},
	watch: {

	},
	async mounted() {
		this.regions = await StreamService.getAvailableRegions('list');
	},
	methods: {

		onDismiss() {
			this.resetForm();
			this.clearErrors();
			// lazy clear
			setTimeout(() => {
				this.processing = false;
			}, 1000);
		},
		dismiss() {
			this.$refs.modalAddStream.hide();
			this.onDismiss();
		},
		changeRegion(region) {
			this.selectedRegion = region
			this.channel.uiRegion = region;
			this.onInputChange('region')
		},
		getCountryFlag(region) {
			return `https://assets.castr.io/countryflags/${region.identifier}/flat/64.png`;
		},
		async forceActivateTrial() {
			this.trialError = null;
			this.trialProcessing = true;

			// activate trial
			const subCat = this.selectedStreamType;
			try {
				await SubscriptionService.requestTrial(subCat);
				// update vuex state
				await this.$store.dispatch('User/loadUser', null, { root: true });
			} catch (e) {
				this.trialError = e;
				return;
			} finally {
				this.trialProcessing = false;
			}

			this.error = null;
			this.operational = true;

			// on trial activated
			if (!this.trialError) {
				this.$notify({
					group: 'success',
					text: `${this.selectedStreamType} trial activated successfully`
				});
			}
		},
		async onSaveChannel() {
			this.error = null;

			if (!this.validateForm()) return;

			this.processing = true;

			try {
				// const stream = await StreamService.addStream(
				const subStream = await StreamService.addStream(
					this.channel.name,
					this.channel.uiRegion._id,
					this.channel.type
				);


				this.$emit('new-channel', subStream, this.selectedRegion);
				await this.$store.dispatch('Streams/getStreams')
				this.dismiss();
			} catch (err) {
				this.error = err;
				this.processing = false;
				this.selectedRegion = null;
				err.message = '<i class="fas fa-exclamation-triangle mr-2"></i>' + err.message;
				// check if subscripiton error
				if (err && _.indexOf(err.message, 'subscription') > -1) {
					this.error.subscription = true;
				}
			}
		},
		validateForm() {
			const props = ['name', 'uiRegion'];

			let valids = 0;
			_.each(props, prop => {
				const val = this.channel[prop];
				if (val) valids++;
				// else this.formErrors[prop] = true;

				this.formErrors[prop] = !val;
			});

			return valids === props.length;
		},
		clearErrors() {
			this.error = null;
			this.formErrors.name = false;
			this.formErrors.region = false;
		},
		resetForm() {
			this.selectedStreamType = null;
			this.channel.name = null;
			// this.channel.region = null;
			// this.selectedRegion = null;
		},
		checkSelected() {
			if (this.typeFromBilling === 'restream' || this.typeFromBilling === 'live') {

				this.selectStreamType(this.typeFromBilling);
			}
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stream-item {
	padding: 16px 26px;
	position: relative;
	/* margin-bottom: 20px; */
	cursor: pointer;
}

.stream-item__desc {
	position: relative;
	flex-grow: 1;
}

.stream-item:not(:last-child) .stream-item__desc:after {
	content: '';
	display: block;
	border-top: 1px solid var(--c-dark-3);
	width: 100%;
	height: 1px;
	position: absolute;
	left: 0;
	bottom: -15px;
}

.stream-item:hover {
	background-color: var(--c-dark-3);
}

.stream-item.active {
	background-color: var(--c-main-1);
}

.stream-item.active .stream-item__desc:after {
	content: none;
}

.stream-item svg {
	color: var(--c-main-1);
}

.stream-item:hover svg,
.stream-item.active svg {
	color: #fff;
}

.stream-item.active .stream-item__p {
	color: #fff;
}

.stream-item__title {
	font-size: var(--font-s-m);
}

.stream-item__p {
	font-size: var(--font-s-s);
}

/* .hideform {
		display: none;
	}
	.showform {
		display: flex;
	} */
.second-modal {
	position: fixed;
	top: 0;
	background-color: var(--c-dark-2);
	margin: auto;
	width: 400px;
	height: 100%;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, right 0.3s ease-out;
	right: 0;
	z-index: -1;
	border-left: 1px solid var(--c-dark-3);
	border-right: 1px solid var(--c-dark-3);
}

.showform.second-modal {
	right: 414px;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, right 0.3s ease-out;
}

.stream-type-info {
	/* background-color: var(--c-dark-3); */
	padding: 0px;
}

.region-dropdown .dropdown-menu {
	left: -100% !important;
}

.region-flag {
	width: 20px;
}

.modal-logo {
	padding-left: 9px;
}

.stream-type-info h4,
.stream-type-info p {
	cursor: pointer;
}

@media (max-width: 767px) {
	.mobile-header {
		border-radius: 24px 24px 0 0;
		margin-top: 0;
		background-color: var(--c-dark-3);
		border-bottom: 1px solid var(--c-dark-5);
	}

	.hideform.second-modal {
		bottom: -115vh;
		left: 0;
		z-index: 0;
		max-width: 100%;
		transition: opacity 0.3s linear, bottom 0.3s ease-out;
	}

	.showform.second-modal {
		height: auto;
		top: auto;
		bottom: 0;
		left: 0;
		z-index: 1030;
		max-width: 100%;
		transition: opacity 0.3s linear, bottom 0.3s ease-out;
	}

	.modal-logo {
		display: none;
	}

	.modal-title {
		padding: 16px 0;
	}

	.modal-title-style2 {
		display: none;
	}

	.second-modal {
		width: 100%;
		border-left: none;
		border-right: none;
	}

	.btn-close {
		background-color: var(--c-dark-5);
		border-radius: 50%;
		color: var(--c-dark-8);
		height: 32px;
		width: 32px;
	}

	.mt-200 {
		margin-top: 0;
	}

	.stream-item {
		padding: 15px;
	}

	.btn-icon {
		color: var(--c-dark-7);
	}

	:deep( .region-dropdown .dropdown-menu) {
		max-width: 315px;
	}

	:deep(.regions-wrapper) {
		top: -191px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.showform.second-modal {
		right: 100%;
	}
}</style>
