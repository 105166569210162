<template>
  <ul class="absolute bg-surface-2 shadow-md z-10 right-0 !p-2 rounded">
    <li class="">
      <b-input-group size="md" class="bg-dark-3 rounded">
        <template #prepend>
          <b-input-group-text class="bg-transparent border-0 text-dark-6">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.25 6.75C2.25 4.26472 4.26472 2.25 6.75 2.25C9.23528 2.25 11.25 4.26472 11.25 6.75C11.25 9.23528 9.23528 11.25 6.75 11.25C4.26472 11.25 2.25 9.23528 2.25 6.75ZM6.75 0.75C3.43629 0.75 0.75 3.43629 0.75 6.75C0.75 10.0637 3.43629 12.75 6.75 12.75C8.13653 12.75 9.41322 12.2797 10.4292 11.4899L13.9697 15.0303C14.2626 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2626 15.0303 13.9697L11.4899 10.4292C12.2797 9.41322 12.75 8.13653 12.75 6.75C12.75 3.43629 10.0637 0.75 6.75 0.75Z"
                fill="currentColor"
              />
            </svg>
          </b-input-group-text>
        </template>
        <b-form-input
          ref="searchWidgetInputElement"
          placeholder="Search for files"
          size="md"
          class="border-0"
          v-model="search"
        ></b-form-input>
        <b-button
          @click="search = ''"
          variant="icon"
          size="md"
          class="search-clear"
          :class="{ active: search.length > 0 }"
        >
          <svg
            class="text-dark-8"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 15.5C11.866 15.5 15 12.366 15 8.5C15 4.63401 11.866 1.5 8 1.5C4.13401 1.5 1 4.63401 1 8.5C1 12.366 4.13401 15.5 8 15.5ZM6.03033 5.46967C5.73744 5.17678 5.26256 5.17678 4.96967 5.46967C4.67678 5.76256 4.67678 6.23744 4.96967 6.53033L6.93934 8.5L4.96967 10.4697C4.67678 10.7626 4.67678 11.2374 4.96967 11.5303C5.26256 11.8232 5.73744 11.8232 6.03033 11.5303L8 9.56066L9.96967 11.5303C10.2626 11.8232 10.7374 11.8232 11.0303 11.5303C11.3232 11.2374 11.3232 10.7626 11.0303 10.4697L9.06066 8.5L11.0303 6.53033C11.3232 6.23744 11.3232 5.76256 11.0303 5.46967C10.7374 5.17678 10.2626 5.17678 9.96967 5.46967L8 7.43934L6.03033 5.46967Z"
              fill="currentColor"
            />
          </svg>
        </b-button>
      </b-input-group>
    </li>
    <b-radio-group>

      <div class="folder-list">
        <li :key="index" v-for="(stream, index) in filteredVods">
            <b-row
              class="text-dark-9 folder-item text-s-s mb-2"
              :class="{
                'disabled-vod-move-folder': stream.liveParent
              }"
              no-gutters
            >
              <b-col
                class="d-flex align-items-center text-truncate py-2 pl-3 folder-item__name"
                md="9"
              >
                <svg
                  class="text-dark-8 mr-2 flex-shrink-0"
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.89135 0.445386C5.67504 0.399638 5.45409 0.399764 5.1801 0.39992L5.13098 0.399939H3.8999L3.87581 0.399939C3.47629 0.39993 3.14057 0.399922 2.86585 0.422368C2.57861 0.445836 2.30564 0.4968 2.04652 0.628825C1.65138 0.830159 1.33012 1.15142 1.12879 1.54656C0.996765 1.80567 0.945801 2.07865 0.922333 2.36588C0.899888 2.6406 0.899894 2.9763 0.899903 3.37581V3.37584V3.39994V9.59991V9.624V9.62404C0.899894 10.0235 0.899888 10.3592 0.922333 10.634C0.945801 10.9212 0.996765 11.1942 1.12879 11.4533C1.33012 11.8484 1.65138 12.1697 2.04652 12.371C2.30564 12.503 2.57861 12.554 2.86585 12.5775C3.14057 12.5999 3.47629 12.5999 3.87582 12.5999H3.8999H12.0999H12.124C12.5235 12.5999 12.8592 12.5999 13.134 12.5775C13.4212 12.554 13.6942 12.503 13.9533 12.371C14.3484 12.1697 14.6697 11.8484 14.871 11.4533C15.003 11.1942 15.054 10.9212 15.0775 10.634C15.0999 10.3592 15.0999 10.0235 15.0999 9.62399V9.59991V5.89994V5.87586C15.0999 5.47633 15.0999 5.14061 15.0775 4.86588C15.054 4.57865 15.003 4.30567 14.871 4.04656C14.6697 3.65142 14.3484 3.33016 13.9533 3.12883C13.6942 2.9968 13.4212 2.94584 13.134 2.92237C12.8592 2.89992 12.5235 2.89993 12.124 2.89994H12.124L12.0999 2.89994H9.86882C9.52217 2.89994 9.43465 2.89694 9.35675 2.88046C9.27506 2.86318 9.19616 2.83462 9.12234 2.79559C9.05196 2.75838 8.9828 2.70466 8.71649 2.48274L7.05154 1.09528L7.01381 1.06382C6.80343 0.888293 6.63377 0.746745 6.43831 0.643412C6.26606 0.552349 6.08198 0.485701 5.89135 0.445386Z"
                    :fill="stream.liveParent ? '#D9D9D9' : 'currentColor'"
                  />
                </svg>
                <b-badge
                  v-if="stream.liveParent"
                  variant="dark-3"
                  class="badge_beta mr-2 disabled-vod-move-folder"
                  >Live-to-VOD</b-badge
                >
                <div class="text-truncate folder-name">
                  {{ stream.name }}
                </div>
              </b-col>
              <b-col
                md="2"
                class="text-right py-2 pr-3 folder-item__files d-flex align-items-center justify-content-end"
              >
                {{ stream.totalFiles }}
              </b-col>
              <b-col md="1" class="d-flex align-items-center text-truncate py-2 folder-item__name">
                <b-radio v-model="selectedFolder" :value="stream._id" :disabled="Boolean(stream.liveParent)">
                </b-radio>
              </b-col>
              <Spinner
                v-if="loading"
                text="Retrieving data..."
                classes="text-dark-8 my-2 ml-3"
                spinner-color="var(--c-dark-8)"
                spinner-size="15px"
              />
            </b-row>
        </li>
      </div>

      <hr class="mb-3" />
      <div class="submit-area">
        <b-button
          variant="outline-secondary"
          class="mr-2"
          size="md"
          @click="$emit('cancel')"
        >
          Cancel
        </b-button>

        <b-button
          :disabled="!selectedFolder"
          variant="primary"
          size="md"
          @click="$emit('confirm', selectedFolder)"
        >
          Move
        </b-button>
      </div>
    </b-radio-group>
  </ul>
</template>

<script>
import _ from "lodash";

export default {
  name: "MoveVod",
  props: {
    vodStreams: {
      type: Array,
      default: () => [],
    },
    currentFolder: {
      type: String,
    }
  },
  data() {
    return {
      search: "",
      loading: false,
      selectedFolder: "",
    };
  },
  computed: {
    filteredVods() {
      const vodStreams = _.cloneDeep(
        this.vodStreams.filter((stream) => stream._id !== this.currentFolder && stream.name.toLowerCase().includes(this.search.toLowerCase())
        )
      );
      return vodStreams.filter(stream => stream._id !== this.currentFolder).sort(function (a, b) {
        return new Date(b.creationTime) - new Date(a.creationTime);
      });
    },
  },
};
</script>

<style scoped>
.submit-area {
  display: flex;
  justify-content: flex-end;
}

.disabled-vod-move-folder {
  color: #939aa8;
}

.folder-list {
  max-height: 300px;
  overflow-y: scroll;
}
</style>