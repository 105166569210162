<script>
export default {
  compatConfig: {
    MODE: 3,
    COMPONENT_V_MODEL: false,
  }
}
</script>
<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  size: {
    type: String,
    default: 'md',
  },
  size: {
    type: String,
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  maxlength: {
    type: Number,
    default: 524288
  },
  helper: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  isError: {
    type: Boolean,
    default: false,
  },
  customClasses: {
    type: String,
  },
  id: {
    type: String,
  },
  modelValue: {
    default: ''
  },
})


const emit = defineEmits({
  'update:modelValue': (value) => {return value}
})

const computedValue = computed({
  get: () => props.modelValue,
  set: value => {
    emit('update:modelValue', value)
  },
})

const inputClass = computed(() => {
  const base = [
    'w-full leading-body bg-surface-3 focus:bg-surface-4 focus:outline-0 focus:ring-0 rounded transition-color placeholder-sureface-8 text-surface-text-2 disabled:text-surface-7',
  ]
  if (props.isError) {
    base.push('ring-1 ring-error-light focus:ring-error-light bg-error-transparent')
  } else {
    base.push('border-neutral-n50 focus:border-surfaces-primary focus:placeholder:text-transparent')
  }
  const size = props.size === 'lg' 
    ? 'h-10' 
    : props.size === 'sm'
      ? 'h-7 px-2 text-base md:text-tiny'
      : 'h-8 px-3 text-base md:text-tiny'
  
  base.push(size)
  return base
})

const wrapperClass = computed(() => {
  return props.helper ? 'pb-4' : ''
})

const focusState = ref(false)

const toggleFocus = async (state) => {
  focusState.value = state
  emit('blur', state)
}

const helperClass = computed(() => {
  const classes = [
    'absolute text-xs px-3',
  ]
  classes.push(props.isError ? 'text-error' : 'text-surface-9')
  classes.push(props.label ? 'top-[72px]' : 'top-10')
  return classes
})
</script>

<template>
  <div class="relative w-full" :class="wrapperClass">
    <label v-if="label" :for="id">{{ label }}</label>
    <input :disabled="disabled" :readonly="readonly" :type="type" :class="[inputClass, customClasses]" :id="id" v-model="computedValue" :placeholder="placeholder" @blur="toggleFocus(false)" :maxlength="maxlength"
      @focus="toggleFocus(true)" />
    <p class="absolute top-10 text-xs px-3" :class="helperClass">{{ helper }}</p>
  </div>
</template>

<style>
@layer tailwind {
  input {
    @apply px-3 py-2
  }
}
</style>
