<script setup>
const props = defineProps({
  icon: {
		type: Object
  },
	title: {
		type: String
	},
	description: {
		type: String
	},
	isActive: {
		type: Boolean,
		default: false,
	},
	onClick: Function,
})

</script>

<template>
	<button @click="onClick" class="grid grid-cols-[min-content_1fr] grid-rows-[min-content_1fr] text-left gap-x-3 !p-4 hover:bg-surface-3 w-full" :class="{'bg-main hover:!bg-main-light':isActive}">
		<component class="row-span-2 size-6" :is="props.icon" />
		<h3 class="m-0">{{props.title}}</h3>
		<p class="text-tiny" :class="isActive ? 'text-surface-text-2' : 'text-surface-8'">{{ props.description }}</p>
	</button>
</template>
