<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import _ from 'lodash';
import { useNotification } from "@kyvg/vue3-notification";
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import StreamService from '@/services/StreamService';
import IconLivestream from '@/components/icon/IconLivestream.vue';
import IconMultistream from '@/components/icon/IconMultistream.vue';
import StreamRegion from '../ui/StreamRegion.vue';
import FormInput from '../Atoms/FormInput.vue';
import FormButton from '../Atoms/FormButton.vue';
import Sidebar from '../modals/Sidebar'
import SidebarItem from './SidebarItem'
import Alert from '../Atoms/Alert.vue';
import SidebarTrial from './SidebarTrial.vue';

const store = useStore()
const { notify } = useNotification()
const router = useRouter()

const sidebar = ref(null)
const stream = ref({
	name: '',
	type: null,
	uiRegion: null,
})

const defaulStage = ref(null)
const regions = ref(null)

const isLoading = ref(false)

const error = ref(null)

const emit = defineEmits({
	'new-channel': (stream, region) => { return stream, region }
})

const hasMultistreamSubscription = computed(() => store.getters['User/hasMultistreamSubscription'])
const hasLiveSubscription = computed(() => store.getters['User/hasLiveSubscription'])
const isLiveSubscriptionExpired = computed(() => store.getters['User/isLiveSubscriptionExpired'])

watch([hasMultistreamSubscription, isLiveSubscriptionExpired], () => {
	if (!hasMultistreamSubscription.value && !isLiveSubscriptionExpired.value) {
		defaulStage.value = 'live'
	} else {
		defaulStage.value = null
	}
	stream.value.type = defaulStage.value
}, { immediate: true })
onMounted(async () => {
	regions.value = await StreamService.getAvailableRegions('list');
})
const toggleSidebar = () => sidebar.value.toggleSidebar()

const selectRegion = (region) => stream.value.uiRegion = region

const setStreamType = (type) => stream.value.type = type

const currentStage = computed(() => stream.value.type ? 2 : 1)

const clearData = () => {
	stream.value.type = defaulStage.value
	stream.value.uiRegion = null
	error.value = null
}
const title = computed(() => {
	if (stream.value.type === 'restream') return 'Multistream'
	if (stream.value.type === 'live') return 'All in One Stream'
})
const description = computed(() => {
	if (stream.value.type === 'restream') return 'Stream your videos to multiple social platforms and destinations at the same time.'
	if (stream.value.type === 'live') return 'Livestream videos on your own website and multiple stream destinations.'
})

const goToStage = (stage) => {
	if (stage === 1) stream.value.type = null
}
const isDisabled = computed(() => {
	return !stream.value.uiRegion || stream.value.name.length === 0
})

const computedStage = computed(() => !hasMultistreamSubscription.value ? 'stage-one' : 'stage-two')

const isMultiStage = computed(() => hasMultistreamSubscription.value)

const showTrial = computed(() => {
	return !hasLiveSubscription.value && currentStage.value === 2 && stream.value.type === 'live'
})
const createStream = async () => {
	try {
		isLoading.value = true
		const newStream = await StreamService.addStream(
			stream.value.name,
			stream.value.uiRegion._id,
			stream.value.type
		);
		notify({ group: 'success', text: 'Stream deployed successfully' })
		await store.dispatch('Streams/getStreams')
		let routeName = ''
		if (stream.value.type === 'live') { routeName = 'LivestreamManage' }
		if (stream.value.type === 'restream') { routeName = 'SimulcastManage' }
		router.push({
			name: routeName,
			params: {
				streamId: newStream._id
			}
		})
	} catch (e) {
		error.value = e.message
		console.log(e);
	} finally {
		isLoading.value = false
	}
}

defineExpose({ toggleSidebar })
</script>

<template>
	<Sidebar ref="sidebar" :stage="currentStage" @closed="clearData" @go-to-stage="goToStage"
		:isMultiStage="isMultiStage">
		<template v-if="isMultiStage" v-slot:stage-one>
			<div class="mt-12">
				<h1 class="text-xl !px-4 font-meduim mb-6">Create New</h1>
				<SidebarItem title="Multistream" description="Stream To Multiple Sites" :icon="IconMultistream"
					@click="setStreamType('restream')" :isActive="stream.type === 'restream'" />
				<SidebarItem title="All in One Stream"
					description="Livestream to websites with your player, stream to social media, and convert livestreams to on-demand videos — all in one place"
					:icon="IconLivestream" @click="setStreamType('live')" :isActive="stream.type === 'live'" />
			</div>
		</template>
		<template v-slot:[computedStage]>
			<SidebarTrial v-if="showTrial" />
			<div v-else class="px-6 h-full">
				<h3 class="text-lg font-meduim mb-8 md:!mb-2 -mt-[44px] md:mt-0 md:!ml-0 md:!mt-3"
					:class="isMultiStage ? '!ml-6' : '!ml-0'">{{ title }}</h3>
				<p class="text-sm text-surface-8 !mb-4">{{ description }}</p>
				<Alert v-if="error" :text="error" type="error" class="!mb-2" />
				<FormInput label="Stream name *" class="!mb-6" placeholder="Stream name" v-model="stream.name" />
				<div class="relative mb-6">
					<StreamRegion :stream="stream" @switch-stream-region="selectRegion" />
				</div>
				<FormButton :isLoading="isLoading" class="!mb-3 w-full text-center justify-center" :label="'Create ' + title"
					:disabled="isDisabled" @click="createStream" />
				<FormButton class="w-full text-center justify-center mb-4" type="secondary" label="Cancel"
					@click="toggleSidebar" />
			</div>
		</template>
	</Sidebar>
</template>
