<template>
  <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)"
    spinner-size="15px" />
  <div v-else-if="(hasLiveSubscription && hasLiveSubscription.enabled)" class="container page-title-area transcoder-container" :class="{'container--transcoder': !(streamFilter.length > 0)}">
    <header class="title d-flex justify-content-between align-items-center">
      <div class="d-flex justify-content-start flex-column">
        <h1 class="page-title mt-0 mt-md-5">Transcoder</h1>

        <div v-if="streamFilter.length > 0" class="d-flex align-items-center feature-doc">
          <a class="text-dark-8 text-s-ss text-500 mb-0 mb-md-3 pb-1 pb-md-0 inline-flex items-center"
            href="https://docs.castr.com/en/articles/8702304-how-to-transcode-your-stream" target="_blank">
            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1.4999 1.8999C1.16853 1.8999 0.899902 2.16853 0.899902 2.4999V12.4999C0.899902 12.8313 1.16853 13.0999 1.4999 13.0999H5.7499C6.66117 13.0999 7.3999 13.8386 7.3999 14.7499V14.9999C7.3999 15.3313 7.66853 15.5999 7.9999 15.5999C8.04132 15.5999 8.08176 15.5957 8.12082 15.5877C8.39423 15.5318 8.5999 15.2899 8.5999 14.9999V14.7499C8.5999 13.8386 9.33863 13.0999 10.2499 13.0999H14.4999C14.8313 13.0999 15.0999 12.8313 15.0999 12.4999V2.4999C15.0999 2.16853 14.8313 1.8999 14.4999 1.8999H10.4999C9.47379 1.8999 8.56413 2.39844 7.9999 3.16652C7.43568 2.39844 6.52601 1.8999 5.4999 1.8999H1.4999ZM8.5999 4.9999L8.5999 12.4258C9.06554 12.0947 9.63498 11.8999 10.2499 11.8999H13.8999V3.0999H10.4999C9.45056 3.0999 8.5999 3.95056 8.5999 4.9999ZM7.3999 4.9999C7.3999 3.95056 6.54924 3.0999 5.4999 3.0999H2.0999V11.8999H5.7499C6.36483 11.8999 6.93426 12.0947 7.3999 12.4258V4.9999Z"
                fill="#ADB8DC" fill-opacity="0.5" />
            </svg>
            How Transcoder works
          </a>

          <a class="text-dark-8 text-s-ss text-500 mb-3 inline-flex items-center ml-0 ml-md-4" v-b-modal.modal-demo-video>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.98597 3.65906L10.6171 5.62591C12.3187 6.54763 13.1695 7.0085 13.3161 7.68016C13.3621 7.89091 13.3621 8.10909 13.3161 8.31984C13.1695 8.9915 12.3187 9.45237 10.6171 10.3741L6.98597 12.3409C5.41916 13.1896 4.63576 13.614 4.01328 13.4004C3.81704 13.3331 3.6369 13.2258 3.48425 13.0853C3 12.6397 3 11.7487 3 9.96685V6.03315C3 4.25126 3 3.36032 3.48425 2.91468C3.6369 2.77419 3.81704 2.6669 4.01328 2.59958C4.63576 2.38604 5.41916 2.81038 6.98597 3.65906Z"
                stroke="#68759C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Watch a tutorial
          </a>
        </div>
      </div>
      <b-button size="md" variant="primary" v-b-modal="'modal-add-transcoder'">
        <svg class="mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.99999 0.399902C6.33136 0.399902 6.59999 0.668532 6.59999 0.999902V5.3999H11C11.3314 5.3999 11.6 5.66853 11.6 5.9999C11.6 6.33127 11.3314 6.5999 11 6.5999H6.59999V10.9999C6.59999 11.3313 6.33136 11.5999 5.99999 11.5999C5.66862 11.5999 5.39999 11.3313 5.39999 10.9999V6.5999H0.999994C0.668623 6.5999 0.399994 6.33127 0.399994 5.9999C0.399994 5.66853 0.668623 5.3999 0.999994 5.3999H5.39999V0.999902C5.39999 0.668532 5.66862 0.399902 5.99999 0.399902Z"
            fill="currentColor" />
        </svg>
        New Transcoder Profile
      </b-button>
    </header>
    <section v-if="streamFilter.length > 0" class="text-center mt-md-4 mt-5 pt-5 pt-md-0 text-dark-6">
      <div :class="{ 'list-view': true }" class="castr-tabs">
        <b-row>
          <b-col cols="12" md="6" :lg="4" v-for="stream in streamFilter" :key="stream._id" class="grid-item">
            <stream-card-view :stream="stream" :showSubstreams="false" :compactMode="false" :lightMode="false"
              :previewMode="false" :check="false" :storage="true" :transcoderView="true"
              @delete-stream="onTranscoderDeleteReq" :deleteBlock="deleteProcessing" />
          </b-col>
        </b-row>
      </div>

      <confirm-modal message="Would you like to delete this transcoder profile ?" modal-id="delete-transcoder"
        modal-type="danger" @modal-confirm="onTranscoderDeleteConfirm" />

    </section>

    <section v-else class="text-center mt-100">
      <h2 class="text-dark-8 text-s-l text-500">Effortlessly Customize Your Streaming Configuration</h2>
      <p class="text-s-m text-dark-6 mb-2">Seamlessly Modify Codecs and Bitrates With Ease</p>
      <div class="d-flex flex-column mw-225 mx-auto">
        <a class="text-dark-8 text-s-ss text-500 d-block"
          href="https://docs.castr.com/en/articles/8702304-how-to-transcode-your-stream" target="_blank">
          <b-button class="mb-2" target="_blank" variant="outline-secondary" size="lg">
            <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1.4999 1.8999C1.16853 1.8999 0.899902 2.16853 0.899902 2.4999V12.4999C0.899902 12.8313 1.16853 13.0999 1.4999 13.0999H5.7499C6.66117 13.0999 7.3999 13.8386 7.3999 14.7499V14.9999C7.3999 15.3313 7.66853 15.5999 7.9999 15.5999C8.04132 15.5999 8.08176 15.5957 8.12082 15.5877C8.39423 15.5318 8.5999 15.2899 8.5999 14.9999V14.7499C8.5999 13.8386 9.33863 13.0999 10.2499 13.0999H14.4999C14.8313 13.0999 15.0999 12.8313 15.0999 12.4999V2.4999C15.0999 2.16853 14.8313 1.8999 14.4999 1.8999H10.4999C9.47379 1.8999 8.56413 2.39844 7.9999 3.16652C7.43568 2.39844 6.52601 1.8999 5.4999 1.8999H1.4999ZM8.5999 4.9999L8.5999 12.4258C9.06554 12.0947 9.63498 11.8999 10.2499 11.8999H13.8999V3.0999H10.4999C9.45056 3.0999 8.5999 3.95056 8.5999 4.9999ZM7.3999 4.9999C7.3999 3.95056 6.54924 3.0999 5.4999 3.0999H2.0999V11.8999H5.7499C6.36483 11.8999 6.93426 12.0947 7.3999 12.4258V4.9999Z"
                fill="#ADB8DC" fill-opacity="0.5" />
            </svg>
            How Transcoder works
          </b-button>
        </a>
        <b-button class="mb-2" target="_blank" variant="outline-secondary" size="lg" v-b-modal.modal-demo-video>
          <svg width="17" height="16" class="mr-2" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.90252 3.12194C4.30411 2.82274 3.60002 3.25788 3.60002 3.92693V12.0728C3.60002 12.7419 4.30411 13.177 4.90252 12.8778L13.0484 8.80486C13.7118 8.47319 13.7118 7.52657 13.0484 7.19489L4.90252 3.12194ZM2.40002 3.92693C2.40002 2.36583 4.04288 1.35048 5.43917 2.04863L13.5851 6.12158C15.1329 6.89548 15.1329 9.10427 13.5851 9.87817L5.43917 13.9511C4.04288 14.6493 2.40002 13.6339 2.40002 12.0728V3.92693Z"
              fill="#8794B5" />
          </svg>
          Transcoder Demo
        </b-button>

        <b-button size="md" variant="primary" v-b-modal="'modal-add-transcoder'">
          <svg class="mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.9999 0.899902C7.33127 0.899902 7.5999 1.16853 7.5999 1.4999V6.3999H12.4999C12.8313 6.3999 13.0999 6.66853 13.0999 6.9999C13.0999 7.33127 12.8313 7.5999 12.4999 7.5999H7.5999V12.4999C7.5999 12.8313 7.33127 13.0999 6.9999 13.0999C6.66853 13.0999 6.3999 12.8313 6.3999 12.4999V7.5999H1.4999C1.16853 7.5999 0.899902 7.33127 0.899902 6.9999C0.899902 6.66853 1.16853 6.3999 1.4999 6.3999H6.3999V1.4999C6.3999 1.16853 6.66853 0.899902 6.9999 0.899902Z"
              fill="white" />
          </svg>
          New transcoder profile
        </b-button>

      </div>
    </section>
    <add-transcoder-modal @transcoder-added="onNewTranscoder" />
    <demo-video-modal videoid="0MPTCZa5OJIYRebzIe6pY" />
  </div>

  <div v-else class="container page-title-area">
    <header class="title d-flex justify-content-between align-items-center">
      <h1 class="page-title mb-3 mt-5">Transcoder</h1>
    </header>

    <section class="text-center mt-100 text-dark-6">
      <h2 class="text-dark-8 text-s-m text-500">Effortlessly Customize Your Streaming Configuration</h2>
      <p class="text-s-s text-dark-6 mb-4">Seamlessly Modify Codecs and Bitrates With Ease</p>
      <div class="d-flex flex-column mw-225 mx-auto">
        <a class="text-dark-8 text-s-ss text-500 d-block"
          href="https://docs.castr.com/en/articles/8702304-how-to-transcode-your-stream" target="_blank">
          <b-button class="mb-2" target="_blank" variant="outline-secondary" size="lg">
            <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1.4999 1.8999C1.16853 1.8999 0.899902 2.16853 0.899902 2.4999V12.4999C0.899902 12.8313 1.16853 13.0999 1.4999 13.0999H5.7499C6.66117 13.0999 7.3999 13.8386 7.3999 14.7499V14.9999C7.3999 15.3313 7.66853 15.5999 7.9999 15.5999C8.04132 15.5999 8.08176 15.5957 8.12082 15.5877C8.39423 15.5318 8.5999 15.2899 8.5999 14.9999V14.7499C8.5999 13.8386 9.33863 13.0999 10.2499 13.0999H14.4999C14.8313 13.0999 15.0999 12.8313 15.0999 12.4999V2.4999C15.0999 2.16853 14.8313 1.8999 14.4999 1.8999H10.4999C9.47379 1.8999 8.56413 2.39844 7.9999 3.16652C7.43568 2.39844 6.52601 1.8999 5.4999 1.8999H1.4999ZM8.5999 4.9999L8.5999 12.4258C9.06554 12.0947 9.63498 11.8999 10.2499 11.8999H13.8999V3.0999H10.4999C9.45056 3.0999 8.5999 3.95056 8.5999 4.9999ZM7.3999 4.9999C7.3999 3.95056 6.54924 3.0999 5.4999 3.0999H2.0999V11.8999H5.7499C6.36483 11.8999 6.93426 12.0947 7.3999 12.4258V4.9999Z"
                fill="#ADB8DC" fill-opacity="0.5" />
            </svg>
            How Transcoder works
          </b-button>
        </a>
        <b-button class="mb-2" target="_blank" variant="outline-secondary" size="lg" v-b-modal.modal-demo-video>
          <svg width="17" height="16" class="mr-2" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.90252 3.12194C4.30411 2.82274 3.60002 3.25788 3.60002 3.92693V12.0728C3.60002 12.7419 4.30411 13.177 4.90252 12.8778L13.0484 8.80486C13.7118 8.47319 13.7118 7.52657 13.0484 7.19489L4.90252 3.12194ZM2.40002 3.92693C2.40002 2.36583 4.04288 1.35048 5.43917 2.04863L13.5851 6.12158C15.1329 6.89548 15.1329 9.10427 13.5851 9.87817L5.43917 13.9511C4.04288 14.6493 2.40002 13.6339 2.40002 12.0728V3.92693Z"
              fill="#8794B5" />
          </svg>
          Transcoder Demo
        </b-button>

        <b-button size="md" variant="primary" to="/subscribe">
          Upgrade Plan
        </b-button>
      </div>
    </section>
    <demo-video-modal videoid="0MPTCZa5OJIYRebzIe6pY" />
  </div>
</template>

<script>
import Spinner from "@/components/ui/Spinner.vue";
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';
import { mapGetters } from 'vuex';
import StreamCardView from '@/components/ui/StreamCardView.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import AddTranscoderModal from "@/components/modals/AddTranscoderModal.vue"
import TranscoderService from '@/services/TranscoderService'

export default {
  name: "TranscoderDashboard",
  components: {
    Spinner,
    DemoVideoModal,
    AddTranscoderModal,
    StreamCardView,
    ConfirmModal
  },
  data() {
    return {
      deleteProcessing: false,
      selectedTranscoder: null
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "Ui/loadingStatus",
      hasLiveSubscription: "User/hasLiveSubscription",
      maxTranscoders: "User/maxTranscoders"
    }),
    streamFilter() {
      const tmp = _.cloneDeep(this.$store.state.Transcoders.transcoders)
      return _.map(tmp, (i) => {
        i.platforms = []
        i.sharedUsers = []
        return i
      })
    },
  },
  watch: {
  },
  async mounted() {
    this.$store.dispatch('Ui/setLoadingStatus', true);
    await this.$store.dispatch('User/loadUser');
    await this.$store.dispatch('Transcoders/getTranscoders');
    this.$store.dispatch('Ui/setLoadingStatus', false);
    this.$root.$on('togglefinished', data => {
			this.$store.dispatch('Transcoders/getTranscoders');
		});
  },
  methods: {
    onTranscoderDeleteReq(transcoder) {
      this.selectedTranscoder = transcoder
      this.$root.$emit('bv::show::modal', 'delete-transcoder')
    },
    async onTranscoderDeleteConfirm() {
      this.deleteProcessing = true
      try {
        await TranscoderService.deleteTranscoder(this.selectedTranscoder._id)
        await this.$store.dispatch('Transcoders/getTranscoders')
        this.$notify({
          group: 'success',
          text: 'Transcoder profile deleted'
        })
      } catch (err) {
        console.error(err)
        this.$notify({
          group: 'error',
          text: 'Delete transcoder profile failed'
        });
      }

      this.deleteProcessing = false
    },
    async onNewTranscoder(transcoder) {
      await this.$store.dispatch('Transcoders/getTranscoders')
      this.$router.push({ path: '/transcoder/' + transcoder._id })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.hovered:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 600px){
.feature-doc {
    position: absolute;
    top: 125px;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
}
}
@media only screen and (max-width: 767px){
.container--transcoder {
  min-height: 90vh;display: 
  flex;flex-direction: column;
  justify-content: space-between;
  }

.container--transcoder+.footer {
    display: none;
}
.transcoder-container{
  margin-top: 3.5rem;
}
}
</style>