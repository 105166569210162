<template>
	<div class="mt-2">
		<div class="feature-item">
			<b-row class="align-items-center">
				<b-col cols="9" md="8">
					<h4 class="text-s-m font-weight-normal mb-0">Autoplay Embed Player</h4>
				</b-col>
				<b-col cols="3" md="4" class="text-right">
					<b-form-checkbox
						v-if="dynamicPlayerFeatures && dynamicPlayerFeatures['player-functions']"
						@change="toggleFeature('embedAutoplay')"
						v-model="features.embedAutoplay.enabled"
						switch>
					</b-form-checkbox>
					<span id="player-functions-tooltip1" v-if="dynamicPlayerFeatures && !dynamicPlayerFeatures['player-functions']">
						<b-form-checkbox class="d-inline-block" disabled switch />
					</span>
					<b-tooltip v-if="dynamicPlayerFeatures && !dynamicPlayerFeatures['player-functions']" target="player-functions-tooltip1">
						Please <a class="text-underline text-reset" target="_blank" href="/app/subscribe">upgrade</a> subscription to use this feature.
					</b-tooltip>
				</b-col>
			</b-row>
		</div>
		<div class="feature-item">
			<b-row class="align-items-center">
				<b-col cols="9" md="8">
					<h4 class="text-s-m font-weight-normal mb-0">Enable Google Analytics</h4>
				</b-col>
				<b-col cols="3" md="4" class="text-right">
					<b-form-checkbox
					v-if="dynamicPlayerFeatures && dynamicPlayerFeatures['player-functions']"
						@change="toggleFeature('ga')"
						v-model="features.ga.enabled"
						switch>
					</b-form-checkbox>
					<span id="player-functions-tooltip2" v-if="dynamicPlayerFeatures && !dynamicPlayerFeatures['player-functions']">
						<b-form-checkbox class="d-inline-block" disabled switch />
					</span>
					<b-tooltip v-if="dynamicPlayerFeatures && !dynamicPlayerFeatures['player-functions']" target="player-functions-tooltip2">
						Please <a class="text-underline text-reset" target="_blank" href="/app/subscribe">upgrade</a> subscription to use this feature.
					</b-tooltip>
				</b-col>
			</b-row>
			<b-row class="align-items-end" v-if="features.ga.enabled">
				<b-col>
					<b-form inline>
						<label>GA ID</label>
						<b-input
							v-model="features.ga.value"
							placeholder="UA-00000 or G-xxxxx"
							size="md"
							class="mx-md-2 mb-2 mb-md-0"
						/>
						<b-button
							:disabled="!features.ga.value"
							variant="dark-5"
							size="md"

							@click="savePlayerGAId"
						>
							{{ featureProcessing.ga ? 'saving ..' : 'save' }}
						</b-button>
						</b-form>	
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import DynamicService from '@/services/DynamicService'
import { mapGetters } from 'vuex'

export default {
	name: 'DynamicPlayerFunctions',
	components: {
	
	},
	data() {
		return {
			features: {
				ga: {
					enabled: false,
					value: null,
					valueType: 'string'
				},
				embedWatermarkPosition: {
					error: false,
					enabled: true,
					value: 'tr',
					title: 'Top Right',
					valueType: 'string'
				},
				embedAutoplay: {
					error: false,
					enabled: true,
					value: null,
					valueType: 'bool'
				},
			},
			featureProcessing: {
				ga: false,
				embedAutoplay: false,
			},
		};
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			player: "DynamicPlayers/dynamicPlayer",
			dynamicPlayerFeatures: "User/dynamicPlayerFeatures",
		}),
	},
	watch: {
		player() {
			if (this.player.metadata && this.player.metadata.ga) {
				this.features.ga.enabled = true
				this.features.ga.value = this.player.metadata.ga
			}
			if (this.player.metadata && this.player.metadata.embedAutoplay != undefined) {
				this.features.embedAutoplay.enabled = this.player.metadata.embedAutoplay
			}
		}
	},
	async mounted() {
		if (this.player.metadata && this.player.metadata.ga) {
			this.features.ga.enabled = true
			this.features.ga.value = this.player.metadata.ga
		}
		if (this.player.metadata && this.player.metadata.embedAutoplay != undefined) {
			this.features.embedAutoplay.enabled = this.player.metadata.embedAutoplay
		}
	},
	destroyed() {

	},
	methods: {
		async toggleFeature(featureName, newFeature = false) {
			if (this.featureProcessing[featureName]) return;
			const feature = this.features[featureName];
			if (!feature) return;

			let nstate = feature.enabled;
			feature.enabled = nstate;
			if (feature.valueType === 'bool' || !nstate) {
				if (feature.valueType === 'array' && !nstate) {
					nstate = [];
				}
				await this.saveSetting(featureName, nstate);
			}
		},
		async savePlayerGAId() {
			const gaId = this.features.ga.value;
			if (!gaId) return;

			await this.saveSetting('ga', gaId);
		},
		async saveSetting(key, value, successToast = false) {
			this.featureProcessing[key] = true;
			try {
				await DynamicService.savePlayerMetadata(this.player._id, key, value)
				// clear stream meta cache in embed player
				DynamicService.clearPlayerMetadataCache(this.player._id)
				await this.$store.dispatch('DynamicPlayers/getPlayer', this.$route && this.$route.params.playerId);
				if (successToast) {
				    this.$notify({ group: 'success', text: 'Setting saved' })
				}
			} catch (e) {
				console.log(e);
				this.$notify({ group: 'error', text: 'could not save changes' })
			}
			if(key == 'ottEnabled'){
				this.ottProcessing = false
			}
			this.featureProcessing[key] = false;
		}
	},
}

</script>

<style scoped>
</style>