<template>
	<div class="">
		<b-modal
			:id="modalId"
			ref="modalAlert"
			modal-class="modal-platform"
			size="sm"
			centered
			hide-header
			hide-footer
		>
				<b-row class="text-dark-9 mb-2">
					<b-col>{{ message }}</b-col>
				</b-row>
				<b-row class="text-dark-8 text-s-s mb-3">
					<b-col>{{ body }}</b-col>
				</b-row>
				<b-row>
					<b-col :class="buttonAlignment">
						<b-button
							variant="outline-secondary"
							size="md"
							class="ml-auto"
							@click="onConfirm"
						>
							{{ okText }}
						</b-button>
						<b-button
							variant="primary"
							size="md"
							class="ml-2"
							target="_blank"
							href="/app/subscribe"
						>
							Upgrade
						</b-button>
					</b-col>
				</b-row>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'UploadAlertModal',
	components: {},
	props: {
		modalId: {
			type: String,
			default: 'modal-alert'
		},
		message: {
			type: String,
			required: false
		},
		body: {
			type: String,
			required: true
		},
		okText: {
			type: String,
			default: 'Yes'
		},
		buttonAlignment: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			processing: false
		};
	},
	methods: {
		onConfirm() {
			this.$emit('modal-confirm');
			this.dismiss();
		},
		dismiss() {
			this.$refs.modalAlert.hide();
			// lazy clear
			setTimeout(() => { this.processing = false; }, 1000);
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-button {
  border: none;
  padding: 7px 30px;
  font-size: 15.5px;
  color: #f7f7f7;
  background-color: transparent;
  text-transform: capitalize;
  font-size: 13.5px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 50px;
  outline: none;
}
.modal-button:hover {
  background-color: rgba(0, 98, 255, 0.425);
}
.modal-button:active {
  background-color: rgba(0, 98, 255, 0.7);
}
.modal-button.highlight {
  background-color: #0062ff;
}
.modal-button.highlight:hover {
  background-color: #001aff
}
</style>
