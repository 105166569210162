<template>
	<div>
		<ul class="list-unstyled mt-5">
			<b-row class="flex-nowrap" tag="li" v-for="item in destinations" :key="item._id">
				<b-col cols="4" lg="auto">
					<b-img v-if="platformIcon(item.template)" width="100" :src="platformIcon(item.template)" />
					<icon-base v-else width-view-box="24" height-view-box="24" iconColor="none" width="100" height="24" class="text-white mt-2">
						<icon-settings />
					</icon-base>
				</b-col>
				<b-col cols="8" lg="fill">
					<b-row no-gutters>
						<b-col>
							<Title :editable="true" titleClasses="h-8 flex items-center w-11/12 -mx-2 px-[0.5rem] rounded hover:bg-surface-3" :title="item.name" @edit="selectDestination(item)" @changed="saveNewDestinationName" />
							<p class="m-0 text-s-m text-dark-7 text-truncate pr-2">{{ item.server }}</p>
							<p class="m-0 text-s-s text-truncate pr-2 flex items-center" :class="item.linkedStreams && item.linkedStreams.length > 0 ? 'text-white' : 'text-dark-7'">
								<icon-base width-view-box="14" height-view-box="14" iconColor="none" width="14" height="14" class="mr-2">
									<icon-link />
								</icon-base>
								{{ (item.linkedStreams && item.linkedStreams.length > 0) ? showLinkedStreams(item.linkedStreams) : 'Not connected to any stream' }}
							</p>
						</b-col>
						<b-col class="pt-2" cols="auto">
							<b-button @click="editDestination(item)" size="sm" variant="outline-secondary" >
								Edit destination
							</b-button>
							<b-button @click="deleteDestination(item)" size="sm" class="p-1 ml-2" variant="outline-danger">
								<icon-base width-view-box="20" height-view-box="21" iconColor="none" width="20" height="20" classIcon="">
									<icon-bucket />
								</icon-base>
							</b-button>
						</b-col>
					</b-row>
					<hr class="my-3">
				</b-col>
			</b-row>
		</ul>
		<confirm-modal modal-id="delete-destination-modal"
			:message='`Do you realy want to delete ${selectedDestination && selectedDestination.name} destination?`' cancel-text="Cancel"
			modal-type="danger"
			@modal-hidden="clearSelected"
			ok-text="Delete" @modal-confirm="confirmDelete()" 
		/>
		<EditDestinationModal :destination="selectedDestination" />
	</div>
</template>

<script>
import DestinationService from "@/services/DestinationService"
import { mapGetters } from 'vuex'

import IconBase from '@/components/icon/IconBase.vue'
import IconBucket from '@/components/icon/IconBucket.vue'
import IconLink from '@/components/icon/IconLink.vue'
import IconSettings from '@/components/icon/IconSettings.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import EditDestinationModal from '@/components/modals/EditDestinationModal.vue'
import Title from '@/components/ui/Title.vue'

export default {
	name: 'DestinationList',
	components: {
		IconBase,
		IconBucket,
		IconLink,
		IconSettings,
		ConfirmModal,
		EditDestinationModal,
		Title,
	},
	data() {
		return {
			selectedDestination: null,
			allowEditTitle: false,
		};
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			destinations: "Destination/destinations",
			platforms: "Destination/platforms",
			allInOneStreams: "Streams/allInOneStreams"
		}),

	},
	watch: {
	},
	async mounted() {
	},
	destroyed() {

	},
	methods: {
		platformIcon(name) {
			return this.platforms.find(platform => platform?.name === name)?.icon ?? ''
		},
		deleteDestination(item) {
			this.selectDestination(item)
			this.$bvModal.show('delete-destination-modal')
		},
		editDestination(item) {
			this.selectDestination(item)
			this.$bvModal.show('edit-destination-modal')
		},
		clearSelected() {
			this.selectedDestination = null
		},
		selectDestination(item) {
			this.selectedDestination = item
		},
		async confirmDelete() {
			await DestinationService.deleteDestination(this.selectedDestination._id)
			this.$store.dispatch('Destination/getDestinations')
		},
		editDestinationName(_id) {
			
		},
		async saveNewDestinationName(newName) {
			const id = this.selectedDestination._id
			const name = newName
			await DestinationService.updateDestination(id, name)
			this.$notify({ group: 'success', text: 'The desatination was renamed successfully' })
			this.$store.dispatch('Destination/getDestinations')
		},
		showLinkedStreams(streams) {
			return streams.length === 1 
				? this.allInOneStreams.find( stream => stream._id === streams[0]._id)?.name
				: `Connected to ${streams.length} streams`
		}
	},
}

</script>

<style scoped></style>