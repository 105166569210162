import HighlightService from "@/services/HighlightService"
import StreamService from '@/services/StreamService';
const defaultState = () => ({
	highlight: null,
  rawhighlight: null,
  sortBy: 'A to Z'
});

const state = defaultState();

const mutations = {
	GET_HIGHLIGHT(state, highlight) {
    state.rawhighlight = highlight
		state.highlight = highlight
	},
  SET_SORT_OPTION(state, option) {
		state.sortBy = option;
	},
  SEARCH_HIGHLIGHT(state, searchStr) {
    console.log(searchStr);
		state.highlight = state.rawhighlight
		state.highlight = state.highlight.filter(s => {
			const search = searchStr.toLowerCase();
			let bool = s.name.toLowerCase().includes(search);
			return bool;
		});
	},
};

const actions = {
    async getHighlight({ commit }) {
        const highlight = await HighlightService.getHighlight()
        commit('GET_HIGHLIGHT', highlight);
      },
      async setSortOption({ commit }, option) {
        commit('SET_SORT_OPTION', option)
      },
      async searchHighlight({ commit }, searchStr) {
        commit('SEARCH_HIGHLIGHT', searchStr)
      }
};

const getters = {
	highlight: (state) => {
    let h = state.highlight
    if (h && state.sortBy === 'A to Z') {
			const lowerCaseStreams = h.map((item) => ({ ...item, lowerCaseName: _.lowerCase(item.name) }));
			const hSorted = _.orderBy(lowerCaseStreams, ['lowerCaseName'], ['asc'])
			return hSorted;
		} else if (h && state.sortBy === 'Z to A') {
      const lowerCaseStreams = h.map((item) => ({ ...item, lowerCaseName: _.lowerCase(item.name) }));
			const hSorted = _.orderBy(lowerCaseStreams, ['lowerCaseName'], ['desc'])
			return hSorted;
		}
  },
  
};

export default {
  namespaced: true,
	state,
	mutations,
	actions,
	getters
};
