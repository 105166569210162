<template>
  <div class="view-wrapper upgrade-page text-center w-100" :style="{ 'min-height': minWindowHeight || 'auto' }">
    <div class="text-center upgrade-page-header" v-if="!subscriptionPackage">
      <h3 class="text-500 mt-4 text-s-xxl">
        Upgrade your subscription
      </h3>
    </div>
    <div class="logo-section" v-if="subscriptionPackage">
      <b-navbar-brand class="mt-lg-n2 ml-lg-n2" :to="{ path: '/dashboard' }" tag="a">
        <!-- <b-img src="https://castr.com/wp-content/uploads/2023/12/Christmas_logo_2023.png" alt="Streaming Dashboard - Castr" height="32px" class="logo-icon" /> -->
        <img height="32px" class="logo-icon" src="@/assets/castr.svg" alt="Streaming Dashboard - Castr" />
      </b-navbar-brand>
    </div>
    <div class="text-center upgrade-page-header" v-if="subscriptionPackage">
      <p class="back_btn" @click="unselectSubscription()"><img decoding="async" src="@/assets/angle-left-grey.svg"
          alt=""> Back</p>
    </div>
    <div class="form container px-0">
      <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60"
        spinner-color="var(--c-dark-8)" spinner-size="15px" />
      <div v-else>
        <div content-class="subscription_packages" v-if="!subscriptionPackage">
          <div class="yearly-switch m-0 top-bar-control mx-auto">


            <p class="mr-5">Monthly</p>
            <b-form-checkbox v-model="monthlyComputed" name="check-button" switch>
            </b-form-checkbox>
            <p>Yearly <span class="text-normal text_yellow"> - Save upto 37% + upfront bandwidth + more streams</span>
            </p>
          </div>


          <div class="package-card-wrapper position-relative" :class="monthly ? 'monthly-plans' : 'yearly-plans'">
            <div class="package-card align-self-stretch bg-white" v-for="(pack, index) in filteredPackages"
              :key="index">
              <div class="package-heading" :class="{ selected: subscriptionPackage === pack }">
                <div class="package-info position-relative px-0">
                  <p class="popular_tag" v-if="findPackage(pack)[0].info.popular">POPULAR</p>
                  <p class="package-name text-s-m text-500" v-html="getPackageName(pack)" />
                  <div v-if="pack.baseCharge > 0">
                    <p v-if="!isAnnualPackage(pack)" class="package-price"
                      :class="!isAnnualPackage(pack) ? 'mb-3' : ''">
                      ${{ pack.baseCharge }}<span style="text-transform:none;">/month</span></p>
                    <div class="mb-1" v-else>
                      <div class="" v-for="(mp, index) in PackageCharacteristics" :key="index">
                        <span class="package-price" v-if="pack.name.includes(mp.info.name)">${{ mp.info.yearly
                          }}<span>/month</span></span>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p class="package-price">Free</p>
                  </div>
                </div>
                <p v-if="isAnnualPackage(pack)" class="text-dark-5 text-s-ss text-left mt-1 mb-0"><span>Billed as
                    ${{ pack.baseCharge }} yearly</span></p>
                <p v-if="isAnnualPackage(pack)" class="text_yellow font-normal text-s-ss text-left mt-0 mb-2"><span
                    class="text_yellow">Save ${{ getSavedamount(pack) }}</span></p>
                <b-button size="md" @click="selectSubscriptionPackage(pack)" variant="primary"
                  class="package-btn w-100 px-0"
                  :class="{ 'selected': (subscriptionPackage === pack), 'current-plan': isCurrentSubscription(pack) }"
                  :disabled="isCurrentSubscription(pack)">
                  <span v-if="isCurrentSubscription(pack)" class="act_plan">Active Plan</span>
                  <!-- isCurrentSubscription(pack) &&  -->
                  <span v-else>
                    {{ (subscriptionPackage === pack) ? 'Selected Plan' : 'Subscribe Now' }}
                  </span>
                </b-button>
                <span v-if="isCurrentSubscription(pack) && monthly" class="renew" @click="renew_yearly(index)">RENEW
                  YEARLY</span>
              </div>
              <div class="package-characteristics" :class="{ selected: subscriptionPackage === pack }">
                <div v-for="(mp, index) in PackageCharacteristics" :key="index">
                  <ul v-if="pack.name.includes(mp.info.name)">
                    <li v-for="(ch, index) in mp.charectiristics" :key="index">
                      <span v-if="ch.hasOwnProperty('monthly') && (pack.cycleAge == 30)" v-html="ch.monthly"> </span>
                      <span v-else v-html="ch.name"> </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="res_btn">
                <a class="btn package-btn border-0 py-3 px-2 text-dark-1 text-s-ss text-500 cplan"
                  href="javascript:void(0);" @click="scrollDown('mb-pricing-table-' + (index + 1))"><span
                    @click="scrollDown('mb-pricing-table-' + (index + 1))">All Features</span><img decoding="async"
                    src="https://castr.com/wp-content/uploads/2023/01/pricing_right-caret-black.svg" alt=""></a>
              </div>
            </div>

            <div class="pricing-right">
              <div class="pt-custom-plan-btn" @mouseover="hover()" :class="{ 'd-none': hoverTop }">
                <div class="tt-custom-plan-btn-area">
                  <span>Custom Plan</span>
                  <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_right-caret-black.svg"
                    alt="Icon">
                </div>
              </div>
              <div class="tt-sticky pt-custom-plan-wrapper" @mouseleave="hoverLeave()"
                :class="{ 'd-block': !hoverBottom }">
                <div class="tt-custom-plan-top">
                  <h3>Custom Plan</h3>
                  <h2>On Request</h2>
                  <div class="pt-plan-bordered-btn pt-btn-bg">
                    <a class="open_calendly" href="https://calendly.com/castr/sales?utm_source=dashboard&utm_medium=website&utm_campaign=dashboard-checkout" target="_blank">Book a
                      meeting</a>
                  </div>
                  <div class="pt-plan-bordered-btn pt-whatsapp-btn">
                    <a class="!flex items-center justify-center" href="https://wa.me/16049988035" target="_blank">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_whatsapp-1.svg"
                        alt="whatsapp">
                      <span>Chat with us</span>
                    </a>
                  </div>
                </div>
                <div class="tt-custom-plan-bottom">
                  <p class="tt-plan-support-text">Custom trial available.
                    Try our service at no risk.</p>
                  <div class="tt-plan-supports">
                    <ul>
                      <li>
                        <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                          alt="Check">
                        <span>24/7 Dedicated Tech Support</span>
                      </li>
                      <li>
                        <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                          alt="Check">
                        <span>Best pricing in the market</span>
                      </li>
                      <li>
                        <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                          alt="Check">
                        <span>No contract to get started with us</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="full-plan-comparison no-background mx-auto rounded-bottom white-bg" v-if="!subscriptionPackage">
          <a class="btn package-btn border-0 py-3 px-2 text-dark-1 text-s-ss text-500 cplan" href="javascript:void(0);"
            @click="scrollDown()"><span @click="scrollDown()">Compare plans</span><img decoding="async"
              src="https://castr.com/wp-content/uploads/2023/01/pricing_right-caret-black.svg" alt=""></a>

        </div>
        <div class="tt-pricing-container-inner" id="full_plan_castr" v-if="!subscriptionPackage">
          <div class="pricing-left">

            <!-- for large device -->
            <table class="tt-pricing-table white-bg">
              <tbody>
                <tr>
                  <th class="pt-switch-col">
                    <div>
                      <div class="yearly-switch align-items-start justify-content-start flex-column">
                        <b-form-checkbox v-model="monthlyComputed" name="check-button" class="plan-duration" switch>
                        </b-form-checkbox>
                        <p class="text-black text-500 text-s-l text-left">Yearly <br><span
                            class="text-normal text-s-s text_yellow d-block w-100"> Save 37%
                            <br>+ more concurrent streams</span></p>

                      </div>
                    </div>
                  </th>
                  <th class="pt-allinone-entry-col tt-sticky position-relative"
                    v-for="(pack, index) in filteredPackages" :key="index">
                    <p class="popular_tag" v-if="findPackage(pack)[0].info.popular">POPULAR</p>
                    <h3 class="text-center text-s-m text-500 text-dark-1 mb-2">{{ pack.name.replace(' Yearly',
                      '').replace('All in One', '').replace('-', '') }}</h3>
                    <div class="pt-price-box">

                      <div v-if="pack.baseCharge > 0">

                        <div v-if="!isAnnualPackage(pack)" class="pt-price-monthly" :class="{ 'd-none': !monthly }">
                          <p class="text-center text-s-xxll text-500 text-dark-1 mb-3">${{ pack.baseCharge }}</p>
                          <p class="text-s-ss font-normal text-dark-5">/month</p>
                        </div>
                        <div v-else>
                          <div v-for="(mp, index) in PackageCharacteristics" :key="index">
                            <div class="pt-price-yearly align-items-center justify-content-center"
                              :class="{ 'd-none': monthly }" v-if="pack.name.includes(mp.info.name)">
                              <p class="text-center text-s-xxll text-500 text-dark-1 mb-3">${{ mp.info.yearly }}</p>
                              <p class="text-s-ss font-normal text-dark-5">/month</p>
                            </div>
                          </div>
                        </div>
                        <p v-if="isAnnualPackage(pack)" class="pt-price-y text-light-3 text-s-ss font-normal"
                          :class="{ 'd-none': monthly }">Billed as ${{ pack.baseCharge }} yearly<span
                            class="text_yellow">Save ${{ getSavedamount(pack) }}</span></p>

                      </div>
                      <b-button size="md" @click="selectSubscriptionPackage(pack)" variant="primary"
                        class="package-btn w-100 px-0"
                        :class="{ 'selected': (subscriptionPackage === pack), 'current-plan': isCurrentSubscription(pack) }"
                        :disabled="isCurrentSubscription(pack)">
                        <span v-if="isCurrentSubscription(pack)" class="act_plan">Active Plan</span>
                        <span v-else>
                          {{ (subscriptionPackage === pack) ? 'Selected Plan' : 'Subscribe Now' }}
                        </span>
                      </b-button>
                      <span v-if="isCurrentSubscription(pack) && monthly" class="renew"
                        @click="renew_yearly(index)">RENEW YEARLY</span>
                    </div>
                  </th>

                </tr>
                <tr class="pt-main-defination-tr">
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Concurrent streams</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="The number of streams on Castr's live streaming dashboard, including those that are currently live and offline">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span class="yearly_data" :class="{ 'd-none': monthly }">3</span>
                      <span class="monthly_data" :class="{ 'd-none': !monthly }">2</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span class="yearly_data" :class="{ 'd-none': monthly }">3</span>
                      <span class="monthly_data" :class="{ 'd-none': !monthly }">2</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span class="yearly_data" :class="{ 'd-none': monthly }">10</span>
                      <span class="monthly_data" :class="{ 'd-none': !monthly }">3</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span class="yearly_data" :class="{ 'd-none': monthly }">10</span>
                      <span class="monthly_data" :class="{ 'd-none': !monthly }">5</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span class="yearly_data" :class="{ 'd-none': monthly }">15</span>
                      <span class="monthly_data" :class="{ 'd-none': !monthly }">10</span>
                    </div>
                  </td>
                </tr>
                <tr class="pt-main-defination-tr">
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Multistream destinations</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Stream simultaneously to 5 destinations including Facebook pages/groups, Youtube, custom RTMP, and many more">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>6</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span>10</span></div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span>15</span></div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span>20</span></div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span>30</span></div>
                  </td>
                </tr>
                <tr class="pt-main-defination-tr">
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span class="yearly_data" :class="{ 'd-none': monthly }">Yearly bandwidth</span>
                      <span class="monthly_data" :class="{ 'd-none': !monthly }">Monthly bandwidth</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Amount of bandwidth available for live and video hosting player.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell ">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span class="yearly_data"
                        :class="{ 'd-none': monthly }">2.4 TB upfront</span><span class="monthly_data"
                        :class="{ 'd-none': !monthly }">200 GB</span></div>
                  </td>
                  <td class="pt-cell ">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span class="yearly_data"
                        :class="{ 'd-none': monthly }">6 TB upfront</span><span class="monthly_data"
                        :class="{ 'd-none': !monthly }">500 GB</span></div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span class="yearly_data"
                        :class="{ 'd-none': monthly }">24 TB upfront</span><span class="monthly_data"
                        :class="{ 'd-none': !monthly }">2 TB</span></div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span class="yearly_data"
                        :class="{ 'd-none': monthly }">36 TB upfront</span><span class="monthly_data"
                        :class="{ 'd-none': !monthly }">3 TB</span></div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span class="yearly_data"
                        :class="{ 'd-none': monthly }">60 TB upfront</span><span class="monthly_data"
                        :class="{ 'd-none': !monthly }">5 TB</span></div>
                  </td>
                </tr>
                <tr class="pt-main-defination-tr">
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Video Hosting Storage</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Amount of storage available for storing video files">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell ">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span>100 GB</span></div>
                  </td>
                  <td class="pt-cell ">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span>200 GB</span></div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span>1 TB</span></div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span>2 TB</span></div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500"><span>3 TB</span></div>
                  </td>
                </tr>
                <tr class="cell-defination-tr text-s-ss text-dark-7 text-500  text-left">
                  <td class="pt-cell pt-cell-defination pt-parent-menu">
                    <div class="pt-cell-inner text-dark-7 text-small">
                      <span>CORE FEATURES</span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Mutiple Ingest (RTSP, RTMP, HLS, MPEG-TS)</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Stream with Castr in multiple ways: either push streams directly from an encoder or pull from any supported sources.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Unlimited Cloud Recording</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Record up to 72 hours and store for maximum 3 days">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Live-to-VOD</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Convert recorded streams to video-on-demand files that you can embed and share on your website">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>SRT/RTMP Pull Links</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="A playable link of your live stream, which you can set up as a live source on another broadcast. This feature allows you to play one live stream inside the other">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Sub-second Streaming (Add-on)</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Experience near-instantaneous streaming">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>HLS URL for Live</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Get HLS URLs for your livestreams to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>HLS URL for VOD</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Get HLS URLs for your uploaded videos to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Low Latency Playback</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title=" Enjoy streaming with latency as low as 15 seconds">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Dedicated Support</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Get direct support from Castr technical team through your preffered channels like Slack, Skype, or WhatsApp">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <!-- <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon"> -->
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Video Source Download</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Option to download video hosting files">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Failover Ingest</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Broadcast simultaneously to a primary and a backup stream. If your primary stream fails, your backup stream will be broadcast to avoid interruption.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Team feature</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="The additional member slots let you add your team, assign roles, customize permissions, and stream from the same dashboard.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>3 additional members</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>3 additional members</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span class="yearly_data" :class="{ 'd-none': monthly }">5 additional members</span>
                      <span class="monthly_data" :class="{ 'd-none': !monthly }">3 additional members</span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Showcase</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Create custom video galleries to showcase your work, brand, or business ">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>24/7 support</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Dedicated support team to provide live chat assistance">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <!-- <tr class="cell-defination-tr text-s-ss text-dark-7 text-500  text-left">
                                  <td class="pt-cell pt-cell-defination pt-parent-menu">
                                      <div class="pt-cell-inner text-dark-7 text-small">
                                          <span>Stream Recording - Live-to-VOD</span>
                                      </div>
                                  </td>
                                  <td class="pt-cell pt-border-right-trnasparent">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <span></span>
                                              </div>
                                  </td>
                                  <td class="pt-cell pt-border-right-trnasparent">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <span></span>
                                              </div>
                                  </td>
                                  <td class="pt-cell pt-border-right-trnasparent">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <span></span>
                                              </div>
                                  </td>
                                  <td class="pt-cell pt-border-right-trnasparent">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <span></span>
                                              </div>
                                  </td>
                                  <td class="pt-cell pt-border-right-trnasparent">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <span></span>
                                              </div>
                                  </td>
                              </tr>
                              <tr>
                                  <td class="pt-cell pt-cell-defination">
                                      <div class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                                          <span>Unlimited Cloud Recording</span>
                                           <b-button class="mr-1 white-bg border-0 shadow-none p-0" v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }" title="Record up to 72 hours and store for maximum 3 days">
                                                <img decoding="async" class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                          </b-button>
                                      </div>
                                  </td>
                                  <td class="pt-cell ">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                      </div>
                                  </td>
                                  <td class="pt-cell  ">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                      </div>
                                  </td>
                                  <td class="pt-cell ">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                      </div>
                                  </td>
                                  <td class="pt-cell ">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                      </div>
                                  </td>
                                  <td class="pt-cell ">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                      </div>
                                  </td>
                              </tr>
                              <tr>
                                  <td class="pt-cell pt-cell-defination">
                                      <div class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                                          <span>Live-to-VOD</span>
                                          <b-button class="mr-1 white-bg border-0 shadow-none p-0" v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }" title="Convert recorded streams to video-on-demand files that you can embed and share on your website">
                                                <img decoding="async" class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                          </b-button>
                                      </div>
                                  </td>
                                  <td class="pt-cell ">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                      </div>
                                  </td>
                                  <td class="pt-cell ">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                      </div>
                                  </td>
                                  <td class="pt-cell ">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                      </div>
                                  </td>
                                  <td class="pt-cell ">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                      </div>
                                  </td>
                                  <td class="pt-cell ">
                                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                      </div>
                                  </td>
                              </tr> -->
                <tr class="cell-defination-tr text-s-ss text-dark-7 text-500  text-left">
                  <td class="pt-cell pt-cell-defination pt-parent-menu">
                    <div class="pt-cell-inner text-dark-7 text-small">
                      <span>PLAYER & SECURITY</span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>HTML5 player and iframe</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Embed videos onto your website using embedded links or iframe">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell ">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Unlimited viewers</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="No restriction on the number of audience. Stream to as many viewers as you want.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Video analytics</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="View real-time data. Retention period of analytics data is 30 days.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Player Chatbox</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Send and receive messages right on your embedded player">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Custom player</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Customize theme color, autoplay embedded player, Google Analytics, poster, and watermark">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Adaptive bitrate (ABR) transcoding</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Auto-adjusts the bitrate based on the viewer's internet connection quality. Limited to 1 stream per account.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Bitrate Ladder</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Easily adjust your stream's bitrate to optimize performance for your viewers.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Player Rewind</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Rewind up to 1 hours, so users can pause and resume the stream anytime">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <!-- <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon"> -->
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Playlist</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Curate and organize your chosen content items into a sequential list.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <!-- <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon"> -->
                      <span>2</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>2</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>3</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Dynamic Player</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Seamlessly transition between content in the player without modifying the embed code">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Security – Password protection</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Set a password for your embedded player">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Security – Domain whitelisting</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Restrict the player to function exclusively on a designated website">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <!-- <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon"> -->
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Timeshifting - Delayed Streaming</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Allow you to go live based on the audience's timezone (e.g., 9 a.m. in San Francisco, Dublin and Dubai)">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Security – Geo-blocking/whitelisting</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Restrict or allow access based on regions and countries">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>




                <tr class="cell-defination-tr text-s-ss text-dark-7 text-500  text-left">
                  <td class="pt-cell pt-cell-defination pt-parent-menu">
                    <div class="pt-cell-inner text-dark-7 text-small">
                      <span>Video Monetization</span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>In-stream Advertising</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Monetize by displaying pre-roll ads on stream player, with an option to choose between skippable and non-skippable ads.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <!-- <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon"> -->
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                      <!-- <span></span> -->
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                      <!-- <span></span> -->
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Paywall</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Charge viewers with a fee for access using paywall.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Commission %</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Commission percentage Castr applies to paywalled content sales.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <!-- <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon"> -->
                      <span>18%</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <!-- <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon"> -->
                      <span>15%</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <!-- <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon"> -->
                      <span>10%</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>7%</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>5%</span>
                    </div>
                  </td>
                </tr>



                <tr class="cell-defination-tr text-s-ss text-dark-7 text-500  text-left">
                  <td class="pt-cell pt-cell-defination pt-parent-menu">
                    <div class="pt-cell-inner text-dark-7 text-small">
                      <span>TRANSCODER</span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Number of Transcoder</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title=" Transcoder allocation per subscription plan.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>0</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>0</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>0</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Convert H265 To H264</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Effortlessly convert streams from H265 to H264 codec">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Split Audio Tracks</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Isolate individual audio tracks from multi-track streams.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Deinterlacing/Interlacing</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Transform interlaced streams into progressive streams.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>

                <tr class="cell-defination-tr text-s-ss text-dark-7 text-500  text-left">
                  <td class="pt-cell pt-cell-defination pt-parent-menu">
                    <div class="pt-cell-inner text-dark-7 text-small">
                      <span>TV PLAYOUT</span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Number of Playouts</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Playout allocation per subscription plan.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Schedule Mode</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Schedule contents one after another.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Advanced Settings</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Adjust the resolution and frame rate of the outgoing stream to your preferences.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Line Up Mode Video Duration</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Aggregate duration of all content in Line Up mode.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>4 hours</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>6 hours</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>10 hours</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>15 hours</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>24 hours</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Max Loop Days</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Limit on content looping days.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>2 Days</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>4 Days</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>7 Days</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>15 Days</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>30 Days</span>
                    </div>
                  </td>
                </tr>

                <tr class="cell-defination-tr text-s-ss text-dark-7 text-500  text-left">
                  <td class="pt-cell pt-cell-defination pt-parent-menu">
                    <div class="pt-cell-inner text-dark-7 text-small">
                      <span>Live Clipping</span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Number of Live Clipping</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Highlight allocation per subscription plan.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Max Duration</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Maximum clip duration limit.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>15 minutes</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>15 minutes</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>15 minutes</span>
                    </div>
                  </td>
                </tr>

                <tr class="cell-defination-tr text-s-ss text-dark-7 text-500  text-left">
                  <td class="pt-cell pt-cell-defination pt-parent-menu">
                    <div class="pt-cell-inner text-dark-7 text-small">
                      <span>Pre-recorded Livestream (Scheduler)</span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent text-center">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent text-center">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Concurrent pre-recorded streams</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="The number of streams on Castr's pre-recorded dashboard, including those that are currently live and offline">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>1</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>2</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>2</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>3</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Max no. of files uploaded in a stream</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Maximum number of files uploaded in a stream">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>2</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>2</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>5</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>10</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>15</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Maximum Video Duration Per File</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Maximum video file length allowed.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>60 minutes</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>120 minutes</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>120 minutes</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>240 minutes</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>240 minutes</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Import files from Dropbox</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Directly transfer pre-recorded files from your Dropbox">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Maximum file size</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="The maximum size limit for each uploaded pre-recorded file">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>4 GB</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>4 GB</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>10 GB</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>10 GB</span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span>10 GB</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Infinite Loop</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Compatible with platforms that support infinite loop streaming">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>

                <tr class="cell-defination-tr text-s-ss text-dark-7 text-500  text-left">
                  <td class="pt-cell pt-cell-defination pt-parent-menu">
                    <div class="pt-cell-inner text-dark-7 text-small">
                      <span>DEVELOPERS</span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell pt-border-right-trnasparent">
                    <div class="pt-cell-inner text-dark-1 text-small text-500 text-center">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>API Access</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Developer API Access granted">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Stream Alerts</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Receive stream status notifications through SMS alerts">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Player Events API</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Receive player status notifications through API">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Webhook Notification</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Receive stream status notifications through webhooks">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pt-cell pt-cell-defination">
                    <div
                      class="pt-cell-inner text-black text-500 text-small text-left d-flex align-items-center justify-content-between">
                      <span>Origin Accelerator</span>
                      <b-button class="mr-1 white-bg border-0 shadow-none p-0"
                        v-b-tooltip.hover="{ customClass: 'ch-popover-btn white-bg border-0' }"
                        title="Reduce load on your origin and ensure low-latency global delivery.">
                        <img decoding="async" class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                      </b-button>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <span></span>
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                  <td class="pt-cell">
                    <div class="pt-cell-inner text-dark-1 text-small text-500">
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Icon">
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- for <span></span device -->
            <div class="mobile-pricing d-none pt-5">
              <!-- switch btn row -->
              <div class="mb-pt-switch">
                <toggle-button :value="monthly"
                  :color="{ checked: 'var(--c-dark-4) !important', unchecked: 'var(--c-success-2)', disabled: '#cccccc' }"
                  :width="36" :height="22" :font-size="9" :sync="true" style="" @change="toggleYearly()" />
                <p>Yearly <span class="text-normal text_yellow"> - Save upto 37% + upfront bandwidth + more
                    streams</span>
                </p>
              </div>

              <div class="mb-pt-area">
                <!-- single pricing: all in one entry -->
                <table id="mb-pricing-table-1" class="tt-pricing-table mb-pricing-table mb-pricing-table-1 expand">
                  <tr>
                    <th class="pt-allinone-entry-col tt-sticky">
                      <div>
                        <h3 class="text-center text-s-m text-500 text-dark-1 mb-2">
                          {{ filteredPackages[0].name.replace('All in One - ', '').replace('- Yearly', '') }}</h3>
                        <div class="pt-price-box">
                          <div class="pt-price-monthly align-items-center" :class="{ 'd-none': !monthly }">
                            <p class="text-center text-s-xxll text-500 text-dark-1 mb-3">
                              ${{ filteredPackages[0].baseCharge }}</p>
                            <p class="text-s-ss font-normal text-dark-5">/month</p>
                          </div>
                          <div class="" :class="{ 'd-none': monthly }">
                            <div class="pt-price-yearly" v-for="(mp, index) in PackageCharacteristics" :key="index">
                              <p class="text-center text-s-xxll text-500 text-dark-1 mb-3"
                                v-if="filteredPackages[0].name.includes(mp.info.name)">${{ mp.info.yearly }}</p>
                              <p class="text-s-ss font-normal text-dark-5"
                                v-if="filteredPackages[0].name.includes(mp.info.name)">/month</p>
                            </div>
                          </div>
                          <p class="pt-price-y text-light-3 text-small font-normal" v-if="!monthly">Billed as $149.99
                            yearly <span class="text_yellow">Save $89.9</span></p>
                        </div>
                        <div class="pt-plan-bordered-btn">
                          <b-button size="md" @click="selectSubscriptionPackage(filteredPackages[0])" variant="primary"
                            class="package-btn w-100 px-0"
                            :class="{ 'selected': (subscriptionPackage === filteredPackages[0]), 'current-plan': isCurrentSubscription(filteredPackages[0]) }"
                            :disabled="isCurrentSubscription(filteredPackages[0])">
                            <span v-if="isCurrentSubscription(filteredPackages[0])" class="act_plan">Active Plan</span>
                            <span v-else>
                              {{ (subscriptionPackage === filteredPackages[0]) ? 'Selected Plan' : 'Subscribe Now' }}
                            </span>
                          </b-button>
                          <span v-if="isCurrentSubscription(filteredPackages[0]) && monthly" class="renew"
                            @click="renew_yearly(0)">RENEW YEARLY</span>
                        </div>

                      </div>
                    </th>

                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Concurrent streams</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The number of streams on Castr's live
                          streaming dashboard, including those that are currently live and offline</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">3</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">2</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span> Multistream destinations</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Stream simultaneously to 15
                          destinations including Facebook pages/groups, Youtube, custom RTMP, and many more</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">6</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">6</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">Yearly bandwidth</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">Monthly bandwidth</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Amount of bandwidth available for
                          live and video hosting player.</div>
                      </div>
                    </td>
                    <td class="pt-cell ">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">2.4 TB upfront</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">200 GB</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">Video Hosting Storage</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">Storage</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Amount of storage available for storing video files&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Amount
                            of storage available for storing video files</span></div>
                      </div>
                    </td>
                    <td class="pt-cell ">
                      <div class="pt-cell-inner text-dark-1 text-small text-500"><span>100 GB</span></div>
                    </td>
                  </tr>
                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>CORE FEATURES</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Mutiple Ingest (RTSP, RTMP, HLS, MPEG-TS)</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Stream with Castr in multiple ways: either push streams directly from an encoder or pull from any supported sources.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Stream
                            with Castr in multiple ways: either push streams directly from an encoder or pull from any
                            supported sources.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Unlimited Cloud Recording</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Record up to 72 hours and store for maximum 3 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Record
                            up to 72 hours and store for maximum 3 days.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Live To VOD</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Stream with Castr in multiple ways: either push streams directly from an encoder or pull from any supported sources.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Stream
                            with Castr in multiple ways: either push streams directly from an encoder or pull from any
                            supported sources.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>SRT/RTMP Pull Links</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">A playable link of your live stream,
                          which you can set up as a live source on another broadcast. This feature allows you to play
                          one live stream inside the other</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Subsecond Streaming (Add-on)</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Experience near-instantaneous
                          streaming</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HLS URL For Live</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get HLS URLs for your livestreams to
                          use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HLS URL For VOD</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get HLS URLs for your uploaded videos
                          to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Low Latency Playback</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Enjoy streaming with latency as low
                          as 15 seconds</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Dedicated Support</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get direct support from Castr
                          technical team through your preffered channels like Slack, Skype, or WhatsApp</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Video Source Download</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Broadcast simultaneously to a primary
                          and a backup stream. If your primary stream fails, your backup stream will be
                          broadcast&nbsp;to avoid interruption.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Failover Ingest</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Broadcast simultaneously to a primary
                          and a backup stream. If your primary stream fails, your backup stream will be broadcast to
                          avoid interruption.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Team feature</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The additional member slots let you
                          add your team, assign roles, customize permissions, and stream from the same dashboard.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Showcase</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through webhooks&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Create
                            custom video galleries to showcase your work, brand, or business</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>24x7 support</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Dedicated support team to provide
                          live chat assistance.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>PLAYER & SECURITY</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HTML5 player and iframe</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Record up to 72 hours and store for
                          maximum 3 days</div>
                      </div>
                    </td>
                    <td class="pt-cell ">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Unlimited viewers</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span data-sheets-value="{"
                            data-sheets-userformat="{">Convert recorded streams to video-on-demand files that you can
                            embed and share on your website</span></div>
                      </div>
                    </td>
                    <td class="pt-cell ">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Video Analytics</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span data-sheets-value="{"
                            data-sheets-userformat="{">Convert recorded streams to video-on-demand files that you can
                            embed and share on your website</span></div>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Chatbox</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Embed videos onto your website using embedded links or iframe&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Embed
                            videos onto your website using embedded links or iframe</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Custom player</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;No restriction on the number of audience. Stream to as many viewers as you want.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:899,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:16777215},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">No
                            restriction on the number of audience. Stream to as many viewers as you want.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Adaptive bitrate (ABR) transcoding</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;View real-time data. Retention period of analytics data is 30 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">View
                            real-time data. Retention period of analytics data is 30 days.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Bitrate Ladder</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;View real-time data. Retention period of analytics data is 30 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Easily
                            adjust your stream's bitrate to optimize performance for your viewers.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Rewind</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;\bSend and receive messages right on your embedded player&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Send
                            and receive messages right on your embedded player</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Playlist</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Curate and organize your chosen content items into a sequential list.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;12&quot;:0}">Curate
                            and organize your chosen content items into a sequential list.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Dynamic Players</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Seamlessly transition between content in the player without modifying the embed code&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Seamlessly
                            transition between content in the player without modifying the embed code</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Password protection</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Set a password for your embedded player&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Set
                            a password for your embedded player</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Domain whitelisting</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Restrict the player to function exclusively on a designated website&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Restrict
                            the player to function exclusively on a designated website</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Timeshifting - Delayed Streaming</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Allow you to go live based on the audience's timezone (e.g., 9 a.m. in San Francisco, Dublin and Dubai)&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Allow
                            you to go live based on the audience's timezone (e.g., 9 a.m. in San Francisco, Dublin and
                            Dubai)</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Geo-blocking/whitelisting</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Restrict or allow access based on regions and countries&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Restrict
                            or allow access based on regions and countries</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>




                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>VIDEO MONETIZATION</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>In-stream Advertising</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Monetize by displaying pre-roll ads
                          on stream player, with an option to choose between skippable and non-skippable ads.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Paywall</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Charge viewers with a fee for access
                          using paywall.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Commission %</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Commission percentage Castr applies
                          to paywalled content sales.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>18%</span>
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>TRANSCODER</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Transcoder</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Transcoder allocation per
                          subscription plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>0</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Convert H265 To H264</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Effortlessly convert streams from
                          H265 to H264 codec.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Split Audio Tracks</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Isolate individual audio tracks from
                          multi-track streams.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Deinterlacing/Interlacing</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Transform interlaced streams into
                          progressive streams.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>



                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>TV PLAYOUT</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Playouts</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Playout allocation per subscription
                          plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Schedule Mode</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Schedule contents for the entire
                          week </div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Advanced Settings</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Adjust the resolution and frame rate
                          of the outgoing stream to your preferences.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Line Up Mode Video Duration</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Aggregate duration of all content in
                          Line Up mode.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>4 hours</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max Loop Days</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Limit on content looping days.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>2 Days</span>
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Live Clipping</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Live Clipping</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Highlight allocation per subscription
                          plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max Duration</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Maximum clip duration limit.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>



                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Pre-recorded Livestream (Scheduler)</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span></span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Concurrent pre-recorded streams</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The number of streams on Castr's
                          pre-recorded dashboard, including those that are currently live and offline</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max no. of files uploaded in a stream</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum number of files uploaded in a stream&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            number of files uploaded in a stream</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>2</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Maximum Video Duration Per File</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum video file length allowed.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            video file length allowed.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>60 minutes</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Import files from Dropbox</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Directly transfer pre-recorded files
                          from your Dropbox</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Maximum file size</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The maximum size limit for each
                          uploaded pre-recorded file</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>4 GB</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Infinite Loop</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Compatible with platforms that support infinite loop streaming&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Compatible
                            with platforms that support infinite loop streaming</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Developers</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>API Access</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Developer API Access granted</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Stream Alerts</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through SMS alerts&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            stream status notifications through SMS alerts</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Events API</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive player status notifications through API&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            player status notifications through API</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Webhook Notification</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through webhooks&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            stream status notifications through webhooks</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Origin Accelerator</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Reduce load on your origin and ensure
                          low-latency global delivery. </div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="pt-cell vm_btn">
                      <a class="all_feature starter_feature d-block align-center">View more <img
                          src="https://castr.com/wp-content/uploads/2023/04/down_arrow_large.svg"
                          style="transform:unset" alt=""></a>
                    </td>
                  </tr>
                </table>

                <!-- single pricing: all in one plus -->
                <table id="mb-pricing-table-2" class="tt-pricing-table mb-pricing-table mb-pricing-table-2 expand">
                  <tr>
                    <th class="pt-allinone-plus-col tt-sticky">
                      <div>
                        <h3 class="text-center text-s-m text-500 text-dark-1 mb-2">
                          {{ filteredPackages[1].name.replace('All in One - ', '').replace('- Yearly', '') }}</h3>
                        <div class="pt-price-box">
                          <div class="pt-price-monthly align-items-center" :class="{ 'd-none': !monthly }">
                            <p class="text-center text-s-xxll text-500 text-dark-1 mb-3">
                              ${{ filteredPackages[1].baseCharge }}</p>
                            <p class="text-s-ss font-normal text-dark-5">/month</p>
                          </div>
                          <div class="" :class="{ 'd-none': monthly }">
                            <div class="pt-price-yearly" v-for="(mp, index) in PackageCharacteristics" :key="index">
                              <p class="text-center text-s-xxll text-500 text-dark-1 mb-3"
                                v-if="filteredPackages[1].name.includes(mp.info.name)">${{ mp.info.yearly }}</p>
                              <p class="text-s-ss font-normal text-dark-5"
                                v-if="filteredPackages[1].name.includes(mp.info.name)">/month</p>
                            </div>
                          </div>
                          <p class="pt-price-y text-light-3 text-small font-normal" v-if="!monthly">Billed as $399.99
                            yearly<span class="text_yellow">Save $79.9</span></p>
                        </div>
                        <div class="pt-plan-bordered-btn">
                          <b-button size="md" @click="selectSubscriptionPackage(filteredPackages[1])" variant="primary"
                            class="package-btn w-100 px-0"
                            :class="{ 'selected': (subscriptionPackage === filteredPackages[1]), 'current-plan': isCurrentSubscription(filteredPackages[1]) }"
                            :disabled="isCurrentSubscription(filteredPackages[1])">
                            <span v-if="isCurrentSubscription(filteredPackages[1])" class="act_plan">Active Plan</span>
                            <span v-else>
                              {{ (subscriptionPackage === filteredPackages[1]) ? 'Selected Plan' : 'Subscribe Now' }}
                            </span>
                          </b-button>
                          <span v-if="isCurrentSubscription(filteredPackages[1]) && monthly" class="renew"
                            @click="renew_yearly(1)">RENEW YEARLY</span>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Concurrent streams</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The number of streams on Castr's live
                          streaming dashboard, including those that are currently live and offline</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">3</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">2</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span> Multistream destinations</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Stream simultaneously to 15
                          destinations including Facebook pages/groups, Youtube, custom RTMP, and many more</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">10</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">10</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">Yearly bandwidth</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">Monthly bandwidth</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Amount of bandwidth available for
                          live and video hosting player.</div>
                      </div>
                    </td>
                    <td class="pt-cell ">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">6 TB upfront</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">500 GB</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">Video Hosting Storage</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">Storage</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Amount of storage available for storing video files&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Amount
                            of storage available for storing video files</span></div>
                      </div>
                    </td>
                    <td class="pt-cell ">
                      <div class="pt-cell-inner text-dark-1 text-small text-500"><span>200 GB</span></div>
                    </td>
                  </tr>
                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>CORE FEATURES</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Mutiple Ingest (RTSP, RTMP, HLS, MPEG-TS)</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Stream with Castr in multiple ways: either push streams directly from an encoder or pull from any supported sources.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Stream
                            with Castr in multiple ways: either push streams directly from an encoder or pull from any
                            supported sources.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Unlimited Cloud Recording</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Record up to 72 hours and store for maximum 3 days&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Record
                            up to 72 hours and store for maximum 3 days</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Live To VOD</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Convert recorded streams to video-on-demand files that you can embed and share on your website.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Convert
                            recorded streams to video-on-demand files that you can embed and share on your
                            website</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>SRT/RTMP Pull Links</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">A playable link of your live stream,
                          which you can set up as a live source on another broadcast. This feature allows you to play
                          one live stream inside the other</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Subsecond Streaming (Add-on)</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Experience near-instantaneous
                          streaming</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HLS URL For Live</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get HLS URLs for your livestreams to
                          use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HLS URL For VOD</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get HLS URLs for your uploaded videos
                          to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Low Latency Playback</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Enjoy streaming with latency as low
                          as 15 seconds</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Dedicated Support</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get direct support from Castr
                          technical team through your preffered channels like Slack, Skype, or WhatsApp</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Video Source Download</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Broadcast simultaneously to a primary
                          and a backup stream. If your primary stream fails, your backup stream will be
                          broadcast&nbsp;to avoid interruption.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Failover Ingest</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Broadcast simultaneously to a primary
                          and a backup stream. If your primary stream fails, your backup stream will be broadcast to
                          avoid interruption.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Team feature</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The additional member slots let you
                          add your team, assign roles, customize permissions, and stream from the same dashboard.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Showcase</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through webhooks&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Create
                            custom video galleries to showcase your work, brand, or business</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>24x7 support</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Dedicated support team to provide
                          live chat assistance.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>PLAYER & SECURITY</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HTML5 player and iframe</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Embed videos onto your website using embedded links or iframe&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Embed
                            videos onto your website using embedded links or iframe</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Unlimited viewers</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;No restriction on the number of audience. Stream to as many viewers as you want.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:899,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:16777215},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">No
                            restriction on the number of audience. Stream to as many viewers as you want.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Video Analytics</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;View real-time data. Retention period of analytics data is 30 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">View
                            real-time data. Retention period of analytics data is 30 days.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Chatbox</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;\bSend and receive messages right on your embedded player&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Send
                            and receive messages right on your embedded player</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Custom player</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Customize theme color, autoplay embedded player, Google Analytics, poster, and watermark&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;12&quot;:0}">Customize
                            theme color, autoplay embedded player, Google Analytics, poster, and watermark</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Adaptive bitrate (ABR) transcoding</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Auto-adjusts the bitrate based on the viewer's internet connection quality. Limited to 1 stream per account.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Auto-adjusts
                            the bitrate based on the viewer's internet connection quality. Limited to 1 stream per
                            account.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Bitrate Ladder</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;View real-time data. Retention period of analytics data is 30 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Easily
                            adjust your stream's bitrate to optimize performance for your viewers.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Rewind</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Get HLS URL for your streams to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Get
                            HLS URL for your streams to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and
                            more.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="close">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Playlist</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Curate and organize your chosen content items into a sequential list.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Curate
                            and organize your chosen content items into a sequential list.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span> 1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Dynamic Players</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Seamlessly transition between content in the player without modifying the embed code.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Seamlessly
                            transition between content in the player without modifying the embed code</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Password protection</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Set a password for your embedded player&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Set
                            a password for your embedded player</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Domain whitelisting</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Restrict the player to function exclusively on a designated website&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Restrict
                            the player to function exclusively on a designated website</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Timeshifting - Delayed Streaming</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Allow you to go live based on the audience's timezone (e.g., 9 a.m. in San Francisco, Dublin and Dubai)&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Allow
                            you to go live based on the audience's timezone (e.g., 9 a.m. in San Francisco, Dublin and
                            Dubai)</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Geo-blocking/whitelisting</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Restrict or allow access based on regions and countries&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Restrict
                            or allow access based on regions and countries</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Video Monetization</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>In-stream Advertising</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Monetize by displaying pre-roll ads
                          on stream player, with an option to choose between skippable and non-skippable ads.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Paywall</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Charge viewers with a fee for access
                          using paywall.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Commission %</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Commission percentage Castr applies
                          to paywalled content sales.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>15%</span>
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Transcoder</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Transcoder</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Transcoder allocation per
                          subscription plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>0</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Convert H265 To H264</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Effortlessly convert streams from
                          H265 to H264 codec</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Split Audio Tracks</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Isolate individual audio tracks from
                          multi-track streams.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Deinterlacing/Interlacing</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Transform interlaced streams into
                          progressive streams.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>TV Playout</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Playouts</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Playout allocation per subscription
                          plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Schedule Mode</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Schedule contents one after another.
                        </div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Advanced Settings</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Adjust the resolution and frame rate
                          of the outgoing stream to your preferences.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Line Up Mode Video Duration</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Aggregate duration of all content in
                          Line Up mode.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>6 hours</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max Loop Days</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Limit on content looping days.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>4 Days</span>
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Live Clipping</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Live Clipping</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Highlight allocation per subscription
                          plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max Duration</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Maximum clip duration limit.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>



                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Pre-recorded Livestream (Scheduler)</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Concurrent pre-recorded streams</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The number of streams on Castr's
                          pre-recorded dashboard, including those that are currently live and offline</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max no. of files uploaded in a stream</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum number of files uploaded in a stream&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            number of files uploaded in a stream</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>2</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Maximum Video Duration Per File</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum video file length allowed.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            video file length allowed.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>120 minutes</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Import files from Dropbox</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum number of files uploaded in a stream&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            number of files uploaded in a stream</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Maximum file size</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The maximum size limit for each
                          uploaded pre-recorded file</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>4 GB</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Infinite Loop</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Compatible with platforms that support infinite loop streaming&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Compatible
                            with platforms that support infinite loop streaming</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Developers</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>API Access</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Developer API Access granted</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Stream Alerts</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through SMS alerts&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            stream status notifications through SMS alerts</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Events API</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive player status notifications through API&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            player status notifications through API</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Webhook Notification</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through webhooks&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            stream status notifications through webhooks</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Origin Accelerator</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Reduce load on your origin and ensure
                          low-latency global delivery. </div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="pt-cell vm_btn">
                      <a class="all_feature starter_feature d-block align-center">View more <img
                          src="https://castr.com/wp-content/uploads/2023/04/down_arrow_large.svg"
                          style="transform:unset" alt=""></a>
                    </td>
                  </tr>
                </table>

                <!-- single pricing: all in one premium -->
                <table id="mb-pricing-table-3"
                  class="tt-pricing-table mb-pricing-table popular mb-pricing-table-3 expand">
                  <tr>
                    <th class="pt-allinone-premium-col tt-sticky">
                      <div>
                        <h3 class="text-center text-s-m text-500 text-dark-1 mb-2">
                          {{ filteredPackages[2].name.replace('All in One - ', '').replace('- Yearly', '') }}</h3>
                        <div class="pt-price-box">
                          <div class="pt-price-monthly align-items-center" :class="{ 'd-none': !monthly }">
                            <p class="text-center text-s-xxll text-500 text-dark-1 mb-3">
                              ${{ filteredPackages[2].baseCharge }}</p>
                            <p class="text-s-ss font-normal text-dark-5">/month</p>
                          </div>
                          <div class="" :class="{ 'd-none': monthly }">
                            <div class="pt-price-yearly" v-for="(mp, index) in PackageCharacteristics" :key="index">
                              <p class="text-center text-s-xxll text-500 text-dark-1 mb-3"
                                v-if="filteredPackages[2].name.includes(mp.info.name)">${{ mp.info.yearly }}</p>
                              <p class="text-s-ss font-normal text-dark-5"
                                v-if="filteredPackages[2].name.includes(mp.info.name)">/month</p>
                            </div>
                          </div>
                          <p class="pt-price-y text-light-3 text-small font-normal" v-if="!monthly">Billed as $750
                            yearly<span class="text_yellow"> Save $450</span></p>
                        </div>
                        <div class="pt-plan-bordered-btn">
                          <b-button size="md" @click="selectSubscriptionPackage(filteredPackages[2])" variant="primary"
                            class="package-btn w-100 px-0"
                            :class="{ 'selected': (subscriptionPackage === filteredPackages[2]), 'current-plan': isCurrentSubscription(filteredPackages[2]) }"
                            :disabled="isCurrentSubscription(filteredPackages[2])">
                            <span v-if="isCurrentSubscription(filteredPackages[2])" class="act_plan">Active Plan</span>
                            <span v-else>
                              {{ (subscriptionPackage === filteredPackages[2]) ? 'Selected Plan' : 'Subscribe Now' }}
                            </span>
                          </b-button>
                          <span v-if="isCurrentSubscription(filteredPackages[2]) && monthly" class="renew"
                            @click="renew_yearly(2)">RENEW YEARLY</span>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Concurrent streams</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The number of streams on Castr's live
                          streaming dashboard, including those that are currently live and offline</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">10</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">3</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span> Multistream destinations</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Stream simultaneously to 15
                          destinations including Facebook pages/groups, Youtube, custom RTMP, and many more</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">15</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">15</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">Monthly bandwidth</span>
                        <span class="yearly_data" :class="{ 'd-none': monthly }">Yearly bandwidth</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Amount of bandwidth available for
                          live and video hosting player.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">24 TB upfront</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">2 TB</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">Video Hosting Storage</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">Storage</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Amount of storage available for storing video files&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Amount
                            of storage available for storing video files</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500"><span>1 TB</span></div>
                    </td>
                  </tr>
                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>CORE FEATURES</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Mutiple Ingest (RTSP, RTMP, HLS, MPEG-TS)</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Stream with Castr in multiple ways: either push streams directly from an encoder or pull from any supported sources.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Stream
                            with Castr in multiple ways: either push streams directly from an encoder or pull from any
                            supported sources.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Unlimited Cloud Recording</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Record up to 72 hours and store for maximum 3 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Record
                            up to 72 hours and store for maximum 3 days.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Live To VOD</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Stream with Castr in multiple ways: either push streams directly from an encoder or pull from any supported sources.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Stream
                            with Castr in multiple ways: either push streams directly from an encoder or pull from any
                            supported sources.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>SRT/RTMP Pull Links</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">A playable link of your live stream,
                          which you can set up as a live source on another broadcast. This feature allows you to play
                          one live stream inside the other</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Subsecond Streaming (Add-on)</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Experience near-instantaneous
                          streaming</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HLS URL For Live</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get HLS URLs for your livestreams to
                          use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HLS URL For VOD</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get HLS URLs for your uploaded videos
                          to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Low Latency Playback</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Enjoy streaming with latency as low
                          as 15 seconds</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Dedicated Support</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get direct support from Castr
                          technical team through your preffered channels like Slack, Skype, or WhatsApp</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Video Source Download</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Broadcast simultaneously to a primary
                          and a backup stream. If your primary stream fails, your backup stream will be
                          broadcast&nbsp;to avoid interruption.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Team feature</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The additional member slots let you
                          add your team, assign roles, customize permissions, and stream from the same dashboard.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>3 additional members</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Showcase</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through webhooks&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Create
                            custom video galleries to showcase your work, brand, or business</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>24x7 support</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Dedicated support team to provide
                          live chat assistance.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>



                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>PLAYER & SECURITY</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HTML5 player and iframe</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Embed videos onto your website using embedded links or iframe&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Embed
                            videos onto your website using embedded links or iframe</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Unlimited viewers</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;No restriction on the number of audience. Stream to as many viewers as you want.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:899,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:16777215},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">No
                            restriction on the number of audience. Stream to as many viewers as you want.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Video Analytics</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;View real-time data. Retention period of analytics data is 30 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">View
                            real-time data. Retention period of analytics data is 30 days.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Chatbox</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;\bSend and receive messages right on your embedded player&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Send
                            and receive messages right on your embedded player</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Custom player</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Customize theme color, autoplay embedded player, Google Analytics, poster, and watermark&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;12&quot;:0}">Customize
                            theme color, autoplay embedded player, Google Analytics, poster, and watermark</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Adaptive bitrate (ABR) transcoding</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Auto-adjusts the bitrate based on the viewer's internet connection quality. Limited to 1 stream per account.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Auto-adjusts
                            the bitrate based on the viewer's internet connection quality. Limited to 1 stream per
                            account.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Bitrate Ladder</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;View real-time data. Retention period of analytics data is 30 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Easily
                            adjust your stream's bitrate to optimize performance for your viewers.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Rewind</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Get HLS URL for your streams to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Get
                            HLS URL for your streams to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and
                            more.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Playlist</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Curate and organize your chosen content items into a sequential list.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Curate
                            and organize your chosen content items into a sequential list.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>2</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500 text-dark-1 text-small text-500">
                        <span>Dynamic Players</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Seamlessly transition between content in the player without modifying the embed code&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Seamlessly
                            transition between content in the player without modifying the embed code.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500 text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Password protection</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Set a password for your embedded player&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Set
                            a password for your embedded player</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Domain whitelisting</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Restrict the player to function exclusively on a designated website&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Restrict
                            the player to function exclusively on a designated website</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Timeshifting - Delayed Streaming</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Allow you to go live based on the audience's timezone (e.g., 9 a.m. in San Francisco, Dublin and Dubai)&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Allow
                            you to go live based on the audience's timezone (e.g., 9 a.m. in San Francisco, Dublin and
                            Dubai)</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Geo-blocking/whitelisting</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Restrict or allow access based on regions and countries&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Restrict
                            or allow access based on regions and countries</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>VIDEO MONETIZATION</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Paywall</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Charge viewers with a fee for access
                          using paywall.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>In-stream Advertising</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Monetize by displaying pre-roll ads
                          on stream player, with an option to choose between skippable and non-skippable ads.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Commission %</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Commission percentage Castr applies
                          to paywalled content sales.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>10%</span>
                      </div>
                    </td>
                  </tr>

                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>TRANSCODER</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Transcoder</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Transcoder allocation per
                          subscription plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>0</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Convert H265 To H264</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Effortlessly convert streams from
                          H265 to H264 codec</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Split Audio Tracks</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Isolate individual audio tracks from
                          multi-track streams.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Deinterlacing/Interlacing</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Transform interlaced streams into
                          progressive streams.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>TV PLAYOUT</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Playouts</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Playout allocation per subscription
                          plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Schedule Mode</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Schedule contents one after another.
                        </div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Convert H265 To H264</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Effortlessly convert streams from
                          H265 to H264 codec</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Advanced Settings</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Adjust the resolution and frame rate
                          of the outgoing stream to your preferences.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Line Up Mode Video Duration</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Aggregate duration of all content in
                          Line Up mode.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>10 hours</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max Loop Days</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Limit on content looping days.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>7 Days</span>
                      </div>
                    </td>
                  </tr>



                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Live Clipping</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Live Clipping</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Highlight allocation per subscription
                          plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max Duration</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Maximum clip duration limit.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>15 minutes</span>
                      </div>
                    </td>
                  </tr>



                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Pre-recorded Livestream (Scheduler)</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Concurrent pre-recorded streams</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The number of streams on Castr's
                          pre-recorded dashboard, including those that are currently live and offline</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>2</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max no. of files uploaded in a stream</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The number of streams on Castr's
                          pre-recorded dashboard, including those that are currently live and offline</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>5</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Maximum Video Duration Per File</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum video file length allowed.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            video file length allowed.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>120 minutes</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Import files from Dropbox</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The maximum size limit for each
                          uploaded pre-recorded file</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Maximum file size</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The maximum size limit for each
                          uploaded pre-recorded file</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>10 GB</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Infinite Loop</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Compatible with platforms that support infinite loop streaming&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Compatible
                            with platforms that support infinite loop streaming</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>DEVELOPERS</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>API Access</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Developer API Access granted.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Stream Alerts</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Receive stream status notifications
                          through SMS alerts</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Events API</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Receive player status notifications
                          through API.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Webhook Notification</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Receive stream status notifications
                          through webhooks.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Origin Accelerator</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Reduce load on your origin and ensure
                          low-latency global delivery. </div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="pt-cell vm_btn">
                      <a class="all_feature starter_feature d-block align-center">View more <img
                          src="https://castr.com/wp-content/uploads/2023/04/down_arrow_large.svg"
                          style="transform:unset" alt=""></a>
                    </td>
                  </tr>
                </table>

                <!-- single pricing: all in one business -->
                <table id="mb-pricing-table-4" class="tt-pricing-table mb-pricing-table mb-pricing-table-4 expand">
                  <tr>
                    <th class="pt-allinone-business-col tt-sticky">
                      <div>
                        <h3 class="text-center text-s-m text-500 text-dark-1 mb-2">
                          Premium</h3>
                        <div class="pt-price-box">
                          <div class="pt-price-monthly align-items-center" :class="{ 'd-none': !monthly }">
                            <p class="text-center text-s-xxll text-500 text-dark-1 mb-3">
                              ${{ filteredPackages[3].baseCharge }}</p>
                            <p class="text-s-ss font-normal text-dark-5">/month</p>
                          </div>
                          <div class="" :class="{ 'd-none': monthly }">
                            <div class="pt-price-yearly" v-for="(mp, index) in PackageCharacteristics" :key="index">
                              <p class="text-center text-s-xxll text-500 text-dark-1 mb-3"
                                v-if="filteredPackages[3].name.includes(mp.info.name)">${{ mp.info.yearly }}</p>
                              <p class="text-s-ss font-normal text-dark-5"
                                v-if="filteredPackages[3].name.includes(mp.info.name)">/month</p>
                            </div>
                          </div>
                          <p class="pt-price-y text-light-3 text-small font-normal" v-if="!monthly">Billed as $1,250
                            yearly<span class="text_yellow"> Save $550</span></p>
                        </div>
                        <div class="pt-plan-bordered-btn">
                          <b-button size="md" @click="selectSubscriptionPackage(filteredPackages[3])" variant="primary"
                            class="package-btn w-100 px-0"
                            :class="{ 'selected': (subscriptionPackage === filteredPackages[3]), 'current-plan': isCurrentSubscription(filteredPackages[3]) }"
                            :disabled="isCurrentSubscription(filteredPackages[3])">
                            <span v-if="isCurrentSubscription(filteredPackages[3])" class="act_plan">Active Plan</span>
                            <span v-else>
                              {{ (subscriptionPackage === filteredPackages[3]) ? 'Selected Plan' : 'Subscribe Now' }}
                            </span>
                          </b-button>
                          <span v-if="isCurrentSubscription(filteredPackages[3]) && monthly" class="renew"
                            @click="renew_yearly(3)">RENEW YEARLY</span>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Concurrent streams</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The number of streams on Castr's live
                          streaming dashboard, including those that are currently live and offline</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">10</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">5</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span> Multistream destinations</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Stream simultaneously to 15
                          destinations including Facebook pages/groups, Youtube, custom RTMP, and many more</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">20</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">20</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">Montly bandwidth</span>
                        <span class="yearly_data" :class="{ 'd-none': monthly }">Yearly bandwidth</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Amount of bandwidth available for
                          live and video hosting player.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">36 TB upfront</span>
                        <span class="yearly_data" :class="{ 'd-none': monthly }">3 TB</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">Video Hosting Storage</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">Storage</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Amount of storage available for storing video files&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Amount
                            of storage available for storing video files</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500"><span>2 TB</span></div>
                    </td>
                  </tr>
                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>CORE FEATURES</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Mutiple Ingest (RTSP, RTMP, HLS, MPEG-TS)</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Stream with Castr in multiple ways: either push streams directly from an encoder or pull from any supported sources.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Stream
                            with Castr in multiple ways: either push streams directly from an encoder or pull from any
                            supported sources.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Unlimited Cloud Recording</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Record up to 72 hours and store for maximum 3 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Record
                            up to 72 hours and store for maximum 3 days.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Live To VOD</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Stream with Castr in multiple ways: either push streams directly from an encoder or pull from any supported sources.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Stream
                            with Castr in multiple ways: either push streams directly from an encoder or pull from any
                            supported sources.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>SRT/RTMP Pull Links</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">A playable link of your live stream,
                          which you can set up as a live source on another broadcast. This feature allows you to play
                          one live stream inside the other</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Subsecond Streaming (Add-on)</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Experience near-instantaneous
                          streaming</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HLS URL For Live</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get HLS URLs for your livestreams to
                          use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HLS URL For VOD</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Get HLS URLs for your uploaded videos to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:4993,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial&quot;}">Get
                            HLS URLs for your uploaded videos to use with OTT platforms like Roku, Apple TV, Amazon Fire
                            TV and more.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Low Latency Playback</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot; Enjoy streaming with latency as low as 15 seconds&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:4993,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial&quot;}">
                            Enjoy streaming with latency as low as 15 seconds</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Dedicated Support</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get direct support from Castr
                          technical team through your preffered channels like Slack, Skype, or WhatsApp</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Video Source Download</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get direct support from Castr
                          technical team through your preffered channels like Slack, Skype, or WhatsApp</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Failover Ingest</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Broadcast simultaneously to a primary
                          and a backup stream. If your primary stream fails, your backup stream will be broadcast to
                          avoid interruption.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Team feature</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The additional member slots let you
                          add your team, assign roles, customize permissions, and stream from the same dashboard.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>3 additional members</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Showcase</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through webhooks&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Create
                            custom video galleries to showcase your work, brand, or business</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>24x7 support</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Dedicated support team to provide
                          live chat assistance.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <!-- <tr>
                                          <td class="pt-cell pt-cell-defination">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>No Castr Branding</span>
                                                  <img class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                                  <div class="mbt-tooltip" style="width:200px; height:auto;"><span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Castr does not show our watermark or branding on your streams.&quot;}" data-sheets-userformat="{&quot;2&quot;:4993,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial&quot;}">Castr does not show our watermark or branding on your streams.</span></div>
                                              </div>
                                          </td>
                                          <td class="pt-cell">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="pt-cell pt-cell-defination">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>Ad-free streaming</span>
                                                  <img class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                                  <div class="mbt-tooltip" style="width:200px; height:auto;">No ads or Castr branding on your stream</div>
                                              </div>
                                          </td>
                                          <td class="pt-cell">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                              </div>
                                          </td>
                                      </tr>
                                  
                                      <tr class="cell-defination-tr">
                                          <td class="pt-cell pt-cell-defination pt-parent-menu">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>MULTISTREAM</span>
                                              </div>
                                          </td>
                                          <td class="pt-cell pt-border-right-trnasparent">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                              <span></span>
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="pt-cell pt-cell-defination">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>Facebook pages, groups, and custom RTMP streaming</span>
                                                  <img class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                                  <div class="mbt-tooltip" style="width:200px; height:auto;"> Allow to add Facebook pages, groups, and any custom platform or media server which supports RTMP</div>
                                              </div>
                                          </td>
                                          <td class="pt-cell">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                              </div>
                                          </td>
                                      </tr> -->
                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>PLAYER & SECURITY</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Unlimited Cloud Recording</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Record up to 72 hours and store for
                          maximum 3 days</div>
                      </div>
                    </td>
                    <td class="pt-cell ">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HTML5 player and iframe</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Embed videos onto your website using embedded links or iframe&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Embed
                            videos onto your website using embedded links or iframe</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Unlimited viewers</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;No restriction on the number of audience. Stream to as many viewers as you want.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:899,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:16777215},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">No
                            restriction on the number of audience. Stream to as many viewers as you want.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Video Analytics</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;View real-time data. Retention period of analytics data is 30 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">View
                            real-time data. Retention period of analytics data is 30 days.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Chatbox</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;\bSend and receive messages right on your embedded player&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Send
                            and receive messages right on your embedded player</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Custom player</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Customize theme color, autoplay embedded player, Google Analytics, poster, and watermark&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;12&quot;:0}">Customize
                            theme color, autoplay embedded player, Google Analytics, poster, and watermark</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Adaptive bitrate (ABR) transcoding</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Auto-adjusts the bitrate based on the viewer's internet connection quality. Limited to 1 stream per account.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Auto-adjusts
                            the bitrate based on the viewer's internet connection quality. Limited to 1 stream per
                            account.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Bitrate Ladder</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;View real-time data. Retention period of analytics data is 30 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Easily
                            adjust your stream's bitrate to optimize performance for your viewers.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner">
                        <img src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Rewind</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Get HLS URL for your streams to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Get
                            HLS URL for your streams to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and
                            more.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Playlist</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Curate and organize your chosen content items into a sequential list.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Curate
                            and organize your chosen content items into a sequential list.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>2</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Dynamic Players</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Seamlessly transition between content in the player without modifying the embed code.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Seamlessly
                            transition between content in the player without modifying the embed code.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Password protection</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Set a password for your embedded player&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Set
                            a password for your embedded player</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Domain whitelisting</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Restrict the player to function exclusively on a designated website&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Restrict
                            the player to function exclusively on a designated website</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Geo-blocking/whitelisting</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Restrict or allow access based on regions and countries&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Restrict
                            or allow access based on regions and countries</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Timeshifting - Delayed Streaming</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Allow you to go live based on the audience's timezone (e.g., 9 a.m. in San Francisco, Dublin and Dubai)&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Allow
                            you to go live based on the audience's timezone (e.g., 9 a.m. in San Francisco, Dublin and
                            Dubai)</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>VIDEO MONETIZATION</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>In-stream Advertising</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Monetize by displaying pre-roll ads
                          on stream player, with an option to choose between skippable and non-skippable ads.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Paywall</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Charge viewers with a fee for access
                          using paywall.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Commission %</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Commission percentage Castr applies
                          to paywalled content sales.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>7%</span>
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>TRANSCODER</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Transcoder</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Transcoder allocation per
                          subscription plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Convert H265 To H264</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Effortlessly convert streams from
                          H265 to H264 codec.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Split Audio Tracks</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Isolate individual audio tracks from
                          multi-track streams</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img decoding="async" src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg"
                          alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Deinterlacing/Interlacing</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Transform interlaced streams into
                          progressive streams.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img decoding="async" src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg"
                          alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>TV PLAYOUT</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Playouts</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Playout allocation per subscription
                          plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Schedule Mode</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Schedule contents one after another.
                        </div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Advanced Settings</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Adjust the resolution and frame rate
                          of the outgoing stream to your preferences.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img decoding="async" src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg"
                          alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Line Up Mode Video Duration</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Aggregate duration of all content in
                          Line Up mode.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>15 hours</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max Loop Days</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Limit on content looping days.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>15 Days</span>
                      </div>
                    </td>
                  </tr>

                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Live Clipping</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Live Clipping</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Highlight allocation per subscription
                          plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max Duration</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Maximum clip duration limit.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>15 minutes</span>
                      </div>
                    </td>
                  </tr>



                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Pre-recorded Livestream (Scheduler)</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Concurrent pre-recorded streams</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The number of streams on Castr's
                          pre-recorded dashboard, including those that are currently live and offline</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>2</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max no. of files uploaded in a stream</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum number of files uploaded in a stream&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            number of files uploaded in a stream</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>5</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Maximum Video Duration Per File</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            video file length allowed.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>240 minutes</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Import files from Dropbox</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum number of files uploaded in a stream&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            number of files uploaded in a stream</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Maximum file size</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The maximum size limit for each
                          uploaded pre-recorded file</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>10 GB</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Infinite Loop</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Compatible with platforms that support infinite loop streaming&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Compatible
                            with platforms that support infinite loop streaming</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <!-- <tr class="cell-defination-tr">
                                          <td class="pt-cell pt-cell-defination pt-parent-menu">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>Video Hosting</span>
                                              </div>
                                          </td>
                                          <td class="pt-cell pt-border-right-trnasparent">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                              <span></span>
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="pt-cell pt-cell-defination">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>Maximum folders</span>
                                                  <img class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                                  <div class="mbt-tooltip" style="width:200px; height:auto;"><span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Group your video files in folders&quot;}" data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Group your video files in folders</span></div>
                                              </div>
                                          </td>
                                          <td class="pt-cell">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                              <span>10</span>
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="pt-cell pt-cell-defination">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>Embeddable VOD player</span>
                                                  <img class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                                  <div class="mbt-tooltip" style="width:200px; height:auto;"><span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Shared bandwidth with the Livestream HTML5 Player&quot;}" data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Shared bandwidth with the Livestream HTML5 Player</span></div>
                                              </div>
                                          </td>
                                          <td class="pt-cell">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                              <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="pt-cell pt-cell-defination">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>Custom player</span>
                                                  <img class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                                  <div class="mbt-tooltip" style="width:200px; height:auto;"><span data-sheets-value="{" data-sheets-userformat="{">Customize theme color, autoplay embedded player, Google Analytics integration, poster, and watermark</span></div>
                                              </div>
                                          </td>
                                          <td class="pt-cell">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                              <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="pt-cell pt-cell-defination">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>Auto-encoding to different formats</span>
                                                  <img class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                                  <div class="mbt-tooltip" style="width:200px; height:auto;"><span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Auto-encode videos to different formats: 360p, 480p, 720p, 1080p, so your viewers can choose the appropriate format based on the internet connection quality&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Auto-encode videos to different formats: 360p, 480p, 720p, 1080p, so your viewers can choose the appropriate format based on the internet connection quality</span></div>
                                              </div>
                                          </td>
                                          <td class="pt-cell">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                              <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="pt-cell pt-cell-defination">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>Security – Password protection</span>
                                                  <img class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                                  <div class="mbt-tooltip" style="width:200px; height:auto;"><span data-sheets-value="{" data-sheets-userformat="{">Set a password for your embedded player</span></div>
                                              </div>
                                          </td>
                                          <td class="pt-cell">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                              <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="pt-cell pt-cell-defination">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>Security – Domain whitelisting</span>
                                                  <img class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                                  <div class="mbt-tooltip" style="width:200px; height:auto;"><span data-sheets-value="{" data-sheets-userformat="{">Restrict the player to function exclusively on a designated website</span></div>
                                              </div>
                                          </td>
                                          <td class="pt-cell">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                              <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="pt-cell pt-cell-defination">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                                  <span>Security – Geo-blocking/whitelisting</span>
                                                  <img class="tt-question-tooltip" src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                                                  <div class="mbt-tooltip" style="width:200px; height:auto;"><span data-sheets-value="{" data-sheets-userformat="{">Restrict or allow access based on regions and countries</span></div>
                                              </div>
                                          </td>
                                          <td class="pt-cell">
                                              <div class="pt-cell-inner text-dark-1 text-small text-500">
                                              <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                                              </div>
                                          </td>
                                      </tr> -->


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>DEVELOPERS</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>API Access</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Developer API Access granted</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Stream Alerts</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through SMS alerts&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            stream status notifications through SMS alerts</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Events API</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum number of files uploaded in a stream&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            player status notifications through API</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img decoding="async" src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg"
                          alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Webhook Notification</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through webhooks&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            stream status notifications through webhooks</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img decoding="async" src="https://castr.com/wp-content/uploads/2022/12/close_castr.svg"
                          alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Origin Accelerator</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Reduce load on your origin and ensure
                          low-latency global delivery. </div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr>
                    <td class="pt-cell vm_btn">
                      <a class="all_feature starter_feature d-block align-center">View more <img
                          src="https://castr.com/wp-content/uploads/2023/04/down_arrow_large.svg"
                          style="transform:unset" alt=""></a>
                    </td>
                  </tr>
                </table>

                <!-- single pricing: all in one enterprise -->
                <table id="mb-pricing-table-5" class="tt-pricing-table mb-pricing-table mb-pricing-table-5 expand">
                  <tr>
                    <th class="pt-allinone-enterprise-col tt-sticky">
                      <div>
                        <h3 class="text-center text-s-m text-500 text-dark-1 mb-2">
                          {{ filteredPackages[4].name.replace('All in One - ', '').replace('- Yearly', '') }}</h3>
                        <div class="pt-price-box">
                          <div class="pt-price-monthly align-items-center" :class="{ 'd-none': !monthly }">
                            <p class="text-center text-s-xxll text-500 text-dark-1 mb-3">
                              ${{ filteredPackages[4].baseCharge }}</p>
                            <p class="text-s-ss font-normal text-dark-5">/month</p>
                          </div>
                          <div class="" :class="{ 'd-none': monthly }">
                            <div class="pt-price-yearly" v-for="(mp, index) in PackageCharacteristics" :key="index">
                              <p class="text-center text-s-xxll text-500 text-dark-1 mb-3"
                                v-if="filteredPackages[4].name.includes(mp.info.name)">${{ mp.info.yearly }}</p>
                              <p class="text-s-ss font-normal text-dark-5"
                                v-if="filteredPackages[4].name.includes(mp.info.name)">/month</p>
                            </div>
                          </div>
                          <p class="pt-price-y text-light-3 text-small font-normal" v-if="!monthly">Billed as $3,000
                            yearly<span class="text_yellow">Save $599</span></p>
                        </div>
                        <div class="pt-plan-bordered-btn">
                          <b-button size="md" @click="selectSubscriptionPackage(filteredPackages[4])" variant="primary"
                            class="package-btn w-100 px-0"
                            :class="{ 'selected': (subscriptionPackage === filteredPackages[4]), 'current-plan': isCurrentSubscription(filteredPackages[4]) }"
                            :disabled="isCurrentSubscription(filteredPackages[4])">
                            <span v-if="isCurrentSubscription(filteredPackages[4])" class="act_plan">Active Plan</span>
                            <span v-else>
                              {{ (subscriptionPackage === filteredPackages[4]) ? 'Selected Plan' : 'Subscribe Now' }}
                            </span>
                          </b-button>
                          <span v-if="isCurrentSubscription(filteredPackages[4]) && monthly" class="renew"
                            @click="renew_yearly(4)">RENEW YEARLY</span>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Concurrent streams</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The number of streams on Castr's live
                          streaming dashboard, including those that are currently live and offline</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">15</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">10</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span> Multistream destinations</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Stream simultaneously to 15
                          destinations including Facebook pages/groups, Youtube, custom RTMP, and many more</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">30</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">30</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">Yearly bandwidth</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">Monthly bandwidth</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Amount of bandwidth available for
                          live and video hosting player.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">60 TB upfront</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">5 TB</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="pt-main-defination-tr">
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">Video Hosting Storage</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">Storage</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Amount of storage available for storing video files&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Amount
                            of storage available for storing video files</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500"><span>3 TB</span></div>
                    </td>
                  </tr>
                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>CORE FEATURES</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Mutiple Ingest (RTSP, RTMP, HLS, MPEG-TS)</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Stream with Castr in multiple ways: either push streams directly from an encoder or pull from any supported sources.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Stream
                            with Castr in multiple ways: either push streams directly from an encoder or pull from any
                            supported sources.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Unlimited Cloud Recording</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Stream with Castr in multiple ways: either push streams directly from an encoder or pull from any supported sources.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Record
                            up to 72 hours and store for maximum 3 days.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Live To VOD</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Stream with Castr in multiple ways: either push streams directly from an encoder or pull from any supported sources.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Stream
                            with Castr in multiple ways: either push streams directly from an encoder or pull from any
                            supported sources.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>SRT/RTMP Pull Links</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">A playable link of your live stream,
                          which you can set up as a live source on another broadcast. This feature allows you to play
                          one live stream inside the other</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Subsecond Streaming (Add-on)</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Experience near-instantaneous
                          streaming</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HLS URL For Live</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Get HLS URLs for your livestreams to
                          use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HLS URL For VOD</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Get HLS URLs for your uploaded videos to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:4993,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial&quot;}">Get
                            HLS URLs for your uploaded videos to use with OTT platforms like Roku, Apple TV, Amazon Fire
                            TV and more.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Low Latency Playback</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot; Enjoy streaming with latency as low as 15 seconds&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:4993,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial&quot;}">
                            Enjoy streaming with latency as low as 15 seconds</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Video Source Download</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Dedicated support team to provide live chat assistance&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:4993,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial&quot;}">Dedicated
                            support team to provide live chat assistance</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Failover Ingest</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Broadcast simultaneously to a primary
                          and a backup stream. If your primary stream fails, your backup stream will be broadcast to
                          avoid interruption.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Team feature</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The additional member slots let you
                          add your team, assign roles, customize permissions, and stream from the same dashboard.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span class="yearly_data" :class="{ 'd-none': monthly }">5 additional members</span>
                        <span class="monthly_data" :class="{ 'd-none': !monthly }">3 additional members</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Showcase</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through webhooks&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Create
                            custom video galleries to showcase your work, brand, or business</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>24x7 support</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Dedicated support team to provide
                          live chat assistance.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>PLAYER & SECURITY</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span></span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>HTML5 player and iframe</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Embed videos onto your website using embedded links or iframe&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Embed
                            videos onto your website using embedded links or iframe</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Unlimited viewers</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;No restriction on the number of audience. Stream to as many viewers as you want.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:899,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:16777215},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">No
                            restriction on the number of audience. Stream to as many viewers as you want.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Video Analytics</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;View real-time data. Retention period of analytics data is 30 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">View
                            real-time data. Retention period of analytics data is 30 days.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Chatbox</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;\bSend and receive messages right on your embedded player&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Send
                            and receive messages right on your embedded player</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Custom player</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Customize theme color, autoplay embedded player, Google Analytics, poster, and watermark&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;12&quot;:0}">Customize
                            theme color, autoplay embedded player, Google Analytics, poster, and watermark</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Adaptive bitrate (ABR) transcoding</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Auto-adjusts the bitrate based on the viewer's internet connection quality. Limited to 1 stream per account.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Auto-adjusts
                            the bitrate based on the viewer's internet connection quality. Limited to 1 stream per
                            account.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Bitrate Ladder</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;View real-time data. Retention period of analytics data is 30 days.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Easily
                            adjust your stream's bitrate to optimize performance for your viewers.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Rewind</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Get HLS URL for your streams to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Get
                            HLS URL for your streams to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and
                            more.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Playlist</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Curate and organize your chosen content items into a sequential list.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Curate
                            and organize your chosen content items into a sequential list.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>3</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Dynamic Players</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Curate and organize your chosen content items into a sequential list.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Curate
                            and organize your chosen content items into a sequential list.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Rewind</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Get HLS URL for your streams to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and more.&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Get
                            HLS URL for your streams to use with OTT platforms like Roku, Apple TV, Amazon Fire TV and
                            more.</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>2</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Password protection</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Set a password for your embedded player&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Set
                            a password for your embedded player</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Domain whitelisting</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Restrict the player to function exclusively on a designated website&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Restrict
                            the player to function exclusively on a designated website</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Timeshifting - Delayed Streaming</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Allow you to go live based on the audience's timezone (e.g., 9 a.m. in San Francisco, Dublin and Dubai)&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Allow
                            you to go live based on the audience's timezone (e.g., 9 a.m. in San Francisco, Dublin and
                            Dubai)</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Security – Geo-blocking/whitelisting</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Restrict or allow access based on regions and countries&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Restrict
                            or allow access based on regions and countries</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>VIDEO MONETIZATION</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>In-stream Advertising</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Monetize by displaying pre-roll ads
                          on stream player, with an option to choose between skippable and non-skippable ads.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Paywall</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Charge viewers with a fee for access
                          using paywall.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Commission %</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Commission percentage Castr applies
                          to paywalled content sales.Charge viewers with a fee for access using paywall.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>5%</span>
                      </div>
                    </td>
                  </tr>




                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>TRANSCODER</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Transcoder</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Transcoder allocation per
                          subscription plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Convert H265 To H264</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Effortlessly convert streams from
                          H265 to H264 codec.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Split Audio Tracks</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Isolate individual audio tracks from
                          multi-track streams</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Deinterlacing/Interlacing</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Transform interlaced streams into
                          progressive streams.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>




                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>TV PLAYOUT</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Playouts</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Playout allocation per subscription
                          plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Schedule Mode</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Schedule contents one after another.
                        </div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Advanced Settings</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Adjust the resolution and frame rate
                          of the outgoing stream to your preferences.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Line Up Mode Video Duration</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Aggregate duration of all content in
                          Line Up mode.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>24 hours</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max Loop Days</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Limit on content looping days.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>30 Days</span>
                      </div>
                    </td>
                  </tr>



                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Live Clipping</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Number of Live Clipping</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Highlight allocation per subscription
                          plan.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>1</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max Duration</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Maximum clip duration limit.</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>15 minutes</span>
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Pre-recorded Livestream (Scheduler)</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Concurrent pre-recorded streams</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The number of streams on Castr's
                          pre-recorded dashboard, including those that are currently live and offline</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>3</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Max no. of files uploaded in a stream</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum number of files uploaded in a stream&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            number of files uploaded in a stream</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>15</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Maximum Video Duration Per File</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum number of files uploaded in a stream&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            number of files uploaded in a stream</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>240 minutes</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Import files from Dropbox</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Maximum number of files uploaded in a stream&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Maximum
                            number of files uploaded in a stream</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Maximum file size</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">The maximum size limit for each
                          uploaded pre-recorded file</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>20 GB</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Infinite Loop</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Compatible with platforms that support infinite loop streaming&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Compatible
                            with platforms that support infinite loop streaming</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr class="cell-defination-tr">
                    <td class="pt-cell pt-cell-defination pt-parent-menu">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>DEVELOPERS</span>
                      </div>
                    </td>
                    <td class="pt-cell pt-border-right-trnasparent">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>API Access</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"> Developer API Access granted</div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Stream Alerts</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through SMS alerts&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            stream status notifications through SMS alerts</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Player Events API</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive player status notifications through API&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            player status notifications through API</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Webhook Notification</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;"><span
                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Receive stream status notifications through webhooks&quot;}"
                            data-sheets-userformat="{&quot;2&quot;:897,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:1,&quot;11&quot;:4,&quot;12&quot;:0}">Receive
                            stream status notifications through webhooks</span></div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="pt-cell pt-cell-defination">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <span>Origin Accelerator</span>
                        <img class="tt-question-tooltip"
                          src="https://castr.com/wp-content/uploads/2023/01/pricing_question-icon.svg" alt="Icon">
                        <div class="mbt-tooltip" style="width:200px; height:auto;">Reduce load on your origin and ensure
                          low-latency global delivery. </div>
                      </div>
                    </td>
                    <td class="pt-cell">
                      <div class="pt-cell-inner text-dark-1 text-small text-500">
                        <img src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg" alt="Icon">
                      </div>
                    </td>
                  </tr>


                  <tr>
                    <td class="pt-cell vm_btn">
                      <a class="all_feature starter_feature d-block align-center">View more <img
                          src="https://castr.com/wp-content/uploads/2023/04/down_arrow_large.svg"
                          style="transform:unset" alt=""></a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="pricing-right">
            <div class="pt-custom-plan-btn" @mouseover="hover()" :class="{ 'd-none': hoverTop }">
              <div class="tt-custom-plan-btn-area">
                <span>Custom Plan</span>
                <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_right-caret-black.svg"
                  alt="Icon">
              </div>
            </div>
            <div class="tt-sticky pt-custom-plan-wrapper" @mouseleave="hoverLeave()"
              :class="{ 'd-block': !hoverBottom }">
              <div class="tt-custom-plan-top">
                <h3>Custom Plan</h3>
                <h2>On Request</h2>
                <div class="pt-plan-bordered-btn pt-btn-bg">
                  <a class="open_calendly" href="https://calendly.com/castr/sales?utm_source=dashboard&utm_medium=website&utm_campaign=dashboard-checkout" target="_blank">Book a
                    meeting</a>
                </div>
                <div class="pt-plan-bordered-btn pt-whatsapp-btn">
                  <a class="!flex items-center justify-center" href="https://wa.me/16049988035" target="_blank">
                    <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_whatsapp-1.svg"
                      alt="whatsapp">
                    <span>Chat with us</span>
                  </a>
                </div>
              </div>
              <div class="tt-custom-plan-bottom">
                <p class="tt-plan-support-text">Custom trial available.
                  Try our service at no risk.</p>
                <div class="tt-plan-supports">
                  <ul>
                    <li>
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Check">
                      <span>24/7 Dedicated Tech Support</span>
                    </li>
                    <li>
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Check">
                      <span>Best pricing in the market</span>
                    </li>
                    <li>
                      <img decoding="async" src="https://castr.com/wp-content/uploads/2023/01/pricing_Check.svg"
                        alt="Check">
                      <span>No contract to get started with us</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="reciept-wrapper" class="receipt-wrapper" v-if="subscriptionPackage">
          <div class="subscription-reciept white-bg">
            <div class="reciept-package-details">
              <div class="package-card align-self-stretch bg-white">
                <div class="package-heading">
                  <div class="package-info px-0">

                    <p class="package-name text-s-m text-500" v-if="!this.hiddenPack"> <span
                        class=" position-relative">{{
                          findPackage(subscriptionPackage)[0].info.name.replace('All in One - ', '') }}<span
                          class="popular_tag"
                          v-if="findPackage(subscriptionPackage)[0].info.popular">POPULAR</span></span> </p>
                    <p class="package-name text-s-m text-500" v-else> <span class=" position-relative">{{
                      subscriptionPackage.name }}</span> </p>
                    <div v-if="subscriptionPackage.baseCharge > 0">
                      <p v-if="!isAnnualPackage(subscriptionPackage)" class="package-price"
                        :class="isAnnualPackage(subscriptionPackage) ? 'mb-3' : ''">
                        ${{ subscriptionPackage.baseCharge }}<span style="text-transform:none;">/month</span></p>
                      <div class="mb-1" v-else>
                        <div class="">
                          <span class="package-price" v-if="!this.hiddenPack">${{
                            findPackage(subscriptionPackage)[0].info.yearly }}<span>/month</span></span>
                          <span class="package-price" v-else>${{ subscriptionPackage.baseCharge
                            }}<span>/year</span></span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p class="package-price">Free</p>
                    </div>
                  </div>
                  <p v-if="isAnnualPackage(subscriptionPackage)" class="text-dark-5 text-s-ss text-left mt-1 mb-0">
                    <span>Billed as ${{ subscriptionPackage.baseCharge }} yearly</span>
                  </p>
                  <p v-if="isAnnualPackage(subscriptionPackage)"
                    class="text_yellow font-normal text-s-ss text-left mt-0 mb-2"><span class="text_yellow">Save
                      {{ getSavedamount(subscriptionPackage) }}</span></p>

                </div>
                <div class="package-characteristics">
                  <div>
                    <ul v-if="!this.hiddenPack">
                      <li v-for="(ch, index) in findPackage(subscriptionPackage)[0].charectiristics" :key="index">
                        <span v-if="ch.hasOwnProperty('monthly') && (subscriptionPackage.cycleAge == 30)"
                          v-html="ch.monthly"> </span>
                        <span v-else v-html="ch.name"></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="rr-right">
              <div class="reciept-row reciept-row-top px-0">
                <div class="reciept-row-left">
                  <p v-if="isAnnualPackage(subscriptionPackage)">
                    Subscription Terms: ${{ !this.hiddenPack ? findPackage(subscriptionPackage)[0].info.yearly + ' for each month' : subscriptionPackage.baseCharge+' for each year'}}
                  </p>
                  <p v-else>Subscription Terms: ${{ subscriptionPackage.baseCharge }} for each month</p>
                  <!-- <p v-if="subscriptionPackage.subscriptionTerm">
              <span class="package-price">
                Subscription Terms : &nbsp;&nbsp;&nbsp; {{subscriptionPackage.subscriptionTerm}}
              </span>
          </p> -->
                </div>
                <!-- <div class="reciept-row-right">
          <p v-if="subscriptionPackage">${{getTotalFee()}}</p>
          <p v-else>--</p>
        </div> -->
              </div>
              <div v-if="subscriptionPackage" class="reciept-row reciept-row-coupon px-0">
                <div class='reciept-row-left w-100' v-if="isShowCouponCode">
                  <b-button variant="link" class="text-decoration-none" style="padding: 0" @click="gotaCouponCode"
                    v-if="!isFillInCouponCode">Got a coupon code?</b-button>
                  <div class="coupon-form d-flex align-items-center justify-content-start mt-2"
                    :class="(!couponCode.isValid && couponCode.status) ? 'coupon-code-error' : ''"
                    v-if="isFillInCouponCode">
                    <input class="input mr-1" placeholder="Got a Coupon Code?" v-model="couponCode.value" />
                    <b-button variant="secondary" size="sm" class="coupon-btn" @click="applyCouponCode">Apply</b-button>
                  </div>
                  <div class="coupon-status mt-1" v-if="couponCode.isValid && couponCode.status">
                    <p style="color: green;margin-bottom: 0px">{{ couponCode.status }}</p>
                  </div>

                  <div class="coupon-status mt-2 mb-0 d-flex align-items-center"
                    v-if="!couponCode.isValid && couponCode.status"><i
                      class="fas mr-1 fa-exclamation-circle c-text-error"></i>
                    <p class="text-small c-text-error">{{ couponCode.status }}</p>
                  </div>
                </div>
              </div>
              <div class="reciept_details">
                <div v-if="hasFee()" class="reciept-row">
                  <div class="reciept-row-left">
                    <p>Valid till</p>
                  </div>
                  <div class="reciept-row-right">
                    <p>{{ dateFormated(getEndingDate(), 'DD-MM-YYYY') }}</p>
                  </div>
                </div>

                <div class="reciept-row receipt-total">
                  <div class="reciept-row-left">
                    <p>Total</p>
                  </div>
                  <div class="reciept-row-right">
                    <p v-if="subscriptionPackage">${{ getTotalFee() }}</p>
                    <p v-else>--</p>
                  </div>
                </div>
                <div v-if="subscriptionPackage && paymentMethodsVisible" class="reciept-row reciept-row-payment-method"
                  style="">
                  <div class="reciept-row-left">
                    <p>Pay With</p>
                  </div>
                  <div class="value currency reciept-row-right"
                    :class="{ 'has-dropdown': subscriptionPackage.name.includes('Starter') || subscriptionPackage.name.includes('Standard') }">
                    <b-dropdown class=" package-dropdown w-100"
                      v-if="subscriptionPackage.name.includes('Starter') || subscriptionPackage.name.includes('Standard')">
                      <template #button-content>
                        <div v-if="!subscriptionPackage" style="display:inline-block;">Select</div>
                        <div v-else class="package-dropdown-item package-dropdown-item-placeholder text-uppercase"
                          style="display: inline-block;margin-right: 10px;">
                          <span class="package-name">{{ selectedPaymentMethod.label }}</span>
                        </div>
                      </template>
                      <b-dropdown-item v-for="method in availablePaymentMethods" :key="method.value"
                        @click="selectPaymentMethod(method)">
                        <div class="a package-dropdown-item"
                          :class="{ selected: selectedPaymentMethod.value === method.value }">
                          <span class="package-name">{{ method.label }}</span>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                    <p class="text-capitalize" v-else>Credit Card</p>
                  </div>
                </div>
              </div>

              <div class="text-center reciept-action-area white-bg px-0">
                <div v-if="checkoutStep == 0 && subscriptionPackage">
                  <b-button size="md" class="mb-3 w-100" variant="primary" @click="requestCheckout">
                    &nbsp;
                    <span>{{ hasFee() ? 'Proceed to Checkout' : 'Change Package' }}</span>
                    &nbsp;
                  </b-button>
                  <p class="text-center text-small text-dark-6 mb-0">*All prices are in USD</p>
                </div>

                <div v-else-if="checkoutStep == 1" class="text-info message">Request is being validated</div>

                <div v-else-if="checkoutStep == 2" class="text-info message">{{ hasFee() ? 'Validating payment' :
                  'Changing subscription' }}</div>
                <div v-else-if="checkoutStep == 3">
                  <div class="alert alert-success ">
                    <div class="message"><strong>Successfully Subscribed</strong></div>
                    <p>Great, we have enabled your selected package to your account.</p>
                  </div>
                  <br>
                  <p>Back to <router-link to="/dashboard">Dashboard</router-link></p>
                </div>

                <div v-if="checkoutStep == 1 || checkoutStep == 2"
                  style="display:flex;justify-content:center;align-items:center;margin-top:5px;">
                  <b-progress :value="100" :max="100" animated class="w-50 mt-2" style="height: 10px;"></b-progress>
                </div>
              </div>
            </div>
          </div>


          <div class="reiept-terms d-none">
            <p>
              By clicking <span>Proceed to Checkout and proceed with your payment in the next page</span>, you agree
              that:
            </p>
            <p>
              We'll automatically renew your subscription at the purchase price. You can see your renewal date in
              Billing
              page after the payment is made. You may cancel your subscription anytime and will not be charged in the
              next
              billing cycle. After cancellation, you can still access the package until the end of the paid period.
            </p>
            <p>All paid subscriptions and successful rebill payments are non-refundable. You also agree to Castr <a
                href="https://castr.com/terms">Terms of Service</a> and <a
                href="https://castr.com/privacypolicy">Privacy
                Policy</a></p>
            <p>Not willing to change? <router-link to="/manage/billing">Go to accounts</router-link></p>
          </div>
        </div>
      </div>

      <!-- error placeholder -->
      <b-alert v-if="error" show variant="danger" class="left inline" style="margin:15px 0;max-width:500px;">{{
        error.message }}</b-alert>
      <confirm-modal modal-id="modal-confirm" message="Do you want to proceed without adding coupon code?"
        @modal-confirm="onCouponCodeConfirm" @modal-dismiss="onCouponCodeDismiss" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import UserService from '@/services/UserService'
import SubscriptionService from '@/services/SubscriptionService'
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import { mapGetters } from 'vuex';
import Spinner from "@/components/ui/Spinner.vue";
import { date, bytes } from "@/filtersNew"

// const AvailablePaymentMethods = [{ label: 'Credit Card', value: 'cc' }, { label: 'Paypal', value: 'paypal' }]
// const AvailablePaymentMethods = [{ label: 'Credit Card', value: 'cc' }]
const AvailablePaymentMethods = [{ label: 'Fastspring', value: 'fastspring-contextual' }]
const PackageCharacteristics = [

  {
    info: {
      name: 'Starter',
      oldname: 'All in One - Entry',
      yearly: '12.5',
      saved: '89.9',
      popular: false
    },
    charectiristics: [
      {
        name: '<strong>3</strong> concurrent streams',
        monthly: '<strong>2</strong> concurrent streams',
        details: 'The number of streams happening at the same time'
      },
      {
        name: '<strong>6</strong> Multistream destinations',
        details: 'Total destinations that you can add to your streams.'
      },
      {
        name: '<strong>2.4 TB</strong> upfront bandwidth',
        monthly: '<strong>200 GB</strong> player bandwidth',
        details: 'Amount of bandwidth available for live and video hosting player'
      },
      {
        name: '<strong>100 GB</strong> Video Storage',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Embed player',
        details: 'Embed videos onto your website using embedded links or iframe  '
      },
      {
        name: 'Akamai CDN',
        details: 'Powered by Akamai CDN, Castr ensures stable and high-quality video delivery on any device or internet connection.'
      },
      {
        name: 'SRT Ingest',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Paywall',
        details: 'Amount of storage available for storing video files'
      }
    ]
  },
  {
    info: {
      oldname: 'All in One - Plus',
      name: 'Standard',
      yearly: '33.5',
      saved: '79.9',
      popular: false
    },
    charectiristics: [
      {
        name: '<strong>3</strong> concurrent streams',
        monthly: '<strong>2</strong> concurrent streams',
        details: 'The number of streams happening at the same time'
      },
      {
        name: '<strong>10</strong> Multistream destinations',
        details: 'Total destinations that you can add to your streams.'
      },
      {
        name: '<strong>6 TB</strong> upfront bandwidth',
        monthly: '<strong>500 GB</strong> player bandwidth',
        details: 'Amount of bandwidth available for live and video hosting player'
      },
      {
        name: '<strong>200 GB</strong> Video Storage',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Embed player',
        details: 'Embed videos onto your website using embedded links or iframe  '
      },
      {
        name: 'Akamai CDN',
        details: 'Powered by Akamai CDN, Castr ensures stable and high-quality video delivery on any device or internet connection.'
      },
      {
        name: 'SRT Ingest',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Paywall',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'SRT/RTMP Pull Links',
        details: 'Amount of storage available for storing video files'
      }
    ]
  },
  {
    info: {
      oldname: 'All in One - Premium',
      name: 'Professional',
      yearly: '62.5',
      saved: '450',
      popular: true
    },
    charectiristics: [
      {
        name: '<strong>10</strong> concurrent streams',
        monthly: '<strong>3</strong> concurrent streams',
        details: 'The number of streams happening at the same time'
      },
      {
        name: '<strong>15</strong> Multistream destinations',
        details: 'Total destinations that you can add to your streams.'
      },
      {
        name: '<strong>24 TB</strong> upfront bandwidth',
        monthly: '<strong>2 TB</strong> player bandwidth',
        details: 'Amount of bandwidth available for live and video hosting player'
      },
      {
        name: '<strong>1 TB</strong> Video Storage',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Embed player',
        details: 'Embed videos onto your website using embedded links or iframe  '
      },
      {
        name: 'Akamai CDN',
        details: 'Powered by Akamai CDN, Castr ensures stable and high-quality video delivery on any device or internet connection.'
      },
      {
        name: 'SRT Ingest',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Paywall',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'SRT/RTMP Pull Links',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Adaptive Bitrate',
        details: 'Amount of storage available for storing video files'
      }
    ]
  },
  {
    info: {
      oldname: 'All in One - Business',
      name: 'Premium',
      yearly: '104.5',
      saved: '550',
      popular: false
    },
    charectiristics: [
      {
        name: '<strong>10</strong> concurrent streams',
        monthly: '<strong>5</strong> concurrent streams',
        details: 'The number of streams happening at the same time'
      },
      {
        name: '<strong>20</strong> Multistream destinations',
        details: 'Total destinations that you can add to your streams.'
      },
      {
        name: '<strong>36 TB</strong> upfront bandwidth',
        monthly: '<strong>3 TB</strong> player bandwidth',
        details: 'Amount of bandwidth available for live and video hosting player'
      },
      {
        name: '<strong>2 TB</strong> Video Storage',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Embed player',
        details: 'Embed videos onto your website using embedded links or iframe  '
      },
      {
        name: 'Akamai CDN',
        details: 'Powered by Akamai CDN, Castr ensures stable and high-quality video delivery on any device or internet connection.'
      },
      {
        name: 'SRT Ingest',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Paywall',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'SRT/RTMP Pull Links',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Adaptive Bitrate',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'HLS URL For OTT ',
        details: 'Amount of storage available for storing video files'
      }
    ]
  },
  {
    info: {
      name: 'Ultra',
      oldname: 'All in One - Enterprise',
      yearly: '250',
      saved: '599',
      popular: false
    },
    charectiristics: [
      {
        name: '<strong>15</strong> concurrent streams',
        monthly: '<strong>10</strong> concurrent streams',
        details: 'The number of streams happening at the same time'
      },
      {
        name: '<strong>30</strong> Multistream destinations',
        details: 'Total destinations that you can add to your streams.'
      },
      {
        name: '<strong>60 TB</strong> upfront bandwidth',
        monthly: '<strong>5 TB</strong> player bandwidth',
        details: 'Amount of bandwidth available for live and video hosting player'
      },
      {
        name: '<strong>3</strong> TB Video Storage',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Embed player',
        details: 'Embed videos onto your website using embedded links or iframe  '
      },
      {
        name: 'Akamai CDN',
        details: 'Powered by Akamai CDN, Castr ensures stable and high-quality video delivery on any device or internet connection.'
      },
      {
        name: 'SRT Ingest',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Paywall',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'SRT/RTMP Pull Links',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Adaptive Bitrate',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'HLS URL For OTT ',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'eCDN - Peer To Peer CDN ',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'Dedicated Support',
        details: 'Amount of storage available for storing video files'
      },
      {
        name: 'RTMP backup',
        details: 'Amount of storage available for storing video files'
      }
    ]
  }
]
export default {
  name: 'Payments',
  components: {
    ConfirmModal,
    Spinner
  },
  data() {
    var self = this
    return {
      error: null,
      minWindowHeight: null,
      checkoutStep: 0,
      // processing: true,
      cardValidated: false,
      hiddenPack: false,
      hoverTop: false,
      hoverBottom: true,
      packages: [],
      showArchivedPlans: false,
      userSubscription: null,
      userBaseSubscription: null,
      packCategory: { label: 'all in one', value: 'live' },
      subscriptionPackage: { 'afffaa': '00' },
      subscriptionPackages: [],
      packCategories: [
        { label: 'all in one', value: 'live' },
        { label: 'multistream', value: 'restream' },
      ],
      quantity: 1,
      selectedPaymentMethod: AvailablePaymentMethods[0],
      availablePaymentMethods: AvailablePaymentMethods,
      PackageCharacteristics: PackageCharacteristics,
      paymentMethodsVisible: true,
      isShowCouponCode: true,
      isFillInCouponCode: false,
      monthly: false,
      couponCode: {
        value: null,
        status: null,
        isValid: false
      },
      getEndingDate(pack) {
        const today = new Date()
        if (self.isAnnualPackage(this.subscriptionPackage)) {
          today.setFullYear(today.getFullYear() + 1)
        } else {
          today.setMonth(today.getMonth() + this.quantity)
        }

        return today
      },
      getSubscriptionFee() {
        return this.subscriptionPackage.baseCharge
      },
      getTotalFee() {
        let fee = this.quantity * this.subscriptionPackage.baseCharge
        fee = fee.toFixed(2)
        return fee
      },
      hasFee() {
        return this.subscriptionPackage && this.getTotalFee() > 0
      },
      onInputChange(prop) {
        this.formErrors[prop] = false
      }
    }
  },
  beforeMount() {
    this.$store.dispatch('Ui/setLoadingStatus', true);
  },
  async mounted() {
    this.minWindowHeight = window.innerHeight + 'px'
    this.loadPackages();
    window.trackEvent(`Payments Page`)

    // Update win title
    this.$emit('updateTitle', 'Purchase Subscription')
  },
  computed: {
    ...mapGetters({
      loadingStatus: "Ui/loadingStatus",
      baseSubscription: 'User/baseSubscription',
      addonSubscriptions: 'User/addonSubscriptions'
    }),
    monthlyComputed: {
      get() {
        return !this.monthly;
      },
      set(value) {
        // Update the original boolean value based on the negated value
        this.monthly = !value;
      },
    },
    filteredPackages() {
      let packs = this.showArchivedPlans ? this.packages : _.filter(this.packages, pack => !pack.archived)
      if (this.monthly) {
        packs = _.filter(packs, pack => !this.isAnnualPackage(pack))
      } else {
        packs = _.filter(packs, pack => this.isAnnualPackage(pack))
      }
      // let newPlans = packs.filter(p=>{
      //   return !p.name.includes('All in One');
      // }).sort((a,b)=> a.baseCharge - b.baseCharge);
      // return newPlans;
      //   console.log(this.packages);
      return packs.sort((a, b) => a.baseCharge - b.baseCharge);
    },
    comparePlanLink() {
      if (!this.packCategory) return

      let href = 'https://castr.com/'
      if (this.packCategory.value === 'live') {
        href += 'livestreaming'
      }

      href += '#pricing'
      return href
    },
    activeTeam() {
      return this.$store.state.User.activatedTeam;
    }
  },
  watch: {
    activeTeam() {
      if (!this.subscriptionPackage && !this.hiddenPack) {
        this.loadPackages();
      }
    }
  },
  methods: {
    async loadPackages() {
      try {
        // Fetch available subscriptions packages
        let packages = await SubscriptionService.getSubscriptionPackages()
        //   let packages = await SubscriptionService.getnewSubscriptionPackages()
        packages = _.sortBy(packages, p => {
          p?.name[0]
        })

        this.subscriptionPackages = packages
        const paymentMethods = await SubscriptionService.getUserPaymentMethods()
        this.selectedPaymentMethod = paymentMethods[0]
        this.availablePaymentMethods = paymentMethods

        if (!_.isEmpty(paymentMethods) && paymentMethods.length === 1) {
          this.paymentMethodsVisible = false
        }

        // Set selected subscription
        let packageId = this.$route.query && this.$route.query.package
        const showPromotionPackageOnly = this.$route.query && this.$route.query.ppo

        if (packageId && !this.hiddenPack) {
          this.subscriptionPackage = _.find(packages, { _id: packageId })
          if (_.isEmpty(this.subscriptionPackage) || !this.subscriptionPackage) {
            this.subscriptionPackage = await SubscriptionService.getSubscriptionPackages(packageId);
            this.hiddenPack = true;
          }

          // Update plan category
          setTimeout(() => {
            const packCat = _.find(this.packCategories, { value: this.subscriptionPackage?.category })
            if (packCat) { this.packCategory = packCat }
            else { this.packCategory = this.packCategories[0] }
            if (packCat) {
              this.selectSubscriptionPackageCategory(packCat, false)
            }
            if (showPromotionPackageOnly === 'true') {
              this.packages = [this.subscriptionPackage]
              this.packCategories = [packCat]
            }
          }, 100)
        } else {
          // Read url query params
          this.processURLSearchParams()
        }


        // Fetch user subscriptions
        this.userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions };

        this.updateUserBaseSubscription()
        let baseSub = this.userBaseSubscription

        const action = this.$route.query && this.$route.query.action
        const category = this.$route.query && this.$route.query.category
        const baseCharge = parseFloat(this.$route.query.bycharge)
        const currentPackage = _.find(packages, { _id: packageId })

        if (action === 'upgrade' || (this.subscriptionPackage && this.subscriptionPackage.baseCharge === 0)) {
          const userPackage = baseSub ? baseSub.package : { baseCharge: 0, cycleAge: 30 }
          let superiorPackage = _.find(this.packages, p => {
            let minBaseCharge = Math.max(userPackage.baseCharge, 9.99)
            let minCycleAge = Math.max(userPackage.cycleAge, 30)
            return p.cycleAge === minCycleAge && p.baseCharge > minBaseCharge
          })

          if (!superiorPackage) { superiorPackage = _.find(this.packages, p => p.baseCharge === userPackage.baseCharge) }
          if (superiorPackage) {
            this.subscriptionPackage = superiorPackage
          }
          setTimeout(() => {
            if (category === 'live' || category === 'restream') {
              // this.selectSubscriptionPackage(this.filteredPackages[0])
            }
            const el = document.querySelector('.full-plan-comparison');
            el && el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
          }, 1000)
        } else if (action === 'resubscribe') {
          const userPackage = baseSub ? baseSub.package : { baseCharge: 0 }
          const curPack = _.find(this.packages, p => p.baseCharge === userPackage.baseCharge)
          this.subscriptionPackage = curPack
        } else if (baseCharge) {
          const isAnnualPack = this.$route.query.annual === '1'

          const subPack = _.find(this.packages, p => {
            let bool = p.baseCharge === baseCharge
            if (isAnnualPack) {
              bool = bool && /year|annual/gi.test(p.name)
            }

            return bool
          })
          if (subPack) {
            this.subscriptionPackage = subPack
          }
        }

        // this.processing = false
        this.$store.dispatch('Ui/setLoadingStatus', false);
      } catch (e) {
        this.error = e
      }

    },
    getSavedamount(pack) {
      const saved_amount = _.find(PackageCharacteristics, function (singlepack) {
        // console.log(singlepack);
        let packname = pack.name;
        return packname.includes(singlepack.info.name);
      });
      if (saved_amount) {
        return saved_amount.info.saved;
      }
    },
    getPackageName(pack) {
      let packageName = pack.name;
      // return packageName;
      // if(pack.category == 'restream'){
      //   return packageName.replace('- Yearly', '').replace("Multistream", '<div class="subtext text-dark-6 text-small text-500">Multistream</div>');
      // }
      // if(pack.category == 'live'){
      return packageName.replace(' Yearly', '').replace('-', '');
      // return packageName.replace(' Yearly', '').replace('-', '').replace("All in One", '<div class="subtext text-dark-6 text-small text-500">All in One</div>');
      // }
    },
    ispopular(pack) {
      const selected = PackageCharacteristics.filter((p) => {
        return p.info.name == pack.name.replace(' Yearly', '');
      });
      return selected;
    },
    toggleYearly() {
      this.monthly = !this.monthly
    },
    isAnnualPackage(pack) {
      return /yearly/i.test(pack.name)
    },
    processURLSearchParams() {
      const searchParams = _.reduce((window.location.search || '').split('&'), (o, e) => {
        let pars = _.split(e, '=')
        return _.assign({}, o, { [_.replace(pars[0], /^\?/, '')]: pars[1] })
      }, {})

      const predefinedCat = searchParams.category || 'restream'
      if (searchParams.package) {
        const packCat = _.find(this.packCategories, { value: this.subscriptionPackage.category }) || this.packCategories[0]
        this.selectSubscriptionPackageCategory(packCat, false)
        this.monthly = !this.isAnnualPackage(this.subscriptionPackage)
        setTimeout(() => {
          const el = document.querySelector('.full-plan-comparison');
          el && el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 2000)
      } else {
        if (predefinedCat) {
          const packCat = _.find(this.packCategories, { value: predefinedCat })
          if (packCat) {
            this.selectSubscriptionPackageCategory(packCat, false)
          }
        }
      }
    },
    selectPaymentMethod(method) {
      this.selectedPaymentMethod = method
    },
    gerPermalink() {
      const route = window.location
      const host = `${route.protocol}//${route.hostname}`
      return `${host}/billing/pay?userId=${UserService.getUserId(this.activeTeam)}&packageId=${this.subscriptionPackage._id}`
    },
    isCurrentSubscription(pack) {
      pack = pack || this.subscriptionPackage

      const packCategoryType = 'live'
      const userSub = this.userSubscription
      if (!userSub) return
      let baseSub = userSub.subscription
      if (packCategoryType !== 'restream') {
        baseSub = _.find(userSub.addonSubscriptions, { category: packCategoryType })
        baseSub = baseSub || { package: { baseCharge: 0 } }
      }
      let userSubNode = baseSub
      if (!userSubNode || !pack) return

      return userSubNode.enabled && userSubNode.package._id === pack._id
    },
    async selectSubscriptionPackage(pack) {
      //   console.log(pack);
      this.hiddenPack = false;
      this.subscriptionPackage = null
      this.subscriptionPackage = pack
      if (pack) {
        let setScroll = setTimeout(() => {
          const el = document.querySelector('#reciept-wrapper');
          el && el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 10);
        const checkpaymentMethods = await SubscriptionService.getUserPaymentMethods()
        if (pack.category == 'live' && checkpaymentMethods) {
          this.selectedPaymentMethod = checkpaymentMethods[0]
        }
        // const receipt = await $(".reciept-wrapper").length 

      }
    },
    selectSubscriptionPackageCategory(packCat, closeCouponCode = true) {
      const oldPackCat = this.packCategory
      this.packCategory = packCat
      this.filterSubscriptionPacks()

      if (oldPackCat && oldPackCat !== packCat) { this.selectSubscriptionPackage(null) }

      this.updateUserBaseSubscription()

      // Reset current coupon code
      if (closeCouponCode) this.closeCouponCode()
    },
    updateUserBaseSubscription() {
      const packCategoryType = this.packCategory.value
      const userSub = this.userSubscription
      if (!userSub) return
      let baseSub = userSub.subscription
      if (packCategoryType !== 'restream') {
        baseSub = _.find(userSub.addonSubscriptions, { category: packCategoryType })
        baseSub = baseSub || { package: { baseCharge: 0 } }
      }

      this.userBaseSubscription = baseSub
    },
    filterSubscriptionPacks() {
      const packCat = this.packCategory
      const catPackages = _.filter(this.subscriptionPackages, { category: 'live' })
      this.packages = catPackages
    },
    async requestCheckout() {
      this.checkoutStep = 1

      try {
        // Validate coupon code first
        if (this.couponCode.value) {
          await this.applyCouponCode()
          if (!this.couponCode.isValid) {
            this.$notify({ group: 'error', text: this.couponCode.status })
            this.checkoutStep = 0
            return
          }
        } else if (this.isFillInCouponCode) {
          // Force user fill in the coupon code or they must choose `Continue without coupon code`
          this.$root.$emit('bv::show::modal', 'modal-confirm');
          return
        }

        // Create invoice
        const invoiceRes = await SubscriptionService.createInvoicePaymentRequest(this.subscriptionPackage._id, this.selectedPaymentMethod.value, this.couponCode.value)
        window.location.href = invoiceRes.invoiceUrl
      } catch (e) {
        this.$notify({ group: 'error', text: e ? e.message : 'failed to create invoice0' })
        this.checkoutStep = 0
      }
    },
    gotaCouponCode() {
      this.isFillInCouponCode = !this.isFillInCouponCode
      if (!this.isFillInCouponCode) this.closeCouponCode()
    },
    closeCouponCode() {
      this.isFillInCouponCode = false
      this.couponCode = {
        value: null,
        status: null,
        isValid: false
      }
    },
    async applyCouponCode() {
      try {
        if (!this.couponCode.value) {
          this.couponCode.status = 'The coupon code cannot be empty'
          return
        }
        const data = await SubscriptionService.checkCouponCode(this.subscriptionPackage._id, this.selectedPaymentMethod.value, this.couponCode.value)
        if (data.status === 'valid_code') {
          this.couponCode.isValid = true
          this.couponCode.status = "Promo code successfully redeemed. You\'ll see the discounted price on the next page"
        } else {
          this.couponCode.isValid = false
          this.couponCode.status = 'Your promo code is no longer valid'
        }
      } catch (e) {
        this.$notify({ group: 'error', text: e ? e.message : 'Failed to check coupon code' })
      }
    },
    async onCouponCodeConfirm() {
      try {
        // Create invoice
        const invoiceRes = await SubscriptionService.createInvoicePaymentRequest(this.subscriptionPackage._id, this.selectedPaymentMethod.value, this.couponCode.value)
        window.location.href = invoiceRes.invoiceUrl
      } catch (error) {
        this.$notify({ group: 'error', text: e ? e.message : 'failed to create invoice0' })
        this.checkoutStep = 0
      }
    },
    async onCouponCodeDismiss() {
      this.checkoutStep = 0
    },
    hover() {
      this.hoverTop = true;
      this.hoverBottom = false;
    },
    hoverLeave() {
      this.hoverTop = false;
      this.hoverBottom = true;
    },
    findPackage(pack) {
      const selected = PackageCharacteristics.filter((p) => {
        return p.info.name == pack.name.replace(' Yearly', '').replace(' -', '');
      });

      const selectedOld = PackageCharacteristics.filter((p) => {
        return p.info.oldname == pack.name.replace(' Yearly', '');
      });
      if (selected.length > 0) {
        return selected;
      } else {
        return selectedOld;
      }
    },
    unselectSubscription() {
      this.subscriptionPackage = null;
      this.hiddenPack = false;
    },
    renew_yearly(index) {
      this.toggleYearly();
      this.selectSubscriptionPackage(this.filteredPackages[index]);
    },
    scrollDown(e = null) {
      let sel = '#full_plan_castr';
      if (e) {
        sel = '#' + e;
        document.getElementById(e).classList.add('expand');
      }
      let setScroll = setTimeout(() => {
        const el = document.querySelector(sel);
        el && el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }, 10);
    },
    toggleBox(sel) {
      const element = document.getElementById(sel);
      if (element) {
        element.classList.toggle('expand');
      }
    },
    dateFormated(value, format) {
      return date(value, format)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.view-wrapper {
  color: #ffffff;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #FFFFFF;
}

.receipt-wrapper {
  width: 796px;
  text-align: left;
  margin: 0 auto;
}

.receipt-wrapper .key {
  text-transform: capitalize;
  opacity: 0.65;
}

.receipt-wrapper .value {
  text-transform: uppercase;
}

.logo-icon {
  width: 130px;
  margin-left: -5px;
}

/* .form {
  display: inline-block;
  align-self: center;
  padding: 60px 0;
} */
.field-container {
  width: 300px;
  padding: 10px 0;
  /* border-bottom: 1px solid #22244d; */
}

.field-container:last-of-type {
  border-bottom: none;
}

.label {
  font-size: 12px;
  opacity: 0.65;
  text-transform: capitalize;
}

.message {
  font-size: 15px;
}

.archived-plans-ctrl {
  margin-top: 7px;
  font-size: 12.5px;
  text-transform: lowercase;
  opacity: 0.8;
}

.archived-plans-ctrl input {
  vertical-align: middle;
  margin-right: 2px;
}

.n-feature-name d-flex align-items-center justify-content-start mb-1>img {
  max-width: 16px;
}

.n-feature-single:not(.show-close) .castr-circle-disabled,
.n-feature-single:not(.show-check) .castr-check-enabled,
.n-feature-wrapper {
  display: none;
}

.multistream .n-feature-multistream,
.all.in.one .n-feature-allinone {
  display: flex;
}

.all.in.one .yearly-plans p.package-name {
  min-height: 44px;
  max-width: 159px;
}

.n-feature-wrapper {
  margin: 0 auto;
  background: var(--c-dark-2);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px 25px;
  margin-bottom: 40px;
  align-items: flex-start;
  justify-content: space-around;
  text-align: center;
}

.n-feature-name d-flex align-items-center justify-content-start mb-1>span {
  display: block;
  margin-left: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.n-feature-single>p {
  font-weight: normal;
  font-size: var(--font-s-s);
  line-height: 18px;
  text-align: left;
  color: var(--c-dark-8);
  margin-bottom: 0;
  padding-left: 24px;
  padding-right: 4px;
}

.n-feature-single>p>span {
  color: var(--c-dark-8);
  text-decoration: underline;
  cursor: pointer;
}

.n-feature-wrapper {
  flex-wrap: wrap;
}

.n-feature-single {
  width: 25%;
}

.yearly-switch {
  margin: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yearly-switch>label {
  margin: 0px 17px;
}

.yearly-switch>p {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* color: #000; */
  margin-bottom: 0;
}

/* .yearly-switch>p>span {
    color: var(--c-dark-7);
} */
.text-center.upgrade-page-header {
  padding-top: 17px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.package-card-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 960px;
  margin: 0 auto;
  /* margin-bottom: 40px; */
}

.package-card {
  width: 100%;
  text-align: left;
  border-right: 1px solid var(--c-dark-9);
  border-bottom: 1px solid var(--c-dark-9);
  /* margin-right: 24px; */
}

.package-card:last-child {
  margin-right: 0;
}

.package-card-wrapper .package-card:last-child {
  border-right: 0px;
}

p.package-name {
  font-style: normal;
  color: var(--c-dark-1);
  margin-bottom: 8px;
  line-height: 1.3;
}

.package-price {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  color: var(--c-dark-1);
}

.package-price>span {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--c-dark-5);
}

/* .btn.package-btn {
    background: var(--c-main-1);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-s-s);
    line-height: 17px;
    color: #FFFFFF;
    padding: 11px 43px;
    position: relative;
    min-width: 155px;
    margin-bottom: 0px;
    width: 100%;
} */
.package-characteristics {
  background-color: #fff;
  border-radius: 0px;
  padding: 16px 7px 16px 7px;
}

.package-characteristics ul {
  padding-left: 0;
}

.package-characteristics li {
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-s-s);
  line-height: 18px;
  color: var(--c-dark-3);
  margin-bottom: 8px;
  list-style-type: none;
  position: relative;
  /* padding-left: 21px; */
}

.package-characteristics li>i {
  color: #434B6B;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

.package-btn.btn-outline-light:hover {
  background-color: var(--c-main-1);
  color: #fff;
}

.btn.package-btn.selected:after {
  content: "\f00c";
  font-family: 'Font Awesome 5 Free';
  position: absolute;
  left: 23px;
}

.subscription-reciept {
  padding: 10px;
  background: var(--c-dark-2);
  border-radius: 12px;
  display: flex;
}

.reciept-action-area {
  background: var(--c-dark-2);
  border-radius: 0px 0px 8px 8px;
}

.reiept-terms {
  max-width: 570px;
  margin: 0 auto;
  margin-top: 25px;
}

.reiept-terms>p {
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-s-s);
  line-height: 20px;
  color: var(--c-dark-5);
  margin-bottom: 20px;
  text-align: center;
}

.reiept-terms>p>a {
  color: var(--c-dark-6);
  text-decoration: underline;
}

.reiept-terms>p>span {
  color: var(--c-dark-6);
  font-weight: 500;
}

.reiept-terms {
  max-width: 570px;
  margin: 0 auto;
  margin-top: 25px;
}

.reciept-action-button {
  background: #0062FF;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  padding: 14px 128px;
  margin-bottom: 15px;
}

.reciept-action-button+p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #B0B3B8;
}

.reciept-action-area {
  padding-top: 20px;
  padding-bottom: 24px;
  padding-left: 40px;
  padding-right: 40px;
}

.btn.package-btn.current-plan {
  background: #ADB8DC;
  border-color: #ADB8DC;
  color: #8794B5;
  opacity: 1;
}

.reciept-row-left p {
  color: #141E3D;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 145%;
  margin-bottom: 0;
}

/* .rr-right .reciept-row-left p{
  color: #141E3D;
  font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 145%;
} */
.reciept-row-top .reciept-row-left p {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 145%;
  color: #141E3D;
  ;
  margin-bottom: 4px;
}

.reciept-row-top .reciept-row-left p>span {
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-s-m);
  line-height: 20px;
  color: var(--c-dark-7);
  display: block;
}

.reciept-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid rgba(167, 181, 226, 0.25);
}

.reciept-row:last-child {
  border-bottom: 0;
}

.reciept-row-right p,
.reciept-row-right span {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 145%;
  text-align: right;
  color: #141E3D;
  margin-bottom: 0;
}

.reciept-row.reciept-row-top {
  padding-bottom: 0px;
  border-bottom: 0px;
}

.reciept-row.reciept-row-coupon {
  padding-top: 4px;
  border-bottom: 0px;
  margin-bottom: 10px;
  transform: translateY(-10px);
}

.reciept-row.payable-amount,
.reciept-row.receipt-total {
  /* border-bottom: 2px solid var(--c-dark-4); */
  /* padding-bottom: 20px; */
  /* margin-bottom: 10px; */
}

.reciept_details {
  border: 1px solid rgba(167, 181, 226, 0.25);
  border-radius: 8px;
  padding: 8px 16px;
}

.reciept-row-top .reciept-row-right p {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: right;
  color: #FFFFFF;
}

.reciept-row.reciept-row-coupon button.btn-link {
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-s-s);
  line-height: 1.2;
  color: #E2A052;
  text-decoration: underline;
}

.reciept-row.reciept-row-coupon>.row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.reciept-row.reciept-row-payment-method .value button {
  background: #434B6B;
  border-radius: 6px;
  padding: 9px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.full-plan-comparison a {
  position: relative;
}

.full-plan-comparison {
  margin-bottom: 80px;
  max-width: 100%;
  max-width: 960px;
  margin: 0 auto;
  margin-bottom: 120px;
}

.full-plan-comparison img {
  position: absolute;
  right: -5px;
  transform: rotate(90deg);
}

.custom-plan {
  background: var(--c-dark-2);
  border-radius: 8px;
  width: 100%;
  max-width: 588px;
  margin: 0 auto;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin-bottom: 10px;
}

.c-plan-left h4 {
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-s-xxl);
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 4px;
}

.c-plan-left p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--c-dark-8);
  padding-right: 20px;
}

.package-characteristics button {
  background: transparent;
  padding: 0px;
  outline: none;
  color: var(--c-dark-5);
  border: 0px;
  position: absolute;
  top: 5px;
  left: 0px;
}

.package-characteristics button:hover {
  background: transparent;
  outline: none;
}

.c-plan-right>.btn {
  padding: 0px 14px;
}

.c-plan-right>.btn>i {
  margin-right: 8px;
}

.c-plan-right {
  min-width: 124px;
}

.value.currency.reciept-row-right .dropdown-item {
  padding: 0px;
}

.reciept-row-coupon .reciept-row-right {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.reciept-row.reciept-row-payment-method {
  align-items: center;
}

.reciept-row-coupon .reciept-row-right input {
  display: inline-block;
  max-width: 200px;
  width: 100%;
  background: #FFFFFF !important;
  border-radius: 8px;
  height: 40px;
  color: #000;
  padding: 0px 10px !important;
  line-height: 40px !important;
}

.reciept-row-coupon .reciept-row-right button {
  background: #0062FF;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 17px;
  height: 40px;
  padding: 0px 32px;
}

.coupon-status {
  width: 100%;
}

.reciept-row-right .coupon-status>p {
  font-size: 14px;
}

@media only screen and (max-width: 1240px) {
  .package-card-wrapper {
    flex-wrap: wrap;
  }

  .package-card {
    margin-bottom: 49px;
  }
}

@media only screen and (max-width: 600px) {
  .custom-plan {
    flex-direction: column;
    justify-content: center;
  }

  .c-plan-left {
    text-align: center;
    margin-bottom: 15px;
  }

  .form {
    max-width: 100%;
    overflow: hidden;
  }

  .n-feature-multistream {
    flex-wrap: wrap;
  }

  .n-feature-wrapper {
    flex-wrap: wrap;
  }

  .n-feature-single {
    width: 100%;
    margin-bottom: 20px;
  }

  .package-card {
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 20px;
  }

  .form.container ul.nav li a {
    font-size: 14px;
    padding: 10px 23px;
  }

  .subscription-reciept {
    padding: 15px;
  }

  .receipt-wrapper {
    width: 100%;
  }

  .reciept-action-button {
    max-width: 300px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .reciept-row.reciept-row-top .reciept-row-left {
    max-width: 50%;
  }

  .n-feature-single>p>span {
    display: inline-block;
  }

  .text-center.upgrade-page-header {
    padding: 5px 0px 25px 0px;
  }

  .coupon-status {
    width: 100%;
  }

  .reciept-row-right .coupon-status>p {
    font-size: 14px;
  }

  .reciept-row.reciept-row-coupon {
    flex-direction: column;
  }

  .reciept-row-right .coupon-status>p {
    text-align: center;
  }

  .all.in.one .yearly-plans p.package-name {
    max-width: 100%;
    min-height: unset;
  }
}
</style>

<style>
.upgrade-page .form.container ul.nav {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  background-color: #343E61;
  padding: 3px 3px 4px 3px;
  border-radius: 6px;
}

.upgrade-page .form.container ul.nav li a {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #fff;
  background: transparent;
  outline: 0px;
  border: 0px;
  border-radius: 4px;
  text-transform: capitalize;
  padding: 4px 14px;
}

.upgrade-page .form.container ul.nav li a.active {
  background: #4F5C87;
  font-weight: 500;
}

.upgrade-page .form.container ul.nav li a:hover {
  outline: 0px;
}

.upgrade-page .value.reciept-row-right button {
  font-family: "Fira Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-s-s);
  line-height: 1;
  border-radius: 6px;
  padding: 6px 8px;
  border: 0px;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.06), 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.12), inset 0px 0.5px 0px rgba(255, 255, 255, 0.08);
}

.ch-popover-btn .tooltip-inner {
  background-color: #343E61;
  font-size: 14px;
  opacity: 1;
  color: #fff;
}

.upgrade-page .value.reciept-row-right button span {
  text-transform: capitalize;
}

.upgrade-page .value.reciept-row-right.has-dropdown .btn-group.dropdown button {
  background: #fff;
  border: 1px solid rgba(167, 181, 226, 0.25);
  box-shadow: unset;
}

.upgrade-page .value.reciept-row-right .btn-group button+ul {
  background: #fff;
  width: 180px;
  left: unset !important;
  right: 0;
  top: 8px !important;
  border-radius: 6px;
}

.upgrade-page .value.reciept-row-right .btn-group button+ul li a span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  font-family: "Fira Sans", sans-serif;
}

.upgrade-page .package-dropdown .dropdown-menu .dropdown-item:hover,
.package-dropdown-item:hover,
.package-dropdown-item.selected {
  background-color: #F2F3F4 !important;
}

.upgrade-page .value .a.package-dropdown-item {
  padding: 9.5px 24px;
}

.upgrade-page .value.currency.reciept-row-right .dropdown-item {
  padding: 0;
}

.upgrade-page .package-dropdown-item:hover {
  background: transparent !important;
}

.subscription_packages .vue-js-switch .v-switch-core,
.vue-js-switch .v-switch-core {
  transform: rotate(180deg);
}

.castr-check-enabled,
.castr-circle-disabled {
  max-width: 21px;
}

.package-card .package-heading {
  background-color: var(--c-light-1);
  padding: 16px 16px;
  border-bottom: 1px solid var(--c-light-1);
  border-radius: 0px;
  /* min-height: 182px; */
}

.package-heading {
  min-height: 201px;
}

.yearly-plans .package-heading {
  min-height: unset;
}

.package-heading .package-info {
  padding: 0px 8px;
  padding-top: 22px;
}

.renew {
  display: block;
  color: #0062FF;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  cursor: pointer;
  margin-top: 8px;
}

.package-heading.no-background {
  background-color: var(--c-dark-5);
  /* border-radius: 8px; */
  border-bottom-color: transparent;
}

.package-heading.no-background+.package-characteristics {
  display: none;
}

.no-background .package-btn {
  /* border-color:0 !important; */
  color: var(--c-dark-1);
  font-size: var(--font-s-ss);
}

.reciept-row-left.w-100 .coupon-form input {
  background: rgba(167, 181, 226, 0.25);
  border-radius: 6px;
  border: 1.5px solid rgba(167, 181, 226, 0.25);
  display: inline-block;
  width: 100%;
  max-width: 185px;
  padding: .5px 9px;
  margin: 0px;
  color: #fff;
}

.reciept-row-left.w-100 .coupon-code-error.coupon-form input {
  border-color: var(--c-danger-1);
}

.reciept-row-left.w-100 .coupon-form input:focus {
  border-color: #0062FF;
}

.reciept-row-left.w-100 .coupon-form input:focus-visible {
  border-color: #0062FF;
  outline: 0px;
}

.reciept-row-left.w-100 .coupon-form button {
  background: rgba(173, 184, 220, 0.5);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02), inset 0px 0.5px 0px rgba(255, 255, 255, 0.04);
  border-radius: 6px;
  border-color: rgba(173, 184, 220, 0.5);
  color: #8794B5;
  font-size: var(--font-s-s);
  font-weight: 500;
}

.reciept-row-left.w-100 .coupon-form input:focus+button {
  background: #0062FF;
  border-color: #0062FF;
  color: #fff;
}

.all.in.one .monthly-plans .package-characteristics li:nth-child(4) {
  display: none;
}

.all.in.one .yearly-plans .package-characteristics li:nth-child(3) {
  display: none;
}

.package-heading,
.package-characteristics {
  border: 2px solid transparent;
}

.package-heading.selected {
  border-color: var(--c-main-light);
  border-bottom: 0px;
}

.package-characteristics.selected {
  border-color: var(--c-main-light);
  border-top: 0px;
  padding-bottom: 24px;
}

.n-feature-single>p.text-dark-7 {
  color: var(--c-dark-7);
}

.text_yellow {
  color: var(--c-yellow);
}

.white-bg {
  background: #fff;
}

.pt-cell-inner button:hover {
  background: #fff;
  border: 0px;
}

.pt-cell-inner {
  padding: 8px 12px;
}

.tt-pricing-container-inner tr {
  border-bottom: 1px solid var(--c-dark-9);
}

.tt-pricing-container-inner table {
  border-top: 1px solid var(--c-dark-9);
  border-bottom: 1px solid var(--c-dark-9);
}

.tt-pricing-container-inner td,
.tt-pricing-container-inner th {
  border-right: 1px solid var(--c-dark-9);
}

.tt-pricing-container-inner th {
  padding: 16px 12px;
  vertical-align: top;
}

.cell-defination-tr td,
.cell-defination-tr th {
  border: 0;
}

.tt-pricing-container-inner {
  background: #fff;
  width: 100%;
  display: flex;
  padding: 80px;
}

.tt-pricing-table tr:nth-child(odd) {
  background: var(--c-light-2);
}

.pt-plan-bordered-btn a {
  background: #0062FF;
  border-radius: 8px;
  padding: 10px 6px;
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.pt-plan-free a {
  color: #0062FF;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.tt-pricing-table tr.cell-defination-tr~tr:nth-child(odd) {
  background: #fff;
}

.tt-pricing-table tr.cell-defination-tr~tr:nth-child(even) {
  background: var(--c-light-2);
}

.tt-pricing-table {
  table-layout: fixed;
}

.tt-pricing-container-inner th,
.tt-pricing-container-inner td {
  width: calc(76.5%/5);
  border-left: 1px solid var(--c-dark-9);
}

.tt-pricing-container-inner th:first-child,
.tt-pricing-container-inner td:first-child {
  width: 23.5%;
  border-left: 1px solid var(--c-dark-9);
}

.tt-pricing-container-inner th:last-child,
.tt-pricing-container-inner td:last-child {
  border-right: 1px solid var(--c-dark-9);
}

.cell-defination-tr td {
  border: 0;
}

.yearly-switch.align-items-start>label {
  margin-left: 0;
  margin-right: 16px;
}

.pt-price-yearly {
  display: flex;
}

.pt-custom-plan-wrapper {
  display: none;
}


.pt-custom-plan-btn {
  width: 242px;
  transform: rotate(90deg);
  transform-origin: left;
  position: absolute;
  top: -72px;
  left: 28px;
  padding: 8px 12px;
  background: linear-gradient(90deg, #82C3FF, #E8FAFB);
  cursor: pointer;
  z-index: 999;
  border-radius: 8px;
}


.pricing-right {
  position: relative;
}

.tt-custom-plan-btn-area {
  display: flex;
  justify-content: space-between;
}

.tt-custom-plan-btn-area>span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -1px;
  color: #000000;
}

.pt-custom-plan-wrapper {
  background: #fff;
  position: absolute;
  top: -52px;
  right: -28px;
  width: 240px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08), 0px 32px 40px rgba(0, 0, 0, 0.12);
  /* padding: 20px; */
  overflow: hidden;
}

.tt-custom-plan-top h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: -1px;

  /* Grey / 3 */
  color: #3A3B3C;
  text-align: left;
}

.tt-custom-plan-top h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 46px;
  /* identical to box height, or 144% */


  /* Grey / 1 */
  color: #000000;
  text-align: left;
  margin-bottom: 24px;
}

h2 {}

.pt-plan-bordered-btn {
  margin-bottom: 8px;
}

.pt-plan-bordered-btn.pt-whatsapp-btn a {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #24CC63;
  background: transparent;
  border: 2px solid #24CC63;
}

.pt-plan-bordered-btn.pt-whatsapp-btn a img {
  margin-right: 10px;
  width: 20px;
}

.pt-plan-bordered-btn.pt-btn-bg>a {
  border: 2px solid #0062FF;
}

.tt-sticky.pt-custom-plan-wrapper>div {
  padding: 20px;
  border-bottom: 1px solid #D1DBF0;
}

.tt-custom-plan-top:after {
  background: #82C3FF;
  filter: blur(70px);
  z-index: 5;
  content: "";
  position: absolute;
  top: -27px;
  right: -52px;
  width: 200px;
  height: 200px;
  z-index: -1;
}

.tt-custom-plan-top:before {
  background: #E8FAFB;
  filter: blur(70px);
  z-index: 5;
  content: "";
  position: absolute;
  top: -27px;
  left: -52px;
  width: 200px;
  height: 200px;
  z-index: -1;
}

.tt-custom-plan-top {
  position: relative;
  z-index: 0;
}

.tt-plan-supports li {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */


  /* Grey / 3 */
  color: #3A3B3C;
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.tt-plan-supports ul {
  padding: 0;
}

.tt-plan-supports li img {
  margin-right: 12px;
  transform: translateY(5px);
}

p.tt-plan-support-text {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */


  /* Grayscale/05 */
  color: #4F5C87;
  margin-bottom: 16px;
  margin-top: 4px;
}

.tt-pricing-container-inner .pt-custom-plan-btn {
  top: -20px;
}

.tt-pricing-container-inner .pt-custom-plan-wrapper {
  top: 0;
}

.pt-price-monthly {
  display: flex;
  align-items: center;
  justify-content: center;
}

p.back_btn {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #8794B5;
  cursor: pointer;
  position: absolute;
  left: 0;
  bottom: 12px;
  margin: 0;
}

.subscription-reciept.white-bg {
  background: #fff;
}

.text-center.reciept-action-area.white-bg {
  background: #fff;
}

.rr-right {
  padding: 22px 44px 14px 54px;
  width: 72%;
}

.reciept-package-details {
  width: 28%;
}

.reciept-package-details .package-card {
  border: 0px;
}

.reciept-package-details {
  border: 1px solid rgba(167, 181, 226, 0.25);
  border-radius: 8px;
}

.reciept-package-details .package-characteristics li {
  color: #343E61;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */
  letter-spacing: 0.01em;
  margin-bottom: 8px;
}

.popular_tag {
  background: #0062FF;
  border-radius: 4px;
  padding: 0px 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  /* identical to box height, or 200% */
  letter-spacing: 1px;
  color: #fff;
  display: inline-block;
  margin-left: 6px;
}

.package-info .popular_tag {
  position: absolute;
  left: -6px;
  top: 0;
}

.logo-section {
  padding-top: 60px;
  padding-bottom: 40px;
}

th.pt-allinone-entry-col.tt-sticky.position-relative .popular_tag {
  position: absolute;
  top: -29px;
  left: -6px;
  letter-spacing: 1px;
  width: 100%;
  padding: 4px;
  border-radius: 6px 6px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
}

.subscription-reciept .package-heading {
  min-height: unset;
}

.package-card:not(:first-child) .package-characteristics li:last-child,
.package-card:nth-child(5) .package-characteristics li:nth-child(13),
.package-card:nth-child(5) .package-characteristics li:nth-child(12) {
  color: #0062FF;
  font-weight: 500;
}

.act_plan {
  background: #ADB8DC;
  color: var(--c-dark-7);
}

.cplan:hover {
  color: var(--c-dark-1);
}

.reciept-row-left.w-100 .coupon-form input {
  color: #000;
}

.reciept-row-left.w-100 .coupon-form input::placeholder {
  font-size: 15px;
  color: #68759C;
  font-weight: 400;
}

p.back_btn {
  max-width: 796px;
  width: 100%;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
}

.res_btn {
  display: none;
}

.pt-price-y>span {
  margin-left: 3px;
}

div#reciept-wrapper .package-info .popular_tag {
  left: unset;
}

tr.cell-defination-tr {
  background: #fff !important;
}

@media only screen and (max-width: 768px) {
  .package-card {
    margin-right: 0px !important;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    max-width: 1300px;
  }
}

@media only screen and (max-width: 600px) {

  .upgrade-page .form.container ul.nav {
    margin-top: 0px;
    width: 100%;
  }

  .upgrade-page .form.container ul.nav li {
    width: 50%;
  }

  .upgrade-page .form.container ul.nav li a {
    padding: 12px 6px;
  }

  .package-card {
    margin-right: 0px !important;
    padding: 0px !important;
  }

  .yearly-switch>p:last-child {
    text-align: left;
  }

  .subscription_packages .yearly-switch {
    max-width: 310px;
    margin: 20px auto;
    align-items: flex-start;
  }

  .tt-pricing-table {
    display: none;
  }

  .mobile-pricing.d-none {
    display: block !important;
  }

  .pricing-right {
    display: none;
  }

  table.tt-pricing-table.mb-pricing-table,
  table.tt-pricing-table.mb-pricing-table tr,
  table.tt-pricing-table.mb-pricing-table td,
  table.tt-pricing-table.mb-pricing-table th,
  table.tt-pricing-table.mb-pricing-table tbody,
  table.tt-pricing-table.mb-pricing-table thead {
    display: block;
    border-left: 0px;
    border-right: 0px;
  }

  table.tt-pricing-table.mb-pricing-table tbody,
  table.tt-pricing-table.mb-pricing-table thead {
    max-width: 320px;
    margin: 0 auto;
    width: 100%;
  }

  table.tt-pricing-table.mb-pricing-table {
    max-width: 320px;
    margin: 0 auto;
    margin-bottom: 30px;
    border: 1px solid #D1DBF0;
    border-radius: 8px;
    overflow: hidden;
  }

  table.tt-pricing-table.mb-pricing-table th {
    max-width: 320px;
    margin: 0 auto;
    width: 100%;
  }

  table.tt-pricing-table.mb-pricing-table td:last-child {
    width: 24%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  table.tt-pricing-table.mb-pricing-table td:first-child {
    width: 80%;
    text-align: left;
  }


  table.tt-pricing-table.mb-pricing-table td:first-child img {
    display: none;
  }

  .mbt-tooltip {
    display: none;
  }

  table.tt-pricing-table.mb-pricing-table tr:not(:first-child) {
    display: flex;
    width: 100%;
    max-width: 100%;
  }

  .tt-pricing-container-inner {
    max-width: 100%;
    padding: 0px 10px;
    justify-content: center;
  }

  .mb-pt-area {
    width: 100%;
  }

  .pt-cell-inner.text-dark-1.text-small span {
    white-space: normal;
  }

  .subscription-reciept.white-bg {
    flex-direction: column;
  }

  .subscription-reciept.white-bg>div {
    width: 100%;
    padding: 0;
  }

  .pt-price-yearly {
    justify-content: center;
    align-items: center;
  }

  tr.cell-defination-tr.text-s-ss.text-dark-7.text-500.text-left {
    background: #fff;
  }

  td.pt-cell.pt-cell-defination {
    justify-content: flex-start !important;
  }

  .full-plan-comparison {
    margin-bottom: 50px;
  }

  .yearly-switch.top-bar-control,
  .package-card-wrapper {
    margin: 0 8px !important;
  }

  .full-plan-comparison {
    display: none;
  }

  .res_btn {
    text-align: center;
    border-top: 1px solid #E2E2E2;
    display: block;
  }

  .res_btn img {
    margin-left: 8px;
    transform: rotate(90deg);
  }

  table.tt-pricing-table.mb-pricing-table tr:nth-child(5)~tr {
    display: none;
  }

  .tt-pricing-table.mb-pricing-table tr:last-child {
    display: block !important;
    width: 100%;
  }

  td.pt-cell.vm_btn {
    display: block !important;
    width: 100%;
    border: 0px !important;
    padding: 10px 0 !important;
  }

  .vm_btn a.all_feature {
    border: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0062FF;
  }

  table.tt-pricing-table.mb-pricing-table tr:nth-child(5) {
    opacity: .2;
  }

  table.tt-pricing-table.mb-pricing-table tr:nth-child(4) {
    opacity: .5;
  }

  table.tt-pricing-table.mb-pricing-table.expand tr {
    display: flex !important;
    opacity: 1;
  }

  .tt-pricing-table.mb-pricing-table.expand tr:last-child {
    opacity: 0 !important;
  }

  a.all_feature {
    display: flex !important;
  }

  .bandwidth-calculator-inner {
    max-height: 100%;
    overflow: scroll;
  }

  td.pt-cell.vm_btn {
    width: 100% !important;
    background: #fff;
  }

  .package-card .package-heading {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .package-card:first-child .package-heading {
    border-radius: 0px;
  }

  .pt-cell-inner img {
    max-width: 18px;
  }
}

.alert.left.inline.alert-danger {
  display: none;
}

td>.pt-cell-inner>img {
  margin: auto
}
</style>
s