import _ from 'lodash'

const defaultState = () => ({
	token: null,
	isAuthenticated: false
});

const state = defaultState();

const mutations = {
	AUTH_SET_IS_AUTHENTICATED(state, isAuthenticated) {
		state.isAuthenticated = isAuthenticated;
	},
	AUTH_SET_TOKEN(state, token) {
		state.token = token;
	}
};

const actions = {
	setIsAuthenticated({ commit }, isAuthenticated) {
		commit('AUTH_SET_IS_AUTHENTICATED', isAuthenticated);
	},
	async login({ commit, dispatch }) {
		// if not logged in redirect to login-page
		const token = window.localStorage.getItem('token');
		if(!_.isEmpty(token)) {
			commit('AUTH_SET_TOKEN', token);
			commit('AUTH_SET_IS_AUTHENTICATED', true);
			await dispatch('User/loadUser', null, { root: true });
		}
		const { href } = window.location;
		if (!token && !/login|signin|signup|forgot-password|samford/gi.test(href))  {
			setTimeout(() => {
				console.log('auth redirect: vuex');
				window.location.href = process.env.VUE_APP_API_ENV === 'prod' ? '/signin' : '/app/login';
			});
			return;
		}
	},
	logout({ commit, dispatch }) {
		window.localStorage.removeItem('user');
		window.localStorage.removeItem('token');
		commit('AUTH_SET_TOKEN', false);
		commit('AUTH_SET_IS_AUTHENTICATED', false);
		dispatch('User/unloadUser', null, { root: true });

		// if current is not an AUTH page
		// const { href } = window.location;
		// if (!/login|signin|signup/gi.test(href)) {
		// 	setTimeout(() => {
		// 		window.location.href = '/signin';
		// 	}, 100);
		// }
	}
};

const getters = {
	isLoggedIn: state => !!state.token,
	authStatus: state => state.isAuthenticated,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
