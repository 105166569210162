import _ from 'lodash';
import RequestError from './RequestError';
import api from '@/api';

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

export default {
	StreamPulseFactory,
	getStreamMediaPulse,
	getPublishPlatformsPulse,
	getSessionsList,
	getSessionData,
};

const streamPulseEndpointsHash = {};

/**
 * @param {string} streamKey
 */
 async function getSessionsList(streamKey, daysOffset, metrics) {
	let pulseUri = 'https://analytics.castr.io' + `/api/session/${streamKey}?metrics=1`;
	pulseUri += `&day_offset=${daysOffset || 360}`;
	let mediaPulse = await makeRequest(pulseUri);
	return mediaPulse;
}

/**
 * @param {string} sessionId
 */
 async function getSessionData(sessionId, points = null, offset=0) {
	let pulseUri = 'https://analytics.castr.io' + `/api/metrics/session/${sessionId}?offset=${offset}`;
	if(points) pulseUri += `&last_points=${points}`
	let mediaPulse = await makeRequest(pulseUri);
	return mediaPulse;
}

/**
 * @param {string} streamId
 */
function getStreamMediaPulseEndpoint(streamKey) {
	const uri = `/streams/${streamKey}/pulseEndpoint`;
	return makeRequest(uri);
}

/**
 * @param {string} streamId
 */
async function getStreamMediaPulse(streamKey) {
	let isBackupKey = false;
	if (streamKey.includes('_backup')) {
		isBackupKey = true;
		streamKey = streamKey.replace('_backup', '');
	}

	let pulseUri = streamPulseEndpointsHash[streamKey];
	if (!pulseUri) {
		pulseUri = `https://stats.castr.io/pulse/${streamKey}`;
		if (pulseUri) {
			streamPulseEndpointsHash[streamKey] = pulseUri;
		}
	}

	if (isBackupKey) {
		pulseUri = `${pulseUri}_backup`
	}
	// pulseUri = 'https://stats.castr.io/pulse/schb9161a307c9b11eaae925dc2950a0e41'
	let mediaPulse = await makeRequest(pulseUri);
	// let proxyUri = `${process.env.VUE_APP_APP_API_BASE}/pulse/proxy?uri=${pulseUri}`
	// let mediaPulse = await makeRequest(proxyUri)

	if (pulseUri.indexOf('stats.castr.io') && mediaPulse && mediaPulse[0]) {
		const originPulse = mediaPulse;
		mediaPulse = buildPulseObject(originPulse[0].value);
		mediaPulse.name = originPulse[0].value.name;
		mediaPulse.hostId = originPulse[0].stream_id;
		mediaPulse.staticPrefix = originPulse[0].static_prefix || false;
		mediaPulse.isWowza = _.get(originPulse, ['0', 'wowza'], false);
	}

	return mediaPulse;
}

/**
 * @param {string} platformMetaIds
 */
function getPublishPlatformsPulse(platformMetaIds, regionId) {
	const uri = `${process.env.VUE_APP_APP_API_BASE}/pulse/pushtargets?region=${regionId}&pushIds=${platformMetaIds.join(',')}`;
	return makeRequest(uri);
}

function StreamPulseFactory(streamKey) {
	let pulseCB = false;

	/**
   * @type {WebSocket} */
	let socket;
	const url = `wss://stats.castr.io/stream/${streamKey}`;
	let connected = false;
	let pending = false;
	let refreshQueued = false;
	let killed = false;

	this.refresh = refresh;
	this.onpulse = onpulse;
	this.disconnect = disconnect;

	function connect() {
		socket = new WebSocket(url);
		pending = true;

		socket.onopen = function() {
			// console.log('stats instance connected for stream', streamKey)
			connected = true;
			pending = false;

			if (refreshQueued) {
				refreshQueued = false;
				refresh();
			}
		};

		socket.onclose = function() {
			connected = false;
			pending = false;
		};

		socket.onerror = function() {
			pending = false;
			// console.log('could not connect to stats instance for stream', streamKey, err)
		};

		socket.onmessage = (ev) => {
			if (!pulseCB) return;

			let pulse;
			try {
				pulse = JSON.parse(ev.data);
			} catch (e) { console.log('stat json parse error', e.message); }

			if (pulse) {
				if (pulse.stats) {
					pulse = buildPulseObject(pulse);
				} else if (pulse.success === false || pulse.description) {
					pulse = {
						alive: false,
						push_stats: {}
					};
				}
			}

			pulseCB(pulse);
		};
	}

	function onpulse(cb) {
		pulseCB = cb;
	}

	function refresh() {
		if (killed) return;

		if (pending) {
			refreshQueued = true;
			return;
		}

		if (connected) {
			// console.log('sending pulse refresh event')
			socket.send('get_stats');
		} else {
			connect();
		}
	}

	function disconnect() {
		if (connected) {
			socket.close();
			killed = true;
		}
	}

	// begin initial conn
	connect();
}

/**
 * @param {object} streamStats
 */
function buildPulseObject(streamStats) {
	const pulse = { alive: false };

	const stats = streamStats.stats;
	if (stats) {
		pulse.alive = stats.alive;
		pulse.bitrate = stats.bitrate;
		pulse.push_stats = stats.push_stats;

		const mediaInfo = stats.media_info;
		if (mediaInfo &&
      mediaInfo.tracks &&
      mediaInfo.tracks.length) {
			let streamWidth;
			let streamHeight;
			const mediaCodecs = [];
            const mediaMeta = {}

			mediaInfo.tracks.forEach((mediaTrack) => {
				if (!mediaTrack) return;

				const trackType = mediaTrack.content;
				const codecItem = {
					type: trackType,
					codec: mediaTrack.codec
				};

				if (mediaTrack.fps && mediaTrack.content === 'video') {
					pulse.fps = mediaTrack.fps;
				}

                if (mediaTrack.content === 'video') {
                    mediaMeta.video = {
                        fps: mediaTrack.fps ?? 0,
                        bitrate: mediaTrack.bitrate ?? 0,
                        width: mediaTrack.pixel_width ?? 0,
                        height: mediaTrack.pixel_height ?? 0,
                        codec: mediaTrack.codec ?? 'n/a',
                    }
				}

                if (mediaTrack.content === 'audio') {
                    mediaMeta.audio = {
                        fps: mediaTrack.avg_fps ?? 0,
                        channels: mediaTrack.channels ?? 0,
                        bitrate: mediaTrack.bitrate ?? 0,
                        codec: mediaTrack.codec ?? 'n/a',
                    }
				}

				if (mediaTrack.avg_fps && mediaTrack.content === 'audio') {
					pulse.audioBitrate = mediaTrack.bitrate;
				}
				// mediaCodecs.push(codecItem.join('-'))
				mediaCodecs.push(codecItem);

				// check for stream width, height
				if (mediaTrack.content === 'video') {
					streamWidth = mediaTrack.pixel_width || mediaTrack.width || _.get(mediaTrack, ['params', 'pixel_width']);
					streamHeight = mediaTrack.pixel_height || mediaTrack.height || _.get(mediaTrack, ['params', 'pixel_height']);
				}
			});

            pulse.meta = mediaMeta;
			pulse.codecs = mediaCodecs;
			pulse.width = streamWidth;
			pulse.height = streamHeight;
		}
	}

	return pulse;
}

/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeRequest(reqConfig) {
	if (typeof reqConfig === 'string') {
		reqConfig = {
			path: reqConfig
		};
	}

	reqConfig.url = reqConfig.path;

	let res;
	try {
		res = await api.request(reqConfig);
	} catch (err) {
		const edata = _.get(err, 'response.data');
		throw new RequestError(edata);
	}

	return res && res.data;
}
