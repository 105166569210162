<template>
	<div>
		<div class="content-container">
			<div class="feature-item">
				<b-row align-v="center" class="align-items-center">
					<b-col cols="9" md="8">
						<h4 class="text-s-l font-weight-normal mb-0">Enable Password Protection</h4>
					</b-col>
					<b-col cols="3" md="4" class="text-right d-flex justify-content-end">
						<!-- <b-badge variant="dark-3" class="badge_beta align-self-center mr-2 d-inline-block">Coming soon</b-badge> -->
						<div id="embedPassword-disabled-tooltip">
							<b-form-checkbox
								@change="toggleFeature('embedPassword')"
								v-model="features.embedPassword.enabled"
								:disabled="featureProcessing.embedPassword || !allowSubSecondStreams"
								switch>
							</b-form-checkbox>
							<b-tooltip v-if="!allowSubSecondStreams" target="embedPassword-disabled-tooltip" triggers="hover focus">
								Contact Sales to enable it
							</b-tooltip>
						</div>
					</b-col>
				</b-row>
				<b-row v-if="features.embedPassword.enabled">
					<b-col>
						<b-form inline>
							<label for="">Password</label>
							<b-form-input 
								v-model="features.embedPassword.value"
								size="md"
								placeholder="Set Password"
								class="mx-0 mx-md-2 mb-2 mb-md-0"
							/>
							<b-button
							:disabled="!features.embedPassword.value"
							variant="dark-5"
							size="md"
							@click="setStreamPassword"
						>
							{{ featureProcessing.embedPassword ? 'saving ..' : 'Save' }}
						</b-button>
						</b-form>
					</b-col>
				</b-row>
			</div>
			<div class="feature-item">
				<b-row align-v="center">
					<b-col cols="9" md="8">
						<h4 class="text-s-l font-weight-normal mb-0">Geo/Country Blocking</h4>
						<p class="m-0 text-s-s text-dark-7">Prevent (ban) viewers from specific country(ies).</p>
					</b-col>
					<b-col cols="3" md="4" class="text-right d-flex justify-content-end">
						<!-- <b-badge variant="dark-3" class="badge_beta align-self-center mr-2 d-inline-block">Coming soon</b-badge> -->
						<div id="countryBlock-disabled-tooltip">
							<b-form-checkbox
								v-model="features.countryBlock.enabled"
								:disabled="featureProcessing.countryBlock || !allowSubSecondStreams"
								@change="toggleFeature('countryBlock')"
								switch>
							</b-form-checkbox>
							<b-tooltip v-if="!allowSubSecondStreams" target="countryBlock-disabled-tooltip" triggers="hover focus">
								Contact Sales to enable it
							</b-tooltip>
						</div>
					</b-col>
				</b-row>
				<b-row class="toggle-hide" v-if="features.countryBlock.enabled">
				<!-- <b-row> -->
					<b-col>
						<div v-if="!countryListLoaded">
								<i class="fas fa-spinner fa-spin loader" /> working ..
							</div>
						<div class="mt-2" v-else inline>
							<multiselect
								v-model="countryBlockSelection"
								:options="countryList"
								:multiple="true"
								:taggable="true"
								tag-placeholder="Unsupported region"
								placeholder="Search for a country"
								label="name"
								track-by="code"
								class="col col-md-11 p-0 select_md mr-2 mb-2"
								@tag="updateGeoBlockSelection"
							/>
							<b-button
								:disabled="featureProcessing.countryBlock || !canSaveGeoBlocking"
								variant="dark-5"
								size="md"
								@click="saveGeoBlocking"
							>
								{{ featureProcessing.countryBlock ? 'saving ..' : 'Save' }}
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>

			<div class="feature-item">
				<b-row class="align-items-center">
					<b-col cols="9" md="8">
						<h4 class="text-s-l font-weight-normal mb-0">Geo/Country Whitelisting</h4>
						<p class="m-0 text-s-s text-dark-7">Allow viewers only from specific country(ies).</p>
					</b-col>
					<b-col cols="3" md="4" class="text-right d-flex justify-content-end">
						<!-- <b-badge variant="dark-3" class="badge_beta align-self-center mr-2 d-inline-block">Coming soon</b-badge> -->
						<div id="countryWhitelists-disabled-tooltip">
							<b-form-checkbox
								v-model="features.countryWhitelists.enabled"
								:disabled="featureProcessing.countryWhitelists || !allowSubSecondStreams"
								@change="toggleFeature('countryWhitelists')"
								switch>
							</b-form-checkbox>
							<b-tooltip v-if="!allowSubSecondStreams" target="countryWhitelists-disabled-tooltip" triggers="hover focus">
								Contact Sales to enable it
							</b-tooltip>
						</div>
					</b-col>
				</b-row>
				<b-row class="toggle-hide" v-if="features.countryWhitelists.enabled">
					<b-col>
						<div v-if="!countryListLoaded">
							<i class="fas fa-spinner fa-spin loader" /> working ..
						</div>
						<div class="mt-2" inline v-else>
							<multiselect
								v-model="countryWhitelistSelection"
								:options="countryList"
								:multiple="true"
								:taggable="true"
								tag-placeholder="Unsupported region"
								placeholder="Search for a country"
								label="name"
								track-by="code"
								class="col col-md-11 p-0 select_md mr-2 mb-2"
								@tag="updateGeoBlockSelection"
							/>

							<b-button
								:disabled="featureProcessing.countryWhitelists"
								@click="saveGeoWhitelisting"
								variant="dark-5"
								size="md"
							>
								{{ featureProcessing.countryWhitelists ? 'saving ..' : 'Save' }}
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>

			<div class="feature-item">
				<b-row class="align-items-center">
					<b-col cols="9" md="8">
						<h4 class="text-s-l font-weight-normal mb-0">Enable Domain Whitelisting</h4>
						<p class="m-0 text-s-s text-dark-7">Allow the embedded player to be watched only on specific domains.</p>
					</b-col>
					<b-col cols="3" md="4" class="text-right d-flex justify-content-end">
						<!-- <b-badge variant="dark-3" class="badge_beta align-self-center mr-2 d-inline-block">Coming soon</b-badge> -->
						<div id="domainWhitelists-disabled-tooltip">
							<b-form-checkbox
								v-model="features.domainWhitelists.enabled"
								:disabled="featureProcessing.domainWhitelists || !allowSubSecondStreams"
								@change="toggleFeature('domainWhitelists')"
								switch>
							</b-form-checkbox>
							<b-tooltip v-if="!allowSubSecondStreams" target="domainWhitelists-disabled-tooltip" triggers="hover focus">
								Contact Sales to enable it
							</b-tooltip>
						</div>
					</b-col>
				</b-row>
				<b-row class="toggle-hide" v-if="features.domainWhitelists.enabled">
					<b-col>
						<div v-if="!whitelistedDomainsLoaded">
							<i class="fa fa-spinner fa-spin" />
						</div>
						<div class="mt-2" v-else>
								<!-- v-model="features.domainWhitelists.value"  -->
								<b-form-textarea
									v-model="whitelistedDomains"
									class="mb-2"
									placeholder="one domain per line"
									autocomplete="off"
									autocorrect="off"
									autocapitalize="off"
									spellcheck="false"
									rows="4"
								/>
							<div>
								<b-button
									:disabled="!features.domainWhitelists.value"
									class="ml-0 mt-1"
									variant="dark-5"
									@click="saveWhitelistedDomains"
								>
									{{ featureProcessing.domainWhitelists ? 'saving ..' : 'Save' }}
								</b-button>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>
		<confirm-modal
			modal-id="feature-upgrade"
			ok-text="Upgrade Now"
			cancel-text="Later"
			@modal-confirm="navigateToPayments(stream.type)">
			<p class="mb-0 text-s-m"><a target="_blank" class="text-reset text-underline" href="/app/subscribe">Upgrade</a> to access this feature. Check <a  target="_blank" class="text-reset text-underline text-nowrap" href="https://castr.com/pricing">Pricing page</a> to know more about eligible plan.</p>
		</confirm-modal>
	</div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import StreamService from '@/services/StreamService';
import IntegrationService from '@/services/IntegrationService';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';

export default {
	name: 'SubSecondSecuritySettings',
	components: {
		ConfirmModal,
		Multiselect
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		}
	},
	data() {
		return {
			processing: true,
			processingMessage: null,
			streamId: null,
			streamMeta: null,
			countryBlockSelection: [],
			countryWhitelistSelection: [],
			countryList: [],
			countryListLoaded: false,
			whitelistedDomains: [],
			whitelistedDomainsLoaded: false,
			features: {
				embedPassword: {
					enabled: false,
					value: null,
					valueType: 'string'
				},
				countryBlock: {
					enabled: false,
					value: [],
					valueType: 'array'
				},
				countryWhitelists: {
					enabled: false,
					value: [],
					valueType: 'array'
				},
				domainWhitelists: {
					enabled: false,
					value: [],
					valueType: 'array'
				}
			},
			featureProcessing: {
				embedPassword: false,
				countryBlock: false,
				countryWhitelists: false,
				domainWhitelists: false
			}
		};
	},
	computed: {
		canSaveGeoBlocking: () => true,
		productsFeatures() {
			return this.$store.state.User.aggregatedDefinition;
		},
		allowSubSecondStreams() {
			return this.$store.state.User.allowSubSecondStreams
		}
	},
	async mounted() {
		localStorage.setItem('live-feature-security', 1);

		this.streamId = this.stream._id;

		const meta = await StreamService.getStreamMetadata(this.streamId);
		this.streamMeta = meta;

		_.forIn(meta, (value, key) => {
			if (value !== undefined && value !== null && key in this.features) {
				let enabled = value !== false;
				if (Array.isArray(value)) {
					enabled = value.length > 0;
				}

				this.features[key].enabled = enabled;
				if (value.constructor !== Boolean) {
					this.features[key].value = value;
				}
			}
		});

		// fetch country list
		this.countryList = await IntegrationService.getCountryList();
		this.countryListLoaded = true;

		this.countryBlockSelection = _.map(
			this.features.countryBlock.value,
			code => _.find(this.countryList, { code })
		);
		this.countryWhitelistSelection = _.map(
			this.features.countryWhitelists.value,
			code => _.find(this.countryList, { code })
		);

		this.whitelistedDomains = _.join(
			_.filter(this.features.domainWhitelists.value, item => item !== 'https://castr.com'),
			'\r\n',
		);
		this.whitelistedDomainsLoaded = true;

		// event tracking
		window.trackEvent(
			this.stream.name + ' - Live Stream Security Settings Page',
			this.stream
		);
	},
	methods: {
		navigateToPayments (category = 'vod') {
			// Redirect to correct live or vod package
			if (category !== 'live' && category !== 'vod') {
				category = 'vod'
			}
			if(category === 'vod'){
				category = 'live'
			}
			this.$router.push({ name: 'Payments', query: { category } });
		},
		updateGeoBlockSelection() {
			// console.log('countryNode', countryNode)
		},
		clipboardCopy(text) {
			try {
				if (text instanceof Function) text = text();

				this.$copyText(text);
				this.$notify({ group: 'info', text: 'Copied to clipboard' });
			} catch (e) {}
		},
		async toggleFeature(featureName) {
			if (this.featureProcessing[featureName]) return;

			const feature = this.features[featureName];
			if (!feature) return;

			let mappedSecurityFeatName = featureName;
			switch (featureName) {
				case 'embedPassword':
					mappedSecurityFeatName = 'securityFeaturesPasswordAuth';
					break;
				case 'countryBlock':
					mappedSecurityFeatName = 'securityFeaturesGeoBlock';
					break;
				case 'countryWhitelists':
					mappedSecurityFeatName = 'securityFeaturesGeoWhitelist';
					break;
				case 'domainWhitelists':
					mappedSecurityFeatName = 'securityFeaturesDomainWhitelist';
					break;
			}

			// Disable geo-blocking/whitelisting on those packages
			const data = _.filter(this.$store.state.User.subscriptions, (item) => item.category === 'live' && item.enabled)[0];
			const disabledGeoFeaturePackages = [
				'5c6e7405b408c1b41dd32f46', // 1TB Bandwidth
				'5c6e7405b408c1b41dd32f47', // 1TB Bandwidth - Yearly
				'5c6e7406b408c1b41dd32f48', // 2TB Bandwidth
				'5c6e7406b408c1b41dd32f49', // 2TB Bandwidth - Yearly
				'5ccd92f8152b7f25111c39cf', // Live 5GB Bandwidth - 7 Day Trial
				'6362114709950a6a642540c3', // New All in One - 7 Days Trial
				'6010063d03afd67aa9831cd4', // All-In-One Entry Monthly
				'6361ecd609950a6a642540b7', // New All-In-One Entry Monthly
				'6018dbb703afd67aa9831ce3', // All-In-One Entry Yearly
				'6361ecd609950a6a642540b8', // New All-In-One Entry Yearly
				'636dcc7ced167e4759926fd9', // BF22 - All in One - Entry Yearly
				'6018dc7403afd67aa9831ce4', // All-In-One Plus Monthly
				'6361ecd609950a6a642540b9', // New All-In-One Plus Monthly
				'602cc7a503afd67aa9831ced', // All-In-One Plus Yearly
				'6361ecd609950a6a642540ba', // New All-In-One Plus Yearly
				'602cc91703afd67aa9831cf2', // All-In-One Premium Monthly
				'6361ecd609950a6a642540bf', // New All-In-One Premium Monthly
				'602cc95903afd67aa9831cf3', // All-In-One Premium Yearly
				'6361ecd609950a6a642540c0', // New All-In-One Premium Yearly
				'6434ffbd40771d569f1e98e6', // Starter 2023
				'6435032f40771d569f1e98f2', // Starter Yearly 2023
				'6435001640771d569f1e98e8', // Standard 2023
				'6435035f40771d569f1e98f4', // Standard Yearly 2023
				'6435007440771d569f1e98ea', // Professional 2023
				'643503a640771d569f1e98f6', // Professional Yearly 2023
				]
				console.log(this.features.countryBlock.enabled);
			if ((this.stream.type === 'live' && data && disabledGeoFeaturePackages.indexOf(data.package._id) !== -1
				&& (['countryBlock'].indexOf(featureName) !== -1)
				&& this.features.countryBlock.enabled) || (this.stream.type === 'live' && data && disabledGeoFeaturePackages.indexOf(data.package._id) !== -1
				&& (['countryWhitelists'].indexOf(featureName) !== -1)
				&& !this.features.countryBlock.enabled)
			) {
				console.log(this.features.countryBlock.enabled);
				this.$root.$emit('bv::show::modal', 'feature-upgrade');
				setTimeout(() => {
					this.features[featureName].enabled = !this.features[featureName].enabled;
				}, 500);
				
				return;
			}

			const trialAndAIOEntryPackages = [
				'5ccd92f8152b7f25111c39cf', // Live 5GB Bandwidth - 7 Day Trial
				'6362114709950a6a642540c3', // New All in One - 7 Days Trial
				'6010063d03afd67aa9831cd4', // All-In-One Entry Monthly
				'6361ecd609950a6a642540b7', // New All-In-One Entry Monthly
				'6018dbb703afd67aa9831ce3', // All-In-One Entry Yearly
				'6361ecd609950a6a642540b8', // New All-In-One Entry Yearly
				'636dcc7ced167e4759926fd9', // BF22 - All in One - Entry Yearly
				'6434ffbd40771d569f1e98e6', // Starter 2023
				'6435032f40771d569f1e98f2', // Starter Yearly 2023
				'6435001640771d569f1e98e8', // Standard 2023
				'6435035f40771d569f1e98f4', // Standard Yearly 2023
			]
			if (this.stream.type === 'live' && data && trialAndAIOEntryPackages.indexOf(data.package._id) !== -1
				&& (['domainWhitelists'].indexOf(featureName) !== -1)
				&& !this.features.domainWhitelists.enabled
			) {
				this.$root.$emit('bv::show::modal', 'feature-upgrade');
				setTimeout(() => {
					this.features[featureName].enabled = !this.features[featureName].enabled;
				}, 500);
				
				return;
			}

			if (!this.productsFeatures || this.productsFeatures[this.stream.type][mappedSecurityFeatName]) {
				this.$root.$emit('bv::show::modal', 'feature-upgrade');
				setTimeout(() => {
					this.features[featureName].enabled = !this.features[featureName].enabled;
				}, 500);
				return;
			}

			let nstate = feature.enabled;
			feature.enabled = nstate;

			if (feature.valueType === 'bool' || !nstate) {
				if (feature.valueType === 'array' && !nstate) {
					nstate = [];
				}

				await this.saveSetting(featureName, nstate);
			}
		},
		async saveGeoBlocking() {
			const updatedSettings = this.countryBlockSelection;
			await this.saveSetting('countryBlock', _.map(updatedSettings, 'code'));
		},
		async saveGeoWhitelisting() {
			const updatedSettings = this.countryWhitelistSelection;
			await this.saveSetting(
				'countryWhitelists',
				_.map(updatedSettings, 'code')
			);
		},
		async setStreamPassword() {
			const password = this.features.embedPassword.value;
			if (!password) return;

			await this.saveSetting('embedPassword', password);
		},
		async saveWhitelistedDomains() {
			let domains = _.split(this.whitelistedDomains, '\n')
				.map(d => d.trim())
				.filter(Boolean);
			const castrExist = domains.find((item) => item === 'https://castr.com');
			if (_.isEmpty(castrExist)) {
				domains = [...domains, 'https://castr.com'];
			}
			if (!domains.length) return;

			await this.saveSetting('domainWhitelists', domains);
		},
		onMediaPulseChanged() {},
		async saveSetting(key, value) {
			this.featureProcessing[key] = true;
			try {
				await StreamService.saveStreamMetadata(
					this.streamId,
					key,
					value
				);

				// clear stream meta cache in embed player
				StreamService.clearStreamMetadataCache(this.streamId);
			} catch (e) {
				this.$notify({ group: 'error', text: 'could not save changes' });
			}
			this.featureProcessing[key] = false;
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.modal-open .toggle-hide {
 display: none;
}
</style>
