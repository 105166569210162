<template>
  <div>
    <div v-if="paywallVersion === 'v2'" class="">
      <b-row class="mt-5">
        <b-col md="10">
          <h2 class="text-s-xxl text-center mb-3 mt-0 text-dark-9">Checkout Paywall</h2>
          <p class="text-dark-7 text-s-m mb-4 text-center">
            By clicking the button below, you will be redirected to the paywall details. <br>
            <b-button class="mx-auto d-inline-block mt-3" variant="primary" size="md" to="/paywall/v2">
            Checkout Paywall
          </b-button>
          </p>
        </b-col>
      </b-row>
    </div>
    <Spinner v-else-if="loadingStatus || processing" text="Retrieving data..." classes="text-dark-8 mt-5" spinner-color="var(--c-dark-8)" spinner-size="15px" />
    <div v-else>
      <div v-if="!this.activeTeam || this.checkPaywallAccess()">
      <paywall-subscription-details v-if="paywallDetailsActive" :stream="stream" :streamPaywall="streamPaywall"
        @close="paywallDetailsActive = false" @price-changed="onStreamPaywallPriceChanged" />
     
        <section v-else>
          <div v-if="trialError">
            Paywall not active. Please
					<router-link :to="`/subscribe?category=live`">
						Upgrade
					</router-link> to extend your service.
          </div>
          <div v-else>
            <b-row v-if="stripeOauthLink">
              <b-col md="6" offset-md="3">
                <b-img class="d-block mx-auto mb-3 stripe-logo mt-5" height="36" width="86"
                  :src="require('@/assets/images/stripe.svg')"></b-img>
                <h2 class="text-center text-s-xl mb-3">Connect with Stripe</h2>
                <p class="text-center text-dark-6 mb-4">
                  Set up your payment method to allow your audiences to pay and
                  allow you to receive payment conveniently
                </p>
              </b-col>
            </b-row>
            <b-row v-if="stripeOauthLink">
              <b-col offset-md="3" md="6">
                <p v-if="stripeConnectProcessing" class="text-center color-white mb-1">
                  Connecting to Stirpe ...
                  <i class="fas fa-spinner fa-spin" />
                </p>
                <p v-if="stripeConnectLinkError" class="text-center text-danger mb-2 mt-2">
                  Stripe connect link error. Try again.
                </p>
                <a v-if="!stripeConnectProcessing" :href="stripeOauthLink" target="_blank">
                  <b-button class="mx-auto d-block" variant="primary" size="md" @click="onStripeConnectClick">
                    Connect to Stripe
                  </b-button>
                </a>

                <b-button v-else class="mx-auto d-block" variant="primary" size="md" @click="onStripeConnectCancelClick">
                  Cancel
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-5" v-if="!stripeOauthLink">
              <b-col md="10">
                <h2 class="text-s-xxl mb-3 mt-0">Paywall</h2>
                <p class="text-dark-8 text-s-m mb-4">
                  Set up your pricing to enable Paywall for this {{ stream.type === 'live' ? 'livestream' : 'VOD stream'}}. Your
                  audiences will have access to watch this {{ stream.type === 'live' ? 'livestream' : 'VOD stream' }} after
                  they
                  make payment.
                  <b-link target="_blank" class="text-reset text-underline"
                    href="https://docs.castr.com/en/collections/2958860-castr-paywall">Learn more</b-link>.
                </p>
              </b-col>
            </b-row>
            <b-row v-if="!stripeOauthLink || streamPaywall">
              <b-col>
                <b-form @submit.stop.prevent>
                  <b-row>
                    <b-col>
                      <b-form-group id="input-group-3" label="Payment Type" label-for="selectPayment"
                        label-class="label-md" class="mb-4">
                        <b-form-select :disabled="streamPaywall && Number(streamPaywall.price) === 0" v-model="selectedType" id="selectPayment" :options="paymentTypesFilter" size="md"
                          required></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="this.selectedType === 'payperview'">
                    <b-col cols="6">
                      <b-form-group id="input-group-3" label="Currency" label-for="Price" class="mb-4"
                        label-class="label-md">
                        <b-row>
                          <b-col class="" cols="">
                              <country-currency-dropdown :is-disabled="!!streamPaywall" @on-currency-selected="onCurrencySelected" :selectedCurrency="selectedCurrency" />
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>

                    <b-col cols="6">
                      <b-form-group id="input-group-3" label="Price" label-for="Price" class="mb-4"
                        label-class="label-md">
                        <b-row>
                          <b-col>
                            <b-form-input v-model="selectedPrice" id="price" size="md"
                              placeholder="Amount your audiences pay"></b-form-input>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>

                  </b-row>

                  <b-row v-if="this.selectedType === 'subscription'">
                    <b-col>
                      <b-form-group id="input-group-3" label="Price" label-for="Price" class="mb-4"
                        label-class="label-md">
                        <b-row>
                          <b-col cols="9">
                            <b-form-input v-model="selectedPrice" id="price" size="md"
                              placeholder="Amount your audiences pay"></b-form-input>
                          </b-col>
                          <b-col cols="3">
                              <country-currency-dropdown :is-disabled="!!streamPaywall" @on-currency-selected="onCurrencySelected" :selectedCurrency="selectedCurrency" />
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group id="input-group-3" label="Frequency" label-for="Frequency" class="mb-4"
                        label-class="label-md">
                        <b-row>
                          <b-col cols="12">
                            <b-form-select 
                              v-model="selectedRecurrence" 
                              id="selectPayment" 
                              :options="subPeriod" size="md"
                              required>
                            </b-form-select>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-alert v-if="
                    this.selectedType === 'collect_emails' &&
                    !this.streamPaywall
                  " show variant="secondary" class="text-dark-7 text-s-s d-flex p-2 mb-4">
                    <svg width="14" height="16" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                      class="mr-2 mt-0">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z"
                        fill="#D1DBF0"></path>
                    </svg>
                    <p class="m-0">
                      Allows you to collect users email without charging payments.
                    </p>
                  </b-alert>

                  <div v-if="streamPaywall">
                    <b-alert v-if="parseFloat(selectedPrice) === 0" show variant="secondary"
                      class="text-dark-7 text-s-s d-flex p-2 mb-4">
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="mr-2 mt-1">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z"
                          fill="#D1DBF0"></path>
                      </svg>
                      <p class="m-0">
                        Paywall not active. All users have free access to the
                        stream.
                      </p>
                    </b-alert>

                    <b-alert v-if="isInitialPriceChanged" show variant="secondary"
                      class="text-dark-7 text-s-s d-flex p-2 mb-4">
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="mr-2 mt-1">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z"
                          fill="#D1DBF0"></path>
                      </svg>
                      <p class="m-0">
                        All users who have already paid will still have access and
                        will not be asked to repay.
                      </p>
                    </b-alert>

                    <b-button :disabled="!initialValueChanged" size="md" variant="secondary"
                      @click="updateStreamPaywall">
                      Update
                    </b-button>

                    <b-button v-if="!isInitialPriceChanged" @click="paywallDetailsActive = true" class="ml-2" size="md"
                      variant="secondary">
                      View Details
                    </b-button>

                    <b-button v-else class="ml-2" type="submit" size="md" variant="outline-danger"
                      @click="onStreamPaywallChangesCancel">
                      Cancel
                    </b-button>
                  </div>
                  <div v-else>
                    <b-button type="submit" size="md" variant="primary" @click="onPaywallActivation">
                      Activate Paywall
                    </b-button>
                  </div>

                  <b-alert v-if="this.selectedType !== 'collect_emails'" 
                    show variant="secondary" class="mt-4 text-dark-7 text-s-s d-flex p-2">
                    <svg width="14" height="16" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                      class="mr-2 mt-0">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z"
                        fill="#D1DBF0"></path>
                    </svg>
                    <p class="m-0">
                      Are you looking for more currencies? Let us know and we will add it for you.
                    </p>
                  </b-alert>

                  <b-alert 
                    v-if="!streamPaywall && this.selectedType !== 'collect_emails'"
                    show variant="secondary" class="text-dark-7 text-s-s d-flex p-2 mt-4">
                    <svg width="14" height="16" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                      class="mr-2 mt-0">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z"
                        fill="#D1DBF0"></path>
                    </svg>
                    <p class="m-0">
                      Once the Paywall is activated with a certain currency, the currency cannot be changed.
                    </p>
                  </b-alert>

                </b-form>
              </b-col>
            </b-row>

            <div v-if="streamPaywall" class="mt-4">
              <hr class="mt-4">
              <h3 class="mt-4 text-500 text-s-xl">
                How to give paywall-protected stream to your audiences:
              </h3>

              <div class="mt-4">
                <label class="mb-2 d-flex align-items-center">Protected Player Page
                  <svg class="text-dark-7 ml-2" v-b-tooltip.hover
                    title="Your audiences can access this page to register, pay and watch your livestream." width="14"
                    height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM8 4C8 4.55228 7.55228 5 7 5C6.44772 5 6 4.55228 6 4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4ZM7.75 7.5C7.75 7.08579 7.41421 6.75 7 6.75C6.58579 6.75 6.25 7.08579 6.25 7.5V10.5C6.25 10.9142 6.58579 11.25 7 11.25C7.41421 11.25 7.75 10.9142 7.75 10.5V7.5Z"
                      fill="currentColor" />
                  </svg>
                </label>
                <b-input-group size="md" class="mb-3">
                  <b-input :value="streamPaywall._streamUrl" class="input" readonly
                    @click="clipboardCopy(streamPaywall._streamUrl)" />
                  <b-input-group-append>
                    <b-button class="p-1 mt-1" variant="icon" size="sm" @click="clipboardCopy(streamPaywall._streamUrl)">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </b-button>
                    <b-button variant="icon" class="p-1 mt-1" size="sm" :href="streamPaywall._streamUrl" target="_blank">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.5 4.5H7.3C5.61984 4.5 4.77976 4.5 4.13803 4.82698C3.57354 5.1146 3.1146 5.57354 2.82698 6.13803C2.5 6.77976 2.5 7.61984 2.5 9.3V12.7C2.5 14.3802 2.5 15.2202 2.82698 15.862C3.1146 16.4265 3.57354 16.8854 4.13803 17.173C4.77976 17.5 5.61984 17.5 7.3 17.5H10.7C12.3802 17.5 13.2202 17.5 13.862 17.173C14.4265 16.8854 14.8854 16.4265 15.173 15.862C15.5 15.2202 15.5 14.3802 15.5 12.7V11.5"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.5 9.5L17.5 2.5M17.5 2.5H12.5M17.5 2.5V7.5" stroke="currentColor" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>

            <div v-if="streamPaywall" class="mt-4">
              <hr class="mt-4">
              <h3 class="mt-4 text-500 text-s-xl">Generate temporary access link</h3>
              <p class="text-dark-6 text-s-s">
                Temporary access link will be expired based on your selection and
                require no sign up or login from users
              </p>

              <div class="mt-4">
                <div v-show="showTempLinkDatePicker">
                  <p class="text-dark-3 text-s-s mb-2">Expiration date</p>
                  <b-row>
                    <b-col>
                      <datetime v-model="tempLinkDateTime" :min-datetime="tempLinkMinDateTime"
                        :max-datetime="tempLinkMaxDateTime" type="datetime" input-class="form-control form-control-md"
                        placeholder="Select date time" class="castr-calendar" @focus="setRangesTempLinkDateTime" />
                    </b-col>
                  </b-row>
                </div>

                <div class="mt-3">
                  <b-button v-show="!showTempLinkDatePicker" type="submit" size="md" variant="primary"
                    @click="showTempLinkDatePicker = true">
                    Generate
                  </b-button>

                  <b-button v-show="showTempLinkDatePicker" :disabled="!tempLinkDateTime" type="submit" size="md"
                    variant="primary" @click="generateTempLink">
                    {{ tempLinkProcessing ? "Generating ..." : "Generate Link" }}
                  </b-button>

                  <b-button v-show="showTempLinkDatePicker" class="ml-2" type="submit" size="md" variant="outline-danger"
                    @click="
                      showTempLinkDatePicker = false;
                    tempLinkDateTime = null;
                                        ">
                    Cancel
                  </b-button>
                </div>

                <div v-for="(item, idx) in tempLinksFiltered" :key="idx" class="mt-4">
                  <div class="mb-4">
                    <p class="text-dark-9 text-s-s mb-2">
                      Expiration date -- {{ humanDate(item.expireAt) }}
                    </p>
                    <b-input-group size="md" class="">
                      <b-input :value="item.url" class="input" readonly @click="clipboardCopy(item.url)" />
                      <b-input-group-append>
                        <b-button class="p-1 mt-1" variant="icon" size="sm" @click="clipboardCopy(item.url)">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667"
                              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z"
                              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
    <div class="no-access mt-0" v-else>
      <div class="text-center pt-5 mt-5">
        <h4 class="text-dark-9 text-s-xl text-500 mb-1">You don’t have permission to access this.</h4>
        <p class="text-dark-6 text-s-m">Contact the team owner at <a class="text-dark-6 text-s-m text-underline"
            :href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</p>
      </div>
    </div>
    </div>

    <confirm-modal
			modal-id="activate-paywall"
			ok-text="Yes"
			cancel-text="No"
			@modal-confirm="activateStreamPaywall">
      <p class="mb0">Activating the <strong>{{this.stream.name || ''}}</strong> permanently fixes the currency for this content, though you can still change the amount. Are you sure you want to proceed with these settings?</p>
		</confirm-modal>

  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from 'vuex'
import Spinner from "@/components/ui/Spinner.vue"
import CountryCurrencyDropdown from "@/components/ui/CountryCurrencyDropdown.vue"
import PaywallService from "@/services/PaywallService"
import PaywallSubscriptionDetails from "@/components/ui/PaywallSubscriptionDetails"
import SubscriptionService from "@/services/SubscriptionService"
import StreamService from "@/services/StreamService"
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

export default {
  name: "StreamManagePaywall",
  components: {
    Spinner,
    PaywallSubscriptionDetails,
    CountryCurrencyDropdown,
    ConfirmModal
  },
  props: {
    stream: {
      type: Object,
      required: true,
      validator(v) {
        return Object.prototype.hasOwnProperty.call(v, "_id");
      },
    },
  },
  async mounted() {
    // if (!this.subscriptionPaywallFeature) {
    //   this.trialError = true
    //   return
    // }
    
   // this.$store.dispatch('Ui/setLoadingStatus', true);
   if (this.paywallVersion !== 'v2') {
    await this.setupPaywall();
   }
  },
  watch: {
    selectedPrice: function (val) {
      const newPrice = parseInt(val);
      if (newPrice <= 0) {
        this.selectedType = "collect_emails";
        return;
      }

      this.selectedType = this.selectedType;
    },
    selectedType: function (val) {
      if (val === "collect_emails") {
        this.selectedPrice = 0;
      }
      if (val === "payperview" || val === 'subscription') {
        this.selectedPrice = this.initialPrice > 0 ? this.initialPrice : 1;
      }
    },
  },
  data() {
    return {
      paywallVersion: 'v2',
      processing: false,
      trialError: false,

      stripeOauthLink: null,
      stripeConnectProcessing: false,
      stripeConnectStatusChecker: null,
      streamPaywall: null,

      paywallDetailsActive: false,
      stripeConnectLinkError: false,

      paymentTypes: [
        // {
        //   text: "Subscription",
        //   value: "subscription",
        // },
        {
          text: "Pay per view",
          value: "payperview",
        },
        {
          text: "Collect emails only",
          value: "collect_emails",
        },
      ],

      subPeriod: [
      {
          text: "Day",
          value: "day",
        },
        {
          text: "Week",
          value: "week",
        },
        {
          text: "Month",
          value: "month",
        },
        {
          text: "Year",
          value: "year",
        },
      ],
      initialSubPeriod: 'year',

      selectedRecurrence: "year",
      selectedType: "payperview",
      initialType: 'payperview',

      initialCurrency: "usd",
      selectedCurrency: "usd",

      initialPrice: 1,
      selectedPrice: 1,

      tempLinks: [],
      tempLinkDateTime: null,
      tempLinkMaxDateTime: null,
      tempLinkMinDateTime: null,
      showTempLinkDatePicker: false,
      tempLinkProcessing: false,
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "Ui/loadingStatus",
      baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions'
		}),
    subscriptionPaywallFeature() {
      // allow paywall for VOD if user has active and not trial all in one sub
      const subMeta = this.$store.state.User.subscriptionMeta.live
      if (subMeta.enabled && !subMeta.trial) {
        return true
      }
      
      return this.$store.state.User.aggregatedDefinition[this.stream.type].paywall
    },
    isInitialPriceChanged() {
      if (
        parseInt(this.selectedPrice) >= 0 &&
        parseInt(this.selectedPrice) !== parseInt(this.initialPrice)
      ) {
        return true;
      }
      return false;
    },
    initialValueChanged() {
      if (this.initialType !== this.selectedType) {
        return true
      }

      if (
        parseInt(this.selectedPrice) >= 0 &&
        parseInt(this.selectedPrice) !== parseInt(this.initialPrice)
      ) {
        return true;
      }

      if (this.initialCurrency.toLowerCase() !== this.selectedCurrency.toLowerCase()) {
        return true;
      }

      if (this.initialSubPeriod !== this.selectedRecurrence) {
        return true
      }

      return false
    },
    tempLinksFiltered() {
      const now = new Date().getTime();
      const filtered = _.filter(this.tempLinks, (item) => {
        return new Date(item.expireAt).getTime() > now;
      });

      return _.sortBy(filtered, function (d) {
        return new Date(d.expireAt);
      });
    },
    baseLiveSubscription() {
      const subs = this.$store.state.User.subscriptions.map((sub) =>
        _.assign({ category: "restream" }, sub)
      );
      return subs.find((sub) => sub.category && sub.category.includes("live"));
    },
    paymentTypesFilter() {
      const baseSub = this.baseLiveSubscription;

      const pwAccess = _.get(baseSub, [
        "package",
        "definition",
        "paywall:full-bundle",
      ]);
      if (pwAccess || pwAccess === undefined) {
        return this.paymentTypes;
      }

      return _.filter(this.paymentTypes, (i) => i.value === "collect_emails");
    },
    activeTeam() {
      return this.$store.state.User.activatedTeam;
    },
    memberPermission() {
      return this.activeTeam && this.$store.state.User.memberPermission;
    },
    userEmail() {
      return this.$store.state.User.email;
    },
    userloaded() {
      return this.$store.state.User.userloaded;
    },
  },
  methods: {
    async onPaywallActivation() {
      if (this.selectedType === 'collect_emails') {
        await this.activateStreamPaywall()
        return
      }

      this.$root.$emit('bv::show::modal', 'activate-paywall');
    },
    onCurrencySelected(curr) {
      this.selectedCurrency = curr.code.toLowerCase()
    },
    checkPaywallAccess() {
      return this.activeTeam && this.memberPermission && this.memberPermission.paywall;
    },
    async liveSubscriptionTrial() {
      const subs = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions};
      const addonSubs = subs.addonSubscriptions.map((sub) =>
        _.assign({ category: "restream" }, sub)
      );
      const baseSub = addonSubs.find(
        (sub) => sub.category && sub.category.includes("live")
      );
      return !baseSub.enabled;
    },
    async generateTempLink() {
      this.tempLinkProcessing = true;
      try {
        const expireAt = new Date(this.tempLinkDateTime).getTime();
        const expireMin = Math.round((expireAt - new Date().getTime()) / 60000);

        const resp = await PaywallService.createTemporaryLink(
          this.stream._id,
          expireAt,
          expireMin
        );
        this.tempLinks.push({
          url: resp.link,
          expireAt,
        });

        this.showTempLinkDatePicker = null;
        this.tempLinkDateTime = null;
        this.$notify({
          group: "success",
          text: "Temporary link generated",
        });
      } catch (err) {
        console.error("err", err);
        this.$notify({
          group: "error",
          text: err.message || "Generate temporary link failed",
        });
      }
      this.tempLinkProcessing = false;
    },
    setRangesTempLinkDateTime() {
      this.tempLinkMaxDateTime = new Date(
        Date.now() + 1 * 1000 * 60 * 60 * 24 * 10
      ).toISOString();
      this.tempLinkMinDateTime = new Date(
        Date.now() + 180 * 1000
      ).toISOString();
    },
    clipboardCopy(text) {
      try {
        if (text instanceof Function) text = text();

        this.$copyText(text);
        this.$notify({ group: "info", text: "Copied to clipboard" });
      } catch (e) { }
    },
    onStreamPaywallPriceChanged(price) {
      this.initialPrice = price;
      this.streamPaywall.price = price;
      this.selectedPrice = 0;
    },
    onStreamPaywallChangesCancel() {
      this.selectedPrice = this.initialPrice;
      this.selectedCurrency = this.initialCurrency;
    },
    async updateStreamPaywall() {
      try {
        const payload = {
          title: this.stream.name,
          recurring: this.selectedType === 'subscription' ? 1 : 0,
          currency: this.selectedCurrency,
          price: this.selectedPrice,
          period: this.selectedRecurrence,
        };

        await PaywallService.updateStreamPaywall(this.stream._id, payload);

        this.initialPrice = this.selectedPrice;
        this.initialCurrency = this.selectedCurrency;
        this.initialSubPeriod = this.selectedRecurrence
        this.initialType = this.selectedType
        this.streamPaywall.price = this.selectedPrice;
        this.streamPaywall.currency = this.selectedCurrency;

        this.$notify({
          group: "success",
          text: "Stream paywall udpated",
        });
      } catch (err) {
        this.$notify({
          group: "error",
          text: err.message || "Stream paywall update failed",
        });
      }
      return;
    },
    async activateStreamPaywall() {
      try {
        const payload = {
          url: `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${this.stream.key}?paywall=1`,
          title: this.stream.name,
          recurring: this.selectedType === 'subscription' ? 1 : 0,
          currency: this.selectedCurrency,
          price: this.selectedPrice,
          period: this.selectedRecurrence,
        };

        if (this.stream.type === 'vod') {
          payload.url = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/vod/playlist/${this.stream._id}?paywall=1`;
        }

        const pwObj = await PaywallService.activateStreamPaywall(
          this.stream._id,
          payload
        );
        this.selectPaywallSubscription(pwObj);

        this.$notify({
          group: "success",
          text: "Stream paywall activated",
        });
      } catch (err) {
        this.$notify({
          group: "error",
          text: err.message || "Stream paywall activation failed",
        });
      }
      return;
    },
    async setupPaywall() {
      // this.$store.dispatch('Ui/setLoadingStatus', true);
      this.processing = true;
      try {
        const pwMaster = await PaywallService.initPaywall();
        if (pwMaster.oauthLink) {
          // stripe not linked
          this.stripeOauthLink = pwMaster.oauthLink;
          this.processing = false;
          // this.$store.dispatch('Ui/setLoadingStatus', false);
          return;
        }

        // stripe attached update each time poster and title
        const streamMeta = await StreamService.getStreamMetadata(
          this.stream._id
        );
        if (streamMeta.embedPoster) {
          const poster = `https://assets.castr.io/embedImages/${streamMeta.embedPoster}`;
          const payload = { wallpaper_url: poster, title: this.stream.name };
          await PaywallService.updateStreamPaywall(this.stream._id, payload);
        }

        const pwStream = await PaywallService.getStreamPaywall(this.stream._id);
        const pwObj = { ...pwStream, master: pwMaster };
        this.selectPaywallSubscription(pwObj);

        const resp = await PaywallService.getTemporaryLinks(this.stream._id);
        this.tempLinks = resp.links;
      } catch (err) {
        if (err.toString().includes("stream not found")) {
          this.processing = false;
          // this.$store.dispatch('Ui/setLoadingStatus', false);
          return;
        }

        this.$notify({
          group: "error",
          text: err.message || "paywall initialization failed",
        });
      }
      this.processing = false;
      // this.$store.dispatch('Ui/setLoadingStatus', false);
    },
    selectPaywallSubscription(pwObj) {
      this.streamPaywall = pwObj;

      this.selectedCurrency = pwObj.currency || "usd";
      this.initialCurrency = pwObj.currency || "usd";
      this.selectedPrice = pwObj.price;
      this.initialPrice = pwObj.price;
      this.initialRecurring = pwObj.recurring;
      this.initialSubPeriod = pwObj.period

      this.selectedType =
        parseInt(pwObj.price) === 0 ? "collect_emails" : "payperview";

      if (pwObj.recurring === 1) {
        this.selectedType = 'subscription'
      }

      this.initialType = this.selectedType
    },
    onStripeConnectClick() {
      this.stripeConnectLinkError = false;
      this.stripeConnectProcessing = true;
      this.monitorStripeLinkingStatus();
    },
    monitorStripeLinkingStatus() {
      this.stripeConnectStatusChecker = setInterval(async () => {
        try {
          const resp = await PaywallService.stripeLinkingStatus();

          if (resp.status === "active") {
            // init stream
            this.stripeOauthLink = null;
            this.stripeConnectLinkError = false;
            clearInterval(this.stripeConnectStatusChecker);
          }

          if (resp.status === "failed") {
            setTimeout(async () => {
              await this.setupPaywall();
            }, 2000);

            this.stripeConnectProcessing = false;
            this.stripeConnectLinkError = true;
            clearInterval(this.stripeConnectStatusChecker);
          }
        } catch (e) {
          this.$notify({
            group: "error",
            text: err.message || "linking paywall failed",
          });
          clearInterval(this.stripeConnectStatusChecker);
        }
      }, 3000);
    },
    onStripeConnectCancelClick() {
      clearInterval(this.stripeConnectStatusChecker);
      this.stripeConnectProcessing = false;
    },
    humanDate(date) {
      return moment(new Date(date)).format("MMM DD, YYYY, [at] hh:mm A");
    },
  },
  beforeDestroy() {
    clearInterval(this.stripeConnectStatusChecker);
  },
};
</script>

<style scoped></style>
