<script setup>
import { computed, ref } from 'vue'
import Spinner from '../ui/Spinner.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'main',
  },
  size: {
    type: String,
    default: 'md',
  },
  isIcon: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: 'Upload File'
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  customClasses: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: 'file-upload'
  },
  name: {
    type: String,
  },
  onFileChange: Function
})

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (props.onFileChange) {
    props.onFileChange(file, props.name);
  }
}
const computedTypeClass = computed(() => {
  switch (props.type) {
    case 'main':
      return 'bg-main hover:bg-main-light text-surface-text-2 hover:text-white'
    case 'secondary':
      return props.isIcon ? 'text-surface-text-1 hover:text-white hover:text-white ring-1 ring-surface-4 hover:text-white hover:ring-surface-6' : 'text-surface-text-1 ring-1 ring-surface-4 bg-transparent hover:bg-surface-2 hover:text-white hover:ring-surface-6'
    case 'link':
      return 'text-surface-text-1 hover:text-white'
    case 'success':
      return 'success'
    case 'danger':
      return 'ring-1 ring-surface-4 hover:ring-error-light hover:text-error-light'
    default: return '!border-main bg-main hover:border-main-light bg:main-light'
  }
})

const computedSizeClass = computed(() => {
  switch (props.size) { 
    case 'lg': 
      return props.isIcon ? 'h-10 w-10 text-base' : 'h-10 text-base'
    case 'md':
      return props.isIcon ? 'h-8 w-8  text-tiny' : 'h-8 text-tiny'
    case 'sm':
      return props.isIcon ? 'h-7 w-7 text-tiny' : 'h-7 text-tiny'
    default: return props.isIcon ? 'h-6 w-6' : 'h-8'
  }
})

const computedClass = computed(() => {
  const baseClass = ['button !border-none form-button inline-flex items-center !rounded-md leading-none text-nowrap font-bolder box-border font-medium hover:no-underline disabled:bg-surface-5 disabled:ring-surface-5 disabled:text-surface-8', props.isIcon ? 'p-0 justify-center' : 'py-2 !px-3', (props.isIcon || props.type === 'link') ? 'border-none' : 'border']
  const result = []
  result.push(baseClass.join(' '), computedSizeClass.value, computedTypeClass.value)
  return props.customClasses ? props.customClasses : result.join(' ')
})
</script>

<template>
  <div :class="['form-upload', computedClass]">
    <label class="m-0" :for="id">{{ label }}</label>
    <input 
      :id="id"
      type="file" 
      :disabled="disabled" 
      @change="handleFileUpload" 
      class="hidden" 
    />
    <Spinner v-if="isLoading" />
  </div>
</template>


<style>
@layer tailwind {
  button[disabled].bg-main {
    @apply !border-surface-4 text-surface-6
  }
}
</style>
