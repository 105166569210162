<script setup>
import _ from 'lodash'
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import FormButton from '../Atoms/FormButton.vue'
import StreamService from '@/services/StreamService'
import FormSwitch from '../Atoms/FormSwitch.vue'

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const props = defineProps({
  vodFile: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  vodFolder: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  streamMeta: {
    type: Object,
  }
})

const socialSharingEnabled = ref(false)

const features = ref(
  {
    shareTwitter: {
      status: false,
    },
    shareFacebook: {
      status: true,
    },
    shareLinkedIn: {
      status: true,
    },
    shareReddit: {
      status: false,
    },
    shareEmail: {
      status: false,
    },
    shareWhatsApp: {
      status: false,
    },
  }
)

const featureProcessing = ref({
  shareFacebook: false,
  shareTwitter: false,
  shareLinkedIn: false,
  shareReddit: false,
  shareEmail: false,
  shareWhatsApp: false,
})

onMounted(async () => {
  getMeta()
})

watch(() => route.params, async () => {
  getMeta()
})

watch(() => props.streamMeta, async () => {
  getMeta()
}, { deep: true })

const getMeta = async () => {
  
  if (props.streamMeta.videoMeta) {
    socialSharingEnabled.value = props.streamMeta.videoMeta.socialSharingEnabled
    features.value.shareFacebook.status = props.streamMeta.videoMeta.shareFacebook === undefined ? true : props.streamMeta.videoMeta.shareFacebook
    features.value.shareTwitter.status = props.streamMeta.videoMeta.shareTwitter === undefined ? true : props.streamMeta.videoMeta.shareTwitter
    features.value.shareLinkedIn.status = props.streamMeta.videoMeta.shareLinkedIn
    features.value.shareReddit.status = props.streamMeta.videoMeta.shareReddit
    features.value.shareEmail.status = props.streamMeta.videoMeta.shareEmail
    features.value.shareWhatsApp.status = props.streamMeta.videoMeta.shareWhatsApp
  }
}

const onSocialSharingToggle = async () => {
  try {
    await saveSetting('socialSharingEnabled', !socialSharingEnabled.value, true, props.vodFile._id);
    socialSharingEnabled.value = !socialSharingEnabled.value
  } catch (e) {
    console.log(e);
  }
}

const saveSetting = async (key, value, successToast = false, videoId) => {
  featureProcessing.value[key] = true;
  try {
    await StreamService.saveStreamMetadata(route.params.streamId, key, value, videoId);
    // clear stream meta cache in embed player
    StreamService.clearStreamMetadataCache(route.params.streamId);
    if (successToast) {
      notify({ group: 'success', text: 'Setting saved' })
    }
  } catch (e) {
    notify({ group: 'error', text: 'could not save changes' });
  }
  featureProcessing.value[key] = false;
}
</script>

<template>
  <section>
    <h3 class="text-lg font-mediun mt-6 !mb-2">Social share</h3>
    <p class="text-surface-8 text-sm">Manage where viewers can share the videos.
    </p>
    <FormButton :type="socialSharingEnabled ? 'secondary' : 'main'" class="mt-2 mb-6"
      @click="onSocialSharingToggle">
      {{ socialSharingEnabled ? 'Disable sharing' : 'Enable sharing' }}
    </FormButton>
    <ul class="flex flex-wrap gap-x-6 gap-y-2">
      <li>
        <FormSwitch size="sm" v-model="features.shareFacebook.status" label="Facebook" @click="saveSetting('shareFacebook', !features.shareFacebook.status, true, props.vodFile._id)" />
      </li>
      <li>
        <FormSwitch size="sm" v-model="features.shareTwitter.status" label="X (Twitter)" @click="saveSetting('shareTwitter', !features.shareTwitter.status, true, props.vodFile._id)" />
      </li>
      <li>
        <FormSwitch size="sm" v-model="features.shareLinkedIn.status" label="LinkedIn" @click="saveSetting('shareLinkedIn', !features.shareLinkedIn.status, true, props.vodFile._id)" />
      </li>
      <li>
        <FormSwitch size="sm" v-model="features.shareReddit.status" label="Reddit" @click="saveSetting('shareReddit', !features.shareReddit.status, true, props.vodFile._id)" />
      </li>
      <li>
        <FormSwitch size="sm" v-model="features.shareEmail.status" label="Email" @click="saveSetting('shareEmail', !features.shareEmail.status, true, props.vodFile._id)" />
      </li>
      <li>
        <FormSwitch size="sm" v-model="features.shareWhatsApp.status" label="WhatsApp" @click="saveSetting('shareWhatsApp', !features.shareWhatsApp.status, true, props.vodFile._id)" />
      </li>
    </ul>
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
