<script setup>
import { ref, computed } from 'vue'
import _ from 'lodash';
// import moment from 'moment'
import { useNotification } from "@kyvg/vue3-notification";
import moment from 'moment-timezone';
// import Spinner from "@/components/ui/Spinner.vue"
// import StreamService from '@/services/StreamService'
import PlayoutService from '@/services/PlayoutService';
import IconBase from '@/components/icon/IconBase.vue'
import IconGlobe from '@/components/icon/IconGlobe.vue'
import IconChevronDown from '@/components/icon/IconChevronDown.vue'
import AddPlayoutErrorAlert from '@/components/TVPlayout/AddPlayoutErrorAlert.vue'
import { useStore } from 'vuex'
import FormButton from '../Atoms/FormButton.vue'
import FormInput from '../Atoms/FormInput.vue'
import IconCross from '../icon/IconCross.vue'
import { SidebarComponent as EjsSidebar } from '@syncfusion/ej2-vue-navigations'
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue'
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue'

const store = useStore()
const { notify } = useNotification()

const modalAddPlayoutSidebar = ref()

const error = ref(null)

const search = ref('')

const playoutModeDropdown = ref()
const playoutTimeZoneDropdown = ref()

const playout = ref({
	name: '',
	mode: null,
	region: null,
	timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
})

const emit = defineEmits(['playout-added'])

const closeModal = (() => {
	modalAddPlayoutSidebar.value.hide()
})
const toggleSidebar = () => {
	modalAddPlayoutSidebar.value.toggle();
}
const onReset = (() => {
	playout.value = {
		name: '',
		type: null,
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	}
})

const setPlayoutMode = ((mode) => {
	playout.value.mode = mode
	playoutModeDropdown.value.toggleDropdown()
})

const setPlayoutTimeZone = ((timeZone) => {
	playout.value.timeZone = timeZone
	playoutTimeZoneDropdown.value.toggleDropdown()
})

const timeZonesFiltered = computed(() => {
	const array = Intl.supportedValuesOf('timeZone').filter(element => element.toLowerCase().includes(search.value.toLowerCase()))
	const sortedArray = array.sort(function (a, b) {
		return moment().tz(a).utcOffset() / 60 - moment().tz(b).utcOffset() / 60
	})
	return sortedArray
})

const createPlayout = (async () => {
	try {
		store.dispatch('Ui/setLoadingStatus', true)
		const newPayout = await PlayoutService.addPlayout(
			playout.value.name,
			playout.value.mode,
			playout.value.timeZone,
		);
		emit('playout-added', newPayout)
		notify({ group: 'success', text: 'New TV Playout Added' })
		closeModal()
		store.dispatch('Ui/setLoadingStatus', false)
	} catch (err) {
		console.log(err);
		error.value = err.message
		store.dispatch('Ui/setLoadingStatus', false)
	}
})

defineExpose({ toggleSidebar })

</script>

<template>
	<Teleport to="body">
		<ejs-sidebar ref="modalAddPlayoutSidebar" :closeOnDocumentClick="true" :isOpen="false" type="Over" position="Right" :showBackdrop="true" target=".apimaincontent" id="modal-add-playout" class="bg-surface-2 h-screen !w-full md:!w-[420px] rounded-t-2xl md:rounded-t-none">
			<div class="modal-container relative z-30 bg-surface-2 rounded-t-2xl md:rounded-t-none">
				<div class="modal-container w-full md:pt-12">
					<div class="flex items-center justify-between px-3">
						<div class="mt-3 !hidden md:!block">
							<router-link :to="{ path: '/dashboard' }" tag="a" class="modal-logo relative">
								<img height="32px" class="logo-icon" src="@/assets/castr.svg" alt="Streaming Dashboard - Castr" />
								<div class="logo-icon-dot" />
							</router-link>
						</div>
						<div class="!mt-3 md:!mt-5 text-right absolute right-4 top-0 md:static rounded-full bg-surface-4 md:bg-transparent flex md:block items-center justify-center">
							<form-button type="link" isIcon @click="closeModal">
								<icon-base iconColor="none"
									class="">
									<icon-cross />
								</icon-base>
							</form-button>
						</div>
					</div>
					<div class="!mt-0 md:!mt-20 px-3">
						<h3 class="text-s-xxl text-500 bg-surface-3 md:bg-transparent !-mx-4 md:!mx-0 rounded-t-2xl md:rounded-t-none !p-4 md:!p-0">New TV Playout</h3>
						<p class="text-dark-7 mb-4 text-sm !mt-4 md:!mt-0">Create TV Playout and schedule your content.</p>
						<AddPlayoutErrorAlert :error="error" v-if="error" />
					</div>
					<div class="px-3 mb-3">
						<label class="text-dark-9 text-tiny">
							Playout name*
						</label>
						<FormInput id="someid" v-model="playout.name" placeholder="TV Playout name" />
					</div>
					<div class="px-3 mb-3">
						<label class="text-dark-9 text-s-ss">
							Type of Playout*
						</label>
						<drop-down-menu ref="playoutModeDropdown" type="secondary" customClasses="w-full text-sm h-8 bg-surface-3 hover:bg-surface-4 rounded-md flex items-center px-3 capitalize text-tiny">
							<template #toggle-button>
								{{ playout.mode ? playout.mode : 'Select an option' }}
								<icon-base
									class="ml-auto">
									<icon-chevron-down />
								</icon-base>
							</template>
							<template #menu-items>
								<drop-down-menu-item customClasses="max-w-full w-full h-auto flex flex-col text-wrap text-left items-start px-3 py-2 text-tiny hover:bg-surface-4" @click="setPlayoutMode('schedule')">
									<h5 class="font-medium">Schedule</h5>
									<p class="text-surface-8">Schedule contents to be published at a specific date and time.</p>
									<p class="text-surface-8 italic">Previously known as Daily and Weekly mode.</p>
								</drop-down-menu-item>
								<hr>
								<drop-down-menu-item customClasses="max-w-full w-full h-auto flex flex-col text-wrap text-left items-start px-3 py-2 text-tiny hover:bg-surface-4" @click="setPlayoutMode('loop')">
									<h5 class="font-medium">Loop</h5>
									<p class="text-surface-8">Set up content to loop over and over again. </p>
									<p class="text-surface-8 italic">Previously known as Line Up mode.</p>
								</drop-down-menu-item>
							</template>
						</drop-down-menu>
					</div>
					<div class="px-3 hidden">
						<label class="text-dark-9 text-s-ss">
							TimeZone*
						</label>
						<drop-down-menu disabled ref="playoutTimeZoneDropdown" type="secondary" fullWidth  maxHeight="[300px]" customClasses="w-full text-sm h-8 bg-surface-3 hover:bg-surface-4 rounded-md flex items-center px-3 capitalize text-tiny">
							<template #toggle-button>
								<icon-base width-view-box="16" height-view-box="16" iconColor="none" width="16" height="16"
									class="mr-2 flex-shrink-0">
									<icon-globe />
								</icon-base>
								{{ playout.timeZone }}
								<icon-base
									class="ml-auto">
									<icon-chevron-down />
								</icon-base>
							</template>
							<template #menu-items>
								<drop-down-menu-item customClasses="max-w-full w-full h-auto flex flex-col text-wrap text-left items-start px-3 py-2 text-tiny hover:bg-surface-4" v-for="(timeZone, index) in timeZonesFiltered" :key="index"
								@click="setPlayoutTimeZone(timeZone)">
									{{ timeZone }}
								</drop-down-menu-item>
							</template>
						</drop-down-menu>
					</div>
					<div class="px-3">
						<div class="text-center">
							<FormButton :disabled="playout.name.length === 0" @click="createPlayout" type="primary" size="md"
								class="w-full mt-4 justify-center">
								Create TV Playout
							</FormButton>
							<FormButton type="secondary" size="md" class="w-full mb-3 mb:md-0 mt-2 justify-center"
								@click="closeModal">
								Cancel
							</FormButton>
						</div>
					</div>
				</div>
			</div>
		</ejs-sidebar>
	</Teleport>
</template>

<style>
.timezone-list {
	@apply max-h-[300px] overflow-y-auto
}
</style>