<template>
  <div class="container page-title-area">
    <header class="mt-5">
      <div class="title d-flex justify-content-between align-items-center mb-4">
        <h1 class="page-title mb-0">Destination</h1>
        <div id="hasDestinationLimit-disabled-tooltip">
          <b-button v-if="hasDestinations && hasDestinationLimit > 0" :disabled="destinations.length >= hasDestinationLimit" v-b-toggle.add-destination-modal variant="primary" size="lg">
            <icon-base width="14" height="14" widthViewBox="14" heightViewBox="14" icon-name="plus"
              classIcon="mr-2"><icon-plus /></icon-base>
            New Destination
          </b-button>
          <b-tooltip v-if="destinations.length >= hasDestinationLimit" target="hasDestinationLimit-disabled-tooltip" triggers="hover focus">
            Contact Sales to add more destinations
          </b-tooltip>
        </div>
      </div>
    </header>
    <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)"
      spinner-size="15px" />
    <div v-else>
      <DestinationList v-if="destinations.length > 0 && hasDestinations" />
      <DestinationEmptyPlaceholder v-else />
    </div>
    <AddDestinationModal />
    <demo-video-modal modalId="modal_tutorial" videoid="kO9HgfAKelvf7YQ-KSSwQ" />
  </div>
</template>

<script>
import Spinner from "@/components/ui/Spinner.vue"
import { mapGetters } from 'vuex';
import IconBase from '@/components/icon/IconBase.vue'
import IconPlus from '@/components/icon/IconPlus.vue'
import DestinationEmptyPlaceholder from '@/components/Destination/DestinationEmptyPlaceholder.vue'
import DestinationList from '@/components/Destination/DestinationList.vue'
import IconDestinationEmptyPlaceholderImage from '@/components/icon/IconDestinationEmptyPlaceholderImage.vue'
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue'
import AddDestinationModal from "@/components/modals/AddDestinationModal.vue"

export default {
  name: "DestinationDashboard",
  components: {
    Spinner,
    AddDestinationModal,
    IconBase,
    IconPlus,
    IconDestinationEmptyPlaceholderImage,
    DestinationEmptyPlaceholder,
    DestinationList,
    DemoVideoModal
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "Ui/loadingStatus",
      destinations: "Destination/destinations",
      hasDestinations: "User/hasDestinations",
      hasDestinationLimit: "User/hasDestinationLimit",
    }),
  },
  watch: {
  },
  async mounted() {
    this.$store.dispatch('Ui/setLoadingStatus', true)
    await this.$store.dispatch('Streams/getStreams')
    await this.$store.dispatch('Destination/getDestinations')
    await this.$store.dispatch('Destination/getPlatforms')
    this.$store.dispatch('Ui/setLoadingStatus', false)
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
