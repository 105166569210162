<script setup>
import { computed, ref, onBeforeMount, onMounted, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import _ from 'lodash';
import StreamService from '@/services/StreamService';
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"
import AlertModal from '@/components/modals/AlertModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import StreamAbrSettingsModal from '@/components/modals/StreamAbrSettingsModal.vue';
import moment from 'moment';
import FormSwitch from '../../components/Atoms/FormSwitch.vue';
import FormButton from '../../components/Atoms/FormButton.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import IconBase from '../../components/icon/IconBase.vue';
import IconChevronUpDown from '../../components/icon/IconChevronUpDown.vue';
import IconHelp from '@/components/icon/IconHelp.vue';
import FormInput from '../../components/Atoms/FormInput.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	},
	streamAlive: {
		type: Boolean
	},
})

const rewindDropdown = ref(null)
const delayDropdown = ref(null)
const ottProcessing = ref(false)
const chatFlushProcessing = ref(null)
const varyingRewindProcessing = ref(false)
const isOtt = ref(false)
const streamMeta = ref(null)
const maxPosterUploadMB = ref(2)
const varyingRewindSelected = ref(null)
const varyingRewindOpts = ref([-1, 3600, 7200, 10800, 14400]) 		// -1 -- disabled rewind
const features = ref({
	ga: {
		enabled: false,
		value: null,
		valueType: 'string'
	},
	chatEnabled: {
		enabled: false,
		valueType: 'bool'
	},
	ottEnabled: {
		enabled: false,
		valueType: 'bool'
	},
	embedRewind: {
		enabled: false,
		valueType: 'bool'
	},
	embedAutoplay: {
		error: false,
		enabled: true,
		value: null,
		valueType: 'bool'
	},
	iframePostMessages: {
		enabled: false,
		valueType: 'bool'
	},
	hidePlayerSidebarAudioIcon: {
		enabled: false,
		valueType: 'bool'
	},
	delayingStream: {
		enabled: false,
		value: null,
		valueType: 'bool'
	},
	hlsCaptionSupport: {
		enabled: false,
		valueType: 'bool'
	},
})
const featureProcessing = ref({
	ga: false,
	abr: false,
	chatEnabled: false,
	ottEnabled: false,
	embedRewind: false,
	embedPoster: false,
	embedWatermark: false,
	embedWatermarkPosition: false,
	embedAutoplay: false,
	embedPassword: false,
	embedLiveViewers: false,
	iframePostMessages: false,
	streamCountdown: false,
	abrFps: false,
	delayingSream: false,
	hlsCaptionSupport: false
})

const streamMetadataLoaded = ref(false)
const streamAbrFps = ref(30)
const delayingStreamOpts = ref([3, 10, 15, 30, 60, 180, 300, 600, 900, 1800, 3600])

const hasVaryingRewind = computed(() => {
	return store.state.User.aggregatedDefinition.live.varyingRewind
})
const hasDelayingStream = computed(() => {
	return store.state.User.aggregatedDefinition.live.delayingStream ?? false
})
const enablePlayerEventsToggle = computed(() => {
	const playerEventPermissions = store.state.User.subscriptions
		.filter(sub => sub.category === 'live')
		.map(sub => sub.package.definition?.['settings:player-events'])
	// check if at least one of the live packages has permission to allow player events					
	return playerEventPermissions?.some(x => x)
})

onBeforeMount(async () => {
	streamMeta.value = await StreamService.getStreamMetadata(props.stream._id);

	_.forIn(streamMeta.value, (value, key) => {
		if (value !== undefined && value !== null && key in features.value) {
			let enabled = value !== false;
			if (Array.isArray(enabled)) {
				enabled = enabled.length;
			}

			features.value[key].enabled = enabled;
			if (value.constructor !== Boolean) {
				features.value[key].value = value;
			}
		}
	});

	if (streamMeta.value.delayingStreamSec) {
		features.value.delayingStream.value = streamMeta.value.delayingStreamSec
	}
	// TODO: move the player events enable/disable logic to backend
	// this is a temporary fix for handling cases where subscription had permission to enable player events, 
	// and iframePostMessages was set to true, but then the permission for the player events for the subscription was revoked
	// in that case the toggle should be disabled and toggled off(same in the player backend as well)
	// check if the player events are enabled but the subscription is not allowed to have this feature
	if (features.value.iframePostMessages.enabled && !enablePlayerEventsToggle.value) {
		// disable the player events
		toggleFeature('iframePostMessages', true)
	}
	if (streamMeta.value.varyingRewind && hasVaryingRewind.value) {
		varyingRewindSelected.value = streamMeta.value.varyingRewind
		features.value.embedRewind.enabled = (streamMeta.value.varyingRewind === -1) ? false : true
	}
	streamMetadataLoaded.value = true
})

onMounted(async () => {
	try {
		// update vuex state
		await store.dispatch('User/checkOtt', null, { root: true });
		isOtt.value = store.state.User.isOtt;
	} catch (e) {
		console.log(e);
		return;
	}
	// event tracking
	window.trackEvent(
		props.stream.name + ' - Stream Settings Page',
		props.stream
	);
})

const delayingStreamHumanTime = (sec) => {
	if (sec === 0) {
		return ''
	}
	if (sec === 3) {
		return '3 seconds'
	}
	if (sec === 10) {
		return '10 seconds'
	}
	if (sec === 15) {
		return '15 seconds'
	}
	if (sec === 30) {
		return '30 seconds'
	}
	return moment.duration(sec, 'seconds').humanize()
}

const onDelayingStreamSelect = async (sec) => {
	delayDropdown.value.toggleDropdown()
	await saveSetting('delayingStreamSec', Number(sec), true)
	features.value.delayingStream.value = sec
}

const onVaringRewindSelect = (rewindOpt) => {
	varyingRewindSelected.value = rewindOpt
	rewindDropdown.value.toggleDropdown()
}
const onVaringRewindSave = async () => {
	varyingRewindProcessing.value = true
	try {
		await StreamService.saveStreamMetadata(props.stream._id, 'varyingRewind', varyingRewindSelected.value);
		StreamService.clearStreamMetadataCache(props.stream._id);
		streamMeta.value.varyingRewind = varyingRewindSelected.value
		features.value.embedRewind.enabled = !features.value.embedRewind.enabled
		notify({ group: 'success', text: 'Setting saved' })
	} catch (e) {
		notify({ group: 'error', text: 'could not save changes' });
	}
	varyingRewindProcessing.value = false
}
const onAbrFpsChange = async (fps) => {
	await saveSetting('abrFps', Number(fps), true)
	streamAbrFps.value = Number(fps)
}

const requestOttunpublish = () => {
	ottProcessing.value = true
	if (!features.value.ottEnabled.enabled) {
		root.$emit('bv::show::modal', 'unpublish-ott');
	} else {
		toggleFeature('ottEnabled')
	}
}

const checkSubscriptionFeature = (featureName) => {
	const subs = store.state.User.subscriptions.map(sub => _.assign({ category: 'restream' }, sub));
	const baseSub = subs.find(sub => sub.category && sub.category.includes('live'));
	return _.get(baseSub, ['package', 'definition', featureName], false);
}

const checkSettingVisibility = (settingPropname) => {
	switch (settingPropname) {
		case 'embedAutoplay':
		case 'ga':
			return ['live', 'ipcam', 'vod'].indexOf(this.stream.type) > -1;
		case 'abr':
		case 'chatConfig':
		case 'iframePostMessages':
			return props.stream.type === 'live';
		case 'hlsCaptionSupport':
			return props.stream.type === 'live';
	}
	return false;
}

const navigateToBilling = () => {
	if ((props.stream.type === 'live') || props.stream.type === 'vod') {
		window.location.href = '/app/subscribe?category=live';
	} else {
		window.location.href = '/app/subscribe';
	}
}

const clearChatNew = async () => {
	if (features.value.chatEnabled.enabled) {
		notify({
			group: 'error',
			text: 'Please disable the chat before clearing chat history'
		});
		return;
	}

	chatFlushProcessing.value = true;

	try {
		await saveSetting('chatBoxId', null);
		await saveSetting('chatBoxKey', null);
	} catch (e) {
		return;
	}

	chatFlushProcessing.value = false;
	notify({
		group: 'success',
		text: 'chat messages have been cleared'
	});
}

const toggleOttcancel = () => {
	features.value['ottEnabled'].enabled = !features.value['ottEnabled'].enabled;
	ottProcessing.value = false
}

const toggleFeature = async (featureName, newFeature = false) => {
	if (featureProcessing.value[featureName]) return;
	const feature = features.value[featureName];
	if (!feature) return;
	// ignore if chat toggled
	if (featureName === 'chatEnabled' && !newFeature) {
		features.value.chatEnabled.enabled = !feature.enabled;
		return;
	}

	if (featureName === 'iframePostMessages' && !newFeature) {
		features.value.iframePostMessages.enabled = !feature.enabled;
		return;
	}

	if (featureName === 'embedRewind' && !checkSubscriptionFeature('settings:player-rewind') && features.value[featureName].enabled) {
		root.$emit('bv::show::modal', 'feature-upgrade');
		setTimeout(() => {
			features.value[featureName].enabled = !features.value[featureName].enabled;
		}, 500)
		return
	}
	let nstate = feature.enabled;
	feature.enabled = nstate;
	if ((featureName === 'ottEnabled') && !nstate) {
		feature.enabled = true;
	}
	if (feature.valueType === 'bool' || !nstate) {
		if (feature.valueType === 'array' && !nstate) {
			nstate = [];
		}
		await saveSetting(featureName, nstate);

	}
}

const savePlayerGAId = async () => {
	var gaId = features.value.ga.value;
	if (!gaId) return;

	await saveSetting('ga', gaId);
}

const saveSetting = async (key, value, successToast = false) => {
	featureProcessing.value[key] = true;
	try {
		await StreamService.saveStreamMetadata(props.stream._id, key, value);
		// clear stream meta cache in embed player
		StreamService.clearStreamMetadataCache(props.stream._id);
		if (successToast) {
			notify({ group: 'success', text: 'Setting saved' })
		}
	} catch (e) {
		notify({ group: 'error', text: 'could not save changes' });
	}
	if (key == 'ottEnabled') {
		ottProcessing.value = false
	}
	featureProcessing.value[key] = false;
}

const tooltipMessage = `<a target="_blank" class="text-reset text-underline" href="/app/subscribe">Upgrade</a> to access this feature. Check <a target="_blank" class="text-reset text-underline text-nowrap" href="https://castr.com/pricing">Pricing page</a> to know more about eligible plan.`

</script>
<template>
	<div class="divide-y divide-surface-2">
		<section v-if="stream.type === 'live' || stream.type === 'vod'" class="flex justify-between !py-3">
			<h4 class="text-base">Autoplay Embed Player</h4>
			<FormSwitch @change="toggleFeature('embedAutoplay')" v-model="features.embedAutoplay.enabled" />
		</section>
		<section v-if="stream.type === 'live'" class="flex justify-between !py-3">
			<h4 class="text-base">Hide Audio Icon</h4>
			<FormSwitch @change="toggleFeature('hidePlayerSidebarAudioIcon')"
				v-model="features.hidePlayerSidebarAudioIcon.enabled" />
		</section>
		<section v-if="stream.type === 'live'" class="flex justify-between items-center !py-3">
			<h4 class="text-base">Enable Embed Player Rewind</h4>
			<span v-if="!hasVaryingRewind">
				<ejs-tooltip v-if="features.delayingStream.enabled" content="Please disable delaying stream on embed player">
					<FormSwitch disabled />
				</ejs-tooltip>
				<FormSwitch v-else @change="toggleFeature('embedRewind')" v-model="features.embedRewind.enabled" />
			</span>
			<div class="flex gap-x-2" v-else>
				<ejs-tooltip v-if="features.delayingStream.enabled" content="Please disable delaying stream on embed player">
					<DropDownMenu type="secondary" disabled>
						<template #toggle-button>
							Disabled
							<icon-base class="!ml-1">
								<icon-chevron-up-down />
							</icon-base>
						</template>
					</DropDownMenu>
				</ejs-tooltip>
				<DropDownMenu v-else type="secondary" ref="rewindDropdown">
					<template #toggle-button>
						{{ varyingRewindSelected === -1
							? 'Disabled'
							: (varyingRewindSelected / 3600) == 1
								? (varyingRewindSelected / 3600) + ' hour'
								: (varyingRewindSelected / 3600) + ' hours' }}
						<icon-base class="!ml-1">
							<icon-chevron-up-down />
						</icon-base>
					</template>
					<template #menu-items>
						<DropDownMenuItem class="uppercase" v-for="(rewindOpt, index) in varyingRewindOpts" :key="index"
							@click="onVaringRewindSelect(rewindOpt)">
							{{
								rewindOpt === -1
									? 'Disabled'
									: (rewindOpt / 3600) == 1
										? (rewindOpt / 3600) + ' hour'
										: (rewindOpt / 3600) + ' hours' }}
						</DropDownMenuItem>
					</template>
				</DropDownMenu>
				<FormButton
					v-if="streamMeta && varyingRewindSelected !== streamMeta.varyingRewind && !features.delayingStream.enabled"
					:disabled="varyingRewindProcessing"
					@click="onVaringRewindSave" size="md" class="ml-2">
					{{ varyingRewindProcessing ? 'Saving' : 'Save' }}
				</FormButton>
			</div>
		</section>
		<section v-if="stream.type === 'live' && streamMetadataLoaded" class="flex justify-between !py-3">
			<div>
				<h4 class="text-base">Delaying Stream On Embed Player</h4>
				<p v-if="!hasDelayingStream" class="text-tiny text-surface-7"> Your plan is not supported. <a target="_blank"
						class="text-white underline" href="/app/subscribe">Upgrade</a> to access this feature</p>
			</div>
			<div class="flex gap-x-2 items-center">
				<DropDownMenu v-if="features.delayingStream.enabled" :disabled="!hasDelayingStream" type="secondary"
					ref="delayDropdown">
					<template #toggle-button>
						{{ features.delayingStream.value
							? delayingStreamHumanTime(features.delayingStream.value)
							: 'Choose delay time' }}
						<icon-base class="!ml-1">
							<icon-chevron-up-down />
						</icon-base>
					</template>
					<template #menu-items>
						<DropDownMenuItem class="uppercase" v-for="(delaySec, index) in delayingStreamOpts" :key="index"
							@click="onDelayingStreamSelect(delaySec)">
							{{ delayingStreamHumanTime(delaySec) }}
						</DropDownMenuItem>
					</template>
				</DropDownMenu>
				<ejs-tooltip
					v-if="streamMeta.varyingRewind > 0 || (streamMeta.varyingRewind === - 1 && features.embedRewind.enabled)"
					content="Please disable embed player rewind">
					<FormSwitch disabled />
				</ejs-tooltip>
				<FormSwitch v-else @change="toggleFeature('delayingStream')" v-model="features.delayingStream.enabled" />
			</div>
		</section>
		<section class="grid grid-cols-2 grid-rows-[1fr_auto] !py-3">
			<h4 class="text-base">Enable Google Analytics</h4>
			<FormSwitch class="justify-self-end" @change="toggleFeature('ga')" v-model="features.ga.enabled" />
			<div class="col-span-2 flex items-center gap-x-2 !mt-1" v-if="features.ga.enabled">
				<label class="m-0">GA ID</label>
				<FormInput class="!w-1/3" v-model="features.ga.value" placeholder="UA-00000 or G-xxxxx" />
				<FormButton :disabled="!features.ga.value || featureProcessing.ga" @click="savePlayerGAId">
					{{ featureProcessing.ga ? 'Saving' : 'Save' }}
				</FormButton>
			</div>
		</section>
		<section v-if="checkSettingVisibility('chatConfig')" class="flex justify-between items-center !py-3">
			<div class="leading-4">
				<h4 class="text-base">Enable Chat</h4>
				<p class="text-tiny text-surface-7">Engage with your audiences via real-time chat box. Click <FormButton
						type="link" @click="clearChatNew" customClasses="h-auto text-surface-9 hover:text-white">here</FormButton>
					to remove all
					messages history in your chatbox.
				</p>
			</div>
			<FormSwitch @change="toggleFeature('chatEnabled', true)" v-model="features.chatEnabled.enabled" />
		</section>
		<section v-if="checkSettingVisibility('iframePostMessages')" class="flex justify-between items-center !py-3">
			<div class="leading-4">
				<h4 class="text-base">Enable Player Events</h4>
				<p class="text-tiny text-surface-7">Catch Play/Pause event from your player (this feature is for developers).
				</p>
			</div>
			<ejs-tooltip v-if="!enablePlayerEventsToggle" :content="tooltipMessage">
				<FormSwitch disabled />
			</ejs-tooltip>
			<FormSwitch v-else @change="toggleFeature('iframePostMessages', true)"
				v-model="features.iframePostMessages.enabled" />
		</section>
		<section v-if="checkSettingVisibility('hlsCaptionSupport')" class="flex justify-between items-center !py-3">
			<div class="leading-4">
				<h4 class="text-base">Enable Caption Support</h4>
				<p class="text-tiny text-surface-7">Enable this option only when using a captioning service, such as Syncwords, Falcon, or similar.
				</p>
			</div>
			<FormSwitch @change="toggleFeature('hlsCaptionSupport', true)"
				v-model="features.hlsCaptionSupport.enabled" />
		</section>
		<section v-if="checkSettingVisibility('iframePostMessages')" class="flex justify-between items-center !py-3">
			<div class="leading-4">
				<h4 class="text-base flex items-center">Publish this {{ (stream.type === 'vod') ? 'Folder' : 'Stream' }} on OTT app
					<ejs-tooltip class="inline"
						content="OTT app is an iOS and Android compatible mobile app with your branding that you can use to publish your livestreams and video-on-demand to audiences.">
						<icon-base class="ml-2">
							<icon-help />
						</icon-base>
					</ejs-tooltip>
				</h4>
				<template v-if="!ottProcessing && !features.ottEnabled.enabled">
					<p class="text-tiny text-surface-7" v-show="!isOtt">Status: <span class="text-white">Not activated.
							<FormButton customClasses="text-white underline" onclick="Intercom('show')">Contact us</FormButton> to
							activate or <a class="text-white underline"
								href="https://docs.castr.com/en/articles/5483773-introducing-castr-ott-mobile-app-for-video-streaming">Learn
								more</a> here</span></p>
					<p class="text-tiny text-surface-7" v-show="isOtt">Status: <span class="text-white">Not published. This {{
						(stream.type === 'vod') ? 'folder' : 'stream' }} is not visible on your OTT app.</span></p>
				</template>
				<p v-if="!ottProcessing && features.ottEnabled.enabled" class="text-tiny text-surface-7">Status: <span
						class="text-white">Published. This {{ (stream.type === 'vod') ? 'folder' : 'stream' }} is visible on your
						OTT
						app for all viewers.</span></p>
				<p v-if="ottProcessing" class="text-tiny text-surface-7">Status: <span class="text-white">Processing...</span>
				</p>
			</div>
			<FormSwitch @change="requestOttunpublish()" v-model="features.ottEnabled.enabled"
				:disabled="!isOtt || ottProcessing" />
		</section>
	</div>
	<div>
		<alert-modal modal-id="embed-upload-format"
			message="Only valid image files are allowed. Please pick an image file" />

		<alert-modal :message="'Uploaded image too large. Image must not exceed ' + maxPosterUploadMB + 'MB'"
			modal-id="embed-image-size-exceed" />

		<alert-modal modal-id="embed-image-mime-type" message="Only PNG/JPG image files are allowed" />
		<confirm-modal modal-id="unpublish-ott"
			:message="'Unpublish this ' + ((stream.type === 'vod') ? 'folder' : 'stream') + ' on OTT app? Your audience wont be able to see this on your app anymore. Are you sure?'"
			ok-text="Yes, unpublish this" cancel-text="Cancel" @modal-confirm="toggleFeature('ottEnabled')"
			@modal-dismiss="toggleOttcancel()" />
		<confirm-modal modal-id="feature-upgrade" ok-text="Upgrade Now" cancel-text="Later"
			@modal-confirm="navigateToBilling()">
			<p class="mb-0 text-s-m"><a target="_blank" class="text-reset text-underline" href="/app/subscribe">Upgrade</a> to
				access this feature. Check <a target="_blank" class="text-reset text-underline text-nowrap"
					href="https://castr.com/pricing">Pricing page</a> to know more about eligible plan.</p>
		</confirm-modal>

		<stream-abr-settings-modal v-if="streamMetadataLoaded" modal-id="abr-advanced-settings" :stream="stream"
			:fps="streamAbrFps" @abr-fps-changed="onAbrFpsChange" />
	</div>
</template>
