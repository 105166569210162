import _ from "lodash";
import RequestError from "./RequestError";
import amProxyUrl from "./AmProxyUrl";
import api from "@/api";


export default {
    createHighlight,
    deleteHighlight,
    getHighlight,
    getHighlightbyid,
	createClips,
	addPlatform,
	deletePlatform,
	shareHighlight,
	checkStatus,
	retryClipshare,
	updatePlatformmeta,
	setHighlightName,
	getClipdata,
	checkDisabled,
	getRecordingStatus,
};
function getRecordingStatus(userId, key) {
	return makeRequest({
		path: `/highlight/recording_status/${userId}/${key}`,
		method: 'get',
	})
}

function getClipdata(uid,key) {
	return makeRequest({
		path: `https://central-storage-1.castr.io/record/edge/${uid}/${key}/recording_status.json`,
		method: 'get',
	});
}
function checkStatus(hid,cid) {
	return makeRequest({
		path: `/highlight/${hid}/clips/${cid}`,
		method: 'get',
	});
}
function createHighlight(name, desc, id) {
    console.log('name');
	return makeRequest({
		path: '/highlight',
		method: 'post',
		data: {
			name: name, description: desc, stream: id
		}
	});
}
function updatePlatformmeta(highlightId, platformId, platform) {
	return makeRequest({
		path: `/highlight/${highlightId}/platform/${platformId}`,
		method: 'put',
		data: platform
	});
}
function checkDisabled(highlightId, status) {
	return makeRequest({
		path: `/highlight/${highlightId}`,
		method: 'put',
		data: {isDisabled: status}
	});
}
function createClips(highlightId, name, starTime,endTime, duration, stream ) {
	return makeRequest({
		path: `/highlight/${highlightId}/clips`,
		method: 'post',
		data: {
			"name": name, "startTime": starTime, "endTime": endTime, "duration": duration, "stream": stream
		}
	});
}
function retryClipshare(highlightId, clipId ) {
	return makeRequest({
		path: `/highlight/${highlightId}/clips/${clipId}/retry`,
		method: 'post',
		// data: {
		// 	"name": name, "startTime": starTime, "endTime": endTime, "duration": duration, "stream": stream
		// }
	});
}
function addPlatform(highlightId, name, type, linkedService, stream, meta ) {
	return makeRequest({
		path: `/highlight/${highlightId}/platform`,
		method: 'post',
		data: {
			"name": name, "type": type, "linkedService": linkedService, "stream": stream, "meta": meta
		}
	});
}
function deletePlatform(highlightId, pid ) {
	return makeRequest({
		path: `/highlight/${highlightId}/platform/${pid}`,
		method: 'delete',
		// data: {
		// 	"name": name, "type": type, "linkedService": linkedService, "stream": stream, "meta": meta
		// }
	});
}
function deleteHighlight(highlightId ) {
	return makeRequest({
		path: `/highlight/${highlightId}`,
		method: 'delete',
		// data: {
		// 	"name": name, "type": type, "linkedService": linkedService, "stream": stream, "meta": meta
		// }
	});
}
function shareHighlight(highlightId, name, startTime, endTime,duration, stream, publishedPlatforms ) {
	return makeRequest({
		path: `/highlight/${highlightId}/clips`,
		method: 'post',
		data: {
			"name": name, "startTime": startTime, "endTime": endTime, "duration": duration, "stream": stream, "publishedPlatforms": publishedPlatforms
		}
	});
}

function setHighlightName(highlightId, name, desc='') {
	return makeRequest({
		path: `/highlight/${highlightId}`,
		method: 'put',
		data: {
			name: name, description: desc
		}
	});
}

function getHighlight() {
	return makeRequest({
		path: '/highlight',
		method: 'get',
	});
}

function getHighlightbyid(highlightId) {
  return makeRequest({
		path: `/highlight/${highlightId}`,
		method: 'get',
	});
}

function deletePlaylist(playerId) {
  return makeRequest({
		path: `/playlist/${playerId}`,
		method: 'delete',
	});
}

function playlistToggle(playerId, shouldDisabled) {
  return makeRequest({
		path: `/playlist/${playerId}`,
		method: 'put',
        data: {
			disabled: shouldDisabled
		}
	});
}

function nameChange(playerId, name) {
  return makeRequest({
		path: `/playlist/${playerId}`,
		method: 'put',
        data: {
			name: name
		}
	});
}

async function makeRequest(reqConfig, preventProxy, throwReal) {
  if (typeof reqConfig === "string") {
    reqConfig = {
      path: reqConfig,
    };
  }

  if (preventProxy !== true) {
    reqConfig = amProxyUrl(reqConfig, process.env.VUE_APP_VOD_API_BASE_URL);
  }

  reqConfig.url = reqConfig.path;

  let res;
  try {
    res = await api.request(reqConfig);
  } catch (err) {
    if (throwReal) {
      throw err;
    }
    const edata = _.get(err, "response.data");
    throw new RequestError(edata);
  }

  return res && res.data;
}