<template>
  <div class="view">
    <Spinner v-if="!this.userloaded" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)" spinner-size="15px" />
    <div class="row" v-else>
      <div class="col-sm-12">
        <div class="refer-n-earn text-center mx-auto" v-if="!this.activeTeam || (this.memberPermission && this.memberPermission.refer )">
          <h3 class="page-title">
            Earn 30% commissions when your friends upgrade.
          </h3>
            <p class="text-s-l text-dark-8 mb-5">
              It’ll take you 2 minutes only.
            </p>
            <b-button class="mb-4" variant="primary" size="md" href="https://refer.castr.io/" target="_blank">
              Sign up
            </b-button>
            <p class="text-dark-6 text-s-s">Already Signed up? <a class="text-underline text-dark-7" target="_blank" href="https://refer.castr.io/login">Login here</a> to access your affliate dashboard</p>
        </div>
        <div class="row" v-else>
          <div class="col-sm-12">
            <h1 class="page-title text-500 mb-4">Refer and Earn</h1>
              <div class="text-center pt-5 mt-5">
                <h4 class="text-dark-9 text-s-xl text-500 mb-1">You don’t have permission to access this.</h4>
                <p class="text-dark-6 text-s-m">Contact the team owner at <a  class="text-dark-6 text-s-m text-underline" :href="'mailto:'+userEmail">{{userEmail}}</a> to gain access.</p>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/ui/Spinner.vue";

export default {
  name: "ManageInviteFriends",
  components: {
    Spinner
  },
  data() {
    return {
      iframeLoading: true
    }
  },
  computed:{
    activeTeam(){
			return this.$store.state.User.activatedTeam;
		},
    memberPermission(){
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
    userEmail() {
			return this.$store.state.User.email;
		},
		userloaded(){
			return this.$store.state.User.userloaded;
		},
  },
  mounted() {
    window.trackEvent(`Invite Friends Page`)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.view {
  color: #f7f7f7;
}
.title {
  color: inherit;
  font-size: 20px;
}
.field-container {
  width: 235px;
  padding: 32px 0;
  border-bottom: 1px solid #22244d;
}
.field-container:last-of-type {
  border-bottom: none;
}
.refer-n-earn{
  max-width: 425px;
  margin-top: 20px;
}
</style>

