<template>
	<div class="">
		<!-- Modal Component -->
		<b-modal
			:id="modalId"
			ref="modalDEMOVideo"
			class="modal-platform modal-frameless"
			size="lg"
			centered
			hide-header
			hide-footer
		>
			<div>
				<div 
					style="position: relative; 
					padding-bottom: calc(49.051932838734864% + 44px); 
					height: 0;"
					class="demo_container"
				>
					<iframe 
						:src="getPlayerUrl()" 
						frameborder="0" 
						webkitallowfullscreen="true" 
						mozallowfullscreen="true" 
						allowfullscreen 
						style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
					</iframe>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'DemoVideoModal',
	components: {},
	props: {
		videoid: {
			type: String,
			required: true
		},
		modalId: {
			type: String,
			required: false,
			default: 'modal-demo-video'
		}
	},
	data() {
		return {
			processing: false,
			active: false
		};
	},
	mounted () {
		this.$refs.modalDEMOVideo.$on('shown', this.onInit);
		this.$refs.modalDEMOVideo.$on('hide', this.onDismiss);
	},
	methods: {
		onInit() {
			this.active = true;
		},
		onDismiss() {
			this.active = false;
		},
		onConfirm() {
			this.$emit('modal-confirm');
			this.dismiss();
		},
		dismiss() {
			this.$refs.modalDEMOVideo.hide();
			// lazy clear
			setTimeout(() => {
				this.processing = false;
			}, 1000);
		},
		show() {
			this.$refs.modalDEMOVideo.show();
		},
		getPlayerUrl () {
			const id = this.videoid;
			if (!this.active) return;
			const playerUri = `https://app.supademo.com/embed/${id}`;
            console.log(playerUri);
			return playerUri;
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* iframe.embed {
    height: calc(25vw);
    width: 50vw;
    margin: 0 auto;
    max-height: 100%;
	z-index: 99999;
    position: relative;
}
@media only screen and (max-width:768px){
	iframe.embed {
		    height: 100%;
    max-width: 98%;
    min-height: 40vh;
    width: auto;
	width: 100%;
	}
} */
@media only screen and (max-width:600px){
	.demo_container {
  padding-bottom: calc(49%) !important;
	}
}
</style>
