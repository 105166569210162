<script>
export default {
  name: "ResizableTextarea",
  methods: {
    resizeTextarea(event) {
      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + "px";
    }
  },
  props: {
    shouldResize: {
      type: Boolean,
			default() { return false; }
    }
  },
  mounted() {
    this.$nextTick(() => {
      // DOM updated
      // console.log(this.$el.scrollHeight)
      this.$el.setAttribute(
        "style",
        "height:" + this.$el.scrollHeight + "px;overflow-y:hidden;"
      );
      this.$el.addEventListener("input", this.resizeTextarea);
    });
  },
  beforeDestroy() {
    this.$el.removeEventListener("input", this.resizeTextarea);
  },
  render() {
    return this.$slots.default[0];
  }
};
</script>
