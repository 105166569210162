<template>
	<div class="position-relative">
		<b-button @click="toggleView" variant="link-7" class="font-normal text-s-ss" size="sm px-0">
			<div class="rounded-circle icon-round mr-2">
				<icon-base width-view-box="8" height-view-box="10" iconColor="none" width="8" height="10" class="text-dark-7">
					<icon-like />
				</icon-base>
			</div>
			Recommended Encoder Settings
		</b-button>
		<transition>
			<div v-if="isShown"
				class="position-absolute z-1010 bg-dark-2 rounded-lg py-3 pl-3 pr-4 zindex-dropdown text-dark-7 text-s-s text-500 border border-dark-3 left-0 bottom-0">
				<b-button variant="icon" size="sm" class="icon-close" @click="toggleView">
					<icon-base width-view-box="12" height-view-box="12" iconColor="none" width="12" height="12" class="text-dark-7">
						<icon-close />
					</icon-base>
				</b-button>
				<p class="mb-2">Recommended Encoder Settings</p>
				<ul class="pl-4 mb-0">
					<li class="mb-2">Bitrate: 2000 kbps</li>
					<li class="mb-2">Keyframe Interval: 2 seconds & Keyframe aligned</li>
					<li class="mb-2">Rate Control: Constant Bit Rate (CBR)</li>
					<li class="mb-2">Tune: Zero Latency</li>
					<li class="mb-2">Profile: Baseline</li>
				</ul>
				<p class="mb-0">If you have questions please reach out via <span class="text-white text-underline cursor-pointer"
						onclick="Intercom('show')">live chat</span></p>
			</div>
		</transition>
	</div>
</template>

<script>
import IconBase from '@/components/icon/IconBase.vue'
import IconLike from '@/components/icon/IconLike.vue'
import IconClose from '@/components/icon/IconClose.vue'

export default {
	name: 'SubSecondRecommendedSettings',
	components: {
		IconBase,
		IconLike,
		IconClose
	},
	props: {

	},
	data() {
		return {
			isShown: false
		}
	},
	computed: {

	},
	methods: {
		toggleView() {
			this.isShown = !this.isShown
		}
	}
};
</script>

<style scoped>
.zindex-dropdown {
	z-index: 1000;
}
.icon-close {
	position: absolute;
	right: 0;
	top: .5rem;
}
</style>
