<script setup>
import { computed, ref } from 'vue'
import IconBase from '../icon/IconBase.vue';
import IconCalendarAdd from '../icon/IconCalendarAdd.vue';
import IconWhatsUp from '../icon/IconWhatsUp.vue';
import IconTalk from '../icon/IconTalk.vue';

const isVisible = ref(true)

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  },
  hideIcon: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'normal'
  },
  text: {
    type: String,
    default: '',
  }
})

</script>
<template>
  <section
    class="w-screen md:w-full bg-surface-darker rounded-b-xl !py-2 !px-4 md:px-6 flex items-center gap-x-2 md:gap-x-6 text-tiny font-medium flex-wrap">
    <h3 class="mr-auto md:mr-0">Need help?</h3>
    <a href="https://calendly.com/castr/sales?utm_source=dashboard&utm_medium=website&utm_campaign=dashboard-top"
      target="_blank"
      class="rounded-full !border border-surface-2 flex gap-x-2 items-center justify-center !p-1 md:ml-auto text-surface-9 hover:text-white hover:no-underline size-9 md:size-auto md:h-8">
      <h4 class="!ml-2 !hidden md:!block">Book a meeting</h4>
      <figure class="size-6 bg-white rounded-full flex items-center justify-center md:ml-auto">
        <icon-base class="shrink-0 text-surface-darker" fill="transparent">
          <icon-calendar-add />
        </icon-base>
      </figure>
    </a>
    <a href="https://wa.me/16049988035" target="_blank"
      class="rounded-full !border border-surface-2 flex gap-x-2 items-center justify-center !p-1 text-surface-9 hover:text-white hover:no-underline size-9 md:size-auto md:h-8">
      <h4 class="!ml-2 !hidden md:!block">Chat on Whatsapp</h4>
      <figure class="size-6 bg-success-light rounded-full flex items-center justify-center md:ml-auto">
        <icon-base class="shrink-0 text-white -mr-px " fill="transparent">
          <icon-whats-up />
        </icon-base>
      </figure>
    </a>
    <button class="text-yellow flex gap-x-1 items-center justify-center rounded-full !border md:!border-none border-surface-2 size-9 md:size-auto md:h-8 items-center"
      onclick="Intercom('show')">
      <icon-base heightViewBox="20" widthViewBox="20" class="shrink-0" fill="transparent">
        <icon-talk />
      </icon-base>
      <span class="!hidden md:!block">Talk To Sales</span>
    </button>
    <h5 class="!hidden md:!flex rounded-full h-8 items-center !px-5 text-center w-full md:w-auto">24/7 Support</h5>
  </section>
</template>

<style scoped></style>