<template>
	<div class>
		<b-modal
			hide-header
			hide-footer
			id="modal-add-stream"
			ref="modalAddStream"
			@shown="checkSelected"
			:modal-class="['modal-right', { 'modal-collapsed': selectedStreamType }]"
		>
			<!-- stream types -->
			<div class="modal-container">
				<b-row align-v="center" class="px-3 mb-2 mt-100 mobile-header">
					<b-col cols="8" class="">
						<b-navbar-brand
							:to="{ path: '/dashboard' }"
							tag="a"
							class="modal-logo"
							>
								<b-img
								height="32px"
									class="logo-icon"
									:src="require('@/assets/castr.svg')"
									alt="Streaming Dashboard - Castr"
								/>
								<div class="logo-icon-dot" />
						</b-navbar-brand>
						<h3 class="modal-title d-block d-md-none">
							Create New
						</h3>
					</b-col>
					<b-col cols="4" class="text-right">
						<b-button
							variant="icon"
							class="px-2 btn-close"
							@click="dismiss"
						>
							<img
								src="@/assets/images/menu-close.svg"
								alt="Menu close"
							>
						</b-button>	
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<h3 class="modal-title-style2">
							Create New
						</h3>
						<div
							v-for="streamTypeDef in this.filterStream()"
							:key="streamTypeDef.type"
							:class="{active: selectedStreamType === streamTypeDef.type}"
							:id="((memberPermission && (streamTypeDef.type === 'live')  && !memberPermission.liveStreamManage) || (memberPermission && (streamTypeDef.type === 'restream') && !memberPermission.recordedStreamManage)) ? ('member-disabled_'+streamTypeDef.type) : ''"
							class="d-flex stream-item align-items-start"
							@click="selectStreamType(streamTypeDef.type)"
						>
							<div class="stream-item__icon mr-3">
								<svg v-if="streamTypeDef.type === 'restream'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12 5V12M12 12H17C18.1046 12 19 12.8954 19 14V19M12 12H7C5.89543 12 5 12.8954 5 14V19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<circle cx="12" cy="5" r="2.5" fill="white"/>
									<circle cx="19" cy="19" r="2.5" fill="white"/>
									<circle cx="5" cy="19" r="2.5" fill="white"/>
								</svg>
								<svg v-if="streamTypeDef.type === 'live'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M10.8711 7.80409L15.951 10.5981L15.951 10.5981C16.7821 11.0551 17.1976 11.2837 17.3351 11.5857C17.4549 11.8489 17.4549 12.1511 17.3351 12.4143C17.1976 12.7163 16.7821 12.9449 15.951 13.4019L15.951 13.4019L10.8711 16.1959C10.0905 16.6252 9.70023 16.8399 9.38103 16.8016C9.10251 16.7683 8.85081 16.6194 8.68734 16.3915C8.5 16.1302 8.5 15.6848 8.5 14.794V9.20603C8.5 8.3152 8.5 7.86979 8.68734 7.60853C8.85081 7.38057 9.10251 7.23175 9.38103 7.19837C9.70023 7.16013 10.0905 7.37478 10.8711 7.80409Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<circle cx="12" cy="12" r="10" stroke="white" stroke-width="1.5"/>
								</svg>
							</div>
							<div class="stream-item__desc">
								<h5 class="stream-item__title text-500" :class="[streamTypeDef.type]">
									{{ streamTypeDef.title }}
								</h5>
								<p class="stream-item__p text-dark-7 mb-0">{{ streamTypeDef.description }}</p>
							</div>
							<b-tooltip
								:target="'member-disabled_'+streamTypeDef.type"
								placement="top"
								v-if="memberPermission"
								>
								<span class="text-dark-8 text-small">
									You don’t have permission. Contact the team owner at <a  class="text-dark-8 text-small" :href="'mailto:'+userEmail">{{userEmail}}</a> to gain access.</span>
							</b-tooltip>
						</div>
					</b-col>
				</b-row>
			</div>
			
			<div class="modal-step-2">
				<!-- stream creation form  -->
				<section
					:class="selectedStreamType ? 'showform' : 'hideform'"
					class="view-stage-item second-modal"
				>
					<div>
						<div v-if="error">
							<div v-if="error.role">
								<b-row no-gutters class="bg-dark-3">
									<b-col class="p-3">
										<p class="mb-2">
											You do not have an active {{ (selectedStreamType === 'restream') ? 'Multistream' :  (selectedStreamType !== 'vod' ? ' all in one' : '') }} subscription.</p>
										<p class="mb-0 text-s-m text-dark-7">Would you like to activate our 7 days trial ?</p>
									</b-col>
								</b-row>
								
								<div v-if="trialError"
									class="alert alert-danger"
								>{{ trialError.message }}</div>
								<div class="text-right">
									<div v-if="trialProcessing"
									>
										<b-progress :value="100"
											:max="100"
											animated
											class="w-100"
										/>
									</div>
									<div v-else>
										<b-row>
											<b-col class="px-5 mt-4">
												<b-button :disabled="trialProcessing" variant="primary" size="md" class="w-100 mb-3" @click="forceActivateTrial"><span v-if="!trialProcessing">Activate Trial</span><span v-else>Activating... Please wait</span></b-button>
												<b-button variant="link" size="md" class="text-dark-7 w-100 mb-3" :to="`/subscribe?category=${selectedStreamType}`">
													Want more options?
												</b-button>
												<b-button variant="outline-secondary" class="w-100" size="md" @click="dismiss">Cancel</b-button>
											</b-col>
										</b-row>
									</div>
								</div>
							</div>
						</div>

						<!-- form -->
						<div v-show="operational" class="mt-200">
							<b-row  no-gutters v-if="selectedStreamType" align-v="center" class="px-3 mb-2 d-flex d-md-none mobile-header">
								<b-col cols="2">
									<b-button variant="icon" size="sm" class="text-dark-7 px-2" @click="selectedStreamType = null">
										<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M7 13L1 7L7 1" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</b-button>
								</b-col>
								<b-col>
									<h3 class="modal-title d-block d-md-none">
										New {{ selectedStreamTypeDef.title }}
									</h3>
								</b-col>
								<b-col cols="2" class="text-right">
									<b-button
										variant="icon"
										class="px-2 btn-close"
										@click="dismiss"
									>
										<img
											src="@/assets/images/menu-close.svg"
											alt="Menu close"
										>
									</b-button>	
								</b-col>
							</b-row>
							<b-row>
								<b-col class="px-5 py-2">
									<div
										v-if="selectedStreamType"
										class="stream-type-info"
									>
										<h4 class="text-s-l d-none d-md-block text-500">{{ selectedStreamTypeDef.title }}</h4>
										<p class="text-s-m text-dark-7 mt-2 mt-md-0 mb-0">{{ selectedStreamTypeDef.description2 }}</p>
									</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-alert
										v-if="error && !error.role"
										show
										variant="danger-style2"
										class="text-left mx-4"
									>
										<!-- <div>{{ error.message }}</div> -->
										<div v-html="error.message"></div>
										<div
											v-if="error.subscription"
											class="text-left"
										>
											<a href="/app/subscribe"><strong>upgrade now</strong></a>
										</div>
									</b-alert>
								</b-col>
							</b-row>
							<b-row>
								<b-col class="px-5 py-2">
									<FormInput v-model="channel.name" label="Stream Name *" placeholder="#stream_name" @keypress="onInputChange('name')" :helper="formErrors.name ? 'specify stream name' : null" />
								</b-col>
							</b-row>
							<b-row>
								<b-col
									v-show="(contextualRegionList.length > 0 && selectedStreamType === 'live' ) || contextualRegionList.length > 1"
									class="px-5 py-2"
								>
									<label class="text-s-s w-100">
										Hosting Region *
									</label>
									<StreamRegion class="position-relative" :stream="newStreamComputed" @switch-stream-region="selectRegion" />
									<p
										v-show="formErrors.region"
										class="text-warning pl-1"
									>
										specify hosting region
									</p>
								</b-col>
							</b-row>
						</div>
						<!-- <br> -->
					</div>

					<!-- view footer -->
					<b-row>
						<b-col  class="px-5"
							v-show="operational || processing"
						>	
							<form-button class="w-full mt-3 justify-center" v-if="processing" disabled>
								<Spinner text="Creating..." classes="text-dark-8" spinner-color="var(--c-dark-8)" spinner-size="15px" />
							</form-button>

							<div
								v-else
								class="w-100"
							>
								<form-button class="w-full mt-3 justify-center" v-if="selectedStreamType" @click="onSaveChannel">
									Create {{selectedStreamTypeDef.title}}
								</form-button>
								<form-button type="secondary" class="w-full mt-3 justify-center" v-if="selectedStreamType" @click="unselectStreamType">
									Cancel
								</form-button>
							</div>
						</b-col>
					</b-row>
				</section>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import IconBase from '@/components/icon/IconBase.vue';
import IconLivestream from '@/components/icon/IconLivestream.vue';
import IconMultistream from '@/components/icon/IconMultistream.vue';
import IconIpcam from '@/components/icon/IconIpcam.vue';
import IconSchedulestream from '@/components/icon/IconSchedulestream.vue';
import IconVodChannel from '@/components/icon/IconVodChannel.vue';
import SubscriptionService from '@/services/SubscriptionService';
import StreamRegion from '../ui/StreamRegion.vue';
import FormInput from '../Atoms/FormInput.vue';
import FormButton from '../Atoms/FormButton.vue';

export default {
	name: 'AddStreamModal',
	components: {
		Spinner,
		IconBase,
		IconLivestream,
		IconMultistream,
		IconIpcam,
		IconSchedulestream,
		IconVodChannel,
		StreamRegion,
		FormInput,
		FormButton,
	},
	data() {
		return {
			processing: false,
			trialProcessing: false,
			error: null,
			trialError: null,
			operational: true,
			viewStage: 1,
			selectedStreamType: null,
			userSubscription: null,
			channel: {
				name: null,
				region: null
			},
			selectedRegion: null,
			regions: [],
			formErrors: { name: false, region: false },
			streamTypeList: [
				{
					type: 'restream',
					title: 'Multistream',
					description: 'Stream To Multiple Sites',
					description2: 'Stream your videos to multiple social platforms and destinations at the same time.',
					icon: require('@/assets/images/simulcast-plan.svg') // eslint-disable-line no-undef
				},
				{
					type: 'live',
					title: 'All in One Stream',
					description: 'Livestream to websites with your player, stream to social media, and convert livestreams to on-demand videos — all in one place',
					description2: 'Livestream videos on your own website and multiple stream destinations.',
					icon: require('@/assets/images/live-plan.svg') // eslint-disable-line no-undef
				},
			],
			onInputChange(prop) {
				this.formErrors[prop] = false;
			}
		};
	},
	props: {
		typeFromBilling: {
			type: String,
		}
	},
	computed: {
		userEmail() {
			return this.$store.state.User.email;
		},
		subscriptions() {
			return this.$store.state.User.subscriptions;
		},
		deploymentRegions() {
			return this.$store.state.User.deploymentRegions;
		},
		contextualRegionList() {
			const stype = this.selectedStreamType;
			// we don't ask scheduled region from user
			if (stype === 'scheduled') {
				return [];
			}

			return stype
				? this.regions.filter(r => r.platforms.indexOf('restream') > -1)
				: [];
		},
		selectedStreamTypeDef() {
			return this.streamTypeList.find(
				e => e.type === this.selectedStreamType
			);
		},
		streamTypeSubscriptionCategory() {
			let subCat = this.selectedStreamType;
			if (subCat === 'scheduled') {
				subCat = 'restream';
			}

			return subCat || 'restream';
		},
		activeTeam(){
			return this.$store.state.User.activatedTeam;
		},
		memberPermission(){
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
		newStreamComputed() {
			const newStream = {}
			newStream.uiRegion = this.selectedRegion 
			newStream.type = this.selectedStreamType
			return newStream
		}
	},
	watch:{
		filterStream(){
			this.filterStream();
			console.log('filtered');
		}
	},
	async mounted() {
		FormInput.compatConfig = { MODE: 3 }
		this.$refs.modalAddStream.$on('hide', this.onDismiss);
		this.$refs.modalAddStream.$on('shown', this.onInit);
		this.regions = await StreamService.getAvailableRegions('list');
		// this.filterStream();
	},
	methods: {
		filterStream(){
			let streamCatarray = [];
			this.subscriptions.map((s)=>{
				if(s.enabled) streamCatarray.push(_.get(s, ['package', 'category']));
			});

				// console.log('=====');
				// console.log(streamCatarray);
				let streamTypeList = this.streamTypeList;
			if(!streamCatarray.includes('restream')){
				streamTypeList = [
					{
						type: 'live',
						title: 'All in One Stream',
						description: 'Livestream to websites with your player, stream to social media, and convert livestreams to on-demand videos — all in one place',
						description2: 'Livestream videos on your own website and multiple stream destinations.',
						icon: require('@/assets/images/live-plan.svg') // eslint-disable-line no-undef
					},
				];
			}
			return streamTypeList;
			},
		async onInit() {
			if ((this.typeFromBilling != 'restream' && this.typeFromBilling != 'live')) {
				this.operational = true;
				this.error = null;
				this.trialError = null;
				// this.processing = true;
				this.trialProcessing = false;
			}
			
		},
		onDismiss() {
			this.resetForm();
			this.clearErrors();
			// lazy clear
			setTimeout(() => {
				this.processing = false;
			}, 1000);
		},
		dismiss() {
			this.$refs.modalAddStream.hide();
			this.onDismiss();
		},
		selectRegion(region) {
			this.selectedRegion = region;
			this.channel.region = region._id;
			this.onInputChange('region');
		},
		getCountryFlag(region) {
			return `https://assets.castr.io/countryflags/${region.identifier}/flat/64.png`;
		},
		selectStreamType(streamType) {
			if(!this.memberPermission && !this.trialProcessing || !(((streamType === 'live') && this.memberPermission && !this.memberPermission.liveStreamManage)  || ((streamType === 'restream') && this.memberPermission && !this.memberPermission.recordedStreamManage)) && !this.trialProcessing){
				this.selectedRegion = null;
				this.channel.region = '';
				if (!this.selectedStreamType) {
					this.selectedStreamType = streamType;
					this.onStreamTypeSelected();
				} else {
					this.unselectStreamType();
					setTimeout(() => {
						this.selectStreamType(streamType);
					}, 250);
				}
			}
		},
		async onStreamTypeSelected() {
			this.operational = true;
			this.error = null;
			this.trialError = null;
			this.trialProcessing = false;

			const subCat = this.selectedStreamType;
			const hasRequiredSub = this.subscriptions.find(
				(s) => {
					const chain = (subCat === 'restream') ? 'package.category': 'category';
					return _.get(s, chain, '').includes(subCat);
				}
			);
			if (!hasRequiredSub) {
				this.operational = false;
				this.error = { role: true };
			}

			// If client has assigned deployment region for certain stream type
			// we need to show only those regions when creating new stream
			// const platformNode = _.find(this.deploymentRegions, pnode =>
			// 	_.includes(pnode.platforms, subCat)
			// );
			// if (platformNode && !_.isEmpty(platformNode.regions)) {
			// }

			// this.regions = await StreamService.getAvailableRegions(subCat);

			if (this.contextualRegionList.length === 1) {
				this.channel.region = this.contextualRegionList[0]._id;
			}
		},
		unselectStreamType() {
			this.selectedStreamType = null;
			this.resetForm();
			this.clearErrors();
		},
		async forceActivateTrial() {
			this.trialError = null;
			this.trialProcessing = true;

			// activate trial
			const subCat = this.selectedStreamType;
			try {
				await SubscriptionService.requestTrial(subCat);
				// update vuex state
				await this.$store.dispatch('User/loadUser', null, { root: true });
			} catch (e) {
				this.trialError = e;
				return;
			} finally {
				this.trialProcessing = false;
			}

			this.error = null;
			this.operational = true;

			// on trial activated
			if (!this.trialError) {
				this.$notify({
					group: 'success',
					text: `${this.selectedStreamType} trial activated successfully`
				});
			}
		},
		async onSaveChannel() {
			this.error = null;

			// if selected scheduled, set first region from server list
			const stype = this.selectedStreamType;
			if (stype === 'scheduled') {
				const region = _.head(this.regions.filter(r => r.platforms.indexOf(stype) > -1));
				this.channel.region = region._id;
			}

			if (!this.validateForm()) return;

			this.processing = true;

			try {
				// const stream = await StreamService.addStream(
				await StreamService.addStream(
					this.channel.name,
					this.channel.region,
					this.selectedStreamType
				);

				// #custom-patch: new stream not found in api response
				const streamList = await StreamService.getUserStreams();
				const stream = _.find(
					streamList,
					s =>
						s.type === this.selectedStreamType &&
                  s.name === this.channel.name
				);
				// --

				this.$emit('new-channel', stream, this.selectedRegion);
				this.dismiss();
			} catch (err) {
				this.error = err;
				this.processing = false;
				this.selectedRegion = null;
				err.message = '<i class="fas fa-exclamation-triangle mr-2"></i>'+err.message;
				if(this.selectedStreamType === 'restream') {
					err.message = err.message.replace('upgrade your subscription', '<a class="upgradelink" target="_blank" href="/app/subscribe?category=restream">upgrade your subscription</a>');
				}
				if(this.selectedStreamType === 'live') {
					err.message = err.message.replace('upgrade your subscription', '<a class="upgradelink" target="_blank" href="/app/subscribe?category=live">upgrade your subscription</a>');
				}
				if(this.selectedStreamType === 'vod') {
					err.message = err.message.replace('streams and platforms', 'VOD Bucket');
				}
				// check if subscripiton error
				if (err && _.indexOf(err.message, 'subscription') > -1) {
					this.error.subscription = true;
				}
			}
		},
		validateForm() {
			const props = ['name', 'region'];

			let valids = 0;
			_.each(props, prop => {
				const val = this.channel[prop];
				if (val) valids++;
				this.formErrors[prop] = !val;
			});

			return valids === props.length;
		},
		clearErrors() {
			this.error = null;
			this.formErrors.name = false;
			this.formErrors.region = false;
		},
		resetForm() {
			this.selectedStreamType = null;
			this.channel.name = null;
		},
		checkSelected() {
			if (this.typeFromBilling === 'restream' || this.typeFromBilling === 'live') {
				
				this.selectStreamType(this.typeFromBilling);
			}
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.stream-item {
		padding: 16px 26px;
		position: relative;
		/* margin-bottom: 20px; */
		cursor: pointer;
	}
	.stream-item__desc {
		position: relative;
		flex-grow: 1;
	}
	.stream-item:not(:last-child) .stream-item__desc:after {
		content: '';
		display: block;
		border-top: 1px solid var(--c-dark-3);
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		bottom: -15px;
	}
	.stream-item:hover {
		background-color: var(--c-dark-3);
	}
	.stream-item.active {
		background-color: var(--c-main-1);
	}
	.stream-item.active .stream-item__desc:after {
		content: none;
	}
	.stream-item svg {
		color: var(--c-main-1);
	}
	.stream-item:hover svg, .stream-item.active svg {
		color: #fff;
	}
	.stream-item.active .stream-item__p {
		color: #fff;
	}
	.stream-item__title {
		font-size: var(--font-s-m);
	}
	.stream-item__p {
		font-size: var(--font-s-s);
	}
	/* .hideform {
		display: none;
	}
	.showform {
		display: flex;
	} */
	.second-modal {
		position: fixed;
		top: 0;
		background-color: var(--c-dark-2);
		margin: auto;
		width: 400px;
		height: 100%;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
		right: 0;
		z-index: -1;
		border-left: 1px solid var(--c-dark-3);
		border-right: 1px solid var(--c-dark-3);
	}
	.showform.second-modal {
		right: 414px;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	.stream-type-info {
		/* background-color: var(--c-dark-3); */
		padding: 0px;
	}
	.region-dropdown .dropdown-menu {
		left: -100%!important;
	}
	.region-flag {
		width: 20px;
	}
	.modal-logo{
		padding-left: 9px;
	}
	.stream-type-info h4,
	.stream-type-info p {
    	cursor: pointer;
	}
	@media (max-width: 767px) {
		.mobile-header {
			border-radius: 24px 24px 0 0;
			margin-top: 0;
			background-color: var(--c-dark-3);
			border-bottom: 1px solid var(--c-dark-5);
		}
		.hideform.second-modal {
			bottom: -115vh;
			left: 0;
			z-index: 0;
			max-width: 100%;
			transition: opacity 0.3s linear, bottom 0.3s ease-out;
		}
		.showform.second-modal {
			height: auto;
			top: auto;
			bottom: 0;
			left: 0;
			z-index: 1030;
			max-width: 100%;
			transition: opacity 0.3s linear, bottom 0.3s ease-out;
		}
		.modal-logo {
			display: none;
		}
		.modal-title {
			padding: 16px 0;
		}
		.modal-title-style2 {
			display: none;
		}
		.second-modal {
			width: 100%;
			border-left: none;
			border-right: none;
		}
		.btn-close {
			background-color: var(--c-dark-5);
			border-radius: 50%;
			color: var(--c-dark-8);
			height: 32px;
			width: 32px;
		}
		.mt-200 {
			margin-top: 0;
		}
		.stream-item {
			padding: 15px;
		}
		.btn-icon {
			color: var(--c-dark-7);
		}
		:deep(.region-dropdown .dropdown-menu) {
			max-width: 315px;
		}
		:deep(.regions-wrapper) {
			top: -191px;
		}
	}
	@media (min-width: 768px) and (max-width: 991px) {
  .showform.second-modal {
    right: 100%;
  }
}
</style>
