const UNIQ_PREFIX = 'castr-cache'
export function setCache(key, data) {
    key = UNIQ_PREFIX + key;
    const payload = {
        data,
        time: Date.now(),
    }
    localStorage.setItem(key, JSON.stringify(payload));
}

export function getFromCache(key, expiry = 5) {
    key = UNIQ_PREFIX + key;
    const cachedData = localStorage.getItem(key);
    if(!cachedData) return null;
    
    try {
        const payload = JSON.parse(cachedData);
        // cache expired
        if((Date.now() - payload.time) > expiry * 1000) return null;

        return payload.data;
    } catch {
        return null;
    }
}

export function clearAllPreviousCache() {
    const cacheKeys = Object.keys(localStorage).filter(x => x.startsWith(UNIQ_PREFIX));
    cacheKeys.forEach(key => localStorage.removeItem(key))
}

export default {
    setCache,
    getFromCache,
}

