<template>
  <div class="">
    <b-modal
      size="md"
      centered
      hide-header
      hide-footer
      ref="create-subaccount-modal"
      id="create-subaccount-modal"
      @hidden="hideModal"
    >
      <b-row>
        <b-col>
          <h3 class="text-s-xl text-500 mb-3">Create sub-account</h3>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <label class="text-dark-8" for="">Name</label>
          <b-form-input
            v-model="name"
            autocomplete="false"
            maxlength="50"
            v-on:keyup.enter=""
            @input=""
            placeholder=""
            class="form-control mb-2"
            
          ></b-form-input>
          <!-- <p v-if="error" class="color-danger d-flex align-items-start">
            <svg
              class="flex-shrink-0 mr-2 mt-1"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.36956 3.79623C6.53108 1.67817 7.11184 0.619141 8 0.619141C8.88816 0.619141 9.46892 1.67817 10.6304 3.79623L14.0638 10.0571C15.15 12.0377 15.693 13.028 15.2574 13.7638C14.8217 14.4996 13.6923 14.4996 11.4334 14.4996H4.56665C2.30772 14.4996 1.17826 14.4996 0.742611 13.7638C0.306962 13.028 0.850044 12.0377 1.93621 10.0571L5.36956 3.79623ZM7.99996 4.74955C8.41417 4.74955 8.74996 5.08534 8.74996 5.49955V7.99955C8.74996 8.41377 8.41417 8.74955 7.99996 8.74955C7.58574 8.74955 7.24996 8.41377 7.24996 7.99955V5.49955C7.24996 5.08534 7.58574 4.74955 7.99996 4.74955ZM8 11.9996C8.55228 11.9996 9 11.5518 9 10.9996C9 10.4473 8.55228 9.99956 8 9.99956C7.44771 9.99956 7 10.4473 7 10.9996C7 11.5518 7.44771 11.9996 8 11.9996Z"
                fill="#E25858"
              />
            </svg>
             {{error}}
          </p> -->
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <label class="text-dark-8" for="">Email address</label>
          <b-form-input
            type="email"
            autocomplete="do-not-autofill"
            v-model="email"
            maxlength="50"
            v-on:keyup.enter=""
            @blur="checkEmail"
            placeholder=""
            class="form-control mb-2"
            v-bind:class="{ error: error }"
          ></b-form-input>
          <p v-if="error" class="color-danger d-flex align-items-start text-capitalize">
            <svg
              class="flex-shrink-0 mr-2 mt-1"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.36956 3.79623C6.53108 1.67817 7.11184 0.619141 8 0.619141C8.88816 0.619141 9.46892 1.67817 10.6304 3.79623L14.0638 10.0571C15.15 12.0377 15.693 13.028 15.2574 13.7638C14.8217 14.4996 13.6923 14.4996 11.4334 14.4996H4.56665C2.30772 14.4996 1.17826 14.4996 0.742611 13.7638C0.306962 13.028 0.850044 12.0377 1.93621 10.0571L5.36956 3.79623ZM7.99996 4.74955C8.41417 4.74955 8.74996 5.08534 8.74996 5.49955V7.99955C8.74996 8.41377 8.41417 8.74955 7.99996 8.74955C7.58574 8.74955 7.24996 8.41377 7.24996 7.99955V5.49955C7.24996 5.08534 7.58574 4.74955 7.99996 4.74955ZM8 11.9996C8.55228 11.9996 9 11.5518 9 10.9996C9 10.4473 8.55228 9.99956 8 9.99956C7.44771 9.99956 7 10.4473 7 10.9996C7 11.5518 7.44771 11.9996 8 11.9996Z"
                fill="#E25858"
              />
            </svg>
            {{error.message}}
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <label class="text-dark-8" for="">Password</label>
          <b-input-group size="md">
            <b-form-input autocomplete="do-not-autofill" :type="showPass ? 'text' : 'password'" v-model="password"></b-form-input>
            <b-input-group-append>
              <b-button class="py-1 px-2 d-inline-flex align-items-center justify-content-center position-relative" variant="icon" @click="toggleShowPass">
                <svg v-if="!showPass" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 7C2.25 6.79345 2.34977 6.35431 2.65019 5.77493C2.94087 5.21433 3.39124 4.57815 4.01786 3.98002C5.26173 2.79268 7.20417 1.75 10 1.75C12.7958 1.75 14.7383 2.79268 15.9821 3.98002C16.6088 4.57815 17.0591 5.21433 17.3498 5.77493C17.6502 6.35431 17.75 6.79345 17.75 7C17.75 7.20655 17.6502 7.64569 17.3498 8.22507C17.0591 8.78567 16.6088 9.42185 15.9821 10.02C14.7383 11.2073 12.7958 12.25 10 12.25C7.20417 12.25 5.26173 11.2073 4.01786 10.02C3.39124 9.42185 2.94087 8.78567 2.65019 8.22507C2.34977 7.64569 2.25 7.20655 2.25 7ZM10 0.25C6.79583 0.25 4.48827 1.45732 2.98214 2.89498C2.23376 3.60935 1.68413 4.37942 1.31856 5.08445C0.96273 5.77069 0.75 6.45655 0.75 7C0.75 7.54345 0.96273 8.22931 1.31856 8.91555C1.68413 9.62058 2.23376 10.3907 2.98214 11.105C4.48827 12.5427 6.79583 13.75 10 13.75C13.2042 13.75 15.5117 12.5427 17.0179 11.105C17.7662 10.3907 18.3159 9.62058 18.6814 8.91555C19.0373 8.22931 19.25 7.54345 19.25 7C19.25 6.45655 19.0373 5.77069 18.6814 5.08445C18.3159 4.37942 17.7662 3.60935 17.0179 2.89498C15.5117 1.45732 13.2042 0.25 10 0.25ZM8.25 7C8.25 6.0335 9.0335 5.25 10 5.25C10.9665 5.25 11.75 6.0335 11.75 7C11.75 7.9665 10.9665 8.75 10 8.75C9.0335 8.75 8.25 7.9665 8.25 7ZM10 3.75C8.20507 3.75 6.75 5.20507 6.75 7C6.75 8.79493 8.20507 10.25 10 10.25C11.7949 10.25 13.25 8.79493 13.25 7C13.25 5.20507 11.7949 3.75 10 3.75Z" fill="currentColor"/>
                </svg>
                <svg v-if="showPass" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.99988 3L16.9999 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 4.17947C8.61797 4.06407 9.28407 4 10 4C16 4 18.5 8.5 18.5 10C18.5 10.6561 18.0217 11.8861 16.9815 13.0625M5 5.29808C2.57295 6.74711 1.5 9.01732 1.5 10C1.5 11.5 4 16 10 16C11.8596 16 13.3829 15.5678 14.5999 14.9265" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.7678 11.7678C10.7915 12.7441 9.20854 12.7441 8.23223 11.7678C7.25592 10.7915 7.25592 9.20854 8.23223 8.23223" stroke="currentColor" stroke-width="1.5"/>
                </svg>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button
            size="md"
            variant="outline-secondary"
            @click="hideModal"
            class="mr-3"
            >Cancel</b-button
          >
          <b-button
            size="md"
            @click="createSubAccount"
            variant="primary"
            :disabled="name.length < 1 || email.length < 1 || password.length < 1"
            >Create Sub-Account</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import UserService from "@/services/UserService"

export default {
  name: "AddSubAccountModal",
  components: {},
  props: {
    
  },
  data() {
    return {
      name: '',
      email: '',
      error: false,
      password: '',
      showPass: false
    };
  },
  updated() {
    
  },
  computed: {
    
  },
  watch: {
    email() {
      this.error = false
    }
  },
  methods: {
    toggleShowPass() {
      this.showPass = !this.showPass
    },
    async checkEmail() {
      const result = await UserService.checkEmail(this.email)
      console.log(result)
    },
    async createSubAccount() {
        try {
        const newSubAccount = await UserService.createSubAccount(this.name, this.email, this.password)
        this.$emit('sub-account-created', newSubAccount)
        this.$refs['create-subaccount-modal'].hide()
      } catch (error) {
        this.error = error
      } 
    },
    hideModal() {
      this.error = false
      this.email = '',
      this.password = '',
      this.name = '',
      this.$refs['create-subaccount-modal'].hide()
    }
	},
};
</script>

<style scoped>

</style>
