<template>
	<div class="mt-2">
		<div class="feature-item">
			<b-row align-v="center">
				<b-col cols="9" md="8">
					<h4 class="text-s-m font-weight-normal mb-0">Geo/Country Blocking</h4>
					<p class="m-0 text-s-s text-dark-7">Prevent (ban) viewers from specific country(ies).</p>
				</b-col>
				<b-col cols="3" md="4" class="text-right">
					<b-form-checkbox v-if="dynamicPlayerFeatures && dynamicPlayerFeatures['geo-block']" v-model="features.countryBlock.enabled" :disabled="featureProcessing.countryBlock"
						@change="toggleFeature('countryBlock')" switch>
					</b-form-checkbox>
					<span id="geo-block-tooltip" v-if="dynamicPlayerFeatures && !dynamicPlayerFeatures['geo-block']">
						<b-form-checkbox class="d-inline-block" disabled switch />
					</span>
					<b-tooltip v-if="dynamicPlayerFeatures && !dynamicPlayerFeatures['geo-block']" target="geo-block-tooltip">
						Please <a class="text-underline text-reset" target="_blank" href="/app/subscribe">upgrade</a> subscription to use this feature.
					</b-tooltip>
				</b-col>
			</b-row>
			<b-row class="toggle-hide" v-if="features.countryBlock.enabled">
				<b-col>
					<div v-if="!countryListLoaded">
						<i class="fas fa-spinner fa-spin loader" /> working ..
					</div>
					<div class="mt-2" v-else inline>
						<multiselect v-model="countryBlockSelection" :options="countryList" :multiple="true" :taggable="true"
							tag-placeholder="Unsupported region" placeholder="Search for a country" label="name" track-by="code"
							class="col col-md-11 p-0 select_md mr-2 mb-2" @tag="updateGeoBlockSelection" />
						<b-button :disabled="featureProcessing.countryBlock || !canSaveGeoBlocking" variant="dark-5" size="md"
							@click="saveGeoBlocking">
							{{ featureProcessing.countryBlock ? 'saving ..' : 'Save' }}
						</b-button>
					</div>
				</b-col>
			</b-row>
		</div>
		<div class="feature-item">
			<b-row class="align-items-center">
				<b-col cols="9" md="8">
					<h4 class="text-s-m font-weight-normal mb-0">Geo/Country Whitelisting</h4>
					<p class="m-0 text-s-s text-dark-7">Allow viewers only from specific country(ies).</p>
				</b-col>
				<b-col cols="3" md="4" class="text-right">
					<b-form-checkbox v-if="dynamicPlayerFeatures && dynamicPlayerFeatures['geo-whitelist']" v-model="features.countryWhitelists.enabled" :disabled="featureProcessing.countryWhitelists"
						@change="toggleFeature('countryWhitelists')" switch>
					</b-form-checkbox>
					<span id="geo-block-tooltip2" v-if="dynamicPlayerFeatures && !dynamicPlayerFeatures['geo-whitelist']">
						<b-form-checkbox class="d-inline-block" disabled switch />
					</span>
					<b-tooltip v-if="dynamicPlayerFeatures && !dynamicPlayerFeatures['geo-whitelist']" target="geo-block-tooltip2">
						Please <a class="text-underline text-reset" target="_blank" href="/app/subscribe">upgrade</a> subscription to use this feature.
					</b-tooltip>
				</b-col>
			</b-row>
			<b-row class="toggle-hide" v-if="features.countryWhitelists && features.countryWhitelists.enabled">
				<b-col>
					<div v-if="!countryListLoaded">
						<i class="fas fa-spinner fa-spin loader" /> working ..
					</div>
					<div class="mt-2" inline v-else>
						<multiselect v-model="countryWhitelistSelection" :options="countryList" :multiple="true" :taggable="true"
							tag-placeholder="Unsupported region" placeholder="Search for a country" label="name" track-by="code"
							class="col col-md-11 p-0 select_md mr-2 mb-2" @tag="updateGeoBlockSelection" />
						<b-button :disabled="featureProcessing.countryWhitelists" @click="saveGeoWhitelisting" variant="dark-5"
							size="md">
							{{ featureProcessing.countryWhitelists ? 'saving ..' : 'Save' }}
						</b-button>
					</div>
				</b-col>
			</b-row>
		</div>
		<div class="feature-item">
			<b-row class="align-items-center">
				<b-col cols="9" md="8">
					<h4 class="text-s-l font-weight-normal mb-0">Enable Domain Whitelisting</h4>
					<p class="m-0 text-s-s text-dark-7">Allow the embedded player to be watched only on specific domains.</p>
				</b-col>
				<b-col cols="3" md="4" class="text-right">
					<b-form-checkbox v-if="dynamicPlayerFeatures && dynamicPlayerFeatures['domain-whitelist']" v-model="features.domainWhitelists.enabled" :disabled="featureProcessing.domainWhitelists"
						@change="toggleFeature('domainWhitelists')" switch>
					</b-form-checkbox>
					<span id="domain-whitelist-tooltip2" v-if="dynamicPlayerFeatures && !dynamicPlayerFeatures['domain-whitelist']">
						<b-form-checkbox class="d-inline-block" disabled switch />
					</span>
					<b-tooltip v-if="dynamicPlayerFeatures && !dynamicPlayerFeatures['domain-whitelist']" target="domain-whitelist-tooltip2">
						Please <a class="text-underline text-reset" target="_blank" href="/app/subscribe">upgrade</a> subscription to use this feature.
					</b-tooltip>
				</b-col>
			</b-row>
			<b-row class="toggle-hide" v-if="features.domainWhitelists.enabled">
				<b-col>
					<div v-if="!whitelistedDomainsLoaded">
						<i class="fa fa-spinner fa-spin" />
					</div>
					<div class="mt-2" v-else>
						<!-- v-model="features.domainWhitelists.value"  -->
						<b-form-textarea v-model="whitelistedDomains" class="mb-2" placeholder="one domain per line"
							autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" rows="4" />
						<div>
							<b-button :disabled="!features.domainWhitelists.value" class="ml-0 mt-1" variant="dark-5"
								@click="saveWhitelistedDomains">
								{{ featureProcessing.domainWhitelists ? 'saving ..' : 'Save' }}
							</b-button>
						</div>
					</div>
				</b-col>
			</b-row>
			<div class="feature-desc">
				<div v-if="features.domainWhitelists.enabled" class="pane">
					<p class="mt-3">
						Whitelisted Domains
						<small class="ml-1">(one domain per line)</small>
					</p>
					<div class="text-s-m">
						example:
						<br>
						<code>https://example.com</code>
						<br>
						<code>https://mysite.com</code>
						<br>
						<code>https://analytics-site.net</code>
					</div>
				</div>
			</div>
		</div>
		<confirm-modal
			modal-id="feature-upgrade"
			ok-text="Upgrade Now"
			cancel-text="Later"
			@modal-confirm="navigateToPayments(stream.type)">
			<p class="mb-0 text-s-m"><a target="_blank" class="text-reset text-underline" href="/app/subscribe">Upgrade</a> to access this feature. Check <a  target="_blank" class="text-reset text-underline text-nowrap" href="https://castr.com/pricing">Pricing page</a> to know more about eligible plan.</p>
		</confirm-modal>
	</div>
</template>

<script>
import _ from 'lodash'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import DynamicService from '@/services/DynamicService'
import IntegrationService from '@/services/IntegrationService'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
	name: 'DynamicPlayerSecurity',
	components: {
		ConfirmModal,
		Multiselect
	},
	data() {
		return {
			processing: true,
			processingMessage: null,
			countryBlockSelection: [],
			countryWhitelistSelection: [],
			countryList: [],
			countryListLoaded: false,
			whitelistedDomains: [],
			whitelistedDomainsLoaded: false,
			features: {
				countryBlock: {
					enabled: false,
					value: [],
					valueType: 'array'
				},
				countryWhitelists: {
					enabled: false,
					value: [],
					valueType: 'array'
				},
				domainWhitelists: {
					enabled: false,
					value: [],
					valueType: 'array'
				}
			},
			featureProcessing: {
				countryBlock: false,
				countryWhitelists: false,
				domainWhitelists: false
			}
		};
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			player: "DynamicPlayers/dynamicPlayer",
			dynamicPlayerFeatures: "User/dynamicPlayerFeatures",
		}),
		canSaveGeoBlocking: () => true,
		productsFeatures() {
			return this.$store.state.User.aggregatedDefinition;
		}
	},
	watch: {
		player() {
			if (this.player?.metadata?.countryWhitelists?.length > 0) {
				this.features.countryWhitelists.enabled = true
				this.features.countryWhitelists.value = this.player.metadata.countryWhitelists
				const countries = this.countryList.filter( country => this.player.metadata.countryWhitelists.find(item => country.code === item))
				this.countryWhitelistSelection = countries
			}
			if (this.player?.metadata?.countryBlock?.length > 0) {
				this.features.countryBlock.enabled = true
				this.features.countryBlock.value = this.player.metadata.countryBlock
				const countries2 = this.countryList.filter( country => this.player.metadata.countryBlock.find(item => country.code === item))
				this.countryBlockSelection = countries2
			}
			if (this.player?.metadata?.domainWhitelists?.length > 0) {
				this.features.domainWhitelists.enabled = true
				this.features.domainWhitelists.value = this.player.metadata.domainWhitelists
				// const countries2 = this.countryList.filter( country => this.player.metadata.countryBlock.find(item => country.code === item))
				this.whitelistedDomains = this.player.metadata.domainWhitelists.join( '\n' );
			}
		}
	},
	async mounted() {
		this.countryList = await IntegrationService.getCountryList();
		this.countryListLoaded = true;

		this.countryBlockSelection = _.map(
			this.features.countryBlock.value,
			code => _.find(this.countryList, { code })
		);
		this.countryWhitelistSelection = _.map(
			this.features.countryWhitelists.value,
			code => _.find(this.countryList, { code })
		);

		this.whitelistedDomains = _.join(
			_.filter(this.features.domainWhitelists.value, item => item !== 'https://castr.com'),
			'\r\n',
		);
		this.whitelistedDomainsLoaded = true;
	},
	destroyed() {

	},
	methods: {
		async toggleFeature(featureName) {
			if (this.featureProcessing[featureName]) return;

			const feature = this.features[featureName];
			if (!feature) return;

			let mappedSecurityFeatName = featureName;
			switch (featureName) {
				case 'embedPassword':
					mappedSecurityFeatName = 'securityFeaturesPasswordAuth';
					break;
				case 'countryBlock':
					mappedSecurityFeatName = 'securityFeaturesGeoBlock';
					break;
				case 'countryWhitelists':
					mappedSecurityFeatName = 'securityFeaturesGeoWhitelist';
					break;
				case 'domainWhitelists':
					mappedSecurityFeatName = 'securityFeaturesDomainWhitelist';
					break;
			}

			// Disable geo-blocking/whitelisting on those packages
			const data = _.filter(this.$store.state.User.subscriptions, (item) => item.category === 'live' && item.enabled)[0];
			const disabledGeoFeaturePackages = [
				'5c6e7405b408c1b41dd32f46', // 1TB Bandwidth
				'5c6e7405b408c1b41dd32f47', // 1TB Bandwidth - Yearly
				'5c6e7406b408c1b41dd32f48', // 2TB Bandwidth
				'5c6e7406b408c1b41dd32f49', // 2TB Bandwidth - Yearly
				'5ccd92f8152b7f25111c39cf', // Live 5GB Bandwidth - 7 Day Trial
				'6362114709950a6a642540c3', // New All in One - 7 Days Trial
				'6010063d03afd67aa9831cd4', // All-In-One Entry Monthly
				'6361ecd609950a6a642540b7', // New All-In-One Entry Monthly
				'6018dbb703afd67aa9831ce3', // All-In-One Entry Yearly
				'6361ecd609950a6a642540b8', // New All-In-One Entry Yearly
				'636dcc7ced167e4759926fd9', // BF22 - All in One - Entry Yearly
				'6018dc7403afd67aa9831ce4', // All-In-One Plus Monthly
				'6361ecd609950a6a642540b9', // New All-In-One Plus Monthly
				'602cc7a503afd67aa9831ced', // All-In-One Plus Yearly
				'6361ecd609950a6a642540ba', // New All-In-One Plus Yearly
				'602cc91703afd67aa9831cf2', // All-In-One Premium Monthly
				'6361ecd609950a6a642540bf', // New All-In-One Premium Monthly
				'602cc95903afd67aa9831cf3', // All-In-One Premium Yearly
				'6361ecd609950a6a642540c0', // New All-In-One Premium Yearly
				]
			if (data && disabledGeoFeaturePackages.indexOf(data.package._id) !== -1
				&& (['countryBlock', 'countryWhitelists'].indexOf(featureName) !== -1)
				&& !this.features.countryBlock.enabled
			) {
				this.$root.$emit('bv::show::modal', 'feature-upgrade');
				setTimeout(() => {
					this.features[featureName].enabled = !this.features[featureName].enabled;
				}, 500);
				
				return;
			}

			const trialAndAIOEntryPackages = [
				'5ccd92f8152b7f25111c39cf', // Live 5GB Bandwidth - 7 Day Trial
				'6362114709950a6a642540c3', // New All in One - 7 Days Trial
				'6010063d03afd67aa9831cd4', // All-In-One Entry Monthly
				'6361ecd609950a6a642540b7', // New All-In-One Entry Monthly
				'6018dbb703afd67aa9831ce3', // All-In-One Entry Yearly
				'6361ecd609950a6a642540b8', // New All-In-One Entry Yearly
				'636dcc7ced167e4759926fd9', // BF22 - All in One - Entry Yearly
			]
			if (data && trialAndAIOEntryPackages.indexOf(data.package._id) !== -1
				&& (['domainWhitelists'].indexOf(featureName) !== -1)
				&& !this.features.domainWhitelists.enabled
			) {
				this.$root.$emit('bv::show::modal', 'feature-upgrade');
				setTimeout(() => {
					this.features[featureName].enabled = !this.features[featureName].enabled;
				}, 500);
				
				return;
			}

			if (!this.productsFeatures) {
				this.$root.$emit('bv::show::modal', 'feature-upgrade');
				setTimeout(() => {
					this.features[featureName].enabled = !this.features[featureName].enabled;
				}, 500);
				return;
			}

			let nstate = feature.enabled;
			feature.enabled = nstate;

			if (feature.valueType === 'bool' || !nstate) {
				if (feature.valueType === 'array' && !nstate) {
					nstate = [];
				}

				await this.saveSetting(featureName, nstate);
			}
		},
		async saveGeoBlocking() {
			const updatedSettings = this.countryBlockSelection;
			await this.saveSetting('countryBlock', _.map(updatedSettings, 'code'));
		},
		async saveGeoWhitelisting() {
			const updatedSettings = this.countryWhitelistSelection;
			await this.saveSetting(
				'countryWhitelists',
				_.map(updatedSettings, 'code')
			);
		},
		async saveWhitelistedDomains() {
			let domains = _.split(this.whitelistedDomains, '\n')
				.map(d => d.trim())
				.filter(Boolean);
			const castrExist = domains.find((item) => item === 'https://castr.com');
			if (_.isEmpty(castrExist)) {
				domains = [...domains, 'https://castr.com'];
			}
			if (!domains.length) return;

			await this.saveSetting('domainWhitelists', domains);
		},
		async saveSetting(key, value) {
			this.featureProcessing[key] = true;
			try {
				await DynamicService.savePlayerMetadata(this.player._id, key, value)

				// clear stream meta cache in embed player
				DynamicService.clearPlayerMetadataCache(this.player._id)
				// await this.$store.dispatch('DynamicPlayers/getPlayer', this.$route && this.$route.params.playerId);
			} catch (e) {
				console.log(e);
				this.$notify({ group: 'error', text: 'could not save changes' })
			}
			this.featureProcessing[key] = false;
		},
		updateGeoBlockSelection() {
			// console.log('countryNode', countryNode)
		},
		navigateToPayments (category = 'vod') {
			// Redirect to correct live or vod package
			if (category !== 'live' && category !== 'vod') {
				category = 'vod'
			}
			if(category === 'vod'){
				category = 'live'
			}
			this.$router.push({ name: 'Payments', query: { category } });
		},
	},
}

</script>

<style scoped></style>