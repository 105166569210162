import _ from "lodash";
import RequestError from "./RequestError";
import amProxyUrl from "./AmProxyUrl";
import api from "@/api";

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

export default {
  getAccessTokens,
  createAccessToken,
  deleteAccessToken,
  updateAccessToken,
};

function getAccessTokens() {
	return makeRequest({
		path: '/access_tokens',
		method: 'get',
	});
}

/**
 * @param {object} payload
 */
async function createAccessToken(payload) {
	return makeRequest({
		path: '/access_tokens',
		method: 'post',
    data: payload
	});
}

/**
 * @param {string} id
 * @param {string} name
 */
async function updateAccessToken(id, name) {
	return makeRequest({
		path: `/access_tokens/${id}`,
		method: 'patch',
    data: {
      name,
    }
	});
}

/**
 * @param {string} id
 */
async function deleteAccessToken(id) {
	return makeRequest({
		path: `/access_tokens/${id}?delete=true`,
		method: 'delete',
	});
}


/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeRequest(reqConfig, preventProxy, throwReal) {
  if (typeof reqConfig === "string") {
    reqConfig = {
      path: reqConfig,
    };
  }

  if (preventProxy !== true) {
    reqConfig = amProxyUrl(reqConfig, process.env.VUE_APP_VOD_API_BASE_URL);
  }

  reqConfig.url = reqConfig.path;

  let res;
  try {
    res = await api.request(reqConfig);
  } catch (err) {
    if (throwReal) {
      throw err;
    }
    const edata = _.get(err, "response.data");
    throw new RequestError(edata);
  }

  return res && res.data;
}
