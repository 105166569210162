<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="30"
		height="28"
		viewBox="0 0 30 28"
	>
		<path
			fill="none"
			fill-rule="evenodd"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="3"
			d="M870 160v23.88l20.745-12.668z"
			transform="translate(-868 -158)"
		/>
	</svg>
</template>
