<template>
	<section class="platform-details bg-dark-2 text-dark-9" :class="{ 'show-details': isPlatformSelected }">
		<header class="platform-details-header d-flex justify-content-center align-items-center bg-dark-4">
			<b-img v-if="!isCustomPlatform && selectedPlatform" width="100" :src="selectedPlatform.icon"
				:alt="selectedPlatform.name" />
			<icon-base v-else width-view-box="24" height-view-box="24" iconColor="none" width="24" height="24"
				class="text-white">
				<icon-settings />
			</icon-base>
		</header>
		<main class="px-4">
			<section>
				<b-row>
					<b-col>
						<b-alert v-if="errors.response" show variant="danger" class="mt-3">
							<div>{{ errors.response }}</div>
						</b-alert>
					</b-col>
				</b-row>
			</section>
			<section v-if="selectedPlatform">
				<b-row>
					<b-col v-if="selectedPlatform.servers && selectedPlatform.servers.length > 1">
						<div class="">
							<label class="mt-5 w-100">
								streaming server
							</label>
							<b-dropdown class="w-100" variant="dark-3" menu-class="server-list dropdown-menu_md" no-caret size="md">
								<template #button-content>
									<div class="d-flex align-items-center justify-content-between w-100">
										<span class="text-dark-8 font-weight-normal text-s-m">{{ server && server.text ? server.text : 'Select one'
										}}</span>
										<icon-base width-view-box="10" height-view-box="6" iconColor="none" width="10" height="6"
											class="text-dark-8">
											<icon-caret-down />
										</icon-base>
									</div>
								</template>
								<b-dropdown-item @click="selectServer(item)" :key="item.id" v-for="item in selectedPlatform.servers">
									{{ item.text }}
								</b-dropdown-item>
							</b-dropdown>
						</div>
					</b-col>
					<b-col v-else-if="selectedPlatform.customServer">
						<label class="mt-5">
							streaming server
						</label>
						<div class="position-relative">
							<b-input v-model="server.value"
							:placeholder="selectedPlatform.serverInputPlaceholder || 'rtmp://broadcaster_addr/'" size="md"
							@blur="validate('server')" :class="{ error: errors.server }" />
							<p v-if="errors.server" class="text-danger text-s-xs position-absolute top-0 error-message m-0">Invalid destination ingest address</p>
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<label :class="!selectedPlatform.customServer && selectedPlatform.servers.length < 2 ? 'mt-5' : 'mt-3'">
							streaming key
						</label>
						<b-input v-model="key" placeholder="#streaming_key" size="md" class="mb-3" />
					</b-col>
				</b-row>
			</section>
			<PlatformDetailsCustom v-else-if="isCustomPlatform" @platform-details="setCustomPlatform" />
			<section class="mt-5">
				<b-row>
					<b-col>
						<b-button :disabled="isDisabled" variant="primary" size="md" block @click="save">
							Create Destination
						</b-button>
						<b-button variant="outline-secondary" size="md" block class="mt-2" @click="cancel">
							Cancel
						</b-button>
					</b-col>
				</b-row>
			</section>
		</main>
	</section>
</template>

<script>
import utils from '@/utils'
import { mapGetters } from 'vuex'

import IconBase from '@/components/icon/IconBase.vue'
import IconSettings from '@/components/icon/IconSettings.vue'
import IconCaretDown from '@/components/icon/IconCaretDown.vue'
import PlatformDetailsCustom from '@/components/PlatformDetailsCustom'

export default {
	name: 'PlatformDetails',
	components: {
		IconBase,
		IconSettings,
		IconCaretDown,
		PlatformDetailsCustom
	},
	props: {
		close: {
			type: Function
		}
	},
	data() {
		return {
			server: {
				text: null,
				value: null,
			},
			key: null,
			mode: null,
			latency: null,
			port: null,
			passphrase: null,
			errors: {
				response: null,
				server: false,
			}
		};
	},
	computed: {
		...mapGetters({
			isPlatformSelected: "Destination/isPlatformSelected",
			selectedPlatform: "Destination/selectedPlatform",
			isCustomPlatform: "Destination/isCustomPlatform",
			isDisabled: "Destination/isDisabled",
		}),
	},
	watch: {
		isPlatformSelected() {
			this.clear()
		},
		key() {
			this.$store.dispatch('Destination/setKey', this.key)
		},
		'server.value'() {
			this.errors.server = false
			if (!this.selectedPlatform?.servers) this.$store.dispatch('Destination/setServer', this.server.value)
		},
		mode() {
			if (this.mode === 'srt') this.$store.dispatch('Destination/setMode', this.mode)
		},
		latency() {
			if (this.mode === 'srt') this.$store.dispatch('Destination/setLatency', this.latency)
		},
		port() {
			if (this.mode === 'srt') this.$store.dispatch('Destination/setPort', this.port)
		},
		passphrase() {
			if (this.mode === 'srt') this.$store.dispatch('Destination/setPassphrase', this.passphrase)
		},
	},
	async mounted() {

	},
	methods: {
		async save() {
			try {
				await this.$store.dispatch('Destination/createDestination')
				setTimeout(() => {
					this.$store.dispatch('Destination/getDestinations')
				}, 1000);
				this.$root.$emit('bv::toggle::collapse', 'add-destination-modal')
			} catch (error) {
				console.log(error);
				this.errors.response = error
			}
			
		},
		cancel() {
			this.$root.$emit('bv::toggle::collapse', 'add-destination-modal')
		},
		clear() {
			this.server = {
				text: null,
				value: null,
			}
			this.key = null
			this.mode = null
			this.latency = null
			this.port = null
			this.passphrase = null
		},
		selectServer(item) {
			this.server = item
			this.$store.dispatch('Destination/setServer', this.server.value)
		},
		setCustomPlatform(item) {
			this.server.value = item.server
			this.key = item.key
			// console.log(item);
			if (item?.mode === 'srt') {
				this.mode = 'srt'
				this.latency = item.latency
				this.port = item.port
				this.passphrase = item.passphrase
			}
		},
		validate(type) {
			if (type === 'server' && this.server?.value) {
				const { valid } = utils.validateURL(this.server?.value, { validatePathname: false })
				valid
					? this.errors[type] = false
					: this.errors[type] = true
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.platform-details {
	position: absolute;
	top: 0;
	margin: auto;
	width: 408px;
	height: 100%;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, left 0.3s ease-out;
	left: 0;
	z-index: -1;
	border-left: 1px solid var(--c-dark-3);
	border-right: 1px solid var(--c-dark-3);
}

.show-details.platform-details {
	left: 516px;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, left 0.3s ease-out;
}

.platform-details-header {
	height: 64px;
}

:deep(.server-list) {
	max-height: 300px;
	overflow-y: auto;
	overflow-x: hidden;
}
</style>