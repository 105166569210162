<template>
	<div class>
		<!-- Modal Component -->
		<b-modal 
			id="edit-destination-modal" 
			ref="modalEditDestination" 
			size="sm" centered hide-footer hide-header
			@hidden="clear"
			@shown="getDestination"
			>
			<b-row>
				<b-col>
					<h4 class="text-s-xl font-weight-normal mb-4" v-html="modalTitle" />
				</b-col>
			</b-row>
			<b-alert v-if="error" show variant="danger" class="mt-3">
				<div>{{ error }}</div>
			</b-alert>
			<b-row v-if="destinationPlatform && destinationPlatform.servers && destinationPlatform.servers.length > 1">
				<b-col>
					<div class="">
						<label class="w-100">
							streaming server
						</label>
						<b-dropdown class="w-100 mb-3" variant="dark-3" menu-class="server-list dropdown-menu_md" no-caret size="md">
							<template #button-content>
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="text-dark-8 font-weight-normal text-s-m text-truncate">{{ selectedServer }}</span>
									<icon-base width-view-box="10" height-view-box="6" iconColor="none" width="10" height="6"
										class="text-dark-8">
										<icon-caret-down />
									</icon-base>
								</div>
							</template>
							<b-dropdown-item @click="selectServer(item)" :key="item.id" v-for="item in destinationPlatform.servers">
								{{ item.text }}
							</b-dropdown-item>
						</b-dropdown>
					</div>
				</b-col>
			</b-row>
			<b-row v-else-if="destinationPlatform && destinationPlatform.customServer || isCustom">
				<b-col>
					<label>
						{{ isCustom ? 'Streaming platform address' : 'Streaming server' }}
					</label>
					<b-input 
						v-model="server"
						@input="clearError"
						:placeholder="destinationPlatform && destinationPlatform.serverInputPlaceholder || 'rtmp://broadcaster_addr/'"
						size="md" class="mb-3" />
				</b-col>
			</b-row>
			<template v-if="isCustom && !isCustomSrt">
				<b-row>
					<b-col>
						<b-form-checkbox class="mb-3" v-model="isAuthRequired">Authentication Required
						</b-form-checkbox>
					</b-col>
				</b-row>
				<b-row v-if="isAuthRequired">
					<b-col cols="12">
						<b-input v-model="username" placeholder="Username" size="md" class="mb-3" />
					</b-col>
					<b-col cols="12">
						<b-input type="password" v-model="password" placeholder="Password" size="md" class="mb-3" />
					</b-col>
				</b-row>
			</template>
			<template v-else-if="isCustom">
				<b-row>
					<b-col cols="12">
						<b-input v-model="port" placeholder="Port" size="md" class="mb-3" />
					</b-col>
					<b-col cols="12">
						<b-input v-model="latency" placeholder="Latency" size="md" class="mb-3" />
					</b-col>
					<b-col cols="12">
						<b-input v-model="passphrase" placeholder="Passphrase" size="md" class="mb-3" />
					</b-col>
				</b-row>
			</template>
			<b-row>
				<b-col>
					<label>
						Streaming key
					</label>
					<b-input v-model="key" placeholder="#streaming_key" size="md" class="mb-3" />
				</b-col>
			</b-row>
			<b-row class="mt-4" align-h="end">
				<b-col cols="auto" class="pr-0">
					<b-button @click="updateDestination" variant="primary" size="md" :disabled="(error || !server || server?.length === 0) ? true : false">
						Update
					</b-button>
				</b-col>
				<b-col cols="auto">
					<b-button @click="closeModal" variant="outline-secondary" size="md">
						Cancel
					</b-button>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex'

import DestinationService from "@/services/DestinationService"

import IconBase from '@/components/icon/IconBase.vue'
import IconCaretDown from '@/components/icon/IconCaretDown.vue'

export default {
	name: 'EditDestinationModal',
	components: {
		IconBase,
		IconCaretDown
	},
	props: {
		destination: {
			type: Object,
			required: false
		},

	},
	data() {
		return {
			server: null,
			key: null,
			mode: null,
			latency: null,
			port: null,
			passphrase: null,
			isAuthRequired: false,
			username: null,
			password: null,
			error: null,
		};
	},
	computed: {
		...mapGetters({
			platforms: "Destination/platforms",
		}),
		destinationPlatform() {
			return this.platforms.find(item => item.name === this.destination?.template)
		},
		isCustom() {
			return this.destination?.template === 'custom'
				? true
				: false
		},
		isCustomSrt() {
			return this.destination?.template === 'custom' && this.destination?.mode === 'srt'
				? true
				: false
		},
		modalTitle() {
			if (this.destination?.template === 'custom') {
				return 'Custom Server'
			} else {
				const template = this.platforms.find(({ name }) => name === this.destination?.template)
				return template && template.icon ? `<img src="${template.icon}" class="picon"/>` : null
			}
		},
		selectedServer() {
			return this.destinationPlatform?.servers?.length > 1
				? this.destinationPlatform?.servers?.find(server => server.value === this.server)?.text
				: this.server
		},
	},
	watch: {
		destination: {
			handler: function (item) {
				this.error = null
				const serverUrl = item.server.match(/:\/\/(.*?):(.*?)@/)
				if (serverUrl) {
					this.isAuthRequired = true
					this.username = decodeURIComponent(serverUrl[1])
					this.password = decodeURIComponent(serverUrl[2])
				}
			},
			deep: true
		},
	},
	async mounted() {

	},
	methods: {
		selectServer(item) {
			this.server = item.value
		},
		getDestination() {
			this.key = this.destination.key
			this.server = this.destination.server
			if (this.destination.mode) this.mode = this.destination.mode
			if (this.destination.port) this.port = this.destination.port
			if (this.destination.latency) this.latency = this.destination.latency
			if (this.destination.passphrase) this.passphrase = this.destination.passphrase
		},
		async updateDestination() {
			const { _id, name } = this.destination
			try {
				if (this.isCustom && this.isCustomSrt) {
				await DestinationService.updateDestination(_id, name, this.server, this.key, this.port, this.latency, this.passphrase)
			} else {
				await DestinationService.updateDestination(_id, name, this.server, this.key)
			}
				await this.$store.dispatch('Destination/getDestinations')
				this.$notify({ group: 'success', text: 'Destination changed' })
				this.closeModal()
			} catch (error) {
				this.error = error
			}
		},
		clear() {
			this.server = null
			this.key = null
			this.mode = null
			this.latency = null
			this.port = null
			this.passphrase = null
			this.isAuthRequired = false
			this.username = null
			this.password = null
			this.error = null
		},
		clearError() {
			this.error = null
		},
		closeModal() {
			this.$refs['modalEditDestination'].hide()
		}
	}
};

</script>

<style scoped></style>
