import StreamService from "@/services/StreamService";
import router from "@/router";
import _ from "lodash";
import { sortStreams } from "../../utils";

const defaultState = () => ({
  streams: [],
  vodFiles: [],
  sortBy: "Sort by Oldest",
});

const state = defaultState();

const mutations = {
  GET_STREAMS(state, streams) {
    state.streams = streams.sort(function(a, b) {
      return new Date(b.creationTime) - new Date(a.creationTime);
    });
  },
  GET_VOD_FILES(state, files) {
    state.vodFiles = files.sort(function(a, b) {
      return new Date(b.creationTime) - new Date(a.creationTime);
    });
  },
  SET_SORT_OPTION(state, option) {
    state.sortBy = option;
  },
  SORT_STREAMS(state, option) {
    state.streams = state.streams.sort(function(a, b) {
      if (option == "Newest first") {
        return new Date(b.creationTime) - new Date(a.creationTime);
      }
      if (option == "Oldest first") {
        return new Date(a.creationTime) - new Date(b.creationTime);
      }
    });
  },
  SEARCH_LIVE(state, searchStr) {
    state.streams = state.streams.filter((s) => {
      const search = searchStr.toLowerCase();
      let bool = s.name.toLowerCase().includes(search);
      return bool;
    });
  },
  UPDATE_STREAMS(state, updatedStreams) {
    state.streams = updatedStreams
  },
};

const actions = {
  async getStreams({ commit }) {
    const streams = await StreamService.getUserStreams();
    commit("GET_STREAMS", streams);
  },
  updateStreams({ commit }, updatedStreams) {
    commit("UPDATE_STREAMS", updatedStreams);
  },
  async getFilesInVODFolder({ commit }, drop) {
    if (drop) {
      commit("GET_VOD_FILES", []);
    } else {
      let streamId = router.currentRoute.value.params?.streamId
      const files = await StreamService.getStreamPlaylist(streamId);
      commit("GET_VOD_FILES", files);
    }
  },
  async getVodFilesByVodId({ commit }, vodId) {
    if (vodId) {
      const files = await StreamService.getStreamPlaylist(vodId);
      commit("GET_VOD_FILES", files);
    } else {
      commit("GET_VOD_FILES", []);
    }
  },
  async setSortOption({ commit }, option) {
    commit("SET_SORT_OPTION", option);
  },
  async sortStreams({ commit }, option) {
    commit("SORT_STREAMS", option);
  },
  async searchLive({ commit }, searchStr) {
    commit("SEARCH_LIVE", searchStr);
  },
};

const getters = {
  streams: (state) => {
    const streams = state.streams
    return sortStreams(streams, state.sortBy)
  },
  multiStreams: (state) =>
    state.streams.filter((stream) => stream.type === "restream"),
  allInOneStreams: (state) =>
    state.streams.filter((stream) => stream.type === "live"),
  subSecondStreams: (state) => {
    const subSecondStreams = state.streams.filter(
      (stream) => stream.type === "subsecond"
    )
    return sortStreams(subSecondStreams, state.sortBy)
  },
  vodStreams: (state) =>
    state.streams.filter((stream) => stream.type === "vod"),
  vodStream: (state) =>
    state.streams.find(
      (stream) => stream._id === router.currentRoute.params?.streamId
    ),
  routerId: (state) => {
    return router.currentRoute.params?.streamId;
  },
  realVodStreams: (state) =>
    state.streams.filter(
      (stream) => stream.type === "vod" && !stream.liveParent
    ),
  vodFiles: (state) => {
		const lowerCaseStreams = state.vodFiles.map((item) => ({ ...item,	lowerCaseName: _.lowerCase(item.fileName) }));
    return sortStreams(lowerCaseStreams, state.sortBy, true)
  },
  scheduledStreams: (state) => {
    const scheduledStreams = state.streams.filter((stream) => stream.type === "scheduled")
    return sortStreams(scheduledStreams, state.sortBy)
  },
  hasVodFiles: (state, getters) => {
    let videoFilesNumber = 0;
    getters.vodStreams.map(
      (stream) => (videoFilesNumber = videoFilesNumber + stream.totalFiles)
    );
    if (videoFilesNumber > 0) {
      return true;
    } else return false;
  },
  tvPlayouts: (state) => {
    const playouts = state.streams.filter(
      (stream) => stream.type === "tvplayout"
    )
    return sortStreams(playouts, state.sortBy)
  },
  tvPlayout: (state) => {
    return state.streams.find(
      (stream) => stream._id === router.currentRoute.value.params?.tvId
    )
  }
    ,
  totalVodBytes: (state, getters) => {
    const sum = getters.vodStreams.reduce(
      (accum, item) => accum + item.totalBytes,
      0
    );
    return sum / 1024 ** 3;
  },
  totalRealVodBytes: (state, getters) => {
    const sum = getters.realVodStreams.reduce(
      (accum, item) => accum + item.totalBytes,
      0
    );
    return sum / 1024 ** 3;
  },
  totalVodFiles: (state, getters) => {
    const sum = getters.realVodStreams.reduce(
      (accum, item) => accum + item.totalFiles,
      0
    );
    return sum;
  },
	sortBy: (state) => state.sortBy
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
