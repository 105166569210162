import PaywallService from "@/services/PaywallService";
import router from "@/router";

const defaultState = () => ({
  paywalls: [],
  paywall: {},
  paywallLoaded: false
});

const state = defaultState();

const mutations = {
  GET_PAYWALLS(state, paywalls) {
    state.paywalls = paywalls
  },
  GET_PAYWALL(state, paywall) {
    state.paywall = paywall
    state.paywallLoaded = true
  },
  SET_PAYWALL_SOURCE_STREAM(state, { id, stream }) {
    const objIndex = state.paywalls.findIndex(obj => { 
      return Number(obj.id) === Number(id) 
    })
    if (objIndex >= 0) {
      state.paywalls[objIndex].source = stream
    }
  },
  SET_PAYWALL_TOGGLE(state, id) {
    const objIndex = state.paywalls.findIndex(obj => { 
      return Number(obj.id) === Number(id) 
    })
    if (objIndex >= 0) {
      const enabled = state.paywalls[objIndex].enabled
      state.paywalls[objIndex].enabled = !enabled
    }
  },
  REMOVE_PAYWALL(state, id) {
    const objIndex = state.paywalls.findIndex(obj => { 
      return Number(obj.id) === Number(id) 
    })
    if (objIndex >= 0) {
      state.paywalls.splice(objIndex, 1)
    }
  },
  UNLOAD_PAYWALL(state) {
    Object.assign(state, defaultState())
  },
  SET_PAYWALL_PROP(state, { id, prop, value }) {
    const objIndex = state.paywalls.findIndex(obj => { 
      return Number(obj.id) === Number(id) 
    })
    if (objIndex >= 0) {
      state.paywalls[objIndex][prop] = value

      // disable popup auth for all other paywall with this stream source
      if (prop === 'popupAuth' && value) {
        const obj = state.paywalls[objIndex]
        if (obj.source) {
          const sourceKey = obj.source.key
          _.map(state.paywalls ?? [], (item) => {
            if (item.source && item.source.key === sourceKey && obj.id !== item.id) {
              item.popupAuth = false
              console.log('disable popupAuth id=', item.id)
            }
          })
        }
      }
    }
  },
};

const actions = {
  async getPaywalls({ commit }, sort = 'asc') {
    const paywalls = await PaywallService.getPaywallStreams(sort)
    commit("GET_PAYWALLS", paywalls)
  },
  async getPaywall({ commit }, id) {
    const paywall = await PaywallService.getStreamPaywall(id)
    commit("GET_PAYWALL", paywall)
  },
  async getPaywallSourceStream({ commit }, id) {
    const stream = await PaywallService.getPaywallStreamSource(id)
    commit("SET_PAYWALL_SOURCE_STREAM", { id, stream })
  },
  setStreamSource({ commit }, { id, stream }) {
    commit("SET_PAYWALL_SOURCE_STREAM", { id, stream })
  },
  setPaywallToggle({ commit }, id) {
    commit("SET_PAYWALL_TOGGLE", id)
  },
  removePaywall({ commit }, id) {
    commit("REMOVE_PAYWALL", id)
  },
  unloadPaywall({ commit }) {
    commit("UNLOAD_PAYWALL")
  },
  setPaywallProp({ commit }, { id, prop, value }) {
    commit("SET_PAYWALL_PROP", { id, prop, value })
  },
};

const getters = {
  paywalls: (state) => state.paywalls,
  paywall: (state) => state.paywall,
  currentPaywall: (state) => state.paywalls?.find((paywall) => paywall?.id?.toString() == router.currentRoute.value.params.paywallId?.toString()),
  paywallLoaded: (state) => state.paywallLoaded,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
