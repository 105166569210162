<template>
	<div class="">
        <b-dropdown :disabled="isDisabled" ref="currency-source" right variant="dark-3" size="md" id="currency-source" menu-class="bg-dark-3 stream-source-menu"
							toggle-class="justify-content-between text-truncate" class="w-100">
            <template #button-content>
                <div class="text-truncate">{{ currencyName }}</div>
            </template>
            <b-dropdown-text text-class="px-0">
                <b-tabs class="tabs-light" nav-class="px-3 tabs-light-border">
                    <div class="px-2 my-3">
                        <!-- <b-input-group size="md" class="bg-dark-5 rounded">
                            <template #prepend>
                                <b-input-group-text class="bg-transparent border-0 text-dark-6">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M2.25 6.75C2.25 4.26472 4.26472 2.25 6.75 2.25C9.23528 2.25 11.25 4.26472 11.25 6.75C11.25 9.23528 9.23528 11.25 6.75 11.25C4.26472 11.25 2.25 9.23528 2.25 6.75ZM6.75 0.75C3.43629 0.75 0.75 3.43629 0.75 6.75C0.75 10.0637 3.43629 12.75 6.75 12.75C8.13653 12.75 9.41322 12.2797 10.4292 11.4899L13.9697 15.0303C14.2626 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2626 15.0303 13.9697L11.4899 10.4292C12.2797 9.41322 12.75 8.13653 12.75 6.75C12.75 3.43629 10.0637 0.75 6.75 0.75Z"
                                            fill="currentColor" />
                                    </svg>
                                </b-input-group-text>
                            </template>
                            <b-form-input ref="searchWidgetInputElement" placeholder="Search for currency" size="md"
                                class="border-0" v-model="search"></b-form-input>
                            <b-button @click="search = ''" variant="icon" size="md" class="search-clear"
                                :class="{ 'active': search.length > 0 }">
                            </b-button>
                        </b-input-group> -->
                    </div>
                    <b-tab title="Currencies" active>
                        <b-list-group class="stream-source-list mt-2">
                            <b-list-group-item button variant="dark-3" 
                                v-for="(item, index) in  currencyFiltered"
                                :key="index" 
                                @click="setElement(item, index)"
                                class="d-flex align-items-center text-dark-8 px-3 py-2 text-s-s text-truncate flex-shrink-0">
                                <span class="d-block text-truncate">{{ item.code }} ({{ item.currency }})</span>
                            </b-list-group-item>
                        </b-list-group>
                    </b-tab>
                </b-tabs>
            </b-dropdown-text>
        </b-dropdown>
	</div>
</template>

<script>
export default {
	name: 'AlertModal',
	components: {},
	props: {
        selectedCurrency: {
            type: String
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
	},
    computed: {
        currencyFiltered() {
			return this.curenncyList.filter(curr => curr.currency.toLowerCase().includes(this.search.toLowerCase()))
		},
        currencyName() {
            const index = _.findIndex(this.curenncyList, (curr) => curr.code === this.selected);
            return this.curenncyList[index].code
        },
    },
    mounted() {
        if (this.selectedCurrency) {
            this.selected = this.selectedCurrency.toUpperCase()
        }
    },
	data() {
		return {
            search: '',
			processing: false,
            selected: 'USD',
            curenncyList: 
            [
                {currency: 'United States Dollar', code: 'USD'},
                // {currency: 'Afghan Afghani', code: 'AFN'},
                // {currency: 'Albanian Lek', code: 'ALL'},
                // {currency: 'Algerian Dinar', code: 'DZD'},
                // {currency: 'Angolan Kwanza', code: 'AOA'},
                // {currency: 'Argentine Peso', code: 'ARS'},
                // {currency: 'Armenian Dram', code: 'AMD'},
                // {currency: 'Aruban Florin', code: 'AWG'},
                {currency: 'Australian Dollar', code: 'AUD'},
                // {currency: 'Azerbaijani Manat', code: 'AZN'},
                // {currency: 'Bahamian Dollar', code: 'BSD'},
                // {currency: 'Bangladeshi Taka', code: 'BDT'},
                // {currency: 'Barbadian Dollar', code: 'BBD'},
                // {currency: 'Belize Dollar', code: 'BZD'},
                // {currency: 'Bermudian Dollar', code: 'BMD'},
                // {currency: 'Bolivian Boliviano', code: 'BOB'},
                // {currency: 'Bosnia & Herzegovina Convertible Mark', code: 'BAM'},
                // {currency: 'Botswana Pula', code: 'BWP'},
                // {currency: 'Brazilian Real', code: 'BRL'},
                {currency: 'British Pound', code: 'GBP'},
                // {currency: 'Brunei Dollar', code: 'BND'},
                // {currency: 'Bulgarian Lev', code: 'BGN'},
                // {currency: 'Burundian Franc', code: 'BIF'},
                // {currency: 'Cambodian Riel', code: 'KHR'},
                {currency: 'Canadian Dollar', code: 'CAD'},
                // {currency: 'Cape Verdean Escudo', code: 'CVE'},
                // {currency: 'Cayman Islands Dollar', code: 'KYD'},
                // {currency: 'Central African Cfa Franc', code: 'XAF'},
                // {currency: 'Cfp Franc', code: 'XPF'},
                // {currency: 'Chilean Peso', code: 'CLP'},
                // {currency: 'Chinese Renminbi Yuan', code: 'CNY'},
                // {currency: 'Colombian Peso', code: 'COP'},
                // {currency: 'Comorian Franc', code: 'KMF'},
                // {currency: 'Congolese Franc', code: 'CDF'},
                // {currency: 'Costa Rican Colón', code: 'CRC'},
                // {currency: 'Croatian Kuna', code: 'HRK'},
                // {currency: 'Czech Koruna', code: 'CZK'},
                {currency: 'Danish Krone', code: 'DKK'},
                // {currency: 'Djiboutian Franc', code: 'DJF'},
                // {currency: 'Dominican Peso', code: 'DOP'},
                // {currency: 'East Caribbean Dollar', code: 'XCD'},
                // {currency: 'Egyptian Pound', code: 'EGP'},
                // {currency: 'Ethiopian Birr', code: 'ETB'},
                {currency: 'Euro', code: 'EUR'},
                // {currency: 'Falkland Islands Pound', code: 'FKP'},
                // {currency: 'Fijian Dollar', code: 'FJD'},
                // {currency: 'Gambian Dalasi', code: 'GMD'},
                // {currency: 'Georgian Lari', code: 'GEL'},
                // {currency: 'Gibraltar Pound', code: 'GIP'},
                // {currency: 'Guatemalan Quetzal', code: 'GTQ'},
                // {currency: 'Guinean Franc', code: 'GNF'},
                // {currency: 'Guyanese Dollar', code: 'GYD'},
                // {currency: 'Haitian Gourde', code: 'HTG'},
                // {currency: 'Honduran Lempira', code: 'HNL'},
                // {currency: 'Hong Kong Dollar', code: 'HKD'},
                // {currency: 'Hungarian Forint', code: 'HUF'},
                // {currency: 'Icelandic Króna', code: 'ISK'},
                // {currency: 'Indian Rupee', code: 'INR'},
                // {currency: 'Indonesian Rupiah', code: 'IDR'},
                // {currency: 'Israeli New Sheqel', code: 'ILS'},
                // {currency: 'Jamaican Dollar', code: 'JMD'},
                // {currency: 'Japanese Yen', code: 'JPY'},
                // {currency: 'Kazakhstani Tenge', code: 'KZT'},
                // {currency: 'Kenyan Shilling', code: 'KES'},
                // {currency: 'Kyrgyzstani Som', code: 'KGS'},
                // {currency: 'Lao Kip', code: 'LAK'},
                // {currency: 'Lebanese Pound', code: 'LBP'},
                // {currency: 'Lesotho Loti', code: 'LSL'},
                // {currency: 'Liberian Dollar', code: 'LRD'},
                // {currency: 'Macanese Pataca', code: 'MOP'},
                // {currency: 'Macedonian Denar', code: 'MKD'},
                // {currency: 'Malagasy Ariary', code: 'MGA'},
                // {currency: 'Malawian Kwacha', code: 'MWK'},
                // {currency: 'Malaysian Ringgit', code: 'MYR'},
                // {currency: 'Maldivian Rufiyaa', code: 'MVR'},
                // {currency: 'Mauritanian Ouguiya', code: 'MRO'},
                // {currency: 'Mauritian Rupee', code: 'MUR'},
                {currency: 'Mexican Peso', code: 'MXN'},
                // {currency: 'Moldovan Leu', code: 'MDL'},
                // {currency: 'Mongolian Tögrög', code: 'MNT'},
                // {currency: 'Moroccan Dirham', code: 'MAD'},
                // {currency: 'Mozambican Metical', code: 'MZN'},
                // {currency: 'Myanmar Kyat', code: 'MMK'},
                // {currency: 'Namibian Dollar', code: 'NAD'},
                // {currency: 'Nepalese Rupee', code: 'NPR'},
                // {currency: 'Netherlands Antillean Gulden', code: 'ANG'},
                // {currency: 'New Taiwan Dollar', code: 'TWD'},
                {currency: 'New Zealand Dollar', code: 'NZD'},
                // {currency: 'Nicaraguan Córdoba', code: 'NIO'},
                // {currency: 'Nigerian Naira', code: 'NGN'},
                // {currency: 'Norwegian Krone', code: 'NOK'},
                // {currency: 'Pakistani Rupee', code: 'PKR'},
                // {currency: 'Panamanian Balboa', code: 'PAB'},
                // {currency: 'Papua New Guinean Kina', code: 'PGK'},
                // {currency: 'Paraguayan Guaraní', code: 'PYG'},
                // {currency: 'Peruvian Nuevo Sol', code: 'PEN'},
                // {currency: 'Philippine Peso', code: 'PHP'},
                // {currency: 'Polish Złoty', code: 'PLN'},
                // {currency: 'Qatari Riyal', code: 'QAR'},
                // {currency: 'Romanian Leu', code: 'RON'},
                // {currency: 'Russian Ruble', code: 'RUB'},
                // {currency: 'Rwandan Franc', code: 'RWF'},
                // {currency: 'São Tomé and Príncipe Dobra', code: 'STD'},
                // {currency: 'Saint Helenian Pound', code: 'SHP'},
                // {currency: 'Salvadoran Colón', code: 'SVC'},
                // {currency: 'Samoan Tala', code: 'WST'},
                // {currency: 'Saudi Riyal', code: 'SAR'},
                // {currency: 'Serbian Dinar', code: 'RSD'},
                // {currency: 'Seychellois Rupee', code: 'SCR'},
                // {currency: 'Sierra Leonean Leone', code: 'SLL'},
                // {currency: 'Singapore Dollar', code: 'SGD'},
                // {currency: 'Solomon Islands Dollar', code: 'SBD'},
                // {currency: 'Somali Shilling', code: 'SOS'},
                // {currency: 'South African Rand', code: 'ZAR'},
                // {currency: 'South Korean Won', code: 'KRW'},
                // {currency: 'Sri Lankan Rupee', code: 'LKR'},
                // {currency: 'Surinamese Dollar', code: 'SRD'},
                // {currency: 'Swazi Lilangeni', code: 'SZL'},
                {currency: 'Swedish Krona', code: 'SEK'},
                // {currency: 'Swiss Franc', code: 'CHF'},
                // {currency: 'Tajikistani Somoni', code: 'TJS'},
                // {currency: 'Tanzanian Shilling', code: 'TZS'},
                // {currency: 'Thai Baht', code: 'THB'},
                // {currency: 'Tongan Paʻanga', code: 'TOP'},
                // {currency: 'Trinidad and Tobago Dollar', code: 'TTD'},
                // {currency: 'Turkish Lira', code: 'TRY'},
                // {currency: 'Ugandan Shilling', code: 'UGX'},
                // {currency: 'Ukrainian Hryvnia', code: 'UAH'},
                // {currency: 'United Arab Emirates Dirham', code: 'AED'},
                // {currency: 'Uruguayan Peso', code: 'UYU'},
                // {currency: 'Uzbekistani Som', code: 'UZS'},
                // {currency: 'Vanuatu Vatu', code: 'VUV'},
                // {currency: 'Vietnamese Đồng', code: 'VND'},
                // {currency: 'West African Cfa Franc', code: 'XOF'},
                // {currency: 'Yemeni Rial', code: 'YER'},
                // {currency: 'Zambian Kwacha', code: 'ZMW'},
            ]
		};
	},
	methods: {
        setElement(item, idx) {
            this.selected = item.code
            this.$emit('on-currency-selected', item);
            this.$refs['currency-source'].hide(true)
        },
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
	padding: .25rem .5rem;
	margin: 0 -.5rem;
	transition: .3s;
	word-break: break-all;
}

.title:hover {
	background-color: var(--c-dark-2);
	transition: .3s;
}

.title-editable {
	background-color: var(--c-dark-2);
	outline: none;
	box-shadow: 0 0 2px rgba(0, 0, 0, .3);
}

:deep(.stream-source-menu) {
	width: 22rem;
	border-radius: 6px;
}
:deep(.b-dropdown.show > .stream-source-menu) {
	z-index: 1040;
}
.stream-source-list {
	max-height: 22rem;
	overflow-y: auto;
}

.stream-source-list .list-group-item-action:hover,
.stream-source-list .list-group-item-action:focus {
	background-color: var(--c-dark-4);
	color: #fff;
	outline: none;
}

:deep(.tabs-light-border) {
	border-bottom: 1px solid var(--c-dark-4);
}

.mw-vod {
	max-width: 80%;
}

.select-arrow {
	position: absolute;
	right: 3rem;
	top: 3.5rem;
}
@media (max-width: 767px) {
  .video-placeholder {
		margin: 0 -15px;
	}
}
@media (min-width: 768px) {
  .video-placeholder {
		border-radius: .5rem;
	}
}
.dynamic_players_demo:hover{
	text-decoration: underline;
	color: var(--c-dark-6);
}
.dynamic_players_doc{
	color: var(--c-dark-6);
}
.dynamic_players_doc:hover{
	color: var(--c-dark-6);
}
.analytics-tab-container {
	text-align: center;
}

.analytics-tab-container p {
	color: rgba(174, 186, 214, 1);
	font-weight: 400;
}
</style>
