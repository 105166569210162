<template>
  <g clip-path="url(#clip0_11660_2257)">
    <path
      d="M14.6667 14.6667H8.00004C4.85734 14.6667 3.286 14.6667 2.30968 13.6904C1.33337 12.714 1.33337 11.1427 1.33337 7.99999V1.33333"
      stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path
      d="M12.6668 4.66667L10.5881 7.28427C10.2697 7.68523 10.1105 7.88571 9.92771 7.98342C9.64669 8.13365 9.31089 8.14096 9.0236 8.00309C8.83675 7.91342 8.66898 7.72006 8.33344 7.33333C7.9979 6.9466 7.83012 6.75324 7.64328 6.66357C7.35599 6.5257 7.0202 6.53301 6.73918 6.68323C6.55641 6.78094 6.3972 6.98141 6.07879 7.38236L4 10"
      stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
  </g>
  <defs>
    <clipPath id="clip0_11660_2257">
      <rect width="16" height="16" fill="currentColor" />
    </clipPath>
  </defs>
</template>