<template>
  <svg :width="size" :height="size" class="spinner" viewBox="0 0 50 50">
    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
  </svg>
</template>

<script setup>
import {defineProps} from 'vue'

const props = defineProps({
    size: {
      type: String,
      default: '16px'
    },
    textClasses: {
      type: String,
      default: 'text-s-m'
    },
    classes: {
      type: String,
      default: ''
    },
    spinnerColor: {
      type: String,
      default: '#ccc'
    },
    text: {
      type: String,
      default: ''
    },
})
</script>
<script>
export default {
	name: 'ClipLoader',
  components: {
  },
  props: {
    
  }
}
</script>

<style scoped>
.spinner {
  animation: rotate 2s linear infinite;
  
  & .path {
    stroke: currentColor;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>