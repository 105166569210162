<template>
	<div class="main-wrapper flex flex-col md:flex-row">
		<MainSidebar v-if="!this.$route.meta.fullScreen" />
		<div class="flex flex-wrap justify-center mt-6 mt-lg-0 w-100 translate-all"
			:class="[{ 'main-content-wrapper': isLoggedIn }, sidebarExpanded ? 'lg:max-w-[calc(100vw-260px)]' : 'lg:max-w-[calc(100vw-64px)]' ]">
			<div v-if="shouldShowMeetBar" class="container !px-0 md:px-4 relative">
				<MeetingBar class="md:absolute top-0 left-0 md:left-auto z-[1010]" />
			</div>
			<router-view :subscriptions="user && user.subscriptions" :products-features="user && user.aggregatedDefinition"
				@updateTitle="changeWinTitle" />
			<app-footer />
		</div>
	</div>
	<notifications group="error" classes="vue-notification error" style="top:10px;right:10px" />
	<notifications group="success" classes="vue-notification success" style="top:10px;right:10px" />
	<notifications group="info" classes="vue-notification info" style="top:10px;right:10px" />
	<div id="thumb-temp-loader" />
	<confirm-modal :modal-id="'billing-prompt'" message="Please settle pending subscription invoices before continue."
		ok-text="See Billing" cancel-text="Close" @modal-confirm="navigateToBilling()" />
</template>

<script>
import { mapGetters } from 'vuex';
import { activateIntercom } from '@/lib/analytics';
import AppFooter from '@/components/layout/AppFooter';
import ConfirmModal from '@/components/modals/ConfirmModal';
import UserService from '@/services/UserService';
import _ from 'lodash';
import { clearAllPreviousCache } from './services/CacheManager';
import MeetingBar from './components/ui/MeetingBar.vue';
import MainSidebar from './components/layout/MainSidebar.vue';

export default {
	name: 'App',
	components: {
		AppFooter,
		ConfirmModal,
		MeetingBar,
		MainSidebar
	},
	data() {
		return {
			winHeight: null
		};
	},
	computed: {
		user() {
			return this.$store?.state?.User;
		},
		...mapGetters({
			isLoggedIn: "Auth/isLoggedIn",
			hasLiveSubscription: "User/hasLiveSubscription",
			hasVodSubscription: "User/hasVodSubscription",
			hasMultistreamSubscription: "User/hasMultistreamSubscription",
			userLoaded: "User/userLoaded",
			sidebarExpanded: 'Ui/sidebarExpanded'
		}),
		shouldShowMeetBar() {
			if (!this.$route.meta.fullScreen && this.userLoaded && !this.hasLiveSubscription && !this.hasVodSubscription && !this.hasMultistreamSubscription) return true
			if (!this.$route.meta.fullScreen && this.userLoaded && this.hasLiveSubscription?.trial) return true
			return false
		}
	},
	watch: {
		async $route() {
			setTimeout(this.computeWinHeight, 0);
			if (!this.user || !this.user._id) {
				await this.$store.dispatch('Auth/login');
			}
		}
	},
	async created() {
		clearAllPreviousCache()
		if (!this.user || !this.user._id) {
			await this.$store.dispatch('Auth/login');
			activateIntercom(this.user);
		}
	},
	mounted() {
		this.computeWinHeight();
		this.enableFullStory();
		this.enableORIBI();
		this.enableHeap();
	},
	methods: {
		isGuestPage() {
			const path = window.location.pathname;
			const GuestPages = ['login', 'signup', 'forgot-password', 'forgot-password/reset'].map(el => `/app/${el}`);
			return GuestPages.indexOf(`${path}`) > - 1;
		},
		authenticated() {
			return UserService.authenticated();
		},
		navigateToBilling() {
			window.location.href = '/app/manage/billing';
		},
		changeWinTitle(viewTitle) {
			let updatedTitle = 'Castr';
			if (viewTitle) {
				updatedTitle = viewTitle + ' - ' + updatedTitle;
			}
			// else
			//   updatedTitle = 'Castr | '  +  updatedTitle

			document.title = updatedTitle.trim();
		},
		computeWinHeight() {
			this.winHeight = window.innerHeight - 50 + 'px';
		},
		enableFullStory() {
			setTimeout(() => {
				if (this.authenticated()) {
					const { name, cmsEntityId: amemberUserId, email } = UserService.getUser();
					!_.isEmpty(window.FS) && window.FS.identify(amemberUserId, {
						displayName: name,
						email: email,
						dashboard: 'v2'
					});
				}
			}, 2000);
		},
		enableORIBI() {
			setTimeout(() => {
				if (this.authenticated() && window.ORIBI) {
					const { email } = UserService.getUser();
					window.ORIBI.api('setUserEmail', email);
				}
			}, 1000);
		},
		enableHeap() {
			setTimeout(() => {
				if (this.authenticated() && window.heap) {
					const { email, name } = UserService.getUser();
					window.heap.identify(email);
					window.heap.addUserProperties({ Name: name || 'Noname' });
				}
			}, 1000);
		}
	}
};
</script>

<style>
#app {
	background-color: var(--c-dark-1);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
</style>
