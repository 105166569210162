<template>
	<div>
		<div :class="{ 'stream-ready' :pushReady }" class="player-container">
			<div v-if="mobile" class="text-center player-loading-indicator">
				<div class="icon">
						<i class="fas fa-video-slash" />
					</div>
				Webcam access <br>is not available in mobile.
			</div>
			<div v-else>
			<div v-if="!permissionHappened" class="player-loading-indicator">
				waiting for camera access
			</div>

			<div v-else-if="!webrtcEnabled" class="player-loading-indicator">
				<div class="text-center">
					<div class="icon">
						<i class="fas fa-video-slash" />
					</div>
					<div>Camera access was forbidden</div>
					<div style="font-size: 12px;">
						Kindly allow media access to proceed
					</div>
				</div>
			</div>

			<div v-show="webrtcEnabled" :class="{'stream-ready': pushReady}" class="video-controls">
				<b-badge variant="success">
					<span v-if="pushReady" class="rec-icon">LIVE</span>
				</b-badge>
				<div class="main">
					<div class="actions-container">
						<code
							v-if="!mediaReady"
							class="text-danger"
							style=""
						>Optimizing video ..</code>
						<b-button
							v-if="mediaReady && !pushReady"
							variant="primary"
							size="sm"
							@click="togglePushReady(true)"
						>
							Go Live
						</b-button>
					</div>
				</div>
				<div class="bottom text-center">
					<div v-show="pushReady">
						<b-button
							variant="outline-secondary"
							size="sm"
							class="mr-2"
							@click="togglePushReady(false)"
						>
							Stop
						</b-button>

						<b-button variant="dark-5" size="sm" @click="toggleMute()">
							{{ muted? 'Unmute' : 'Mute' }}
						</b-button>
					</div>
				</div>
			</div>
			<video
				v-show="webrtcEnabled"
				id="webcam-player"
				:class="{blurred: !pushReady}"
				autoplay
			/>
			</div>
		</div>
		<div v-if="error" class="text-danger text-center">
			<code style="color:inherit;">Error, coundn't publish to server</code>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import StreamService from '@/services/StreamService';
import {
  PUBLISHER_EVENTS,
  Publisher,
} from "@flussonic/flussonic-webrtc-player";


export default {
	name: 'WebcamPlayer',
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		audioDevice: String,
		videoDevice: String,
	},
	data() {
		return {
			error: null,
			scopeAlive: true,
			webrtcEnabled: false,
			webcamConstraints: null,
			webcamPublishConf: null,
			permissionHappened: false,
			playback: false,
			pushReady: false,
			mediaReady: false,
			websocket: null,
			peerConn: null,
			videoPlayer: null,
			muted: true,
windowWidth: window.innerWidth,
			publisher: null
		};
	},
	watch: {
		audioDevice(a) {
			this.requestMediaAccess();
		},
		videoDevice(a) {
			this.requestMediaAccess();
		}
	},
	computed:{
		mobile() {
			if (this.windowWidth >767) {
				return false
			} else {
				return true
			}
		},
	},
	async mounted() {
		const video = document.getElementById('webcam-player');

		this.videoPlayer = video;
		this.requestMediaAccess();

		// this.webcamPublishConf = await StreamService.getStreamWebcamPublishConfig(this.stream._id);
		// if (this.stream.type === 'live') {
		// 	const password = this.stream.config.password 
		// 	this.webcamPublishConf.publishUrl += `?password=${password}`
		// }
		// console.log('webcamPublishConf', this.webcamPublishConf);
	},
	destroyed() {
		this.scopeAlive = false;
		this.stopStreaming();
		this.stopMediaAccess();
	},
	methods: {
		togglePushReady (state) {
			// this.pushReady = state
			if (state === true) {
				this.$emit('stream-authorized');
				// skip if pull url is enabled
				if (this.stream.pullUrl) return;

				this.pushReady = state;

				this.createConnection(() => {
					this.startStreaming();
				});
			} else {
				this.pushReady = state;
				this.stopStreaming();
			}
		},
		toggleMute(forceState) {
			const newState = forceState !== undefined ? forceState : !this.muted;
			this.muted = newState;
			this.videoPlayer.muted = newState;
		},
		async requestMediaAccess(constraints) {
			let mediaConstraints = { audio: true, video: true };

			if (this.audioDevice) mediaConstraints.audio = { deviceId: this.audioDevice.deviceId }
			if (this.videoDevice) mediaConstraints.video = { deviceId: this.videoDevice.deviceId }

			if (constraints) { mediaConstraints = constraints; }

			if (navigator.mediaDevices) {
				try {
					const camStream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
					this.webcamConstraints = mediaConstraints;
					this.onMediaAccess(camStream);
				} catch(e) {
					this.onForbidMediaAccess();
				}
			} else {
				this.onForbidMediaAccess();
			}
		},
		createConnection (cb) {
			cb()
		},
		onMediaAccess (stream) {
			this.webrtcEnabled = true;
			this.permissionHappened = true;

			this.videoPlayer.srcObject = stream;
			this.toggleMute(this.muted);

			if (!this.webcamConstraints) {
				const vtracks = stream.getVideoTracks();
				if (_.head(vtracks)) {
					const vtrack = _.head(vtracks);
					const capabilities = vtrack.getCapabilities();
					const maxWidth = capabilities.width.max;
					const maxHeight = capabilities.height.max;

					if (maxHeight && maxWidth) {
						const mediaConstraints = {
							audio: true,
							video: {
								width: { min: 1024, ideal: 1280, max: 1920 },
   								height: { min: 576, ideal: 720, max: 1080 },
							}
						};

						this.mediaReady = false;
						this.webcamConstraints = mediaConstraints;
						this.stopMediaAccess();

						setTimeout(() => {
							this.requestMediaAccess(mediaConstraints);
						}, 500);
						return;
					}
				}
			}

			// lazy loading
			setTimeout(() => {
				this.mediaReady = true;
			}, 500);

			if (!this.pushReady) return;
			this.startStreaming();
		},
		stopMediaAccess () {
			if (!this.videoPlayer || !this.videoPlayer.srcObject) return;
			const mediaStream = this.videoPlayer.srcObject;
			_.each(mediaStream.getTracks(), (streamTrack) => {
				streamTrack.stop();
			});

			this.videoPlayer.srcObject = null;
		},
		async startStreaming () {
			const pushUrl = this.getStreamUrl()
			const opt = {
				whipwhap: true,
				password: this.stream.config.password,
				constraints: {
					video: {
						width: { min: 320, ideal: 1920, max: 4096 },
						height: { min: 240, ideal: 1080, max: 2160 },
					},
					audio: true,
				},
				onWebsocketClose: () => console.log('Publishing socket closed')
			}

			if (this.webcamConstraints) opt.constraints = this.webcamConstraints

			const publisher = new Publisher(pushUrl, opt, true)
			this.publisher = publisher
			publisher.on(PUBLISHER_EVENTS.STREAMING, () => {
			});
      		publisher.start();

			this.$emit('stream-started');
		},
		stopStreaming () {
			if (!this.publisher) return;

			this.publisher.stop();
			this.publisher = null;
			this.$emit('stream-stopped');
		},
		onForbidMediaAccess () {
			console.log('action forbid by user');
			this.permissionHappened = true;
		},
		getStreamUrl () {
			// console.log(this.stream)
			// const subhost = _.get(this.stream, 'region.identifierHaxr');
			// const hostname = `${subhost}.origincdn.com`;
			const hostname = _.get(this.stream, 'region.hostname')
			// return `wss://${hostname}:443/${this.stream.key}/webrtc/publish`;

			return `https://live.castr.io/${this.stream.key}`
		}
	}
};
</script>

<style scoped>
.badge {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 48px;
}
</style>