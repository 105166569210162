<template>
	<div class="expansion-panel">
		<h3 @click="isExpanded = !isExpanded">
			<span>{{ title }}
				<span v-if="betaBadge" class="beta-btn" href="#">BETA</span>
			</span>
			<svg v-show="isExpanded" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M5 15l7-7 7 7" /></svg>
			<svg v-show="!isExpanded" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M19 9l-7 7-7-7" /></svg>
		</h3>
		<div :class="[ isExpanded ? 'expand-it' : 'collapse-it']">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		expanded: {
			type: Boolean,
			default: true
		},
		betaBadge: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isExpanded: this.expanded
		};
	}
};
</script>

<style scoped>
.beta-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  background: #3E64FF;
  display: inline-block;
  padding: 3px 8.5px;
  border-radius: 4px;
  margin-left: 6px;
  transition: all .3s ease-in-out;
}

h3 {
	padding: 14.5px 20px;
	font-size: 16px;
	line-height: 19px;
	font-weight: normal;
	background-color: #373f5e;
	text-transform: capitalize;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	user-select: none;
	margin-bottom: 0;
}

.expansion-panel {
	border-bottom: 1px solid #1E2745;
}

.expansion-panel > div {
	padding: 15px 20px 20px 20px;
}

.expansion-panel > div.collapse-it {
    overflow: hidden;
    max-height: 0px;
    padding: 0px 20px;
    transition: all .5s ease;
}

.expansion-panel > div.expand-it {
    /* overflow: hidden; */
    max-height: 800px;
    transition: all .5s ease;
}
@media only screen and (max-width: 600px){
	.expansion-panel > div.expand-it {
		max-height: unset;
	}
}
svg {
	color: #fff;
	width: 16px;
    height: 16px;
}

.expansion-panel.sc_exp > div.expand-it {
    max-height: unset;
	overflow: unset;
	padding: 18px;
	padding-bottom: 0;
}
</style>
