<script setup>
import { computed, ref } from 'vue'
import TabGroup from '../../components/Atoms/TabGroup.vue';
import TabItem from '../../components/Atoms/TabItem.vue';
import { useRoute } from 'vue-router'

const route = useRoute()

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	},
	streamAlive: {
		type: Boolean
	},
})

const emit = defineEmits({
	'set-live-stream-countdown': (value) => { return value },
	'live-stream-abr-changed': (value) => { return value }
})
const childViewList = ref([
	{
		streamType: 'live',
		name: 'Notifications',
		viewName: 'StreamsManageNotifications'
	},
	{
		streamType: 'live',
		name: 'Webhooks',
		viewName: 'StreamsManageWebhooks'
	},
	{
		streamType: 'restream',
		name: 'Notifications',
		viewName: 'StreamsManageNotifications'
	},
	{
		streamType: 'restream',
		name: 'Webhooks',
		viewName: 'StreamsManageWebhooks'
	},
])

const childViews = computed(() => {
	return (props.stream && childViewList.value.filter(view => {
		const isIncluded = view.streamType === props.stream?.type;
		return isIncluded && view.validate ? view.validate(props.stream) : isIncluded;
	}));
}
)
const liveStreamCountdownSelected = (d) => {
	emit('set-live-stream-countdown', d)
}
const liveStreamAbrChanged = (d) => {
	emit('live-stream-abr-changed', d);
}
const isActive = (name) => {
	return route.name === name
}
</script>
<template>
	<TabGroup>
		<TabItem v-for="(viewItem, index) in childViews" :key="index" :label="viewItem.name" :isActive="isActive(viewItem.viewName)" :to="viewItem.name" />
	</TabGroup>
	<div class="tab-content position-relative mt-4">
		<router-view :stream="stream" :stream-alive="streamAlive" @set-live-stream-countdown="liveStreamCountdownSelected"
			@live-stream-abr-changed="liveStreamAbrChanged" />
	</div>

</template>

<style scoped></style>
