<script setup>
import { computed, ref } from 'vue'

import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconChevronLeft from '../icon/IconChevronLeft.vue';
import MainSidebarItemIcon from './MainSidebarItemIcon.vue';

const props = defineProps({
  expanded: {
    type: Boolean,
  },
  title: {
    type: String,
  },
  icon: {
    type: Object,
  },
  to: {
    type: String,
  },
  onClick: Function,
})

const route = useRoute()

const isExternalLink = computed(() => {
  return typeof props.to === 'string' && props.to.startsWith('http')
})

const isActive = computed(() => {
  return route.path.startsWith(props.to)
})

</script>

<template>
  <a v-if="isExternalLink" target="_blank" :href="props.to" class="text-base md:text-tiny text-surface-8 hover:text-white hover:bg-surface-2 flex items-center gap-x-2 leading-none !py-1 h-8 hover:no-underline rounded" :class="props.expanded ? '!px-3' : '!px-2 w-8'">
    <MainSidebarItemIcon :expanded="props.expanded" :title="props.title" :icon="props.icon" />
    <span :class="props.expanded ? 'opacity-100' : 'opacity-0'" class="translate-all truncate leading-4">{{ props.title }}</span>
  </a>
  <router-link v-else-if="props.to" :to="{ path: props.to }" class="text-base md:text-tiny text-surface-8 hover:text-white hover:bg-surface-2 flex items-center gap-x-2 leading-none !py-1 h-8 hover:no-underline rounded" :class="[props.expanded ? '!px-3' : '!px-2 w-8', {'text-white bg-surface-2': isActive}]">
    <MainSidebarItemIcon :expanded="props.expanded" :title="props.title" :icon="props.icon" />
    <span :class="props.expanded ? 'opacity-100' : 'opacity-0'" class="translate-all truncate leading-4">{{ props.title }}</span>
  </router-link>
  <FormButton v-else @click="onClick" :customClasses="`text-base md:text-tiny text-surface-8 hover:text-white hover:bg-surface-2 flex items-center gap-x-2 leading-none !py-1 h-8 hover:no-underline rounded ${props.expanded ? '!px-3' : '!px-2 w-8'}`">
    <MainSidebarItemIcon :expanded="props.expanded" :title="props.title" :icon="props.icon" />
    <span :class="props.expanded ? 'opacity-100' : 'opacity-0'" class="translate-all truncate leading-4">{{ props.title }}</span>
  </FormButton>
</template>