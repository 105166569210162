<template>
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M24.4616 20.8139V17.0872C24.4616 15.5685 23.2917 14.3952 21.9279 14.3952H18.2251C15.951 14.3952 14.0674 12.3932 14.0674 9.97765V3.9043"
			fill="none"
			stroke="currentColor"
			stroke-width="1.5"
		/>
		<path
			d="M3.54199 20.8139V17.0872C3.54199 15.5685 4.71185 14.3952 6.07565 14.3952H9.77851C12.0526 14.3952 13.9361 12.3932 13.9361 9.97765V3.9043"
			fill="none"
			stroke="currentColor"
			stroke-width="1.5"
		/>
		<path
			d="M14.0009 8.16721C15.6982 8.16721 17.0741 6.70531 17.0741 4.90196C17.0741 3.09862 15.6982 1.63672 14.0009 1.63672C12.3037 1.63672 10.9277 3.09862 10.9277 4.90196C10.9277 6.70531 12.3037 8.16721 14.0009 8.16721Z"
			fill="currentColor"
			stroke="currentColor"
			stroke-width="1.5"
		/>
		<path
			d="M3.75823 26.8845C5.45549 26.8845 6.8314 25.4226 6.8314 23.6192C6.8314 21.8159 5.45549 20.354 3.75823 20.354C2.06096 20.354 0.685059 21.8159 0.685059 23.6192C0.685059 25.4226 2.06096 26.8845 3.75823 26.8845Z"
			stroke="currentColor"
			stroke-width="1.5"
		/>
		<path
			d="M24.247 26.8845C25.9443 26.8845 27.3202 25.4226 27.3202 23.6192C27.3202 21.8159 25.9443 20.354 24.247 20.354C22.5498 20.354 21.1738 21.8159 21.1738 23.6192C21.1738 25.4226 22.5498 26.8845 24.247 26.8845Z"
			stroke="currentColor"
			stroke-width="1.5"
		/>
	</svg>
</template>
