<template>
  <div class="view">
    <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)" spinner-size="15px" />
    <b-row v-if="subAccounts.length > 0 && !loadingStatus && hasSubAccountSubscription && permissionSubAccountCreateView">
      <b-col cols="12">
        <h1 class="page-title text-500 mb-4 mt-4 d-flex flex-wrap align-items-center">
          <div class="d-flex align-items-center mb-3 mb-sm-0">
            Sub Accounts
            <b-badge variant="dark-3" class="stream-count ml-2 text-500" pill >{{subAccountsNumber}}/{{maxSubAccounts.package.definition.maxSubAccounts}}</b-badge>
          </div>
          <b-button :disabled="!subAccountStatus || subAccountsNumber >= maxSubAccounts.package.definition.maxSubAccounts" v-if="subAccounts.length > 0" variant="primary"  v-b-modal="'create-subaccount-modal'" class="ml-auto w-sm-100" >
            <svg class="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 4V10M10 16V10M10 10H4M10 10H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Create a Sub-Account
          </b-button>
        </h1>
        <b-alert v-if="!subAccountStatus" class="d-inline-flex align-items-center" show variant="warning">
          <svg class="mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.36963 4.29623C6.53114 2.17817 7.1119 1.11914 8.00006 1.11914C8.88823 1.11914 9.46898 2.17817 10.6305 4.29623L14.0639 10.5571C15.15 12.5377 15.6931 13.528 15.2575 14.2638C14.8218 14.9996 13.6923 14.9996 11.4334 14.9996H4.56671C2.30779 14.9996 1.17833 14.9996 0.742676 14.2638C0.307027 13.528 0.850109 12.5377 1.93627 10.5571L5.36963 4.29623ZM8.00002 5.24955C8.41424 5.24955 8.75002 5.58534 8.75002 5.99955V8.49955C8.75002 8.91377 8.41424 9.24955 8.00002 9.24955C7.58581 9.24955 7.25002 8.91377 7.25002 8.49955V5.99955C7.25002 5.58534 7.58581 5.24955 8.00002 5.24955ZM8.00006 12.4996C8.55235 12.4996 9.00006 12.0518 9.00006 11.4996C9.00006 10.9473 8.55235 10.4996 8.00006 10.4996C7.44778 10.4996 7.00006 10.9473 7.00006 11.4996C7.00006 12.0518 7.44778 12.4996 8.00006 12.4996Z" fill="currentColor"/>
          </svg>
          <span>Your sub-account subscription has expired. Please&nbsp;<b-link class="text-reset text-underline" onclick="Intercom('show')"> contact our support</b-link>.</span> 
        </b-alert>
        <div>
          <div class="field-container">
            <b-table fixed :items="subAccounts" :fields="fields" :sort-by.sync="sortBy" table-class="main-table with-details"
              thead-class="text-dark-6"  sort-icon-left>
              <template #head(name)="header">
                <div class="d-flex justify-content-between align-items-center text-truncate w-100">
                  {{ header.label }}
                </div>
              </template>
              <template #cell(name)="row">
                <div :class="row.item.locked ? 'text-dark-6' : 'text-dark-9'" class="align-items-center cursor-pointer text-nowrap text-s-m text-truncate">
                  <svg v-if="row.item.locked" :id="'lock-tooltip' + row.item._id" class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 10.5C2 9.09987 2 8.3998 2.27248 7.86502C2.51217 7.39462 2.89462 7.01217 3.36502 6.77248C3.8998 6.5 4.59987 6.5 6 6.5H10C11.4001 6.5 12.1002 6.5 12.635 6.77248C13.1054 7.01217 13.4878 7.39462 13.7275 7.86502C14 8.3998 14 9.09987 14 10.5V10.5C14 11.9001 14 12.6002 13.7275 13.135C13.4878 13.6054 13.1054 13.9878 12.635 14.2275C12.1002 14.5 11.4001 14.5 10 14.5H6C4.59987 14.5 3.8998 14.5 3.36502 14.2275C2.89462 13.9878 2.51217 13.6054 2.27248 13.135C2 12.6002 2 11.9001 2 10.5V10.5Z" stroke="currentColor" stroke-width="1.5"/>
                    <path d="M11 6.5V4.5C11 2.84315 9.65685 1.5 8 1.5V1.5C6.34315 1.5 5 2.84315 5 4.5V6.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M8 10V11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <b-tooltip :target="'lock-tooltip' + row.item._id" placement="top">
                    The account is locked. <b-link class="text-reset text-underline" onclick="Intercom('show')">Contact our support</b-link> if you want to unlock the account.
                  </b-tooltip>
                  {{ row.item.name }}
                </div>
              </template>
              <template #cell(email)="row">
                <div :class="row.item.locked ? 'text-dark-6' : 'text-dark-9'" class="text-truncate text-s-m">
                  {{ row.item.email }}
                </div>
              </template>
              <template #cell(plan)="row">
                <template v-if="row.item.subscriptions.addonSubscriptions.length > 0 && !row.item.locked && currentPlanFull(row.item.subscriptions).length > 0 || row.item.subscriptions.subscription && !row.item.locked && currentPlanFull(row.item.subscriptions).length > 0" class="text-nowrap text-dark-9  text-s-m">
                  <span :class="row.item.locked ? 'text-dark-6' : 'text-dark-9'" v-for="element in currentPlan(row.item.subscriptions)" class="subscription">{{element.package.name}}</span>
                </template>
                <!-- <b-button :disabled="!subAccountStatus" v-else-if="!row.item.locked  && currentPlanFull(row.item.subscriptions).length === 0" :to="'/manage/sub-accounts/subscribe/' + row.item._id" variant="outline-secondary" size="sm">
                  Choose Plan
                </b-button> -->
                <b-button :disabled="!subAccountStatus || isTeamModerator" v-else-if="!row.item.locked  && currentPlanFull(row.item.subscriptions).length === 0" @click="requestPlan(row.item.email)" variant="outline-secondary" size="sm">
                  Request Plan 
                </b-button>
                <span v-else class="text-dark-6">Canceled</span>
              </template>
              <template #cell(action)="row">
                <b-button :disabled="!subAccountStatus || isTeamModerator" v-if="!row.item.locked  && currentPlanFull(row.item.subscriptions).length === 0" @click="requestPlan(row.item.email)" variant="outline-secondary" size="sm" class="d-md-none">
                  Request Plan 
                </b-button>
                <b-button
                  v-if="row.item.subscriptions.addonSubscriptions.length > 0 && currentPlanFull(row.item.subscriptions).length > 0 && !row.item.locked || row.item.subscriptions.subscription && currentPlanFull(row.item.subscriptions).length > 0 && !row.item.locked"
                  variant="outline-secondary"
                  size="sm"
                  class="px-1 btn-details"
                  @click="row.toggleDetails"
                >
                  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6 6L11 1" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </b-button>
                <!-- <b-dropdown :disabled="!subAccountStatus" v-else menu-class="dropdown-menu_md" right no-caret variant="icon"
                  size="sm" toggle-class="pr-0 pl-1" class="">
                  <template #button-content>
                    <svg class="text-dark-8" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="4.25" cy="10" r="1.75" fill="currentColor"/>
                      <circle cx="10" cy="10" r="1.75" fill="currentColor"/>
                      <circle cx="15.75" cy="10" r="1.75" fill="currentColor"/>
                    </svg>
                  </template>
                  <b-dropdown-item :disabled="!subAccountStatus" v-if="!row.item.locked" :to="'/manage/sub-accounts/subscribe/' + row.item._id" class="">
                    <span>Choose Plan</span>
                  </b-dropdown-item>
                  <b-dropdown-item :disabled="!subAccountStatus" v-if="!row.item.locked" @click="chosenSubAccount = row.item" v-b-modal="'lock-subaccount-modal'" class="">
                    <span>Lock Sub-account</span>
                  </b-dropdown-item>
                  <b-dropdown-item :disabled="!subAccountStatus" v-if="row.item.locked" @click="unLockSubAccount(row.item)" class="">
                    <span>Unlock Sub-account</span>
                  </b-dropdown-item>
                </b-dropdown> -->
              </template>
              <template #row-details="row">
                <div v-for="element in currentPlanFull(row.item.subscriptions)">
                  <b-card no-body class="p-3 py-md-0 mx-n2 mb-3" bg-variant="dark-2">
                    <b-row class="package-line py-3">
                      <b-col md="6">
                        <h3 class="text-s-xl text-wrap mb-1">{{ element.package.name }} <b-badge class="ml-2 text-500 text-capitalize" variant="outline-success" pill v-if="element.enabled">active</b-badge></h3>
                        <b-badge variant="dark-3" class="text-500 mb-2 mb-md-0 text-capitalize">{{ formatSubscriptionName(element.package.category) }}</b-badge>
                      </b-col>
                      <b-col class="mt-3 mt-sm-0 text-s-m text-sm-right text-wrap d-flex flex-wrap d-sm-block" md="6">
                        <span class="flex-100 mb-2 mb-sm-0" v-if="!row.item.locked && element.package.category != 'scheduled'">Valid until: <span class="color-danger">{{ element.cend | date('DD MMM, YYYY') }}</span></span>
                        <span class="text-dark-6" v-else-if="!row.item.locked && element.package.category == 'scheduled'"><b-badge class="text-500 text-capitalize" variant="dark-3">bundled</b-badge></span>
                        <!-- <b-button :disabled="row.item.locked || !subAccountStatus" v-if="element.package.category == 'restream' || element.package.category == 'live'" variant="secondary" size="md" :to="'/manage/sub-accounts/subscribe/' + row.item._id" class="ml-auto ml-sm-3 text-nowrap text-dark-9  text-s-m">
                            Change
                        </b-button> -->
                        <b-button :disabled="row.item.locked || !subAccountStatus || isTeamModerator" v-if="element.package.category == 'restream' || element.package.category == 'live'" variant="secondary" size="md" @click="requestPlan(row.item.email)" class="ml-auto ml-sm-3 text-nowrap text-dark-9  text-s-m">
                            Request change
                        </b-button>
                        <!-- <b-button :disabled="row.item.locked || !subAccountStatus" v-if="element.package.category == 'restream' || element.package.category == 'live'" variant="outline-secondary" size="md" @click="chooseSubAccountSubscription(row.item, element.package.name)" class="ml-2 mt-md-2 mt-lg-0">
                          <span>Cancel</span> 
                        </b-button> -->
                      </b-col>
                    </b-row>
                    <hr v-if="element.package.category != 'restream'">
                    <b-row v-if="element.package.category != 'restream'" class="mb-3">
                      <b-col cols="12"><h4 v-if="element.package.category != 'restream'" class="text-s-l text-500 mt-4 mb-4 text-dark-9">Usage Quota</h4></b-col>
                      <b-col v-if="element.package.category != 'scheduled'" cols="3"><h5 class="text-s-m font-normal text-dark-7">Bandwidth</h5></b-col>
                      <b-col v-if="element.package.category != 'scheduled'" cols="9" md="3">
                        <p class="text-s-m mb-2">{{ formatHumanFileze(currentPlanBandwidthAssigned(element.package.category, row.item.bandwidth)).totalBytes }} {{ formatHumanFileze(currentPlanBandwidthAssigned(element.package.category, row.item.bandwidth)).unit }} assigned</p>
                        <b-row class="flex-nowrap gap" no-gutters>
                          <div class="col-6 pr-1">
                            <b-card no-body bg-variant="dark-3" class="py-2 text-center">
                              <p class="text-s-m mb-1 text-nowrap">{{ formatHumanFileze(currentPlanBandwidthUsage(element.package.category, row.item.bandwidth)).totalBytes }} {{ formatHumanFileze(currentPlanBandwidthUsage(element.package.category, row.item.bandwidth)).unit }}</p>
                              <p class="text-dark-8 text-s-s mb-0">Usage</p>
                            </b-card>
                          </div>
                          <div class="col-6 pl-1">
                            <b-card no-body bg-variant="dark-3" class="py-2 text-center">
                              <p class="text-s-m mb-1 text-nowrap">{{ formatHumanFileze(currentPlanBandwidthAssigned(element.package.category, row.item.bandwidth)).totalBytes }} {{ formatHumanFileze(currentPlanBandwidthAssigned(element.package.category, row.item.bandwidth)).unit }}</p>
                              <p class="text-dark-8 text-s-s mb-0">Assigned</p>
                            </b-card>
                          </div>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row v-if="element.package.category != 'restream'" class="mb-3">
                      <b-col cols="3"><h5 class="text-s-m font-normal text-dark-7">Storage</h5></b-col>
                      <b-col cols="9" md="3">
                        <p class="text-s-m mb-2">{{ formatHumanFileze(currentPlanStorageAssigned(element.package.category, row.item.storage)).totalBytes }} {{ formatHumanFileze(currentPlanStorageAssigned(element.package.category, row.item.storage)).unit }} assigned</p>
                        <b-row class="flex-nowrap gap" no-gutters>
                          <div class="col-6 pr-1">
                          <b-card no-body bg-variant="dark-3" class=" py-2 text-center">
                            <p class="text-s-m mb-1 text-nowrap">{{ formatHumanFileze(currentPlanStorageUsage(element.package.category, row.item.storage)).totalBytes }} {{ formatHumanFileze(currentPlanStorageUsage(element.package.category, row.item.storage)).unit }}</p>
                            <p class="text-dark-8 text-s-s mb-0">Usage</p>
                          </b-card>
                          </div>
                          <div class="col-6 pl-1">
                          <b-card no-body bg-variant="dark-3" class="py-2 text-center">
                            <p class="text-s-m mb-1 text-nowrap">{{ formatHumanFileze(currentPlanStorageAssigned(element.package.category, row.item.storage)).totalBytes }} {{ formatHumanFileze(currentPlanStorageAssigned(element.package.category, row.item.storage)).unit }}</p>
                            <p class="text-dark-8 text-s-s mb-0">Assigned</p>
                          </b-card>
                          </div>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
                <!-- <b-button :disabled="row.item.locked || !subAccountStatus" @click="chosenSubAccount = row.item" v-b-modal="'lock-subaccount-modal'" size="md" variant="outline-secondary" class="d-flex ml-auto mr-sm-n2 mt-3 w-sm-100">
                  <svg class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 13.5C2.5 11.6308 2.5 10.6962 2.90192 10C3.16523 9.54394 3.54394 9.16523 4 8.90192C4.69615 8.5 5.63077 8.5 7.5 8.5H12.5C14.3692 8.5 15.3038 8.5 16 8.90192C16.4561 9.16523 16.8348 9.54394 17.0981 10C17.5 10.6962 17.5 11.6308 17.5 13.5V13.5C17.5 15.3692 17.5 16.3038 17.0981 17C16.8348 17.4561 16.4561 17.8348 16 18.0981C15.3038 18.5 14.3692 18.5 12.5 18.5H7.5C5.63077 18.5 4.69615 18.5 4 18.0981C3.54394 17.8348 3.16523 17.4561 2.90192 17C2.5 16.3038 2.5 15.3692 2.5 13.5V13.5Z" stroke="currentColor" stroke-width="1.5"/>
                    <path d="M14 8.5V5.5C14 3.29086 12.2091 1.5 10 1.5V1.5C7.79086 1.5 6 3.29086 6 5.5V8.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M10 12.5V14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span v-if="!row.item.locked">Lock Sub-account</span>
                  <span v-else>Locked</span>
                </b-button> -->
              </template>
            </b-table>
          </div>
        </div>
      </b-col>
      <div class="col-sm-7"></div>
    </b-row>
    <b-row align-h="center" v-else-if="subAccounts.length === 0 && !loadingStatus && hasSubAccountSubscription && permissionSubAccountCreateView">
      <b-col lg="4">
        <b-card bg-variant="transparent" class="mt-6 border-0" body-class="text-center">
          <h3 class="text-dark-9 text-s-xl text-500">Create the first sub-account</h3>
          <p class="text-dark-6 text-s-m">Sub-account is stand-alone account that you can monitor and manage from this account</p>
          <b-button :disabled="!subAccountStatus" variant="primary"  v-b-modal="'create-subaccount-modal'" class="mt-2">
            <svg class="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 4V10M10 16V10M10 10H4M10 10H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Create a Sub-Account
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center" v-else-if="!hasSubAccountSubscription && !loadingStatus">
      <b-col lg="4">
        <b-card bg-variant="transparent" class="mt-6 border-0" body-class="text-center">
          <h3 class="text-dark-9 text-s-xl text-500">Need access to create Sub-account?</h3>
          <p class="text-dark-6 text-s-m">Talk to our Sales team and have your <span class="text-nowrap">sub-accounts</span> set up in less than hour. <a class="text-reset text-underline" target="_blank" href="http://docs.castr.com/en/articles/6537566-creating-sub-accounts-and-managing-on-your-dashboard">What is <span class="text-nowrap">Sub-account</span></a>?</p>
          <b-button variant="primary" onclick="Intercom('showNewMessage', 'I want to have access to Sub-account feature')" class="mt-2">
            Get Access
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <AddSubAccountModal
			@sub-account-created="addNewSubAccount"
		/>
    <!-- <ConfirmModal
      message="Sub-account created successfully! Next, choose a subscription plan for it."
      body="Your sub-account is created but not yet subscribed to any plan. Choose a plan now to activate your sub-account."
      modalId="choose-subscription-modal"
      okText="Choose Subscription"
      cancelText="I’ll Choose Later"
			@modal-confirm="$router.push({ path: '/manage/sub-accounts/subscribe/' + newSubAccount._id })"
		/> -->
    <ConfirmModal
      message="Sub-account created successfully! Next, request a subscription plan for it"
      body="Your sub-account is created but not yet subscribed to any plan. Request our team to add a plan now to activate your sub-account."
      modalId="choose-subscription-modal"
      okText="Request Plan"
      cancelText="I’ll Choose Later"
			@modal-confirm="activateIntercom"
		/>
    <ConfirmModal
      :message="`Lock ${chosenSubAccount.name}’s sub-account?`"
      body="You won’t be able to login to this account. The subscription plan will be canceled."
      modalId="lock-subaccount-modal"
      okText="Lock Sub-Account"
      modalType="danger"
      cancelText="Cancel"
			@modal-confirm="lockSubAccount"
		/>
    <UnsubscribeSubAccountModal
      :message=chosenSubaccountSubscription.title
      :body=chosenSubaccountSubscription.body
      modalId="unsubscribe-subaccount-modal"
      ref="unsubscribe-subaccount-modal"
      okText="Cancel Subscription"
      modalType="danger"
      cancelText="Close"
			@modal-confirm="unsubscribeSubAccount"
		/>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserService from "@/services/UserService"
import SubscriptionService from '@/services/SubscriptionService'
import AddSubAccountModal from '@/components/modals/AddSubAccountModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import UnsubscribeSubAccountModal from '@/components/modals/UnsubscribeSubAccountModal.vue'
import moment from "moment";
import Spinner from "@/components/ui/Spinner.vue"
export default {
  name: "ManageSubAccounts",
  components: {
    AddSubAccountModal,
    ConfirmModal,
    UnsubscribeSubAccountModal,
    Spinner
    // UserService
  },
  data() {
    return {
      sortBy: 'email',
      packages: [],
      subAccounts: [],
      subaccStorage: [],
      subaccBandwidth: [],
      expanded: true,
      // dataLoading: false,
      newSubAccount: null,
      fields: [
        { key: "name", label: "Name", sortable: true, sortDirection: "desc", class: 'col col-md-3' },
        { key: "email", label: 'Email address', sortable: true, sortDirection: "desc", class: 'col col-md-3' },
        { key: "plan", label: "Plan", sortable: false, sortDirection: "desc", class: 'col-md-5 package-col' },
        { key: "action", label: "", sortable: false, class: 'col col-md-1 action-col text-right' }
      ],
      chosenSubaccountSubscription: {
        title: '',
        body: '',
        email: '',
        category: '',
        name: ''
      },
      chosenSubAccount: {}
    };
  },
  // props: {
  //   userSubscription: '',
  // },
  computed: {
    ...mapGetters({
			permissionSubAccountCreateView: 'User/permissionSubAccountCreateView',
      addonSubscriptions: 'User/addonSubscriptions',
      isTeamModerator: 'User/isTeamModerator',
      isOwner: 'User/isOwner',
			isSubAccount: 'User/isSubAccount',
      loadingStatus: "Ui/loadingStatus"
		}),
    // addonSubscriptions: {
    //   get() {
    //     return this.$store.state.User.addonSub
    //   }
    // },
    maxSubAccounts() {
      if (this.addonSubscriptions) {
        const sub = _.cloneDeep(this.addonSubscriptions).find(item => item.category === 'sub-accounts')
        if (sub.definitionOverride) sub.package.definition = _.assign(sub.package.definition, sub.definitionOverride)
        return sub
      }
    },
    subAccountsNumber() {
      return this.subAccounts.length
    },
    subAccountStatus() {
      let subAccountPackage = this.addonSubscriptions.find(item => item.category === 'sub-accounts')
      if (subAccountPackage && subAccountPackage.enabled) {
        return true
      } else return false
    },
    hasSubAccountSubscription() {
      if (this.addonSubscriptions.find( item => item.package && item.package.category == 'sub-accounts')) {
        return true
      } else return false
    }
  },
  async mounted() {
    // this.dataLoading = true
    this.$store.dispatch('Ui/setLoadingStatus', true);
    if (this.permissionSubAccountCreateView) {
      try {
        // const packagesList = await SubscriptionService.getSubscriptionPackages()
        const getSubaccStorage = await UserService.getSubaccStorage()
        const getSubaccBandwidth = await UserService.getSubaccBandwidth()
        const subAccounts = await UserService.getSubAccounts()
        // this.packages = packagesList
        this.subAccounts = subAccounts
        this.subaccStorage = getSubaccStorage;
        this.subaccBandwidth = getSubaccBandwidth;
        this.subAccounts.map(subAccount => {
          subAccount.storage = this.subaccStorage.find( item => item && item.email === subAccount.email)
        })
        this.subAccounts.map(subAccount => {
          subAccount.bandwidth = this.subaccBandwidth.find( item => item && item.email === subAccount.email)
        })
        // this.dataLoading = false
        this.$store.dispatch('Ui/setLoadingStatus', false);
      } catch (err) {
        this.$notify({ group: "error", title: err.error, text: err.message });
        this.loading = false;
        return;
      }
    }
  },
  methods: {
    toggleView() {
      this.expanded = !this.expanded;
      if (this.expanded === true) {
        this.shortStringNumber = 15;
      } else this.shortStringNumber = 5;
    },
    mobile() {
      if (this.windowWidth > 767) {
        return false;
      } else {
        return true;
      }
    },
    formatHumanFileze(bytes) {
      const k = 1000;
      const decimals = 2;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      const res = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
      return {
        totalBytes: res ? res : 0,
        unit: sizes[i],
      };
    },
    filterBandwidth(email) {
      var bandwidth = null;
      this.subaccBandwidth.forEach(function (o) {
        if (o.email == email) {
          bandwidth = {
            assignedBytes: o.assignedBytes,
            usedBytes: o.usedBytes,
          };
        }
      });
      return bandwidth
    },
    currentPlan(subscriptions) {
      const addonSubscriptionsEnabled = subscriptions.addonSubscriptions.filter(item => 
        item.enabled === true
      )
      const addonSubscription = addonSubscriptionsEnabled.find(({category}) => (category === 'live' || category === 'restream'))
      let subscription = ''
      if(subscriptions.subscription && subscriptions.subscription.enabled && (subscriptions.subscription.package.category === 'restream' || subscriptions.subscription.package.category === 'live')) {
        subscription = subscriptions.subscription.package.name
      }
      
      let result = []
      if (addonSubscription) {
        result.push(addonSubscription)
      }
      if (subscriptions.subscription && subscriptions.subscription.enabled) {
        result.push(subscriptions.subscription)
      }
      return result
    },
    currentPlanFull(subscriptions) {
      const addonSubscription = subscriptions.addonSubscriptions.filter(item => 
        item.enabled === true
      )
      // const addonSubscription = addonSubscriptionsEnabled.filter(({category}) => (category === 'live' || category === 'restream'))
      let subscription = ''
      if(subscriptions.subscription && subscriptions.subscription.enabled) {
        subscription = subscriptions.subscription.package.name
      }
      
      let result = addonSubscription
      
      if (subscriptions.subscription && subscriptions.subscription.enabled) {
        result.push(subscriptions.subscription)
      }
      return result
    },
    currentPlanBandwidthUsage(category, plans) {
      const bandwidthUsage = plans.bandwidth.find((item) => item.category === category)
      if (bandwidthUsage) {
        return bandwidthUsage.usedBytes >= 100000000 ? bandwidthUsage.usedBytes : 0; // only show bandwidth > 100MB
      } else return 0
      
    },
    currentPlanBandwidthAssigned(category, plans) {
      const bandwidthAssigned = plans.bandwidth.find((item) => item.category === category)
      if (bandwidthAssigned) {
        return bandwidthAssigned.assignedBytes
      } else return 0
    },
    currentPlanStorageUsage(category, plans) {
      const storageUsage = plans.storage.find((item) => item.category === category)
      if (storageUsage) {
        return storageUsage.usedBytes
      } else return 0
      
    },
    currentPlanStorageAssigned(category, plans) {
      const storageAssigned = plans.storage.find((item) => item.category === category)
      if (storageAssigned) {
        return storageAssigned.assignedBytes
      } else return 0
    },
    async addNewSubAccount(payload) {
      this.newSubAccount = payload
      // const packagesList = await SubscriptionService.getSubscriptionPackages()
      const getSubaccStorage = await UserService.getSubaccStorage()
      const getSubaccBandwidth = await UserService.getSubaccBandwidth()
      const subAccounts = await UserService.getSubAccounts()
      // this.packages = packagesList
      this.subAccounts = subAccounts
      this.subaccStorage = getSubaccStorage;
      this.subaccBandwidth = getSubaccBandwidth;
      this.subAccounts.map(subAccount => {
        subAccount.storage = this.subaccStorage.find( item => item && item.email === subAccount.email)
      })
      this.subAccounts.map(subAccount => {
        subAccount.bandwidth = this.subaccBandwidth.find( item => item && item.email === subAccount.email)
      })
      if (!this.isTeamModerator) {
        this.$root.$emit('bv::show::modal', 'choose-subscription-modal')
      }
    },
    chooseSubAccountSubscription(subaccount, element) {
      const addonSubscription = subaccount.subscriptions.addonSubscriptions.find((item) => 
        item.package.name.includes(element)
      )
      let cend =''
      this.chosenSubaccountSubscription.email = subaccount.email
      if (addonSubscription) {
        cend = moment(addonSubscription.cend).format('DD MMMM YYYY hh:mm A')
        this.chosenSubaccountSubscription.title = 'Cancel ' + addonSubscription.package.name + ' subscription of ' +  subaccount.name + '?'
        this.chosenSubaccountSubscription.body = 'The subscription and its bundled plan (if applicable) will continue to run until ' + cend + '. No more recurrent charges after that.' 
        this.chosenSubaccountSubscription.category = addonSubscription.category
      } else {
        cend = moment(subaccount.subscriptions.subscription.cend).format('DD MMMM YYYY hh:mm A')
        this.chosenSubaccountSubscription.title = 'Cancel ' + subaccount.subscriptions.subscription.package.name + ' subscription of ' +  subaccount.name + '?'
        this.chosenSubaccountSubscription.body = 'The subscription and its bundled plan (if applicable) will continue to run until ' + cend + '. No more recurrent charges after that.' 
        this.chosenSubaccountSubscription.category = subaccount.subscriptions.subscription.package.category
      }
      this.$root.$emit('bv::show::modal', 'unsubscribe-subaccount-modal')
    },
    async unsubscribeSubAccount() {
      await SubscriptionService.unsubscribeSubAccount(this.chosenSubaccountSubscription.category, this.chosenSubaccountSubscription.email, 'Other', 'No comment')
      this.$root.$emit('bv::hide::modal', 'unsubscribe-subaccount-modal')
      this.chosenSubaccountSubscription = {
        title: '',
        body: '',
        email: '',
        category: '',
        name: ''
      }
    },
    async lockSubAccount() {
      await UserService.lockSubAccount(this.chosenSubAccount._id, true)
      this.$notify({
        group: 'success',
        text: `${this.chosenSubAccount.name}’s sub-account was locked`
      });
      const packagesList = await SubscriptionService.getSubscriptionPackages()
      const getSubaccStorage = await UserService.getSubaccStorage()
      const getSubaccBandwidth = await UserService.getSubaccBandwidth()
      const subAccounts = await UserService.getSubAccounts()
      this.packages = packagesList
      this.subAccounts = subAccounts
      this.subaccStorage = getSubaccStorage;
      this.subaccBandwidth = getSubaccBandwidth;
      this.subAccounts.map(subAccount => {
        subAccount.storage = this.subaccStorage.find( item => item && item.email === subAccount.email)
      })
      this.subAccounts.map(subAccount => {
        subAccount.bandwidth = this.subaccBandwidth.find( item => item && item.email === subAccount.email)
      })
    },
    async unLockSubAccount(subaccount) {
      await UserService.lockSubAccount(subaccount._id, false)
      const packagesList = await SubscriptionService.getSubscriptionPackages()
      const getSubaccStorage = await UserService.getSubaccStorage()
      const getSubaccBandwidth = await UserService.getSubaccBandwidth()
      const subAccounts = await UserService.getSubAccounts()
      this.packages = packagesList
      this.subAccounts = subAccounts
      this.subaccStorage = getSubaccStorage;
      this.subaccBandwidth = getSubaccBandwidth;
      this.subAccounts.map(subAccount => {
        subAccount.storage = this.subaccStorage.find( item => item && item.email === subAccount.email)
      })
      this.subAccounts.map(subAccount => {
        subAccount.bandwidth = this.subaccBandwidth.find( item => item && item.email === subAccount.email)
      })
      this.$notify({
        group: 'success',
        text: `${this.chosenSubAccount.name}’s sub-account was unlocked`
      });
    },
    formatSubscriptionName(category) {
      switch (category) {
        case 'live':
          category = 'Live Streaming'
          break
        case 'restream':
          category = 'Restreaming'
          break  
        case 'ipcam':
          category = 'IP Camera Restreaming'
          break
        case 'vod':
          category = 'Video Hosting'
          break
        case 'scheduled':
          category = 'Scheduler'
          break
        case 'sub-accounts':
          category = 'Sub-accounts'
          break
      }

      return category
    },
    activateIntercom() {
      window.Intercom('showNewMessage', 'I want to add subscription plan for sub-account ' + this.newSubAccount.email)
    },
    requestPlan(email) {
      window.Intercom('showNewMessage', 'I want to add subscription plan for sub-account ' + email)
    }
  },
  watch:{
    async addonSubscriptions(){
      // const packagesList = await SubscriptionService.getSubscriptionPackages()
      const getSubaccStorage = await UserService.getSubaccStorage()
      const getSubaccBandwidth = await UserService.getSubaccBandwidth()
      const subAccounts = await UserService.getSubAccounts()
      // this.packages = packagesList
      this.subAccounts = subAccounts
      this.subaccStorage = getSubaccStorage;
      this.subaccBandwidth = getSubaccBandwidth;
      this.subAccounts.map(subAccount => {
        subAccount.storage = this.subaccStorage.find( item => item && item.email === subAccount.email)
      })
      this.subAccounts.map(subAccount => {
        subAccount.bandwidth = this.subaccBandwidth.find( item => item && item.email === subAccount.email)
      })

      if (this.isSubAccount && this.isOwner || this.isSubAccount && !this.isOwner && !this.permissionSubAccountCreateView) {
        this.$router.push({ path: '/' });
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 992px) {
  .table-responsive {
    overflow-x: visible;
  }
}
@media (max-width: 767px) {
  :deep(.table .action-col) {
    text-align: right;
  }
  :deep(.table .package-col) {
    visibility: hidden;
    width: 1px;
    padding: 0;
  }
  .package-col  > * {
    display: none;
  }
}
</style>
<style>
th.b-table-sort-icon-left:after,
th.b-table-sort-icon-left:before {
  border: 4px solid transparent;
  content: "";
  display: block;
  height: 0;
  left: 7px;
  top: 50%;
  position: absolute;
  width: 0;
}

th.b-table-sort-icon-left {
  position: relative;
}

th.b-table-sort-icon-left:before {
  border-bottom-color: var(--c-dark-6);
  margin-top: -9px;
}

th.b-table-sort-icon-left:after {
  border-top-color: var(--c-dark-6);
  margin-top: 1px;
}
.subscription + .subscription:before {
  content: ', ';
}
.package-line + .package-line {
  border-top: 1px solid var(--c-dark-3);
}
</style>