<template>
	<div>
		<div class="content-container">
			<platform-list
				:stream="stream"
				:media-pulse="mediaPulse"
				@platform-list="onPlatformListUpdated"
				@refresh-stream="requestStreamRefresh"
			/>
		</div>
	</div>
</template>

<script>
import PlatformList from '@/components/ui/PlatformList.vue';

// const SourceTypes = {
//   Pull: 'pull',
//   Publish: 'publish',
//   Webcam: 'webcam'
// }

export default {
	name: 'ChannelManage',
	components: {
		PlatformList
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		mediaPulse: {
			type: Object,
			default() { return {}; }
		}
	},
	beforeRouteLeave(to, from, next) {
		// if (this.streamSourceType === SourceTypes.Webcam && this.webcamPushReady) {
		//   this.$root.$emit('bv::show::modal', 'modal-webcam-leave-navigation')
		//   this.webcamRouteLeaveNavigationCallback = () => next()
		//   return
		// }

		next();
	},
	data() {
		return {
			processing: true,
			processingMessage: null,
			rmptPullUrlProcessing: false,
			streamSourceType: null,
			streamSourceTypeModel: null,
			streamPreviewSpecs: {},
			streamPullUrl: null,
			streamPullError: false,
			streamPullSourceChunksCount: 0,
			pullSourceWorking: true,
			pullSourceStatusTimeoutCtrl: -1,
			webcamPushReady: false,
			streamSourceTypeProcessing: null,
			streamId: null,
			streamFps: null,
			streamKeyVisible: false,
			streamKeyVisibleTimeout: 0,
			streamKeyVisibleTimeoutCtrl: null,
			windowHeight: 0,
			webcamRouteLeaveNavigationCallback: null
		};
	},
	watch: {
		mediaPulse() {
			this.onMediaPulseChanged();
		}
	},
	async mounted() {
		// get stream details
		await this.setupStream();
		// event tracking
		window.trackEvent(this.stream.name + ' - Stream Dashboard Page', this.stream);
	},
	methods: {
		async setupStream() {
			// get stream details
			try {
				const { stream } = this;
				// this.stream = stream;
				this.streamId = this.stream._id;
			} catch (err) {
				// redirect to stream list
				console.log(err);
				this.$router.push({ name: 'ChannelList' });
				this.$notify({ group: 'error', title: err.error, text: err.message });
			}
		},
		requestStreamRefresh(cb) {
			this.$emit('refresh-stream', cb);
		},
		onPlatformListUpdated(platformList) {
			this.$emit('stream-updated', { platforms: platformList });
		},
		onMediaPulseChanged() {}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.view-wrapper {
  color: #f7f7f7;
  margin-top: 20px;
}
.title {
  padding: 5px 0;
  font-size: 20px;
}
.title input {
  margin: 0;
  padding: 2px 3px;
  color: inherit;
  background-color: transparent;
  border: none;
  width: auto;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
}
.title input:hover {
  background-color: #17193e;
}
.title input:focus {
  /* background-color: #17193e; */
  outline-color: #ffffff;
}
.subtitle {
  padding: 5px 0;
  font-size: 16px;
}
.content-container {
  padding: 5px 0 40px 0;
}
.preveiw-container {
  /* padding: 0 10px 0 20px; */
  padding: 0 10px 0 10px;
}

.placeholder {
  font-size: 21px;
  opacity: 0.75;
  margin: 15px 0;
}
.source-switch-container {
  float: right;
  clear: both;
}

.field-container {
  /* width: 235px; */
  width: 100%;
  padding: 10px 0;
  position: relative;
}
.field-container:last-of-type {
  border-bottom: none;
}
.label {
  font-size: 12px;
  opacity: 0.65;
  text-transform: capitalize;
}
.input {
  display: block;
  width: 100%;
  height: auto !important;
  margin: 10px 0 10px 0;
  padding: 10px 14px;
  color: #ffffff;
  /* background-color: #010329; */
  background-color: #202940;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  outline-color: #0074fc;
}
.input:focus {
  background-color: rgba(18, 23, 37, 0.67);
}
.video-thumb {
  width: inherit;
  height: 100%;
  background-size: 100% auto;
  background-position: center center;
}
.video-thumb.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  min-height: 350px;
}

.field-container .badge-button {
  opacity: 0;
  font-size: 11px;
  padding: 4px 9px;
  position: absolute;
  right: 10px;
  top: 46px;
  pointer-events: none;
  transition: all 0.15s linear;
}
.field-container:hover .badge-button {
  opacity: 1;
  pointer-events: inherit;
}
.field-container-sm .badge-button {
  top: 8px;
}
</style>
