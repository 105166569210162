<template>
	<figure :class="{'bg-primary': isActive}" @click="selectPlatform" class="mw-100 w-100 h-100 d-flex flex-column align-items-center justify-content-between p-2 cursor-pointer rounded platform-item">
    <b-img v-if="platform && platform.name" width="100" :src="platform.icon" :alt="platform.name" />
    <icon-base v-else width-view-box="24" height-view-box="24" iconColor="none" width="24" height="24" class="text-white mt-2">
      <icon-settings />
    </icon-base>
    <figcaption class="figure-caption">
      <h4 class="text-s-m text-500 text-white">{{ platform && platform.name ? platform.name : 'Custom' }}</h4>
    </figcaption>
  </figure>
</template>

<script>

import Spinner from "@/components/ui/Spinner.vue";
import IconBase from '@/components/icon/IconBase.vue'
import IconSettings from '@/components/icon/IconSettings.vue'

export default {
	name: 'PlatformItem',
	components: {
		Spinner,
		IconBase,
		IconSettings
	},
	props: {
		platform: {
			type: Object,
			required: false,
			// validator(v) {
			// 	return Object.prototype.hasOwnProperty.call(v, '_id');
			// }
		},
	},
	data() {
		return {
			
		};
	},
	computed: {
		isActive() {
      return !this.platform && this.$store.state.Destination.isCustom || this.$store.state.Destination.selectedPlatform && this.$store.state.Destination.selectedPlatform?.name === this.platform?.name
    }
	},
	watch: {
		
	},
	async mounted() {
	
	},
	methods: {
    selectPlatform() {
      this.$store.dispatch('Destination/selectPlatform', this.platform)
			if (this.platform?.servers?.length === 1) this.$store.dispatch('Destination/setServer', this.platform?.servers[0])
    }
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.platform-item:hover {
  background-color: var(--c-dark-5);
}
</style>