import _ from 'lodash'
import Vue from 'vue'
import RequestError from './RequestError'
import api from '@/api'
import { getFromCache, setCache } from './CacheManager';

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

export default {
	login,
	register,
	authenticated,
	setSession,
	setOwnerSession,
	unsetSession,
	getInvitedpermission,
	updateInvitedpermission,
	getUser,
	getUserId,
	getUserToken,
	getSubaccBandwidth,
	teaminfoOwner,
	teamInfoMember,
	setTeam,
	inviteTeamadmin,
	joinTeam,
	selectTeam,
	removeMember,
	toggleMember,
	getMemberpermission,
	updateMemberpermission,
	getTeamOwner,
	getInvited,
	getMyTeam,
	changeTeamname,
	getSubaccStorage,
	changeName,
	changeUsername,
	requestPasswordReset,
	validatePasswordReset,
	changePassword,
	getUserApiKey,
	getUserEmail,
	uploadOttPoster,
	uploadOttLogo,
	uploadShowcaseLogo,
	deleteShowcaselogo,
	deleteShowcaseBg,
	uploadShowcaseSettings,
	publishShowcase,
	deleteOttposter,
	deleteOttLogo,

	checkEmail,
	createSubAccount,
	getSubAccounts,
	lockSubAccount,

	createUserContact,
	getUserContacts,
	deleteUserContact,
	updateUserContact,
	getUserInfo,

	getShowcase,
	createShowcase,
	addShowcase,
	getShowcasePublic
}

function updateUserContact(contact, payload, meta = true) {
	return makeRequest({
		method: 'put',
		path: `/users/contacts/${contact}?meta=${meta}`,
		data: payload
	})
}

function deleteUserContact(contactId) {
	const url = `/users/contacts/${contactId}`;
	return makeRequest({
		path: url,
		method: 'delete'
	});
}

async function getUserContacts(type = 'phone') {
	const config = {
		path: `/users/contacts?type=${type}`
	}
	let response = await makeRequest(config)
	return response
}

function createUserContact(phone) {
	return makeRequest({
		method: 'post',
		path: '/users/contacts',
		data: { phone }
	})
}
async function getUserInfo() {
	const key = `/users/me`;
    const data = getFromCache(key);
    if(data) return data;

	const config = {
		path: '/users/me',
		headers: {
			'auth-token': localStorage.getItem('token')
		}
	}
	let response = await makeRequest(config)
	setCache(key, response);
	return response
}

async function getSubaccBandwidth() {
	const config = {
		path: '/usage_metrics/sub_accounts/bandwidth'
	}
	let response = await makeRequest(config)
	return response
}

async function getShowcase() {
	const config = {
		path: '/showcase?detailed=true',
		headers: {
			'auth-token': getUserToken()
		}
	}
	let response = await makeRequest(config)
	return response
}

async function getShowcasePublic(id) {
	const config = {
		path: `/showcase/${id}?detailed=true`,
		// headers: {
		// 	'auth-token': getUserToken()
		// }
	}
	let response = await makeRequest(config)
	return response
}

function createShowcase() {
	return makeRequest({
		method: 'post',
		path: '/showcase',
		data: { },
		headers: {
			'auth-token': getUserToken()
		}
	})
}
function addShowcase(streams, vods, order, id) {
	return makeRequest({
		method: 'put',
		path: `/showcase/${id}`,
		data: { 'streams': streams, 'vods': vods, 'streamOrder': order },
		headers: {
			'auth-token': getUserToken()
		}
	})
}

async function setTeam(teamname) {
	return makeRequest({
		path: `/teams`,
		method: 'post',
		data: { name: teamname },
		headers: {
			'auth-token': getUserToken()
		}
	});
}
async function joinTeam(id) {
	return makeRequest({
		path: `/teams/${id}/join`,
		method: 'post',
		data: { isEnabled: true },
		headers: {
			'auth-token': getUserToken()
		}
	});
}
async function inviteTeamadmin(id, name, email, permissions) {
	return makeRequest({
		path: `/teams/${id}/invite-user`,
		method: 'post',
		data: { name: name, email: email, permissions: permissions },
		headers: {
			'auth-token': getUserToken()
		}
	});
}
async function selectTeam(id) {
	return makeRequest({
		path: `/teams/select/${id}`,
		method: 'post',
		headers: {
			'auth-token': getUserToken()
		}
	});
}
async function getMemberpermission(tid,mid) {
	const res = await makeRequest({
		path: `/teams/${tid}/member/${mid}`,
		headers: {
			'auth-token': getUserToken()
		}
	});
	const permissionKeys = [
		"isEnabled",
		"liveStreamView",
		"liveStreamManage",
		"liveStreamDelete",
		"liveStreamToggle",
		"liveStreamPlatformToggle",
		"recordedStreamManage",
		"recordedStreamDelete",
		"recordedStreamToggle",
		"recordedStreamPlatformToggle",
		"preRecordedStreamManage",
		"preRecordedStreamDelete",
		"preRecordedStreamToggle",
		"preRecordedStreamPlatformToggle",
		"hostingManage",
		"hostingDelete",
		"paywall",
		"developers",
		"refer",
		"accountView",
		"teamView",
		"billingAccess",
		"subAccountInfoView",
		"subAccountInfoCreate",
		"subAccountStreamView",
		"subSecondStreamView", // only in FE right now
		"subSecondStreamManage",
		"subSecondStreamDelete",
	]
	if(res.isEnabled && res.role === 'admin') {
		permissionKeys.forEach(key => {
			res[key] = true;
		})
	}

	if(res.isEnabled && res.role === 'moderator') {
		const subSecond = [
			"subSecondStreamView", // only in FE right now
			"subSecondStreamManage",
			"subSecondStreamDelete",
		]
		subSecond.forEach(key => {
			res[key] = true;
		})

		// set vod manage state for already active moderators
		if(res['hostingDelete']) {
			res['hostingManage'] = res['hostingDelete'];
		}
	}
	
	return res;
}
async function getInvitedpermission(id) {
	return makeRequest({
		path: `/teams/invitation-permission/${id}`,
		headers: {
			'auth-token': getUserToken()
		}
	});
}
async function updateInvitedpermission(id, permission) {
	return makeRequest({
		path: `/teams/invitation-permission/${id}`,
		method: 'put',
		data:  {permissions: permission} ,
		headers: {
			'auth-token': getUserToken()
		}
	});
}
async function updateMemberpermission(tid,mid,permission) {
	return makeRequest({
		path: `/teams/${tid}/member/${mid}/permissions`,
		method: 'put',
		data:  permission ,
		headers: {
			'auth-token': getUserToken()
		}
	});
}
async function removeMember(mid, tid, endpoint) {
	return makeRequest({
		method: 'delete',
		path: `/teams/${tid}/${endpoint}/${mid}`,
		// headers: {
		// 	'auth-token': getUserToken()
		// }
	});
}
async function toggleMember(mid, tid, enabled, endpoint) {
	return makeRequest({
		path: `/teams/${tid}/${endpoint}/${mid}/permissions`,
		method: 'put',
		data: { isEnabled: enabled },
		headers: {
			'auth-token': getUserToken()
		}
	});
}
async function changeTeamname(tid, tname) {
	return makeRequest({
		path: `/teams/${tid}`,
		method: 'put',
		data: { name: tname, access: {} },
		headers: {
			'auth-token': getUserToken()
		}
	});
}
async function teaminfoOwner() {
	const config = {
		path: '/my-teams',
		headers: {
			'auth-token': getUserToken()
		}
	}
	let response = await makeRequest(config)
	return response
}
async function getMyTeam() {
	const config = {
		path: 'teams/my-active-team',
		headers: {
			'auth-token': getUserToken()
		}
	}
	let response = await makeRequest(config)
	return response
}
async function teamInfoMember() {
	const config = {
		path: '/teams',
		headers: {
			'auth-token': getUserToken()
		}
	}
	let response = await makeRequest(config)
	return response
}
async function getTeamOwner(teamID, nameonly=false, guest=false) {
	let pathParam = '?role=true';
	if(nameonly){
		pathParam = '?invitation=true';
	}
	if(guest){
		pathParam = '?guest=true';
	}
	const config = {
		path: `/teams/info/${teamID+pathParam}`,
		headers: {
			'auth-token': getUserToken()
		}
	}
	let response = await makeRequest(config)
	return response
}
async function getInvited(teamID) {
	const config = {
		path: `/teams/${teamID}/invited-users`,
		headers: {
			'auth-token': getUserToken()
		}
	}
	let response = await makeRequest(config)
	return response
}

async function getSubaccStorage() {
	const config = {
		path: '/usage_metrics/sub_accounts/storage'
	}
	let response = await makeRequest(config)
	return response
}
  
function authenticated() {
	return getUserId() && getUserToken()
}

function login(email, password) {
	return makeRequest({
		method: 'post',
		path: '/users/login',
		data: { email, password }
	})
}

/**
 * @param {object} user
 * @param {string} user.name
 * @param {string} user.email
 * @param {string} user.password
 * @param {object} user.phoneCaptchaResponse
 * @param {string} user.phoneCaptchaResponse.code
 * @param {string} user.phoneCaptchaResponse.token
 * @param {string} user.phoneCaptchaResponse.phone
 */
function register(user) {
	return makeRequest({
		method: 'post',
		path: '/users/register',
		data: { user }
	})
}

function setSession(user, token) {
	localStorage.setItem('user', JSON.stringify(user))
	localStorage.setItem('token', token)
}

function setOwnerSession(user) {
	localStorage.setItem('owneruser', JSON.stringify(user))
}

function unsetSession(user, token) {
	Vue.localStorage.remove('user')
	Vue.localStorage.remove('token')
	delete Vue.axios.defaults.headers.common['auth-token']
}

// function unsetOwnerSession() {
// 	Vue.localStorage.remove('owneruser')
// }

function deleteOttposter(integrationId) {
	const url = '/users/app_poster ';
	return makeRequest({
		path: url,
		method: 'delete'
	});
}

function deleteOttLogo(integrationId) {
	const url = '/users/app_logo ';
	return makeRequest({
		path: url,
		method: 'delete'
	});
}

function getUserId(activeTeam=null) {
	let user = JSON.parse(localStorage.getItem('user'))
	let owneruser = JSON.parse(localStorage.getItem('owneruser'))
	if(!activeTeam){
		return user && user._id
	}else{
		return owneruser && owneruser.ownerId
	}
}

function getUserEmail() {
	let user = JSON.parse(localStorage.getItem('user'))
	return user && user.email
}

function getUserToken() {
	return localStorage.getItem('token')
}

function getUser(activeTeam = null) {
	if(!activeTeam){
		return JSON.parse(localStorage.getItem('user'))
	}else{
		let owneruser = JSON.parse(localStorage.getItem('owneruser'))
		return owneruser && owneruser.owner
	}
}

/**
 * @param {string} name
 */
async function changeName(name) {
	await makeRequest({
		method: 'put',
		path: `/users/${getUserId()}/update`,
		data: { updates: { name } }
	})

	const updatedUser = _.assign({}, getUser(), { name })
	localStorage.setItem('user', JSON.stringify(updatedUser))
}

/**
 * @param {string} username
 */
async function changeUsername(username) {
	await makeRequest({
		method: 'put',
		path: `/users/username`,
		data: { updates: { username } }
	})

	const updatedUser = _.assign({}, getUser(), { username })
	localStorage.setItem('user', JSON.stringify(updatedUser))
}

/**
 * @param {string} email
 */
async function requestPasswordReset(email) {
	await makeRequest({
		method: 'post',
		path: `/users/support/reset-password/request`,
		data: { email }
	})
}

/**
 * @param {string} email
 */
async function checkEmail(email) {
	const config = {
		method: 'post',
		path: '/users/check_existing_email',
		data: { email: email },
		headers: {
			'auth-token': getUserToken()
		},
	}
	let response = await makeRequest(config)
	return response
}

/**
 * @param {string} name
 * @param {string} email
 * @param {string} password
 */
 async function createSubAccount(name, email, password) {
	const config = {
		method: 'post',
		path: '/users/sub_accounts',
		data: {
			user: { 
				name: name,
				email: email,
				password: password 
			}
		},
		headers: {
			'auth-token': getUserToken()
		},
	}
	let response = await makeRequest(config)
	return response
}

/**
 * @param {string} sub_account
 * @param {string} status
 */
 function lockSubAccount(sub_account, status) {
  return makeRequest({
    path: `/users/sub_accounts/${sub_account}`,
    method: 'put',
    data: { 
      locked: status
    }
  })
}

/**
 * @param {string} name
 * @param {string} email
 * @param {string} password
 */
 async function getSubAccounts(name, email, password) {
	const config = {
		method: 'get',
		path: '/users/sub_accounts',
		headers: {
			'auth-token': getUserToken()
		},
	}
	let response = await makeRequest(config)
	return response
}

/**
 * @param {string} token
 * @param {string} password
 * @param {string} confirmPassword
 */
async function validatePasswordReset(token, password, confirmPassword) {
	await makeRequest({
		method: 'post',
		path: `/users/support/reset-password/verify`,
		data: { token, password, confirmPassword }
	})
}

/**
 * @param {object} form
 * @param {string} form.currentPassword
 * @param {string} form.newPassword
 * @param {string} form.newPasswordConfirm
 */
function changePassword(form) {
	return makeRequest({
		method: 'post',
		path: `/users/${getUserId()}/reset-password`,
		data: form
	})
}


function uploadOttPoster(fdata) {
	return makeRequest({
		path: `/users/app_poster`,
		method: 'post',
		data:  fdata ,
		headers: {
			'content-type': 'multipart/form-data'
		}
	});
}

function uploadOttLogo(fdata) {
	return makeRequest({
		path: `/users/app_logo`,
		method: 'post',
		data:  fdata ,
		headers: {
			'content-type': 'multipart/form-data'
		}
	});
}

function uploadShowcaseLogo(fdata ,id) {
	return makeRequest({
		path: `/showcase/images/logo/${id}`,
		method: 'post',
		data:  fdata ,
		headers: {
			// 'content-type': 'multipart/form-data',
			'auth-token': getUserToken()
		}
	});
}
function deleteShowcaselogo(id) {
	const url = `/showcase/images/logo/${id}`;
	return makeRequest({
		path: url,
		method: 'delete',
		headers: {
			// 'content-type': 'multipart/form-data',
			'auth-token': getUserToken()
		}
	});
}
function deleteShowcaseBg(id) {
	const url = `/showcase/images/backgroundImage/${id}`;
	return makeRequest({
		path: url,
		method: 'delete',
		headers: {
			// 'content-type': 'multipart/form-data',
			'auth-token': getUserToken()
		}
	});
}
function publishShowcase(id) {
	const url = `/showcase/${id}/publish`;
	return makeRequest({
		path: url,
		method: 'post',
		headers: {
			// 'content-type': 'multipart/form-data',
			'auth-token': getUserToken()
		}
	});
}

function uploadShowcaseSettings(settings,id) {
	return makeRequest({
		path: `/showcase/${id}`,
		method: 'put',
		data:  {'settings': settings} ,
		headers: {
			// 'content-type': 'multipart/form-data',
			'auth-token': getUserToken()
		}
	});
}

function getUserApiKey() {
	return makeRequest('/users/developer/apiKey')
}

/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeRequest(reqConfig) {
	if (typeof reqConfig === 'string') {
		reqConfig = {
			path: reqConfig
		}
	}

	reqConfig.url = reqConfig.path

	let res
	try {
		res = await api.request(reqConfig)
	} catch (err) {
		let edata = _.get(err, 'response.data')
		throw new RequestError(edata)
	}

	return res.data
}