<script setup>
import { computed } from 'vue'

import { useStore } from 'vuex'

import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconChevronLeft from '../icon/IconChevronLeft.vue';
import Title from '../ui/Title.vue';
import PlayoutMode from './PlayoutMode.vue';
import PlayoutPreview from './PlayoutPreview.vue';
import PlayoutSidebarTabs from './PlayoutSidebarTabs.vue';
import PlayoutControls from './PlayoutControls.vue';
import StreamService from '../../services/StreamService';

const props = defineProps({
  mediaPulse: {
    type: Object,
  },
})

const store = useStore()

const playout = computed(() => store.getters['Streams/tvPlayout'])

const saveNewName = (newName) => {
  StreamService.setStreamName(playout?.value._id, newName);
}
</script>

<template>
  <aside class="border-r border-surface-2 pt-8 px-3">
    <div class="flex items-center mb-4">
      <FormButton isIcon type="link" to="/tv-playout" size="sm">
        <icon-base class="" fill="none">
          <icon-chevron-left />
        </icon-base>
      </FormButton>
      <Title :editable="true" :title="playout?.name" @changed="saveNewName" />
		</div>
    <PlayoutMode :mediaPulse="mediaPulse" class="mb-4" />
    <PlayoutPreview class="mb-4" :mediaPulse="mediaPulse" />
    <PlayoutSidebarTabs :mediaPulse="mediaPulse" class="mb-4" />
    <PlayoutControls />
  </aside>
</template>

<style scoped>
</style>
