<template>
  <div class="container showcase-container">
    <div v-if="!this.preview">
      <header class="mt-5 mb-4">
        <b-row>
          <b-col>
            <div class="d-flex align-items-center justify-content-between w-100 doc showcase">
              <div>
                <h1 class="page-title mb-0">Showcase </h1>
                <p class="text-dark-6 text-s-s m-0">
                  <a target="_blank" class="text-reset mr-3 inline-flex items-center"
                    href="https://docs.castr.com/en/articles/7220837-set-up-a-showcase">
                    <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                      fill="none">
                      <path d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z"
                        stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
                      <path d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z"
                        stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
                    </svg>
                    How Showcase works
                  </a>
                  <a class="text-reset mr-3 inline-flex items-center" v-b-modal.modal-demo-video>
                    <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                      fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9 2.8999C8.66863 2.8999 8.4 3.16853 8.4 3.4999C8.4 3.83127 8.66863 4.0999 9 4.0999H14.5C14.8314 4.0999 15.1 3.83127 15.1 3.4999C15.1 3.16853 14.8314 2.8999 14.5 2.8999H9ZM9 7.3999C8.66863 7.3999 8.4 7.66853 8.4 7.9999C8.4 8.33127 8.66863 8.5999 9 8.5999H14.5C14.8314 8.5999 15.1 8.33127 15.1 7.9999C15.1 7.66853 14.8314 7.3999 14.5 7.3999H9ZM2 11.8999C1.66863 11.8999 1.4 12.1685 1.4 12.4999C1.4 12.8313 1.66863 13.0999 2 13.0999H14.5C14.8314 13.0999 15.1 12.8313 15.1 12.4999C15.1 12.1685 14.8314 11.8999 14.5 11.8999H2ZM1 3.80567V8.19412C1 8.77296 1.62791 9.13362 2.12791 8.84195L5.88943 6.64771C6.38556 6.3583 6.38555 5.64145 5.88942 5.35204L2.1279 3.15784C1.62791 2.86618 1 3.22683 1 3.80567Z"
                        fill="#8794B5" />
                    </svg>
                    Showcase Demo
                  </a>
                </p>
              </div>
              <div class="d-flex align-items-center justify-content-end showcase-control">
                <b-button variant="outline-secondary" size="md" class="mr-2" @click="copyLink"
                  v-if="this.publicshowcase && ((this.publicshowcase.streams.length != 0) || (this.publicshowcase.vods.length != 0))">
                  <b-img :src="require('@/assets/images/links.svg')" alt="check" class="mr-2" height="20px" />
                  Copy Link
                </b-button>
                <b-button variant="outline-secondary" size="md" class="mr-2" @click="openPreview()"
                  v-if="this.showcase && ((this.showcase.streams.length != 0) || (this.showcase.vods.length != 0))">
                  <b-img :src="require('@/assets/images/external_link.svg')" alt="check" class="mr-2" height="20px" />
                  Open Preview
                </b-button>
                <b-button size="md" variant="primary" @click="publish()" :disabled="!this.publishesStatus">
                  Publish
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </header>
      <b-row>
        <b-col>
          <div class="tabs tabs-light">
            <b-nav class="" tabs>
              <b-nav-item link-classes="nav-tabs_light" to="/showcase/content"
                :active="$route.path === '/showcase/content'">
                Content
              </b-nav-item>
              <b-nav-item link-classes="nav-tabs_light" to="/showcase/settings"
                :active="$route.path === '/showcase/settings'">
                Settings
              </b-nav-item>
            </b-nav>
            <div class="tab-content">
              <router-view></router-view>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div class="showcase_preview_wrapper">
        <header class="s_preview">
          <div class="container">
            <p class="mb-0 back_showcase" @click="closePreview()">
              <img src="@/assets/images/left-arrow.svg" alt="Folder" class="mr-2">
              <span class="text-dark-8 text-s-m mb-0">Back to Dashboard</span>
            </p>
            <b-button size="md" variant="primary" :disabled="!this.publishesStatus" @click="publish()">
              Publish
            </b-button>
          </div>
        </header>
        <section class="preview_banner"
          :style="((this.showcase.settings && this.showcase.settings.backgroundUrl) ? 'background-image: url(https://assets.castr.io/showcaseImages/' + this.showcase.settings.backgroundUrl + ')' : '')">
          <div class="container">
            <img :src="'https://assets.castr.io/showcaseImages/' + this.showcase.settings.logoUrl"
              v-if="this.showcase.settings && this.showcase.settings.logoUrl" alt="">
            <h2 v-if="this.showcase.settings && this.showcase.settings.title">{{ this.showcase.settings.title }}</h2>
            <p v-if="this.showcase.settings && this.showcase.settings.description">{{ this.showcase.settings.description
            }}</p>
            <button class="sw_preview_btn"><a href="javascript:void(0);">Start Watching Videos</a></button>
          </div>
        </section>
        <section class="preview_body">
          <div class="container">
            <p class="sowcase_preview_list px-2">{{ this.showcase.vods.length }} videos, {{ this.showcase.streams.length
            }} live streams</p>
            <div>
              <tr class="" v-for="stream in this.sortedStream" :key="stream._id">
                <!-- <div v-if="streamFilter(stream)"> -->
                <!-- {{stream}} -->
                <showcase-thumb :streamo="stream"></showcase-thumb>
                <p class="mb-0" v-if="stream.type">{{ stream.name }}</p>
                <p class="mb-0" v-else>{{ stream.fileName }}</p>
                <!-- </div> -->
              </tr>
            </div>
          </div>
        </section>
      </div>
    </div>
    <demo-video-modal videoid="hWjuTQYhYQ2UKlgVkwa7s" />
  </div>
</template>

<script>
import _ from "lodash";
// import ShowcaseDetails from "@/components/ui/ShowcaseDetails";
import ClipLoader from "@/components/ui/ClipLoader.vue";
import UserService from "@/services/UserService";
import ShowcaseThumb from '@/components/ui/ShowcaseThumb.vue';
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';
export default {
  name: "StreamManageShowcase",
  components: {
    ClipLoader,
    DemoVideoModal,
    ShowcaseThumb
  },
  props: {

  },
  async mounted() {

    this.checkStatus();

    this.$root.$on('publishesStatus', async data => {
      console.log('data');
      console.log(data);
      this.publishesStatus = data;
      let showcase = await UserService.getShowcase();
      this.showcase = showcase;
    });
    let self = this;
    this.$root.$on('publishSettings', data => {
      //   console.log('datadatadatadatadatadatadatadatadatadata');
      self.checkStatus();
      //   setTimeout(() => {
      //       this.publish();
      //   }, 2000);
    });


  },
  methods: {
    async checkStatus() {
      let showcase = await UserService.getShowcase();
      this.showcase = showcase;
      try {
        let publicshowcase = await UserService.getShowcasePublic(showcase._id);
        this.publicshowcase = publicshowcase;
        let checkSettings = JSON.stringify(showcase.settings) === JSON.stringify(this.publicshowcase.settings);
        let checkStreams = this.arraysEqual(showcase.streamOrder, this.publicshowcase.streamOrder);
        this.publishesStatus = !(this.publicshowcase && checkSettings && checkStreams);
        // console.log('lpkkjggtyujinjbyftyguhjibvytfrdftgyh');
        // console.log(showcase);
        // console.log(publicshowcase);
        // console.log(checkSettings);
        // console.log(checkStreams);
      }
      catch (e) {
        // window.alert('error: ' + e.message)
        this.publishesStatus = false;
        console.log('eee');
        console.log(e);
      }
    },
    async openPreview() {
      let showcase = await UserService.getShowcase();
      this.showcase = showcase;
      this.preview = true;
      this.sortStream(this.showcase);
    },
    sortStream(sobject) {
      let sortedStream = sobject.streams.concat(sobject.vods);
      sortedStream.map(s => {
        if (s.id) {
          s._id = s.id;
        }
      });
      sortedStream.sort((a, b) => {

        if (sobject.streamOrder.indexOf(a._id) > sobject.streamOrder.indexOf(b._id)) {
          return 1;
        } else {
          return -1;
        }

      });
      console.log('this.showcasedStream');
      console.log(sortedStream);
      this.sortedStream = sortedStream;
    },
    closePreview() {
      this.preview = false;
    },
    async publish(events = false) {
      console.log(this.showcase);
      let publish = await UserService.publishShowcase(this.showcase._id);
      console.log('publish');
      console.log(publish);
      this.showcase = publish;
      if (publish.streams) {
        let publicshowcase = await UserService.getShowcasePublic(this.showcase._id);
        this.publicshowcase = publicshowcase;
        if (!events) {
          this.$notify({ group: "success", text: 'Showcase content published' });
        }
        // else{
        //     this.$notify({ group: "success", text: 'Showcase settings updated & published' });
        // }
      }
      this.publishesStatus = false;
    },
    copyLink() {
      let link = `https://castr.com/showcase/${this.showcase._id}`;
      if (link) {
        this.$copyText(link);
        this.$notify({ group: 'info', text: 'Link copied!' });
      }
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }
  },
  watch: {
  },
  data() {
    return {
      preview: false,
      showcase: null,
      sortedStream: null,
      publishesStatus: false,
      publicshowcase: null
    };
  },
};
</script>

<style scoped></style>
